import { cn } from "common/utils";
import React from "react";
import styled from "styled-components";

const StyledWrapperRowTabNavLinkWrapper = styled.nav`
  a {
    display: inline-flex;
    height: inherit;
    padding-left: 9px;
    padding-right: 9px;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    color: #737476;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    align-items: center;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    white-space: nowrap;

    &.dont_activate {
      color: #21272d;

      &:not(:last-child) {
        margin-right: 9px;
        border-right: 1px solid rgb(223, 223, 217);
      }
    }

    &.active:not(.dont_activate) {
      color: #21272d;
      border-bottom: 2px solid #bcb3a5;
    }

    &:hover {
      color: #21272d;
    }

    &:first-child {
      margin-left: 9px;
    }
  }
`;

// // Convert the RowTabNavLinkWrapper to a tailwind component
export const RowTabNavLinkWrapper = ({
  children,
  showBorderBottom,
  className,
  actions,
}: {
  children: React.ReactNode;
  showBorderBottom?: boolean;
  className?: string;
  actions?: React.ReactNode;
}) => {
  const hasActions = !!actions;

  return (
    <StyledWrapperRowTabNavLinkWrapper
      className={cn("flex flex-1 relative h-14", {
        "border-b": showBorderBottom,
        "justify-between": hasActions,
        className,
      })}
    >
      <div className="flex flex-row gap-2 h-full justify-center items-center">
        {children}
      </div>
      {actions && (
        <div className="flex flex-row gap-2 h-full justify-center items-center pr-4 relative">
          {actions}
        </div>
      )}
    </StyledWrapperRowTabNavLinkWrapper>
  );
};
