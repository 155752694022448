import { gql } from "@apollo/client";

export const GetTransactionFundDocuments = gql`
  query DocumentsInformation($transactionId: String!) {
    documentsInformation(transactionId: $transactionId) {
      transactionDocumentId
      fileId
      fileName
      holdingId
      documentProcessed
    }
  }
`;
