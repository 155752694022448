import styled from "styled-components";

export const StyledFundDetails = styled.div`
  display: block;
  padding: 24px;
  box-sizing: border-box;
  background: var(--branding-texture-2, #fafaf8);

  div.bid_actions {
    display: flex;
    margin-top: 24px;

    button,
    a {
      display: flex;
      flex: 1;
    }
  }
`;
