export enum CapitalAccountKeys {
  FundCommittedCapital = "fund_committed_capital",
  FundContributedCapitalItd = "fund_contributed_capital_itd",
  FundDistributedCapitalItd = "fund_distributed_capital_itd",
  FundRecallableCapitalItd = "fund_recallable_capital_itd",
  FundRemainingUnfunded = "fund_remaining_unfunded",
  FundEndingCapitalAccountValue = "fund_ending_capital_account_value",
  GpCommittedCapital = "gp_committed_capital",
  GpContributedCapitalItd = "gp_contributed_capital_itd",
  GpDistributedCapitalItd = "gp_distributed_capital_itd",
  GpRecallableCapital = "gp_recallable_capital",
  GpRemainingUnfunded = "gp_remaining_unfunded",
  GpEndingCapitalAccountValue = "gp_ending_capital_account_value",
  GpDistributedAsCarryItd = "gp_distributed_as_carry_itd",
  AllLpCommittedCapital = "all_lp_committed_capital",
  AllLpsContributedCapitalItd = "all_lps_contributed_capital_itd",
  AllLpsDistributedCapitalItd = "all_lps_distributed_capital_itd",
  AllLpsRecallableCapital = "all_lps_recallable_capital",
  AllLpsRemainingUnfunded = "all_lps_remaining_unfunded",
  AllLpsEndingCapitalAccountValue = "all_lps_ending_capital_account_value",
  LpCommittedCapital = "lp_committed_capital",
  LpContributedCapitalItd = "lp_contributed_capital_itd",
  LpDistributedCapitalItd = "lp_distributed_capital_itd",
  LpRecallableCapitalItd = "lp_recallable_capital_itd",
  LpRemainingUnfunded = "lp_remaining_unfunded",
  LpEndingCapitalAccountValue = "lp_ending_capital_account_value",
  LpPartnershipPercent = "lp_partnership_percent",
  LpContributedCapitalYtd = "lp_contributed_capital_ytd",
  LpContributedCapitalQtd = "lp_contributed_capital_qtd",
  LpDistributedCapitalYtd = "lp_distributed_capital_ytd",
  LpDistributedCapitalQtd = "lp_distributed_capital_qtd",
  FundNavQoqPercentChg1q = "fund_nav_qoq_percent_chg_1q",
  FundNavQoqPercentChg2q = "fund_nav_qoq_percent_chg_2q",
  FundNavQoqPercentChg3q = "fund_nav_qoq_percent_chg_3q",
  FundNavYoyPercentChangeFy1 = "fund_nav_yoy_percent_change_fy1",
  FundNavYoyPercentChangeFy2 = "fund_nav_yoy_percent_change_fy2",
}

export interface ICapitalAccount {
  [CapitalAccountKeys.FundCommittedCapital]: string;
  [CapitalAccountKeys.FundContributedCapitalItd]: string;
  [CapitalAccountKeys.FundDistributedCapitalItd]: string;
  [CapitalAccountKeys.FundRecallableCapitalItd]: string;
  [CapitalAccountKeys.FundRemainingUnfunded]: string;
  [CapitalAccountKeys.FundEndingCapitalAccountValue]: string;
  [CapitalAccountKeys.GpCommittedCapital]: string;
  [CapitalAccountKeys.GpContributedCapitalItd]: string;
  [CapitalAccountKeys.GpDistributedCapitalItd]: string;
  [CapitalAccountKeys.GpRecallableCapital]: string;
  [CapitalAccountKeys.GpRemainingUnfunded]: string;
  [CapitalAccountKeys.GpEndingCapitalAccountValue]: string;
  [CapitalAccountKeys.GpDistributedAsCarryItd]: string;
  [CapitalAccountKeys.AllLpCommittedCapital]: string;
  [CapitalAccountKeys.AllLpsContributedCapitalItd]: string;
  [CapitalAccountKeys.AllLpsDistributedCapitalItd]: string;
  [CapitalAccountKeys.AllLpsRecallableCapital]: string;
  [CapitalAccountKeys.AllLpsRemainingUnfunded]: string;
  [CapitalAccountKeys.AllLpsEndingCapitalAccountValue]: string;
  [CapitalAccountKeys.LpCommittedCapital]: string;
  [CapitalAccountKeys.LpContributedCapitalItd]: string;
  [CapitalAccountKeys.LpDistributedCapitalItd]: string;
  [CapitalAccountKeys.LpRecallableCapitalItd]: string;
  [CapitalAccountKeys.LpRemainingUnfunded]: string;
  [CapitalAccountKeys.LpEndingCapitalAccountValue]: string;
  [CapitalAccountKeys.LpPartnershipPercent]: string;
  [CapitalAccountKeys.LpContributedCapitalYtd]: string;
  [CapitalAccountKeys.LpContributedCapitalQtd]: string;
  [CapitalAccountKeys.LpDistributedCapitalYtd]: string;
  [CapitalAccountKeys.LpDistributedCapitalQtd]: string;
  [CapitalAccountKeys.FundNavQoqPercentChg1q]: string;
  [CapitalAccountKeys.FundNavQoqPercentChg2q]: string;
  [CapitalAccountKeys.FundNavQoqPercentChg3q]: string;
  [CapitalAccountKeys.FundNavYoyPercentChangeFy1]: string;
  [CapitalAccountKeys.FundNavYoyPercentChangeFy2]: string;
}

export const capitalAccountKeyLabels: Record<keyof ICapitalAccount, string> = {
  [CapitalAccountKeys.FundCommittedCapital]: "Commitment",
  [CapitalAccountKeys.FundContributedCapitalItd]: "Contributed Capital",
  [CapitalAccountKeys.FundDistributedCapitalItd]: "Distributed Capital",
  [CapitalAccountKeys.FundRecallableCapitalItd]: "Recallable Capital",
  [CapitalAccountKeys.FundRemainingUnfunded]: "Remaining Unfunded",
  [CapitalAccountKeys.FundEndingCapitalAccountValue]: "NAV",
  [CapitalAccountKeys.GpDistributedAsCarryItd]: "GP Distributed As Carry",
  [CapitalAccountKeys.GpCommittedCapital]: "GP Committed Capital",
  [CapitalAccountKeys.GpContributedCapitalItd]: "GP Contributed Capital",
  [CapitalAccountKeys.GpDistributedCapitalItd]: "GP Distributed Capital",
  [CapitalAccountKeys.GpRecallableCapital]: "GP Recallable Capital",
  [CapitalAccountKeys.GpRemainingUnfunded]: "GP Remaining Unfunded",
  [CapitalAccountKeys.GpEndingCapitalAccountValue]:
    "GP Ending Capital Account Value",
  [CapitalAccountKeys.AllLpCommittedCapital]: "All LPs Committed Capital",
  [CapitalAccountKeys.AllLpsContributedCapitalItd]:
    "All LPs Contributed Capital",
  [CapitalAccountKeys.AllLpsDistributedCapitalItd]:
    "All LPs Distributed Capital",
  [CapitalAccountKeys.AllLpsRecallableCapital]: "All LPs Recallable Capital",
  [CapitalAccountKeys.AllLpsRemainingUnfunded]: "All LPs Remaining Unfunded",
  [CapitalAccountKeys.AllLpsEndingCapitalAccountValue]:
    "All LPs Ending Capital Account Value",
  [CapitalAccountKeys.LpCommittedCapital]: "LP Committed Capital",
  [CapitalAccountKeys.LpContributedCapitalItd]: "LP Contributed Capital",
  [CapitalAccountKeys.LpDistributedCapitalItd]: "LP Distributed Capital",
  [CapitalAccountKeys.LpRecallableCapitalItd]: "LP Recallable Capital",
  [CapitalAccountKeys.LpRemainingUnfunded]: "LP Remaining Unfunded",
  [CapitalAccountKeys.LpEndingCapitalAccountValue]:
    "LP Ending Capital Account Value",
  [CapitalAccountKeys.LpPartnershipPercent]: "LP Partnership Percent",
  [CapitalAccountKeys.LpContributedCapitalYtd]: "LP Contributed Capital Ytd",
  [CapitalAccountKeys.LpContributedCapitalQtd]: "LP Contributed Capital Qtd",
  [CapitalAccountKeys.LpDistributedCapitalYtd]: "LP Distributed Capital Ytd",
  [CapitalAccountKeys.LpDistributedCapitalQtd]: "LP Distributed Capital Qtd",
  [CapitalAccountKeys.FundNavQoqPercentChg1q]: "Fund NAV QoQ Percent Chg 1q",
  [CapitalAccountKeys.FundNavQoqPercentChg2q]: "Fund NAV QoQ Percent Chg 2q",
  [CapitalAccountKeys.FundNavQoqPercentChg3q]: "Fund NAV QoQ Percent Chg 3q",
  [CapitalAccountKeys.FundNavYoyPercentChangeFy1]:
    "Fund NAV YoY Percent Change FY1",
  [CapitalAccountKeys.FundNavYoyPercentChangeFy2]:
    "Fund NAV YoY Percent Change FY2",
};

export interface CasTab {
  name: string;
  data: string;
}

export interface CapitalAccountsCsv {
  casTabs: CasTab[];
}

// Table 1; "Fund"
export const fundTableKeys = [
  CapitalAccountKeys.FundCommittedCapital,
  CapitalAccountKeys.FundContributedCapitalItd,
  CapitalAccountKeys.FundDistributedCapitalItd,
  CapitalAccountKeys.FundRecallableCapitalItd,
  CapitalAccountKeys.FundRemainingUnfunded,
  CapitalAccountKeys.FundEndingCapitalAccountValue, // TODO @ JEFF - This is not in the data
];

// Table 2; "GP"
export const gpTableKeys = [
  CapitalAccountKeys.GpCommittedCapital,
  CapitalAccountKeys.GpContributedCapitalItd,
  CapitalAccountKeys.GpDistributedCapitalItd,
  CapitalAccountKeys.GpRecallableCapital,
  CapitalAccountKeys.GpRemainingUnfunded,
  CapitalAccountKeys.GpEndingCapitalAccountValue,
  CapitalAccountKeys.GpDistributedAsCarryItd,
];

// Table 3; "All LPs"
export const allLpKeys = [
  CapitalAccountKeys.AllLpCommittedCapital,
  CapitalAccountKeys.AllLpsContributedCapitalItd,
  CapitalAccountKeys.AllLpsDistributedCapitalItd,
  CapitalAccountKeys.AllLpsRecallableCapital,
  CapitalAccountKeys.AllLpsRemainingUnfunded,
  CapitalAccountKeys.AllLpsEndingCapitalAccountValue,
];

// Table 4; "Individual LP"
export const individualLpKeys = [
  CapitalAccountKeys.LpCommittedCapital,
  CapitalAccountKeys.LpContributedCapitalItd,
  CapitalAccountKeys.LpDistributedCapitalItd,
  CapitalAccountKeys.LpRecallableCapitalItd,
  CapitalAccountKeys.LpRemainingUnfunded,
  CapitalAccountKeys.LpEndingCapitalAccountValue,
  CapitalAccountKeys.LpPartnershipPercent,
  CapitalAccountKeys.LpContributedCapitalYtd,
  CapitalAccountKeys.LpContributedCapitalQtd,
  CapitalAccountKeys.LpDistributedCapitalYtd,
  CapitalAccountKeys.LpDistributedCapitalQtd,
  CapitalAccountKeys.FundNavQoqPercentChg1q,
  CapitalAccountKeys.FundNavQoqPercentChg2q,
  CapitalAccountKeys.FundNavQoqPercentChg3q,
  CapitalAccountKeys.FundNavYoyPercentChangeFy1,
  CapitalAccountKeys.FundNavYoyPercentChangeFy2,
];

export const currencyRowKeys = [
  CapitalAccountKeys.FundCommittedCapital,
  CapitalAccountKeys.FundContributedCapitalItd,
  CapitalAccountKeys.FundDistributedCapitalItd,
  CapitalAccountKeys.FundRecallableCapitalItd,
  CapitalAccountKeys.FundRemainingUnfunded,
  CapitalAccountKeys.FundEndingCapitalAccountValue,
  CapitalAccountKeys.GpDistributedAsCarryItd,
  CapitalAccountKeys.GpCommittedCapital,
  CapitalAccountKeys.GpContributedCapitalItd,
  CapitalAccountKeys.GpDistributedCapitalItd,
  CapitalAccountKeys.GpRecallableCapital,
  CapitalAccountKeys.GpRemainingUnfunded,
  CapitalAccountKeys.GpEndingCapitalAccountValue,
  CapitalAccountKeys.AllLpCommittedCapital,
  CapitalAccountKeys.AllLpsContributedCapitalItd,
  CapitalAccountKeys.AllLpsDistributedCapitalItd,
  CapitalAccountKeys.AllLpsRecallableCapital,
  CapitalAccountKeys.AllLpsRemainingUnfunded,
  CapitalAccountKeys.AllLpsEndingCapitalAccountValue,
  CapitalAccountKeys.LpCommittedCapital,
  CapitalAccountKeys.LpContributedCapitalItd,
  CapitalAccountKeys.LpDistributedCapitalItd,
  CapitalAccountKeys.LpRecallableCapitalItd,
  CapitalAccountKeys.LpRemainingUnfunded,
  CapitalAccountKeys.LpEndingCapitalAccountValue,
  CapitalAccountKeys.LpContributedCapitalYtd,
  CapitalAccountKeys.LpContributedCapitalQtd,
  CapitalAccountKeys.LpDistributedCapitalYtd,
  CapitalAccountKeys.LpDistributedCapitalQtd,
];

export const percentageRowKeys = [
  CapitalAccountKeys.LpPartnershipPercent,
  CapitalAccountKeys.FundNavQoqPercentChg1q,
  CapitalAccountKeys.FundNavQoqPercentChg2q,
  CapitalAccountKeys.FundNavQoqPercentChg3q,
  CapitalAccountKeys.FundNavYoyPercentChangeFy1,
  CapitalAccountKeys.FundNavYoyPercentChangeFy2,
];

export const TableLabels = {
  Fund: "Fund",
  Gp: "GP",
  AllLps: "All LPs",
  IndividualLp: "Individual LP",
};
