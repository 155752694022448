import { RequestedBuyer } from "../models/RequestedBuyer";

export const requestedBuyerMock: RequestedBuyer[] = [
  {
    id: "09e74835-8430-42cb-8c48-ea5191b48f62",
    logoFileName: "BigRockCapitalPartners.jpg",
    name: "Big Rock Capital Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "NO",
    matchScore: 95,
  },
  {
    id: "98f8f36c-50f6-4d82-adec-19dce304bab8",
    logoFileName: "OakwoodEquityHoldings.jpg",
    name: "Oakwood Equity Holdings",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScope: "I, II, III, IV",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "YES",
    matchScore: 94,
  },
  {
    id: "a970f985-b3fc-4805-9372-0278ae2daa31",
    logoFileName: "CrestwoodPrivateWealth.jpg",
    name: "Crestwood Private Wealth",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Wealth Manager",
    checkSize: "$50M - $500M",
    staple: "YES",
    matchScore: 94,
  },
  {
    id: "e8f3df9b-272b-4c27-8349-91b8c96b17e3",
    logoFileName: "FalconStrategicInvestments.jpg",
    name: "Falcon Strategic Investments",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$30M - $80M",
    staple: "NO",
    matchScore: 94,
  },
  {
    id: "f9921794-1d5d-49e9-b8b4-2d6e4f2b9ad1",
    logoFileName: "AuroraVentureHoldings.jpg",
    name: "Aurora Venture Holdings",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "I, II",
    type: "Family Office",
    checkSize: "$10 - $50M",
    staple: "NO",
    matchScore: 90,
  },
  {
    id: "db0fbfad-a4f0-422b-a553-92fb2dae7399",
    logoFileName: "BluePeakEquityPartners.jpg",
    name: "BluePeak Equity Partners",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Pension",
    checkSize: "$10 - $150M",
    staple: "YES",
    matchScore: 89,
  },
  {
    id: "9fe1af7c-67d4-43d1-ae71-3fdef0b03667",
    logoFileName: "CrestviewCapital.jpg",
    name: "Crestview Capital",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Pension",
    checkSize: "$30M - $80M",
    staple: "YES",
    matchScore: 86,
  },
  {
    id: "253aaac5-57d2-4eba-be70-54261f9bb6ab",
    logoFileName: "DragonflyInvestmentGroup.jpg",
    name: "Dragonfly Investment Group",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "I, II, III, IV",
    type: "Endowment",
    checkSize: "$50M - $500M",
    staple: "NO",
    matchScore: 86,
  },
  {
    id: "8b0b3a76-35eb-4fa8-99d9-57594ffaba09",
    logoFileName: "EclipsePrivateEquity.jpg",
    name: "Eclipse Private Equity",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Pension",
    checkSize: "$10 - $150M",
    staple: "NO",
    matchScore: 83,
  },
  {
    id: "7736fb79-6d8e-4394-a48f-9344c7492122",
    logoFileName: "FalconCrestFunds.jpg",
    name: "Falcon Crest Funds",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "IV",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "YES",
    matchScore: 80,
  },
  {
    id: "f7ce32c1-64ab-45e3-89f8-ab5801d76c93",
    logoFileName: "GoldenHorizonPartners.jpg",
    name: "Golden Horizon Partners",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "I, II",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    matchScore: 80,
  },
  {
    id: "fab2dd7d-e9d4-4f68-a924-2bedbb391706",
    logoFileName: "HorizonWealthManagement.jpg",
    name: "Horizon Wealth Management",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$30M - $80M",
    staple: "NO",
    matchScore: 79,
  },
  {
    id: "e4795160-a2d0-43cd-8ca5-818accb3f41a",
    logoFileName: "InfinityEquityVentures.jpg",
    name: "Infinity Equity Ventures",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Endowment",
    checkSize: "$10 - $50M",
    staple: "NO",
    matchScore: 76,
  },
  {
    id: "274cabf8-121a-4abd-86be-d595a7c9f82e",
    logoFileName: "JadeStoneCapital.jpg",
    name: "Jade Stone Capital",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Pension",
    checkSize: "$30M - $80M",
    staple: "YES",
    matchScore: 76,
  },
  {
    id: "6a61250c-9eac-4b90-a4fa-783670646e70",
    logoFileName: "KestrelPrivateEquity.jpg",
    name: "Kestrel Private Equity",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$10 - $50M",
    staple: "YES",
    matchScore: 75,
  },
  {
    id: "dca1e843-c538-4462-b80d-10fc7b67c114",
    logoFileName: "LunarCapitalManagement.jpg",
    name: "Lunar Capital Management",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "NO",
    matchScore: 72,
  },
  {
    id: "3c6f9acf-c954-4627-9a20-7614d7323f8b",
    logoFileName: "MysticRiverInvestments.jpg",
    name: "Mystic River Investments",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "NO",
    matchScore: 72,
  },
  {
    id: "34d5ff48-96dc-468f-bbe8-cc0eb07f5375",
    logoFileName: "NebulaVenturePartners.jpg",
    name: "Nebula Venture Partners",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$30M - $80M",
    staple: "YES",
    matchScore: 71,
  },
  {
    id: "10ee4465-f2d5-4fa7-9fa4-f943fde2937c",
    logoFileName: "OrionEquitySolutions.jpg",
    name: "Orion Equity Solutions",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$10 - $50M",
    staple: "YES",
    matchScore: 68,
  },
  {
    id: "84fc4165-55b7-43a4-9a70-a18a78318d1e",
    logoFileName: "PhoenixRisingCapital.jpg",
    name: "Phoenix Rising Capital",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Wealth Manager",
    checkSize: "$10 - $150M",
    staple: "NO",
    matchScore: 68,
  },
  {
    id: "decb8b39-4b8a-4c14-bdc4-ff15ba44c1b4",
    logoFileName: "QuasarPrivateFunds.jpg",
    name: "Quasar Private Funds",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "I, II, III, IV",
    type: "Secondaries Fund",
    checkSize: "$30M - $80M",
    staple: "NO",
    matchScore: 67,
  },
  {
    id: "e9b78d18-c2ff-46d4-91b3-90d0477ff689",
    logoFileName: "RiverstoneEquityGroup.jpg",
    name: "Riverstone Equity Group",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "ALL",
    type: "Family Office",
    checkSize: "$50M - $500M",
    staple: "YES",
    matchScore: 64,
  },
  {
    id: "c7022d82-2a6e-4a9e-9889-0775c5f1a883",
    logoFileName: "SolarFlareInvestments.jpg",
    name: "Solar Flare Investments",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "IV",
    type: "Pension",
    checkSize: "$10 - $150M",
    staple: "YES",
    matchScore: 64,
  },
  {
    id: "d6046cfd-d592-41ca-bb01-f9009469e7d7",
    logoFileName: "TitaniumFinanceGroup.jpg",
    name: "Titanium Finance Group",
    I: "",
    II: "",
    III: "",
    IV: "",
    Opportunities: "",
    fundScope: "I, II",
    type: "Pension",
    checkSize: "$50M - $1,000M",
    staple: "NO",
    matchScore: 63,
  },
];
