import { gql } from "@apollo/client";

export interface ICompanyQualitativeDataInvestmentUpdate {
  holding: string | null;
  fund: string | null;
  fundName: string | null;
  managerId: string | null;
  managerName: string | null;
  company: string | null;
  companyId: string | null;
  companyName: string | null;
  reportDate: string | null;
  isHumanVerified: boolean | null;
  isReadyForUser: boolean | null;
  type: string | null; // TODO: enum
  headline: string | null;
  text: string | null;
  impact: string | null;
  lpImpliedTransactionValue: number | null;
}

export interface ICompanyQualitativeData {
  investmentsInsights: null;
  investmentsUpdates: ICompanyQualitativeDataInvestmentUpdate[];
}

export interface ICompanyQualitativeDataQuery {
  qualitativeData: ICompanyQualitativeData;
}

export const GetCompanyQualitativeDataQuery = gql`
  query Query($companyId: String!, $reportDate: Date!) {
    qualitativeData(companyId: $companyId, reportDate: $reportDate) {
      investmentsInsights {
        holding
        fund
        fundName
        managerId
        managerName
        company
        companyId
        companyName
        reportDate
        isHumanVerified
        isReadyForUser
        gpOutlook
        gpOutlookDescription
        performanceToPlan
        performanceToPlanDescription
        liquidityTimeline
        liquidityTimelineDescription
        distressIndicator
        distressIndicatorDescription
        macroExposure
        macroExposureDescription
        lpImpliedTransactionValue
        lpImpliedInvestmentPercentage
      }
      investmentsUpdates {
        holding
        fund
        fundName
        managerId
        managerName
        company
        companyId
        companyName
        reportDate
        isHumanVerified
        isReadyForUser
        type
        headline
        text
        impact
        lpImpliedTransactionValue
      }
    }
  }
`;
