import {
  Box,
  Stack,
  Step,
  StepConnector,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Check } from "@phosphor-icons/react";

interface IReviewStep {
  label: string;
  caption: string;
}
interface IReviewStepper {
  steps: IReviewStep[];
}

export const ReviewStepper: React.FC<IReviewStepper> = ({ steps }) => {
  return (
    <Stepper
      activeStep={1}
      orientation="vertical"
      connector={<ReviewConnector />}
    >
      {steps.map((step, index) => (
        <Step expanded key={step.label}>
          <StepLabel StepIconComponent={ReviewStepperIcon} sx={{ py: "0px" }}>
            <Typography
              color="black"
              sx={{
                fontWeight: "bold",
                color: index === 0 ? "#BCB3A5" : "#000000",
                pl: "20px",
              }}
            >
              {step.label}
            </Typography>
          </StepLabel>
          <StepContent>
            <Stack pl={"20px"}>
              <Typography sx={{ color: "#737476" }}>{step.caption}</Typography>
              <Box
                width="100%"
                height="1px"
                sx={{ backgroundColor: "#DFDFD9", my: 2 }}
              />
            </Stack>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

const ReviewConnector = withStyles({
  alternativeLabel: {
    top: "calc(-50% + 0px)",
    bottom: "calc(50% + 0px)",
  },
  line: {
    borderColor: "#eaeaf0",
    position: "relative",
  },
})(StepConnector);

const CompletedReviewStepIcon = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div
      style={{
        borderRadius: "5000px",
        border: "1px solid #BCB3A5",
        background: "#FAFAF8",
        boxShadow: "0px 0px 0px 3px rgba(188, 179, 165, 0.25)",
        width: 24,
        height: 24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

const OutstandingReviewStepIcon = () => {
  return (
    <div
      style={{
        borderRadius: "5000px",
        border: "0.75px solid #DFDFD9",
        background: "#fff",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
      }}
    ></div>
  );
};

const ReviewStepperIcon = (props: StepIconProps) => {
  const { active, completed } = props;
  if (active) {
    return <OutstandingReviewStepIcon />;
  }

  if (completed) {
    return (
      <CompletedReviewStepIcon>
        <Check width={16} color="#BCB3A5" />
      </CompletedReviewStepIcon>
    );
  }

  return <OutstandingReviewStepIcon />;
};
