import { useEffect } from "react";
import { useNavigate } from "react-router";

import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { TpTransactionListTable } from "../components/TpTransactionListTable";
import { useTransactionsContext } from "../../state/TransactionsContext";

import { TPTransactionsListRequested } from "../../state/tp/TPTransactionsEvent";
import {
  TPTransactionListLoaded,
  TPTransactionListLoading,
} from "../../state/tp/TPTransactionsState";
import { THIRD_PARTY_ROUTES } from "common/constants/routes";

export interface ITPTransaction {
  id: string;
  name: string;
  funds: string;
  sellingEntity: string;
  nav: string;
  stage: string;
  targetClose: string;
  buyers: string;
}

const useTPTransactions = () => {
  const navigate = useNavigate();

  const { tpTransactions } = useTransactionsContext();

  // TODO @julio: Disable auto loading of transactions in main transaction provider,
  // and load transactions manually so that using the hook does not make an
  // unnecessary request to the server unless we explicitly want to load transactions
  const { emitEvent, transactionListState } = tpTransactions;

  const handleCreateTransactionClick = () => {
    navigate(THIRD_PARTY_ROUTES.CreateTransaction);
  };

  const handleViewTransactionClick = (transaction: ITPTransaction) => {
    console.log("TP_VIEW_TRANSACTION_CLICKED", transaction);
    navigate(
      THIRD_PARTY_ROUTES.TransactionDetail.replace(":txnId", transaction.id),
    );
  };

  useEffect(() => {
    emitEvent(new TPTransactionsListRequested());
  }, []);

  return {
    transactionListState,
    handleCreateTransactionClick,
    handleViewTransactionClick,
  };
};

export const TpTransactionListPage = () => {
  const {
    handleCreateTransactionClick,
    handleViewTransactionClick,
    transactionListState,
  } = useTPTransactions();

  return (
    <>
      <PageHead
        title="Transactions"
        actions={
          <>
            <Button
              onClick={handleCreateTransactionClick}
              icon={<PlusIcon />}
              type={BUTTON_TYPES.SECONDARY}
            >
              Create Transaction
            </Button>
          </>
        }
      />
      <TpTransactionListTable
        transactions={
          transactionListState instanceof TPTransactionListLoaded
            ? transactionListState.transactions
            : []
        }
        handleCreateTransactionClick={handleCreateTransactionClick}
        handleViewTransactionClick={handleViewTransactionClick}
        isLoading={transactionListState instanceof TPTransactionListLoading}
      />
    </>
  );
};
