import React, { forwardRef, useMemo } from "react";
import { Group } from "@visx/group";
import { BarGroup } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleOrdinal } from "@visx/scale";
import { GridRows } from "@visx/grid";
import { useTooltipInPortal } from "@visx/tooltip";
import { ParentSize } from "@visx/responsive";

import { Fund } from "experiences/funds/domain/models/Fund";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";

import { ICashFlowsChart, useCashFlows } from "../../hooks/useCashFlows";
import {
  ChartLegend,
  ChartLegendGroup,
} from "./priceHistoryChart/StyledLegendGroup";
import { cn } from "common/utils";
import { FundConnectPortfolioOverlay } from "experiences/dashboard/presentation/components/VerifyPermissionOverlay";

type BarGroupProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const distributionsColor = "#BCB3A5";
const contributionsColor = "#737476";

export const CONTRIBUTIONS_NEGATIVE = true;
export const cashFlowsDistributionsKey = "distributions";
export const cashFlowsContributionsKey = "contributions";
export const cashFlowsDateKey = "date";
export const cashFlowKeys = [
  cashFlowsDistributionsKey,
  cashFlowsContributionsKey,
];

// fixed scales
const valueColumnCategories = scaleBand<string>({
  domain: cashFlowKeys,
});

const colorScale = scaleOrdinal<string, string>({
  domain: cashFlowKeys,
  range: [distributionsColor, contributionsColor],
});

const leftAxisWidth = 70;

const DistributionsIcon = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M7.49985 0.877045C3.84216 0.877045 0.877014 3.84219 0.877014 7.49988C0.877014 11.1575 3.84216 14.1227 7.49985 14.1227C11.1575 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1575 0.877045 7.49985 0.877045ZM1.82701 7.49988C1.82701 4.36686 4.36683 1.82704 7.49985 1.82704C10.6328 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6328 13.1727 7.49985 13.1727C4.36683 13.1727 1.82701 10.6329 1.82701 7.49988ZM7.49999 9.49999C8.60456 9.49999 9.49999 8.60456 9.49999 7.49999C9.49999 6.39542 8.60456 5.49999 7.49999 5.49999C6.39542 5.49999 5.49999 6.39542 5.49999 7.49999C5.49999 8.60456 6.39542 9.49999 7.49999 9.49999Z"
        fill={distributionsColor}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const ContributionsIcon = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M7.49985 0.877045C3.84216 0.877045 0.877014 3.84219 0.877014 7.49988C0.877014 11.1575 3.84216 14.1227 7.49985 14.1227C11.1575 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1575 0.877045 7.49985 0.877045ZM1.82701 7.49988C1.82701 4.36686 4.36683 1.82704 7.49985 1.82704C10.6328 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6328 13.1727 7.49985 13.1727C4.36683 13.1727 1.82701 10.6329 1.82701 7.49988ZM7.49999 9.49999C8.60456 9.49999 9.49999 8.60456 9.49999 7.49999C9.49999 6.39542 8.60456 5.49999 7.49999 5.49999C6.39542 5.49999 5.49999 6.39542 5.49999 7.49999C5.49999 8.60456 6.39542 9.49999 7.49999 9.49999Z"
        fill={contributionsColor}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const CashFlowsChart = ({
  width,
  height,
  margin = { top: 16, right: 0, bottom: 40, left: 0 + leftAxisWidth },
  fund,
  style,
  cashFlows,
}: BarGroupProps & {
  fund?: Fund;
  style?: React.CSSProperties;
  cashFlows: ICashFlowsChart;
}) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const {
    showDistributions,
    showContributions,
    toggleShowDistributions,
    toggleShowContributions,
    timeRange,
    handleTimeOptionClick,
    quarters,
    quarterScale,
    amountScale,
    timeRangeOptions,
  } = cashFlows;

  // this looks unused but removing it breaks the chart
  // it's because of how d3 works internally, requires calling quarterScale.rangeRound
  const xScale = useMemo(() => {
    return quarterScale.rangeRound([0, xMax]);
  }, [innerWidth, margin.left, timeRange, quarters]);

  const yScale = useMemo(() => {
    return amountScale.range([yMax, 0]);
  }, [innerWidth, margin.left, timeRange, quarters]);

  valueColumnCategories.rangeRound([0, quarterScale.bandwidth()]);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  return width < 10 ? null : (
    <div style={style}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ChartLegendGroup>
          <ChartLegend onClick={toggleShowContributions}>
            <ContributionsIcon />
            Contributions
          </ChartLegend>
          <ChartLegend onClick={toggleShowDistributions}>
            <DistributionsIcon />
            Distributions
          </ChartLegend>
        </ChartLegendGroup>
        <ChartLegendGroup>
          {timeRangeOptions.map((option) => {
            const isSelected = option.value === timeRange;
            return (
              <ChartLegend
                key={option.label}
                onClick={() => handleTimeOptionClick(option.value)}
                style={{
                  borderBottom: isSelected
                    ? "2px solid #21272D"
                    : "2px solid transparent",
                  borderRadius: 0,
                }}
              >
                {option.label}
              </ChartLegend>
            );
          })}
        </ChartLegendGroup>
      </div>
      <div style={{ position: "relative" }}>
        <svg width={width} height={height} ref={containerRef}>
          <Group top={margin.top} left={margin.left}>
            <AxisLeft
              scale={yScale}
              hideAxisLine
              hideTicks
              tickFormat={(value, i, values) => {
                const isNegative = value.valueOf() < 0;
                return `${isNegative ? "- " : ""}${new DollarAmount(
                  Math.abs(value.valueOf()),
                ).formattedBig()}`;
              }}
            />
            <GridRows
              left={0}
              scale={yScale}
              width={innerWidth}
              stroke="rgba(223, 223, 217, 0.80)"
              pointerEvents="none"
            />

            <BarGroup
              data={quarters}
              keys={cashFlowKeys.filter((key) => {
                // omit columns if they're disabled
                if (key === cashFlowsDistributionsKey && !showDistributions) {
                  return false;
                }
                if (key === cashFlowsContributionsKey && !showContributions) {
                  return false;
                }
                return key;
              })}
              height={yMax}
              x0={(d) => d[cashFlowsDateKey]}
              x0Scale={quarterScale}
              x1Scale={valueColumnCategories}
              yScale={yScale}
              color={colorScale}
            >
              {(barGroups) =>
                barGroups.map((barGroup) => (
                  <Group
                    key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                    left={barGroup.x0}
                  >
                    {barGroup.bars.map((bar, i, bars) => {
                      // if bar index is 0, it's the first bar in the group
                      // start that bar @ bar.x + bar.width / 2
                      // if bar index is 1 or greater, start bars @ bar.x + bar.width / 2
                      // note: it may need a negative multiplication, test that when we have more than 2 bars
                      const x = bar.width - bar.width / 2;

                      // stack bars on top of each other
                      // NOTE: this is different than stacking bars behind each other
                      // height (y position) is calculated differently
                      const y =
                        bar.index === 0
                          ? yMax - bar.height
                          : yMax - bar.height - bars[0].height;

                      if (CONTRIBUTIONS_NEGATIVE) {
                        if (bar.key === cashFlowsContributionsKey) {
                          const zero = yScale(0);
                          const barHeight = zero - yScale(bar.value);
                          return (
                            <rect
                              key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                              x={x}
                              y={zero}
                              width={bar.width}
                              height={barHeight}
                              fill={bar.color}
                            />
                          );
                        }
                        if (bar.key === cashFlowsDistributionsKey) {
                          const zero = yScale(0);
                          const barHeight = zero - yScale(bar.value);
                          return (
                            <rect
                              key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                              x={x}
                              y={zero - barHeight}
                              width={bar.width}
                              height={barHeight}
                              fill={bar.color}
                            />
                          );
                        }

                        return (
                          <rect
                            key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                            x={x}
                            y={y}
                            width={bar.width}
                            height={bar.height}
                            fill={bar.color}
                          />
                        );
                      }

                      return (
                        <rect
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={x}
                          y={y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                        />
                      );
                    })}
                  </Group>
                ))
              }
            </BarGroup>
          </Group>
          <AxisBottom
            top={yMax + margin.top}
            left={margin.left}
            scale={quarterScale}
            hideTicks
            hideAxisLine
            tickLabelProps={{
              fill: "#737476",
              fontSize: 8,
              textAnchor: "middle",
              fontFamily: "Inter, sans-serif",
              cursor: "default",
            }}
          />
        </svg>
      </div>
    </div>
  );
};

// Note: main component is wrapped in forwardRef to allow parent component to scroll to this component
export const CashFlows = forwardRef(function CashFlows(
  {
    fund,
    locked,
  }: {
    fund?: Fund;
    locked: boolean;
  },
  ref: any,
) {
  const cashFlows = useCashFlows({
    fund,
  });

  return (
    <div ref={ref}>
      <PageSectionTitleDivider showBorderTop>
        Cash Flows
      </PageSectionTitleDivider>
      <div
        className={cn("relative", {
          "min-h-96": locked,
        })}
      >
        {locked && <FundConnectPortfolioOverlay />}
        <ParentSize>
          {(parent) => (
            <CashFlowsChart
              width={parent.width}
              height={350}
              fund={fund}
              style={{
                ...(locked
                  ? {
                      filter: "blur(7px)",
                      opacity: 0.3,
                    }
                  : {}),
              }}
              cashFlows={cashFlows}
            />
          )}
        </ParentSize>
      </div>
    </div>
  );
});
