import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";

import Loader from "common/components/TapLoader";
import { ApprovedBuyersTable } from "../components/ApprovedBuyersTable";
import { useApprovedBuyersContext } from "../state/ApprovedBuyersContext";
import { ApprovedBuyersLoaded } from "../state/ApprovedBuyersState";
import { SearchInput } from "common/components/SearchInput";
import { LoadApprovedBuyers } from "../state/ApprovedBuyerEvent";
import { PageHead } from "common/components/PageHead";

export const ApprovedBuyers = () => {
  const { approvedBuyerTablePageState, emitEvent } = useApprovedBuyersContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    emitEvent!(new LoadApprovedBuyers());
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredApprovedBuyers =
    approvedBuyerTablePageState instanceof ApprovedBuyersLoaded
      ? approvedBuyerTablePageState.approvedBuyers.filter((approvedBuyer) =>
          approvedBuyer.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

  return (
    <>
      <Stack width="100%">
        <Helmet title="Approved Buyers" />
        <PageHead
          title="Approved Buyers"
          countLabel={filteredApprovedBuyers.length}
          actions={
            <SearchInput
              value={search}
              placeholder="Search Approved Buyers..."
              onChange={handleSearchChange}
            />
          }
        />
      </Stack>
      <Stack style={{ width: "100%" }}>
        {approvedBuyerTablePageState instanceof ApprovedBuyersLoaded ? (
          <ApprovedBuyersTable
            approvedBuyers={filteredApprovedBuyers}
            searchQuery={search}
          />
        ) : (
          <Loader />
        )}
      </Stack>
    </>
  );
};
