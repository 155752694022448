import React, { createContext, useEffect, useState } from "react";

import { Fund } from "experiences/funds/domain/models/Fund";
import {
  BidRankType,
  BidStatus,
  FundSummary,
} from "experiences/funds/domain/models/FundSummary";
import { IndicationOfInterest } from "experiences/indications-of-interest/domain/models/IOI";
import {
  NoUserBidState,
  BidRankState,
  PartialUserBidState,
  InsufficientDataState,
  CompleteBidRankState,
  EmptyBidState,
  Locked,
} from "./BidState";

type BidProviderProps = {
  children: React.ReactNode;
  summary: FundSummary;
  iois: IndicationOfInterest[];
};

type BidContextState = {
  fund?: Fund;
  asksLocked: boolean;
  bidsLocked: boolean;
  bidRank?: BidRankType;
  bidState?: BidRankState;
};

export const BidsContext = createContext<BidContextState | undefined>(
  undefined,
);

export const BidProvider = ({ children, summary, iois }: BidProviderProps) => {
  const [bidState, setBidState] = useState<BidRankState>();

  const isPermittedToViewBids =
    summary?.permissionProfile.isPermittedToViewBuyIOIs() ?? false;
  const isPermittedToViewAsks =
    summary?.permissionProfile.isPermittedToViewSellIOIs() ?? false;
  const bidsLocked = !isPermittedToViewBids;
  const asksLocked = !isPermittedToViewAsks;
  const bidRank = summary?.bidRank;

  const calculateBidState = () => {
    if (bidRank?.status === BidStatus.NoUserBids) {
      return new NoUserBidState();
    } else if (bidRank?.status === BidStatus.PartialUserBid) {
      return new PartialUserBidState();
    } else if (bidRank?.status === BidStatus.InsufficientData) {
      return new InsufficientDataState();
    } else if (bidRank?.status === BidStatus.Ok) {
      return new CompleteBidRankState();
    } else if (bidsLocked) {
      return new Locked();
    } else if (!bidsLocked && !bidRank && iois.length === 0) {
      return new EmptyBidState();
    }
  };

  useEffect(() => {
    const newBidState = calculateBidState();
    if (newBidState !== bidState) {
      setBidState(newBidState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidRank, bidsLocked, iois.length]);

  const value = {
    iois,
    asksLocked,
    bidsLocked,
    bidRank,
    bidState,
  };

  return <BidsContext.Provider value={value}>{children}</BidsContext.Provider>;
};
