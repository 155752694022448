import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";

import { transactionsClient } from "common/clients/ApolloClient";
import {
  IFundDataReportDate,
  IFundDataReportDates,
  IFundDataReportName,
} from "experiences/transactions/domain/models/FundDataReportDates";
import { GetTransactionFundDataReportDates } from "experiences/transactions/domain/usecases/GetTransactionFundDataReportDates";
import { SelectOption } from "./verify-permission/useAddFundInterestForm";

export interface IReportDate {
  options: SelectOption[];
  currentDate: string;
  onReportDateChange: (value: string) => void;
}

export interface ITransactionFundDataReportDate {
  loading: boolean;
  reportDates: {
    dealBreakdown: IReportDate;
    exposureSummary: IReportDate;
    soiLookthrough: IReportDate;
    capitalAccounts: IReportDate;
    qualitativeData: IReportDate;
    companyLoan: IReportDate;
    interimCashflows: IReportDate;
  };
}

const generateReportDateOptions = (dates: string[]): SelectOption[] => {
  return dates.map((ddate) => {
    return {
      label: ddate,
      value: ddate,
    };
  });
};

const getDatesByReportName = (
  dates: IFundDataReportDate[],
  reportName: IFundDataReportName,
): string[] => {
  const filteredDates =
    dates.find((date) => date.name === reportName)?.dates || [];

  // If there is only one or zero dates, return as is
  if (filteredDates.length <= 1) {
    return filteredDates;
  }

  const sortedDates = filteredDates.slice().sort((a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  });

  return sortedDates;
};

export const useFundDataReportDate: () => ITransactionFundDataReportDate =
  () => {
    const { txnId } = useParams<{ txnId: string }>();
    const [currentDealBreakdownReportDate, setCurrentDealBreakdownReportDate] =
      useState<string>(undefined);
    const [currentFundExposureSummaryDate, setCurrentFundExposureSummaryDate] =
      useState<string>(undefined);
    const [currentSoiLookthroughDate, setCurrentSoiLookthroughDate] =
      useState<string>(undefined);
    const [currentCapitalAccountsDate, setCurrentCapitalAccountsDate] =
      useState<string>(undefined);
    const [currentQualitativeDataDate, setCurrentQualitativeDataDate] =
      useState<string>(undefined);
    const [currentCompanyLoanDate, setCurrentCompanyLoanDate] =
      useState<string>(undefined);
    const [currentInterimCashflowsDate, setCurrentInterimCashflowsDate] =
      useState<string>(undefined);

    const { data, loading } = useQuery<IFundDataReportDates>(
      GetTransactionFundDataReportDates,
      {
        variables: { transactionId: txnId },
        client: transactionsClient,
        fetchPolicy: "network-only",
      },
    );

    const dealBreakdownDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.DealBreakdown,
    );

    const fundExposureSummaryDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.FundExposureSummary,
    );

    const soiLookthroughDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.SoiLookthrough,
    );

    const capitalAccountsDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.CapitalAccounts,
    );

    const qualitativeDataDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.QualitativeData,
    );

    const companyLoanDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.CompanyLoan,
    );

    const interimCashflowsDates = getDatesByReportName(
      data?.reportsDates || [],
      IFundDataReportName.InterimCashflows,
    );

    // Grab the first date from each report and set it as the current date
    // Dates are already sorted in descending order, so the first date is the latest
    // Dates were sorted by getDatesByReportName()
    useEffect(() => {
      if (dealBreakdownDates.length > 0) {
        setCurrentDealBreakdownReportDate(dealBreakdownDates[0]);
      }
      if (fundExposureSummaryDates.length > 0) {
        setCurrentFundExposureSummaryDate(fundExposureSummaryDates[0]);
      }
      if (soiLookthroughDates.length > 0) {
        setCurrentSoiLookthroughDate(soiLookthroughDates[0]);
      }
      if (capitalAccountsDates.length > 0) {
        setCurrentCapitalAccountsDate(capitalAccountsDates[0]);
      }
      if (qualitativeDataDates.length > 0) {
        setCurrentQualitativeDataDate(qualitativeDataDates[0]);
      }
      if (companyLoanDates.length > 0) {
        setCurrentCompanyLoanDate(companyLoanDates[0]);
      }
      if (interimCashflowsDates.length > 0) {
        setCurrentInterimCashflowsDate(interimCashflowsDates[0]);
      }
    }, [loading, data]);

    const handleDealBreakdownDateChange = (value: string) => {
      setCurrentDealBreakdownReportDate(value);
    };

    const handleFundExposureSummaryDateChange = (value: string) => {
      setCurrentFundExposureSummaryDate(value);
    };

    const handleSoiLookthroughDateChange = (value: string) => {
      setCurrentSoiLookthroughDate(value);
    };

    const handleCapitalAccountsDateChange = (value: string) => {
      setCurrentCapitalAccountsDate(value);
    };

    const handleQualitativeDataDateChange = (value: string) => {
      setCurrentQualitativeDataDate(value);
    };

    const handleCompanyLoanDateChange = (value: string) => {
      setCurrentCompanyLoanDate(value);
    };

    const handleInterimCashflowsDateChange = (value: string) => {
      setCurrentInterimCashflowsDate(value);
    };

    const dealBreakdownDateOptions =
      generateReportDateOptions(dealBreakdownDates);
    const fundExposureSummaryDateOptions = generateReportDateOptions(
      fundExposureSummaryDates,
    );
    const soiLookthroughDateOptions =
      generateReportDateOptions(soiLookthroughDates);
    const capitalAccountsDateOptions =
      generateReportDateOptions(capitalAccountsDates);
    const qualitativeDataDateOptions =
      generateReportDateOptions(qualitativeDataDates);
    const companyLoanDateOptions = generateReportDateOptions(companyLoanDates);
    const interimCashflowsDateOptions = generateReportDateOptions(
      interimCashflowsDates,
    );

    const result: ITransactionFundDataReportDate = {
      loading,
      reportDates: {
        dealBreakdown: {
          options: dealBreakdownDateOptions,
          currentDate: currentDealBreakdownReportDate,
          onReportDateChange: handleDealBreakdownDateChange,
        },
        exposureSummary: {
          options: fundExposureSummaryDateOptions,
          currentDate: currentFundExposureSummaryDate,
          onReportDateChange: handleFundExposureSummaryDateChange,
        },
        soiLookthrough: {
          options: soiLookthroughDateOptions,
          currentDate: currentSoiLookthroughDate,
          onReportDateChange: handleSoiLookthroughDateChange,
        },
        capitalAccounts: {
          options: capitalAccountsDateOptions,
          currentDate: currentCapitalAccountsDate,
          onReportDateChange: handleCapitalAccountsDateChange,
        },
        qualitativeData: {
          options: qualitativeDataDateOptions,
          currentDate: currentQualitativeDataDate,
          onReportDateChange: handleQualitativeDataDateChange,
        },
        companyLoan: {
          options: companyLoanDateOptions,
          currentDate: currentCompanyLoanDate,
          onReportDateChange: handleCompanyLoanDateChange,
        },
        interimCashflows: {
          options: interimCashflowsDateOptions,
          currentDate: currentInterimCashflowsDate,
          onReportDateChange: handleInterimCashflowsDateChange,
        },
      },
    };

    return result;
  };
