import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase, NoReturn } from "common/@types/app/UseCase";

interface IParams {
  txnId: string;
}

export class DeclineParticipation implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    txnId,
  }) => {
    return await new TapClient(
      `/transactions/tenders/${txnId}/decline-participation/`,
    )
      .post()
      .then((_) => right(new NoReturn()))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}
