import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { APP_MENU_HEIGHT_PX } from "experiences/common/PageWrapper";
import { IconTap, SUPPORT_EMAIL } from "common/constants/assets";
import { Question } from "@phosphor-icons/react";

interface StyledMenuProps {
  isImpersonationActive?: boolean;
}

interface StyledSubMenuProps {
  isImpersonationActive?: boolean;
  flushedLeft?: boolean;
}

export const StyledMenu = styled.nav<StyledMenuProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: ${APP_MENU_HEIGHT_PX}px;
  top: ${(props) => (props.isImpersonationActive ? APP_MENU_HEIGHT_PX : 0)}px;
  left: 0;
  height: ${(props) =>
    props.isImpersonationActive
      ? `calc(100vh - ${APP_MENU_HEIGHT_PX}px)`
      : "100vh"};
  justify-content: space-between;
  z-index: ${(props) => (props.isImpersonationActive ? 1 : 2)};
  user-select: none;
  overflow: hidden;
  background-color: #21272d;
  transition: width 90ms ease-in-out;

  &:hover {
    width: 320px;
  }
`;

// Secondary menu left of the global search
export const NavLinkWrapper = styled.span<StyledSubMenuProps>`
  position: fixed;
  display: flex;
  flex-direction: row;
  top: ${(props) => (props.isImpersonationActive ? APP_MENU_HEIGHT_PX : 0)}px;
  left: ${(props) => (props.flushedLeft ? 0 : APP_MENU_HEIGHT_PX)}px;
  height: ${APP_MENU_HEIGHT_PX}px;
  width: calc(
    100% - ${(props) => (props.flushedLeft ? 0 : APP_MENU_HEIGHT_PX)}px
  );
  border-bottom: 1px solid #dfdfd9;
  cursor: default;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.isImpersonationActive ? APP_MENU_HEIGHT_PX : 0}px;
  background-color: #ffffff;
  z-index: 1;
`;

export const StyledMenuGroup = styled.span`
  display: flex;
  flex-direction: column;
`;

export const StyledMenuButton = styled(NavLink)<{
  isActive?: boolean;
  isTapLogo?: boolean;
  bottom?: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${APP_MENU_HEIGHT_PX}px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background-color: transparent;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent; // this is so content is centered and not pushed to the left
  text-align: left;
  line-height: 1;

  img,
  svg {
    position: absolute;
    display: inline-block;
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
    left: calc(${APP_MENU_HEIGHT_PX}px / 2); // center-ish the icon
    top: calc(${APP_MENU_HEIGHT_PX}px / 2); // center-ish the icon
    transform: translateX(-50%) translateY(-50%); // adjust for center
  }

  &:hover {
    background-color: #353b40;
  }

  &:hover,
  &:active {
    img,
    svg {
      opacity: 1;
    }
  }
  ${(props) => props.isActive && `img, svg { opacity: 1; }`}
  ${(props) => props.isTapLogo && `img, svg { opacity: 1; }`}

  /* if active prop */
  ${(props) => props.isActive && `border-left: 2px solid #BCB3A5;`}
  ${(props) => props.bottom && `justify-self: end;`}

  span.label {
    display: flex;
    height: ${APP_MENU_HEIGHT_PX}px;
    position: absolute;
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 3%;
    left: ${APP_MENU_HEIGHT_PX}px;
    align-items: center;
    white-space: nowrap;
  }
`;

export const CurrentExperience = styled.span`
  display: flex;
  flex-direction: row;
  font-family: "StaffWide", "Open sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #21272d;
  height: inherit;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  width: fit-content;
  border-right: 1px solid #dfdfd9;
  user-select: none;
  cursor: pointer;
`;

const StyledUserPicture = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #21272d;
  cursor: pointer;
  user-select: none;
  margin-left: 12px;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 3%;
  padding-top: 2px;
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;

  background: #ffffff;
  border: 1px solid #dfdfd9;
  box-shadow: 0px 9px 24px rgba(184, 184, 184, 0.32);
  border-radius: 8px;
  overflow: hidden;

  z-index: 100;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 10px 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #21272d;
    min-width: 130px;
    text-align: right;

    transition: background-color 120ms ease-in-out;

    &:hover {
      background-color: #f4f4f1;
    }
  }
`;

export const StyledUser = styled.div`
  position: relative;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
  user-select: none;
`;

export const UserPicture = ({ initials }: { initials: string }) => {
  return <StyledUserPicture>{initials}</StyledUserPicture>;
};

export const TapIconLink = ({ to }: { to: string }) => {
  return (
    <StyledMenuButton to={to} isTapLogo>
      <img src={IconTap} alt="tap's logo" />
    </StyledMenuButton>
  );
};

export const TapSupportLink = () => {
  return (
    <StyledMenuButton to={`mailto:${SUPPORT_EMAIL}`}>
      <Question color="white" width={18} height={18} weight="regular" />
      <span className="label">{SUPPORT_EMAIL}</span>
    </StyledMenuButton>
  );
};
