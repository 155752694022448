// Participant section of the transaction

export interface IMockedParticipant {
  id: string;
  pcptName: string;
  contactName: string;
  status: string;
  lastActivity: string;
  logoFileName: string;
  type: "participant" | "team member";
}
export const pcpts: IMockedParticipant[] = [
  {
    id: "d7f0dc9a-27d6-478d-8ff3-164d8f99b079",
    logoFileName: "GaussFoundation.jpg",
    pcptName: "Gauss Foundation",
    contactName: "James Smith",
    status: "Viewed",
    lastActivity: "23 days ago",
    type: "participant",
  },
  {
    id: "3c3908ed-1188-4bac-a09d-2e42299dc8c0",
    logoFileName: "YellowstonePartners.jpg",
    pcptName: "Yellowstone Partners",
    contactName: "Emily Johnson",
    status: "Viewed",
    lastActivity: "5 days ago",
    type: "participant",
  },
  {
    id: "65c68475-93a8-4c54-9ace-55672d9d7a6f",
    logoFileName: "HorizonEndowment.jpg",
    pcptName: "Horizon Endowment",
    contactName: "Michael Williams",
    status: "Viewed",
    lastActivity: "8 days ago",
    type: "participant",
  },
  {
    id: "5a862691-9b27-4c27-956e-e620d6093abf",
    logoFileName: "WillowCreekPension.jpg",
    pcptName: "Willow Creek Pension",
    contactName: "Linda Brown",
    status: "Viewed",
    lastActivity: "3 days ago",
    type: "participant",
  },
  {
    id: "8450b28d-c9b4-4902-9243-e277d88770ee",
    logoFileName: "GoldenHillsGroup.jpg",
    pcptName: "Golden Hills Group",
    contactName: "Ethan Jones",
    status: "Viewed",
    lastActivity: "25 days ago",
    type: "participant",
  },
  {
    id: "1c06d949-75ca-4158-8fc0-ffd77bc1c8db",
    logoFileName: "JSERS.jpg",
    pcptName: "JSERS",
    contactName: "Sophia Garcia",
    status: "Viewed",
    lastActivity: "22 days ago",
    type: "participant",
  },
  {
    id: "63d8458c-1cb3-4f03-afbd-6b6aeb63a341",
    logoFileName: "DadeEmployeeRetirementSystem.jpg",
    pcptName: "Dade Employee Retirement System",
    contactName: "Alexander Miller",
    status: "Viewed",
    lastActivity: "8 days ago",
    type: "participant",
  },
  {
    id: "f82eb06c-fc2d-4f82-93b7-823686245723",
    logoFileName: "YarboCountyTeachers.jpg",
    pcptName: "Yarbo County Teachers",
    contactName: "Isabella Davis",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "participant",
  },
  {
    id: "1e60ea34-fbf7-4c59-a8a1-fcc7d7ebc3d6",
    logoFileName: "EntrypointFundIII.jpg",
    pcptName: "Entrypoint Fund III",
    contactName: "Noah Rodriguez",
    status: "Viewed",
    lastActivity: "11 days ago",
    type: "participant",
  },
  {
    id: "393626f2-3436-4624-91aa-479f1dc4bb98",
    logoFileName: "BancGaston.jpg",
    pcptName: "Banc Gaston",
    contactName: "Olivia Martinez",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "participant",
  },
  {
    id: "7c173357-9f3d-473a-8fa9-9e79291e5479",
    logoFileName: "SilverleafFamilyOffice.jpg",
    pcptName: "Silverleaf Family Office",
    contactName: "Liam Hernandez",
    status: "Viewed",
    lastActivity: "4 days ago",
    type: "participant",
  },
  {
    id: "6fecdb30-fa4b-46c1-a512-bdfd13f74a7d",
    logoFileName: "MarkT.HamilFamilyFoundation.jpg",
    pcptName: "Mark T. Hamil Family Foundation",
    contactName: "Ava Lopez",
    status: "Viewed",
    lastActivity: "18 days ago",
    type: "participant",
  },
  {
    id: "10a2a73b-6405-4506-9559-ee1754bc0200",
    logoFileName: "MarbleArchPrivateEquity.jpg",
    pcptName: "Marble Arch Private Equity",
    contactName: "Jacob Gonzalez",
    status: "Viewed",
    lastActivity: "6 days ago",
    type: "participant",
  },
  {
    id: "54b2bfde-7eb3-4c63-92a0-373ca490226b",
    logoFileName: "HueInvestmentGroup.jpg",
    pcptName: "Hue Investment Group",
    contactName: "Mia Wilson",
    status: "Viewed",
    lastActivity: "22 days ago",
    type: "participant",
  },
  {
    id: "cef4eda3-dcfa-477c-bf25-8f7887c5daa7",
    logoFileName: "GoldenHillsGroup.jpg",
    pcptName: "Golden Hills Group",
    contactName: "Mason Anderson",
    status: "Viewed",
    lastActivity: "10 days ago",
    type: "participant",
  },
  {
    id: "16e2de28-0541-4f0f-b06a-885462d8e470",
    logoFileName: "Stanley&KaseAdvisory.jpg",
    pcptName: "Stanley & Kase Advisory",
    contactName: "Rahul Bose",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "52a0b104-c0f6-4ee7-8017-98ec0c8e5448",
    logoFileName: "JanetStreet.jpg",
    pcptName: "Janet Street",
    contactName: "Lina Saleh",
    status: "Viewed",
    lastActivity: "17 days ago",
    type: "participant",
  },
  {
    id: "65c848a6-6db4-4545-a216-a9f27ec0aefd",
    logoFileName: "CrimsonPeakRetirementFund.jpg",
    pcptName: "Crimson Peak Retirement Fund",
    contactName: "Jun Wei",
    status: "Viewed",
    lastActivity: "18 days ago",
    type: "participant",
  },
  {
    id: "de909984-6088-408d-a2d5-a18a9a338480",
    logoFileName: "EmeraldValleyPensionTrust.jpg",
    pcptName: "Emerald Valley Pension Trust",
    contactName: "Abeer Al-Masri",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "764d54ef-b3b4-4cd8-9866-778a92cfd2b2",
    logoFileName: "CedarGroveCapitalPartners.jpg",
    pcptName: "Cedar Grove Capital Partners",
    contactName: "Chen Wang",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "89d11660-8b99-4416-a52e-60a51a7b9667",
    logoFileName: "PineRidgeEndowmentFund.jpg",
    pcptName: "Pine Ridge Endowment Fund",
    contactName: "Logan Martin",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "b87d3ffa-d917-4ac0-a6c8-8d43e2cf0055",
    logoFileName: "BoulderCrestRetirementSystem.jpg",
    pcptName: "Boulder Crest Retirement System",
    contactName: "Avery Thompson",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "participant",
  },
  {
    id: "5fb8a036-ea48-41cf-ba29-f9b184c6b6d1",
    logoFileName: "OakwoodCityPensionFund.jpg",
    pcptName: "Oakwood City Pension Fund",
    contactName: "Carter Jackson",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "cfab2538-e5d0-4732-9958-33e058b8ae6f",
    logoFileName: "SunsetHarborWealthManagement.jpg",
    pcptName: "Sunset Harbor Wealth Management",
    contactName: "Scarlett Lewis",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "c9754331-a889-4200-971f-9e5b85a125fc",
    logoFileName: "WillowCreekInvestmentPartners.jpg",
    pcptName: "Willow Creek Investment Partners",
    contactName: "Jayden Clark",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "9d2c381f-03df-4140-80a9-2269873f098e",
    logoFileName: "MapleGroveInstitutionalInvestors.jpg",
    pcptName: "Maple Grove Institutional Investors",
    contactName: "Zoe Walker",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "25b41dce-17aa-4e3c-b83c-0b73fc7cdad0",
    logoFileName: "RiverstoneFamilyOffice.jpg",
    pcptName: "Riverstone Family Office",
    contactName: "Owen Allen",
    status: "Viewed",
    lastActivity: "23 days ago",
    type: "participant",
  },
  {
    id: "b1181a7f-2890-472f-aedc-a7c98fc29e89",
    logoFileName: "QuantumFoundationLP.jpg",
    pcptName: "Quantum Foundation LP",
    contactName: "Lily Young",
    status: "Viewed",
    lastActivity: "22 days ago",
    type: "participant",
  },
  {
    id: "fdf7dc09-e6e2-4413-83bf-e388d69a7fea",
    logoFileName: "AzureCapitalManagement.jpg",
    pcptName: "Azure Capital Management",
    contactName: "Dylan King",
    status: "Viewed",
    lastActivity: "13 days ago",
    type: "participant",
  },
  {
    id: "a885ba90-5d33-44ae-a18f-15e4f8198a24",
    logoFileName: "RedwoodAssetManagement.jpg",
    pcptName: "Redwood Asset Management",
    contactName: "Chloe Wright",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "f533c484-5100-473f-8cb5-f8aa3aec45f6",
    logoFileName: "NationalInvestmentFund.jpg",
    pcptName: "National Investment Fund",
    contactName: "Ryan Scott",
    status: "Viewed",
    lastActivity: "17 days ago",
    type: "participant",
  },
  {
    id: "ef1bb362-d284-4f8b-a03f-af9c1ac27aa1",
    logoFileName: "BlueSkyPrivateEquity.jpg",
    pcptName: "Blue Sky Private Equity",
    contactName: "Madison Green",
    status: "Viewed",
    lastActivity: "5 days ago",
    type: "participant",
  },
  {
    id: "c1f6c52a-6871-4d0d-9c97-049353282b33",
    logoFileName: "ElmwoodWealthManagement.jpg",
    pcptName: "Elmwood Wealth Management",
    contactName: "Isaac Adams",
    status: "Viewed",
    lastActivity: "3 days ago",
    type: "participant",
  },
  {
    id: "5c69d935-1fdd-4a23-a617-06c3729f914a",
    logoFileName: "GranitePeakCapitalPartners.jpg",
    pcptName: "Granite Peak Capital Partners",
    contactName: "Sophie Baker",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "3c9633b5-e841-4987-ad55-454d1be66c96",
    logoFileName: "SterlingVenturePartners.jpg",
    pcptName: "Sterling Venture Partners",
    contactName: "Luke Nelson",
    status: "Viewed",
    lastActivity: "14 days ago",
    type: "participant",
  },
  {
    id: "d29204fa-f2df-4690-8ac3-e4a58e47c59e",
    logoFileName: "CypressFamilyOffice.jpg",
    pcptName: "Cypress Family Office",
    contactName: "Gabriella Carter",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "202ffbb7-6f72-4e7e-a668-2e8f01cd2d8b",
    logoFileName: "EagleEyeInsuranceInvestments.jpg",
    pcptName: "Eagle Eye Insurance Investments",
    contactName: "Henry Mitchell",
    status: "Status",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "cf149b24-e26c-4fbf-a91e-a82dc4f9b69d",
    logoFileName: "LighthouseEndowmentFund.jpg",
    pcptName: "Lighthouse Endowment Fund",
    contactName: "Ella Perez",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "b10656c8-f1d8-48d6-a1db-0f60fef40c11",
    logoFileName: "MaplewoodCommercialHoldings.jpg",
    pcptName: "Maplewood Commercial Holdings",
    contactName: "Julian Roberts",
    status: "Viewed",
    lastActivity: "14 days ago",
    type: "participant",
  },
  {
    id: "2210a7ec-8a86-47bf-8103-87aaef042fe4",
    logoFileName: "DiamondKeyInvestmentGroup.jpg",
    pcptName: "Diamond Key Investment Group",
    contactName: "Grace Turner",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "ec1ba248-41dd-4960-a773-2e51bbb16657",
    logoFileName: "RiverbendCapitalAdvisors.jpg",
    pcptName: "Riverbend Capital Advisors",
    contactName: "Levi Phillips",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "8b10fa96-1bd5-4328-94e1-8489dcda9b28",
    logoFileName: "SunriseEquityPartners.jpg",
    pcptName: "Sunrise Equity Partners",
    contactName: "Isabelle Campbell",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "7876b6b3-a618-4f69-83c1-4d3594d0795c",
    logoFileName: "BlackwaterPrivateInvestments.jpg",
    pcptName: "Blackwater Private Investments",
    contactName: "Adam Parker",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "f9e9f0df-9933-4451-ac8c-4447d11543b8",
    logoFileName: "OakhillFamilyOffice.jpg",
    pcptName: "Oakhill Family Office",
    contactName: "Nora Hall",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "5c85c889-1971-4311-a0dc-586934ad8731",
    logoFileName: "CrystalLakeCapital.jpg",
    pcptName: "Crystal Lake Capital",
    contactName: "Oscar Murphy",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "36bfde85-8cd9-4034-993f-b7a69b0174be",
    logoFileName: "VanguardLegacyInvestments.jpg",
    pcptName: "Vanguard Legacy Investments",
    contactName: "Aurora Sanders",
    status: "Viewed",
    lastActivity: "6 days ago",
    type: "participant",
  },
  {
    id: "c904b408-ed7b-465a-9240-fd021dc6d0ce",
    logoFileName: "PhoenixGlobalWealthManagement.jpg",
    pcptName: "Phoenix Global Wealth Management",
    contactName: "Austin Morris",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "ce093bf3-db9a-44e4-bd80-893709c9399a",
    logoFileName: "StarlightVentureCapital.jpg",
    pcptName: "Starlight Venture Capital",
    contactName: "Victoria Peterson",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "22a3f850-39f2-41ed-894c-38e5a3bc213a",
    logoFileName: "CedarPointCapitalPartners.jpg",
    pcptName: "Cedar Point Capital Partners",
    contactName: "Colin Diaz",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "807695a3-1bde-4f68-aa9e-14f8e558c9e9",
    logoFileName: "AmberFieldPrivateEquity.jpg",
    pcptName: "Amber Field Private Equity",
    contactName: "Penelope Coleman",
    status: "Viewed",
    lastActivity: "1 days ago",
    type: "participant",
  },
  {
    id: "99a8a963-b489-46c0-9077-7721aaa935e2",
    logoFileName: "TwilightFoundationLP.jpg",
    pcptName: "Twilight Foundation LP",
    contactName: "James Smith",
    status: "Viewed",
    lastActivity: "22 days ago",
    type: "participant",
  },
  {
    id: "ac2e9695-4543-40e8-b214-07d57886209f",
    logoFileName: "GoldenOakInvestments.jpg",
    pcptName: "Golden Oak Investments",
    contactName: "Emily Johnson",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "6832c537-31c9-45c3-902d-edf8dbdf07f2",
    logoFileName: "AspenFamilyHoldings.jpg",
    pcptName: "Aspen Family Holdings",
    contactName: "Michael Williams",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "31153295-0506-4d98-9911-67c11b9514d2",
    logoFileName: "ClearwaterCapitalGroup.jpg",
    pcptName: "Clearwater Capital Group",
    contactName: "Linda Brown",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "af340587-b3db-4470-a5f3-1230635cbf2b",
    logoFileName: "NobleTrustEquityPartners.jpg",
    pcptName: "Noble Trust Equity Partners",
    contactName: "Ethan Jones",
    status: "Viewed",
    lastActivity: "24 days ago",
    type: "participant",
  },
  {
    id: "92534d1e-f814-40ab-8b69-2e78dce12b1b",
    logoFileName: "MajesticInsuranceSolutions.jpg",
    pcptName: "Majestic Insurance Solutions",
    contactName: "Sophia Garcia",
    status: "Viewed",
    lastActivity: "6 days ago",
    type: "participant",
  },
  {
    id: "0d9fca3c-d982-4e3d-96c1-6beb3eb97f1b",
    logoFileName: "PrairieWealthAdvisors.jpg",
    pcptName: "Prairie Wealth Advisors",
    contactName: "Alexander Miller",
    status: "Viewed",
    lastActivity: "1 days ago",
    type: "participant",
  },
  {
    id: "ed91b6be-1969-428b-9dc1-f6ae9d4c19d6",
    logoFileName: "SilverMountainEndowments.jpg",
    pcptName: "Silver Mountain Endowments",
    contactName: "Isabella Davis",
    status: "Viewed",
    lastActivity: "5 days ago",
    type: "participant",
  },
  {
    id: "267190a6-64ab-49e6-aa01-d09024859651",
    logoFileName: "RavenwoodCapitalManagement.jpg",
    pcptName: "Ravenwood Capital Management",
    contactName: "Noah Rodriguez",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "afd63728-cc4f-48e1-9628-c29528c218c7",
    logoFileName: "HarborviewPrivateFunds.jpg",
    pcptName: "Harborview Private Funds",
    contactName: "Olivia Martinez",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "d03bad2a-65e1-4394-9c7e-b66034e4bd3d",
    logoFileName: "WildflowerAssetManagement.jpg",
    pcptName: "Wildflower Asset Management",
    contactName: "Liam Hernandez",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "7e54bf76-5da3-4ff6-b6f3-cfca79b677b1",
    logoFileName: "CresthillVentureGroup.jpg",
    pcptName: "Cresthill Venture Group",
    contactName: "Ava Lopez",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "262d1844-ee0f-4dff-8dc3-3e919ecb339f",
    logoFileName: "SummitRidgeFamilyOffice.jpg",
    pcptName: "Summit Ridge Family Office",
    contactName: "Jacob Gonzalez",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "1850cd70-6ebc-4381-803a-a1e000fd691c",
    logoFileName: "IroncladPensionStrategies.jpg",
    pcptName: "Ironclad Pension Strategies",
    contactName: "Mia Wilson",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "6cc1b085-6589-41cb-8ffe-35eebc1a9f8f",
    logoFileName: "BirchwoodRealEstateInvestors.jpg",
    pcptName: "Birchwood Real Estate Investors",
    contactName: "Mason Anderson",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "9c21717d-1896-4a91-8e5b-d70a93362f37",
    logoFileName: "OceanviewFoundationLP.jpg",
    pcptName: "Oceanview Foundation LP",
    contactName: "Charlotte Thomas",
    status: "Viewed",
    lastActivity: "8 days ago",
    type: "participant",
  },
  {
    id: "8d3e39bc-567f-4a84-9d20-33df419cf8a5",
    logoFileName: "SkylineEquityHoldings.jpg",
    pcptName: "Skyline Equity Holdings",
    contactName: "Benjamin Taylor",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "73068573-0f89-43f1-8d5f-b7431055e805",
    logoFileName: "GreenfieldCapitalPartners.jpg",
    pcptName: "Greenfield Capital Partners",
    contactName: "Amelia Lee",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "14cca56f-3461-46e7-a0ab-0d1e95a849d3",
    logoFileName: "RoyalOakFinancialGroup.jpg",
    pcptName: "Royal Oak Financial Group",
    contactName: "Lucas White",
    status: "Viewed",
    lastActivity: "8 days ago",
    type: "participant",
  },
  {
    id: "1cd4b77d-c242-4b85-9ef8-b995ec90bba7",
    logoFileName: "SunsetCapitalAdvisors.jpg",
    pcptName: "Sunset Capital Advisors",
    contactName: "Evelyn Harris",
    status: "Viewed",
    lastActivity: "10 days ago",
    type: "participant",
  },
  {
    id: "5efa8b56-34d5-4cb4-ad33-8135a46e366e",
    logoFileName: "MoonlightEquityPartners.jpg",
    pcptName: "Moonlight Equity Partners",
    contactName: "Logan Martin",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "ff5309ad-1cdd-4d3a-b475-2fee9c6291ce",
    logoFileName: "SilverleafFamilyOffice.jpg",
    pcptName: "Silverleaf Family Office",
    contactName: "Avery Thompson",
    status: "Viewed",
    lastActivity: "14 days ago",
    type: "participant",
  },
  {
    id: "591dbc05-b6e8-435e-81f0-f4dfb019bee2",
    logoFileName: "MarbleArchPrivateEquity.jpg",
    pcptName: "Marble Arch Private Equity",
    contactName: "Carter Jackson",
    status: "Viewed",
    lastActivity: "11 days ago",
    type: "participant",
  },
  {
    id: "516ff62f-1142-4282-b6fb-c6b4497e9b44",
    logoFileName: "YellowstonePartners.jpg",
    pcptName: "Yellowstone Partners",
    contactName: "Scarlett Lewis",
    status: "Viewed",
    lastActivity: "25 days ago",
    type: "participant",
  },
  {
    id: "d04afd96-6c6d-4653-8f83-c5c58f10ce16",
    logoFileName: "HorizonEndowment.jpg",
    pcptName: "Horizon Endowment",
    contactName: "Jayden Clark",
    status: "Viewed",
    lastActivity: "14 days ago",
    type: "participant",
  },
  {
    id: "89f012d4-cd25-4d71-b8c1-1ead30ba3035",
    logoFileName: "WillowCreekPension.jpg",
    pcptName: "Willow Creek Pension",
    contactName: "Zoe Walker",
    status: "Viewed",
    lastActivity: "20 days ago",
    type: "participant",
  },
  {
    id: "0798e2c5-96a4-4b67-9cf1-bdbc98d9432b",
    logoFileName: "SalientPartners.jpg",
    pcptName: "Salient Partners",
    contactName: "Owen Allen",
    status: "Viewed",
    lastActivity: "5 days ago",
    type: "participant",
  },
  {
    id: "0c0f8f1b-01b0-47db-b5bc-935b1d6da051",
    logoFileName: "GoldenHillsGroup.jpg",
    pcptName: "Golden Hills Group",
    contactName: "Lily Young",
    status: "Viewed",
    lastActivity: "10 days ago",
    type: "participant",
  },
  {
    id: "5fb25d32-8d7e-47fb-a12c-01e589677d15",
    logoFileName: "HueInvestmentGroup.jpg",
    pcptName: "Hue Investment Group",
    contactName: "Dylan King",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "participant",
  },
  {
    id: "2ea6f698-0206-4e12-955d-eff796a4eaa1",
    logoFileName: "JanetStreet.jpg",
    pcptName: "Janet Street",
    contactName: "Chloe Wright",
    status: "Viewed",
    lastActivity: "20 days ago",
    type: "participant",
  },
  {
    id: "fbe9c09c-46b3-4ac5-9fc9-9823c4dc70f1",
    logoFileName: "OpenFront.jpg",
    pcptName: "Open Front",
    contactName: "Ryan Scott",
    status: "Viewed",
    lastActivity: "4 days ago",
    type: "participant",
  },
  {
    id: "490293c9-5af5-46a2-aea7-19914fbec655",
    logoFileName: "TRECP.jpg",
    pcptName: "TRECP",
    contactName: "Madison Green",
    status: "Viewed",
    lastActivity: "8 days ago",
    type: "participant",
  },
  {
    id: "985a2f42-804d-4af3-ac05-b60b0bbd21d4",
    logoFileName: "HashmontPartners.jpg",
    pcptName: "Hashmont Partners",
    contactName: "Isaac Adams",
    status: "Viewed",
    lastActivity: "16 days ago",
    type: "participant",
  },
  {
    id: "0d9fd515-31c8-4ce2-97d6-63e4a304ba07",
    logoFileName: "HiFongGroup.jpg",
    pcptName: "HiFong Group",
    contactName: "Sophie Baker",
    status: "Viewed",
    lastActivity: "19 days ago",
    type: "participant",
  },
  {
    id: "7729fbc6-9e1a-47e7-ba75-25a8cc2dee8c",
    logoFileName: "Knoll&Co.jpg",
    pcptName: "Knoll & Co",
    contactName: "Luke Nelson",
    status: "Viewed",
    lastActivity: "6 days ago",
    type: "participant",
  },
  {
    id: "fbeb5d63-4eaf-49f8-aab9-d692e8c5d5e0",
    logoFileName: "VitalRockPartners.jpg",
    pcptName: "Vital Rock Partners",
    contactName: "Gabriella Carter",
    status: "Viewed",
    lastActivity: "22 days ago",
    type: "participant",
  },
  {
    id: "dfdf961c-a5f9-4fec-92e7-d792f0102a3d",
    logoFileName: "HorizonEndowment.jpg",
    pcptName: "Horizon Endowment",
    contactName: "Henry Mitchell",
    status: "Viewed",
    lastActivity: "9 days ago",
    type: "participant",
  },
  {
    id: "88e6d1c8-b1a6-4611-91df-e826996dc796",
    logoFileName: "GaussFoundation.jpg",
    pcptName: "Gauss Foundation",
    contactName: "Ella Perez",
    status: "Viewed",
    lastActivity: "13 days ago",
    type: "participant",
  },
  {
    id: "c74b4132-b827-4f07-8855-63ac716d6b05",
    logoFileName: "YellowstonePartners.jpg",
    pcptName: "Yellowstone Partners",
    contactName: "Julian Roberts",
    status: "Viewed",
    lastActivity: "15 days ago",
    type: "participant",
  },
  {
    id: "0fb26b14-9097-4333-b05c-f5ff3efcb3a2",
    logoFileName: "HorizonEndowment.jpg",
    pcptName: "Horizon Endowment",
    contactName: "Grace Turner",
    status: "Viewed",
    lastActivity: "3 days ago",
    type: "participant",
  },
  {
    id: "2bc16d7a-3815-4a37-ba53-4cdb551f0a53",
    logoFileName: "WillowCreekPension.jpg",
    pcptName: "Willow Creek Pension",
    contactName: "Levi Phillips",
    status: "Viewed",
    lastActivity: "17 days ago",
    type: "participant",
  },
  {
    id: "af9c19b9-7e32-42c5-b7b3-e5c0d20c7244",
    logoFileName: "GoldenHillsGroup.jpg",
    pcptName: "Golden Hills Group",
    contactName: "Isabelle Campbell",
    status: "Viewed",
    lastActivity: "7 days ago",
    type: "participant",
  },
  {
    id: "c7d46870-0880-4aa6-8049-41b17a1c265f",
    logoFileName: "JanetStreet.jpg",
    pcptName: "Janet Street",
    contactName: "Adam Parker",
    status: "Viewed",
    lastActivity: "10 days ago",
    type: "participant",
  },
  {
    id: "ed338860-6852-4ef0-ba4a-ad667356fb25",
    logoFileName: "OpenFront.jpg",
    pcptName: "Open Front",
    contactName: "Nora Hall",
    status: "Viewed",
    lastActivity: "4 days ago",
    type: "participant",
  },
  {
    id: "2a32c254-7956-4a18-8ce3-f4a890b7f145",
    logoFileName: "JanetStreet.jpg",
    pcptName: "Janet Street",
    contactName: "Oscar Murphy",
    status: "Viewed",
    lastActivity: "4 days ago",
    type: "participant",
  },
  {
    id: "71bfe49d-cb93-4d4f-b212-7fb0b8b476f8",
    logoFileName: "OpenFront.jpg",
    pcptName: "Open Front",
    contactName: "Aurora Sanders",
    status: "Viewed",
    lastActivity: "16 days ago",
    type: "participant",
  },
  {
    id: "3492f3b4-c9ed-4e9a-8fa9-bc33523f1c03",
    logoFileName: "SummitRidgeFamilyOffice.jpg",
    pcptName: "Summit Ridge Family Office",
    contactName: "Austin Morris",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "1594c965-17e2-4e1c-99b3-80c9a0add39c",
    logoFileName: "IroncladPensionStrategies.jpg",
    pcptName: "Ironclad Pension Strategies",
    contactName: "Victoria Peterson",
    status: "Viewed",
    lastActivity: "5 days ago",
    type: "participant",
  },
  {
    id: "648925f4-445e-41d7-a1ed-016de0643268",
    logoFileName: "BirchwoodRealEstateInvestors.jpg",
    pcptName: "Birchwood Real Estate Investors",
    contactName: "Colin Diaz",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "d8d4e2f6-2deb-4c00-8bfd-86fdd96da683",
    logoFileName: "OceanviewFoundationLP.jpg",
    pcptName: "Oceanview Foundation LP",
    contactName: "Penelope Coleman",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "327485cb-43f9-4bfd-9aba-455d52f86127",
    logoFileName: "SunriseEquityPartners.jpg",
    pcptName: "Sunrise Equity Partners",
    contactName: "Ayaan Patel",
    status: "Viewed",
    lastActivity: "23 days ago",
    type: "participant",
  },
  {
    id: "aea37862-adcb-4b3a-b68c-ef34e2df6238",
    logoFileName: "BlackwaterPrivateInvestments.jpg",
    pcptName: "Blackwater Private Investments",
    contactName: "Mei Wong",
    status: "Viewed",
    lastActivity: "21 days ago",
    type: "participant",
  },
  {
    id: "94149629-54c8-4ad8-a03a-c87bd003e343",
    logoFileName: "OakhillFamilyOffice.jpg",
    pcptName: "Oakhill Family Office",
    contactName: "Nadia Khan",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "deb541c2-8d2b-4c41-a042-4a57b3d4f3a0",
    logoFileName: "YellowstonePartners.jpg",
    pcptName: "Yellowstone Partners",
    contactName: "Haruto Tanaka",
    status: "Viewed",
    lastActivity: "17 days ago",
    type: "participant",
  },
  {
    id: "99af76b6-b719-4040-b371-6a71fbaf8e86",
    logoFileName: "HorizonEndowment.jpg",
    pcptName: "Horizon Endowment",
    contactName: "Fatima Al-Farsi",
    status: "Viewed",
    lastActivity: "11 days ago",
    type: "participant",
  },
  {
    id: "8f30a67a-fe31-4055-aab1-61c8fb7a9bc0",
    logoFileName: "WillowCreekPension.jpg",
    pcptName: "Willow Creek Pension",
    contactName: "Sanjay Gupta",
    status: "Viewed",
    lastActivity: "8 days ago",
    type: "participant",
  },
  {
    id: "f065d1df-eac6-4813-a3ae-9e9ced4dbc01",
    logoFileName: "GoldenHillsGroup.jpg",
    pcptName: "Golden Hills Group",
    contactName: "Hana Kim",
    status: "Viewed",
    lastActivity: "17 days ago",
    type: "participant",
  },
  {
    id: "14dd336d-ed10-4d8b-b365-a7fd5282c5aa",
    logoFileName: "JSERS.jpg",
    pcptName: "JSERS",
    contactName: "Ali Al-Amiri",
    status: "Viewed",
    lastActivity: "22 days ago",
    type: "participant",
  },
  {
    id: "210f4ff9-70f0-43b4-b1a7-69be7a719e08",
    logoFileName: "EmeraldValleyPensionTrust.jpg",
    pcptName: "Emerald Valley Pension Trust",
    contactName: "Yumi Sato",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "54852330-e660-49a0-bdd2-26466d196592",
    logoFileName: "CedarGroveCapitalPartners.jpg",
    pcptName: "Cedar Grove Capital Partners",
    contactName: "Omar Syed",
    status: "Not Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "3a964557-59c6-46ea-92e5-872eadba8607",
    logoFileName: "PineRidgeEndowmentFund.jpg",
    pcptName: "Pine Ridge Endowment Fund",
    contactName: "Anika Sharma",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "a2b6e525-24f8-438b-ac8c-f656bbfd9544",
    logoFileName: "BoulderCrestRetirementSystem.jpg",
    pcptName: "Boulder Crest Retirement System",
    contactName: "Takashi Yamamoto",
    status: "Viewed",
    lastActivity: "1 days ago",
    type: "participant",
  },
  {
    id: "cab8b565-f66e-4893-a09e-1dc0820dac94",
    logoFileName: "OakwoodCityPensionFund.jpg",
    pcptName: "Oakwood City Pension Fund",
    contactName: "Layla Nassar",
    status: "Invited",
    lastActivity: "-",
    type: "participant",
  },
  {
    id: "2ddc3c60-5703-40ea-9cbc-4c3f0966bc01",
    logoFileName: "SunsetHarborWealthManagement.jpg",
    pcptName: "Sunset Harbor Wealth Management",
    contactName: "Min-Jun Park",
    status: "Viewed",
    lastActivity: "1 days ago",
    type: "participant",
  },
  {
    id: "7fc4ac0b-964f-43bb-9723-c7a7bdfd4613",
    logoFileName: "ViewpointPartners.jpg",
    pcptName: "Viewpoint Partners",
    contactName: "Logan Morgan",
    status: "Viewed",
    lastActivity: "23 days ago",
    type: "team member",
  },
  {
    id: "5b741c91-f1a8-4d29-94e5-b60fb7e48c16",
    logoFileName: "ViewpointPartners.jpg",
    pcptName: "Viewpoint Partners",
    contactName: "Jason Hadid",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "team member",
  },
  {
    id: "d29ea7b5-08b8-4666-ad1c-81789706a353",
    logoFileName: "ViewpointPartners.jpg",
    pcptName: "Viewpoint Partners",
    contactName: "Corey Lee",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "team member",
  },
  {
    id: "255c68dc-49fb-4dfc-a7be-f5d5f7681770",
    logoFileName: "ViewpointPartners.jpg",
    pcptName: "Viewpoint Partners",
    contactName: "Jamie Alston",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "team member",
  },
  {
    id: "f5183db1-925f-4998-9ef3-ba48fcb34ee1",
    logoFileName: "Janis&SilverLLP.jpg",
    pcptName: "Janis & Silver LLP",
    contactName: "Mark Houster",
    status: "Viewed",
    lastActivity: "2 days ago",
    type: "team member",
  },
];
// MarketMonitor section of the transaction

// txnMonitor section of the transaction

export interface ITxnMonitorParticipationLP {
  logoFileName: string;
  lpName: string;
  isParticipating: string;
  cav: string;
  participatingPercent: string;
  participatingDollar: string;
  reservePrice: string;
  proFormaSize: string;
}

export const txnMonitorParticipationLps: ITxnMonitorParticipationLP[] = [
  {
    logoFileName: "BlueSkyPrivateEquity.jpg",
    lpName: "Blue Sky Private Equity",
    isParticipating: "YES",
    cav: "$163,889,504",
    participatingPercent: "100.0%",
    participatingDollar: "$163,889,504",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "GaussFoundation.jpg",
    lpName: "Gauss Foundation",
    isParticipating: "YES",
    cav: "$142,895,543",
    participatingPercent: "100.0%",
    participatingDollar: "$142,895,543",
    reservePrice: "94.0%",
    proFormaSize: "$134,321,810",
  },
  {
    logoFileName: "YellowstonePartners.jpg",
    lpName: "Yellowstone Partners",
    isParticipating: "YES",
    cav: "$77,609,911",
    participatingPercent: "100.0%",
    participatingDollar: "$77,609,911",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "JSERS.jpg",
    lpName: "JSERS",
    isParticipating: "YES",
    cav: "$122,893,128",
    participatingPercent: "50.0%",
    participatingDollar: "$61,446,564",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "GranitePeakCapitalPartners.jpg",
    lpName: "Granite Peak Capital Partners",
    isParticipating: "YES",
    cav: "$25,246,130",
    participatingPercent: "100.0%",
    participatingDollar: "$25,246,130",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "CedarGroveCapitalPartners.jpg",
    lpName: "Cedar Grove Capital Partners",
    isParticipating: "YES",
    cav: "$20,850,328",
    participatingPercent: "100.0%",
    participatingDollar: "$20,850,328",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "YarboCountyTeachers.jpg",
    lpName: "Yarbo County Teachers",
    isParticipating: "YES",
    cav: "$20,124,433",
    participatingPercent: "100.0%",
    participatingDollar: "$20,124,433",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    lpName: "Silverleaf Family Office",
    isParticipating: "YES",
    cav: "$19,486,871",
    participatingPercent: "100.0%",
    participatingDollar: "$19,486,871",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "BancGaston.jpg",
    lpName: "Banc Gaston",
    isParticipating: "YES",
    cav: "$17,727,849",
    participatingPercent: "100.0%",
    participatingDollar: "$17,727,849",
    reservePrice: "90.0%",
    proFormaSize: "$15,955,064",
  },
  {
    logoFileName: "NobleTrustEquityPartners.jpg",
    lpName: "Noble Trust Equity Partners",
    isParticipating: "YES",
    cav: "$14,588,407",
    participatingPercent: "100.0%",
    participatingDollar: "$14,588,407",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "WillowCreekInvestmentPartners.jpg",
    lpName: "Willow Creek Investment Partners",
    isParticipating: "YES",
    cav: "$28,741,399",
    participatingPercent: "50.0%",
    participatingDollar: "$14,370,700",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "DadeEmployeeRetirementSystem.jpg",
    lpName: "Dade Employee Retirement System",
    isParticipating: "YES",
    cav: "$12,972,315",
    participatingPercent: "100.0%",
    participatingDollar: "$12,972,315",
    reservePrice: "88.0%",
    proFormaSize: "$11,415,637",
  },
  {
    logoFileName: "GoldenOakInvestments.jpg",
    lpName: "Golden Oak Investments",
    isParticipating: "YES",
    cav: "$8,033,120",
    participatingPercent: "100.0%",
    participatingDollar: "$8,033,120",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "DiamondKeyInvestmentGroup.jpg",
    lpName: "Diamond Key Investment Group",
    isParticipating: "YES",
    cav: "$10,384,458",
    participatingPercent: "50.0%",
    participatingDollar: "$5,192,229",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    lpName: "Willow Creek Pension",
    isParticipating: "YES",
    cav: "$7,717,689",
    participatingPercent: "50.0%",
    participatingDollar: "$3,858,844",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "JanetStreet.jpg",
    lpName: "Janet Street",
    isParticipating: "YES",
    cav: "$2,508,771",
    participatingPercent: "100.0%",
    participatingDollar: "$2,508,771",
    reservePrice: "85%",
    proFormaSize: "$2,132,456",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    lpName: "Horizon Endowment",
    isParticipating: "YES",
    cav: "$2,134,758",
    participatingPercent: "100.0%",
    participatingDollar: "$2,134,758",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SummitRidgeFamilyOffice.jpg",
    lpName: "Summit Ridge Family Office",
    isParticipating: "YES",
    cav: "$1,389,855",
    participatingPercent: "100.0%",
    participatingDollar: "$1,389,855",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "StarlightVentureCapital.jpg",
    lpName: "Starlight Venture Capital",
    isParticipating: "YES",
    cav: "$1,079,196",
    participatingPercent: "100.0%",
    participatingDollar: "$1,079,196",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "EntrypointFundIII.jpg",
    lpName: "Entrypoint Fund III",
    isParticipating: "YES",
    cav: "$1,022,478",
    participatingPercent: "100.0%",
    participatingDollar: "$1,022,478",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SunsetHarborWealthManagement.jpg",
    lpName: "Sunset Harbor Wealth Management",
    isParticipating: "YES",
    cav: "$931,162",
    participatingPercent: "100.0%",
    participatingDollar: "$931,162",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "CrystalLakeCapital.jpg",
    lpName: "Crystal Lake Capital",
    isParticipating: "YES",
    cav: "$538,905",
    participatingPercent: "100.0%",
    participatingDollar: "$538,905",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    lpName: "Golden Hills Group",
    isParticipating: "NO",
    cav: "$117,633,776",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "PhoenixGlobalWealthManagement.jpg",
    lpName: "Phoenix Global Wealth Management",
    isParticipating: "NO",
    cav: "$140,799,566",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "MapleGroveInstitutionalInvestors.jpg",
    lpName: "Maple Grove Institutional Investors",
    isParticipating: "NO",
    cav: "$117,690,726",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "MajesticInsuranceSolutions.jpg",
    lpName: "Majestic Insurance Solutions",
    isParticipating: "NO",
    cav: "$91,357,056",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "AzureCapitalManagement.jpg",
    lpName: "Azure Capital Management",
    isParticipating: "NO",
    cav: "$81,857,623",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "LighthouseEndowmentFund.jpg",
    lpName: "Lighthouse Endowment Fund",
    isParticipating: "NO",
    cav: "$48,464,178",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "OakwoodCityPensionFund.jpg",
    lpName: "Oakwood City Pension Fund",
    isParticipating: "NO",
    cav: "$45,462,223",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SterlingVenturePartners.jpg",
    lpName: "Sterling Venture Partners",
    isParticipating: "NO",
    cav: "$41,276,988",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "AmberFieldPrivateEquity.jpg",
    lpName: "Amber Field Private Equity",
    isParticipating: "NO",
    cav: "$39,702,188",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "RiverbendCapitalAdvisors.jpg",
    lpName: "Riverbend Capital Advisors",
    isParticipating: "NO",
    cav: "$33,314,172",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "AspenFamilyHoldings.jpg",
    lpName: "Aspen Family Holdings",
    isParticipating: "NO",
    cav: "$31,594,417",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "EmeraldValleyPensionTrust.jpg",
    lpName: "Emerald Valley Pension Trust",
    isParticipating: "NO",
    cav: "$28,294,154",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "CedarPointCapitalPartners.jpg",
    lpName: "Cedar Point Capital Partners",
    isParticipating: "NO",
    cav: "$22,979,372",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "HueInvestmentGroup.jpg",
    lpName: "Hue Investment Group",
    isParticipating: "NO",
    cav: "$19,081,861",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "VanguardLegacyInvestments.jpg",
    lpName: "Vanguard Legacy Investments",
    isParticipating: "NO",
    cav: "$14,229,474",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "NationalInvestmentFund.jpg",
    lpName: "National Investment Fund",
    isParticipating: "NO",
    cav: "$13,897,457",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "MarbleArchPrivateEquity.jpg",
    lpName: "Marble Arch Private Equity",
    isParticipating: "NO",
    cav: "$11,780,842",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "EagleEyeInsuranceInvestments.jpg",
    lpName: "Eagle Eye Insurance Investments",
    isParticipating: "NO",
    cav: "$10,063,572",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "BlackwaterPrivateInvestments.jpg",
    lpName: "Blackwater Private Investments",
    isParticipating: "NO",
    cav: "$9,706,277",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "Stanley&KaseAdvisory.jpg",
    lpName: "Stanley & Kase Advisory",
    isParticipating: "NO",
    cav: "$8,677,879",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "BoulderCrestRetirementSystem.jpg",
    lpName: "Boulder Crest Retirement System",
    isParticipating: "NO",
    cav: "$6,469,004",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "QuantumFoundationLP.jpg",
    lpName: "Quantum Foundation LP",
    isParticipating: "NO",
    cav: "$4,841,264",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "OakhillFamilyOffice.jpg",
    lpName: "Oakhill Family Office",
    isParticipating: "NO",
    cav: "$3,211,416",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "ElmwoodWealthManagement.jpg",
    lpName: "Elmwood Wealth Management",
    isParticipating: "NO",
    cav: "$2,161,238",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SunsetCapitalAdvisors.jpg",
    lpName: "Sunset Capital Advisors",
    isParticipating: "NO",
    cav: "$2,113,069",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "GreenfieldCapitalPartners.jpg",
    lpName: "Greenfield Capital Partners",
    isParticipating: "NO",
    cav: "$1,652,648",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "PrairieWealthAdvisors.jpg",
    lpName: "Prairie Wealth Advisors",
    isParticipating: "NO",
    cav: "$1,641,184",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "PineRidgeEndowmentFund.jpg",
    lpName: "Pine Ridge Endowment Fund",
    isParticipating: "NO",
    cav: "$1,448,145",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "IroncladPensionStrategies.jpg",
    lpName: "Ironclad Pension Strategies",
    isParticipating: "NO",
    cav: "$1,424,447",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "CresthillVentureGroup.jpg",
    lpName: "Cresthill Venture Group",
    isParticipating: "NO",
    cav: "$1,395,659",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "BirchwoodRealEstateInvestors.jpg",
    lpName: "Birchwood Real Estate Investors",
    isParticipating: "NO",
    cav: "$1,314,101",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "RoyalOakFinancialGroup.jpg",
    lpName: "Royal Oak Financial Group",
    isParticipating: "NO",
    cav: "$1,305,893",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SkylineEquityHoldings.jpg",
    lpName: "Skyline Equity Holdings",
    isParticipating: "NO",
    cav: "$1,263,877",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "RedwoodAssetManagement.jpg",
    lpName: "Redwood Asset Management",
    isParticipating: "NO",
    cav: "$1,179,842",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "WildflowerAssetManagement.jpg",
    lpName: "Wildflower Asset Management",
    isParticipating: "NO",
    cav: "$1,045,887",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SunriseEquityPartners.jpg",
    lpName: "Sunrise Equity Partners",
    isParticipating: "NO",
    cav: "$969,279",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "CypressFamilyOffice.jpg",
    lpName: "Cypress Family Office",
    isParticipating: "NO",
    cav: "$916,727",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "CrimsonPeakRetirementFund.jpg",
    lpName: "Crimson Peak Retirement Fund",
    isParticipating: "NO",
    cav: "$877,370",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "OceanviewFoundationLP.jpg",
    lpName: "Oceanview Foundation LP",
    isParticipating: "NO",
    cav: "$759,214",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "TwilightFoundationLP.jpg",
    lpName: "Twilight Foundation LP",
    isParticipating: "NO",
    cav: "$635,869",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "RavenwoodCapitalManagement.jpg",
    lpName: "Ravenwood Capital Management",
    isParticipating: "NO",
    cav: "$584,403",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "MaplewoodCommercialHoldings.jpg",
    lpName: "Maplewood Commercial Holdings",
    isParticipating: "NO",
    cav: "$219,208",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "SilverMountainEndowments.jpg",
    lpName: "Silver Mountain Endowments",
    isParticipating: "NO",
    cav: "$218,935",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "ClearwaterCapitalGroup.jpg",
    lpName: "Clearwater Capital Group",
    isParticipating: "NO",
    cav: "$212,673",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "MarkT.HamilFamilyFoundation.jpg",
    lpName: "Mark T. Hamil Family Foundation",
    isParticipating: "NO",
    cav: "$195,579",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "RiverstoneFamilyOffice.jpg",
    lpName: "Riverstone Family Office",
    isParticipating: "NO",
    cav: "$159,085",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "MoonlightEquityPartners.jpg",
    lpName: "Moonlight Equity Partners",
    isParticipating: "NO",
    cav: "$37,690",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
  {
    logoFileName: "HarborviewPrivateFunds.jpg",
    lpName: "Harborview Private Funds",
    isParticipating: "NO",
    cav: "$2,099",
    participatingPercent: "-",
    participatingDollar: "-",
    reservePrice: "-",
    proFormaSize: "-",
  },
];

export interface ITxnMonitorNonBindingIOIBuyer {
  logoFileName: string;
  buyerName: string;
  pricePercent: string;
  amount: string;
}

export const txnMonitorNonBindingIOIBuyers: ITxnMonitorNonBindingIOIBuyer[] = [
  {
    logoFileName: "YellowstonePartners.jpg",
    buyerName: "Yellowstone Partners",
    pricePercent: "93.0%",
    amount: "$350,000,000",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    buyerName: "Willow Creek Pension",
    pricePercent: "92.0%",
    amount: "$750,000,000",
  },
  {
    logoFileName: "MarbleArchPrivateEquity.jpg",
    buyerName: "Marble Arch Private Equity",
    pricePercent: "91.0%",
    amount: "$500,000,000",
  },
  {
    logoFileName: "HueInvestmentGroup.jpg",
    buyerName: "Hue Investment Group",
    pricePercent: "90.0%",
    amount: "$150,000,000",
  },
  {
    logoFileName: "JanetStreet.jpg",
    buyerName: "Janet Street",
    pricePercent: "90.0%",
    amount: "$350,000,000",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    buyerName: "Silverleaf Family Office",
    pricePercent: "88.0%",
    amount: "$110,000,000",
  },
  {
    logoFileName: "SalientPartners.jpg",
    buyerName: "Salient Partners",
    pricePercent: "87.0%",
    amount: "$600,000,000",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    buyerName: "Horizon Endowment",
    pricePercent: "86.0%",
    amount: "$150,000,000",
  },
  {
    logoFileName: "TRECP.jpg",
    buyerName: "TRECP",
    pricePercent: "86.0%",
    amount: "$600,000,000",
  },
  {
    logoFileName: "HashmontPartners.jpg",
    buyerName: "Hashmont Partners",
    pricePercent: "86.0%",
    amount: "$350,000,000",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    buyerName: "Golden Hills Group",
    pricePercent: "85.0%",
    amount: "$150,000,000",
  },
  {
    logoFileName: "Knoll&Co.jpg",
    buyerName: "Knoll & Co",
    pricePercent: "83.0%",
    amount: "$250,000,000",
  },
  {
    logoFileName: "OpenFront.jpg",
    buyerName: "Open Front",
    pricePercent: "-",
    amount: "-",
  },
  {
    logoFileName: "HiFongGroup.jpg",
    buyerName: "HiFong Group",
    pricePercent: "-",
    amount: "-",
  },
  {
    logoFileName: "VitalRockPartners.jpg",
    buyerName: "Vital Rock Partners",
    pricePercent: "-",
    amount: "-",
  },
];

export interface ITxnMonitorBindingLOIBuyers {
  logoFileName: string;
  buyerName: string;
  pricePercent: string;
  size: string;
}

export const txnMonitorBindingLOIBuyers: ITxnMonitorBindingLOIBuyers[] = [
  {
    logoFileName: "HueInvestmentGroup.jpg",
    buyerName: "Hue Investment Group",
    pricePercent: "95.0%",
    size: "$50,000,000",
  },
  {
    logoFileName: "Knoll&Co.jpg",
    buyerName: "Knoll & Co",
    pricePercent: "95.0%",
    size: "$150,000,000",
  },
  {
    logoFileName: "MarbleArchPrivateEquity.jpg",
    buyerName: "Marble Arch Private Equity",
    pricePercent: "92.5%",
    size: "$400,000,000",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    buyerName: "Horizon Endowment",
    pricePercent: "91.5%",
    size: "$700,000,000",
  },
  {
    logoFileName: "JanetStreet.jpg",
    buyerName: "Janet Street",
    pricePercent: "91.5%",
    size: "$250,000,000",
  },
  {
    logoFileName: "YellowstonePartners.jpg",
    buyerName: "Yellowstone Partners",
    pricePercent: "90.0%",
    size: "$150,000,000",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    buyerName: "Golden Hills Group",
    pricePercent: "90.0%",
    size: "$100,000,000",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    buyerName: "Silverleaf Family Office",
    pricePercent: "85.0%",
    size: "$110,000,000",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    buyerName: "Willow Creek Pension",
    pricePercent: "85.0%",
    size: "$350,000,000",
  },
  {
    logoFileName: "HashmontPartners.jpg",
    buyerName: "Hashmont Partners",
    pricePercent: "-",
    size: "$50,000,000",
  },
  {
    logoFileName: "TRECP.jpg",
    buyerName: "TRECP",
    pricePercent: "-",
    size: "$25,000,000",
  },
  {
    logoFileName: "SalientPartners.jpg",
    buyerName: "Salient Partners",
    pricePercent: "-",
    size: "-",
  },
];

export interface ITxnMonitorElectionLP {
  logoFileName: string;
  lpName: string;
  electing: string;
  cav: string;
  electionPercent: string;
  electionDollar: string;
  proceeds: string;
  pas: string;
  ta: string;
}
export interface ITxnMonitorElectionOverview {
  electingCount: number;
  electingAmount: string;
  lps: ITxnMonitorElectionLP[];
}

export const txnMonitorElectingLPs: ITxnMonitorElectionLP[] = [
  {
    logoFileName: "BlueSkyPrivateEquity.jpg",
    lpName: "Blue Sky Private Equity",
    electing: "YES",
    cav: "$163,889,504",
    electionPercent: "100.0%",
    electionDollar: "$ 163,889,503.87",
    proceeds: "$ 151,597,791.08",
    pas: "In Progress",
    ta: "In Progress",
  },
  {
    logoFileName: "YellowstonePartners.jpg",
    lpName: "Yellowstone Partners",
    electing: "YES",
    cav: "$77,609,911",
    electionPercent: "100.0%",
    electionDollar: "$ 77,609,910.88",
    proceeds: "$ 71,789,167.56",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "GranitePeakCapitalPartners.jpg",
    lpName: "Granite Peak Capital Partners",
    electing: "YES",
    cav: "$25,246,130",
    electionPercent: "100.0%",
    electionDollar: "$ 25,246,129.59",
    proceeds: "$ 23,352,669.87",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "CedarGroveCapitalPartners.jpg",
    lpName: "Cedar Grove Capital Partners",
    electing: "YES",
    cav: "$20,850,328",
    electionPercent: "100.0%",
    electionDollar: "$ 20,850,327.96",
    proceeds: "$ 19,286,553.36",
    pas: "In Progress",
    ta: "In Progress",
  },
  {
    logoFileName: "YarboCountyTeachers.jpg",
    lpName: "Yarbo County Teachers",
    electing: "YES",
    cav: "$20,124,433",
    electionPercent: "100.0%",
    electionDollar: "$ 20,124,433.40",
    proceeds: "$ 18,615,100.90",
    pas: "In Progress",
    ta: "In Progress",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    lpName: "Silverleaf Family Office",
    electing: "YES",
    cav: "$19,486,871",
    electionPercent: "100.0%",
    electionDollar: "$ 19,486,871.04",
    proceeds: "$ 18,025,355.71",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "BancGaston.jpg",
    lpName: "Banc Gaston",
    electing: "YES",
    cav: "$17,727,849",
    electionPercent: "100.0%",
    electionDollar: "$ 17,727,848.82",
    proceeds: "$ 16,398,260.16",
    pas: "In Progress",
    ta: "Complete",
  },
  {
    logoFileName: "NobleTrustEquityPartners.jpg",
    lpName: "Noble Trust Equity Partners",
    electing: "YES",
    cav: "$14,588,407",
    electionPercent: "100.0%",
    electionDollar: "$ 14,588,406.76",
    proceeds: "$ 13,494,276.25",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "WillowCreekInvestmentPartners.jpg",
    lpName: "Willow Creek Investment Partners",
    electing: "YES",
    cav: "$28,741,399",
    electionPercent: "50.0%",
    electionDollar: "$ 14,370,699.62",
    proceeds: "$ 13,292,897.15",
    pas: "In Progress",
    ta: "In Progress",
  },
  {
    logoFileName: "DadeEmployeeRetirementSystem.jpg",
    lpName: "Dade Employee Retirement System",
    electing: "YES",
    cav: "$12,972,315",
    electionPercent: "100.0%",
    electionDollar: "$ 12,972,314.52",
    proceeds: "$ 11,999,390.93",
    pas: "In Progress",
    ta: "In Progress",
  },
  {
    logoFileName: "GoldenOakInvestments.jpg",
    lpName: "Golden Oak Investments",
    electing: "YES",
    cav: "$8,033,120",
    electionPercent: "100.0%",
    electionDollar: "$ 8,033,119.58",
    proceeds: "$ 7,430,635.61",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "DiamondKeyInvestmentGroup.jpg",
    lpName: "Diamond Key Investment Group",
    electing: "YES",
    cav: "$10,384,458",
    electionPercent: "50.0%",
    electionDollar: "$ 5,192,228.84",
    proceeds: "$ 4,802,811.68",
    pas: "In Progress",
    ta: "Complete",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    lpName: "Willow Creek Pension",
    electing: "YES",
    cav: "$7,717,689",
    electionPercent: "50.0%",
    electionDollar: "$ 3,858,844.32",
    proceeds: "$ 3,569,431.00",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "SummitRidgeFamilyOffice.jpg",
    lpName: "Summit Ridge Family Office",
    electing: "YES",
    cav: "$1,389,855",
    electionPercent: "100.0%",
    electionDollar: "$ 1,389,855.06",
    proceeds: "$ 1,285,615.93",
    pas: "Complete",
    ta: "Complete",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    lpName: "Golden Hills Group",
    electing: "NO",
    cav: "$117,633,776",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "GaussFoundation.jpg",
    lpName: "Gauss Foundation",
    electing: "NO",
    cav: "$142,895,543",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "PhoenixGlobalWealthManagement.jpg",
    lpName: "Phoenix Global Wealth Management",
    electing: "NO",
    cav: "$140,799,566",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "JSERS.jpg",
    lpName: "JSERS",
    electing: "NO",
    cav: "$122,893,128",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MapleGroveInstitutionalInvestors.jpg",
    lpName: "Maple Grove Institutional Investors",
    electing: "NO",
    cav: "$117,690,726",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MajesticInsuranceSolutions.jpg",
    lpName: "Majestic Insurance Solutions",
    electing: "NO",
    cav: "$91,357,056",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "AzureCapitalManagement.jpg",
    lpName: "Azure Capital Management",
    electing: "NO",
    cav: "$81,857,623",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "LighthouseEndowmentFund.jpg",
    lpName: "Lighthouse Endowment Fund",
    electing: "NO",
    cav: "$48,464,178",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "OakwoodCityPensionFund.jpg",
    lpName: "Oakwood City Pension Fund",
    electing: "NO",
    cav: "$45,462,223",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "SterlingVenturePartners.jpg",
    lpName: "Sterling Venture Partners",
    electing: "NO",
    cav: "$41,276,988",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "AmberFieldPrivateEquity.jpg",
    lpName: "Amber Field Private Equity",
    electing: "NO",
    cav: "$39,702,188",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "RiverbendCapitalAdvisors.jpg",
    lpName: "Riverbend Capital Advisors",
    electing: "NO",
    cav: "$33,314,172",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "AspenFamilyHoldings.jpg",
    lpName: "Aspen Family Holdings",
    electing: "NOT REPLIED",
    cav: "$31,594,417",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "EmeraldValleyPensionTrust.jpg",
    lpName: "Emerald Valley Pension Trust",
    electing: "NOT REPLIED",
    cav: "$28,294,154",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "CedarPointCapitalPartners.jpg",
    lpName: "Cedar Point Capital Partners",
    electing: "NOT REPLIED",
    cav: "$22,979,372",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "HueInvestmentGroup.jpg",
    lpName: "Hue Investment Group",
    electing: "NOT REPLIED",
    cav: "$19,081,861",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "VanguardLegacyInvestments.jpg",
    lpName: "Vanguard Legacy Investments",
    electing: "NOT REPLIED",
    cav: "$14,229,474",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "NationalInvestmentFund.jpg",
    lpName: "National Investment Fund",
    electing: "NOT REPLIED",
    cav: "$13,897,457",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MarbleArchPrivateEquity.jpg",
    lpName: "Marble Arch Private Equity",
    electing: "NOT REPLIED",
    cav: "$11,780,842",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "EagleEyeInsuranceInvestments.jpg",
    lpName: "Eagle Eye Insurance Investments",
    electing: "NOT REPLIED",
    cav: "$10,063,572",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "BlackwaterPrivateInvestments.jpg",
    lpName: "Blackwater Private Investments",
    electing: "NOT REPLIED",
    cav: "$9,706,277",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "Stanley&KaseAdvisory.jpg",
    lpName: "Stanley & Kase Advisory",
    electing: "NOT REPLIED",
    cav: "$8,677,879",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "BoulderCrestRetirementSystem.jpg",
    lpName: "Boulder Crest Retirement System",
    electing: "NOT REPLIED",
    cav: "$6,469,004",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "QuantumFoundationLP.jpg",
    lpName: "Quantum Foundation LP",
    electing: "NOT REPLIED",
    cav: "$4,841,264",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "OakhillFamilyOffice.jpg",
    lpName: "Oakhill Family Office",
    electing: "NOT REPLIED",
    cav: "$3,211,416",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "JanetStreet.jpg",
    lpName: "Janet Street",
    electing: "NOT REPLIED",
    cav: "$2,508,771",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "ElmwoodWealthManagement.jpg",
    lpName: "Elmwood Wealth Management",
    electing: "NOT REPLIED",
    cav: "$2,161,238",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    lpName: "Horizon Endowment",
    electing: "NOT REPLIED",
    cav: "$2,134,758",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "SunsetCapitalAdvisors.jpg",
    lpName: "Sunset Capital Advisors",
    electing: "NOT REPLIED",
    cav: "$2,113,069",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "GreenfieldCapitalPartners.jpg",
    lpName: "Greenfield Capital Partners",
    electing: "NOT REPLIED",
    cav: "$1,652,648",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "PrairieWealthAdvisors.jpg",
    lpName: "Prairie Wealth Advisors",
    electing: "NOT REPLIED",
    cav: "$1,641,184",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "PineRidgeEndowmentFund.jpg",
    lpName: "Pine Ridge Endowment Fund",
    electing: "NOT REPLIED",
    cav: "$1,448,145",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "IroncladPensionStrategies.jpg",
    lpName: "Ironclad Pension Strategies",
    electing: "NOT REPLIED",
    cav: "$1,424,447",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "CresthillVentureGroup.jpg",
    lpName: "Cresthill Venture Group",
    electing: "NOT REPLIED",
    cav: "$1,395,659",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "BirchwoodRealEstateInvestors.jpg",
    lpName: "Birchwood Real Estate Investors",
    electing: "NOT REPLIED",
    cav: "$1,314,101",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "RoyalOakFinancialGroup.jpg",
    lpName: "Royal Oak Financial Group",
    electing: "NOT REPLIED",
    cav: "$1,305,893",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "SkylineEquityHoldings.jpg",
    lpName: "Skyline Equity Holdings",
    electing: "NOT REPLIED",
    cav: "$1,263,877",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "RedwoodAssetManagement.jpg",
    lpName: "Redwood Asset Management",
    electing: "NOT REPLIED",
    cav: "$1,179,842",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "WildflowerAssetManagement.jpg",
    lpName: "Wildflower Asset Management",
    electing: "NOT REPLIED",
    cav: "$1,045,887",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "StarlightVentureCapital.jpg",
    lpName: "Starlight Venture Capital",
    electing: "NOT REPLIED",
    cav: "$981,087",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "SunriseEquityPartners.jpg",
    lpName: "Sunrise Equity Partners",
    electing: "NOT REPLIED",
    cav: "$969,279",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "CypressFamilyOffice.jpg",
    lpName: "Cypress Family Office",
    electing: "NOT REPLIED",
    cav: "$916,727",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "CrimsonPeakRetirementFund.jpg",
    lpName: "Crimson Peak Retirement Fund",
    electing: "NOT REPLIED",
    cav: "$877,370",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "SunsetHarborWealthManagement.jpg",
    lpName: "Sunset Harbor Wealth Management",
    electing: "NOT REPLIED",
    cav: "$846,511",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "EntrypointFundIII.jpg",
    lpName: "Entrypoint Fund III",
    electing: "NOT REPLIED",
    cav: "$766,858",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "OceanviewFoundationLP.jpg",
    lpName: "Oceanview Foundation LP",
    electing: "NOT REPLIED",
    cav: "$759,214",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "TwilightFoundationLP.jpg",
    lpName: "Twilight Foundation LP",
    electing: "NOT REPLIED",
    cav: "$635,869",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "RavenwoodCapitalManagement.jpg",
    lpName: "Ravenwood Capital Management",
    electing: "NOT REPLIED",
    cav: "$584,403",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "CrystalLakeCapital.jpg",
    lpName: "Crystal Lake Capital",
    electing: "NOT REPLIED",
    cav: "$489,914",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MaplewoodCommercialHoldings.jpg",
    lpName: "Maplewood Commercial Holdings",
    electing: "NOT REPLIED",
    cav: "$219,208",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "SilverMountainEndowments.jpg",
    lpName: "Silver Mountain Endowments",
    electing: "NOT REPLIED",
    cav: "$218,935",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "ClearwaterCapitalGroup.jpg",
    lpName: "Clearwater Capital Group",
    electing: "NOT REPLIED",
    cav: "$212,673",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MarkT.HamilFamilyFoundation.jpg",
    lpName: "Mark T. Hamil Family Foundation",
    electing: "NOT REPLIED",
    cav: "$195,579",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "RiverstoneFamilyOffice.jpg",
    lpName: "Riverstone Family Office",
    electing: "NOT REPLIED",
    cav: "$159,085",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MoonlightEquityPartners.jpg",
    lpName: "Moonlight Equity Partners",
    electing: "NOT REPLIED",
    cav: "$37,690",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "MoonlightEquityPartners.jpg",
    lpName: "Moonlight Equity Partners",
    electing: "NOT REPLIED",
    cav: "$37,690",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
  {
    logoFileName: "HarborviewPrivateFunds.jpg",
    lpName: "Harborview Private Funds",
    electing: "NOT REPLIED",
    cav: "$2,099",
    electionPercent: "-",
    electionDollar: "-",
    proceeds: "-",
    pas: "-",
    ta: "-",
  },
];

export const txnMonitorElectionOverview: ITxnMonitorElectionOverview = {
  electingCount: 14,
  electingAmount: "$405,340,494",
  lps: txnMonitorElectingLPs,
};
