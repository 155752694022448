import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { ITPTransaction } from "../pages/TpTransactionListPage";

import { DollarAmount } from "common/@types/app/DollarAmount";
import { BarLoader } from "common/components/BarLoader";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

export const TpTransactionListTable = ({
  transactions,
  handleCreateTransactionClick,
  handleViewTransactionClick,
  isLoading,
}: {
  transactions: ITPTransaction[];
  handleCreateTransactionClick: () => void;
  handleViewTransactionClick: (transaction: ITPTransaction) => void;
  isLoading: boolean;
}) => {
  return (
    <div>
      <TapTable
        isLoading={isLoading}
        data={transactions}
        columns={[
          {
            key: "name",
            label: "Project Name",
            align: TableAlignment.LEFT,
          },
          { key: "funds", label: "Funds", align: TableAlignment.LEFT },
          {
            key: "sellingEntity",
            label: "Selling Entity",
            align: TableAlignment.LEFT,
          },
          { key: "nav", label: "NAV", align: TableAlignment.LEFT },
          { key: "stage", label: "Stage", align: TableAlignment.LEFT },
          {
            key: "targetClose",
            label: "Target Close",
            align: TableAlignment.LEFT,
          },
          { key: "buyers", label: "Buyers", align: TableAlignment.LEFT },
          { key: "actions", label: "", align: TableAlignment.LEFT },
        ]}
        emptyStateTitle="Create Transaction"
        emptyStateDescription="Start a transaction process and invite participants with a guided process."
        emptyStateActions={[
          {
            label: "Create Transaction",
            onClick: handleCreateTransactionClick,
            type: BUTTON_TYPES.SECONDARY,
            icon: <PlusIcon />,
          },
        ]}
        renderRow={(transaction: ITPTransaction) => {
          const nav = transaction.nav || NOT_AVAILABLE_STR;
          return (
            <>
              <TableCell isPrimaryColumn hasBullet>
                {transaction.name}
              </TableCell>
              <TableCell>{transaction.funds}</TableCell>
              <TableCell>{transaction.sellingEntity}</TableCell>
              <TableCell>
                {/* if nav is a number, format as currency otherwise just show the string */}
                {typeof nav === "number"
                  ? `${new DollarAmount(nav).formatted()}`
                  : nav}
              </TableCell>
              <TableCell>{transaction.stage}</TableCell>
              <TableCell>
                {transaction.targetClose || NOT_AVAILABLE_STR}
              </TableCell>
              <TableCell>{transaction.buyers}</TableCell>
              <TableCell hasActions>
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  onClick={() => handleViewTransactionClick(transaction)}
                >
                  View
                </Button>
              </TableCell>
            </>
          );
        }}
      />
      {isLoading && <BarLoader />}
    </div>
  );
};
