import { gql } from "@apollo/client";

export const GetTransactionHoldingsQuery = gql`
  query Holdings($transactionId: String!) {
    holdings(transactionId: $transactionId) {
      id
      issuerName
      isManagerAdmin
      holding {
        id
        issuerId
        issuerType
        entityId
        bookValue
        contributed
        committed
        distributed
        marketValue
        referenceDate
      }
    }
  }
`;
