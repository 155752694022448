import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { TransactionPolicy } from "experiences/transactions/domain/models/TransactionPolicy";

interface TransactionPoliciesTableProps {
  transactionPolicies: TransactionPolicy[];
}

export const TransactionPoliciesTable = ({
  transactionPolicies,
}: TransactionPoliciesTableProps) => {
  return (
    <>
      <TapTable
        data={transactionPolicies}
        emptyStateTitle={
          !transactionPolicies.length
            ? "There are no transactions to display."
            : null
        }
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "recurringTenders",
            label: "Recurring Tenders",
            align: TableAlignment.LEFT,
          },
          {
            key: "tenderCadence",
            label: "Tender Cadence",
            align: TableAlignment.LEFT,
          },
          {
            key: "tenderClosing",
            label: "Tender Closing",
            align: TableAlignment.LEFT,
          },
          {
            key: "preEmptionRights",
            label: "Pre-emption Rights",
            align: TableAlignment.LEFT,
          },
          {
            key: "majorityApproval",
            label: "Majority Approval",
            align: TableAlignment.LEFT,
          },
          {
            key: "qms",
            label: "QMS",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(transactionPolicy: TransactionPolicy) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                {transactionPolicy.fundName}
              </TableCell>
              <TableCell>
                <TextChip
                  text={transactionPolicy.recurringTenders}
                  success={
                    transactionPolicy.recurringTenders.toLocaleLowerCase() ===
                    "yes"
                  }
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>{transactionPolicy.tenderCadence}</TableCell>
              <TableCell>{transactionPolicy.tenderClosing}</TableCell>
              <TableCell>
                <TextChip
                  text={transactionPolicy.preemptionRights}
                  success={
                    transactionPolicy.preemptionRights.toLocaleLowerCase() ===
                    "yes"
                  }
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>
                <TextChip
                  text={transactionPolicy.majorityApproval}
                  success={
                    transactionPolicy.majorityApproval.toLocaleLowerCase() ===
                    "yes"
                  }
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>
                <TextChip
                  text={transactionPolicy.qms}
                  success={transactionPolicy.qms.toLocaleLowerCase() === "yes"}
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
