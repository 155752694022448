import React from "react";
import { useParams } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import { Stack, Typography } from "@mui/material";

import { HalfImageLayout } from "common/components/HalfImage";
import FormikOtpInput from "common/components/formik/FormikOtpInput";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { useAuthContext } from "../state/AuthenticationContext";
import { OTPLoginSubmitted } from "../state/AuthenticationEvent";
import { FormContentSkeleton } from "../components/FormContentSkeleton";
import { AuthAppBar } from "./AuthAppBar";

export const SixDigitCode = () => {
  const { challengeId } = useParams();

  return (
    <HalfImageLayout
      imageUrl="/images/login/login.webp"
      contentOnLeft
      appBar={<AuthAppBar showSignUpButton />}
    >
      <FormContentSkeleton formTitle={"One time code"}>
        <SixDigitCodeForm challengeId={challengeId!} />
      </FormContentSkeleton>
    </HalfImageLayout>
  );
};

interface ISixDigitCodeForm {
  challengeId: string;
}

export const SixDigitCodeForm: React.FC<ISixDigitCodeForm> = ({
  challengeId,
}) => {
  const { emitEvent } = useAuthContext();

  const validationSchema = yup.object({
    code: yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent!(
        new OTPLoginSubmitted({
          code: +values.code,
          challengeId: challengeId,
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Typography>Enter the code from the login email below</Typography>
        <FormikOtpInput formik={formik} name="code" />
        <br />
        <br />
        <br />
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size="large"
          onClick={formik.submitForm}
          fullWidth
        >
          Continue
        </Button>
      </Stack>
    </form>
  );
};
