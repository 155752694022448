import { useEffect } from "react";
import { useDocumentsContext } from "../state/DocumentsContext";
import {
  DocumentSignFlowLoading,
  RequestRedliningConfirmationScreenLoaded,
  RedliningPromptScreenLoaded,
  SignatureScreenLoaded,
  DelegateSignatureConfirmationScreenLoaded,
} from "../state/DocumentsState";
import Loader from "common/components/TapLoader";
import SignNDA from "./SignNda";
import { NDASignatureFlowAccessed } from "../state/DocumentsEvent";
import RequestRedlining from "./RequestRedlining";
import RequestRedliningConfirmation from "./RequestRedliningConfirmation";
import DelegateSignatoryConfirmation from "./DelegateSignatoryConfirmation";

const SignNDAFlow = () => {
  const { emitEvent, documentSignFlowState } = useDocumentsContext();

  useEffect(() => {
    emitEvent(new NDASignatureFlowAccessed());
  }, []);

  if (documentSignFlowState instanceof DocumentSignFlowLoading) {
    return <Loader />;
  } else if (documentSignFlowState instanceof SignatureScreenLoaded) {
    return <SignNDA {...documentSignFlowState} />;
  } else if (documentSignFlowState instanceof RedliningPromptScreenLoaded) {
    return <RequestRedlining />;
  } else if (
    documentSignFlowState instanceof RequestRedliningConfirmationScreenLoaded
  ) {
    return <RequestRedliningConfirmation />;
  } else if (
    documentSignFlowState instanceof DelegateSignatureConfirmationScreenLoaded
  ) {
    return <DelegateSignatoryConfirmation />;
  }
  return <Loader />;
};

export default SignNDAFlow;
