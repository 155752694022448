import { IDataRoom } from "experiences/transactions/domain/models/DataRoom";

export const mockDR: IDataRoom = {
  details: {
    transactionId: "demo",
    name: "demo",
    path: "https://cdn.usetap.com/dataroom-zip/HwHCrvRt.zip",
  },
  directory: {
    name: "root",
    path: "",
    items: [
      {
        name: "Viewpoint Partners Fund III Q2 2024 Tender VDR",
        path: "Viewpoint Partners Fund III Q2 2024 Tender VDR",
        items: [
          {
            name: "2023 Q1",
            path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q1",
            items: [
              {
                name: "Viewpoint Partners Fund III LP - Financial Statements.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q1/Viewpoint Partners Fund III LP - Financial Statements.pdf",
                size: "1.87 MB",
              },
              {
                name: "Viewpoint Partners Fund III LP - Operating Report.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q1/Viewpoint Partners Fund III LP - Operating Report.pdf",
                size: "1.87 MB",
              },
            ],
            size: "3.74 MB",
          },
          {
            name: "2023 Q2",
            path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q2",
            items: [
              {
                name: "Viewpoint Partners Fund III LP - Financial Statements.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q2/Viewpoint Partners Fund III LP - Financial Statements.pdf",
                size: "1.87 MB",
              },
              {
                name: "Viewpoint Partners Fund III LP - Operating Report.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q2/Viewpoint Partners Fund III LP - Operating Report.pdf",
                size: "1.87 MB",
              },
            ],
            size: "3.74 MB",
          },
          {
            name: "2023 Q3",
            path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q3",
            items: [
              {
                name: "Viewpoint Partners Fund III LP - Financial Statements.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q3/Viewpoint Partners Fund III LP - Financial Statements.pdf",
                size: "1.87 MB",
              },
              {
                name: "Viewpoint Partners Fund III LP - Operating Report.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q3/Viewpoint Partners Fund III LP - Operating Report.pdf",
                size: "1.87 MB",
              },
            ],
            size: "3.74 MB",
          },
          {
            name: "2023 Q4",
            path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q4",
            items: [
              {
                name: "Viewpoint Partners Fund III LP - Financial Statements.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q4/Viewpoint Partners Fund III LP - Financial Statements.pdf",
                size: "1.87 MB",
              },
              {
                name: "Viewpoint Partners Fund III LP - Operating Report.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/2023 Q4/Viewpoint Partners Fund III LP - Operating Report.pdf",
                size: "1.87 MB",
              },
            ],
            size: "3.74 MB",
          },
          {
            name: "Closing Agreements",
            path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/Closing Agreements",
            items: [
              {
                name: "Viewpoint Partners Fund III - Purchase and Sale Agreement.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/Closing Agreements/Viewpoint Partners Fund III - Purchase and Sale Agreement.pdf",
                size: "1.3 MB",
              },
              {
                name: "Viewpoint Partners Fund III - Transfer Agreement.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/Closing Agreements/Viewpoint Partners Fund III - Transfer Agreement.pdf",
                size: "1.3 MB",
              },
            ],
            size: "2.6 MB",
          },
          {
            name: "Formation Documents",
            path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/Formation Documents",
            items: [
              {
                name: "Viewpoint Partners - KYC:AML Policy Statement.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/Formation Documents/Viewpoint Partners - KYC:AML Policy Statement.pdf",
                size: "1.3 MB",
              },
              {
                name: "Viewpoint Partners Fund III - Limited Partnership Agreement.pdf",
                path: "Viewpoint Partners Fund III Q2 2024 Tender VDR/Formation Documents/Viewpoint Partners Fund III - Limited Partnership Agreement.pdf",
                size: "1.3 MB",
              },
            ],
            size: "2.6 MB",
          },
        ],
        size: "20.16 MB",
      },
    ],
    size: "20.16 MB",
  },
};
