import { useEffect, useState } from "react";
import { Navigate, Route, Routes, generatePath, useParams } from "react-router";

import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { ManageParticipantsButtonClicked } from "../state/TransactionsEvent";
import { GPMockedParticipantListLoaded } from "../state/participants/ParticipantsState";
import { GpTransactionParticipantsTable } from "./participants/GpTransactionParticipantsTable";
import { IMockedParticipant } from "../mock/GP";
import {
  GP_ROUTES,
  GpTransactionParticipantRoutes,
} from "common/constants/routes";
import { GpTransactionTeamMembersTable } from "./participants/GpTransactionTeamMembersTable";

// Note: Be careful, in the participants list, the id we're looking for is `userId`, not `id`
const getParticipantByName = ({
  participants,
  name: participantId,
}: {
  participants: IMockedParticipant[];
  name: IMockedParticipant["pcptName"];
}) => {
  return participants.find(
    (participant) => participant.pcptName === participantId,
  );
};

export const GpTransactionParticipants = () => {
  const baseRoute = GP_ROUTES.TransactionDetailParticipants;
  const { transactionId } = useParams();
  const { emitPcptEvent, participantsListState } = useTransactionsContext();

  useEffect(() => {
    emitPcptEvent!(
      new ManageParticipantsButtonClicked({ txnId: transactionId! }),
    );
  }, []);

  const { user } = useAuthContext();
  const [openSendInviteModal, setOpenSendInviteModal] = useState(false);

  const handleSendInvite = () => {
    setOpenSendInviteModal(true);
  };

  const [selectedParticipants, setSelectedParticipants] = useState<
    IMockedParticipant[]
  >([]);

  const selectParticipants = (
    newlySelectedParticipants: IMockedParticipant[],
  ) => {
    // add all selected newlySelectedParticipants to the selectedParticipants array
    setSelectedParticipants((prev) => {
      const next = prev.filter(
        (p) => !newlySelectedParticipants.map((pcpt) => pcpt.id).includes(p.id),
      );
      next.push(...newlySelectedParticipants);

      return next;
    });
  };

  const unselectParticipants = (pcpts: IMockedParticipant[]) => {
    setSelectedParticipants((prev) => {
      return prev.filter((p) => !pcpts.map((pcpt) => pcpt.id).includes(p.id));
    });
  };

  useEffect(() => {
    emitPcptEvent!(
      new ManageParticipantsButtonClicked({ txnId: transactionId! }),
    );
  }, []);

  if (!user) return null;

  if (participantsListState instanceof GPMockedParticipantListLoaded) {
    const currUserParticipant = getParticipantByName({
      participants: participantsListState.participants,
      name: user.firstName + " " + user.lastName,
    });

    return (
      <>
        <Routes>
          <Route
            path={GpTransactionParticipantRoutes.Participants}
            element={
              <GpTransactionParticipantsTable
                selectParticipants={selectParticipants}
                selectedParticipants={selectedParticipants}
                unselectParticipants={unselectParticipants}
                participants={participantsListState.participants}
                currentParticipant={currUserParticipant}
              />
            }
          />
          <Route
            path={GpTransactionParticipantRoutes.TeamMembers}
            element={
              <GpTransactionTeamMembersTable
                selectParticipants={selectParticipants}
                selectedParticipants={selectedParticipants}
                unselectParticipants={unselectParticipants}
                participants={participantsListState.participants}
                currentParticipant={currUserParticipant}
              />
            }
          />
          <Route
            path="/"
            element={
              <Navigate
                to={`${generatePath(
                  `${baseRoute}${GpTransactionParticipantRoutes.Participants}`,
                  {
                    transactionId: transactionId,
                  },
                )}`}
              />
            }
          />
        </Routes>
      </>
    );
  }
  return null;
};
