import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IValidatedInviteeData } from "../models/ValidatedInviteeData";
import { Organization, OrganizationType } from "../models/Organization";

interface IResp {
  email: string;
  isAlreadyOnboarded: boolean;
  preSelectedOrg: {
    id: string;
    name: string;
    type: OrganizationType;
  };
}

export class ValidateInvitee
  implements IAsyncUseCase<IParams, IValidatedInviteeData>
{
  call: (params: IParams) => Promise<Either<Failure, IValidatedInviteeData>> =
    async ({ inviteeId }) => {
      return await new TapClient(`/invitee/validate/${inviteeId}`)
        .get({})
        .then((res: IResp) => {
          return right({
            email: res.email,
            isAlreadyOnboarded: res.isAlreadyOnboarded,
            preSelectedOrg: res.preSelectedOrg
              ? new Organization(res.preSelectedOrg)
              : undefined,
          });
        })
        .catch((resp: any) => {
          console.log(resp);
          return left(new Failure("Issue validating invite"));
        });
    };
}

interface IParams {
  inviteeId: string;
}
