export enum FundDataDocumentType {
  FinancialStatement = 1, // Financial Statement
  CapitalAccount = 2, // Capital Account Statement
  OperatingReport = 3, // Operating Report
  CapitalCallNotice = 4, // Capital Call Notice
  DistributionNotice = 5, // Distribution Notice
  AGMMaterial = 6, // AGM Materials
  Other = 7, // Other
}

export enum FundDataDocumentTypeGql {
  FinancialStatement = "FinancialStatement",
  CapitalAccount = "CapitalAccount",
  OperatingReport = "OperatingReport",
  CapitalCallNotice = "CapitalCallNotice",
  DistributionNotice = "DistributionNotice",
  AGMMaterial = "AGMMaterial",
  Other = "Other",
}

export const documentTypeLabels: Record<FundDataDocumentType, string> = {
  [FundDataDocumentType.FinancialStatement]: "Financial Statements",
  [FundDataDocumentType.CapitalAccount]: "Capital Account Statements",
  [FundDataDocumentType.OperatingReport]: "Operating Reports",
  [FundDataDocumentType.CapitalCallNotice]: "Capital Call Notices",
  [FundDataDocumentType.DistributionNotice]: "Distribution Notices",
  [FundDataDocumentType.AGMMaterial]: "AGM Materials",
  [FundDataDocumentType.Other]: "Other",
};

export const documentTypeLabelsGql: Record<FundDataDocumentTypeGql, string> = {
  [FundDataDocumentTypeGql.FinancialStatement]: "Financial Statements",
  [FundDataDocumentTypeGql.CapitalAccount]: "Capital Account Statements",
  [FundDataDocumentTypeGql.OperatingReport]: "Operating Reports",
  [FundDataDocumentTypeGql.CapitalCallNotice]: "Capital Call Notices",
  [FundDataDocumentTypeGql.DistributionNotice]: "Distribution Notices",
  [FundDataDocumentTypeGql.AGMMaterial]: "AGM Materials",
  [FundDataDocumentTypeGql.Other]: "Other",
};

export const abbreviatedDocumentTypeLabels: Record<
  FundDataDocumentType,
  string
> = {
  [FundDataDocumentType.FinancialStatement]: "FS",
  [FundDataDocumentType.CapitalAccount]: "CAS",
  [FundDataDocumentType.OperatingReport]: "OR",
  [FundDataDocumentType.CapitalCallNotice]: "CN",
  [FundDataDocumentType.DistributionNotice]: "DN",
  [FundDataDocumentType.AGMMaterial]: "AGM",
  [FundDataDocumentType.Other]: "OTH",
};

export const abbreviatedDocumentTypeLabelsGql: Record<
  FundDataDocumentTypeGql,
  string
> = {
  [FundDataDocumentTypeGql.FinancialStatement]: "FS",
  [FundDataDocumentTypeGql.CapitalAccount]: "CAS",
  [FundDataDocumentTypeGql.OperatingReport]: "OR",
  [FundDataDocumentTypeGql.CapitalCallNotice]: "CN",
  [FundDataDocumentTypeGql.DistributionNotice]: "DN",
  [FundDataDocumentTypeGql.AGMMaterial]: "AGM",
  [FundDataDocumentTypeGql.Other]: "OTH",
};

export const documentTypeOptions = Object.entries(documentTypeLabels).map(
  ([value, label]) => ({
    label,
    value: parseInt(value),
  }),
);
