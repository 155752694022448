import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";

import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import Loader from "common/components/TapLoader";
import { PageHead } from "common/components/PageHead";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { useIOIsContext } from "../state/IOIContext";
import { Ask } from "experiences/indications-of-interest/domain/models/IOI";
import { CreateIOIFormOff, IOIsLoaded } from "../state/IOIState";
import {
  ArchiveIOIOptionPressed,
  CreateIOIFormTriggered,
  NewIOIFlowCloseRequested,
} from "../state/IOIEvent";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { AddInterestModal, IoiType } from "./AddInterestModal";
import { FUND_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";

const useSellList = () => {
  const navigate = useNavigate();
  const handleAddHoldingsClick = () => navigate(LP_ROUTES.PortfolioHoldings);
  const { emitEvent, asksState, createIOIFormState } = useIOIsContext();

  const handleNewAskClick = () => {
    emitEvent!(new CreateIOIFormTriggered());
  };

  const handleCloseNewAskModal = () => {
    emitEvent!(new NewIOIFlowCloseRequested());
  };

  const handleArchiveClick = (ask: Ask) => {
    emitEvent!(new ArchiveIOIOptionPressed({ ioiId: ask.id }));
  };

  const goToFundDetail = (fundId: string, e?: React.MouseEvent) => {
    // do not navigate if the user is clicking on the checkbox
    if (e?.isPropagationStopped() || e?.target !== e?.currentTarget) {
      return;
    }

    // TODO @jmz7v
    // Use https://reactrouter.com/en/main/utils/generate-path
    navigate(`${LP_ROUTES.FundsFundDetail.replace(":fundId", fundId)}`);
  };

  return {
    asksState,
    handleNewAskClick,
    handleCloseNewAskModal,
    handleArchiveClick,
    goToFundDetail,
    handleAddHoldingsClick,
    createIOIFormState,
  };
};

const sortIoisByManager = (iois: Ask[]) => {
  return iois.sort((a, b) => {
    if (a.managerName < b.managerName) {
      return -1;
    }
    if (a.managerName > b.managerName) {
      return 1;
    }
    return 0;
  });
};

export const Asks = () => {
  const {
    asksState,
    handleNewAskClick,
    handleCloseNewAskModal,
    handleArchiveClick,
    goToFundDetail,
    handleAddHoldingsClick,
    createIOIFormState,
  } = useSellList();

  const headActions = (
    <Stack direction={"row"} spacing={1}>
      <Button
        onClick={handleNewAskClick}
        icon={<PlusIcon />}
        type={BUTTON_TYPES.SECONDARY}
      >
        Add Sell Interest
      </Button>
    </Stack>
  );

  const sortedAsks =
    asksState instanceof IOIsLoaded
      ? sortIoisByManager(asksState.iois as Ask[])
      : [];

  return (
    <>
      <Helmet title="Sell List" />
      <PageHead
        title="Sell List"
        {...(asksState instanceof IOIsLoaded
          ? { hug: String(sortedAsks.length) }
          : {})}
        actions={headActions}
      />
      {asksState instanceof IOIsLoaded ? (
        <TapTable
          data={sortedAsks as Ask[]}
          groupBy={(ask: Ask) => ask.managerName}
          renderGroupHeader={(ask: Ask) => ask.managerName}
          emptyStateTitle="No active interests"
          emptyStateDescription="Add your LP portfolio holdings to receive interests, begin transactions, and access price estimates."
          emptyStateActions={[
            {
              label: "Add Sell Interest",
              onClick: handleAddHoldingsClick,
              type: BUTTON_TYPES.SECONDARY,
              icon: <PlusIcon />,
            },
          ]}
          columns={[
            {
              key: "fund",
              label: "Fund",
              align: TableAlignment.LEFT,
            },
            {
              key: "create_date",
              label: "Create Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "ref_date",
              label: "Ref Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "expiration_date",
              label: "Exp Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "bid",
              label: "Bid (% of NAV)",
              align: TableAlignment.LEFT,
            },
            {
              key: "min_nav",
              label: "Min NAV",
              align: TableAlignment.LEFT,
            },
            {
              key: "max_nav",
              label: "Max NAV",
              align: TableAlignment.LEFT,
            },
            {
              key: "actions",
              label: "",
              align: TableAlignment.LEFT,
            },
          ]}
          renderRow={(ask: Ask) => {
            const fundId = ask.fundId;

            const imgSrc = `${FUND_LOGO_URL}/${fundId}.jpg`;

            return (
              <>
                <TableCell
                  isPrimaryColumn
                  onClick={(e) => goToFundDetail(fundId, e)}
                >
                  <TableFundLogo imgSrc={imgSrc} fundName={ask.issuerName} />
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {new DateFormatter(ask.createdAt).humanDate()}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {ask.referenceDate
                    ? new DateFormatter(ask.referenceDate).quarterFormatted()
                    : NOT_AVAILABLE_STR}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {ask.expirationDate
                    ? new DateFormatter(ask.expirationDate).quarterFormatted()
                    : NOT_AVAILABLE_STR}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {(Boolean(ask.minPrice) && `${ask.minPrice}%`) || null}
                  {(Boolean(ask.minPrice) && Boolean(ask.maxPrice) && " - ") ||
                    null}
                  {(Boolean(ask.maxPrice) && `${ask.maxPrice}%`) || null}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {ask.minAmount
                    ? new DollarAmount(ask.minAmount).formatted()
                    : "-"}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {new DollarAmount(ask.maxAmount).formatted()}
                </TableCell>
                <TableCell hasActions>
                  <Button onClick={() => handleArchiveClick(ask)}>
                    Archive
                  </Button>
                </TableCell>
              </>
            );
          }}
        />
      ) : (
        <Loader />
      )}
      <AddInterestModal
        open={!(createIOIFormState instanceof CreateIOIFormOff)}
        onClose={handleCloseNewAskModal}
        type={IoiType.Ask}
        onFormSubmitted={handleCloseNewAskModal}
      />
    </>
  );
};
