import { useCallback, useEffect } from "react";

const KEYBOARD_SHORTCUTS = {
  toggleTheme: { key: "k" },
  globalSearch: { key: "k", requiresMetaKey: true },
};

export const useKeyboardShortcuts = ({
  openGlobalSearch,
  handleDownKey,
  handleUpKey,
}: {
  openGlobalSearch?: () => void;
  handleDownKey?: () => void;
  handleUpKey?: () => void;
}) => {
  // handle what happens on key press

  const onGlobalSearchTrigger = useCallback(() => {
    openGlobalSearch();
  }, []);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    // global search
    if (event.key === KEYBOARD_SHORTCUTS.globalSearch.key) {
      if (KEYBOARD_SHORTCUTS.globalSearch.requiresMetaKey && event.metaKey) {
        onGlobalSearchTrigger && onGlobalSearchTrigger();
      }
    }
    // down key
    if (event.key === "ArrowDown") {
      if (handleDownKey) {
        handleDownKey();
      }
    }
    // up key
    if (event.key === "ArrowUp") {
      if (handleUpKey) {
        handleUpKey();
      }
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return {
    onGlobalSearchTrigger,
  };
};
