export const SHOW_NEW_MENU = true;
export const SUPPORT_EMAIL = "team@usetap.com";
export const ADAM_TRANSACTION_EMAIL = "adam@usetap.com";
export const APP_BAR_FUND_NAME_NODE = "fund_name";
export const APP_BAR_TRANSACTION_NAME_NODE = "transaction_name";
export const APP_BAR_COMPANY_NAME_NODE = "company_name";
export const IconTap = "/images/ui/tap-menu-icon-logo.svg";

// adding icons here, export to icons section later
export const TableBullet = "/images/ui/table-bullet.svg";
export const TablePin = "/images/ui/table-pin.svg";
