import { Navigate, Route, Routes } from "react-router";
import { Loader } from "lucide-react";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { FundsStateProvider } from "experiences/funds/presentation/state/FundsContext";
import { PortfolioStateProvider } from "experiences/portfolio/presentation/state/PortfolioContext";
import { TransactionsStateProvider } from "experiences/transactions/presentation/state/TransactionsContext";
import { DocumentsStateProvider } from "experiences/documents/presentation/state/DocumentsContext";

// funds
import { FundTabsBelt } from "experiences/funds/presentation/components/FundTabsBelt";
import { AlertsTable } from "experiences/funds/presentation/pages/Alerts";
import Funds from "experiences/funds/presentation/pages/Funds";

// transactions
import { ParticipantsScreen } from "experiences/transactions/presentation/components/participants/ParticipantsScreen";
import { TransactionDetails } from "experiences/transactions/presentation/components/TransactionDetails";
import {
  ActiveTransactions,
  PendingTransactions,
} from "experiences/transactions/presentation/components/Transactions";
import { InitiateMarkup } from "experiences/transactions/presentation/components/ndas/InitiateMarkup";

// portfolio
import { SellHoldingFlow } from "experiences/portfolio/presentation/pages/SellHoldingFlow";
import { PortfolioHoldings } from "experiences/portfolio/presentation/pages/PortfolioHoldings";

// indications-of-interest
import { Bids } from "experiences/indications-of-interest/presentation/pages/Bids";
import { Asks } from "experiences/indications-of-interest/presentation/pages/Asks";
import { Archived } from "experiences/indications-of-interest/presentation/pages/Archived";

import { ContentWrapper } from "common/components/WidthWrapper";
import { RequireAuth } from "experiences/authentication/presentation/components/RequireAuth";

import { SHOW_NEW_MENU } from "common/constants/assets";
import { IOIsStateProvider } from "experiences/indications-of-interest/presentation/state/IOIContext";
import ConnectionReview from "experiences/portfolio/presentation/pages/ConnectionReview";
import ConnectPortfolio from "experiences/portfolio/presentation/pages/ConnectPortfolio";
import { IoiBulkUpload } from "experiences/indications-of-interest/presentation/pages/IoiBulkUploadPage";
import VerifyPermissionFlow from "experiences/funds/presentation/components/verify-permission/VerifyPermissionFlow";
import { HoldingsBulkUpload } from "experiences/portfolio/presentation/pages/HoldingsBulkUpload";
import { ViewFund } from "experiences/funds/presentation/pages/ViewFund";
import SignNDAFlow from "experiences/documents/presentation/pages/SignNDAFlow";
import { DashboardStateProvider } from "experiences/dashboard/presentation/state/DashboardContext";
import { UploadCapitalAccounts } from "experiences/portfolio/presentation/pages/UploadCapitalAccounts";
import { LP_ROUTES } from "common/constants/routes";

import AppLayout from "./AppLayout";
import { UniversalRoutes } from "./UniversalRoutes";

import { TenderDetails } from "experiences/transactions/presentation/tenders/TenderDetails";
import { ActiveIndicationsOfInterest } from "experiences/dashboard/presentation/pages/ActiveIndicationsOfInterest";
import { LpDashboard } from "experiences/dashboard/presentation/pages/LpDashboard";
import { SettingsPage } from "experiences/authentication/presentation/pages/Settings";
import { CreateTransaction } from "experiences/transactions/presentation/components/CreateTransaction";
import { PortfolioPageV2 } from "experiences/portfolio-v2/presentation/pages/PortfolioPageV2";
import { CompanyDetail } from "experiences/portfolio-v2/presentation/pages/CompanyDetail";

export const LPRoutes = () => {
  const { userIsLoading } = useAuthContext();

  if (userIsLoading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path={`${LP_ROUTES.DashboardNewIois}/*`}
          element={
            <RequireAuth>
              <DashboardStateProvider>
                <ActiveIndicationsOfInterest />
              </DashboardStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.Dashboard}/*`}
          element={
            <RequireAuth>
              <DashboardStateProvider>
                <TransactionsStateProvider>
                  <PortfolioStateProvider>
                    <DocumentsStateProvider>
                      <IOIsStateProvider>
                        <FundsStateProvider>
                          <ContentWrapper fullWidth>
                            <LpDashboard />
                          </ContentWrapper>
                        </FundsStateProvider>
                      </IOIsStateProvider>
                    </DocumentsStateProvider>
                  </PortfolioStateProvider>
                </TransactionsStateProvider>
              </DashboardStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.TenderDetails}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TenderDetails />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.FundsScreen}/*`}
          element={
            <RequireAuth>
              <FundsStateProvider>
                {SHOW_NEW_MENU ? (
                  <ContentWrapper fullWidth>
                    <Funds />
                  </ContentWrapper>
                ) : (
                  <ContentWrapper>
                    <FundTabsBelt />
                    <Funds />
                  </ContentWrapper>
                )}
              </FundsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.FundsAlerts}/*`}
          element={
            <RequireAuth>
              <FundsStateProvider>
                <ContentWrapper fullWidth>
                  <AlertsTable />
                </ContentWrapper>
              </FundsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterestBids}/*`}
          element={
            <RequireAuth>
              <IOIsStateProvider>
                <ContentWrapper fullWidth>
                  <Bids />
                </ContentWrapper>
              </IOIsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterestAsks}/*`}
          element={
            <RequireAuth>
              <IOIsStateProvider>
                <ContentWrapper fullWidth>
                  <Asks />
                </ContentWrapper>
              </IOIsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterestArchive}/*`}
          element={
            <RequireAuth>
              <IOIsStateProvider>
                <ContentWrapper fullWidth>
                  <Archived />
                </ContentWrapper>
              </IOIsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioConnectPortfolio}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <ContentWrapper fullWidth>
                  <ConnectPortfolio />
                </ContentWrapper>
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioHoldingsUpload}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <IOIsStateProvider>
                  <ContentWrapper fullWidth>
                    <HoldingsBulkUpload />
                  </ContentWrapper>
                </IOIsStateProvider>
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioUploadCapitalAccounts}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <IOIsStateProvider>
                  <ContentWrapper fullWidth>
                    <UploadCapitalAccounts />
                  </ContentWrapper>
                </IOIsStateProvider>
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioPortalConnectionReview}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <ContentWrapper fullWidth>
                  <ConnectionReview />
                </ContentWrapper>
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterestUpload}/*`}
          element={
            <RequireAuth>
              <ContentWrapper fullWidth>
                <IOIsStateProvider>
                  <IoiBulkUpload />
                </IOIsStateProvider>
              </ContentWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterestAsks}/*`}
          element={
            <RequireAuth>
              <ContentWrapper fullWidth>Asks</ContentWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterestArchive}/*`}
          element={
            <RequireAuth>
              <ContentWrapper fullWidth>Archive</ContentWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterest}/*`}
          element={
            <Navigate to={LP_ROUTES.IndicationsOfInterestBids} replace />
          }
        />
        <Route
          path={`${LP_ROUTES.Funds}/*`}
          element={<Navigate to={LP_ROUTES.FundsScreen} replace />}
        />
        <Route
          path={`${LP_ROUTES.PortfolioSellHoldings}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <SellHoldingFlow />
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioHoldings}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <PortfolioHoldings />
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioEntrypoint}/*`}
          element={
            <RequireAuth>
              <PortfolioPageV2 />
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.Portfolio}/*`}
          element={<Navigate to={LP_ROUTES.PortfolioHoldings} replace />}
        />
        <Route
          path={`${LP_ROUTES.TransactionsManageAccess}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ParticipantsScreen />
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionsInitiateMarkup}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <InitiateMarkup />
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionDetails}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TransactionDetails />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionsCreate}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <PortfolioStateProvider>
                  <ContentWrapper fullWidth>
                    <CreateTransaction />
                  </ContentWrapper>
                </PortfolioStateProvider>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionsActive}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <ActiveTransactions />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionsPending}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <PendingTransactions />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.Transactions}/*`}
          element={<Navigate to={LP_ROUTES.TransactionsActive} replace />}
        />
        <Route
          path={`${LP_ROUTES.CompanyDetail}/*`}
          element={<CompanyDetail />}
        />
        <Route
          path={`${LP_ROUTES.Settings}/*`}
          element={
            <RequireAuth>
              <DocumentsStateProvider>
                <ContentWrapper fullWidth>
                  <SettingsPage />
                </ContentWrapper>
              </DocumentsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.Root}*`}
          element={<Navigate to={LP_ROUTES.Dashboard} replace />}
        />

        <Route
          path={`${LP_ROUTES.DocumentsSignNDA}/*`}
          element={
            <RequireAuth>
              <DocumentsStateProvider>
                <ContentWrapper fullWidth>
                  <SignNDAFlow />
                </ContentWrapper>
              </DocumentsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.FundsFundDetail}/*`}
          element={
            <RequireAuth>
              <DashboardStateProvider>
                <FundsStateProvider>
                  <IOIsStateProvider>
                    <ContentWrapper fullWidth>
                      <ViewFund />
                    </ContentWrapper>
                  </IOIsStateProvider>
                </FundsStateProvider>
              </DashboardStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${LP_ROUTES.VerifyFundPermissions}/*`}
          element={
            <RequireAuth>
              <FundsStateProvider>
                <PortfolioStateProvider>
                  <ContentWrapper fullWidth>
                    <VerifyPermissionFlow />
                  </ContentWrapper>
                </PortfolioStateProvider>
              </FundsStateProvider>
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<UniversalRoutes />} />
    </Routes>
  );
};
