import { classNames } from "primereact/utils";

import { IoiType } from "experiences/indications-of-interest/presentation/pages/AddInterestModal";
import { BidRankType } from "experiences/funds/domain/models/FundSummary";
import { StyledList } from "experiences/common/List";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { EmptyState } from "common/components/EmptyState";
import { NoUserBids } from "./bidRank/NoUserBids";
import { InsufficientData } from "./bidRank/InsufficientData";
import { BidRank } from "./bidRank/BidRank";
import {
  BidRankState,
  Locked,
  CompleteBidRankState,
  InsufficientDataState,
  NoUserBidState,
  PartialUserBidState,
  EmptyBidState,
} from "../state/BidState";
import { FundVerifyPermissionOverlay } from "./VerifyPermissionOverlay";

type BuyInterestProps = {
  hasNonExpiredBids: boolean;
  Bids: JSX.Element[];
  bidState: BidRankState;
  bidRank: BidRankType;
  toggleSubmitIoiModal: (type: IoiType) => void;
  fundId: string;
  canViewBuyIois: boolean;
};

export const IoiBuyInterestList = ({
  hasNonExpiredBids,
  Bids,
  bidState,
  bidRank,
  toggleSubmitIoiModal,
  fundId,
  canViewBuyIois,
}: BuyInterestProps) => {
  return (
    <>
      <PageSectionTitleDivider showBorderTop>
        Buy Interest
      </PageSectionTitleDivider>
      <StyledList
        className={classNames({ locked: !canViewBuyIois })}
        style={{ position: "relative" }}
      >
        <table className="dashboard_table">
          <thead>
            <tr className={`${bidState instanceof Locked ? "locked" : ""}`}>
              <th></th>

              <th style={{ textAlign: "center" }}>Price (% of NAV)</th>
              <th style={{ width: "33%" }}>Check Size</th>
              <th>Reference Date</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {hasNonExpiredBids ? Bids : null}
            {bidState instanceof InsufficientDataState ? (
              <tr className="dont_hover" style={{ height: 300 }}>
                <td colSpan={5}>
                  <InsufficientData />
                </td>
              </tr>
            ) : bidState instanceof PartialUserBidState ? (
              <tr className="dont_hover" style={{ height: 300 }}>
                <td colSpan={5}>
                  <InsufficientData />
                </td>
              </tr>
            ) : bidState instanceof CompleteBidRankState ? (
              <tr className="dont_hover" style={{ height: 220 }}>
                <td colSpan={5}>
                  <BidRank
                    lowerRange={bidRank?.bidRange?.start}
                    higherRange={bidRank?.bidRange?.end}
                    bidDelta={bidRank?.bidDelta}
                    bidCategory={bidRank?.bidCategory}
                  />
                </td>
              </tr>
            ) : bidState instanceof NoUserBidState ? (
              <tr className="dont_hover" style={{ height: 320 }}>
                <td colSpan={5}>
                  <NoUserBids toggleSubmitIoiModal={toggleSubmitIoiModal} />
                </td>
              </tr>
            ) : bidState instanceof EmptyBidState ? (
              <tr>
                <td colSpan={5}>
                  <EmptyState title="There are no Buy Interests available, yet." />
                </td>
              </tr>
            ) : bidState instanceof Locked ? (
              <tr className="dont_hover" style={{ height: 300 }}>
                <td colSpan={5}>
                  <FundVerifyPermissionOverlay fundId={fundId} />
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </StyledList>
    </>
  );
};
