import { useFormik } from "formik";
import * as yup from "yup";

import { usePortfolioContext } from "../../state/PortfolioContext";
import { BUTTON_TYPES, Button } from "common/components/Button";
import FormikDatePicker from "common/components/formik/FormikDatePicker";
import styled from "styled-components";
import { PageHead } from "common/components/PageHead";
import FormikCheckbox from "common/components/formik/FormikCheckbox";
import { Stack } from "@mui/material";

import {
  DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY,
  FIRST_ROUND_BID_EVALUATION_DATE_KEY,
  FIRST_ROUND_DUE_DILIGENCE_DATE_KEY,
  SECOND_ROUND_BID_EVALUATION_DATE_KEY,
  SECOND_ROUND_DUE_DILIGENCE_DATE_KEY,
  TARGET_CLOSE_DATE_KEY,
  TRANSACTION_PREPARATION_START_DATE_KEY,
} from "experiences/portfolio/presentation/components/sell/TimelineKeys";
import { SetTimelineScreenContinueClicked } from "../../state/PortfolioEvent";

const StyledSetTransactionTimeline = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  align-items: stretch;

  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    width: 100%;

    .MuiFormControl-root + .MuiFormControl-root {
      margin-top: 32px;
    }
  }

  .left-side {
    padding-right: 32px;
  }

  .right-side {
    border-left: 1px solid var(--branding-keyline-default, #dfdfd9);
    padding-left: 32px;
  }
`;

const TOTAL_MODAL_HEADER_HEIGHT_PX = 64 + 56;

const TransactionTimelineWrapper = styled.div`
  display: flex:
  flex-direction: column;
  position: relative;
  width: 100%;
  height: calc(100vh - ${TOTAL_MODAL_HEADER_HEIGHT_PX}px);
  box-sizing: border-box;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
    box-sizing: border-box;
  }

  .actions-bar {
    display: flex;
    margin-top: 48px;
    flex-direction: row;
    justify-content: flex-end;
    flex-basis: 64px;
  }
`;

export const SetTimelineStep = ({}: {} = {}) => {
  const { emitEvent } = usePortfolioContext();

  const validationSchema = yup.object({
    [TRANSACTION_PREPARATION_START_DATE_KEY]: yup.date().required("Required"),
    [FIRST_ROUND_DUE_DILIGENCE_DATE_KEY]: yup.date().required("Required"),
    [FIRST_ROUND_BID_EVALUATION_DATE_KEY]: yup.date().required("Required"),
    [SECOND_ROUND_DUE_DILIGENCE_DATE_KEY]: yup.date(),
    [SECOND_ROUND_BID_EVALUATION_DATE_KEY]: yup.date(),
    [DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY]: yup
      .date()
      .required("Required"),
    [TARGET_CLOSE_DATE_KEY]: yup.date().required("Required"),
  });

  const currentDate = new Date().toISOString().slice(0, 10);

  const form = useFormik({
    initialValues: {
      enableSecondRound: true,
      [TRANSACTION_PREPARATION_START_DATE_KEY]: "",
      [FIRST_ROUND_DUE_DILIGENCE_DATE_KEY]: "",
      [FIRST_ROUND_BID_EVALUATION_DATE_KEY]: "",
      [SECOND_ROUND_DUE_DILIGENCE_DATE_KEY]: "",
      [SECOND_ROUND_BID_EVALUATION_DATE_KEY]: "",
      [DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY]: "",
      [TARGET_CLOSE_DATE_KEY]: "",
    },
    validationSchema,
    onSubmit: (values) => {
      let txnPrepStartDate =
        values[TRANSACTION_PREPARATION_START_DATE_KEY].toISOString().slice(
          0,
          10,
        ) ?? undefined;

      console.log(txnPrepStartDate);
      let firstRoundDueDiligenceDate =
        values[FIRST_ROUND_DUE_DILIGENCE_DATE_KEY].toISOString().slice(0, 10) ??
        undefined;
      let firstRoundBidEvaluationDate =
        values[FIRST_ROUND_BID_EVALUATION_DATE_KEY].toISOString().slice(
          0,
          10,
        ) ?? undefined;
      let secondRoundDueDiligenceDate =
        values[SECOND_ROUND_DUE_DILIGENCE_DATE_KEY].toISOString().slice(
          0,
          10,
        ) ?? undefined;
      let secondRoundBidEvaluationDate =
        values[SECOND_ROUND_BID_EVALUATION_DATE_KEY].toISOString().slice(
          0,
          10,
        ) ?? undefined;
      let documentationAndTransactionClosingDate =
        values[
          DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY
        ].toISOString().slice(0, 10) ?? undefined;
      let targetCloseDate =
        values[TARGET_CLOSE_DATE_KEY]?.toISOString().slice(0, 10) ?? undefined;
      emitEvent(
        new SetTimelineScreenContinueClicked({
          [TRANSACTION_PREPARATION_START_DATE_KEY]: {
            start: currentDate,
            end: txnPrepStartDate,
          },
          [FIRST_ROUND_DUE_DILIGENCE_DATE_KEY]: {
            start: txnPrepStartDate,
            end: firstRoundDueDiligenceDate,
          },
          [FIRST_ROUND_BID_EVALUATION_DATE_KEY]: {
            start: firstRoundDueDiligenceDate,
            end: firstRoundBidEvaluationDate,
          },
          [SECOND_ROUND_DUE_DILIGENCE_DATE_KEY]: {
            start: firstRoundBidEvaluationDate,
            end: secondRoundDueDiligenceDate,
          },
          [SECOND_ROUND_BID_EVALUATION_DATE_KEY]: {
            start: secondRoundDueDiligenceDate,
            end: secondRoundBidEvaluationDate,
          },
          [DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY]: {
            start: secondRoundBidEvaluationDate,
            end: documentationAndTransactionClosingDate,
          },
          [TARGET_CLOSE_DATE_KEY]: {
            start: documentationAndTransactionClosingDate,
            end: targetCloseDate,
          },
        }),
      );
    },
  });

  return (
    <TransactionTimelineWrapper>
      <div className="main-content">
        <form
          onSubmit={form.handleSubmit}
          className="max-w-screen-lg mx-auto w-full h-full pt-24 box-border"
        >
          <PageHead
            title="Set Transaction Timeline"
            description="Configure the key dates for this transaction. They will be visible to transaction participants."
            paddingless
            descriptionStyle={{ marginBottom: 36 }}
          />

          <StyledSetTransactionTimeline>
            <div className="left-side"></div>
            <div className="right-side">
              <Stack mb={2}>
                <FormikCheckbox
                  name="enableSecondRound"
                  label="Enable Second Round (optional)"
                  formik={form}
                />
              </Stack>
            </div>
          </StyledSetTransactionTimeline>
          <StyledSetTransactionTimeline>
            <div className="left-side">
              <FormikDatePicker
                formik={form}
                inputFormat="MM/dd/yyyy"
                name={TRANSACTION_PREPARATION_START_DATE_KEY}
                label="Start Date for Transaction Preparation"
              />
              <FormikDatePicker
                formik={form}
                inputFormat="MM/dd/yyyy"
                name={FIRST_ROUND_DUE_DILIGENCE_DATE_KEY}
                label="Round 1 Due Diligence"
              />
              <FormikDatePicker
                formik={form}
                inputFormat="MM/dd/yyyy"
                name={FIRST_ROUND_BID_EVALUATION_DATE_KEY}
                label="Round 1 Bid Evaluation"
              />
            </div>
            <div className="right-side">
              {form.values.enableSecondRound && (
                <FormikDatePicker
                  formik={form}
                  inputFormat="MM/dd/yyyy"
                  name={SECOND_ROUND_DUE_DILIGENCE_DATE_KEY}
                  label="Round 2 Due Diligence (optional)"
                />
              )}

              {form.values.enableSecondRound && (
                <FormikDatePicker
                  formik={form}
                  inputFormat="MM/dd/yyyy"
                  name={SECOND_ROUND_BID_EVALUATION_DATE_KEY}
                  label="Round 2 Bid Evaluation (optional)"
                />
              )}
              <FormikDatePicker
                formik={form}
                inputFormat="MM/dd/yyyy"
                name={DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY}
                label="Documentation and Transaction Closing Date"
              />
              <FormikDatePicker
                formik={form}
                inputFormat="MM/dd/yyyy"
                name={TARGET_CLOSE_DATE_KEY}
                label="Target Closing Date"
              />
            </div>
          </StyledSetTransactionTimeline>
        </form>
      </div>
      <div className="actions-bar max-w-screen-lg mx-auto">
        <Button
          type={BUTTON_TYPES.SECONDARY}
          onClick={() => form.handleSubmit()}
          size="large"
        >
          Continue
        </Button>
      </div>
    </TransactionTimelineWrapper>
  );
};

export default SetTimelineStep;
