import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase, NoReturn } from "common/@types/app/UseCase";

interface IParams {
  tenderAmount: number;
  txnId: string;
}

export class FinalizeParticipation implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    txnId,
    ...form
  }) => {
    return await new TapClient(
      `/transactions/tenders/${txnId}/participant-offer/`,
      form,
    )
      .post()
      .then((_) => right(new NoReturn()))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}
