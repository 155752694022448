import styled from "styled-components";

import { ApprovedBuyer } from "experiences/approved-buyers/domain/models/ApprovedBuyer";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";

export const StyledFundNameAndImage = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 1em;
    width: 32px;
    height: 32px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--branding-keyline-default, #dfdfd9);
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bcb3a5;
    flex-shrink: 0;
  }
`;

export const ApprovedBuyersTable = ({
  approvedBuyers,
}: {
  approvedBuyers: ApprovedBuyer[];
  searchQuery?: string;
}) => {
  return (
    <>
      <TapTable
        data={approvedBuyers}
        emptyStateTitle={
          !approvedBuyers.length ? "There are no buyers to display." : null
        }
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "type",
            label: "Type",
            align: TableAlignment.LEFT,
          },
          {
            key: "checkSize",
            label: "Check Size",
            align: TableAlignment.LEFT,
          },
          {
            key: "stapleRation",
            label: "Staple",
            align: TableAlignment.LEFT,
          },
          {
            key: "informationRights",
            label: "Information Rights",
            align: TableAlignment.LEFT,
          },
          {
            key: "txnsCount",
            label: "Transactions",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(approvedBuyer: ApprovedBuyer) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                <StyledFundNameAndImage>
                  <img
                    src={`https://cdn.usetap.com/funds/logos/${approvedBuyer.logoFileName}`}
                    alt="Fund logo"
                  />{" "}
                  {approvedBuyer.name}
                </StyledFundNameAndImage>
              </TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    flexDirection: "column",
                    maxWidth: 400,
                  }}
                >
                  {approvedBuyer.fundScope}
                </div>
              </TableCell>
              <TableCell>{approvedBuyer.type}</TableCell>
              <TableCell>{approvedBuyer.checkSize}</TableCell>
              <TableCell>
                <TextChip
                  text={approvedBuyer.staple}
                  pending={approvedBuyer.staple.toLocaleLowerCase() === "no"}
                  success={approvedBuyer.staple.toLocaleLowerCase() === "yes"}
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>
                <TextChip
                  text={approvedBuyer.informationRights}
                  pending={
                    approvedBuyer.informationRights.toLocaleLowerCase() ===
                    "partial"
                  }
                  success={
                    approvedBuyer.informationRights.toLocaleLowerCase() ===
                    "yes"
                  }
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>{approvedBuyer.txnsCount}</TableCell>
            </>
          );
        }}
      />
    </>
  );
};
