import React from "react";
import { Theme } from "@mui/material/styles";
import { Box, SvgIcon, SvgIconProps } from "@mui/material";

// ----------------------------------------------------------------------

function Icon(props: SvgIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="2.5"
        fill="white"
        stroke="#DBDCDA"
      />
    </svg>
  );
}

function CheckedIcon(props: SvgIconProps) {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="3" fill="#BCB3A5" />
      <path
        d="M11.6954 5.36039C11.8805 5.15543 11.8805 4.82313 11.6954 4.61818C11.5103 4.41322 11.2103 4.41322 11.0252 4.61818L6.34602 9.80041L4.7865 8.07323C4.60144 7.86828 4.3014 7.86828 4.11634 8.07323C3.93127 8.27819 3.93127 8.61049 4.11634 8.81545L5.98067 10.8802C6.0884 10.9995 6.23509 11.0494 6.37538 11.0298C6.4982 11.0311 6.62139 10.9799 6.7151 10.8761L11.6954 5.36039Z"
        fill="white"
      />
    </svg>
  );
}

function IndeterminateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17 3a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7a4 4 0 014-4h10zm-1.75 8h-6.5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75h6.5a.75.75 0 00.75-.75v-.5a.75.75 0 00-.75-.75z" />
    </SvgIcon>
  );
}

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <Icon />,
        checkedIcon: <CheckedIcon />,
        indeterminateIcon: <IndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          "&.Mui-checked.Mui-disabled, &.Mui-disabled": {
            color: theme.palette.action.disabled,
          },
          "& .MuiSvgIcon-fontSizeMedium": {
            width: 24,
            height: 24,
          },
          "& .MuiSvgIcon-fontSizeSmall": {
            width: 20,
            height: 20,
          },
        },
      },
    },
  };
}
