import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

import TextField from "../TextField";

const PopperContainer = styled.div`
  z-index: 3;
  height: 0;
  position: absolute;

  width: 320px;

  &:empty {
    display: none;
  }
`;

export const getLastQuarter = (fromDate: Date = new Date()): Date => {
  const month = fromDate.getMonth();
  const year = fromDate.getFullYear();
  let lastDay: Date;

  if (month >= 9) {
    lastDay = new Date(year, 11, 31);
  } else if (month >= 6) {
    lastDay = new Date(year, 8, 30);
  } else if (month >= 3) {
    lastDay = new Date(year, 5, 30);
  } else {
    lastDay = new Date(year, 2, 31);
  }

  lastDay.setHours(0, 0, 0, 0);
  return lastDay;
};

export const FormikQuarterPicker = ({
  formik,
  name,
  label,
  inputProps,
  defaultNow = false,
  ...props
}) => {
  const [startDate, setStartDate] = useState<Date>();

  useEffect(() => {
    if (defaultNow) setStartDate(getLastQuarter());
  }, []);

  useEffect(() => {
    formik.setFieldValue(
      name,
      startDate
        ?.toISOString()
        .substring(0, startDate?.toISOString().indexOf("T")),
    );
  }, [startDate]);

  return (
    <DatePicker
      {...props}
      selected={startDate}
      onChange={(date) => {
        // @ts-ignore
        if (date instanceof Date && !Number.isNaN(date.valueOf())) {
          const dateString = date.toISOString().slice(0, 10);
          setStartDate(getLastQuarter(date));
        }
      }}
      dateFormat="QQQ yyyy"
      customInput={<TextField label={label} {...inputProps} />}
      popperContainer={PopperContainer}
      showQuarterYearPicker
      showPopperArrow={false}
    />
  );
};
