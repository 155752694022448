import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { Percentage } from "common/@types/app/Percentage";
import { PageHead } from "common/components/PageHead";
import Loader from "common/components/TapLoader";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import React from "react";
import { useParams } from "react-router";
import { Button } from "shadcn/ui/Button";
import { FinalizeParticipationFlowSubmitted } from "../state/tenders/TendersEvent";
import {
  FinalizeElectionFlowLoaded,
  FinalizeElectionFlowLoading,
} from "../state/tenders/TendersState";
import { useTransactionsContext } from "../state/TransactionsContext";
import TenderSimulator from "./TenderSimulator";

const useStyles = makeStyles((_) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

interface IReviewTransactionProps {
  tenderAmount: number;
  reservePrice: number;
  closeDate: string;
  referenceDate: string;
  holdingFundName: string;
}

const ReviewTransaction: React.FC<IReviewTransactionProps> = ({
  tenderAmount,
  reservePrice,
  closeDate,
  referenceDate,
  holdingFundName,
}) => {
  return (
    <div className="flex flex-col w-3/6 p-4 gap-12">
      <div>
        <PageHead
          paddingless
          title={"Review Transaction"}
          description={
            "Give the details of your transaction a final review before signing and submitting your participation in the tender offer."
          }
        />
      </div>
      <div className="flex flex-col divide-y divide-nomad-100">
        <div className="flex flex-row justify-between p-4">
          <span className="text-sm text-stone-500">Tendered Fund</span>
          <span>{holdingFundName}</span>
        </div>
        <div className="flex flex-row justify-between p-4">
          <span className="text-sm text-stone-500">Target Close Date</span>
          <span>{closeDate}</span>
        </div>
        <div className="flex flex-row justify-between p-4">
          <span className="text-sm text-stone-500">Reference Date</span>
          <span>{referenceDate}</span>
        </div>
        <div className="flex flex-row justify-between p-4">
          <span className="text-sm text-stone-500">Price (% of NAV)</span>
          <span>{new Percentage(reservePrice * 100).formatted()}</span>
        </div>
        <div className="flex flex-row justify-between p-4">
          <span className="text-sm text-stone-500">Commitment Tendered</span>
          <span>{new DollarAmount(tenderAmount).formatted()}</span>
        </div>
        <div className="flex flex-row justify-between p-4">
          <span className="text-sm text-stone-500">Target Proceeds</span>
          <span>
            {new DollarAmount(tenderAmount * reservePrice).formatted()}
          </span>
        </div>
      </div>
    </div>
  );
};

enum ActiveStep {
  EnterAmount = 0,
  ReviewTransaction = 1,
  SignLOI = 2,
}

interface IProps {
  onClose: () => void;
  open: boolean;
}

export const FinalizeElection: React.FC<IProps> = ({ onClose, open }) => {
  const [activeStep, setActiveStep] = React.useState<ActiveStep>(
    ActiveStep.EnterAmount,
  );
  const { txnId } = useParams();
  const { tender } = useTransactionsContext();
  const { emitEvent, finalizeElectionFlowState } = tender;
  const [tenderAmount, setTenderAmount] = React.useState<number>(() => {
    if (finalizeElectionFlowState instanceof FinalizeElectionFlowLoaded)
      return (
        finalizeElectionFlowState.tenderAmount ??
        finalizeElectionFlowState.holdingsProfile.cavBefore
      );
  });
  const [reservePrice, setReservePrice] = React.useState<number>(
    finalizeElectionFlowState instanceof FinalizeElectionFlowLoaded
      ? finalizeElectionFlowState.offerPrice
      : 100,
  );
  const classes = useStyles();

  const buildStepChild = () => {
    if (finalizeElectionFlowState instanceof FinalizeElectionFlowLoaded) {
      let agg = finalizeElectionFlowState.holdingsProfile;
      return (
        <>
          {activeStep === ActiveStep.ReviewTransaction ? (
            <ReviewTransaction
              closeDate={finalizeElectionFlowState.targetCloseDate}
              referenceDate={finalizeElectionFlowState.referenceDate}
              holdingFundName={finalizeElectionFlowState.holdingFundName}
              tenderAmount={tenderAmount}
              reservePrice={reservePrice}
            />
          ) : (
            // : activeStep === ActiveStep.SignLOI ? (
            //   // <SignLOI loiEmbedLink={tender.loiEmbedLink} />
            // )
            <TenderSimulator
              tenderAmount={tenderAmount}
              setTenderAmount={setTenderAmount}
              initialReservePrice={reservePrice}
              setReservePrice={setReservePrice}
              interimCapitalCalls={agg.interimCapitalCalls}
              interimDistributions={agg.interimDistributions}
              commitedBefore={agg.commitedBefore}
              unfundedBefore={agg.unfundedBefore}
              cavBefore={agg.cavBefore}
              title={"Enter Tender Amount"}
              description="Enter the amount that you want to commit to selling in this tender offer."
            />
          )}

          <div className="flex flex-row-reverse w-full p-12 gap-4">
            {activeStep === ActiveStep.ReviewTransaction ? (
              <Button
                className="font-bold p-2"
                onClick={() => {
                  setActiveStep(ActiveStep.SignLOI);
                }}
              >
                Continue to Sign
              </Button>
            ) : activeStep === ActiveStep.EnterAmount ? (
              <Button
                className="font-bold p-2"
                onClick={() => {
                  setActiveStep(ActiveStep.ReviewTransaction);
                }}
              >
                Review
              </Button>
            ) : (
              <Button
                className="font-bold p-2"
                onClick={() => {
                  emitEvent(
                    new FinalizeParticipationFlowSubmitted({
                      txnId,
                      tenderAmount,
                    }),
                  );
                  onClose();
                }}
              >
                Finish
              </Button>
            )}
            {activeStep === ActiveStep.EnterAmount && (
              <Button
                className="p-2 border-2 border-stone-200 text-black bg-white"
                variant="secondary"
                onClick={onClose}
              >
                Decline Participation
              </Button>
            )}
          </div>
        </>
      );
    }
  };

  if (finalizeElectionFlowState instanceof FinalizeElectionFlowLoading) {
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      return <Loader />;
    </Drawer>;
  }

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <StepperFlow
        onClose={onClose}
        steps={[{ name: "Enter Amount" }, { name: "Review Transaction" }]}
        name={"Finalize Participation"}
        activeStepIndex={activeStep}
      >
        {buildStepChild()}
      </StepperFlow>
    </Drawer>
  );
};
