import { gql } from "@apollo/client";

export const GET_TRANSACTION_FUND_DATA_DOCUMENTS = gql`
  query Query($transactionId: String!) {
    documentsInformation(transactionId: $transactionId) {
      transactionDocumentId
      fileId
      fileUrl
      fileName
      fileSize
      reportId
      holdingId
      fundId
      fundName
      managerId
      managerName
      reportDate
      reportType
      documentProcessed
    }
  }
`;
