import { Either, right } from "fp-ts/lib/Either";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import { TransactionNDA } from "../models/TransactionNDA";
import { TransactionNDAMock } from "./transactionNDAMock";

interface IParams {}

export class GetTransactionNDAs
  implements IAsyncUseCase<IParams, TransactionNDA[]>
{
  call: (params: IParams) => Promise<Either<Failure, TransactionNDA[]>> =
    async (): Promise<Either<Failure, TransactionNDA[]>> => {
      return right([...TransactionNDAMock]);
    };
}
