import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageHead } from "common/components/PageHead";
import { EmptyState } from "common/components/EmptyState";
import { useTransactionsContext } from "../state/TransactionsContext";
import { GpTransactionListMockStateLoaded } from "../state/TransactionsState";
import { IGpTransaction } from "experiences/dashboard/mock/GP";
import {
  TableAlignment,
  TapTable,
  TableCell,
} from "experiences/funds/presentation/components/Table";
import { GpTransactionListRequested } from "../state/TransactionsEvent";
import { generatePath, useNavigate } from "react-router";
import { GP_ROUTES } from "common/constants/routes";
import { DEMO_TXN_ID } from "./GpTransactionDetailPage";

const TransactionListTable = ({
  transactions,
}: {
  transactions: IGpTransaction[];
}) => {
  const navigate = useNavigate();
  return (
    <>
      <TapTable
        data={transactions}
        emptyStateTitle={
          !transactions.length ? "There are no transactions to display." : null
        }
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "sizeDollar",
            label: "Size ($)",
            align: TableAlignment.RIGHT,
          },
          {
            key: "period",
            label: "Period",
            align: TableAlignment.LEFT,
          },
          {
            key: "stage",
            label: "Stage",
            align: TableAlignment.LEFT,
          },
          {
            key: "buyersCount",
            label: "Buyers",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(transaction: IGpTransaction) => {
          const handleClick = () => {
            const path = generatePath(GP_ROUTES.TransactionDetailOverview, {
              transactionId: DEMO_TXN_ID,
            });
            navigate(path);
          };
          return (
            <>
              <TableCell
                onClick={handleClick}
                className="cursor-pointer"
                hasBullet
              >
                {transaction.name}
              </TableCell>
              <TableCell onClick={handleClick} className="cursor-pointer">
                {transaction.funds}
              </TableCell>
              <TableCell
                onClick={handleClick}
                className="cursor-pointer text-right"
              >
                {transaction.sizeDollar}
              </TableCell>
              <TableCell onClick={handleClick} className="cursor-pointer">
                {transaction.period}
              </TableCell>
              <TableCell onClick={handleClick} className="cursor-pointer">
                {transaction.stage}
              </TableCell>
              <TableCell onClick={handleClick} className="cursor-pointer">
                {transaction.buyersCount}
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};

export const TransactionList = () => {
  const { emitEvent, gpTransactionListState } = useTransactionsContext();

  useEffect(() => {
    emitEvent(new GpTransactionListRequested());
  }, []);

  const transactions =
    gpTransactionListState instanceof GpTransactionListMockStateLoaded
      ? gpTransactionListState.transactions
      : [];

  return (
    <>
      <Helmet title="Transactions" />
      <PageHead title="Transactions" countLabel={transactions.length} />
      {gpTransactionListState instanceof GpTransactionListMockStateLoaded ? (
        <TransactionListTable transactions={transactions} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
