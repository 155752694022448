import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import {
  IParticipant,
  ParticipantCapacity,
  ParticipantPhase,
} from "experiences/transactions/domain/models/Participant";
import TapClient from "common/clients/TapClient";

export const useTransactionParticipants = () => {
  const { txnId } = useParams();
  const { user } = useAuthContext();
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [currentUserParticipant, setCurrentUserParticipant] =
    useState<IParticipant | null>(null);
  const [loading, setLoading] = useState(false);

  const refreshParticipants = async () => {
    setLoading(true);
    await new TapClient(`/transactions/${txnId}/participants/list/`)
      .get({})
      .then((resp: IParticipant[]) => {
        // Participants list omit "bid submitted" participants
        const preParticipants = resp.filter(
          (participant) =>
            participant.currentPhase != ParticipantPhase.BidSubmitted,
        );
        setParticipants(preParticipants);
        setCurrentUserParticipant(
          preParticipants.find((p) => p.userId === user?.id) ?? null,
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    refreshParticipants();
  }, []);

  const currUserParticipantIsBuyer =
    currentUserParticipant?.capacity === ParticipantCapacity.Buyer;

  // **** start utils ****
  const showSettingsPage = !currUserParticipantIsBuyer; // Hide settings page from "buyers"
  // **** end utils ****

  return { currentUserParticipant, showSettingsPage, loading, participants };
};
