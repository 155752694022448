import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";

import { transactionsClient } from "common/clients/ApolloClient";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";

import {
  ICompanyData,
  ICompanyDataResponse,
  ICompanyLoanDataResponse,
} from "./model";
import {
  GET_TRANSACTION_INVESTMENTS_COMPANY_DATA,
  GET_TRANSACTION_INVESTMENTS_COMPANY_LOAN_DATA,
} from "./query";

// TODO: Remove company loan data? looks like it's not used
export const useInvestmentsCompanyLoanData = ({
  currentReportDate,
}: {
  currentReportDate: string;
}) => {
  const { txnId } = useParams();
  const { selectedHoldingsIds } = useContext(TransactionFundDataContext);

  let {
    data: companyLoanData,
    loading: companyLoanDataLoading,
    refetch,
  } = useQuery<ICompanyLoanDataResponse>(
    GET_TRANSACTION_INVESTMENTS_COMPANY_LOAN_DATA,
    {
      client: transactionsClient,
      skip: !currentReportDate || selectedHoldingsIds.length === 0,
      variables: {
        transactionId: txnId,
        reportDate: currentReportDate,
        issuersIds: selectedHoldingsIds,
      },
    },
  );

  useEffect(() => {
    if (txnId && currentReportDate && selectedHoldingsIds.length > 0) {
      refetch();
    }
  }, [txnId, currentReportDate, selectedHoldingsIds]);

  const formattedCompanyLoanData =
    companyLoanData?.companyLoanData?.map((company) => ({
      ...company,
    })) || [];

  return {
    companyLoanData: formattedCompanyLoanData,
    companyLoanDataLoading,
  };
};

export const useInvestmentsCompanyData = ({
  currentReportDate,
}: {
  currentReportDate: string;
}) => {
  const { txnId } = useParams();
  const { selectedHoldingsIds } = useContext(TransactionFundDataContext);

  let {
    data: companyData,
    loading: companyDataLoading,
    refetch,
  } = useQuery<ICompanyDataResponse>(GET_TRANSACTION_INVESTMENTS_COMPANY_DATA, {
    client: transactionsClient,
    skip: !currentReportDate || selectedHoldingsIds.length === 0,
    variables: {
      transactionId: txnId,
      reportDate: currentReportDate,
      holdingsIds: selectedHoldingsIds,
    },
  });

  useEffect(() => {
    if (txnId && currentReportDate && selectedHoldingsIds.length > 0) {
      refetch();
    }
  }, [txnId, currentReportDate, selectedHoldingsIds]);

  const formattedCompanyData: ICompanyData[] = useMemo(() => {
    const innerData = companyData?.companyData || [];

    const formattedCompanyData = innerData?.map((company) => {
      return {
        ...company,
        lpImpliedInvestmentPercentage:
          company.lpImpliedInvestmentPercentage || NOT_AVAILABLE_STR,
      };
    });

    const companyDataByInvestmentPercentWithNullValues =
      formattedCompanyData.filter(
        (company) =>
          company.lpImpliedInvestmentPercentage === null ||
          company.lpImpliedInvestmentPercentage === NOT_AVAILABLE_STR ||
          company.lpImpliedInvestmentPercentage === "",
      );

    const companyDataByInvestmentPercentNonNull = formattedCompanyData.filter(
      (company) =>
        company.lpImpliedInvestmentPercentage !== null &&
        company.lpImpliedInvestmentPercentage !== NOT_AVAILABLE_STR &&
        company.lpImpliedInvestmentPercentage !== "",
    );

    const sortedCompanyDataByInvestmentPercentNonNull =
      companyDataByInvestmentPercentNonNull.sort(
        (a, b) =>
          Number(b.lpImpliedInvestmentPercentage) -
          Number(a.lpImpliedInvestmentPercentage),
      );

    return [
      ...sortedCompanyDataByInvestmentPercentNonNull,
      ...companyDataByInvestmentPercentWithNullValues,
    ];
  }, [companyData]);

  return {
    companyData: formattedCompanyData,
    companyDataLoading,
  };
};
