import { gql } from "@apollo/client";

export const GetPortfolioCompanyLoanDataQuery = gql`
  query Query($entitiesIds: [String!]!, $reportDate: Date!) {
    companyLoanData(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      investmentName
      fundName
      type
      amount
      currentAmount
      maturityDate
      interestRate
      interestRateType
      term
      collateral
      amortization
      otherTerms
      equityValue
      enterpriseValue
      leverageMultipleLtm
      interestCoverageRatio
      investmentId
      fundId
      psin
      managerId
      managerName
      companyId
      companyName
      reportDate
      originationDate
      isHumanVerified
    }
  }
`;
