import { CaretRight, File, FilePdf, Folder } from "@phosphor-icons/react";
import { filesize } from "filesize";
import { useEffect, useState } from "react";

import { cn } from "common/utils";

import {
  StyledTableRow,
  TableCell,
} from "experiences/funds/presentation/components/Table";
import { IBaseDocument } from "experiences/transactions/presentation/components/fund-data/documents/model";
import {
  documentTypeLabelsGql,
  FundDataDocumentTypeGql,
} from "experiences/transactions/presentation/components/fund-data/verify-permission/model";

import {
  getDocumentCount,
  getDocumentDateRanges,
  getDocumentsTotalSize,
  getDocumentTypes,
  getUniqueDocumentTypes,
  IRow,
  RowType,
  SEPARATOR,
} from "common/components/dataroom/DataroomV2";

const FALLBACK_FUND_NAME = "Unnamed Fund";
const FALLBACK_MANAGER_NAME = "Unnamed Manager";
const FALLBACK_FUND_ID = "00000000-0000-0000-0000-000000000000";

export const useDocumentsTable = ({
  documentsList,
  handleFileDownload,
}: {
  documentsList: IBaseDocument[];
  handleFileDownload: (args: { document: IBaseDocument }) => void;
}) => {
  const [documents, setDocuments] = useState<IBaseDocument[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [openRows, setOpenRows] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (documentsList.length === 0) {
      setDocuments([]);
      setRows([]);
      return;
    }

    const documentsWithoutFundId = documentsList.filter(
      (document) => !document.fundId,
    );

    if (documentsWithoutFundId.length > 0) {
      console.warn("There are documents without a set fundId");
    }

    const formattedDocuments = documentsList.map((document) => {
      return {
        ...document,
        fundName: document.fundName || FALLBACK_FUND_NAME,
        managerName: document.managerName || FALLBACK_MANAGER_NAME,
        fundId: document.fundId || document.managerId || FALLBACK_FUND_ID,
        holdingId: document.holdingId,
        // fileSize: filesizeParser(document.fileSize),
        reportType: document.reportType || FundDataDocumentTypeGql.Other,
      };
    });

    setDocuments(formattedDocuments);
  }, [documentsList, search]);

  useEffect(() => {
    const rowFunds: IRow[] = documents
      .reduce((acc, document) => {
        const fundExists = acc.find((fund) => fund.label === document.fundName);

        if (fundExists) {
          fundExists.documents = [...fundExists.documents, document.fileId];
        } else {
          acc.push({
            type: RowType.Fund,
            label: document.fundName,
            size: 0,
            children: [],
            documents: [document.fileId],
            uuid: document.fundId,
          });
        }
        return acc;
      }, [])
      // sort alphabetically
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    setRows(rowFunds);
  }, [documents]);

  const closeFundRow = (rowId: string) => {
    // Remove the row[rowId].children.children from rows
    setRows((prevRows) =>
      prevRows.map((fundRow) => {
        // Fund level
        if (rowId.includes(fundRow.uuid)) {
          const newFundRow = {
            ...fundRow,
            children: [],
          };
          return newFundRow;
        }

        return fundRow;
      }),
    );

    // Remove all rows and children rows that start with the same fundId
    // Do not include separator because click was at the fund level (meaning does not have a separator)
    setOpenRows(openRows.filter((row) => !row.startsWith(rowId)));
  };

  const handleRowFundClick = (rowId: string) => {
    // Within this if, rowId === fundId
    // If it's already "open", close it. Closing means removing children.
    if (openRows.includes(rowId)) {
      closeFundRow(rowId);
      return;
    }

    // If it's closed, open it. Opening means adding children.
    const documentsForThisFund = documents.filter(
      (document) => document.fundId === rowId,
    );

    const documentTypesForThisFund =
      getUniqueDocumentTypes(documentsForThisFund);

    // Sort elements in documentTypesForThisFund by this specific order
    // FundDataDocumentTypeGql.CapitalAccount
    // FundDataDocumentTypeGql.FinancialStatement
    // FundDataDocumentTypeGql.OperatingReport
    // FundDataDocumentTypeGql.AGMMaterial
    // FundDataDocumentTypeGql.CapitalCallNotice
    // FundDataDocumentTypeGql.DistributionNotice
    // FundDataDocumentTypeGql.Other
    // prettier-ignore
    const sortedDocumentTypes = [
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.CapitalAccount) ? FundDataDocumentTypeGql.CapitalAccount : null,
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.FinancialStatement) ? FundDataDocumentTypeGql.FinancialStatement : null,
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.OperatingReport) ? FundDataDocumentTypeGql.OperatingReport : null,
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.AGMMaterial) ? FundDataDocumentTypeGql.AGMMaterial : null,
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.CapitalCallNotice) ? FundDataDocumentTypeGql.CapitalCallNotice : null,
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.DistributionNotice) ? FundDataDocumentTypeGql.DistributionNotice : null,
        documentTypesForThisFund.includes(FundDataDocumentTypeGql.Other) ? FundDataDocumentTypeGql.Other : null,
      ].filter((type) => type !== null);

    const children = sortedDocumentTypes.map((docType) => {
      const filteredDocs = documentsForThisFund.filter(
        (document) => document.reportType === docType,
      );
      const size = getDocumentsTotalSize(filteredDocs);

      return {
        type: RowType.DocumentType,
        label: documentTypeLabelsGql[docType],
        size,
        children: [], // Don't add children yet, this would be the next level of nesting
        documents: filteredDocs.map((doc) => doc.fileId),
        uuid: `${rowId}${SEPARATOR}${docType}`,
      };
    });

    setRows((prev) =>
      prev.map((row) =>
        row.uuid === rowId
          ? {
              ...row,
              children: children,
            }
          : row,
      ),
    );
    setOpenRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId],
    );
  };

  const handleRowDocumentTypeClick = (rowId: string) => {
    // rowId is in this format `${fundId}${SEPARATOR}${docType}`
    const fundId = rowId.split(SEPARATOR)[0];
    const docType: FundDataDocumentTypeGql = rowId.split(
      SEPARATOR,
    )[1] as FundDataDocumentTypeGql;

    // If it's already "open", close it. Closing means removing children.
    if (openRows.includes(rowId)) {
      setOpenRows(openRows.filter((id) => id !== rowId));

      setRows((prevFunds) => {
        return prevFunds.map((fundRow) => {
          if (fundRow.uuid === fundId) {
            const categories = fundRow.children;
            const newCategories = categories.map((category) => {
              if (category.uuid === rowId) {
                return { ...category, children: [] };
              }
              return category;
            });

            return { ...fundRow, children: newCategories };
          }
          return fundRow;
        });
      });
      return;
    }

    const filesForThisDocumentType = documents.filter(
      (document) =>
        document.fundId === fundId && document.reportType === docType,
    );

    const filesByFileType = filesForThisDocumentType.map((file) => {
      return {
        type: RowType.Document,
        label: file.fileName,
        size: file.fileSize,
        documents: [file.fileId],
        children: [], // This is a document, not a folder
        uuid: `${fundId}${SEPARATOR}${docType}${SEPARATOR}${file.fileId}`,
      };
    });

    const fundRow = rows.find((row) => row.uuid === fundId);
    const documentTypeRow = fundRow?.children.find((row) => row.uuid === rowId);

    // Update the fund row to include the new children
    setRows((prevFunds) => {
      return prevFunds.map((fundRow) => {
        if (fundRow.uuid === fundId) {
          const categories = fundRow.children;
          const newCategories = categories.map((category) => {
            if (category.uuid === documentTypeRow?.uuid) {
              return { ...category, children: filesByFileType };
            }
            return category;
          });

          return { ...fundRow, children: newCategories };
        }
        return fundRow;
      });
    });
    setOpenRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId],
    );
  };

  const getDocumentsByIds = (ids: string[]) => {
    return documents.filter((document) => ids.includes(document.fileId));
  };

  const handleRowClick = (rowId: string, rowType: RowType) => {
    if (rowType === RowType.Fund) {
      handleRowFundClick(rowId);
      return;
    }
    // RowType.Document is pretty much the same as RowType.Fund but instead of nesting document types, it just shows the documents.
    if (rowType === RowType.DocumentType) {
      handleRowDocumentTypeClick(rowId);
      return;
    }
    // If it's a document, download it
    if (rowType === RowType.Document) {
      const fileId = rowId.split(SEPARATOR)[2];
      const document = getDocumentsByIds([fileId])[0];
      handleFileDownload({ document });
    }
  };

  const renderRow = (row: IRow) => {
    const isOpen = openRows.includes(row.uuid);
    const size = getDocumentsTotalSize(getDocumentsByIds(row.documents));
    const dates = getDocumentDateRanges(getDocumentsByIds(row.documents));
    const count = getDocumentCount(getDocumentsByIds(row.documents));
    const types = getDocumentTypes(getDocumentsByIds(row.documents));
    const isFund = row.type === RowType.Fund;
    const isDocumentType = row.type === RowType.DocumentType;
    const isDocument = row.type === RowType.Document;

    const showCaret = isFund || isDocumentType;
    const showFile = isDocument;
    const showFolder = isFund || isDocumentType;
    const isPdf = row.label.toLowerCase().endsWith(".pdf");

    return (
      <>
        <StyledTableRow
          className={cn({
            "bg-zinc-50": isDocumentType,
            "bg-zinc-100 text-zinc-700": isDocument,
          })}
        >
          <TableCell
            onClick={() => handleRowClick(row.uuid, row.type)}
            className={cn("select-none overflow-ellipsis", {
              "!pl-10": isDocumentType,
              "!pl-16": isDocument,
              "!font-medium": isFund || isDocumentType,
            })}
            {...(isDocumentType && {
              title: row.label,
            })}
          >
            {showCaret && (
              <CaretRight
                className={cn(
                  "transition-transform inline mr-2",
                  isOpen && "rotate-90",
                )}
              />
            )}
            {showFile && !isPdf && <File className="inline mr-2" />}
            {showFile && isPdf && <FilePdf className="inline mr-2" />}
            {showFolder && <Folder className="inline mr-2" />}
            {row.label}
          </TableCell>
          <TableCell
            onClick={() => handleRowClick(row.uuid, row.type)}
            className="!w-48"
          >
            {dates}
          </TableCell>
          <TableCell
            onClick={() => handleRowClick(row.uuid, row.type)}
            className="!w-36 !max-w-36"
          >
            {types}
          </TableCell>
          <TableCell className="text-right !w-36 !max-w-36">
            {filesize(size * 1024 * 1024, {
              precision: 0,
              base: 10,
              output: "string",
              exponent: 2,
            })}
          </TableCell>
        </StyledTableRow>
        {row.children.length > 0 &&
          row.children.map((child) => renderRow(child))}
      </>
    );
  };

  return {
    rows,
    documents,
    openRows,
    handleRowClick,
    getDocumentsByIds,
    renderRow,
    search,
    setSearch,
  };
};
