import { gql } from "@apollo/client";

export const GetCompanyReportDatesQuery = gql`
  query Query($companyId: String!) {
    reportDates(companyId: $companyId) {
      name
      dates
    }
  }
`;
