import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "@apollo/client";

import { portfolioClient } from "common/clients/ApolloClient";

import { getReportDatesForReportName } from "experiences/portfolio-v2/domain/hooks/getReportDatesForReportName";
import { ReportDateName } from "experiences/portfolio-v2/domain/models/ReportDates";
import { IPortfolioExposureSummaryWithGrouping } from "experiences/portfolio-v2/domain/models/PortfolioExposureSummary";
import { GetPortfolioSummaryExposureQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioSummaryExposure";
import { GetPortfolioCsvExposureSummaryQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioCsvQuery";
import { createFundExposureSummaryExcel } from "experiences/common/excel/fund-summary/createExcel";

import { useEntitiesSelector } from "../components/EntitiesDropdown";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { FundExposureGroupingKeys } from "./TableGrouping";

export const usePortfolioSummary = () => {
  const {
    selectedEntitiesIds,
    reportDates: allReportDates,
    entitiesLoading,
  } = useContext(PortfolioContext);
  const summaryReportDates = getReportDatesForReportName({
    reportDates: allReportDates,
    reportName: ReportDateName.FundExposureSummary,
  });
  const [loading, setLoading] = useState(false);
  const [selectedReportDate, setSelectedReportDate] = useState<string | null>(
    summaryReportDates.length > 0 ? summaryReportDates[0] : null,
  );
  const [groupBy, setGroupBy] = useState<FundExposureGroupingKeys>(
    FundExposureGroupingKeys.Strategy,
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [summaryData, setSummaryData] =
    useState<IPortfolioExposureSummaryWithGrouping | null>(null);
  const entitySelector = useEntitiesSelector();

  const reportDatesOptions = summaryReportDates.map((reportDate) => ({
    label: reportDate,
    value: reportDate,
  }));

  const handleRefetch = async () => {
    setLoading(true);
    if (selectedEntitiesIds.length === 0 || selectedReportDate === null) {
      setLoading(false);
      setSelectedReportDate(null);
      setGroupBy(FundExposureGroupingKeys.Strategy);
      setSummaryData(null);
      return;
    }
    await refetchSummary({
      entitiesIds: selectedEntitiesIds,
      reportDate: selectedReportDate,
      groupBy: groupBy,
    }).then((r) => {
      setLoading(false);
      setSummaryData(r.data?.fundSummaryExposure);
    });
  };

  const { refetch: downloadCsvData } = useQuery<FundDataSummaryCsv>(
    GetPortfolioCsvExposureSummaryQuery,
    {
      variables: {
        entitiesIds: selectedEntitiesIds,
        reportDate: selectedReportDate,
      },
      client: portfolioClient,
      fetchPolicy: "no-cache",
      skip: true,
    },
  );

  const {
    data: summaryRawData,
    loading: exposureLoading,
    refetch: refetchSummary,
  } = useQuery<{
    fundSummaryExposure: IPortfolioExposureSummaryWithGrouping;
  }>(GetPortfolioSummaryExposureQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
      reportDate: selectedReportDate,
      groupBy: groupBy,
    },
    // skip: selectedEntitiesIds.length === 0 || selectedReportDate === null,
    skip: true,
  });

  useEffect(() => {
    if (summaryRawData) {
      setSummaryData(summaryRawData.fundSummaryExposure);
    }
  }, [summaryRawData]);

  useEffect(() => {
    if (summaryReportDates.length > 0) {
      setSelectedReportDate(summaryReportDates[0]);
    }
    // DO NOT USE summaryReportDates
  }, [allReportDates]);

  useEffect(() => {
    if (!selectedReportDate) {
      setSelectedReportDate(summaryReportDates[0]);
    }
  }, []);

  useEffect(() => {
    handleRefetch();
  }, [selectedReportDate, selectedEntitiesIds, groupBy]);

  const onSelectedReportDateChange = (reportDate: string) => {
    setSelectedReportDate(reportDate);
  };

  const handleDownloadClick = () => {
    setIsDownloading(true);
    toast.success(
      "Download started. Make sure your browser is accepting multiple files when prompted.",
    );

    downloadCsvData({
      variables: {
        entitiesIds: selectedEntitiesIds,
        reportDate: selectedReportDate,
      },
    })
      .then((res) => {
        // Fund exposure summary is a download of the Excel file
        createFundExposureSummaryExcel({
          data: res.data?.dataSummaryCsv?.fundExposureSummaryTabs,
          namePrefix: `portfolio-summary`,
        });
      })
      .catch((error) => {
        toast.error("Download failed. Please try again.");
      })
      .finally(() => {
        setIsDownloading(false);
        toast.success("Download complete!");
      });
  };

  const onGroupByChange = (groupBy: FundExposureGroupingKeys) => {
    setGroupBy(groupBy);
  };

  const fesData =
    selectedEntitiesIds.length > 0 && reportDatesOptions.length > 0
      ? summaryData
      : null;

  return {
    loading: loading || entitiesLoading,
    entitySelector,
    data: fesData,
    groupBy,
    onGroupByChange,
    reportDatesOptions,
    selectedReportDate,
    onSelectedReportDateChange,
    handleDownloadClick,
    isDownloading,
  };
};
