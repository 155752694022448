export const SidebarHead = ({
  fundLogoSrc,
  fundName,
}: {
  fundLogoSrc: string;
  fundName: string;
}) => {
  return (
    <div className="flex items-center gap-4">
      <img
        src={fundLogoSrc}
        alt="Fund Icon"
        className="border border-nomad-200 rounded-2xl shadow-sm select-none w-16"
      />
      <h1 className="font-semibold text-lg tracking-tight leading-snug mt-2 cursor-default">
        {fundName}
      </h1>
    </div>
  );
};
