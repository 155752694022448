import { Outlet } from "react-router";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

import ImpersonationBanner from "experiences/authentication/presentation/components/ImpersonationBanner";
import useLocalStorage from "common/hooks/useLocalStorage";
import { AUTH_KEYS } from "common/constants/localStorage";
import { UserExperienceType } from "experiences/authentication/domain/models/UserType";

import { LPAppBar } from "experiences/common/AppBar/LP/LPAppBar";
import { GPAppBar } from "experiences/common/AppBar/GP/GPAppBar";
import { TPAppBar } from "experiences/common/AppBar/ThirdParty/TPAppBar";

const AppBarForExperienceType = {
  [UserExperienceType.LP]: LPAppBar,
  [UserExperienceType.GP]: GPAppBar,
  [UserExperienceType.THIRD_PARTY]: TPAppBar,
};

const AppLayout = () => {
  const { user } = useAuthContext();
  const [impersonationState] = useLocalStorage(
    AUTH_KEYS.IMPERSONATION,
    undefined,
  );

  return (
    <>
      {impersonationState ? <ImpersonationBanner /> : null}
      {AppBarForExperienceType[user?.experienceType || UserExperienceType.LP]()}

      <Outlet />
    </>
  );
};

export default AppLayout;
