import { ReactNode } from "react";

export const SelectWithLabelWrapper = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex gap-4 h-8 items-center">
      <label className="text-xs font-medium select-none cursor-default">
        {label}
      </label>
      {children}
    </div>
  );
};
