import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  forwardRef,
} from "react";
import { ParentSize } from "@visx/responsive";
import { AreaClosed, Line, Bar } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { GridRows, GridColumns } from "@visx/grid";
import { scaleTime, scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import * as d3 from "d3";
import {
  useTooltip,
  useTooltipInPortal,
  Tooltip,
  defaultStyles,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { max, min, extent, bisector } from "@visx/vendor/d3-array";
import { timeFormat } from "@visx/vendor/d3-time-format";
import { CheckCircle } from "@phosphor-icons/react";

import { LP_ROUTES } from "common/constants/routes";
import { VerifyPermissionOverlay } from "../../../../dashboard/presentation/components/VerifyPermissionOverlay";
import { useFundPrivacy } from "../../hooks/useFundPrivacy";
import { formatMillions, formatPercentPoints } from "../../pages/ViewFund";
import { Fund } from "experiences/funds/domain/models/Fund";
import {
  IOIType,
  IndicationOfInterest,
} from "experiences/indications-of-interest/domain/models/IOI";
import { useIndicationOfInterest } from "./IndicationsOfInterestTable";

import { generatePath, useNavigate } from "react-router";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { IPriceHistory } from "experiences/funds/domain/models/FundPriceHistory";
import { priceHistoryKey, sidebarPositions } from "./Sidebar";
import { BidTriangle } from "./priceHistoryChart/BidIcon";
import { AskTriangle } from "./priceHistoryChart/AskIcon";
import {
  ChartLegend,
  ChartLegendGroup,
} from "./priceHistoryChart/StyledLegendGroup";
import { FundSummary } from "experiences/funds/domain/models/FundSummary";

const tapMvpiLineColor = "#BCB3A5";
const tpviLineColor = "#737476";
const gridColor = "#d9dbde";

// util
export const formatDate = timeFormat("%b %d, '%y");
export const IOI_DATE_FORMAT = "%Y-%m-%d";

export type TVPI_CHART = {
  date: string;
  tvpi: number;
  marketTvpi: number;
  nav: number;
  marketNav: number;
  percentOfNav: number;
};

// override keys
const PRICE_KEY = "price";
const DATE_KEY = "referenceDate";

// accessors
const getMarketTvpi = (d: TVPI_CHART) => d.marketTvpi * 100;
const getTvpi = (d: TVPI_CHART) => d.tvpi * 100;
const getNav = (d: TVPI_CHART) => d.nav;
const getMarketNav = (d: TVPI_CHART) => d.marketNav;
const getPrice = (d: TVPI_CHART) => d[PRICE_KEY];
const getHistoricPriceDate = (d: IPriceHistory[0]) => new Date(d[DATE_KEY]);
const bisectDate = bisector<TVPI_CHART, Date>(
  (d) => new Date(d[DATE_KEY]),
).left;

const leftAxisWidth = 70;

// small chart preview for buttons
const smallLinePoints = [60, 60, 60, 24, 24, 24, 84, 84, 84, 70, 70, 70];
const smallAreaPoints = [90, 60, 64, 84, 52, 24, 50, 37, 3, 84, 24, 46];
const smallAreaPointsXScale = scaleLinear({
  domain: [0, smallAreaPoints.length - 1],
  range: [0, 20], // 20 px
  nice: true,
});

const smallAreaPointsYScale = scaleLinear({
  domain: [0, max(smallAreaPoints) || 0],
  range: [12, 0], // 12 px
  nice: true,
});

const timeRangeInDaysOptions = [
  {
    label: "1Y",
    value: 365,
  },
  {
    label: "2Y",
    value: 730,
  },
  {
    label: "3Y",
    value: 1095,
  },
  {
    label: "Max",
    value: 365 * 10,
  },
];

const usePriceHistoryChart = ({
  selectedChart,
}: {
  selectedChart: PriceHistoryChartType;
}) => {
  const [showBids, setShowBids] = useState(true);
  const [showAsks, setShowAsks] = useState(true);
  const [showTvpi, setShowTVPI] = useState(true);
  const [showMarketTvpi, setShowTapMVPI] = useState(true);
  const [showNav, setShowNav] = useState(false);
  const [showMarketNav, setShowMarketNav] = useState(false);
  const [showPercentOfNav, setShowPercentOfNav] = useState(false);

  const [timeRange, setTimeRange] = useState(
    timeRangeInDaysOptions[timeRangeInDaysOptions.length - 1].value,
  );

  useEffect(() => {
    if (selectedChart === PriceHistoryChartType.TVPI) {
      setShowTVPI(true);
      setShowTapMVPI(true);
      setShowNav(false);
      setShowMarketNav(false);
      setShowPercentOfNav(false);
    }
    if (selectedChart === PriceHistoryChartType.NAV) {
      setShowTVPI(false);
      setShowTapMVPI(false);
      setShowNav(true);
      setShowMarketNav(true);
      setShowPercentOfNav(false);
    }
    if (selectedChart === PriceHistoryChartType.Price) {
      setShowTVPI(false);
      setShowTapMVPI(false);
      setShowNav(false);
      setShowMarketNav(false);
      setShowPercentOfNav(true);
    }
    setShowBids(true);
    setShowAsks(true);
  }, [selectedChart]);

  const showAreaChart = showMarketTvpi || showMarketNav || showPercentOfNav;
  const showLineChart = showTvpi || showNav;

  const toggleShowBids = () => {
    setShowBids(!showBids);
  };

  const toggleShowAsks = () => {
    setShowAsks(!showAsks);
  };

  const toggleShowTVPI = () => {
    setShowTVPI(!showTvpi);
  };

  const toggleShowMarketTvpi = () => {
    setShowTapMVPI(!showMarketTvpi);
  };

  const toggleShowNav = () => {
    setShowNav(!showNav);
  };

  const toggleShowMarketNav = () => {
    setShowMarketNav(!showMarketNav);
  };

  const toggleShowPercentOfNav = () => {
    setShowPercentOfNav(!showPercentOfNav);
  };

  const handleTimeOptionClick = (value: number) => {
    setTimeRange(value);
  };

  return {
    showBids,
    showAsks,
    showAreaChart,
    showLineChart,
    showNav,
    showMarketNav,
    showPercentOfNav,
    showTvpi,
    showMarketTvpi,
    toggleShowBids,
    toggleShowAsks,
    toggleShowTVPI,
    toggleShowMarketNav,
    toggleShowNav,
    toggleShowPercentOfNav,
    toggleShowMarketTvpi,
    handleTimeOptionClick,
    timeRange,
  };
};

const getXscale = ({
  historicPricing,
  margin,
  innerWidth,
  timeRange,
}: {
  historicPricing: IPriceHistory;
  margin: { top: number; right: number; bottom: number; left: number };
  innerWidth: number;
  timeRange: number;
}) => {
  const filteredHistoricPricing = historicPricing.filter((price) => {
    const timeRangeToStartDate = new Date();
    timeRangeToStartDate.setDate(timeRangeToStartDate.getDate() - timeRange);
    const pricePointDate = d3.timeParse(IOI_DATE_FORMAT)(price.referenceDate);
    return pricePointDate >= timeRangeToStartDate;
  });

  return scaleTime({
    domain: extent(filteredHistoricPricing, getHistoricPriceDate) as [
      Date,
      Date,
    ],
    range: [margin.left, innerWidth + margin.left],
  });
};

const PriceHistoryChart = ({
  width,
  height,
  margin = { top: 16, right: 0, bottom: 32, left: 0 + leftAxisWidth },
  style,
  selectedChart,
  iois: summaryIois,
  historicPricing = [],
}: {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  parentWidth?: number;
  parentHeight?: number;
  parentTop?: number;
  parentLeft?: number;
  parentRef?: HTMLDivElement | null;
  resizeParent?: number;
  style?: React.CSSProperties;
  selectedChart: PriceHistoryChartType;
  iois: IndicationOfInterest[];
  historicPricing?: IPriceHistory;
}) => {
  const ioiAccessDateKey = "createdAt";
  const ioiAccessMaxPriceKey = "maxPrice";
  const ioiAccessMinPriceKey = "minPrice";
  const ioiAccessMaxTvpiKey = "maxTvpi";
  const ioiMaxNavKey = "maxAmount";

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip({
    tooltipOpen: false,
  });

  const {
    showBids,
    showAsks,
    showAreaChart,
    showLineChart,
    toggleShowBids,
    toggleShowAsks,
    toggleShowTVPI,
    toggleShowMarketNav,
    toggleShowNav,
    toggleShowPercentOfNav,
    toggleShowMarketTvpi,
    handleTimeOptionClick,
    timeRange,
  } = usePriceHistoryChart({ selectedChart });

  const { containerRef } = useTooltipInPortal({
    detectBounds: true, // use TooltipWithBounds
    scroll: true, // when tooltip containers are scrolled, this will correctly update the Tooltip position
  });

  const firstHistoricPrice: IPriceHistory[0] =
    historicPricing && historicPricing.length && historicPricing?.[0];
  const lastHistoricPrice: IPriceHistory[0] =
    historicPricing &&
    historicPricing.length &&
    historicPricing?.[historicPricing.length - 1];

  // create new const called startDate should be set to the date from `timeRange` days ago
  const startDate =
    (firstHistoricPrice.referenceDate &&
      d3.timeParse(IOI_DATE_FORMAT)(firstHistoricPrice.referenceDate)) ||
    new Date();
  startDate.setDate(startDate.getDate() - timeRange);
  const today = new Date();

  const { bidPriceData, askPriceData } = useIndicationOfInterest({
    iois: summaryIois,
  });

  const sourceIois: IndicationOfInterest[] = [...bidPriceData, ...askPriceData];

  const stock = historicPricing
    .filter((price) => {
      const pricePointDate = d3.timeParse(IOI_DATE_FORMAT)(price.referenceDate);
      return pricePointDate >= startDate && pricePointDate <= today;
    })
    .reduce((acc, price, i, prices) => {
      // if price[DATE_KEY] is not today, add a new price point to the array that is the same price but with today's date
      if (prices.length - 1 === i) {
        const pricePointDate = d3.timeParse(IOI_DATE_FORMAT)(
          price.referenceDate,
        );
        if (pricePointDate < today) {
          const newLastPrice = {
            ...price,
            date: d3.timeFormat(IOI_DATE_FORMAT)(today),
          };
          return [...acc, price, newLastPrice];
        }
      }

      return [...acc, price];
    }, []);

  const iois = sourceIois.filter((ioi) => {
    // for real funds, date is ioi.referenceDate
    const ioiDate = d3.timeParse(IOI_DATE_FORMAT)(ioi[ioiAccessDateKey]);

    return ioiDate >= startDate && ioiDate <= today;
  });

  const bids = iois.filter((ioi) => ioi.type === IOIType.Bid);
  const asks = iois.filter((ioi) => ioi.type === IOIType.Ask);

  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // scales
  const xScale = useMemo(
    () => getXscale({ historicPricing, margin, innerWidth, timeRange }),
    [innerWidth, margin.left, timeRange, selectedChart, historicPricing],
  );

  const yScale = useMemo(() => {
    // TODO: should consider only visible points in current time scale

    // note: for PriceHistoryChartType.TVPI, there are 4 different sets of data
    // 1. tvpi
    // 2. market tvpi
    // 3. bids
    // 4. asks
    // we need to find the highest and lowest point of all 4 sets of data
    // and set the yScale domain to that
    let highestPoint = 0;
    let lowestPoint = 0;

    if (selectedChart === PriceHistoryChartType.TVPI) {
      highestPoint = max(stock, (d: IPriceHistory[0]) => {
        return Math.max(
          getMarketTvpi(d),
          getTvpi(d),
          ...iois.map((ioi) => {
            return ioi[ioiAccessMaxTvpiKey] * 100;
          }),
        );
      });
      lowestPoint = min(stock, (d: IPriceHistory[0]) => {
        return Math.min(
          getMarketTvpi(d),
          getTvpi(d),
          ...iois.map((ioi) => {
            return ioi[ioiAccessMaxTvpiKey] * 100;
          }),
        );
      });
    }

    if (selectedChart === PriceHistoryChartType.NAV) {
      highestPoint = max(stock, (d) => {
        return Math.max(
          getMarketNav(d),
          getNav(d),
          ...iois.map((ioi) => {
            return ioi[ioiMaxNavKey];
          }),
        );
      });
      lowestPoint = min(stock, (d) => {
        return Math.min(
          getMarketNav(d),
          getNav(d),
          ...iois
            .filter((ioi) => {
              return (
                Boolean(ioi[ioiAccessMaxPriceKey]) &&
                Boolean(ioi[ioiAccessMinPriceKey])
              );
            })
            .map((ioi) => {
              return ioi[ioiMaxNavKey];
            }),
        );
      });
    }

    if (selectedChart === PriceHistoryChartType.Price) {
      const highestPointLines = max(historicPricing, (d) => d[PRICE_KEY]) || 0;
      const lowestPointLines = min(historicPricing, (d) => d[PRICE_KEY]) || 0;

      const highestPointIois =
        max(sourceIois, (d) => {
          return Math.max(
            ...sourceIois.map((ioi) => {
              return ioi[ioiAccessMaxPriceKey] || 0;
            }),
          );
        }) || 0;

      const lowestPointIois =
        min(sourceIois, (ioi) => {
          return ioi[ioiAccessMinPriceKey] || 0;
        }) || 0;

      highestPoint = Math.max(highestPointLines, highestPointIois);
      lowestPoint = Math.min(lowestPointLines, lowestPointIois);
    }

    return scaleLinear({
      domain: [lowestPoint, highestPoint],
      range: [innerHeight + margin.top, margin.top],
      nice: true,
    });
  }, [
    margin.top,
    innerHeight,
    timeRange,
    selectedChart,
    historicPricing,
    iois.length,
  ]);

  // tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x);
      const index = bisectDate(historicPricing, x0, 1);
      const d0 = historicPricing[index - 1];
      const d1 = historicPricing[index];
      let d = d0;
      if (d1 && getHistoricPriceDate(d1)) {
        d =
          x0.valueOf() - getHistoricPriceDate(d0).valueOf() >
          getHistoricPriceDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }

      let tooltipTop = 0;

      // TODO: FIX TOP DISTANCE @ jmz7v
      // if (selectedChart === PriceHistoryChartType.TVPI) {
      //   yScale(getMarketTvpi(d));
      //   tooltipTop = yScale(getMarketTvpi(d));
      // }
      // if (selectedChart === PriceHistoryChartType.NAV) {
      //   yScale(getMarketNav(d));
      //   tooltipTop = yScale(getMarketNav(d));
      // }
      if (selectedChart === PriceHistoryChartType.Price) {
        yScale(d[PRICE_KEY]);
        tooltipTop = yScale(d[PRICE_KEY]);
      }

      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: tooltipTop,
      });
    },
    [showTooltip, yScale, xScale],
  );

  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ChartLegendGroup>
          {selectedChart === PriceHistoryChartType.TVPI && (
            <>
              <ChartLegend onClick={toggleShowTVPI}>
                <svg width={20} height={12}>
                  <LinePath
                    curve={curveMonotoneX}
                    data={smallLinePoints}
                    x={(d, i) => smallAreaPointsXScale(i)}
                    y={smallAreaPointsYScale}
                    stroke={tpviLineColor}
                    strokeDasharray={"3,2"}
                  />
                </svg>
                TVPI
              </ChartLegend>
              <ChartLegend onClick={toggleShowMarketTvpi}>
                <svg width={20} height={12}>
                  <LinePath
                    curve={curveMonotoneX}
                    data={smallAreaPoints}
                    x={(d, i) => smallAreaPointsXScale(i)}
                    y={smallAreaPointsYScale}
                    stroke={tapMvpiLineColor}
                  />
                </svg>
                Market TVPI
              </ChartLegend>
            </>
          )}

          {selectedChart === PriceHistoryChartType.NAV && (
            <>
              <ChartLegend onClick={toggleShowNav}>
                <svg width={20} height={12}>
                  <LinePath
                    curve={curveMonotoneX}
                    data={smallLinePoints}
                    x={(d, i) => smallAreaPointsXScale(i)}
                    y={smallAreaPointsYScale}
                    stroke={tpviLineColor}
                    strokeDasharray={"3,2"}
                  />
                </svg>
                NAV
              </ChartLegend>
              <ChartLegend onClick={toggleShowMarketNav}>
                <svg width={20} height={12}>
                  <LinePath
                    curve={curveMonotoneX}
                    data={smallAreaPoints}
                    x={(d, i) => smallAreaPointsXScale(i)}
                    y={smallAreaPointsYScale}
                    stroke={tapMvpiLineColor}
                  />
                </svg>
                Market NAV
              </ChartLegend>
            </>
          )}

          {selectedChart === PriceHistoryChartType.Price && (
            <>
              <ChartLegend onClick={toggleShowPercentOfNav}>
                <svg width={20} height={12}>
                  <LinePath
                    curve={curveMonotoneX}
                    data={smallAreaPoints}
                    x={(d, i) => smallAreaPointsXScale(i)}
                    y={smallAreaPointsYScale}
                    stroke={tapMvpiLineColor}
                  />
                </svg>
                % of NAV
              </ChartLegend>
            </>
          )}

          <ChartLegend onClick={toggleShowBids}>
            <BidTriangle />
            Bid
          </ChartLegend>
          <ChartLegend onClick={toggleShowAsks}>
            <AskTriangle />
            Ask
          </ChartLegend>
        </ChartLegendGroup>
        <ChartLegendGroup>
          {timeRangeInDaysOptions.map((option) => {
            const isSelected = option.value === timeRange;
            return (
              <ChartLegend
                key={`${option.label}-${option.value}`}
                onClick={() => handleTimeOptionClick(option.value)}
                style={{
                  borderBottom: isSelected
                    ? "2px solid #21272D"
                    : "2px solid transparent",
                  borderRadius: 0,
                }}
              >
                {option.label}
              </ChartLegend>
            );
          })}
        </ChartLegendGroup>
      </div>

      <div
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <svg width={width} height={height} ref={containerRef}>
          <AxisLeft
            scale={yScale}
            left={leftAxisWidth}
            hideAxisLine
            hideTicks
            tickFormat={(value, i, values) => {
              // hide the edges
              if (i === 0 || values.length - 1 === i) {
                return undefined;
              }
              if (selectedChart === PriceHistoryChartType.TVPI) {
                return `${(value / 100).toFixed(1)}x`;
              }
              if (selectedChart === PriceHistoryChartType.NAV) {
                return `${formatMillions(value)}`;
              }
              if (selectedChart === PriceHistoryChartType.Price) {
                return `${formatPercentPoints(value * 100)}`;
              }
            }}
          />

          <AxisBottom
            scale={xScale}
            top={innerHeight + 16}
            hideAxisLine
            hideTicks
          />

          <LinearGradient
            id="area-gradient"
            from={tapMvpiLineColor}
            to={tapMvpiLineColor}
            fromOpacity={1}
            toOpacity={0}
          />
          <GridRows
            left={margin.left}
            scale={yScale}
            width={innerWidth}
            strokeDasharray="1,10"
            stroke={gridColor}
            pointerEvents="none"
          />
          <GridColumns
            left={margin.left}
            top={margin.top}
            scale={xScale}
            height={innerHeight}
            strokeDasharray="1,10"
            stroke={gridColor}
            pointerEvents="none"
          />
          {showLineChart && (
            <LinePath
              data={historicPricing}
              stroke={tpviLineColor}
              strokeDasharray={"4,10"}
              strokeWidth={1}
              style={{ left: leftAxisWidth }}
              x={(d) => {
                return xScale((d) => {
                  return d3.timeParse(IOI_DATE_FORMAT)(d.referenceDate);
                });
              }}
              y={(d) => {
                if (selectedChart === PriceHistoryChartType.NAV) {
                  return yScale(getNav(d));
                }
                if (selectedChart === PriceHistoryChartType.TVPI) {
                  return yScale(getTvpi(d));
                }
                if (selectedChart === PriceHistoryChartType.Price) {
                  const yValue = yScale(d.price);
                  return yValue;
                }
                return 0;
              }}
              curve={d3.curveStepAfter}
            />
          )}
          {showAreaChart && (
            <AreaClosed
              data={historicPricing}
              // x={(d) => xScale(getDate(d)) ?? 0}
              x={(d) => {
                return xScale(d3.timeParse(IOI_DATE_FORMAT)(d.referenceDate));
              }}
              y={(d) => {
                if (selectedChart === PriceHistoryChartType.TVPI) {
                  return yScale(getMarketTvpi(d));
                }
                if (selectedChart === PriceHistoryChartType.NAV) {
                  return yScale(getMarketNav(d));
                }
                if (selectedChart === PriceHistoryChartType.Price) {
                  const yValue = yScale(d.price);
                  return yValue;
                }
                return 0;
              }}
              yScale={yScale}
              stroke="url(#area-gradient)"
              fill="url(#area-gradient)"
              curve={curveMonotoneX}
            />
          )}
          <Bar
            x={margin.left} // todo: sum leftAxisWidth, fix circle position 1st
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{
                  x: tooltipLeft,
                  y: innerHeight + margin.top,
                }}
                stroke={"#21272D"}
                strokeWidth={1}
                strokeOpacity={0.1}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={"white"}
                stroke="#BCB3A5"
                strokeWidth={1}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        {showAsks &&
          asks.map((ask, i) => {
            let top = 0;
            // TVPI, NAV charts are not used at the moment, feb 2024
            // if (selectedChart === PriceHistoryChartType.TVPI) {
            //   top = yScale(ask[ioiAccessMaxTvpiKey] * 100);
            // }
            // if (selectedChart === PriceHistoryChartType.NAV) {
            //   top = yScale(ask[ioiMaxNavKey]);
            // }
            if (selectedChart === PriceHistoryChartType.Price) {
              top = yScale(
                Math.max(ask[ioiAccessMaxPriceKey], ask[ioiAccessMinPriceKey]),
              );
            }
            const left = xScale(new Date(ask[ioiAccessDateKey]));
            // hide asks if they're outside main drawing area
            if (left < leftAxisWidth || left > width - margin.right) {
              return null;
            }
            return (
              <AskTriangle
                key={`ask-${i}`}
                style={{
                  position: "absolute",
                  left: left,
                  top: top,
                  transform: "translate(-50%, -50%)", // center icon on the set position
                  pointerEvents: "none",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#f0f0f0",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.15)",
                }}
              />
            );
          })}
        {showBids &&
          bids.map((bid, i) => {
            let top = 0;
            // TVPI, NAV charts are not used at the moment, feb 2024
            // if (selectedChart === PriceHistoryChartType.TVPI) {
            //   top = yScale(bid[ioiAccessMaxTvpiKey] * 100);
            // }
            // if (selectedChart === PriceHistoryChartType.NAV) {
            //   top = yScale(bid[ioiMaxNavKey]);
            // }
            if (selectedChart === PriceHistoryChartType.Price) {
              top = yScale(
                Math.max(bid[ioiAccessMaxPriceKey], bid[ioiAccessMinPriceKey]),
              );
            }
            const left = xScale(new Date(bid[ioiAccessDateKey]));
            // hide asks if they're outside main drawing area
            if (left < leftAxisWidth) {
              return null;
            }
            return (
              <BidTriangle
                key={`bid-${i}`}
                style={{
                  position: "absolute",
                  left: left,
                  top: top,
                  transform: "translate(-50%, -50%)", // center icon on the set position
                  pointerEvents: "none",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#f0f0f0",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.15)",
                }}
              />
            );
          })}

        {/* </Group> */}
        {tooltipOpen && (
          <Tooltip
            top={tooltipTop + 36}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 72,
              textAlign: "center",
              transform: "translateX(-50%)",
              fontFamily: "'Inter', sans-serif",
              fontSize: 11,
              fontWeight: 500,
              padding: 8,
              borderRadius: 9,
              border: "1px solid #DFDFD9",
              backgroundColor: "#FFF",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.03)",
              zIndex: 100,
            }}
          >
            {tooltipData && `Percent of NAV: ${getPrice(tooltipData)}%`}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

enum PriceHistoryChartType {
  TVPI = "tvpi",
  NAV = "nav",
  Price = "price",
}

// Note: main component is wrapped in forwardRef to allow parent component to scroll to this component
export const PriceHistoryChartWrapper = forwardRef(
  function PriceHistoryChartWrapper(
    {
      fundPrivacy,
      fund,
      iois,
      summary,
    }: {
      fundPrivacy: ReturnType<typeof useFundPrivacy>;
      fund?: Fund;
      summary?: FundSummary;
      iois?: IndicationOfInterest[];
    },
    ref: any,
  ) {
    const { isViewpoint } = fundPrivacy;
    const locked =
      !summary?.permissionProfile.isPermittedToViewSellIOIs() && !isViewpoint;
    const [selectedChart, setSelectedChart] = useState(
      PriceHistoryChartType.Price,
    );

    const navigate = useNavigate();
    var day = 60 * 60 * 24 * 1000;

    let historicPricing =
      fund?.historicPricing.length === 1
        ? [
            fund?.historicPricing[0],
            {
              referenceDate: new Date(
                new Date(
                  new Date(fund?.historicPricing[0].referenceDate).getTime() +
                    day,
                ).toISOString(),
              )
                .toISOString()
                .substring(0, 10),
              price: fund?.historicPricing[0].price,
            },
          ]
        : fund?.historicPricing;

    if (locked) historicPricing = [];

    return (
      <div style={{ position: "relative" }} ref={ref}>
        <PageSectionTitleDivider
          selectedSideSelectValue={selectedChart}
          handleSideSelectChange={(value) => {
            setSelectedChart(value as PriceHistoryChartType);
          }}
        >
          {sidebarPositions[priceHistoryKey].label}
        </PageSectionTitleDivider>
        <ParentSize>
          {(parent) => (
            <PriceHistoryChart
              width={parent.width}
              height={300}
              selectedChart={selectedChart}
              iois={iois}
              historicPricing={historicPricing}
              style={{
                ...(locked
                  ? {
                      filter: "blur(7px)",
                      opacity: 0.5,
                    }
                  : {}),
              }}
            />
          )}
        </ParentSize>
        {locked && (
          <VerifyPermissionOverlay
            mainIcon={<CheckCircle size={32} color="#BCB3A5" />}
            buttonText="Verify Permission"
            headerText={"Verify Permission"}
            handleVerifyPermissionClick={() => {
              navigate(
                generatePath(LP_ROUTES.VerifyFundPermissions, {
                  fundId: fund!.id,
                }),
                { replace: true },
              );
            }}
            subHeaderText={
              "To view this data you need to be an existing Limited Partner or an Approved Buyer."
            }
          />
        )}
      </div>
    );
  },
);
