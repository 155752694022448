import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { transactionsClient } from "common/clients/ApolloClient";

import { IFundExposureSummary } from "experiences/transactions/domain/models/FundExposureSummary";

import { GetTransactionFundExposureSummary } from "experiences/transactions/domain/usecases/GetTransactionFundExposureSummaryQuery";

import TapClient from "common/clients/TapClient";

export interface IManagerLogos {
  issuerId: string;
  managerId: string;
}

export const useFundExposureSummary = ({
  transactionId,
  currentReportDate,
  selectedHoldings,
}: {
  transactionId: string;
  currentReportDate: string;
  selectedHoldings: string[];
}) => {
  const [logoIds, setLogoIds] = useState<IManagerLogos[]>([]);
  const { refetch, data, loading } = useQuery<IFundExposureSummary>(
    GetTransactionFundExposureSummary,
    {
      variables: {
        transactionId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldings,
      },
      client: transactionsClient,
      fetchPolicy: "cache-and-network",
      skip: !currentReportDate || !transactionId,
    },
  );

  const refetchIssuerLogos = async ({ issuerIds }: { issuerIds: string[] }) => {
    // for each issuerId, get the logo url but we need to make one api call for each issuerId
    const appropriateIds = await Promise.all(
      issuerIds.map(async (issuerId) => {
        const fund = await new TapClient(`/fund/${issuerId}/`).get({});

        return { issuerId, managerId: fund.managerId };
      }),
    ).then((ids) => ids.filter((id) => !!id.managerId));

    setLogoIds(appropriateIds);
  };

  // if data changes, we'll re-request only the fund ids to another endpoint so that I can get logos
  useEffect(() => {
    const issuerIds = data?.fundSummaryExposure.map((fund) => fund.issuerId);
    if (issuerIds?.length) {
      refetchIssuerLogos({ issuerIds });
    }
  }, [data]);

  // if currentReportDate changes, refetch data
  useEffect(() => {
    if (currentReportDate && transactionId && selectedHoldings.length > 0) {
      refetch({
        transactionId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldings,
      });
    }
  }, [currentReportDate, transactionId, selectedHoldings]);

  return {
    refetch,
    data,
    loading,
    logoIds,
  };
};
