import { Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserPlus } from "@phosphor-icons/react";
import { useParams } from "react-router";

import FormikTextField from "../../../../../common/components/formik/FormikTextField";
import FormikDropdownSelect from "../../../../../common/components/formik/FormikDropdownSelect";
import {
  IParticipant,
  ParticipantCapacity,
  ParticipantCreationRole,
} from "../../../domain/models/Participant";
import { CreateParticipantFormSubmitted } from "../../state/TransactionsEvent";

import { useTransactionsContext } from "../../state/TransactionsContext";
import {
  TapUiModalContent,
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { OrganizationSelector } from "experiences/authentication/presentation/components/OrganizationsSelector";
import {
  EMAIL_ERROR_MESSAGE,
  EMAIL_REGEX,
} from "../../../../../common/constants/validation.ts";

interface IAddParticipantModal {
  open: boolean;
  onClose: () => void;
  actingParticipant: IParticipant | null;
}

export const AddParticipantModal: React.FC<IAddParticipantModal> = ({
  open,
  onClose,
  actingParticipant,
}) => {
  const { emitPcptEvent } = useTransactionsContext();
  const { txnId } = useParams();

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    firmName:
      actingParticipant?.capacity == ParticipantCapacity.Buyer
        ? yup.string()
        : yup.string().required("Required"),
    email: yup
      .string()
      .matches(EMAIL_REGEX, EMAIL_ERROR_MESSAGE)
      .required("Required"),
    role:
      actingParticipant?.capacity == ParticipantCapacity.Buyer
        ? yup.number()
        : yup.number().required("Required"),
  });

  const showFirmField =
    actingParticipant?.capacity != ParticipantCapacity.Buyer;
  const showRoleField =
    actingParticipant?.capacity != ParticipantCapacity.Buyer;

  const formik = useFormik({
    initialValues: {
      name: "",
      firmName: "",
      email: "",
      role: ParticipantCreationRole.SecondaryBuyer,
    },
    validationSchema,
    onSubmit: (values) => {
      var firstName = values.name.split(" ").slice(0, -1).join(" ");
      var lastName = values.name.split(" ").slice(-1).join(" ");

      // if, when creating a participant, if the firm field is hidden,
      // then the organization name should be the same as the acting participant's organization name
      // similarly, if the role field is hidden, then the role should be of a buyer

      const organizationName = showFirmField
        ? values.firmName
        : actingParticipant?.organizationName;

      const role = showRoleField
        ? values.role
        : ParticipantCreationRole.BuyerTeamMember;

      emitPcptEvent!(
        new CreateParticipantFormSubmitted({
          form: {
            firstName,
            lastName,
            transactionId: txnId!,
            organizationName: organizationName,
            email: values.email,
            role: role,
          },
          txnId: txnId!,
          creator: actingParticipant,
        }),
      );
      handleClose();
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className="z-40" // 40 is lower than the default 50 that firm search popover has
    >
      <form onSubmit={formik.submitForm}>
        <TapUiVerticalModalWrapper className="max-w-md">
          <TapUiModalHead
            title="Add Participant"
            subtitle="Add relevant parties to the deal to give them access to the data
              room or the ability to bid."
            onCloseClick={handleClose}
          />
          <TapUiModalContent>
            <FormikTextField
              name="name"
              formik={formik}
              label="Name"
              fullWidth
            />
            <FormikTextField
              name="email"
              formik={formik}
              label="Email"
              fullWidth
            />
            {showFirmField && (
              <>
                <OrganizationSelector
                  key={open ? "open" : "closed"} // re-render the component when the modal is opened/closed
                  onFormFieldChange={(value) =>
                    formik.setFieldValue("firmName", value?.name || "")
                  }
                  errorMessage={
                    formik.touched["firmName"] && formik.errors["firmName"]
                      ? String(formik.errors["firmName"])
                      : undefined
                  }
                  placeholder="Firm"
                />
              </>
            )}
            {showRoleField && (
              <FormikDropdownSelect
                formik={formik}
                name="role"
                label="Role"
                options={[
                  {
                    value: ParticipantCreationRole.MyAdvisor,
                    label: "Advisor",
                  },
                  {
                    value: ParticipantCreationRole.MyTeamMember,
                    label: "Team Member",
                  },
                  {
                    value: ParticipantCreationRole.SecondaryBuyer,
                    label: "Buyer",
                  },
                ]}
              />
            )}
          </TapUiModalContent>

          <Button
            onClick={formik.submitForm}
            fullWidth
            type={BUTTON_TYPES.SECONDARY}
            size="large"
            icon={<UserPlus />}
          >
            Add Participant
          </Button>
        </TapUiVerticalModalWrapper>
      </form>
    </Modal>
  );
};
