import { BUTTON_TYPES, Button } from "common/components/Button";
import { EmptyState } from "common/components/EmptyState";
import {
  ITabTableColumn,
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "../../../../common/TextChip";
import { useTransactionsContext } from "../../state/TransactionsContext";
import {
  CopyInviteLinkButtonClicked,
  RejectButtonClicked,
  SendInviteButtonClicked,
} from "../../state/participants/ParticipantsEvent";

import { Copy, EnvelopeSimple } from "@phosphor-icons/react";
import { TapCheckbox } from "common/components/Checkbox";
import { cn } from "common/utils";
import ReactTimeAgo from "react-time-ago";
import {
  IParticipant,
  ParticipantStatusNameMap,
} from "../../../domain/models/Participant";

const columns: ITabTableColumn[] = [
  {
    key: "firm",
    label: "Firm",
    align: TableAlignment.LEFT,
  },
  {
    key: "name",
    label: "Name",
    align: TableAlignment.LEFT,
  },
  {
    key: "status",
    label: "Status",
    align: TableAlignment.LEFT,
  },
  {
    key: "last_access",
    label: "Last Access",
    align: TableAlignment.LEFT,
  },
  {
    key: "actions",
    label: "",
    align: TableAlignment.LEFT,
  },
];

export const ParticipantsTable = ({
  participants,
  currentParticipant,
  omitCurrentParticipant = false,
  emptyActions,
  handleSendInvite,
  selectParticipants,
  unselectParticipants,
  selectedParticipants,
  userCanSendInvites,
}: {
  participants: IParticipant[];
  currentParticipant?: IParticipant;
  omitCurrentParticipant?: boolean;
  handleSendInvite: () => void;
  selectParticipants: (pcpts: IParticipant[]) => void;
  unselectParticipants: (pcpts: IParticipant[]) => void;
  selectedParticipants: IParticipant[];
  userCanSendInvites: boolean;
  emptyActions?: [
    {
      label: string;
      onClick: () => void;
      type: BUTTON_TYPES;
      icon?: React.ReactNode;
    },
  ];
}) => {
  const { emitPcptEvent } = useTransactionsContext();

  // Do not show anything until the current participant is loaded
  if (!currentParticipant?.id) return null;
  const copyInviteLink = (pcpt: IParticipant) => {
    emitPcptEvent!(
      new CopyInviteLinkButtonClicked({
        pcpt: pcpt,
        txnId: currentParticipant.txnId,
      }),
    );
  };

  const sendInvite = (pcpt: IParticipant) => {
    emitPcptEvent!(
      new SendInviteButtonClicked({
        txnId: currentParticipant.txnId!,
        pcpts: [pcpt],
      }),
    );
  };

  const removeParticipant = (pcpt: IParticipant) => {
    emitPcptEvent!(
      new RejectButtonClicked({
        participantId: pcpt.id,
        txnId: currentParticipant.txnId,
      }),
    );
  };

  const toggleAllChecked = participants.every((pcpt) =>
    selectedParticipants.includes(pcpt),
  );

  const checkAll = () => {
    toggleAllChecked
      ? unselectParticipants(participants)
      : selectParticipants(participants);
  };

  const filteredParticipants = participants.filter((participant) => {
    if (omitCurrentParticipant) {
      return participant.id !== currentParticipant.id;
    }
    return true;
  });

  if (filteredParticipants.length === 0) {
    return (
      <EmptyState
        title="No Participants yet"
        description="Invite people to the transaction or connect with the Tap team to add people."
        actions={emptyActions}
      />
    );
  }

  const handleCheckboxClick = ({
    checked,
    pcpt,
  }: {
    checked: boolean;
    pcpt: IParticipant;
  }) => {
    checked ? selectParticipants([pcpt]) : unselectParticipants([pcpt]);
  };

  return (
    <TapTable
      emptyStateTitle="No Participants yet"
      emptyStateDescription="Invite people to the transaction or connect with the Tap team to add people."
      emptyStateActions={emptyActions}
      onToggleAllClick={userCanSendInvites ? checkAll : undefined}
      toggleAllChecked={userCanSendInvites ? toggleAllChecked : undefined}
      data={filteredParticipants}
      columns={columns}
      skipTopBorder
      renderRow={(participant: IParticipant) => {
        const isSameAsViewer = participant.id === currentParticipant.id;
        const showCheckbox = userCanSendInvites;
        return (
          <>
            <TableCell hasBullet hasCheckbox={showCheckbox}>
              {showCheckbox && (
                <TapCheckbox
                  checked={selectedParticipants.includes(participant)}
                  onChange={(e) =>
                    handleCheckboxClick({
                      // @ts-ignore
                      checked: e.target.checked,
                      pcpt: participant,
                    })
                  }
                  className="table-checkbox"
                />
              )}
              {participant.organizationName}
              {participant.isSuggested && "suggested"}
            </TableCell>
            <TableCell>
              {participant.fullName}

              {isSameAsViewer && (
                <TextChip
                  success
                  text="you"
                  size="small"
                  style={{ marginLeft: 8 }}
                />
              )}
            </TableCell>
            <TableCell>
              {ParticipantStatusNameMap.get(participant.currentPhase)}
            </TableCell>
            <TableCell>
              {participant.txnActions.slice(-1)[0] ? (
                <ReactTimeAgo
                  date={new Date(participant.txnActions.slice(-1)[0].timestamp)}
                  locale="en-US"
                />
              ) : (
                "_"
              )}
            </TableCell>
            <TableCell hasActions>
              <div
                className={cn("flex flex-row gap-2", {
                  hidden: isSameAsViewer,
                })}
              >
                <Button
                  onClick={() => removeParticipant(participant)}
                  disabled={isSameAsViewer}
                >
                  Remove
                </Button>
                <Button
                  icon={<Copy />}
                  onClick={() => copyInviteLink(participant)}
                  disabled={!userCanSendInvites}
                >
                  Copy Invite Link
                </Button>
                <Button
                  icon={<EnvelopeSimple />}
                  onClick={() => sendInvite(participant)}
                  disabled={!userCanSendInvites}
                >
                  Email Invite
                </Button>
              </div>
            </TableCell>
          </>
        );
      }}
    />
  );
};
