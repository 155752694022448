import { useState, useEffect } from "react";

import { Fund } from "experiences/funds/domain/models/Fund";

import { useFundsContext } from "experiences/funds/presentation/state/FundsContext";

import {
  VIEWPOINT_PARTNERS_DEMO_DATA,
  VIEWPOINT_PARTNERS_DEMO_NAMES,
} from "experiences/funds/presentation/components/viewpoint/viewpointOverview";

export const isFundViewpoint = (fund?: Fund) => {
  if (!fund) {
    return false;
  }
  return VIEWPOINT_PARTNERS_DEMO_NAMES.includes(fund.name);
};

export const useViewFund = ({ fundId }: { fundId?: string }) => {
  const [loading, setLoading] = useState(false);
  const [showChatSidebar, setShowChatSidebar] = useState(false);
  const [fund, setFund] = useState<Fund | undefined>();
  const { retrieveSingleFund } = useFundsContext();

  const handleShowChatSidebar = () => {
    setShowChatSidebar(true);
  };

  const handleHideChatSidebar = () => {
    setShowChatSidebar(false);
  };

  const loadFund = async (id: string) => {
    setLoading(true);

    retrieveSingleFund
      .call({
        id,
      })
      .then((result) => {
        if (result._tag === "Right") {
          const f = result.right;

          if (VIEWPOINT_PARTNERS_DEMO_NAMES.includes(f.name)) {
            // Note: We overwrite the demo ID so that we don't actually replace the fund id you're viewing
            setFund({
              ...VIEWPOINT_PARTNERS_DEMO_DATA,
              id: f.id,
            });
            return;
          }

          // save fund to hook state so that it's available to the entire hook scope
          setFund(result.right);
        } else {
          throw new Error("Failed to load fund");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fundId) {
      loadFund(fundId);
    }
  }, [fundId]);

  return {
    showChatSidebar,
    handleShowChatSidebar,
    handleHideChatSidebar,
    fund,
    loaded: fund?.name || !loading,
  };
};
