import { DefaultFundIcon } from "experiences/common/DefaultFundIcon";
import { IRelatedParties } from "experiences/funds/domain/models/FundRelatedParties";

export const viewpointRelatedParties: IRelatedParties = {
  parties: [
    {
      name: "ViewPoint Partners Fund I",
    },
    {
      name: "ViewPoint Partners Fund II",
    },
    {
      name: "ViewPoint Partners Fund III",
    },
  ],
  serviceProviders: [
    {
      name: "Haden Carps LLP",
    },
    {
      name: "EE&G",
    },
  ],
  managers: [
    {
      name: "Thomas Walsh",
      party: "Managing Partner",
      icon: DefaultFundIcon,
    },
    {
      name: "Huang Lee",
      party: "Managing Partner",
      icon: DefaultFundIcon,
    },
    {
      name: "Maria Bohmont",
      party: "Vice President",
      icon: DefaultFundIcon,
    },
    {
      name: "Isador Fielding",
      party: "Senior Associate",
      icon: DefaultFundIcon,
    },
  ],
};
