import { Navigate, Route, Routes } from "react-router";

import { TransactionsStateProvider } from "experiences/transactions/presentation/state/TransactionsContext";

import { ApprovedBuyers } from "experiences/approved-buyers/presentation/pages/ApprovedBuyers";
import { ComplianceWrapper } from "experiences/compliance/presentation/pages/Compliance";
import { MarketMonitor } from "experiences/transactions/presentation/pages/MarketMonitor";

import { ContentWrapper } from "common/components/WidthWrapper";
import { RequireAuth } from "experiences/authentication/presentation/components/RequireAuth";
import { GP_ROUTES } from "common/constants/routes";
import { FirmTeamMembers } from "experiences/firm/presentation/pages/TeamMembers";
import { FirmFunds } from "experiences/firm/presentation/pages/FirmFunds";
import { FirmStateProvider } from "experiences/firm/presentation/state/FirmContext";
import { ApprovedBuyersStateProvider } from "experiences/approved-buyers/presentation/state/ApprovedBuyersContext";
import { TransactionSettingsNDAs } from "experiences/transactions/presentation/pages/TransactionSettingsNDAs";
import { TransactionSettingsAgreements } from "experiences/transactions/presentation/pages/TransactionSettingsAgreements";
import { TransactionSettingsPolicies } from "experiences/transactions/presentation/pages/TransactionSettingsPolicies";
import { DashboardStateProvider } from "experiences/dashboard/presentation/state/DashboardContext";
import { GpDashboardPage } from "experiences/dashboard/presentation/gp/GpDashboardPage";
import { GpTransactionDetailPage } from "experiences/transactions/presentation/gp/GpTransactionDetailPage";
import { RequestedBuyers } from "experiences/approved-buyers/presentation/pages/RequestedBuyers";
import { BlockedBuyers } from "experiences/approved-buyers/presentation/pages/BlockedBuyers";
import { ArchivedBuyers } from "experiences/approved-buyers/presentation/pages/ArchivedBuyers";
import { TransactionList } from "experiences/transactions/presentation/gp/GpTransactionListPage";
import { GpLimitedPartnersStateProvider } from "experiences/limited-partners/presentation/state/GpLimitedPartnersContext";
import { GpLimitedPartnersWrapper } from "experiences/limited-partners/presentation/pages/GpLimitedPartnersWrapper";
import { PortfolioStateProvider } from "experiences/portfolio/presentation/state/PortfolioContext";
import { ActiveIndicationsOfInterest } from "experiences/dashboard/presentation/pages/ActiveIndicationsOfInterest";

import AppLayout from "./AppLayout";
import { UniversalRoutes } from "./UniversalRoutes";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import Loader from "common/components/TapLoader";

export const GPRoutes = () => {
  const { userIsLoading } = useAuthContext();

  if (userIsLoading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path={`${GP_ROUTES.Dashboard}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <DashboardStateProvider>
                  <ContentWrapper fullWidth>
                    <GpDashboardPage />
                  </ContentWrapper>
                </DashboardStateProvider>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.DashboardNewIois}/*`}
          element={
            <RequireAuth>
              <DashboardStateProvider>
                <ActiveIndicationsOfInterest />
              </DashboardStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.TransactionList}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TransactionList />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.FirmTeamMembers}/*`}
          element={
            <RequireAuth>
              <FirmStateProvider>
                <ContentWrapper fullWidth>
                  <FirmTeamMembers />
                </ContentWrapper>
              </FirmStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.FirmFunds}/*`}
          element={
            <RequireAuth>
              <FirmStateProvider>
                <ContentWrapper fullWidth>
                  <FirmFunds />
                </ContentWrapper>
              </FirmStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.Firm}/*`}
          element={<Navigate to={GP_ROUTES.FirmTeamMembers} replace />}
        />

        <Route
          path={`${GP_ROUTES.ApprovedBuyers}/*`}
          element={<Navigate to={GP_ROUTES.ApprovedBuyersApproved} replace />}
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersApproved}/*`}
          element={
            <RequireAuth>
              <ApprovedBuyersStateProvider>
                <ContentWrapper fullWidth>
                  <ApprovedBuyers />
                </ContentWrapper>
              </ApprovedBuyersStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersRequested}/*`}
          element={
            <RequireAuth>
              <ApprovedBuyersStateProvider>
                <ContentWrapper fullWidth>
                  <RequestedBuyers />
                </ContentWrapper>
              </ApprovedBuyersStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersBlocked}/*`}
          element={
            <RequireAuth>
              <ApprovedBuyersStateProvider>
                <ContentWrapper fullWidth>
                  <BlockedBuyers />
                </ContentWrapper>
              </ApprovedBuyersStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersArchived}/*`}
          element={
            <RequireAuth>
              <ApprovedBuyersStateProvider>
                <ContentWrapper fullWidth>
                  <ArchivedBuyers />
                </ContentWrapper>
              </ApprovedBuyersStateProvider>
            </RequireAuth>
          }
        />

        <Route
          path={`${GP_ROUTES.TransactionDetail}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <GpTransactionDetailPage />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />

        <Route
          path={`${GP_ROUTES.TransactionSettings}/*`}
          element={
            <Navigate
              to={GP_ROUTES.TransactionSettingsTransactionPolicies}
              // replace
              state={{ from: GP_ROUTES.TransactionSettings }}
            />
          }
        />
        <Route
          path={`${GP_ROUTES.TransactionSettingsTransactionPolicies}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TransactionSettingsPolicies />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.TransactionsSettingsNDAs}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TransactionSettingsNDAs />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.TransactionsSettingsTransferAgreements}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TransactionSettingsAgreements />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.Compliance}/*`}
          element={
            <RequireAuth>
              <ContentWrapper fullWidth>
                <ComplianceWrapper />
              </ContentWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.MarketMonitor}/*`}
          element={
            <RequireAuth>
              <ContentWrapper fullWidth>
                <MarketMonitor />
              </ContentWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={`${GP_ROUTES.LimitedPartners}/*`}
          element={
            <RequireAuth>
              <PortfolioStateProvider>
                <GpLimitedPartnersStateProvider>
                  <ContentWrapper fullWidth>
                    <GpLimitedPartnersWrapper />
                  </ContentWrapper>
                </GpLimitedPartnersStateProvider>
              </PortfolioStateProvider>
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<UniversalRoutes />} />
    </Routes>
  );
};
