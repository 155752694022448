import {
  Buyer,
  GpDashboardTransaction,
  LimitedPartner,
} from "experiences/dashboard/mock/GP";
import { Index } from "experiences/funds/domain/models/Index";
import { IIndicationOfInterest } from "experiences/indications-of-interest/domain/models/IOI";
import { ITransaction } from "experiences/transactions/domain/models/Transaction";

export class DashboardState {}

export class DashboardLoading extends DashboardState {}

// when we have the actual component handling error states as part of its markup, we will need this state but
// currently we handle errors in the manager itself using notistack and that's handling its own presentation
// export class DashboardFailedLoading extends DashboardState {}

export class MockDashboardLoaded extends DashboardState {
  canSignPlatformNDA: boolean;
  transactionList: ITransaction[];

  constructor({
    canSignPlatformNDA,
    tranasctionList,
  }: {
    canSignPlatformNDA: boolean;
    tranasctionList: ITransaction[];
  }) {
    super();
    this.transactionList = tranasctionList;
    this.canSignPlatformNDA = canSignPlatformNDA;
  }
}

export class MockDashboardLoadedForGP extends DashboardState {
  canSignPlatformNDA: boolean;
  shouldApproveBuyers: boolean;
  buyersList: Buyer[];
  limitedPartners: LimitedPartner[];
  transactionsList: GpDashboardTransaction[]; // for dashboard

  constructor({
    canSignPlatformNDA,
    shouldApproveBuyers,
    buyersList,
    limitedPartners,
    transactionsList,
  }: {
    canSignPlatformNDA: boolean;
    shouldApproveBuyers: boolean;
    buyersList: Buyer[];
    limitedPartners: LimitedPartner[];
    transactionsList: GpDashboardTransaction[];
  }) {
    super();
    this.canSignPlatformNDA = canSignPlatformNDA;
    this.shouldApproveBuyers = shouldApproveBuyers;
    this.buyersList = buyersList;
    this.limitedPartners = limitedPartners;
    this.transactionsList = transactionsList;
  }
}
` `;
export class ActiveRecentIoisState extends DashboardState {}

export class ActiveRecentIoisLoaded extends ActiveRecentIoisState {
  iois: IIndicationOfInterest[];

  constructor({ iois }: { iois: IIndicationOfInterest[] }) {
    super();
    this.iois = iois;
  }
}

export class IndexesLoaded extends DashboardState {
  public readonly indexes: Index[];

  constructor({ indexes }: { indexes: Index[] }) {
    super();
    this.indexes = indexes;
  }
}
