import { IIconProps, SvgIconWrapper } from "./Icon";

const Target: React.FC<IIconProps> = (props) => {
  return (
    <SvgIconWrapper
      clipPathId="clip0_182_1333"
      strokeWidth={1}
      pathDs={[
        "M4.5 8.85001C6.57107 8.85001 8.25 7.17107 8.25 5.10001C8.25 3.02894 6.57107 1.35001 4.5 1.35001C2.42893 1.35001 0.75 3.02894 0.75 5.10001C0.75 7.17107 2.42893 8.85001 4.5 8.85001Z",
        "M8.25 5.10001H6.75",
        "M2.25 5.10001H0.75",
        "M4.5 2.85001V1.35001",
        "M4.5 8.85001V7.35001",
      ]}
      width={10}
      height={10}
      {...props}
    ></SvgIconWrapper>
  );
};

export default Target;
