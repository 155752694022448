"use client";

import * as React from "react";
import { Calendar as CalendarIcon } from "@phosphor-icons/react";
import { DateRange } from "react-day-picker";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { cn } from "common/utils";
import { Range } from "experiences/common/models/Range";
import { DateFormatter } from "common/@types/app/DateFormatter";

interface DatePickerWithRangeProps {
  initialRange?: Range<Date>;
  className?: string;
  value?: Range<Date>;
  onChange?: (value: Range<Date>) => void;
  errorMessage?: string;
}

export function DatePickerWithRange({
  className,
  initialRange,
  value,
  onChange,
  errorMessage,
}: DatePickerWithRangeProps) {
  const [date, setDate] = React.useState<DateRange | undefined>(
    initialRange
      ? {
          from: initialRange.start,
          to: initialRange.end,
        }
      : undefined,
  );

  React.useEffect(() => {
    if (value) {
      setDate({
        from: value.start,
        to: value.end,
      });
    }
  }, [value]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal w-full",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {new DateFormatter(date.from.toISOString()).prettyDate()} -{" "}
                  {new DateFormatter(date.to.toISOString()).prettyDate()}
                </>
              ) : (
                new DateFormatter(date.from.toISOString()).prettyDate()
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(newDate) =>
              onChange({
                start: newDate.from,
                end: newDate.to,
              })
            }
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
      <p className="text-xs text-red-500">{errorMessage ?? ""}</p>
    </div>
  );
}
