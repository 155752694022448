import { useMutation } from "@apollo/client";
import { ArrowRight } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shadcn/ui/select";

import { portfolioClient } from "common/clients/ApolloClient";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { useSendSlackMessage } from "common/hooks/useSendSlackMessage";
import { uploadFilesToS3 } from "common/utils/uploadFileS3";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { DropZone } from "experiences/common/DropZone";
import { IPortfolioEntity } from "experiences/portfolio-v2/domain/models/PortfolioEntities";
import {
  GetEntityDocUploadLinkInput,
  UploadPortfolioDocumentsMutation,
} from "experiences/portfolio-v2/domain/usecases/PostPortfolioDocumentsMutation";
import { PortfolioContext } from "../state/PorfolioV2Context";

interface IUploadPortfolioDocumentsMutation {
  uploadEntityProcessingDocuments: {
    entityId: string;
    urls: {
      name: string;
      url: string;
      fields: {
        key: string;
        AWSAccessKeyId: string;
        policy: string;
        signature: string;
      };
    }[];
  }[];
}
interface IUploadPortfolioDocumentsMutationVariables {
  docs: GetEntityDocUploadLinkInput[];
}

const UploadDocumentStepWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="max-w-2xl flex flex-col gap-8 h-full py-12 px-4">
      <div className="flex-1 flex flex-col gap-8">{children}</div>
    </div>
  );
};

const SelectEntityDropdown = ({
  entities,
  selectedEntity,
  setSelectedEntity,
  disabled,
  loading,
}: {
  entities: IPortfolioEntity[];
  selectedEntity?: IPortfolioEntity | null;
  setSelectedEntity: (entity: IPortfolioEntity) => void;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const onValueChange = (value: string) => {
    setSelectedEntity(entities.find((entity) => entity.id === value) || null);
  };

  return (
    <div>
      <Select
        onValueChange={onValueChange}
        value={selectedEntity?.id}
        disabled={disabled}
      >
        <SelectTrigger className="w-full h-10" disabled={loading}>
          <SelectValue placeholder="Select an entity" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {entities.map((entity) => (
              <SelectItem key={entity.id} value={entity.id}>
                {entity.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export const UploadDocumentsStep0 = ({
  goToNextStep,
}: {
  goToNextStep: () => void;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const { user } = useAuthContext();
  const { sendMessage } = useSendSlackMessage();
  const { entities, setUploadEntity, uploadEntity } =
    useContext(PortfolioContext);

  useEffect(() => {
    if (entities.length > 0) {
      setUploadEntity(entities[0]);
    }
  }, [entities]);

  const [uploadDocuments, { loading: mutationLoading }] = useMutation<
    IUploadPortfolioDocumentsMutation,
    IUploadPortfolioDocumentsMutationVariables
  >(UploadPortfolioDocumentsMutation, {
    client: portfolioClient,
  });

  const handleFileChange = (newFiles: File[]) => {
    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else {
      setFiles([]);
    }
  };

  const handleContinueClick = async () => {
    if (!uploadEntity) {
      toast.error("No default entity found, please contact support.");
      return;
    }

    // Step 1: Call the mutation to upload the files, this will return a signed URL for each file
    const response = await uploadDocuments({
      variables: {
        docs: files.map((file) => ({
          fileName: file.name,
          entityId: uploadEntity.id,
        })),
      },
    });

    // Step 2: Upload the files to the signed URL

    setUploadingFiles(true);
    const fileCount = files.length;
    console.log(response?.data);
    console.log(
      response?.data?.uploadEntityProcessingDocuments
        ?.map((doc) => doc.urls)
        .flat(),
    );
    const r = await uploadFilesToS3({
      files,
      fileParams:
        response?.data?.uploadEntityProcessingDocuments
          ?.map((doc) => doc.urls)
          .flat() || [],
    })
      .then(() => {
        toast.success("Files have been uploaded successfully!");
        goToNextStep();
      })
      .catch(() => {
        toast.error("Failed to upload files, please try again.");
      })
      .finally(() => {
        setUploadingFiles(false);
        console.log("uploading files finished", { fileCount, r });

        // TODO: send slack message, maybe to a new channel???
        // sendMessage({
        //   blocks: [
        //     generateSlackMessagePayload({
        //       title: `${user.firstName} ${user.lastName} has uploaded ${
        //         fileCount === 1 ? "a file" : `${fileCount} files`
        //       } to transaction documents.`,
        //       fields: generateObjectAsFields({
        //         "Holding ID": defaultHolding.id,
        //         "Holding Name": defaultHolding.issuerName,
        //         // Generate one entry for each file
        //         "File Names": files
        //           .map((file) => {
        //             return file.name;
        //           })
        //           .join(", "),
        //       }),
        //     }),
        //   ],
        // });
      });
  };

  return (
    <UploadDocumentStepWrapper>
      <div className="flex flex-col">
        <PageHead
          paddingless
          title="Upload LP Documents"
          description={
            <>
              Confidentially upload fund documents to get access to detailed
              extracted data. If you would like to sign a mutual NDA prior to
              upload you can do so on the Dashboard.
            </>
          }
        />
      </div>
      <SelectEntityDropdown
        entities={entities}
        selectedEntity={uploadEntity}
        setSelectedEntity={setUploadEntity}
      />
      <DropZone
        className="h-64 max-h-64 pb-4"
        filesClassName="max-h-80 w-full max-w-full no-scrollbar overflow-scroll pb-4"
        onFileChange={handleFileChange}
        multiple
        compactFileList
        showRemoveButton
        accept={["pdf"]}
        acceptDropzone={{
          "application/pdf": [".pdf", ".PDF"],
          "application/x-pdf": [".pdf", ".PDF"],
        }}
        customMessage={
          <>
            Drop or <span className="link">choose file</span> to upload your
            Fund Documents
          </>
        }
      />

      <div className="flex flex-row justify-end">
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size="medium_large"
          onClick={handleContinueClick}
          disabled={files.length === 0 || mutationLoading || uploadingFiles}
          icon={<ArrowRight />}
          loading={mutationLoading || uploadingFiles}
          iconRight
        >
          Continue
        </Button>
      </div>
    </UploadDocumentStepWrapper>
  );
};
