import { IFundExposureSummary } from "experiences/transactions/domain/models/FundExposureSummary";

// prettier-ignore
export const fundExposureSummaryMock: IFundExposureSummary = {
  fundSummaryExposure: [
    { tapFundId: "c9f5ed6f", fundName: "Tap Capital CWO", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null },
    { tapFundId: "bc0c5ef4", fundName: "Tap Capital 5XF", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null },
    { tapFundId: "81e05ecd", fundName: "Tap Capital TZP", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null },
    { tapFundId: "b45271de", fundName: "Tap Capital SD5", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null },
    { tapFundId: "8f42687b", fundName: "Tap Capital GGN", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null },
    { tapFundId: "3cb59efd", fundName: "Tap Capital PXG", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null },
  ],
};

// prettier-ignore
export const fundExposureSummaryGqlMock: IFundExposureSummary = {
  fundSummaryExposure: [
    { tapFundId: "Test B", fundName: "Tap Capital VII", tapManagerId: "Test A", managerName: "Tap Capital", fundSize: "100000000.00", fundCommittedCapital: "12.00", strategy: "VentureCapital", vintage: 2032, geography: "NorthAmerica", reportDate: "2024-06-30", lpCommittedCapital: "12.00", lpContributedCapitalItd: "12.00", lpDistributedCapitalItd: "12.00", lpRemainingUnfunded: "12.00", lpEndingCapitalAccountValue: "12.00", lpCurrentExposure: null, lpInterimContributions: null, lpInterimDistributions: null, unrealizedInvestmentCount: "12", top3Concentration: "12.00", top10Concentration: "12.00", percentPublic: "12.00", weightedAvgRevenueGrowth: "12.00", weightedAvgEbitdaMargin: "12.00", weightedAvgEbitdaMultiple: "12.00", weightedAvgLeverage: "12.00", fundNavQoqPercentChg1q: "21.00", fundNavQoqPercentChg2q: "12.00", fundNavQoqPercentChg3q: "12.00", fundNavYoyPercentChangeFy1: "12.00", fundNavYoyPercentChangeFy2: "12.00", tapPriceEstimate: "100.00", tapPriceEstimateCertainty: "ThreeFourths", baseManagementFee: "10.00", carry: "80.00", hurdleRate: "80.00", ptpRiskLevel: 3, ptpQueueLength: 1, rofr: false, rofo: true, blocker: true, domicile: "United States", fundSeriesAverageMoic: "a", isHumanVerified: null, transactionPercent: "100.00" },
  ],
};

export enum fundExposureSummaryKeys {
  fundName = "fundName",
  transactionPercent = "transactionPercent",
  tapFundId = "tapFundId",
  tapManagerId = "tapManagerId",
  managerName = "managerName",
  fundSize = "fundSize",
  fundCommittedCapital = "fundCommittedCapital",
  strategy = "strategy",
  vintage = "vintage",
  geography = "geography",
  reportDate = "reportDate",
  lpCommittedCapital = "lpCommittedCapital",
  lpContributedCapitalItd = "lpContributedCapitalItd",
  lpDistributedCapitalItd = "lpDistributedCapitalItd",
  lpRemainingUnfunded = "lpRemainingUnfunded",
  lpEndingCapitalAccountValue = "lpEndingCapitalAccountValue",
  lpCurrentExposure = "lpCurrentExposure",
  lpInterimContributions = "lpInterimContributions",
  lpInterimDistributions = "lpInterimDistributions",
  unrealizedInvestmentCount = "unrealizedInvestmentCount",
  top3Concentration = "top3Concentration",
  top10Concentration = "top10Concentration",
  percentPublic = "percentPublic",
  weightedAvgRevenueGrowth = "weightedAvgRevenueGrowth",
  weightedAvgEbitdaMargin = "weightedAvgEbitdaMargin",
  weightedAvgEbitdaMultiple = "weightedAvgEbitdaMultiple",
  weightedAvgLeverage = "weightedAvgLeverage",
  fundNavQoqPercentChg1q = "fundNavQoqPercentChg1q",
  fundNavQoqPercentChg2q = "fundNavQoqPercentChg2q",
  fundNavQoqPercentChg3q = "fundNavQoqPercentChg3q",
  fundNavYoyPercentChangeFy1 = "fundNavYoyPercentChangeFy1",
  fundNavYoyPercentChangeFy2 = "fundNavYoyPercentChangeFy2",
  tapPriceEstimate = "tapPriceEstimate",
  tapPriceEstimateCertainty = "tapPriceEstimateCertainty",
  baseManagementFee = "baseManagementFee",
  carry = "carry",
  hurdleRate = "hurdleRate",
  ptpRiskLevel = "ptpRiskLevel",
  ptpQueueLength = "ptpQueueLength",
  rofr = "rofr",
  rofo = "rofo",
  blocker = "blocker",
  domicile = "domicile",
  fundSeriesAverageMoic = "fundSeriesAverageMoic",
  isHumanVerified = "isHumanVerified",
}

// prettier-ignore
export const fundExposureSummaryLabels = {
  [fundExposureSummaryKeys.fundName]: "Fund Name",
  [fundExposureSummaryKeys.transactionPercent]: "Transaction Percent",
  [fundExposureSummaryKeys.tapFundId]: "Tap Fund ID",
  [fundExposureSummaryKeys.tapManagerId]: "Tap Manager ID",
  [fundExposureSummaryKeys.managerName]: "Manager Name",
  [fundExposureSummaryKeys.fundSize]: "Fund Size",
  [fundExposureSummaryKeys.fundCommittedCapital]: "Fund Committed Capital",
  [fundExposureSummaryKeys.strategy]: "Strategy",
  [fundExposureSummaryKeys.vintage]: "Vintage",
  [fundExposureSummaryKeys.geography]: "Geography",
  [fundExposureSummaryKeys.reportDate]: "Report Date",
  [fundExposureSummaryKeys.lpCommittedCapital]: "LP Committed Capital",
  [fundExposureSummaryKeys.lpContributedCapitalItd]: "LP Contributed Capital ITD",
  [fundExposureSummaryKeys.lpDistributedCapitalItd]: "LP Distributed Capital ITD",
  [fundExposureSummaryKeys.lpRemainingUnfunded]: "LP Remaining Unfunded",
  [fundExposureSummaryKeys.lpEndingCapitalAccountValue]: "LP Ending Capital Account Value",
  [fundExposureSummaryKeys.lpCurrentExposure]: "LP Current Exposure",
  [fundExposureSummaryKeys.lpInterimContributions]: "LP Interim Contributions",
  [fundExposureSummaryKeys.lpInterimDistributions]: "LP Interim Distributions",
  [fundExposureSummaryKeys.unrealizedInvestmentCount]: "Unrealized Investment Count",
  [fundExposureSummaryKeys.top3Concentration]: "Top 3 Concentration",
  [fundExposureSummaryKeys.top10Concentration]: "Top 10 Concentration",
  [fundExposureSummaryKeys.percentPublic]: "Percent Public",
  [fundExposureSummaryKeys.weightedAvgRevenueGrowth]: "Weighted Avg Revenue Growth",
  [fundExposureSummaryKeys.weightedAvgEbitdaMargin]: "Weighted Avg EBITDA Margin",
  [fundExposureSummaryKeys.weightedAvgEbitdaMultiple]: "Weighted Avg EBITDA Multiple",
  [fundExposureSummaryKeys.weightedAvgLeverage]: "Weighted Avg Leverage",
  [fundExposureSummaryKeys.fundNavQoqPercentChg1q]: "Fund Nav QoQ Percent Change 1Q",
  [fundExposureSummaryKeys.fundNavQoqPercentChg2q]: "Fund Nav QoQ Percent Change 2Q",
  [fundExposureSummaryKeys.fundNavQoqPercentChg3q]: "Fund Nav QoQ Percent Change 3Q",
  [fundExposureSummaryKeys.fundNavYoyPercentChangeFy1]: "Fund Nav YoY Percent Change Fy1",
  [fundExposureSummaryKeys.fundNavYoyPercentChangeFy2]: "Fund Nav YoY Percent Change Fy2",
  [fundExposureSummaryKeys.tapPriceEstimate]: "Tap Price Estimate",
  [fundExposureSummaryKeys.tapPriceEstimateCertainty]: "Tap Price Estimate Certainty",
  [fundExposureSummaryKeys.baseManagementFee]: "Base Management Fee",
  [fundExposureSummaryKeys.carry]: "Carry",
  [fundExposureSummaryKeys.hurdleRate]: "Hurdle Rate",
  [fundExposureSummaryKeys.ptpRiskLevel]: "PTP Risk Level",
  [fundExposureSummaryKeys.ptpQueueLength]: "PTP Queue Length",
  [fundExposureSummaryKeys.rofr]: "ROFR",
  [fundExposureSummaryKeys.rofo]: "ROFO",
  [fundExposureSummaryKeys.blocker]: "Blocker",
  [fundExposureSummaryKeys.domicile]: "Domicile",
  [fundExposureSummaryKeys.fundSeriesAverageMoic]: "Fund Series Average MOIC",
  [fundExposureSummaryKeys.isHumanVerified]: "Is Human Verified",
}
