import { useState } from "react";
import { cn } from "common/utils";

import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";
import { Button } from "common/components/Button";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "shadcn/ui/Command";
import { TransactionHolding } from "./useTransactionHoldings";
import { CaretUpDown } from "@phosphor-icons/react";

export const SelectFundHoldingsDropdown = ({
  onSelect,
  value,
  handleAddHoldingClick,
  holdings,
  className,
  fileId,
}: {
  onSelect: (holding: TransactionHolding) => void;
  value?: string | number;
  handleAddHoldingClick: ({ fileId }: { fileId: string }) => void;
  holdings: TransactionHolding[];
  className?: string;
  fileId: string;
}) => {
  const [open, setOpen] = useState(false);

  const selectedHolding = holdings.find((holding) => holding.id === value);

  return (
    <div className={cn("relative w-full", className)}>
      <Popover
        open={open}
        onOpenChange={setOpen}
        defaultOpen
        modal={false} // this one is required so that it doesn't cause a bug with the AddFundInterestModal https://github.com/radix-ui/primitives/issues/1496
      >
        <PopoverTrigger className="w-full">
          {value && selectedHolding ? (
            <Button
              role="combobox"
              className="!w-full !justify-between overflow-clip h-10"
              aria-expanded={open}
              onClick={() => setOpen((prev) => !prev)}
            >
              {selectedHolding.issuerName}
              <CaretUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          ) : (
            <Button
              role="combobox"
              className="!w-full !justify-between h-10 !shadow-none !font-normal !text-sm"
              aria-expanded={open}
              onClick={() => setOpen((prev) => !prev)}
            >
              Select Fund Interest
              <CaretUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="p-0 !relative !z-50 !translate-x-0 !translate-y-0 popover-content-same-trigger-width">
          <Command className="relative z-50 !w-full">
            {/* <CommandInput placeholder="Select Fund Interest" /> */}
            <CommandList>
              <CommandGroup>
                {holdings.map((option) => {
                  const label = option.issuerName;
                  return (
                    <CommandItem
                      key={option.id}
                      value={option.issuerName}
                      onSelect={() => {
                        onSelect(option);
                        setOpen(false);
                      }}
                    >
                      {label}
                    </CommandItem>
                  );
                })}
                <CommandItem
                  key={"other"}
                  onSelect={() => {
                    handleAddHoldingClick({ fileId });
                    setOpen((prev) => !prev);
                  }}
                  forceMount
                >
                  Don’t see the fund interest?{" "}
                  <span className="underline ml-2">Add it</span>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
