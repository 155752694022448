import { Alert } from "../../domain/models/Alert";
import { Fund } from "../../domain/models/Fund";
import { FundsPage } from "../../domain/models/FundsPage";

export class FundsState {}

export interface CheckedFundsState {
  checkedFundIds: string[];
  isEntirePageChecked: boolean;
}

export class FundScreenState extends FundsState {
  checkedFunds: CheckedFundsState;

  constructor({ checkedFunds }: { checkedFunds: CheckedFundsState }) {
    super();
    this.checkedFunds = checkedFunds;
  }
}

export class AlertsScreenState extends FundsState {}

export class ScreenResultsLoaded extends FundScreenState {
  fundsPage: FundsPage;
  watchlistLoadingFundIds: string[];

  constructor({
    fundsPage,
    checkedFunds,
    watchlistLoadingFundIds,
  }: {
    watchlistLoadingFundIds: string[];
    fundsPage: FundsPage;
    checkedFunds: CheckedFundsState;
  }) {
    super({ checkedFunds });
    this.watchlistLoadingFundIds = watchlistLoadingFundIds;
    this.fundsPage = fundsPage;
  }
}

export class ScreenResultsLoadingInPorgress extends FundScreenState {}

export class ScreenResultsLoadingFailed extends FundScreenState {}

export class AlertCreatedSuccessfully extends FundsState {}

export class AlertCreationFailure extends FundsState {}

export class AlertsPageLoaded extends AlertsScreenState {
  alerts: Alert[];
  alertIdsToggleInProgress: string[];

  constructor({
    alerts,
    alertIdsToggleInProgress,
  }: {
    alerts: Alert[];
    alertIdsToggleInProgress: string[];
  }) {
    super();
    this.alerts = alerts;
    this.alertIdsToggleInProgress = alertIdsToggleInProgress;
  }
}

export class AlertsPageFailedToLoad extends AlertsScreenState {}

export class AlertsPageLoading extends AlertsScreenState {}

export class WatchlistLoaded extends FundScreenState {
  funds: Fund[];

  constructor({
    funds,
    checkedFunds,
  }: {
    funds: Fund[];
    checkedFunds: CheckedFundsState;
  }) {
    super({ checkedFunds });
    this.funds = funds;
  }
}

export class MultiSelectActionBeltState extends FundsState {}

export class MultiSelectActionBeltLoaded extends MultiSelectActionBeltState {
  bulkWatchlistToggeledOn: boolean;

  constructor({
    bulkWatchlistToggeledOn,
  }: {
    bulkWatchlistToggeledOn: boolean;
  }) {
    super();
    this.bulkWatchlistToggeledOn = bulkWatchlistToggeledOn;
  }
}

export class MultiSelectActionBeltNotLoaded extends MultiSelectActionBeltState {}

export class VerifyPermissionState extends FundsState {}

export class ShowAccessSelectionScreen extends VerifyPermissionState {}

export class ShowConfirmHoldingsScreen extends VerifyPermissionState {
  fund: Fund;

  constructor({ fund }: { fund: Fund }) {
    super();
    this.fund = fund;
  }
}

export class ShowGPAccessRequestReceiptScreen extends VerifyPermissionState {}
