import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useLocation,
  useParams,
} from "react-router";
import { NavLink } from "react-router-dom";

import { useAuthContext } from "../../../../authentication/presentation/state/AuthenticationContext";
import { RowTabNavLinkWrapper } from "experiences/authentication/presentation/components/RowTabWrapper";
import { baseTransactionPathForExperienceType } from "Routes";
import { BarLoader } from "common/components/BarLoader";
import { TransactionDetailsRoutes } from "../TransactionDetails";
import { TransactionFundDataSummary } from "./TransactionFundDataSummary";
import { TransactionFundDataVerifyPermissionFlow } from "./TransactionFundDataVerifyPermission";
import { CapitalAccountsTab } from "./capitalaccounts/CapitalAccountsTab";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { useFundDataStatus } from "./useFundDataStatus";
import { isTxnIdQuay } from "./utils";
import { HoldingSelector } from "./HoldingSelector";
import { CommentaryTab } from "./commentary/CommentaryTab";
import { InvestmentsTab } from "./investments/InvestmentsTab";
import { InterimCashflowsTab } from "./intermin-cashflows/InterimCashflowsTab";
import { DocumentsTab } from "./documents/DocumentsTab";

import DataRoom from "../data-room/DataRoom";
import { useShowNewDocumentsTab } from "./useShowNewDocumentsTab";
import { TransactionFundDataProvider } from "../../state/TransactionFundDataContext";

export enum TransactionFundDataRoutes {
  Summary = "/summary",
  Documents = "/documents",
  Investments = "/investments",
  Commentary = "/commentary",
  CapitalAccounts = "/capital-accounts",
  VerifyPermission = "/verify-permission",
  InterimCashflows = "/interim-cashflows",
}

// https://usetap.jetbrains.space/p/main/issues/653
const PROJECT_PYTHON = "ZyClGbQjhYCZK";
// https://usetap.jetbrains.space/p/main/issues/653
const HIDE_SUMMARY_FROM_TRANSACTION_IDS = [PROJECT_PYTHON];
const HIDE_CAPITAL_ACCOUNTS_FROM_TRANSACTION_IDS = [PROJECT_PYTHON];
const HIDE_INVESTMENTS_FROM_TRANSACTION_IDS = [PROJECT_PYTHON];
const HIDE_COMMENTARY_FROM_TRANSACTION_IDS = [PROJECT_PYTHON];
const HIDE_INTERIM_CASHFLOWS_FROM_TRANSACTION_IDS = [PROJECT_PYTHON];

export const TransactionFundDataScreen = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const { txnId } = useParams();
  const location = useLocation();

  const { user } = useAuthContext();
  const isQuay = isTxnIdQuay({ txnId });
  const { showNewDocumentsTab, loading } = useShowNewDocumentsTab({ txnId });
  const {
    isCapitalAccountsReady,
    isFundExposureSummaryReady,
    isSoiLookthroughReady,
    isDealBreakdownReady,
  } = useFundDataStatus();

  const baseRoute = generatePath(
    baseTransactionPathForExperienceType[user.experienceType],
    { txnId },
  );

  if (!user || loading) return <BarLoader />;

  // Short-circuit, if !loading && !showNewDocumentsTab
  // Show old documents tab
  if (!loading && !showNewDocumentsTab) {
    return <DataRoom />;
  }

  const showSummary = !HIDE_SUMMARY_FROM_TRANSACTION_IDS.includes(txnId);
  const showCapitalAccountsTab =
    !isQuay && !HIDE_CAPITAL_ACCOUNTS_FROM_TRANSACTION_IDS.includes(txnId);
  const showInvestmentsTab =
    !isQuay && !HIDE_INVESTMENTS_FROM_TRANSACTION_IDS.includes(txnId);
  const showCommentaryTab =
    !isQuay && !HIDE_COMMENTARY_FROM_TRANSACTION_IDS.includes(txnId);
  const showInterimCashflowsTab =
    !isQuay && !HIDE_INTERIM_CASHFLOWS_FROM_TRANSACTION_IDS.includes(txnId);

  let defaultTab = TransactionFundDataRoutes.Documents;

  if (showSummary) {
    defaultTab = TransactionFundDataRoutes.Summary;
  }

  const showHoldingsSelectorCapitalAccounts =
    isCapitalAccountsReady &&
    location.pathname.includes(
      `${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.CapitalAccounts}`,
    );
  const showHoldingsSelectorFundDataSummary = location.pathname.includes(
    `${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Summary}`,
  );
  const showHoldingsSelectorInvestments = location.pathname.includes(
    `${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Investments}`,
  );
  const showHoldingsSelectorCommentary = location.pathname.includes(
    `${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Commentary}`,
  );

  const showHoldingsSelector =
    (showHoldingsSelectorCapitalAccounts ||
      showHoldingsSelectorFundDataSummary ||
      showHoldingsSelectorInvestments ||
      showHoldingsSelectorCommentary) &&
    (isFundExposureSummaryReady ||
      isSoiLookthroughReady ||
      isDealBreakdownReady);

  return (
    <TransactionFundDataProvider>
      <RowTabNavLinkWrapper
        showBorderBottom
        actions={showHoldingsSelector && <HoldingSelector />}
      >
        {showSummary && (
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Summary}`}
          >
            Summary
          </NavLink>
        )}
        {showCapitalAccountsTab && (
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.CapitalAccounts}`}
          >
            Capital Accounts
          </NavLink>
        )}
        {showInvestmentsTab && (
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Investments}`}
          >
            Investments
          </NavLink>
        )}
        {showCommentaryTab && (
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Commentary}`}
          >
            Commentary
          </NavLink>
        )}
        {showInterimCashflowsTab && (
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.InterimCashflows}`}
          >
            Interim Cashflows
          </NavLink>
        )}
        <NavLink
          to={`${baseRoute}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.Documents}`}
        >
          Documents
        </NavLink>
      </RowTabNavLinkWrapper>

      {/* Tabs */}
      <Routes>
        <Route
          path={TransactionFundDataRoutes.Summary}
          element={<TransactionFundDataSummary summary={summary} />}
        />
        <Route
          path={TransactionFundDataRoutes.CapitalAccounts}
          element={<CapitalAccountsTab summary={summary} />}
        />
        <Route
          path={TransactionFundDataRoutes.Documents}
          element={showNewDocumentsTab ? <DocumentsTab /> : <DataRoom />}
        />
        <Route
          path={TransactionFundDataRoutes.Investments}
          element={<InvestmentsTab summary={summary} />}
        />
        <Route
          path={TransactionFundDataRoutes.Commentary}
          element={<CommentaryTab />}
        />
        <Route
          path={TransactionFundDataRoutes.InterimCashflows}
          element={<InterimCashflowsTab />}
        />
        {/* Flows */}
        <Route
          path={TransactionFundDataRoutes.VerifyPermission}
          element={
            <TransactionFundDataVerifyPermissionFlow summary={summary} />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={`${baseRoute}${TransactionDetailsRoutes.FundData}${defaultTab}`}
            />
          }
        />
      </Routes>
    </TransactionFundDataProvider>
  );
};
