import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

import { BUTTON_TYPES } from "common/components/Button";
import Loader from "common/components/TapLoader";
import { PageHead } from "common/components/PageHead";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { useIOIsContext } from "../state/IOIContext";
import { IOIsLoaded } from "../state/IOIState";
import { Box } from "@mui/material";
import {
  Ask,
  Bid,
} from "experiences/indications-of-interest/domain/models/IOI";
import { LP_ROUTES } from "common/constants/routes";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { FUND_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";

export const Archived = () => {
  const { archivedIOIsState } = useIOIsContext();
  const navigate = useNavigate();

  const handleAddBuyInterestClick = () => {
    navigate(LP_ROUTES.IndicationsOfInterestBids);
  };
  const handleAddSellInterestClick = () => {
    navigate(LP_ROUTES.IndicationsOfInterestAsks);
  };

  return archivedIOIsState instanceof IOIsLoaded ? (
    <div className="pb-24">
      <Helmet title="Archived" />
      <Box sx={{ pb: 10 }}>
        <PageHead
          title="Archived Buy List"
          hug={String(
            archivedIOIsState.iois.filter((ioi) => ioi instanceof Bid).length,
          )}
        />
        <TapTable
          emptyStateTitle="No archived interests"
          emptyStateDescription="Archived interests will show up here."
          emptyStateActions={[
            {
              label: "Add Buy Interest",
              onClick: handleAddBuyInterestClick,
              type: BUTTON_TYPES.PRIMARY,
            },
          ]}
          data={archivedIOIsState.iois.filter((ioi) => ioi instanceof Bid)}
          columns={[
            {
              key: "fund",
              label: "Fund",
              align: TableAlignment.LEFT,
            },
            {
              key: "manager",
              label: "Manager",
              align: TableAlignment.LEFT,
            },
            {
              key: "create_date",
              label: "Create Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "ref_date",
              label: "Ref Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "bid",
              label: "Bid (% of NAV)",
              align: TableAlignment.LEFT,
            },
            {
              key: "min_nav",
              label: "Min NAV",
              align: TableAlignment.LEFT,
            },
            {
              key: "max_nav",
              label: "Max NAV",
              align: TableAlignment.LEFT,
            },
          ]}
          renderRow={(bid: Bid) => {
            const imgSrc = `${FUND_LOGO_URL}/${bid.fundId}.jpg`;

            return (
              <>
                <TableCell isPrimaryColumn>
                  <TableFundLogo imgSrc={imgSrc} fundName={bid.issuerName} />
                </TableCell>
                <TableCell>{bid.managerName}</TableCell>
                <TableCell>
                  {new DateFormatter(bid.createdAt).humanDate()}
                </TableCell>
                <TableCell>
                  {bid.referenceDate
                    ? new DateFormatter(bid.referenceDate).quarterFormatted()
                    : NOT_AVAILABLE_STR}
                </TableCell>
                <TableCell>
                  {(Boolean(bid.minPrice) && `${bid.minPrice}%`) || null}
                  {(Boolean(bid.minPrice) && Boolean(bid.maxPrice) && " - ") ||
                    null}
                  {(Boolean(bid.maxPrice) && `${bid.maxPrice}%`) || null}
                </TableCell>
                <TableCell>
                  {new DollarAmount(bid.minAmount).formatted()}
                </TableCell>
                <TableCell>
                  {bid.maxAmount
                    ? new DollarAmount(bid.maxAmount).formatted()
                    : "-"}
                </TableCell>
              </>
            );
          }}
        />
      </Box>
      <>
        <PageHead
          title="Archived Sell List"
          hug={String(
            archivedIOIsState.iois.filter((ioi) => ioi instanceof Ask).length,
          )}
        />

        <TapTable
          emptyStateTitle="No archived interests"
          emptyStateDescription="Archived interests will show up here."
          emptyStateActions={[
            {
              label: "Add Sell Interest",
              onClick: handleAddSellInterestClick,
              type: BUTTON_TYPES.PRIMARY,
            },
          ]}
          data={archivedIOIsState.iois.filter((ioi) => ioi instanceof Ask)}
          columns={[
            {
              key: "fund",
              label: "Fund",
              align: TableAlignment.LEFT,
            },
            {
              key: "manager",
              label: "Manager",
              align: TableAlignment.LEFT,
            },
            {
              key: "create_date",
              label: "Create Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "ref_date",
              label: "Ref Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "bid",
              label: "Bid (% of NAV)",
              align: TableAlignment.LEFT,
            },
            {
              key: "min_nav",
              label: "Min NAV",
              align: TableAlignment.LEFT,
            },
            {
              key: "max_nav",
              label: "Max NAV",
              align: TableAlignment.LEFT,
            },
          ]}
          renderRow={(ask: Ask) => {
            const imgSrc = `${FUND_LOGO_URL}/${ask.fundId}.jpg`;

            return (
              <>
                <TableCell isPrimaryColumn>
                  <TableFundLogo imgSrc={imgSrc} fundName={ask.issuerName} />
                </TableCell>
                <TableCell>{ask.managerName}</TableCell>
                <TableCell>
                  {new DateFormatter(ask.createdAt).humanDate()}
                </TableCell>
                <TableCell>
                  {ask.referenceDate
                    ? new DateFormatter(ask.referenceDate).quarterFormatted()
                    : NOT_AVAILABLE_STR}
                </TableCell>
                <TableCell>
                  {(Boolean(ask.minPrice) && `${ask.minPrice}%`) || null}
                  {(Boolean(ask.minPrice) && Boolean(ask.maxPrice) && " - ") ||
                    null}
                  {(Boolean(ask.maxPrice) && `${ask.maxPrice}%`) || null}
                </TableCell>
                <TableCell>
                  {ask.minAmount
                    ? new DollarAmount(ask.minAmount).formatted()
                    : "-"}
                </TableCell>
                <TableCell>
                  {ask.maxAmount
                    ? new DollarAmount(ask.maxAmount).formatted()
                    : "-"}
                </TableCell>
              </>
            );
          }}
        />
      </>
    </div>
  ) : (
    <Loader />
  );
};
