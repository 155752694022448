import { useState } from "react";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import {
  ComplianceState,
  FairnessOpinionListLoaded,
  FirmOverviewLoaded,
  QmsListLoaded,
} from "./ComplianceState";
import {
  ComplianceEvent,
  FairnessOpinionListRequested,
  FirmOverviewRequested,
  QmsListRequested,
} from "./ComplianceEvent";
import { GetQmsList } from "experiences/compliance/domain/usecases/GetQmsList";
import { QmsEntry } from "experiences/compliance/domain/models/QmsEntry";
import { GetFairnessOpinionList } from "experiences/compliance/domain/usecases/GetFairnessOpinionList";
import { FairnessOpinion } from "experiences/compliance/domain/models/FairnessOpinion";
import { GetFirmOverview } from "experiences/compliance/domain/usecases/GetFirmOverview";
import { FirmOverview } from "experiences/compliance/domain/models/FirmOverview";

export const useComplianceState = ({
  getQmsList,
  getFairnessOpinionList,
  getFirmOverview,
}: {
  getQmsList: GetQmsList;
  getFairnessOpinionList: GetFairnessOpinionList;
  getFirmOverview: GetFirmOverview;
}) => {
  const [qmsListState, setQmsListState] = useState<ComplianceState>();
  const [fairnessOpinionListState, setFairnessOpinionListState] =
    useState<ComplianceState>();
  const [firmOverviewState, setFirmOverviewState] = useState<ComplianceState>();

  let prevEvent: ComplianceEvent;
  const emitEvent = (event: ComplianceEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof QmsListRequested) {
      getQmsList.call({}).then((resp) => {
        match<Failure, QmsEntry[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (qmsList: QmsEntry[]) => {
            setQmsListState(
              new QmsListLoaded({
                qmsList: qmsList,
              }),
            );
          },
        )(resp);
      });
    } else if (event instanceof FairnessOpinionListRequested) {
      getFairnessOpinionList.call({}).then((resp) => {
        match<Failure, FairnessOpinion[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (fairnessOpinions: FairnessOpinion[]) => {
            setFairnessOpinionListState(
              new FairnessOpinionListLoaded({
                fairnessOpinions: fairnessOpinions,
              }),
            );
          },
        )(resp);
      });
    } else if (event instanceof FirmOverviewRequested) {
      getFirmOverview.call({}).then((resp) => {
        match<Failure, FirmOverview, void>(
          (_: Failure) => {
            console.log("error");
          },
          (firmOverview: FirmOverview) => {
            setFirmOverviewState(
              new FirmOverviewLoaded({
                firmOverview: firmOverview,
              }),
            );
          },
        )(resp);
      });
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    qmsListState,
    fairnessOpinionListState,
    firmOverviewState,
  };
};
