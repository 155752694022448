import { Either, left, right } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";

interface IResp {}

interface ITxnName {}

const PATH = "/transactions/:txnId/name/";

export class UpdateTransactionName implements IAsyncUseCase<IParams, ITxnName> {
  call: (params: IParams) => Promise<Either<Failure, ITxnName>> = async ({
    name,
    txnId,
  }) => {
    return await new TapClient(PATH.replace(":txnId", txnId), {
      name,
    })
      .post()
      .then((resp: IResp) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  name: string;
  txnId: string;
}
