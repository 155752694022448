import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";

import FormikTextField from "../../../../../common/components/formik/FormikTextField";
import {
  PayloadTextType,
  generateSlackMessagePayload,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { Fund } from "experiences/funds/domain/models/Fund";
import { IOIType } from "experiences/indications-of-interest/domain/models/IOI";
import { FundSummary } from "experiences/funds/domain/models/FundSummary";
import { BUTTON_TYPES, Button } from "common/components/Button";

export const ContactSellerModal = ({
  open,
  onClose,
  fund,
  summary,
}: {
  open: IOIType | false;
  onClose: () => void;
  fund?: Fund;
  summary?: FundSummary;
}) => {
  const isBuy = open === IOIType.Bid;
  const isSell = open === IOIType.Ask;
  const { user } = useAuthContext();
  const { sendMessage } = useSendSlackMessage({
    channel: "app-events",
  });
  const validationSchema = yup.object({
    message: yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        sendMessage({
          blocks: [
            generateSlackMessagePayload({
              title: `Submitted an indication of interest`,
              fields: [
                {
                  type: PayloadTextType.Markdown,
                  text: `*Message*: ${values.message}`,
                },
              ],
              context: [
                {
                  type: PayloadTextType.Markdown,
                  text: `*Fund*: ${fund?.name} (${fund?.id})`,
                },
                {
                  type: PayloadTextType.Markdown,
                  text: `*Manager*: ${fund?.managerName}`,
                },
                {
                  type: PayloadTextType.Markdown,
                  text: `*User*: ${user?.firstName} ${user?.lastName} (${user?.email})`,
                },
              ],
            }),
          ],
        });

        onClose();
      } catch (e) {
        console.error(e);
      } finally {
        formik.setSubmitting(false);
        formik.resetForm();
      }
    },
  });

  return (
    <Modal
      keepMounted
      open={Boolean(open)}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={formik.submitForm}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3">
                  {isBuy && "Contact Buyer"}
                  {isSell && "Contact Seller"}
                </Typography>
                <IconButton
                  onClick={onClose}
                  sx={{
                    border: `2px solid #DBDCDA`,
                    height: 40,
                    width: 40,
                    float: "right",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Typography variant="body1" color="#737476">
                Request Tap reach out to the {isSell ? "seller" : "buyer"} to
                begin a discussion. All contact is kept anonymous until Tap
                confirms you want your identity revealed.
              </Typography>
            </Box>
            <br />
            <FormikTextField
              name="message"
              formik={formik}
              label="Message"
              fullWidth
            />
            <br />
            <Button
              onClick={formik.submitForm}
              fullWidth
              type={BUTTON_TYPES.SECONDARY}
              size="large"
            >
              Send
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
