import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { Either, left, right } from "fp-ts/lib/Either";

export interface IHoldingsProfile {
  interimCapitalCalls: number;
  interimDistributions: number;
  commitedBefore: number;
  unfundedBefore: number;
  cavBefore: number;
  holdingIssuersNameList: string[];
}

interface IParams {
  txnId: string;
}

export class GetHoldingsProfile
  implements IAsyncUseCase<IParams, IHoldingsProfile>
{
  call: (params: IParams) => Promise<Either<Failure, IHoldingsProfile>> =
    async ({ txnId }) => {
      return await new TapClient(
        `/transactions/tenders/${txnId}/holdings-profile/`,
      )
        .get({})
        .then((resp: IHoldingsProfile) => right(resp))
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}
