import { useContext } from "react";

import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";

import { CommentaryUpdates } from "./CommentaryUpdates";
import { ManagerAssessmentSection } from "./ManagerAssessment";
import { LiquidityProjectionSection } from "./LiquidityProjection";
import { useCommentary } from "./useCommentary";
import { CompanyDataSection } from "./CompanyData";
import { FundDataWrapper } from "../FundDataWrapper";
import { parseStrToNumber } from "experiences/common/excel/utils";

export const CommentaryTab = () => {
  const { reportDates } = useContext(TransactionFundDataContext);

  const {
    loading,
    managerAssessmentCharts,
    updatesData,
    liquidityProjectionChart,
    companyData,
    isQualitativeDataReady,
    awaitingUpload,
    extractionInProgress,
  } = useCommentary({
    currentReportDate: reportDates.qualitativeData.currentDate,
  });

  const sortedUpdates = [...updatesData].sort(
    (a, b) =>
      (parseStrToNumber(b.lpImpliedTransactionValue || "") || 0) -
      (parseStrToNumber(a.lpImpliedTransactionValue || "") || 0),
  );

  return (
    <FundDataWrapper
      barLoader={loading}
      awaitingUpload={awaitingUpload}
      extractionInProgress={extractionInProgress}
      extractionReady={isQualitativeDataReady}
    >
      <ManagerAssessmentSection
        managerAssessmentCharts={managerAssessmentCharts}
        reportDate={reportDates.qualitativeData}
        loading={loading}
      />
      <LiquidityProjectionSection
        chart={liquidityProjectionChart}
        loading={loading}
      />
      <CommentaryUpdates updatesData={sortedUpdates} loading={loading} />
      <CompanyDataSection
        data={companyData}
        reportDateSelector={reportDates.qualitativeData}
        loading={loading}
      />
    </FundDataWrapper>
  );
};
