import { createElement } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "@phosphor-icons/react";

import { APP_MENU_HEIGHT_PX } from "../PageWrapper";

const StyleddNavLink = styled(NavLink)<{
  isActive?: boolean;
  isTapLogo?: boolean;
  bottom?: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${APP_MENU_HEIGHT_PX}px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background-color: transparent;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent; // this is so content is centered and not pushed to the left
  text-align: left;
  line-height: 1;

  img,
  svg {
    position: absolute;
    display: inline-block;
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
    left: calc(${APP_MENU_HEIGHT_PX}px / 2); // center-ish the icon
    top: calc(${APP_MENU_HEIGHT_PX}px / 2); // center-ish the icon
    transform: translateX(-50%) translateY(-50%); // adjust for center
  }

  &:hover {
    background-color: #353b40;
  }

  &:hover,
  &:active {
    img,
    svg {
      opacity: 1;
    }
  }
  ${(props) => props.isActive && `img, svg { opacity: 1; }`}
  ${(props) => props.isTapLogo && `img, svg { opacity: 1; }`}

  /* if active prop */
  ${(props) => props.isActive && `border-left: 2px solid #BCB3A5;`}
  ${(props) => props.bottom && `justify-self: end;`}

  span.label {
    display: flex;
    height: ${APP_MENU_HEIGHT_PX}px;
    position: absolute;
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 3%;
    left: ${APP_MENU_HEIGHT_PX}px;
    align-items: center;
    white-space: nowrap;
  }
`;

export const NavLinks = ({
  buttons,
}: {
  buttons: Array<{
    to: string;
    label: string;
    icon: Icon;
    isTapLogo?: boolean;
    hidden?: boolean;
  }>;
}) => {
  return (
    <>
      {buttons.map(({ to, label, icon, isTapLogo, hidden }) => {
        if (hidden) return null;
        return (
          <StyleddNavLink
            to={to}
            isActive={window.location.pathname.includes(to)}
            isTapLogo={!!isTapLogo}
            key={to}
          >
            {createElement(icon, {
              color: "white",
              width: 18,
              height: 18,
              weight: "regular",
            })}
            <span className="label">{label}</span>
          </StyleddNavLink>
        );
      })}
    </>
  );
};
