import { useEffect, useState } from "react";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";

import {
  DashboardEvent,
  IndexesRequested,
} from "experiences/dashboard/presentation/state/DashboardEvent";
import { ActiveRecentIoiListRequested } from "./DashboardEvent";
import { Index } from "experiences/funds/domain/models/Index";
import { LoadIndexes } from "experiences/dashboard/domain/usecases/LoadIndexes";
import { ActiveRecentIoiList } from "experiences/indications-of-interest/domain/usecases/ActiveRecentIoiList";
import {
  ActiveRecentIoisLoaded,
  ActiveRecentIoisState,
  DashboardLoading,
  DashboardState,
  IndexesLoaded,
  MockDashboardLoadedForGP,
} from "./DashboardState";
import { IIndicationOfInterest } from "experiences/indications-of-interest/domain/models/IOI";
import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { TransactionsLoaded } from "../../../transactions/presentation/state/TransactionsState";
import {
  dashboardBuyersList,
  dashboardLimitedPartners,
  transactionsList,
} from "experiences/dashboard/mock/GP";

export const useManageDashboardState = ({
  activeRecentIoiList,
  loadIndexes,
}: {
  activeRecentIoiList: ActiveRecentIoiList;
  loadIndexes: LoadIndexes;
}) => {
  let prevEvent: DashboardEvent;

  const { txnsListState } = useTransactionsContext();
  const [indexes, setIndexes] = useState<Index[]>([]);
  const [activeRecentIoisState, setActiveRecentIoisState] = useState(
    new ActiveRecentIoisState(),
  );
  const [dashboardState, setDashboardState] = useState<DashboardState>(
    new DashboardLoading(),
  );

  useEffect(() => {
    if (txnsListState instanceof TransactionsLoaded) {
      // for demo purposes, we are setting the dashboard state to a mock state
      // and we can just assume that the dashboard page was requested at some point
      // maybe a truthful longer term fix is:
      // add dashboardState instanceof DashboardLoaded or something to the condition of this useEffect
      setDashboardState(
        new MockDashboardLoadedForGP({
          canSignPlatformNDA: true,
          transactionsList: transactionsList,
          buyersList: dashboardBuyersList,
          limitedPartners: dashboardLimitedPartners,
          shouldApproveBuyers: false,
        }),
      );
    }
  }, [txnsListState]);

  const emitEvent = (event: DashboardEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof ActiveRecentIoiListRequested) {
      loadActiveRecentIois();
    } else if (event instanceof IndexesRequested) {
      getIndexes();
    }

    prevEvent = event;
  };

  const getIndexes = () => {
    loadIndexes.call({}).then((resp) => {
      match<Failure, Index[], void>(
        (_) => {},
        (res) => {
          setIndexes(res);
        },
      )(resp);
    });

    emitEvent(new IndexesLoaded({ indexes }));
  };

  const loadActiveRecentIois = () => {
    activeRecentIoiList.call({}).then((resp) => {
      match<Failure, IIndicationOfInterest[], void>(
        (f: Failure) => {
          // Load an empty list if there is an error
          setActiveRecentIoisState(new ActiveRecentIoisLoaded({ iois: [] }));
        },
        (iois: IIndicationOfInterest[]) => {
          setActiveRecentIoisState(new ActiveRecentIoisLoaded({ iois }));
        },
      )(resp);
    });
  };

  return {
    emitEvent,
    indexes,
    loadIndexes,
    dashboardState,
    activeRecentIoisState,
  };
};
