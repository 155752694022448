import { cn } from "common/utils";
import styled from "styled-components";

const StyledDescriptionList = styled.dl`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  div.row {
    display: flex;
    padding: 12px 8px;
    box-sizing: border-box;
    & + div.row {
      border-top: 1px solid var(--branding-keyline-light, #f2f2f0);
    }
  }

  dt,
  dd {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    cursor: default;
    padding-left: 8px;
    padding-right: 8px;
  }

  dt {
    flex: 1;
    color: var(--branding-light, #737476);
  }

  dd {
    color: var(--branding-dark, #21272d);
  }
`;

interface DescriptionListItem {
  label: string;
  value: string | React.ReactNode;
  locked?: boolean;
  bold?: boolean;
}

export const DescriptionList = ({
  items,
}: {
  items: DescriptionListItem[];
}) => {
  return (
    <StyledDescriptionList className="no-scrollbar">
      {items.map((item) => {
        return (
          <div className="row items-center">
            <dt>{item.label}</dt>
            <dd
              className={cn("flex gap-2 items-center whitespace-nowrap", {
                "blur-sm select-none": item.locked,
                "!font-staff !text-lg !font-semibold": item.bold,
              })}
            >
              {item.value}
            </dd>
          </div>
        );
      })}
    </StyledDescriptionList>
  );
};
