import { IParticipant } from "../../../domain/models/Participant";
import { IMockedParticipant } from "../../mock/GP";
import { TransactionsState } from "../TransactionsState";

export class ParticipantListState extends TransactionsState {}

export class ParticipantListLoaded extends TransactionsState {
  participants: IParticipant[];

  constructor({ participants }: { participants: IParticipant[] }) {
    super();
    this.participants = participants;
  }
}

export class GPMockedParticipantListLoaded extends TransactionsState {
  participants: IMockedParticipant[];
  constructor({ participants }: { participants: IMockedParticipant[] }) {
    super();
    this.participants = participants;
  }
}

export class ParticipantListLoading extends TransactionsState {}

export class ParticipantListFailedToLoad extends TransactionsState {}
