import { GpDashboardTask } from "../GpDashboardPage";

export const useGpDashboardTaskList = ({
  tasks: innerTasks,
}: {
  tasks: GpDashboardTask[];
}) => {
  const showOtherTasks = innerTasks.length > 0;

  let count = innerTasks.length;

  const tasks: GpDashboardTask[] = [...innerTasks];

  return {
    showOtherTasks,
    count,
    tasks,
  };
};
