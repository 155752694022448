import { Either, left, right } from "fp-ts/lib/Either";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { Failure } from "common/@types/app/Failure";

export interface ITenderOfferMaterials {
  offerPrice: number;
  capacity: number;
  recurring: boolean;
  summary: Object;
  closingDate: string;
}

export class GetTenderMaterials
  implements IAsyncUseCase<IParams, ITenderOfferMaterials>
{
  call: (params: IParams) => Promise<Either<Failure, ITenderOfferMaterials>> =
    async ({ txnId }) => {
      return await new TapClient(`/transactions/tenders/${txnId}/materials/`)
        .get({})
        .then((resp: ITenderOfferMaterials) => {
          return right(resp);
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {
  txnId: string;
}
