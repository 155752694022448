import { RegAssetClassPref } from "./RegAssetClassPref";
import { RegGeography } from "./RegGeography";
import { RegMarketEngagement } from "./RegMarketEngagement";
import { UserExperienceType } from "./UserType";

export interface ICurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  isActive?: boolean;
  isStaff?: boolean;
  isSuperuser?: boolean;
  isAccreditedInvestor?: boolean;
  marketEngagement?: RegMarketEngagement[];
  interestedIn?: RegAssetClassPref[];
  geographiesInterestedIn?: RegGeography[];
  investmentSizeRange?: { start: number; end: number };
  experienceType: UserExperienceType;
}

export const isCurrentUserAdmin = (currentUser: ICurrentUser): boolean => {
  return currentUser.isStaff && currentUser.isSuperuser;
};
