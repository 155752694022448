import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import authService, { memoizedRefreshToken } from "common/services/authService";

// @MO: These are the subpaths for the Apollo clients
const TRANSITIONS_SUBPATH = "transactions/";
const FUNDS_SUBPATH = "funds/";
const PORTFOLIO_SUBPATH = "portfolio/";
const COMPANY_SUBPATH = "company/";

const APOLLO_URL = `${import.meta.env.VITE_APP_API_URL}/graphql/`;

const authLink = setContext(async (_, { headers }) => {
  const session = authService.getSession();

  // do not request a new token if it's still valid
  if (!authService.isValidToken(session?.access)) {
    const token = await memoizedRefreshToken();

    return {
      headers: {
        ...headers,
        authorization: token.data.access ? `Bearer ${token.data.access}` : "",
      },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: session.access ? `Bearer ${session.access}` : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: APOLLO_URL,
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const transactionsClientPath = `${APOLLO_URL}${TRANSITIONS_SUBPATH}`;

export const transactionsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    new HttpLink({
      uri: transactionsClientPath,
    }),
  ),
});

const portfolioClientPath = `${APOLLO_URL}${PORTFOLIO_SUBPATH}`;

export const portfolioClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    new HttpLink({
      uri: portfolioClientPath,
    }),
  ),
});

const fundsClientPath = `${
  import.meta.env.VITE_APP_APOLLO_SERVER_URL
}${FUNDS_SUBPATH}`;

export const fundsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    new HttpLink({
      uri: fundsClientPath,
    }),
  ),
});

const companyClientPath = `${APOLLO_URL}${COMPANY_SUBPATH}`;

export const companyClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(new HttpLink({ uri: companyClientPath })),
});
