import { gql } from "@apollo/client";

export const GET_TRANSACTION_INVESTMENTS_COMPANY_LOAN_DATA = gql`
  query companyLoanData(
    $transactionId: String!
    $reportDate: Date!
    $issuersIds: [String!]!
  ) {
    companyLoanData(
      transactionId: $transactionId
      reportDate: $reportDate
      issuersIds: $issuersIds
    ) {
      investmentId
      investmentName
      fundId
      fundName
      psin
      managerId
      managerName
      companyId
      companyName
      reportDate
      type
      originationDate
      amount
      currentAmount
      maturityDate
      interestRate
      interestRateType
      term
      collateral
      amortization
      otherTerms
      interestCoverageRatio
    }
  }
`;

export const GET_TRANSACTION_INVESTMENTS_COMPANY_DATA = gql`
  query CompanyData(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]!
  ) {
    companyData(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      companyName
      tapCompanyId
      companyId
      lpImpliedInvestmentValue
      lpImpliedInvestmentPercentage
      fundName
      tapFundId
      tapInvestmentId
      reportDate
      companyCurrency
      fundOwnershipPercent
      equityValue
      enterpriseValue
      revenueLtm
      revenueLatestAnnual
      revenueYoyPercentChgLtm
      revenueYoyPercentChgAnnual
      revenueProjected
      ebitdaLtm
      ebitdaLatestAnnual
      ebitdaYoyPercentChgLtm
      ebitdaYoyPercentChgAnnual
      ebitdaProjected
      ebitdaLtmMargin
      companyCash
      companyDebt
      companyNetDebt
      capexLtm
      capexLatestAnnual
      ebitdaMultipleEntry
      ebitdaMultipleLtm
      leverageMultipleEntry
      leverageMultipleLtm
      revenueMultipleEntry
      revenueMultipleLtm
      companyDescription
      isHumanVerified
    }
  }
`;
