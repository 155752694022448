import ReactDOM from "react-dom/client";
import moment from "moment";
import unformat from "accounting-js/lib/unformat.js";
import formatNumber from "accounting-js/lib/formatNumber.js";

import { HEADER_TOOLTIP_ELEMENT_ID } from "experiences/indications-of-interest/presentation/components/hotTableWrapper";
import { CellCoords } from "handsontable";
import { CellChange, RangeType } from "handsontable/common";

export const BULK_UPLOAD_DATE_FORMAT = "YYYY-MM-DD";

interface ITable<T> {
  getData: () => T[];
  isEmptyRow: (index: number) => boolean;
  validateCell: (
    value: any,
    cellMeta: any,
    callback: (valid: boolean) => void,
  ) => void;
  getCellMeta: (row: number, col: number) => any;
  getDataAtCell: (row: number, col: number) => any;
  setDataAtCell: (data: [number, number, number][]) => void;
  validateRows: (rows: number[], callback: (valid: boolean) => void) => void;
  setCellMeta: (row: number, col: number, key: string, value: any) => void;
  render: () => void;
}

export const onlyFutureDatesValidator = (
  value: string,
  callback: (valid: boolean) => void,
) => {
  // empty values should be considered valid dates
  if (!value) {
    callback(true);
    return;
  }

  const now = moment();
  const isValid = moment(value, BULK_UPLOAD_DATE_FORMAT).isAfter(now);

  callback(isValid);
};

export const getRawTableData = <T>(table: ITable<T>) => {
  // getData returns an array of arrays, all values are nullable according to handsontable's types
  const data: T[] = table.getData();

  // Array of numbers that contains the indexes of the rows that have some data
  const rowsWithSomeData = data
    .map((row, index) => {
      return table.isEmptyRow(index);
    })
    .reduce((acc: number[], row: boolean, i: number) => {
      return !Boolean(row) ? [...acc, i] : acc;
    }, []);

  // Array of numbers that contains the indexes of all the rows in the table
  const tableRowIndexes: number[] = data.map((_: any, index: number) => index);

  // Array of numbers that contains the indexes of the rows that have no data
  const emptyRows = tableRowIndexes.filter(
    (row) => !rowsWithSomeData.includes(row),
  );

  return { data, rowsWithSomeData, tableRowIndexes, emptyRows };
};

// This function is called by handsontable when the user hovers over a cell
// We grab the element that was placed at the parent component by its id HEADER_TOOLTIP_ELEMENT_ID
// and then we render the tooltip component there, only if the column has a tooltip defined in the columns list
export const afterOnCellMouseOver = (
  coords: CellCoords,
  el: HTMLElement,
  columns: any[],
) => {
  if (coords.row === -1 && el.nodeName === "TH") {
    const tooltip = document.getElementById(HEADER_TOOLTIP_ELEMENT_ID);

    if (!tooltip) return;
    if (tooltip.offsetParent === null) return;

    const root = ReactDOM.createRoot(tooltip);

    const hasTooltip = Boolean(
      columns[coords.col] && columns[coords.col].tooltip,
    );

    if (!hasTooltip) return;

    root.render(columns[coords.col].tooltip);

    tooltip.style.visibility = "visible";
    tooltip.style.left = `${el.offsetLeft + el.offsetWidth / 2}px`;
  }
};

// Opposite of afterOnCellMouseOver
// Note: we hide instead of remove from the DOM because we want to keep the tooltip component
// which will still be hidden with CSS if there's no content inside it
export const afterOnCellMouseOut = () => {
  const tooltip = document.getElementById(HEADER_TOOLTIP_ELEMENT_ID);

  if (!tooltip) return;

  tooltip.style.visibility = "hidden";
};

export const handleAddMoreRowsClick = (
  tableRef: {
    countRows: () => number;
    alter: (action: string, index: number, count: number) => void;
  },
  setRowCount: (count: number) => void,
  count: number = 10,
) => {
  const currentRowCount = tableRef.countRows();

  setRowCount(currentRowCount + count);
  tableRef.alter("insert_row_below", currentRowCount, count);
};

export const getNamesForColumn = <T>(table: ITable<T>, colIndex: number) => {
  const { data, rowsWithSomeData } = getRawTableData<T>(table);

  const names = rowsWithSomeData.map(
    (rowIndex) => data[rowIndex][colIndex] || "",
  ) as string[];

  return {
    data,
    names,
    rowsWithSomeData,
  };
};

// handsontable does not provide a way to "clear" validation for empty rows
// you can get an invalid empty row by having an invalid row and then clearing its values
// that should now be an empty row, but it should be valid
// so we need to manually remove the "invadid" styles from the empty rows
export const validateAndSubmit = <T>({
  table,
  columnsToValidate,
  tableValidRef,
  submitApiCall,
}: {
  table: ITable<T>;
  columnsToValidate: number[];
  tableValidRef: { current: boolean };
  submitApiCall: (valid: boolean) => void;
}) => {
  const { rowsWithSomeData, emptyRows } = getRawTableData<T>(table);

  // validate all cells that are required, set the ref to fase if there's an invalid cell

  rowsWithSomeData.forEach((row) => {
    columnsToValidate.forEach((col) => {
      table.validateCell(
        table.getDataAtCell(row, col),
        table.getCellMeta(row, col),
        (valid: boolean) => {
          // force errors on invalid cells
          if (!valid) {
            table.setCellMeta(row, col, "valid", false);
            tableValidRef.current = false;
          }
        },
      );
    });
  });

  // but we also need to remove the "invalid" styles from the empty rows, because they are valid
  emptyRows.forEach((row) => {
    columnsToValidate.forEach((col) => {
      table.setCellMeta(row, col, "valid", true);
    });
  });

  // re-render the table so that the styles are updated
  table.render();

  // only validate rows that have some data (rowsWithSomeData)
  table.validateRows(rowsWithSomeData, submitApiCall);

  // re-render the table so that the styles are updated
  table.render();

  // return the ref, this is done to prevent multiple re-render bugs caused by handsontable
  // because ideally we would use react's useState hook, but handsontable looses its state
  // if we do any state updates while the validate+submit process is happening, so we need to use a ref instead
  return { table, valid: tableValidRef };
};

export const formatPercentageColumns = <T>({
  table,
  changes,
  columnsToFormat,
}: {
  table: ITable<T>;
  changes: CellChange[] | null;
  columnsToFormat: number[];
}) => {
  // transform into raw numbers
  changes?.forEach(([row, col, oldValue, newValue]) => {
    // When using the delete key, new values will be null
    // Do not try to format null values or do anything to them
    if (newValue === null) {
      return;
    }

    // DO NOT REMOVE
    // prevents infinite loop triggered by setting a new value repeatedly
    if (unformat(oldValue) === unformat(newValue)) {
      return;
    }

    // format % price columns
    if ([columnsToFormat].includes(col)) {
      if (unformat(newValue) === unformat(oldValue) / 100) {
        return;
      }

      if (newValue > 1) {
        table.setDataAtCell([
          [row, col, formatNumber(unformat(newValue) / 100, { precision: 4 })],
        ] as [number, number, number][]);
      }
    }
  });
};

export const formatCurrencyColumns = <T>({
  table,
  changes,
  columnsToFormat,
}: {
  table: ITable<T>;
  changes: CellChange[] | null;
  columnsToFormat: number[];
}) => {
  // transform into raw numbers
  changes?.forEach(([row, col, oldValue, newValue]) => {
    // When using the delete key, new values will be null
    // Do not try to format null values or do anything to them
    if (newValue === null) {
      return;
    }

    // DO NOT REMOVE
    // prevents infinite loop triggered by setting a new value repeatedly
    if (unformat(oldValue) === unformat(newValue)) {
      return;
    }

    // format currency columns
    if (columnsToFormat.includes(col)) {
      const rawValue = unformat(newValue);

      table.setDataAtCell([[row, col, rawValue]] as [number, number, number][]);
    }
  });
};

export const beforePaste = ({
  data,
  coords,
  currencyColumnIndexes,
}: {
  data: any[][];
  coords: RangeType[];
  currencyColumnIndexes: number[];
}) => {
  // remove formatting from currency columns
  for (var row = 0; row < data.length; row++) {
    for (var col = 0; col < data[row].length; col++) {
      if (currencyColumnIndexes.includes(col + coords[0].startCol)) {
        data[row][col] = unformat(data[row][col]);
      }
    }
  }
};
