import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";

import { FairnessOpinion } from "../models/FairnessOpinion";
import { fairnessOpinionListMock } from "../mock/FairnessOpinionListMock";

interface IParams {}

export class GetFairnessOpinionList
  implements IAsyncUseCase<IParams, FairnessOpinion[]>
{
  call: (params: IParams) => Promise<Either<Failure, FairnessOpinion[]>> =
    async (): Promise<Either<Failure, FairnessOpinion[]>> => {
      return right([...fairnessOpinionListMock]);
    };
}
