import { TableCell, styled, tableCellClasses } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F4F1",
    // height: "2px",
    paddingLeft: "0px",
  },

  [`&.${tableCellClasses.body}`]: {
    borderBottomStyle: "solid",
    borderBottomColor: "#DBDCDA",
    paddingLeft: "0px",
  },

  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    paddingLeft: theme.spacing(3),
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    paddingLeft: "6px",
  },

  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderBottomStyle: "solid",
    borderBottomColor: "#DBDCDA",
    paddingLeft: "0px",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderBottomStyle: "solid",
    borderBottomColor: "#DBDCDA",
    paddingLeft: "8px",
  },
}));
