import { IHolding } from "experiences/portfolio/domain/models/Holding";

export interface IBidHoldingPrice {
  referenceDate?: string;
  price?: number;
  txnHoldingId: string;
  percentValue?: number;
  holdingFundName?: string;
}

export interface IWholePortfolioPrice {
  price?: number;
}

export interface BidTransactionHolding {
  id: string;
  holding: IHolding;
  issuerName: string;
}

export enum BidTypeEnum {
  Partial = 1,
  WholePortfolio = 2,
}

export interface BidPricingUpsertForm {
  details: IWholePortfolioPrice | IBidHoldingPrice[];
  bidType: BidTypeEnum;
  txnId: string;
  bidId?: string;
}

enum BidType {
  Partial = 1,
  WholePortfolio = 2,
}

enum BidStatus {
  Draft = 1,
  Active = 2,
  Settlement = 3,
  Inactive = 99,
}

export interface IBidDocument {
  fileName: string;
  url?: string;
}

export interface IBid {
  id?: string;
  bidType?: BidType;
  bidderOrgId?: string;
  currentStatus?: BidStatus;
  document?: IBidDocument;
  bidderOrgName?: string;
  submittedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  percentValue?: number;
  dollarValue?: number;
  txnName?: string;
  isOpaque?: boolean;
}

export interface IWholePortfolioBid extends IBid {
  details?: IWholePortfolioPrice;
}

export interface IPartialBid extends IBid {
  detailsSet?: IBidHoldingPrice[];
}

export class Bid implements IBid {
  id?: string;
  bidType?: BidType;
  bidderOrgId?: string;
  txnName?: string;
  currentStatus?: BidStatus;
  document?: IBidDocument;
  bidderOrgName?: string;
  submittedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  percentValue?: number;
  dollarValue?: number;
  isOpaque?: boolean;

  constructor({
    bidType: type,
    bidderOrgId,
    currentStatus,
    document,
    id,
    submittedAt,
    createdAt,
    updatedAt,
    bidderOrgName,
    percentValue,
    dollarValue,
    txnName,
  }: IBid) {
    this.bidType = type;
    this.bidderOrgId = bidderOrgId;
    this.currentStatus = currentStatus;
    this.document = document;
    this.id = id;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.bidderOrgName = bidderOrgName;
    this.submittedAt = submittedAt;
    this.percentValue = percentValue;
    this.dollarValue = dollarValue;
    this.txnName = txnName;
  }

  isSettling: () => boolean = () => {
    if (this.currentStatus) return this.currentStatus == BidStatus.Settlement;
    return false;
  };
}

export class WholePortfolioBid extends Bid implements IWholePortfolioBid {
  details?: IWholePortfolioPrice;

  constructor({ details, ...rest }: IWholePortfolioBid) {
    super(rest);
    this.details = details;
  }
}

export class PartialBid extends Bid implements IPartialBid {
  detailsSet?: IBidHoldingPrice[];

  constructor({ detailsSet, ...rest }: IPartialBid) {
    super(rest);
    this.detailsSet = detailsSet;
  }
}

export const bidFromResponse: (
  resp: IPartialBid | IWholePortfolioBid,
) => Bid = (resp) => {
  if (resp.bidType && resp.bidType == BidType.Partial) {
    return new PartialBid(resp as IPartialBid);
  } else {
    return new WholePortfolioBid(resp as IWholePortfolioBid);
  }
};
