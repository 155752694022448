import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

const TapLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "6px",
  borderRadius: "6px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#DBDCDA",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: "#BCB3A5",
  },
}));

export default TapLinearProgress;
