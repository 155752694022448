import {
  ChartDonut,
  Compass,
  Gear,
  Handshake,
  House,
  Receipt,
} from "@phosphor-icons/react";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { AUTH_KEYS } from "common/constants/localStorage";
import { LP_ROUTES } from "common/constants/routes";
import useLocalStorage from "common/hooks/useLocalStorage";
import {
  GlobalSearchModal,
  ModalSearchbox,
} from "experiences/authentication/presentation/components/GlobalSearchModal";
import { ImpersonationDialog } from "experiences/authentication/presentation/components/ImpersonationDialog";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { useKeyboardShortcuts } from "../../KeyboardShortcuts";

import { LPNavLinks } from "./LPNavLinks";

import { CurrentExperience, NavLinkWrapper } from "../styles";

import { ImpersonationButton } from "experiences/authentication/presentation/components/ImpersonationButton";
import { FlagNames, tapFeatureFlags } from "experiences/common/Flags";
import { PortfolioV2Routes } from "experiences/portfolio-v2/presentation/components/navigation";
import { CurrentExperienceIcon } from "../CurrentExperience";
import { lpSidebarNavEntries, TapSideNav } from "../TapSideNav";
import { LPUser } from "./LPUser";

const useLpAppBar = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [searchOpen, setSearchOpen] = useState(false);
  const [impersonationOpen, setImpersonationOpen] = useState(false);
  const [impersonationState] = useLocalStorage(
    AUTH_KEYS.IMPERSONATION,
    undefined,
  );

  // for some reason, navlink's active prop is not working so I'm doing it manually
  const dashboardActive = window.location.pathname.includes(
    LP_ROUTES.Dashboard,
  );
  const fundsActive =
    window.location.pathname.includes(LP_ROUTES.Funds) ||
    window.location.pathname.includes(LP_ROUTES.FundsFundDetail);
  const tendersActive = window.location.pathname.includes(
    LP_ROUTES.TendersRoot,
  );
  const transactionsActive = window.location.pathname.includes(
    LP_ROUTES.Transactions,
  );
  const portfolioActive =
    window.location.pathname.includes(LP_ROUTES.Portfolio) ||
    window.location.pathname.includes(LP_ROUTES.PortfolioEntrypoint);
  const ioiActive = window.location.pathname.includes(
    LP_ROUTES.IndicationsOfInterest,
  );
  const settingsActive = window.location.pathname.includes(LP_ROUTES.Settings);
  const companyDetailActive = window.location.pathname.includes(
    LP_ROUTES.CompanyDetail.replace(":companyId", ""),
  );
  const isStaff = user.isStaff;

  const goToCurrentExperience = () => {
    if (dashboardActive) {
      navigate(LP_ROUTES.Dashboard);
    } else if (fundsActive) {
      navigate(LP_ROUTES.Funds);
    } else if (transactionsActive) {
      navigate(LP_ROUTES.Transactions);
    } else if (portfolioActive) {
      navigate(
        tapFeatureFlags[FlagNames.ShowPortfolioV2]
          ? `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Summary}`
          : LP_ROUTES.Portfolio,
      );
    } else if (ioiActive) {
      navigate(LP_ROUTES.IndicationsOfInterest);
    } else if (settingsActive) {
      navigate(LP_ROUTES.Settings);
    }
  };

  const handleSearchClick = () => {
    setSearchOpen(true);
  };

  const openImpersonation = () => {
    setImpersonationOpen(true);
  };

  useKeyboardShortcuts({
    openGlobalSearch: handleSearchClick,
  });

  const handleGoToFundDetail = (fundId: string) => {
    navigate(generatePath(LP_ROUTES.FundsFundDetail, { fundId }));
  };

  return {
    dashboardActive,
    fundsActive,
    tendersActive,
    transactionsActive,
    portfolioActive,
    ioiActive,
    settingsActive,
    companyDetailActive,
    isStaff,
    menuEntries: lpSidebarNavEntries,
    goToCurrentExperience,
    handleSearchClick,
    openImpersonation,
    impersonationOpen,
    setImpersonationOpen,
    searchOpen,
    setSearchOpen,
    impersonationState,
    handleGoToFundDetail,
  };
};

export const LPAppBar = () => {
  const {
    dashboardActive,
    fundsActive,
    tendersActive,
    transactionsActive,
    portfolioActive,
    ioiActive,
    settingsActive,
    companyDetailActive,
    isStaff,
    menuEntries,
    goToCurrentExperience,
    handleSearchClick,
    openImpersonation,
    impersonationOpen,
    setImpersonationOpen,
    searchOpen,
    setSearchOpen,
    impersonationState,
    handleGoToFundDetail,
  } = useLpAppBar();

  return (
    <>
      <TapSideNav
        entries={menuEntries}
        impersonationState={impersonationState}
      />

      {/* Horizontal Nav */}
      <NavLinkWrapper isImpersonationActive={impersonationState}>
        <CurrentExperience onClick={goToCurrentExperience}>
          {dashboardActive && (
            <CurrentExperienceIcon icon={House} label="Dashboard" />
          )}
          {portfolioActive || companyDetailActive ? (
            <CurrentExperienceIcon icon={ChartDonut} label="Portfolio" />
          ) : null}
          {fundsActive && (
            <CurrentExperienceIcon icon={Compass} label="Funds" />
          )}
          {ioiActive && (
            <CurrentExperienceIcon
              icon={Receipt}
              label="Indications Of Interest"
            />
          )}
          {transactionsActive && (
            <CurrentExperienceIcon icon={Handshake} label="Transactions" />
          )}
          {tendersActive && (
            <CurrentExperienceIcon icon={Handshake} label="Transactions" />
          )}
          {/* TODO: @jmz7v Refactor this to use a single icon */}
          {settingsActive && (
            <CurrentExperienceIcon icon={Gear} label="Settings" />
          )}
        </CurrentExperience>
        <LPNavLinks />
        <ModalSearchbox handleSearchClick={handleSearchClick} />
        <GlobalSearchModal
          open={searchOpen}
          handleClose={() => setSearchOpen(false)}
          handleGoToFundDetail={handleGoToFundDetail}
        />
        {isStaff ? (
          <ImpersonationButton openImpersonation={openImpersonation} />
        ) : null}
        <ImpersonationDialog
          open={impersonationOpen}
          handleClose={() => setImpersonationOpen(false)}
        />
        <LPUser />
      </NavLinkWrapper>
    </>
  );
};
