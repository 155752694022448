import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";

import Loader from "common/components/TapLoader";
import { useApprovedBuyersContext } from "../state/ApprovedBuyersContext";
import { ArchivedBuyersLoaded } from "../state/ApprovedBuyersState";
import { LoadArchivedBuyers } from "../state/ApprovedBuyerEvent";
import { PageHead } from "common/components/PageHead";
import { ArchivedBuyersTable } from "../components/ArchivedBuyersTable";

export const ArchivedBuyers = () => {
  const { archivedBuyerTablePageState, emitEvent } = useApprovedBuyersContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    emitEvent!(new LoadArchivedBuyers());
  }, []);

  const buyers =
    archivedBuyerTablePageState instanceof ArchivedBuyersLoaded
      ? archivedBuyerTablePageState.archivedBuyers.filter((approvedBuyer) =>
          approvedBuyer.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

  return (
    <>
      <Stack width="100%">
        <Helmet title="Archived" />
        <PageHead title="Archived Buyers" />
      </Stack>
      <Stack style={{ width: "100%" }}>
        {archivedBuyerTablePageState instanceof ArchivedBuyersLoaded ? (
          <ArchivedBuyersTable archivedBuyers={buyers} searchQuery={search} />
        ) : (
          <Loader />
        )}
      </Stack>
    </>
  );
};
