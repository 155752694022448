import { useState } from "react";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import {
  BidBulkUploadManager,
  ManagerBid,
} from "experiences/funds/domain/usecases/BidBulkUploadManager";

export const useIoiBulkUploadSubmitManager = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitBids = async ({ bids }: { bids: ManagerBid[] }) => {
    setIsLoading(true);
    return await new BidBulkUploadManager()
      .call({
        bids,
      })
      .then((resp) => {
        setIsLoading(false);
        return match<
          Failure,
          ManagerBid[],
          { success: boolean; response: any }
        >(
          (response: Failure) => {
            return { success: false, response };
          },
          (response) => {
            return { success: true, response };
          },
        )(resp);
      });
  };

  return {
    isLoading,
    submitBids,
  };
};
