import { Box, Modal, Stack } from "@mui/material";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";
import { useDocumentsContext } from "../state/DocumentsContext";
import { ClaimSignatorySubmitted } from "../state/DocumentsEvent";

export const ClaimSignatoryModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { emitEvent } = useDocumentsContext();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Stack sx={{ px: 4, py: 4 }} spacing={2}>
          <Box>
            <TapUiModalHead
              title="Claim signatory role for this document"
              subtitle="By confirming, you will become this document's signatory on behalf of your organization."
              onCloseClick={handleClose}
            />
          </Box>
          <Button
            onClick={() => emitEvent!(new ClaimSignatorySubmitted())}
            fullWidth
            type={BUTTON_TYPES.SECONDARY}
            size="large"
          >
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
