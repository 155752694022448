import { Failure } from "common/@types/app/Failure";
import { NoReturn } from "common/@types/app/UseCase";
import {
  useSendSlackMessage,
  generateSlackMessagePayload,
  generateObjectAsFields,
} from "common/hooks/useSendSlackMessage";
import {
  IParticipant,
  ICreateParticipantForm,
  ParticipantCreationRoleNames,
  getParticipantPersona,
} from "experiences/transactions/domain/models/Participant";
import { match } from "fp-ts/lib/Either";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  CreateParticipantFormSubmitted,
  ManageParticipantsButtonClicked,
  ParticipantListRequested,
  TransactionsEvent,
} from "../TransactionsEvent";
import {
  TransactionDetailsScreenLoaded,
  TransactionsState,
} from "../TransactionsState";
import {
  CopyInviteLinkButtonClicked,
  RejectButtonClicked,
  SendInviteButtonClicked,
} from "./ParticipantsEvent";
import {
  GPMockedParticipantListLoaded,
  ParticipantListFailedToLoad,
  ParticipantListLoaded,
  ParticipantListLoading,
  ParticipantListState,
} from "./ParticipantsState";
import { ListTransactionParticipants } from "experiences/transactions/domain/usecases/ListTransactionParticipants";
import { ApproveParticipantToInvite } from "experiences/transactions/domain/usecases/ApproveParticipantToInvite";
import { CreateTransactionParticipant } from "experiences/transactions/domain/usecases/CreateTransactionParticipant";
import { RemoveParticipant } from "experiences/transactions/domain/usecases/RemoveParticipant";
import { SendParticipantsInvite } from "experiences/transactions/domain/usecases/SendParticipantsInvite";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { UserExperienceType } from "experiences/authentication/domain/models/UserType";
import { pcpts } from "../../mock/GP";

interface IUseCases {
  retrieveParticipants: ListTransactionParticipants;
  approvePcptToInvite: ApproveParticipantToInvite;
  createTxnParticipant: CreateTransactionParticipant;
  removeParticipant: RemoveParticipant;
  txnDetailsState: TransactionsState;
  sendParticipantsInvite: SendParticipantsInvite;
}

export const useManageParticipantState = ({
  retrieveParticipants,
  approvePcptToInvite,
  createTxnParticipant,
  removeParticipant,
  txnDetailsState,
  sendParticipantsInvite,
}: IUseCases) => {
  const { enqueueSnackbar } = useSnackbar();

  const { sendMessage } = useSendSlackMessage();

  const { user } = useAuthContext();

  const [participantsListState, setParticipantsListState] =
    useState<ParticipantListState>(new ParticipantListLoading());

  const refreshParticipants = ({
    txnId,
    onParticipantsRetrieval = (_: IParticipant[]) => {},
  }: {
    txnId: string;
    onParticipantsRetrieval?: (pcpts: IParticipant[]) => void;
  }) => {
    // @Mo: I did change this so that the real API call does not happen because it was failing
    if (user.experienceType == UserExperienceType.GP) {
      setParticipantsListState(
        new GPMockedParticipantListLoaded({ participants: pcpts }),
      );
      // Do not remove return otherwise the real api call is made
      return;
    }

    // Else, assume the user is an LP
    retrieveParticipants.call({ txnId }).then((resp) => {
      match<Failure, IParticipant[], void>(
        (_: Failure) => {
          setParticipantsListState(new ParticipantListFailedToLoad());
        },
        (participants: IParticipant[]) => {
          if (participants.length > 0) {
            onParticipantsRetrieval(participants);
          }
          setParticipantsListState(
            new ParticipantListLoaded({ participants: participants }),
          );
        },
      )(resp);
    });
  };

  const removeTxnParticipant = (participantId: string, txnId: string) => {
    removeParticipant.call({ txnId, participantId }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          // TODO: toast something here
        },
        (_: NoReturn) => {
          refreshParticipants({ txnId: txnId });
        },
      )(resp);
    });
  };

  const copyPcptInviteLink = (pcpt: IParticipant) => {
    navigator.clipboard.writeText(pcpt.inviteLink).then(() => {
      enqueueSnackbar(
        `Invite link copied for ${pcpt.firstName} ${pcpt.lastName}`,
        {
          variant: "success",
        },
      );
    });
  };

  const sendPcptsInvite = (
    pcptIds: string[],
    txnId: string,
    toCopyInviteLink: boolean,
  ) => {
    sendParticipantsInvite
      .call({
        form: {
          txnId,
          notify: !toCopyInviteLink,
          participantIds: pcptIds,
        },
      })
      .then((resp) => {
        match<Failure, NoReturn, void>(
          (_: Failure) => {
            // TODO: toast something here
          },
          (_: NoReturn) => {
            refreshParticipants({
              txnId: txnId,
              onParticipantsRetrieval: toCopyInviteLink
                ? (pcpts: IParticipant[]) => {
                    const pcpt = pcpts.find((pcpt) => pcpt.id == pcptIds[0]);
                    copyPcptInviteLink(pcpt);
                  }
                : undefined,
            });
          },
        )(resp);
      });
  };

  const createParticipant = (
    creatorPcpt: IParticipant,
    form: ICreateParticipantForm,
  ) => {
    return createTxnParticipant
      .call({ txnId: form.transactionId, form })
      .then((resp) => {
        return match<Failure, NoReturn, void>(
          (error: Failure) => {
            const errorMessage = error.message?.data?.includes(
              "UniqueViolation",
            )
              ? "A user with this email already exists in this transaction."
              : "Failed to create user.";
            enqueueSnackbar(errorMessage, {
              variant: "error",
            });

            // TODO: Raise Sentry error?
          },
          (_: NoReturn) => {
            // we are making the assumption that if this method is called
            // then the state of the transaction has been worked out properly
            if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
              const summary = txnDetailsState.summary;
              sendMessage({
                blocks: [
                  generateSlackMessagePayload({
                    title: "A Participant has created another participant ",
                    fields: generateObjectAsFields({
                      "invitee name": `${form.firstName} ${form.lastName}`,
                      "invitee email": form.email,
                      "invitee organization": form.organizationName,
                      "invited as": `${ParticipantCreationRoleNames.get(
                        form.role,
                      )}`,
                    }),
                    context: generateObjectAsFields({
                      "inviter name": `${creatorPcpt.firstName} ${creatorPcpt.lastName}`,
                      "inviter email": creatorPcpt.email,
                      "inviter participant ID": creatorPcpt.id,
                      "inviter organization": creatorPcpt.organizationName,
                      "inviter acting as": getParticipantPersona(creatorPcpt),
                      "transaction name": summary.transaction.name,
                      "transaction ID": form.transactionId,
                    }),
                  }),
                ],
              });
            }

            refreshParticipants({ txnId: form.transactionId });
          },
        )(resp);
      });
  };

  let prevEvent: TransactionsEvent;
  const emitEvent = (event: TransactionsEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof ManageParticipantsButtonClicked) {
      refreshParticipants({ txnId: event.txnId });
    } else if (event instanceof CreateParticipantFormSubmitted) {
      createParticipant(event.creator, event.form);
    } else if (event instanceof RejectButtonClicked) {
      removeTxnParticipant(event.participantId, event.txnId);
    } else if (event instanceof SendInviteButtonClicked) {
      sendPcptsInvite(
        event.pcpts.map((pcpt) => pcpt.id),
        event.txnId,
        false,
      );
    } else if (event instanceof CopyInviteLinkButtonClicked) {
      if (event.pcpt.inviteLink) {
        copyPcptInviteLink(event.pcpt);
      } else {
        sendPcptsInvite([event.pcpt.id], event.txnId, true);
      }
    } else if (event instanceof ParticipantListRequested) {
      refreshParticipants({ txnId: event.txnId });
    }

    prevEvent = event;
  };

  return {
    emitPcptEvent: emitEvent,
    participantsListState,
  };
};
