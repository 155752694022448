import { gql } from "@apollo/client";

export const GetPortfolioDocumentsQuery = gql`
  query Holdings($entitiesIds: [String!]!) {
    documentsInformation(entitiesIds: $entitiesIds) {
      fileId
      fileUrl
      fileName
      fileSize
      reportId
      fundId
      fundName
      managerId
      managerName
      reportDate
      reportType
      documentProcessed
      entityDocumentId
      entityId
    }
  }
`;
