import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { MicrosoftExcelLogo } from "@phosphor-icons/react";

import { cn } from "common/utils";
import { transactionsClient } from "common/clients/ApolloClient";
import { BarLoader } from "common/components/BarLoader";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";

import {
  ITabTableColumn,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";

import { GetTransactionFundDataSoi } from "experiences/transactions/domain/usecases/GetTransactionFundDataSoi";
import {
  coreSectorLabels,
  FundDataAggregateSoiLookthrough,
  FundDataSoiLookthrough,
  investmentGeographyLabels,
  investmentTypeLabels,
} from "experiences/transactions/domain/models/FundDataSoiLookthrough";

import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";
import { FundDataVerifyPermissionOverlay } from "experiences/dashboard/presentation/components/VerifyPermissionOverlay";
import { DataExtractionInProgress } from "experiences/common/DataExtractionInProgress";

import { EmptyState } from "common/components/EmptyState";
import { COMPANY_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";
import { TransactionFundDataContext } from "../../state/TransactionFundDataContext";

const useAggregateSoi = ({
  transactionId,
  currentReportDate,
  selectedHoldings,
}: {
  transactionId: string;
  currentReportDate?: string;
  selectedHoldings: string[];
}) => {
  const { refetch, data, loading } = useQuery<FundDataSoiLookthrough>(
    GetTransactionFundDataSoi,
    {
      variables: {
        transactionId: transactionId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldings,
      },
      client: transactionsClient,
      fetchPolicy: "cache-and-network",
      skip: !currentReportDate || !transactionId,
    },
  );

  useEffect(() => {
    if (currentReportDate && transactionId && selectedHoldings.length > 0) {
      refetch({
        transactionId: transactionId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldings,
      });
    }
  }, [currentReportDate, transactionId, selectedHoldings]);

  const aggregateSoiLookthrough: FundDataAggregateSoiLookthrough[] =
    useMemo(() => {
      const aggregateSoiData = data?.aggregateSoi || [];

      const formattedAggregateSoi = aggregateSoiData.map((fund) => {
        return {
          ...fund,
          lpImpliedInvestmentPercentage:
            fund.lpImpliedInvestmentPercentage || NOT_AVAILABLE_STR,
        };
      });

      const aggregateSoiByInvestmentPercentWithNullValues =
        formattedAggregateSoi.filter(
          (soi) =>
            soi.lpImpliedInvestmentPercentage === null ||
            soi.lpImpliedInvestmentPercentage === NOT_AVAILABLE_STR ||
            soi.lpImpliedInvestmentPercentage === "",
        );

      const aggregateSoiByInvestmentPercentNonNull =
        formattedAggregateSoi.filter(
          (soi) =>
            soi.lpImpliedInvestmentPercentage !== null &&
            soi.lpImpliedInvestmentPercentage !== NOT_AVAILABLE_STR &&
            soi.lpImpliedInvestmentPercentage !== "",
        );

      const sortedAggregateSoiByInvestmentPercentNonNull =
        aggregateSoiByInvestmentPercentNonNull.sort(
          (a, b) =>
            Number(b.lpImpliedInvestmentPercentage) -
            Number(a.lpImpliedInvestmentPercentage),
        );

      return [
        ...sortedAggregateSoiByInvestmentPercentNonNull,
        ...aggregateSoiByInvestmentPercentWithNullValues,
      ];
    }, [data, currentReportDate, transactionId, selectedHoldings]);

  return {
    refetch,
    aggregateSoiLookthrough,
    loading,
  };
};

// prettier-ignore
const columns: ITabTableColumn[] = [
  { key: "investmentName", label: "Investment Name", align: TableAlignment.LEFT, className: "!w-96 !max-w-96" },
  { key: "transactionPercent", label: "Txn %", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "lpImpliedInvestmentValue", label: "LP Implied Investment Value", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "fundName", label: "Fund Name", align: TableAlignment.LEFT, className: "!w-36 !min-w-36" },
  { key: "investmentDate", label: "Investment Date", align: TableAlignment.LEFT, className: "!w-36 !min-w-36" },
  { key: "holdPeriod", label: "Holding Period", align: TableAlignment.LEFT, className: "!w-36 !min-w-36" },
  { key: "investmentType", label: "Investment Type", align: TableAlignment.LEFT, className: "!w-36 !min-w-36" },
  { key: "investmentGeography", label: "Investment Geography", align: TableAlignment.LEFT, className: "!w-36 !min-w-36" },
  { key: "sector", label: "Sector", align: TableAlignment.LEFT, className: "!w-36 !min-w-36" },
  { key: "realizedCost", label: "Cost", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "fmv", label: "FMV", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "realizedValue", label: "Realized Value", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "totalCost", label: "Total Cost", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "totalValue", label: "Total Value", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
  { key: "investmentMoic", label: "Investment MOIC", align: TableAlignment.RIGHT, className: "!w-36 !min-w-36" },
];

export const SoiLookthrough = ({
  status,
  handleDownloadClick,
  isDownloading,
}: {
  status: TransactionDataSummarySectionStatus;
  handleDownloadClick: () => void;
  isDownloading: boolean;
}) => {
  const { txnId } = useParams();
  const { selectedHoldingsIds, reportDates } = useContext(
    TransactionFundDataContext,
  );

  const { aggregateSoiLookthrough, loading } = useAggregateSoi({
    transactionId: txnId,
    currentReportDate: reportDates.soiLookthrough.currentDate,
    selectedHoldings: selectedHoldingsIds,
  });

  const hasReportDates = reportDates.soiLookthrough.options.length > 0;
  const hasData = aggregateSoiLookthrough.length > 0;
  const awaitingUpload =
    status === TransactionDataSummarySectionStatus.NotYetUploaded;
  const extractionInProgress =
    status === TransactionDataSummarySectionStatus.UploadedAndProcessing;
  const extractionReady =
    status === TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  return (
    <div className="relative min-h-64">
      <PageSectionTitleDivider
        actions={
          <>
            {extractionReady && hasReportDates && (
              <>
                <SelectDateDropdown
                  value={reportDates.soiLookthrough.currentDate}
                  onValueChange={reportDates.soiLookthrough.onReportDateChange}
                  options={reportDates.soiLookthrough.options}
                  loading={loading}
                />
                <Button
                  onClick={handleDownloadClick}
                  type={BUTTON_TYPES.SECONDARY}
                  icon={<MicrosoftExcelLogo />}
                  loading={isDownloading}
                  disabled={isDownloading}
                >
                  Download Data
                </Button>
              </>
            )}
          </>
        }
      >
        SOI Lookthrough
      </PageSectionTitleDivider>

      {awaitingUpload && <FundDataVerifyPermissionOverlay txnId={txnId} />}
      {extractionInProgress && <DataExtractionInProgress />}

      {extractionReady && (
        <div className="w-full max-w-full overflow-x-scroll h-full max-h-96 overflow-y-scroll relative pb-12">
          {!loading && hasData && (
            <TapTable
              skipTopBorder
              columns={columns}
              fixedHeaders
              layoutFixed
              className="!max-w-max !w-max !overflow-scroll"
              data={aggregateSoiLookthrough}
              renderRow={({
                companyId,
                companyName,
                fundName,
                lpImpliedInvestmentPercentage,
                investmentDate,
                holdPeriod,
                investmentType,
                investmentGeography,
                sector,
                totalCost,
                totalValue,
                investmentMoic,
                realizedValue,
                unrealizedCost,
                unrealizedValue,
                lpImpliedInvestmentValue,
                isHumanVerified,
              }: FundDataAggregateSoiLookthrough) => {
                const isUnverified = !isHumanVerified;
                const imgSrc = `${COMPANY_LOGO_URL}/${
                  companyId || undefined
                }.jpg`;
                // prettier-ignore
                return (
                  <>
                    <TableCell isPrimaryColumn isFixed className={cn("!whitespace-nowrap !overflow-hidden !overflow-ellipsis !pt-1 !pb-1", "!w-96 !max-w-96")}><TableFundLogo imgSrc={imgSrc} fundName={companyName || NOT_AVAILABLE_STR} /></TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: lpImpliedInvestmentPercentage, format: "percentage2dec" })}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: lpImpliedInvestmentValue, format: "currency" })}</TableCell>
                    <TableCell isUnverified={isUnverified}>{fundName || NOT_AVAILABLE_STR}</TableCell>
                    <TableCell isUnverified={isUnverified}>{investmentDate || NOT_AVAILABLE_STR}</TableCell>
                    <TableCell isUnverified={isUnverified}>{holdPeriod || NOT_AVAILABLE_STR}</TableCell>
                    <TableCell isUnverified={isUnverified}>{investmentType ? investmentTypeLabels[investmentType] : NOT_AVAILABLE_STR}</TableCell>
                    <TableCell isUnverified={isUnverified}>{investmentGeography ? investmentGeographyLabels[investmentGeography] : NOT_AVAILABLE_STR}</TableCell>
                    <TableCell isUnverified={isUnverified}>{sector ? coreSectorLabels[sector] : NOT_AVAILABLE_STR}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: unrealizedCost, format: "currency" })}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: unrealizedValue, format: "currency" })}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: realizedValue, format: "currency" })}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: totalCost, format: "currency" })}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: totalValue, format: "currency" })}</TableCell>
                    <TableCell isUnverified={isUnverified} className="text-right">{tableCellDataFormatter({ value: investmentMoic, format: "multiplier" })}</TableCell>
                  </>
                );
              }}
            />
          )}
          {!loading && !hasData && (
            <EmptyState title="There's no data available for this report." />
          )}
          {loading && !hasData && <BarLoader />}
        </div>
      )}
    </div>
  );
};
