import React, { useMemo, useCallback, useState, forwardRef } from "react";
import { interpolate, samples, parse, formatHex } from "culori";

import * as d3 from "d3";
import { ParentSize } from "@visx/responsive";
import { Line, Bar } from "@visx/shape";
import { GridRows, GridColumns } from "@visx/grid";
import { scaleTime, scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { max, min, extent, bisector } from "@visx/vendor/d3-array";
import { timeFormat } from "@visx/vendor/d3-time-format";

import { IPriceHistory } from "experiences/funds/domain/models/FundPriceHistory";
import { chartColors } from "experiences/common/CircleChart";
import { PortfolioPerformanceGroupingKeys } from "./TableGrouping";

const tapMvpiLineColor = "#BCB3A5";
const gridColor = "#d9dbde";

export const CHART_KEY_NAV_BY_STRATEGY: PortfolioPerformanceGroupingKeys =
  PortfolioPerformanceGroupingKeys.Strategy;

export const CHART_KEY_NAV_BY_ENTITY: PortfolioPerformanceGroupingKeys =
  PortfolioPerformanceGroupingKeys.Entity;

export const CHART_KEY_NAV_BY_GEOGRAPHY: PortfolioPerformanceGroupingKeys =
  PortfolioPerformanceGroupingKeys.Geography;

export const CHART_KEY_NAV_BY_VINTAGE: PortfolioPerformanceGroupingKeys =
  PortfolioPerformanceGroupingKeys.Vintage;

export const CHART_KEY_NAV_BY_MANAGER: PortfolioPerformanceGroupingKeys =
  PortfolioPerformanceGroupingKeys.Manager;

// util
export const formatDate = timeFormat("%b %d, '%y");
export const IOI_DATE_FORMAT = "%Y-%m-%d";

export type TVPI_CHART = {
  date: string;
  tvpi: number;
  marketTvpi: number;
  nav: number;
  marketNav: number;
  percentOfNav: number;
};

// override keys
const CHART_NAV_TOTAL = "total";
const CHART_NAV_BUYOUT = "buyout";
const CHART_NAV_CREDIT = "credit";
const DATE_KEY = "referenceDate";

const CHARTS: {
  key: PortfolioPerformanceGroupingKeys;
  keys: string[];
  label: string;
  colors: string[];
}[] = [
  {
    key: CHART_KEY_NAV_BY_STRATEGY,
    keys: [CHART_NAV_TOTAL, CHART_NAV_BUYOUT, CHART_NAV_CREDIT],
    label: "NAV by Strategy",
    colors: chartColors.blue,
  },
  {
    key: CHART_KEY_NAV_BY_ENTITY,
    keys: [],
    label: "NAV by Entity",
    colors: chartColors.blue,
  },
  {
    key: CHART_KEY_NAV_BY_GEOGRAPHY,
    keys: [CHART_NAV_TOTAL],
    label: "NAV by Geography",
    colors: chartColors.blue,
  },
  {
    key: CHART_KEY_NAV_BY_VINTAGE,
    keys: [],
    label: "NAV by Vintage",
    colors: chartColors.blue,
  },
  {
    key: CHART_KEY_NAV_BY_MANAGER,
    keys: [],
    label: "NAV by Manager",
    colors: chartColors.blue,
  },
];

// accessors
const getHistoricPriceDate = (d: IPriceHistory[0]) => new Date(d[DATE_KEY]);
const bisectDate = bisector<TVPI_CHART, Date>(
  (d) => new Date(d[DATE_KEY]),
).left;

const leftAxisWidth = 70;

const timeRangeInDaysOptions = [
  {
    label: "3Y",
    value: 365 * 3,
  },
  {
    label: "10Y",
    value: 365 * 10,
  },
];

const usePriceHistoryChart = ({}: {}) => {
  const [timeRange, setTimeRange] = useState(
    timeRangeInDaysOptions[timeRangeInDaysOptions.length - 1].value,
  );

  // useEffect(() => {}, [selectedChart]);

  const handleTimeOptionClick = (value: number) => {
    setTimeRange(value);
  };

  return {
    handleTimeOptionClick,
    timeRange,
  };
};

const getXscale = ({
  historicPricing,
  margin,
  innerWidth,
  timeRange,
}: {
  historicPricing: IPriceHistory;
  margin: { top: number; right: number; bottom: number; left: number };
  innerWidth: number;
  timeRange: number;
}) => {
  const filteredHistoricPricing = historicPricing.filter((price) => {
    const timeRangeToStartDate = new Date();
    timeRangeToStartDate.setDate(timeRangeToStartDate.getDate() - timeRange);
    const pricePointDate = d3.timeParse(IOI_DATE_FORMAT)(price.referenceDate);
    return pricePointDate >= timeRangeToStartDate;
  });

  return scaleTime({
    domain: extent(filteredHistoricPricing, getHistoricPriceDate) as [
      Date,
      Date,
    ],
    range: [margin.left, innerWidth + margin.left],
  });
};

const PriceHistoryChart = ({
  width,
  height,
  margin = { top: 16, right: 0, bottom: 32, left: 0 + leftAxisWidth },
  style,
  historicPricing = [],
  currentChart,
}: {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  parentWidth?: number;
  parentHeight?: number;
  parentTop?: number;
  parentLeft?: number;
  parentRef?: HTMLDivElement | null;
  resizeParent?: number;
  style?: React.CSSProperties;
  historicPricing?: IPriceHistory;
  currentChart: string;
}) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip({
    tooltipOpen: false,
  });

  const currentChartSpecs = CHARTS.find((spec) => spec.key === currentChart);

  const { handleTimeOptionClick, timeRange } = usePriceHistoryChart({});

  const { containerRef } = useTooltipInPortal({
    detectBounds: true, // use TooltipWithBounds
    scroll: true, // when tooltip containers are scrolled, this will correctly update the Tooltip position
  });

  const firstHistoricPrice: IPriceHistory[0] =
    historicPricing && historicPricing.length && historicPricing?.[0];
  const lastHistoricPrice: IPriceHistory[0] =
    historicPricing &&
    historicPricing.length &&
    historicPricing?.[historicPricing.length - 1];

  // create new const called startDate should be set to the date from `timeRange` days ago
  const startDate =
    (firstHistoricPrice.referenceDate &&
      d3.timeParse(IOI_DATE_FORMAT)(firstHistoricPrice.referenceDate)) ||
    new Date();
  startDate.setDate(startDate.getDate() - timeRange);
  const today = new Date();

  const stock = historicPricing
    .filter((price) => {
      const pricePointDate = d3.timeParse(IOI_DATE_FORMAT)(price.referenceDate);
      return pricePointDate >= startDate && pricePointDate <= today;
    })
    .reduce((acc, price, i, prices) => {
      // if price[DATE_KEY] is not today, add a new price point to the array that is the same price but with today's date
      if (prices.length - 1 === i) {
        const pricePointDate = d3.timeParse(IOI_DATE_FORMAT)(
          price.referenceDate,
        );
        if (pricePointDate < today) {
          const newLastPrice = {
            ...price,
            date: d3.timeFormat(IOI_DATE_FORMAT)(today),
          };
          return [...acc, price, newLastPrice];
        }
      }

      return [...acc, price];
    }, []);

  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // scales
  const xScale = useMemo(
    () => getXscale({ historicPricing, margin, innerWidth, timeRange }),
    [innerWidth, margin.left, timeRange, historicPricing],
  );

  const yScale = useMemo(() => {
    // TODO: should consider only visible points in current time scale

    // note: for PriceHistoryChartType.TVPI, there are 4 different sets of data
    // 1. tvpi
    // 2. market tvpi
    // 3. bids
    // 4. asks
    // we need to find the highest and lowest point of all 4 sets of data
    // and set the yScale domain to that
    let highestPoint = 0;
    let lowestPoint = 0;

    const highestPointLines =
      max(historicPricing, (d) => d[CHART_NAV_TOTAL]) || 0;
    const lowestPointLines =
      min(historicPricing, (d) => d[CHART_NAV_TOTAL]) || 0;
    const highestPointLines2 =
      max(historicPricing, (d) => d[CHART_NAV_BUYOUT]) || 0;
    const lowestPointLines2 =
      min(historicPricing, (d) => d[CHART_NAV_BUYOUT]) || 0;

    highestPoint = Math.max(highestPointLines, highestPointLines2); // add multiple lines here
    lowestPoint = Math.min(lowestPointLines, lowestPointLines2);

    return scaleLinear({
      domain: [lowestPoint * 1.2, highestPoint * 1.2], // add 20% buffer
      range: [innerHeight + margin.top, margin.top],
      nice: true,
    });
  }, [margin.top, innerHeight, timeRange, historicPricing]);

  // tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x);
      const index = bisectDate(historicPricing, x0, 1);
      const d0 = historicPricing[index - 1];
      const d1 = historicPricing[index];
      let d = d0;
      if (d1 && getHistoricPriceDate(d1)) {
        d =
          x0.valueOf() - getHistoricPriceDate(d0).valueOf() >
          getHistoricPriceDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }

      let tooltipTop = 0;

      yScale(d[CHART_NAV_TOTAL]);
      tooltipTop = yScale(d[CHART_NAV_TOTAL]);

      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: tooltipTop,
      });
    },
    [showTooltip, yScale, xScale],
  );

  const chartColorsByKey = currentChartSpecs.keys.map((key, i) => {
    const interpolated: () => any = interpolate([
      parse(currentChartSpecs.colors[0]),
      parse(currentChartSpecs.colors[1]),
    ]);
    const sampled: number[] = samples(currentChartSpecs.keys.length);
    const palette: { mode: string; r: number; g: number; b: number }[] =
      sampled.map(interpolated);
    const realPalette: string[] = palette.map(formatHex);

    return { key, colors: realPalette[i] };
  });

  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="ml-4 flex flex-row gap-3 h-10">
          {chartColorsByKey.map((spec) => {
            return (
              <div
                key={spec.key}
                className="text-xs flex items-center gap-1 h-10 select-none cursor-default"
              >
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: spec.colors }}
                />
                {spec.key}
              </div>
            );
          })}
        </div>
        <div className="flex flex-row gap-3">
          {timeRangeInDaysOptions.map((option) => {
            const isSelected = option.value === timeRange;
            return (
              <div
                key={`${option.label}-${option.value}`}
                onClick={() => handleTimeOptionClick(option.value)}
                style={{
                  borderBottom: isSelected
                    ? "2px solid #21272D"
                    : "2px solid transparent",
                  borderRadius: 0,
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <svg width={width} height={height} ref={containerRef}>
          <AxisLeft
            scale={yScale}
            left={leftAxisWidth}
            hideAxisLine
            hideTicks
            tickFormat={(value, i, values) => {
              // hide the edges
              if (i === 0 || values.length - 1 === i) {
                return undefined;
              }
              return `${value}`;
            }}
          />

          <AxisBottom
            scale={xScale}
            top={innerHeight + 16}
            hideAxisLine
            hideTicks
          />

          <LinearGradient
            id="area-gradient"
            from={tapMvpiLineColor}
            to={tapMvpiLineColor}
            fromOpacity={1}
            toOpacity={0}
          />
          <GridRows
            left={margin.left}
            scale={yScale}
            width={innerWidth}
            strokeDasharray="1,10"
            stroke={gridColor}
            pointerEvents="none"
          />
          <GridColumns
            left={margin.left}
            top={margin.top}
            scale={xScale}
            height={innerHeight}
            strokeDasharray="1,10"
            stroke={gridColor}
            pointerEvents="none"
          />

          {currentChartSpecs.keys.map((spec, i) => {
            const innerChart = CHARTS.find((spec) => spec.key === currentChart);

            const currentKey = innerChart.keys[i];

            const interpolated: () => any = interpolate([
              parse(innerChart.colors[0]),
              parse(innerChart.colors[1]),
            ]);
            const sampled: number[] = samples(innerChart.keys.length);
            const palette: { mode: string; r: number; g: number; b: number }[] =
              sampled.map(interpolated);
            const realPalette: string[] = palette.map(formatHex);

            return (
              <LinePath
                data={historicPricing}
                x={(d) => {
                  return xScale(d3.timeParse(IOI_DATE_FORMAT)(d.referenceDate));
                }}
                y={(d) => {
                  const yValue = yScale(d[currentKey]);
                  return yValue;
                }}
                stroke={realPalette[i]}
                strokeWidth={2}
                curve={d3.curveLinear}
              />
            );
          })}

          {/* 👇 This is for the tooltip line, do not remove */}
          <Bar
            x={margin.left} // todo: sum leftAxisWidth, fix circle position 1st
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{
                  x: tooltipLeft,
                  y: innerHeight + margin.top,
                }}
                stroke={"#21272D"}
                strokeWidth={1}
                strokeOpacity={0.1}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={"white"}
                stroke="#BCB3A5"
                strokeWidth={1}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
      </div>
    </div>
  );
};

// Note: main component is wrapped in forwardRef to allow parent component to scroll to this component
export const PerformanceChartWrapper = forwardRef(
  function PriceHistoryChartWrapper(
    {
      data,
      currentChart,
    }: {
      data: any[];
      currentChart: string;
    },
    ref: any,
  ) {
    var day = 60 * 60 * 24 * 1000;

    let historicPricing =
      data.length === 1
        ? [
            data[0],
            {
              referenceDate: new Date(
                new Date(
                  new Date(data[0].referenceDate).getTime() + day,
                ).toISOString(),
              )
                .toISOString()
                .substring(0, 10),
              price: data[0].price,
            },
          ]
        : data;

    return (
      <div style={{ position: "relative", width: "100%" }} ref={ref}>
        <ParentSize>
          {(parent) => (
            <PriceHistoryChart
              width={parent.width}
              height={300}
              currentChart={currentChart}
              historicPricing={historicPricing}
            />
          )}
        </ParentSize>
      </div>
    );
  },
);

// prettier-ignore
export  const navByStrategyMock = [
  { referenceDate: "2014-01-01", [CHART_NAV_TOTAL]: -9.7, [CHART_NAV_BUYOUT]: -2, [CHART_NAV_CREDIT]: 9.7 },
  { referenceDate: "2015-01-01", [CHART_NAV_TOTAL]: 1.6, [CHART_NAV_BUYOUT]: -2, [CHART_NAV_CREDIT]: 7.2 },
  { referenceDate: "2016-01-01", [CHART_NAV_TOTAL]: -7.3, [CHART_NAV_BUYOUT]: 8, [CHART_NAV_CREDIT]: 1.1 },
  { referenceDate: "2017-01-01", [CHART_NAV_TOTAL]: -3.5, [CHART_NAV_BUYOUT]: 6, [CHART_NAV_CREDIT]: 4.7 },
  { referenceDate: "2018-01-01", [CHART_NAV_TOTAL]: 1.3, [CHART_NAV_BUYOUT]: -10, [CHART_NAV_CREDIT]: 10 },
  { referenceDate: "2019-01-01", [CHART_NAV_TOTAL]: 2.3, [CHART_NAV_BUYOUT]: 1, [CHART_NAV_CREDIT]: 1.0 },
  { referenceDate: "2020-01-01", [CHART_NAV_TOTAL]: 2.9, [CHART_NAV_BUYOUT]: -1, [CHART_NAV_CREDIT]: 5.1 },
  { referenceDate: "2021-01-01", [CHART_NAV_TOTAL]: -2.0, [CHART_NAV_BUYOUT]: -8, [CHART_NAV_CREDIT]: 2.9 },
  { referenceDate: "2022-01-01", [CHART_NAV_TOTAL]: 2.6, [CHART_NAV_BUYOUT]: 2, [CHART_NAV_CREDIT]: 3.5 },
  { referenceDate: "2023-01-01", [CHART_NAV_TOTAL]: -9.7, [CHART_NAV_BUYOUT]: 10, [CHART_NAV_CREDIT]: 6.5 },
  { referenceDate: "2024-01-01", [CHART_NAV_TOTAL]: 6.7, [CHART_NAV_BUYOUT]: -10, [CHART_NAV_CREDIT]: 9.4 },
]
