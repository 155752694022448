import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { Fund } from "../models/Fund";
import { FundGeography } from "../../../common/models/FundGeography";
import { FundStrategy } from "../../../common/models/FundStrategy";

interface IResp {
  id: string;
  name: string;
  managerName: string;
  geography: FundGeography;
  strategy: FundStrategy;
  vintage: number;
  size: number;
  isWatchlisted: boolean;
}

export class FundToWatchlistAction implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    fundIds,
    action,
  }) => {
    let fundUrlTerm = "fund";
    if (fundIds.length > 1) {
      fundUrlTerm = "funds";
    }

    const actionText = action == ActionType.Add ? "add" : "remove";
    return await new TapClient(
      `watchlist/${fundUrlTerm}/${actionText}/`,
      fundIds.length > 1 ? { fundIds: fundIds } : { fundId: fundIds[0] },
    )
      .post()
      .then((resp: IResp) => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  fundIds: string[];
  action: ActionType;
}

export enum ActionType {
  Add = 1,
  Remove = 2,
}
