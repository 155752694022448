import { useNavigate } from "react-router";

import { DashboardList } from "experiences/common/List";
import { Buyer } from "experiences/dashboard/mock/GP";
import { DashboardRightColumnContent } from "../../components/DashboardRightColumn";
import TextChip from "experiences/common/TextChip";
import { GP_ROUTES } from "common/constants/routes";
import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";

const RenderBuyerListTable = ({ buyers }: { buyers: Buyer[] }) => {
  return (
    <>
      <table className={`dashboard_table dont_hover`}>
        <thead>
          <tr className="select-none">
            <th>Buyer Name</th>
            <th className="text-right tabular-nums">Status</th>
          </tr>
        </thead>
        <tbody>
          {buyers.map((buyer, i) => {
            return (
              <tr>
                <td className="!cursor-default select-none">
                  <StyledFundNameAndImage>
                    <img
                      src={`https://cdn.usetap.com/funds/logos/${buyer.logoFileName}`}
                      alt="Fund logo"
                    />
                    {buyer.name}
                  </StyledFundNameAndImage>
                </td>

                <td className="!cursor-default text-right variant-numeric">
                  <TextChip
                    key={i}
                    text={buyer.status}
                    success={buyer.status.toLocaleLowerCase() === "approved"}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const GpDashboardRightColumn = ({
  buyers,
  buyersLoaded,
}: {
  buyers: Buyer[];
  buyersLoaded: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <DashboardRightColumnContent>
      <DashboardList
        title="Buyer List"
        table={<RenderBuyerListTable buyers={buyers} />}
        onTitleClick={() => navigate(GP_ROUTES.ApprovedBuyersApproved)}
      />
    </DashboardRightColumnContent>
  );
};
