import { useState } from "react";
import { useSnackbar } from "notistack";
import { match } from "fp-ts/lib/Either";

import {
  MatchNames,
  MatchResult,
} from "experiences/funds/domain/usecases/MatchNames";
import { Failure } from "common/@types/app/Failure";

export const useGetFundNamesFromApi = ({ useAi }: { useAi?: boolean }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCallMatchNames = async ({
    searchTerms,
  }: {
    searchTerms: string[];
  }) => {
    setIsLoading(true);
    return await new MatchNames()
      .call({
        searchTerms,
        useAi,
      })
      .then((resp) => {
        setIsLoading(false);
        return match<Failure, MatchResult[], MatchResult[] | void>(
          (_: Failure) => {
            enqueueSnackbar("Error while getting fund options for list.", {
              variant: "error",
            });
          },
          (matches: MatchResult[]) => {
            return matches;
          },
        )(resp);
      });
  };

  return { handleCallMatchNames, isLoading };
};
