import { useMutation } from "@apollo/client";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";

import { transactionsClient } from "common/clients/ApolloClient";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { uploadFilesToS3 } from "common/utils/uploadFileS3";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { DropZone } from "experiences/common/DropZone";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { UploadLpDocumentsMutation } from "experiences/transactions/domain/usecases/UploadLpDocumentsMutation";

interface IUploadTransactionDocumentsMutation {
  uploadTransactionProcessingDocuments: {
    transactionId: string;
    urls: {
      name: string;
      url: string;
      fields: {
        key: string;
        AWSAccessKeyId: string;
        policy: string;
        signature: string;
      };
    }[];
  };
}

export const Step0UploadDocuments = ({
  goToNextStep,
  summary,
}: {
  goToNextStep: () => void;
  summary: ITransactionSummary;
}) => {
  const { txnId } = useParams();
  const [files, setFiles] = useState<File[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const { user } = useAuthContext();
  const { sendMessage } = useSendSlackMessage();
  const [uploadDocuments, { loading: mutationLoading }] =
    useMutation<IUploadTransactionDocumentsMutation>(
      UploadLpDocumentsMutation,
      {
        client: transactionsClient,
      },
    );

  const handleFileChange = (newFiles: File[]) => {
    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else {
      setFiles([]);
    }
  };

  const handleContinueClick = async () => {
    // Step 1: Call the mutation to upload the files, this will return a signed URL for each file
    const response = await uploadDocuments({
      variables: {
        transactionId: txnId,
        files: files.map((file) => file.name),
      },
    });

    // Step 2: Upload the files to the signed URL

    setUploadingFiles(true);
    const fileCount = files.length;
    const r = await uploadFilesToS3({
      files,
      fileParams:
        response?.data?.uploadTransactionProcessingDocuments?.urls || [],
    })
      .then(() => {
        toast.success("Files have been uploaded successfully!");
        goToNextStep();
      })
      .catch(() => {
        toast.error("Failed to upload files, please try again.");
      })
      .finally(() => {
        setUploadingFiles(false);
        sendMessage({
          blocks: [
            generateSlackMessagePayload({
              title: `${user.firstName} ${user.lastName} has uploaded ${
                fileCount === 1 ? "a file" : `${fileCount} files`
              } to transaction documents.`,
              fields: generateObjectAsFields({
                "Transaction ID": txnId,
                "Transaction Name": summary?.transaction?.name,
                // Generate one entry for each file
                "File Names": files
                  .map((file) => {
                    return file.name;
                  })
                  .join(", "),
              }),
            }),
          ],
        });
      });
  };

  return (
    <div className="max-w-2xl flex flex-col gap-8 h-full pt-12 pb-12">
      <div className="flex-1 flex flex-col gap-8">
        <div>
          <PageHead
            paddingless
            title="Upload LP Documents"
            description={
              <>
                Confidentially upload fund documents to get access to detailed
                extracted data. If you would like to sign a mutual NDA prior to
                upload you can do so on the Dashboard.
              </>
            }
          />
        </div>
        <DropZone
          className="h-64 max-h-64"
          filesClassName="max-h-80 no-scrollbar overflow-scroll pb-4"
          onFileChange={handleFileChange}
          multiple
          compactFileList
          showRemoveButton
          accept={["pdf"]}
          acceptDropzone={{
            "application/pdf": [".pdf", ".PDF"],
            "application/x-pdf": [".pdf", ".PDF"],
          }}
          customMessage={
            <>
              Drop or <span className="link">choose file</span> to upload your
              Fund Documents
            </>
          }
        />
      </div>
      <div className="flex flex-row justify-end">
        <Button
          type={BUTTON_TYPES.SECONDARY}
          onClick={handleContinueClick}
          disabled={files.length === 0 || mutationLoading || uploadingFiles}
          icon={<ArrowRight />}
          loading={mutationLoading || uploadingFiles}
          iconRight
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
