import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";

import { FundsTable } from "experiences/funds/presentation/components/FundsTable";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import { useFundsContext } from "../state/FundsContext";
import {
  FundSizeFilterChanged,
  GeographyFilterChanged,
  SearchTermChanged,
  StrategyFilterChanged,
  VintageFilterChanged,
} from "../state/FundsEvent";
import ConfirmCreateAlertModal from "../components/ConfirmCreateAlertModal";
import StrategyFilter from "../components/funds/filters/StrategyFilter";
import VintageFilter from "../components/funds/filters/VintageFilter";
import GeographyFilter from "../components/funds/filters/GeographyFilter";
import SizeFilter from "../components/funds/filters/SizeFilter";
import { SearchInput } from "common/components/SearchInput";
import { FundStrategy } from "experiences/common/models/FundStrategy";
import { generateQueryString } from "./Alerts";
import { FundGeography } from "experiences/common/models/FundGeography";
import { Range } from "experiences/common/models/Range";
import { LP_ROUTES } from "common/constants/routes";

const Funds = () => {
  return (
    <Stack width="100%">
      <Helmet title="Funds" />
      <FundsTableControlsBelt />
      <FundsTable />
    </Stack>
  );
};

const getStrategiesFromUrl = (
  searchParams: URLSearchParams,
): FundStrategy[] => {
  const prestrategies = searchParams.get("strategies") || "";

  if (prestrategies.length === 0) return [];

  const strategies = prestrategies.split(",");

  return strategies.map((strategy) => {
    return Number(strategy);
  });
};

const getVintagesFromUrl = (searchParams: URLSearchParams): Range<number> => {
  const vintageStart = searchParams.get("vintageStart") || "";
  const vintageEnd = searchParams.get("vintageEnd") || "";

  return {
    start: vintageStart.length > 0 ? Number(vintageStart) : undefined,
    end: vintageEnd.length > 0 ? Number(vintageEnd) : undefined,
  };
};

const getGeographiesFromUrl = (
  searchParams: URLSearchParams,
): FundGeography[] => {
  const pregeographies = searchParams.get("geographies") || "";
  if (pregeographies.length === 0) return [];

  const geographies = pregeographies.split(",");

  return geographies.map((geography) => {
    return Number(geography);
  });
};

const getFundSizeFromUrl = (searchParams: URLSearchParams): Range<number> => {
  const fundSizeStart = searchParams.get("fundSizeStart") || "";
  const fundSizeEnd = searchParams.get("fundSizeEnd") || "";

  return {
    start: fundSizeStart.length > 0 ? Number(fundSizeStart) : undefined,
    end: fundSizeEnd.length > 0 ? Number(fundSizeEnd) : undefined,
  };
};

const useFundsTableControlsBelt = () => {
  const { emitEvent, filtersSpecs, actionBeltState } = useFundsContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(location.search),
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const strategies = getStrategiesFromUrl(searchParams);
  const { start: vintageStart, end: vintageEnd } =
    getVintagesFromUrl(searchParams);
  const geographies = getGeographiesFromUrl(searchParams);
  const { start: fundSizeStart, end: fundSizeEnd } =
    getFundSizeFromUrl(searchParams);

  useEffect(() => {
    emitEvent!(new SearchTermChanged({ searchTerm: search }));
  }, [search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // prettier-ignore
  const handleInitialPageLoad = () => {
    // emit events for all filters so that initial page load either removes old filters or filters new params
    emitEvent!(new StrategyFilterChanged({ strategies }));
    emitEvent!(new VintageFilterChanged({vintageRange: { start: vintageStart, end: vintageEnd }}));
    emitEvent!(new GeographyFilterChanged({ geographies }));
    emitEvent!(new FundSizeFilterChanged({sizeRange: { start: fundSizeStart, end: fundSizeEnd }}));
    emitEvent!(new SearchTermChanged({ searchTerm: search }));
  };

  useEffect(() => {
    handleInitialPageLoad();
  }, []);

  useEffect(() => {
    const queryDataToString = generateQueryString({
      strategies: filtersSpecs?.strategies,
      vintageStart,
      vintageEnd,
      geographies,
      fundSizeStart,
      fundSizeEnd,
      search,
    });

    navigate(`${LP_ROUTES.FundsScreen}?${queryDataToString}`);
  }, [filtersSpecs?.strategies]);

  useEffect(() => {
    const queryDataToString = generateQueryString({
      strategies,
      vintageStart: filtersSpecs?.vintageRange?.start,
      vintageEnd: filtersSpecs?.vintageRange?.end,
      geographies,
      fundSizeStart,
      fundSizeEnd,
      search,
    });

    navigate(`${LP_ROUTES.FundsScreen}?${queryDataToString}`);
  }, [filtersSpecs?.vintageRange?.start, filtersSpecs?.vintageRange?.end]);

  useEffect(() => {
    const queryDataToString = generateQueryString({
      strategies,
      vintageStart,
      vintageEnd,
      geographies: filtersSpecs?.geographies,
      fundSizeStart,
      fundSizeEnd,
      search,
    });

    navigate(`${LP_ROUTES.FundsScreen}?${queryDataToString}`);
  }, [filtersSpecs?.geographies]);

  useEffect(() => {
    const queryDataToString = generateQueryString({
      strategies,
      vintageStart,
      vintageEnd,
      geographies,
      fundSizeStart: filtersSpecs?.sizeRange?.start,
      fundSizeEnd: filtersSpecs?.sizeRange?.end,
      search,
    });

    navigate(`${LP_ROUTES.FundsScreen}?${queryDataToString}`);
  }, [filtersSpecs?.sizeRange?.start, filtersSpecs?.sizeRange?.end]);

  useEffect(() => {
    const queryDataToString = generateQueryString({
      strategies,
      vintageStart,
      vintageEnd,
      geographies,
      fundSizeStart,
      fundSizeEnd,
      search: filtersSpecs?.searchTerm,
    });

    navigate(`${LP_ROUTES.FundsScreen}?${queryDataToString}`);
  }, [filtersSpecs?.searchTerm]);

  return {
    search,
    handleSearchChange,
  };
};

const FundsTableControlsBelt = () => {
  const { handleSearchChange, search } = useFundsTableControlsBelt();

  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      spacing={2}
      p={2}
    >
      <Stack direction={"row"} spacing={1} alignItems="center">
        <SearchInput
          value={search}
          placeholder="Search by fund or manager name"
          onChange={handleSearchChange}
        />
        <StrategyFilter />
        <VintageFilter />
        <GeographyFilter />
        <SizeFilter />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <CreateAlertButton />
      </Stack>
    </Stack>
  );
};

export const CreateAlertButton = () => {
  const { filtersSpecs } = useFundsContext();

  const filtersHaveBeenSet =
    filtersSpecs?.geographies?.length ||
    filtersSpecs?.strategies?.length ||
    filtersSpecs?.sizeRange ||
    filtersSpecs?.vintageRange ||
    filtersSpecs?.searchTerm;

  const [open, setOpen] = useState<boolean>(false);

  const handleCreateAlert = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      title={
        filtersHaveBeenSet
          ? ""
          : "Change the filters to create an alert for your search"
      }
    >
      <Box>
        <Button
          onClick={handleCreateAlert}
          disabled={!filtersHaveBeenSet}
          type={BUTTON_TYPES.SECONDARY}
          icon={<PlusIcon />}
        >
          Create Alert
        </Button>
        <ConfirmCreateAlertModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default Funds;
