import { useNavigate } from "react-router";

import { LP_ROUTES } from "common/constants/routes";
import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { TransactionNDAsLoaded } from "experiences/transactions/presentation/state/TransactionsState";
import {
  useSendSlackMessage,
  generateSlackMessagePayload,
  generateUserFields,
  PayloadTextType,
} from "common/hooks/useSendSlackMessage";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

const steps = [
  {
    isActive: true,
    label: "Markup Initiated",
    description: (
      <p>We’ll send you an email within 24 hours to start working.</p>
    ),
  },
  {
    isActive: false,
    label: "Negotiate Terms",
    description: <p>Buyer and seller communicate about NDA terms.</p>,
  },
  {
    isActive: false,
    label: "Execute NDAs",
    description: (
      <p>The signatories sign the non-disclosure agreements in Tap.</p>
    ),
  },
  {
    isActive: false,
    label: "Access Data Room",
    description: (
      <p>
        Tap does KYC, the GP approves the transfer, you wire cash, and sign your
        documents.
      </p>
    ),
  },
];

export const generateNdaFields = (nda: TransactionNDAsLoaded) => {
  // strip the /markup from the url
  // todo: when we have a more robust routes object, use that instead
  const currentTransactionUrl = window.location.href.replace("/markup", "");

  // only send 1 message, thus only 1 nda
  return [
    {
      type: PayloadTextType.Markdown,
      text: `Issuer: *${nda.ndas[0].issuerName}*`,
    },
    {
      type: PayloadTextType.Markdown,
      text: `Transaction ID: *<${currentTransactionUrl}|${nda.ndas[0].transactionId}>*`,
    },
    {
      type: PayloadTextType.Markdown,
      text: `Time: ${new Date().toISOString()}`,
    },
  ];
};

export const useMarkupContext = () => {
  const navigate = useNavigate();
  const { txnNDAsState } = useTransactionsContext();
  const { sendMessage } = useSendSlackMessage();
  const { user } = useAuthContext();

  const handleSaveAndCloseClick = () => {
    if (txnNDAsState instanceof TransactionNDAsLoaded) {
      sendMessage({
        blocks: [
          generateSlackMessagePayload({
            title: "User has clicked NDA markup save and close button.",
            fields: generateUserFields(user),
            context: generateNdaFields(txnNDAsState),
          }),
        ],
      });

      navigate(LP_ROUTES.Transactions);
    }
  };

  const handleDownloadNdaClick = () => {
    if (txnNDAsState instanceof TransactionNDAsLoaded) {
      txnNDAsState.ndas.forEach((nda) => {
        sendMessage({
          blocks: [
            generateSlackMessagePayload({
              title: "User clicked download NDA button.",
              fields: generateUserFields(user),
              context: generateNdaFields(txnNDAsState),
            }),
          ],
        });
        window.open(nda.presignedUrl, "_blank");
      });
    } else {
      // todo: are we logging errors???
      // ideally the user should never get here but if they do we should log it
    }
  };

  return { steps, handleSaveAndCloseClick, handleDownloadNdaClick };
};
