import { Either, left, right } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";
import { TransactionSettingType } from "../models/Transaction";

interface IResp {}

interface ITxnSetting {}

const PATH = "/transactions/:txnId/settings/";

export class UpdateTransactionSetting
  implements IAsyncUseCase<IParams, ITxnSetting>
{
  call: (params: IParams) => Promise<Either<Failure, ITxnSetting>> = async ({
    type,
    isActive,
    txnId,
  }) => {
    return await new TapClient(PATH.replace(":txnId", txnId), {
      type,
      isActive,
    })
      .post()
      .then((resp: IResp) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  txnId: string;
  type: TransactionSettingType;
  isActive: boolean;
}
