import { useState } from "react";
import { generatePath, useNavigate } from "react-router";
import styled from "styled-components";

import { LP_ROUTES } from "common/constants/routes";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES, PlusIcon } from "common/components/Button";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import HarveyBall from "common/icons/HarveyBall";

import { MainPortfolioScreenLoaded } from "../../../portfolio/presentation/state/PortfolioState";
import { IHolding } from "experiences/portfolio/domain/models/Holding";
import DashboardEmptyStatePlaceholderChart from "../assets/dashboard-empty-placeholder-chart.svg";
import { DashboardList } from "experiences/common/List";
import { FUND_LOGO_URL } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";

const StyledLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: 30%;
  height: inherit;
  max-height: inherit;
  width: 100%;
  height: 100%;
`;

const StyledPortfolioHeader = styled.div`
  display: block;
  margin-top: 16px;

  h1 {
    display: block;
    padding: 0 24px 12px;
    box-sizing: border-box;
    color: #21272d;
    font-family: "StaffWide", "Open sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.36px;
    text-transform: capitalize;
  }

  .portfolio_value {
    display: flex;
    padding: 0 24px;
    box-sizing: border-box;
    align-items: center;
    color: #21272d;
    font-family: "StaffWide", "Open sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.75px;
    text-transform: capitalize;
  }

  .as_of {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 24px;
    box-sizing: border-box;
    color: #737476;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 12px */
  }
`;

const PortfolioHeader = ({
  portfolio,
  holdings,
}: {
  portfolio?: MainPortfolioScreenLoaded;
  holdings?: IHolding[];
}) => {
  const value = portfolio?.portfolio.accumBookValue;

  return (
    <StyledPortfolioHeader>
      <h1>Portfolio</h1>
      <div className="portfolio_value">
        {(value && new DollarAmount(value).formattedBig()) || "––"}
      </div>
      <p className="as_of">As of {new Date().toDateString()}</p>
    </StyledPortfolioHeader>
  );
};

const TABS = [{ label: "Holdings", value: "holdings" }];

const useDashboardLeftColumn = () => {
  const [tab, setTab] = useState(TABS[0].value);
  const navigate = useNavigate();

  const handleTabClick = (tab: string) => {
    if (TABS.map((tab) => tab.value).includes(tab)) {
      setTab(tab);
    }
  };

  const handleAddHoldingsClick = () => {
    navigate(LP_ROUTES.PortfolioConnectPortfolio);
  };

  const handleGoToPortfolioClick = () => {
    navigate(LP_ROUTES.Portfolio);
  };

  return {
    tab,
    handleTabClick,
    handleAddHoldingsClick,
    handleGoToPortfolioClick,
  };
};

const StyledTabs = styled.nav`
  display: flex;
  flex: 1;
  position: relative;
  border-bottom: 1px solid #dfdfd9;
  height: 40px;
  max-height: 40px;

  a {
    display: inline-flex;
    height: inherit;
    padding-left: 9px;
    padding-right: 9px;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    color: #737476;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    align-items: center;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;

    &.active {
      color: #21272d;
      border-bottom: 2px solid #bcb3a5;
    }

    &:hover {
      color: #21272d;
    }

    &:first-child {
      margin-left: 9px;
    }
  }
`;

const TapTabs = ({
  tabs,
  activeTab,
  onTabClick,
  style,
}: {
  tabs: {
    label: string;
    value: string;
  }[];
  activeTab: string;
  onTabClick: (tab: string) => void;
  style?: React.CSSProperties;
}) => {
  return (
    <StyledTabs style={style}>
      {tabs.map((tab) => {
        return (
          <a
            className={tab.value === activeTab ? "active" : ""}
            key={tab.value}
            onClick={() => {
              onTabClick(tab.value);
            }}
          >
            {tab.label}
          </a>
        );
      })}
    </StyledTabs>
  );
};

const HoldingsTable = ({
  holdings,
}: {
  holdings: MainPortfolioScreenLoaded["portfolio"]["holdings"];
}) => {
  const navigate = useNavigate();

  const goToFundDetail = (fundId: string) => {
    const path = generatePath(LP_ROUTES.FundsFundDetail, { fundId });
    navigate(path);
  };

  const renderHoldings = holdings.map((position, i) => {
    const value =
      (position.marketValue &&
        new DollarAmount(position.marketValue).formattedBig()) ||
      "Pending";
    const price = position.fundPrice
      ? `${position.fundPrice.price}%`
      : "Pending";
    const showHarvey = Boolean(position.fundPrice);

    return (
      <tr
        key={`${position.issuer.id}-${i}`}
        onClick={() => goToFundDetail(position.issuer.id)}
      >
        <td>
          <TableFundLogo
            imgSrc={`${FUND_LOGO_URL}/${position.issuer.id}.jpg`}
            fundName={position.issuer.name}
          />
        </td>
        <td>{value}</td>
        <td className="nowrap">
          <div className="flex flex-direction-row items-center">
            <span style={{ marginRight: "8px" }}>{price}</span>
            {showHarvey ? (
              <HarveyBall
                harveyLevel={position.fundPrice.level}
                transform="scale(1.35)"
              />
            ) : null}
          </div>
        </td>
      </tr>
    );
  });

  const handleAddHoldingsClick = () => {
    navigate(LP_ROUTES.PortfolioConnectPortfolio);
  };

  return (
    <table className={`dashboard_table`} style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>Fund</th>
          <th
            style={{
              width: "30%",
            }}
          >
            Market Value
          </th>
          <th
            style={{
              width: "20%",
            }}
          >
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {holdings.length ? (
          renderHoldings
        ) : (
          <tr>
            <td colSpan={2} style={{ padding: 0 }}>
              <EmptyState
                title="There are no current holdings"
                actions={[
                  {
                    label: "Add holdings",
                    onClick: handleAddHoldingsClick,
                    type: BUTTON_TYPES.SECONDARY,
                    icon: <PlusIcon />,
                  },
                ]}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export const DashboardLeftColumn = ({
  portfolio,
  portfolioLoaded,
}: {
  portfolio?: MainPortfolioScreenLoaded;
  portfolioLoaded: boolean;
}) => {
  const { tab, handleTabClick, handleGoToPortfolioClick } =
    useDashboardLeftColumn();

  const holdings = portfolio?.portfolio?.holdings || [];
  const showEmptyState = holdings.length === 0;

  // Return the base structure of the page without any content inside it
  if (!portfolioLoaded) {
    return <StyledLeftColumn></StyledLeftColumn>;
  }

  return (
    <StyledLeftColumn>
      {showEmptyState ? (
        <>
          <PageSectionTitleDivider onClick={handleGoToPortfolioClick}>
            Portfolio
          </PageSectionTitleDivider>
          <EmptyState
            title="Portfolio"
            description="Add funds to your portfolio to see bids, valuations, or start a sale."
            actions={[
              {
                label: "Add Holdings",
                onClick: handleGoToPortfolioClick,
                type: BUTTON_TYPES.SECONDARY,
                icon: <PlusIcon />,
              },
            ]}
            className="max-h-96"
          />
          <img
            src={DashboardEmptyStatePlaceholderChart}
            alt="Chart"
            style={{ width: "100%", userSelect: "none" }}
          />
        </>
      ) : (
        <>
          <PortfolioHeader portfolio={portfolio} holdings={holdings} />
          <TapTabs
            tabs={[
              {
                label: `Holdings${
                  holdings.length > 0 ? ` (${holdings.length})` : ""
                }`,
                value: "holdings",
              },
            ]}
            activeTab={tab}
            onTabClick={handleTabClick}
            style={{ marginTop: 24, marginBottom: 0, flexGrow: 0 }}
          />
          <DashboardList table={<HoldingsTable holdings={holdings} />} />
        </>
      )}
    </StyledLeftColumn>
  );
};
