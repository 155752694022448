const InfoCircle = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      cx={7}
      cy={7}
      r={6.5}
      stroke="#DBDCDA"
      d="M15.429 8A7.429 7.429 0 0 1 8 15.429A7.429 7.429 0 0 1 0.571 8A7.429 7.429 0 0 1 15.429 8z"
      strokeWidth={1.1428571428571428}
    />
    <path
      d="M7.999 5.934C8.401 5.934 8.694 5.623 8.694 5.23C8.694 4.837 8.401 4.535 7.999 4.535C7.606 4.535 7.304 4.837 7.304 5.23C7.304 5.623 7.606 5.934 7.999 5.934ZM8.538 11.429V6.583H7.459V11.429H8.538Z"
      fill="#737476"
    />
  </svg>
);

export default InfoCircle;
