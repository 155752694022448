import {
  ChartDonut,
  Compass,
  Handshake,
  House,
  Receipt,
} from "@phosphor-icons/react";

import { LP_ROUTES } from "common/constants/routes";

import { NavLinks } from "./NavLinks";

import {
  StyledMenu,
  StyledMenuGroup,
  TapIconLink,
  TapSupportLink,
} from "./styles";
import { FlagNames, tapFeatureFlags } from "../Flags";
import { PortfolioV2Routes } from "experiences/portfolio-v2/presentation/components/navigation";

// LEFT SIDEBAR NAV
// TODO: DO not duplicate icons
export const lpSidebarNavEntries = [
  {
    to: LP_ROUTES.Dashboard,
    label: "Dashboard",
    icon: House,
  },
  {
    to: tapFeatureFlags[FlagNames.ShowPortfolioV2]
      ? `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Summary}`
      : LP_ROUTES.Portfolio,
    label: "Portfolio",
    icon: ChartDonut,
  },
  {
    to: LP_ROUTES.Funds,
    label: "Funds",
    icon: Compass,
  },
  {
    to: LP_ROUTES.IndicationsOfInterest,
    label: "Indications of Interest",
    icon: Receipt,
  },
  {
    to: LP_ROUTES.Transactions,
    label: "Transactions",
    icon: Handshake,
  },
];

export const TapSideNav = ({
  entries,
  impersonationState,
}: {
  entries: typeof lpSidebarNavEntries;
  impersonationState: boolean;
}) => {
  return (
    <StyledMenu isImpersonationActive={impersonationState}>
      <StyledMenuGroup>
        <TapIconLink to={LP_ROUTES.Root} />
        <NavLinks buttons={entries} />
      </StyledMenuGroup>
      <StyledMenuGroup>
        <TapSupportLink />
      </StyledMenuGroup>
    </StyledMenu>
  );
};
