import * as yup from "yup";
import { Link, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";

import FormikTextField from "common/components/formik/FormikTextField";
import { HalfImageLayout } from "common/components/HalfImage";
import { BUTTON_TYPES, Button } from "common/components/Button";

import { BadLogin } from "./Invalid";
import { AuthAppBar } from "./AuthAppBar";
import { FormContentSkeleton } from "../components/FormContentSkeleton";
import {
  ResetLoginState,
  SendLoginEmailButtonClicked,
} from "../state/AuthenticationEvent";
import { useAuthContext } from "../state/AuthenticationContext";
import {
  SendingLoginEmailFailed,
  SendingLoginEmailSucceeded,
} from "../state/AuthenticationState";
import { useNavigate } from "react-router";
import { UNIVERSAL_ROUTES } from "common/constants/routes";
import {
  EMAIL_ERROR_MESSAGE,
  EMAIL_REGEX,
} from "../../../../common/constants/validation.ts";

const LoginForm = () => {
  const { emitEvent, loginPageState } = useAuthContext();

  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, EMAIL_ERROR_MESSAGE)
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: () => {
      emitEvent!(new SendLoginEmailButtonClicked(formik.values.email));
    },
  });

  return loginPageState instanceof SendingLoginEmailSucceeded ? (
    <Stack direction="column" spacing={4}>
      <Typography variant="body1" sx={{ fontSize: 20, fontWeight: "bold" }}>
        {formik.values.email}
      </Typography>
      <span>
        <Typography variant="body1" display="inline">
          If you did not receive the Sign in email{" "}
        </Typography>
        <Link
          variant="body1"
          color="#848484"
          display="inline"
          onClick={formik.submitForm}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          click here to send again.
        </Link>
      </span>
    </Stack>
  ) : (
    <Stack alignContent={"center"} direction="column" height={"100%"}>
      <Stack spacing={4}>
        <form onSubmit={formik.handleSubmit}>
          <FormikTextField
            name="email"
            formik={formik}
            label="Email"
            autoComplete="email"
            fullWidth
          />
        </form>
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size="large"
          onClick={formik.submitForm}
          fullWidth
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const { loginPageState, emitEvent } = useAuthContext();
  const emailSent = loginPageState instanceof SendingLoginEmailSucceeded;

  const handleResetLoginState = () => {
    emitEvent(new ResetLoginState());
  };

  const handleSignUp = () => {
    navigate(UNIVERSAL_ROUTES.SignUp);
  };

  return loginPageState instanceof SendingLoginEmailFailed ? (
    <>
      <BadLogin
        title="Don't have an account?"
        description="If you recently signed up, please wait until we reach out to you with further instructions."
        className="mb-8"
      >
        <p className="text-stone-500 text-sm select-none mt-8 cursor-default">
          Try again using a{" "}
          <button
            onClick={handleResetLoginState}
            className="text-stone-700 underline cursor-pointer hover:text-stone-900"
          >
            different email
          </button>
          .
        </p>
        <p className="text-stone-500 text-sm select-none mt-8 cursor-pointer">
          Don't have an account?
          <Button onClick={handleSignUp} className="!inline-block !ml-4">
            Register
          </Button>
        </p>
      </BadLogin>
    </>
  ) : (
    <HalfImageLayout
      imageUrl="/images/login/login.webp"
      contentOnLeft
      appBar={<AuthAppBar showSignUpButton />}
    >
      <FormContentSkeleton
        formTitle={emailSent ? "We sent a log in email to:" : "Log In"}
        withDisclaimer
      >
        <LoginForm />
      </FormContentSkeleton>
    </HalfImageLayout>
  );
};
