import { IParticipant } from "experiences/transactions/domain/models/Participant";

// Note: Be careful, in the participants list, the id we're looking for is `userId`, not `id`
export const getParticipantById = ({
  participants,
  participantId,
}: {
  participants: IParticipant[];
  participantId: IParticipant["id"];
}) => {
  return participants.find(
    (participant) => participant.userId === participantId,
  );
};

// Note: Be careful, in the participants list, the id we're looking for is `userId`, not `id`
export const getViewableParticipants = ({
  participants,
  participantId,
}: {
  participants: IParticipant[];
  participantId: IParticipant["id"];
}) => {
  return participants.filter(
    (participant) => participant.userId != participantId,
  );
};
