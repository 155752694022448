import { TRANSACTION_LOGO_URL } from "common/constants/platform";
import { APP_MENU_HEIGHT_PX } from "experiences/common/PageWrapper";
import TextChip from "experiences/common/TextChip";
import styled from "styled-components";

export const SIDEBAR_CARD_WIDTH_PX = 300;

const Mountains = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="landscape_FILL1_wght400_GRAD0_opsz48 1">
      <path
        id="Vector"
        d="M1.33301 17.819L7.15119 10.0614L11.8785 16.3645H13.6966L9.98755 11.4432L13.9391 6.18264L22.6663 17.819H1.33301Z"
        fill="#BCB3A5"
      />
    </g>
  </svg>
);

const StyledTransactionSidebar = styled.aside`
  display: flex;
  flex-direction: column;
  width: ${SIDEBAR_CARD_WIDTH_PX}px;
  height: inherit;

  .overview_details {
    display: block;
    padding: 24px;
    box-sizing: border-box;
    user-select: none;

    border-bottom: 1px solid #bcb3a533;

    background: #abbaab;
    background: linear-gradient(to top, #e7e4e0, #f7f7f7);

    background-size: cover;

    box-sizing: border-box;
  }

  .overview_details-project_title {
    display: block;
    margin-top: 16px;
    color: #21272d;
    font-size: 18px;
    font-family: "StaffWide", sans-serif;
    font-weight: 600;
    line-height: 110%;
    cursor: default;
  }

  .overview_details-project_description {
    display: block;
    margin-top: 8px;
    color: #737476;
    font-size: 12px;
    font-family: "Inter";
    line-height: 140%;
    letter-spacing: -0.12px;

    cursor: default;
  }

  .overview_details-project_img {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    // padding: 8px;
    background-color: #ffffff;
    border: 1px solid #dfdfd9;
    box-sizing: border-box;
  }

  .overview_details-target_close {
    display: flex;
    color: #737476;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.1px;

    cursor: default;

    &:first-of-type {
      margin-top: 48px;
    }

    & + .overview_details-target_close {
      border-top: 1px solid #d6d3d1;
      padding-top: 16px;
      margin-top: 16px;
      box-sizing: border-box;
    }
  }

  .overview_more {
    flex: 1;
    & > * {
      height: 100%;
      padding-bottom: 24px;
      box-sizing: border-box;
    }
  }
`;

export const Sidebar = ({
  title,
  subtitle,
  children,
  items = [],
  image,
}: {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  items?: { label: string; value: string; isRecurring?: boolean }[];
  image?: string;
}) => {
  return (
    <StyledTransactionSidebar>
      <div className="overview_details h-40 overflow-hidden">
        {!!image ? (
          <img
            src={`${TRANSACTION_LOGO_URL}/${image}`}
            className="overview_details-project_img"
          />
        ) : (
          <Mountains className="overview_details-project_img" />
        )}
        <h1 className="overview_details-project_title">{title}</h1>
        {!!subtitle && (
          <p className="overview_details-project_description">{subtitle}</p>
        )}
        {!!items.length
          ? items.map((item, i) => (
              <p
                className="overview_details-target_close flex select-none"
                key={i}
              >
                <span className="flex-1">{item.label}</span>
                <span className="text-neutral-800">
                  {item.isRecurring && (
                    <TextChip text="Recurring" success className="mr-2" />
                  )}
                  {item.value}
                </span>
              </p>
            ))
          : null}
      </div>
      <div className="overview_more">{children}</div>
    </StyledTransactionSidebar>
  );
};

export const MainContent = styled.div`
  display: block;
  height: inherit;
  max-height: inherit;
  flex: 1;
  border-left: 1px solid #bcb3a533;
  overflow-y: scroll;
  position: relative;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  height: calc(100vh - ${APP_MENU_HEIGHT_PX}px);
  max-height: calc(100vh - ${APP_MENU_HEIGHT_PX}px);
`;
