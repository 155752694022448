import { useState } from "react";
import { FileXls } from "@phosphor-icons/react";

import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import {
  DealBreakdownCenterColumn,
  DealBreakdownLeftColumn,
  DealBreakdownRightColumn,
} from "../TransactionFundDataDealBreakdown";
import { Button, BUTTON_TYPES } from "common/components/Button";
import {
  CompanySectorChart,
  GeographyChart,
  StrategyChart,
} from "../DealBreakdownCharts";
import {
  DealBreakdownChart,
  DealBreakdownRow,
  mockDataQuay,
} from "../mock/DealBreakdownMock";

const useDealBreakdownQuay = () => {
  const [data] = useState<{
    left: DealBreakdownRow[];
    center: DealBreakdownRow[];
    right: DealBreakdownRow[];
    strategy: DealBreakdownChart[];
    geography: DealBreakdownChart[];
    companySector: DealBreakdownChart[];
  }>({
    left: mockDataQuay.left,
    center: mockDataQuay.center,
    right: mockDataQuay.right,
    strategy: mockDataQuay.strategy,
    geography: mockDataQuay.geography,
    companySector: mockDataQuay.companySector,
  });

  return { data };
};

export const DealBreakdownQuay = () => {
  const { data } = useDealBreakdownQuay();

  return (
    <div>
      <PageSectionTitleDivider
        actions={
          <>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              icon={<FileXls />}
              download
              href="https://tap-public-assets.s3.amazonaws.com/Project+Quay+-+Tap+Secondaries+Data+(27870172).xlsx"
              as="a"
              target="_blank"
            >
              Download Data
            </Button>
          </>
        }
      >
        Deal Breakdown
      </PageSectionTitleDivider>

      <div className="flex">
        <DealBreakdownLeftColumn data={data.left} />
        <DealBreakdownCenterColumn data={data.center} />
        <DealBreakdownRightColumn data={data.right} />
      </div>
      <div className="flex items-center py-12 gap-8 justify-around">
        <StrategyChart data={data.strategy} />
        <GeographyChart data={data.geography} />
        <CompanySectorChart data={data.companySector} />
      </div>
    </div>
  );
};
