import { CircleNotch } from "@phosphor-icons/react";
import { Button, BUTTON_TYPES, PlusIcon } from "common/components/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "shadcn/ui/Dialog";
import { Input } from "shadcn/ui/input";

import { SelectFundDropdown } from "./NewFundSelector";
import { SelectManagerDropdown } from "./NewManagerSelector";
import { NewQuarterDatePicker, ShadcnField } from "./NewReferenceDateField";
import {
  ITransactionHolding,
  useAddFundInterestForm,
} from "./useAddFundInterestForm";

import { InterestNavField } from "./add-fund-interest-modal/InterestNavField";
import {
  REMOVE_NAV_FLAG,
  REMOVE_REFERENCE_DATE_FLAG,
} from "./useAddFundInterestForm";

export const AddFundInterestModal = ({
  handleClose,
  open,
  refetchHoldings,
  onHoldingAdded,
  forPortfolio = false,
  currentFileId,
}: {
  handleClose: () => void;
  open: boolean;
  refetchHoldings: () => void;
  forPortfolio?: boolean;
  onHoldingAdded: ({ holding }: { holding: ITransactionHolding }) => void;
  currentFileId: string | null;
}) => {
  const {
    openFundOptionsDropdown,
    setOpenFundOptionsDropdown,
    openManagerOptionsDropdown,
    setOpenManagerOptionsDropdown,
    fundOptions,
    handleSelectFundInterest,
    handleSelectManager,
    form,
    isNewFundInterest,
    handleFundNotFoundCreateNew,
    handleManagerNotFoundClick,
  } = useAddFundInterestForm({
    handleClose,
    refetchHoldings,
    open,
    onHoldingAdded,
    forPortfolio,
  });

  const showFundDropdown = !isNewFundInterest;
  const showManagerInput = isNewFundInterest;

  const onClose = () => {
    if (open) {
      handleClose();
    }
    // Wait 500ms before resetting the form
    setTimeout(() => {
      form.resetForm();
    }, 500);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newIsOpen) => {
        console.log("newIsOpen", newIsOpen);
        if (!newIsOpen) {
          handleClose();
        }
      }}
    >
      <DialogContent onInteractOutside={onClose}>
        <DialogHeader>
          <DialogTitle className="select-none">Add Fund Interest</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <form onSubmit={form.handleSubmit} className="grid flex-1 gap-4 mt-4">
            {showManagerInput && (
              <ShadcnField
                label="Manager Name"
                name="managerName"
                input={
                  <SelectManagerDropdown
                    open={openManagerOptionsDropdown}
                    onOpenChange={setOpenManagerOptionsDropdown}
                    form={form}
                    onManagerSelect={handleSelectManager}
                    onOtherManagerSelect={handleManagerNotFoundClick}
                  />
                }
                errors={form.errors.managerName}
              />
            )}

            <ShadcnField
              label="Fund Name"
              name="fundName"
              input={
                showFundDropdown ? (
                  <SelectFundDropdown
                    openFundOptionsDropdown={openFundOptionsDropdown}
                    setOpenFundOptionsDropdown={setOpenFundOptionsDropdown}
                    fundOptions={fundOptions}
                    form={form}
                    handleSelectFundInterest={handleSelectFundInterest}
                    handleFundNotFoundCreateNew={handleFundNotFoundCreateNew}
                  />
                ) : (
                  <Input
                    id="fundName"
                    name="fundName"
                    placeholder="Fund Name"
                    className="!w-full"
                    value={form.values.fundName}
                    onChange={(e) =>
                      form.setFieldValue("fundName", e.target.value)
                    }
                  />
                )
              }
              errors={form.errors.fundName}
            />

            {!REMOVE_NAV_FLAG && (
              <ShadcnField
                label="Interest NAV"
                name="interestNav"
                input={<InterestNavField form={form} />}
                errors={form.errors.interestNav}
              />
            )}

            {!REMOVE_REFERENCE_DATE_FLAG && (
              <ShadcnField
                label="Reference Date"
                name="referenceDate"
                input={
                  <NewQuarterDatePicker
                    onChange={(value) =>
                      form.setFieldValue("referenceDate", value)
                    }
                    value={form.values.referenceDate}
                    label="Reference Date"
                  />
                }
                errors={form.errors.referenceDate}
              />
            )}

            <div className="flex justify-end w-full mt-4 gap-4">
              <div className="flex gap-4">
                <Button htmlType="button" onClick={onClose}>
                  Cancel
                </Button>
              </div>
              <Button
                htmlType="submit"
                type={BUTTON_TYPES.SECONDARY}
                disabled={
                  form.isSubmitting ||
                  (!Boolean(form.values.fundId) &&
                    (!Boolean(form.values.fundName) ||
                      !Boolean(form.values.managerId)))
                }
              >
                {form.isSubmitting ? (
                  <CircleNotch className="animate-spin" />
                ) : (
                  <PlusIcon />
                )}
                Add Fund Interest
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
