export const multiplyArray = (arr: any[], times: number) => {
  let result = [];

  if (arr.length === 0) {
    return arr; // same as result
  }

  for (let i = 0; i < times; i++) {
    result = result.concat(arr);
  }
  return result;
};

export const fundCsvFileCount = (csvData: FundDataSummaryCsv) => {
  const allFiles = [
    ...(csvData?.dataSummaryCsv?.fundExposureSummaryTabs || []),
    ...(csvData?.dataSummaryCsv?.soiLookthroughTabs || []),
    ...(csvData?.dataSummaryCsv?.casTabs || []),
  ];

  const allNonNullFiles = allFiles.filter((file) => file.data !== null);

  return allNonNullFiles.length;
};

export const isTxnIdQuay = ({ txnId }: { txnId?: string }) => {
  return ["KdYl8QZxrwX87", "aSCLjttHQ5kWh", "M7YJPjeGtoCql"].includes(
    txnId || "",
  );
};

export const getPreviousQuarter = () => {
  const dateObj = new Date();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  const quarter = Math.floor((month + 3) / 3);
  const previousQuarter = quarter - 1;

  if (previousQuarter === 0) {
    return `${year - 1}-Q4`;
  }

  return `${year}-Q${previousQuarter}`;
};

// Expects date in format YYYY-MM-DD
export const dateToQuarter = (date: string, { separator = " " } = {}) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const quarter = Math.floor((month + 3) / 3);

  return `${year}${separator}Q${quarter}`;
};

// Expects dates in format YYYY-MM-DD
export const listDateRanges = (dates: string[]) => {
  const nonNullDates = dates.filter((date) => !!date);
  const uniqueDates = [...new Set(nonNullDates)];
  if (uniqueDates.length === 0) {
    return "";
  }
  if (uniqueDates.length === 1) {
    return dateToQuarter(uniqueDates[0]);
  }
  const sortedDates = uniqueDates.sort();
  return `${dateToQuarter(sortedDates[0])} - ${dateToQuarter(
    sortedDates[sortedDates.length - 1],
  )}`;
};

// Convert "1.23 MB" a string to a number, parse size in KB, MB, GB, TB, etc.
export const parseFileSize = (size: string) => {
  const regex = /(\d+)([KMGT]?B)/;
  const match = size.match(regex);
  return match
    ? parseInt(match[1]) *
        1024 **
          (match[2] === "B" ? 0 : match[2].charCodeAt(0) - "K".charCodeAt(0))
    : 0;
};
