import { ISignatureForm } from "../../domain/models/SignatureForm";
import {
  ICreateParticipantForm,
  IParticipant,
} from "../../domain/models/Participant";
import { IDataRoom, IItem } from "../../domain/models/DataRoom";
import {
  BidPricingUpsertForm,
  IBidDocument,
} from "../../domain/models/Bidding";
import { IGpTransaction } from "experiences/dashboard/mock/GP";
import { ICreateThirdPartyTransactionForm } from "experiences/portfolio/domain/models/CreateTransactionForm";
import {
  ITransactionPeriod,
  TransactionSettingType,
} from "experiences/transactions/domain/models/Transaction";

export class TransactionsEvent {}

// a state hierarchy specific to when the user inside of a transaction
export class TransactionDetailsEvent extends TransactionsEvent {
  txnId: string;

  constructor({ txnId }: { txnId: string }) {
    super();
    this.txnId = txnId;
  }
}

export class ManageParticipantsButtonClicked extends TransactionDetailsEvent {}

export class ParticipantListRequested extends TransactionDetailsEvent {}

export class CreateParticipantFormSubmitted extends TransactionDetailsEvent {
  form: ICreateParticipantForm;
  creator: IParticipant;

  constructor({
    form,
    txnId,
    creator,
  }: {
    form: ICreateParticipantForm;
    txnId: string;
    creator: IParticipant;
  }) {
    super({ txnId });
    this.form = form;
    this.creator = creator;
  }
}

export class LoadTransactionDetails extends TransactionDetailsEvent {}

export class NDAsParticipantSignatureSubmitted extends TransactionDetailsEvent {
  signature: ISignatureForm;
  participantId: string;

  constructor({
    signature,
    participantId,
    txnId,
  }: {
    txnId: string;
    participantId: string;
    signature: ISignatureForm;
  }) {
    super({ txnId });
    this.signature = signature;
    this.participantId = participantId;
  }
}

export class RedirectedToSignNDAScreen extends TransactionDetailsEvent {}

// This is a single zip file
export class UploadDataRoomFilePressed extends TransactionDetailsEvent {
  files: File[];

  constructor({ files, txnId }: { files: File[]; txnId: string }) {
    super({ txnId: txnId });
    this.files = files;
  }
}

export class ProcessDataroomUploadPressed extends TransactionsEvent {
  transactionId: string;

  constructor({ transactionId }: { transactionId: string }) {
    super();
    this.transactionId = transactionId;
  }
}
export class NotifyDataroomUploadRequested extends TransactionsEvent {
  transactionId: string;
  participantIds: string[];
  message: string;

  constructor({
    transactionId,
    participantIds,
    message,
  }: {
    transactionId: string;
    participantIds: string[];
    message: string;
  }) {
    super();
    this.transactionId = transactionId;
    this.participantIds = participantIds;
    this.message = message;
  }
}

export class DowloadDataRoomFilePressed extends TransactionDetailsEvent {
  item: IItem;

  constructor({ item, txnId }: { item: IItem; txnId: string }) {
    super({ txnId: txnId });
    this.item = item;
  }
}

export class DownloadDataRoomZipPressed extends TransactionDetailsEvent {
  dataroom: IDataRoom;

  constructor({ dataroom, txnId }: { dataroom: IDataRoom; txnId: string }) {
    super({ txnId: txnId });
    this.dataroom = dataroom;
  }
}

export class BiddingEvent extends TransactionDetailsEvent {}

export class CreateBidFlowEvent extends BiddingEvent {}

export class CreateBidFlowTriggered extends CreateBidFlowEvent {}
export class EnterBidPricingStepSubmitted extends CreateBidFlowEvent {
  form: BidPricingUpsertForm;

  constructor({ txnId, form }: { form: BidPricingUpsertForm; txnId: string }) {
    super({ txnId: txnId });
    this.form = form;
  }
}

export class UploadLetterOfIntentStepSubmitted extends CreateBidFlowEvent {
  letterOfIntent: File;

  constructor({
    txnId,
    letterOfIntent,
  }: {
    letterOfIntent: File;
    txnId: string;
  }) {
    super({ txnId: txnId });
    this.letterOfIntent = letterOfIntent;
  }
}

export class ReviewBidStepSubmitted extends CreateBidFlowEvent {
  bidId: string;

  constructor({ txnId, bidId }: { bidId: string; txnId: string }) {
    super({ txnId: txnId });
    this.bidId = bidId;
  }
}

export class ReviewBidStepDownloadDocumentClicked extends CreateBidFlowEvent {
  document: IBidDocument;

  constructor({ txnId, document }: { document: IBidDocument; txnId: string }) {
    super({ txnId: txnId });
    this.document = document;
  }
}

export class BidListDownloadDocumentClicked extends BiddingEvent {
  document: IBidDocument;

  constructor({ txnId, document }: { document: IBidDocument; txnId: string }) {
    super({ txnId: txnId });
    this.document = document;
  }
}

export class RedirectedToBidsListScreen extends TransactionDetailsEvent {}

export class AcceptBidButtonClicked extends TransactionDetailsEvent {
  bidId: string;

  constructor({ txnId, bidId }: { bidId: string; txnId: string }) {
    super({ txnId: txnId });
    this.bidId = bidId;
  }
}

export class DeleteBidButtonClicked extends TransactionDetailsEvent {
  bidId: string;

  constructor({ txnId, bidId }: { bidId: string; txnId: string }) {
    super({ txnId: txnId });
    this.bidId = bidId;
  }
}

export class BuyerBidRowClicked extends TransactionDetailsEvent {}

export class LoadTransactionPolicies extends TransactionsEvent {}

export class LoadTransactionNDAs extends TransactionsEvent {}

export class LoadTransactionAgreements extends TransactionsEvent {}

export class GpTransactionListRequested extends TransactionsEvent {}

export class GpTransactionListLoaded extends TransactionsEvent {
  transactions: IGpTransaction[];

  constructor({ transactions }: { transactions: IGpTransaction[] }) {
    super();
    this.transactions = transactions;
  }
}

export class LPTendersEvent extends TransactionDetailsEvent {}

export class IndicateInterestScreenSubmitted extends LPTendersEvent {
  tenderAmount: number;
  reservePrice: number;

  constructor({
    tenderAmount,
    txnId,
    reservePrice,
  }: {
    tenderAmount: number;
    txnId: string;
    reservePrice: number;
  }) {
    super({ txnId: txnId });
    this.tenderAmount = tenderAmount;
    this.reservePrice = reservePrice;
  }
}

export class OfferFinalized extends LPTendersEvent {}

export class CreateThirdPartyTransactionFormSubmitted extends TransactionsEvent {
  form: ICreateThirdPartyTransactionForm;

  constructor({ form }: { form: ICreateThirdPartyTransactionForm }) {
    super();
    this.form = form;
  }
}

export class TimelineEditModalSubmitted extends TransactionDetailsEvent {
  // name of the period and the new date as a string
  newTimeline: ITransactionPeriod[];

  constructor({
    newTimeline,
    txnId,
  }: {
    newTimeline: ITransactionPeriod[];
    txnId: string;
  }) {
    super({ txnId: txnId });
    this.newTimeline = newTimeline;
  }
}

export class TransactionNameUpdated extends TransactionDetailsEvent {
  name: string;

  constructor({ name, txnId }: { name: string; txnId: string }) {
    super({ txnId: txnId });
    this.name = name;
  }
}

export class TransactionSettingUpdated extends TransactionDetailsEvent {
  type: TransactionSettingType;
  isActive: boolean;

  constructor({
    type,
    isActive,
    txnId,
  }: {
    type: TransactionSettingType;
    isActive: boolean;
    txnId: string;
  }) {
    super({ txnId: txnId });
    this.type = type;
    this.isActive = isActive;
  }
}
