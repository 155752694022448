import { Either, left } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { GetCurrentUser } from "./GetCurrentUser";
import { ICurrentUser } from "../models/CurrentUser";

interface IParams {}

export class StopImpersonation implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, ICurrentUser>> =
    async () => {
      return await new TapClient("/impersonate/")
        .delete()
        .then((_: any) => {
          return new GetCurrentUser().call({});
        })
        .catch((resp: any) => {
          return left(new Failure(resp.response.data?.error));
        });
    };
}
