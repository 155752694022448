import { createElement } from "react";
import { Navigate, useLocation } from "react-router";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

import Loader from "common/components/TapLoader";
import {
  GP_ROUTES,
  LP_ROUTES,
  THIRD_PARTY_ROUTES,
  UNIVERSAL_ROUTES,
} from "common/constants/routes";
import { UserExperienceType } from "experiences/authentication/domain/models/UserType";

import { GPRoutes } from "./GPRoutes";
import { LPRoutes } from "./LPRoutes";
import { TPRoutes } from "./TPRoutes";
import { UniversalRoutes } from "./UniversalRoutes";

export const RouterByExperienceType = {
  [UserExperienceType.LP]: LPRoutes,
  [UserExperienceType.GP]: GPRoutes,
  [UserExperienceType.THIRD_PARTY]: TPRoutes,
};

export const startScreenForExperienceType = {
  [UserExperienceType.LP]: LP_ROUTES.Dashboard,
  [UserExperienceType.GP]: GP_ROUTES.Dashboard,
  [UserExperienceType.THIRD_PARTY]: THIRD_PARTY_ROUTES.Transactions,
};

// Note: GP_ROUTES.TransactionDetail uses :transactionId instead of :txnId
// TODO: refactor to use one or the other
export const baseTransactionPathForExperienceType = {
  [UserExperienceType.LP]: LP_ROUTES.TransactionDetails,
  [UserExperienceType.GP]: GP_ROUTES.TransactionDetail,
  [UserExperienceType.THIRD_PARTY]: THIRD_PARTY_ROUTES.TransactionDetail,
};

export const AppRoutes = () => {
  const { user, userIsLoading } = useAuthContext();
  const location = useLocation();
  const isAuthed = Boolean(user);

  if (userIsLoading) {
    return <Loader />;
  }

  // if it's going to any login/signup routes but not going to logout path
  // and there's already a session, redirect to ROUTES.Dashboard
  // which is another way of saying, do not show login if there's already a session
  // but allow the user to logout
  if (
    location.pathname.includes(UNIVERSAL_ROUTES.Auth) &&
    !location.pathname.includes(UNIVERSAL_ROUTES.AuthLogout) &&
    isAuthed
  ) {
    return (
      <Navigate
        to={startScreenForExperienceType[user?.experienceType!]}
        replace
      />
    );
  }

  const currentExperienceType = user?.experienceType;

  // If user is not logged in, show the universal routes (that will default to login page)
  if (!currentExperienceType) {
    return createElement(UniversalRoutes);
  }

  // Create custom router (available routes file) based on the experience type of the user
  return createElement(RouterByExperienceType[user.experienceType], {
    isAuthed,
    experienceType: user.experienceType,
  });
};
