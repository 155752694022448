import { Label } from "shadcn/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";
import { ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { cn } from "common/utils";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { Separator } from "shadcn/ui/Separator";

export const ShadcnField = ({
  label,
  name,
  input,
  errors,
}: {
  label: string;
  //   value: string;
  name: string;
  //   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  input: React.ReactNode;
  errors: string;
}) => {
  return (
    <div className="grid gap-2">
      <Label htmlFor={name} className="text-foreground text-sm">
        {label}
      </Label>
      {input}
      {errors && (
        <p className="text-xs font-medium mt-0 mb-2 text-red-500 select-none">
          {errors}
        </p>
      )}
    </div>
  );
};

export const quarterToDate = {
  Q1: "03-31",
  Q2: "06-30",
  Q3: "09-30",
  Q4: "12-31",
};

// Assume date is YYYY-MM-DD
export const NewQuarterDatePicker = ({
  onChange,
  value,
  label,
}: {
  onChange;
  value;
  label;
}) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(value.split("-")[0] || "");
  const [quarter, setQuarter] = useState(value.split("-")[1] || "");

  const isEmpty = !value.length;

  // Generate year options, starting from the current year and going back 25 years
  const currentYear = new Date().getFullYear();

  const quarterOptions = [
    { value: "Q1", label: "Q1" },
    { value: "Q2", label: "Q2" },
    { value: "Q3", label: "Q3" },
    { value: "Q4", label: "Q4" },
  ];

  const handleQuarterChange = (value: string) => {
    setQuarter(value);
  };

  useEffect(() => {
    if (year && quarter) {
      onChange(`${year}-${quarter}`);
    }
  }, [year, quarter]);

  const handleApply = () => {
    if (!!year && !!quarter) {
      // Generate a date using the year and quarter
      const formattedReferenceDate = `${year}-${quarterToDate[quarter]}`;
      onChange(formattedReferenceDate);
      setOpen(false);
    }
  };

  const handlePreviousYear = () => {
    setYear(year - 1);
  };

  const handleNextYear = () => {
    setYear(year + 1);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <Button
          role="combobox"
          className={cn("!w-full !justify-between !h-10", {
            "!text-foreground-muted": isEmpty,
          })}
          aria-expanded={open}
          onClick={() => setOpen((prev) => !prev)}
        >
          {`${year || "Year"} ${quarter || "Quarter"}`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="flex flex-col w-72 gap-2"
        onInteractOutside={handleApply}
      >
        <div className="flex justify-between gap-2 flex-1 select-none">
          <Button
            onClick={handlePreviousYear}
            type={BUTTON_TYPES.PRIMARY}
            icon={<ArrowLeft weight="bold" />}
          >
            {null}
          </Button>
          {year}
          <Button
            onClick={handleNextYear}
            type={BUTTON_TYPES.PRIMARY}
            icon={<ArrowRight weight="bold" />}
            iconRight
            disabled={year >= currentYear}
          >
            {null}
          </Button>
        </div>
        <Separator className="my-2" />
        <div className="flex justify-between flex-1">
          {quarterOptions.map((q) => (
            <Button
              key={q.value}
              onClick={() => {
                handleQuarterChange(q.value);
                setOpen(false);
              }}
              type={BUTTON_TYPES.PRIMARY}
              className={cn({
                "!ring-2 !ring-nomad-500 !shadow-none !font-extrabold":
                  q.label === quarter,
              })}
            >
              {q.label}
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
