import { useState } from "react";

import { AUTH_KEYS } from "common/constants/localStorage";
import useLocalStorage from "common/hooks/useLocalStorage";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { StyledDropdown, StyledUser, UserPicture } from "../styles";
import { ClickOutside } from "common/components/ClickOutside";
import { Link } from "react-router-dom";
import { LP_ROUTES, UNIVERSAL_ROUTES } from "common/constants/routes";
import { Gear, SignOut } from "@phosphor-icons/react";

const Dropdown = ({
  isImpersonationActive,
}: {
  isImpersonationActive: boolean;
}) => {
  return (
    <StyledDropdown>
      <Link to={LP_ROUTES.Settings}>
        Settings
        <Gear />
      </Link>
      {!isImpersonationActive ? (
        <Link to={UNIVERSAL_ROUTES.AuthLogout}>
          Logout <SignOut />
        </Link>
      ) : null}
    </StyledDropdown>
  );
};

export const LPUser = () => {
  const { user } = useAuthContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [impersonationState] = useLocalStorage(
    AUTH_KEYS.IMPERSONATION,
    undefined,
  );

  if (!user) return null;

  const initials = user.firstName[0] + user.lastName[0];

  return (
    <StyledUser onClick={() => setShowDropdown(!showDropdown)}>
      <ClickOutside
        handleOutsideClick={() => setShowDropdown(false)}
        role="menu"
      >
        {user.firstName}
        <UserPicture initials={initials} />

        {showDropdown && (
          <Dropdown isImpersonationActive={impersonationState} />
        )}
      </ClickOutside>
    </StyledUser>
  );
};
