import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { Alert } from "../models/Alert";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";

export class ListAlerts implements IAsyncUseCase<IParams, Alert[]> {
  call: (params: IParams) => Promise<Either<Failure, Alert[]>> = async () => {
    return await new TapClient(`/alert/deal_screen/list/`)
      .get({})
      .then((resp: Alert[]) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {}
