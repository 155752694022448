import { TransactionDetailsEvent } from "../TransactionsEvent";

export class TendersEvent extends TransactionDetailsEvent {}

export class RedirectedToTender extends TendersEvent {}

// Overview Screen task clicks
export class TenderTaskClicked extends TendersEvent {}
export class FinalizeParticipationTaskClicked extends TenderTaskClicked {}
export class DeclineInterestTaskClicked extends TenderTaskClicked {}
export class IndicateInterestTaskClicked extends TenderTaskClicked {}
export class ReviewPrelimaryInterestTaskClicked extends TenderTaskClicked {}
export class SignOfferLetterTaskClicked extends TenderTaskClicked {}
export class ReviewSignedOfferLetterTaskClicked extends TenderTaskClicked {}

export class DocumentDownloadClicked extends TendersEvent {
  docId: string;

  constructor({ docId, txnId }: { docId: string; txnId: string }) {
    super({ txnId });
    this.docId = docId;
  }
}

export class FinalizeParticipationFlowSubmitted extends TendersEvent {
  tenderAmount: number;

  constructor({
    tenderAmount,
    txnId,
  }: {
    tenderAmount: number;
    txnId: string;
  }) {
    super({ txnId });
    this.tenderAmount = tenderAmount;
  }
}

export class RedirectedToOfferScreen extends TendersEvent {}

// offer letter events
export class OfferLetterEvent extends TendersEvent {}
export class OfferLetterSignatureIframeEventEmitted extends OfferLetterEvent {}
export class DocumentShareSidePaneSubmitted extends OfferLetterEvent {}
export class ClaimSignatoryClicked extends OfferLetterEvent {}
export class FinalizeElectionFlowTriggered extends OfferLetterEvent {}
export class IndicateInterestScreenTriggered extends OfferLetterEvent {}
