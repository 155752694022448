import { useRanger } from "react-ranger";

type RangeDataItem = {
  value: number;
  label: string;
};

interface RangeProps {
  values: number[];
  setValues?: (values: number[]) => void;
  data: RangeDataItem[];
}

const Range = ({ values, setValues, data }: RangeProps) => {
  const steps = data.map((obj) => obj.value);

  const { ticks, handles, getTrackProps, segments } = useRanger({
    values,
    onChange: setValues,
    min: steps[0],
    max: steps[steps.length - 1],
    stepSize: 1,
    steps,
    ticks: steps,
  });

  const COLORS = ["#DCDCD6", "#BCB3A5", "#DCDCD6"];

  return (
    <div
      {...getTrackProps({
        style: {
          height: 2,
          background: "#DCDCD6",
          borderRadius: 12,
        },
      })}
    >
      {segments.map(({ getSegmentProps }, index) => {
        return (
          <div
            {...getSegmentProps({
              style: {
                background: COLORS[index],
                height: 2,
              },
            })}
          />
        );
      })}
      {ticks.map(({ value, getTickProps }) => {
        return (
          <div
            {...getTickProps({
              style: {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            })}
          >
            <div
              style={{
                width: 8,
                height: 8,
                background: "white",
                bottom: 2.5,
                border: "1px solid #DCDCD6",
                borderRadius: "50%",
                zIndex: 999,
                position: "relative",
              }}
            />
            <span style={{ marginTop: 15 }}>{data[value].label}</span>
          </div>
        );
      })}
      {handles.map(({ getHandleProps }) => {
        return (
          <div
            {...getHandleProps({
              style: {
                width: 20,
                height: 20,
                border: "1px solid #BCB3A5",
                borderRadius: "50%",
                background: "white",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                position: "absolute",
                top: "0",
              },
            })}
          >
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                background: "#BCB3A5",
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: "50%",
                left: "50%",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Range;
