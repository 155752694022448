import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import {
  IIndicationOfInterest,
  IndicationOfInterest,
  askOrBid,
} from "../models/IOI";
import { ListIOIMode } from "../models/ListIOIMode";

export class ListIOIs
  implements IAsyncUseCase<IParams, IndicationOfInterest[]>
{
  call: (params: IParams) => Promise<Either<Failure, IndicationOfInterest[]>> =
    async ({ mode }) => {
      return await new TapClient(`ioi/${mode}/list/`)
        .get({})
        .then((resp: IIndicationOfInterest[]) => {
          return right(resp.map((item) => askOrBid(item)));
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {
  mode: ListIOIMode;
}
