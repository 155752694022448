import { gql } from "@apollo/client";

export const GetPortfolioReportDatesQuery = gql`
  query ReportsDates($entitiesIds: [String!]!) {
    reportsDates(entitiesIds: $entitiesIds) {
      name
      dates
    }
  }
`;
