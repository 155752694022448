// TODO: Fix this import, we are using ROUTES here prior it's being mounted in its child
enum ROUTES {
  FundsScreen = "/funds/screen",
  Transactions = "/transactions",
  PortfolioHoldings = "/portfolio/holdings",
}

export enum TabType {
  Dashboard = 0,
  Funds = 1,
  Transactions = 2,
  Portfolio = 3,
}

export const TabTypeNames = new Map<TabType, string>([
  // [TabType.Dashboard, "Dashboard"],
  [TabType.Funds, "Funds"],
  [TabType.Transactions, "Transactions"],
  [TabType.Portfolio, "Portfolio"],
]);

export const TabTypeRoute = new Map<TabType, string>([
  // [TabType.Dashboard, "/dashboard"],
  [TabType.Funds, ROUTES.FundsScreen],
  [TabType.Transactions, ROUTES.Transactions],
  [TabType.Portfolio, ROUTES.PortfolioHoldings],
]);
