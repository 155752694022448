import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import { Bid, IBid, bidFromResponse } from "../models/Bidding";
import TapClient from "../../../../common/clients/TapClient";

export class ListBidsOfUserOnTransaction
  implements IAsyncUseCase<IParams, Bid[]>
{
  call: (params: IParams) => Promise<Either<Failure, Bid[]>> = async ({
    txnId,
  }) => {
    return await new TapClient(`/transactions/${txnId}/bidding/bids/list/`)
      .post()
      .then((resp: IBid[]) => {
        return right(resp.map((resp) => bidFromResponse(resp)));
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  txnId: string;
}
