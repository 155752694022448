export enum LiquidityTimeline {
  Planned = "Planned",
  ActivelyExploring = "ActivelyExploring",
  NoMention = "NoMention", // TODO: This may be changed soon (Need to add "no actively exploring" option)
}

export enum PerformanceToPlan {
  AheadOfPlan = "AheadOfPlan",
  OnPlan = "OnPlan",
  BehindPlan = "BehindPlan",
  NotMentioned = "NotMentioned",
}

export enum GpOutlook {
  Positive = "Positive",
  Neutral = "Neutral",
  Negative = "Negative",
  NotMentioned = "NotMentioned",
}

export enum DistressIndicator {
  Distressed = "Distressed",
  NotDistressed = "NotDistressed",
}

export enum MacroExposure {
  Favorable = "Favorable",
  Neutral = "Neutral",
  Unfavorable = "Unfavorable",
  ExtremelyUnfavorable = "ExtremelyUnfavorable",
}

export interface IInvestmentsInsights {
  holding: string;
  fund: string;
  fundName: string;
  managerId: string;
  managerName: string;
  company: string;
  companyName: string;
  reportDate: string;
  gpOutlook: GpOutlook | null;
  gpOutlookDescription: string;
  performanceToPlan: PerformanceToPlan | null;
  performanceToPlanDescription: string;
  liquidityTimeline: LiquidityTimeline | null;
  liquidityTimelineDescription: string;
  distressIndicator: DistressIndicator | null;
  distressIndicatorDescription: string;
  macroExposure: MacroExposure | null;
  macroExposureDescription: string;
  lpImpliedTransactionValue: string;
  lpImpliedInvestmentPercentage: string | null;
}

export interface IInvestmentsInsightsWithTransactionPercent
  extends IInvestmentsInsights {
  transactionPercent: number;
  isHumanVerified: boolean;
}

export enum UpdateType {
  RecentHighlight = "RecentHighlight",
  RecentLowlight = "RecentLowlight",
  NewInitiative = "NewInitiative",
  NewRisk = "NewRisk",
}

export const updateTypeLabels: Record<UpdateType, string> = {
  [UpdateType.RecentHighlight]: "Recent Highlight",
  [UpdateType.RecentLowlight]: "Recent Lowlight",
  [UpdateType.NewInitiative]: "New Initiative",
  [UpdateType.NewRisk]: "New Risk",
};

export interface IInvestmentsUpdates {
  holding: string;
  fund: string;
  fundName: string;
  managerId: string;
  managerName: string;
  company: string;
  companyName: string;
  reportDate: string;
  type: UpdateType;
  headline: string;
  text: string;
  impact: string;
  lpImpliedTransactionValue: string;
}

export interface IQualitativeDataInsights {
  qualitativeData: {
    investmentsInsights: IInvestmentsInsights[];
  };
}

export interface IQualitativeDataUpdates {
  qualitativeData: {
    investmentsUpdates: IInvestmentsUpdates[];
  };
}

export const LiquidityTimelineLabels: Record<LiquidityTimeline, string> = {
  [LiquidityTimeline.Planned]: "Planned",
  [LiquidityTimeline.ActivelyExploring]: "Actively Exploring",
  [LiquidityTimeline.NoMention]: "No Mention",
};

export const PerformanceToPlanLabels: Record<PerformanceToPlan, string> = {
  [PerformanceToPlan.AheadOfPlan]: "Ahead of Plan",
  [PerformanceToPlan.OnPlan]: "On Plan",
  [PerformanceToPlan.BehindPlan]: "Behind Plan",
  [PerformanceToPlan.NotMentioned]: "Not Mentioned",
};

export const DistressIndicatorLabels: Record<DistressIndicator, string> = {
  [DistressIndicator.Distressed]: "Distressed",
  [DistressIndicator.NotDistressed]: "Not Distressed",
};

export const MacroExposureLabels: Record<MacroExposure, string> = {
  [MacroExposure.Favorable]: "Favorable",
  [MacroExposure.Neutral]: "Neutral",
  [MacroExposure.Unfavorable]: "Unfavorable",
  [MacroExposure.ExtremelyUnfavorable]: "Extremely Unfavorable",
};

export const GpOutlookLabels: Record<GpOutlook, string> = {
  [GpOutlook.Positive]: "Positive",
  [GpOutlook.Neutral]: "Neutral",
  [GpOutlook.Negative]: "Negative",
  [GpOutlook.NotMentioned]: "Not Mentioned",
};
