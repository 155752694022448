import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import {
  GeographyFilterSpec,
  SearchFilterSpec,
  SizeFilterSpec,
  StrategyFilterSpec,
  VintageFilterSpec,
} from "../../domain/models/IssuerFilterSpecs";
import {
  PageIndexSpec,
  PerPageSpec,
} from "../../../../common/@types/models/PageSpecs";
import { TabType } from "../../domain/models/TabType";
import { ActionType } from "../../domain/usecases/FundToWatchlistAction";

export class FundsEvent {}

export class FundsScreenEvent extends FundsEvent {}

export class AlertsScreenEvent extends FundsEvent {}

export class FundsAppBarSelectedTabChanged extends FundsEvent {
  tab: TabType;

  constructor({ tab }: { tab: TabType }) {
    super();
    this.tab = tab;
  }
}

export class FundsTableEvent extends FundsScreenEvent {}

export class FundsTableFilterEvent extends FundsTableEvent {}

export class StrategyFilterChanged extends FundsTableFilterEvent {
  spec: StrategyFilterSpec;

  constructor(spec: StrategyFilterSpec) {
    super();
    this.spec = spec;
  }
}

export class GeographyFilterChanged extends FundsTableFilterEvent {
  spec: GeographyFilterSpec;

  constructor(spec: GeographyFilterSpec) {
    super();
    this.spec = spec;
  }
}
export class VintageFilterChanged extends FundsTableFilterEvent {
  spec: VintageFilterSpec;

  constructor(spec: VintageFilterSpec) {
    super();
    this.spec = spec;
  }
}

export class FundSizeFilterChanged extends FundsTableFilterEvent {
  spec: SizeFilterSpec;

  constructor(spec: SizeFilterSpec) {
    super();
    this.spec = spec;
  }
}

export class SearchTermChanged extends FundsTableFilterEvent {
  spec: SearchFilterSpec;

  constructor(spec: SearchFilterSpec) {
    super();
    this.spec = spec;
  }
}

export class FundsTablePageEvent extends FundsTableEvent {}

export class PageSizeChanged extends FundsTablePageEvent {
  spec: PerPageSpec;

  constructor(spec: PerPageSpec) {
    super();
    this.spec = spec;
  }
}

export class NewPageRequested extends FundsTablePageEvent {
  spec: PageIndexSpec;

  constructor(spec: PageIndexSpec) {
    super();
    this.spec = spec;
  }
}

export class ConfirmCreateAlertButtonClicked extends FundsTableEvent {
  name: string;
  dealSize?: DollarAmount;

  constructor({ name, dealSize }: { name: string; dealSize?: DollarAmount }) {
    super();
    this.dealSize = dealSize;
    this.name = name;
  }
}

export class AlertActivationToggled extends AlertsScreenEvent {
  alertId: string;

  constructor({ alertId }: { alertId: string }) {
    super();
    this.alertId = alertId;
  }
}

export class FundWatchlistToggled extends AlertsScreenEvent {
  fundId: string;

  constructor({ fundId }: { fundId: string }) {
    super();
    this.fundId = fundId;
  }
}

export class FundWatchlistToggledForCurrentlyChecked extends FundsTableEvent {
  actionType: ActionType;

  constructor({ actionType }: { actionType: ActionType }) {
    super();
    this.actionType = actionType;
  }
}

export class SingleFundCheckToggled extends FundsTableEvent {
  fundId: string;

  constructor({ fundId }: { fundId: string }) {
    super();
    this.fundId = fundId;
  }
}

export class EntireCurrentFundsPageCheckToggled extends FundsTableEvent {}

export const enum AccessChoice {
  LP = 1,
  ApprovedBuyer = 2,
  GP = 3,
}

export class VerifyAccessChoiceSelected extends FundsEvent {
  fundId: string;
  choice: AccessChoice;

  constructor({ fundId, choice }: { fundId: string; choice: AccessChoice }) {
    super();
    this.fundId = fundId;
    this.choice = choice;
  }
}

export class VerifyPermissionFlowClosed extends FundsEvent {
  fundId: string;

  constructor({ fundId }: { fundId: string }) {
    super();
    this.fundId = fundId;
  }
}
