import { Either, right } from "fp-ts/lib/Either";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import { TransactionAgreement } from "../models/TransactionAgreement";
import { TransactionAgreementsMock } from "./transactionAgreementsMock";

interface IParams {}

export class GetTransactionAgreements
  implements IAsyncUseCase<IParams, TransactionAgreement[]>
{
  call: (params: IParams) => Promise<Either<Failure, TransactionAgreement[]>> =
    async (): Promise<Either<Failure, TransactionAgreement[]>> => {
      return right([...TransactionAgreementsMock]);
    };
}
