import { Stack, Typography } from "@mui/material";
import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { ReviewStepper } from "experiences/common/ReviewStepper";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { useNavigate } from "react-router";

const ConnectionReview = () => {
  const navigate = useNavigate();
  return (
    <>
      <StepperFlow name="Connect Portfolio" activeStepIndex={0}>
        <Stack
          justifyContent={"space-between"}
          sx={{ height: "100%" }}
          alignItems={"center"}
        >
          <Stack sx={{ width: "100%" }} alignItems={"center"} spacing={16}>
            <Stack alignItems={"center"} maxWidth="800px" pt={8}>
              <Typography variant="h5">Connection Processing</Typography>
              <Typography textAlign="center">
                Tap is connecting to your LP management portal to import your
                private fund portfolio. You will receive update notifications as
                the data is processed.
              </Typography>
            </Stack>
            <ReviewStepper
              steps={[
                {
                  label: "Connection Processing",
                  caption:
                    "Tap establishes the connection to your LP management portal",
                },
                {
                  label: "Data Parsing",
                  caption:
                    "Imported fund holdings are being mapped to the database",
                },
                {
                  label: "Data Feed Activated",
                  caption:
                    "You will begin receiving market data, including bids, asks, and price estimates",
                },
              ]}
            />
            <Button
              onClick={() => {
                navigate(LP_ROUTES.PortfolioHoldings, { replace: true });
              }}
              style={{ fontSize: "16px", fontWeight: "500", width: "280px" }}
              type={BUTTON_TYPES.SECONDARY}
            >
              Complete
            </Button>
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};

export default ConnectionReview;
