import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { usePortfolioContext } from "../../state/PortfolioContext";
import { useAuthContext } from "../../../../authentication/presentation/state/AuthenticationContext";
import FormikTextField from "../../../../../common/components/formik/FormikTextField";
import SignatureTextField from "../../../../../common/components/SignatureTextField";
import { EngageTapScreenContinueClicked } from "../../state/PortfolioEvent";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageHead } from "common/components/PageHead";

const SELLER_ENGAGEMENT_PDF_URL =
  "https://tap-public-assets.s3.amazonaws.com/Seller+Engagement+Letter.pdf";

export const EngageTapStep = () => {
  const { emitEvent } = usePortfolioContext();
  const { user } = useAuthContext();

  const currentUserName = `${user?.firstName} ${user?.lastName}`;

  const validationSchema = yup.object({
    signature: yup
      .string()
      .test(
        "signature",
        `Signature must match user name: ${currentUserName}`,
        (value) => {
          return (
            !value || value?.toLowerCase() === currentUserName.toLowerCase()
          );
        },
      )
      .required("Required"),
    institutionName: yup.string(),
    signatoryLegalName: yup.string().required("Required"),
    signatoryTitle: yup.string(),
  });

  const handleSkipButtonClick = () => {
    emitEvent!(
      new EngageTapScreenContinueClicked({
        engagementLetterSigned: false,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      signature: "",
      institutionName: "",
      signatoryLegalName: "",
      signatoryTitle: "",
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent!(
        new EngageTapScreenContinueClicked({
          engagementLetterSigned: Boolean(values.signature),
          institutionName: values.institutionName,
          signatoryLegalName: values.signatoryLegalName,
          signatoryTitle: values.signatoryTitle,
        }),
      );
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formik.isValid) {
      formik.submitForm();
    } else {
      formik.setTouched({
        signature: true,
        institutionName: true,
        signatoryLegalName: true,
        signatoryTitle: true,
      });
    }
  };

  return (
    <Stack p={4} pb={8}>
      <form onSubmit={handleSubmit}>
        <Stack maxWidth={560} spacing={4}>
          <div>
            <PageHead
              title="Engage Tap"
              description={
                <>
                  By using Tap to sell your LP interest, you are agreeing to the{" "}
                  <Link
                    display="inline"
                    href={SELLER_ENGAGEMENT_PDF_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {" "}
                    Tap Engagement Letter.
                  </Link>
                </>
              }
              paddingless
            />
          </div>
          {/* <Stack spacing={4} width="100%">
            <Stack>
              <Typography
                variant="body1"
                sx={{ color: "#737476", fontWeight: "bold" }}
              >
                Key Engagement Letter Terms
              </Typography>
              <Divider />
            </Stack>
            <EngagementTerms
              terms={[
                {
                  header:
                    "Tap earns a Success Fee when your transaction closes",
                  subheader:
                    "When Tap finds a buyer for your LP interest and your transaction closes, Tap earns a Success Fee according to the fee schedule.",
                },
                {
                  header: "Fees are waived for buyers you invite",
                  subheader:
                    "If you independently find and invite a buyer to your transaction on Tap, the Success Fee is waived if a deal closes with that buyer.",
                },
                {
                  header: "You exclusively engage Tap to sell your interest",
                  subheader:
                    "You cannot engage another marketplace to sell your LP interest for at least 12 months. You may engage investment bankers.",
                },
              ]}
            />
          </Stack> */}
          <Stack spacing={2}>
            <FormikTextField
              formik={formik}
              name="institutionName"
              label="Institution Name (Optional)"
            />
            <FormikTextField
              formik={formik}
              label="Signatory Legal Name"
              name="signatoryLegalName"
            />
            <FormikTextField
              formik={formik}
              label="Signatory Title (Optional)"
              name="signatoryTitle"
            />
            <FormikTextField
              TextFieldComponent={SignatureTextField}
              formik={formik}
              name="signature"
            />
          </Stack>
          <Stack alignItems="center">
            <Button
              size="large"
              type={BUTTON_TYPES.SECONDARY}
              onClick={formik.submitForm}
              fullWidth
              htmlType="submit"
            >
              Sign & Continue
            </Button>
            <br />
            <Button
              size="small"
              htmlType="button"
              onClick={handleSkipButtonClick}
            >
              Skip for now
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

interface IEngagementTerm {
  header: string;
  subheader: string;
}

interface IEngagementTermsProps {
  terms: IEngagementTerm[];
}

export const EngagementTerms: React.FC<IEngagementTermsProps> = ({ terms }) => {
  return (
    <Stack spacing={2}>
      {terms.map((term) => (
        <Stack direction="row" spacing={2}>
          <CheckCircleIcon sx={{ color: "#BCB3A5", width: 24 }} />
          <Stack width={420}>
            <Typography variant="body1" fontWeight="bold" fontSize="16px">
              {term.header}
            </Typography>
            <Typography variant="body1" color="#737476" fontSize="14px">
              {term.subheader}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default EngageTapStep;
