import { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { transactionsClient } from "common/clients/ApolloClient";

import { TableAlignment } from "experiences/funds/presentation/components/Table";
import {
  CapitalAccountStatementKeys,
  IFundDataCapitalAccounts,
} from "experiences/transactions/domain/models/FundDataCapitalAccounts";
import { GetTransactionFundDataCapitalAccounts } from "experiences/transactions/domain/usecases/GetTransactionFundDataCapitalAccounts";
import { GetTransactionFundDataCapitalAccountStatementCsvQueryVariables } from "experiences/transactions/domain/usecases/GetTransactionFundDataCsvQuery";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { createCapitalAccountsExcel } from "experiences/common/excel/capital-accounts/createExcel";

import { useFundDataStatus } from "../useFundDataStatus";
import { DealBreakdownChart } from "../mock/DealBreakdownMock";
import { capitalAccountLabels } from "./model";
import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";

export const useCapitalAccounts = ({
  txnId,
  currentReportDate,
  summary,
}: {
  txnId: string;
  currentReportDate: string;
  summary: ITransactionSummary;
}) => {
  const { selectedHoldingsIds } = useContext(TransactionFundDataContext);
  const { loading: loadingStatus, capitalAccountsStatus } = useFundDataStatus();

  // Get Capital Accounts
  const {
    refetch,
    data: capAccountData,
    loading,
  } = useQuery<IFundDataCapitalAccounts>(
    GetTransactionFundDataCapitalAccounts,
    {
      variables: {
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldingsIds,
      },
      client: transactionsClient,
      fetchPolicy: "cache-and-network",
      skip: !currentReportDate || !txnId,
    },
  );

  // Get Capital Accounts CSV
  const { refetch: downloadCsvData } = useQuery<FundDataSummaryCsv>(
    GetTransactionFundDataCapitalAccountStatementCsvQueryVariables,
    {
      variables: { transactionId: txnId, reportDate: currentReportDate },
      client: transactionsClient,
      fetchPolicy: "no-cache",
      skip: true,
    },
  );

  useEffect(() => {
    if (currentReportDate && txnId && selectedHoldingsIds.length > 0) {
      refetch({
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldingsIds,
      });
    }
  }, [currentReportDate, txnId, selectedHoldingsIds]);

  const tableData = capAccountData?.capitalAccounts || [];

  const columns = [
    {
      label: "",
      key: CapitalAccountStatementKeys.psin,
      align: TableAlignment.LEFT,
      className: "!w-64 !min-w-64",
    },
    ...tableData.map((row) => {
      return {
        label: row[CapitalAccountStatementKeys.psin],
        key: row[CapitalAccountStatementKeys.psin],
        align: TableAlignment.LEFT,
        className: "!w-48 !min-w-48",
      };
    }),
  ];

  // we need to rotate the data because funds are the columns, and each capital account key is a row
  const rotatedData = Object.keys(CapitalAccountStatementKeys)
    .map((key) => {
      return {
        key,
        label: capitalAccountLabels[key as CapitalAccountStatementKeys],
        funds: tableData.map((row) => row[key]),
      };
    })
    // filter out the fundName key
    .filter((row) => row.key !== CapitalAccountStatementKeys.psin)
    .filter((row) => row.key !== CapitalAccountStatementKeys.isHumanVerified)
    .filter((row) => row.key !== CapitalAccountStatementKeys.managerId);

  const exposureSegmentationCharts: {
    gpChart: DealBreakdownChart[];
    fundChart: DealBreakdownChart[];
  } = {
    gpChart: capAccountData?.capitalAccounts
      .filter((capAccount) => !!capAccount[CapitalAccountStatementKeys.nav])
      .map((capAccount) => {
        const totalNav = capAccountData?.capitalAccounts
          .filter((capAccount) => !!capAccount[CapitalAccountStatementKeys.nav])
          .reduce(
            (acc, item) =>
              acc + Number.parseFloat(item[CapitalAccountStatementKeys.nav]),
            0,
          );

        return {
          id: capAccount[CapitalAccountStatementKeys.managerId],
          name: capAccount[CapitalAccountStatementKeys.managerName],
          percentage:
            Number.parseFloat(capAccount[CapitalAccountStatementKeys.nav]) /
            totalNav,
        };
      })
      .reduce((acc, i) => {
        const entry = acc.find((entry) => entry.id === i.id);
        if (entry) {
          entry.percentage += i.percentage;
        } else {
          acc.push(i);
        }
        return acc;
      }, []),

    fundChart: capAccountData?.capitalAccounts
      .filter((capAccount) => !!capAccount[CapitalAccountStatementKeys.nav])
      .map((capAccount) => {
        const totalNav = capAccountData?.capitalAccounts
          .filter((capAccount) => !!capAccount[CapitalAccountStatementKeys.nav])
          .reduce(
            (acc, item) =>
              acc + Number.parseFloat(item[CapitalAccountStatementKeys.nav]),
            0,
          );
        return {
          name: capAccount[CapitalAccountStatementKeys.fundName],
          percentage:
            Number.parseFloat(capAccount[CapitalAccountStatementKeys.nav]) /
            totalNav,
        };
      }),
  };

  const handleDownloadDataClick = () => {
    const reportDate = currentReportDate;
    const variables = {
      reportDate,
      transactionId: txnId,
    };

    downloadCsvData({
      variables,
    }).then((res) => {
      // Capital accounts is a download of the Excel file
      createCapitalAccountsExcel({
        data: res.data?.dataSummaryCsv?.casTabs,
        namePrefix: `${summary?.transaction?.name
          .replace(/ /g, "_")
          .toLowerCase()}-capital-accounts`,
      });
    });
  };

  const awaitingUpload =
    capitalAccountsStatus ===
    TransactionDataSummarySectionStatus.NotYetUploaded;

  const extractionInProgress =
    capitalAccountsStatus ===
    TransactionDataSummarySectionStatus.UploadedAndProcessing;

  const extractionReady =
    capitalAccountsStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  return {
    columns,
    rotatedData,
    loading: loading || loadingStatus,
    capitalAccountsStatus,
    exposureSegmentationCharts,
    handleDownloadDataClick,
    tableData,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
  };
};
