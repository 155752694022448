import { useContext } from "react";
import { useParams } from "react-router";

import { cn } from "common/utils";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { EmptyState } from "common/components/EmptyState";
import { TableFundLogo } from "common/components/TableFundLogo";
import { FUND_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { BarLoader } from "common/components/BarLoader";

import {
  IFundExposureSummaryEntryWithAddedFields,
  geographyLabels,
  strategyLabels,
} from "experiences/transactions/domain/models/FundExposureSummary";

import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { DataExtractionInProgress } from "experiences/common/DataExtractionInProgress";
import { FundDataVerifyPermissionOverlay } from "experiences/dashboard/presentation/components/VerifyPermissionOverlay";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";
import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";
import {
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";

import {
  IManagerLogos,
  useFundExposureSummary,
} from "./useFundExposureSummary";
import {
  fundExposureSummaryKeys,
  fundExposureSummaryLabels,
} from "./mock/FundExposureSummaryMock";

// prettier-ignore
const fundExposureSummaryColumns = [
  { key: fundExposureSummaryKeys.fundName, label: fundExposureSummaryLabels.fundName, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.managerName, label: fundExposureSummaryLabels.managerName, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.transactionPercent, label: fundExposureSummaryLabels.transactionPercent, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.strategy, label: fundExposureSummaryLabels.strategy, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.vintage, label: fundExposureSummaryLabels.vintage, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.geography, label: fundExposureSummaryLabels.geography, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.fundSize, label: fundExposureSummaryLabels.fundSize, align: TableAlignment.RIGHT, },
  // todo: missing MOIC
  // todo: missing TVPI
  // todo: missing DPI
  // todo: missing Quartile
  { key: fundExposureSummaryKeys.fundSeriesAverageMoic, label: fundExposureSummaryLabels.fundSeriesAverageMoic, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.fundNavQoqPercentChg1q, label: fundExposureSummaryLabels.fundNavQoqPercentChg1q, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.unrealizedInvestmentCount, label: fundExposureSummaryLabels.unrealizedInvestmentCount, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.top3Concentration, label: fundExposureSummaryLabels.top3Concentration, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.top10Concentration, label: fundExposureSummaryLabels.top10Concentration, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.percentPublic, label: fundExposureSummaryLabels.percentPublic, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.weightedAvgRevenueGrowth, label: fundExposureSummaryLabels.weightedAvgRevenueGrowth, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.weightedAvgEbitdaMultiple, label: fundExposureSummaryLabels.weightedAvgEbitdaMultiple, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.baseManagementFee, label: fundExposureSummaryLabels.baseManagementFee, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.carry, label: fundExposureSummaryLabels.carry, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.hurdleRate, label: fundExposureSummaryLabels.hurdleRate, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.ptpRiskLevel, label: fundExposureSummaryLabels.ptpRiskLevel, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.ptpQueueLength, label: fundExposureSummaryLabels.ptpQueueLength, align: TableAlignment.RIGHT, },
  { key: fundExposureSummaryKeys.rofr, label: fundExposureSummaryLabels.rofr, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.rofo, label: fundExposureSummaryLabels.rofo, align: TableAlignment.LEFT, },
  { key: fundExposureSummaryKeys.blocker, label: fundExposureSummaryLabels.blocker, align: TableAlignment.LEFT, },
];

export const renderSummaryRow = ({
  fund,
  logoIds,
}: {
  fund: IFundExposureSummaryEntryWithAddedFields;
  logoIds: IManagerLogos[];
}) => {
  const managerId = logoIds.find(
    (logo) => logo.issuerId === fund.issuerId,
  )?.managerId;
  const imgSrc = `${FUND_LOGO_URL}/${managerId}.jpg`;

  // prettier-ignore
  return (
    <>
      <TableCell isPrimaryColumn isFixed className="!pt-1 !pb-1"> <TableFundLogo imgSrc={imgSrc} fundName={fund.fundName} /> </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} isPrimaryColumn> {typeof fund.managerName !== null ? fund.managerName : ""} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.transactionPercent, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {fund.strategy ? strategyLabels[fund.strategy] : NOT_AVAILABLE_STR} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {typeof fund.vintage === null || fund.vintage === undefined ? "" : fund.vintage} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {fund.geography ? geographyLabels[fund.geography] : NOT_AVAILABLE_STR} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.fundSize, format: "currency" })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.fundSeriesAverageMoic, format: "multiplier", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.fundNavQoqPercentChg1q, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {typeof fund.unrealizedInvestmentCount === null || fund.unrealizedInvestmentCount === undefined ? "" : fund.unrealizedInvestmentCount} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.top3Concentration, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.top10Concentration, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.percentPublic, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.weightedAvgRevenueGrowth, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.weightedAvgEbitdaMultiple, format: "multiplier", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.baseManagementFee, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.carry, format: "percentage" })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified} className="text-right"> {tableCellDataFormatter({ value: fund.hurdleRate, format: "percentage", })} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {typeof fund.ptpRiskLevel === null || fund.ptpRiskLevel === undefined ? "" : fund.ptpRiskLevel} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {typeof fund.ptpQueueLength === null || fund.ptpQueueLength === undefined ? "" : fund.ptpQueueLength} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {typeof fund.rofr === null || fund.rofr === undefined ? "" : fund.rofr} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {typeof fund.rofo === null || fund.rofo === undefined ? "" : fund.rofo} </TableCell>
      <TableCell isUnverified={!fund.isHumanVerified}> {typeof fund.blocker === null || fund.blocker === undefined ? "" : fund.blocker} </TableCell>
      
    </>
  );
};

export const FundExposureSummaryTable = ({
  status,
}: {
  status: TransactionDataSummarySectionStatus;
}) => {
  const { txnId } = useParams();

  const { selectedHoldingsIds, reportDates } = useContext(
    TransactionFundDataContext,
  );

  const { data, loading, logoIds } = useFundExposureSummary({
    transactionId: txnId,
    currentReportDate: reportDates.exposureSummary.currentDate,
    selectedHoldings: selectedHoldingsIds,
  });

  const tableData = data?.fundSummaryExposure || [];

  const totalTransactionPercent = tableData.reduce(
    (acc, fund) =>
      acc + Number(fund[fundExposureSummaryKeys.lpEndingCapitalAccountValue]),
    0,
  );

  // Sort fund exposure summary by a new column called "transactionPercent" that calculates the transaction percent based on
  // totalTransactionPercent / fund @ lpEndingCapitalAccountValue
  const tableDataWithTransactionPercent = tableData
    .map((fund) => {
      const transactionPercent =
        totalTransactionPercent === 0
          ? 0
          : Number(fund[fundExposureSummaryKeys.lpEndingCapitalAccountValue]) /
            totalTransactionPercent;

      return {
        ...fund,
        transactionPercent: transactionPercent || 0,
      };
    })
    .sort((a, b) => b.transactionPercent - a.transactionPercent);

  const awaitingUpload =
    status === TransactionDataSummarySectionStatus.NotYetUploaded;

  const extractionInProgress =
    status === TransactionDataSummarySectionStatus.UploadedAndProcessing;

  const extractionReady =
    status === TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const hasReportDates = reportDates.exposureSummary.options.length > 0;

  const hasData = tableData.length > 0;

  return (
    <div
      className={cn("relative", {
        "min-h-64": !hasData,
      })}
    >
      <PageSectionTitleDivider
        showBorderTop
        actions={
          extractionReady &&
          hasReportDates && (
            <SelectDateDropdown
              value={reportDates.exposureSummary.currentDate}
              onValueChange={reportDates.exposureSummary.onReportDateChange}
              options={reportDates.exposureSummary.options}
              loading={loading}
            />
          )
        }
      >
        Fund Exposure Summary
      </PageSectionTitleDivider>

      {awaitingUpload && <FundDataVerifyPermissionOverlay txnId={txnId} />}
      {extractionInProgress && <DataExtractionInProgress />}

      {extractionReady && (
        <div className="w-full max-w-full overflow-x-scroll relative pb-16">
          {!loading && hasData && (
            <TapTable
              skipTopBorder
              columns={fundExposureSummaryColumns}
              fixedHeaders
              renderRow={(fund) => renderSummaryRow({ fund, logoIds })}
              data={tableDataWithTransactionPercent}
              className="!max-w-max !w-max !overflow-scroll"
              showEmptyState
            />
          )}
          {!loading && !hasData && (
            <EmptyState title="There's no data available for this report." />
          )}
          {loading && !hasData && <BarLoader />}
        </div>
      )}
    </div>
  );
};
