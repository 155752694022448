import { InputLabel, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ArrowRight } from "@phosphor-icons/react";

import FormikTextField from "common/components/formik/FormikTextField";
import FormikCheckbox from "common/components/formik/FormikCheckbox";
import IntlPhoneNumberField from "common/components/phone/IntlPhoneNumberField";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { CreateYourAccountFormSubmitted } from "../../state/AuthenticationEvent";
import { useAuthContext } from "../../state/AuthenticationContext";
import {
  EMAIL_ERROR_MESSAGE,
  EMAIL_REGEX,
} from "../../../../../common/constants/validation.ts";

export interface IProps {
  email?: string;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiInputBase-input": {
      paddingTop: 8,
    },
  },
  phone: {
    "& .MuiInputAdornment-root": {
      marginTop: "0 !important",
    },
  },
}));

// this is the start of "add your details" page
export const CreateYourAccountStep: React.FC<IProps> = ({ email }) => {
  const classes = useStyles();
  const { emitEvent } = useAuthContext();
  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(EMAIL_REGEX, EMAIL_ERROR_MESSAGE)
      .required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    phoneNumber: yup.string().min(11, "Number is too short"),
    confirmedAccreditedInvestor: yup
      .boolean()
      .required("Required")
      .oneOf([true], "Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: email ?? "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      confirmedAccreditedInvestor: false,
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent!(
        new CreateYourAccountFormSubmitted({
          regForm: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phoneNumber,
            isAccreditedInvestor: values.confirmedAccreditedInvestor,
            email: values.email,
          },
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-4">
        <div>
          <InputLabel
            shrink={false}
            htmlFor="firstName"
            sx={{ marginBottom: 1 }}
          >
            <Typography variant="body1" color="#21272D" display="inline">
              First Name
            </Typography>
          </InputLabel>
          <FormikTextField
            id="firstName"
            name="firstName"
            formik={formik}
            placeholder="First Name"
            autoComplete="firstName"
            fullWidth
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </div>

        <div>
          <InputLabel
            shrink={false}
            htmlFor="lastName"
            sx={{ marginBottom: 1 }}
          >
            <Typography variant="body1" color="#21272D" display="inline">
              Last Name
            </Typography>
          </InputLabel>
          <FormikTextField
            id="lastName"
            name="lastName"
            formik={formik}
            placeholder="Last Name"
            autoComplete="lastName"
            fullWidth
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </div>

        <div>
          <InputLabel shrink={false} htmlFor="email" sx={{ marginBottom: 1 }}>
            <Typography variant="body1" color="#21272D" display="inline">
              Email
            </Typography>
          </InputLabel>
          {email ? (
            <FormikTextField
              id="email"
              name="email"
              formik={formik}
              placeholder={email}
              autoComplete="email"
              mask="email"
              disabled={true}
              fullWidth
              InputLabelProps={{
                shrink: true,
                classes: { root: classes.label },
                style: { backgroundColor: "#00bbff" },
              }}
              className={classes.input}
            />
          ) : (
            <FormikTextField
              id="email"
              name="email"
              formik={formik}
              placeholder="first.last@company.com"
              autoComplete="email"
              mask="email"
              fullWidth
              InputLabelProps={{ shrink: true }}
              className={classes.input}
            />
          )}
          <p className="text-xs mt-2 text-slate-500">
            Please use the professional email address that you commonly use for
            investing activity.
          </p>
        </div>

        <div>
          <InputLabel shrink={false} htmlFor="phoneNumber">
            <Typography variant="body1" color="#21272D" display="inline">
              Phone Number
            </Typography>
            <Typography variant="body2" color="textSecondary" display="inline">
              {" "}
              (optional)
            </Typography>
          </InputLabel>
          <IntlPhoneNumberField
            id="phoneNumber"
            name="phoneNumber"
            formik={formik}
            placeholder="Phone Number"
            autoComplete="phoneNumber"
            fullWidth
            InputLabelProps={{ shrink: true }}
            className={`${classes.input} ${classes.phone}`}
          />
        </div>

        <div>
          <FormikCheckbox
            name="confirmedAccreditedInvestor"
            label={
              <span>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                >
                  I personally am and/or the institution I represent is an
                  Accredited Investor as defined in{" "}
                </Typography>
                <Link
                  variant="body2"
                  color="#848484"
                  display="inline"
                  href="https://www.law.cornell.edu/cfr/text/17/230.501"
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={{ textDecoration: "underline" }}
                >
                  Rule 501
                </Link>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                >
                  {" "}
                  by the Securities and Exchange Commission.{" "}
                </Typography>
              </span>
            }
            formik={formik}
          />
        </div>
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size="large"
          fullWidth
          onClick={formik.submitForm}
          icon={<ArrowRight />}
          iconRight
        >
          Continue
        </Button>
      </div>
    </form>
  );
};
