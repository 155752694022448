export class DocumentsEvent {}

export class NDASignatureFlowAccessed extends DocumentsEvent {}

export class SignatureScreenSignatureSucceeded extends DocumentsEvent {
  customOnSuccess?: () => void;

  constructor({ customOnSuccess }: { customOnSuccess?: () => void }) {
    super();
    this.customOnSuccess = customOnSuccess;
  }
}

export class InviteSignatoryCompleted extends DocumentsEvent {
  signatoryFirstName: string;
  signatoryLastName: string;
  signatoryEmail: string;

  constructor({
    signatoryEmail,
    signatoryFirstName,
    signatoryLastName,
  }: {
    signatoryEmail: string;
    signatoryFirstName: string;
    signatoryLastName: string;
  }) {
    super();
    this.signatoryEmail = signatoryEmail;
    this.signatoryFirstName = signatoryFirstName;
    this.signatoryLastName = signatoryLastName;
  }
}
export class ClaimSignatorySubmitted extends DocumentsEvent {}

export class AssignedSignatoryCompleted extends DocumentsEvent {}

export class RedliningFormSubmitted extends DocumentsEvent {
  currentUserIsRedliner: boolean = true;
  emailOfDifferentReadliner?: string;
  currentUserIsSigner: boolean = true;
  emailOfDifferentSigner?: string;

  constructor({
    currentUserIsRedliner,
    emailOfDifferentReadliner,
    currentUserIsSigner,
    emailOfDifferentSigner,
  }: {
    currentUserIsRedliner: boolean;
    emailOfDifferentReadliner?: string;
    currentUserIsSigner: boolean;
    emailOfDifferentSigner?: string;
  }) {
    super();
    this.currentUserIsRedliner = currentUserIsRedliner;
    this.emailOfDifferentReadliner = emailOfDifferentReadliner;
    this.currentUserIsSigner = currentUserIsSigner;
    this.emailOfDifferentSigner = emailOfDifferentSigner;
  }
}

export class RedliningPromptConfirmClicked extends DocumentsEvent {}

export class RedliningPromptContinueToSignClicked extends DocumentsEvent {}

export class RedliningRequestCompleted extends DocumentsEvent {}

export class RedirectedToDashboard extends DocumentsEvent {}

export class RedirectedToUserSettingsPage extends DocumentsEvent {}
