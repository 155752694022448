import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Stack, Typography } from "@mui/material";
import { usePortfolioContext } from "../../state/PortfolioContext";
import { ConfigureTransactionScreenContinueClicked } from "../../state/PortfolioEvent";
import FormikTextField from "../../../../../common/components/formik/FormikTextField";
import FormikCheckbox from "../../../../../common/components/formik/FormikCheckbox";
import { InformationPopup } from "../../../../../common/components/InformationPopup";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageHead } from "common/components/PageHead";

const ConfigureTransactionStep = () => {
  const { emitEvent } = usePortfolioContext();
  const validationSchema = yup.object({
    transactionName: yup.string().required("Required"),
    holdingEntityName: yup.string().required("Required"),
    organizationName: yup.string().required("Required"),
    onlyWholePortfolioBids: yup.boolean(),
  });
  const formik = useFormik({
    initialValues: {
      transactionName: "",
      holdingEntityName: "",
      onlyWholePortfolioBids: false,
      organizationName: "",
      adoptPlatformNda: true,
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent!(
        new ConfigureTransactionScreenContinueClicked({
          transactionName: values.transactionName,
          onlyWholePortfolioBids: values.onlyWholePortfolioBids,
          holdingEntityName: values.holdingEntityName,
          organizationName: values.organizationName,
          adoptPlatformNda: values.adoptPlatformNda,
        }),
      );
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formik.isValid) {
      formik.submitForm();
    } else {
      formik.setTouched({
        transactionName: true,
        holdingEntityName: true,
        organizationName: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack p={4} pb={8}>
        <Stack maxWidth={600} gap={4}>
          <div>
            <PageHead
              title="Configure Transaction"
              description="Select a name and configure basic parameters of this transaction."
              paddingless
              descriptionStyle={{ marginBottom: 36 }}
            />
          </div>
          <Stack mt={-4}>
            <FormikTextField
              name="transactionName"
              formik={formik}
              label="Transaction Name"
              fullWidth
            />
            <Box pl={2} pt={1}>
              <Typography variant="body2">
                Enter a name for your transaction. This is visible to buyers and
                GPs. Examples: “Project Longhorn” or “UT Austin 2023 Portfolio
                Sale”
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box pl={0.5}>
              <FormikCheckbox
                labelProps={{
                  sx: {
                    mr: 0,
                  },
                }}
                name="adoptPlatformNda"
                label="Adopt Tap's platform NDAs Signatures."
                formik={formik}
              />
            </Box>
            <InformationPopup
              contentText={
                "By selecting this box you allow the LPs that have signed the Tap's Platform NDA to access the transaction under the same terms."
              }
            />
          </Stack>
          <Stack direction="row">
            <Box pl={0.5}>
              <FormikCheckbox
                labelProps={{
                  sx: {
                    mr: 0,
                  },
                }}
                name="onlyWholePortfolioBids"
                label="Only allow whole portfolio bids."
                formik={formik}
              />
            </Box>
            <InformationPopup
              contentText={
                "By selecting this box you require that all buyers bid on the entire portfolio at once. They cannot purchase a subset. Whole portfolio sales allow for faster and simpler execution but usually result in worse prices."
              }
            />
          </Stack>
          <Stack>
            <FormikTextField
              name="holdingEntityName"
              formik={formik}
              label="Transacting Entity Name"
              fullWidth
            />
            <Box pl={2} pt={1}>
              <Typography variant="body2">
                Enter the legal name of the entity that is selling this fund
                interest.
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <FormikTextField
              name="organizationName"
              formik={formik}
              label="Firm Name"
              fullWidth
            />
            <Box pl={2} pt={1}>
              <Typography variant="body2">
                Enter the name of the firm that is selling this fund interest.
              </Typography>
            </Box>
          </Stack>
          <Button
            size="large"
            type={BUTTON_TYPES.SECONDARY}
            fullWidth
            htmlType="submit"
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ConfigureTransactionStep;
