import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Navigate, Route, Routes, generatePath, useParams } from "react-router";

import { LP_ROUTES, TenderDetailsRoutes } from "common/constants/routes";
import { SidebarSideMenu } from "experiences/dashboard/presentation/components/SidebarMenu";
import OverviewScreen from "./OverviewScreen";
import OfferScreen from "./OfferScreen";
import { TransactionContact } from "experiences/common/TransactionContact";
import { LPDataRoom } from "./LPDataRoom";
import { useTransactionsContext } from "../state/TransactionsContext";
import {
  DataRoomScreenLoaded,
  DataRoomScreenLoading,
  OfferScreenShouldntLoad,
  TenderDetailsLoaded,
  TenderDetailsLoading,
} from "../state/tenders/TendersState";
import Loader from "common/components/TapLoader";
import { RedirectedToTender } from "../state/tenders/TendersEvent";
import {
  StyledTransactionMain,
  StyledTransactionOverview,
  StyledTransactionsLayout,
} from "../ui/TransactionDetailLayout";

const Mountains = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="landscape_FILL1_wght400_GRAD0_opsz48 1">
      <path
        id="Vector"
        d="M1.33301 17.819L7.15119 10.0614L11.8785 16.3645H13.6966L9.98755 11.4432L13.9391 6.18264L22.6663 17.819H1.33301Z"
        fill="#BCB3A5"
      />
    </g>
  </svg>
);

const TransactionOverview = ({
  state,
  children,
  image,
}: {
  state: TenderDetailsLoaded | TenderDetailsLoading;
  children?: React.ReactNode;
  image?: string;
}) => {
  if (state instanceof TenderDetailsLoading) return <Loader />;
  return (
    <StyledTransactionOverview>
      <div className="flex flex-col h-full">
        <div className="overview_details flex flex-col justify-between">
          {Boolean(image) ? (
            <img
              src={`https://cdn.usetap.com/funds/logos/${state.fundLogoUrl}`}
              className="w-20 h-20 rounded-full border-2 border-nomad-300"
            />
          ) : (
            <Mountains className="w-24 h-24 rounded-full border-2 border-nomad-300" />
          )}
          <h1 className="overview_details-project_title">
            {state.transactionName}
          </h1>
        </div>
        <div className="flex flex-col grow justify-between pb-8">
          <div>{children}</div>
          <div>{/* TODO ADD CONTACT, NEEDS TO BE MERGED */}</div>
        </div>
      </div>
    </StyledTransactionOverview>
  );
};

export const TenderDetails = () => {
  const { txnId } = useParams();
  const { tender } = useTransactionsContext();
  const {
    dataRoomScreenState,
    overviewScreenState,
    offerScreenState,
    tenderDetailsState,
    emitEvent,
  } = tender!;

  const TransactionsMenu = [
    {
      label: "Overview",
      path: TenderDetailsRoutes.Overview,
    },
    {
      label: "Data Room",
      path: TenderDetailsRoutes.DataRoom,
    },
    ...(offerScreenState instanceof OfferScreenShouldntLoad
      ? []
      : [
          {
            label: "Offer",
            path: TenderDetailsRoutes.Offer,
          },
        ]),
  ];

  useEffect(() => {
    emitEvent!(new RedirectedToTender({ txnId }));
  }, []);

  if (!(tenderDetailsState instanceof TenderDetailsLoaded)) return null;

  // if (!txnId) return null;
  // TODO: Maybe change this to a redirect to the transactions list?
  let state = tenderDetailsState;
  const baseRoute = generatePath(LP_ROUTES.TenderDetails, { txnId });

  // const transactionNameElement = document.getElementById(
  //   APP_BAR_TRANSACTION_NAME_NODE,
  // );

  return (
    <StyledTransactionsLayout key={txnId}>
      {state.transactionName && (
        <Helmet>
          <title>{state.transactionName}</title>
        </Helmet>
      )}
      <TransactionOverview state={tenderDetailsState}>
        <SidebarSideMenu
          menu={TransactionsMenu}
          baseRoute={baseRoute}
          contact={<TransactionContact />}
        />
      </TransactionOverview>
      <StyledTransactionMain>
        <Routes>
          <Route
            path={TenderDetailsRoutes.Overview}
            element={<OverviewScreen state={overviewScreenState} />}
          />
          {!(offerScreenState instanceof OfferScreenShouldntLoad) && (
            <Route
              path={TenderDetailsRoutes.Offer}
              element={<OfferScreen state={offerScreenState} />}
            />
          )}
          <Route
            path={`${TenderDetailsRoutes.DataRoom}/*`}
            element={
              <>
                {dataRoomScreenState instanceof DataRoomScreenLoading && (
                  <Loader />
                )}
                {dataRoomScreenState instanceof DataRoomScreenLoaded && (
                  <LPDataRoom dataRoom={dataRoomScreenState.dataRoom} />
                )}
              </>
            }
          />
          {/* write a fallback route that redirects everything else to TransactionDetailsRoutes.Overview */}
          <Route
            path="*"
            element={
              <Navigate
                to={`${baseRoute}${TenderDetailsRoutes.Overview}`}
                replace
              />
            }
          />
        </Routes>
      </StyledTransactionMain>
      {/* {transactionNameElement && createPortal(txn.name, transactionNameElement)} */}
    </StyledTransactionsLayout>
  );
};
