import { Link, Typography } from "@mui/material";

export const TOSSubheader = () => {
  return (
    <span>
      <Typography display="inline">
        By using Tap to buy LP interests, you are agreeing to the
      </Typography>
      <Link
        display="inline"
        href="https://www.usetap.com/terms"
        rel="noopener noreferrer"
        target="_blank"
      >
        {" "}
        Terms of Service.
      </Link>
    </span>
  );
};
