import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IHoldingProcess } from "../models/HoldingProcess";

const getPath = (id: string) => `/holding/process/${id}`;

export class GetHoldingProcess
  implements IAsyncUseCase<IParams, IHoldingProcess[]>
{
  call: (params: IParams) => Promise<Either<Failure, IHoldingProcess[]>> =
    async ({ id }) => {
      return await new TapClient(getPath(id))
        .get({})
        .then((resp: IHoldingProcess[]) => right(resp))
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {
  id: string;
}
