import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { match } from "fp-ts/lib/Either";

import FormikAutocomplete from "common/components/formik/FormikAutocomplete";
import { Failure } from "common/@types/app/Failure";
import { GetImpersonateUsers } from "experiences/authentication/domain/usecases/GetImpersonateUsers";
import { IImpersonateUser } from "experiences/authentication/domain/models/ImpersonateUser";
import { ImpersonationUsersPage } from "experiences/authentication/domain/models/ImpersonationUsersPage";
import { useAuthContext } from "../state/AuthenticationContext";

const mapUsersToOptions = (users: IImpersonateUser[]) =>
  users
    ? users.map(({ id, email }) => ({
        label: email,
        value: id,
      }))
    : [];

interface IProps {
  name: string;
  formik: FormikProps<{ email: string; reason: string }>;
  label: string;
  prefetchedUsers?: IImpersonateUser[];
  preSelectedUser?: IImpersonateUser;
  onOptionChanged?: (label: string) => void;
}

const UserSelector: React.FC<IProps> = ({
  formik,
  prefetchedUsers,
  preSelectedUser,
  onOptionChanged,
  ...props
}) => {
  const [users, setUsers] = useState<IImpersonateUser[]>([]);
  const [searchValue, setSearchValue] = useState<string>(() => {
    if (preSelectedUser) {
      return preSelectedUser?.email;
    }
    return "";
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { canCurrentUserImpersonate } = useAuthContext();

  const refreshUsers = () => {
    setIsLoading(true);
    if (!prefetchedUsers) {
      new GetImpersonateUsers()
        .call({
          paginationSpecs: { perPage: 100, pageIndex: 1 },
          filters: { searchTerm: searchValue },
        })
        .then((resp) => {
          match<Failure, ImpersonationUsersPage, void>(
            () => {},
            (page: ImpersonationUsersPage) => {
              setUsers(page.users);
            },
          )(resp);
        });
    } else {
      setUsers(prefetchedUsers);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (canCurrentUserImpersonate) refreshUsers();
  }, [searchValue, prefetchedUsers]);

  return (
    <FormikAutocomplete
      formik={formik}
      {...props}
      onOptionChange={(option) =>
        onOptionChanged && onOptionChanged(option.label)
      }
      onInputChange={(value: string) => {
        onOptionChanged && onOptionChanged(value);
        setSearchValue(value);
      }}
      loading={isLoading}
      options={
        preSelectedUser
          ? [
              ...mapUsersToOptions([preSelectedUser]),
              ...mapUsersToOptions(users),
            ]
          : mapUsersToOptions(users)
      }
      filterOptions={(x) => x}
      defaultValue={
        preSelectedUser
          ? mapUsersToOptions([preSelectedUser])[0].value
          : undefined
      }
    />
  );
};

export default UserSelector;
