import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { registerCellType, DropdownCellType } from "handsontable/cellTypes";
import { Stack } from "@mui/material";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";

import { LP_ROUTES } from "common/constants/routes";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { RowTabNavLinkWrapper } from "experiences/authentication/presentation/components/RowTabWrapper";
import { IndividualFundsTable } from "experiences/indications-of-interest/presentation/pages/IoiIndividualFundsTable";
import { IoiManagerTable } from "experiences/indications-of-interest/presentation/pages/IoiManagerTable";
import { usePreloadBids } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/usePreloadBids";
import { HoldingsBulkUpload } from "experiences/portfolio/presentation/pages/HoldingsBulkUpload";
import { HoldingsTable } from "experiences/portfolio/presentation/components/holdings-bulk-upload/HoldingsTable";

registerAllModules();
registerCellType(DropdownCellType);

export const GpLimitedPartnersUploadHoldings = () => {
  const navigate = useNavigate();

  const handleCloseClick = () => {
    navigate(LP_ROUTES.IndicationsOfInterestBids);
  };

  const { isReconfirm } = usePreloadBids();

  const lastSegmentManagerUpload =
    LP_ROUTES.IndicationsOfInterestManagerUpload.substring(
      LP_ROUTES.IndicationsOfInterestManagerUpload.lastIndexOf("/"),
    );

  const lastSegmentTableUpload =
    LP_ROUTES.IndicationsOfInterestTableUpload.substring(
      LP_ROUTES.IndicationsOfInterestTableUpload.lastIndexOf("/"),
    );

  return (
    <>
      <PageHead title="Add Holdings" />

      <StepperFlow
        onClose={handleCloseClick}
        name="Add Holdings"
        activeStepIndex={0}
      >
        <Stack width={"100%"} height={"100%"} justifyContent={"space-between"}>
          <Stack>
            <PageHead
              title="Upload Holdings"
              description="Upload holdings description..."
            />

            <HoldingsTable hasLpColumn />
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};
