import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { BlockedBuyer } from "experiences/approved-buyers/domain/models/BlockedBuyer";
import { StyledFundNameAndImage } from "./ApprovedBuyersTable";

export const BlockedBuyersTable = ({
  blockedBuyers: blockedBuyers,
}: {
  blockedBuyers: BlockedBuyer[];
  searchQuery?: string;
}) => {
  return (
    <>
      <TapTable
        data={blockedBuyers}
        emptyStateTitle={
          !blockedBuyers.length ? "There are no buyers to display." : null
        }
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "type",
            label: "Type",
            align: TableAlignment.LEFT,
          },
          {
            key: "checkSize",
            label: "Check Size",
            align: TableAlignment.LEFT,
          },
          {
            key: "stapleRation",
            label: "Staple",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(buyer: BlockedBuyer) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                <StyledFundNameAndImage>
                  <img
                    src={`https://cdn.usetap.com/funds/logos/${buyer.logoFileName}`}
                    alt="Fund logo"
                  />{" "}
                  {buyer.name}
                </StyledFundNameAndImage>
              </TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    flexDirection: "column",
                    maxWidth: 400,
                  }}
                >
                  {buyer.fundScope}
                </div>
              </TableCell>
              <TableCell>{buyer.type}</TableCell>
              <TableCell>{buyer.checkSize}</TableCell>
              <TableCell>
                <TextChip
                  text={buyer.staple}
                  pending={buyer.staple.toLocaleLowerCase() === "no"}
                  success={buyer.staple.toLocaleLowerCase() === "yes"}
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
