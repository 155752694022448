import { IWorksheetColumn } from "../model";
import {
  DEFAULT_WORKSHEET_COLUMN_WIDTH,
  WORKSHEET_COLUMN_WIDTH_LARGE,
} from "../utils";

export enum FundExposureSummaryKeys {
  TapFundId = "tap_fund_id",
  FundName = "fund_name",
  TapManagerId = "tap_manager_id",
  ManagerName = "manager_name",
  FundSize = "fund_size",
  FundCommitedCapital = "fund_commited_capital",
  Strategy = "strategy",
  Vintage = "vintage",
  Geography = "geography",
  ReportDate = "report_date",
  LpCommittedCapital = "lp_commited_capital",
  LpContributedCapitalItd = "lp_contributed_capital_itd",
  LpDistributedCapitalItd = "lp_distributed_capital_itd",
  LpRemainingUnfunded = "lp_remaining_unfunded",
  LpEndingCapitalAccountValue = "lp_ending_capital_account_value",
  LpCurrentExposure = "lp_current_exposure",
  LpInterimContributions = "lp_interim_contributions",
  LpInterimDistributions = "lp_interim_distributions",
  UnrealizedInvestmentCount = "unrealized_investment_count",
  Top3Concentration = "top_3_concentration",
  Top10Concentration = "top_10_concentration",
  PercentPublic = "percent_public",
  WeightedAvgRevenueGrowth = "weighted_avg_revenue_growth",
  WeightedAvgEbitdaMargin = "weighted_avg_ebitda_margin",
  WeightedAvgEbitdaMultiple = "weighted_avg_ebitda_multiple",
  WeightedAvgLeverage = "weighted_avg_leverage",
  FundNavQoqPercentChg1q = "fund_nav_qoq_percent_chg_1q",
  FundNavQoqPercentChg2q = "fund_nav_qoq_percent_chg_2q",
  FundNavQoqPercentChg3q = "fund_nav_qoq_percent_chg_3q",
  FundNavYoyPercentChangeFy1 = "fund_nav_yoy_percent_change_fy1",
  FundNavYoyPercentChangeFy2 = "fund_nav_yoy_percent_change_fy2",
  TapPriceEstimate = "tap_price_estimate",
  TapPriceEstimateCertainty = "tap_price_estimate_certainty",
  BaseManagementFee = "base_management_fee",
  Carry = "carry",
  HurdleRate = "hurdle_rate",
  PtpRiskLevel = "ptp_risk_level",
  PtpQueueLength = "ptp_queue_length",
  Rofr = "rofr",
  Rofo = "rofo",
  Blocker = "blocker",
  Domicile = "domicile",
  FundSeriesAverageMoic = "fund_series_average_moic",
}

export interface IFundExposureSummary {
  [FundExposureSummaryKeys.TapFundId]: string;
  [FundExposureSummaryKeys.FundName]: string;
  [FundExposureSummaryKeys.TapManagerId]: string;
  [FundExposureSummaryKeys.ManagerName]: string;
  [FundExposureSummaryKeys.FundSize]: string;
  [FundExposureSummaryKeys.FundCommitedCapital]: string;
  [FundExposureSummaryKeys.Strategy]: string;
  [FundExposureSummaryKeys.Vintage]: string;
  [FundExposureSummaryKeys.Geography]: string;
  [FundExposureSummaryKeys.ReportDate]: string;
  [FundExposureSummaryKeys.LpCommittedCapital]: string;
  [FundExposureSummaryKeys.LpContributedCapitalItd]: string;
  [FundExposureSummaryKeys.LpDistributedCapitalItd]: string;
  [FundExposureSummaryKeys.LpRemainingUnfunded]: string;
  [FundExposureSummaryKeys.LpEndingCapitalAccountValue]: string;
  [FundExposureSummaryKeys.LpCurrentExposure]: string;
  [FundExposureSummaryKeys.LpInterimContributions]: string;
  [FundExposureSummaryKeys.LpInterimDistributions]: string;
  [FundExposureSummaryKeys.UnrealizedInvestmentCount]: string;
  [FundExposureSummaryKeys.Top3Concentration]: string;
  [FundExposureSummaryKeys.Top10Concentration]: string;
  [FundExposureSummaryKeys.PercentPublic]: string;
  [FundExposureSummaryKeys.WeightedAvgRevenueGrowth]: string;
  [FundExposureSummaryKeys.WeightedAvgEbitdaMargin]: string;
  [FundExposureSummaryKeys.WeightedAvgEbitdaMultiple]: string;
  [FundExposureSummaryKeys.WeightedAvgLeverage]: string;
  [FundExposureSummaryKeys.FundNavQoqPercentChg1q]: string;
  [FundExposureSummaryKeys.FundNavQoqPercentChg2q]: string;
  [FundExposureSummaryKeys.FundNavQoqPercentChg3q]: string;
  [FundExposureSummaryKeys.FundNavYoyPercentChangeFy1]: string;
  [FundExposureSummaryKeys.FundNavYoyPercentChangeFy2]: string;
  [FundExposureSummaryKeys.TapPriceEstimate]: string;
  [FundExposureSummaryKeys.TapPriceEstimateCertainty]: string;
  [FundExposureSummaryKeys.BaseManagementFee]: string;
  [FundExposureSummaryKeys.Carry]: string;
  [FundExposureSummaryKeys.HurdleRate]: string;
  [FundExposureSummaryKeys.PtpRiskLevel]: string;
  [FundExposureSummaryKeys.PtpQueueLength]: string;
  [FundExposureSummaryKeys.Rofr]: string;
  [FundExposureSummaryKeys.Rofo]: string;
  [FundExposureSummaryKeys.Blocker]: string;
  [FundExposureSummaryKeys.Domicile]: string;
  [FundExposureSummaryKeys.FundSeriesAverageMoic]: string;
}

export const fundExposureSummaryKeyLabels: Record<
  keyof IFundExposureSummary,
  string
> = {
  [FundExposureSummaryKeys.TapFundId]: "Tap Fund ID",
  [FundExposureSummaryKeys.FundName]: "Fund Name",
  [FundExposureSummaryKeys.TapManagerId]: "Tap Manager ID",
  [FundExposureSummaryKeys.ManagerName]: "Manager Name",
  [FundExposureSummaryKeys.FundSize]: "Fund Size",
  [FundExposureSummaryKeys.FundCommitedCapital]: "Fund Committed Capital",
  [FundExposureSummaryKeys.Strategy]: "Strategy",
  [FundExposureSummaryKeys.Vintage]: "Vintages",
  [FundExposureSummaryKeys.Geography]: "Geography",
  [FundExposureSummaryKeys.ReportDate]: "Report Date",
  [FundExposureSummaryKeys.LpCommittedCapital]: "LP Committed Capital",
  [FundExposureSummaryKeys.LpContributedCapitalItd]:
    "LP Contributed Capital ITD",
  [FundExposureSummaryKeys.LpDistributedCapitalItd]:
    "LP Distributed Capital ITD",
  [FundExposureSummaryKeys.LpRemainingUnfunded]: "LP Remaining Unfunded",
  [FundExposureSummaryKeys.LpEndingCapitalAccountValue]:
    "LP Ending Capital Account Value",
  [FundExposureSummaryKeys.LpCurrentExposure]: "LP Current Exposure",
  [FundExposureSummaryKeys.LpInterimContributions]: "LP Interim Contributions",
  [FundExposureSummaryKeys.LpInterimDistributions]: "LP Interim Distributions",
  [FundExposureSummaryKeys.UnrealizedInvestmentCount]:
    "Unrealized Investment Count",
  [FundExposureSummaryKeys.Top3Concentration]: "Top 3 Investment Concentration",
  [FundExposureSummaryKeys.Top10Concentration]:
    "Top 10 Investment Concentration",
  [FundExposureSummaryKeys.PercentPublic]: "Public Ownership Percentage",
  [FundExposureSummaryKeys.WeightedAvgRevenueGrowth]:
    "Weighted Average Revenue Growth",
  [FundExposureSummaryKeys.WeightedAvgEbitdaMargin]:
    "Weighted Average EBITDA Margin",
  [FundExposureSummaryKeys.WeightedAvgEbitdaMultiple]:
    "Weighted Average EBITDA Multiple",
  [FundExposureSummaryKeys.WeightedAvgLeverage]:
    "Weighted Average Leverage Multiple",
  [FundExposureSummaryKeys.FundNavQoqPercentChg1q]:
    "Fund NAV Q-o-Q % Change (1Q)",
  [FundExposureSummaryKeys.FundNavQoqPercentChg2q]:
    "Fund NAV Q-o-Q % Change (2Q)",
  [FundExposureSummaryKeys.FundNavQoqPercentChg3q]:
    "Fund NAV Q-o-Q % Change (3Q)",
  [FundExposureSummaryKeys.FundNavYoyPercentChangeFy1]:
    "Fund NAV Y-o-Y % Change (1Y)",
  [FundExposureSummaryKeys.FundNavYoyPercentChangeFy2]:
    "Fund NAV Y-o-Y % Change (2Y)",
  [FundExposureSummaryKeys.TapPriceEstimate]: "Estimated Price",
  [FundExposureSummaryKeys.TapPriceEstimateCertainty]:
    "Estimated Price Certainty",
  [FundExposureSummaryKeys.BaseManagementFee]: "Base Management Fee",
  [FundExposureSummaryKeys.Carry]: "Carried Interest",
  [FundExposureSummaryKeys.HurdleRate]: "Hurdle Rate",
  [FundExposureSummaryKeys.PtpRiskLevel]: "PTP Risk Level",
  [FundExposureSummaryKeys.PtpQueueLength]: "PTP Queue Length",
  [FundExposureSummaryKeys.Rofr]: "Right of First Refusal (ROFR)",
  [FundExposureSummaryKeys.Rofo]: "Right of First Offer (ROFO)",
  [FundExposureSummaryKeys.Blocker]: "Blocker",
  [FundExposureSummaryKeys.Domicile]: "Domicile",
  [FundExposureSummaryKeys.FundSeriesAverageMoic]: "Fund Series Average MOIC",
};

// fund_id,fund_name,manager_id,manager_name,report_date,financial_statement_currency,fund_committed_capital,fund_contributed_capital_itd,fund_distributed_capital_itd,fund_recallable_capital_itd,fund_remaining_unfunded,fund_ending_capital_account_value,gp_distributed_as_carry_itd,portfolio_investments_cost,portfolio_investments_nav,cash,other_assets,debt_and_capital_call_lines,other_liabilities,total_nav
export enum FundFinancialsKeys {
  FundId = "fund_id",
  FundName = "fund_name",
  ManagerId = "manager_id",
  ManagerName = "manager_name",
  ReportDate = "report_date",
  FinancialStatementCurrency = "financial_statement_currency",
  FundCommittedCapital = "fund_committed_capital",
  FundContributedCapitalItd = "fund_contributed_capital_itd",
  FundDistributedCapitalItd = "fund_distributed_capital_itd",
  FundRecallableCapitalItd = "fund_recallable_capital_itd",
  FundRemainingUnfunded = "fund_remaining_unfunded",
  FundEndingCapitalAccountValue = "fund_ending_capital_account_value",
  GpDistributedAsCarryItd = "gp_distributed_as_carry_itd",
  PortfolioInvestmentsCost = "portfolio_investments_cost",
  PortfolioInvestmentsNav = "portfolio_investments_nav",
  Cash = "cash",
  OtherAssets = "other_assets",
  DebtAndCapitalCallLines = "debt_and_capital_call_lines",
  OtherLiabilities = "other_liabilities",
  TotalNav = "total_nav",
}

export interface IFundFinancials {
  [FundFinancialsKeys.FundId]: string;
  [FundFinancialsKeys.FundName]: string;
  [FundFinancialsKeys.ManagerId]: string;
  [FundFinancialsKeys.ManagerName]: string;
  [FundFinancialsKeys.ReportDate]: string;
  [FundFinancialsKeys.FinancialStatementCurrency]: string;
  [FundFinancialsKeys.FundCommittedCapital]: string;
  [FundFinancialsKeys.FundContributedCapitalItd]: string;
  [FundFinancialsKeys.FundDistributedCapitalItd]: string;
  [FundFinancialsKeys.FundRecallableCapitalItd]: string;
  [FundFinancialsKeys.FundRemainingUnfunded]: string;
  [FundFinancialsKeys.FundEndingCapitalAccountValue]: string;
  [FundFinancialsKeys.GpDistributedAsCarryItd]: string;
  [FundFinancialsKeys.PortfolioInvestmentsCost]: string;
  [FundFinancialsKeys.PortfolioInvestmentsNav]: string;
  [FundFinancialsKeys.Cash]: string;
  [FundFinancialsKeys.OtherAssets]: string;
  [FundFinancialsKeys.DebtAndCapitalCallLines]: string;
  [FundFinancialsKeys.OtherLiabilities]: string;
  [FundFinancialsKeys.TotalNav]: string;
}

// Tap Fund ID	Fund Name	Tap Manager ID	Manager Name	Report Date	FS Currency	Fund Committed Capital	Fund Contributed Capital ITD	Fund Distributed Capital ITD	Fund Recallable Capital ITD	Fund Remaining Unfunded	Fund Ending Capital Account Value	GP Distributed as Carry ITD	Investments Current Cost	Investments Current FMV	Cash Balance	Other Assets	Debt & Capital Call Lines	Other Liabilities	Total NAV
export const fundFinancialsKeyLabels: Record<keyof IFundFinancials, string> = {
  [FundFinancialsKeys.FundId]: "Tap Fund ID",
  [FundFinancialsKeys.FundName]: "Fund Name",
  [FundFinancialsKeys.ManagerId]: "Tap Manager ID",
  [FundFinancialsKeys.ManagerName]: "Manager Name",
  [FundFinancialsKeys.ReportDate]: "Report Date",
  [FundFinancialsKeys.FinancialStatementCurrency]: "FS Currency",
  [FundFinancialsKeys.FundCommittedCapital]: "Fund Committed Capital",
  [FundFinancialsKeys.FundContributedCapitalItd]:
    "Fund Contributed Capital ITD",
  [FundFinancialsKeys.FundDistributedCapitalItd]:
    "Fund Distributed Capital ITD",
  [FundFinancialsKeys.FundRecallableCapitalItd]: "Fund Recallable Capital ITD",
  [FundFinancialsKeys.FundRemainingUnfunded]: "Fund Remaining Unfunded",
  [FundFinancialsKeys.FundEndingCapitalAccountValue]:
    "Fund Ending Capital Account Value",
  [FundFinancialsKeys.GpDistributedAsCarryItd]: "GP Distributed as Carry ITD",
  [FundFinancialsKeys.PortfolioInvestmentsCost]: "Investments Current Cost",
  [FundFinancialsKeys.PortfolioInvestmentsNav]: "Investments Current FMV",
  [FundFinancialsKeys.Cash]: "Cash Balance",
  [FundFinancialsKeys.OtherAssets]: "Other Assets",
  [FundFinancialsKeys.DebtAndCapitalCallLines]: "Debt & Capital Call Lines",
  [FundFinancialsKeys.OtherLiabilities]: "Other Liabilities",
  [FundFinancialsKeys.TotalNav]: "Total NAV",
};

export interface IFundSummaryTab {
  name: string;
  data: string;
}

export interface FundExposureSummaryCsv {
  fundExposureSummaryTabs: IFundSummaryTab[];
}

export enum FundSummaryTabs {
  FundExposureSummary = "Fund Exposure Summary",
  FundFinancials = "Fund Financials",
}

// prettier-ignore
export const fundExposureSummaryColumns: IWorksheetColumn[] = [
  { key: FundExposureSummaryKeys.TapFundId, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.TapFundId], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundExposureSummaryKeys.FundName, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundName], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundExposureSummaryKeys.TapManagerId, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.TapManagerId], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundExposureSummaryKeys.ManagerName, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.ManagerName], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundExposureSummaryKeys.FundSize, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundSize], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundCommitedCapital, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundCommitedCapital], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Strategy, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Strategy], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Vintage, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Vintage], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Geography, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Geography], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.ReportDate, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.ReportDate], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpCommittedCapital, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpCommittedCapital], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpContributedCapitalItd, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpContributedCapitalItd], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpDistributedCapitalItd, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpDistributedCapitalItd], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpRemainingUnfunded, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpRemainingUnfunded], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpEndingCapitalAccountValue, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpEndingCapitalAccountValue], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpCurrentExposure, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpCurrentExposure], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpInterimContributions, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpInterimContributions], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.LpInterimDistributions, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.LpInterimDistributions], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.UnrealizedInvestmentCount, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.UnrealizedInvestmentCount], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Top3Concentration, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Top3Concentration], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Top10Concentration, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Top10Concentration], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.PercentPublic, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.PercentPublic], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.WeightedAvgRevenueGrowth, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.WeightedAvgRevenueGrowth], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.WeightedAvgEbitdaMargin, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.WeightedAvgEbitdaMargin], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.WeightedAvgEbitdaMultiple, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.WeightedAvgEbitdaMultiple], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.WeightedAvgLeverage, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.WeightedAvgLeverage], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundNavQoqPercentChg1q, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundNavQoqPercentChg1q], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundNavQoqPercentChg2q, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundNavQoqPercentChg2q], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundNavQoqPercentChg3q, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundNavQoqPercentChg3q], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundNavYoyPercentChangeFy1, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundNavYoyPercentChangeFy1], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundNavYoyPercentChangeFy2, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundNavYoyPercentChangeFy2], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.TapPriceEstimate, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.TapPriceEstimate], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.TapPriceEstimateCertainty, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.TapPriceEstimateCertainty], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.BaseManagementFee, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.BaseManagementFee], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Carry, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Carry], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.HurdleRate, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.HurdleRate], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.PtpRiskLevel, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.PtpRiskLevel], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.PtpQueueLength, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.PtpQueueLength], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Rofr, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Rofr], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Rofo, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Rofo], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Blocker, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Blocker], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.Domicile, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.Domicile], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundExposureSummaryKeys.FundSeriesAverageMoic, header: fundExposureSummaryKeyLabels[FundExposureSummaryKeys.FundSeriesAverageMoic], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
];

// prettier-ignore
export const fundFinancialsColumns: IWorksheetColumn[] = [
  { key: FundFinancialsKeys.FundId, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundId], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundFinancialsKeys.FundName, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundName], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundFinancialsKeys.ManagerId, header: fundFinancialsKeyLabels[FundFinancialsKeys.ManagerId], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundFinancialsKeys.ManagerName, header: fundFinancialsKeyLabels[FundFinancialsKeys.ManagerName], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: FundFinancialsKeys.ReportDate, header: fundFinancialsKeyLabels[FundFinancialsKeys.ReportDate], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FinancialStatementCurrency, header: fundFinancialsKeyLabels[FundFinancialsKeys.FinancialStatementCurrency], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FundCommittedCapital, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundCommittedCapital], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FundContributedCapitalItd, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundContributedCapitalItd], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FundDistributedCapitalItd, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundDistributedCapitalItd], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FundRecallableCapitalItd, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundRecallableCapitalItd], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FundRemainingUnfunded, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundRemainingUnfunded], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.FundEndingCapitalAccountValue, header: fundFinancialsKeyLabels[FundFinancialsKeys.FundEndingCapitalAccountValue], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.GpDistributedAsCarryItd, header: fundFinancialsKeyLabels[FundFinancialsKeys.GpDistributedAsCarryItd], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.PortfolioInvestmentsCost, header: fundFinancialsKeyLabels[FundFinancialsKeys.PortfolioInvestmentsCost], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.PortfolioInvestmentsNav, header: fundFinancialsKeyLabels[FundFinancialsKeys.PortfolioInvestmentsNav], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.Cash, header: fundFinancialsKeyLabels[FundFinancialsKeys.Cash], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.OtherAssets, header: fundFinancialsKeyLabels[FundFinancialsKeys.OtherAssets], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.DebtAndCapitalCallLines, header: fundFinancialsKeyLabels[FundFinancialsKeys.DebtAndCapitalCallLines], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.OtherLiabilities, header: fundFinancialsKeyLabels[FundFinancialsKeys.OtherLiabilities], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
  { key: FundFinancialsKeys.TotalNav, header: fundFinancialsKeyLabels[FundFinancialsKeys.TotalNav], width: DEFAULT_WORKSHEET_COLUMN_WIDTH },
];
