import styled from "styled-components";

import { BUTTON_TYPES, Button } from "common/components/Button";
import { DownloadSimple } from "@phosphor-icons/react/dist/ssr";

const StyledFileDownloadBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
  color: #000;
  text-align: left;

  cursor: default;
  user-select: none;

  border-radius: 12px;
  border: 1px solid var(--branding-keyline-default, #dfdfd9);

  width: 100%;
  max-width: 800px;

  & + & {
    margin-top: 16px;
  }

  .download-icon {
    margin-right: 8px;
  }

  .download-details {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .download-title {
    color: var(--branding-dark, #21272d);
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .download-subtitle {
    color: var(--branding-light, #737476);
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const FileDownloadBox = ({
  title,
  subtitle,
  downloadLink,
  icon,
}: {
  title: React.ReactNode;
  subtitle: string;
  downloadLink: string;
  icon?: React.ReactNode;
}) => {
  return (
    <StyledFileDownloadBox>
      {icon && <div className="download-icon">{icon}</div>}
      <div className="download-details">
        <h3 className="download-title">{title}</h3>
        <p className="download-subtitle">{subtitle}</p>
      </div>
      <Button
        onClick={() => {
          window.open(downloadLink, "_blank");
        }}
        type={BUTTON_TYPES.PRIMARY}
        style={{ margin: 0 }}
      >
        <DownloadSimple />
        Download
      </Button>
    </StyledFileDownloadBox>
  );
};
