import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";

import { FirmOverview } from "../models/FirmOverview";
import { firmOverviewMock } from "../mock/FirmOverviewMock";

interface IParams {}

export class GetFirmOverview implements IAsyncUseCase<IParams, FirmOverview> {
  call: (params: IParams) => Promise<Either<Failure, FirmOverview>> =
    async (): Promise<Either<Failure, FirmOverview>> => {
      return right(firmOverviewMock);
    };
}
