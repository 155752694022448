import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import {
  ILPLedTransaction,
  SellerPerspectiveTxn,
  ISellerPerspectiveTxn,
  ITransaction,
  BuyerPersepectiveTxn,
  IBuyerPersepectiveTxn,
  TransactionType,
  TransactionStatus,
} from "../models/Transaction";
import { ParticipantCapacity } from "../models/Participant";
import { ITenderListing, TenderListing } from "../models/Tender";

export class ListUserTransactions implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, ITransaction[]>> =
    async ({}) => {
      return await new TapClient("/transactions/list/")
        .get({})
        .then((resp: ITransaction[]) => {
          return right(
            resp
              .map((txn: ITransaction) => {
                if (txn.type == TransactionType.LPLed) {
                  let lpLed = txn as ILPLedTransaction;
                  return lpLed.requesterParticipant.capacity ==
                    ParticipantCapacity.Seller
                    ? new SellerPerspectiveTxn(lpLed as ISellerPerspectiveTxn)
                    : new BuyerPersepectiveTxn(lpLed as IBuyerPersepectiveTxn);
                } else if (txn.type == TransactionType.Tender) {
                  let tender = txn as ITenderListing;
                  return new TenderListing(tender);
                }
              })
              .map((txn) => {
                return txn;
              })
              .filter((txn) => {
                if (txn instanceof TenderListing) {
                  return txn.currentStatus == TransactionStatus.Participation;
                } else {
                  return true;
                }
              }),
          );
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {}
