import React from "react";
import { CommandEmpty } from "shadcn/ui/Command";

export const UiCommandEmpty = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <CommandEmpty
      className="flex gap-2 h-10 items-center px-3 cursor-pointer hover:bg-nomad-50/50"
      onClick={onClick}
    >
      {children}
    </CommandEmpty>
  );
};
