import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";

import { BlockedBuyer } from "../models/BlockedBuyer";
import { blockedBuyersMock } from "./blockedBuyersMock";

interface IParams {}

export class GetBlockedBuyers
  implements IAsyncUseCase<IParams, BlockedBuyer[]>
{
  call: (params: IParams) => Promise<Either<Failure, BlockedBuyer[]>> =
    async (): Promise<Either<Failure, BlockedBuyer[]>> => {
      return right([...blockedBuyersMock]);
    };
}
