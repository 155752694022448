import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

import CloseIcon from "@mui/icons-material/Close";

import Logo from "../../common/components/Logo";

interface ITapAppBarProps {
  children: React.ReactNode;
  style: React.CSSProperties;
}

const TapAppBar: React.FC<ITapAppBarProps> = ({ children, style }) => (
  <Stack direction="column" style={style}>
    <AppBar
      position="sticky"
      sx={{
        boxShadow: "none",
        backgroundColor: "#ffffff",
        color: "#848484",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
        }}
      >
        <Box onClick={() => {}}>
          <Logo width={68} height={34} />
        </Box>
        {children}
      </Toolbar>
    </AppBar>
    <Divider />
  </Stack>
);

interface IBasicAppBarPorps {
  onClose?: () => void;
}

const BasicAppBar: React.FC<IBasicAppBarPorps> = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <TapAppBar
      style={{
        position: "absolute",
        top: 0,
        boxSizing: "border-box",
        width: "100%",
        zIndex: 10000,
      }}
    >
      <IconButton
        onClick={
          onClose
            ? onClose
            : () => {
                navigate(-1);
              }
        }
        sx={{
          border: `2px solid #DBDCDA`,
        }}
      >
        <CloseIcon />
      </IconButton>
    </TapAppBar>
  );
};

interface IBasicAppBarLayoutPorps extends IBasicAppBarPorps {
  children: React.ReactNode;
  centeredContent?: boolean;
}

export const AppLayoutWithTopMenu: React.FC<IBasicAppBarLayoutPorps> = ({
  children,
  onClose,
  centeredContent = false,
}) => {
  return (
    <div
      style={{
        minHeight: "calc(100% - 65px)",
        width: "100%",
      }}
    >
      <BasicAppBar onClose={onClose} />
      {centeredContent ? (
        <Box
          pt={12}
          sx={{
            position: "absolute",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            overflowY: "auto",
            overflowX: "hidden",
            flex: "1 1",
            minHeight: "inherit",
            width: "100%",
            height: "calc(100% - 65px)",
          }}
        >
          <Box
            sx={{
              width: "80%",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-between",
              display: "flex",
              maxWidth: 1050,
            }}
          >
            {children}
          </Box>
        </Box>
      ) : (
        children
      )}
    </div>
  );
};
