import { useState } from "react";
import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";

import { APP_BAR_FUND_NAME_NODE } from "common/constants/assets";
import { useFundPrivacy } from "../hooks/useFundPrivacy";

import { Sidebar } from "experiences/funds/presentation/components/viewFund/Sidebar";
import { ContactSellerModal } from "experiences/funds/presentation/components/viewFund/ContactSellerModal";
import { ViewFundContent } from "experiences/funds/presentation/components/viewFund/ViewFundContent";
import { useViewFundSummary } from "experiences/funds/presentation/hooks/useViewFundSummary";
import {
  AddInterestModal,
  IoiType,
} from "experiences/indications-of-interest/presentation/pages/AddInterestModal";
import { IOIType } from "experiences/indications-of-interest/domain/models/IOI";
import { BidProvider } from "experiences/dashboard/presentation/state/BidContext";
import { useViewFund } from "experiences/funds/presentation/hooks/useViewFund";
import { PageWrapper } from "experiences/common/PageWrapper";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

export const formatPercentPoints = (
  points: number | undefined | null | string,
) => {
  if (points === null) return NOT_AVAILABLE_STR;
  if (points === undefined) return NOT_AVAILABLE_STR;

  const isString = typeof points === "string";

  if (isString) {
    const pointsNumber = Number.parseFloat(points);
    if (Number.isNaN(pointsNumber)) return NOT_AVAILABLE_STR;

    return `${Number(pointsNumber / 100).toFixed(1)}%`;
  }

  if (points === 0) return "0%";

  return `${Number(points / 100).toFixed(1)}%`;
};

export const formatMillions = (amount: number) => {
  return `$${amount / 1000000}M`;
};

export const ViewFund = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { summary, iois, refreshSummary } = useViewFundSummary({ fundId });
  const { fund, loaded } = useViewFund({ fundId });
  const fundPrivacy = useFundPrivacy({ fund: fund });

  const [showContactSellerModal, setShowContactSellerModal] = useState<
    IOIType | false
  >(false);
  const [showSubmitIoiModal, setShowSubmitIoiModal] = useState<IoiType | false>(
    false,
  );

  const toggleContactModal = (type: IOIType) => {
    setShowContactSellerModal(type);
  };

  const closeContactModal = () => {
    setShowContactSellerModal(false);
  };

  const handleCloseSubmitIoiModal = () => {
    setShowSubmitIoiModal(false);
  };

  const toggleSubmitIoiModal = (type: IoiType) => {
    setShowSubmitIoiModal((prev) => {
      if (prev) return false;
      return type;
    });
  };

  const fundNameElement = document.getElementById(APP_BAR_FUND_NAME_NODE);

  const showPageTitle = fund?.name && loaded;

  return (
    <BidProvider summary={summary} iois={iois}>
      <PageWrapper>
        {showPageTitle && (
          <Helmet>
            <title>{fund?.name}</title>
          </Helmet>
        )}
        {loaded ? (
          <>
            <Sidebar
              fund={fund}
              toggleSubmitIoiModal={toggleSubmitIoiModal}
              summary={summary}
            />
            <ViewFundContent
              fundPrivacy={fundPrivacy}
              fund={fund}
              summary={summary}
              iois={iois}
              toggleContactModal={toggleContactModal}
              toggleSubmitIoiModal={toggleSubmitIoiModal}
            />
            <ContactSellerModal
              open={showContactSellerModal}
              onClose={() => closeContactModal()}
              fund={fund}
              summary={summary}
            />
            <AddInterestModal
              key={fund?.id} // forces a re-render when viewing another fund without reloading the page, so that IOIs are assigned to the appropriate fund
              open={!!showSubmitIoiModal}
              fund={fund}
              prefillFundId
              {...(showSubmitIoiModal && { type: showSubmitIoiModal })}
              onClose={handleCloseSubmitIoiModal}
              onFormSubmitted={refreshSummary}
            />
          </>
        ) : null}
        {showPageTitle &&
          Boolean(fundNameElement) &&
          createPortal(fund?.name, fundNameElement)}
      </PageWrapper>
    </BidProvider>
  );
};
