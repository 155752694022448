// Values here would be the keys of the first row of the CSV
// Order will be the order of the columns in the worksheet

import { IWorksheetColumn } from "../model";
import {
  DEFAULT_WORKSHEET_COLUMN_WIDTH,
  WORKSHEET_COLUMN_WIDTH_EXTRA_LARGE,
  WORKSHEET_COLUMN_WIDTH_LARGE,
} from "../utils";

// TODO: missing: tap_investment_id
export enum AggregateSoiColumnKeys {
  InvestmentName = "company_name", // TODO: this is not on the CSV download as of 2024-09-12, but used company_name
  TapCompanyID = "tap_company_id",
  FundName = "fund_name",
  TapFundID = "tap_fund_id",
  ReportDate = "report_date",
  CompanyCurrency = "company_currency",
  InvestmentDate = "investment_date",
  HoldPeriod = "hold_period",
  InvestmentType = "investment_type",
  InvestmentGeography = "investment_geography",
  Sector = "sector",
  InvestmentStatus = "investment_status",
  TotalCost = "total_cost",
  TotalValue = "total_value",
  InvestmentMOIC = "investment_moic",
  RealizedCost = "realized_cost",
  RealizedValue = "realized_value",
  UnrealizedCost = "unrealized_cost",
  UnrealizedValue = "unrealized_value",
  LpImpliedInvestmentValue = "lp_implied_investment_value",
  PercentOfTotal = "PercentOfTotal", // this is not on the CSV download as of 2024-09-12, should skip
}

export const aggregateSoiColumnLabels = {
  [AggregateSoiColumnKeys.InvestmentName]: "Investment Name",
  [AggregateSoiColumnKeys.TapCompanyID]: "Tap Company ID",
  [AggregateSoiColumnKeys.FundName]: "Fund Name",
  [AggregateSoiColumnKeys.TapFundID]: "Tap Fund ID",
  [AggregateSoiColumnKeys.ReportDate]: "Report Date",
  [AggregateSoiColumnKeys.CompanyCurrency]: "Company Currency",
  [AggregateSoiColumnKeys.InvestmentDate]: "Investment Date",
  [AggregateSoiColumnKeys.HoldPeriod]: "Hold Period",
  [AggregateSoiColumnKeys.InvestmentType]: "Investment Type",
  [AggregateSoiColumnKeys.InvestmentGeography]: "Investment Geography",
  [AggregateSoiColumnKeys.Sector]: "Sector",
  [AggregateSoiColumnKeys.InvestmentStatus]: "Investment Status",
  [AggregateSoiColumnKeys.TotalCost]: "Total Cost",
  [AggregateSoiColumnKeys.TotalValue]: "Total Value",
  [AggregateSoiColumnKeys.InvestmentMOIC]: "Investment MOIC",
  [AggregateSoiColumnKeys.RealizedCost]: "Realized Cost",
  [AggregateSoiColumnKeys.RealizedValue]: "Realized Value",
  [AggregateSoiColumnKeys.UnrealizedCost]: "Unrealized Cost",
  [AggregateSoiColumnKeys.UnrealizedValue]: "Unrealized Value",
  [AggregateSoiColumnKeys.LpImpliedInvestmentValue]:
    "LP Implied Investment Value",
  [AggregateSoiColumnKeys.PercentOfTotal]: "% of Total",
};

export const aggregateSoiColumns: IWorksheetColumn[] = [
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.InvestmentName],
    key: AggregateSoiColumnKeys.InvestmentName,
    width: WORKSHEET_COLUMN_WIDTH_LARGE,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.TapCompanyID],
    key: AggregateSoiColumnKeys.TapCompanyID,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.FundName],
    key: AggregateSoiColumnKeys.FundName,
    width: WORKSHEET_COLUMN_WIDTH_LARGE,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.TapFundID],
    key: AggregateSoiColumnKeys.TapFundID,
    width: WORKSHEET_COLUMN_WIDTH_LARGE,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.ReportDate],
    key: AggregateSoiColumnKeys.ReportDate,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.CompanyCurrency],
    key: AggregateSoiColumnKeys.CompanyCurrency,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.InvestmentDate],
    key: AggregateSoiColumnKeys.InvestmentDate,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.HoldPeriod],
    key: AggregateSoiColumnKeys.HoldPeriod,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.InvestmentType],
    key: AggregateSoiColumnKeys.InvestmentType,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateSoiColumnLabels[AggregateSoiColumnKeys.InvestmentGeography],
    key: AggregateSoiColumnKeys.InvestmentGeography,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.Sector],
    key: AggregateSoiColumnKeys.Sector,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.InvestmentStatus],
    key: AggregateSoiColumnKeys.InvestmentStatus,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.TotalCost],
    key: AggregateSoiColumnKeys.TotalCost,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.TotalValue],
    key: AggregateSoiColumnKeys.TotalValue,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.InvestmentMOIC],
    key: AggregateSoiColumnKeys.InvestmentMOIC,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.RealizedCost],
    key: AggregateSoiColumnKeys.RealizedCost,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.RealizedValue],
    key: AggregateSoiColumnKeys.RealizedValue,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.UnrealizedCost],
    key: AggregateSoiColumnKeys.UnrealizedCost,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.UnrealizedValue],
    key: AggregateSoiColumnKeys.UnrealizedValue,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateSoiColumnLabels[AggregateSoiColumnKeys.LpImpliedInvestmentValue],
    key: AggregateSoiColumnKeys.LpImpliedInvestmentValue,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header: aggregateSoiColumnLabels[AggregateSoiColumnKeys.PercentOfTotal],
    key: AggregateSoiColumnKeys.PercentOfTotal,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
];

// company_name,tap_company_id,fund_name,tap_fund_id,tap_investment_id,report_date,company_currency,fund_ownership_percent,equity_value,enterprise_value,revenue_ltm,revenue_lfy,revenue_lfy_1,revenue_lfy_2,revenue_yoy_percent_chg_ltm,revenue_yoy_percent_chg_fy1,revenue_ntm,ebitda_ltm,ebitda_lfy,ebitda_lfy_1,ebitda_ltm_margin,ebitda_lfy_2,ebitda_yoy_percent_chg_ltm,ebitda_yoy_percent_chg_fy1,ebitda_ntm,company_cash,company_debt,company_net_debt,capex_ltm,ebitda_multiple_entry,ebitda_multiple_ltm,leverage_multiple_entry,leverage_multiple_ltm,revenue_multiple_entry,revenue_multiple_ltm,valuation_lfy,valuation_lfy_1,valuation_lfy_2,valuation_yoy_percent_chg_ltm,valuation_yoy_percent_chg_fy2,company_description,investment_gp_assessment
export enum AggregateCompanyDataColumnKeys {
  CompanyName = "company_name",
  TapCompanyID = "tap_company_id",
  FundName = "fund_name",
  TapFundID = "tap_fund_id",
  TapInvestmentID = "tap_investment_id",
  ReportDate = "report_date",
  CompanyCurrency = "company_currency",
  FundOwnershipPercent = "fund_ownership_percent",
  EquityValue = "equity_value",
  EnterpriseValue = "enterprise_value",
  RevenueLTM = "revenue_ltm",
  RevenueLFY = "revenue_lfy",
  RevenueLFY1 = "revenue_lfy_1",
  RevenueLFY2 = "revenue_lfy_2",
  RevenueYOYPercentChgLTM = "revenue_yoy_percent_chg_ltm",
  RevenueYOYPercentChgFY1 = "revenue_yoy_percent_chg_fy1",
  RevenueNTM = "revenue_ntm",
  EBITDALTM = "ebitda_ltm",
  EBITDALFY = "ebitda_lfy",
  EBITDALFY1 = "ebitda_lfy_1",
  EBITDALTM_Margin = "ebitda_ltm_margin",
  EBITDALFY2 = "ebitda_lfy_2",
  EBITDAYOYPercentChgLTM = "ebitda_yoy_percent_chg_ltm",
  EBITDAYOYPercentChgFY1 = "ebitda_yoy_percent_chg_fy1",
  EBITDANTM = "ebitda_ntm",
  CompanyCash = "company_cash",
  CompanyDebt = "company_debt",
  CompanyNetDebt = "company_net_debt",
  CapexLTM = "capex_ltm",
  EBITDAMultipleEntry = "ebitda_multiple_entry",
  EBITDAMultipleLTM = "ebitda_multiple_ltm",
  LeverageMultipleEntry = "leverage_multiple_entry",
  LeverageMultipleLTM = "leverage_multiple_ltm",
  RevenueMultipleEntry = "revenue_multiple_entry",
  RevenueMultipleLTM = "revenue_multiple_ltm",
  ValuationLFY = "valuation_lfy",
  ValuationLFY1 = "valuation_lfy_1",
  ValuationLFY2 = "valuation_lfy_2",
  ValuationYOYPercentChgLTM = "valuation_yoy_percent_chg_ltm",
  ValuationYOYPercentChgFY2 = "valuation_yoy_percent_chg_fy2",
  CompanyDescription = "company_description",
  InvestmentGPAssessment = "investment_gp_assessment",
}

export const aggregateCompanyDataColumnLabels = {
  [AggregateCompanyDataColumnKeys.CompanyName]: "Company Name",
  [AggregateCompanyDataColumnKeys.TapCompanyID]: "Tap Company ID",
  [AggregateCompanyDataColumnKeys.FundName]: "Fund Name",
  [AggregateCompanyDataColumnKeys.TapFundID]: "Tap Fund ID",
  [AggregateCompanyDataColumnKeys.TapInvestmentID]: "Tap Investment ID",
  [AggregateCompanyDataColumnKeys.ReportDate]: "Report Date",
  [AggregateCompanyDataColumnKeys.CompanyCurrency]: "Company Currency",
  [AggregateCompanyDataColumnKeys.FundOwnershipPercent]:
    "Fund Ownership Percent",
  [AggregateCompanyDataColumnKeys.EquityValue]: "Equity Value",
  [AggregateCompanyDataColumnKeys.EnterpriseValue]: "Enterprise Value",
  [AggregateCompanyDataColumnKeys.RevenueLTM]: "Revenue LTM",
  [AggregateCompanyDataColumnKeys.RevenueLFY]: "Revenue LFY",
  [AggregateCompanyDataColumnKeys.RevenueLFY1]: "Revenue LFY 1",
  [AggregateCompanyDataColumnKeys.RevenueLFY2]: "Revenue LFY 2",
  [AggregateCompanyDataColumnKeys.RevenueYOYPercentChgLTM]:
    "Revenue YOY Percent Chg LTM",
  [AggregateCompanyDataColumnKeys.RevenueYOYPercentChgFY1]:
    "Revenue YOY Percent Chg FY1",
  [AggregateCompanyDataColumnKeys.RevenueNTM]: "Revenue NTM",
  [AggregateCompanyDataColumnKeys.EBITDALTM]: "EBITDA LTM",
  [AggregateCompanyDataColumnKeys.EBITDALFY]: "EBITDA LFY",
  [AggregateCompanyDataColumnKeys.EBITDALFY1]: "EBITDA LFY 1",
  [AggregateCompanyDataColumnKeys.EBITDALTM_Margin]: "EBITDA LTM Margin",
  [AggregateCompanyDataColumnKeys.EBITDALFY2]: "EBITDA LFY 2",
  [AggregateCompanyDataColumnKeys.EBITDAYOYPercentChgLTM]:
    "EBITDA YOY Percent Chg LTM",
  [AggregateCompanyDataColumnKeys.EBITDAYOYPercentChgFY1]:
    "EBITDA YOY Percent Chg FY1",
  [AggregateCompanyDataColumnKeys.EBITDANTM]: "EBITDA NTM",
  [AggregateCompanyDataColumnKeys.CompanyCash]: "Company Cash",
  [AggregateCompanyDataColumnKeys.CompanyDebt]: "Company Debt",
  [AggregateCompanyDataColumnKeys.CompanyNetDebt]: "Company Net Debt",
  [AggregateCompanyDataColumnKeys.CapexLTM]: "Capex LTM",
  [AggregateCompanyDataColumnKeys.EBITDAMultipleEntry]: "EBITDA Multiple Entry",
  [AggregateCompanyDataColumnKeys.EBITDAMultipleLTM]: "EBITDA Multiple LTM",
  [AggregateCompanyDataColumnKeys.LeverageMultipleEntry]:
    "Leverage Multiple Entry",
  [AggregateCompanyDataColumnKeys.LeverageMultipleLTM]: "Leverage Multiple LTM",
  [AggregateCompanyDataColumnKeys.RevenueMultipleEntry]:
    "Revenue Multiple Entry",
  [AggregateCompanyDataColumnKeys.RevenueMultipleLTM]: "Revenue Multiple LTM",
  [AggregateCompanyDataColumnKeys.ValuationLFY]: "Valuation LFY",
  [AggregateCompanyDataColumnKeys.ValuationLFY1]: "Valuation LFY 1",
  [AggregateCompanyDataColumnKeys.ValuationLFY2]: "Valuation LFY 2",
  [AggregateCompanyDataColumnKeys.ValuationYOYPercentChgLTM]:
    "Valuation YOY Percent Chg LTM",
  [AggregateCompanyDataColumnKeys.ValuationYOYPercentChgFY2]:
    "Valuation YOY Percent Chg FY2",
  [AggregateCompanyDataColumnKeys.CompanyDescription]: "Company Description",
  [AggregateCompanyDataColumnKeys.InvestmentGPAssessment]:
    "Investment GP Assessment",
};

export const aggregateCompanyDataColumns: IWorksheetColumn[] = [
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.CompanyName
      ],
    key: AggregateCompanyDataColumnKeys.CompanyName,
    width: WORKSHEET_COLUMN_WIDTH_LARGE,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.TapCompanyID
      ],
    key: AggregateCompanyDataColumnKeys.TapCompanyID,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[AggregateCompanyDataColumnKeys.FundName],
    key: AggregateCompanyDataColumnKeys.FundName,
    width: WORKSHEET_COLUMN_WIDTH_LARGE,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.TapFundID
      ],
    key: AggregateCompanyDataColumnKeys.TapFundID,
    width: WORKSHEET_COLUMN_WIDTH_LARGE,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.TapInvestmentID
      ],
    key: AggregateCompanyDataColumnKeys.TapInvestmentID,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.ReportDate
      ],
    key: AggregateCompanyDataColumnKeys.ReportDate,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.CompanyCurrency
      ],
    key: AggregateCompanyDataColumnKeys.CompanyCurrency,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.FundOwnershipPercent
      ],
    key: AggregateCompanyDataColumnKeys.FundOwnershipPercent,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EquityValue
      ],
    key: AggregateCompanyDataColumnKeys.EquityValue,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EnterpriseValue
      ],
    key: AggregateCompanyDataColumnKeys.EnterpriseValue,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueLTM
      ],
    key: AggregateCompanyDataColumnKeys.RevenueLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueLFY
      ],
    key: AggregateCompanyDataColumnKeys.RevenueLFY,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueLFY1
      ],
    key: AggregateCompanyDataColumnKeys.RevenueLFY1,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueLFY2
      ],
    key: AggregateCompanyDataColumnKeys.RevenueLFY2,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueYOYPercentChgLTM
      ],
    key: AggregateCompanyDataColumnKeys.RevenueYOYPercentChgLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueYOYPercentChgFY1
      ],
    key: AggregateCompanyDataColumnKeys.RevenueYOYPercentChgFY1,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueNTM
      ],
    key: AggregateCompanyDataColumnKeys.RevenueNTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDALTM
      ],
    key: AggregateCompanyDataColumnKeys.EBITDALTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDALFY
      ],
    key: AggregateCompanyDataColumnKeys.EBITDALFY,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDALFY1
      ],
    key: AggregateCompanyDataColumnKeys.EBITDALFY1,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDALTM_Margin
      ],
    key: AggregateCompanyDataColumnKeys.EBITDALTM_Margin,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDALFY2
      ],
    key: AggregateCompanyDataColumnKeys.EBITDALFY2,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDAYOYPercentChgLTM
      ],
    key: AggregateCompanyDataColumnKeys.EBITDAYOYPercentChgLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDAYOYPercentChgFY1
      ],
    key: AggregateCompanyDataColumnKeys.EBITDAYOYPercentChgFY1,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDANTM
      ],
    key: AggregateCompanyDataColumnKeys.EBITDANTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.CompanyCash
      ],
    key: AggregateCompanyDataColumnKeys.CompanyCash,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.CompanyDebt
      ],
    key: AggregateCompanyDataColumnKeys.CompanyDebt,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.CompanyNetDebt
      ],
    key: AggregateCompanyDataColumnKeys.CompanyNetDebt,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[AggregateCompanyDataColumnKeys.CapexLTM],
    key: AggregateCompanyDataColumnKeys.CapexLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDAMultipleEntry
      ],
    key: AggregateCompanyDataColumnKeys.EBITDAMultipleEntry,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.EBITDAMultipleLTM
      ],
    key: AggregateCompanyDataColumnKeys.EBITDAMultipleLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.LeverageMultipleEntry
      ],
    key: AggregateCompanyDataColumnKeys.LeverageMultipleEntry,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.LeverageMultipleLTM
      ],
    key: AggregateCompanyDataColumnKeys.LeverageMultipleLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueMultipleEntry
      ],
    key: AggregateCompanyDataColumnKeys.RevenueMultipleEntry,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.RevenueMultipleLTM
      ],
    key: AggregateCompanyDataColumnKeys.RevenueMultipleLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.ValuationLFY
      ],
    key: AggregateCompanyDataColumnKeys.ValuationLFY,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.ValuationLFY1
      ],
    key: AggregateCompanyDataColumnKeys.ValuationLFY1,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.ValuationLFY2
      ],
    key: AggregateCompanyDataColumnKeys.ValuationLFY2,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.ValuationYOYPercentChgLTM
      ],
    key: AggregateCompanyDataColumnKeys.ValuationYOYPercentChgLTM,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.ValuationYOYPercentChgFY2
      ],
    key: AggregateCompanyDataColumnKeys.ValuationYOYPercentChgFY2,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.CompanyDescription
      ],
    key: AggregateCompanyDataColumnKeys.CompanyDescription,
    width: WORKSHEET_COLUMN_WIDTH_EXTRA_LARGE,
  },
  {
    header:
      aggregateCompanyDataColumnLabels[
        AggregateCompanyDataColumnKeys.InvestmentGPAssessment
      ],
    key: AggregateCompanyDataColumnKeys.InvestmentGPAssessment,
    width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
  },
];
