import { cn } from "common/utils";
import styled from "styled-components";

import {
  FundsStats,
  SellerPerspectiveTxn,
  LPLedTransaction,
} from "../../domain/models/Transaction";

const StyledSummary = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  tr {
    width: 100%;
    border: none;

    td {
      border: none !important;
    }
  }

  tr:first-child td {
    padding-bottom: 8px;
  }

  tr:last-child td {
    padding-top: 8px;
  }

  tr:not(:first-child):not(:last-child) td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .summary_value {
    font-weight: 500;
    &:after {
      display: "inline-block";
      content: " ";
    }
  }
`;

export const TransactionSummaryCell = ({
  transaction,
  className,
}: {
  transaction: LPLedTransaction;
  className?: string;
}) => {
  const startVintage =
    transaction.stats instanceof FundsStats
      ? transaction.stats.vintageRange.start
      : undefined;
  const endVintage =
    transaction.stats instanceof FundsStats
      ? transaction.stats.vintageRange.end
      : undefined;

  const sameVintage = startVintage === endVintage;
  const vintageRange = sameVintage
    ? startVintage
    : `${startVintage}-${endVintage.toString().slice(-2)}`;

  return (
    <StyledSummary className={cn("inline-table", className)}>
      <tr className="w-full">
        <td>
          <span className="summary_value">{transaction.fundsCount}</span>
          {transaction.fundsCount === 1 ? "Asset" : "Assets"}
        </td>
        {transaction instanceof SellerPerspectiveTxn && (
          <td>
            <span className="summary_value">{transaction.bidCount}</span>
            {transaction.bidCount === 1 ? "Bid" : "Bids"}
          </td>
        )}
      </tr>
      <tr>
        {transaction.stats instanceof FundsStats && (
          <td>
            <span className="summary_value">{vintageRange}</span>
            {sameVintage ? "Vintage" : "Vintages"}
          </td>
        )}
        {transaction instanceof SellerPerspectiveTxn && (
          <td>
            <span className="summary_value">{transaction.inviteeCount}</span>
            {transaction.inviteeCount === 1 ? "Invitee" : "Invitees"}
          </td>
        )}
      </tr>
      <tr>
        {transaction instanceof SellerPerspectiveTxn && (
          <td>
            <span className="summary_value">
              {transaction.participantCount}
            </span>
            {transaction.participantCount === 1
              ? "Participant"
              : "Participants"}
          </td>
        )}
      </tr>
    </StyledSummary>
  );
};
