import { IPriceHistory } from "experiences/funds/domain/models/FundPriceHistory";
import { TVPI_CHART } from "../viewFund/PriceHistoryChart";

export const VIEWPOINT_PARTNERS_PRICE_NAV_ONLY: IPriceHistory = [
  {
    referenceDate: "2017-03-31",
    price: 80,
  },
  {
    referenceDate: "2017-04-07",
    price: 80,
  },
  {
    referenceDate: "2017-04-14",
    price: 81,
  },
  {
    referenceDate: "2017-04-21",
    price: 81,
  },
  {
    referenceDate: "2017-04-28",
    price: 81,
  },
  {
    referenceDate: "2017-05-05",
    price: 82,
  },
  {
    referenceDate: "2017-05-12",
    price: 82,
  },
  {
    referenceDate: "2017-05-19",
    price: 82,
  },
  {
    referenceDate: "2017-05-26",
    price: 82,
  },
  {
    referenceDate: "2017-06-02",
    price: 82,
  },
  {
    referenceDate: "2017-06-09",
    price: 82,
  },
  {
    referenceDate: "2017-06-16",
    price: 83,
  },
  {
    referenceDate: "2017-06-23",
    price: 83,
  },
  {
    referenceDate: "2017-06-30",
    price: 82,
  },
  {
    referenceDate: "2017-07-07",
    price: 82,
  },
  {
    referenceDate: "2017-07-14",
    price: 82,
  },
  {
    referenceDate: "2017-07-21",
    price: 81,
  },
  {
    referenceDate: "2017-07-28",
    price: 81,
  },
  {
    referenceDate: "2017-08-04",
    price: 82,
  },
  {
    referenceDate: "2017-08-11",
    price: 82,
  },
  {
    referenceDate: "2017-08-18",
    price: 81,
  },
  {
    referenceDate: "2017-08-25",
    price: 81,
  },
  {
    referenceDate: "2017-09-01",
    price: 80,
  },
  {
    referenceDate: "2017-09-08",
    price: 80,
  },
  {
    referenceDate: "2017-09-15",
    price: 80,
  },
  {
    referenceDate: "2017-09-22",
    price: 81,
  },
  {
    referenceDate: "2017-09-29",
    price: 81,
  },
  {
    referenceDate: "2017-10-06",
    price: 82,
  },
  {
    referenceDate: "2017-10-13",
    price: 82,
  },
  {
    referenceDate: "2017-10-20",
    price: 81,
  },
  {
    referenceDate: "2017-10-27",
    price: 82,
  },
  {
    referenceDate: "2017-11-03",
    price: 83,
  },
  {
    referenceDate: "2017-11-10",
    price: 82,
  },
  {
    referenceDate: "2017-11-17",
    price: 81,
  },
  {
    referenceDate: "2017-11-24",
    price: 82,
  },
  {
    referenceDate: "2017-12-01",
    price: 82,
  },
  {
    referenceDate: "2017-12-08",
    price: 82,
  },
  {
    referenceDate: "2017-12-15",
    price: 81,
  },
  {
    referenceDate: "2017-12-22",
    price: 82,
  },
  {
    referenceDate: "2017-12-29",
    price: 81,
  },
  {
    referenceDate: "2018-01-05",
    price: 81,
  },
  {
    referenceDate: "2018-01-12",
    price: 82,
  },
  {
    referenceDate: "2018-01-19",
    price: 82,
  },
  {
    referenceDate: "2018-01-26",
    price: 81,
  },
  {
    referenceDate: "2018-02-02",
    price: 81,
  },
  {
    referenceDate: "2018-02-09",
    price: 82,
  },
  {
    referenceDate: "2018-02-16",
    price: 84,
  },
  {
    referenceDate: "2018-02-23",
    price: 84,
  },
  {
    referenceDate: "2018-03-02",
    price: 84,
  },
  {
    referenceDate: "2018-03-09",
    price: 84,
  },
  {
    referenceDate: "2018-03-16",
    price: 85,
  },
  {
    referenceDate: "2018-03-23",
    price: 84,
  },
  {
    referenceDate: "2018-03-30",
    price: 83,
  },
  {
    referenceDate: "2018-04-06",
    price: 83,
  },
  {
    referenceDate: "2018-04-13",
    price: 83,
  },
  {
    referenceDate: "2018-04-20",
    price: 81,
  },
  {
    referenceDate: "2018-04-27",
    price: 81,
  },
  {
    referenceDate: "2018-05-04",
    price: 82,
  },
  {
    referenceDate: "2018-05-11",
    price: 81,
  },
  {
    referenceDate: "2018-05-18",
    price: 81,
  },
  {
    referenceDate: "2018-05-25",
    price: 81,
  },
  {
    referenceDate: "2018-06-01",
    price: 81,
  },
  {
    referenceDate: "2018-06-08",
    price: 81,
  },
  {
    referenceDate: "2018-06-15",
    price: 81,
  },
  {
    referenceDate: "2018-06-22",
    price: 81,
  },
  {
    referenceDate: "2018-06-29",
    price: 81,
  },
  {
    referenceDate: "2018-07-06",
    price: 82,
  },
  {
    referenceDate: "2018-07-13",
    price: 82,
  },
  {
    referenceDate: "2018-07-20",
    price: 82,
  },
  {
    referenceDate: "2018-07-27",
    price: 83,
  },
  {
    referenceDate: "2018-08-03",
    price: 84,
  },
  {
    referenceDate: "2018-08-10",
    price: 83,
  },
  {
    referenceDate: "2018-08-17",
    price: 83,
  },
  {
    referenceDate: "2018-08-24",
    price: 82,
  },
  {
    referenceDate: "2018-08-31",
    price: 83,
  },
  {
    referenceDate: "2018-09-07",
    price: 82,
  },
  {
    referenceDate: "2018-09-14",
    price: 81,
  },
  {
    referenceDate: "2018-09-21",
    price: 80,
  },
  {
    referenceDate: "2018-09-28",
    price: 79,
  },
  {
    referenceDate: "2018-10-05",
    price: 79,
  },
  {
    referenceDate: "2018-10-12",
    price: 80,
  },
  {
    referenceDate: "2018-10-19",
    price: 79,
  },
  {
    referenceDate: "2018-10-26",
    price: 80,
  },
  {
    referenceDate: "2018-11-02",
    price: 79,
  },
  {
    referenceDate: "2018-11-09",
    price: 78,
  },
  {
    referenceDate: "2018-11-16",
    price: 78,
  },
  {
    referenceDate: "2018-11-23",
    price: 77,
  },
  {
    referenceDate: "2018-11-30",
    price: 78,
  },
  {
    referenceDate: "2018-12-07",
    price: 77,
  },
  {
    referenceDate: "2018-12-14",
    price: 76,
  },
  {
    referenceDate: "2018-12-21",
    price: 76,
  },
  {
    referenceDate: "2018-12-28",
    price: 76,
  },
  {
    referenceDate: "2019-01-04",
    price: 76,
  },
  {
    referenceDate: "2019-01-11",
    price: 74,
  },
  {
    referenceDate: "2019-01-18",
    price: 73,
  },
  {
    referenceDate: "2019-01-25",
    price: 73,
  },
  {
    referenceDate: "2019-02-01",
    price: 72,
  },
  {
    referenceDate: "2019-02-08",
    price: 72,
  },
  {
    referenceDate: "2019-02-15",
    price: 71,
  },
  {
    referenceDate: "2019-02-22",
    price: 71,
  },
  {
    referenceDate: "2019-03-01",
    price: 70,
  },
  {
    referenceDate: "2019-03-08",
    price: 70,
  },
  {
    referenceDate: "2019-03-15",
    price: 70,
  },
  {
    referenceDate: "2019-03-22",
    price: 71,
  },
  {
    referenceDate: "2019-03-29",
    price: 70,
  },
  {
    referenceDate: "2019-04-05",
    price: 69,
  },
  {
    referenceDate: "2019-04-12",
    price: 69,
  },
  {
    referenceDate: "2019-04-19",
    price: 69,
  },
  {
    referenceDate: "2019-04-26",
    price: 68,
  },
  {
    referenceDate: "2019-05-03",
    price: 68,
  },
  {
    referenceDate: "2019-05-10",
    price: 69,
  },
  {
    referenceDate: "2019-05-17",
    price: 69,
  },
  {
    referenceDate: "2019-05-24",
    price: 69,
  },
  {
    referenceDate: "2019-05-31",
    price: 70,
  },
  {
    referenceDate: "2019-06-07",
    price: 70,
  },
  {
    referenceDate: "2019-06-14",
    price: 71,
  },
  {
    referenceDate: "2019-06-21",
    price: 72,
  },
  {
    referenceDate: "2019-06-28",
    price: 72,
  },
  {
    referenceDate: "2019-07-05",
    price: 72,
  },
  {
    referenceDate: "2019-07-12",
    price: 72,
  },
  {
    referenceDate: "2019-07-19",
    price: 71,
  },
  {
    referenceDate: "2019-07-26",
    price: 71,
  },
  {
    referenceDate: "2019-08-02",
    price: 72,
  },
  {
    referenceDate: "2019-08-09",
    price: 71,
  },
  {
    referenceDate: "2019-08-16",
    price: 71,
  },
  {
    referenceDate: "2019-08-23",
    price: 71,
  },
  {
    referenceDate: "2019-08-30",
    price: 72,
  },
  {
    referenceDate: "2019-09-06",
    price: 71,
  },
  {
    referenceDate: "2019-09-13",
    price: 73,
  },
  {
    referenceDate: "2019-09-20",
    price: 74,
  },
  {
    referenceDate: "2019-09-27",
    price: 74,
  },
  {
    referenceDate: "2019-10-04",
    price: 74,
  },
  {
    referenceDate: "2019-10-11",
    price: 74,
  },
  {
    referenceDate: "2019-10-18",
    price: 73,
  },
  {
    referenceDate: "2019-10-25",
    price: 74,
  },
  {
    referenceDate: "2019-11-01",
    price: 74,
  },
  {
    referenceDate: "2019-11-08",
    price: 73,
  },
  {
    referenceDate: "2019-11-15",
    price: 73,
  },
  {
    referenceDate: "2019-11-22",
    price: 72,
  },
  {
    referenceDate: "2019-11-29",
    price: 72,
  },
  {
    referenceDate: "2019-12-06",
    price: 72,
  },
  {
    referenceDate: "2019-12-13",
    price: 72,
  },
  {
    referenceDate: "2019-12-20",
    price: 71,
  },
  {
    referenceDate: "2019-12-27",
    price: 72,
  },
  {
    referenceDate: "2020-01-03",
    price: 73,
  },
  {
    referenceDate: "2020-01-10",
    price: 72,
  },
  {
    referenceDate: "2020-01-17",
    price: 73,
  },
  {
    referenceDate: "2020-01-24",
    price: 74,
  },
  {
    referenceDate: "2020-01-31",
    price: 74,
  },
  {
    referenceDate: "2020-02-07",
    price: 76,
  },
  {
    referenceDate: "2020-02-14",
    price: 75,
  },
  {
    referenceDate: "2020-02-21",
    price: 75,
  },
  {
    referenceDate: "2020-02-28",
    price: 74,
  },
  {
    referenceDate: "2020-03-06",
    price: 76,
  },
  {
    referenceDate: "2020-03-13",
    price: 73,
  },
  {
    referenceDate: "2020-03-20",
    price: 72,
  },
  {
    referenceDate: "2020-03-27",
    price: 71,
  },
  {
    referenceDate: "2020-04-03",
    price: 69,
  },
  {
    referenceDate: "2020-04-10",
    price: 67,
  },
  {
    referenceDate: "2020-04-17",
    price: 66,
  },
  {
    referenceDate: "2020-04-24",
    price: 65,
  },
  {
    referenceDate: "2020-05-01",
    price: 63,
  },
  {
    referenceDate: "2020-05-08",
    price: 61,
  },
  {
    referenceDate: "2020-05-15",
    price: 60,
  },
  {
    referenceDate: "2020-05-22",
    price: 59,
  },
  {
    referenceDate: "2020-05-29",
    price: 59,
  },
  {
    referenceDate: "2020-06-05",
    price: 59,
  },
  {
    referenceDate: "2020-06-12",
    price: 60,
  },
  {
    referenceDate: "2020-06-19",
    price: 59,
  },
  {
    referenceDate: "2020-06-26",
    price: 60,
  },
  {
    referenceDate: "2020-07-03",
    price: 60,
  },
  {
    referenceDate: "2020-07-10",
    price: 59,
  },
  {
    referenceDate: "2020-07-17",
    price: 60,
  },
  {
    referenceDate: "2020-07-24",
    price: 61,
  },
  {
    referenceDate: "2020-07-31",
    price: 61,
  },
  {
    referenceDate: "2020-08-07",
    price: 61,
  },
  {
    referenceDate: "2020-08-14",
    price: 63,
  },
  {
    referenceDate: "2020-08-21",
    price: 62,
  },
  {
    referenceDate: "2020-08-28",
    price: 63,
  },
  {
    referenceDate: "2020-09-04",
    price: 63,
  },
  {
    referenceDate: "2020-09-11",
    price: 63,
  },
  {
    referenceDate: "2020-09-18",
    price: 63,
  },
  {
    referenceDate: "2020-09-25",
    price: 62,
  },
  {
    referenceDate: "2020-10-02",
    price: 63,
  },
  {
    referenceDate: "2020-10-09",
    price: 62,
  },
  {
    referenceDate: "2020-10-16",
    price: 62,
  },
  {
    referenceDate: "2020-10-23",
    price: 61,
  },
  {
    referenceDate: "2020-10-30",
    price: 61,
  },
  {
    referenceDate: "2020-11-06",
    price: 62,
  },
  {
    referenceDate: "2020-11-13",
    price: 62,
  },
  {
    referenceDate: "2020-11-20",
    price: 61,
  },
  {
    referenceDate: "2020-11-27",
    price: 60,
  },
  {
    referenceDate: "2020-12-04",
    price: 62,
  },
  {
    referenceDate: "2020-12-11",
    price: 61,
  },
  {
    referenceDate: "2020-12-18",
    price: 61,
  },
  {
    referenceDate: "2020-12-25",
    price: 63,
  },
  {
    referenceDate: "2021-01-01",
    price: 62,
  },
  {
    referenceDate: "2021-01-08",
    price: 63,
  },
  {
    referenceDate: "2021-01-15",
    price: 63,
  },
  {
    referenceDate: "2021-01-22",
    price: 64,
  },
  {
    referenceDate: "2021-01-29",
    price: 65,
  },
  {
    referenceDate: "2021-02-05",
    price: 67,
  },
  {
    referenceDate: "2021-02-12",
    price: 69,
  },
  {
    referenceDate: "2021-02-19",
    price: 69,
  },
  {
    referenceDate: "2021-02-26",
    price: 69,
  },
  {
    referenceDate: "2021-03-05",
    price: 69,
  },
  {
    referenceDate: "2021-03-12",
    price: 70,
  },
  {
    referenceDate: "2021-03-19",
    price: 71,
  },
  {
    referenceDate: "2021-03-26",
    price: 73,
  },
  {
    referenceDate: "2021-04-02",
    price: 74,
  },
  {
    referenceDate: "2021-04-09",
    price: 75,
  },
  {
    referenceDate: "2021-04-16",
    price: 74,
  },
  {
    referenceDate: "2021-04-23",
    price: 75,
  },
  {
    referenceDate: "2021-04-30",
    price: 76,
  },
  {
    referenceDate: "2021-05-07",
    price: 77,
  },
  {
    referenceDate: "2021-05-14",
    price: 78,
  },
  {
    referenceDate: "2021-05-21",
    price: 79,
  },
  {
    referenceDate: "2021-05-28",
    price: 80,
  },
  {
    referenceDate: "2021-06-04",
    price: 82,
  },
  {
    referenceDate: "2021-06-11",
    price: 85,
  },
  {
    referenceDate: "2021-06-18",
    price: 87,
  },
  {
    referenceDate: "2021-06-25",
    price: 88,
  },
  {
    referenceDate: "2021-07-02",
    price: 89,
  },
  {
    referenceDate: "2021-07-09",
    price: 89,
  },
  {
    referenceDate: "2021-07-16",
    price: 89,
  },
  {
    referenceDate: "2021-07-23",
    price: 89,
  },
  {
    referenceDate: "2021-07-30",
    price: 89,
  },
  {
    referenceDate: "2021-08-06",
    price: 89,
  },
  {
    referenceDate: "2021-08-13",
    price: 89,
  },
  {
    referenceDate: "2021-08-20",
    price: 91,
  },
  {
    referenceDate: "2021-08-27",
    price: 93,
  },
  {
    referenceDate: "2021-09-03",
    price: 93,
  },
  {
    referenceDate: "2021-09-10",
    price: 93,
  },
  {
    referenceDate: "2021-09-17",
    price: 93,
  },
  {
    referenceDate: "2021-09-24",
    price: 92,
  },
  {
    referenceDate: "2021-10-01",
    price: 91,
  },
  {
    referenceDate: "2021-10-08",
    price: 92,
  },
  {
    referenceDate: "2021-10-15",
    price: 92,
  },
  {
    referenceDate: "2021-10-22",
    price: 92,
  },
  {
    referenceDate: "2021-10-29",
    price: 92,
  },
  {
    referenceDate: "2021-11-05",
    price: 92,
  },
  {
    referenceDate: "2021-11-12",
    price: 93,
  },
  {
    referenceDate: "2021-11-19",
    price: 93,
  },
  {
    referenceDate: "2021-11-26",
    price: 93,
  },
  {
    referenceDate: "2021-12-03",
    price: 94,
  },
  {
    referenceDate: "2021-12-10",
    price: 95,
  },
  {
    referenceDate: "2021-12-17",
    price: 96,
  },
  {
    referenceDate: "2021-12-24",
    price: 98,
  },
  {
    referenceDate: "2021-12-31",
    price: 98,
  },
  {
    referenceDate: "2022-01-07",
    price: 98,
  },
  {
    referenceDate: "2022-01-14",
    price: 99,
  },
  {
    referenceDate: "2022-01-21",
    price: 99,
  },
  {
    referenceDate: "2022-01-28",
    price: 99,
  },
  {
    referenceDate: "2022-02-04",
    price: 100,
  },
  {
    referenceDate: "2022-02-11",
    price: 100,
  },
  {
    referenceDate: "2022-02-18",
    price: 99,
  },
  {
    referenceDate: "2022-02-25",
    price: 100,
  },
  {
    referenceDate: "2022-03-04",
    price: 101,
  },
  {
    referenceDate: "2022-03-11",
    price: 100,
  },
  {
    referenceDate: "2022-03-18",
    price: 100,
  },
  {
    referenceDate: "2022-03-25",
    price: 98,
  },
  {
    referenceDate: "2022-04-01",
    price: 100,
  },
  {
    referenceDate: "2022-04-08",
    price: 103,
  },
  {
    referenceDate: "2022-04-15",
    price: 103,
  },
  {
    referenceDate: "2022-04-22",
    price: 101,
  },
  {
    referenceDate: "2022-04-29",
    price: 100,
  },
  {
    referenceDate: "2022-05-06",
    price: 98,
  },
  {
    referenceDate: "2022-05-13",
    price: 97,
  },
  {
    referenceDate: "2022-05-20",
    price: 97,
  },
  {
    referenceDate: "2022-05-27",
    price: 97,
  },
  {
    referenceDate: "2022-06-03",
    price: 96,
  },
  {
    referenceDate: "2022-06-10",
    price: 95,
  },
  {
    referenceDate: "2022-06-17",
    price: 95,
  },
  {
    referenceDate: "2022-06-24",
    price: 95,
  },
  {
    referenceDate: "2022-07-01",
    price: 92,
  },
  {
    referenceDate: "2022-07-08",
    price: 93,
  },
  {
    referenceDate: "2022-07-15",
    price: 91,
  },
  {
    referenceDate: "2022-07-22",
    price: 90,
  },
  {
    referenceDate: "2022-07-29",
    price: 88,
  },
  {
    referenceDate: "2022-08-05",
    price: 88,
  },
  {
    referenceDate: "2022-08-12",
    price: 89,
  },
  {
    referenceDate: "2022-08-19",
    price: 89,
  },
  {
    referenceDate: "2022-08-26",
    price: 88,
  },
  {
    referenceDate: "2022-09-02",
    price: 87,
  },
  {
    referenceDate: "2022-09-09",
    price: 86,
  },
  {
    referenceDate: "2022-09-16",
    price: 85,
  },
  {
    referenceDate: "2022-09-23",
    price: 84,
  },
  {
    referenceDate: "2022-09-30",
    price: 85,
  },
  {
    referenceDate: "2022-10-07",
    price: 84,
  },
  {
    referenceDate: "2022-10-14",
    price: 85,
  },
  {
    referenceDate: "2022-10-21",
    price: 84,
  },
  {
    referenceDate: "2022-10-28",
    price: 85,
  },
  {
    referenceDate: "2022-11-04",
    price: 85,
  },
  {
    referenceDate: "2022-11-11",
    price: 83,
  },
  {
    referenceDate: "2022-11-18",
    price: 84,
  },
  {
    referenceDate: "2022-11-25",
    price: 83,
  },
  {
    referenceDate: "2022-12-02",
    price: 82,
  },
  {
    referenceDate: "2022-12-09",
    price: 83,
  },
  {
    referenceDate: "2022-12-16",
    price: 83,
  },
  {
    referenceDate: "2022-12-23",
    price: 86,
  },
  {
    referenceDate: "2022-12-30",
    price: 86,
  },
  {
    referenceDate: "2023-01-06",
    price: 87,
  },
  {
    referenceDate: "2023-01-13",
    price: 88,
  },
  {
    referenceDate: "2023-01-20",
    price: 87,
  },
  {
    referenceDate: "2023-01-27",
    price: 87,
  },
  {
    referenceDate: "2023-02-03",
    price: 88,
  },
  {
    referenceDate: "2023-02-10",
    price: 88,
  },
  {
    referenceDate: "2023-02-17",
    price: 87,
  },
  {
    referenceDate: "2023-02-24",
    price: 87,
  },
  {
    referenceDate: "2023-03-03",
    price: 87,
  },
  {
    referenceDate: "2023-03-10",
    price: 87,
  },
  {
    referenceDate: "2023-03-17",
    price: 88,
  },
  {
    referenceDate: "2023-03-24",
    price: 89,
  },
  {
    referenceDate: "2023-03-31",
    price: 88,
  },
  {
    referenceDate: "2023-04-07",
    price: 89,
  },
  {
    referenceDate: "2023-04-14",
    price: 89,
  },
  {
    referenceDate: "2023-04-21",
    price: 89,
  },
  {
    referenceDate: "2023-04-28",
    price: 88,
  },
  {
    referenceDate: "2023-05-05",
    price: 88,
  },
  {
    referenceDate: "2023-05-12",
    price: 90,
  },
  {
    referenceDate: "2023-05-19",
    price: 90,
  },
  {
    referenceDate: "2023-05-26",
    price: 88,
  },
  {
    referenceDate: "2023-06-02",
    price: 90,
  },
  {
    referenceDate: "2023-06-09",
    price: 89,
  },
  {
    referenceDate: "2023-06-16",
    price: 90,
  },
  {
    referenceDate: "2023-06-23",
    price: 89,
  },
  {
    referenceDate: "2023-06-30",
    price: 89,
  },
  {
    referenceDate: "2023-07-07",
    price: 89,
  },
  {
    referenceDate: "2023-07-14",
    price: 89,
  },
  {
    referenceDate: "2023-07-21",
    price: 88,
  },
  {
    referenceDate: "2023-07-28",
    price: 88,
  },
  {
    referenceDate: "2023-08-04",
    price: 88,
  },
  {
    referenceDate: "2023-08-11",
    price: 89,
  },
  {
    referenceDate: "2023-08-18",
    price: 90,
  },
  {
    referenceDate: "2023-08-25",
    price: 92,
  },
  {
    referenceDate: "2023-09-01",
    price: 92,
  },
  {
    referenceDate: "2023-09-08",
    price: 91,
  },
  {
    referenceDate: "2023-09-15",
    price: 92,
  },
  {
    referenceDate: "2023-09-22",
    price: 93,
  },
  {
    referenceDate: "2023-09-29",
    price: 94,
  },
  {
    referenceDate: "2023-10-06",
    price: 94,
  },
  {
    referenceDate: "2023-10-13",
    price: 95,
  },
  {
    referenceDate: "2023-10-20",
    price: 94,
  },
  {
    referenceDate: "2023-10-27",
    price: 94,
  },
  {
    referenceDate: "2023-11-03",
    price: 94,
  },
  {
    referenceDate: "2023-11-10",
    price: 94,
  },
  {
    referenceDate: "2023-11-17",
    price: 94,
  },
  {
    referenceDate: "2023-11-24",
    price: 95,
  },
  {
    referenceDate: "2023-12-01",
    price: 95,
  },
  {
    referenceDate: "2023-12-08",
    price: 94,
  },
  {
    referenceDate: "2023-12-15",
    price: 94,
  },
  {
    referenceDate: "2023-12-22",
    price: 93,
  },
  {
    referenceDate: "2023-12-29",
    price: 93,
  },
];

export const VIEWPOINT_PARTNERS_PRICE = [
  {
    date: "2017-03-31",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 5000000,
    marketNav: 4000000,
    percentOfNav: 80,
  },
  {
    date: "2017-04-07",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 5000000,
    marketNav: 4004017,
    percentOfNav: 80,
  },
  {
    date: "2017-04-14",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 5000000,
    marketNav: 4040670,
    percentOfNav: 81,
  },
  {
    date: "2017-04-21",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 5000000,
    marketNav: 4048722,
    percentOfNav: 81,
  },
  {
    date: "2017-04-28",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 5000000,
    marketNav: 4048784,
    percentOfNav: 81,
  },
  {
    date: "2017-05-05",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 5000000,
    marketNav: 4092850,
    percentOfNav: 82,
  },
  {
    date: "2017-05-12",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 5000000,
    marketNav: 4094620,
    percentOfNav: 82,
  },
  {
    date: "2017-05-19",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 5000000,
    marketNav: 4086424,
    percentOfNav: 82,
  },
  {
    date: "2017-05-26",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 5000000,
    marketNav: 4120752,
    percentOfNav: 82,
  },
  {
    date: "2017-06-02",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 5000000,
    marketNav: 4122828,
    percentOfNav: 82,
  },
  {
    date: "2017-06-09",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 5000000,
    marketNav: 4123633,
    percentOfNav: 82,
  },
  {
    date: "2017-06-16",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 5000000,
    marketNav: 4134699,
    percentOfNav: 83,
  },
  {
    date: "2017-06-23",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 5000000,
    marketNav: 4162749,
    percentOfNav: 83,
  },
  {
    date: "2017-06-30",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 33000000,
    marketNav: 27176003,
    percentOfNav: 82,
  },
  {
    date: "2017-07-07",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 33000000,
    marketNav: 27167927,
    percentOfNav: 82,
  },
  {
    date: "2017-07-14",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 33000000,
    marketNav: 26969711,
    percentOfNav: 82,
  },
  {
    date: "2017-07-21",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 33000000,
    marketNav: 26716565,
    percentOfNav: 81,
  },
  {
    date: "2017-07-28",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 33000000,
    marketNav: 26881437,
    percentOfNav: 81,
  },
  {
    date: "2017-08-04",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 33000000,
    marketNav: 27151533,
    percentOfNav: 82,
  },
  {
    date: "2017-08-11",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 33000000,
    marketNav: 26990372,
    percentOfNav: 82,
  },
  {
    date: "2017-08-18",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 33000000,
    marketNav: 26867294,
    percentOfNav: 81,
  },
  {
    date: "2017-08-25",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 33000000,
    marketNav: 26636661,
    percentOfNav: 81,
  },
  {
    date: "2017-09-01",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 33000000,
    marketNav: 26337332,
    percentOfNav: 80,
  },
  {
    date: "2017-09-08",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 33000000,
    marketNav: 26387089,
    percentOfNav: 80,
  },
  {
    date: "2017-09-15",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 33000000,
    marketNav: 26298723,
    percentOfNav: 80,
  },
  {
    date: "2017-09-22",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 33000000,
    marketNav: 26741032,
    percentOfNav: 81,
  },
  {
    date: "2017-09-29",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 33000000,
    marketNav: 26583320,
    percentOfNav: 81,
  },
  {
    date: "2017-10-06",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 44896441,
    percentOfNav: 82,
  },
  {
    date: "2017-10-13",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 44869125,
    percentOfNav: 82,
  },
  {
    date: "2017-10-20",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 55000000,
    marketNav: 44310459,
    percentOfNav: 81,
  },
  {
    date: "2017-10-27",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 44833721,
    percentOfNav: 82,
  },
  {
    date: "2017-11-03",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 55000000,
    marketNav: 45583872,
    percentOfNav: 83,
  },
  {
    date: "2017-11-10",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 45206930,
    percentOfNav: 82,
  },
  {
    date: "2017-11-17",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 55000000,
    marketNav: 44750154,
    percentOfNav: 81,
  },
  {
    date: "2017-11-24",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 45127211,
    percentOfNav: 82,
  },
  {
    date: "2017-12-01",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 44841268,
    percentOfNav: 82,
  },
  {
    date: "2017-12-08",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 45197005,
    percentOfNav: 82,
  },
  {
    date: "2017-12-15",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 55000000,
    marketNav: 44809819,
    percentOfNav: 81,
  },
  {
    date: "2017-12-22",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 55000000,
    marketNav: 44932838,
    percentOfNav: 82,
  },
  {
    date: "2017-12-29",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 55000000,
    marketNav: 44704325,
    percentOfNav: 81,
  },
  {
    date: "2018-01-05",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 65000000,
    marketNav: 52608978,
    percentOfNav: 81,
  },
  {
    date: "2018-01-12",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 65000000,
    marketNav: 53272348,
    percentOfNav: 82,
  },
  {
    date: "2018-01-19",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 65000000,
    marketNav: 53593758,
    percentOfNav: 82,
  },
  {
    date: "2018-01-26",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 65000000,
    marketNav: 52715761,
    percentOfNav: 81,
  },
  {
    date: "2018-02-02",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 65000000,
    marketNav: 52633994,
    percentOfNav: 81,
  },
  {
    date: "2018-02-09",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 65000000,
    marketNav: 53602402,
    percentOfNav: 82,
  },
  {
    date: "2018-02-16",
    tvpi: 1,
    marketTvpi: 0.84,
    nav: 65000000,
    marketNav: 54282620,
    percentOfNav: 84,
  },
  {
    date: "2018-02-23",
    tvpi: 1,
    marketTvpi: 0.84,
    nav: 65000000,
    marketNav: 54659860,
    percentOfNav: 84,
  },
  {
    date: "2018-03-02",
    tvpi: 1,
    marketTvpi: 0.84,
    nav: 65000000,
    marketNav: 54871215,
    percentOfNav: 84,
  },
  {
    date: "2018-03-09",
    tvpi: 1,
    marketTvpi: 0.84,
    nav: 65000000,
    marketNav: 54857177,
    percentOfNav: 84,
  },
  {
    date: "2018-03-16",
    tvpi: 1,
    marketTvpi: 0.85,
    nav: 65000000,
    marketNav: 55201750,
    percentOfNav: 85,
  },
  {
    date: "2018-03-23",
    tvpi: 1,
    marketTvpi: 0.84,
    nav: 65000000,
    marketNav: 54872543,
    percentOfNav: 84,
  },
  {
    date: "2018-03-30",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 65000000,
    marketNav: 54034929,
    percentOfNav: 83,
  },
  {
    date: "2018-04-06",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 80000000,
    marketNav: 66157765,
    percentOfNav: 83,
  },
  {
    date: "2018-04-13",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 80000000,
    marketNav: 66187867,
    percentOfNav: 83,
  },
  {
    date: "2018-04-20",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 65085160,
    percentOfNav: 81,
  },
  {
    date: "2018-04-27",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 64974501,
    percentOfNav: 81,
  },
  {
    date: "2018-05-04",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 80000000,
    marketNav: 65857787,
    percentOfNav: 82,
  },
  {
    date: "2018-05-11",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 64815537,
    percentOfNav: 81,
  },
  {
    date: "2018-05-18",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 65140039,
    percentOfNav: 81,
  },
  {
    date: "2018-05-25",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 65192638,
    percentOfNav: 81,
  },
  {
    date: "2018-06-01",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 65059333,
    percentOfNav: 81,
  },
  {
    date: "2018-06-08",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 64480927,
    percentOfNav: 81,
  },
  {
    date: "2018-06-15",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 65151004,
    percentOfNav: 81,
  },
  {
    date: "2018-06-22",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 64743534,
    percentOfNav: 81,
  },
  {
    date: "2018-06-29",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 80000000,
    marketNav: 64652055,
    percentOfNav: 81,
  },
  {
    date: "2018-07-06",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 90000000,
    marketNav: 73798105,
    percentOfNav: 82,
  },
  {
    date: "2018-07-13",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 90000000,
    marketNav: 73820245,
    percentOfNav: 82,
  },
  {
    date: "2018-07-20",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 90000000,
    marketNav: 74202376,
    percentOfNav: 82,
  },
  {
    date: "2018-07-27",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 90000000,
    marketNav: 74552600,
    percentOfNav: 83,
  },
  {
    date: "2018-08-03",
    tvpi: 1,
    marketTvpi: 0.84,
    nav: 90000000,
    marketNav: 75418745,
    percentOfNav: 84,
  },
  {
    date: "2018-08-10",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 90000000,
    marketNav: 74567774,
    percentOfNav: 83,
  },
  {
    date: "2018-08-17",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 90000000,
    marketNav: 75006955,
    percentOfNav: 83,
  },
  {
    date: "2018-08-24",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 90000000,
    marketNav: 73981136,
    percentOfNav: 82,
  },
  {
    date: "2018-08-31",
    tvpi: 1,
    marketTvpi: 0.83,
    nav: 90000000,
    marketNav: 74262984,
    percentOfNav: 83,
  },
  {
    date: "2018-09-07",
    tvpi: 1,
    marketTvpi: 0.82,
    nav: 90000000,
    marketNav: 73581145,
    percentOfNav: 82,
  },
  {
    date: "2018-09-14",
    tvpi: 1,
    marketTvpi: 0.81,
    nav: 90000000,
    marketNav: 72785257,
    percentOfNav: 81,
  },
  {
    date: "2018-09-21",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 90000000,
    marketNav: 71717813,
    percentOfNav: 80,
  },
  {
    date: "2018-09-28",
    tvpi: 1,
    marketTvpi: 0.79,
    nav: 90000000,
    marketNav: 71380561,
    percentOfNav: 79,
  },
  {
    date: "2018-10-05",
    tvpi: 1,
    marketTvpi: 0.79,
    nav: 108000000,
    marketNav: 85634109,
    percentOfNav: 79,
  },
  {
    date: "2018-10-12",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 108000000,
    marketNav: 86318782,
    percentOfNav: 80,
  },
  {
    date: "2018-10-19",
    tvpi: 1,
    marketTvpi: 0.79,
    nav: 108000000,
    marketNav: 85559951,
    percentOfNav: 79,
  },
  {
    date: "2018-10-26",
    tvpi: 1,
    marketTvpi: 0.8,
    nav: 108000000,
    marketNav: 86656855,
    percentOfNav: 80,
  },
  {
    date: "2018-11-02",
    tvpi: 1,
    marketTvpi: 0.79,
    nav: 108000000,
    marketNav: 85573498,
    percentOfNav: 79,
  },
  {
    date: "2018-11-09",
    tvpi: 1,
    marketTvpi: 0.78,
    nav: 108000000,
    marketNav: 84396384,
    percentOfNav: 78,
  },
  {
    date: "2018-11-16",
    tvpi: 1,
    marketTvpi: 0.78,
    nav: 108000000,
    marketNav: 84289564,
    percentOfNav: 78,
  },
  {
    date: "2018-11-23",
    tvpi: 1,
    marketTvpi: 0.77,
    nav: 108000000,
    marketNav: 82621419,
    percentOfNav: 77,
  },
  {
    date: "2018-11-30",
    tvpi: 1,
    marketTvpi: 0.78,
    nav: 108000000,
    marketNav: 83861318,
    percentOfNav: 78,
  },
  {
    date: "2018-12-07",
    tvpi: 1,
    marketTvpi: 0.77,
    nav: 108000000,
    marketNav: 83497154,
    percentOfNav: 77,
  },
  {
    date: "2018-12-14",
    tvpi: 1,
    marketTvpi: 0.76,
    nav: 108000000,
    marketNav: 81749260,
    percentOfNav: 76,
  },
  {
    date: "2018-12-21",
    tvpi: 1,
    marketTvpi: 0.76,
    nav: 108000000,
    marketNav: 82190530,
    percentOfNav: 76,
  },
  {
    date: "2018-12-28",
    tvpi: 1,
    marketTvpi: 0.76,
    nav: 108000000,
    marketNav: 82548154,
    percentOfNav: 76,
  },
  {
    date: "2019-01-04",
    tvpi: 1,
    marketTvpi: 0.76,
    nav: 118000000,
    marketNav: 89257907,
    percentOfNav: 76,
  },
  {
    date: "2019-01-11",
    tvpi: 1,
    marketTvpi: 0.74,
    nav: 118000000,
    marketNav: 87752644,
    percentOfNav: 74,
  },
  {
    date: "2019-01-18",
    tvpi: 1,
    marketTvpi: 0.73,
    nav: 118000000,
    marketNav: 85665173,
    percentOfNav: 73,
  },
  {
    date: "2019-01-25",
    tvpi: 1,
    marketTvpi: 0.73,
    nav: 118000000,
    marketNav: 85907857,
    percentOfNav: 73,
  },
  {
    date: "2019-02-01",
    tvpi: 1,
    marketTvpi: 0.72,
    nav: 118000000,
    marketNav: 85090629,
    percentOfNav: 72,
  },
  {
    date: "2019-02-08",
    tvpi: 1,
    marketTvpi: 0.72,
    nav: 118000000,
    marketNav: 85093476,
    percentOfNav: 72,
  },
  {
    date: "2019-02-15",
    tvpi: 1,
    marketTvpi: 0.71,
    nav: 118000000,
    marketNav: 84261671,
    percentOfNav: 71,
  },
  {
    date: "2019-02-22",
    tvpi: 1,
    marketTvpi: 0.71,
    nav: 118000000,
    marketNav: 84146661,
    percentOfNav: 71,
  },
  {
    date: "2019-03-01",
    tvpi: 1,
    marketTvpi: 0.7,
    nav: 118000000,
    marketNav: 82810652,
    percentOfNav: 70,
  },
  {
    date: "2019-03-08",
    tvpi: 1,
    marketTvpi: 0.7,
    nav: 118000000,
    marketNav: 82701013,
    percentOfNav: 70,
  },
  {
    date: "2019-03-15",
    tvpi: 1,
    marketTvpi: 0.7,
    nav: 118000000,
    marketNav: 82868326,
    percentOfNav: 70,
  },
  {
    date: "2019-03-22",
    tvpi: 1,
    marketTvpi: 0.71,
    nav: 118000000,
    marketNav: 83474714,
    percentOfNav: 71,
  },
  {
    date: "2019-03-29",
    tvpi: 1.2,
    marketTvpi: 0.84,
    nav: 141600000,
    marketNav: 99075639,
    percentOfNav: 70,
  },
  {
    date: "2019-04-05",
    tvpi: 1.2,
    marketTvpi: 0.83,
    nav: 229200000,
    marketNav: 158874209,
    percentOfNav: 69,
  },
  {
    date: "2019-04-12",
    tvpi: 1.2,
    marketTvpi: 0.83,
    nav: 229200000,
    marketNav: 158137174,
    percentOfNav: 69,
  },
  {
    date: "2019-04-19",
    tvpi: 1.2,
    marketTvpi: 0.83,
    nav: 229200000,
    marketNav: 157652242,
    percentOfNav: 69,
  },
  {
    date: "2019-04-26",
    tvpi: 1.2,
    marketTvpi: 0.82,
    nav: 229200000,
    marketNav: 156079793,
    percentOfNav: 68,
  },
  {
    date: "2019-05-03",
    tvpi: 1.2,
    marketTvpi: 0.81,
    nav: 229200000,
    marketNav: 155622472,
    percentOfNav: 68,
  },
  {
    date: "2019-05-10",
    tvpi: 1.2,
    marketTvpi: 0.82,
    nav: 229200000,
    marketNav: 157197893,
    percentOfNav: 69,
  },
  {
    date: "2019-05-17",
    tvpi: 1.2,
    marketTvpi: 0.83,
    nav: 229200000,
    marketNav: 157993455,
    percentOfNav: 69,
  },
  {
    date: "2019-05-24",
    tvpi: 1.2,
    marketTvpi: 0.83,
    nav: 229200000,
    marketNav: 158853361,
    percentOfNav: 69,
  },
  {
    date: "2019-05-31",
    tvpi: 1.2,
    marketTvpi: 0.84,
    nav: 229200000,
    marketNav: 160060937,
    percentOfNav: 70,
  },
  {
    date: "2019-06-07",
    tvpi: 1.2,
    marketTvpi: 0.84,
    nav: 229200000,
    marketNav: 160644787,
    percentOfNav: 70,
  },
  {
    date: "2019-06-14",
    tvpi: 1.2,
    marketTvpi: 0.85,
    nav: 229200000,
    marketNav: 162704076,
    percentOfNav: 71,
  },
  {
    date: "2019-06-21",
    tvpi: 1.2,
    marketTvpi: 0.86,
    nav: 229200000,
    marketNav: 165214744,
    percentOfNav: 72,
  },
  {
    date: "2019-06-28",
    tvpi: 1.2,
    marketTvpi: 0.87,
    nav: 229200000,
    marketNav: 165706641,
    percentOfNav: 72,
  },
  {
    date: "2019-07-05",
    tvpi: 1.32,
    marketTvpi: 0.95,
    nav: 258720000,
    marketNav: 185405512,
    percentOfNav: 72,
  },
  {
    date: "2019-07-12",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 185117480,
    percentOfNav: 72,
  },
  {
    date: "2019-07-19",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 183817364,
    percentOfNav: 71,
  },
  {
    date: "2019-07-26",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 183933221,
    percentOfNav: 71,
  },
  {
    date: "2019-08-02",
    tvpi: 1.32,
    marketTvpi: 0.95,
    nav: 258720000,
    marketNav: 185898372,
    percentOfNav: 72,
  },
  {
    date: "2019-08-09",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 183996219,
    percentOfNav: 71,
  },
  {
    date: "2019-08-16",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 184330926,
    percentOfNav: 71,
  },
  {
    date: "2019-08-23",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 183767705,
    percentOfNav: 71,
  },
  {
    date: "2019-08-30",
    tvpi: 1.32,
    marketTvpi: 0.95,
    nav: 258720000,
    marketNav: 185485285,
    percentOfNav: 72,
  },
  {
    date: "2019-09-06",
    tvpi: 1.32,
    marketTvpi: 0.94,
    nav: 258720000,
    marketNav: 184514193,
    percentOfNav: 71,
  },
  {
    date: "2019-09-13",
    tvpi: 1.32,
    marketTvpi: 0.96,
    nav: 258720000,
    marketNav: 188141661,
    percentOfNav: 73,
  },
  {
    date: "2019-09-20",
    tvpi: 1.32,
    marketTvpi: 0.98,
    nav: 258720000,
    marketNav: 191349176,
    percentOfNav: 74,
  },
  {
    date: "2019-09-27",
    tvpi: 1.5,
    marketTvpi: 1.11,
    nav: 294000000,
    marketNav: 217330961,
    percentOfNav: 74,
  },
  {
    date: "2019-10-04",
    tvpi: 1.5,
    marketTvpi: 1.1,
    nav: 309000000,
    marketNav: 227629079,
    percentOfNav: 74,
  },
  {
    date: "2019-10-11",
    tvpi: 1.5,
    marketTvpi: 1.11,
    nav: 309000000,
    marketNav: 228205158,
    percentOfNav: 74,
  },
  {
    date: "2019-10-18",
    tvpi: 1.5,
    marketTvpi: 1.09,
    nav: 309000000,
    marketNav: 225376410,
    percentOfNav: 73,
  },
  {
    date: "2019-10-25",
    tvpi: 1.5,
    marketTvpi: 1.1,
    nav: 309000000,
    marketNav: 227570755,
    percentOfNav: 74,
  },
  {
    date: "2019-11-01",
    tvpi: 1.5,
    marketTvpi: 1.1,
    nav: 309000000,
    marketNav: 227626257,
    percentOfNav: 74,
  },
  {
    date: "2019-11-08",
    tvpi: 1.5,
    marketTvpi: 1.1,
    nav: 309000000,
    marketNav: 225768467,
    percentOfNav: 73,
  },
  {
    date: "2019-11-15",
    tvpi: 1.5,
    marketTvpi: 1.09,
    nav: 309000000,
    marketNav: 224340771,
    percentOfNav: 73,
  },
  {
    date: "2019-11-22",
    tvpi: 1.5,
    marketTvpi: 1.07,
    nav: 309000000,
    marketNav: 221115853,
    percentOfNav: 72,
  },
  {
    date: "2019-11-29",
    tvpi: 1.5,
    marketTvpi: 1.07,
    nav: 309000000,
    marketNav: 221207465,
    percentOfNav: 72,
  },
  {
    date: "2019-12-06",
    tvpi: 1.5,
    marketTvpi: 1.07,
    nav: 309000000,
    marketNav: 221010173,
    percentOfNav: 72,
  },
  {
    date: "2019-12-13",
    tvpi: 1.5,
    marketTvpi: 1.08,
    nav: 309000000,
    marketNav: 221649426,
    percentOfNav: 72,
  },
  {
    date: "2019-12-20",
    tvpi: 1.5,
    marketTvpi: 1.06,
    nav: 309000000,
    marketNav: 218394032,
    percentOfNav: 71,
  },
  {
    date: "2019-12-27",
    tvpi: 2.2,
    marketTvpi: 1.58,
    nav: 453200000,
    marketNav: 325921600,
    percentOfNav: 72,
  },
  {
    date: "2020-01-03",
    tvpi: 2.2,
    marketTvpi: 1.61,
    nav: 464200000,
    marketNav: 339239926,
    percentOfNav: 73,
  },
  {
    date: "2020-01-10",
    tvpi: 2.2,
    marketTvpi: 1.59,
    nav: 464200000,
    marketNav: 335715527,
    percentOfNav: 72,
  },
  {
    date: "2020-01-17",
    tvpi: 2.2,
    marketTvpi: 1.61,
    nav: 464200000,
    marketNav: 340288830,
    percentOfNav: 73,
  },
  {
    date: "2020-01-24",
    tvpi: 2.2,
    marketTvpi: 1.63,
    nav: 464200000,
    marketNav: 343336779,
    percentOfNav: 74,
  },
  {
    date: "2020-01-31",
    tvpi: 2.2,
    marketTvpi: 1.63,
    nav: 464200000,
    marketNav: 344868729,
    percentOfNav: 74,
  },
  {
    date: "2020-02-07",
    tvpi: 2.2,
    marketTvpi: 1.68,
    nav: 464200000,
    marketNav: 354294848,
    percentOfNav: 76,
  },
  {
    date: "2020-02-14",
    tvpi: 2.2,
    marketTvpi: 1.66,
    nav: 464200000,
    marketNav: 349739710,
    percentOfNav: 75,
  },
  {
    date: "2020-02-21",
    tvpi: 2.2,
    marketTvpi: 1.64,
    nav: 464200000,
    marketNav: 346295199,
    percentOfNav: 75,
  },
  {
    date: "2020-02-28",
    tvpi: 2.2,
    marketTvpi: 1.64,
    nav: 464200000,
    marketNav: 345101729,
    percentOfNav: 74,
  },
  {
    date: "2020-03-06",
    tvpi: 2.2,
    marketTvpi: 1.66,
    nav: 464200000,
    marketNav: 351077799,
    percentOfNav: 76,
  },
  {
    date: "2020-03-13",
    tvpi: 2.2,
    marketTvpi: 1.62,
    nav: 464200000,
    marketNav: 340876229,
    percentOfNav: 73,
  },
  {
    date: "2020-03-20",
    tvpi: 2.2,
    marketTvpi: 1.59,
    nav: 464200000,
    marketNav: 336207085,
    percentOfNav: 72,
  },
  {
    date: "2020-03-27",
    tvpi: 2.8,
    marketTvpi: 2.0,
    nav: 590800000,
    marketNav: 422369735,
    percentOfNav: 71,
  },
  {
    date: "2020-04-03",
    tvpi: 2.8,
    marketTvpi: 1.93,
    nav: 618800000,
    marketNav: 426845012,
    percentOfNav: 69,
  },
  {
    date: "2020-04-10",
    tvpi: 2.8,
    marketTvpi: 1.88,
    nav: 618800000,
    marketNav: 415640058,
    percentOfNav: 67,
  },
  {
    date: "2020-04-17",
    tvpi: 2.8,
    marketTvpi: 1.85,
    nav: 618800000,
    marketNav: 408222401,
    percentOfNav: 66,
  },
  {
    date: "2020-04-24",
    tvpi: 2.8,
    marketTvpi: 1.82,
    nav: 618800000,
    marketNav: 402491006,
    percentOfNav: 65,
  },
  {
    date: "2020-05-01",
    tvpi: 2.8,
    marketTvpi: 1.76,
    nav: 618800000,
    marketNav: 389529968,
    percentOfNav: 63,
  },
  {
    date: "2020-05-08",
    tvpi: 2.8,
    marketTvpi: 1.71,
    nav: 618800000,
    marketNav: 378410583,
    percentOfNav: 61,
  },
  {
    date: "2020-05-15",
    tvpi: 2.8,
    marketTvpi: 1.69,
    nav: 618800000,
    marketNav: 374268179,
    percentOfNav: 60,
  },
  {
    date: "2020-05-22",
    tvpi: 2.8,
    marketTvpi: 1.67,
    nav: 618800000,
    marketNav: 368095866,
    percentOfNav: 59,
  },
  {
    date: "2020-05-29",
    tvpi: 2.8,
    marketTvpi: 1.65,
    nav: 618800000,
    marketNav: 364406834,
    percentOfNav: 59,
  },
  {
    date: "2020-06-05",
    tvpi: 2.8,
    marketTvpi: 1.67,
    nav: 618800000,
    marketNav: 368100293,
    percentOfNav: 59,
  },
  {
    date: "2020-06-12",
    tvpi: 2.8,
    marketTvpi: 1.67,
    nav: 618800000,
    marketNav: 369956924,
    percentOfNav: 60,
  },
  {
    date: "2020-06-19",
    tvpi: 2.8,
    marketTvpi: 1.67,
    nav: 618800000,
    marketNav: 368104097,
    percentOfNav: 59,
  },
  {
    date: "2020-06-26",
    tvpi: 2.9,
    marketTvpi: 1.73,
    nav: 640900000,
    marketNav: 383195858,
    percentOfNav: 60,
  },
  {
    date: "2020-07-03",
    tvpi: 2.9,
    marketTvpi: 1.73,
    nav: 727900000,
    marketNav: 434731848,
    percentOfNav: 60,
  },
  {
    date: "2020-07-10",
    tvpi: 2.9,
    marketTvpi: 1.72,
    nav: 727900000,
    marketNav: 432848772,
    percentOfNav: 59,
  },
  {
    date: "2020-07-17",
    tvpi: 2.9,
    marketTvpi: 1.75,
    nav: 727900000,
    marketNav: 438253220,
    percentOfNav: 60,
  },
  {
    date: "2020-07-24",
    tvpi: 2.9,
    marketTvpi: 1.76,
    nav: 727900000,
    marketNav: 441758624,
    percentOfNav: 61,
  },
  {
    date: "2020-07-31",
    tvpi: 2.9,
    marketTvpi: 1.76,
    nav: 727900000,
    marketNav: 440611508,
    percentOfNav: 61,
  },
  {
    date: "2020-08-07",
    tvpi: 2.9,
    marketTvpi: 1.77,
    nav: 727900000,
    marketNav: 443913109,
    percentOfNav: 61,
  },
  {
    date: "2020-08-14",
    tvpi: 2.9,
    marketTvpi: 1.82,
    nav: 727900000,
    marketNav: 456316366,
    percentOfNav: 63,
  },
  {
    date: "2020-08-21",
    tvpi: 2.9,
    marketTvpi: 1.81,
    nav: 727900000,
    marketNav: 453886669,
    percentOfNav: 62,
  },
  {
    date: "2020-08-28",
    tvpi: 2.9,
    marketTvpi: 1.82,
    nav: 727900000,
    marketNav: 457667151,
    percentOfNav: 63,
  },
  {
    date: "2020-09-04",
    tvpi: 2.9,
    marketTvpi: 1.83,
    nav: 727900000,
    marketNav: 458535134,
    percentOfNav: 63,
  },
  {
    date: "2020-09-11",
    tvpi: 2.9,
    marketTvpi: 1.84,
    nav: 727900000,
    marketNav: 460867524,
    percentOfNav: 63,
  },
  {
    date: "2020-09-18",
    tvpi: 2.9,
    marketTvpi: 1.83,
    nav: 727900000,
    marketNav: 459040425,
    percentOfNav: 63,
  },
  {
    date: "2020-09-25",
    tvpi: 3.12,
    marketTvpi: 1.95,
    nav: 783120000,
    marketNav: 488305125,
    percentOfNav: 62,
  },
  {
    date: "2020-10-02",
    tvpi: 3.12,
    marketTvpi: 1.95,
    nav: 876720000,
    marketNav: 549299505,
    percentOfNav: 63,
  },
  {
    date: "2020-10-09",
    tvpi: 3.12,
    marketTvpi: 1.92,
    nav: 876720000,
    marketNav: 540506844,
    percentOfNav: 62,
  },
  {
    date: "2020-10-16",
    tvpi: 3.12,
    marketTvpi: 1.92,
    nav: 876720000,
    marketNav: 540266761,
    percentOfNav: 62,
  },
  {
    date: "2020-10-23",
    tvpi: 3.12,
    marketTvpi: 1.9,
    nav: 876720000,
    marketNav: 534126581,
    percentOfNav: 61,
  },
  {
    date: "2020-10-30",
    tvpi: 3.12,
    marketTvpi: 1.91,
    nav: 876720000,
    marketNav: 537505682,
    percentOfNav: 61,
  },
  {
    date: "2020-11-06",
    tvpi: 3.12,
    marketTvpi: 1.92,
    nav: 876720000,
    marketNav: 540640600,
    percentOfNav: 62,
  },
  {
    date: "2020-11-13",
    tvpi: 3.12,
    marketTvpi: 1.93,
    nav: 876720000,
    marketNav: 541977642,
    percentOfNav: 62,
  },
  {
    date: "2020-11-20",
    tvpi: 3.12,
    marketTvpi: 1.89,
    nav: 876720000,
    marketNav: 531616372,
    percentOfNav: 61,
  },
  {
    date: "2020-11-27",
    tvpi: 3.12,
    marketTvpi: 1.87,
    nav: 876720000,
    marketNav: 526553973,
    percentOfNav: 60,
  },
  {
    date: "2020-12-04",
    tvpi: 3.12,
    marketTvpi: 1.92,
    nav: 876720000,
    marketNav: 539708071,
    percentOfNav: 62,
  },
  {
    date: "2020-12-11",
    tvpi: 3.12,
    marketTvpi: 1.89,
    nav: 876720000,
    marketNav: 532218634,
    percentOfNav: 61,
  },
  {
    date: "2020-12-18",
    tvpi: 3.12,
    marketTvpi: 1.91,
    nav: 876720000,
    marketNav: 535641756,
    percentOfNav: 61,
  },
  {
    date: "2020-12-25",
    tvpi: 3.05,
    marketTvpi: 1.91,
    nav: 857050000,
    marketNav: 537070008,
    percentOfNav: 63,
  },
  {
    date: "2021-01-01",
    tvpi: 3.05,
    marketTvpi: 1.91,
    nav: 933300000,
    marketNav: 582998031,
    percentOfNav: 62,
  },
  {
    date: "2021-01-08",
    tvpi: 3.05,
    marketTvpi: 1.91,
    nav: 933300000,
    marketNav: 585824340,
    percentOfNav: 63,
  },
  {
    date: "2021-01-15",
    tvpi: 3.05,
    marketTvpi: 1.93,
    nav: 933300000,
    marketNav: 589272804,
    percentOfNav: 63,
  },
  {
    date: "2021-01-22",
    tvpi: 3.05,
    marketTvpi: 1.96,
    nav: 933300000,
    marketNav: 600945355,
    percentOfNav: 64,
  },
  {
    date: "2021-01-29",
    tvpi: 3.05,
    marketTvpi: 1.97,
    nav: 933300000,
    marketNav: 602770876,
    percentOfNav: 65,
  },
  {
    date: "2021-02-05",
    tvpi: 3.05,
    marketTvpi: 2.04,
    nav: 933300000,
    marketNav: 623910185,
    percentOfNav: 67,
  },
  {
    date: "2021-02-12",
    tvpi: 3.05,
    marketTvpi: 2.1,
    nav: 933300000,
    marketNav: 641317786,
    percentOfNav: 69,
  },
  {
    date: "2021-02-19",
    tvpi: 3.05,
    marketTvpi: 2.11,
    nav: 933300000,
    marketNav: 644455407,
    percentOfNav: 69,
  },
  {
    date: "2021-02-26",
    tvpi: 3.05,
    marketTvpi: 2.1,
    nav: 933300000,
    marketNav: 641764932,
    percentOfNav: 69,
  },
  {
    date: "2021-03-05",
    tvpi: 3.05,
    marketTvpi: 2.12,
    nav: 933300000,
    marketNav: 647595646,
    percentOfNav: 69,
  },
  {
    date: "2021-03-12",
    tvpi: 3.05,
    marketTvpi: 2.13,
    nav: 933300000,
    marketNav: 652538324,
    percentOfNav: 70,
  },
  {
    date: "2021-03-19",
    tvpi: 3.05,
    marketTvpi: 2.17,
    nav: 933300000,
    marketNav: 664444273,
    percentOfNav: 71,
  },
  {
    date: "2021-03-26",
    tvpi: 3.5,
    marketTvpi: 2.55,
    nav: 1071000000,
    marketNav: 780545963,
    percentOfNav: 73,
  },
  {
    date: "2021-04-02",
    tvpi: 3.5,
    marketTvpi: 2.58,
    nav: 1211000000,
    marketNav: 892795974,
    percentOfNav: 74,
  },
  {
    date: "2021-04-09",
    tvpi: 3.5,
    marketTvpi: 2.61,
    nav: 1211000000,
    marketNav: 902488375,
    percentOfNav: 75,
  },
  {
    date: "2021-04-16",
    tvpi: 3.5,
    marketTvpi: 2.58,
    nav: 1211000000,
    marketNav: 894231320,
    percentOfNav: 74,
  },
  {
    date: "2021-04-23",
    tvpi: 3.5,
    marketTvpi: 2.64,
    nav: 1211000000,
    marketNav: 911953544,
    percentOfNav: 75,
  },
  {
    date: "2021-04-30",
    tvpi: 3.5,
    marketTvpi: 2.64,
    nav: 1211000000,
    marketNav: 914922864,
    percentOfNav: 76,
  },
  {
    date: "2021-05-07",
    tvpi: 3.5,
    marketTvpi: 2.69,
    nav: 1211000000,
    marketNav: 929669162,
    percentOfNav: 77,
  },
  {
    date: "2021-05-14",
    tvpi: 3.5,
    marketTvpi: 2.73,
    nav: 1211000000,
    marketNav: 943267588,
    percentOfNav: 78,
  },
  {
    date: "2021-05-21",
    tvpi: 3.5,
    marketTvpi: 2.77,
    nav: 1211000000,
    marketNav: 960002601,
    percentOfNav: 79,
  },
  {
    date: "2021-05-28",
    tvpi: 3.5,
    marketTvpi: 2.81,
    nav: 1211000000,
    marketNav: 973510356,
    percentOfNav: 80,
  },
  {
    date: "2021-06-04",
    tvpi: 3.5,
    marketTvpi: 2.88,
    nav: 1211000000,
    marketNav: 996875460,
    percentOfNav: 82,
  },
  {
    date: "2021-06-11",
    tvpi: 3.5,
    marketTvpi: 2.96,
    nav: 1211000000,
    marketNav: 1023566288,
    percentOfNav: 85,
  },
  {
    date: "2021-06-18",
    tvpi: 3.5,
    marketTvpi: 3.05,
    nav: 1211000000,
    marketNav: 1055096681,
    percentOfNav: 87,
  },
  {
    date: "2021-06-25",
    tvpi: 3.5,
    marketTvpi: 3.06,
    nav: 1211000000,
    marketNav: 1059861942,
    percentOfNav: 88,
  },
  {
    date: "2021-07-02",
    tvpi: 3.5,
    marketTvpi: 3.1,
    nav: 1386000000,
    marketNav: 1228670982,
    percentOfNav: 89,
  },
  {
    date: "2021-07-09",
    tvpi: 3.5,
    marketTvpi: 3.11,
    nav: 1386000000,
    marketNav: 1232027620,
    percentOfNav: 89,
  },
  {
    date: "2021-07-16",
    tvpi: 3.5,
    marketTvpi: 3.11,
    nav: 1386000000,
    marketNav: 1230829562,
    percentOfNav: 89,
  },
  {
    date: "2021-07-23",
    tvpi: 3.5,
    marketTvpi: 3.13,
    nav: 1386000000,
    marketNav: 1239794028,
    percentOfNav: 89,
  },
  {
    date: "2021-07-30",
    tvpi: 3.5,
    marketTvpi: 3.1,
    nav: 1386000000,
    marketNav: 1229205863,
    percentOfNav: 89,
  },
  {
    date: "2021-08-06",
    tvpi: 3.5,
    marketTvpi: 3.11,
    nav: 1386000000,
    marketNav: 1230067536,
    percentOfNav: 89,
  },
  {
    date: "2021-08-13",
    tvpi: 3.5,
    marketTvpi: 3.13,
    nav: 1386000000,
    marketNav: 1238475352,
    percentOfNav: 89,
  },
  {
    date: "2021-08-20",
    tvpi: 3.5,
    marketTvpi: 3.19,
    nav: 1386000000,
    marketNav: 1263812173,
    percentOfNav: 91,
  },
  {
    date: "2021-08-27",
    tvpi: 3.5,
    marketTvpi: 3.26,
    nav: 1386000000,
    marketNav: 1290981956,
    percentOfNav: 93,
  },
  {
    date: "2021-09-03",
    tvpi: 3.5,
    marketTvpi: 3.25,
    nav: 1386000000,
    marketNav: 1287990445,
    percentOfNav: 93,
  },
  {
    date: "2021-09-10",
    tvpi: 3.5,
    marketTvpi: 3.27,
    nav: 1386000000,
    marketNav: 1293762429,
    percentOfNav: 93,
  },
  {
    date: "2021-09-17",
    tvpi: 3.5,
    marketTvpi: 3.26,
    nav: 1386000000,
    marketNav: 1291675917,
    percentOfNav: 93,
  },
  {
    date: "2021-09-24",
    tvpi: 3.55,
    marketTvpi: 3.27,
    nav: 1405800000,
    marketNav: 1296108511,
    percentOfNav: 92,
  },
  {
    date: "2021-10-01",
    tvpi: 3.55,
    marketTvpi: 3.25,
    nav: 1405800000,
    marketNav: 1286268878,
    percentOfNav: 91,
  },
  {
    date: "2021-10-08",
    tvpi: 3.55,
    marketTvpi: 3.26,
    nav: 1405800000,
    marketNav: 1290708471,
    percentOfNav: 92,
  },
  {
    date: "2021-10-15",
    tvpi: 3.55,
    marketTvpi: 3.26,
    nav: 1405800000,
    marketNav: 1291075099,
    percentOfNav: 92,
  },
  {
    date: "2021-10-22",
    tvpi: 3.55,
    marketTvpi: 3.25,
    nav: 1405800000,
    marketNav: 1288771565,
    percentOfNav: 92,
  },
  {
    date: "2021-10-29",
    tvpi: 3.55,
    marketTvpi: 3.25,
    nav: 1405800000,
    marketNav: 1288544529,
    percentOfNav: 92,
  },
  {
    date: "2021-11-05",
    tvpi: 3.55,
    marketTvpi: 3.27,
    nav: 1405800000,
    marketNav: 1295812469,
    percentOfNav: 92,
  },
  {
    date: "2021-11-12",
    tvpi: 3.55,
    marketTvpi: 3.31,
    nav: 1405800000,
    marketNav: 1312163358,
    percentOfNav: 93,
  },
  {
    date: "2021-11-19",
    tvpi: 3.55,
    marketTvpi: 3.32,
    nav: 1405800000,
    marketNav: 1313134893,
    percentOfNav: 93,
  },
  {
    date: "2021-11-26",
    tvpi: 3.55,
    marketTvpi: 3.3,
    nav: 1405800000,
    marketNav: 1307661612,
    percentOfNav: 93,
  },
  {
    date: "2021-12-03",
    tvpi: 3.55,
    marketTvpi: 3.32,
    nav: 1405800000,
    marketNav: 1316372684,
    percentOfNav: 94,
  },
  {
    date: "2021-12-10",
    tvpi: 3.55,
    marketTvpi: 3.38,
    nav: 1405800000,
    marketNav: 1337160780,
    percentOfNav: 95,
  },
  {
    date: "2021-12-17",
    tvpi: 3.55,
    marketTvpi: 3.4,
    nav: 1405800000,
    marketNav: 1348336764,
    percentOfNav: 96,
  },
  {
    date: "2021-12-24",
    tvpi: 3.2,
    marketTvpi: 3.15,
    nav: 1267200000,
    marketNav: 1247554108,
    percentOfNav: 98,
  },
  {
    date: "2021-12-31",
    tvpi: 3.2,
    marketTvpi: 3.14,
    nav: 1267200000,
    marketNav: 1244603655,
    percentOfNav: 98,
  },
  {
    date: "2022-01-07",
    tvpi: 3.2,
    marketTvpi: 3.14,
    nav: 1267200000,
    marketNav: 1243477174,
    percentOfNav: 98,
  },
  {
    date: "2022-01-14",
    tvpi: 3.2,
    marketTvpi: 3.15,
    nav: 1267200000,
    marketNav: 1248413028,
    percentOfNav: 99,
  },
  {
    date: "2022-01-21",
    tvpi: 3.2,
    marketTvpi: 3.17,
    nav: 1267200000,
    marketNav: 1254172084,
    percentOfNav: 99,
  },
  {
    date: "2022-01-28",
    tvpi: 3.2,
    marketTvpi: 3.17,
    nav: 1267200000,
    marketNav: 1255760922,
    percentOfNav: 99,
  },
  {
    date: "2022-02-04",
    tvpi: 3.2,
    marketTvpi: 3.18,
    nav: 1267200000,
    marketNav: 1261133393,
    percentOfNav: 100,
  },
  {
    date: "2022-02-11",
    tvpi: 3.2,
    marketTvpi: 3.21,
    nav: 1267200000,
    marketNav: 1271960923,
    percentOfNav: 100,
  },
  {
    date: "2022-02-18",
    tvpi: 3.2,
    marketTvpi: 3.18,
    nav: 1267200000,
    marketNav: 1260698514,
    percentOfNav: 99,
  },
  {
    date: "2022-02-25",
    tvpi: 3.2,
    marketTvpi: 3.2,
    nav: 1267200000,
    marketNav: 1268219890,
    percentOfNav: 100,
  },
  {
    date: "2022-03-04",
    tvpi: 3.2,
    marketTvpi: 3.23,
    nav: 1267200000,
    marketNav: 1279065184,
    percentOfNav: 101,
  },
  {
    date: "2022-03-11",
    tvpi: 3.2,
    marketTvpi: 3.21,
    nav: 1267200000,
    marketNav: 1270988374,
    percentOfNav: 100,
  },
  {
    date: "2022-03-18",
    tvpi: 3.2,
    marketTvpi: 3.21,
    nav: 1267200000,
    marketNav: 1271029937,
    percentOfNav: 100,
  },
  {
    date: "2022-03-25",
    tvpi: 4,
    marketTvpi: 3.94,
    nav: 1584000000,
    marketNav: 1558690878,
    percentOfNav: 98,
  },
  {
    date: "2022-04-01",
    tvpi: 4,
    marketTvpi: 3.99,
    nav: 1584000000,
    marketNav: 1578510489,
    percentOfNav: 100,
  },
  {
    date: "2022-04-08",
    tvpi: 4,
    marketTvpi: 4.11,
    nav: 1584000000,
    marketNav: 1627634807,
    percentOfNav: 103,
  },
  {
    date: "2022-04-15",
    tvpi: 4,
    marketTvpi: 4.11,
    nav: 1584000000,
    marketNav: 1627979990,
    percentOfNav: 103,
  },
  {
    date: "2022-04-22",
    tvpi: 4,
    marketTvpi: 4.06,
    nav: 1584000000,
    marketNav: 1606228508,
    percentOfNav: 101,
  },
  {
    date: "2022-04-29",
    tvpi: 4,
    marketTvpi: 4.01,
    nav: 1584000000,
    marketNav: 1588763059,
    percentOfNav: 100,
  },
  {
    date: "2022-05-06",
    tvpi: 4,
    marketTvpi: 3.92,
    nav: 1584000000,
    marketNav: 1551434896,
    percentOfNav: 98,
  },
  {
    date: "2022-05-13",
    tvpi: 4,
    marketTvpi: 3.89,
    nav: 1584000000,
    marketNav: 1542214076,
    percentOfNav: 97,
  },
  {
    date: "2022-05-20",
    tvpi: 4,
    marketTvpi: 3.87,
    nav: 1584000000,
    marketNav: 1533146361,
    percentOfNav: 97,
  },
  {
    date: "2022-05-27",
    tvpi: 4,
    marketTvpi: 3.88,
    nav: 1584000000,
    marketNav: 1537092300,
    percentOfNav: 97,
  },
  {
    date: "2022-06-03",
    tvpi: 4,
    marketTvpi: 3.85,
    nav: 1584000000,
    marketNav: 1525778361,
    percentOfNav: 96,
  },
  {
    date: "2022-06-10",
    tvpi: 4,
    marketTvpi: 3.79,
    nav: 1584000000,
    marketNav: 1501401664,
    percentOfNav: 95,
  },
  {
    date: "2022-06-17",
    tvpi: 4,
    marketTvpi: 3.81,
    nav: 1584000000,
    marketNav: 1507450599,
    percentOfNav: 95,
  },
  {
    date: "2022-06-24",
    tvpi: 4,
    marketTvpi: 3.8,
    nav: 1584000000,
    marketNav: 1505276592,
    percentOfNav: 95,
  },
  {
    date: "2022-07-01",
    tvpi: 4.11,
    marketTvpi: 3.8,
    nav: 1627560000,
    marketNav: 1504224039,
    percentOfNav: 92,
  },
  {
    date: "2022-07-08",
    tvpi: 4.11,
    marketTvpi: 3.82,
    nav: 1627560000,
    marketNav: 1512362123,
    percentOfNav: 93,
  },
  {
    date: "2022-07-15",
    tvpi: 4.11,
    marketTvpi: 3.76,
    nav: 1627560000,
    marketNav: 1488128288,
    percentOfNav: 91,
  },
  {
    date: "2022-07-22",
    tvpi: 4.11,
    marketTvpi: 3.71,
    nav: 1627560000,
    marketNav: 1469351416,
    percentOfNav: 90,
  },
  {
    date: "2022-07-29",
    tvpi: 4.11,
    marketTvpi: 3.62,
    nav: 1627560000,
    marketNav: 1434108940,
    percentOfNav: 88,
  },
  {
    date: "2022-08-05",
    tvpi: 4.11,
    marketTvpi: 3.61,
    nav: 1627560000,
    marketNav: 1430023423,
    percentOfNav: 88,
  },
  {
    date: "2022-08-12",
    tvpi: 4.11,
    marketTvpi: 3.65,
    nav: 1627560000,
    marketNav: 1444486240,
    percentOfNav: 89,
  },
  {
    date: "2022-08-19",
    tvpi: 4.11,
    marketTvpi: 3.66,
    nav: 1627560000,
    marketNav: 1451005490,
    percentOfNav: 89,
  },
  {
    date: "2022-08-26",
    tvpi: 4.11,
    marketTvpi: 3.61,
    nav: 1627560000,
    marketNav: 1430053274,
    percentOfNav: 88,
  },
  {
    date: "2022-09-02",
    tvpi: 4.11,
    marketTvpi: 3.57,
    nav: 1627560000,
    marketNav: 1413152940,
    percentOfNav: 87,
  },
  {
    date: "2022-09-09",
    tvpi: 4.11,
    marketTvpi: 3.53,
    nav: 1627560000,
    marketNav: 1399054347,
    percentOfNav: 86,
  },
  {
    date: "2022-09-16",
    tvpi: 4.11,
    marketTvpi: 3.48,
    nav: 1627560000,
    marketNav: 1377446164,
    percentOfNav: 85,
  },
  {
    date: "2022-09-23",
    tvpi: 4.11,
    marketTvpi: 3.43,
    nav: 1627560000,
    marketNav: 1359467017,
    percentOfNav: 84,
  },
  {
    date: "2022-09-30",
    tvpi: 4.11,
    marketTvpi: 3.5,
    nav: 1627560000,
    marketNav: 1385059822,
    percentOfNav: 85,
  },
  {
    date: "2022-10-07",
    tvpi: 4.11,
    marketTvpi: 3.47,
    nav: 1627560000,
    marketNav: 1372897190,
    percentOfNav: 84,
  },
  {
    date: "2022-10-14",
    tvpi: 4.11,
    marketTvpi: 3.51,
    nav: 1627560000,
    marketNav: 1389966212,
    percentOfNav: 85,
  },
  {
    date: "2022-10-21",
    tvpi: 4.11,
    marketTvpi: 3.47,
    nav: 1627560000,
    marketNav: 1373662196,
    percentOfNav: 84,
  },
  {
    date: "2022-10-28",
    tvpi: 4.11,
    marketTvpi: 3.49,
    nav: 1627560000,
    marketNav: 1382738710,
    percentOfNav: 85,
  },
  {
    date: "2022-11-04",
    tvpi: 4.11,
    marketTvpi: 3.48,
    nav: 1627560000,
    marketNav: 1378620012,
    percentOfNav: 85,
  },
  {
    date: "2022-11-11",
    tvpi: 4.11,
    marketTvpi: 3.41,
    nav: 1627560000,
    marketNav: 1351842829,
    percentOfNav: 83,
  },
  {
    date: "2022-11-18",
    tvpi: 4.11,
    marketTvpi: 3.47,
    nav: 1627560000,
    marketNav: 1373903537,
    percentOfNav: 84,
  },
  {
    date: "2022-11-25",
    tvpi: 4.11,
    marketTvpi: 3.43,
    nav: 1627560000,
    marketNav: 1358802109,
    percentOfNav: 83,
  },
  {
    date: "2022-12-02",
    tvpi: 4.11,
    marketTvpi: 3.38,
    nav: 1627560000,
    marketNav: 1339812183,
    percentOfNav: 82,
  },
  {
    date: "2022-12-09",
    tvpi: 4.11,
    marketTvpi: 3.4,
    nav: 1627560000,
    marketNav: 1348161155,
    percentOfNav: 83,
  },
  {
    date: "2022-12-16",
    tvpi: 4.11,
    marketTvpi: 3.43,
    nav: 1627560000,
    marketNav: 1357264416,
    percentOfNav: 83,
  },
  {
    date: "2022-12-23",
    tvpi: 4.11,
    marketTvpi: 3.52,
    nav: 1627560000,
    marketNav: 1392649825,
    percentOfNav: 86,
  },
  {
    date: "2022-12-30",
    tvpi: 4,
    marketTvpi: 3.43,
    nav: 1584000000,
    marketNav: 1356786357,
    percentOfNav: 86,
  },
  {
    date: "2023-01-06",
    tvpi: 4,
    marketTvpi: 3.49,
    nav: 1584000000,
    marketNav: 1380343491,
    percentOfNav: 87,
  },
  {
    date: "2023-01-13",
    tvpi: 4,
    marketTvpi: 3.5,
    nav: 1584000000,
    marketNav: 1387689415,
    percentOfNav: 88,
  },
  {
    date: "2023-01-20",
    tvpi: 4,
    marketTvpi: 3.49,
    nav: 1584000000,
    marketNav: 1381462921,
    percentOfNav: 87,
  },
  {
    date: "2023-01-27",
    tvpi: 4,
    marketTvpi: 3.48,
    nav: 1584000000,
    marketNav: 1376199936,
    percentOfNav: 87,
  },
  {
    date: "2023-02-03",
    tvpi: 4,
    marketTvpi: 3.53,
    nav: 1584000000,
    marketNav: 1398214601,
    percentOfNav: 88,
  },
  {
    date: "2023-02-10",
    tvpi: 4,
    marketTvpi: 3.53,
    nav: 1584000000,
    marketNav: 1397689638,
    percentOfNav: 88,
  },
  {
    date: "2023-02-17",
    tvpi: 4,
    marketTvpi: 3.49,
    nav: 1584000000,
    marketNav: 1383971273,
    percentOfNav: 87,
  },
  {
    date: "2023-02-24",
    tvpi: 4,
    marketTvpi: 3.49,
    nav: 1584000000,
    marketNav: 1381411074,
    percentOfNav: 87,
  },
  {
    date: "2023-03-03",
    tvpi: 4,
    marketTvpi: 3.46,
    nav: 1584000000,
    marketNav: 1371549432,
    percentOfNav: 87,
  },
  {
    date: "2023-03-10",
    tvpi: 4,
    marketTvpi: 3.47,
    nav: 1584000000,
    marketNav: 1374952291,
    percentOfNav: 87,
  },
  {
    date: "2023-03-17",
    tvpi: 4,
    marketTvpi: 3.52,
    nav: 1584000000,
    marketNav: 1393359979,
    percentOfNav: 88,
  },
  {
    date: "2023-03-24",
    tvpi: 4,
    marketTvpi: 3.57,
    nav: 1584000000,
    marketNav: 1412872762,
    percentOfNav: 89,
  },
  {
    date: "2023-03-31",
    tvpi: 4.25,
    marketTvpi: 3.73,
    nav: 1683000000,
    marketNav: 1478286837,
    percentOfNav: 88,
  },
  {
    date: "2023-04-07",
    tvpi: 4.25,
    marketTvpi: 3.78,
    nav: 1683000000,
    marketNav: 1497793115,
    percentOfNav: 89,
  },
  {
    date: "2023-04-14",
    tvpi: 4.25,
    marketTvpi: 3.79,
    nav: 1683000000,
    marketNav: 1501684331,
    percentOfNav: 89,
  },
  {
    date: "2023-04-21",
    tvpi: 4.25,
    marketTvpi: 3.77,
    nav: 1683000000,
    marketNav: 1494708882,
    percentOfNav: 89,
  },
  {
    date: "2023-04-28",
    tvpi: 4.25,
    marketTvpi: 3.73,
    nav: 1683000000,
    marketNav: 1478390896,
    percentOfNav: 88,
  },
  {
    date: "2023-05-05",
    tvpi: 4.25,
    marketTvpi: 3.73,
    nav: 1683000000,
    marketNav: 1478528208,
    percentOfNav: 88,
  },
  {
    date: "2023-05-12",
    tvpi: 4.25,
    marketTvpi: 3.81,
    nav: 1683000000,
    marketNav: 1507445112,
    percentOfNav: 90,
  },
  {
    date: "2023-05-19",
    tvpi: 4.25,
    marketTvpi: 3.83,
    nav: 1683000000,
    marketNav: 1517464646,
    percentOfNav: 90,
  },
  {
    date: "2023-05-26",
    tvpi: 4.25,
    marketTvpi: 3.73,
    nav: 1683000000,
    marketNav: 1478567983,
    percentOfNav: 88,
  },
  {
    date: "2023-06-02",
    tvpi: 4.25,
    marketTvpi: 3.81,
    nav: 1683000000,
    marketNav: 1509242647,
    percentOfNav: 90,
  },
  {
    date: "2023-06-09",
    tvpi: 4.25,
    marketTvpi: 3.78,
    nav: 1683000000,
    marketNav: 1495514308,
    percentOfNav: 89,
  },
  {
    date: "2023-06-16",
    tvpi: 4.25,
    marketTvpi: 3.82,
    nav: 1683000000,
    marketNav: 1514564006,
    percentOfNav: 90,
  },
  {
    date: "2023-06-23",
    tvpi: 4.25,
    marketTvpi: 3.78,
    nav: 1683000000,
    marketNav: 1498813217,
    percentOfNav: 89,
  },
  {
    date: "2023-06-30",
    tvpi: 4.3,
    marketTvpi: 3.83,
    nav: 1702800000,
    marketNav: 1514764980,
    percentOfNav: 89,
  },
  {
    date: "2023-07-07",
    tvpi: 4.3,
    marketTvpi: 3.83,
    nav: 1702800000,
    marketNav: 1515832682,
    percentOfNav: 89,
  },
  {
    date: "2023-07-14",
    tvpi: 4.3,
    marketTvpi: 3.83,
    nav: 1702800000,
    marketNav: 1515345730,
    percentOfNav: 89,
  },
  {
    date: "2023-07-21",
    tvpi: 4.3,
    marketTvpi: 3.8,
    nav: 1702800000,
    marketNav: 1502897671,
    percentOfNav: 88,
  },
  {
    date: "2023-07-28",
    tvpi: 4.3,
    marketTvpi: 3.77,
    nav: 1702800000,
    marketNav: 1493066062,
    percentOfNav: 88,
  },
  {
    date: "2023-08-04",
    tvpi: 4.3,
    marketTvpi: 3.78,
    nav: 1702800000,
    marketNav: 1495748214,
    percentOfNav: 88,
  },
  {
    date: "2023-08-11",
    tvpi: 4.3,
    marketTvpi: 3.84,
    nav: 1702800000,
    marketNav: 1520540935,
    percentOfNav: 89,
  },
  {
    date: "2023-08-18",
    tvpi: 4.3,
    marketTvpi: 3.87,
    nav: 1702800000,
    marketNav: 1534064945,
    percentOfNav: 90,
  },
  {
    date: "2023-08-25",
    tvpi: 4.3,
    marketTvpi: 3.97,
    nav: 1702800000,
    marketNav: 1570653568,
    percentOfNav: 92,
  },
  {
    date: "2023-09-01",
    tvpi: 4.3,
    marketTvpi: 3.97,
    nav: 1702800000,
    marketNav: 1571361448,
    percentOfNav: 92,
  },
  {
    date: "2023-09-08",
    tvpi: 4.3,
    marketTvpi: 3.93,
    nav: 1702800000,
    marketNav: 1555805663,
    percentOfNav: 91,
  },
  {
    date: "2023-09-15",
    tvpi: 4.3,
    marketTvpi: 3.96,
    nav: 1702800000,
    marketNav: 1569217745,
    percentOfNav: 92,
  },
  {
    date: "2023-09-22",
    tvpi: 4.3,
    marketTvpi: 4.0,
    nav: 1702800000,
    marketNav: 1585024638,
    percentOfNav: 93,
  },
  {
    date: "2023-09-29",
    tvpi: 4.32,
    marketTvpi: 4.08,
    nav: 1710720000,
    marketNav: 1616283901,
    percentOfNav: 94,
  },
  {
    date: "2023-10-06",
    tvpi: 4.32,
    marketTvpi: 4.07,
    nav: 1710720000,
    marketNav: 1612908396,
    percentOfNav: 94,
  },
  {
    date: "2023-10-13",
    tvpi: 4.32,
    marketTvpi: 4.09,
    nav: 1710720000,
    marketNav: 1621104523,
    percentOfNav: 95,
  },
  {
    date: "2023-10-20",
    tvpi: 4.32,
    marketTvpi: 4.07,
    nav: 1710720000,
    marketNav: 1613297588,
    percentOfNav: 94,
  },
  {
    date: "2023-10-27",
    tvpi: 4.32,
    marketTvpi: 4.06,
    nav: 1710720000,
    marketNav: 1608679349,
    percentOfNav: 94,
  },
  {
    date: "2023-11-03",
    tvpi: 4.32,
    marketTvpi: 4.06,
    nav: 1710720000,
    marketNav: 1607269864,
    percentOfNav: 94,
  },
  {
    date: "2023-11-10",
    tvpi: 4.32,
    marketTvpi: 4.07,
    nav: 1710720000,
    marketNav: 1612841213,
    percentOfNav: 94,
  },
  {
    date: "2023-11-17",
    tvpi: 4.32,
    marketTvpi: 4.06,
    nav: 1710720000,
    marketNav: 1606045202,
    percentOfNav: 94,
  },
  {
    date: "2023-11-24",
    tvpi: 4.32,
    marketTvpi: 4.09,
    nav: 1710720000,
    marketNav: 1620860601,
    percentOfNav: 95,
  },
  {
    date: "2023-12-01",
    tvpi: 4.32,
    marketTvpi: 4.09,
    nav: 1710720000,
    marketNav: 1618026370,
    percentOfNav: 95,
  },
  {
    date: "2023-12-08",
    tvpi: 4.32,
    marketTvpi: 4.07,
    nav: 1710720000,
    marketNav: 1613038621,
    percentOfNav: 94,
  },
  {
    date: "2023-12-15",
    tvpi: 4.32,
    marketTvpi: 4.08,
    nav: 1710720000,
    marketNav: 1616392353,
    percentOfNav: 94,
  },
  {
    date: "2023-12-22",
    tvpi: 4.32,
    marketTvpi: 4.03,
    nav: 1710720000,
    marketNav: 1595077063,
    percentOfNav: 93,
  },
  {
    date: "2023-12-29",
    tvpi: 4.32,
    marketTvpi: 4.03,
    nav: 1710720000,
    marketNav: 1597347062,
    percentOfNav: 93,
  },
] as TVPI_CHART[];
