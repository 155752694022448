import DemoDataRoomChart from "experiences/common/DemoDataRoomChart";

export const GpTransactionDetailDataroomCashFlowsChart = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-4 px-8 pt-8">
        <div className="flex flex-row gap-2 items-center">
          <div className="rounded-full bg-nomad-300 inline-block h-2 w-2"></div>
          <p className="text-sm font-medium">Distributions</p>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="rounded-full bg-stone-500 inline-block h-2 w-2"></div>
          <p className="text-sm font-medium">Contributions</p>
        </div>
      </div>
      <div className="flex inline-block p-8">
        <DemoDataRoomChart />
      </div>
    </div>
  );
};
