export enum LPPortal {
  Arch = 1,
  SnP = 2,
  Canoe = 3,
  Cobalt = 4,
  Solovis = 5,
  Burgiss = 6,
  Efront = 7,
  Aksia = 8, // Aksia is not shown in the app now
  StateStreet = 9,
  NorthernTrust = 10,
  Addepar = 11,
  StepStone = 12,
  BnyMellon = 13,
}

export type LPPortalOption = LPPortal | string;
