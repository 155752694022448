import { useNavigate } from "react-router";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import { LP_ROUTES } from "common/constants/routes";
import { useFundsContext } from "../state/FundsContext";
import { Alert } from "../../domain/models/Alert";
import { AlertsPageLoaded } from "../state/FundsState";
import { FundFilterSpecs } from "../../domain/models/IssuerFilterSpecs";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import { AlertActivationToggled } from "../state/FundsEvent";
import { IOSSwitch } from "../../../../common/components/IOSSwitch";
import {
  FundGeography,
  FundGeographyNameMap,
} from "../../../common/models/FundGeography";
import {
  FundStrategy,
  FundStrategyNameMap,
} from "../../../common/models/FundStrategy";
import { TapTable, TableAlignment, TableCell } from "../components/Table";
import { PageHead } from "common/components/PageHead";
import Loader from "common/components/TapLoader";
import TextChip from "experiences/common/TextChip";

// this prepares an object that will be translated into query params
// so that the fund list page can be opened with the same filters
const convertSpecsToQueryElements = (specs: FundFilterSpecs) => {
  const {
    strategies,
    vintageRange: { start: vintageStart, end: vintageEnd } = {
      start: undefined,
      end: undefined,
    },
    geographies,
    sizeRange: { start: fundSizeStart, end: fundSizeEnd } = {
      start: undefined,
      end: undefined,
    },
    searchTerm: search,
  } = specs;

  return {
    strategies,
    vintageStart,
    vintageEnd,
    geographies,
    fundSizeStart,
    fundSizeEnd,
    search,
  };
};

export const generateQueryString = ({
  strategies,
  vintageStart,
  vintageEnd,
  geographies,
  fundSizeStart,
  fundSizeEnd,
  search,
}: {
  strategies?: FundStrategy[];
  vintageStart?: number;
  vintageEnd?: number;
  geographies?: FundGeography[];
  fundSizeStart?: number;
  fundSizeEnd?: number;
  search?: string;
}) => {
  const queryData = {
    ...(strategies && strategies.length
      ? { strategies: strategies.join(",") }
      : {}),
    ...(!!vintageStart ? { vintageStart: String(vintageStart) } : {}),
    ...(!!vintageEnd ? { vintageEnd: String(vintageEnd) } : {}),
    ...(geographies && geographies.length
      ? { geographies: geographies.join(",") }
      : {}),
    ...(!!fundSizeStart ? { fundSizeStart: String(fundSizeStart) } : {}),
    ...(!!fundSizeEnd ? { fundSizeEnd: String(fundSizeEnd) } : {}),
    ...(!!search ? { search } : {}),
  };

  return new URLSearchParams(queryData).toString();
};

export const AlertsTable = () => {
  const { emitEvent, alertTablePageState } = useFundsContext();
  const navigate = useNavigate();
  const alertsLoaded = alertTablePageState instanceof AlertsPageLoaded;

  const alerts = alertTablePageState as AlertsPageLoaded;

  return (
    <>
      <Helmet title="Alerts" />
      <PageHead
        title="Alerts"
        countLabel={alertsLoaded ? alerts.alerts.length : undefined}
      />
      {alertsLoaded ? (
        <TapTable
          emptyStateTitle="You haven't created any alerts yet"
          data={alerts.alerts} // TODO: update types so AlertsPageLoaded is properly hinted and we can extract alerts.alerts directly
          columns={[
            {
              key: "name",
              label: "Name",
              align: TableAlignment.LEFT,
            },
            {
              key: "filters",
              label: "Filters",
              align: TableAlignment.LEFT,
            },
            {
              key: "alerts",
              label: "Alerts",
              align: TableAlignment.LEFT,
            },
          ]}
          renderRow={(alert: Alert) => {
            const queryParams = convertSpecsToQueryElements(alert.fundFilters);
            const queryDataToString = generateQueryString(queryParams);

            const handleClick = () => {
              navigate(`${LP_ROUTES.FundsScreen}?${queryDataToString}`);
            };

            return (
              <>
                <TableCell hasBullet isPrimaryColumn onClick={handleClick}>
                  {alert.name}
                </TableCell>
                <TableCell wrap onClick={handleClick}>
                  <FundFiltersChips
                    filters={alert.fundFilters}
                    dealSize={alert.dealSize}
                  />
                </TableCell>
                <TableCell>
                  <IOSSwitch
                    checked={alert.isActive}
                    onChange={() => {
                      emitEvent!(
                        new AlertActivationToggled({
                          alertId: alert.id,
                        }),
                      );
                    }}
                  />
                </TableCell>
              </>
            );
          }}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

interface IFundsFiltersChipsProps {
  filters: FundFilterSpecs;
  dealSize?: number;
}

const FundFiltersChips: React.FC<IFundsFiltersChipsProps> = ({
  filters,
  dealSize,
}) => {
  const showGeography = filters.geographies && filters.geographies.length;
  const showStrategy = filters.strategies && filters.strategies.length;
  const showVintage = filters.vintageRange?.start || filters.vintageRange?.end;
  const showSize = filters.sizeRange?.start || filters.sizeRange?.end;
  const showDealSize = Boolean(dealSize);
  return (
    <Box>
      {showVintage && (
        <RangeChip
          dateTerminology
          label="Vintage"
          start={filters.vintageRange?.start}
          end={filters.vintageRange?.end}
        />
      )}
      {showSize && (
        <RangeChip
          label="Size"
          start={
            filters.sizeRange?.start
              ? new DollarAmount(filters.sizeRange?.start).formattedBig()
              : undefined
          }
          end={
            filters.sizeRange?.end
              ? new DollarAmount(filters.sizeRange?.end).formattedBig()
              : undefined
          }
        />
      )}
      {showGeography ? (
        <ArrayChip
          label="Geography"
          values={filters.geographies!.map(
            (geo) => FundGeographyNameMap.get(geo) as string,
          )}
        />
      ) : (
        <></>
      )}
      {showStrategy ? (
        <ArrayChip
          label="Strategy"
          values={filters.strategies!.map(
            (stra) => FundStrategyNameMap.get(stra) as string,
          )}
        />
      ) : (
        <></>
      )}
      {filters.searchTerm && (
        <ChipContainer text={`Search Term: ${filters.searchTerm}`} />
      )}
      {showDealSize && (
        <ChipContainer
          text={`Deal Size: ${new DollarAmount(dealSize!).formattedBig()}`}
        />
      )}
    </Box>
  );
};

interface IArrayChip {
  label: string;
  values: string[];
}

export const ArrayChip: React.FC<IArrayChip> = ({ label, values }) => {
  return <ChipContainer text={`${label}: ${values.join(", ")}`} />;
};

interface IRangeChip {
  dateTerminology?: boolean;
  label: string;
  start?: string | number;
  end?: string | number;
}

export const RangeChip: React.FC<IRangeChip> = ({
  label,
  start,
  end,
  dateTerminology = false,
}) => {
  if (end && start) return <ChipContainer text={`${label}: ${start}-${end}`} />;
  if (dateTerminology) {
    if (end) return <ChipContainer text={`${label}: ${end} and before`} />;
    return <ChipContainer text={`${label}: ${start} and after`} />;
  }

  if (end) return <ChipContainer text={`${label}: less than ${end}`} />;
  return <ChipContainer text={`${label}: greater than ${start}`} />;
};

interface IChipContainer {
  text: string;
}
export const ChipContainer: React.FC<IChipContainer> = ({ text }) => {
  return <TextChip text={text} regularCase size="medium" />;
};
