import { createContext, useContext } from "react";

import { ComplianceState } from "./ComplianceState";
import { useComplianceState } from "./ComplianceManager";
import { GetQmsList } from "experiences/compliance/domain/usecases/GetQmsList";
import { GetFairnessOpinionList } from "experiences/compliance/domain/usecases/GetFairnessOpinionList";
import { GetFirmOverview } from "experiences/compliance/domain/usecases/GetFirmOverview";

export const ComplianceContext = createContext<{
  emitEvent?: (event: ComplianceState) => void;
  qmsListState?: ComplianceState;
  fairnessOpinionListState?: ComplianceState;
  firmOverviewState?: ComplianceState;
}>({});

export const useComplianceContext = () => useContext(ComplianceContext);

export const ComplianceStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    emitEvent,
    qmsListState,
    fairnessOpinionListState,
    firmOverviewState,
  } = useComplianceState({
    getQmsList: new GetQmsList(),
    getFairnessOpinionList: new GetFairnessOpinionList(),
    getFirmOverview: new GetFirmOverview(),
  });

  return (
    <ComplianceContext.Provider
      value={{
        emitEvent,
        qmsListState,
        fairnessOpinionListState,
        firmOverviewState,
      }}
    >
      {children}
    </ComplianceContext.Provider>
  );
};
