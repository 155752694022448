import { ArrowRight, CircleNotch, X } from "@phosphor-icons/react";
import { BarLoader } from "common/components/BarLoader";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { IPortfolioDocument } from "experiences/portfolio-v2/domain/models/PortfolioDocuments";
import { IPortfolioHolding } from "experiences/portfolio-v2/domain/models/PortfolioHoldings";
import { AddFundInterestModal } from "experiences/transactions/presentation/components/fund-data/verify-permission/AddFundInterestModal";
import { SelectFundHoldingsDropdown } from "experiences/transactions/presentation/components/fund-data/verify-permission/SelectFundHoldingsDropdown";
import { useClassifyDocuments } from "./useClassifyDocuments";

export const UploadDocumentsStep1 = ({
  goToNextStep,
  goToFirstStep,
}: {
  goToNextStep: () => void;
  goToFirstStep: () => void;
}) => {
  const {
    showAddFundInterest,
    openAddFundInterestModal,
    closeAddFundInterestModal,
    holdings,
    form,
    refetchHoldings,
    documentsLoading,
    onHoldingClick,
    unprocessedDocuments,
    removeDocument,
    onHoldingAdded,
    currentFileId,
  } = useClassifyDocuments({
    goToNextStep,
    goToFirstStep,
  });

  return (
    <>
      <div className="w-screen box-border p-8 h-full">
        <div className="!min-h-full flex-1 flex flex-col gap-8 w-full max-w-5xl mx-auto">
          <div className="h-full w-full">
            <PageHead
              paddingless
              title="Classify Documents"
              description={
                <>
                  Add basic information for the funds you would like to analyze
                  to ensure they are mapped correctly.
                </>
              }
            />
          </div>

          {documentsLoading ? (
            <BarLoader className="min-h-96" />
          ) : (
            <>
              <TapTable
                data={unprocessedDocuments}
                isLoading={documentsLoading}
                columns={[
                  {
                    label: "Document Name",
                    align: TableAlignment.LEFT,
                    key: "documentName",
                  },
                  {
                    label: "Fund Interest",
                    key: "fundInterest",
                    align: TableAlignment.LEFT,
                    tooltip:
                      "Check that the documents are connected to the correct fund interest in the transaction. This will ensure the data is correctly extracted for each interest.",
                  },
                  {
                    label: "",
                    key: "actions",
                    align: TableAlignment.CENTER,
                    className: "!w-[74px]",
                  },
                ]}
                className="border rounded-sm"
                layoutFixed
                renderRow={(doc: IPortfolioDocument) => {
                  return (
                    <>
                      <TableCell
                        className="!whitespace-normal !leading-5"
                        isPrimaryColumn
                      >
                        {doc.fileName}
                      </TableCell>
                      <TableCell>
                        <SelectFundHoldingsDropdown
                          onSelect={(holding: IPortfolioHolding) => {
                            onHoldingClick(doc, holding);
                          }}
                          value={form.values[`${doc.fileId}-holdingId`]}
                          holdings={holdings}
                          handleAddHoldingClick={openAddFundInterestModal}
                          className="w-full max-w-96"
                          fileId={doc.fileId}
                        />

                        {form.errors[`${doc.fileId}-holdingId`] && (
                          <p className="flex text-red-500 select-none mt-2">
                            {form.errors[`${doc.fileId}-holdingId`]}
                          </p>
                        )}
                      </TableCell>
                      <TableCell className="text-center">
                        <Button
                          icon={<X />}
                          onClick={() => removeDocument(doc)}
                        />
                      </TableCell>
                    </>
                  );
                }}
              />
            </>
          )}

          <div className="flex justify-end pb-12">
            <Button
              onClick={() => {
                // because this is not ready yet we'll skip the form submission
                form.handleSubmit();
                goToNextStep();
              }}
              type={BUTTON_TYPES.SECONDARY}
              icon={
                form.isSubmitting ? (
                  <CircleNotch className="animate-spin" />
                ) : (
                  <ArrowRight />
                )
              }
              disabled={form.isSubmitting || unprocessedDocuments.length === 0}
              iconRight
              size="large"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <AddFundInterestModal
        handleClose={closeAddFundInterestModal}
        open={showAddFundInterest}
        refetchHoldings={refetchHoldings}
        onHoldingAdded={onHoldingAdded}
        forPortfolio
        currentFileId={currentFileId}
      />
    </>
  );
};
