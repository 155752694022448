import { gql } from "@apollo/client";

export interface ICompanyData {
  companyName: string | null;
  companyId: string | null;
  tapCompanyId: string | null;
  lpImpliedInvestmentValue: null;
  lpImpliedInvestmentPercentage: null;
  fundName: string | null;
  tapFundId: string | null;
  tapInvestmentId: string | null;
  reportDate: string | null;
  companyCurrency: string | null;
  fundOwnershipPercent: null;
  equityValue: string | null;
  enterpriseValue: string | null;
  revenueLtm: number | null;
  revenueLatestAnnual: number | null;
  revenueYoyPercentChgLtm: number | null;
  revenueYoyPercentChgAnnual: number | null;
  revenueProjected: number | null;
  ebitdaLtm: number | null;
  ebitdaLatestAnnual: number | null;
  ebitdaYoyPercentChgLtm: number | null;
  ebitdaYoyPercentChgAnnual: number | null;
  ebitdaProjected: number | null;
  ebitdaLtmMargin: number | null;
  companyCash: number | null;
  companyDebt: number | null;
  companyNetDebt: number | null;
  capexLtm: number | null;
  capexLatestAnnual: number | null;
  ebitdaMultipleEntry: number | null;
  ebitdaMultipleLtm: string | null;
  leverageMultipleEntry: number | null;
  leverageMultipleLtm: string | null;
  revenueMultipleEntry: number | null;
  revenueMultipleLtm: number | null;
  companyDescription: string | null;
  isHumanVerified: true;
}

export interface ICompanyDataQuery {
  companyData: ICompanyData;
}

export const GetCompanyDataQuery = gql`
  query Query($companyId: String!, $reportDate: Date!) {
    companyData(companyId: $companyId, reportDate: $reportDate) {
      companyName
      companyId
      tapCompanyId
      lpImpliedInvestmentValue
      lpImpliedInvestmentPercentage
      fundName
      tapFundId
      tapInvestmentId
      reportDate
      companyCurrency
      fundOwnershipPercent
      equityValue
      enterpriseValue
      revenueLtm
      revenueLatestAnnual
      revenueYoyPercentChgLtm
      revenueYoyPercentChgAnnual
      revenueProjected
      ebitdaLtm
      ebitdaLatestAnnual
      ebitdaYoyPercentChgLtm
      ebitdaYoyPercentChgAnnual
      ebitdaProjected
      ebitdaLtmMargin
      companyCash
      companyDebt
      companyNetDebt
      capexLtm
      capexLatestAnnual
      ebitdaMultipleEntry
      ebitdaMultipleLtm
      leverageMultipleEntry
      leverageMultipleLtm
      revenueMultipleEntry
      revenueMultipleLtm
      companyDescription
      isHumanVerified
    }
  }
`;
