import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";

import { ListTPTransactions } from "experiences/transactions/domain/usecases/tp/ListTransactions";
import {
  TPTransactionListLoadFailed,
  TPTransactionListLoaded,
  TPTransactionListLoading,
  TPTransactionsState,
} from "./TPTransactionsState";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import {
  TPTransactionsEvent,
  TPTransactionsListRequested,
} from "./TPTransactionsEvent";

import { ITPTransaction } from "../../tp/pages/TpTransactionListPage";
import { TPTransactionsProps } from "../TransactionsContext";

export interface IUseCases {
  listTPTransactions: ListTPTransactions;
}

export const useTPTransactionsState = ({
  listTPTransactions,
}: IUseCases): TPTransactionsProps => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [tpTxnsListState, setTpTxnsListState] = useState<TPTransactionsState>();

  const refreshTransactions = () => {
    setTpTxnsListState(new TPTransactionListLoading());
    const t = listTPTransactions.call({}).then((resp) => {
      match<Failure, ITPTransaction[], void>(
        (e: Failure) => {
          setTpTxnsListState(new TPTransactionListLoadFailed(e));
        },
        (transactions: ITPTransaction[]) => {
          setTpTxnsListState(new TPTransactionListLoaded({ transactions }));
        },
      )(resp);
    });
  };

  // event emitter
  let prevEvent: TPTransactionsEvent;
  const emitEvent = (event: TPTransactionsEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof TPTransactionsListRequested) {
      refreshTransactions();
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    transactionListState: tpTxnsListState,
  };
};
