import { useParams } from "react-router";
import { Box, Button, Stack, Typography } from "@mui/material";

import Loader from "common/components/TapLoader";
import { useTransactionsContext } from "../../state/TransactionsContext";
import { BidDetailsScreenLoaded } from "../../state/TransactionsState";
import {
  Bid,
  PartialBid,
} from "experiences/transactions/domain/models/Bidding";
import ReviewDocumentSection from "./ReviewDocumentSection";
import ReviewPricingTable from "./ReviewPricingTable";
import { StepHeader } from "../StepHeader";
import { DeleteBidButtonClicked } from "../../state/TransactionsEvent";

import { AppLayoutWithTopMenu } from "experiences/common/AppLayoutWithTopMenu";

interface IBidDetailsScreen {
  onClose: () => void;
  bid: Bid;
}

const BidDetailsScreen: React.FC<IBidDetailsScreen> = ({ onClose, bid }) => {
  const { bidDetailsScreenState, emitEvent } = useTransactionsContext();
  const { txnId } = useParams();

  return (
    <AppLayoutWithTopMenu centeredContent onClose={onClose}>
      {bidDetailsScreenState instanceof BidDetailsScreenLoaded ? (
        <Stack spacing={4} py={4}>
          <Stack>
            <StepHeader text="Review Bid" />
            <Typography>Submitted bids cannot be edited</Typography>
          </Stack>
          <ReviewDocumentSection document={bid.document!} />
          <Stack spacing={2}>
            <ReviewPricingTable
              fullBid={bid}
              holdings={bidDetailsScreenState.holdings}
            />
            <Typography sx={{ pl: 2 }}>
              {bid instanceof PartialBid
                ? "Partial Sale Bid"
                : "Whole Portfolio Bid"}
            </Typography>
          </Stack>
          <Box />
          {!bid.isSettling() && (
            <Button
              size="large"
              sx={{
                width: "120px",
                border: "2px solid #DBDCDA",
              }}
              onClick={() => {
                emitEvent!(
                  new DeleteBidButtonClicked({ txnId: txnId!, bidId: bid.id! }),
                );
                onClose();
              }}
            >
              Delete Bid
            </Button>
          )}
        </Stack>
      ) : (
        <Loader />
      )}
    </AppLayoutWithTopMenu>
  );
};

export default BidDetailsScreen;
