export interface IManager {
  id: string;
  name: string;
  type: ManagerType;
}

export enum ManagerType {
  Manager = 1,
}

export const ManagerTypeString = {
  [ManagerType.Manager]: "MANAGER",
};
