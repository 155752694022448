import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IParticipant, ParticipantPhase } from "../models/Participant";

export class ListTransactionParticipants
  implements IAsyncUseCase<IParams, NoReturn>
{
  call: (params: IParams) => Promise<Either<Failure, IParticipant[]>> = async ({
    txnId,
  }) => {
    return await new TapClient(`/transactions/${txnId}/participants/list/`)
      .get({})
      .then((resp: IParticipant[]) => {
        return right(
          resp.filter(
            (participant) =>
              participant.currentPhase != ParticipantPhase.BidSubmitted,
          ),
        );
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  txnId: string;
}
