import { Route, Routes } from "react-router";

import { TransactionsStateProvider } from "experiences/transactions/presentation/state/TransactionsContext";

import AppLayout from "./AppLayout";
import { UniversalRoutes } from "./UniversalRoutes";
import { ContentWrapper } from "common/components/WidthWrapper";
import { RequireAuth } from "experiences/authentication/presentation/components/RequireAuth";

import { THIRD_PARTY_ROUTES } from "common/constants/routes";
import Loader from "common/components/TapLoader";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

import { TpTransactionListPage } from "experiences/transactions/presentation/tp/pages/TpTransactionListPage";
import CreateTransactionForm from "experiences/transactions/presentation/tp/components/CreateTransactionForm";
import { TransactionDetails } from "experiences/transactions/presentation/components/TransactionDetails";

export const TPRoutes = () => {
  const { userIsLoading } = useAuthContext();

  if (userIsLoading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path={`${THIRD_PARTY_ROUTES.TransactionDetail}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TransactionDetails />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${THIRD_PARTY_ROUTES.Transactions}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <TpTransactionListPage />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${THIRD_PARTY_ROUTES.CreateTransaction}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>
                  <CreateTransactionForm />
                </ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
        <Route
          path={`${THIRD_PARTY_ROUTES.Compliance}/*`}
          element={
            <RequireAuth>
              <TransactionsStateProvider>
                <ContentWrapper fullWidth>compliance page asdf</ContentWrapper>
              </TransactionsStateProvider>
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<UniversalRoutes />} />
    </Routes>
  );
};
