import { Slider, Stack } from "@mui/material";
import SelectDropDown from "experiences/common/SelectDropDown";
import { useFundsContext } from "experiences/funds/presentation/state/FundsContext";
import { VintageFilterChanged } from "experiences/funds/presentation/state/FundsEvent";
import FilterPill from "./FilterPill";

export const VintageFilter = () => {
  const { emitEvent, filtersSpecs } = useFundsContext();

  const minYear = 2002;
  const maxYear = 2023;

  const emitFilterEvent = (from: number, to: number) => {
    if (
      filtersSpecs?.vintageRange?.start != from ||
      filtersSpecs?.vintageRange?.end != to
    )
      emitEvent!(
        new VintageFilterChanged({
          vintageRange:
            from == minYear && to == maxYear
              ? undefined
              : { start: from, end: to },
        }),
      );
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    emitFilterEvent((newValue as number[])[0], (newValue as number[])[1]);
  };

  function valuetext(value: number) {
    return `${value}`;
  }

  const getDropDownVal = () => {
    let currentFrom = filtersSpecs?.vintageRange?.start ?? minYear;
    let currentTo = filtersSpecs?.vintageRange?.end ?? maxYear;
    return `${currentFrom} - ${currentTo}`;
  };

  const showClearOption =
    filtersSpecs?.vintageRange?.start ||
    filtersSpecs?.vintageRange?.start == minYear ||
    filtersSpecs?.vintageRange?.end ||
    filtersSpecs?.vintageRange?.end == maxYear;

  const clearVintage = () => {
    emitEvent!(new VintageFilterChanged({ vintageRange: {} }));
  };

  return (
    <FilterPill
      title="Vintage"
      rightText={getDropDownVal()}
      menuWidth={394}
      clearFn={clearVintage}
      showClear={showClearOption}
    >
      <Stack width={360} height={80} justifyContent="flex-end" px={3} py={1}>
        <Slider
          step={1}
          marks
          min={minYear}
          max={maxYear}
          value={[
            filtersSpecs?.vintageRange?.start ?? minYear,
            filtersSpecs?.vintageRange?.end ?? maxYear,
          ]}
          onChange={handleChange}
          valueLabelDisplay="on"
          getAriaValueText={valuetext}
        />
      </Stack>
    </FilterPill>
  );
};

export default VintageFilter;
