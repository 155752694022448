import { classNames } from "primereact/utils";
import styled from "styled-components";

const StyledTextChip = styled.span`
  position: relative;
  display: inline-flex;
  padding: 6px 10px;
  box-sizing: border-box;
  border-radius: 59px;
  gap: 4px;
  width: fit-content;

  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: -0.08px;

  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  align-items: center;
  line-height: 100%;

  /* default styles */
  color: #737476;
  background-color: #f5f4f1;

  &.success {
    color: #63b966;
    background-color: rgba(133, 211, 136, 0.21);
  }

  &.danger {
    color: #f44336;
    background-color: rgba(244, 67, 54, 0.15);
  }

  &.pending {
    color: #fa963a;
    background-color: rgba(250, 150, 58, 0.15);
  }

  &.regular_case {
    text-transform: none;
  }

  &.small {
    font-size: 8px;
    line-height: 100%;
  }

  &.medium {
    font-size: 10px;
    line-height: 100%;
  }
`;

const TextChip = ({
  text,
  success,
  danger,
  pending,
  style,
  icon,
  regularCase,
  size = "small",
  className,
}: {
  text: React.ReactNode;
  success?: boolean;
  danger?: boolean;
  pending?: boolean;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  regularCase?: boolean;
  size?: "small" | "medium";
  className?: string;
}) => {
  return (
    <StyledTextChip
      className={classNames(
        size,
        {
          success: success,
          pending: pending,
          danger: danger,
          regular_case: regularCase,
        },
        className,
      )}
      style={style}
    >
      {icon}
      {text}
    </StyledTextChip>
  );
};

export default TextChip;
