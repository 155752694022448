import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import axios from "axios";

export class UploadHoldingsList implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    file,
  }) => {
    return await new TapClient(`/portfolio/holdings-list/url/get/`, {
      documentName: file.name
        .replaceAll(" ", "_")
        .replaceAll("(", "")
        .replaceAll(")", ""),
    })
      .post()
      .then((resp) => {
        const formData = new FormData();

        Object.keys(resp.uploadUrl.fields).map((key) => {
          formData.append(key, resp.uploadUrl.fields[key]);
        });

        formData.append("file", file);

        axios
          .post(resp.uploadUrl.url, formData, {
            headers: {
              "Content-Type": file.type,
            },
          })
          .catch(function (error) {
            return left(error);
          });

        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  file: File;
}
