import { gql } from "@apollo/client";

export const UploadTransactionClassifyDocuments = gql`
  mutation ClassifyDocuments(
    $transactionDocuments: [ClassifyDocumentType!]!
    $transactionId: String!
  ) {
    classifyDocuments(
      transactionDocuments: $transactionDocuments
      transactionId: $transactionId
    ) {
      fileName
      result
    }
  }
`;
