import React from "react";
import classNames from "classnames";

const HalfImageContentWrapper = ({
  children,
  appBar,
}: {
  children: React.ReactNode;
  appBar?: React.ReactNode;
}) => {
  return (
    <div className="w-full md:w-1/2 h-full max-h-full">
      {appBar}
      <div className="w-full h-full overflow-y-scroll no-scrollbar relative items-center justify-center">
        <div className="flex justify-center items-center h-full overflow-y-scroll box-border no-scrollbar">
          {children}
        </div>
      </div>
    </div>
  );
};

export const HalfImageLayout = ({
  children,
  imageUrl,
  contentOnLeft = false,
  appBar,
}: {
  children: React.ReactNode;
  imageUrl: string;
  contentOnLeft?: boolean;
  appBar?: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "w-full h-screen flex max-h-screen overflow-hidden max-w-screen md:flex-row",
        contentOnLeft ? "flex-row" : "flex-row-reverse",
      )}
    >
      <HalfImageContentWrapper appBar={appBar}>
        {children}
      </HalfImageContentWrapper>
      <img
        src={imageUrl}
        alt="City from the sky"
        className="hidden object-cover md:block md:w-1/2"
      />
    </div>
  );
};
