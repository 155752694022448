import {
  GP_ROUTES,
  GpTransactionDataRoomRoutes,
} from "common/constants/routes";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";

import { mockDR } from "experiences/dashboard/mock/DataRoom";
import DataRoom from "../components/data-room/DataRoom";
import { GpTransactionDataRoomDataPage } from "./dataroom/DataPage";

export const GpTransactionDataRoom = () => {
  const baseRoute = GP_ROUTES.TransactionDetailDataRoom;
  const params = useParams();

  return (
    <Routes>
      <Route
        path={GpTransactionDataRoomRoutes.Data}
        element={<GpTransactionDataRoomDataPage />}
      />
      <Route
        path={GpTransactionDataRoomRoutes.Documents}
        element={<DataRoom dataRoom={mockDR} />}
      />
      <Route
        path="/"
        element={
          <Navigate
            to={`${generatePath(
              `${baseRoute}${GpTransactionDataRoomRoutes.Data}`,
              {
                transactionId: params.transactionId,
              },
            )}`}
          />
        }
      />
    </Routes>
  );
};
