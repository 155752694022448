import styled from "styled-components";

export const ChartLegendGroup = styled.div`
  display: flex;
  gap: 16px;
  height: 40px;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
`;

export const ChartLegend = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  color: var(--branding-dark, #21272d);
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.2s ease-in-out;
  box-sizing: border-box;

  &:hover {
    background-color: #d7dce1;
  }

  svg {
    margin-right: 6px;
  }
`;
