import React from "react";
import styled from "styled-components";
import { FileDashed } from "@phosphor-icons/react";

import { cn } from "common/utils";
import { BUTTON_TYPES, Button } from "common/components/Button";

const StyledEmptyStateTitle = styled.h2`
  color: #21272d;
  font-family: "Inter", sans-serif;
`;

const StyledEmptyStateDescription = styled.p`
  color: #21272d;
  font-family: "Inter", sans-serif;
`;

const StyledEmptyState = styled.div`
  padding: 32px 32px 40px; // slightly larger padding on bottom to account for visual weight

  .empty_actions {
    display: flex;
    margin-top: 24px;
    text-align: center;
    justify-content: center;
  }
`;

export const EmptyState = ({
  icon,
  title,
  description,
  actions,
  style,
  className,
}: {
  icon?: React.ReactNode;
  title?: string;
  description?: React.ReactNode;
  actions?: {
    label: string;
    onClick: () => void;
    type: BUTTON_TYPES;
    icon?: React.ReactNode;
  }[];
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <StyledEmptyState
      style={style}
      className={cn(
        "flex flex-col items-center justify-center text-center w-full bg-white min-h-96 box-border",
        className,
      )}
    >
      {icon || (
        <FileDashed
          size={38}
          color="#998776"
          className="select-none"
          weight="thin"
        />
      )}
      {title && (
        <StyledEmptyStateTitle className="block mt-4 text-center font-medium leading-4 cursor-default select-none tracking-tight text-sm">
          {title}
        </StyledEmptyStateTitle>
      )}
      {description && (
        <StyledEmptyStateDescription className="block mt-2 text-center text-xs tracking-normal leading-5 cursor-default select-none">
          {description}
        </StyledEmptyStateDescription>
      )}
      {actions && (
        <div className="empty_actions">
          {actions.map((action) => (
            <Button
              key={action.label}
              onClick={action.onClick}
              type={action.type}
              icon={action.icon}
            >
              {action.label}
            </Button>
          ))}
        </div>
      )}
    </StyledEmptyState>
  );
};
