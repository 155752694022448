export enum StatusTag {
  LP = "LPs",
  Buyers = "BUYERS",
  All = "ALL",
}

export interface ILPTenderTimelineStep {
  titleText: string;
  dueText: string;
  startDate: string;
  dueDate: string;
  tag: StatusTag;
  bullets: string[];
  completed: boolean;
  current: boolean;
}

export const lpTenderTimelineSteps: ILPTenderTimelineStep[] = [
  {
    titleText: "Preliminary Period",
    dueText: "Preliminary Interest Due",
    startDate: "Begins April 1, 2024",
    dueDate: "April 17, 2024",
    tag: StatusTag.LP,
    bullets: [
      "Reach out to Limited Partners to gauge interest.",
      "Collect preliminary information on assets and capital accounts.",
    ],
    completed: false,
    current: true,
  },
  {
    titleText: "Round 1: Non-Binding Bids",
    dueText: "Non-Binding Bids Due",
    startDate: "Begins April 18, 2024",
    dueDate: "May 6, 2024",
    tag: StatusTag.Buyers,
    bullets: [
      "Provide buyers access to a virtual data room with fund documents and performance data.",
      "Allow buyers to conduct initial financial, legal, and compliance reviews.",
    ],
    completed: false,
    current: false,
  },
  {
    titleText: "Round 2: Binding Bids",
    dueText: "Binding Bids Due",
    startDate: "Begins May 9, 2024",
    dueDate: "May 14, 2024",
    tag: StatusTag.Buyers,
    bullets: [
      "Perform more detailed analysis on the fund's assets, liabilities, and operations.",
      "Hold an investor call with Viewpoint and potential buyers.",
      "Solicit final binding offers from buyers, including price and transaction terms.",
    ],
    completed: false,
    current: false,
  },
  {
    titleText: "Participation Period",
    dueText: "Participation Decisions Due",
    startDate: "Begins May 20, 2024",
    dueDate: "June 10, 2024",
    tag: StatusTag.LP,
    bullets: [
      "Present final offers to sellers for consideration.",
      "Sellers decide whether to accept offers based on price and terms.",
      "Execute commitment letters from sellers who accept the offers.",
    ],
    completed: false,
    current: false,
  },
  {
    titleText: "Closing Period",
    dueText: "Target Closing",
    startDate: "Begins June 15, 2024",
    dueDate: "June 30, 2024",
    tag: StatusTag.All,
    bullets: [
      "Finalize and execute purchase and sale agreements.",
      "Arrange for the transfer of funds from buyers to sellers.",
      "Complete the transfer of ownership interests in the PE fund.",
    ],
    completed: false,
    current: false,
  },
];

export interface IDocument {
  size: string;
  name: string;
  downloadLink: string;
}

export const documents: IDocument[] = [
  {
    size: "443KB",
    name: "Letter of Intent - Viewpoint Partners Fund III - 2024 Q2 Tender Offer",
    downloadLink:
      "https://tap-public-assets.s3.amazonaws.com/Letter+of+Intent+-+Viewpoint+Partners+Fund+III+-+2024+Q2+Tender+Offer.pdf",
  },
  {
    size: "903KB",
    name: "Tap Third Party Valuation Opinion - Viewpoint Partners Fund III - 2024 Q2 Tender Offer",
    downloadLink:
      "https://tap-public-assets.s3.amazonaws.com/Tap+Third+Party+Valuation+Opinion+-+Viewpoint+Partners+Fund+III+-+2024+Q2+Tender+Offer.pdf",
  },
  {
    size: "500KB",
    name: "Purchase and Sale Agreement - Viewpoint Partners Fund III - 2024 Q2 Tender Offer",
    downloadLink:
      "https://tap-public-assets.s3.amazonaws.com/Purchase+and+Sale+Agreement+-+Viewpoint+Partners+Fund+III+-+2024+Q2+Tender+Offer.pdf",
  },
  {
    size: "1.2MB",
    name: "Transfer Agreement - Viewpoint Partners Fund III - 2024 Q2 Tender Offer",
    downloadLink:
      "https://tap-public-assets.s3.amazonaws.com/Transfer+Agreement+-+Viewpoint+Partners+Fund+III+-+2024+Q2+Tender+Offer.pdf",
  },
];
