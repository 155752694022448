import { Box, Modal, Stack } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { BUTTON_TYPES, Button } from "common/components/Button";
import FormikTextField from "common/components/formik/FormikTextField";
import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";

// NOTE: This component is mocked up but not implemented because we don't have real data for it
export const UpdateUserModal = ({
  open,
  onClose,
  onFormSubmitted,
}: {
  open: boolean;
  onClose: () => void;
  onFormSubmitted?: () => void;
}) => {
  const validationSchema = yup.object({
    userId: yup.string().required("Required"),
    firstName: yup.date().required("Required"),
    lastName: yup.date().required("Required"),
    email: yup.date().required("Required"),
  });

  const userForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    onSubmit: (values) => {
      onFormSubmitted();
      handleClose();
    },
    validationSchema,
  });

  const handleClose = () => {
    onClose();
    userForm.resetForm();
  };

  return (
    <Modal
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={userForm.submitForm}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <Box>
              <TapUiModalHead
                title="Update profile"
                onCloseClick={handleClose}
              />
            </Box>
            <Stack spacing={2}>
              <FormikTextField
                name="firstName"
                formik={userForm}
                label="First Name"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <FormikTextField
                name="lastName"
                formik={userForm}
                label="Last Name"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <FormikTextField
                name="email"
                formik={userForm}
                label="Email"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />

              <Button
                onClick={userForm.submitForm}
                fullWidth
                type={BUTTON_TYPES.SECONDARY}
                size="large"
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
