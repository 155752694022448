export class BidState {}

export class Locked extends BidState {}

export class EmptyBidState extends BidState {}

export class BidRankState extends BidState {}

export class CompleteBidRankState extends BidRankState {}

export class InsufficientDataState extends BidRankState {}

export class PartialUserBidState extends BidRankState {}

export class NoUserBidState extends BidRankState {}
