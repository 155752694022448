import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";

import { TransactionPolicy } from "../models/TransactionPolicy";
import { transactionPoliciesMock } from "./transactionPoliciesMock";

interface IParams {}

export class GetTransactionPolicies
  implements IAsyncUseCase<IParams, TransactionPolicy[]>
{
  call: (params: IParams) => Promise<Either<Failure, TransactionPolicy[]>> =
    async (): Promise<Either<Failure, TransactionPolicy[]>> => {
      return right([...transactionPoliciesMock]);
    };
}
