import { FirmTeamMember } from "../domain/models/FirmTeamMember";

export const mockFirmTeamMembers: FirmTeamMember[] = [
  {
    pcptName: "Logan Morgan",
    email: "logan@vp.vc",
    fundAccess: "ALL",
    lastAccess: "23 days ago",
  },
  {
    pcptName: "Jason Hadid",
    email: "jason@vp.vc",
    fundAccess: "ALL",
    lastAccess: "2 days ago",
  },
  {
    pcptName: "Corey Lee",
    email: "corey@vp.vc",
    fundAccess: "ALL",
    lastAccess: "2 days ago",
  },
  {
    pcptName: "Jamie Alston",
    email: "jamie@vp.vc",
    fundAccess: "ALL",
    lastAccess: "2 days ago",
  },
];
