import styled from "styled-components";

const Styled = styled.div`
  #outerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    user-select: none;
    pointer-events: none;
  }

  #container {
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  .circle {
    width: 4px;
    height: 4px;
    position: absolute;
    opacity: 0;
    right: 233px;
    bottom: 183px;
    animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  }

  .item {
    z-index: 100;
    padding: 5px;
  }

  .item img {
    width: 150px;
  }

  @keyframes scaleIn {
    from {
      transform: scale(1, 1);
      opacity: 0.5;
    }
    to {
      transform: scale(50, 50);
      opacity: 0;
    }
  }
`;

const Loader = () => {
  return (
    <Styled>
      <div id="outerWrapper">
        <div id="container">
          <div className="item">
            <img src="/images/tap-logo.svg" />
          </div>
          <svg
            className="circle"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ animationDelay: "-3s" }}
          >
            <path
              d="M100 53.324c0 27.7 -14.552 45.252 -48.748 45.252C17.248 98.576 0 81.024 0 53.648v-1.252C0 24.824 17.248 7.124 51.248 7.124 85.624 7.124 100 24.676 100 52.676v0.624Z"
              fill="#BCB3A5"
            />
          </svg>
          <svg
            className="circle"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ animationDelay: "-2s" }}
          >
            <path
              d="M100 53.324c0 27.7 -14.552 45.252 -48.748 45.252C17.248 98.576 0 81.024 0 53.648v-1.252C0 24.824 17.248 7.124 51.248 7.124 85.624 7.124 100 24.676 100 52.676v0.624Z"
              fill="#BCB3A5"
            />
          </svg>
          <svg
            className="circle"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ animationDelay: "-1s" }}
          >
            <path
              d="M100 53.324c0 27.7 -14.552 45.252 -48.748 45.252C17.248 98.576 0 81.024 0 53.648v-1.252C0 24.824 17.248 7.124 51.248 7.124 85.624 7.124 100 24.676 100 52.676v0.624Z"
              fill="#BCB3A5"
            />
          </svg>
          <svg
            className="circle"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ animationDelay: "0s" }}
          >
            <path
              d="M100 53.324c0 27.7 -14.552 45.252 -48.748 45.252C17.248 98.576 0 81.024 0 53.648v-1.252C0 24.824 17.248 7.124 51.248 7.124 85.624 7.124 100 24.676 100 52.676v0.624Z"
              fill="#BCB3A5"
            />
          </svg>
        </div>
      </div>
    </Styled>
  );
};

export default Loader;
