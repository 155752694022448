import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledBreadcrumb = styled(NavLink)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const BreadcrumbChevron = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.89787 9.35156C8.00354 9.45723 8.0629 9.60056 8.0629 9.75C8.0629 9.89944 8.00354 10.0428 7.89787 10.1484C7.79219 10.2541 7.64887 10.3135 7.49943 10.3135C7.34998 10.3135 7.20666 10.2541 7.10099 10.1484L3.35099 6.39844C3.29855 6.34618 3.25694 6.28408 3.22855 6.21571C3.20016 6.14734 3.18555 6.07403 3.18555 6C3.18555 5.92597 3.20016 5.85266 3.22855 5.78429C3.25694 5.71592 3.29855 5.65382 3.35099 5.60156L7.10099 1.85156C7.20666 1.74589 7.34998 1.68652 7.49943 1.68652C7.64887 1.68652 7.79219 1.74589 7.89787 1.85156C8.00354 1.95723 8.0629 2.10056 8.0629 2.25C8.0629 2.39944 8.00354 2.54276 7.89787 2.64844L4.54677 5.99953L7.89787 9.35156Z"
      fill="#737476"
    />
  </svg>
);

export const BreadCrumbs = ({
  children,
  to,
}: {
  children: React.ReactNode;
  to: string;
}) => {
  return (
    <StyledBreadcrumb to={to}>
      <BreadcrumbChevron />
      {children}
    </StyledBreadcrumb>
  );
};
