import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ITransactionPeriod } from "../models/Transaction";

export class GetTimeline
  implements IAsyncUseCase<IParams, ITransactionPeriod[]>
{
  call: (params: IParams) => Promise<Either<Failure, ITransactionPeriod[]>> =
    async ({ txnId }) => {
      return await new TapClient(`/transactions/lp-led/${txnId}/timeline/`)
        .get({})
        .then((resp: ITransactionPeriod[]) => {
          return right(resp);
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {
  txnId: string;
}
