import { forwardRef, useEffect } from "react";
import styled from "styled-components";
import { CheckCircle } from "@phosphor-icons/react";

import { Fund } from "experiences/funds/domain/models/Fund";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import {
  FundGeography,
  FundGeographyNameMap,
} from "experiences/common/models/FundGeography";

import { VerifyPermissionOverlay } from "../../../../dashboard/presentation/components/VerifyPermissionOverlay";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { FundSummary } from "experiences/funds/domain/models/FundSummary";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { overviewKey, sidebarPositions } from "./Sidebar";
import { useDashboardContext } from "experiences/dashboard/presentation/state/DashboardContext";
import { IndexPriceChangePill } from "experiences/dashboard/presentation/components/Indexes";
import {
  FundCoreSector,
  FundCoreSectorNameMap,
} from "experiences/common/models/FundCoreSector";
import {
  FundCurrency,
  FundCurrencyNameMap,
} from "experiences/common/models/FundCurrencies";

import { StyledList } from "experiences/common/List";
import { IndexesRequested } from "experiences/dashboard/presentation/state/DashboardEvent";
import { useNavigate } from "react-router";
import { LP_ROUTES } from "common/constants/routes";
import { SimpleTable, SimpleTableRow } from "experiences/common/SimpleTable";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

const getFundGeography = ({ geography }: { geography?: FundGeography }) => {
  if (!geography) {
    return "-";
  }

  return FundGeographyNameMap.get(geography);
};

const getFundCoreSector = ({ coreSector }: { coreSector?: FundCoreSector }) => {
  if (!coreSector) {
    return "-";
  }

  return FundCoreSectorNameMap.get(coreSector);
};

const getFundCurrency = ({ currency }: { currency?: FundCurrency }) => {
  if (!currency) {
    return "-";
  }

  return FundCurrencyNameMap.get(currency);
};

const getFundSize = ({ size }: { size?: DollarAmount }) => {
  if (!size) {
    return "-";
  }

  return size.formattedBig();
};

const RenderLeftTable = ({
  summary,
  locked,
}: {
  fund?: Fund;
  summary?: FundSummary;
  locked?: boolean;
}) => {
  const strategy = summary?.strategy
    ? FundStrategyNameMap.get(summary.strategy)
    : "-";

  const geography = getFundGeography({
    geography: summary?.geography,
  });

  const size = getFundSize({ size: summary?.size });

  return (
    <SimpleTable>
      <SimpleTableRow locked={locked} label="Strategy" value={strategy} />
      <SimpleTableRow
        locked={locked}
        label="Vintage"
        value={summary?.vintage}
      />
      <SimpleTableRow locked={locked} label="Geography" value={geography} />
      <SimpleTableRow locked={locked} label="Size" value={size} />
      <SimpleTableRow
        locked={locked}
        label="Manager"
        value={summary?.managerName || NOT_AVAILABLE_STR}
      />
    </SimpleTable>
  );
};
const RenderRightTable = ({
  fund,
  summary,
  locked,
}: {
  fund?: Fund;
  summary?: FundSummary;
  locked: boolean;
}) => {
  const navigate = useNavigate();
  const { indexes, emitEvent } = useDashboardContext();
  locked = false;

  useEffect(() => {
    emitEvent(new IndexesRequested());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fundStragegy = summary?.strategy;

  const indexForStrategy = indexes.find(
    (index) => index.strategy === fundStragegy,
  );

  const priceString = indexForStrategy?.mostRecentPrice
    ? `${indexForStrategy?.mostRecentPrice}%`
    : NOT_AVAILABLE_STR;

  const currency = getFundCurrency({ currency: summary?.currency });

  const coreSector = getFundCoreSector({ coreSector: summary?.coreSector });

  const series = summary?.series || NOT_AVAILABLE_STR;

  return (
    <StyledList>
      {locked && (
        <VerifyPermissionOverlay
          mainIcon={<CheckCircle size={32} color="#BCB3A5" />}
          buttonText="Verify Permission"
          headerText="Verify Permission"
          subHeaderText={
            "To view this data you need to be an existing Limited Partner or an Approved Buyer."
          }
          handleVerifyPermissionClick={() =>
            navigate(LP_ROUTES.PortfolioConnectPortfolio)
          }
        />
      )}
      <SimpleTable>
        <SimpleTableRow
          locked={locked}
          label="Country"
          value={fund?.country || NOT_AVAILABLE_STR}
        />
        <SimpleTableRow locked={locked} label="Currency" value={currency} />
        <SimpleTableRow
          locked={locked}
          label="PSIN"
          value={fund?.psin || NOT_AVAILABLE_STR}
          valueClassname="dashboard_table-psin"
        />
        <SimpleTableRow
          locked={locked}
          label="Core Sector"
          value={coreSector}
        />
        <SimpleTableRow
          locked={locked}
          label="Benchmark"
          value={
            <>
              {priceString}
              {indexForStrategy?.mostRecentPrice ? (
                <IndexPriceChangePill
                  priceChange={indexForStrategy?.priceChange}
                />
              ) : null}
            </>
          }
        />
        {series !== NOT_AVAILABLE_STR ? (
          <SimpleTableRow locked={locked} label="Series" value={series} />
        ) : null}
      </SimpleTable>
    </StyledList>
  );
};

// Note: main component is wrapped in forwardRef to allow parent component to scroll to this component
export const Overview = forwardRef(function Overview(
  {
    fund,
    summary,
    locked,
  }: {
    fund?: Fund;
    summary?: FundSummary;
    locked?: boolean;
  },
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div ref={ref}>
      <PageSectionTitleDivider showBorderTop>
        {sidebarPositions[overviewKey].label}
      </PageSectionTitleDivider>

      <div className="flex">
        <RenderLeftTable fund={fund} summary={summary} />
        <RenderRightTable fund={fund} summary={summary} locked={locked} />
      </div>
    </div>
  );
});
