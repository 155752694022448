import { useEffect } from "react";

import { isBuyer, isSeller } from "../../../domain/models/Participant";
import { ITransactionSummary } from "../../../domain/models/Transaction";
import { useTransactionsContext } from "../../state/TransactionsContext";
import { RedirectedToBidsListScreen } from "../../state/TransactionsEvent";
import { BuyerBidViewScreen } from "./BidsViewScreenBuyer";
import { SellerBidViewScreen } from "./BidsViewScreenSeller";
import { BarLoader } from "common/components/BarLoader";

interface IBidsViewScreenProps {
  txnSummary?: ITransactionSummary;
}

const BidsViewScreen: React.FC<IBidsViewScreenProps> = ({ txnSummary }) => {
  const { emitEvent } = useTransactionsContext();

  useEffect(() => {
    if (!txnSummary) return;

    emitEvent!(
      new RedirectedToBidsListScreen({ txnId: txnSummary.transaction.id }),
    );
  }, []);

  useEffect(() => {
    if (!txnSummary) return;
    emitEvent!(
      new RedirectedToBidsListScreen({ txnId: txnSummary.transaction.id }),
    );
  }, [txnSummary]);

  const isViewerSeller = Boolean(txnSummary)
    ? isSeller(txnSummary.viewerParticipant)
    : false;
  const isViewerBuyer = Boolean(txnSummary)
    ? isBuyer(txnSummary.viewerParticipant)
    : false;

  return isViewerBuyer ? (
    <BuyerBidViewScreen txnSummary={txnSummary} />
  ) : isViewerSeller ? (
    <SellerBidViewScreen txnSummary={txnSummary} />
  ) : (
    <>
      <BarLoader appHeight />
    </>
  );
};

export default BidsViewScreen;
