import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { registerCellType, DropdownCellType } from "handsontable/cellTypes";
import { Stack } from "@mui/material";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";

import { LP_ROUTES } from "common/constants/routes";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { RowTabNavLinkWrapper } from "experiences/authentication/presentation/components/RowTabWrapper";
import { IndividualFundsTable } from "experiences/indications-of-interest/presentation/pages/IoiIndividualFundsTable";
import { IoiManagerTable } from "experiences/indications-of-interest/presentation/pages/IoiManagerTable";
import { usePreloadBids } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/usePreloadBids";

registerAllModules();
registerCellType(DropdownCellType);

export const IoiBulkUpload = () => {
  const navigate = useNavigate();

  const handleCloseClick = () => {
    navigate(LP_ROUTES.IndicationsOfInterestBids);
  };

  const { isReconfirm } = usePreloadBids();

  const lastSegmentManagerUpload =
    LP_ROUTES.IndicationsOfInterestManagerUpload.substring(
      LP_ROUTES.IndicationsOfInterestManagerUpload.lastIndexOf("/"),
    );

  const lastSegmentTableUpload =
    LP_ROUTES.IndicationsOfInterestTableUpload.substring(
      LP_ROUTES.IndicationsOfInterestTableUpload.lastIndexOf("/"),
    );

  return (
    <>
      <PageHead title="Upload" />

      <StepperFlow
        onClose={handleCloseClick}
        name={isReconfirm ? "Update List" : "Upload List"}
        activeStepIndex={0}
      >
        <Stack width={"100%"} height={"100%"} justifyContent={"space-between"}>
          <Stack>
            <PageHead
              title={isReconfirm ? "Update List" : "Upload List"}
              description={
                isReconfirm
                  ? "Update your list of IOIs. This will archive all of your prior IOIs and enter new IOIs based on the spreadsheet below."
                  : "Add a list of your target funds or managers to be notified of activity and new opportunities."
              }
            />

            {!isReconfirm && (
              <RowTabNavLinkWrapper style={{ height: 56 }}>
                <NavLink to={LP_ROUTES.IndicationsOfInterestTableUpload}>
                  Individual Funds
                </NavLink>
                <NavLink to={LP_ROUTES.IndicationsOfInterestManagerUpload}>
                  Managers
                </NavLink>
              </RowTabNavLinkWrapper>
            )}
            <Routes>
              <Route
                path={lastSegmentTableUpload}
                element={<IndividualFundsTable />}
              />
              <Route
                path={lastSegmentManagerUpload}
                element={<IoiManagerTable />}
              />
            </Routes>
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};
