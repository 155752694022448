import {
  CompanyReportDateName,
  IReportDates,
  ReportDateName,
} from "../models/ReportDates";

export const getReportDatesForReportName = ({
  reportDates,
  reportName,
}: {
  reportDates: IReportDates[];
  reportName: ReportDateName | CompanyReportDateName;
}) => {
  if (!reportDates || reportDates.length === 0) {
    return [];
  }

  const reportDatesForReportName =
    reportDates.find((el) => el.name === reportName)?.dates || [];

  return reportDatesForReportName.slice().sort((a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  });
};
