import { IIssuer } from "common/@types/models/Issuer";
import {
  ICreateAskForm,
  ICreateBidForm,
} from "experiences/indications-of-interest/domain/models/CreateIOIForm";
import {
  IIndicationOfInterest,
  IOIType,
} from "experiences/indications-of-interest/domain/models/IOI";

export class IOIEvent {}

export class ArchiveIOIOptionPressed extends IOIEvent {
  ioiId: string;

  constructor({ ioiId }: { ioiId: string }) {
    super();
    this.ioiId = ioiId;
  }
}

export class CreateIOIFormSubmitted extends IOIEvent {
  form: ICreateBidForm | ICreateAskForm;
  type: IOIType;

  constructor({
    form,
    type,
  }: {
    form: ICreateBidForm | ICreateAskForm;
    type: IOIType;
  }) {
    super();
    this.form = form;
    this.type = type;
  }
}

export class CreateIOIFormTriggered extends IOIEvent {}
export class NewIOIFlowCloseRequested extends IOIEvent {}

export class BulkIOIUploadScreenSubmitted extends IOIEvent {
  file: File;

  constructor({ file }: { file: File }) {
    super();
    this.file = file;
  }
}

export class BulkIOIUploadScreenPasted extends IOIEvent {
  searchTerms: string[];

  constructor({ searchTerms }: { searchTerms: string[] }) {
    super();
    this.searchTerms = searchTerms;
  }
}

export class BulkIOIUploadScreenIssuerCacheIssuerAdded extends IOIEvent {
  issuer: IIssuer;

  constructor({ issuer }: { issuer: IIssuer }) {
    super();
    this.issuer = issuer;
  }
}
