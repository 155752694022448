import * as yup from "yup";
import { useFormik } from "formik";
import { ArrowRight } from "@phosphor-icons/react";

import FormikCheckbox from "../../../../../common/components/formik/FormikCheckbox";
import { SelectGeographiesFormSubmitted } from "../../state/AuthenticationEvent";
import { useAuthContext } from "../../state/AuthenticationContext";
import { RegGeography } from "../../../domain/models/RegGeography";
import { BUTTON_TYPES, Button } from "common/components/Button";

const GeographyOption = ({ name, label, formik }) => {
  return (
    <FormikCheckbox
      name={name}
      label={label}
      formik={formik}
      className="w-full"
    />
  );
};

export const SelectGeographiesStep = () => {
  const { emitEvent } = useAuthContext();
  const validationSchema = yup.object({
    northAmerica: yup.boolean(),
    middleEast: yup.boolean(),
    latinAmerica: yup.boolean(),
    asia: yup.boolean(),
    europe: yup.boolean(),
    china: yup.boolean(),
    africa: yup.boolean(),
    australia: yup
      .boolean()
      .test(
        "oneOfRequired",
        "Please select at least one",
        (value, testContext) =>
          Boolean(
            testContext.parent.northAmerica ||
              testContext.parent.middleEast ||
              testContext.parent.latinAmerica ||
              testContext.parent.asia ||
              testContext.parent.europe ||
              testContext.parent.china ||
              testContext.parent.australia ||
              testContext.parent.africa,
          ),
      ),
  });
  const formik = useFormik({
    initialValues: {
      northAmerica: false,
      middleEast: false,
      latinAmerica: false,
      asia: false,
      europe: false,
      china: false,
      australia: false,
      africa: false,
    },
    validationSchema,
    onSubmit: () => {
      emitEvent!(
        new SelectGeographiesFormSubmitted({
          regForm: {
            geographiesInterestedIn: [
              ...Object.keys(formik.values)
                .map((key) => {
                  if (key === "northAmerica" && formik.values[key]) {
                    return RegGeography.NortAmerica;
                  } else if (key === "middleEast" && formik.values[key]) {
                    return RegGeography.MiddleEast;
                  } else if (key === "latinAmerica" && formik.values[key]) {
                    return RegGeography.LatinAmerica;
                  } else if (key === "asia" && formik.values[key]) {
                    return RegGeography.Asia;
                  } else if (key === "europe" && formik.values[key]) {
                    return RegGeography.Europe;
                  } else if (key === "china" && formik.values[key]) {
                    return RegGeography.China;
                  } else if (key === "australia" && formik.values[key]) {
                    return RegGeography.Australia;
                  } else if (key === "africa" && formik.values[key]) {
                    return RegGeography.Africa;
                  } else {
                    return RegGeography.Unknown;
                  }
                })
                .filter((pref) => pref !== RegGeography.Unknown),
            ],
          },
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-8">
      <div>
        <p className="mb-4 font-medium">What geographies do you invest in?</p>
        <div className="columns-2">
          <GeographyOption
            name="northAmerica"
            label={"North America"}
            formik={formik}
          />
          <GeographyOption
            name="latinAmerica"
            label={"Latin America"}
            formik={formik}
          />
          <GeographyOption name="europe" label={"Europe"} formik={formik} />
          <GeographyOption
            name="australia"
            label={"Australia"}
            formik={formik}
          />

          <GeographyOption
            name="middleEast"
            label={"Middle East"}
            formik={formik}
          />
          <GeographyOption name="asia" label={"Asia"} formik={formik} />
          <GeographyOption name="china" label={"China"} formik={formik} />
          <GeographyOption name="africa" label={"Africa"} formik={formik} />
        </div>
      </div>

      <Button
        type={BUTTON_TYPES.SECONDARY}
        size="large"
        onClick={() => {
          formik.submitForm();
        }}
        icon={<ArrowRight />}
        iconRight
        fullWidth
      >
        Finish
      </Button>
    </form>
  );
};
