import { Button } from "shadcn/ui/Button";
import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";
import React from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "shadcn/ui/Command";
import { CaretUpDown } from "@phosphor-icons/react";
import { ScrollArea } from "shadcn/ui/ScrollArea";
import classNames from "classnames";

interface IListItem {
  value: string;
  child: React.ReactNode;
}

interface IProps {
  listItems: IListItem[];
  emptySearchResultChild: React.ReactNode;
  buttonChild?: React.ReactNode;
  searchInputPlaceholder?: string;
  searchInput?: string;
  onSearchInputChanged: (searchTerm: string) => void;
  onItemChanged: (itemValue: string) => void;
  disabled?: boolean;
  popoverButtonPlaceholder?: string;
  errorMessage?: string;
  className?: string;
}

const Combobox: React.FC<IProps> = ({
  listItems,
  emptySearchResultChild,
  searchInputPlaceholder,
  onSearchInputChanged,
  searchInput,
  onItemChanged,
  disabled,
  buttonChild,
  errorMessage,
  className,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={classNames("flex flex-col gap-1", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={classNames(
              "w-full min-h-14 justify-between h-fit flex rounded-md",
              { "border-2 border-red-400": Boolean(errorMessage) },
            )}
          >
            {buttonChild}
            <CaretUpDown size={16} className="opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="popover-content-same-trigger-width p-0">
          <Command shouldFilter={false}>
            <CommandInput
              placeholder={searchInputPlaceholder}
              className="h-9"
              onValueChange={onSearchInputChanged}
              value={searchInput}
            />
            <CommandEmpty className="p-0">
              {emptySearchResultChild}
            </CommandEmpty>
            <CommandGroup className="p-0">
              <ScrollArea className="flex max-h-48 flex-col rounded-md w-full">
                <CommandList className="overflow-y-auto">
                  {listItems.map((item) => (
                    <CommandItem
                      className="m-0 p-0"
                      key={item.value}
                      value={item.value}
                      onSelect={(currentValue) => {
                        onItemChanged(
                          listItems.find(
                            (item) =>
                              item.value.toLocaleLowerCase() ===
                              currentValue.toLocaleLowerCase(),
                          )!.value,
                        );
                        setOpen(false);
                      }}
                    >
                      {item.child}
                    </CommandItem>
                  ))}
                </CommandList>
              </ScrollArea>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      <p className="text-xs text-red-500">{errorMessage ?? ""}</p>
    </div>
  );
};

export default Combobox;
