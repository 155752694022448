import { Box, Modal, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";

import FormikTextField from "../../../../common/components/formik/FormikTextField";
import { useFundsContext } from "../state/FundsContext";
import { ConfirmCreateAlertButtonClicked } from "../state/FundsEvent";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import {
  TapUiModalContent,
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";

interface IConfirmCreateAlertModal {
  open: boolean;
  onClose: () => void;
}

const ConfirmCreateAlertModal: React.FC<IConfirmCreateAlertModal> = ({
  open,
  onClose,
}) => {
  const { emitEvent } = useFundsContext();
  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    dealSize: yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      dealSize: "",
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent!(
        new ConfirmCreateAlertButtonClicked({
          name: values.name,
          dealSize: values.dealSize
            ? DollarAmount.fromString(values.dealSize)
            : undefined,
        }),
      );
      onClose();
    },
  });

  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={formik.submitForm}>
        <TapUiVerticalModalWrapper className="w-[500px] max-w-[calc(100%-36px)]">
          <TapUiModalHead
            title="One last thing"
            subtitle="Add few last details about your alert"
            onCloseClick={onClose}
          />
          <TapUiModalContent>
            <FormikTextField
              formik={formik}
              label="Alert Name"
              name="name"
              autoComplete="name"
              fullWidth
            />
            <FormikTextField
              formik={formik}
              label="Deal Size"
              name="dealSize"
              autoComplete="dealSize"
              mask="currency"
              fullWidth
            />
            <Button
              onClick={formik.submitForm}
              fullWidth
              type={BUTTON_TYPES.SECONDARY}
              size="large"
              icon={<PlusIcon />}
            >
              Create Alert
            </Button>
          </TapUiModalContent>
        </TapUiVerticalModalWrapper>
      </form>
    </Modal>
  );
};

export default ConfirmCreateAlertModal;
