import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

import { Fund } from "experiences/funds/domain/models/Fund";
import { useViewFundSummary } from "experiences/funds/presentation/hooks/useViewFundSummary";
import { IoiType } from "experiences/indications-of-interest/presentation/pages/AddInterestModal";
import { IOIType } from "experiences/indications-of-interest/domain/models/IOI";
import { FundSummary } from "experiences/funds/domain/models/FundSummary";
import { PageMainContent } from "experiences/common/PageWrapper";

import { PriceHistoryChartWrapper } from "./PriceHistoryChart";
import { InvestmentsTable } from "./InvestmentsTable";
import { CashFlows } from "./CashFlowsChart";
import {
  overviewKey,
  priceHistoryKey,
  ioisKey,
  performanceKey,
  investmentsKey,
  cashFlowsKey,
} from "./Sidebar";
import { Overview } from "./Overview";
import { IndicationsOfInterestTable } from "./IndicationsOfInterestTable";
import { useFundPrivacy } from "../../hooks/useFundPrivacy";
import { isFundViewpoint } from "../../hooks/useViewFund";

const useViewFundContentScroll = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { hash } = useLocation();

  const overviewRef = useRef<HTMLDivElement | null>(null);
  const priceHistoryRef = useRef<HTMLDivElement | null>(null);
  const ioisRef = useRef<HTMLDivElement | null>(null);
  const performanceRef = useRef<HTMLDivElement | null>(null);
  const investmentsRef = useRef<HTMLDivElement | null>(null);
  const cashFlowsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (hash === `#${priceHistoryKey}`) {
      priceHistoryRef?.current?.scrollIntoView();
    }
    if (hash === `#${overviewKey}`) {
      overviewRef?.current?.scrollIntoView();
    }
    if (hash === `#${ioisKey}`) {
      ioisRef?.current?.scrollIntoView();
    }
    if (hash === `#${performanceKey}`) {
      performanceRef?.current?.scrollIntoView();
    }
    if (hash === `#${investmentsKey}`) {
      investmentsRef?.current?.scrollIntoView();
    }
    if (hash === `#${cashFlowsKey}`) {
      cashFlowsRef?.current?.scrollIntoView();
    }
  }, [hash]);

  return {
    ref,
    overviewRef,
    priceHistoryRef,
    ioisRef,
    performanceRef,
    investmentsRef,
    cashFlowsRef,
  };
};

export const ViewFundContent = ({
  fundPrivacy,
  fund,
  summary,
  iois,
  toggleContactModal,
  toggleSubmitIoiModal,
}: {
  fundPrivacy: ReturnType<typeof useFundPrivacy>;
  fund?: Fund;
  summary?: FundSummary;
  iois?: ReturnType<typeof useViewFundSummary>["iois"];
  toggleContactModal: (type: IOIType) => void;
  toggleSubmitIoiModal: (type: IoiType) => void;
}) => {
  const {
    ref,
    overviewRef,
    priceHistoryRef,
    ioisRef,
    investmentsRef,
    cashFlowsRef,
  } = useViewFundContentScroll();

  const canViewBasicInfo =
    summary?.permissionProfile.isPermittedToViewBasicInfo() ||
    isFundViewpoint(fund);
  const canViewCashFlows =
    summary?.permissionProfile.isPermittedToViewCashFlows() ||
    isFundViewpoint(fund);
  const canViewInvestments =
    summary?.permissionProfile.isPermittedToViewInvestments() ||
    isFundViewpoint(fund);
  const canViewBuyIois =
    summary?.permissionProfile.isPermittedToViewBuyIOIs() ||
    isFundViewpoint(fund);
  const canViewSellIois =
    summary?.permissionProfile.isPermittedToViewSellIOIs() ||
    isFundViewpoint(fund);

  return (
    <PageMainContent ref={ref}>
      <PriceHistoryChartWrapper
        summary={summary}
        fundPrivacy={fundPrivacy}
        fund={fund}
        iois={iois}
        ref={priceHistoryRef}
      />
      <Overview
        ref={overviewRef}
        fund={fund}
        summary={summary}
        locked={!canViewBasicInfo}
      />
      <IndicationsOfInterestTable
        ref={ioisRef}
        toggleContactModal={toggleContactModal}
        toggleSubmitIoiModal={toggleSubmitIoiModal}
        canViewBuyIois={canViewBuyIois}
        canViewSellIois={canViewSellIois}
        iois={iois}
        fund={fund}
      />
      {/* <PerformanceChart ref={performanceRef} /> */}
      <InvestmentsTable
        ref={investmentsRef}
        fund={fund}
        locked={!canViewInvestments}
      />
      <CashFlows ref={cashFlowsRef} fund={fund} locked={!canViewCashFlows} />
    </PageMainContent>
  );
};
