import { CompanyDetail } from "experiences/portfolio-v2/presentation/pages/CompanyDetail";

// This page is designed for testing and development purposes
export const Playground = () => {
  return (
    <div>
      <CompanyDetail />
    </div>
  );
};
