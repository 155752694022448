import {
  Stack,
  Typography,
  Stepper,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { withStyles } from "@mui/styles";
import styled from "styled-components";

import { useMarkupContext } from "experiences/transactions/domain/usecases/useMarkupContext";
import { Button } from "common/components/Button";
import { AppLayoutWithTopMenu } from "experiences/common/AppLayoutWithTopMenu";

const stepSizePx = 16;

const MarkupPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 512px;
  box-sizing: border-box;
  padding: 16px;

  cursor: default;
`;

const WhatHappensNext = styled.h4`
  display: block;
  margin-top: 32px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #737476;

  border-bottom: 1px solid #dbdcda;
`;

const DownloadNda = styled.p`
  display: block;
  margin-top: 32px;
  margin-bottom: 32px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #737476;

  .download {
    color: #bcb3a5;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CustomizedConnector = withStyles({
  root: {
    display: "block",
    position: "relative",
    marginLeft: 0,
    left: stepSizePx / 2,
    top: -stepSizePx / 2,
  },
  completed: {
    "& $line": {
      borderColor: "#85d388",
      borderTopWidth: 1,
    },
  },
  line: {
    borderColor: "#dbdcda",
    position: "absolute",
  },
})(StepConnector);

const StyledStep = styled.div`
  width: ${stepSizePx}px;
  height: ${stepSizePx}px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 1px solid #dbdcda;
`;

const CompletedStepIcon = styled(StyledStep)`
  background-color: #85d388;
  border: none;
`;

const StepLabelTitle = styled.h3`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  color: #21272d;

  cursor: default;
`;

const StepLabelDescription = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #737476;

  cursor: default;
`;

const HeaderTitle = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <Stack direction="column" width="100%" justifyContent="space-between">
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2" width={600}>
        {subtitle}
      </Typography>
    </Stack>
  );
};

const CustomStepperIcon = ({ completed, ...asd }: StepIconProps) => {
  if (completed) {
    return (
      <CompletedStepIcon>
        <CheckIcon sx={{ color: "white", width: 10 }} />
      </CompletedStepIcon>
    );
  }

  return <StyledStep />;
};

export const InitiateMarkup = () => {
  const { steps, handleSaveAndCloseClick, handleDownloadNdaClick } =
    useMarkupContext();

  return (
    <AppLayoutWithTopMenu centeredContent>
      <MarkupPageWrapper>
        <HeaderTitle
          title="Markup Initiated"
          subtitle="You will receive an email from our team with the NDAs shortly. "
        />
        <WhatHappensNext>What happens next</WhatHappensNext>
        <Stepper
          activeStep={1}
          orientation="vertical"
          connector={<CustomizedConnector />}
        >
          {steps.map((step) => (
            <Step
              key={step.label}
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              <StepLabel StepIconComponent={CustomStepperIcon}>
                <StepLabelTitle>{step.label}</StepLabelTitle>
                <StepLabelDescription>{step.description}</StepLabelDescription>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <DownloadNda>
          Want to get started? Download the NDA{" "}
          <span onClick={handleDownloadNdaClick} className="download">
            here
          </span>
          .
        </DownloadNda>
        <Button size="large" onClick={handleSaveAndCloseClick} fullWidth>
          Save &amp; Close
        </Button>
      </MarkupPageWrapper>
    </AppLayoutWithTopMenu>
  );
};
