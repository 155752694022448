import { Users } from "@phosphor-icons/react";

import TextChip from "experiences/common/TextChip";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { TimelineStep } from "common/components/TimelineStep";

import Phase1Chart from "./overview/phase-1-chart.svg";
import Phase2Chart from "./overview/phase-2-chart.svg";
import { SideComparison } from "./SideComparison";
import { UiProgress } from "experiences/common/Progress";

const steps = [
  {
    title: "Preliminary Period",
    subtitle: "begins April 1, 2024",
    secondaryTitle: "Preliminary Interest Due",
    secondarySubtitle: "April 17, 2024",
    titleTokens: (
      <>
        <TextChip success text="LPs" icon={<Users />} />
      </>
    ),

    bullets: [
      "Reach out to Limited Partners to gauge interest.",
      "Collect preliminary information on assets and capital accounts.",
    ],
    completed: true,
    children: (
      <>
        <SideComparison
          items={[
            {
              title: "Total Participation Interest",
              value: "$617.9M",
            },
            {
              title: "Average Reserve Price",
              value: "89.5%",
            },
          ]}
        />
        <UiProgress
          title="LP Response Rate"
          value={22}
          value2={60}
          label="22 participating"
          label2="60 responded"
          outOf={70}
          labelOutOf="out of 70"
        />
      </>
    ),
  },
  {
    title: "Round 1: Non-Binding Bids",
    subtitle: "Begins April 18, 2024",
    secondaryTitle: "Non-Binding Bids Due",
    secondarySubtitle: "May 6, 2024",
    titleTokens: (
      <>
        <TextChip pending text="Buyers" icon={<Users />} />
      </>
    ),
    bullets: [
      "Provide buyers access to a virtual data room with fund documents and performance data.",
      "Allow buyers to conduct initial financial, legal, and compliance reviews.",
    ],
    completed: true,
    children: (
      <>
        <SideComparison
          items={[
            {
              title: "Total Non Binding Bids",
              value: "$4,160M",
            },
            {
              title: "Average Bid Price",
              value: "88.0%",
            },
          ]}
        />
        <img
          src={Phase1Chart}
          width="100%"
          style={{ maxWidth: 1024, margin: "0 auto" }}
          className="p-4 box-border"
        />
        <UiProgress
          title="Buyer Response Rate"
          value={12}
          value2={15}
          label="12 bid"
          label2="15 responded"
          outOf={16}
          labelOutOf="out of 16"
        />
      </>
    ),
  },
  {
    title: "Round 2: Binding Bids",
    subtitle: "Begins May 9, 2024",
    secondaryTitle: "Binding Bids Due",
    secondarySubtitle: "May 14, 2024",
    titleTokens: (
      <>
        <TextChip pending text="Buyers" icon={<Users />} />
      </>
    ),
    bullets: [
      "Perform more detailed analysis on the fund's assets, liabilities, and operations.",
      "Hold an investor call with Viewpoint and potential buyers.",
      "Solicit final binding offers from buyers, including price and transaction terms.",
    ],
    completed: true,
    children: (
      <>
        <SideComparison
          items={[
            {
              title: "Total Non Binding Bids",
              value: "$2,335M",
            },
            {
              title: "Average Bid Price",
              value: "90.5%",
            },
          ]}
        />
        <UiProgress
          title="Buyer Response Rate"
          value={9}
          label="9 bid"
          outOf={13}
          labelOutOf="out of 13"
        />
        <img
          src={Phase2Chart}
          width="100%"
          style={{ maxWidth: 1024, margin: "0 auto" }}
          className="p-4 box-border"
        />
      </>
    ),
  },
  {
    title: "Participation Period",
    subtitle: "Begins May 20, 2024",
    secondaryTitle: "Participation Decisions Due",
    secondarySubtitle: "June 10, 2024",
    titleTokens: (
      <>
        <TextChip success text="LPs" icon={<Users />} />
      </>
    ),
    bullets: [
      "Present final offers to sellers for consideration.",
      "Sellers decide whether to accept offers based on price and terms.",
      "Execute commitment letters from sellers who accept the offers.",
    ],

    completed: true,
    current: true,
    children: (
      <>
        <SideComparison
          items={[
            {
              title: "Total Participation Interest",
              value: "$405.3M",
            },
            {
              title: "Offer Price",
              value: "92.5%",
            },
          ]}
        />
        <SideComparison
          skipBorderTop
          items={[
            {
              title: "LP New",
              value: "1",
            },
            {
              title: "LP Maintained",
              value: "69",
            },
            {
              title: "LP Exiting",
              value: "2",
            },
          ]}
        />
        <UiProgress
          title="Buyer Response Rate"
          value={14}
          label="14 elected"
          value2={25}
          label2="25 responded"
          outOf={71}
          labelOutOf="out of 71"
        />
      </>
    ),
  },
  {
    title: "Closing & Settlement",
    subtitle: "Begins June 15, 2024",
    secondaryTitle: "Target Closing",
    secondarySubtitle: "June 30, 2024",
    // openByDefault: true,
    titleTokens: (
      <>
        <TextChip text="All" icon={<Users />} />
      </>
    ),
    bullets: [
      "Finalize and execute purchase and sale agreements.",
      "Arrange for the transfer of funds from buyers to sellers.",
      "Complete the transfer of ownership interests in the PE fund.",
    ],
  },
];

// TODO: timeline: Fix bottom border on last step
export const GpTransactionOverview = () => {
  return (
    <div>
      <PageSectionTitleDivider>Overview</PageSectionTitleDivider>
      {steps.map((step, index) => (
        <TimelineStep
          key={index}
          title={step.title}
          subtitle={step.subtitle}
          secondaryTitle={step.secondaryTitle}
          secondarySubtitle={step.secondarySubtitle}
          bullets={step.bullets}
          completed={step.completed}
          openByDefault={step.openByDefault}
          current={step.current}
          children={step.children}
          isLastStep={index === steps.length - 1}
          titleTokens={step.titleTokens}
        />
      ))}
    </div>
  );
};
