import { ICashFlows } from "experiences/funds/domain/models/FundCashFlows";
import {
  cashFlowsContributionsKey,
  cashFlowsDateKey,
  cashFlowsDistributionsKey,
} from "../viewFund/CashFlowsChart";

export const viewpointCashFlows: ICashFlows[] = [
  {
    [cashFlowsDateKey]: "2016-12-31",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2017-03-31",
    [cashFlowsContributionsKey]: 5000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2017-06-30",
    [cashFlowsContributionsKey]: 28000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2017-09-30",
    [cashFlowsContributionsKey]: 22000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2017-12-31",
    [cashFlowsContributionsKey]: 10000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2018-03-31",
    [cashFlowsContributionsKey]: 15000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2018-06-30",
    [cashFlowsContributionsKey]: 10000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2018-09-30",
    [cashFlowsContributionsKey]: 18000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2018-12-31",
    [cashFlowsContributionsKey]: 10000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2019-03-31",
    [cashFlowsContributionsKey]: 73000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2019-06-30",
    [cashFlowsContributionsKey]: 5000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2019-09-30",
    [cashFlowsContributionsKey]: 10000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2019-12-31",
    [cashFlowsContributionsKey]: 5000000,
    [cashFlowsDistributionsKey]: 15000000,
  },
  {
    [cashFlowsDateKey]: "2020-03-31",
    [cashFlowsContributionsKey]: 10000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2020-06-30",
    [cashFlowsContributionsKey]: 30000000,
    [cashFlowsDistributionsKey]: 24000000,
  },
  {
    [cashFlowsDateKey]: "2020-09-30",
    [cashFlowsContributionsKey]: 30000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2020-12-31",
    [cashFlowsContributionsKey]: 25000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2021-03-31",
    [cashFlowsContributionsKey]: 40000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2021-06-30",
    [cashFlowsContributionsKey]: 50000000,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2021-09-30",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2021-12-31",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 5000000,
  },
  {
    [cashFlowsDateKey]: "2022-03-31",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2022-06-30",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 3750000,
  },
  {
    [cashFlowsDateKey]: "2022-09-30",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2022-12-31",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 10000000,
  },
  {
    [cashFlowsDateKey]: "2023-03-31",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2023-06-30",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 0,
  },
  {
    [cashFlowsDateKey]: "2023-09-30",
    [cashFlowsContributionsKey]: 0,
    [cashFlowsDistributionsKey]: 40000000,
  },
];
