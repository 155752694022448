import { useLocation, useNavigate, useParams } from "react-router";
import { DownloadSimple, FilePdf } from "@phosphor-icons/react";
import { useQuery } from "@apollo/client";

import {
  StyledTableBody,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { IFundDataDocument } from "experiences/transactions/presentation/components/fund-data/documents/model";
import TapClient from "common/clients/TapClient";
import { transactionsClient } from "common/clients/ApolloClient";
import { PageHead } from "common/components/PageHead";
import { Button, BUTTON_TYPES } from "common/components/Button";

import { DowloadDataRoomFilePressed } from "experiences/transactions/presentation/state/TransactionsEvent";
import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { DownloadDataRoomZipPressed } from "experiences/transactions/presentation/state/TransactionsEvent";
import { GetTransactionDataroomQuery } from "experiences/transactions/domain/usecases/GetTransactionDataroomQuery";
import {
  IDataRoom,
  IItem,
} from "experiences/transactions/domain/models/DataRoom";
import { dataroomV2TableColumns } from "common/components/dataroom/DataroomV2";
import { useDocumentsTable } from "common/components/dataroom/useDocumentsTable";

import { TransactionDetailsRoutes } from "../../TransactionDetails";
import { TransactionFundDataRoutes } from "../TransactionFundDataScreen";
import { FundDataWrapper } from "../FundDataWrapper";
import { GET_TRANSACTION_FUND_DATA_DOCUMENTS } from "./query";
import { handleDataroomDownload } from "./handleDataroomDownload";
import { useEffect, useMemo } from "react";

const useTransactionDocuments = () => {
  const { txnId } = useParams();
  const navigate = useNavigate();
  const currentPath = useLocation();

  const {
    data: documentsInformation,
    loading: documentsLoading,
    refetch: refetchDocuments,
    error: documentsError,
  } = useQuery<{
    documentsInformation: IFundDataDocument[];
  }>(GET_TRANSACTION_FUND_DATA_DOCUMENTS, {
    client: transactionsClient,
    fetchPolicy: "network-only",
    variables: {
      transactionId: txnId,
    },
    skip: !txnId,
    onError: (error) => {
      console.error("error fetching documents", error);
    },
    errorPolicy: "ignore",
  });

  const awaitingUpload = false;
  const extractionInProgress = false;
  const extractionReady = true;

  // const { emitEvent } = useTransactionsContext();

  const {
    data: dataroomData,
    refetch: refetchDataroom,
    error: dataroomError,
  } = useQuery<{
    dataroom?: IDataRoom;
  }>(GetTransactionDataroomQuery, {
    client: transactionsClient,
    variables: { transactionId: txnId },
    fetchPolicy: "network-only",
    skip: !txnId || !!documentsError,
    onError: (error) => {
      console.error("error fetching dataroom", error);
    },
  });

  useEffect(() => {
    if (txnId) {
      refetchDocuments();
      refetchDataroom();
    }
  }, [txnId]);

  const handleDownloadAll = () => {
    console.log("download all");
    // emitEvent!(
    //   new DownloadDataRoomZipPressed({
    //     dataroom: dataroomData?.dataroom,
    //     txnId,
    //   }),
    // );
  };

  const handleDownload = (
    item: IItem,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // prevent from "double clicking" if the click happens on the button
    e.stopPropagation();

    console.log("download", item);
    // emitEvent!(
    //   new DowloadDataRoomFilePressed({
    //     item: item,
    //     txnId,
    //   }),
    // );
  };

  const handleUploadDocumentsClick = () => {
    const basePath = currentPath.pathname.split(
      TransactionDetailsRoutes.FundData,
    )[0];
    const path = `${basePath}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.VerifyPermission}`;
    navigate(path);
  };

  const documents: IFundDataDocument[] = useMemo(() => {
    return documentsInformation?.documentsInformation || [];
  }, [documentsInformation]);

  return {
    documents,
    loading: documentsLoading,
    handleUploadDocumentsClick,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
    handleDownloadAll,
    handleDownload,
    documentsError,
    dataroomError,
  };
};

export const DocumentsTab = () => {
  const {
    documents,
    loading,
    handleUploadDocumentsClick,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
    handleDownloadAll,
    documentsError,
    dataroomError,
  } = useTransactionDocuments();

  const filteredDocuments = useMemo(
    () => documents.filter((document) => document.documentProcessed),
    [documents],
  );

  const { rows, renderRow } = useDocumentsTable({
    documentsList: filteredDocuments,
    handleFileDownload: handleDataroomDownload,
  });

  return (
    <FundDataWrapper
      barLoader={loading}
      awaitingUpload={awaitingUpload}
      extractionInProgress={extractionInProgress}
      extractionReady={extractionReady}
    >
      <PageHead
        title="Documents"
        actions={
          <>
            <Button icon={<FilePdf />} onClick={handleUploadDocumentsClick}>
              Upload More Documents
            </Button>
            <Button
              icon={<DownloadSimple />}
              onClick={handleDownloadAll}
              type={BUTTON_TYPES.SECONDARY}
            >
              Download All
            </Button>
          </>
        }
      />

      <TapTable
        data={rows}
        layoutFixed
        columns={dataroomV2TableColumns}
        className="w-full min-w-[700px]"
        renderBody={(data) => (
          <StyledTableBody>{data.map(renderRow)}</StyledTableBody>
        )}
        showEmptyState={!!documentsError || !!dataroomError}
        emptyStateTitle="No documents found."
        emptyStateDescription="Please check back later."
      />
    </FundDataWrapper>
  );
};
