import { DesktopDatePicker as MuiDatepicker } from "@mui/x-date-pickers";

import TextField from "./TextField";

interface Props {
  label: string;
  error?: string;
  helperText?: string;
  placeholder?: string;
  onChange: (value?: string) => void;
  value: string;
  InputLabelProps?: any;
}

const DatePicker = ({
  label,
  error,
  helperText,
  placeholder,
  onChange,
  value,
  ...props
}: Props) => (
  <MuiDatepicker
    {...props}
    value={value}
    onChange={(date) => {
      // @ts-ignore
      if (date instanceof Date && !Number.isNaN(date.valueOf())) {
        const dateString = date.toISOString().slice(0, 10);
        onChange(date);
      }
    }}
    inputFormat="MM/dd/yyyy"
    renderInput={(params) => (
      <TextField
        {...params}
        InputLabelProps={props.InputLabelProps}
        error={error}
        helperText={helperText}
        label={label}
        placeholder={placeholder}
      />
    )}
  />
);

export default DatePicker;
