import { GpLimitedPartner } from "experiences/limited-partners/domain/models/GpLimitedPartner";

export class GpLimitedPartnersState {}

export class GpLimitedPartnersLoaded extends GpLimitedPartnersState {
  partners: GpLimitedPartner[];

  constructor({ partners }: { partners: GpLimitedPartner[] }) {
    super();
    this.partners = partners;
  }
}
