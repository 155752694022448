import { createContext, useContext } from "react";
import { useManageDashboardState } from "./DashboardManager";
import { DashboardEvent } from "./DashboardEvent";
import { Index } from "experiences/funds/domain/models/Index";
import { DashboardState } from "./DashboardState";
import { ActiveRecentIoiList } from "experiences/indications-of-interest/domain/usecases/ActiveRecentIoiList";
import { LoadIndexes } from "experiences/dashboard/domain/usecases/LoadIndexes";

export interface DashboardContextProps {
  emitEvent?: (event: DashboardEvent) => void;
  indexes?: Index[];
  loadIndexes?: () => void;
  activeRecentIoisState?: DashboardState;
  dashboardState?: DashboardState;
}

const initialContextState: DashboardContextProps = {};

const DashboardContext = createContext(initialContextState);

interface ITransactionsStateProviderProps {
  children: React.ReactNode;
  activeRecentIoisState?: DashboardState;
}

const DashboardStateProvider = ({
  children,
}: ITransactionsStateProviderProps) => {
  const { emitEvent, indexes, activeRecentIoisState, dashboardState } =
    useManageDashboardState({
      activeRecentIoiList: new ActiveRecentIoiList(),
      loadIndexes: new LoadIndexes(),
    });

  return (
    <DashboardContext.Provider
      value={{
        emitEvent,
        indexes,
        dashboardState,
        activeRecentIoisState,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);

export { DashboardStateProvider };
