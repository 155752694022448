import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";

const HOLDING_BULK_PATH = "/holding/bulk/create/";

export enum HoldingKeys {
  IssuerId = "issuer_id",
  LpName = "lp_name",
  Nav = "nav",
  Committed = "committed",
  Contributed = "contributed",
  Distributed = "distributed",
  ReferenceDate = "reference_date",
}

export type Holding = {
  [HoldingKeys.IssuerId]: string;
  [HoldingKeys.LpName]: string;
  [HoldingKeys.Nav]: number;
  [HoldingKeys.Committed]?: number;
  [HoldingKeys.Contributed]?: number;
  [HoldingKeys.Distributed]?: number;
  [HoldingKeys.ReferenceDate]: string;
};

export class HoldingsBulkUpload implements IAsyncUseCase<IParams, any> {
  call: (params: IParams) => Promise<Either<Failure, any>> = async (
    payload,
  ) => {
    const { holdings } = payload;
    return await new TapClient(HOLDING_BULK_PATH, { holdings })
      .post()
      .then((resp: any) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  holdings: Holding[];
}
