import { gql } from "@apollo/client";

export interface GetEntityDocUploadLinkInput {
  entityId: string;
  fileName: string;
}

export const UploadPortfolioDocumentsMutation = gql`
  mutation Mutation($docs: [GetEntityDocUploadLinkInput!]!) {
    uploadEntityProcessingDocuments(docs: $docs) {
      entityId
      urls {
        name
        url
        fields {
          key
          AWSAccessKeyId
          policy
          signature
        }
      }
    }
  }
`;
