import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Radio,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Link, Link as PhosphorLink } from "@phosphor-icons/react";
import { Barcode } from "@phosphor-icons/react/dist/ssr";

import { GP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { TapCheckbox } from "common/components/Checkbox";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { LPPortal } from "experiences/portfolio/domain/models/LPPortal";
import { LPPortalConnectionSubmitted } from "experiences/portfolio/presentation/state/PortfolioEvent";
import { usePortfolioContext } from "experiences/portfolio/presentation/state/PortfolioContext";
import {
  ContainerChoiceSection,
  LPPortalNameInput,
  lpListAvailable,
  useHoldingsFileUpload,
} from "experiences/portfolio/presentation/pages/ConnectPortfolio";
import { listOfLpPortals } from "experiences/portfolio/presentation/components/LpPortalList";

export const GpLimitedPartnersUploadSplit = () => {
  const [activePortalId, setActivePortalId] = useState<number>();
  const [isDifferentSystem, setIsDifferentSystem] = useState<boolean>(false);
  const [portalCustomName, setPortalCustomName] = useState<string>();
  const [confirmBoxOpen, setConfirmBoxOpen] = useState<boolean>(false);
  const { file, setFile, handleUploadClick } = useHoldingsFileUpload();
  const navigate = useNavigate();

  useEffect(() => {
    if (activePortalId && isDifferentSystem) {
      setIsDifferentSystem(false);
      setPortalCustomName(undefined);
    }
  }, [activePortalId]);

  useEffect(() => {
    if (isDifferentSystem && activePortalId) {
      setActivePortalId(undefined);
    }
  }, [isDifferentSystem]);

  const handleBulkUploadClick = () => {
    navigate(GP_ROUTES.LimitedPartnersUploadCapitalAccountStatement);
  };

  const handleCloseClick = () => {
    setConfirmBoxOpen(false);
  };

  return (
    <>
      <PageHead title="Upload" />
      <StepperFlow name="Connect" activeStepIndex={0}>
        <Stack sx={{ height: "100%" }} width={"100%"} alignItems={"center"}>
          <Stack
            direction={"row"}
            width={"100%"}
            spacing={4}
            divider={
              <Divider orientation="vertical" sx={{ height: "100%" }}>
                OR
              </Divider>
            }
          >
            <Stack sx={{ width: "60%" }} alignItems={"center"} spacing={2}>
              <PageHead
                titleStyle={{ paddingTop: 0 }}
                title="Connect Your Portfolio"
                description="Connect your LP portfolio management tool to automatically sync your holdings, get estimated market prices, and be notified of buy and sell interest."
              />
              <ContainerChoiceSection
                choicesSet={listOfLpPortals}
                activePortalChoiceId={activePortalId}
                setActivePortalChoiceId={setActivePortalId}
              />
              <Stack>
                <Stack
                  direction={"row"}
                  spacing={1}
                  p={2}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => setIsDifferentSystem(!isDifferentSystem)}
                >
                  <TapCheckbox
                    checked={isDifferentSystem}
                    onChange={() => setIsDifferentSystem(!isDifferentSystem)}
                  />
                  <Typography>
                    I use another portfolio management system
                  </Typography>
                </Stack>
                {isDifferentSystem && (
                  <LPPortalNameInput
                    value={portalCustomName}
                    placeholder="Name of system"
                    style={{ width: "400px" }}
                    onChange={(event) =>
                      setPortalCustomName(event.target.value)
                    }
                  />
                )}
              </Stack>
              <Stack direction="row" py={"32px"}>
                <>
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    onClick={() => {
                      file ? handleUploadClick() : setConfirmBoxOpen(true);
                    }}
                    disabled={
                      !Boolean(file) &&
                      !(isDifferentSystem && portalCustomName) &&
                      !Boolean(activePortalId)
                    }
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "48px",
                      paddingRight: "48px",
                    }}
                    icon={<Link />}
                    size="large"
                  >
                    Connect Portfolio
                  </Button>
                  <ConfirmLPPortalDialogue
                    open={confirmBoxOpen}
                    portalChoice={
                      isDifferentSystem && portalCustomName
                        ? portalCustomName!
                        : (lpListAvailable.find(
                            (portal) => portal.portalId == activePortalId!,
                          ) ?? "")
                    }
                    onClose={handleCloseClick}
                  />
                </>
              </Stack>
            </Stack>
            <Stack
              width={"40%"}
              alignItems={"space-between"}
              justifyContent={"start"}
              direction={"column"}
            >
              <PageHead
                title="Import Capital Account Data"
                description="Upload your Capital Account Statements and your holdings information is automatically extracted. Or manually input the holdings yourself."
                alignCenter
              />
              <img
                src="/images/upload-holdings-ui-preview.webp"
                alt=""
                style={{
                  width: "100%",
                  marginTop: 32,
                  marginBottom: 48,
                  padding: 32,
                  border: "1px solid #fdfdfd",
                  borderRadius: 8,
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              />
              <Button
                fullWidth
                icon={<Barcode height={16} />}
                onClick={handleBulkUploadClick}
                size="large"
                type={BUTTON_TYPES.SECONDARY}
              >
                Use Bulk Upload Table
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};

export interface ILPPortalChoice {
  imagePath: string;
  portalId: LPPortal;
  altText: string;
}

export interface IChoiceContainerProps extends ILPPortalChoice {
  sx?: SxProps;
  isActive?: boolean;
  onClick: () => void;
}

const StyledChoiceContainer = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 96px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fafaf8;
  transition: all 0.2s ease-in-out;

  img {
    user-select: none;
    display: block;
    width: 120px;
    align-self: center;
  }

  outline: ${(props) =>
    props.isActive ? "4px solid #dfdfd9" : "4px solid transparent"};
  border: ${(props) =>
    props.isActive ? "2px solid #bcb3a5" : "2px solid #dfdfd9"};

  &:hover {
    outline: ${(props) =>
      props.isActive ? "4px solid #dfdfd9" : "4px solid #fafaf8"};
    border: 2px solid #bcb3a5;
  }
`;

export const ChoiceContainer: React.FC<IChoiceContainerProps> = ({
  sx,
  imagePath,
  altText,
  isActive = false,
  onClick,
}) => {
  return (
    <StyledChoiceContainer onClick={onClick} isActive={isActive}>
      <div style={{ position: "absolute", right: "4px", top: "4px" }}>
        <Radio color="secondary" checked={isActive} />
      </div>

      <img src={imagePath} alt={altText} />
    </StyledChoiceContainer>
  );
};

interface IConfirmLPPortalDialogueProps {
  open: boolean;
  onClose: () => void;
  portalChoice: ILPPortalChoice | string;
}

export const ConfirmLPPortalDialogue: React.FC<
  IConfirmLPPortalDialogueProps
> = ({ open, onClose, portalChoice }) => {
  const [allowImportChecked, setAllowImportChecked] = useState<boolean>(true);
  const { emitEvent } = usePortfolioContext();

  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Stack sx={{ px: 4, py: 4 }} spacing={2}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ position: "relative" }}
            >
              <Typography variant="h5">Confirm portfolio linking</Typography>
              <IconButton
                onClick={onClose}
                sx={{
                  height: 40,
                  width: 40,
                  float: "right",
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography variant="body1" color="#737476">
              Tap will connect to your LP portfolio management tool to regularly
              import updated fund holdings.
            </Typography>
          </Stack>
          <Box
            sx={{
              height: "128px",
              border: `1px solid #DFDFD9`,
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", top: "16px", left: "20px" }}>
              <img src="/images/tap-logo.svg" alt="tap-logo" width={"64px"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "20px",
                left: "20px",
              }}
            >
              {typeof portalChoice == "string" ? (
                <Typography variant="h6">{portalChoice}</Typography>
              ) : (
                <img
                  src={(portalChoice as ILPPortalChoice).imagePath}
                  alt={(portalChoice as ILPPortalChoice).altText}
                  height={"24px"}
                />
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#DFDFD9",
                height: "1px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                border: `1px solid #DFDFD9`,
                borderRadius: "50px",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FAFAF8",
                right: "20px",
              }}
            >
              <PhosphorLink size={32} />
            </Box>
          </Box>
          <Stack
            direction={"row"}
            spacing={1}
            display="flex"
            alignItems={"center"}
            onClick={() => setAllowImportChecked(!allowImportChecked)}
          >
            <TapCheckbox
              checked={allowImportChecked}
              onChange={() => setAllowImportChecked(!allowImportChecked)}
            />
            <Typography>I agree Tap can import my holdings data.</Typography>
          </Stack>
          <Button
            type={BUTTON_TYPES.SECONDARY}
            onClick={() => {
              emitEvent!(
                new LPPortalConnectionSubmitted({
                  portal:
                    typeof portalChoice == "string"
                      ? portalChoice
                      : portalChoice.portalId,
                }),
              );
            }}
            disabled={!allowImportChecked}
            style={{
              marginTop: "40px",
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
              height: "48px",
            }}
          >
            Continue
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
