import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageHead } from "common/components/PageHead";
import { EmptyState } from "common/components/EmptyState";

import { useLimitedPartnersContext } from "../state/GpLimitedPartnersContext";
import { GpLimitedPartnersLoaded } from "../state/GpLimitedPartnersState";
import { GpLimitedPartnersTable } from "../components/GpLimitedPartnersTable";
import { LimitedPartnersListRequested } from "../state/GpLimitedPartnersEvent";

import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import { useNavigate } from "react-router";
import { GP_ROUTES } from "common/constants/routes";

export const GpLimitedPartners = () => {
  const navigate = useNavigate();
  const { limitedPartnersState, emitEvent } = useLimitedPartnersContext();

  useEffect(() => {
    emitEvent!(new LimitedPartnersListRequested());
  }, []);

  const handleBulkAddLpsClick = () => {
    navigate(GP_ROUTES.LimitedPartnersUploadSplit);
  };

  const partners =
    limitedPartnersState instanceof GpLimitedPartnersLoaded
      ? limitedPartnersState.partners
      : [];

  return (
    <>
      <Helmet title="Limited Partners" />
      <PageHead
        title="Limited Partners"
        countLabel={partners.length}
        actions={
          <>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              icon={<PlusIcon />}
              onClick={handleBulkAddLpsClick}
            >
              Bulk Add LPs
            </Button>
          </>
        }
      />
      {limitedPartnersState instanceof GpLimitedPartnersLoaded ? (
        <GpLimitedPartnersTable partners={partners} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
