import { cn } from "common/utils";

import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";

import {
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import {
  mockSoiLookthroughQuay,
  soiLookthroughMockColumnsQuay,
} from "../mock/SoiLookthroughMock";

export const SoiLookthroughQuay = ({}: {}) => {
  return (
    <>
      <PageSectionTitleDivider showBorderTop className="mt-24">
        SOI Lookthrough
      </PageSectionTitleDivider>

      <div className="w-full max-w-full overflow-x-scroll h-full  max-h-[700px] overflow-y-scroll relative">
        <TapTable
          skipBottomBorder
          skipTopBorder
          columns={soiLookthroughMockColumnsQuay}
          fixedHeaders
          className="!relative "
          renderRow={(row, { fixedHeaders }) => {
            return (
              <>
                <TableCell
                  className={cn({
                    "!sticky left-0 !bg-neutral-50 z-10 border-zinc-400 overflow-visible":
                      fixedHeaders,
                  })}
                  isPrimaryColumn
                >
                  {row[0]}
                </TableCell>
                <TableCell>{row[1]}</TableCell>
                <TableCell>{row[2]}</TableCell>
                <TableCell>{row[3]}</TableCell>
                <TableCell>{row[4]}</TableCell>
                <TableCell>{row[5]}</TableCell>
                <TableCell>{row[6]}</TableCell>
                <TableCell>{row[7]}</TableCell>
                <TableCell>{row[8]}</TableCell>
                <TableCell>{row[9]}</TableCell>
                <TableCell>{row[10]}</TableCell>
                <TableCell>{row[11]}</TableCell>
                <TableCell>{row[12]}</TableCell>
                <TableCell>{row[13]}</TableCell>
                <TableCell>{row[14]}</TableCell>
              </>
            );
          }}
          data={mockSoiLookthroughQuay}
        />
      </div>
    </>
  );
};
