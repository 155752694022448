import { Helmet } from "react-helmet";
import { Buildings, Signature } from "@phosphor-icons/react";

import { GpDashboardLeftColumn } from "./components/GpDashboardLeftColumn";
import { GpDashboardCenterColumn } from "./components/GpDashboardCenterColumn";
import { GpDashboardRightColumn } from "./components/GpDashboardRightColumn";
import { useEffect } from "react";
import { useDashboardContext } from "../state/DashboardContext";
import {
  ActiveRecentIoiListRequested,
  RedirectedToDashboardScreen,
} from "../state/DashboardEvent";
import { MockDashboardLoadedForGP } from "../state/DashboardState";
import { GP_ROUTES } from "common/constants/routes";
import { BarLoader } from "common/components/BarLoader";
import { StyledDashboard } from "../components/DashboardWrapper";

export type GpDashboardTask = {
  title: string;
  description: string;
  buttonCta: string;
  buttonClick?: () => void;
  enabled: boolean;
  icon: React.ReactNode;
};

const getDashboardTasks = (
  dashboardState: MockDashboardLoadedForGP,
): GpDashboardTask[] => {
  // const navigate = useNavigate();

  const tasks = [
    {
      title: "Platform NDA",
      description:
        "Review Tap’s Non-Disclosure Agreement to share information.",
      buttonCta: "View NDA",
      buttonClick: () => {},
      enabled: dashboardState.canSignPlatformNDA,
      icon: <Signature color="#BCB3A5" size={14} />,
    },
    {
      title: "Approve Buyers",
      description: "Review proposed Buyers for a transaction in your funds.",
      buttonCta: "Review",
      buttonClick: () => {
        window.location.href = GP_ROUTES.ApprovedBuyersApproved;
      },
      enabled: dashboardState.shouldApproveBuyers,
      icon: <Buildings color="#BCB3A5" size={14} />,
    },
  ];

  return tasks;
};

export const useGpDashboardPage = () => {
  const { emitEvent, dashboardState } = useDashboardContext();

  useEffect(() => {
    emitEvent(
      new RedirectedToDashboardScreen({
        isDemo: true,
      }),
    );
  }, []);

  const managedFunds =
    dashboardState instanceof MockDashboardLoadedForGP
      ? dashboardState.limitedPartners
      : [];

  const tasks =
    dashboardState instanceof MockDashboardLoadedForGP
      ? getDashboardTasks(dashboardState)
      : [];

  const transactions =
    dashboardState instanceof MockDashboardLoadedForGP
      ? dashboardState.transactionsList
      : [];

  const buyers =
    dashboardState instanceof MockDashboardLoadedForGP
      ? dashboardState.buyersList
      : [];

  const managedFundsLoaded = dashboardState instanceof MockDashboardLoadedForGP;
  const tasksLoaded = dashboardState instanceof MockDashboardLoadedForGP;
  const transactionsLoaded = dashboardState instanceof MockDashboardLoadedForGP;
  const buyersLoaded = dashboardState instanceof MockDashboardLoadedForGP;

  return {
    managedFunds,
    managedFundsLoaded,

    tasks,
    tasksLoaded,
    transactions,
    transactionsLoaded,

    buyers,
    buyersLoaded,
  };
};

export const GpDashboardPage = () => {
  const {
    managedFunds,
    managedFundsLoaded,
    tasks,
    tasksLoaded,
    transactions,
    transactionsLoaded,
    buyers,
    buyersLoaded,
  } = useGpDashboardPage();

  const hasLoaded = managedFundsLoaded && tasksLoaded && transactionsLoaded;

  return (
    <>
      <Helmet title="Dashboard" />
      {hasLoaded ? (
        <StyledDashboard>
          <GpDashboardLeftColumn
            managedFunds={managedFunds}
            managedFundsLoaded={managedFundsLoaded}
          />
          <GpDashboardCenterColumn
            tasks={tasks}
            transactions={transactions}
            transactionsLoaded={transactionsLoaded}
          />
          <GpDashboardRightColumn buyers={buyers} buyersLoaded={buyersLoaded} />
        </StyledDashboard>
      ) : (
        <BarLoader className="min-h-screen pb-24 box-border" />
      )}
    </>
  );
};
