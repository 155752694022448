import { useState } from "react";
import { scaleBand, scaleLinear } from "@visx/scale";

import { Fund } from "experiences/funds/domain/models/Fund";
import { ICashFlows } from "experiences/funds/domain/models/FundCashFlows";
import {
  CONTRIBUTIONS_NEGATIVE,
  cashFlowKeys,
  cashFlowsContributionsKey,
  cashFlowsDistributionsKey,
} from "../components/viewFund/CashFlowsChart";

const timeRangeOptions = [
  {
    label: "1y",
    value: 4,
  },
  {
    label: "2y",
    value: 8,
  },
  {
    label: "3Y",
    value: 12,
  },
  {
    label: "Max",
    value: 99,
  },
];

export type ICashFlowsChart = {
  showDistributions: boolean;
  showContributions: boolean;
  toggleShowDistributions: () => void;
  toggleShowContributions: () => void;
  quarters: ICashFlows[];
  timeRange: number;
  handleTimeOptionClick: (value: number) => void;
  quarterScale: any;
  amountScale: any;
  timeRangeOptions: typeof timeRangeOptions;
};

export const useCashFlows = ({ fund }: { fund?: Fund }): ICashFlowsChart => {
  const [showDistributions, setShowDistributions] = useState(true);
  const [showContributions, setShowContributions] = useState(true);

  const [timeRange, setTimeRange] = useState(
    timeRangeOptions[timeRangeOptions.length - 1].value,
  );

  const cashFlows = fund?.cashFlows || [];

  const quarters = cashFlows.slice(
    cashFlows.length - timeRange,
    cashFlows.length,
  ); // doing this in reverse so newest value is on the right (starting from most recent)

  const toggleShowDistributions = () => {
    setShowDistributions(!showDistributions);
    // toggle the other bar so we don't have a blank chart
    if (!showContributions) {
      setShowContributions(true);
    }
  };

  const toggleShowContributions = () => {
    setShowContributions(!showContributions);
    // toggle the other bar so we don't have a blank chart
    if (!showDistributions) {
      setShowDistributions(true);
    }
  };

  const handleTimeOptionClick = (value: number) => {
    setTimeRange(value);
  };

  // dynamic scales
  const quarterScale = scaleBand<string>({
    domain: quarters.map((d) => `${d.date}`),
    padding: 0.2,
  });

  // for quarters, get the max value of the sum of each individual quarter's keys
  // if distributions are negative, return the highest contribution
  // if distributions are negative: [-min(distribution), max(contribution)]
  // if distributions are positive: [0, max(contribution | distribution)]
  const maxQuarter = quarters.reduce((acc, quarter) => {
    if (CONTRIBUTIONS_NEGATIVE) {
      const contribution = quarter[cashFlowsDistributionsKey] || 0;
      return Math.max(acc, contribution);
    }

    // keys does in fact exist but kinda hard to hint typescript it does exist
    // ignoring for now
    const sum = cashFlowKeys.reduce((acc, key) => {
      // @ts-ignore -- sum all keys values
      return acc + quarter[key];
    }, 0);
    return Math.max(acc, sum);
  }, 0);

  // if distributions are negative, return the highest distribution (as negative)
  // else, the start of the scale is 0
  // if distributions are negative: [-min(distribution), max(contribution)]
  // if distributions are positive: [0, max(contribution | distribution)]
  const minQuarter = -quarters.reduce((acc, quarter) => {
    if (CONTRIBUTIONS_NEGATIVE) {
      const distribution = quarter[cashFlowsContributionsKey] || 0;
      return Math.max(acc, distribution);
    }

    return 0;
  }, 0);

  const amountScale = scaleLinear<number>({
    domain: [minQuarter, maxQuarter],
  });

  //
  const filteredQuarters = quarters.map((quarter) => {
    const filteredQuarter = {
      ...quarter,
    };
    if (!showDistributions) {
      delete filteredQuarter[cashFlowsDistributionsKey];
    }
    if (!showContributions) {
      delete filteredQuarter[cashFlowsContributionsKey];
    }
    return filteredQuarter;
  });

  return {
    showDistributions,
    showContributions,
    toggleShowDistributions,
    toggleShowContributions,
    quarters: filteredQuarters,
    timeRange,
    handleTimeOptionClick,
    quarterScale,
    amountScale,
    timeRangeOptions: timeRangeOptions,
  };
};
