import { useEffect, useState, useCallback } from "react";
import { match } from "fp-ts/lib/Either";
import classNames from "classnames";

import { Failure } from "../../../../common/@types/app/Failure";
import { RetrieveOrganizations } from "experiences/authentication/domain/usecases/RetrieveOrganizations";
import {
  CreateOrganizationForm,
  ICreateOrganizationForm,
  IOrganization,
  OrganizationTypeLabels,
  Organization,
  OrganizationPage,
} from "experiences/authentication/domain/models/Organization";
import Combobox from "common/components/Combobox";
import { FUND_LOGO_URL } from "common/constants/platform";
import Highlighted from "common/components/Highlighted";
import CreateOrganizationModal from "./CreateOrganizationModal";

export const OrganizationSelector = ({
  preSelectedOrg,
  onFormFieldChange,
  errorMessage,
  placeholder,
  className,
  modalClassName,
}: {
  onFormFieldChange: (value: IOrganization | ICreateOrganizationForm) => void;
  errorMessage?: string;
  preSelectedOrg?: IOrganization;
  placeholder?: string;
  className?: string;
  modalClassName?: string;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [creationModalOpen, setCreationModalOpen] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    CreateOrganizationForm | Organization
  >(() => {
    if (preSelectedOrg) {
      return new Organization(preSelectedOrg);
    }
  });

  useEffect(() => {
    if (preSelectedOrg) {
      onFormFieldChange(preSelectedOrg);
    } else {
      onFormFieldChange(selectedOrganization);
    }
  }, [selectedOrganization]);

  const refreshOrganizations = useCallback(() => {
    new RetrieveOrganizations()
      .call({
        paginationSpecs: { perPage: 100, pageIndex: 1 },
        filters: { searchTerm },
      })
      .then((resp) => {
        match<Failure, OrganizationPage, void>(
          (_: Failure) => {},
          (page: OrganizationPage) => {
            setOrganizations(page.organizations);
          },
        )(resp);
      });
  }, [searchTerm]);

  useEffect(() => {
    refreshOrganizations();
  }, [searchTerm]);

  const resetSearchTerm = () => {
    setSearchTerm("");
  };

  const onCreateOrganizationClicked = () => {
    setCreationModalOpen(false);
    resetSearchTerm();
  };

  return (
    <>
      <Combobox
        searchInput={searchTerm}
        listItems={organizations.map((org) => ({
          value: org.id,
          child: (
            <OrganizationListItem
              org={org}
              selected={false}
              highlightedSubstr={searchTerm}
            />
          ),
        }))}
        buttonChild={
          selectedOrganization ? (
            <OrganizationListItem
              org={selectedOrganization}
              selected={true}
              highlightedSubstr={searchTerm}
            />
          ) : (
            <div>{placeholder}</div>
          )
        }
        emptySearchResultChild={
          <EmptySearchResultListItem
            onClick={() => setCreationModalOpen(true)}
          />
        }
        onSearchInputChanged={setSearchTerm}
        onItemChanged={(itemValue: string) => {
          const matchedOrg = organizations.find((org) => org.id === itemValue);
          if (matchedOrg) {
            setSelectedOrganization(new Organization(matchedOrg));
          }
        }}
        disabled={Boolean(preSelectedOrg)}
        errorMessage={errorMessage}
        className={className}
      />
      <CreateOrganizationModal
        open={creationModalOpen}
        onClose={onCreateOrganizationClicked}
        setFirm={setSelectedOrganization}
        className={modalClassName}
      />
    </>
  );
};

interface IProps {
  org: Organization | CreateOrganizationForm;
  selected: boolean;
  highlightedSubstr?: string;
}

const OrganizationListItem: React.FC<IProps> = ({
  org,
  selected,
  highlightedSubstr,
}) => {
  return (
    <div
      className={classNames(
        "flex py-1 flex-direction-row w-full gap-2 rounded-md items-center px-1",
        { "m-1": !selected },
      )}
    >
      <img
        src={`${FUND_LOGO_URL}/${
          org instanceof Organization ? org.id : "no-logo"
        }.jpg`}
        alt="Logo"
        className="w-8 h-8 rounded-lg object-cover border-2 border-nomad-100"
      />
      <div className="text-base max-w-full overflow-x-clip text-ellipsis">
        {selected ? (
          org.name
        ) : (
          <Highlighted text={org.name} highlight={highlightedSubstr} />
        )}
      </div>
      <div className="flex text-xs bg-nomad-100 px-2 py-0.5 rounded-full items-center justify-center">
        {OrganizationTypeLabels[org.type]}
      </div>
    </div>
  );
};

interface IEmptySearchResultListItemProps {
  onClick: () => void;
}

const EmptySearchResultListItem: React.FC<IEmptySearchResultListItemProps> = ({
  onClick,
}) => {
  return (
    <div
      className="flex flex-direction-row items-center px-2 py-2 gap-1 cursor-pointer"
      onClick={onClick}
    >
      <div className="text-nomad-700">Can't find your organization?</div>
      <div className="border-b-2 border-nomad-700 leading-4">create it</div>
    </div>
  );
};
