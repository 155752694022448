import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { portfolioClient } from "common/clients/ApolloClient";
import { PageHead } from "common/components/PageHead";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { PageWrapperPortfolioV2 } from "experiences/common/PageWrapper";
import {
  EntitiesSelector,
  useEntitiesSelector,
} from "../components/EntitiesDropdown";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { getReportDatesForReportName } from "experiences/portfolio-v2/domain/hooks/getReportDatesForReportName";
import { ReportDateName } from "experiences/portfolio-v2/domain/models/ReportDates";

import { GetPortfolioCashflowsQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioCashflows";
import {
  IPortfolioCashflows,
  IPortfolioCashflowsResponse,
} from "experiences/portfolio-v2/domain/models/PortfolioCashflows";
import { CashflowsTable } from "../components/CashflowsTable";

const formatCashflows = (cashflows: IPortfolioCashflows[]) => {
  const nonNullCashflows = cashflows
    .filter(
      (cashflow) => cashflow.amount !== null && cashflow.amount !== undefined,
    )
    .sort((a, b) => {
      return Number(b.amount) - Number(a.amount);
    });
  const nullCashflows = cashflows.filter(
    (cashflow) =>
      cashflow.amount === null ||
      cashflow.amount === "" ||
      cashflow.amount === undefined,
  );

  return [...nonNullCashflows, ...nullCashflows];
};

const useCashflows = () => {
  const {
    selectedEntitiesIds,
    reportDates: allReportDates,
    entitiesLoading,
  } = useContext(PortfolioContext);
  const cashflowsReportDates = getReportDatesForReportName({
    reportDates: allReportDates,
    reportName: ReportDateName.InterimCashflows,
  });
  const [loading, setLoading] = useState(false);
  const [selectedReportDate, setSelectedReportDate] = useState<string | null>(
    cashflowsReportDates.length > 0 ? cashflowsReportDates[0] : null,
  );
  const [cashflowsData, setCashflowsData] = useState<IPortfolioCashflows[]>([]);
  const entitySelector = useEntitiesSelector();

  const reportDatesOptions = cashflowsReportDates.map((reportDate) => ({
    label: reportDate,
    value: reportDate,
  }));

  const handleRefetch = async () => {
    setLoading(true);
    if (selectedEntitiesIds.length === 0 || selectedReportDate === null) {
      setLoading(false);
      setSelectedReportDate(null);
      setCashflowsData([]);
      return;
    }
    await refetch({
      entitiesIds: selectedEntitiesIds,
      reportDate: selectedReportDate,
    }).then((r) => {
      setLoading(false);
      const formattedCashflows = formatCashflows(r.data?.interimCashflows);
      setCashflowsData(formattedCashflows);
    });
  };

  const {
    data,
    loading: cashflowsLoading,
    refetch: refetch,
  } = useQuery<IPortfolioCashflowsResponse>(GetPortfolioCashflowsQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
      reportDate: selectedReportDate,
    },
    skip: true,
  });

  useEffect(() => {
    if (data) {
      const formattedCashflows = formatCashflows(data.interimCashflows);
      setCashflowsData(formattedCashflows);
    }
  }, [data]);

  useEffect(() => {
    if (cashflowsReportDates.length > 0) {
      setSelectedReportDate(cashflowsReportDates[0]);
    }
  }, [allReportDates]);

  useEffect(() => {
    if (!selectedReportDate) {
      setSelectedReportDate(cashflowsReportDates[0]);
    }
  }, []);

  useEffect(() => {
    handleRefetch();
  }, [selectedReportDate, selectedEntitiesIds]);

  const onReportDateChange = (reportDate: string) => {
    setSelectedReportDate(reportDate);
  };

  return {
    loading: loading || entitiesLoading || cashflowsLoading,
    entitySelector,
    data: cashflowsData || [],
    reportDatesOptions,
    selectedReportDate,
    onReportDateChange,
  };
};

export const CashflowsPage = () => {
  const {
    loading,
    entitySelector,
    data,
    selectedReportDate,
    reportDatesOptions,
    onReportDateChange,
  } = useCashflows();

  return (
    <PageWrapperPortfolioV2 className="min-h-fit">
      <PageHead
        title="Cashflows"
        actions={
          <EntitiesSelector
            entitiesOptions={entitySelector.entitiesOptions}
            selectedEntitiesIds={entitySelector.selectedEntitiesIds}
            onSelectEntity={entitySelector.onSelectEntity}
            onRemoveEntity={entitySelector.onRemoveEntity}
            onToggleAllEntities={entitySelector.onToggleAllEntities}
          />
        }
      />
      <PageSectionTitleDivider
        showBorderTop
        skipBorderBottom
        actions={
          <>
            <SelectDateDropdown
              value={selectedReportDate}
              onValueChange={onReportDateChange}
              options={reportDatesOptions}
              loading={loading}
            />
          </>
        }
      ></PageSectionTitleDivider>
      <CashflowsTable data={data} loading={loading} />
    </PageWrapperPortfolioV2>
  );
};
