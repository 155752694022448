import { UserSettings } from "experiences/authentication/domain/models/Settings";
import styled from "styled-components";

const StyledAvatar = styled.div<{
  size: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f2f2f0;
  border: 1px solid #dfdfd9;
  box-sizing: border-box;
  user-select: none;

  img {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    object-fit: cover;
  }
`;
export const Avatar = ({
  size = 32,
  src,
  user,
}: {
  size?: number;
  src?: string;
  user?: UserSettings;
}) => {
  const initials = `${user?.firstName[0] || ""}` + `${user?.lastName[0] || ""}`;

  return (
    <StyledAvatar size={size}>
      {src ? <img src={src} /> : <span>{initials}</span>}
    </StyledAvatar>
  );
};
