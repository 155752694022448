export enum UNIVERSAL_ROUTES {
  Auth = "/auth",
  SignUp = "/auth/register",
  AuthLogin = "/auth/login",
  AuthRegisterInvitee = "/auth/onboard/invitee/:inviteeId",
  AuthInvalid = "/auth/invalid",
  AuthSessionClaim = "/auth/session/claim/:idb64/:token",
  AuthOtp = "/auth/otp/:challengeId",
  AuthLogout = "/auth/logout",
  Playground = "/playground",
}

// TODO: make all relative routes absolute at some point
export enum TenderDetailsRoutes {
  Offer = "/offer",
  DataRoom = "/dataroom",
  Overview = "/overview",
}

export enum LPTransactionDataRoomRoutes {
  Data = "/data",
  Documents = "/documents",
}

export enum LP_ROUTES {
  Funds = "/lp/funds",
  FundsAlerts = "/lp/funds/alerts",
  FundsScreen = "/lp/funds/screen",
  FundsFundDetail = "/lp/funds/fund/:fundId",
  VerifyFundPermissions = "/lp/funds/fund/:fundId/verify-permissions",
  CompanyDetail = "/lp/companies/:companyId",
  // portfolio v2
  PortfolioEntrypoint = "/lps/portfolio",
  // portfolio v1
  Portfolio = "/lp/portfolio",
  PortfolioSellHoldings = "/lp/portfolio/sell/holdings",
  PortfolioHoldings = "/lp/portfolio/holdings",
  PortfolioPortalConnectionReview = "/lp/portfolio/portfolio-connection-review",
  PortfolioConnectPortfolio = "/lp/portfolio/connect-portfolio",
  PortfolioHoldingsUpload = "/lp/portfolio/holdings/upload",
  PortfolioUploadCapitalAccounts = "/lp/portfolio/upload-capital-accounts",
  DashboardNewIois = "/lp/dashboard-new-iois", // TODO: Create a fallback route when shipping LP experience, or it will break older emails
  Dashboard = "/lp/dashboard",
  DocumentsSignNDA = "/lp/documents/sign-nda",
  Transactions = "/lp/transactions",
  TendersRoot = "/lp/tenders",
  TenderDetails = "/lp/tenders/:txnId",
  TenderDetailsOverview = `/lp/tenders/:txnId${TenderDetailsRoutes.Overview}`,
  TenderDetailsDataRoomData = `/lp/tenders/:txnId${TenderDetailsRoutes.DataRoom}${LPTransactionDataRoomRoutes.Data}`,
  TenderDetailsDataRoomDocuments = `/lp/tenders/:txnId${TenderDetailsRoutes.DataRoom}${LPTransactionDataRoomRoutes.Documents}`,
  TenderDetailsDataRoom = `/lp/tenders/:txnId${TenderDetailsRoutes.DataRoom}`,
  TenderDetailsOffer = `/lp/tenders/:txnId${TenderDetailsRoutes.Offer}`,
  TransactionsCreate = "/lp/transactions/create",
  TransactionsActive = "/lp/transactions/active",
  TransactionsPending = "/lp/transactions/pending",
  TransactionsManageAccess = "/lp/transactions/:txnId/manage/access",
  TransactionsInitiateMarkup = "/lp/transactions/:txnId/markup",
  TransactionDetails = "/lp/transactions/:txnId/details",
  IndicationsOfInterest = "/lp/indications-of-interest",
  IndicationsOfInterestBids = "/lp/indications-of-interest/bids",
  IndicationsOfInterestAsks = "/lp/indications-of-interest/asks",
  IndicationsOfInterestArchive = "/lp/indications-of-interest/archive",
  IndicationsOfInterestBulkUpload = "/lp/indications-of-interest/bulk-upload",
  IndicationsOfInterestUpload = "/lp/indications-of-interest/upload",
  IndicationsOfInterestTableUpload = "/lp/indications-of-interest/upload/table-upload",
  IndicationsOfInterestManagerUpload = "/lp/indications-of-interest/upload/manager-upload",
  Settings = "/lp/settings",
  Root = "/lp",
}

export enum LpSettingsRoutes {
  InvestmentProfile = "/investment-profile",
  Documents = "/documents",
}

// used for nesting routes
export enum GpTransactionDetailRoutes {
  Overview = "/overview",
  TransactionMonitor = "/monitor",
  DataRoom = "/data-room",
  Participants = "/participants",
  Settlement = "/settlement",
}

// used for nesting routes
export enum GpTransactionMonitorRoutes {
  PreliminaryInterest = "/preliminary-interest",
  NonBindingBids = "/non-binding-bids",
  BindingBids = "/binding-bids",
  ParticipationAmount = "/participation-amount",
}

// used for nesting routes
export enum GpTransactionParticipantRoutes {
  Participants = "/participants",
  TeamMembers = "/team-members",
}

export enum GpTransactionDataRoomRoutes {
  Data = "/data",
  Documents = "/documents",
}

export enum GpComplianceRoutes {
  QualifiedMatchingService = "/qms",
  FirmOverviewDetail = "/firm-overview/:firmId",
  FairnessOpinions = "/fairness-opinions",
}

export enum GpLimitedPartnersRoutes {
  List = "/list",
  UploadSplit = "/upload-split",
  UploadCapitalAccountStatement = "/upload-capital-account-statement",
  UploadCapitalAccountsBulk = "/upload-capital-accounts-bulk",
}

export enum GP_ROUTES {
  ApprovedBuyers = "/gp/approved-buyers",
  ApprovedBuyersApproved = "/gp/approved-buyers/approved",
  ApprovedBuyersRequested = "/gp/approved-buyers/requested",
  ApprovedBuyersBlocked = "/gp/approved-buyers/bloqued",
  ApprovedBuyersArchived = "/gp/approved-buyers/archived",
  Dashboard = "/gp/dashboard",
  DashboardNewIois = "/gp/dashboard-new-iois",
  TransactionDetail = "/gp/transaction/:transactionId",
  TransactionDetailOverview = `/gp/transaction/:transactionId/${GpTransactionDetailRoutes.Overview}`,
  TransactionDetailTransactionMonitor = `/gp/transaction/:transactionId${GpTransactionDetailRoutes.TransactionMonitor}`,
  TransactionDetailTransactionMonitorParticipation = `/gp/transaction/:transactionId${GpTransactionDetailRoutes.TransactionMonitor}${GpTransactionMonitorRoutes.PreliminaryInterest}`,
  TransactionDetailTransactionMonitorNonBindingBids = `/gp/transaction/:transactionId${GpTransactionDetailRoutes.TransactionMonitor}${GpTransactionMonitorRoutes.NonBindingBids}`,
  TransactionDetailTransactionMonitorBindingBids = `/gp/transaction/:transactionId${GpTransactionDetailRoutes.TransactionMonitor}${GpTransactionMonitorRoutes.BindingBids}`,
  TransactionDetailTransactionMonitorElection = `/gp/transaction/:transactionId${GpTransactionDetailRoutes.TransactionMonitor}${GpTransactionMonitorRoutes.ParticipationAmount}`,
  TransactionDetailDataRoom = `/gp/transaction/:transactionId/${GpTransactionDetailRoutes.DataRoom}`,
  TransactionDetailDataRoomData = `/gp/transaction/:transactionId/${GpTransactionDetailRoutes.DataRoom}${GpTransactionDataRoomRoutes.Data}`,
  TransactionDetailDataRoomDocuments = `/gp/transaction/:transactionId/${GpTransactionDetailRoutes.DataRoom}${GpTransactionDataRoomRoutes.Documents}`,
  TransactionDetailSettlement = `/gp/transaction/:transactionId/${GpTransactionDetailRoutes.Settlement}`,
  TransactionDetailParticipants = `/gp/transaction/:transactionId/${GpTransactionDetailRoutes.Participants}`,
  TransactionList = "/gp/transaction-list",
  TransactionSettings = "/gp/transaction-settings",
  TransactionSettingsTransactionPolicies = "/gp/transaction-settings/transaction-policies",
  TransactionsSettingsNDAs = "/gp/transaction-settings/ndas",
  TransactionsSettingsTransferAgreements = "/gp/transaction-settings/transfer-agreements",
  Compliance = "/gp/compliance",
  ComplianceQualifiedMatchingService = `/gp/compliance${GpComplianceRoutes.QualifiedMatchingService}`,
  ComplianceFirmOverviewDetail = `/gp/compliance${GpComplianceRoutes.FirmOverviewDetail}`,
  ComplianceFairnessOpinions = `/gp/compliance${GpComplianceRoutes.FairnessOpinions}`,
  MarketMonitor = "/gp/market-monitor",
  LimitedPartners = "/gp/limited-partners",
  LimitedPartnersList = `/gp/limited-partners${GpLimitedPartnersRoutes.List}`,
  LimitedPartnersUploadSplit = `/gp/limited-partners${GpLimitedPartnersRoutes.UploadSplit}`,
  LimitedPartnersUploadCapitalAccountStatement = `/gp/limited-partners${GpLimitedPartnersRoutes.UploadCapitalAccountStatement}`,
  LimitedPartnersUploadCapitalAccountsBulk = `/gp/limited-partners${GpLimitedPartnersRoutes.UploadCapitalAccountsBulk}`,
  Root = "/gp",
  Firm = "/gp/firm",
  FirmTeamMembers = "/gp/firm/team-members",
  FirmFunds = "/gp/firm/funds",
}

export enum THIRD_PARTY_ROUTES {
  Transactions = "/tp/transactions",
  TransactionDetail = "/tp/transactions/:txnId",
  CreateTransaction = "/tp/create_transaction",
  Compliance = "/tp/compliance",
}
