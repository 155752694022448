import {
  Stack,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import styled from "styled-components";

import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { useRedliningStepsContext } from "../components/useRedliningStepsContext";
import { RedliningRequestCompleted } from "../state/DocumentsEvent";
import { useDocumentsContext } from "../state/DocumentsContext";

const STEP_SIZE = 24;

const CustomizedConnector = withStyles({
  root: {
    display: "block",
    position: "relative",
    marginLeft: 0,
    left: STEP_SIZE / 2,
    top: -STEP_SIZE / 2,
  },
  completed: {
    "& $line": {
      borderColor: "#85d388",
      borderTopWidth: 1,
    },
  },
  line: {
    borderColor: "#dbdcda",
    position: "absolute",
  },
})(StepConnector);

const StepLabelTitle = styled.h3`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  color: #21272d;

  cursor: default;
`;

const StepLabelDescription = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #737476;

  cursor: default;
`;

const StyledStep = styled.div`
  width: ${STEP_SIZE}px;
  height: ${STEP_SIZE}px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 1px solid #dbdcda;
  margin-right: 22px;
`;

const CompletedStepIcon = styled(StyledStep)`
  width: 24px;
  height: 24px;
  border-radius: 5000px;
  border: 1px solid var(--branding-accent, #bcb3a5);
  background: var(--branding-texture-2, #fafaf8);
  box-shadow: 0px 0px 0px 3px rgba(188, 179, 165, 0.25);
  margin-right: 22px;
`;

const CustomStepperIcon = ({ completed }: StepIconProps) => {
  if (completed) {
    return (
      <CompletedStepIcon>
        <CheckIcon sx={{ color: "#bcb3a5", width: 12 }} />
      </CompletedStepIcon>
    );
  }

  return <StyledStep />;
};

const RequestRedliningConfirmation = () => {
  const { emitEvent } = useDocumentsContext();
  const { steps } = useRedliningStepsContext();

  return (
    <>
      <PageHead title="Sign NDA" />
      <StepperFlow name="Sign NDA" activeStepIndex={0}>
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            marginTop: "-32px",
            justifyContent: "center",
          }}
        >
          <PageHead
            titleStyle={{ paddingTop: 0 }}
            title="Document Redlining Request Received"
            description="Our team has been notified and your capital markets advisor will contact you shortly to work through the redlining process."
          />

          <Stepper
            activeStep={1}
            orientation="vertical"
            connector={<CustomizedConnector />}
          >
            {steps.map((step, idx) => (
              <Step
                key={step.label}
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                <StepLabel StepIconComponent={CustomStepperIcon}>
                  <StepLabelTitle
                    style={{ color: idx === 0 ? "#BCB3A5" : "#21272d" }}
                  >
                    {step.label}
                  </StepLabelTitle>
                  <StepLabelDescription>
                    {step.description}
                  </StepLabelDescription>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Stack direction="row" py={"32px"}>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              onClick={() => emitEvent!(new RedliningRequestCompleted())}
            >
              Finish
            </Button>
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};

export default RequestRedliningConfirmation;
