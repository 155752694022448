import { PageHead } from "common/components/PageHead";
import { Helmet } from "react-helmet";
import { useComplianceContext } from "../state/ComplianceContext";
import { useEffect } from "react";
import { FirmOverviewRequested } from "../state/ComplianceEvent";
import { FirmOverviewLoaded } from "../state/ComplianceState";
import { EmptyState } from "common/components/EmptyState";
import { FirmOverviewTable } from "../components/FirmOverviewTable";

export const FirmOverviewPage = () => {
  const { emitEvent, firmOverviewState } = useComplianceContext();

  useEffect(() => {
    emitEvent(new FirmOverviewRequested());
  }, []);

  const firmOverview =
    firmOverviewState instanceof FirmOverviewLoaded
      ? firmOverviewState.firmOverview
      : {};

  const title =
    firmOverviewState instanceof FirmOverviewLoaded
      ? firmOverviewState.firmOverview.firmName
      : "Firm Overview";

  console.log({ firmOverview });

  return (
    <>
      <Helmet title={title} />
      {/* <PageHead title={title} /> */}

      {firmOverviewState instanceof FirmOverviewLoaded ? (
        <FirmOverviewTable firmOverview={firmOverview} title={title} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
