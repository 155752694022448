import { gql } from "@apollo/client";

export const GetTransactionFundExposureSummary = gql`
  query FundExposureSummary(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]
  ) {
    fundSummaryExposure(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      tapFundId
      fundName
      tapManagerId
      managerName
      fundSize
      fundCommittedCapital
      strategy
      vintage
      geography
      reportDate
      lpCommittedCapital
      lpContributedCapitalItd
      lpDistributedCapitalItd
      lpRemainingUnfunded
      lpEndingCapitalAccountValue
      lpCurrentExposure
      lpInterimContributions
      lpInterimDistributions
      unrealizedInvestmentCount
      top3Concentration
      top10Concentration
      percentPublic
      weightedAvgRevenueGrowth
      weightedAvgEbitdaMargin
      weightedAvgEbitdaMultiple
      weightedAvgLeverage
      fundNavQoqPercentChg1q
      fundNavQoqPercentChg2q
      fundNavQoqPercentChg3q
      fundNavYoyPercentChangeFy1
      fundNavYoyPercentChangeFy2
      tapPriceEstimate
      tapPriceEstimateCertainty
      baseManagementFee
      carry
      hurdleRate
      ptpRiskLevel
      ptpQueueLength
      rofr
      rofo
      blocker
      domicile
      fundSeriesAverageMoic
      isHumanVerified
      issuerId
    }
  }
`;
