import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";

import Loader from "common/components/TapLoader";
import { useApprovedBuyersContext } from "../state/ApprovedBuyersContext";
import { BlockedBuyersLoaded } from "../state/ApprovedBuyersState";
import { LoadBlockedBuyers } from "../state/ApprovedBuyerEvent";
import { PageHead } from "common/components/PageHead";
import { BlockedBuyersTable } from "../components/BlockedBuyersTable";

export const BlockedBuyers = () => {
  const { blockedBuyerTablePageState, emitEvent } = useApprovedBuyersContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    emitEvent!(new LoadBlockedBuyers());
  }, []);

  const filteredApprovedBuyers =
    blockedBuyerTablePageState instanceof BlockedBuyersLoaded
      ? blockedBuyerTablePageState.blockedBuyers.filter((approvedBuyer) =>
          approvedBuyer.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

  return (
    <>
      <Stack width="100%">
        <Helmet title="Blocked" />
        <PageHead title="Blocked Buyers" />
      </Stack>
      <Stack style={{ width: "100%" }}>
        {blockedBuyerTablePageState instanceof BlockedBuyersLoaded ? (
          <BlockedBuyersTable
            blockedBuyers={filteredApprovedBuyers}
            searchQuery={search}
          />
        ) : (
          <Loader />
        )}
      </Stack>
    </>
  );
};
