import { Ruler } from "@phosphor-icons/react";

import { VerifyPermissionOverlay } from "../VerifyPermissionOverlay";
import { IoiType } from "experiences/indications-of-interest/presentation/pages/AddInterestModal";

interface NoUserBidsProps {
  toggleSubmitIoiModal: (type: IoiType) => void;
}

export const NoUserBids = ({ toggleSubmitIoiModal }: NoUserBidsProps) => {
  return (
    <VerifyPermissionOverlay
      overlay
      messageStyle={{ width: 280, whiteSpace: "pre-wrap" }}
      mainIcon={<Ruler size={32} color="#BCB3A5" />}
      buttonText="Submit IOI"
      buttonIcon={<></>}
      headerText={"Add A Bid Price To Compare"}
      subHeaderText={
        "Secondaries Funds cannot see the buy side of the Order Book directly. If you enter a priced bid you can compare it with the Average Bid Range of other buyers."
      }
      handleVerifyPermissionClick={() => toggleSubmitIoiModal(IoiType.Bid)}
    />
  );
};
