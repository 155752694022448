import { gql } from "@apollo/client";

export const UploadLpDocumentsMutation = gql`
  mutation UploadTransactionProcessingDocuments(
    $transactionId: String!
    $files: [String!]!
  ) {
    uploadTransactionProcessingDocuments(
      transactionId: $transactionId
      filesNames: $files
    ) {
      transactionId
      urls {
        name
        url
        fields {
          key
          AWSAccessKeyId
          policy
          signature
        }
      }
    }
  }
`;
