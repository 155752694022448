// TODO: There's a duplicate of this @ experiences/transactions/domain/models/FundExposureSummary.ts, consolidate them
export enum FundStrategy {
  VentureCapital = 1,
  PrivateEquity = 2,
  HedgeFunds = 3,
  FundOfFunds = 4,
  RealEstate = 5,
  PrivateDebt = 6,
  Growth = 7,
  Secondaries = 8,
  Infrastructure = 9,
  NaturalResource = 10,
  Other = 11,
}

export const FundStrategyNameMap = new Map<FundStrategy, string>([
  [FundStrategy.VentureCapital, "Venture Capital"],
  [FundStrategy.PrivateEquity, "Buyout"],
  [FundStrategy.HedgeFunds, "Hedge Funds"],
  [FundStrategy.FundOfFunds, "Fund of Funds"],
  [FundStrategy.RealEstate, "Real Estate"],
  [FundStrategy.PrivateDebt, "Private Debt"],
  [FundStrategy.Growth, "Growth"],
  [FundStrategy.Secondaries, "Secondaries"],
  [FundStrategy.Infrastructure, "Infrastructure"],
  [FundStrategy.NaturalResource, "Natural Resources"],
  [FundStrategy.Other, "Other"],
]);
