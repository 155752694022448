import { IHoldingProcess } from "experiences/portfolio/domain/models/HoldingProcess";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import { IHolding } from "../../domain/models/Holding";
import { Portfolio } from "../../domain/models/Portfolio";

export class PortfolioState {}

export class SellInterestFlowState extends PortfolioState {}

export class SellInterestFlowLoading extends SellInterestFlowState {}

export class SellInterestFlowFailedToLoad extends SellInterestFlowState {}

export class MainPortfolioScreenLoaded extends PortfolioState {
  portfolio: Portfolio;

  constructor({ portfolio }: { portfolio: Portfolio }) {
    super();
    this.portfolio = portfolio;
  }
}

export class MainPortfolioScreenFailedToLoad extends PortfolioState {}

export class MainPortfolioScreenLoading extends PortfolioState {}

export class HoldingCreationModalOn extends PortfolioState {}

export class HoldingCreationModalOff extends PortfolioState {}

// sell flow states
export class SellInterestFlowLoaded extends SellInterestFlowState {}

export class ConfirmHoldingDetailsLoaded extends SellInterestFlowLoaded {
  selectedHoldings: IHolding[];

  constructor({ selectedHoldings }: { selectedHoldings: IHolding[] }) {
    super();
    this.selectedHoldings = selectedHoldings;
  }
}

export class ChooseManagerInvolvementLoaded extends SellInterestFlowLoaded {
  selectedHoldings: IHolding[];

  constructor({ selectedHoldings }: { selectedHoldings: IHolding[] }) {
    super();
    this.selectedHoldings = selectedHoldings;
  }
}

export class ConfigureTransactionLoaded extends SellInterestFlowLoaded {}

export class EngageTapLoaded extends SellInterestFlowLoaded {}

export class SetTimelineLoaded extends SellInterestFlowLoaded {}

export class ReviewTransactionLoaded extends SellInterestFlowLoaded {
  transactionName: string;
  accumNav: DollarAmount;
  targetCloseDate: string;
  selectedHoldings: IHolding[];
  managerAdminHoldingIds: string[];

  constructor({
    transactionName,
    accumNav,
    targetCloseDate,
    selectedHoldings,
    managerAdminHoldingIds,
  }: {
    transactionName: string;
    accumNav: DollarAmount;
    targetCloseDate: string;
    selectedHoldings: IHolding[];
    managerAdminHoldingIds: string[];
  }) {
    super();
    this.transactionName = transactionName;
    this.accumNav = accumNav;
    this.targetCloseDate = targetCloseDate;
    this.selectedHoldings = selectedHoldings;
    this.managerAdminHoldingIds = managerAdminHoldingIds;
  }
}

export class SellInterestFlowSubmittedSuccessfully extends SellInterestFlowLoaded {}

export class SellInterestFlowFailedSubmission extends SellInterestFlowLoaded {}

export class HoldingProcessState extends PortfolioState {}

export class HoldingProcessStateLoading extends HoldingProcessState {}

export class HoldingProcessStateLoaded extends HoldingProcessState {
  holdings: IHoldingProcess[];

  constructor({ holdings }: { holdings: IHoldingProcess[] }) {
    super();
    this.holdings = holdings;
  }
}
