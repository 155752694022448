import { classNames } from "primereact/utils";

import { EmptyState } from "common/components/EmptyState";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { StyledList } from "experiences/common/List";
import { IndicationOfInterest } from "experiences/indications-of-interest/domain/models/IOI";
import { FundVerifyPermissionOverlay } from "./VerifyPermissionOverlay";

export const IoiSellInterestList = ({
  canViewSellIois,
  fundId,
  nonExpiredAsks,
  renderBidAskRow,
}: {
  canViewSellIois: boolean;
  fundId: string;
  nonExpiredAsks: IndicationOfInterest[];
  renderBidAskRow: (
    row: IndicationOfInterest,
    i: number,
    locked: boolean,
  ) => JSX.Element;
}) => {
  const showEmptyState = nonExpiredAsks.length === 0 && canViewSellIois;

  return (
    <>
      <PageSectionTitleDivider showBorderTop>
        Sell Interest
      </PageSectionTitleDivider>

      <StyledList
        className={classNames({ locked: !canViewSellIois })}
        style={{ position: "relative" }}
      >
        <table className="dashboard_table">
          <thead>
            <tr
              className={classNames("has_bullet", {
                locked: !canViewSellIois,
              })}
            >
              <th></th>
              <th style={{ textAlign: "center" }}>Price (% of NAV)</th>
              <th style={{ width: "33%" }}>Check Size</th>
              <th>Reference Date</th>
              <th>Tags</th>
            </tr>
          </thead>
          {nonExpiredAsks.length > 0 ? (
            <tbody>
              {nonExpiredAsks.map((row, i) =>
                renderBidAskRow(row, i, !canViewSellIois),
              )}
            </tbody>
          ) : null}
          {showEmptyState && (
            <tr>
              <td colSpan={5}>
                <EmptyState title="There are no Sell Interests available, yet." />
              </td>
            </tr>
          )}
        </table>
        {!canViewSellIois ? (
          <FundVerifyPermissionOverlay fundId={fundId} />
        ) : null}
      </StyledList>
    </>
  );
};
