import React from "react";
import { BUTTON_TYPES, Button } from "common/components/Button";

export const RegistrationSubmittedStep: React.FC = () => {
  return (
    <div>
      <div className="mb-10 cursor-default">
        <p>
          Thank you for submitting your application. Tap is limited to verified
          secondaries market participants to ensure a high quality marketplace.
        </p>
        <br />
        <p>
          We will follow up with you shortly via email to activate your account.
        </p>
      </div>
      <Button
        as={"a"}
        type={BUTTON_TYPES.SECONDARY}
        size="large"
        fullWidth
        href="https://usetap.com"
        rel="noopener noreferrer"
        className="block mt-4"
      >
        Finish
      </Button>
    </div>
  );
};
