export enum CashflowType {
  CapitalCall = "CapitalCall",
  Distribution = "Distribution",
  NetCapitalCall = "NetCapitalCall",
  NetDistribution = "NetDistribution",
}

export const CashflowTypeLabels = {
  [CashflowType.CapitalCall]: "Capital Call",
  [CashflowType.Distribution]: "Distribution",
  [CashflowType.NetCapitalCall]: "Net Capital Call",
  [CashflowType.NetDistribution]: "Net Distribution",
};

export interface IPortfolioCashflows {
  fund: string;
  issuerId: string;
  noticeId: string;
  type: CashflowType;
  amount: string;
  wireDate: string;
  description: string;
}

export interface IPortfolioCashflowsResponse {
  interimCashflows: IPortfolioCashflows[];
}
