import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as yup from "yup";

import toast from "react-hot-toast";

import { maskitoParseNumber } from "@maskito/kit";
import { Failure } from "common/@types/app/Failure";
import { IssuerTypeGql } from "common/@types/models/Issuer";
import { IManager, ManagerTypeString } from "common/@types/models/Manager";
import { transactionsClient } from "common/clients/ApolloClient";
import { PortfolioContext } from "experiences/portfolio-v2/presentation/state/PorfolioV2Context";
import { IHolding } from "experiences/portfolio/domain/models/Holding";
import { CreateHolding } from "experiences/portfolio/domain/usecases/CreateHolding";
import { UploadTransactionHolding } from "experiences/transactions/domain/usecases/UploadTransactionHolding";
import { match } from "fp-ts/lib/Either";
import { getPreviousQuarter } from "../utils";

export const REMOVE_NAV_FLAG = true;
export const REMOVE_REFERENCE_DATE_FLAG = true;

// prettier-ignore
export const fundInterestOptionsMock = [
  { value: "bab7de33", label: "Redrock Partners Fund EZ - $20.4M", },
  { value: "0af0281d", label: "Redrock Partners Fund ZY - $5.1M", },
  { value: "8676bf5a", label: "Redrock Partners Fund OR - $10.2M", },
  { value: "3cf6b80d", label: "Redrock Partners Fund RU - $15.3M", },
  { value: "19915a3c", label: "Redrock Partners Fund WX - $20.4M", },
  { value: "8eb74b0b", label: "Redrock Partners Fund PK - $25.5M", },
];

const OTHER_MANAGER_VALUE = "other";

const otherManager = { value: OTHER_MANAGER_VALUE, label: "Other" };

// prettier-ignore
const managerOptionsMock = [
  { value: "bab7de33", label: "Redrock Partners", },
  { value: "0af0281d", label: "Bluerock Partners", },
  { value: "8676bf5a", label: "Bluecrest Partners", },
]

export interface SelectOption {
  value: string;
  label: string;
}

interface FormValues {
  fundName: string;
  fundId: string;
  interestNav: string;
  managerName: string;
  managerId: string;
  managerType: string;
  referenceDate: string;
}

export interface ITransactionHolding {
  id: string;
  issuerName: string;
  holding: {
    id: string;
    issuerId: string;
    issuerType: IssuerTypeGql.Fund;
    entityId: string;
  };
}

const validationSchema = yup.object().shape({
  fundName: yup.string().required("Please select a fund"),
  ...(!REMOVE_NAV_FLAG && {
    interestNav: yup.string().required("Interest NAV is required"),
  }),
  ...(!REMOVE_REFERENCE_DATE_FLAG && {
    referenceDate: yup.string().required("Reference date is required"),
  }),
});

const convertQuarterToDate = (inputDate: string) => {
  const [year, quarter] = inputDate.split("Q");
  const month = parseInt(quarter) * 3;

  const lastDayOfMonth = new Date(parseInt(year), month + 1, 0).getDate();

  const preDate = new Date(parseInt(year), month, lastDayOfMonth);

  return preDate.toISOString().split("T")[0];
};

const initialFormValues = {
  fundName: "",
  fundId: "",
  ...(!REMOVE_NAV_FLAG && {
    interestNav: "",
  }),
  managerName: "",
  managerId: "",
  managerType: "",
  referenceDate: getPreviousQuarter(),
};

export const useAddFundInterestForm = ({
  handleClose,
  refetchHoldings,
  onHoldingAdded,
  forPortfolio = false,
  open,
}: {
  handleClose: () => void;
  refetchHoldings: () => void;
  onHoldingAdded: ({ holding }: { holding: ITransactionHolding }) => void;
  forPortfolio?: boolean;
  open: boolean;
}) => {
  const { txnId } = useParams();
  const [openFundOptionsDropdown, setOpenFundOptionsDropdown] = useState(false);
  const [openManagerOptionsDropdown, setOpenManagerOptionsDropdown] =
    useState(false);

  const [isNewFundInterest, setIsNewFundInterest] = useState(false);

  // TODO: Write mutation to add a holding to this transaction
  // Holdings (holding.id) is required before submitting the entire classify documents screen
  const [uploadTransactionHolding] = useMutation<{
    createHolding: ITransactionHolding[];
  }>(UploadTransactionHolding, {
    client: transactionsClient,
    fetchPolicy: "no-cache",
  });
  const { uploadEntity } = useContext(PortfolioContext);

  const form = useFormik<FormValues>({
    initialValues: initialFormValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const holding = {
        referenceDate: REMOVE_REFERENCE_DATE_FLAG
          ? undefined
          : convertQuarterToDate(values.referenceDate),
        bookValue: REMOVE_NAV_FLAG
          ? undefined
          : maskitoParseNumber(values.interestNav),
      };

      const attachHolding = Object.values(holding).some(
        (value) => value !== undefined,
      );

      const payload = {
        transactionHolding: {
          // only attach holding if it has fund keys:
          ...(attachHolding && { holding }),
          fund: { name: values.fundName },
          ...(isNewFundInterest && {
            organization: { name: values.managerName, type: "MANAGER" },
          }),
        },
        transactionId: txnId,
      };
      if (forPortfolio) {
        new CreateHolding()
          .call({
            form: {
              entityId: uploadEntity.id,
              issuerId: values.fundId,
              nav: values.interestNav
                ? maskitoParseNumber(values.interestNav)
                : 0,
              referenceDate: values.referenceDate
                ? convertQuarterToDate(values.referenceDate)
                : "",
              ...(isNewFundInterest && {
                potentialFund: {
                  id: values.fundId,
                  name: values.fundName,
                  manager: {
                    id: values.managerId,
                    name: values.managerName,
                  },
                },
              }),
            },
          })
          .then((resp) => {
            match<Failure, IHolding, void>(
              (failure: Failure) => toast.error(failure.message),
              (holding: IHolding) => {
                toast.success("Holding added successfully");
                onHoldingAdded({
                  holding: {
                    id: holding.id,
                    issuerName: values.fundName,
                    holding: {
                      id: holding.id,
                      issuerId: values.fundId,
                      issuerType: IssuerTypeGql.Fund,
                      entityId: holding.entityId,
                    },
                  },
                });
                refetchHoldings();
                setTimeout(() => {
                  form.resetForm({
                    values: initialFormValues,
                  });
                  handleClose();
                  form.setSubmitting(false);
                  setIsNewFundInterest(false);
                }, 500);
              },
            )(resp);
          });
      } else {
        // Handle the actual upload, payload is `variables`
        uploadTransactionHolding({
          variables: payload,
        })
          .then((res) => {
            const createdHolding = res.data?.createHolding[0];
            // Send message to callback with created holding
            onHoldingAdded({
              holding: createdHolding,
            });
            // Refetch holdings to update the table's dropdown
            refetchHoldings();
            // Wait 500ms before closing the modal
            setTimeout(() => {
              form.resetForm({
                values: initialFormValues,
              });
              handleClose();
              form.setSubmitting(false);
              setIsNewFundInterest(false);
            }, 500);
          })
          .catch((error) => {
            const isDuplicateKeyError = error.message.includes(
              "duplicate key value",
            );
            if (isDuplicateKeyError) {
              toast.error(
                "Holding already exists in this transaction. Please select or create a different one.",
              );
            } else {
              toast.error("Error adding fund interest");
            }
            form.setSubmitting(false);
            return;
          });
      }

      return;
    },
  });

  useEffect(() => {
    if (!open) {
      setIsNewFundInterest(false);
    }
  }, [open]);

  // I'm setting the value here because this will be replaced by a useQuery hook
  const fundOptions = fundInterestOptionsMock;

  // I'm setting the value here because this will be replaced by a useQuery hook
  const managerOptions = [...managerOptionsMock, otherManager];

  // Set the value @ classify documents form
  const handleSelectFundInterest = ({
    fundInterest,
    managerName,
  }: {
    fundInterest: SelectOption;
    managerName: string;
  }) => {
    form.setFieldValue("fundName", fundInterest.label);
    form.setFieldValue("fundId", fundInterest.value);
    form.setFieldValue("managerName", managerName);
  };

  // Set the value @ classify documents form
  const handleSelectManager = ({
    manager,
    type,
  }: {
    manager: SelectOption;
    type: IManager["type"];
  }) => {
    form.setFieldValue("managerId", manager.value);
    form.setFieldValue("managerName", manager.label);
    form.setFieldValue("managerType", ManagerTypeString[type]);
  };

  const handleFundNotFoundCreateNew = () => {
    setIsNewFundInterest(true);
    form.setFieldValue("fundId", "");
    form.setFieldValue("fundName", "");
    setOpenFundOptionsDropdown(false);
  };

  const handleManagerNotFoundClick = () => {
    form.setFieldValue("managerId", OTHER_MANAGER_VALUE);
    form.setFieldValue("managerName", "Other");
    setOpenManagerOptionsDropdown(false);
  };

  return {
    openFundOptionsDropdown,
    setOpenFundOptionsDropdown,
    openManagerOptionsDropdown,
    setOpenManagerOptionsDropdown,
    fundOptions,
    managerOptions,
    handleSelectFundInterest,
    handleSelectManager,
    form,
    isNewFundInterest,
    handleFundNotFoundCreateNew,
    handleManagerNotFoundClick,
  };
};
