import { GpLimitedPartner } from "./domain/models/GpLimitedPartner";

export const mockData: GpLimitedPartner[] = [
  {
    logoFileName: "BlueSkyPrivateEquity.jpg",
    lpName: "Blue Sky Private Equity",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Madison Green",
    cav: "$496,634,860",
    unfunded: "$869,111,005",
    distributions: "$57,940,734",
    currentParticipation: "$163,889,504",
    lastAccessDate: "-",
  },
  {
    logoFileName: "GaussFoundation.jpg",
    lpName: "Gauss Foundation",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "James Smith",
    cav: "$357,238,858",
    unfunded: "$129,499,086",
    distributions: "$281,325,600",
    currentParticipation: "$142,895,543",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "YellowstonePartners.jpg",
    lpName: "Yellowstone Partners",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Emily Johnson",
    cav: "$194,024,777",
    unfunded: "$121,265,486",
    distributions: "$14,551,858",
    currentParticipation: "$77,609,911",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "JSERS.jpg",
    lpName: "JSERS",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Sophia Garcia",
    cav: "$204,821,880",
    unfunded: "$95,583,544",
    distributions: "$71,687,658",
    currentParticipation: "$61,446,564",
    lastAccessDate: "6 days ago",
  },
  {
    logoFileName: "GranitePeakCapitalPartners.jpg",
    lpName: "Granite Peak Capital Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Sophie Baker",
    cav: "$76,503,423",
    unfunded: "$149,181,675",
    distributions: "$50,492,259",
    currentParticipation: "$25,246,130",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "CedarGroveCapitalPartners.jpg",
    lpName: "Cedar Grove Capital Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Chen Wang",
    cav: "$63,182,812",
    unfunded: "$91,615,077",
    distributions: "$49,756,464",
    currentParticipation: "$20,850,328",
    lastAccessDate: "-",
  },
  {
    logoFileName: "YarboCountyTeachers.jpg",
    lpName: "Yarbo County Teachers",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "III",
    contantName: "Isabella Davis",
    cav: "$100,622,167",
    unfunded: "$50,311,084",
    distributions: "$20,124,433",
    currentParticipation: "$20,124,433",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    lpName: "Silverleaf Family Office",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Liam Hernandez",
    cav: "$24,358,589",
    unfunded: "$8,829,988",
    distributions: "$19,182,389",
    currentParticipation: "$19,486,871",
    lastAccessDate: "-",
  },
  {
    logoFileName: "BancGaston.jpg",
    lpName: "Banc Gaston",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Olivia Martinez",
    cav: "$59,092,829",
    unfunded: "$34,470,817",
    distributions: "$6,894,163",
    currentParticipation: "$17,727,849",
    lastAccessDate: "11 days ago",
  },
  {
    logoFileName: "NobleTrustEquityPartners.jpg",
    lpName: "Noble Trust Equity Partners",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Ethan Jones",
    cav: "$44,207,293",
    unfunded: "$77,362,763",
    distributions: "$5,157,518",
    currentParticipation: "$14,588,407",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "WillowCreekInvestmentPartners.jpg",
    lpName: "Willow Creek Investment Partners",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Jayden Clark",
    cav: "$43,547,575",
    unfunded: "$76,208,256",
    distributions: "$5,080,550",
    currentParticipation: "$14,370,700",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "DadeEmployeeRetirementSystem.jpg",
    lpName: "Dade Employee Retirement System",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Alexander Miller",
    cav: "$21,620,524",
    unfunded: "$12,611,972",
    distributions: "$2,522,394",
    currentParticipation: "$12,972,315",
    lastAccessDate: "8 days ago",
  },
  {
    logoFileName: "GoldenOakInvestments.jpg",
    lpName: "Golden Oak Investments",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Emily Johnson",
    cav: "$24,342,787",
    unfunded: "$42,599,877",
    distributions: "$2,839,992",
    currentParticipation: "$8,033,120",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "DiamondKeyInvestmentGroup.jpg",
    lpName: "Diamond Key Investment Group",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Grace Turner",
    cav: "$15,734,027",
    unfunded: "$27,534,547",
    distributions: "$1,835,636",
    currentParticipation: "$5,192,229",
    lastAccessDate: "8 days ago",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    lpName: "Willow Creek Pension",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "II, III",
    contantName: "Linda Brown",
    cav: "$19,294,222",
    unfunded: "$6,270,622",
    distributions: "$10,129,466",
    currentParticipation: "$3,858,844",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "JanetStreet.jpg",
    lpName: "Janet Street",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Lina Saleh",
    cav: "$7,602,337",
    unfunded: "$13,304,090",
    distributions: "$886,939",
    currentParticipation: "$2,508,771",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    lpName: "Horizon Endowment",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Michael Williams",
    cav: "$3,557,930",
    unfunded: "$2,075,459",
    distributions: "$415,092",
    currentParticipation: "$2,134,758",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "SummitRidgeFamilyOffice.jpg",
    lpName: "Summit Ridge Family Office",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Jacob Gonzalez",
    cav: "$4,211,682",
    unfunded: "$8,212,780",
    distributions: "$2,779,710",
    currentParticipation: "$1,389,855",
    lastAccessDate: "-",
  },
  {
    logoFileName: "StarlightVentureCapital.jpg",
    lpName: "Starlight Venture Capital",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Victoria Peterson",
    cav: "$3,270,290",
    unfunded: "$4,741,921",
    distributions: "$2,575,354",
    currentParticipation: "$1,079,196",
    lastAccessDate: "-",
  },
  {
    logoFileName: "EntrypointFundIII.jpg",
    lpName: "Entrypoint Fund III",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Noah Rodriguez",
    cav: "$2,556,194",
    unfunded: "$1,597,622",
    distributions: "$191,715",
    currentParticipation: "$1,022,478",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "SunsetHarborWealthManagement.jpg",
    lpName: "Sunset Harbor Wealth Management",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Scarlett Lewis",
    cav: "$2,821,703",
    unfunded: "$3,527,129",
    distributions: "$211,628",
    currentParticipation: "$931,162",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "CrystalLakeCapital.jpg",
    lpName: "Crystal Lake Capital",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Oscar Murphy",
    cav: "$1,633,046",
    unfunded: "$2,041,307",
    distributions: "$122,478",
    currentParticipation: "$538,905",
    lastAccessDate: "-",
  },
  {
    logoFileName: "PhoenixGlobalWealthManagement.jpg",
    lpName: "Phoenix Global Wealth Management",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Austin Morris",
    cav: "$469,331,887",
    unfunded: "$821,330,802",
    distributions: "$54,755,387",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "MapleGroveInstitutionalInvestors.jpg",
    lpName: "Maple Grove Institutional Investors",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Zoe Walker",
    cav: "$392,302,418",
    unfunded: "$568,838,507",
    distributions: "$308,938,154",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    lpName: "Golden Hills Group",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Ethan Jones",
    cav: "$392,112,586",
    unfunded: "$142,140,813",
    distributions: "$308,788,662",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "MajesticInsuranceSolutions.jpg",
    lpName: "Majestic Insurance Solutions",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Sophia Garcia",
    cav: "$304,523,522",
    unfunded: "$441,559,106",
    distributions: "$239,812,273",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "AzureCapitalManagement.jpg",
    lpName: "Azure Capital Management",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Dylan King",
    cav: "$272,858,743",
    unfunded: "$477,502,801",
    distributions: "$31,833,520",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "LighthouseEndowmentFund.jpg",
    lpName: "Lighthouse Endowment Fund",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "III",
    contantName: "Ella Perez",
    cav: "$161,547,259",
    unfunded: "$80,773,629",
    distributions: "$32,309,452",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "OakwoodCityPensionFund.jpg",
    lpName: "Oakwood City Pension Fund",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "III",
    contantName: "Carter Jackson",
    cav: "$151,540,743",
    unfunded: "$75,770,372",
    distributions: "$30,308,149",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "SterlingVenturePartners.jpg",
    lpName: "Sterling Venture Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Luke Nelson",
    cav: "$137,589,959",
    unfunded: "$199,505,441",
    distributions: "$108,352,093",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "AmberFieldPrivateEquity.jpg",
    lpName: "Amber Field Private Equity",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Penelope Coleman",
    cav: "$132,340,627",
    unfunded: "$191,893,909",
    distributions: "$104,218,244",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "RiverbendCapitalAdvisors.jpg",
    lpName: "Riverbend Capital Advisors",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Levi Phillips",
    cav: "$111,047,239",
    unfunded: "$161,018,497",
    distributions: "$87,449,701",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "AspenFamilyHoldings.jpg",
    lpName: "Aspen Family Holdings",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "III",
    contantName: "Michael Williams",
    cav: "$105,314,723",
    unfunded: "$52,657,361",
    distributions: "$21,062,945",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "EmeraldValleyPensionTrust.jpg",
    lpName: "Emerald Valley Pension Trust",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Abeer Al-Masri",
    cav: "$94,313,845",
    unfunded: "$183,911,998",
    distributions: "$62,247,138",
    currentParticipation: "-",
    lastAccessDate: "8 days ago",
  },
  {
    logoFileName: "CedarPointCapitalPartners.jpg",
    lpName: "Cedar Point Capital Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Colin Diaz",
    cav: "$76,597,905",
    unfunded: "$149,365,915",
    distributions: "$50,554,617",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "HueInvestmentGroup.jpg",
    lpName: "Hue Investment Group",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Mia Wilson",
    cav: "$63,606,205",
    unfunded: "$37,103,619",
    distributions: "$7,420,724",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "VanguardLegacyInvestments.jpg",
    lpName: "Vanguard Legacy Investments",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Aurora Sanders",
    cav: "$47,431,582",
    unfunded: "$83,005,268",
    distributions: "$5,533,685",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "NationalInvestmentFund.jpg",
    lpName: "National Investment Fund",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Ryan Scott",
    cav: "$46,324,858",
    unfunded: "$81,068,501",
    distributions: "$5,404,567",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "MarbleArchPrivateEquity.jpg",
    lpName: "Marble Arch Private Equity",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Jacob Gonzalez",
    cav: "$39,269,473",
    unfunded: "$18,325,754",
    distributions: "$13,744,316",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "EagleEyeInsuranceInvestments.jpg",
    lpName: "Eagle Eye Insurance Investments",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Henry Mitchell",
    cav: "$33,545,241",
    unfunded: "$58,704,172",
    distributions: "$3,913,611",
    currentParticipation: "-",
    lastAccessDate: "8 days ago",
  },
  {
    logoFileName: "BlackwaterPrivateInvestments.jpg",
    lpName: "Blackwater Private Investments",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Adam Parker",
    cav: "$32,354,258",
    unfunded: "$45,295,961",
    distributions: "$11,323,990",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "Stanley&KaseAdvisory.jpg",
    lpName: "Stanley & Kase Advisory",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Rahul Bose",
    cav: "$28,926,265",
    unfunded: "$50,620,963",
    distributions: "$3,374,731",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "BoulderCrestRetirementSystem.jpg",
    lpName: "Boulder Crest Retirement System",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Avery Thompson",
    cav: "$21,563,347",
    unfunded: "$37,735,858",
    distributions: "$2,515,724",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "QuantumFoundationLP.jpg",
    lpName: "Quantum Foundation LP",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Lily Young",
    cav: "$16,137,547",
    unfunded: "$22,592,566",
    distributions: "$5,648,142",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "OakhillFamilyOffice.jpg",
    lpName: "Oakhill Family Office",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Nora Hall",
    cav: "$10,704,721",
    unfunded: "$18,733,262",
    distributions: "$1,248,884",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "ElmwoodWealthManagement.jpg",
    lpName: "Elmwood Wealth Management",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Isaac Adams",
    cav: "$7,204,126",
    unfunded: "$10,445,983",
    distributions: "$5,673,250",
    currentParticipation: "-",
    lastAccessDate: "8 days ago",
  },
  {
    logoFileName: "SunsetCapitalAdvisors.jpg",
    lpName: "Sunset Capital Advisors",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Evelyn Harris",
    cav: "$7,043,563",
    unfunded: "$13,734,948",
    distributions: "$4,648,752",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "GreenfieldCapitalPartners.jpg",
    lpName: "Greenfield Capital Partners",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Amelia Lee",
    cav: "$5,508,827",
    unfunded: "$9,640,448",
    distributions: "$642,697",
    currentParticipation: "-",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "PrairieWealthAdvisors.jpg",
    lpName: "Prairie Wealth Advisors",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Alexander Miller",
    cav: "$5,470,613",
    unfunded: "$7,658,858",
    distributions: "$1,914,714",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "PineRidgeEndowmentFund.jpg",
    lpName: "Pine Ridge Endowment Fund",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Logan Martin",
    cav: "$4,827,150",
    unfunded: "$6,758,010",
    distributions: "$1,689,503",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "IroncladPensionStrategies.jpg",
    lpName: "Ironclad Pension Strategies",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Mia Wilson",
    cav: "$4,748,155",
    unfunded: "$6,647,417",
    distributions: "$1,661,854",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "CresthillVentureGroup.jpg",
    lpName: "Cresthill Venture Group",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Ava Lopez",
    cav: "$4,652,197",
    unfunded: "$6,745,685",
    distributions: "$3,663,605",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "BirchwoodRealEstateInvestors.jpg",
    lpName: "Birchwood Real Estate Investors",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Mason Anderson",
    cav: "$4,380,338",
    unfunded: "$7,665,591",
    distributions: "$511,039",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "RoyalOakFinancialGroup.jpg",
    lpName: "Royal Oak Financial Group",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Lucas White",
    cav: "$4,352,978",
    unfunded: "$6,311,818",
    distributions: "$3,427,970",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "SkylineEquityHoldings.jpg",
    lpName: "Skyline Equity Holdings",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Benjamin Taylor",
    cav: "$4,212,924",
    unfunded: "$7,372,617",
    distributions: "$491,508",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "RedwoodAssetManagement.jpg",
    lpName: "Redwood Asset Management",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Chloe Wright",
    cav: "$3,932,808",
    unfunded: "$4,916,010",
    distributions: "$294,961",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    lpName: "Golden Hills Group",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Ethan Jones",
    cav: "$3,676,567",
    unfunded: "$2,297,855",
    distributions: "$275,743",
    currentParticipation: "-",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "WildflowerAssetManagement.jpg",
    lpName: "Wildflower Asset Management",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Liam Hernandez",
    cav: "$3,486,289",
    unfunded: "$6,101,006",
    distributions: "$406,734",
    currentParticipation: "-",
    lastAccessDate: "2 days ago",
  },
  {
    logoFileName: "SunriseEquityPartners.jpg",
    lpName: "Sunrise Equity Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Isabelle Campbell",
    cav: "$3,230,931",
    unfunded: "$6,300,315",
    distributions: "$2,132,414",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "CypressFamilyOffice.jpg",
    lpName: "Cypress Family Office",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "Gabriella Carter",
    cav: "$3,055,755",
    unfunded: "$4,278,057",
    distributions: "$1,069,514",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "CrimsonPeakRetirementFund.jpg",
    lpName: "Crimson Peak Retirement Fund",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "I, II, III, IV",
    contantName: "Jun Wei",
    cav: "$2,924,566",
    unfunded: "$4,240,621",
    distributions: "$2,303,096",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "OceanviewFoundationLP.jpg",
    lpName: "Oceanview Foundation LP",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Charlotte Thomas",
    cav: "$2,530,715",
    unfunded: "$3,163,394",
    distributions: "$189,804",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "TwilightFoundationLP.jpg",
    lpName: "Twilight Foundation LP",
    I: "FALSE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "FALSE",
    fundScop: "II, III, IV",
    contantName: "James Smith",
    cav: "$2,119,562",
    unfunded: "$2,967,387",
    distributions: "$741,847",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "RavenwoodCapitalManagement.jpg",
    lpName: "Ravenwood Capital Management",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "III",
    contantName: "Noah Rodriguez",
    cav: "$1,948,010",
    unfunded: "$974,005",
    distributions: "$389,602",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "MaplewoodCommercialHoldings.jpg",
    lpName: "Maplewood Commercial Holdings",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Julian Roberts",
    cav: "$730,694",
    unfunded: "$913,367",
    distributions: "$54,802",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "SilverMountainEndowments.jpg",
    lpName: "Silver Mountain Endowments",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "III, IV, Opportunities",
    contantName: "Isabella Davis",
    cav: "$729,782",
    unfunded: "$1,277,119",
    distributions: "$85,141",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "ClearwaterCapitalGroup.jpg",
    lpName: "Clearwater Capital Group",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Linda Brown",
    cav: "$708,910",
    unfunded: "$886,137",
    distributions: "$53,168",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "MarkT.HamilFamilyFoundation.jpg",
    lpName: "Mark T. Hamil Family Foundation",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Ava Lopez",
    cav: "$651,929",
    unfunded: "$254,252",
    distributions: "$430,273",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "RiverstoneFamilyOffice.jpg",
    lpName: "Riverstone Family Office",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "ALL",
    contantName: "Owen Allen",
    cav: "$530,282",
    unfunded: "$1,034,050",
    distributions: "$349,986",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
  {
    logoFileName: "MoonlightEquityPartners.jpg",
    lpName: "Moonlight Equity Partners",
    I: "FALSE",
    II: "FALSE",
    III: "TRUE",
    IV: "FALSE",
    Opportunities: "FALSE",
    fundScop: "III",
    contantName: "Logan Martin",
    cav: "$125,633",
    unfunded: "$62,817",
    distributions: "$25,127",
    currentParticipation: "-",
    lastAccessDate: "1 days ago",
  },
  {
    logoFileName: "HarborviewPrivateFunds.jpg",
    lpName: "Harborview Private Funds",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScop: "IV, Opportunities",
    contantName: "Olivia Martinez",
    cav: "$6,997",
    unfunded: "$8,747",
    distributions: "$525",
    currentParticipation: "-",
    lastAccessDate: "-",
  },
];
