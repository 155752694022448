import { TableAlignment } from "experiences/funds/presentation/components/Table";
import { IFundDataDocument } from "experiences/transactions/presentation/components/fund-data/documents/model";
import { listDateRanges } from "experiences/transactions/presentation/components/fund-data/utils";
import {
  abbreviatedDocumentTypeLabelsGql,
  documentTypeLabelsGql,
  FundDataDocumentTypeGql,
} from "experiences/transactions/presentation/components/fund-data/verify-permission/model";

export enum RowType {
  Fund = "fund",
  DocumentType = "documentType",
  Document = "document",
}

export interface IRow {
  type: RowType;
  label: string;
  size: number;
  uuid: string;
  children: IRow[];
  documents: string[];
}

export interface IFormattedDocument extends IFundDataDocument {
  fileSize: number;
}

// DO NOT USE "-" as a separator, UUIDs already contain it
export const SEPARATOR = "#";

export const getDocumentCount = (documents: IFormattedDocument[]) => {
  return documents.length;
};

export const getDocumentsTotalSize = (documents: IFormattedDocument[]) => {
  return documents.reduce((acc, document) => acc + document.fileSize, 0);
};

export const getUniqueDocumentTypes = (documents: IFormattedDocument[]) => {
  return [...new Set(documents.map((document) => document.reportType))].sort();
};

export const getDocumentDateRanges = (documents: IFormattedDocument[]) => {
  return listDateRanges(documents.map((document) => document.reportDate));
};

export const getDocumentTypes = (documents: IFormattedDocument[]) => {
  return getUniqueDocumentTypes(documents)
    .filter((doc) => !!doc)
    .map((type) => abbreviatedDocumentTypeLabelsGql[type])
    .join(", ");
};

export const dataroomV2TableColumns = [
  {
    key: "fundName",
    label: "Fund Name",
    align: TableAlignment.LEFT,
  },
  {
    key: "period",
    label: "Period",
    align: TableAlignment.LEFT,
    className: "!w-48",
  },
  {
    key: "types",
    label: "Types",
    align: TableAlignment.LEFT,
    tooltip:
      // prettier-ignore
      <ul>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.AGMMaterial]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.AGMMaterial]}</li>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.CapitalAccount]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.CapitalAccount]}</li>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.CapitalCallNotice]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.CapitalCallNotice]}</li>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.DistributionNotice]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.DistributionNotice]}</li>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.FinancialStatement]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.FinancialStatement]}</li>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.Other]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.Other]}</li>
        <li>{abbreviatedDocumentTypeLabelsGql[FundDataDocumentTypeGql.OperatingReport]} - {documentTypeLabelsGql[FundDataDocumentTypeGql.OperatingReport]}</li>
      </ul>,
    className: "!w-36 !max-w-36",
  },
  {
    key: "size",
    label: "Size",
    align: TableAlignment.RIGHT,
    className: "!w-36 !max-w-36",
  },
];
