import { gql } from "@apollo/client";

export const GetTransactionFundDataCapitalAccounts = gql`
  query CapitalAccounts(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]
  ) {
    capitalAccounts(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      fundName
      managerName
      managerId
      psin
      isHumanVerified
      nav
      remainingUnfunded
      percentUnfunded
      commitment
      percentFundCommitment
      contributedCapital
      distributedCapital
      recallableCapital
      tvpi
      dpi
      percentWriteUpQoq
      percentWriteUpYoy
      postReferenceContributions
      postReferenceDistributions
    }
  }
`;
