import React from "react";
import styled from "styled-components";
import { CaretRight, CircleNotch } from "@phosphor-icons/react";

import TextChip from "experiences/common/TextChip";

const StyledTitle = styled.div<{
  showBorderTop?: boolean;
  skipBorderBottom?: boolean;
  onClick?: () => void;
}>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  border-top: none;
  border-bottom: 1px solid #dfdfd9;
  ${(props) =>
    props.skipBorderBottom &&
    `
      border-bottom: none!important;
    `}

  background: rgba(250, 250, 248, 0.7);
  color: #21272d;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 40px;
  min-height: 40px;
  user-select: none;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;

  ${(props) =>
    props.onClick &&
    `
      cursor: pointer;
    `}
  ${(props) =>
    props.showBorderTop &&
    `
      border-top: 1px solid #dfdfd9!important;
    `}
  
    & > span {
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
    }
  }
  .secondary_text {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: var(--branding-light, #737476);
  }
  .side_select select {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
    cursor: pointer;
    & .selected {
      font-weight: 600;
    }
  }
  select {
    background-color: transparent;
  }
`;

// TODO:
// Needs actions on the right
// Needs prop to show/hide a border top
export const PageSectionTitleDivider = ({
  loading,
  children,
  showBorderTop,
  skipBorderBottom,
  sideSelectOptions,
  selectedSideSelectValue,
  handleSideSelectChange,
  id,
  ref,
  secondaryText,
  onClick,
  countLabel,
  as,
  actions,
  className,
}: {
  loading?: boolean;
  children?: React.ReactNode;
  showBorderTop?: boolean;
  skipBorderBottom?: boolean;
  sideSelectOptions?: {
    value: string;
    label: React.ReactNode;
  }[];
  selectedSideSelectValue?: string;
  handleSideSelectChange?: (value: string) => void;
  id?: string;
  ref?: any;
  secondaryText?: string;
  onClick?: () => void;
  countLabel?: string | number;
  as?: any;
  actions?: React.ReactNode;
  className?: string;
}) => {
  if (!children) return null;

  return (
    <StyledTitle
      showBorderTop={showBorderTop}
      skipBorderBottom={skipBorderBottom}
      id={id}
      ref={ref}
      onClick={onClick}
      as={as}
      className={className}
    >
      <span>
        {loading ? <CircleNotch className="animate-spin mr-2" /> : null}
        {children}
        {countLabel ? (
          <TextChip text={countLabel} style={{ marginLeft: 8 }} />
        ) : null}
        {Boolean(onClick) ? <CaretRight /> : null}
      </span>
      {sideSelectOptions ? (
        <div className="side_select">
          <select
            name="side"
            id="side"
            onChange={(e) => {
              handleSideSelectChange?.(e.target.value);
            }}
          >
            {sideSelectOptions?.map((option, i) => (
              <option
                value={option.value}
                key={`${option.value}-${i}`}
                selected={selectedSideSelectValue === option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      {secondaryText ? (
        <span className="secondary_text">{secondaryText}</span>
      ) : null}
      {!!actions && <div className="flex gap-4 h-8">{actions}</div>}
    </StyledTitle>
  );
};
