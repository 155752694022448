import { IFundInvestment } from "experiences/funds/domain/models/FundInvestments";

export const viewpointInvestments: IFundInvestment[] = [
  {
    company: "BellView",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2000000,
    date: "6/2/2017",
    cost: 10000000,
    multiple: 14.9,
    bookValue: 149000000,
    unrelalizedPL: 149000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Hatchet",
    type: "Preferred Equity",
    class: "Series A",
    shares: 1580000,
    date: "3/31/2017",
    cost: 5000000,
    multiple: 3,
    bookValue: 15000000,
    unrelalizedPL: 0,
    realizedPL: 15000000,
    ratioPoints: 100,
    realizationDate: "10/21/2019",
  },
  {
    company: "Kola",
    type: "Preferred Equity",
    class: "Series A",
    shares: 6789000,
    date: "6/16/2017",
    cost: 18000000,
    multiple: 25,
    bookValue: 450000000,
    unrelalizedPL: 450000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Jamboree",
    type: "Preferred Equity",
    class: "Series A",
    shares: 1500000,
    date: "7/23/2017",
    cost: 22000000,
    multiple: 1,
    bookValue: 22000000,
    unrelalizedPL: 22000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Keep Card",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2461195,
    date: "11/19/2017",
    cost: 10000000,
    multiple: 2.4,
    bookValue: 24000000,
    unrelalizedPL: 0,
    realizedPL: 24000000,
    ratioPoints: 100,
    realizationDate: "6/10/2020",
  },
  {
    company: "Iterative Labs",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2980000,
    date: "1/6/2018",
    cost: 15000000,
    multiple: 1,
    bookValue: 15000000,
    unrelalizedPL: 15000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Paisley (dba MarkUps)",
    type: "Preferred Equity",
    class: "Series A",
    shares: 3668968,
    date: "4/22/2018",
    cost: 10000000,
    multiple: 1,
    bookValue: 10000000,
    unrelalizedPL: 10000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Vim",
    type: "Preferred Equity",
    class: "Series A",
    shares: 3811944,
    date: "9/8/2018",
    cost: 18000000,
    multiple: 4.2,
    bookValue: 75600000,
    unrelalizedPL: 75600000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Pallet Pros",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2050000,
    date: "11/1/2018",
    cost: 10000000,
    multiple: 0,
    bookValue: 0,
    unrelalizedPL: 0,
    realizedPL: 0,
    ratioPoints: 100,
    realizationDate: "5/23/2021",
  },
  {
    company: "Sprig",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2000000,
    date: "1/12/2019",
    cost: 18000000,
    multiple: 5,
    bookValue: 90000000,
    unrelalizedPL: 90000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "BellView",
    type: "Preferred Equity",
    class: "Series B",
    shares: 3430000,
    date: "1/4/2019",
    cost: 30000000,
    multiple: 10,
    bookValue: 300000000,
    unrelalizedPL: 300000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Kola",
    type: "Preferred Equity",
    class: "Series B",
    shares: 1250000,
    date: "3/18/2019",
    cost: 25000000,
    multiple: 8,
    bookValue: 200000000,
    unrelalizedPL: 200000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "OMG Studios",
    type: "Preferred Equity",
    class: "Series A",
    shares: 1986277,
    date: "5/16/2019",
    cost: 5000000,
    multiple: 1,
    bookValue: 5000000,
    unrelalizedPL: 0,
    realizedPL: 5000000,
    ratioPoints: 100,
    realizationDate: "12/5/2021",
  },
  {
    company: "Gallant",
    type: "Preferred Equity",
    class: "Series A",
    shares: 1538970,
    date: "7/30/2019",
    cost: 10000000,
    multiple: 1.5,
    bookValue: 15000000,
    unrelalizedPL: 11250000,
    realizedPL: 3750000,
    ratioPoints: 25,
    realizationDate: undefined,
  },
  {
    company: "TimeOnTarget",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2720000,
    date: "10/22/2019",
    cost: 5000000,
    multiple: 0.75,
    bookValue: 3750000,
    unrelalizedPL: 0,
    realizedPL: 3750000,
    ratioPoints: 100,
    realizationDate: "5/13/2022",
  },
  {
    company: "Total",
    type: "Preferred Equity",
    class: "Series A",
    shares: 1766039,
    date: "2/15/2020",
    cost: 10000000,
    multiple: 1,
    bookValue: 10000000,
    unrelalizedPL: 10000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "HelloWorld",
    type: "Preferred Equity",
    class: "Series A",
    shares: 2345967,
    date: "6/28/2020",
    cost: 10000000,
    multiple: 7.8,
    bookValue: 78000000,
    unrelalizedPL: 78000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Optics",
    type: "Preferred Equity",
    class: "Series A",
    shares: 3006085,
    date: "4/29/2020",
    cost: 10000000,
    multiple: 1,
    bookValue: 10000000,
    unrelalizedPL: 0,
    realizedPL: 10000000,
    ratioPoints: 100,
    realizationDate: "11/19/2022",
  },
  {
    company: "Shelf Life",
    type: "Preferred Equity",
    class: "Series A",
    shares: 1280000,
    date: "6/26/2020",
    cost: 10000000,
    multiple: 1,
    bookValue: 10000000,
    unrelalizedPL: 10000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Keep Card",
    type: "Preferred Equity",
    class: "Series B",
    shares: 1410000,
    date: "7/19/2020",
    cost: 30000000,
    multiple: 1.7,
    bookValue: 51000000,
    unrelalizedPL: 25500000,
    realizedPL: 25500000,
    ratioPoints: 50,
    realizationDate: undefined,
  },
  {
    company: "Vim",
    type: "Preferred Equity",
    class: "Series B",
    shares: 2010000,
    date: "11/7/2020",
    cost: 25000000,
    multiple: 1.3,
    bookValue: 32500000,
    unrelalizedPL: 32500000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
  {
    company: "Sprig",
    type: "Preferred Equity",
    class: "Series B",
    shares: 3370000,
    date: "2/9/2021",
    cost: 40000000,
    multiple: 1,
    bookValue: 40000000,
    unrelalizedPL: 0,
    realizedPL: 40000000,
    ratioPoints: 100,
    realizationDate: "9/1/2023",
  },
  {
    company: "Kola",
    type: "Preferred Equity",
    class: "Series C",
    shares: 2710000,
    date: "4/28/2021",
    cost: 50000000,
    multiple: 2.1,
    bookValue: 105000000,
    unrelalizedPL: 105000000,
    realizedPL: 0,
    ratioPoints: 0,
    realizationDate: undefined,
  },
];
