import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { TransactionAgreement } from "experiences/transactions/domain/models/TransactionAgreement";
import { Button } from "common/components/Button";
import { Download } from "@phosphor-icons/react";
import { generatePath, useNavigate } from "react-router";
import { DEMO_TXN_ID } from "common/constants/platform";
import { GP_ROUTES } from "common/constants/routes";

interface TransactionPoliciesTableProps {
  transactionAgreements: TransactionAgreement[];
}

export const TransactionAgreementsTable = ({
  transactionAgreements,
}: TransactionPoliciesTableProps) => {
  const navigate = useNavigate();
  return (
    <>
      <TapTable
        data={transactionAgreements}
        emptyStateTitle={
          !transactionAgreements.length
            ? "There are no transactions to display."
            : null
        }
        columns={[
          {
            key: "documentName",
            label: "Document Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "completedTransactions",
            label: "Completed Transactions",
            align: TableAlignment.LEFT,
          },
          {
            key: "status",
            label: "Status",
            align: TableAlignment.LEFT,
          },
          {
            key: "actions",
            label: "Actions",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(agreement: TransactionAgreement) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                {agreement.ndaName}
              </TableCell>
              <TableCell>{agreement.funds}</TableCell>
              <TableCell>{agreement.signatures}</TableCell>
              <TableCell>
                <TextChip
                  text={agreement.status}
                  success={agreement.status.toLocaleLowerCase() === "active"}
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", gap: 8 }}>
                  <a href="https://tap-public-assets.s3.amazonaws.com/Tap+-+Mutual+NDA+Form+-+2023.11.docx">
                    <Button icon={<Download />}>Download</Button>
                  </a>
                </div>
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
