import { TextField } from "@mui/material";

const SignatureTextField = ({ ...props }) => {
  return (
    <TextField
      {...props}
      placeholder="Digital Signature"
      InputProps={{
        sx: {
          fontFamily: "Sacramento, cursive",
          fontSize: 24,
        },
      }}
      fullWidth
      helperText={props.helperText}
    />
  );
};

export default SignatureTextField;
