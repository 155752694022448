import { Box, Link, Typography } from "@mui/material";
import { Envelope } from "@phosphor-icons/react";

import { FormContentSkeleton } from "../components/FormContentSkeleton";
import { SUPPORT_EMAIL } from "common/constants/assets";
import { PageHead } from "common/components/PageHead";
import { Button } from "common/components/Button";
import { HalfImageLayout } from "common/components/HalfImage";
import { AuthAppBar } from "./AuthAppBar";
import { cn } from "common/utils";

export const InvalidLink = () => {
  return (
    <HalfImageLayout
      imageUrl="/images/login/invalid.webp"
      contentOnLeft
      appBar={<AuthAppBar showLoginButton />}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FormContentSkeleton formTitle="There's nothing here">
          <Typography variant="body1">
            The link you clicked is expired or invalid. Verification links can
            only be used once and expire after a certain amount of time.
          </Typography>
          <br />
          <span>
            <Typography variant="body1" display="inline">
              Please{" "}
            </Typography>
            <Link
              variant="body1"
              color="#848484"
              display="inline"
              href="/auth/login"
              sx={{ textDecoration: "underline" }}
            >
              request a new link
            </Link>
            <Typography variant="body1" display="inline">
              {" "}
              or{" "}
            </Typography>
            <Link
              variant="body1"
              color="#848484"
              display="inline"
              href={`mailto:${SUPPORT_EMAIL}`}
              rel="noopener noreferrer"
              target="_blank"
              sx={{ textDecoration: "underline" }}
            >
              contact us
            </Link>
            <Typography variant="body1" display="inline">
              {" "}
              if the issue persists.
            </Typography>
          </span>
        </FormContentSkeleton>
      </Box>
    </HalfImageLayout>
  );
};

export const BadLogin = ({
  title,
  description,
  showContact,
  children,
  className,
}: {
  title: string;
  description: string;
  showContact?: boolean;
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <HalfImageLayout
      imageUrl="/images/login/invalid.webp"
      contentOnLeft
      appBar={<AuthAppBar showLoginButton />}
    >
      <div className={cn("flex flex-col select-none", className)}>
        <PageHead title={title} className="!w-full" />
        <div className="-mt-4 px-8 box-border select-none">
          <p>{description}</p>
          {showContact && (
            <>
              <Button
                href={`mailto:${SUPPORT_EMAIL}`}
                className="px-8 !mt-4"
                icon={<Envelope />}
                as="a"
                target="_blank"
              >
                Contact us
              </Button>
            </>
          )}
          {children}
        </div>
      </div>
    </HalfImageLayout>
  );
};
