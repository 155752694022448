import { LPPortalOption } from "experiences/portfolio/domain/models/LPPortal";
import { ICreateHoldingForm } from "../../domain/models/CreateHoldingForm";
import { IHolding } from "../../domain/models/Holding";
import { Range } from "../../../common/models/Range";

import {
  DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY,
  FIRST_ROUND_BID_EVALUATION_DATE_KEY,
  FIRST_ROUND_DUE_DILIGENCE_DATE_KEY,
  SECOND_ROUND_BID_EVALUATION_DATE_KEY,
  SECOND_ROUND_DUE_DILIGENCE_DATE_KEY,
  TARGET_CLOSE_DATE_KEY,
  TRANSACTION_PREPARATION_START_DATE_KEY,
} from "experiences/portfolio/presentation/components/sell/TimelineKeys";

export class PortfolioEvent {}

export class SellInterestFlowTriggered extends PortfolioEvent {
  holdings: IHolding[];
  constructor({ holdings }: { holdings: IHolding[] }) {
    super();
    this.holdings = holdings;
  }
}

export class CloseSellInterestFlowPressed extends PortfolioEvent {}

export class ConfirmHoldingsDetailsContinueClicked extends PortfolioEvent {}

export class ChooseManagerInvolvementContinueClicked extends PortfolioEvent {
  adminEnabledGPHoldings: IHolding[];

  constructor({
    adminEnabledGPHoldings,
  }: {
    adminEnabledGPHoldings: IHolding[];
  }) {
    super();
    this.adminEnabledGPHoldings = adminEnabledGPHoldings;
  }
}

export class ConfigureTransactionScreenContinueClicked extends PortfolioEvent {
  onlyWholePortfolioBids: boolean;
  adoptPlatformNda: boolean;
  transactionName: string;
  holdingEntityName: string;
  organizationName: string;

  constructor({
    transactionName,
    onlyWholePortfolioBids,
    holdingEntityName,
    organizationName,
    adoptPlatformNda,
  }: {
    transactionName: string;
    organizationName: string;
    onlyWholePortfolioBids: boolean;
    adoptPlatformNda: boolean;
    holdingEntityName: string;
  }) {
    super();
    this.transactionName = transactionName;
    this.organizationName = organizationName;
    this.holdingEntityName = holdingEntityName;
    this.onlyWholePortfolioBids = onlyWholePortfolioBids;
    this.adoptPlatformNda = adoptPlatformNda;
  }
}

export class EngageTapScreenContinueClicked extends PortfolioEvent {
  engagementLetterSigned: boolean;
  institutionName?: string;
  signatoryLegalName?: string;
  signatoryTitle?: string;

  constructor({
    engagementLetterSigned,
    institutionName,
    signatoryLegalName,
    signatoryTitle,
  }: {
    engagementLetterSigned: boolean;
    institutionName?: string;
    signatoryLegalName?: string;
    signatoryTitle?: string;
  }) {
    super();
    this.institutionName = institutionName;
    this.signatoryLegalName = signatoryLegalName;
    this.signatoryTitle = signatoryTitle;
    this.engagementLetterSigned = engagementLetterSigned;
  }
}

export class SetTimelineScreenContinueClicked extends PortfolioEvent {
  [TRANSACTION_PREPARATION_START_DATE_KEY]?: Range<string>;
  [FIRST_ROUND_DUE_DILIGENCE_DATE_KEY]?: Range<string>;
  [FIRST_ROUND_BID_EVALUATION_DATE_KEY]?: Range<string>;
  [SECOND_ROUND_DUE_DILIGENCE_DATE_KEY]?: Range<string>;
  [SECOND_ROUND_BID_EVALUATION_DATE_KEY]?: Range<string>;
  [DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY]?: Range<string>;
  [TARGET_CLOSE_DATE_KEY]?: Range<string>;

  constructor({
    [TRANSACTION_PREPARATION_START_DATE_KEY]: transactionPreparationStartDate,
    [FIRST_ROUND_DUE_DILIGENCE_DATE_KEY]: firstRoundDueDiligenceDate,
    [FIRST_ROUND_BID_EVALUATION_DATE_KEY]: firstRoundBidEvaluationDate,
    [SECOND_ROUND_DUE_DILIGENCE_DATE_KEY]: secondRoundDueDiligenceDate,
    [SECOND_ROUND_BID_EVALUATION_DATE_KEY]: secondRoundBidEvaluationDate,
    [DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY]:
      documentationAndTransactionClosingDate,
    [TARGET_CLOSE_DATE_KEY]: targetCloseDate,
  }: {
    [TRANSACTION_PREPARATION_START_DATE_KEY]?: Range<string>;
    [FIRST_ROUND_DUE_DILIGENCE_DATE_KEY]?: Range<string>;
    [FIRST_ROUND_BID_EVALUATION_DATE_KEY]?: Range<string>;
    [SECOND_ROUND_DUE_DILIGENCE_DATE_KEY]?: Range<string>;
    [SECOND_ROUND_BID_EVALUATION_DATE_KEY]?: Range<string>;
    [DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY]?: Range<string>;
    [TARGET_CLOSE_DATE_KEY]?: Range<string>;
  }) {
    super();

    this.transactionPreparationStartDate = transactionPreparationStartDate;
    this.firstRoundDueDiligenceDate = firstRoundDueDiligenceDate;
    this.firstRoundBidEvaluationDate = firstRoundBidEvaluationDate;
    if (secondRoundDueDiligenceDate) {
      this.secondRoundDueDiligenceDate = secondRoundDueDiligenceDate;
    }
    if (secondRoundBidEvaluationDate) {
      this.secondRoundBidEvaluationDate = secondRoundBidEvaluationDate;
    }
    this.documentationAndTransactionClosingDate =
      documentationAndTransactionClosingDate;
    this.targetCloseDate = targetCloseDate;
  }
}

export class SubmitSellInterestDonePressed extends PortfolioEvent {}

export class CreateHoldingModalSubmitted extends PortfolioEvent {
  form: ICreateHoldingForm;

  constructor({ form }: { form: ICreateHoldingForm }) {
    super();
    this.form = form;
  }
}

export class UploadHoldingListSubmitted extends PortfolioEvent {
  file: File;

  constructor({ file }: { file: File }) {
    super();
    this.file = file;
  }
}

export class LPPortalConnectionSubmitted extends PortfolioEvent {
  portal: LPPortalOption;

  constructor({ portal }: { portal: LPPortalOption }) {
    super();
    this.portal = portal;
  }
}

export class CapitalAccountsFileSubmitted extends PortfolioEvent {
  files: File[];

  constructor({ files }: { files: File[] }) {
    super();
    this.files = files;
  }
}

export class GetHoldingsProcessRequested extends PortfolioEvent {
  id: string;

  constructor({ id }: { id: string }) {
    super();
    this.id = id;
  }
}

export class LpCapitalAccountsFileSubmitted extends PortfolioEvent {
  files: File[];

  constructor({ files }: { files: File[] }) {
    super();
    this.files = files;
  }
}

export class MainPortfolioScreenRequested extends PortfolioEvent {}
