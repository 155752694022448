import { ITransactionPeriod } from "experiences/transactions/domain/models/Transaction";
import {
  TransactionPeriodChecklist,
  TransactionPeriodLabels,
  TransactionPeriodName,
} from "./TimelinePeriod";

export const useTimeline = ({
  timeline,
}: {
  timeline?: ITransactionPeriod[];
}) => {
  // If there's no summary, return empty periods
  if (!timeline) {
    return { periods: [] };
  }

  // @Mo - Is the API returning sorted periods?
  // if not, let's keep this

  const sortOrder: string[] = [
    TransactionPeriodName.TRANSACTION_PREPARATION,
    TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE,
    TransactionPeriodName.FIRST_ROUND_BID_EVALUATION,
    TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE,
    TransactionPeriodName.SECOND_ROUND_BID_EVALUATION,
    TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING,
  ];

  const targetCloseFilteredTimeline = timeline.filter(
    (period) => period.name !== TransactionPeriodName.TARGET_CLOSE,
  );

  const sortedExistingPeriods =
    targetCloseFilteredTimeline.length > 1
      ? targetCloseFilteredTimeline.sort((a, b) => {
          return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
        })
      : targetCloseFilteredTimeline;

  const now = new Date();

  // find the current period index
  const currentPeriodIndex = sortedExistingPeriods.findLastIndex((sorted) => {
    return (
      new Date(sorted.period.start) < now && new Date(sorted.period.end) > now
    );
  });

  // After periods are sorted, generate an object with the necessary data to render the timeline
  const periods = sortedExistingPeriods.map((period, thisIndex) => {
    const thisPeriod = targetCloseFilteredTimeline.find(
      (p) => p.name === period.name,
    );

    const startDate = thisPeriod.period.start;
    const endDate = thisPeriod.period.end;

    const isCurrentStep = currentPeriodIndex === thisIndex;

    const title = !!TransactionPeriodLabels[period.name]
      ? TransactionPeriodLabels[period.name].start
      : period.name;

    const secondaryTitle = !!TransactionPeriodLabels[period.name]
      ? TransactionPeriodLabels[period.name].end
      : "Due";

    return {
      id: period.name,
      title,
      secondaryTitle,
      subtitle: startDate,
      secondarySubtitle: endDate,
      checklist: TransactionPeriodChecklist[period.name],
      completed: new Date(endDate) < new Date(),
      current: isCurrentStep,
    };
  });

  return { periods };
};
