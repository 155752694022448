export enum FlagNames {
  ShowDashboardSignNdaTask = "show-dashboard-sign-nda-task",
  ShowPortfolioV2 = "show-portfolio-v2",
}

// TODO: add different flags for different envs
// Guidelines:
// - false is off, true is on
// - prefer flags names to start with "show", "is", "has", and other positive words, that way it's easier to understand how to use them
export const tapFeatureFlags = {
  [FlagNames.ShowDashboardSignNdaTask]: false,
  [FlagNames.ShowPortfolioV2]: true,
};
