import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ICreateHoldingForm } from "../models/CreateHoldingForm";
import { IHolding } from "../models/Holding";

export class CreateHolding implements IAsyncUseCase<IParams, IHolding> {
  call: (params: IParams) => Promise<Either<Failure, IHolding>> = async ({
    form,
  }) => {
    return await new TapClient(`/holding/create/`, form)
      .post()
      .then((resp: IHolding) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  form: ICreateHoldingForm;
}
