export const DefaultFundIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="landscape_FILL1_wght400_GRAD0_opsz48 1">
        <path
          id="Vector"
          d="M2.34766 27.3068L11.1477 15.5743L18.2977 25.1069H21.0477L15.4377 17.6641L21.4143 9.70801L34.6143 27.3068H2.34766Z"
          fill="#BCB3A5"
        />
      </g>
    </svg>
  );
};
