import MuiPhoneNumber from "material-ui-phone-number";
import { useEffect, useState } from "react";

const IntlPhoneNumberField = ({ formik, label = "", name, ...props }) => {
  const [value, setValue] = useState<string>();
  const inputProps = {
    ...props.InputProps,
  };

  useEffect(() => {
    if (value)
      formik.setFieldValue(
        name,
        value
          ?.replaceAll(" ", "")
          .replaceAll(")", "")
          .replaceAll("(", "")
          .replaceAll("+", "")
          .replaceAll("-", ""),
      );
  }, [value]);

  return (
    <MuiPhoneNumber
      {...props}
      onChange={(value: any) => setValue(value)}
      variant="filled"
      defaultCountry="us"
      InputProps={{ disableUnderline: true, ...inputProps }}
      name={name}
      value={value}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      fullWidth
    />
  );
};

export default IntlPhoneNumberField;
