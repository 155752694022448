import { QmsEntry } from "../models/QmsEntry";

export const qmsListMock: QmsEntry[] = [
  {
    name: "Viewpoint Partners Fund I",
    qmsEnabled: "YES",
    qmsTurnover: "2.5%",
    nonQmsTurnover: "0.2%",
    status: "SAFE HARBOR",
  },
  {
    name: "Viewpoint Partners Fund III",
    qmsEnabled: "YES",
    qmsTurnover: "1.5%",
    nonQmsTurnover: "0.0%",
    status: "SAFE HARBOR",
  },
  {
    name: "Viewpoint Partners Fund III",
    qmsEnabled: "YES",
    qmsTurnover: "4.2%",
    nonQmsTurnover: "1.8%",
    status: "SAFE HARBOR",
  },
  {
    name: "Viewpoint Partners Opportunities",
    qmsEnabled: "YES",
    qmsTurnover: "2.9%",
    nonQmsTurnover: "0.5%",
    status: "SAFE HARBOR",
  },
  {
    name: "Viewpoint Partners Fund IV",
    qmsEnabled: "YES",
    qmsTurnover: "0.0%",
    nonQmsTurnover: "0.0%",
    status: "SAFE HARBOR",
  },
];
