import React from "react";
import TextField from "../TextField";
import OtpInput from "../OtpInput";

const FormikOtpInput = ({
  formik,
  name,
  TextFieldComponent = TextField,
  ...props
}) => (
  <OtpInput
    {...props}
    name={name}
    value={formik.values[name]}
    onChange={(value) => formik.setFieldValue(name, value)}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
  />
);

export default FormikOtpInput;
