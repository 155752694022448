import { FirmFund } from "../domain/models/FirmFund";

export const mockFirmFunds: FirmFund[] = [
  {
    fundName: "Viewpoint Partners Fund IV",
    strategy: "Buyout",
    vintage: 2022,
    size: "$4,850,000,000",
    domicile: "US",
  },
  {
    fundName: "Viewpoint Partners Opportunities",
    strategy: "Buyout",
    vintage: 2022,
    size: "$550,000,000",
    domicile: "US",
  },
  {
    fundName: "Viewpoint Partners Fund III",
    strategy: "Buyout",
    vintage: 2019,
    size: "$2,000,000,000",
    domicile: "US",
  },
  {
    fundName: "Viewpoint Partners Fund III",
    strategy: "Buyout",
    vintage: 2016,
    size: "$890,000,000",
    domicile: "US",
  },
  {
    fundName: "Viewpoint Partners Fund I",
    strategy: "Buyout",
    vintage: 2014,
    size: "$500,000,000",
    domicile: "US",
  },
];
