import { gql } from "@apollo/client";

export const GetTransactionFundDataDealBreakdown = gql`
  query DealBreakdown(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]
  ) {
    dealBreakdown(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      commitments {
        value
        verified
      }
      nav {
        value
        verified
      }
      unfunded {
        value
        verified
      }
      fundCount {
        value
        verified
      }
      gpCount {
        value
        verified
      }
      vintages {
        value
        verified
      }
      investmentCount {
        value
        verified
      }
      top3Concentration {
        value
        verified
      }
      top10Concentration {
        value
        verified
      }
      weightedAvgRevenueGrowth {
        value
        verified
      }
      weightedAvgEbitdaMargin {
        value
        verified
      }
      weightedAvgEbitdaMultiple {
        value
        verified
      }
      weightedAvgLeverageMultiple {
        value
        verified
      }
      weightedAvgPercentPublic {
        value
        verified
      }
      interimContributions {
        value
        verified
      }
      interimDistributions {
        value
        verified
      }
      weightedAvgMoic {
        value
        verified
      }
      weightedAvgDpi {
        value
        verified
      }
      fundSeriesTrackRecord {
        value
        verified
      }
      weightedAvgEstimatedPrice {
        value
        verified
      }
      strategies {
        name
        percentage
      }
      geographies {
        name
        percentage
      }
      sectors {
        name
        percentage
      }
    }
  }
`;
