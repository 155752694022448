import { Icon } from "@phosphor-icons/react";
import { createElement } from "react";

export const CurrentExperienceIcon = ({
  icon,
  label,
}: {
  icon: Icon;
  label: string;
}) => {
  return (
    <>
      {createElement(icon, {
        color: "#BCB3A5",
        width: 16,
        height: 16,
        weight: "regular",
        className: "mr-2",
      })}
      {label}
    </>
  );
};
