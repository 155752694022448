import styled from "styled-components";

const options = [
  {
    label: "As it happens",
    value: "immediate",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Never",
    value: "never",
  },
];

const availableNotifications = [
  {
    label: "New Indication of Interest",
    description:
      "Receive a notification when a buyer submits a new Indication of interest on a fund you hold",
  },
  {
    label: "Transaction Updates",
    description:
      "Receive a notification for changes to your transactions, such as the addition of new files to the dataroom",
  },
  {
    label: "Indication of Interest Refresh",
    description:
      "Receive a notification to update your indications of interest so Tap sends you the best suited opportunities",
  },
  {
    label: "Tap Reports",
    description: "Receive secondary market data and reports from Tap",
  },
];

// NOTE: This component is mocked up but not implemented because we don't have real data for it
const Select = ({
  handleSideSelectChange,
  sideSelectOptions,
  selectedSideSelectValue,
}: {
  handleSideSelectChange?: (value: string) => void;
  sideSelectOptions?: {
    value: string;
    label: React.ReactNode;
  }[];
  selectedSideSelectValue?: string;
}) => {
  return (
    <div className="side_select">
      <select
        name="side"
        id="side"
        onChange={(e) => {
          handleSideSelectChange?.(e.target.value);
        }}
      >
        {sideSelectOptions?.map((option, i) => (
          <option
            value={option.value}
            key={`${option.value}-${i}`}
            selected={selectedSideSelectValue === option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const StyledNotification = styled.div`
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;

  & + & {
    border-top: 1px solid #ebebeb;
  }

  .notification-content {
    flex: 1;
  }
  .notification-label,
  .notification-description {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.14px;
    cursor: default;
  }
  .notification-label {
    color: var(--branding-dark, #21272d);
    font-weight: 500;
  }
  .notification-description {
    color: #737476;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.12px;
    margin-top: 6px;
  }
`;

const Notification = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => {
  return (
    <StyledNotification>
      <div className="notification-content">
        <p className="notification-label">{label}</p>
        <p className="notification-description">{description}</p>
      </div>
      <div>
        <Select sideSelectOptions={options} />
      </div>
    </StyledNotification>
  );
};

// NOTE: This component is mocked up but not implemented because we don't have real data for it
export const NotificationSettings = () => {
  return (
    <div>
      {availableNotifications.map((notification) => (
        <Notification
          label={notification.label}
          description={notification.description}
        />
      ))}
    </div>
  );
};
