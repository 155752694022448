import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { ArrowRight } from "@phosphor-icons/react";

import FormikTextField from "common/components/formik/FormikTextField";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { useTransactionsContext } from "../../state/TransactionsContext";
import { FormikQuarterPicker } from "common/components/formik/FormikQuarterPicker";
import FormikDropdownSelect from "common/components/formik/FormikDropdownSelect";
import {
  ParticipantCapacity,
  ParticipantCapacityOptions,
} from "experiences/transactions/domain/models/Participant";
import FormikCheckbox from "common/components/formik/FormikCheckbox";
import { CreateThirdPartyTransactionFormSubmitted } from "../../state/TransactionsEvent";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { UserExperienceType } from "experiences/authentication/domain/models/UserType";

const validationSchemaThirdParty = yup.object({
  transactionName: yup.string(),
  targetClosingDate: yup.string().required("Required"),
  creatorCapacity: yup.number().required("Required"),
  ndasEnabled: yup.boolean(),
  dataroomEnabled: yup.boolean(),
  biddingEnabled: yup.boolean(),
  isLiveTransactionForSingleParticipant: yup.boolean(),
  qmsEnabled: yup
    .boolean()
    .test("oneOfRequired", "Please select at least one", (value, testContext) =>
      Boolean(
        testContext.parent.ndasEnabled ||
          testContext.parent.dataroomEnabled ||
          testContext.parent.biddingEnabled ||
          testContext.parent.qmsEnabled,
      ),
    ),
});

// clone validationSchemaThirdParty and make isLiveTransactionForSingleParticipant required and true
const validationSchemaLP = validationSchemaThirdParty.clone().shape({
  isLiveTransactionForSingleParticipant: yup
    .boolean()
    .required(
      "Please certify that this is a live transaction for a single seller.",
    )
    .isTrue(
      "Please certify that this is a live transaction for a single seller.",
    ),
});

const initialValues = {
  transactionName: "",
  targetClosingDate: "",
  creatorCapacity: ParticipantCapacity.Seller,
  ndasEnabled: false,
  dataroomEnabled: false,
  biddingEnabled: false,
  isLiveTransactionForSingleParticipant: false,
  qmsEnabled: false,
};

const useCreateTransactionForm = () => {
  const navigate = useNavigate();
  const { emitEvent } = useTransactionsContext();
  const { user } = useAuthContext();
  const showIsLiveTransactionForSingleParticipant =
    user.experienceType === UserExperienceType.LP;

  const handleCreateTransactionThirdParty = (values: typeof initialValues) => {
    const payload = {
      transactionName: values.transactionName,
      targetCloseDate: values.targetClosingDate,
      creatorCapacity: values.creatorCapacity,
      adoptPlatformNda: values.ndasEnabled,
      useDataroom: values.dataroomEnabled,
      useBidding: values.biddingEnabled,
      useQms: values.qmsEnabled,
    };

    emitEvent!(
      new CreateThirdPartyTransactionFormSubmitted({
        form: payload,
      }),
    );
  };

  const handleCreateTransactionLP = (values: typeof initialValues) => {
    console.log("Create Transaction LP", values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      user.experienceType === UserExperienceType.THIRD_PARTY
        ? validationSchemaThirdParty
        : validationSchemaLP,
    onSubmit: (values) => {
      if (user.experienceType === UserExperienceType.THIRD_PARTY) {
        handleCreateTransactionThirdParty(values);
      } else if (user.experienceType === UserExperienceType.LP) {
        handleCreateTransactionLP(values);
      }
    },
  });

  const handleCloseClick = () => {
    navigate(-1);
  };

  // For TP users, buyer is not part of the select field
  // For GP users, buyer is part of the select field
  // For TP users, buyer is part of the select field
  const roleOptions =
    user.experienceType === UserExperienceType.THIRD_PARTY
      ? ParticipantCapacityOptions.filter(
          ({ value }) => value != ParticipantCapacity.Buyer,
        )
      : ParticipantCapacityOptions;

  return {
    formik,
    handleCloseClick,
    roleOptions,
    showIsLiveTransactionForSingleParticipant,
  };
};

const CreateTransactionForm = () => {
  const {
    formik,
    handleCloseClick,
    roleOptions,
    showIsLiveTransactionForSingleParticipant,
  } = useCreateTransactionForm();

  return (
    <StepperFlow
      onClose={handleCloseClick}
      name={"Create Transaction"}
      activeStepIndex={0}
    >
      <div className="p-8">
        <div className="max-w-xl flex flex-col gap-6">
          <div>
            <PageHead
              title="Create Transaction"
              description="Select a name and configure basic parameters of this transaction. The transaction is only visible to you, buyers that you invite, and GPs of selected funds."
              paddingless
            />
          </div>
          <div className="flex flex-col">
            <FormikTextField
              name="transactionName"
              formik={formik}
              label="Project Name (Optional)"
              placeholder="Ex: Project Longhorn"
              fullWidth
            />
            <p className="text-stone-400 pt-2 pl-1 cursor-default select-none text-xs">
              If this transaction has a project name, add that here. If name is
              left blank it will be called “Project Untitled” until someone
              updates the project name.
            </p>
          </div>
          <FormikQuarterPicker
            formik={formik}
            name="targetClosingDate"
            defaultNow
            label="Target Closing"
            inputProps={undefined}
          />
          <FormikDropdownSelect
            formik={formik}
            label="Your Capacity"
            name="creatorCapacity"
            options={roleOptions}
          />
          {showIsLiveTransactionForSingleParticipant && (
            <div>
              <FormikCheckbox
                name={"isLiveTransactionForSingleParticipant"}
                label={
                  "I certify that this is a live transaction for a single seller."
                }
                formik={formik}
                className="w-full"
              />
            </div>
          )}
          <div>
            <label className="font-medium text-sm text-stone-900">
              Select Features (You can change this later)
            </label>
            <div className="flex flex-col pl-2 pb-2 pt-2">
              <FormikCheckbox
                name={"ndasEnabled"}
                label={"NDAs"}
                formik={formik}
                className="w-full"
                tooltipText="Utilize Tap’s template NDA for any participants you add to the transaction."
              />
              <FormikCheckbox
                name={"dataroomEnabled"}
                label={"Data Room"}
                formik={formik}
                className="w-full"
                tooltipText="Extract, analyze, and export all of the underlying data relevant to this transaction across funds, capital accounts and more."
              />
              <FormikCheckbox
                label={"Bidding"}
                name={"biddingEnabled"}
                formik={formik}
                className="w-full"
                tooltipText="Track bids on the transaction with Tap’s in-app bid management software."
              />
              <FormikCheckbox
                name={"qmsEnabled"}
                label={"Qualified Matching Service (QMS)"}
                formik={formik}
                className="w-full"
                tooltipText="Ensure this transaction is compliant and avoid PTP delays by using Tap to document and enforce QMS requirements. "
              />
            </div>
          </div>
          <div className="full-w flex flex-row justify-end">
            <Button
              htmlType="submit"
              onClick={formik.submitForm}
              type={BUTTON_TYPES.SECONDARY}
              size="medium"
              icon={<ArrowRight />}
              iconRight
            >
              Create Transaction
            </Button>
          </div>
        </div>
      </div>
    </StepperFlow>
  );
};

export default CreateTransactionForm;
