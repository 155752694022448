import { registerCellType, DropdownCellType } from "handsontable/cellTypes";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";

import { LP_ROUTES } from "common/constants/routes";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { HoldingsTable } from "../components/holdings-bulk-upload/HoldingsTable";

registerAllModules();
registerCellType(DropdownCellType);

export const HoldingsBulkUpload = () => {
  const navigate = useNavigate();

  const handleCloseClick = () => {
    navigate(LP_ROUTES.PortfolioHoldings);
  };

  return (
    <>
      <PageHead title="Upload" />

      <StepperFlow
        onClose={handleCloseClick}
        name="Upload List"
        activeStepIndex={0}
      >
        <Stack width={"100%"} height={"100%"} justifyContent={"space-between"}>
          <Stack>
            <PageHead
              title="Add Holdings"
              description="Copy-paste and edit your holdings data to get access to estimated prices and bid notifications."
            />
            <HoldingsTable />
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};
