import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ISignatureForm } from "../models/SignatureForm";

export class SignAllParticipantNDAs
  implements IAsyncUseCase<IParams, NoReturn>
{
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    pcptId,
    signature,
    txnId,
  }) => {
    return await new TapClient(
      `/transactions/${txnId}/participant/${pcptId}/ndas/sign/`,
      { signature },
    )
      .post()
      .then((_) => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  pcptId: string;
  signature: ISignatureForm;
  txnId: string;
}
