import { gql } from "@apollo/client";

interface TransactionHoldingInput {
  holding: { referenceDate: string; bookValue: string };
  fund: { name: string };
  organization: { name: string; type: string };
}

export interface UploadTransactionHoldingProps {
  transactionId: string;
  transactionHolding: TransactionHoldingInput;
}

export const UploadTransactionHolding = gql`
  mutation CreateHolding(
    $transactionId: String!
    $transactionHolding: CreateTransactionHoldingInput!
  ) {
    createHolding(
      transactionId: $transactionId
      transactionHolding: $transactionHolding
    ) {
      id
      issuerName
      holding {
        id
        issuerId
        issuerType
        entityId
      }
    }
  }
`;
