import { TransactionPolicy } from "../../domain/models/TransactionPolicy";
import { Bid, BidTransactionHolding } from "../../domain/models/Bidding";
import {
  ITransaction,
  ITransactionSettings,
  ITransactionSummary,
} from "../../domain/models/Transaction";
import { INDADoc } from "../../domain/models/TransactionDocument";
import { TransactionNDA } from "experiences/transactions/domain/models/TransactionNDA";
import { TransactionAgreement } from "experiences/transactions/domain/models/TransactionAgreement";
import { IGpTransaction } from "experiences/dashboard/mock/GP";
import { IDataRoom } from "experiences/transactions/domain/models/DataRoom";
import { ApolloError } from "@apollo/client";

export class TransactionsState {}

export class TransactionsLoaded extends TransactionsState {
  txns: ITransaction[];

  constructor({ txns }: { txns: ITransaction[] }) {
    super();
    this.txns = txns;
  }
}

export class TransactionsLoading extends TransactionsState {}

export class TransactionsFailedToLoad extends TransactionsState {}

export class TransactionDetailsScreenLoaded extends TransactionsState {
  summary: ITransactionSummary;

  constructor({ summary }: { summary: ITransactionSummary }) {
    super();
    this.summary = summary;
  }
}

export class TransactionSettingsScreenLoaded extends TransactionsState {
  settings: ITransactionSettings[];

  constructor({ settings }: { settings: ITransactionSettings[] }) {
    super();
    this.settings = settings;
  }
}

export class TransactionSettingsUpdateLoading extends TransactionsState {}

export class TransactionSettingsUpdateSuccess extends TransactionsState {}

export class TransactionSettingsUpdateFailed extends TransactionsState {}

export class TransactionDetailsFailedToLoad extends TransactionsState {}

export class TransactionDetailsLoading extends TransactionsState {}

export class ListNDAsInProgress extends TransactionsState {}

export class TransactionNDAsLoaded extends TransactionsState {
  ndas: INDADoc[];

  constructor({ ndas }: { ndas: INDADoc[] }) {
    super();
    this.ndas = ndas;
  }
}

export class BiddingState extends TransactionsState {}

export class CreateBidFlowState extends BiddingState {}

export class EnterBidPricingScreenLoaded extends CreateBidFlowState {
  holdings: BidTransactionHolding[];

  constructor({ holdings }: { holdings: BidTransactionHolding[] }) {
    super();
    this.holdings = holdings;
  }
}

export class UploadLetterOfIntentScreenLoaded extends CreateBidFlowState {
  fileName?: string;

  constructor({ fileName }: { fileName?: string }) {
    super();
    this.fileName = fileName;
  }
}

export class ReviewBidScreenLoaded extends CreateBidFlowState {
  holdings: BidTransactionHolding[];
  fullBid: Bid;

  constructor({
    fullBid,
    holdings,
  }: {
    fullBid: Bid;
    holdings: BidTransactionHolding[];
  }) {
    super();
    this.holdings = holdings;
    this.fullBid = fullBid;
  }
}

export class BidCreationSuccessful extends CreateBidFlowState {}

export class BidsListLoaded extends TransactionsState {
  bids: Bid[];

  constructor({ bids }: { bids: Bid[] }) {
    super();
    this.bids = bids;
  }
}

export class BidsListLoading extends TransactionsState {}

export class BidsListFailedToLoad extends TransactionsState {}

export class BidDetailsScreenLoaded extends TransactionsState {
  holdings: BidTransactionHolding[];

  constructor({ holdings }: { holdings: BidTransactionHolding[] }) {
    super();
    this.holdings = holdings;
  }
}

export class TransactionSettingsState extends TransactionsState {}

export class TransactionPoliciesLoaded extends TransactionSettingsState {
  transactionPolicies: TransactionPolicy[];

  constructor({
    transactionPolicies,
  }: {
    transactionPolicies: TransactionPolicy[];
  }) {
    super();
    this.transactionPolicies = transactionPolicies;
  }
}

export class TransactionNDAState extends TransactionsState {}

export class TransactionSettingsNDAsLoaded extends TransactionNDAState {
  transactionNDAs: TransactionNDA[];

  constructor({ transactionNDAs }: { transactionNDAs: TransactionNDA[] }) {
    super();
    this.transactionNDAs = transactionNDAs;
  }
}

export class TransactionAgreementsState extends TransactionsState {}

export class TransactionSettingsAgreementsLoaded extends TransactionAgreementsState {
  transactionAgreements: TransactionAgreement[];

  constructor({
    transactionAgreements,
  }: {
    transactionAgreements: TransactionAgreement[];
  }) {
    super();
    this.transactionAgreements = transactionAgreements;
  }
}

export class GpTransactionListMockState extends TransactionsState {}

export class GpTransactionListMockStateLoaded extends GpTransactionListMockState {
  transactions: IGpTransaction[];

  constructor({ transactions }: { transactions: IGpTransaction[] }) {
    super();
    this.transactions = transactions;
  }
}

export class TendersState extends TransactionsState {}

export class TenderOverviewScreenLoaded extends TendersState {
  summary: ITransactionSummary;

  constructor({ summary }: { summary: ITransactionSummary }) {
    super();
    this.summary = summary;
  }
}

export class UploadDataRoomFileSubmitting extends TransactionsState {}

export class UploadDataRoomFileSuccess extends TransactionsState {}

export class UploadDataRoomFileFailed extends TransactionsState {}

export class ProcessDataRoomFileProcessing extends TransactionsState {}

export class ProcessDataRoomFileFinished extends TransactionsState {}

export class ResetDataroomFileState extends TransactionsState {}

export class EditTimelineModalOpened extends TransactionsState {}
export class TimelineModalLoading extends TransactionsState {}
export class EditTimelineModalClosed extends TransactionsState {}
