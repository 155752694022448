import { createContext, useContext } from "react";
import { IOIEvent } from "./IOIEvent";
import { useManageIOIsState } from "./IOIManager";
import { IOIsState } from "./IOIState";
import { ArchiveIOI } from "experiences/indications-of-interest/domain/usecases/ArchiveIOI";
import { CreateIOI } from "experiences/indications-of-interest/domain/usecases/CreateIOI";
import { ListIOIs } from "experiences/indications-of-interest/domain/usecases/ListIOIs";
import { ListIssuersForAsk } from "experiences/indications-of-interest/domain/usecases/ListIssuersForAsk";
import { UploadIOIsList } from "experiences/indications-of-interest/domain/usecases/UploadIOIsList";
import { IIssuer } from "common/@types/models/Issuer";

export interface IOIsContextProps {
  emitEvent?: (event: IOIEvent) => void;
  asksState?: IOIsState;
  bidsState?: IOIsState;
  archivedIOIsState?: IOIsState;
  dashboardState?: IOIsState;
  createIOIFormState?: IOIsState;
  issuerCacheState: React.MutableRefObject<Set<IIssuer>>;
}

const initialContextState: IOIsContextProps = {
  issuerCacheState: { current: new Set<IIssuer>() },
};

const IOIsContext = createContext(initialContextState);

interface IIOIsStateProviderProps {
  children: React.ReactNode;
  skipLoadAllIoisByDefault?: boolean;
}

const IOIsStateProvider = ({
  children,
  skipLoadAllIoisByDefault,
}: IIOIsStateProviderProps) => {
  const {
    emitEvent,
    asksState,
    bidsState,
    archivedIOIsState,
    dashboardState,
    createIOIFormState,
    issuerCacheState,
  } = useManageIOIsState({
    archiveIOI: new ArchiveIOI(),
    createIOI: new CreateIOI(),
    listIOIs: new ListIOIs(),
    listIssuersForAsk: new ListIssuersForAsk(),
    uploadIOIsList: new UploadIOIsList(),
    skipLoadAllIoisByDefault,
  });

  return (
    <IOIsContext.Provider
      value={{
        emitEvent,
        asksState,
        bidsState,
        archivedIOIsState,
        dashboardState,
        createIOIFormState,
        issuerCacheState,
      }}
    >
      {children}
    </IOIsContext.Provider>
  );
};

export const useIOIsContext = () => useContext(IOIsContext);

export { IOIsStateProvider, IOIsContext };
