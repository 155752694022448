import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Slider,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { BUTTON_TYPES, Button } from "common/components/Button";
import FormikTextField from "common/components/formik/FormikTextField";
import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";
import {
  FundStrategy,
  FundStrategyNameMap,
} from "experiences/common/models/FundStrategy";
import { Range } from "experiences/common/models/Range";
import { useState } from "react";
import {
  FundGeography,
  FundGeographyNameMap,
} from "experiences/common/models/FundGeography";
import TextField from "common/components/TextField";

// NOTE: This component is mocked up but not implemented because we don't have real data for it
export const UpdateInvestmentProfileModal = ({
  open,
  onClose,
  onFormSubmitted,
}: {
  open: boolean;
  onClose: () => void;
  onFormSubmitted?: () => void;
}) => {
  const validationSchema = yup.object({
    userId: yup.string().required("Required"),
    firstName: yup.date().required("Required"),
    lastName: yup.date().required("Required"),
    email: yup.date().required("Required"),
  });

  const userForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    onSubmit: (values) => {
      onFormSubmitted();
      handleClose();
    },
    validationSchema,
  });

  const handleClose = () => {
    onClose();
    userForm.resetForm();
  };

  const minYear = 2002;
  const maxYear = 2023;

  const [vintageRange, setVintageRange] = useState<Range<number> | undefined>();
  const [sizeFrom, setSizeFrom] = useState<Range<number> | undefined>();
  const [sizeTo, setSizeTo] = useState<Range<number> | undefined>();

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fromVal = +event.target.value.replaceAll(",", "").replaceAll("$", "");

    if (fromVal == sizeFrom?.start) return;

    setSizeFrom({
      start: fromVal,
      end: sizeFrom?.end,
    });
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let toVal: number | undefined = +event.target.value
      .replaceAll(",", "")
      .replaceAll("$", "");
    if (toVal <= 0) {
      toVal = undefined;
    }

    if (toVal == sizeTo?.end) return;

    setSizeTo({
      start: sizeTo?.start,
      end: toVal,
    });
  };

  return (
    <Modal
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={userForm.submitForm}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <Box>
              <TapUiModalHead
                title="Update Invetsment Profile"
                onCloseClick={handleClose}
              />
            </Box>

            <Stack spacing={2}>
              <Stack direction="row">
                {[
                  [
                    FundStrategy.VentureCapital,
                    FundStrategy.Growth,
                    FundStrategy.RealEstate,
                    FundStrategy.Other,
                  ],
                  [
                    FundStrategy.Secondaries,
                    FundStrategy.Infrastructure,
                    FundStrategy.NaturalResource,
                  ],
                  [
                    FundStrategy.PrivateEquity,
                    FundStrategy.PrivateDebt,
                    FundStrategy.FundOfFunds,
                  ],
                  [],
                ].map((strategyRow) => (
                  <Stack>
                    {strategyRow.map((strategy) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                          // checked={isStrategySelected(strategy)}
                          // onChange={() => toggleStrategy(strategy)}
                          />
                        }
                        label={FundStrategyNameMap.get(strategy)}
                      />
                    ))}
                  </Stack>
                ))}
              </Stack>

              {/* <Stack
                  width={360}
                  height={80}
                  justifyContent="flex-end"
                  px={3}
                  py={1}
                >
                  <Slider
                    step={1}
                    marks
                    min={minYear}
                    max={maxYear}
                    value={[
                      vintageRange?.start ?? minYear,
                      vintageRange?.end ?? maxYear,
                    ]}
                    onChange={() => setVintageRange}
                    valueLabelDisplay="on"
                  />
                </Stack> */}

              <Stack direction="row">
                {[
                  [
                    FundGeography.NorthAmerica,
                    FundGeography.LatinAmerica,
                    FundGeography.MiddleEast,
                  ],
                  [FundGeography.Europe, FundGeography.Africa],
                  [FundGeography.Asia, FundGeography.Australia],
                ].map((geographyRow) => (
                  <Stack>
                    {geographyRow.map((geography) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                          // checked={isGeographySelected(geography)}
                          // onChange={() => toggleGeography(geography)}
                          />
                        }
                        label={FundGeographyNameMap.get(geography)}
                      />
                    ))}
                  </Stack>
                ))}
              </Stack>

              <Stack direction={"row"} spacing={1} my={1}>
                <TextField
                  mask="currency"
                  name="from"
                  label="From"
                  value={sizeFrom ? sizeFrom : ""}
                  autoComplete="from"
                  InputProps={{
                    onChange: handleFromChange,
                  }}
                  fullWidth
                />
                <TextField
                  mask="currency"
                  name="to"
                  label="To"
                  autoComplete="to"
                  value={sizeTo}
                  InputProps={{
                    onChange: handleToChange,
                  }}
                  fullWidth
                />
              </Stack>

              <Button
                onClick={userForm.submitForm}
                fullWidth
                type={BUTTON_TYPES.SECONDARY}
                size="large"
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
