import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { Button } from "common/components/Button";
import { FileArrowDown } from "@phosphor-icons/react/dist/ssr";
import { FairnessOpinion } from "experiences/compliance/domain/models/FairnessOpinion";
import TextChip from "experiences/common/TextChip";

const tableColumns = [
  {
    key: "transaction",
    label: "Transaction",
    align: TableAlignment.LEFT,
  },
  {
    key: "period",
    label: "Period",
    align: TableAlignment.LEFT,
  },
  {
    key: "fund",
    label: "Fund",
    align: TableAlignment.LEFT,
  },
  {
    key: "opinion",
    label: "Opinion",
    align: TableAlignment.LEFT,
  },
  {
    key: "actions",
    label: "",
    align: TableAlignment.RIGHT,
  },
];

export const FairnessOpinionsTable = ({
  fairnessOpinions,
}: {
  fairnessOpinions: FairnessOpinion[];
}) => {
  const handleDownloadQmsCertificateClick = (opinion: FairnessOpinion) => {
    console.log("download", opinion);
  };

  return (
    <>
      <TapTable
        data={fairnessOpinions}
        emptyStateTitle={
          !fairnessOpinions.length ? "There are no buyers to display." : null
        }
        columns={tableColumns}
        renderRow={(opinion: FairnessOpinion) => {
          return (
            <>
              <TableCell isPrimaryColumn hasBullet>
                {opinion.transaction}
              </TableCell>
              <TableCell isPrimaryColumn>{opinion.period}</TableCell>
              <TableCell isPrimaryColumn>{opinion.fund}</TableCell>
              <TableCell isPrimaryColumn>
                <TextChip
                  text={opinion.opinion}
                  success={opinion.opinion.toLocaleLowerCase() === "fair"}
                />
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => handleDownloadQmsCertificateClick(opinion)}
                  icon={<FileArrowDown />}
                >
                  Download
                </Button>
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
