import React from "react";

import { PageHead } from "common/components/PageHead";
import StepperFlow from "./Stepper/StepperFlow";

const FullModalContainer = ({
  children,
  headerText,
  subheaderText,
  topBarHeaderText,
  onClose = () => {},
  className,
  childrenClassName,
}: {
  topBarHeaderText: string;
  headerText: string;
  subheaderText: string;
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
  childrenClassName?: string;
}) => {
  return (
    <StepperFlow
      name={topBarHeaderText}
      activeStepIndex={0}
      onClose={onClose}
      className={className}
      childrenClassName={childrenClassName}
    >
      <div>
        <PageHead title={headerText} description={subheaderText} />
      </div>
      {children}
    </StepperFlow>
  );
};

export default FullModalContainer;
