import { TransactionPolicy } from "../models/TransactionPolicy";

export const transactionPoliciesMock: TransactionPolicy[] = [
  {
    id: "01ebb359-fdb5-4217-ba86-9f8686d9260b",
    fundName: "Viewpoint Partners Fund I",
    recurringTenders: "NO",
    tenderCadence: "-",
    tenderClosing: "-",
    preemptionRights: "NO",
    majorityApproval: "NO",
    qms: "YES",
  },
  {
    id: "59246bec-8339-4c53-8369-f237cc1586e8",
    fundName: "Viewpoint Partners Fund II",
    recurringTenders: "NO",
    tenderCadence: "-",
    tenderClosing: "-",
    preemptionRights: "NO",
    majorityApproval: "NO",
    qms: "YES",
  },
  {
    id: "b7b72f48-0bea-44f5-be24-5fcef09b2c6f",
    fundName: "Viewpoint Partners Fund III",
    recurringTenders: "YES",
    tenderCadence: "Semi-annual",
    tenderClosing: "Q2, Q4",
    preemptionRights: "NO",
    majorityApproval: "NO",
    qms: "YES",
  },
  {
    id: "371ca62c-dabf-47dc-b77d-a14a786a7006",
    fundName: "Viewpoint Partners Opportunities",
    recurringTenders: "YES",
    tenderCadence: "Semi-annual",
    tenderClosing: "Q2, Q4",
    preemptionRights: "NO",
    majorityApproval: "NO",
    qms: "YES",
  },
  {
    id: "66e90e00-2741-44cb-9aa4-d02678d97ce4",
    fundName: "Viewpoint Partners Fund IV",
    recurringTenders: "YES",
    tenderCadence: "Semi-annual",
    tenderClosing: "Q2, Q4",
    preemptionRights: "NO",
    majorityApproval: "NO",
    qms: "YES",
  },
];
