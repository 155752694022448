import { useNavigate, generatePath } from "react-router";

import { LP_ROUTES } from "common/constants/routes";
import { cn } from "common/utils";
import {
  StyledTableRow,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { ITabTableColumn } from "experiences/funds/presentation/components/Table";
import { BarLoader } from "common/components/BarLoader";
import { FUND_LOGO_URL } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";
import { ICompanyLoanData } from "experiences/transactions/presentation/components/fund-data/investments/model";
import { VIEWPOINT_FUND_ID } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";

enum ColumnKeys {
  Investment = "investmentName",
  Amount = "amount",
  CurrentAmount = "currentAmount",
  OriginationDate = "originationDate",
  MaturityDate = "maturityDate",
  InterestRate = "interestRate",
  InterestRateType = "interestRateType",
  LoanTerm = "term",
  Collateral = "collateral",
}

const columnLabels: Record<ColumnKeys, string> = {
  [ColumnKeys.Investment]: "Loan Investments",
  [ColumnKeys.Amount]: "Loan Amount",
  [ColumnKeys.CurrentAmount]: "Current Amount",
  [ColumnKeys.OriginationDate]: "Origination Date",
  [ColumnKeys.MaturityDate]: "Maturity Date",
  [ColumnKeys.InterestRate]: "Interest Rate",
  [ColumnKeys.InterestRateType]: "Interest Rate Type",
  [ColumnKeys.LoanTerm]: "Loan Term",
  [ColumnKeys.Collateral]: "Collateral",
};

const columns: ITabTableColumn[] = [
  {
    label: columnLabels[ColumnKeys.Investment],
    key: ColumnKeys.Investment,
    align: TableAlignment.LEFT,
    className: "!w-48",
  },
  {
    label: columnLabels[ColumnKeys.Amount],
    key: ColumnKeys.Amount,
    align: TableAlignment.RIGHT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.CurrentAmount],
    key: ColumnKeys.CurrentAmount,
    align: TableAlignment.RIGHT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.OriginationDate],
    key: ColumnKeys.OriginationDate,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.MaturityDate],
    key: ColumnKeys.MaturityDate,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.InterestRate],
    key: ColumnKeys.InterestRate,
    align: TableAlignment.RIGHT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.InterestRateType],
    key: ColumnKeys.InterestRateType,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.LoanTerm],
    key: ColumnKeys.LoanTerm,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.Collateral],
    key: ColumnKeys.Collateral,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
];

// This is almost a 1:1 copy of SummaryTable.tsx BUT for lookthrough soi, meaning IHoldingRow has a different set of fields
const useCompanyLoanDataTable = ({
  data,
  loading,
}: {
  data: ICompanyLoanData[];
  loading: boolean;
}) => {
  const navigate = useNavigate();

  const rows = data.map((row) => ({
    ...row,
  }));

  const handleRowClick = (rowId: string) => {
    const path = generatePath(LP_ROUTES.FundsFundDetail, {
      fundId: VIEWPOINT_FUND_ID,
    });
    navigate(path);
  };

  const renderRow = (row: ICompanyLoanData) => {
    const imgSrc = `${FUND_LOGO_URL}/${row.managerId}.jpg`;

    return (
      <>
        <StyledTableRow
          key={row.investmentId}
          onClick={() => handleRowClick(row.investmentId)}
        >
          <TableCell
            className={cn("select-none overflow-ellipsis !pt-1 !pb-1")}
          >
            <div className="flex items-center gap-2">
              <TableFundLogo imgSrc={imgSrc}>
                {row.investmentName}
              </TableFundLogo>
            </div>
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: row.amount,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: row.currentAmount,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-left">
            {tableCellDataFormatter({
              value: row.originationDate,
              format: "date",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-left">
            {tableCellDataFormatter({
              value: row.maturityDate,
              format: "date",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: row.interestRate,
              format: "percentage",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-left">
            {row.interestRateType}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-left">
            {row.term}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-left">
            {row.collateral}
          </TableCell>
        </StyledTableRow>
      </>
    );
  };

  return { rows, renderRow };
};

export const CompanyLoanDataTable = ({
  data,
  loading,
}: {
  data: ICompanyLoanData[];
  loading: boolean;
}) => {
  const { rows, renderRow } = useCompanyLoanDataTable({ data, loading });

  return (
    <>
      <div className="w-full max-w-full overflow-x-scroll min-h-96 h-auto relative pb-12">
        <TapTable
          data={loading ? [] : rows}
          columns={columns}
          layoutFixed
          isLoading={loading}
          className="w-full min-w-[700px]"
          renderRow={renderRow}
          showEmptyState
          emptyStateTitle="No company loan data found"
          emptyStateDescription="No company loan data found for the selected report date and entities."
        />
        {loading && <BarLoader />}
      </div>
    </>
  );
};
