export enum InvestmentGeography {
  NorthAmerica = "NorthAmerica",
  Europe = "Europe",
  Asia = "Asia",
  LatinAmerica = "LatinAmerica",
  Australia = "Australia",
  MiddleEast = "MiddleEast",
  Africa = "Africa",
}

export enum CoreSector {
  Energy = "Energy",
  Materials = "Materials",
  Industrial = "Industrial",
  Consumer = "Consumer",
  ConsumerStaples = "ConsumerStaples",
  HealthCare = "HealthCare",
  Financials = "Financials",
  InformationTechnology = "InformationTechnology",
  CommunicationServices = "CommunicationServices",
  Utilities = "Utilities",
  RealEstate = "RealEstate",
  Diversified = "Diversified",
  DiversifiedServices = "DiversifiedServices",
}

export enum InvestmentStatus {
  Unrealized = "Unrealized",
  Realized = "Realized",
  PartiallyRealized = "PartiallyRealized",
}

export enum InvestmentType {
  PEBackedCompany = "PEBackedCompany",
  VCBackedCompany = "VCBackedCompany",
  PublicCompany = "PublicCompany",
  RealEstateProperty = "RealEstateProperty",
  InfrastructureProject = "InfrastructureProject",
  Other = "Other",
  Fund = "Fund",
  Loan = "Loan",
}

export const InvestmentTypeIndexToType = Object.values(InvestmentType).reduce(
  (acc, type, index) => {
    acc[index] = type;
    return acc;
  },
  {},
);

export const investmentGeographyLabels: Record<InvestmentGeography, string> = {
  [InvestmentGeography.NorthAmerica]: "North America",
  [InvestmentGeography.Europe]: "Europe",
  [InvestmentGeography.Asia]: "Asia",
  [InvestmentGeography.LatinAmerica]: "Latin America",
  [InvestmentGeography.Australia]: "Australia",
  [InvestmentGeography.MiddleEast]: "Middle East",
  [InvestmentGeography.Africa]: "Africa",
};

export const coreSectorLabels: Record<CoreSector, string> = {
  [CoreSector.Energy]: "Energy",
  [CoreSector.Materials]: "Materials",
  [CoreSector.Industrial]: "Industrial",
  [CoreSector.Consumer]: "Consumer",
  [CoreSector.ConsumerStaples]: "Consumer Staples",
  [CoreSector.HealthCare]: "Health Care",
  [CoreSector.Financials]: "Financials",
  [CoreSector.InformationTechnology]: "Information Technology",
  [CoreSector.CommunicationServices]: "Communication Services",
  [CoreSector.Utilities]: "Utilities",
  [CoreSector.RealEstate]: "Real Estate",
  [CoreSector.Diversified]: "Diversified",
  [CoreSector.DiversifiedServices]: "Diversified Services",
};

export const investmentStatusLabels: Record<InvestmentStatus, string> = {
  [InvestmentStatus.Unrealized]: "Unrealized",
  [InvestmentStatus.Realized]: "Realized",
  [InvestmentStatus.PartiallyRealized]: "Partially Realized",
};

export const investmentTypeLabels: Record<InvestmentType, string> = {
  [InvestmentType.PEBackedCompany]: "PE Backed Company",
  [InvestmentType.VCBackedCompany]: "VC Backed Company",
  [InvestmentType.PublicCompany]: "Public Company",
  [InvestmentType.RealEstateProperty]: "Real Estate Property",
  [InvestmentType.InfrastructureProject]: "Infrastructure Project",
  [InvestmentType.Other]: "Other",
  [InvestmentType.Fund]: "Fund",
  [InvestmentType.Loan]: "Loan",
};

export interface FundDataAggregateSoiLookthrough {
  companyId?: string;
  companyName?: string;
  tapCompanyId?: string;
  fundName?: string;
  tapFundId?: string;
  tapInvestmentId?: string;
  reportDate?: string;
  companyCurrency?: string;
  investmentDate?: string;
  holdPeriod?: string;
  investmentType?: InvestmentType | null;
  investmentGeography?: InvestmentGeography | null;
  sector?: CoreSector | null;
  investmentStatus?: InvestmentStatus | null;
  totalCost?: string;
  totalValue?: string;
  investmentMoic: null;
  realizedCost?: string;
  realizedValue?: string;
  unrealizedCost?: string;
  unrealizedValue?: string;
  lpImpliedInvestmentValue?: string;
  isHumanVerified?: boolean | null;
  lpImpliedInvestmentPercentage?: string | null;
}

export interface FundDataSoiLookthrough {
  aggregateSoi?: FundDataAggregateSoiLookthrough[];
}
