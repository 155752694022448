import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";

export class SubmitLoginForm implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    email,
    redirectOnLogin,
  }) => {
    return await new TapClient("/auth/email/generate/", {
      email,
      redirectOnLogin,
    })
      .post()
      .then((_: any) => right(new NoReturn()))
      .catch((resp: any) => {
        return left(new Failure(resp.response.data?.error));
      });
  };
}

interface IParams {
  email: string;
  redirectOnLogin?: string;
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
