import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useTransactionsContext } from "../state/TransactionsContext";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { DashboardList } from "experiences/common/List";
import {
  StyledTable,
  StyledTableRow,
} from "experiences/funds/presentation/components/Table";
import { StyledTableCell } from "experiences/common/StyledTableCell";
import { TransactionDetailsScreenLoaded } from "../state/TransactionsState";
import { BarLoader } from "common/components/BarLoader";
import { UpdateTransactionNameModal } from "../components/UpdateTransactionNameModal";
import {
  ITransactionSettings,
  TransactionSettingType,
} from "experiences/transactions/domain/models/Transaction";
import { ToggleRow } from "experiences/common/ToggleRow";
import {
  TransactionOptionLabels,
  TransactionOptionTypeLiteral,
} from "experiences/transactions/domain/models/TransactionOptions";
import TapClient from "common/clients/TapClient";

const transactionFeatureList = [
  {
    type: TransactionSettingType.AdoptPlatformNda,
    title:
      TransactionOptionLabels[TransactionOptionTypeLiteral.AdoptPlatformNda],
    description:
      "Invite buyers can sign the transaction NDA directly in Tap, including redlining, before gaining access to the transaction.",
  },
  {
    type: TransactionSettingType.DataRoom,
    title: TransactionOptionLabels[TransactionOptionTypeLiteral.DataRoom],
    description:
      "Host a full data room in Tap, accessible online for all invited parties.",
  },
  {
    type: TransactionSettingType.AllowBids,
    title: TransactionOptionLabels[TransactionOptionTypeLiteral.AllowBids],
    description: "Receive, track, and evaluate bids in Tap.",
  },
  {
    type: TransactionSettingType.Qms,
    title: TransactionOptionLabels[TransactionOptionTypeLiteral.Qms],
    description:
      "Ensure this transaction does not hit PTP blocks by using Tap to document and enforce QMS requirements. ",
  },
];

const useTransactionSettings = () => {
  const { txnId } = useParams();
  const { txnDetailsState } = useTransactionsContext();
  useTransactionsContext(); // @julio 11/01/2024 - Do not remove for now, may be used somewhere else and required for the context
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [isLoadingSettingChange, setIsLoadingSettingChange] = useState<
    TransactionSettingType | undefined
  >();
  const [settings, setSettings] = useState<ITransactionSettings[]>([]);
  const [loading, setLoading] = useState(false);

  const transactionName =
    txnDetailsState instanceof TransactionDetailsScreenLoaded
      ? txnDetailsState.summary.transaction.name
      : "";

  const loadTransactionSettings = async () => {
    setLoading(true);
    const path = `/transactions/${txnId}/settings/`;
    await new TapClient(path)
      .get({})
      .then((resp: ITransactionSettings[]) => {
        setSettings(resp);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTransactionSetting = async (
    type: TransactionSettingType,
    isActive: boolean,
  ) => {
    const path = "/transactions/:txnId/settings/".replace(":txnId", txnId);
    setIsLoadingSettingChange(type);
    return await new TapClient(path, {
      type,
      isActive,
    })
      .post()
      .then(async () => {
        await loadTransactionSettings();
      })
      .finally(() => {
        setIsLoadingSettingChange(undefined);
      });
  };

  useEffect(() => {
    loadTransactionSettings();
  }, []);

  return {
    transactionName,
    showEditNameModal,
    setShowEditNameModal,
    isLoadingSettingChange,
    settings,
    loading,
    updateTransactionSetting,
  };
};

export const TransactionSettings = () => {
  const { txnId } = useParams();
  const {
    transactionName,
    showEditNameModal,
    setShowEditNameModal,
    isLoadingSettingChange,
    settings,
    loading,
    updateTransactionSetting,
  } = useTransactionSettings();

  return (
    <div className="w-full">
      <PageSectionTitleDivider
        actions={
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={() => setShowEditNameModal(true)}
            disabled={loading}
          >
            Edit
          </Button>
        }
      >
        Settings
      </PageSectionTitleDivider>
      <DashboardList
        className="mb-12"
        table={
          <StyledTable>
            <StyledTableRow>
              <StyledTableCell style={{ width: "35%" }}>
                Transaction Name
              </StyledTableCell>
              <StyledTableCell>{transactionName}</StyledTableCell>
            </StyledTableRow>
          </StyledTable>
        }
      />
      <PageSectionTitleDivider showBorderTop>
        Transaction Features
      </PageSectionTitleDivider>
      <div className="space-y-4">
        {!loading && settings.length > 0 ? (
          transactionFeatureList.map((setting) => {
            const isEnabled = settings.find(
              (s) => s.type === setting.type,
            )?.isActive;

            return (
              <ToggleRow
                key={setting.type}
                title={setting.title}
                description={setting.description}
                checked={isEnabled}
                onChange={(checked) => {
                  updateTransactionSetting(setting.type, checked);
                }}
                isLoading={isLoadingSettingChange === setting.type}
              />
            );
          })
        ) : (
          <BarLoader />
        )}
      </div>
      <UpdateTransactionNameModal
        key={transactionName}
        open={showEditNameModal}
        onClose={() => setShowEditNameModal(false)}
        defaultName={transactionName}
        txnId={txnId}
      />
    </div>
  );
};
