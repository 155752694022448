import { FirmOverview } from "../models/FirmOverview";

export const firmOverviewMock: FirmOverview = {
  firmId: "demo",
  firmName: "Fund III QMS",
  transactions: [
    {
      transactionId: "T-4490504",
      transactionName: "Fund III Q4 2023 Tender",
      period: "Q4 2023",
      qmsTransactions: "1.9%",
      nonQmsTransactions: "-",
      turnoverTotal: "1.9%",
    },
    {
      transactionId: "T-4490504",
      transactionName: "Project Manilla",
      period: "Q3 2023",
      qmsTransactions: "-",
      nonQmsTransactions: "0.5%",
      turnoverTotal: "0.5%",
    },
    {
      transactionId: "T-4490505",
      transactionName: "Project Galileo",
      period: "Q2 2023",
      qmsTransactions: "0.3%",
      nonQmsTransactions: "-",
      turnoverTotal: "0.3%",
    },
    {
      transactionId: "T-4490506",
      transactionName: "Project Geronimo",
      period: "Q3 2023",
      qmsTransactions: "-",
      nonQmsTransactions: "1.3%",
      turnoverTotal: "1.3%",
    },
    {
      transactionId: "T-4490507",
      transactionName: "Fund III Q2 2023 Tender",
      period: "Q2 2023",
      qmsTransactions: "2.2%",
      nonQmsTransactions: "-",
      turnoverTotal: "2.2%",
    },
    {
      transactionId: "T-4490518",
      transactionName: "Project AAA",
      period: "Q4 2022",
      qmsTransactions: "0.2%",
      nonQmsTransactions: "-",
      turnoverTotal: "0.2%",
    },
    {
      transactionId: "T-4490509",
      transactionName: "Project Willow",
      period: "Q4 2022",
      qmsTransactions: "-",
      nonQmsTransactions: "0.1%",
      turnoverTotal: "0.1%",
    },
    {
      transactionId: "T-4490510",
      transactionName: "Project Adventure",
      period: "Q3 2022",
      qmsTransactions: "-",
      nonQmsTransactions: "0.9%",
      turnoverTotal: "0.9%",
    },
    {
      transactionId: "T-4490511",
      transactionName: "Fund III 2022 Q2 Tender",
      period: "Q2 2022",
      qmsTransactions: "1.9%",
      nonQmsTransactions: "-",
      turnoverTotal: "1.9%",
    },
    {
      transactionId: "T-4490512",
      transactionName: "Project Uptown",
      period: "Q3 2022",
      qmsTransactions: "-",
      nonQmsTransactions: "0.2%",
      turnoverTotal: "0.2%",
    },
    {
      transactionId: "",
      transactionName: "Project Solo",
      period: "Q2 2022",
      qmsTransactions: "0.2%",
      nonQmsTransactions: "-",
      turnoverTotal: "0.2%",
    },
  ],
};
