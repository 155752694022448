import { useState } from "react";
import { match } from "fp-ts/lib/Either";

import {
  GpLimitedPartnersLoaded,
  GpLimitedPartnersState,
} from "./GpLimitedPartnersState";
import {
  GpLimitedPartnersEvent,
  LimitedPartnersListRequested,
} from "./GpLimitedPartnersEvent";
import { GetLimitedPartners } from "../../domain/usecases/GetLimitedPartners";
import { Failure } from "common/@types/app/Failure";
import { GpLimitedPartner } from "experiences/limited-partners/domain/models/GpLimitedPartner";

export interface IUseCases {
  getLimitedPartners: GetLimitedPartners;
}

export const useGpLimitedPartnersState = ({
  getLimitedPartners,
}: IUseCases) => {
  const [limitedPartnersState, setLimitedPartnersState] =
    useState<GpLimitedPartnersState>();

  let prevEvent: GpLimitedPartnersEvent;
  const emitEvent = (event: GpLimitedPartnersEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof LimitedPartnersListRequested) {
      getLimitedPartners.call({}).then((resp) => {
        match<Failure, GpLimitedPartner[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (partners: GpLimitedPartner[]) => {
            setLimitedPartnersState(
              new GpLimitedPartnersLoaded({
                partners,
              }),
            );
          },
        )(resp);
      });
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    limitedPartnersState,
  };
};
