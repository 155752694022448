import { useNavigate } from "react-router";
import { Signature } from "@phosphor-icons/react";

import { LP_ROUTES } from "common/constants/routes";
import { DashboardTask } from "../DashboardTask";

export const SignNdaTask = () => {
  const navigate = useNavigate();
  const handleSignNDAClick = () => {
    navigate(LP_ROUTES.DocumentsSignNDA);
  };

  return (
    <DashboardTask
      title="Platform NDA"
      description="Review Tap’s Non-Disclosure Agreement to securely share information."
      icon={<Signature color="#BCB3A5" />}
      buttonCta="View NDA"
      onCtaClick={handleSignNDAClick}
    />
  );
};
