import { gql } from "@apollo/client";

export const GetPortfolioPerformanceQuery = gql`
  query Query(
    $entitiesIds: [String!]!
    $groupBy: PortfolioPerformanceGrouping!
    $reportDate: Date!
  ) {
    portfolioPerformance(
      entitiesIds: $entitiesIds
      groupBy: $groupBy
      reportDate: $reportDate
    ) {
      groupingData {
        groupedBy
        aggregateData {
          groupElements {
            id
            dateReported
          }
          groupKey
          isHumanVerified
          fundsCount
          portfolioPercent
          nav
          grossTvpi
          grossMoic
          grossDpi
          called
        }
        topLevelAggregateData {
          groupKey
          groupElements {
            id
            dateReported
          }
          isHumanVerified
          fundsCount
          portfolioPercent
          nav
          grossTvpi
          grossMoic
          grossDpi
          called
        }
      }
      reportData {
        isHumanVerified
        nav
        reportDate
        remainingUnfunded
        tvpi
        dpi
        fundName
        managerName
        managerId
        issuerId
        portfolioPercent
        moic
        fundTvpi
        fundDpi
        holdingId
      }
    }
  }
`;
