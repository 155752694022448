import { useEffect, useState } from "react";

import { useFundsContext } from "experiences/funds/presentation/state/FundsContext";
import { WatchlistLoaded } from "experiences/funds/presentation/state/FundsState";
import { useIOIsContext } from "experiences/indications-of-interest/presentation/state/IOIContext";
import { IOIsLoaded } from "experiences/indications-of-interest/presentation/state/IOIState";
import { Fund } from "experiences/funds/domain/models/Fund";
import {
  Ask,
  Bid,
} from "experiences/indications-of-interest/domain/models/IOI";
import { usePortfolioContext } from "experiences/portfolio/presentation/state/PortfolioContext";
import {
  MainPortfolioScreenLoaded,
  PortfolioState,
} from "../../../portfolio/presentation/state/PortfolioState";
import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { TransactionsLoaded } from "experiences/transactions/presentation/state/TransactionsState";
import { LPLedTransaction } from "experiences/transactions/domain/models/Transaction";

import { useActivity } from "./useDashboardActivity";
import { useDashboardContext } from "../state/DashboardContext";
import { IndexesRequested } from "../state/DashboardEvent";
import { MainPortfolioScreenRequested } from "experiences/portfolio/presentation/state/PortfolioEvent";

const LIMIT_DASHBOARD_WATCHLIST_ITEM_COUNT = 5;
const LIMIT_DASHBOARD_BID_ITEM_COUNT = 5;

export const useDashboard = () => {
  const { watchlistState } = useFundsContext();
  const { bidsState, dashboardState } = useIOIsContext();
  const { portfolioScreenState } = usePortfolioContext();
  const { txnsListState } = useTransactionsContext();
  const { activity, loaded: activityLoaded } = useActivity();
  const { indexes, emitEvent } = useDashboardContext();
  const { emitEvent: emitPortfolioEvent } = usePortfolioContext();

  const watchListLoaded = watchlistState instanceof WatchlistLoaded;
  const bidsLoaded = bidsState instanceof IOIsLoaded;
  const dashboardLoaded = dashboardState instanceof IOIsLoaded;
  const portfolioLoaded =
    portfolioScreenState instanceof MainPortfolioScreenLoaded;
  const transactionsLoaded = txnsListState instanceof TransactionsLoaded;

  const [watchlist, setWatchlist] = useState<Fund[]>([]);
  const [bids, setBids] = useState<Bid[]>([]);
  const [dashboardActivity, setDashboardActivity] = useState<(Bid | Ask)[]>([]);
  const [portfolio, setPortfolio] = useState<
    MainPortfolioScreenLoaded | PortfolioState
  >();
  const [transactions, setTransactions] = useState<LPLedTransaction[]>([]);

  useEffect(() => {
    if (watchListLoaded) {
      setWatchlist(
        watchlistState.funds.slice(0, LIMIT_DASHBOARD_WATCHLIST_ITEM_COUNT),
      );
    }
  }, [watchListLoaded]);

  useEffect(() => {
    if (bidsLoaded) {
      if (bidsState.iois.every((bid) => bid instanceof Bid)) {
        const bidSlice = bidsState.iois.slice(
          0,
          LIMIT_DASHBOARD_BID_ITEM_COUNT,
        ) as Bid[];
        setBids(bidSlice);
      }
    }
  }, [bidsState]);

  useEffect(() => {
    if (dashboardLoaded) {
      if (
        dashboardState.iois.every(
          (ioi) => ioi instanceof Ask || ioi instanceof Bid,
        )
      ) {
        setDashboardActivity(dashboardState.iois as (Bid | Ask)[]);
      }
    }
  }, [dashboardState]);

  useEffect(() => {
    if (portfolioLoaded) {
      setPortfolio(portfolioScreenState);
    }
  }, [portfolioScreenState]);

  useEffect(() => {
    if (transactionsLoaded) {
      setTransactions(txnsListState.txns);
    }
  }, [txnsListState]);

  useEffect(() => {
    emitPortfolioEvent(new MainPortfolioScreenRequested());
    emitEvent(new IndexesRequested());
  }, []);

  return {
    watchlist,
    watchListLoaded,
    bids,
    bidsLoaded,
    dashboardActivity,
    dashboardLoaded,
    portfolio,
    portfolioLoaded,
    transactions,
    transactionsLoaded,
    activity,
    activityLoaded,
    indexes,
  };
};
