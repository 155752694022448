import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { portfolioClient } from "common/clients/ApolloClient";

import { getReportDatesForReportName } from "experiences/portfolio-v2/domain/hooks/getReportDatesForReportName";
import { ReportDateName } from "experiences/portfolio-v2/domain/models/ReportDates";
import { GetPortfolioCsvPerformanceQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioCsvQuery";
import { GetPortfolioPerformanceQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioPerformanceQuery";
import { useEntitiesSelector } from "../components/EntitiesDropdown";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { createPerformanceExcel } from "./performance-excel/createExcel";
import { PortfolioPerformanceGroupingKeys } from "./TableGrouping";

interface PortfolioPerformanceGroupElementType {
  id: string;
  dateReported: string;
}

export interface IPerformanceAggregateData {
  groupElements: PortfolioPerformanceGroupElementType[];
  groupKey: string | null;
  isHumanVerified: string | null;
  fundsCount: string | null;
  portfolioPercent: string | null;
  nav: string | null;
  grossTvpi: string | null;
  grossMoic: string | null;
  grossDpi: string | null;
  called: string | null;
}

interface IGroupingData {
  groupedBy: string;
  aggregateData: IPerformanceAggregateData[];
  topLevelAggregateData: IPerformanceAggregateData | null;
}

export interface IReportData {
  isHumanVerified: boolean | null;
  nav: string | null;
  remainingUnfunded: string | null;
  tvpi: string | null;
  dpi: string | null;
  fundName: string | null;
  managerName: string | null;
  managerId: string | null;
  issuerId: string | null;
  portfolioPercent: string | null;
  moic: string | null;
  fundTvpi: string | null;
  fundDpi: string | null;
}

export interface IPortfolioPerformance {
  portfolioPerformance: {
    groupingData: IGroupingData | null;
    reportData: IReportData[];
  };
}

export const usePortfolioPerformance = () => {
  const [loading, setLoading] = useState(false);
  const [selectedReportDate, setSelectedReportDate] = useState<string | null>(
    null,
  );
  const [groupBy, setGroupBy] = useState<PortfolioPerformanceGroupingKeys>(
    PortfolioPerformanceGroupingKeys.Strategy,
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const entitySelector = useEntitiesSelector();

  const { selectedEntitiesIds, reportDates: allReportDates } =
    useContext(PortfolioContext);

  const reportDates = getReportDatesForReportName({
    reportDates: allReportDates,
    reportName: ReportDateName.FundExposureSummary, // NOTE: This means performance uses the same date as summary for arguments
  });

  const reportDatesOptions = reportDates.map((reportDate) => ({
    label: reportDate,
    value: reportDate,
  }));

  useEffect(() => {
    console.log("@reportDates changed", { reportDates });
    const hasReportDates = reportDates.length > 0;
    if (hasReportDates) {
      const firstReportDate = reportDates[0];
      setSelectedReportDate(firstReportDate);
    }
  }, [allReportDates]);

  const onSelectedReportDateChange = (reportDate: string) => {
    setSelectedReportDate(reportDate);
  };

  const {
    data: data,
    loading: performanceLoading,
    refetch,
  } = useQuery<IPortfolioPerformance>(GetPortfolioPerformanceQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
      reportDate: selectedReportDate,
      groupBy: groupBy,
    },
    skip: selectedEntitiesIds.length === 0 || selectedReportDate === null,
  });

  const handleRefetch = async () => {
    setLoading(true);
    if (selectedEntitiesIds.length === 0 || selectedReportDate === null) {
      setLoading(false);
      return;
    }
    await refetch({
      entitiesIds: selectedEntitiesIds,
      reportDate: selectedReportDate,
      groupBy: groupBy,
    }).finally(() => {
      setLoading(false);
    });
  };

  const { refetch: downloadCsvData } = useQuery<FundDataSummaryCsv>(
    GetPortfolioCsvPerformanceQuery,
    {
      variables: {
        entitiesIds: selectedEntitiesIds,
        reportDate: selectedReportDate,
      },
      client: portfolioClient,
      fetchPolicy: "no-cache",
      skip: true,
    },
  );

  useEffect(() => {
    handleRefetch();
  }, [performanceLoading, selectedReportDate]);

  const onGroupByChange = (groupBy: PortfolioPerformanceGroupingKeys) => {
    setGroupBy(groupBy);
    handleRefetch();
  };

  const handleDownloadClick = () => {
    setIsDownloading(true);
    toast.success(
      "Download started. Make sure your browser is accepting multiple files when prompted.",
    );

    downloadCsvData({
      variables: {
        entitiesIds: selectedEntitiesIds,
        reportDate: selectedReportDate,
      },
    })
      .then((res) => {
        createPerformanceExcel({
          data: res.data?.dataSummaryCsv?.portfolioPerformance,
          namePrefix: `portfolio-performance`,
        });
      })
      .catch((error) => {
        toast.error("Download failed. Please try again.");
      })
      .finally(() => {
        setIsDownloading(false);
        toast.success("Download complete!");
      });
  };

  return {
    loading: loading || performanceLoading,
    entitySelector,
    data: data?.portfolioPerformance,
    groupBy,
    onGroupByChange,
    reportDatesOptions,
    selectedReportDate,
    onSelectedReportDateChange,
    handleDownloadClick,
    isDownloading,
  };
};
