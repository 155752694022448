import csvToJson from "convert-csv-to-json";

import {
  fundExposureSummaryColumns,
  FundExposureSummaryKeys,
  fundFinancialsColumns,
  FundFinancialsKeys,
  FundSummaryTabs,
} from "./model";
import {
  formatCellsCurrency,
  formatCellsDate,
  formatCellsMultiplier,
  formatCellsPercentage,
  generateScaffoldWorkbook,
  generateWorksheets,
  saveWorkbookToDownloads,
} from "../utils";

const fundExposureSummaryCurrencyColumns = [
  FundExposureSummaryKeys.FundSize,
  FundExposureSummaryKeys.FundCommitedCapital,
  FundExposureSummaryKeys.LpCommittedCapital,
  FundExposureSummaryKeys.LpContributedCapitalItd,
  FundExposureSummaryKeys.LpDistributedCapitalItd,
  FundExposureSummaryKeys.LpRemainingUnfunded,
  FundExposureSummaryKeys.LpEndingCapitalAccountValue,
  FundExposureSummaryKeys.LpCurrentExposure,
  FundExposureSummaryKeys.LpInterimContributions,
  FundExposureSummaryKeys.LpInterimDistributions,
];

const fundExposureSummaryPercentageColumns = [
  FundExposureSummaryKeys.Top10Concentration,
  FundExposureSummaryKeys.Top10Concentration,
  FundExposureSummaryKeys.PercentPublic,
  FundExposureSummaryKeys.WeightedAvgRevenueGrowth,
  FundExposureSummaryKeys.WeightedAvgEbitdaMargin,
  FundExposureSummaryKeys.FundNavQoqPercentChg1q,
  FundExposureSummaryKeys.FundNavQoqPercentChg2q,
  FundExposureSummaryKeys.FundNavQoqPercentChg3q,
  FundExposureSummaryKeys.FundNavYoyPercentChangeFy1,
  FundExposureSummaryKeys.FundNavYoyPercentChangeFy2,
  FundExposureSummaryKeys.TapPriceEstimate,
  FundExposureSummaryKeys.BaseManagementFee,
  FundExposureSummaryKeys.Carry,
  FundExposureSummaryKeys.HurdleRate,
];

const fundExposureSummaryMultiplierColumns = [
  FundExposureSummaryKeys.FundSeriesAverageMoic,
];
const fundExposureSummaryDateColumns = [FundExposureSummaryKeys.ReportDate];

const fundFinancialsCurrencyColumns = [
  FundFinancialsKeys.FundCommittedCapital,
  FundFinancialsKeys.FundContributedCapitalItd,
  FundFinancialsKeys.FundDistributedCapitalItd,
  FundFinancialsKeys.FundRemainingUnfunded,
  FundFinancialsKeys.FundRecallableCapitalItd,
  FundFinancialsKeys.FundRemainingUnfunded,
  FundFinancialsKeys.FundEndingCapitalAccountValue,
  FundFinancialsKeys.GpDistributedAsCarryItd,
  FundFinancialsKeys.PortfolioInvestmentsCost,
  FundFinancialsKeys.PortfolioInvestmentsNav,
  FundFinancialsKeys.Cash,
  FundFinancialsKeys.OtherAssets,
  FundFinancialsKeys.DebtAndCapitalCallLines,
  FundFinancialsKeys.OtherLiabilities,
  FundFinancialsKeys.TotalNav,
];

const fundFinancialsPercentageColumns = [];

const fundFinancialsMultiplierColumns = [];

const fundFinancialsDateColumns = [FundFinancialsKeys.ReportDate];

const getDataByTabName = (data, tabName: string) => {
  const csvStr = data.find((tab) => tab.name === tabName)?.data;

  if (!csvStr) {
    return [];
  }

  return csvToJson
    .fieldDelimiter(",")
    .supportQuotedField(true)
    .csvStringToJson(csvStr);
};

export const createFundExposureSummaryExcel = async ({
  data,
  namePrefix,
}: {
  data: FundSummaryDataTab[];
  namePrefix: string;
}) => {
  const workbook = generateScaffoldWorkbook();

  const [fundExposureSummarySheet, fundFinancialsSheet] = generateWorksheets({
    workbook,
    sheets: [
      {
        label: FundSummaryTabs.FundExposureSummary,
        columns: fundExposureSummaryColumns,
      },
      {
        label: FundSummaryTabs.FundFinancials,
        columns: fundFinancialsColumns,
      },
    ],
  });

  // Cleaned up the data from the CSV string, get a real JSON object
  const fundExposureSummaryData = getDataByTabName(
    data,
    FundSummaryTabs.FundExposureSummary,
  );
  const fundFinancialsData = getDataByTabName(
    data,
    FundSummaryTabs.FundFinancials,
  );

  // Fund Exposure Summary
  fundExposureSummarySheet.addRows(fundExposureSummaryData);
  fundExposureSummaryData.forEach((row, index) => {
    const indexOffset = 2; // 1 is for the header row, 1 is because excel is 1-indexed

    // 1st format all the values to numbers / currency
    formatCellsCurrency({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryCurrencyColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 2nd format all the values to numbers / percentage
    formatCellsPercentage({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryPercentageColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 3rd format all the values to numbers / multiplier
    formatCellsMultiplier({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryMultiplierColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 4th format all the values to date
    formatCellsDate({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryDateColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });
  });

  // SOI Lookthrough
  fundFinancialsSheet.addRows(fundFinancialsData);
  fundFinancialsData.forEach((row, index) => {
    const indexOffset = 2; // 1 is for the header row, 1 is because excel is 1-indexed

    // 1st format all the values to numbers / currency
    formatCellsCurrency({
      worksheet: fundFinancialsSheet,
      columns: fundFinancialsCurrencyColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 2nd format all the values to numbers / percentage
    formatCellsPercentage({
      worksheet: fundFinancialsSheet,
      columns: fundFinancialsPercentageColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 3rd format all the values to numbers / multiplier
    formatCellsMultiplier({
      worksheet: fundFinancialsSheet,
      columns: fundFinancialsMultiplierColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 4th format all the values to date
    formatCellsDate({
      worksheet: fundFinancialsSheet,
      columns: fundFinancialsDateColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });
  });

  await saveWorkbookToDownloads(workbook, `${namePrefix}.xlsx`);
};
