import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ITransactionPeriod } from "../models/Transaction";

export class UpsertTimeline implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    txnId,
    periods,
  }) => {
    return await new TapClient(`/transactions/lp-led/${txnId}/timeline/`, {
      timeline: periods,
    })
      .post()
      .then((_) => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  txnId: string;
  periods: ITransactionPeriod[];
}
