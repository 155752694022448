import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";

import { transactionsClient } from "common/clients/ApolloClient";

import {
  GpOutlook,
  IQualitativeDataInsights,
  IQualitativeDataUpdates,
  LiquidityTimeline,
  PerformanceToPlan,
} from "./model";
import {
  GET_TRANSACTION_QUALITATIVE_DATA_INSIGHTS,
  GET_TRANSACTION_QUALITATIVE_DATA_UPDATES,
} from "./query";
import { ILiquidityProjectionTimeline } from "./LiquidityProjection";

import { useFundDataStatus } from "../useFundDataStatus";

import { parseStrToNumber } from "experiences/common/excel/utils";
import { generateManagerAssessmentCharts } from "./generateManagerAssessmentCharts";
import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";

const getLiquidityProjectionChart = (data: IQualitativeDataInsights) => {
  const sourceInvestmentsInsights =
    data?.qualitativeData?.investmentsInsights || [];

  return sourceInvestmentsInsights.map((insight) => {
    const entry: ILiquidityProjectionTimeline = {
      name: insight.liquidityTimeline,
      value: parseStrToNumber(insight.lpImpliedTransactionValue || "0"),
    };

    return entry;
  });
};

export const useCommentary = ({
  currentReportDate,
}: {
  currentReportDate: string;
}) => {
  const { txnId } = useParams();
  const { selectedHoldingsIds } = useContext(TransactionFundDataContext);

  const { loading: loadingStatus, qualitativeDataStatus } = useFundDataStatus();
  useFundDataStatus();

  let {
    data: rawInsights,
    loading: insightsLoading,
    refetch: refetchInsights,
  } = useQuery<IQualitativeDataInsights>(
    GET_TRANSACTION_QUALITATIVE_DATA_INSIGHTS,
    {
      client: transactionsClient,
      skip: !currentReportDate || selectedHoldingsIds.length === 0,
      variables: {
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldingsIds,
      },
    },
  );

  const {
    data: rawUpdates,
    loading: updatesLoading,
    refetch: refetchUpdates,
  } = useQuery<IQualitativeDataUpdates>(
    GET_TRANSACTION_QUALITATIVE_DATA_UPDATES,
    {
      client: transactionsClient,
      skip: !currentReportDate || selectedHoldingsIds.length === 0,
      variables: {
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldingsIds,
      },
    },
  );

  // Default some values associated with "NoMention" or "NoMentioned" if values are not defined
  const formattedInsights: IQualitativeDataInsights = useMemo(() => {
    return {
      ...rawInsights,
      qualitativeData: {
        ...rawInsights?.qualitativeData,
        investmentsInsights:
          rawInsights?.qualitativeData?.investmentsInsights?.map((insight) => ({
            ...insight,
            liquidityTimeline:
              insight.liquidityTimeline || LiquidityTimeline.NoMention,
            gpOutlook: insight.gpOutlook || GpOutlook.NotMentioned,
            performanceToPlan:
              insight.performanceToPlan || PerformanceToPlan.NotMentioned,
          })) || [],
      },
    };
  }, [rawInsights]);

  const managerAssessmentCharts = useMemo(() => {
    return formattedInsights
      ? generateManagerAssessmentCharts(
          formattedInsights.qualitativeData.investmentsInsights || [],
        )
      : { outlookChartData: [], performanceToPlanChartData: [] };
  }, [formattedInsights]);

  const liquidityProjectionChart: ILiquidityProjectionTimeline[] =
    useMemo(() => {
      return formattedInsights
        ? getLiquidityProjectionChart(formattedInsights)
        : [];
    }, [formattedInsights]);

  useEffect(() => {
    if (currentReportDate && selectedHoldingsIds.length > 0) {
      refetchInsights({
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingIds: selectedHoldingsIds,
      });
      refetchUpdates({
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingIds: selectedHoldingsIds,
      });
    }
  }, [currentReportDate, selectedHoldingsIds]);

  // I swear I was doing this because I was adding some extra data to the insights, but now I can't remember what it was
  // I'll just leave this here for now, and refactor later if needed
  const updatesData = useMemo(() => {
    const sourceInvestmentsUpdates =
      rawUpdates?.qualitativeData?.investmentsUpdates || [];

    return (
      sourceInvestmentsUpdates?.map((update) => {
        return {
          ...update,
        };
      }) || []
    );
  }, [rawUpdates]);

  const companyData = useMemo(() => {
    const investmentInsights =
      formattedInsights?.qualitativeData?.investmentsInsights || [];

    const formattedInvestmentInsights =
      investmentInsights?.map((insight) => {
        return {
          ...insight,
          lpImpliedInvestmentPercentage:
            insight.lpImpliedInvestmentPercentage || NOT_AVAILABLE_STR,
        };
      }) || [];

    const investmentInsightsByInvestmentPercentWithNullValues =
      formattedInvestmentInsights.filter(
        (insight) =>
          insight.lpImpliedInvestmentPercentage === null ||
          insight.lpImpliedInvestmentPercentage === NOT_AVAILABLE_STR ||
          insight.lpImpliedInvestmentPercentage === "",
      ) || [];

    const investmentInsightsByInvestmentPercentNonNull =
      formattedInvestmentInsights.filter(
        (insight) =>
          insight.lpImpliedInvestmentPercentage !== null &&
          insight.lpImpliedInvestmentPercentage !== NOT_AVAILABLE_STR &&
          insight.lpImpliedInvestmentPercentage !== "",
      ) || [];

    const sortedInvestmentInsightsByInvestmentPercentNonNull =
      investmentInsightsByInvestmentPercentNonNull.sort(
        (a, b) =>
          Number(b.lpImpliedInvestmentPercentage) -
          Number(a.lpImpliedInvestmentPercentage),
      ) || [];

    return [
      ...sortedInvestmentInsightsByInvestmentPercentNonNull,
      ...investmentInsightsByInvestmentPercentWithNullValues,
    ];
  }, [formattedInsights]);

  const isQualitativeDataReady =
    qualitativeDataStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const awaitingUpload =
    qualitativeDataStatus ===
    TransactionDataSummarySectionStatus.NotYetUploaded;

  const extractionInProgress =
    qualitativeDataStatus ===
    TransactionDataSummarySectionStatus.UploadedAndProcessing;

  return {
    data: formattedInsights,
    loading: insightsLoading || updatesLoading || loadingStatus,
    managerAssessmentCharts,
    liquidityProjectionChart,
    updatesData,
    companyData,
    isQualitativeDataReady,
    awaitingUpload,
    extractionInProgress,
  };
};
