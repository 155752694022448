import { Stack } from "@mui/material";
import { X } from "@phosphor-icons/react";
import classNames from "classnames";
import styled from "styled-components";

export const TapUiModalTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 140%;
  color: var(--branding-dark, #21272d);

  user-select: none;
  cursor: default;
`;

export const TapUiModalSubTitle = styled.h2`
  margin-top: 4px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #737476;

  user-select: none;
  cursor: default;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  right: -12px;
  top: -8px;
  display: block;
  padding: 8px;
  background-color: transparent;
  color: var(--branding-dark, #21272d);
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 0;

  border-radius: 50%;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }

  &:focus {
    outline: 2px solid #f5f5f5;
  }
`;

const StyledTapUiModalHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const TapUiModalHead = ({
  title,
  subtitle,
  onCloseClick,
}: {
  title: string;
  subtitle?: string;
  onCloseClick: () => void;
}) => {
  return (
    <StyledTapUiModalHead>
      <TapUiModalTitle>{title}</TapUiModalTitle>
      {subtitle && <TapUiModalSubTitle>{subtitle}</TapUiModalSubTitle>}
      <ModalCloseButton onClick={onCloseClick} type="button">
        <X size={16} weight="bold" />
      </ModalCloseButton>
    </StyledTapUiModalHead>
  );
};

export const TapUiModalContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <>{children}</>;
};

export const TapUiVerticalModalWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col gap-4 p-6 box-border bg-white rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
        "max-w-full max-h-full overflow-y-auto",
        className,
      )}
    >
      {children}
    </div>
  );
};
