import { useEffect, useState } from "react";
import { NotePencil } from "@phosphor-icons/react";
import { match } from "fp-ts/lib/Either";

import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import {
  PageMainContent,
  PageSidebar,
  PageWrapper,
} from "experiences/common/PageWrapper";
import { SidebarSideMenu } from "experiences/dashboard/presentation/components/SidebarMenu";
import { InvestmentProfile } from "experiences/authentication/presentation/components/settings/InvestmentProfile";
import { UserPreview } from "experiences/authentication/presentation/components/settings/UserPreview";
import { NotificationSettings } from "experiences/authentication/presentation/components/settings/NotificationSettings";
import { Button } from "common/components/Button";
import { GetUserSettings } from "experiences/authentication/domain/usecases/GetUserSettings";
import { Failure } from "common/@types/app/Failure";
import { UserSettings } from "experiences/authentication/domain/models/Settings";
import { useDocumentsContext } from "experiences/documents/presentation/state/DocumentsContext";
import { DocumentListLoaded } from "experiences/documents/presentation/state/DocumentsState";
import { RedirectedToUserSettingsPage } from "experiences/documents/presentation/state/DocumentsEvent";
import { SettingsDocumentsList } from "../components/SettingsDocumentsList";
import { UpdateUserModal } from "../components/settings/UpdateUserModal";
import { UpdateInvestmentProfileModal } from "../components/settings/UpdateInvestmentProfile";
import { LP_ROUTES, LpSettingsRoutes } from "common/constants/routes";

const useSettingsPage = () => {
  const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);
  const [documentsEnabled, setDocumentsEnabled] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [
    showUpdateInvestmentProfileButton,
    setShowUpdateInvestmentProfileButton,
  ] = useState(false);
  const [
    updateInvestmentProfileModalOpen,
    setUpdateInvestmentProfileModalOpen,
  ] = useState(false);

  const openUpdateUserModal = () => {
    setUpdateUserModalOpen(true);
  };

  const closeUpdateUserModal = () => {
    setUpdateUserModalOpen(false);
  };

  const openUpdateInvestmentProfileModal = () => {
    setUpdateInvestmentProfileModalOpen(true);
  };

  const closeUpdateInvestmentProfileModal = () => {
    setUpdateInvestmentProfileModalOpen(false);
  };
  const [user, setUser] = useState<UserSettings | undefined>(undefined);

  const getUser = () => {
    new GetUserSettings().call({}).then((resp) => {
      return match<Failure, UserSettings, { success: boolean; response: any }>(
        (response: Failure) => {
          // TODO: show error
          return { success: false, response };
        },
        (response) => {
          setUser(response);
          return { success: true, response };
        },
      )(resp);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return {
    user,
    openUpdateInvestmentProfileModal,
    openUpdateUserModal,
    updateUserModalOpen,
    closeUpdateUserModal,
    closeUpdateInvestmentProfileModal,
    updateInvestmentProfileModalOpen,
    documentsEnabled,
    notificationsEnabled,
    showUpdateInvestmentProfileButton,
  };
};

export const SettingsPage = () => {
  const {
    user,
    openUpdateInvestmentProfileModal,
    openUpdateUserModal,
    updateUserModalOpen,
    closeUpdateUserModal,
    closeUpdateInvestmentProfileModal,
    updateInvestmentProfileModalOpen,
    documentsEnabled,
    notificationsEnabled,
    showUpdateInvestmentProfileButton,
  } = useSettingsPage();

  const { documentListState, emitEvent } = useDocumentsContext();

  useEffect(() => {
    emitEvent!(new RedirectedToUserSettingsPage());
  }, []);

  return (
    <PageWrapper>
      <PageSidebar className="no-scrollbar">
        <UserPreview openUpdateUserModal={openUpdateUserModal} user={user} />
        <SidebarSideMenu
          isFundSidebar
          menu={[
            {
              label: "Investment Profile",
              path: `${LP_ROUTES.Settings}${LpSettingsRoutes.InvestmentProfile}`,
            },
            {
              label: "Documents",
              path: `${LP_ROUTES.Settings}${LpSettingsRoutes.Documents}`,
            },
          ]}
        />
      </PageSidebar>
      <PageMainContent>
        <PageSectionTitleDivider
          actions={
            <>
              {showUpdateInvestmentProfileButton ? (
                <Button
                  size="small"
                  icon={<NotePencil />}
                  onClick={openUpdateInvestmentProfileModal}
                >
                  Edit
                </Button>
              ) : null}
            </>
          }
        >
          Investment Profile
        </PageSectionTitleDivider>
        <InvestmentProfile user={user} />
        {documentListState instanceof DocumentListLoaded &&
        documentListState.documents.length > 0 ? (
          <>
            <PageSectionTitleDivider showBorderTop>
              Documents
            </PageSectionTitleDivider>
            <SettingsDocumentsList documents={documentListState.documents} />
          </>
        ) : null}
        {notificationsEnabled ? (
          <>
            <PageSectionTitleDivider>Notifications</PageSectionTitleDivider>
            <NotificationSettings />
          </>
        ) : null}
      </PageMainContent>
      <UpdateUserModal
        open={updateUserModalOpen}
        onClose={closeUpdateUserModal}
      />
      <UpdateInvestmentProfileModal
        open={updateInvestmentProfileModalOpen}
        onClose={closeUpdateInvestmentProfileModal}
      />
    </PageWrapper>
  );
};
