import styled from "styled-components";

export const HOT_LICENSE = import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY;
export const HOT_IOI_BULK_UPLOAD_TABLE_ID = "ioi_bulk_upload_table";
export const HEADER_TOOLTIP_ELEMENT_ID = "header_tooltip_el";
const HANDS_ON_TABLE_INVALID_CELL_CLASSNAME = "htInvalid";
const HANDS_ON_TABLE_PLACEHOLDER_CELL_CLASSNAME = "htPlaceholder";

export const HotTableWrapper = styled.div<{
  isLoading?: boolean;
}>`
  position: relative;

  #${HOT_IOI_BULK_UPLOAD_TABLE_ID} {
    position: relative;

    * {
      font-family: "Inter", sans-serif;
      border-color: #fafaf8;
    }

    th {
      background-color: #fafaf8;
      font-weight: 500;
    }

    td {
      line-height: 1.5;
      height: auto;
    }

    .relative {
      span {
      }
    }

    .${HANDS_ON_TABLE_PLACEHOLDER_CELL_CLASSNAME} {
      color: #d4d4d4;
    }

    .${HANDS_ON_TABLE_INVALID_CELL_CLASSNAME} {
      background-color: #ffffff !important;
      outline: 1px solid #ef4444 !important;
    }

    .${HANDS_ON_TABLE_INVALID_CELL_CLASSNAME}.${HANDS_ON_TABLE_PLACEHOLDER_CELL_CLASSNAME} {
      color: #f87171;
    }

    .autocompleteEditor .htCore {
      /* border: 1px solid #a8a29e; */
      background-color: #ffffff;
      box-shadow:
        0px 0.2px 0.3px -16px rgba(0, 0, 0, 0.077),
        0px 0.5px 0.8px -16px rgba(0, 0, 0, 0.115),
        0px 1px 1.5px -16px rgba(0, 0, 0, 0.131),
        0px 1.6px 2.4px -16px rgba(0, 0, 0, 0.137),
        0px 2.6px 4px -16px rgba(0, 0, 0, 0.146),
        0px 4.6px 6.9px -16px rgba(0, 0, 0, 0.176),
        0px 10px 15px -16px rgba(0, 0, 0, 0.29);
    }
  }

  #${HOT_IOI_BULK_UPLOAD_TABLE_ID} {
    ${(props) => props.isLoading && "pointer-events: none; opacity: 0.5;"}
  }

  #${HEADER_TOOLTIP_ELEMENT_ID} {
    position: absolute;
    transform: translate(-50%, -100%);
    top: -8px;

    display: block;
    padding: 8px 16px;
    width: fit-content;
    max-width: 420px;
    font-family: "Inter", sans-serif;
    font-size: 11px;
    font-weight: 400;
    cursor: default;
    z-index: 100;
    visibility: visible;

    background-color: #ffffff;

    border: 1px solid #dfdfd9;
    border-radius: 4px;
    box-shadow:
      0px 1.2px 1.7px -32px rgba(0, 0, 0, 0.059),
      0px 2.8px 4.1px -32px rgba(0, 0, 0, 0.089),
      0px 5.3px 7.8px -32px rgba(0, 0, 0, 0.101),
      0px 9.4px 13.8px -32px rgba(0, 0, 0, 0.114),
      0px 17.5px 25.9px -32px rgba(0, 0, 0, 0.145),
      0px 42px 62px -32px rgba(0, 0, 0, 0.2);
    overflow: visible;

    &:empty {
      visibility: hidden;
    }
  }
`;
