import { useNavigate } from "react-router";
import { Coins } from "@phosphor-icons/react";

import { LP_ROUTES } from "common/constants/routes";
import { DashboardTask } from "../DashboardTask";

export const ActiveIoisTask = () => {
  const navigate = useNavigate();
  const handleViewIoisClick = () => {
    navigate(LP_ROUTES.DashboardNewIois);
  };

  return (
    <DashboardTask
      title="New Indications of Interest"
      description="See new IOIs for the funds in your portfolio"
      icon={<Coins color="#BCB3A5" />}
      buttonCta="View IOIs"
      onCtaClick={handleViewIoisClick}
    />
  );
};
