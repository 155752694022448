import styled from "styled-components";
import { useState, useCallback, useEffect } from "react";
import byteSize from "byte-size";
import { Accept, useDropzone } from "react-dropzone";
import { FileText, Upload, X } from "@phosphor-icons/react";

import { delayMs } from "common/utils";
import { Button, BUTTON_TYPES } from "common/components/Button";

const UploadIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  border-radius: 50%;

  border: 1px solid var(--branding-keyline-default, #dfdfd9);
  background: #fff;
  cursor: pointer;

  &:hover {
    background: #f7f7f5;
  }
`;

const StyledDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--branding-keyline-default, #dfdfd9);
  background: var(--branding-texture-2, #fafaf8);
  gap: 16px;
  box-sizing: border-box;

  p.instructions {
    display: block;
    text-align: center;
    color: #21272d;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: default;
    max-width: 80%;

    user-select: none;

    .link {
      color: #bcb3a5;
    }
  }

  p.accepted_types {
    display: block;
    color: #737476;
    font-family: "Inter", sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    user-select: none;
  }
`;

const StyledUploadedFile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  gap: 11px;
  border-radius: 12px;
  border: 1px solid var(--branding-keyline-default, #dfdfd9);
  margin-top: 16px;

  svg {
    display: inline;
  }

  .file_details {
    display: block;
  }

  .file_name {
    font-family: "Inter", sans-serif;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    user-select: none;

    color: #21272d;
  }

  .file_size {
    font-family: "Inter", sans-serif;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    user-select: none;

    color: #737476;
  }
`;

const FileList = ({
  compact,
  files,
  className,
  onRemoveFileClick,
}: {
  compact?: boolean;
  files: File[];
  className?: string;
  onRemoveFileClick?: (file: File) => void;
}) => {
  if (files.length === 0) return null;

  const showRemoveButton = typeof onRemoveFileClick !== "undefined";

  return (
    <div className={className}>
      {files.map((file) => {
        if (compact) {
          return (
            <div
              key={file.name}
              className="flex items-center gap-4 h-10 mb-2 last:mb-0"
              title={file.name}
            >
              <div className="border rounded-md p-2 px-2 text-xs flex items-center gap-2 h-10 flex-1">
                <FileText size={16} />
                <p className="flex-1 cursor-default">{file.name}</p>
                <span className="text-stone-400 cursor-default">
                  {byteSize(file.size).toString()}
                </span>
              </div>
              {showRemoveButton && (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  icon={<X size={16} />}
                  onClick={() => onRemoveFileClick(file)}
                  size="small"
                  className="h-10"
                />
              )}
            </div>
          );
        }
        return (
          <StyledUploadedFile key={file.name} title={file.name}>
            <FileText size={24} />
            <div className="file_details">
              <p className="file_name">{file.name}</p>
              <p className="file_size">{byteSize(file.size).toString()}</p>
            </div>
            {showRemoveButton && (
              <Button
                type={BUTTON_TYPES.SECONDARY}
                icon={<X size={24} />}
                onClick={() => onRemoveFileClick(file)}
                size="small"
              />
            )}
          </StyledUploadedFile>
        );
      })}
    </div>
  );
};

export const DropZone = ({
  onFileChange,
  customMessage,
  multiple,
  accept,
  acceptDropzone,
  className,
  hideDropZoneWhenHasFiles,
  wrapperModalOpen,
  filesClassName,
  compactFileList,
  showRemoveButton,
}: {
  onFileChange: (files?: File[]) => void;
  customMessage?: React.ReactNode;
  multiple?: boolean;
  accept?: string[];
  acceptDropzone?: Accept;
  className?: string;
  hideDropZoneWhenHasFiles?: boolean;
  wrapperModalOpen?: boolean;
  filesClassName?: string;
  compactFileList?: boolean;
  showRemoveButton?: boolean;
}) => {
  const [files, setFiles] = useState<File[]>([]);

  // receive an array of files from the browser
  const onDrop = useCallback((newFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  useEffect(() => {
    onFileChange(files);
  }, [files]);

  const handleRemoveFileClick = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: acceptDropzone,
  });

  const resetFiles = () => {
    setFiles([]);
  };

  // If inside a wrapper modal, reset the files when the modal is closed
  useEffect(() => {
    if (files.length > 0 && wrapperModalOpen === false) {
      // Do a small delay because if we reset immediately, the files will be removed before the modal is closed and the user will see a flicker
      delayMs(500).then(() => {
        resetFiles();
      });
    }
  }, [wrapperModalOpen, files]);

  let showDropZone = true;

  if (hideDropZoneWhenHasFiles && files.length) {
    showDropZone = false;
  }

  return (
    <>
      {showDropZone && (
        <StyledDropzone
          {...getRootProps()}
          style={{ padding: "48px 24px" }}
          className={className}
        >
          <UploadIconCircle>
            <Upload size={28} />
          </UploadIconCircle>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="instructions">Drop the files here ...</p>
          ) : (
            <p className="instructions">
              {customMessage ? (
                customMessage
              ) : (
                <>
                  Drag and drop or <span className="link">choose file</span> to
                  upload your Capital Account Statement
                </>
              )}
            </p>
          )}
          {accept?.length > 0 ? (
            <p className="accepted_types whitespace-nowrap">
              <span className="mr-2">Accepted file types:</span>
              {accept?.join(", ").toLocaleUpperCase()}
            </p>
          ) : null}
        </StyledDropzone>
      )}
      <FileList
        files={files}
        className={filesClassName}
        compact={compactFileList}
        onRemoveFileClick={showRemoveButton ? handleRemoveFileClick : undefined}
      />
    </>
  );
};
