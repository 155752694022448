import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";

export class RemoveParticipant implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    participantId,
    txnId,
  }) => {
    return await new TapClient(
      `/transactions/${txnId}/participant/${participantId}/delete/`,
    )
      .post()
      .then(() => {
        return right(NoReturn);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  participantId: string;
  txnId: string;
}
