import { useState } from "react";
import { Lightbulb, MagicWand, PlusCircle, Rows } from "@phosphor-icons/react";
import { HotTable } from "@handsontable/react";
import { registerCellType, DropdownCellType } from "handsontable/cellTypes";
import { Stack } from "@mui/material";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";

import { BUTTON_TYPES, Button } from "common/components/Button";
import {
  ADD_MORE_ROWS_COUNT,
  DEFAULT_ROW_COUNT,
} from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/useBulkUpload";
import { useBulkUpload } from "experiences/indications-of-interest/presentation/components/manager-bulk-upload/useBulkUpload";
import {
  HotTableWrapper,
  HOT_IOI_BULK_UPLOAD_TABLE_ID,
  HEADER_TOOLTIP_ELEMENT_ID,
  HOT_LICENSE,
} from "experiences/indications-of-interest/presentation/components/hotTableWrapper";

registerAllModules();
registerCellType(DropdownCellType);

export const IoiManagerTable = () => {
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const {
    hotRef,
    handleAddMoreRowsClick,
    handleSubmitClick,
    autoCorrectManagerNames,
    validateNamesLoading,
    tableColumnTitles,
    tableColumns,
    tableColumnWidths,
    dataSchema,
    submitLoading,
    disableAddMoreRows,
    tableValid,
    handlePaste,
    afterOnCellMouseOver,
    afterOnCellMouseOut,
    afterChange,
  } = useBulkUpload();

  return (
    <>
      <HotTableWrapper>
        <HotTable
          ref={hotRef}
          dataSchema={dataSchema}
          rowHeaders
          manualColumnResize
          licenseKey={HOT_LICENSE}
          startRows={DEFAULT_ROW_COUNT}
          trimDropdown={false}
          colHeaders={tableColumnTitles}
          columns={tableColumns}
          colWidths={tableColumnWidths}
          height="auto"
          width="100%"
          afterPaste={handlePaste}
          id={HOT_IOI_BULK_UPLOAD_TABLE_ID}
          afterOnCellMouseOver={afterOnCellMouseOver}
          afterOnCellMouseOut={afterOnCellMouseOut}
          afterChange={afterChange}
        />
        <div id={HEADER_TOOLTIP_ELEMENT_ID}></div>
      </HotTableWrapper>

      {showValidationMessage && (
        <div
          style={{
            display: "flex",
            width: "fit-content",
            gap: 8,
            marginBottom: 32,
            marginTop: 32,
            padding: "16px 24px",
            alignItems: "center",
            border: "1px solid #dfdfd9",
            boxSizing: "border-box",
            borderRadius: 4,
            fontSize: 12,
            cursor: "default",
          }}
        >
          <Lightbulb style={{ marginRight: 8 }} />
          <p>
            There are invalid values or missing fields, please review the table
            data and try again.
            <br />
            Make sure you <em>Autocorrect manager names</em> before submitting.
            <br />
            <b>Duplicate managers are not allowed.</b>
          </p>
        </div>
      )}
      <div
        style={{
          display: "flex",
          gap: 8,
          marginBottom: 32,
          marginTop: 32,
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={handleAddMoreRowsClick}
          icon={<Rows />}
          disabled={submitLoading || disableAddMoreRows}
        >
          Add {ADD_MORE_ROWS_COUNT} more rows
        </Button>
        <Stack direction="row">
          <>
            <Button
              onClick={autoCorrectManagerNames}
              icon={<MagicWand />}
              disabled={validateNamesLoading}
            >
              Autocorrect names
            </Button>
            <Button
              onClick={() => {
                const { valid } = handleSubmitClick();

                setShowValidationMessage(() => valid.current);
              }}
              type={BUTTON_TYPES.SECONDARY}
              icon={<PlusCircle />}
              disabled={submitLoading}
            >
              Submit IOIs
            </Button>
          </>
        </Stack>
      </div>
    </>
  );
};
