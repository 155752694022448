import csvToJson from "convert-csv-to-json";

import {
  formatCellsCurrency,
  formatCellsPercentage,
  formatCellsMultiplier,
  saveWorkbookToDownloads,
  formatCellsDate,
  generateScaffoldWorkbook,
  generateWorksheets,
} from "../utils";
import {
  AggregateCompanyDataColumnKeys,
  aggregateCompanyDataColumns,
  AggregateSoiColumnKeys,
  aggregateSoiColumns,
} from "./soiLookthroughTabs";

const AGGREGATE_SOI_TAB_NAME = "Aggregate SOI";
const AGGREGATE_COMPANY_DATA_TAB_NAME = "Aggregate Company Data";

const parseStrToNumber = (str: string): number => {
  return Number(str.replaceAll("$", "").replaceAll(",", ""));
};

const getCsvData = (
  data: FundSummaryDataTab[],
  tabName: string,
): any[] | null => {
  const string = data.find((tab) => tab.name === tabName)?.data;

  if (!string) {
    return [];
  }

  return csvToJson
    .fieldDelimiter(",")
    .supportQuotedField(true)
    .csvStringToJson(string);
};

export const createSoiExcel = async ({
  data,
  namePrefix,
}: {
  data: FundSummaryDataTab[];
  namePrefix: string;
}) => {
  const workbook = generateScaffoldWorkbook();

  const [aggregateSoiSheet, aggregateCompanyDataSheet] = generateWorksheets({
    workbook,
    sheets: [
      {
        label: AGGREGATE_SOI_TAB_NAME,
        columns: aggregateSoiColumns,
      },
      {
        label: AGGREGATE_COMPANY_DATA_TAB_NAME,
        columns: aggregateCompanyDataColumns,
      },
    ],
  });

  // Cleaned up the data from the CSV string, get a real JSON object
  const aggregateSoiData = getCsvData(data, AGGREGATE_SOI_TAB_NAME);
  const aggregateCompanyData = getCsvData(
    data,
    AGGREGATE_COMPANY_DATA_TAB_NAME,
  );

  // Attach the data to the worksheets
  aggregateSoiSheet.addRows(aggregateSoiData);
  aggregateCompanyDataSheet.addRows(aggregateCompanyData);

  const percentOfTotalColumn = aggregateSoiSheet.getColumn(
    AggregateSoiColumnKeys.PercentOfTotal,
  );

  const sumOfAllLpImpliedInvestmentValues = aggregateSoiData.reduce(
    (acc, row) => {
      const numberValue = parseStrToNumber(
        row[AggregateSoiColumnKeys.LpImpliedInvestmentValue],
      );

      if (isNaN(numberValue)) {
        return acc;
      }

      return acc + numberValue;
    },
    0,
  );

  // aggregate soi data specific formatting
  aggregateSoiData.forEach((row, index) => {
    const indexOffset = 2; // 1 is for the header row, 1 is because excel is 1-indexed

    // 1st format all the values to numbers / currency
    formatCellsCurrency({
      worksheet: aggregateSoiSheet,
      columns: [
        AggregateSoiColumnKeys.TotalCost,
        AggregateSoiColumnKeys.TotalValue,
        AggregateSoiColumnKeys.InvestmentMOIC,
        AggregateSoiColumnKeys.RealizedCost,
        AggregateSoiColumnKeys.RealizedValue,
        AggregateSoiColumnKeys.UnrealizedCost,
        AggregateSoiColumnKeys.UnrealizedValue,
        AggregateSoiColumnKeys.LpImpliedInvestmentValue,
      ],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 2nd format all the values to numbers / percentage
    formatCellsPercentage({
      worksheet: aggregateSoiSheet,
      columns: [AggregateSoiColumnKeys.PercentOfTotal],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 3rd format all the values to numbers / multiplier
    formatCellsMultiplier({
      worksheet: aggregateSoiSheet,
      columns: [AggregateSoiColumnKeys.InvestmentMOIC],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 4th format all the values to date
    formatCellsDate({
      worksheet: aggregateSoiSheet,
      columns: [
        AggregateSoiColumnKeys.ReportDate,
        AggregateSoiColumnKeys.InvestmentDate,
      ],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    const cell = aggregateSoiSheet
      .getRow(index + indexOffset)
      .getCell(AggregateSoiColumnKeys.PercentOfTotal);

    // this is assuming that the previous column is "T"
    // otherwise we would need to calculate the column letter from the index

    const percentOfTotalValue =
      Number(
        row[AggregateSoiColumnKeys.LpImpliedInvestmentValue]
          .replaceAll("$", "")
          .replaceAll(",", ""),
      ) / sumOfAllLpImpliedInvestmentValues;

    if (isNaN(percentOfTotalValue) || percentOfTotalValue === 0) {
      cell.value = null;
      return;
    }

    // Add formulas
    cell.value = {
      formula: `T${index + indexOffset}/SUM($T:$T)`,
      result: percentOfTotalValue,
    };

    // Add formatting
    cell.numFmt = "0.00%";
  });

  // aggregate company data specific formatting
  aggregateCompanyData.forEach((row, index) => {
    const indexOffset = 2; // 1 is for the header row, 1 is because excel is 1-indexed

    formatCellsDate({
      worksheet: aggregateCompanyDataSheet,
      columns: [AggregateCompanyDataColumnKeys.ReportDate],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    formatCellsCurrency({
      worksheet: aggregateCompanyDataSheet,
      columns: [
        AggregateCompanyDataColumnKeys.EnterpriseValue,
        AggregateCompanyDataColumnKeys.RevenueLTM,
        AggregateCompanyDataColumnKeys.EBITDALTM,
        AggregateCompanyDataColumnKeys.EBITDALFY,
        AggregateCompanyDataColumnKeys.EBITDALFY1,
        AggregateCompanyDataColumnKeys.EBITDALFY2,
        AggregateCompanyDataColumnKeys.EBITDANTM,
        AggregateCompanyDataColumnKeys.EquityValue,
        AggregateCompanyDataColumnKeys.CompanyCash,
        AggregateCompanyDataColumnKeys.CompanyDebt,
        AggregateCompanyDataColumnKeys.CompanyNetDebt,
        AggregateCompanyDataColumnKeys.CapexLTM,
        AggregateCompanyDataColumnKeys.EBITDAMultipleLTM,
        AggregateCompanyDataColumnKeys.LeverageMultipleLTM,
        AggregateCompanyDataColumnKeys.RevenueMultipleLTM,
        AggregateCompanyDataColumnKeys.ValuationLFY,
        AggregateCompanyDataColumnKeys.ValuationLFY1,
        AggregateCompanyDataColumnKeys.ValuationLFY2,
      ],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    formatCellsPercentage({
      worksheet: aggregateCompanyDataSheet,
      columns: [
        AggregateCompanyDataColumnKeys.FundOwnershipPercent,
        AggregateCompanyDataColumnKeys.EBITDAMultipleLTM,
        AggregateCompanyDataColumnKeys.LeverageMultipleLTM,
        AggregateCompanyDataColumnKeys.RevenueMultipleLTM,
      ],
      rowIndex: index,
      rowOffset: indexOffset,
    });

    formatCellsMultiplier({
      worksheet: aggregateCompanyDataSheet,
      columns: [
        AggregateCompanyDataColumnKeys.EBITDAMultipleLTM,
        AggregateCompanyDataColumnKeys.LeverageMultipleLTM,
        AggregateCompanyDataColumnKeys.RevenueMultipleLTM,
      ],
      rowIndex: index,
      rowOffset: indexOffset,
    });
  });

  await saveWorkbookToDownloads(workbook, `${namePrefix}.xlsx`);

  return workbook;
};
