import { createContext, useContext } from "react";

import { DocumentListState, DocumentSignFlowState } from "./DocumentsState";
import { DocumentsEvent } from "./DocumentsEvent";
import { useManageDocumentsState } from "./DocumentsManager";
import { GetNDAAccessPackageForUser } from "experiences/documents/domain/usecases/GetNDAAccessPackageForUser";
import { GetNDASignaturePackageForUser } from "experiences/documents/domain/usecases/GetNDASignaturePackageForUser";
import { DashboardTaskSectionState } from "./DashboardTaskSectionState";
import { DelegateSignatureForDocument } from "experiences/documents/domain/usecases/DelegateSignatureForDocument";
import { ListDocumentsForCurrentUser } from "experiences/documents/domain/usecases/ListDocumentsForCurrentUser";

export interface DocumentsContextProps {
  dashboardTaskSectionState?: DashboardTaskSectionState;
  documentSignFlowState?: DocumentSignFlowState;
  documentListState?: DocumentListState;
  emitEvent?: (event: DocumentsEvent) => void;
}

interface DocumentsStateProviderProps {
  children: React.ReactNode;
}

const initialContextState: DocumentsContextProps = {};

const DocumentsContext = createContext(initialContextState);

const DocumentsStateProvider = ({ children }: DocumentsStateProviderProps) => {
  const {
    documentSignFlowState,
    emitEvent,
    dashboardTaskSectionState,
    documentListState,
  } = useManageDocumentsState({
    getNDAAccessPackageForUser: new GetNDAAccessPackageForUser(),
    getNDASignaturePackageForUser: new GetNDASignaturePackageForUser(),
    delegateSignatureForDocument: new DelegateSignatureForDocument(),
    listDocumentsForUser: new ListDocumentsForCurrentUser(),
  });

  return (
    <DocumentsContext.Provider
      value={{
        documentListState,
        documentSignFlowState,
        dashboardTaskSectionState,
        emitEvent,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

const useDocumentsContext = () => useContext(DocumentsContext);

export { DocumentsStateProvider, DocumentsContext, useDocumentsContext };
