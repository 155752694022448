import styled from "styled-components";

import { APP_MENU_HEIGHT_PX } from "experiences/common/PageWrapper";
import { SIDEBAR_CARD_WIDTH_PX } from "common/components/MainSidebarLayout";

export const StyledTransactionMain = styled.div`
  display: block;
  height: inherit;
  max-height: inherit;
  flex: 1;
  border-left: 1px solid #bcb3a533;
  overflow-y: scroll;
`;

export const StyledTransactionOverview = styled.aside`
  display: block;
  width: ${SIDEBAR_CARD_WIDTH_PX}px;
  height: inherit;

  .overview_details {
    display: block;
    padding: 24px;
    box-sizing: border-box;

    border-bottom: 1px solid #bcb3a533;

    background: #abbaab;
    background: linear-gradient(to top, #e7e4e0, #f7f7f7);

    background-size: cover;
  }

  .overview_details-project_title {
    display: block;
    margin-top: 16px;
    color: #21272d;
    font-size: 18px;
    font-family: "StaffWide", sans-serif;
    font-weight: 600;
    line-height: 110%;
    cursor: default;
  }

  .overview_details-project_description {
    display: block;
    margin-top: 8px;
    color: #737476;
    font-size: 12px;
    font-family: "Inter";
    line-height: 140%;
    letter-spacing: -0.12px;

    cursor: default;
  }

  .overview_details-project_img {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dfdfd9;
  }

  .overview_details-target_close {
    display: block;
    margin-top: 48px;
    color: #737476;
    font-size: 10px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.1px;

    cursor: default;

    &-date {
      color: #21272d;
    }
  }
`;

export const StyledTransactionsLayout = styled.div`
  display: flex;
  height: calc(100vh - ${APP_MENU_HEIGHT_PX}px);
  max-height: calc(100vh - ${APP_MENU_HEIGHT_PX}px);
`;
