import { IIssuer } from "../../../../common/@types/models/Issuer";

export interface IHolding {
  id: string;
  issuer: IIssuer;
  bookValue: number;
  fundPrice?: FundPrice;
  referenceDate: string;
  contributed?: number;
  issuerId?: string;
  committed?: number;
  distributed?: number;
  marketValue?: number;
  tvpi?: number;
  numberOfProspects?: number;
  hasLiveDeal: boolean;
  entityId: string;
}

export interface ICreateHoldingForm {
  holding: IHolding;
}

export enum HarveyEnum {
  Quarter = 1,
  Half = 2,
  ThreeFourths = 3,
  Full = 4,
}

export interface FundPrice {
  price: number;
  referenceDate: string;
  level: HarveyEnum;
}
