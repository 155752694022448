import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
          fontFamily: "Inter, sans-serif",
          color: "var(--branding-light, #21272D)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05);",
          borderColor: "red",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter, sans-serif",
          fontSize: 14,
          color: "var(--branding-light, #737476)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05);",

          "&::placeholder": {
            opacity: 1,
            color: "var(--branding-light, #737476)",
            fontFamily: "Inter, sans-serif",
            fontSize: 14,
          },
        },
      },
    },
  };
}
