import styled from "styled-components";

enum BidCategory {
  Below = 1,
  Within = 2,
  Above = 3,
}

interface BidRankProps {
  lowerRange: number;
  higherRange: number;
  bidDelta: number;
  bidCategory: BidCategory;
}

const StyledBidRank = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0 auto;

  h2 {
    display: block;
    margin-bottom: 8px;
    color: var(--branding-dark, #737476);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.2px;
    user-select: none;
  }

  p {
    display: block;
    margin-bottom: 24px;
    color: black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
  }

  p:nth-of-type(2) {
    display: blox;
    margin-bottom: 24;
    color: black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  p:nth-of-type(2) span {
    color: #bcb3a5;
  }
`;

const StyledBar = styled.div<{ bidCategory: BidCategory }>`
  height: 24px;
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 24px 0px rgba(177, 166, 149, 0.35);
  position: relative;

  &::before {
    content: ${(props) =>
      props.bidCategory === BidCategory.Below
        ? '"Below Range"'
        : props.bidCategory === BidCategory.Within
          ? '"Within Range"'
          : '"Above Range"'};
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) =>
      props.bidCategory === BidCategory.Below
        ? "0"
        : props.bidCategory === BidCategory.Within
          ? "33.3%"
          : "66.6%"};
    width: 33.3%;
    border-radius: ${(props) =>
      props.bidCategory === BidCategory.Below
        ? "4px 0px 0px 4px"
        : props.bidCategory === BidCategory.Within
          ? 0
          : "0px 4px 4px 0px"};
    background-color: #bcb3a5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.1px;
  }
`;

export const BidRank = ({
  lowerRange,
  higherRange,
  bidDelta,
  bidCategory,
}: BidRankProps) => {
  const category =
    bidCategory === BidCategory.Below
      ? "Below"
      : bidCategory === BidCategory.Within
        ? "Within"
        : "Above";
  return (
    <StyledBidRank>
      <h2>Average Bid Range</h2>
      <p>
        {lowerRange}% - {higherRange}%
      </p>
      <p>
        Your Bid is{" "}
        <span>{`${
          bidDelta !== null ? `${Math.abs(bidDelta).toFixed(1)}%` : ""
        } ${category}`}</span>{" "}
        The Average Bid Range
      </p>
      <StyledBar bidCategory={bidCategory} />
    </StyledBidRank>
  );
};
