import { escapeRegExp } from "lodash";

const Highlighted = ({ text = "", highlight = "" }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark
              key={i}
              className="bg-nomad-100 text-nomad-900 rounded-md px-0 py-0.5"
            >
              <u>
                <b>{part}</b>
              </u>
            </mark>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
    </span>
  );
};
export default Highlighted;
