import { DeclineParticipation } from "experiences/transactions/domain/usecases/tenders/DeclineParticipation";
import {
  DownloadOfferDoc,
  ITenderDoc,
} from "experiences/transactions/domain/usecases/tenders/DownloadOfferDoc";
import { FinalizeParticipation } from "experiences/transactions/domain/usecases/tenders/FinalizeParticipation";
import {
  GetHoldingsProfile,
  IHoldingsProfile,
} from "experiences/transactions/domain/usecases/tenders/GetHoldingsProfile";
import {
  GetParticipationInterest,
  ITenderParticipationInterest,
} from "experiences/transactions/domain/usecases/tenders/GetParticipationInterest";
import {
  GetTenderMaterials,
  ITenderOfferMaterials,
} from "experiences/transactions/domain/usecases/tenders/GetTenderMaterials";
import { ListOfferDocs } from "experiences/transactions/domain/usecases/tenders/ListOfferDocs";
import {
  DataRoomScreenLoaded,
  DataRoomScreenLoading,
  FinalizeElectionFlowLoading,
  OfferScreenLoaded,
  OfferScreenLoading,
  OfferScreenShouldntLoad,
  OverviewScreenLoaded,
  OverviewScreenLoading,
  TaskStatus,
  TenderDetailsLoaded,
  TenderDetailsLoading,
  TendersState,
} from "./TendersState";
import { useState } from "react";
import { GetTransactionSummary } from "experiences/transactions/domain/usecases/GetTransactionSummary";
import {
  ITransactionSummary,
  TransactionStatus,
} from "experiences/transactions/domain/models/Transaction";
import { Failure } from "common/@types/app/Failure";
import { match } from "fp-ts/lib/Either";
import { ITenderOffer } from "experiences/transactions/domain/models/Tender";
import { ParticipantActionType } from "experiences/transactions/domain/models/Participant";
import {
  RedirectedToOfferScreen,
  RedirectedToTender,
  TendersEvent,
  TenderTaskClicked,
} from "./TendersEvent";

interface IUseCases {
  declineParticipation: DeclineParticipation;
  downloadOfferDoc: DownloadOfferDoc;
  finalizeParticipation: FinalizeParticipation;
  getHoldingsProfile: GetHoldingsProfile;
  listOfferDocs: ListOfferDocs;
  getParticipationInterest: GetParticipationInterest;
  getTenderMaterials: GetTenderMaterials;
  submitParticipationInterest: GetParticipationInterest;
  getTenderSummary: GetTransactionSummary;
}

export const useManageTendersState = ({
  declineParticipation,
  downloadOfferDoc,
  finalizeParticipation,
  getHoldingsProfile,
  listOfferDocs,
  getParticipationInterest,
  getTenderMaterials,
  submitParticipationInterest,
  getTenderSummary,
}: IUseCases) => {
  const [tenderDetailsState, setTenderDetailsState] = useState<TendersState>(
    new TenderDetailsLoading(),
  );
  const [overviewScreenState, setOverviewScreenState] = useState<TendersState>(
    new OverviewScreenLoading(),
  );
  const [offerScreenState, setOfferScreenState] = useState<TendersState>(
    new OfferScreenLoading(),
  );
  const [dataRoomScreenState, setDataRoomScreenState] = useState<TendersState>(
    new DataRoomScreenLoading(),
  );
  const [finalizeElectionFlowState, setFinalizeElectionFlowState] =
    useState<TendersState>(new FinalizeElectionFlowLoading());

  const [indicateInterestFlowState, setIndicateInterestFlowState] =
    useState<TendersState>(new FinalizeElectionFlowLoading());

  const [transactionSummary, setTransactionSummary] =
    useState<ITransactionSummary>({} as ITransactionSummary);

  const setTenderDetailsStateBasedOnTxnSummary = (
    summary: ITransactionSummary,
  ) => {
    const tender = summary.transaction as ITenderOffer;
    const txnActionsByType = summary.viewerParticipant.txnActions.map(
      (action) => action.type,
    );
    const hasAlreadyIndicatedInterest = txnActionsByType.includes(
      ParticipantActionType.InterestIndicated,
    );
    const hasAlreadyReviewedOffer = txnActionsByType.includes(
      ParticipantActionType.OfferReviewed,
    );
    const hasAlreadyFinalizedParticipation = txnActionsByType.includes(
      ParticipantActionType.FinalizedParticipation,
    );
    const hasAlreadySignedOfferLetter = txnActionsByType.includes(
      ParticipantActionType.SingedOfferLetter,
    );

    setTenderDetailsState(
      new TenderDetailsLoaded({
        fundLogoUrl: "https://via.placeholder.com/150",
        transactionName: tender.name,
        closingDate: tender.closingDate,
        recrurring: tender.recurring,
      }),
    );

    setDataRoomScreenState(
      new DataRoomScreenLoaded({ dataRoom: summary.dataroom }),
    );

    if (tender.currentStatus == TransactionStatus.PreliminaryInterest) {
      setOverviewScreenState(
        new OverviewScreenLoaded({
          transactionName: tender.name,
          currentTenderStatus: tender.currentStatus,
          periods: tender.periods,
          viewOfferStatus: hasAlreadyReviewedOffer
            ? TaskStatus.Completed
            : TaskStatus.NotStarted,
          indicateInterestStatus: hasAlreadyIndicatedInterest
            ? TaskStatus.Completed
            : TaskStatus.NotStarted,
          finalizeParticipationStatus: TaskStatus.DontShow,
          signOfferLetterStatus: TaskStatus.DontShow,
        }),
      );
      setOfferScreenState(new OfferScreenShouldntLoad());
    } else if (tender.currentStatus == TransactionStatus.Participation) {
      setOverviewScreenState(
        new OverviewScreenLoaded({
          transactionName: tender.name,
          currentTenderStatus: tender.currentStatus,
          periods: tender.periods,
          viewOfferStatus: hasAlreadyReviewedOffer
            ? TaskStatus.Completed
            : TaskStatus.NotStarted,
          indicateInterestStatus: hasAlreadyIndicatedInterest
            ? TaskStatus.Completed
            : TaskStatus.NotStarted,
          finalizeParticipationStatus: hasAlreadyFinalizedParticipation
            ? TaskStatus.Completed
            : TaskStatus.NotStarted,
          signOfferLetterStatus: hasAlreadySignedOfferLetter
            ? TaskStatus.Completed
            : TaskStatus.NotStarted,
        }),
      );
    }
  };

  const refreshSummary = (txnId: string) => {
    getTenderSummary.call({ txnId }).then((resp) => {
      match<Failure, ITransactionSummary, void>(
        (_: Failure) => {
          // TODO: failed to fetch summary
        },
        (txnSummary: ITransactionSummary) => {
          setTransactionSummary(txnSummary);
          setTenderDetailsStateBasedOnTxnSummary(txnSummary);
        },
      )(resp);
    });
  };

  const getOfferMaterials = (txnId: string) => {
    getTenderMaterials.call({ txnId }).then((resp) => {
      match<Failure, ITenderOfferMaterials, void>(
        (_: Failure) => {},
        (materials: ITenderOfferMaterials) => {
          listOfferDocs.call({ txnId }).then((resp) => {
            match<Failure, ITenderDoc[], void>(
              (_: Failure) => {
                // TODO: failed to fetch docs
              },
              (docs: ITenderDoc[]) => {
                const txnActionsByType =
                  transactionSummary.viewerParticipant.txnActions.map(
                    (action) => action.type,
                  );
                const hasAlreadyFinalizedParticipation =
                  txnActionsByType.includes(
                    ParticipantActionType.FinalizedParticipation,
                  );
                const hasAlreadySignedOfferLetter = txnActionsByType.includes(
                  ParticipantActionType.SingedOfferLetter,
                );
                setOfferScreenState(
                  new OfferScreenLoaded({
                    totalCapacity: materials.capacity,
                    closeDate: materials.closingDate,
                    offerPrice: materials.offerPrice,
                    summary: materials.summary,
                    docs: docs,
                    finalizeParticipationStatus:
                      hasAlreadyFinalizedParticipation
                        ? TaskStatus.Completed
                        : TaskStatus.NotStarted,
                    signOfferLetterStatus: hasAlreadySignedOfferLetter
                      ? TaskStatus.Completed
                      : TaskStatus.NotStarted,
                  }),
                );
              },
            )(resp);
          });
        },
      )(resp);
    });
  };

  const getFinalizeElectionDetails = (txnId: string) => {
    getHoldingsProfile.call({ txnId }).then((resp) => {
      match<Failure, IHoldingsProfile, void>(
        (_: Failure) => {
          // TODO: toast something about failure of retrieval of holdings qualified for this tender
        },
        (holdingsProfile: IHoldingsProfile) => {
          getParticipationInterest.call({ txnId }).then((resp) => {
            match<Failure, ITenderParticipationInterest, void>(
              (_: Failure) => {
                // TODO: toast something about failure of retrieval of tender interest
              },
              (interest: ITenderParticipationInterest) => {
                // setFinalizeElectionFlowState();
                // new FinalizeElectionFlowLoaded({
                //   holdingsProfile: holdingsProfile,
                //   currentStep: FinalizeElectionFlowStep.IndicateInterest,
                //   tenderAmount: interest.tenderAmount,
                //   offerPrice: ,
                //   targetCloseDate: transactionSummary.transaction.closingDate,
                //   referenceDate: transactionSummary.transaction.referenceDate,
                //   holdingFundName:
                //     holdingsProfile.holdingIssuersNameList.join(", "),
                // }),
              },
            )(resp);
          });
        },
      )(resp);
    });
  };

  // event emitter
  let prevEvent: TendersEvent;
  const emitEvent = (event: TendersEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof RedirectedToTender) {
      refreshSummary(event.txnId);
    } else if (event instanceof TenderTaskClicked) {
      console.log(event);
    } else if (event instanceof RedirectedToOfferScreen) {
      getOfferMaterials(event.txnId);
    }

    prevEvent = event;
  };

  return {
    tenderDetailsState,
    overviewScreenState,
    offerScreenState,
    dataRoomScreenState,
    finalizeElectionFlowState,
    indicateInterestFlowState,
    emitEvent,
  };
};
