import React from "react";
import styled from "styled-components";

import { CircleNotch, Lock, Plus } from "@phosphor-icons/react";
import classNames from "classnames";

export enum BUTTON_TYPES {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

const StyledButton = styled.button<{
  iconRight?: boolean;
  hasChildren?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: fit-content;

  margin: 0;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  text-decoration: none !important;

  cursor: pointer;
  transition:
    background-color 70ms ease-in-out,
    box-shadow 70ms ease-in-out,
    border 70ms ease-in-out;
  user-select: none;
  white-space: nowrap;

  & > svg {
    ${(props) => (props.iconRight ? "margin-left" : "margin-right")}: 8px;
    ${(props) =>
      !props.hasChildren &&
      "margin-left: 0!important; margin-right: 0!important;"};
    display: inline-block;
  }

  &.full_width {
    width: 100%;
  }

  /* sizes */
  &.small {
    font-size: 10px;
    line-height: 100%;
    padding: 7px 12px;
    border-radius: 6px;
  }

  &.medium {
    font-size: 13px;
    line-height: 100%;
    padding: 7px 12px;
    border-radius: 6px;
  }

  &.medium_large {
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    padding: 11px 16px;
    border-radius: 6px;
  }

  &.large {
    font-size: 15px;
    font-weight: 600;
    line-height: 100%;
    padding: 15px 18px;
    border-radius: 6px;
  }

  /* types */
  &.primary {
    background-color: #ffffff;
    color: #21272d;
    border: 1px solid #dfdfd9;

    &:disabled {
      background-color: #ffffff;
      color: #868a8e;
      pointer-events: none;
    }
    &:hover {
      background-color: #f5f4f1;
    }
    &:active {
      border: 1px solid #cecec7;
    }
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px rgba(188, 179, 165, 0.25);
    }
  }

  &.secondary {
    background-color: #21272d;
    color: #ffffff;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #8d8f93;
    }

    &:disabled {
      background-color: #696a6c;
      color: #ffffff;
      cursor: not-allowed;
    }

    &:active {
      border: 1px solid #cecec7;
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px rgba(188, 179, 165, 0.25);
    }
  }

  & + & {
    margin-left: 8px;
  }

  .secondary_text {
    color: #21272d;
    opacity: 0.4;
    cursor: inherit;
    margin-left: 8px;
  }
`;

export const Button = ({
  children,
  onClick,
  href,
  type = BUTTON_TYPES.PRIMARY,
  disabled,
  icon,
  iconRight,
  fullWidth,
  size = "medium",
  style,
  as,
  target,
  htmlType = "button",
  rel,
  secondaryText,
  className,
  download,
  role,
  loading,
}: {
  children?: React.ReactNode;
  onClick?: (e?: React.MouseEvent) => void;
  href?: string;
  type?: BUTTON_TYPES;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconRight?: boolean;
  fullWidth?: boolean;
  size?: "small" | "medium" | "medium_large" | "large";
  style?: React.CSSProperties;
  as?: React.ElementType;
  target?: string;
  htmlType?: "button" | "submit" | "reset";
  rel?: string;
  secondaryText?: string;
  className?: string;
  download?: boolean;
  role?: string;
  ["aria-expanded"]?: boolean;
  loading?: boolean;
}) => {
  const iconElement = loading ? (
    <CircleNotch size={16} className="animate-spin" />
  ) : (
    icon
  );

  return (
    <StyledButton
      onClick={onClick}
      href={href}
      className={classNames(type, size, className, {
        full_width: fullWidth,
      })}
      disabled={disabled}
      style={style}
      as={as}
      target={target}
      type={htmlType}
      iconRight={iconRight}
      rel={rel}
      download={download}
      role={role}
      hasChildren={!!children}
    >
      {iconRight ? children : null}
      {iconElement}
      {iconRight ? null : children}
      {secondaryText ? (
        <span className="secondary_text">{secondaryText}</span>
      ) : null}
    </StyledButton>
  );
};

export const PlusIcon = () => <Plus size={16} />;

export const LockIcon = () => <Lock size={16} />;
