import * as yup from "yup";
import { useFormik } from "formik";
import { Box, Modal, Stack } from "@mui/material";

import { useAuthContext } from "../state/AuthenticationContext";
import { ImpersonateUserFormSubmitted } from "../state/AuthenticationEvent";
import { BUTTON_TYPES, Button } from "common/components/Button";
import FormikTextField from "common/components/formik/FormikTextField";
import {
  TapUiModalContent,
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import UserSelector from "./UserSelector";
import useLocalStorage from "common/hooks/useLocalStorage";
import { AUTH_KEYS } from "common/constants/localStorage";
import { Fingerprint } from "@phosphor-icons/react";

interface ImpersonationDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const ImpersonationDialog = ({
  open,
  handleClose,
}: ImpersonationDialogProps) => {
  const { emitEvent } = useAuthContext();
  const [, setImpersonationState] = useLocalStorage(
    AUTH_KEYS.IMPERSONATION,
    "false",
  );

  const validationSchema = yup.object({
    email: yup.string().required("Required"),
    reason: yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      reason: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      emitEvent!(
        new ImpersonateUserFormSubmitted({
          userId: values.email,
          reason: values.reason,
        }),
      );
      setImpersonationState("true");
      window.location.reload();
    },
  });

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <TapUiVerticalModalWrapper className="max-w-md">
          <TapUiModalHead
            title="Impersonate User"
            subtitle="Please enter the email address of the user you wish 
              to impersonate and provide a brief note explaining the reason 
              for this action."
            onCloseClick={handleClose}
          />
          <TapUiModalContent>
            <UserSelector formik={formik} label="Email" name="email" />
            <FormikTextField
              formik={formik}
              label="Reason"
              name="reason"
              fullWidth
            />
            <Button
              onClick={formik.submitForm}
              fullWidth
              type={BUTTON_TYPES.SECONDARY}
              size="large"
              icon={<Fingerprint />}
            >
              Impersonate
            </Button>
          </TapUiModalContent>
        </TapUiVerticalModalWrapper>
      </form>
    </Modal>
  );
};
