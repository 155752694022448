import {
  ITransactionPeriod,
  TransactionStatus,
} from "experiences/transactions/domain/models/Transaction";
import { TransactionsState } from "../TransactionsState";
import { IDataRoom } from "experiences/transactions/domain/models/DataRoom";
import { ITenderDoc } from "experiences/transactions/domain/usecases/tenders/DownloadOfferDoc";
import { IHoldingsProfile } from "experiences/transactions/domain/usecases/tenders/GetHoldingsProfile";

export class TendersState extends TransactionsState {}

// Loaded classes
export class TenderLoaded extends TendersState {}
export class TenderLoading extends TendersState {}

export class TenderDetailsLoading extends TenderLoading {}
export class TenderDetailsLoaded extends TenderLoaded {
  fundLogoUrl: string;
  transactionName: string;
  closingDate: string;
  recrurring: boolean;

  constructor({ fundLogoUrl, transactionName, closingDate, recrurring }) {
    super();
    this.fundLogoUrl = fundLogoUrl;
    this.transactionName = transactionName;
    this.closingDate = closingDate;
    this.recrurring = recrurring;
  }
}

////// LP perspective
export class TenderForLP extends TenderLoaded {}
export class OverviewScreenLoading extends TenderLoading {}
export class OverviewScreenLoaded extends TenderForLP {
  transactionName: string;
  currentTenderStatus: TransactionStatus;
  periods?: ITransactionPeriod[];
  viewOfferStatus: TaskStatus;
  indicateInterestStatus: TaskStatus;
  finalizeParticipationStatus: TaskStatus;
  signOfferLetterStatus: TaskStatus;

  constructor({
    transactionName,
    currentTenderStatus,
    periods,
    viewOfferStatus,
    indicateInterestStatus,
    finalizeParticipationStatus,
    signOfferLetterStatus,
  }) {
    super();
    this.transactionName = transactionName;
    this.currentTenderStatus = currentTenderStatus;
    this.periods = periods;
    this.viewOfferStatus = viewOfferStatus;
    this.indicateInterestStatus = indicateInterestStatus;
    this.finalizeParticipationStatus = finalizeParticipationStatus;
    this.signOfferLetterStatus = signOfferLetterStatus;
  }
}

export enum TaskStatus {
  DontShow = 1,
  NotStarted = 2,
  Completed = 3,
}

export class OfferScreenLoading extends TenderLoading {}
export class OfferScreenLoaded extends TenderForLP {
  offerPrice: number;
  totalCapacity: number;
  closeDate: string;
  summary: Object;
  docs: ITenderDoc[];
  finalizeParticipationStatus: TaskStatus;
  signOfferLetterStatus: TaskStatus;

  constructor({
    offerPrice,
    totalCapacity,
    closeDate,
    summary,
    docs,
    finalizeParticipationStatus,
    signOfferLetterStatus,
  }) {
    super();
    this.offerPrice = offerPrice;
    this.totalCapacity = totalCapacity;
    this.closeDate = closeDate;
    this.summary = summary;
    this.docs = docs;
    this.finalizeParticipationStatus = finalizeParticipationStatus;
    this.signOfferLetterStatus = signOfferLetterStatus;
  }
}
export class OfferScreenShouldntLoad extends TenderForLP {}

export class IndicateInterestFlowState {
  offerPrice?: number;
  tenderAmount?: number;

  constructor({ offerPrice, tenderAmount }) {
    this.offerPrice = offerPrice;
    this.tenderAmount = tenderAmount;
  }
}

export class FinalizeElectionFlowLoading extends TenderLoading {}

export enum FinalizeElectionFlowStep {
  IndicateInterest = 1,
  ReviewOffer = 2,
}
export class FinalizeElectionFlowLoaded extends TenderForLP {
  currentStep: FinalizeElectionFlowStep;
  offerPrice?: number;
  tenderAmount?: number;
  holdingFundName: string;
  holdingsProfile: IHoldingsProfile;
  targetCloseDate: string;
  referenceDate: string;

  constructor({
    currentStep,
    offerPrice,
    tenderAmount,
    holdingFundName,
    holdingsProfile,
    targetCloseDate,
    referenceDate,
  }) {
    super();
    this.currentStep = currentStep;
    this.offerPrice = offerPrice;
    this.tenderAmount = tenderAmount;
    this.holdingsProfile = holdingsProfile;
    this.holdingFundName = holdingFundName;
    this.targetCloseDate = targetCloseDate;
    this.referenceDate = referenceDate;
  }
}

export class OfferLetterSignatureScreenLoaded extends TenderForLP {
  iframeUrl: string;

  constructor({ iframeUrl }) {
    super();
    this.iframeUrl = iframeUrl;
  }
}

export class InviteParticipantOnOfferLetterFormSubmitted extends TenderForLP {
  email: string;
  firstName: string;
  lastName: string;
  participantCapacity: number;
  organizationId: string;
  canSignOfferLetter: boolean;

  constructor({ email }) {
    super();
    this.email = email;
  }
}

export class DataRoomScreenLoading extends TenderForLP {}
export class DataRoomScreenLoaded extends TenderForLP {
  dataRoom: IDataRoom;

  constructor({ dataRoom }) {
    super();
    this.dataRoom = dataRoom;
  }
}

////// GP perspective
export class TenderForGP extends TenderLoaded {}

////// Buyer perspective
export class TenderForBuyers extends TenderLoaded {}
export class Round1Period extends TenderForBuyers {}
export class Round2Period extends TenderForBuyers {}
