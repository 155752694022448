import { Stack, Typography } from "@mui/material";

import { AppLayoutWithTopMenu } from "../../../../common/AppLayoutWithTopMenu";
import { BidDetailsTable } from "../bid-acceptance/BidDetailsTable";
import { BidBreakDownTable } from "../bid-acceptance/BidBreakdowntable";
import {
  Bid,
  PartialBid,
} from "experiences/transactions/domain/models/Bidding";
import { BUTTON_TYPES, Button } from "common/components/Button";

interface IProps {
  bid: Bid;
  onSubmit: () => void;
  onClose: () => void;
}

const AcceptBidScreen: React.FC<IProps> = ({ bid, onSubmit, onClose }) => {
  return (
    <AppLayoutWithTopMenu centeredContent onClose={onClose}>
      <Stack direction="column" width="100%" spacing={4}>
        <Stack
          direction="column"
          width="100%"
          justifyContent="space-between"
          px={30}
          spacing={4}
        >
          <Stack>
            <Typography variant="h3">Advance to Settlement</Typography>
            <Typography variant="body1">
              {`Review the bid from ${bid.bidderOrgName!}. You can move this bid
              into the Settlement process to handle the legal, compliance and
              closing aspects of the transaction.`}
            </Typography>
          </Stack>
          <BidDetailsTable bid={bid} />
          {bid instanceof PartialBid && <BidBreakDownTable bid={bid} />}
          <Button
            fullWidth
            size="large"
            onClick={onSubmit}
            type={BUTTON_TYPES.SECONDARY}
          >
            Advance To Settlement
          </Button>
          <br />
          <br />
        </Stack>
      </Stack>
    </AppLayoutWithTopMenu>
  );
};

export default AcceptBidScreen;
