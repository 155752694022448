import { MaskHappy } from "@phosphor-icons/react";

import { Button } from "common/components/Button";

export const ImpersonationButton = ({
  openImpersonation,
}: {
  openImpersonation: () => void;
}) => (
  <div className="pr-10 place-self-center">
    <Button onClick={openImpersonation} icon={<MaskHappy />} iconRight>
      Impersonate
    </Button>
  </div>
);
