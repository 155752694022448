import { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { match } from "fp-ts/lib/Either";

import { Button } from "common/components/Button";
import {
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import { SearchInput } from "common/components/SearchInput";
import { Failure } from "common/@types/app/Failure";
import { RetrieveFilteredFunds } from "experiences/funds/domain/usecases/RetrieveFilteredFunds";
import { FundsPage } from "experiences/funds/domain/models/FundsPage";
import { Fund } from "experiences/funds/domain/models/Fund";
import { FundStrategy } from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { IssuerTypeLabel } from "common/@types/models/Issuer";
import { EmptyState } from "common/components/EmptyState";
import { DashboardList } from "experiences/common/List";
import { cn } from "common/utils";
import { useStateAndRef } from "common/utils/useStateAndRef";

const StyledModalSearchbox = styled.div`
  align-self: center;
  justify-self: flex-end;
  padding-right: 40px;
  box-sizing: border-box;
`;

// This looks like a text field but it's more like a button
export const ModalSearchbox = ({
  handleSearchClick,
}: {
  handleSearchClick: () => void;
}) => {
  const macOrPc = window.navigator.platform.includes("Mac") ? "⌘" : "Ctrl";

  return (
    <StyledModalSearchbox>
      <Button
        onClick={handleSearchClick}
        icon={<MagnifyingGlass />}
        style={{ width: 220, justifyContent: "flex-start" }}
        secondaryText={`${macOrPc} + K`}
      >
        Search
      </Button>
    </StyledModalSearchbox>
  );
};

// Note: I (Julio) did not add tabs because we're doing fund-only search for now, tabs will come once we add other types of search
export const GlobalSearchModal = ({
  open,
  handleClose,
  handleGoToFundDetail,
}: {
  open: boolean;
  handleClose: () => void;
  handleGoToFundDetail: (fundId: string) => void;
}) => {
  const [search, setSearch] = useState("");
  const [funds, setFunds, fundsRef] = useStateAndRef<Fund[]>([]);
  const [focusedFundIndex, setFocusedFundIndex, focusedFundIndexRef] =
    useStateAndRef<number>(-1);
  const searchRef = useRef<HTMLInputElement>(null);
  const innerOpen = useRef(open);

  const refreshIssuers = () => {
    new RetrieveFilteredFunds()
      .call({
        paginationSpecs: { perPage: 10, pageIndex: 1 },
        filters: { searchTerm: search },
      })
      .then((resp) => {
        match<Failure, FundsPage, void>(
          (_: Failure) => {},
          (page: FundsPage) => {
            setFunds(() => page.funds);
            setFocusedFundIndex(-1);
          },
        )(resp);
      });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const innerHandleGoToFundDetail = (fund: Fund) => {
    handleGoToFundDetail(fund.id);
    setSearch("");
    handleClose();
  };

  useEffect(() => {
    refreshIssuers();
  }, [search]);

  const handleArrowDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setFocusedFundIndex((prev) => {
      // if (prev < funds.length - 1) {
      return prev + 1;
      // }
      // return prev;
    });
  };

  const handleArrowUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setFocusedFundIndex((prev) => {
      // if (prev > 0) {
      return prev - 1;
      // }
      // return prev;
    });
  };

  const keyHandler = (e) => {
    if (!innerOpen.current) return;

    if (e.key === "ArrowDown") {
      handleArrowDown(e);
    }
    if (e.key === "ArrowUp") {
      handleArrowUp(e);
    }
    // for enter, go to the focused fund
    if (e.key === "Enter") {
      innerHandleGoToFundDetail(fundsRef.current[focusedFundIndexRef.current]);
      setFocusedFundIndex(-1);
    }
  };

  useEffect(() => {
    if (open) {
      searchRef.current?.focus();
      setFocusedFundIndex(-1);
    }
    innerOpen.current = open;
  }, [open]);

  useEffect(() => {
    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, []);

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className=""
    >
      <TapUiVerticalModalWrapper className="w-full max-w-screen-lg mx-6">
        <TapUiModalHead title="Search" onCloseClick={handleClose} />

        <SearchInput
          value={search}
          placeholder="Search by fund or manager name"
          onChange={handleSearchChange}
          ref={searchRef}
        />
        <DashboardList
          table={
            <table className="table dashboard_table">
              <tbody>
                {funds.map((fund, index) => {
                  return (
                    <tr
                      onClick={() => innerHandleGoToFundDetail(fund)}
                      tabIndex={0}
                      key={fund.id}
                      className={cn("cursor-pointer", `index-${index}`, {
                        "bg-nomad-100 hover:!bg-nomad-100":
                          focusedFundIndexRef.current === index,
                      })}
                    >
                      <td
                        style={{ width: "300px", maxWidth: "300px" }}
                        title={fund.name}
                        className="overflow-hidden overflow-ellipsis"
                      >
                        {fund.name}

                        <TextChip
                          text={IssuerTypeLabel[fund.type]}
                          className="ml-2"
                        />
                      </td>
                      <td>{fund.vintage}</td>
                      <td>{fund.managerName}</td>
                      <td style={{ textAlign: "right" }}>
                        <FundStrategy fund={fund} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
        />
        {funds.length === 0 ? (
          <EmptyState
            title="No funds found"
            description="Try another search term"
          />
        ) : null}
      </TapUiVerticalModalWrapper>
    </Modal>
  );
};
