export class DashboardEvent {}

export class RedirectedToDashboardScreen extends DashboardEvent {
  public readonly isDemo: boolean;

  constructor({ isDemo }: { isDemo?: boolean } = {}) {
    super();
    this.isDemo = isDemo;
  }
}

export class IndexesRequested extends DashboardEvent {}

export class ActiveRecentIoiListRequested extends DashboardEvent {}
