import { useEffect, useState } from "react";

import {
  GpTransactionDataroomInvestment,
  gpTransactionDetailDataroomInvestmentsMock,
} from "./mock";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";

export const GpTransactionDetailDataroomInvestmentsTable = () => {
  const [data, setData] = useState<GpTransactionDataroomInvestment[]>([]);
  const isLoading = false;

  useEffect(() => {
    setData(gpTransactionDetailDataroomInvestmentsMock);
  }, []);

  return (
    <>
      <TapTable
        isLoading={isLoading}
        data={data}
        emptyStateTitle={"..."}
        emptyStateDescription={"..."}
        skipTopBorder
        columns={[
          {
            key: "",
            label: "Company",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Type",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Ownership",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Date",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Cost",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Book Value",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Unrealized P/L",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Realized P/L",
            align: TableAlignment.RIGHT,
          },
        ]}
        renderRow={(investment: GpTransactionDataroomInvestment) => {
          const onRowClick = (investment: GpTransactionDataroomInvestment) => {
            console.log("clicked", investment);
          };

          return (
            <>
              <TableCell
                isPrimaryColumn
                alignTop
                hasBullet
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
              >
                {investment.company}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
              >
                {investment.type}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
                className="text-right"
              >
                {investment.ownership}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
              >
                {investment.date}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
                className="text-right"
              >
                {investment.cost}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
                className="text-right"
              >
                {investment.bookValue}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
                className="text-right"
              >
                {investment.unrealizedPL}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(investment)}
                className="text-right"
              >
                {investment.realizedPL}
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
