import { FundDataVerifyPermissionOverlay } from "experiences/dashboard/presentation/components/VerifyPermissionOverlay";
import { TapTable } from "experiences/funds/presentation/components/Table";
import {
  fundExposureSummaryGqlMock,
  fundExposureSummaryMock,
} from "../mock/FundExposureSummaryMock";
import { renderSummaryRow } from "../FundExposureSummaryTable";

// This is rendering a blurred fund exposure summary table as a background for the VerifyPermissionOverlay
// Data is fetched from the mock file, so it's not real data
export const MissingDocumentsState = ({ txnId }: { txnId: string }) => {
  return (
    <div className="relative overflow-x-hidden w-full max-w-full min-h-96">
      <FundDataVerifyPermissionOverlay txnId={txnId} />
      <TapTable
        skipBottomBorder
        skipTopBorder
        blurred
        columns={[]}
        renderRow={() =>
          renderSummaryRow({
            fund: fundExposureSummaryGqlMock.fundSummaryExposure[0],
            logoIds: [],
          })
        }
        data={fundExposureSummaryMock.fundSummaryExposure}
        className="absolute top-0 left-0 w-full h-full"
      />
    </div>
  );
};
