import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "./StyledTableCell";
import { useState } from "react";

export interface IHeaderCellElement {
  label: string;
  key: string;
  align?: "left" | "right" | "center";
  width: string | number;
}

export interface ITableLayoutProps {
  headerElems: IHeaderCellElement[];
  rows: React.ReactNode;
  withLeadingEmptyColumn?: boolean;
  withTailingEmptyColumn?: boolean;
  minWidth?: string;
  borderless?: boolean;
  noHeading?: boolean;
  headerStyles?: {};
  containerProps?: {};
  leadingColumnChild?: React.ReactNode;
}

export const TableLayout: React.FC<ITableLayoutProps> = ({
  headerElems,
  rows,
  withLeadingEmptyColumn = false,
  withTailingEmptyColumn = false,
  minWidth = "900px",
  borderless = false,
  noHeading = false,
  headerStyles,
  containerProps,
  leadingColumnChild,
}) => {
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        border: borderless ? "none" : "1px solid #DBDCDA",
      }}
      {...containerProps}
    >
      <TableContainer>
        <Table
          sx={{ minWidth: minWidth, tableLayout: "auto" }}
          aria-labelledby="tableTitle"
          padding={"none"}
          stickyHeader
        >
          {!noHeading && (
            <TableHead>
              <TableRow>
                {withLeadingEmptyColumn && (
                  <StyledTableCell sx={{ width: "5%" }} />
                )}
                {!withLeadingEmptyColumn && leadingColumnChild && (
                  <TableCell sx={{ width: "5%" }}>
                    {leadingColumnChild}
                  </TableCell>
                )}
                {headerElems.map((element) => (
                  <StyledTableCell
                    sx={{ width: element.width }}
                    align={element.align}
                    key={element.key + "-header"}
                  >
                    <Box {...headerStyles}>{element.label.toUpperCase()}</Box>
                  </StyledTableCell>
                ))}
                {withTailingEmptyColumn && <StyledTableCell />}
              </TableRow>
            </TableHead>
          )}
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
