import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { ITxnMonitorElectionLP, txnMonitorElectingLPs } from "../../mock/GP";
import { SideComparison } from "../SideComparison";
import { useEffect, useState } from "react";
import TextChip from "experiences/common/TextChip";
import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";

export const ParticipationAmountTable = () => {
  const [data, setData] = useState<ITxnMonitorElectionLP[]>([]);
  const isLoading = false;

  useEffect(() => {
    setData(txnMonitorElectingLPs);
  }, []);

  const lpsElecting = 11;
  const electingAmount = "$405.3M";

  return (
    <>
      <SideComparison
        skipBorderTop
        biggerHeight
        items={[
          {
            title: "LPs Participating",
            value: String(lpsElecting),
          },
          {
            title: "Participation Amount",
            value: electingAmount,
          },
        ]}
      />

      <TapTable
        isLoading={isLoading}
        data={data}
        emptyStateTitle={"..."}
        emptyStateDescription={"..."}
        skipTopBorder
        columns={[
          {
            key: "",
            label: "Limited Partner",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Participating",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "CAV",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Participation Amount (%)",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Participation Amount ($)",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Proceeds",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "PSA",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "TA",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(lp: ITxnMonitorElectionLP) => {
          const onRowClick = (m: ITxnMonitorElectionLP) => {
            console.log("team member clicked", m);
          };

          return (
            <>
              <TableCell
                isPrimaryColumn
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                <StyledFundNameAndImage>
                  <img
                    src={`https://cdn.usetap.com/funds/logos/${lp.logoFileName}`}
                    alt="Fund logo"
                  />{" "}
                  {lp.lpName}
                </StyledFundNameAndImage>
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                <TextChip text={lp.electing} success={lp.electing === "YES"} />
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
                className="text-right"
              >
                {lp.cav}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                {lp.electionPercent}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
                className="text-right"
              >
                {lp.electionDollar}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
                className="text-right"
              >
                {lp.proceeds}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                {lp.pas !== "-" ? (
                  <TextChip
                    text={lp.pas}
                    success={lp.pas === "Complete"}
                    pending={lp.pas === "In Progress"}
                  />
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                {lp.ta !== "-" ? (
                  <TextChip
                    text={lp.ta}
                    success={lp.ta === "Complete"}
                    pending={lp.ta === "In Progress"}
                  />
                ) : (
                  "-"
                )}
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
