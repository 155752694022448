import { useNavigate } from "react-router";
import styled from "styled-components";

import { LP_ROUTES } from "common/constants/routes";
import { DashboardBuyList } from "./DashboardBuyList";
import { Bid } from "experiences/indications-of-interest/domain/models/IOI";

export const DashboardRightColumnContent = styled.div`
  flex-basis: 30%;
  height: inherit;
  max-height: inherit;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DashboardRightColumn = ({
  bids,
  bidsLoaded,
}: {
  bids: Bid[];
  bidsLoaded: boolean;
}) => {
  const navigate = useNavigate();

  const handleBidsClick = () => {
    navigate(LP_ROUTES.IndicationsOfInterestBids);
  };

  return (
    <DashboardRightColumnContent>
      <DashboardBuyList
        handleBidsClick={handleBidsClick}
        bids={bids}
        bidsLoaded={bidsLoaded}
      />
    </DashboardRightColumnContent>
  );
};
