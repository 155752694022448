import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageHead } from "common/components/PageHead";
import { EmptyState } from "common/components/EmptyState";
import { useTransactionsContext } from "../state/TransactionsContext";
import { LoadTransactionAgreements } from "../state/TransactionsEvent";
import { TransactionAgreementsTable } from "../components/transaction-settings/TransactionAgreementsTable";
import { TransactionSettingsAgreementsLoaded } from "../state/TransactionsState";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";

export const TransactionSettingsAgreements = () => {
  const { transactionAgreementsState, emitEvent } = useTransactionsContext();

  useEffect(() => {
    emitEvent!(new LoadTransactionAgreements());
  }, []);

  const agreements =
    transactionAgreementsState instanceof TransactionSettingsAgreementsLoaded
      ? transactionAgreementsState.transactionAgreements
      : [];

  return (
    <>
      <Helmet title="Transfer Agreements" />
      <PageHead
        title="Transfer Agreements"
        countLabel={agreements.length}
        actions={<></>}
      />
      {transactionAgreementsState instanceof
      TransactionSettingsAgreementsLoaded ? (
        <TransactionAgreementsTable transactionAgreements={agreements} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
