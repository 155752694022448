import { createContext, useContext } from "react";

import { useManageApprovedBuyersState } from "./ApprovedBuyersManager";
import { ApprovedBuyersState } from "./ApprovedBuyersState";
import { GetApprovedBuyers } from "experiences/approved-buyers/domain/usecases/GetApprovedBuyers";
import { GetRequestedBuyers } from "experiences/approved-buyers/domain/usecases/GetRequestedBuyers";
import { GetBlockedBuyers } from "experiences/approved-buyers/domain/usecases/GetBlockedBuyers";
import { GetArchivedBuyers } from "experiences/approved-buyers/domain/usecases/GetArchivedBuyers";

export interface ApprovedBuyersContextProps {
  emitEvent?: (event: ApprovedBuyersState) => void;
  approvedBuyerTablePageState?: ApprovedBuyersState;
  requestedBuyerTablePageState?: ApprovedBuyersState;
  blockedBuyerTablePageState?: ApprovedBuyersState;
  archivedBuyerTablePageState?: ApprovedBuyersState;
}

const initialContextState: ApprovedBuyersContextProps = {};

const ApprovedBuyersContext = createContext(initialContextState);

interface IApprovedBuyersStateProviderProps {
  children: React.ReactNode;
}

const ApprovedBuyersStateProvider = ({
  children,
}: IApprovedBuyersStateProviderProps) => {
  const {
    emitEvent,
    approvedBuyerTablePageState,
    requestedBuyerTablePageState,
    blockedBuyerTablePageState,
    archivedBuyerTablePageState,
  } = useManageApprovedBuyersState({
    getApprovedBuyers: new GetApprovedBuyers(),
    getRequestedBuyers: new GetRequestedBuyers(),
    getBlockedBuyers: new GetBlockedBuyers(),
    getArchivedBuyers: new GetArchivedBuyers(),
  });
  return (
    <ApprovedBuyersContext.Provider
      value={{
        emitEvent,
        approvedBuyerTablePageState,
        requestedBuyerTablePageState,
        blockedBuyerTablePageState,
        archivedBuyerTablePageState,
      }}
    >
      {children}
    </ApprovedBuyersContext.Provider>
  );
};

export const useApprovedBuyersContext = () => useContext(ApprovedBuyersContext);

export { ApprovedBuyersStateProvider, ApprovedBuyersContext };
