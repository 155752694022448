import {
  TableAlignment,
  ITabTableColumn,
  TapTable,
  StyledTableRow,
} from "experiences/funds/presentation/components/Table";
import { TableCell } from "experiences/funds/presentation/components/Table";
import { TableFundLogo } from "common/components/TableFundLogo";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { FUND_LOGO_URL } from "common/constants/platform";
import { BarLoader } from "common/components/BarLoader";
import { ICompanyFundExposure } from "experiences/portfolio-v2/domain/usecases/GetCompanyFundExposureQuery";

// prettier-ignore
const columns: ITabTableColumn[] = [
  { label: "Fund", key: "fund", align: TableAlignment.LEFT, className: "min-w-64" },
  { label: "Fund Ownership  %", key: "fund_ownership_percent", align: TableAlignment.RIGHT },
  { label: "Hold Period", key: "hold_period", align: TableAlignment.LEFT },
  { label: "Total Cost", key: "total_cost", align: TableAlignment.RIGHT },
  { label: "Total Value", key: "total_value", align: TableAlignment.RIGHT },
  { label: "YoY Markup", key: "yoy_markup", align: TableAlignment.RIGHT },
  { label: "QoQ Markup", key: "qoq_markup", align: TableAlignment.RIGHT },
  { label: "Size", key: "size", align: TableAlignment.RIGHT },
  { label: "Remain. Cost", key: "remain_cost", align: TableAlignment.RIGHT },
];

export const FundsTable = ({
  funds,
  loading,
}: {
  funds: ICompanyFundExposure[];
  loading: boolean;
}) => {
  return (
    <div className="pt-4">
      <PageSectionTitleDivider showBorderTop skipBorderBottom>
        Funds
      </PageSectionTitleDivider>

      <TapTable
        data={funds}
        columns={columns}
        isLoading={loading}
        renderBody={() => (
          <>
            {funds.map((row) => {
              const imgSrc = `${FUND_LOGO_URL}/${row.tapFundId}`;
              return (
                <StyledTableRow>
                  <TableCell className="min-w-64">
                    <TableFundLogo imgSrc={imgSrc} />
                    {row.fundName}
                  </TableCell>
                  <TableCell className="text-right">
                    {row.portfolioPercent}
                  </TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                  <TableCell className="text-right">TODO: fill</TableCell>
                </StyledTableRow>
              );
            })}
          </>
        )}
        showEmptyState
        emptyStateTitle="No funds found"
        emptyStateDescription="No funds found holding this company"
      />
      {loading && funds.length === 0 ? <BarLoader /> : null}
    </div>
  );
};
