import { useQuery } from "@apollo/client";

import { transactionsClient } from "common/clients/ApolloClient";
import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";
import { useFundDataStatus } from "../useFundDataStatus";
import { IInterimCashflowsQuery } from "./model";
import { GET_TRANSACTION_INTERIM_CASHFLOWS } from "./query";

export const useInterimCashflows = ({
  txnId,
  currentReportDate,
}: {
  txnId: string;
  currentReportDate: string;
}) => {
  const { loading: loadingStatus, soiLookthroughStatus } = useFundDataStatus();

  let {
    data: interimCashflows,
    loading: interimCashflowsLoading,
    refetch: refetchInterimCashflows,
  } = useQuery<IInterimCashflowsQuery>(GET_TRANSACTION_INTERIM_CASHFLOWS, {
    client: transactionsClient,
    skip: !currentReportDate,
    variables: {
      transactionId: txnId,
      reportDate: currentReportDate,
    },
  });

  const formattedInterimCashflows =
    interimCashflows?.interimCashflows?.map((cashflow) => ({
      ...cashflow,
    })) || [];

  // Sort by fund name, then by date
  const sortedInterimCashflows = formattedInterimCashflows.sort(
    (a, b) =>
      Number.parseFloat(b.fund) - Number.parseFloat(a.fund) ||
      Number.parseFloat(b.wireDate) - Number.parseFloat(a.wireDate),
  );

  const awaitingUpload =
    TransactionDataSummarySectionStatus.NotYetUploaded === soiLookthroughStatus;
  const extractionInProgress =
    TransactionDataSummarySectionStatus.UploadedAndProcessing ===
    soiLookthroughStatus;
  const extractionReady =
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV ===
    soiLookthroughStatus;

  return {
    interimCashflows: sortedInterimCashflows,
    interimCashflowsLoading,
    refetchInterimCashflows,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
    loading: loadingStatus || interimCashflowsLoading,
  };
};
