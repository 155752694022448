import { InputAdornment, TextField as MuiTextField } from "@mui/material";

import CurrencyInput from "./masks/CurrencyInput";
import PercentageInput from "./masks/PercentageInput";
import PhoneNumberInput from "./masks/PhoneNumberInput";
import SocialSecurityMask from "./masks/SocialSecurityInput";
import NumbersOnlyInput from "./masks/NumbersOnlyInput";
import YearInput from "./masks/YearInput";
import ZipCodeInput from "./masks/ZipCodeInput";
import YearlyQuarterMask from "./masks/YearlyQuarterInput";

const TextField = ({ mask, ...props }: any) => {
  const inputProps = {
    ...props.InputProps,
  };

  if (mask === "currency") {
    inputProps.inputComponent = CurrencyInput;
    inputProps.startAdornment = (
      <InputAdornment position="start">$</InputAdornment>
    );
  } else if (mask === "phone") {
    inputProps.inputComponent = PhoneNumberInput;
  } else if (mask === "ssn") {
    inputProps.inputComponent = SocialSecurityMask;
  } else if (mask === "percentage") {
    inputProps.endAdornment = (
      <InputAdornment position="start">%</InputAdornment>
    );
    inputProps.inputComponent = PercentageInput;
  } else if (mask === "year") {
    inputProps.inputComponent = YearInput;
  } else if (mask === "quarter") {
    inputProps.inputComponent = YearlyQuarterMask;
  } else if (mask === "zipCode") {
    inputProps.inputComponent = ZipCodeInput;
  } else if (mask === "number") {
    inputProps.inputComponent = NumbersOnlyInput;
  }

  return (
    <MuiTextField
      variant="filled"
      fullWidth
      {...props}
      required={false}
      InputProps={{
        disableUnderline: true,
        ...inputProps,
      }}
    />
  );
};

export default TextField;
