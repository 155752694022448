import { Either, left, right } from "fp-ts/lib/Either";

import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import {
  FundSummary,
  FundSummaryIn,
  PermissionProfile,
} from "experiences/funds/domain/models/FundSummary";

const formatSummary = (summary: FundSummaryIn): FundSummary => {
  return {
    ...summary,
    permissionProfile: new PermissionProfile({
      permissions: summary.permissionProfile?.permissions,
    }),
    size: (summary.size && new DollarAmount(summary.size)) || undefined,
  };
};

export class RetrieveSingleFundSummary
  implements IAsyncUseCase<IParams, FundSummary>
{
  call: (params: IParams) => Promise<Either<Failure, FundSummary>> = async ({
    fundId,
  }) => {
    return await new TapClient(`/fund/${fundId}/summary/`)
      .get({})
      .then((resp: FundSummaryIn) => {
        if (!resp?.id) {
          return left(new Failure("Invalid fund"));
        }
        return right(formatSummary(resp));
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  fundId: string;
}
