export interface IReportDates {
  name: string;
  dates: string[];
}

export enum ReportDateName {
  CapitalAccounts = "capital_accounts_dates",
  DealBreakdown = "deal_breakdown_dates",
  FundExposureSummary = "fund_exposure_summary_dates",
  SoiLookthrough = "soi_lookthrough_dates",
  QualitativeData = "qualitative_data_dates",
  InterimCashflows = "interim_cashflows_dates",
}

// DOING THIS HERE FOR SPEED BUT MAY NEED TO GO UNDER OR CLOSER TO COMPANY DETAILS
export enum CompanyReportDateName {
  CompanyData = "company_data_dates",
  FundExposureSummary = "fund_exposure_summary_dates",
  QualitativeData = "qualitative_data_dates",
}

export const ReportDateNameToLabel: Record<ReportDateName, string> = {
  [ReportDateName.CapitalAccounts]: "Capital Accounts",
  [ReportDateName.DealBreakdown]: "Deal Breakdown",
  [ReportDateName.FundExposureSummary]: "Fund Exposure Summary",
  [ReportDateName.SoiLookthrough]: "SOI Lookthrough",
  [ReportDateName.QualitativeData]: "Qualitative Data",
  [ReportDateName.InterimCashflows]: "Interim Cashflows",
};
