import { cn } from "common/utils";
import styled from "styled-components";

export const APP_MENU_HEIGHT_PX = 56;

export const PageWrapper = styled.div`
  display: flex;
  height: calc(100vh - ${APP_MENU_HEIGHT_PX}px);
`;

export const PageSidebar = styled.div`
  flex-basis: 30%;
  height: inherit;
  max-height: inherit;
  overflow-y: scroll;
  border-right: 1px solid #d9d9d9;
`;

export const PageMainContent = styled.div`
  flex-basis: 70%;
  height: inherit;
  max-height: inherit;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const PageWrapperPortfolioV2 = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <PageWrapper
      className={cn("flex flex-col w-full overflow-x-hidden", className)}
    >
      {children}
    </PageWrapper>
  );
};
