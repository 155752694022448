import { useLocation, useNavigate } from "react-router";

import { Button } from "common/components/Button";
import { OverlayWithIcon } from "./OverlayWithIcon";
import { TransactionDetailsRoutes } from "experiences/transactions/presentation/components/TransactionDetails";
import { TransactionFundDataRoutes } from "experiences/transactions/presentation/components/fund-data/TransactionFundDataScreen";
import { cn } from "common/utils";
import { StyledLoader } from "common/components/BarLoader";

export const DataExtractionInProgress = ({
  className,
}: {
  className?: string;
}) => {
  const currentPath = useLocation();
  const navigate = useNavigate();

  const handleUploadMoreDocuments = () => {
    const basePath = currentPath.pathname.split(
      TransactionDetailsRoutes.FundData,
    )[0];
    const path = `${basePath}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.VerifyPermission}`;

    navigate(path);
  };

  return (
    <OverlayWithIcon
      className={cn("min-h-96", className)}
      title="Data Extraction In Progress"
      customIcon={<StyledLoader />}
      subtitle={
        <>
          Tap is currently extracting, calculating, and checking your data. You
          will receive an email when the data is ready to view.
        </>
      }
    >
      <Button onClick={handleUploadMoreDocuments}>Upload More Documents</Button>
    </OverlayWithIcon>
  );
};
