import { Either, left } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import authService from "../../../../common/services/authService";
import { ICurrentUser } from "../models/CurrentUser";
import { GetCurrentUser } from "./GetCurrentUser";
import { IInviteeRegForm } from "../models/RegForm";

export class OnboardInvitee implements IAsyncUseCase<IParams, ICurrentUser> {
  call: (params: IParams) => Promise<Either<Failure, ICurrentUser>> = async ({
    form,
  }) => {
    return await new TapClient(`/invitee/onboard`, form)
      .post()
      .then((res: any) => {
        // auth and then get the current user
        authService.handleAuthentication(res);
        return new GetCurrentUser().call({});
      })
      .catch((resp: any) => {
        return left(new Failure("whoopsie doodle"));
      });
  };
}

interface IParams {
  form: IInviteeRegForm;
}
