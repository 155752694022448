import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import { PageHead } from "common/components/PageHead";

import { ParticipantCapacity } from "../../../domain/models/Participant";
import { AddParticipantModal } from "./AddParticipantModal";
import { ParticipantsTabWrapper } from "./ParticipantsTabRouter";
import { SendDataRoomUpdateModal } from "./SendDataRoomUpdateModal";
import { useTxnParticipantsScreen } from "./useParticipants";

export const ParticipantsScreen = () => {
  const {
    showSendDataRoomUpdateModal,
    setShowSendDataRoomUpdateModal,
    showAddParticipantModal,
    selectedParticipants,
    onSendInviteToSelectedParticipantsClick,
    onSelectParticipants,
    onUnselectParticipants,
    currUserParticipant,
    userCanSendInvites,
    addParticipantsButtonVisible,
    showSendDataRoomUpdateButton,
    openAddParticipantModal,
    closeAddParticipantModal,
  } = useTxnParticipantsScreen();

  return (
    <>
      <PageHead
        title="Manage Participants"
        actions={
          <div className="flex flex-row gap-2">
            {showSendDataRoomUpdateButton && (
              <Button onClick={() => setShowSendDataRoomUpdateModal(true)}>
                Send Dataroom Update
              </Button>
            )}

            {addParticipantsButtonVisible && (
              <Button
                onClick={openAddParticipantModal}
                type={BUTTON_TYPES.SECONDARY}
                icon={<PlusIcon />}
              >
                {currUserParticipant &&
                currUserParticipant.capacity == ParticipantCapacity.Seller
                  ? "Add Participants"
                  : null}
                {currUserParticipant &&
                currUserParticipant.capacity == ParticipantCapacity.Buyer
                  ? "Add Team Members"
                  : null}
              </Button>
            )}
          </div>
        }
      />
      <ParticipantsTabWrapper
        selectParticipants={onSelectParticipants}
        unselectParticipants={onUnselectParticipants}
        selectedParticipants={selectedParticipants}
        handleAddParticipant={openAddParticipantModal}
        handleSendInvite={onSendInviteToSelectedParticipantsClick}
        userCanSendInvites={userCanSendInvites}
      />
      <AddParticipantModal
        open={showAddParticipantModal}
        onClose={closeAddParticipantModal}
        actingParticipant={currUserParticipant!}
      />
      <SendDataRoomUpdateModal
        open={showSendDataRoomUpdateModal}
        onClose={() => setShowSendDataRoomUpdateModal(false)}
        participants={selectedParticipants}
      />
    </>
  );
};
