import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BidCreationSuccessful,
  EnterBidPricingScreenLoaded,
  ReviewBidScreenLoaded,
  UploadLetterOfIntentScreenLoaded,
} from "../../state/TransactionsState";
import { CreateBidFlowStepType } from "../../../domain/models/CreateBidFlowStep";
import { useTransactionsContext } from "../../state/TransactionsContext";
import Loader from "../../../../../common/components/TapLoader";
import { EnterBidPricing } from "./create-bid/EnterBidPricingStep";
import UploadLOIStep from "./create-bid/UploadLOIStep";
import ReviewStep from "./create-bid/ReviewStep";
import StepperFlow from "experiences/common/Stepper/StepperFlow";

interface ICreateBidFlowProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

export interface ICreateBidFlowStep {
  isActive: boolean;
  label: string;
  child: React.ReactNode;
  type: CreateBidFlowStepType;
}

interface ICurrentStep {
  child: React.ReactNode;
  index: number;
}

const CreateBidFlow: React.FC<ICreateBidFlowProps> = ({ open, onClose }) => {
  const classes = useStyles();
  //   const { bidFlowState, emitEvent } = useTransactionsContext();
  //   useEffect(() => {}, []);

  const [currentStep, setCurrentStep] = useState<ICurrentStep>({
    child: <Loader />,
    index: 0,
  });

  const { createBidFlowState } = useTransactionsContext();

  const activateStep = ({
    stepType,
    child,
  }: {
    stepType: CreateBidFlowStepType;
    child: React.ReactNode;
  }) => {
    setCurrentStep({
      index: stepType,
      child: child,
    });
  };

  useEffect(() => {
    if (createBidFlowState instanceof EnterBidPricingScreenLoaded) {
      activateStep({
        stepType: CreateBidFlowStepType.EnterBidPricing,
        child: <EnterBidPricing txnHoldings={createBidFlowState.holdings} />,
      });
    } else if (createBidFlowState instanceof UploadLetterOfIntentScreenLoaded)
      activateStep({
        stepType: CreateBidFlowStepType.UploadLOI,
        child: <UploadLOIStep {...createBidFlowState} />,
      });
    else if (createBidFlowState instanceof ReviewBidScreenLoaded)
      activateStep({
        stepType: CreateBidFlowStepType.ReviewBid,
        child: <ReviewStep {...createBidFlowState} />,
      });
    else if (createBidFlowState instanceof BidCreationSuccessful) onClose();
  }, [createBidFlowState]);

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <StepperFlow
        name={"Create Bid"}
        steps={[
          { name: "Enter Bid Pricing" },
          { name: "Upload Letter of Intent" },
          { name: "Review Bid" },
        ]}
        activeStepIndex={currentStep.index}
        onClose={onClose}
        children={currentStep.child}
      />
      ;
    </Drawer>
  );
};

export default CreateBidFlow;
