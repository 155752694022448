import { Either, left, right } from "fp-ts/lib/Either";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { OrganizationFilterSpecs } from "../models/OrganizationFilterSpec";
import { PageSpecs } from "common/@types/models/PageSpecs";
import { Organization, OrganizationPage } from "../models/Organization";
import { IOrganization } from "../models/Organization";

interface IResp {
  items: IOrganization[];
  total: number;
  perPage: number;
  pageIndex: number;
  pageCount: number;
}

export class RetrieveOrganizations
  implements IAsyncUseCase<IParams, OrganizationPage>
{
  call: (params: IParams) => Promise<Either<Failure, OrganizationPage>> =
    async (params) => {
      return await new TapClient("/organizations/", params)
        .post()
        .then((resp: IResp) => {
          return right({
            organizations: resp.items.map((item) => new Organization(item)),
            totalOrgs: resp.total,
            pageIndex: resp.pageIndex,
            pageCount: resp.pageCount,
            perPage: resp.perPage,
          });
        })
        .catch((resp: Error) => left(new Failure(resp.message)));
    };
}

interface IParams {
  paginationSpecs: PageSpecs;
  filters: OrganizationFilterSpecs;
}
