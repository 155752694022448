import React from "react";
import DatePicker from "../DatePicker";

const FormikDatePicker = ({ formik, name, ...props }) => (
  <DatePicker
    {...props}
    name={name}
    value={formik.values[name]}
    onChange={(value) => {
      formik.setFieldValue(name, value);
    }}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
  />
);

export default FormikDatePicker;
