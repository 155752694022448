import { Helmet } from "react-helmet";

import { DashboardCenterColumn } from "../components/DashboardCenterColumn";
import { DashboardLeftColumn } from "../components/DashboardLeftColumn";
import { DashboardRightColumn } from "../components/DashboardRightColumn";
import { useDashboard } from "../hooks/useDashboard";
import { Indexes } from "../components/Indexes";
import { StyledDashboard } from "../components/DashboardWrapper";

const allIndexFunds = [
  {
    name: "Dividend Appreciation ETF",
    amount: "317",
    variationPoints: 881,
    chart: [],
  },
  {
    name: "ESG International Stock ETF",
    amount: "5,962",
    variationPoints: 799,
    chart: [],
  },
  {
    name: "ESG U.S. Stock ETF",
    amount: "1,483",
    variationPoints: 861,
    chart: [],
  },
  {
    name: "Extended Market ETF",
    amount: "0",
    variationPoints: 743,
    chart: [],
  },
  {
    name: "FTSE All-World ex-US ETF",
    amount: "3,790",
    variationPoints: 484,
    chart: [],
  },
  {
    name: "FTSE All-World ex-US Small-Cap ETF",
    amount: "4,331",
    variationPoints: -221,
    chart: [],
  },
  {
    name: "FTSE Developed Markets ETF",
    amount: "4,104",
    variationPoints: -980,
    chart: [],
  },
  {
    name: "FTSE Emerging Markets ETF",
    amount: "5,829",
    variationPoints: -64,
    chart: [],
  },
  {
    name: "FTSE Europe ETF",
    amount: "0",
    variationPoints: -563,
    chart: [],
  },
  {
    name: "FTSE Pacific ETF",
    amount: "0",
    variationPoints: -904,
    chart: [],
  },
  {
    name: "Growth ETF",
    amount: "238",
    variationPoints: -733,
    chart: [],
  },
  {
    name: "High Dividend Yield ETF",
    amount: "465",
    variationPoints: -120,
    chart: [],
  },
  {
    name: "International Dividend Appreciation ETF",
    amount: "366",
    variationPoints: -987,
    chart: [],
  },
  {
    name: "International High Dividend Yield ETF",
    amount: "1,414",
    variationPoints: 238,
    chart: [],
  },
  {
    name: "Large-Cap ETF",
    amount: "552",
    variationPoints: -763,
    chart: [],
  },
  {
    name: "Mega Cap ETF",
    amount: "232",
    variationPoints: 984,
    chart: [],
  },
  {
    name: "Mega Cap Growth ETF",
    amount: "100",
    variationPoints: -544,
    chart: [],
  },
  {
    name: "Mega Cap Value ETF",
    amount: "148",
    variationPoints: 388,
    chart: [],
  },
  {
    name: "Mid-Cap ETF",
    amount: "347",
    variationPoints: 760,
    chart: [],
  },
  {
    name: "Mid-Cap Growth ETF",
    amount: "165",
    variationPoints: 853,
    chart: [],
  },
  {
    name: "Mid-Cap Value ETF",
    amount: "198",
    variationPoints: 180,
    chart: [],
  },
  {
    name: "Russell 2000 ETF",
    amount: "2,025",
    variationPoints: -3,
    chart: [],
  },
  {
    name: "S&P 500 ETF",
    amount: "508",
    variationPoints: 522,
    chart: [],
  },
  {
    name: "S&P 500 Growth ETF",
    amount: "238",
    variationPoints: -669,
    chart: [],
  },
  {
    name: "S&P 500 Value ETF",
    amount: "407",
    variationPoints: 877,
    chart: [],
  },
  {
    name: "S&P Small-Cap 600 ETF",
    amount: "606",
    variationPoints: 43,
    chart: [],
  },
  {
    name: "Small-Cap ETF",
    amount: "1,452",
    variationPoints: 494,
    chart: [],
  },
  {
    name: "Small-Cap Growth ETF",
    amount: "659",
    variationPoints: 688,
    chart: [],
  },
  {
    name: "Small-Cap Value ETF",
    amount: "879",
    variationPoints: 427,
    chart: [],
  },
  {
    name: "Total International Stock ETF",
    amount: "8,155",
    variationPoints: 435,
    chart: [],
  },
  {
    name: "Total Stock Market ETF",
    amount: "0",
    variationPoints: -381,
    chart: [],
  },
  {
    name: "Total World Stock ETF",
    amount: "9,633",
    variationPoints: 218,
    chart: [],
  },
  {
    name: "Value ETF",
    amount: "346",
    variationPoints: 385,
    chart: [],
  },
  {
    name: "Communication Services ETF",
    amount: "120",
    variationPoints: 700,
    chart: [],
  },
  {
    name: "Consumer Discretionary ETF",
    amount: "310",
    variationPoints: -312,
    chart: [],
  },
  {
    name: "Consumer Staples ETF",
    amount: "107",
    variationPoints: 147,
    chart: [],
  },
  {
    name: "Energy ETF",
    amount: "115",
    variationPoints: -246,
    chart: [],
  },
  {
    name: "Financials ETF",
    amount: "396",
    variationPoints: -159,
    chart: [],
  },
];

export const firstFiveFunds = allIndexFunds.slice(0, 5);

export const LpDashboard = () => {
  const {
    bids,
    bidsLoaded,
    portfolio,
    portfolioLoaded,
    transactions,
    transactionsLoaded,
    activity,
    activityLoaded,
    dashboardActivity,
    dashboardLoaded,
    indexes,
  } = useDashboard();

  return (
    <>
      <Helmet title="Dashboard" />
      <Indexes indexes={indexes} />
      <StyledDashboard hasIndexes={Boolean(indexes.length)}>
        <DashboardLeftColumn
          portfolio={portfolio}
          portfolioLoaded={portfolioLoaded}
        />
        <DashboardCenterColumn
          activeTransactions={transactions}
          transactionsLoaded={transactionsLoaded}
          activity={activity}
          activityLoaded={activityLoaded}
          dashboardActivity={dashboardActivity}
          dashboardLoaded={dashboardLoaded}
        />
        <DashboardRightColumn bids={bids} bidsLoaded={bidsLoaded} />
      </StyledDashboard>
    </>
  );
};
