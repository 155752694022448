import { TableAlignment } from "experiences/funds/presentation/components/Table";
import { FundExposureColumnsQuay } from "./FundExposureSummaryMockQuay";

export interface SoiLookthroughRow {
  company: string;
  funds: number | string;
  txnPercent: string;
  type: string;
  sector: string;
  date: string;
  cost: string;
  size: string;
  nav: string;
}

export const mockColumns = [
  { label: "Company", align: TableAlignment.LEFT, key: "company" },
  { label: "Fund(s)", align: TableAlignment.LEFT, key: "funds" },
  { label: "Txn%", align: TableAlignment.LEFT, key: "txnPercent" },
  { label: "Type", align: TableAlignment.LEFT, key: "type" },
  { label: "Sector", align: TableAlignment.LEFT, key: "sector" },
  { label: "Date", align: TableAlignment.LEFT, key: "date" },
  { label: "Cost", align: TableAlignment.LEFT, key: "cost" },
  { label: "Size", align: TableAlignment.LEFT, key: "size" },
  { label: "NAV", align: TableAlignment.LEFT, key: "nav" },
];

// prettier-ignore
export const soiLookthroughMockColumnsQuay = [
  { label: "Company", align: TableAlignment.LEFT, key: "COMPANY_NAME" },
  { label: "LP Implied Investment Txn %", align: TableAlignment.LEFT, key: "LP_IMPLIED_INVESTMENT_TXN_%" },
  { label: "LP Implied Investment Value", align: TableAlignment.LEFT, key: "LP_IMPLIED_INVESTMENT_VALUE", },
  { label: "Fund Name", align: TableAlignment.LEFT, key: "FUND_NAME" },
  { label: "Investment Date", align: TableAlignment.LEFT, key: "INVESTMENT_DATE", },
  { label: "Hold Period", align: TableAlignment.LEFT, key: "HOLD_PERIOD" },
  { label: "Investment Type", align: TableAlignment.LEFT, key: "INVESTMENT_TYPE",},
  { label: "Investment Geography", align: TableAlignment.LEFT, key: "INVESTMENT_GEOGRAPHY", },
  { label: "Sector", align: TableAlignment.LEFT, key: "SECTOR" },
  { label: "Revenue Multiple LTM", align: TableAlignment.LEFT, key: "REVENUE_MULTIPLE_LTM", },
  { label: "Revenue YoY Percent Chg LTM", align: TableAlignment.LEFT, key: "REVENUE_YOY_PERCENT_CHG_LTM", },
  { label: "EBITDA LTM Margin", align: TableAlignment.LEFT, key: "EBITDA_LTM_MARGIN", },
  { label: "EBITDA YoY Percent Chg LTM", align: TableAlignment.LEFT, key: "EBITDA_YOY_PERCENT_CHG_LTM", },
  { label: "EBITDA Multiple LTM", align: TableAlignment.LEFT, key: "EBITDA_MULITPLE_LTM", },
  { label: "Leverage Multiple LTM", align: TableAlignment.LEFT, key: "LEVERAGE_MULTIPLE_LTM", },
]

export const mockSoiLookthrough: SoiLookthroughRow[] = [
  {
    company: "Heat (Redacted)",
    funds: "fb247466-7a69-590f-997e-75f4243e465e",
    txnPercent: "60",
    type: "motor",
    sector: "#4c70b2",
    date: "#a4aaac",
    cost: "#33a811",
    size: "#e3e937",
    nav: "#db01a8",
  },
  {
    company: "Natural (Redacted)",
    funds: "ac9c6b76-27f8-572f-8737-9ab497255f25",
    txnPercent: "11",
    type: "breath",
    sector: "#c74edd",
    date: "#bcae79",
    cost: "#9b194c",
    size: "#70b584",
    nav: "#8adb0d",
  },
  {
    company: "Chemical (Redacted)",
    funds: "f8d2579c-3ad4-5eab-b5d0-4a5417c88604",
    txnPercent: "98",
    type: "bee",
    sector: "#371007",
    date: "#e2b0fd",
    cost: "#44289d",
    size: "#78b85b",
    nav: "#b02349",
  },
  {
    company: "Gently (Redacted)",
    funds: "f3ba92b9-3692-5ccb-8104-4c0b71864cf4",
    txnPercent: "35",
    type: "general",
    sector: "#e139b9",
    date: "#344dfa",
    cost: "#206cff",
    size: "#745f10",
    nav: "#547807",
  },
  {
    company: "Unless (Redacted)",
    funds: "c00dac1b-508e-5e56-bb23-75c587615215",
    txnPercent: "78",
    type: "got",
    sector: "#9de763",
    date: "#77248a",
    cost: "#8a8f20",
    size: "#c1f24d",
    nav: "#8499dd",
  },
  {
    company: "Depend (Redacted)",
    funds: "e18835be-fc4c-524b-af0c-653cf2c48b28",
    txnPercent: "25",
    type: "somehow",
    sector: "#0dc530",
    date: "#fc79ed",
    cost: "#a52cdc",
    size: "#3755c4",
    nav: "#feb597",
  },
  {
    company: "Jar (Redacted)",
    funds: "7ed32ad4-5b0e-5fee-8576-46b49938a8b4",
    txnPercent: "22",
    type: "swung",
    sector: "#8c77cc",
    date: "#804055",
    cost: "#a45126",
    size: "#785eee",
    nav: "#d4618e",
  },
  {
    company: "Women (Redacted)",
    funds: "284c4f85-ada2-5a02-83fd-e6fb5c24b461",
    txnPercent: "44",
    type: "hurried",
    sector: "#02fb46",
    date: "#c7db28",
    cost: "#9afb8d",
    size: "#fa324c",
    nav: "#3157cc",
  },
  {
    company: "Prize (Redacted)",
    funds: "2f59b1eb-e314-5a5b-94ab-6a6381a5264c",
    txnPercent: "29",
    type: "flow",
    sector: "#fa5ff6",
    date: "#cd7898",
    cost: "#80e6d4",
    size: "#23a052",
    nav: "#d70939",
  },
  {
    company: "Nearly (Redacted)",
    funds: "df886d46-c660-50d5-9e26-f842df899a64",
    txnPercent: "95",
    type: "mice",
    sector: "#409a94",
    date: "#ace24f",
    cost: "#7c5f95",
    size: "#01e9a1",
    nav: "#2eb1d6",
  },
  {
    company: "Remember (Redacted)",
    funds: "c458a881-a7a5-5790-9eb6-62c9e079389f",
    txnPercent: "25",
    type: "promised",
    sector: "#826bc0",
    date: "#d08ccc",
    cost: "#49fef8",
    size: "#89dfe1",
    nav: "#3dc0d1",
  },
  {
    company: "Harder (Redacted)",
    funds: "aa4532f0-0aa3-5a46-879f-9ce0eca2adb7",
    txnPercent: "92",
    type: "syllable",
    sector: "#1908b4",
    date: "#d71c33",
    cost: "#123543",
    size: "#c2c48c",
    nav: "#40dc9f",
  },
  {
    company: "Follow (Redacted)",
    funds: "f5876931-f7ae-5ff5-9c27-b00f027ee53f",
    txnPercent: "94",
    type: "provide",
    sector: "#b062cd",
    date: "#642ab1",
    cost: "#a0ae86",
    size: "#7c087c",
    nav: "#0b9f45",
  },
  {
    company: "Even (Redacted)",
    funds: "45bca83c-e003-5acc-908b-2326d857a770",
    txnPercent: "91",
    type: "show",
    sector: "#b8df71",
    date: "#a0b5b8",
    cost: "#d4bb62",
    size: "#badfd3",
    nav: "#c9f798",
  },
  {
    company: "Warn (Redacted)",
    funds: "a4ad4cd3-a6bf-533d-8562-b6d5099aced8",
    txnPercent: "34",
    type: "cent",
    sector: "#3f499d",
    date: "#12aff7",
    cost: "#0a4229",
    size: "#307ab7",
    nav: "#b3cd55",
  },
  {
    company: "Cry (Redacted)",
    funds: "d384b673-7bd9-559a-85cc-411ce7cc4388",
    txnPercent: "76",
    type: "pass",
    sector: "#c648dd",
    date: "#ed99de",
    cost: "#72dbdf",
    size: "#48ea0a",
    nav: "#bad05f",
  },
  {
    company: "Won (Redacted)",
    funds: "e0e9d23b-8825-5aba-866c-2b914f5e5014",
    txnPercent: "30",
    type: "step",
    sector: "#fbf9bc",
    date: "#01b646",
    cost: "#0dbdc6",
    size: "#298ba5",
    nav: "#6f6439",
  },
  {
    company: "Particular (Redacted)",
    funds: "4bfb8931-01c7-56f4-b296-46abc8f930a3",
    txnPercent: "44",
    type: "store",
    sector: "#1789ba",
    date: "#c419d5",
    cost: "#36ade3",
    size: "#96d34f",
    nav: "#f44d66",
  },
];

export interface SoiLookthroughQuayRow {
  COMPANY_NAME;
  "LP_IMPLIED_INVESTMENT_TXN_%";
  LP_IMPLIED_INVESTMENT_VALUE;
  FUND_NAME;
  INVESTMENT_DATE;
  HOLD_PERIOD;
  INVESTMENT_TYPE;
  INVESTMENT_GEOGRAPHY;
  SECTOR;
  REVENUE_MULTIPLE_LTM;
  REVENUE_YOY_PERCENT_CHG_LTM;
  EBITDA_LTM_MARGIN;
  EBITDA_YOY_PERCENT_CHG_LTM;
  EBITDA_MULITPLE_LTM;
  LEVERAGE_MULTIPLE_LTM;
}

// create mock columns for SoiLookthroughQuayRow using label, align, and key
// prettier-ignore
export const fundExposureMockColumnsQuay = [
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_NAME, label: "Fund Name"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.MANAGER_NAME, label: "Manager Name"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.TXN_PERCENT, label: "Txn %"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.STRATEGY, label: "Strategy"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.VINTAGE, label: "Vintage"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.GEOGRAPHY, label: "Geography"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_COMMITTED_CAPITAL, label: "Fund Committed Capital"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.MOIC, label: "MOIC"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.QUARTILE, label: "Quartile"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_SERIES_AVERAGE_MOIC, label: "Fund Series Average MOIC"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_NAV_QOQ_PERCENT_CHG_1Q, label: "Fund NAV QoQ % Chg 1Q"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_NAV_YOY_PERCENT_CHANGE_FY1, label: "Fund NAV YoY % Change FY1"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.UNREALIZED_INVESTMENT_COUNT, label: "Unrealized Investment Count"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.TOP_3_CONCENTRATION, label: "Top 3 Concentration"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.TOP_10_CONCENTRATION, label: "Top 10 Concentration"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.WEIGHTED_AVG_REVENUE_GROWTH, label: "Weighted Avg Revenue Growth"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.CAPITAL_ACCOUNT_CURRENCY, label: "Capital Account Currency"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CURRENT_EXPOSURE, label: "LP Current Exposure"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_COMMITTED_CAPITAL, label: "LP Committed Capital"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_REMAINING_UNFUNDED, label: "LP Remaining Unfunded"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CONTRIBUTED_CAPITAL_ITD, label: "LP Contributed Capital ITD"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_DISTRIBUTED_CAPITAL_ITD, label: "LP Distributed Capital ITD"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_ENDING_CAPITAL_ACCOUNT_VALUE, label: "LP Ending Capital Account Value"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CAS_TVPI, label: "LP CaS TVPI"},
  { align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CAS_DPI, label: "LP CaS DPI"},
];

// prettier-ignore
export const mockSoiLookthroughQuay = [
  ["Filevine, Inc.","20.00%","$6,056,066.00","Signal Peak Ventures III CIV-A, L.P.","2018-09-17","5.9","VC-Backed Company","United States","Information Technology","20.2x","-","-","","",""],
  ["Filevine, Inc.","12.00%","$3,519,781.82","Signal Peak Ventures III, L.P.","2018-09-17","5.9","VC-Backed Company","United States","Information Technology","20.2x","-","-","-","-","-"],
  ["Filevine, Inc","2.00%","$613,614.00","Signal Peak Ventures IV, L.P.","9/17/18","5.9","VC-Backed Company","United States","Information Technology","20.2x","-","-","","",""],
  ["Acceleprise SF Venture Capital Fund II, L.P.","9.00%","$2,758,614.49","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Financials","-","-","-","-","-","-"],
  ["Star Power Partners II Feeder, LLC","6.00%","$1,891,290.76","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Financials","-","-","-","-","-","-"],
  ["QuickrCare, Inc.","4.00%","$1,250,310.32","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Bark Technologies, Inc.","4.00%","$1,089,237.40","Signal Peak Ventures III, L.P.","2018-08-17","6","VC-Backed Company","United States","Information Technology","6.9x","-","-","-","-","-"],
  ["FloatMe, Corp","2.00%","$687,715.72","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Ironclad, Inc. (acquirer of PactSafe, Inc.)","2.00%","$649,729.27","Signal Peak Ventures III, L.P.","2018-07-01","6.1","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Arkestro, Inc.","2.00%","$617,397.42","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Fetch Package, Inc.","2.00%","$531,626.60","Signal Peak Ventures III, L.P.","2019-09-25","4.9","VC-Backed Company","United States","Logistics","3.5x","35.5","-","-","-","-"],
  ["Authenticx, Inc.","2.00%","$513,600.13","Signal Peak Ventures III, L.P.","2021-06-01","3.2","VC-Backed Company","United States","Information Technology","12.6x","-","-","-","-","-"],
  ["GAS POS, Inc.","2.00%","$484,940.66","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["ChargeWheel Co.","1.00%","$444,084.85","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["HireMadskills, Inc.","1.00%","$393,282.60","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Goldfish Social Inc.","1.00%","$388,352.20","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Precision Compounding Pharmacy & Wellness","1.00%","$364,496.47","Peregrine Select Fund II, LP","2023-10-01","0.8","VC-Backed Company","United States","Healthcare","-","-","-","-","-","-"],
  ["Elife","1.00%","$344,743.31","Peregrine Select Fund II, LP","2022-06-01","2.2","VC-Backed Company","United States","Transportation","-","-","-","-","-","-"],
  ["Diib, Inc.","1.00%","$325,881.10","Signal Peak Ventures III, L.P.","2020-04-01","4.3","VC-Backed Company","United States","Information Technology","10.9x","-","-","-","-","-"],
  ["CometChat, Inc","1.00%","$238,224.00","Signal Peak Ventures IV, L.P.","-","-","-","-","-","-","-","-","","",""],
  ["Hubpay, Inc.","1.00%","$215,530.12","Signal Peak Ventures III, L.P.","2022-08-01","2","VC-Backed Company","Europe","Information Technology","35.9x","-","-","-","-","-"],
  ["TCARE, Inc.","1.00%","$211,881.34","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Graphite Systems, Inc.","1.00%","$196,489.68","Signal Peak Ventures III, L.P.","2020-12-01","3.7","VC-Backed Company","United States","Information Technology","22.2x","-","-","-","-","-"],
  ["Boomtown Fund III LLC","1.00%","$195,673.72","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Financials","-","-","-","-","-","-"],
  ["Car Body Lab Inc.","1.00%","$190,322.08","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Enable My Child","1.00%","$180,991.39","Peregrine Select Fund II, LP","2022-11-01","1.7","VC-Backed Company","United States","Healthcare","-","-","-","-","-","-"],
  ["Nostra, Inc","1.00%","$171,911.00","Signal Peak Ventures IV, L.P.","-","-","-","-","-","-","-","-","","",""],
  ["Techmate Technologies","1.00%","$165,515.90","Peregrine Select Fund II, LP","2022-05-01","2.2","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Aiden Technologies","1.00%","$165,058.03","Peregrine Select Fund II, LP","2021-06-01","3.2","VC-Backed Company","United States","Information Technology","7.85x","-","-","-","-","-"],
  ["Careteam Technologies","1.00%","$160,881.23","Peregrine Select Fund II, LP","2022-08-01","2","VC-Backed Company","Canada","Information Technology","-","-","-","-","-","-"],
  ["Enable My Child, Ltd.","1.00%","$155,210.19","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Healthcare","-","-","-","-","-","-"],
  ["Boomtown Fund IV LLC","1.00%","$153,811.96","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Financials","-","-","-","-","-","-"],
  ["Qeepsake","0.00%","$148,854.48","Peregrine Select Fund II, LP","2022-02-01","2.5","VC-Backed Company","United States","Information Technology","3.0x","-","-","-","-","-"],
  ["Revi Technologies","0.00%","$136,861.02","Peregrine Select Fund II, LP","2023-04-01","1.3","VC-Backed Company","United States","Information Technology","5.17x","-","-","-","-","-"],
  ["ICONYC Labs IV LLC","0.00%","$136,473.41","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Financials","-","-","-","-","-","-"],
  ["Monarx, Inc","0.00%","$136,128.00","Signal Peak Ventures IV, L.P.","-","-","-","-","-","-","-","-","","",""],
  ["Local Locker, Inc","0.00%","$136,128.00","Signal Peak Ventures IV, L.P.","-","-","-","-","-","-","-","-","","",""],
  ["Advocatia Solutions, Inc.","0.00%","$130,998.69","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Lumanu, Inc.","0.00%","$130,352.86","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Wherefour, Inc.","0.00%","$128,326.35","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Wherefour","0.00%","$128,182.53","Peregrine Select Fund II, LP","2022-06-01","2.2","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Benefitbay","0.00%","$124,996.11","Peregrine Select Fund II, LP","2023-02-01","1.5","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["The Akia Syndicate","0.00%","$121,768.49","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Upswot","0.00%","$120,660.93","Peregrine Select Fund II, LP","2023-12-01","0.7","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Goldfish Social","0.00%","$116,764.38","Peregrine Select Fund II, LP","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Medsien, Inc.","0.00%","$114,282.91","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Seed investments","0.00%","$114,232.11","Signal Peak Ventures III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["SocialHP Media","0.00%","$100,550.77","Peregrine Select Fund II, LP","2022-04-01","2.3","VC-Backed Company","Canada","Information Technology","5.88x","-","-","-","-","-"],
  ["Upflex","0.00%","$100,550.77","Peregrine Select Fund II, LP","2023-12-01","0.7","VC-Backed Company","United States","Information Technology","6.48x","-","-","-","-","-"],
  ["Everee, Inc.","0.00%","$99,899.53","Signal Peak Ventures III, L.P.","2020-04-01","4.3","VC-Backed Company","United States","Information Technology","15.4x","-","-","-","-","-"],
  ["Seed Investments","0.00%","$95,290.00","Signal Peak Ventures IV, L.P.","-","-","-","-","-","-","-","-","","",""],
  ["Tiled, Inc.","0.00%","$93,758.66","Signal Peak Ventures III, L.P.","2018-09-01","5.9","VC-Backed Company","United States","Information Technology","6.0x","-","","-","-","-"],
  ["BookClub.com, Inc.","0.00%","$93,174.88","Signal Peak Ventures III, L.P.","2020-08-01","4","VC-Backed Company","United States","Information Technology","28.98x","","","-","-","-"],
  ["PAXAFE, Inc.","0.00%","$93,024.57","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Vertify","0.00%","$92,506.63","Peregrine Select Fund II, LP","2022-01-01","2.6","VC-Backed Company","United States","Information Technology","10.32x","-","-","-","-","-"],
  ["HAAS Alert","0.00%","$90,147.79","Peregrine Select Fund II, LP","-","-","VC-Backed Company","United States","Transportation","-","-","-","-","-","-"],
  ["4Degrees AV","0.00%","$85,097.73","Peregrine Select Fund II, LP","2023-03-23","1.3","VC-Backed Company","United States","Information Technology","5.83x","-","-","-","-","-"],
  ["Eze Technologies","0.00%","$80,440.62","Peregrine Select Fund II, LP","2023-03-01","1.4","VC-Backed Company","United States","Information Technology","1.79x","-","-","-","-","-"],
  ["Hopthru, Inc.","0.00%","$79,136.34","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Bullpen Sports Network","0.00%","$76,149.83","Peregrine Select Fund II, LP","2021-07-01","3.1","VC-Backed Company","United States","Media","13.46x","-","-","-","-","-"],
  ["Revi Technologies Inc.","0.00%","$72,657.99","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Lincode Labs Inc.","0.00%","$72,615.27","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Corza Technologies Inc.","0.00%","$70,241.11","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Aktus","0.00%","$60,330.46","Peregrine Select Fund II, LP","2024-02-01","0.5","VC-Backed Company","NO_DATA","NO_DATA","-","-","-","-","-","-"],
  ["ForwardLane","0.00%","$60,330.46","Peregrine Select Fund II, LP","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Automation Marketing, Inc.","0.00%","$54,847.02","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Cadalys","0.00%","$51,122.02","Peregrine Select Fund II, LP","2022-12-01","1.7","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Rentgrata, Inc.","0.00%","$50,752.55","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Rapidly Financial, Inc.","0.00%","$44,748.10","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Parsley Software, Inc.","0.00%","$42,293.80","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Upflex, Inc.","0.00%","$42,293.80","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","6.48x","-","-","-","-","-"],
  ["Vertify, Inc.","0.00%","$42,293.80","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","10.32x","-","-","-","-","-"],
  ["Elidah","0.00%","$40,220.31","Peregrine Select Fund II, LP","2023-06-01","1.2","VC-Backed Company","United States","Healthcare","-","-","-","-","-","-"],
  ["Lateral Technology","0.00%","$40,220.31","Peregrine Select Fund II, LP","2023-12-01","0.7","VC-Backed Company","United Kingdom","Information Technology","-","-","-","-","-","-"],
  ["Maya AI","0.00%","$40,220.31","Peregrine Select Fund II, LP","2023-10-01","0.8","VC-Backed Company","United States","Healthcare","-","-","-","-","-","-"],
  ["SelectFew","0.00%","$40,220.31","Peregrine Select Fund II, LP","2024-03-01","0.4","VC-Backed Company","NO_DATA","NO_DATA","6.0x","-","-","-","-","-"],
  ["Stage Technology","0.00%","$40,220.31","Peregrine Select Fund II, LP","-","-","VC-Backed Company","Canada","Information Technology","-","-","-","-","-","-"],
  ["Sware","0.00%","$40,220.31","Peregrine Select Fund II, LP","2023-12-01","0.7","VC-Backed Company","United States","Information Technology","4.87x","-","-","-","-","-"],
  ["Unboxt","0.00%","$38,209.29","Peregrine Select Fund II, LP","2022-07-01","2.1","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Backstitch Inc.","0.00%","$32,078.15","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["JoinEight, Inc.","0.00%","$31,720.35","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Native Voice, Inc.","0.00%","$30,119.44","Signal Peak Ventures III, L.P.","2021-07-01","3.1","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Measured Analytics and Insurance, Inc.","0.00%","$25,099.51","Signal Peak Ventures III, L.P.","2019-05-01","5.2","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Alpine Media Technology, LLC","0.00%","$22,204.24","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Sofar Sounds Limited","0.00%","$22,075.25","RSCM Fund III, L.P.","-","-","VC-Backed Company","United Kingdom","Information Technology","-","-","-","-","-","-"],
  ["Bardavon Health Innovations, Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Community Benchmark, Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Ethica Data Services Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","Canada","Information Technology","-","-","-","-","-","-"],
  ["Gatsby Tech, Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Nonna Technologies Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["SecondKeys, Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["UnboXt, Inc.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["VR Motion Corp.","0.00%","$21,146.90","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["9338-8924 Quebec Inc.","0.00%","$20,713.39","RSCM Fund III, L.P.","-","-","VC-Backed Company","Canada","Information Technology","-","-","-","-","-","-"],
  ["Service Squared Inc.","0.00%","$20,440.38","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Careteam Technologies Inc.","0.00%","$20,308.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","Canada","Information Technology","-","-","-","-","-","-"],
  ["Friendefi Inc.","0.00%","$20,308.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","Canada","Information Technology","-","-","-","-","-","-"],
  ["Etho Inc.","0.00%","$15,860.17","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Predictus","0.00%","$15,685.29","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Karyosoft LLC","0.00%","$10,573.45","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Kuhmute LLC","0.00%","$10,573.45","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Daily Muse, Inc. (acquirer of Fairygodboss, Inc.)","0.00%","$9,737.75","Signal Peak Ventures III, L.P.","2019-03-08","5.4","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Alation, Inc.","0.00%","$1,702.33","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Autobon Holdings, Inc.","0.00%","$0.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["LotMonkey, Inc.","0.00%","$0.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["MLS MY Next, Inc.","0.00%","$0.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["RefineRE, Inc.","0.00%","$0.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Robbie AI Inc.","0.00%","$0.00","RSCM Fund III, L.P.","-","-","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
  ["Testa, Inc. (acquirer of Kronologic, Inc.)","0.00%","$0.00","Signal Peak Ventures III, L.P.","2021-06-01","3.2","VC-Backed Company","United States","Information Technology","-","-","-","-","-","-"],
];
