import { gql } from "@apollo/client";

export enum TransactionDataSummarySectionStatus {
  NotYetUploaded = "NotYetUploaded",
  UploadedAndProcessing = "UploadedAndProcessing",
  ReadyToDownloadCSV = "ReadyToDownloadCSV",
}

export interface TransactionDataSummarySectionsStatus {
  dataSummarySectionsStatuses: {
    fundExposureSummary: TransactionDataSummarySectionStatus;
    soiLookthrough: TransactionDataSummarySectionStatus;
    cas: TransactionDataSummarySectionStatus;
    dealBreakdown?: TransactionDataSummarySectionStatus;
  };
}

export const GetDataSummarySectionsStatus = gql`
  query DataSummarySectionsStatuses($transactionId: String!) {
    dataSummarySectionsStatuses(transactionId: $transactionId) {
      fundExposureSummary
      soiLookthrough
      cas
      dealBreakdown
    }
  }
`;
