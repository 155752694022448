import { useEffect } from "react";
import { useParams } from "react-router";

import { HalfImageLayout } from "common/components/HalfImage";
import { FormContentSkeleton } from "../components/FormContentSkeleton";
import { InitialSignup } from "./register/InitialSignup";
import { CreateYourAccountStep } from "./register/CreateYourAccount";
import CreateProfile from "./register/CreateProfile";
import { RegistrationSubmittedStep } from "./register/RegistrationSubmitted";
import { SelectYourOrganizationStep } from "./register/SelectYourOrganization";
import RegFlowProgressBar from "../components/RegFlowProgressBar";
import { useAuthContext } from "../state/AuthenticationContext";
import {
  CreateYourAccountStepLoaded,
  SelectYourOrganizationStepLoaded,
  CreateProfileStepLoaded,
  InitialRegStepLoaded,
  RegistrationSubmittedStepLoaded,
  SelectGeographiesStepLoaded,
  CreateAccountFailed,
} from "../state/AuthenticationState";
import { RedirectedToInviteeOnboarding } from "../state/AuthenticationEvent";
import { SelectGeographiesStep } from "./register/CreateProfileGeo";
import { BarLoader } from "common/components/BarLoader";
import { BadLogin } from "./Invalid";
import { AuthAppBar } from "./AuthAppBar";

export const Register = () => {
  const { currRegStepState, emitEvent } = useAuthContext();
  const { inviteeId } = useParams();

  useEffect(() => {
    emitEvent!(new RedirectedToInviteeOnboarding({ inviteeId: inviteeId }));
  }, []);

  const renderComponent = (currRegStepState) => {
    if (currRegStepState instanceof InitialRegStepLoaded) {
      return <InitialSignup />;
    } else if (currRegStepState instanceof CreateYourAccountStepLoaded) {
      return <CreateYourAccountStep email={currRegStepState.email} />;
    } else if (currRegStepState instanceof SelectYourOrganizationStepLoaded) {
      return (
        <SelectYourOrganizationStep
          preSelectedOrg={currRegStepState.preSelectedOrg}
          fullName={currRegStepState.fullName}
        />
      );
    } else if (currRegStepState instanceof CreateProfileStepLoaded) {
      return <CreateProfile />;
    } else if (currRegStepState instanceof SelectGeographiesStepLoaded) {
      return <SelectGeographiesStep />;
    } else if (currRegStepState instanceof RegistrationSubmittedStepLoaded) {
      return <RegistrationSubmittedStep />;
    } else if (typeof currRegStepState === "undefined") {
      return <BarLoader />;
    }
  };

  if (currRegStepState instanceof CreateAccountFailed) {
    return (
      <BadLogin
        title="An error occurred."
        description="We've been notified about it. Contact us to further assist you."
        showContact
      />
    );
  }

  return (
    <HalfImageLayout
      imageUrl="/images/login/register.webp"
      appBar={<AuthAppBar showLoginButton={!inviteeId} headerTitle="Log In" />}
    >
      {currRegStepState && currRegStepState.withPercent && (
        <div className="absolute w-full top-1 px-1 md:px-4 md:top-2 opacity-30 md:opacity-70">
          <RegFlowProgressBar percentage={currRegStepState.progress} />
        </div>
      )}
      <FormContentSkeleton
        formTitle={currRegStepState?.headerText || undefined}
        withDisclaimer={currRegStepState?.disclaimerOn}
      >
        {renderComponent(currRegStepState)}
      </FormContentSkeleton>
    </HalfImageLayout>
  );
};
