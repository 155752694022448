import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import TextChip from "experiences/common/TextChip";
import {
  FinalizeParticipationTask,
  SignOfferLetterTask,
} from "./OverviewScreen";
import { TableHeadCard } from "experiences/funds/presentation/components/Table";
import { PageHead } from "common/components/PageHead";
import { IDocument } from "../mock/LP";
import { Button } from "shadcn/ui/Button";
import React, { useEffect } from "react";
import { Percentage } from "common/@types/app/Percentage";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { DateFormatter } from "common/@types/app/DateFormatter";
import {
  OfferScreenLoaded,
  OfferScreenLoading,
} from "../state/tenders/TendersState";
import Loader from "common/components/TapLoader";
import { useTransactionsContext } from "../state/TransactionsContext";
import { RedirectedToOfferScreen } from "../state/tenders/TendersEvent";
import { useParams } from "react-router";

interface IProps {
  state: OfferScreenLoaded | OfferScreenLoading;
}

const OfferScreen: React.FC<IProps> = ({ state }) => {
  const { tender } = useTransactionsContext();
  const { emitEvent } = tender;
  const { txnId } = useParams();

  useEffect(() => {
    emitEvent(new RedirectedToOfferScreen({ txnId }));
  }, []);

  if (state instanceof OfferScreenLoading) return <Loader />;
  return (
    <div>
      <div className="flex flex-row divide-x divide-stone-200">
        <div className="w-1/3 p-4 ">
          <TableHeadCard
            title="Price"
            value={new Percentage(state.offerPrice).formatted()}
          />
        </div>
        <div className="w-1/3 p-4">
          <TableHeadCard
            title="Total Capacity"
            value={
              state.totalCapacity
                ? new DollarAmount(state.totalCapacity).formattedBig()
                : "-"
            }
          />
        </div>
        <div className="w-1/3 p-4">
          <TableHeadCard
            title="Close Date"
            value={
              state.closeDate
                ? new DateFormatter(state.closeDate).date.toUTCString()
                : "-"
            }
          />
        </div>
      </div>
      <>
        <PageSectionTitleDivider showBorderTop>
          <div className="flex gap-2 items-center">
            <p>Action Items</p>
            <TextChip text={"1"} pending />
          </div>
        </PageSectionTitleDivider>
        <SignOfferLetterTask status={state.signOfferLetterStatus} />
        <FinalizeParticipationTask status={state.finalizeParticipationStatus} />
      </>
      <PageSectionTitleDivider showBorderTop>Summary</PageSectionTitleDivider>
      <div className="flex flex-col gap-8 p-8">
        {Object.keys(state.summary).map((title) => (
          <PageHead
            title={title}
            description={state.summary[title]}
            paddingless
          />
        ))}
      </div>
      <PageSectionTitleDivider showBorderTop>Documents</PageSectionTitleDivider>
      {/* {state.docs.map((doc) => (
        <DocumentCard {...doc} />
      ))} */}
    </div>
  );
};

export default OfferScreen;

interface IDocumentCardProps extends IDocument {}

const DocumentCard: React.FC<IDocumentCardProps> = ({
  name,
  size,
  downloadLink,
}) => {
  return (
    <div className="flex flex-row p-4 justify-between items-center border border-stone-200 bg-stone-50 rounded-xl m-4">
      <div className="flex flex-row flex-row items-center gap-4">
        <img src="/images/pdf-file-logo.svg" />
        <div className="flex flex-col justify-center">
          <p className="">{name}</p>
          <p className="text-xs text-stone-400">{size}</p>
        </div>
      </div>
      <a href={downloadLink} target="_blank">
        <div className="flex flex-row gap-4 items-center">
          <Button variant="outline">View</Button>
        </div>
      </a>
    </div>
  );
};
