import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";

const IOI_BID_BULK_PATH = "/ioi/bid/bulk/create/";
const IOI_BID_BULK_PATH_RECONFIRM = "/iois/bid/bulk/reconfirm/";

export type FundBid = {
  fundId: string;
  minAmount: number;
  maxAmount?: number;
  maxPrice?: number;
  minPrice?: number;
  referenceDate?: string;
  expirationDate?: string;
};

export class BidBulkUploadFunds implements IAsyncUseCase<IParams, any> {
  call: (params: IParams) => Promise<Either<Failure, any>> = async (
    payload,
  ) => {
    const { iois, options } = payload;
    const path =
      (options?.reconfirm ?? false)
        ? IOI_BID_BULK_PATH_RECONFIRM
        : IOI_BID_BULK_PATH;
    // API expects key `bids` instead of `iois`
    return await new TapClient(path, { bids: iois })
      .post()
      .then((resp: any) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  iois: FundBid[];
  options?: {
    reconfirm?: boolean;
  };
}
