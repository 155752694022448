import { formatPercentPoints } from "../pages/ViewFund";
import { VIEWPOINT_FUND_DETAILS } from "../components/viewpoint/viewpointOverview";
import { IoiType } from "experiences/indications-of-interest/presentation/pages/AddInterestModal";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

export const useViewpointFundDetails = ({
  locked,
  toggleSubmitIoiModal,
}: {
  locked: boolean;
  toggleSubmitIoiModal: (type: IoiType) => void;
}) => {
  const handleSubmidBid = () => {
    toggleSubmitIoiModal(IoiType.Bid);
  };

  const handleSubmidAsk = () => {
    toggleSubmitIoiModal(IoiType.Ask);
  };

  return {
    marketPricePoints: !locked
      ? (VIEWPOINT_FUND_DETAILS.marketPricePoints &&
          formatPercentPoints(VIEWPOINT_FUND_DETAILS.marketPricePoints)) ||
        NOT_AVAILABLE_STR
      : "XX.Y%",
    openInterest: !locked
      ? (VIEWPOINT_FUND_DETAILS.openInterest &&
          VIEWPOINT_FUND_DETAILS.openInterest?.formattedBig()) ||
        NOT_AVAILABLE_STR
      : "$XX,XXX,XXX",
    handleSubmidBid: handleSubmidBid,
    handleSubmidAsk: handleSubmidAsk,
  };
};
