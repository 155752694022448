// Sections
import { DealBreakdown } from "./TransactionFundDataDealBreakdown";
import { FundExposureSummaryTable } from "./FundExposureSummaryTable";

import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";

// Quay
import { DealBreakdownQuay } from "./quay/DealBreakdownQuay";
import { FundExposureSummaryQuay } from "./quay/FundExposureSummaryQuay";
import { SoiLookthroughQuay } from "./quay/SoiLookthroughQuay";
import { useFundDataSummary } from "./useFundDataSummary";

export const TransactionFundDataSummary = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const {
    isQuay,
    handleDownloadClick,
    fundExposureSummaryStatus,
    dealBreakdownStatus,
    isDownloading,
  } = useFundDataSummary({
    summary,
  });

  if (isQuay) {
    return (
      <div className="max-w-full w-full overflow-hidden">
        <DealBreakdownQuay />
        <FundExposureSummaryQuay />
        <SoiLookthroughQuay />
      </div>
    );
  }

  return (
    <div className="max-w-full w-full overflow-x-hidden relative min-h-96 max-h-screen overflow-y-scroll no-scrollbar">
      <DealBreakdown
        handleDownloadClick={handleDownloadClick}
        isDownloading={isDownloading}
        status={dealBreakdownStatus}
      />
      <FundExposureSummaryTable status={fundExposureSummaryStatus} />
    </div>
  );
};
