import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Box,
  Divider,
  Drawer,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { AppLayoutWithTopMenu } from "../../../../common/AppLayoutWithTopMenu";
import { makeStyles } from "@mui/styles";
import {
  ILPLedTransaction,
  ParticipantAccessStatus,
} from "../../../domain/models/Transaction";
import { useTransactionsContext } from "../../state/TransactionsContext";
import DownloadIcon from "@mui/icons-material/Download";
import {
  TransactionNDAsLoaded,
  TransactionsState,
} from "../../state/TransactionsState";
import {
  NDAsParticipantSignatureSubmitted,
  RedirectedToSignNDAScreen,
} from "../../state/TransactionsEvent";
import { useAuthContext } from "../../../../authentication/presentation/state/AuthenticationContext";
import FormikTextField from "../../../../../common/components/formik/FormikTextField";
import SignatureTextField from "../../../../../common/components/SignatureTextField";
import { TableLayout } from "../../../../common/TableLayout";
import { INDADoc } from "../../../domain/models/TransactionDocument";
import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button } from "common/components/Button";

interface IProps {
  onClose: () => void;
  open: boolean;
  txn?: ILPLedTransaction;
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

export const SignNDAModal: React.FC<IProps> = ({ onClose, open, txn }) => {
  const { txnNDAsState, emitEvent } = useTransactionsContext();
  const classes = useStyles();

  const { user } = useAuthContext();

  const navigate = useNavigate();

  const currentUserName = `${user?.firstName} ${user?.lastName}`;

  const validationSchema = yup.object({
    signature: yup
      .string()
      .test(
        "signature",
        `Signature must match user name: ${currentUserName}`,
        (value) => {
          return (
            !value || value?.toLowerCase() === currentUserName.toLowerCase()
          );
        },
      )
      .required("Required"),
    institutionName: yup.string(),
    signatoryLegalName: yup.string().required("Required"),
    signatoryTitle: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      signature: "",
      institutionName: "",
      signatoryLegalName: "",
      signatoryTitle: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!txn) return;
      emitEvent!(
        new NDAsParticipantSignatureSubmitted({
          signature: {
            signatoryLegalName: values.signatoryLegalName,
            signatoryTitle: values.signatoryTitle,
            signatoryInstitutionName: values.institutionName,
          },
          txnId: txn.id,
          participantId: txn.requesterParticipant.id,
        }),
      );
    },
  });

  useEffect(() => {
    if (open && txn)
      emitEvent!(new RedirectedToSignNDAScreen({ txnId: txn.id }));
  }, [open]);

  const buildBasedOnState = (state: TransactionsState) => {
    if (state instanceof TransactionNDAsLoaded) {
      if (!txn) return null;

      const accessStatus = txn.requesterAccessStatus;

      return (
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={onClose}
          classes={{ paper: classes.drawerPaper }}
        >
          <AppLayoutWithTopMenu centeredContent={true} onClose={onClose}>
            <Stack p={8}>
              <form onSubmit={formik.submitForm}>
                <Stack maxWidth={560} spacing={4}>
                  <Stack>
                    <Typography variant="h3">
                      {accessStatus == ParticipantAccessStatus.HasToSign
                        ? "Sign NDAs"
                        : "Review NDAs"}
                    </Typography>
                    <Typography>
                      {accessStatus == ParticipantAccessStatus.HasToSign
                        ? "Digitally sign the NDAs to view the data rooms for the funds in this portfolio. You can download the files to review with counsel, if required."
                        : "A person from your organization has already reviewed and signed the NDAs for data connected with these assets. You are covered by these agreements and do not need to sign them separately. They can reviewed and downloaded below."}
                    </Typography>
                  </Stack>
                  <Divider />
                  <TableLayout
                    minWidth="500px"
                    headerElems={[
                      {
                        label: "Asset name",
                        key: "fundName",
                        width: "50%",
                      },
                      {
                        label: "documents",
                        key: "proceeds",
                        align: "left",
                        width: "30%",
                      },
                    ]}
                    rows={
                      <React.Fragment>
                        {state.ndas.map((nda) => (
                          <NDATableRow nda={nda} />
                        ))}
                      </React.Fragment>
                    }
                  />
                  {accessStatus == ParticipantAccessStatus.HasToSign && (
                    <Stack spacing={4}>
                      <Divider />
                      <Stack spacing={2} px={4}>
                        <FormikTextField
                          formik={formik}
                          name="institutionName"
                          label="Institution Name (Optional)"
                        />
                        <FormikTextField
                          formik={formik}
                          label="Signatory Legal Name"
                          name="signatoryLegalName"
                        />
                        <FormikTextField
                          formik={formik}
                          label="Signatory Title (Optional)"
                          name="signatoryTitle"
                        />
                        <FormikTextField
                          TextFieldComponent={SignatureTextField}
                          formik={formik}
                          name="signature"
                        />
                      </Stack>
                    </Stack>
                  )}

                  <Divider />
                  <Stack alignItems="center">
                    <Button
                      type={BUTTON_TYPES.SECONDARY}
                      size="large"
                      onClick={
                        accessStatus == ParticipantAccessStatus.HasToSign
                          ? formik.submitForm
                          : () =>
                              navigate(
                                `${LP_ROUTES.Transactions}/${txn.id}/details/`,
                              )
                      }
                      fullWidth
                    >
                      {accessStatus == ParticipantAccessStatus.HasToSign
                        ? "Sign"
                        : "Continue"}
                    </Button>
                    <br />
                    {accessStatus == ParticipantAccessStatus.HasToSign && (
                      <Button
                        size="large"
                        onClick={() =>
                          navigate(
                            `${LP_ROUTES.Transactions}/${txn.id}/markup/`,
                          )
                        }
                        fullWidth
                      >
                        Markup These NDAs
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AppLayoutWithTopMenu>
        </Drawer>
      );
    }
    return <></>;
  };

  return <>{buildBasedOnState(txnNDAsState!)}</>;
};

interface INDATableRowProps {
  nda: INDADoc;
}

export const NDATableRow: React.FC<INDATableRowProps> = ({ nda }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography fontWeight="bold">
          <Box pr={4}>{nda.issuerName}</Box>
        </Typography>
      </TableCell>
      <TableCell sx={{ pl: 1 }}>
        <Button
          icon={<DownloadIcon />}
          href={nda.presignedUrl}
          rel="noopener noreferrer"
          target="_blank"
          as="a"
        >
          {nda.documentName}
        </Button>
      </TableCell>
    </TableRow>
  );
};
