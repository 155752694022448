import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import {
  Bid,
  bidFromResponse,
  BidPricingUpsertForm,
  IPartialBid,
  IWholePortfolioBid,
} from "../models/Bidding";
import TapClient from "../../../../common/clients/TapClient";

export class SubmitBid implements IAsyncUseCase<IParams, Bid> {
  call: (params: IParams) => Promise<Either<Failure, Bid>> = async ({
    form,
  }) => {
    return await new TapClient(
      `/transactions/${form.txnId}/bidding/bid/submit/`,
      form,
    )
      .post()
      .then((resp: IWholePortfolioBid | IPartialBid) => {
        return right(bidFromResponse(resp));
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface ISubmitBidForm {
  bidId: string;
  txnId: string;
}

interface IParams {
  form: ISubmitBidForm;
}
