import { useEffect, useState } from "react";
import { Progress } from "shadcn/ui/progress";

const TIME_MULTIPLIER = 100;

const incrementalProgressSteps = [
  {
    value: 50,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 20,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 30,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 40,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 45,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 50,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 64,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 70,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
  {
    value: Math.random() * 75,
    time: Math.random() * 1000 * TIME_MULTIPLIER,
  },
];

const totalOutOf = incrementalProgressSteps.reduce(
  (acc, step) => acc + step.value * 3,
  0,
);

const useGenerateIncrementalProgress = () => {
  const [progress, setProgress] = useState(incrementalProgressSteps[0].value);

  const animate = () => {
    incrementalProgressSteps.forEach((step) => {
      setTimeout(() => {
        setProgress((prev) => step.value + prev);
      }, step.time);
    });
  };

  useEffect(() => {
    animate();
  }, []);

  return {
    progress,
    outOf: totalOutOf, // padding to make sure the progress bar is never full
  };
};

export const UiProgress = ({
  title,
  value,
  value2,
  label,
  label2,
  outOf,
  labelOutOf,
  animate,
  flash,
  incremental,
}: {
  title: string;
  value: number;
  value2?: number;
  outOf: number;
  label?: string;
  label2?: string;
  labelOutOf?: string;
  flash?: boolean;
  animate?: boolean;
  incremental?: boolean;
}) => {
  const { progress, outOf: progressOutOf } = useGenerateIncrementalProgress();

  return (
    <div className="py-5 pb-0 -mb-4">
      <p className="text-xs font-semibold text-nomad-800 mb-4">{title}</p>
      <div className="relative">
        <Progress
          value={incremental ? progress : value}
          value2={value2}
          label={label}
          label2={label2}
          outOf={incremental ? progressOutOf : outOf}
          labelOutOf={labelOutOf}
          animate={animate}
          flash={flash || incremental}
        />
      </div>
    </div>
  );
};
