import axios from "axios";
import { Either, left, right } from "fp-ts/lib/Either";
import slugify from "slugify";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { FileUploadS3Response } from "experiences/common/FileUploadS3Response";

const API_PATH = "/holding/process/get-upload-urls/";
const API_PAYLOAD_KEY = "files_names";

export class UploadCapitalAccounts implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<
    Either<
      Failure,
      {
        processingId: string;
      }
    >
  > = async ({ files }) => {
    const formattedFiles = new Map(
      files.map((file) => [slugify(file.name), file]),
    );
    // payload should be an array of strings with the formatted file names values
    const createUrlsPayload = Array.from(formattedFiles.keys());

    return await new TapClient(API_PATH, {
      [API_PAYLOAD_KEY]: createUrlsPayload,
    })
      .withoutCasing()
      .post()
      .then(async (resp: FileUploadS3Response) => {
        const fileFormData = resp.urls
          .map((fileEntry) => {
            const [fileName, { fields, url }] = Object.entries(fileEntry)[0];
            const file = formattedFiles.get(fileName);

            if (!file) {
              return;
            }

            const formData = new FormData();

            formData.append("key", fields.key);
            formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
            formData.append("policy", fields.policy);
            formData.append("signature", fields.signature);
            formData.append("file", file);

            return {
              formData,
              url,
              type: file.type,
            };
          })
          .filter(Boolean);

        return await Promise.all(
          fileFormData.map(({ formData, url, type }) =>
            axios
              .post(url, formData, {
                headers: {
                  "Content-Type": type,
                },
              })
              .then(() => right(new NoReturn()))
              .catch((error) => left(error)),
          ),
        )
          .then((results) => {
            // TODO?: Deeper error handling, verify if all results are right
            return right({
              processingId: resp.processing_id,
            });
          })
          .catch((error) => left(error));
      })
      .catch((resp: any) => {
        return left(new Failure(resp.response));
      });
  };
}

interface IParams {
  files: File[];
}
