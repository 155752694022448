import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useTransactionsContext } from "../../state/TransactionsContext";
import {
  IParticipant,
  ParticipantCapacity,
  ParticipantRole,
} from "../../../domain/models/Participant";
import { useAuthContext } from "../../../../authentication/presentation/state/AuthenticationContext";
import { SendInviteButtonClicked } from "../../state/participants/ParticipantsEvent";
import { ManageParticipantsButtonClicked } from "../../state/TransactionsEvent";
import { getParticipantById } from "./utils";
import { ParticipantListLoaded } from "../../state/participants/ParticipantsState";

// Requires: txnId
// Requires context: TransactionsContext, AuthenticationContext
export const useTxnParticipantsScreen = () => {
  const { txnId } = useParams();
  const { participantsListState, emitPcptEvent } = useTransactionsContext();
  const [loading, setLoading] = useState(true);
  const { user } = useAuthContext();

  const [showSendDataRoomUpdateModal, setShowSendDataRoomUpdateModal] =
    useState(false);
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState<
    IParticipant[]
  >([]);
  const [currUserParticipant, setCurrUserParticipant] =
    useState<IParticipant | null>(null);
  const [participants, setParticipants] = useState<IParticipant[]>([]);

  useEffect(() => {
    setLoading(true);
    // Initial load
    emitPcptEvent!(new ManageParticipantsButtonClicked({ txnId: txnId! }));
  }, []);

  useEffect(() => {
    if (participantsListState instanceof ParticipantListLoaded) {
      setLoading(false);
      const currentUser = getParticipantById({
        participants: participantsListState.participants,
        participantId: user!.id,
      });
      setCurrUserParticipant(currentUser);
      setParticipants(participantsListState.participants);
    }
  }, [participantsListState]);

  const onSendInviteToSelectedParticipantsClick = () => {
    emitPcptEvent!(
      new SendInviteButtonClicked({
        txnId: txnId!,
        pcpts: selectedParticipants,
      }),
    );
  };

  const onSelectParticipants = (pcpts: IParticipant[]) => {
    setSelectedParticipants((prev) => {
      const next = prev.filter(
        (p) => !pcpts.map((pcpt) => pcpt.id).includes(p.id),
      );
      next.push(...pcpts);
      return next;
    });
  };

  const onUnselectParticipants = (pcpts: IParticipant[]) => {
    setSelectedParticipants((prev) => {
      return prev.filter((p) => !pcpts.map((pcpt) => pcpt.id).includes(p.id));
    });
  };

  const userCanSendInvites =
    currUserParticipant?.capacity == ParticipantCapacity.Seller ||
    currUserParticipant?.role == ParticipantRole.TapAdvisor;

  const addParticipantsButtonVisible =
    currUserParticipant?.capacity == ParticipantCapacity.Seller ||
    currUserParticipant?.capacity == ParticipantCapacity.Buyer;

  const showSendDataRoomUpdateButton =
    selectedParticipants.length > 0 && userCanSendInvites;

  console.log({ loading, participants, currUserParticipant });

  const openAddParticipantModal = () => {
    setShowAddParticipantModal(true);
  };

  const closeAddParticipantModal = () => {
    setShowAddParticipantModal(false);
  };

  return {
    showSendDataRoomUpdateModal,
    setShowSendDataRoomUpdateModal,
    showAddParticipantModal,
    setShowAddParticipantModal,
    selectedParticipants,
    setSelectedParticipants,
    onSendInviteToSelectedParticipantsClick,
    onSelectParticipants,
    onUnselectParticipants,
    currUserParticipant,
    userCanSendInvites,
    addParticipantsButtonVisible,
    showSendDataRoomUpdateButton,
    openAddParticipantModal,
    closeAddParticipantModal,
  };
};
