import { useEffect } from "react";
import { Navigate } from "react-router";

import { UNIVERSAL_ROUTES } from "common/constants/routes";
import { CurrentUserLogoutRequested } from "experiences/authentication/presentation/state/AuthenticationEvent";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

export const Logout = () => {
  const { user, emitEvent } = useAuthContext();

  useEffect(() => {
    emitEvent!(new CurrentUserLogoutRequested());
  }, []);

  // Go to login if there's no user
  if (!user) {
    return <Navigate to={UNIVERSAL_ROUTES.AuthLogin} replace />;
  }

  return null;
};
