import { IssuerType } from "common/@types/models/Issuer";
import { FundGeography } from "../../../common/models/FundGeography";
import { FundStrategy } from "../../../common/models/FundStrategy";
import { Range } from "../../../common/models/Range";
import { IDataRoom } from "./DataRoom";
import { IParticipant } from "./Participant";
import { ITransactionDocument } from "./TransactionDocument";

export enum TransactionStatus {
  Live = 1,
  Draft = 2,
  Pending = 3,
  Settling = 4,
  Settled = 5,
  Cancelled = 6,

  // NOTE: the following statuses are used for the
  // the logic to render the transaction outline in the
  // overview screen is dependent on the order of these
  // values if this list is updated to disregard the order,
  // the logic in the OverviewScreen.tsx must have a
  // separate enum that preserves order of the statuses
  PreliminaryInterest = 1001,
  Round1 = 1002,
  Round2 = 1003,
  Participation = 1004,
}

// export const TransactionStatusNameMap = new Map<TransactionStatus, String>([]);

export enum TransactionType {
  LPLed = 1,
  Tender = 2,
}

export interface ITransactionPeriod {
  name: string;
  period: Range<string>;
}

export interface ITransaction {
  id: string;
  closingDate?: string;
  name: string;
  type: TransactionType;
  periods?: ITransactionPeriod[];
}

export class Transaction implements ITransaction {
  id: string;
  closingDate?: string;
  name: string;
  type: TransactionType;
  periods?: ITransactionPeriod[];

  constructor({ id, closingDate, name, type, periods }: ITransaction) {
    this.id = id;
    this.closingDate = closingDate;
    this.name = name;
    this.type = type;
    this.periods = periods;
  }
}

export interface ILPLedTransaction extends ITransaction {
  stats?: ITransactionStats;
  fundsCount: number;
  accumNav: number;
  closingDate?: string;
  isNew: boolean;
  currentStatus: TransactionStatus;
  requesterParticipant: IParticipant;
  teaser?: ITransactionDocument;
  requesterAccessStatus: ParticipantAccessStatus;
}

export enum ParticipantAccessStatus {
  HasToSign = 1,
  CanAccessWithAcknowledgement = 2,
  CanAccessDirectly = 3,
  HasToSignPlatformNDA = 4,
}

export class LPLedTransaction extends Transaction implements ILPLedTransaction {
  fundsCount: number;
  accumNav: number;
  stats?: ITransactionStats;
  isNew: boolean;
  currentStatus: TransactionStatus;
  requesterParticipant: IParticipant;
  teaser?: ITransactionDocument;
  requesterAccessStatus: ParticipantAccessStatus;

  constructor({
    fundsCount,
    accumNav,
    isNew,
    stats,
    currentStatus,
    requesterParticipant,
    requesterAccessStatus,
    teaser,
    ...other
  }: ILPLedTransaction) {
    super({ ...other });

    this.fundsCount = fundsCount;
    this.accumNav = accumNav;
    this.isNew = isNew;
    this.currentStatus = currentStatus;
    this.requesterParticipant = requesterParticipant;
    this.teaser = teaser;
    this.requesterAccessStatus = requesterAccessStatus;
    if (stats) {
      if (stats.type == IssuerType.Fund) {
        this.stats = new FundsStats(stats as IFundsStats);
      } else {
        this.stats = new CompaniesStats(stats as ICompaniesStats);
      }
    }
  }
}

export interface ISellerPerspectiveTxn extends ILPLedTransaction {
  inviteeCount: number;
  participantCount: number;
  bidCount: number;
}

export class SellerPerspectiveTxn extends LPLedTransaction {
  inviteeCount: number;
  participantCount: number;
  bidCount: number;

  constructor({
    inviteeCount,
    participantCount,
    bidCount,
    ...txn
  }: ISellerPerspectiveTxn) {
    super({ ...txn });
    this.inviteeCount = inviteeCount;
    this.participantCount = participantCount;
    this.bidCount = bidCount;
  }
}

export interface IBuyerPersepectiveTxn extends LPLedTransaction {}

export class BuyerPersepectiveTxn extends LPLedTransaction {
  constructor({ ...txn }: IBuyerPersepectiveTxn) {
    super({ ...txn });
  }
}

// TODO: add all the fields of the transaction to this type
// for now, we only added what is necessary for the UI
export interface IFullTransaction {
  id: string;
  name: string;
  tenderOfferQuarter: string;
  currentStatus: TransactionStatus;
}

export interface ITransactionSummary {
  transaction: ITransaction;
  holdingsCount: number;
  committedTotal: number;
  nav: number;
  percentCalled: number;
  viewerParticipant: IParticipant;
}

export interface ITransactionStats {
  type: IssuerType;
}

export interface IFundsStats extends ITransactionStats {
  vintageRange: Range<number>;
  geographies: FundGeography[];
  strategies: FundStrategy[];
}

export interface ICompaniesStats extends ITransactionStats {}

export class TransactionStats implements ITransactionStats {
  type: IssuerType;

  constructor({ type }: ITransactionStats) {
    this.type = type;
  }
}

export class FundsStats extends TransactionStats implements IFundsStats {
  vintageRange: Range<number>;
  geographies: FundGeography[];
  strategies: FundStrategy[];

  constructor({ type, vintageRange, geographies, strategies }: IFundsStats) {
    super({ type });
    this.vintageRange = vintageRange;
    this.geographies = geographies;
    this.strategies = strategies;
  }
}

export class CompaniesStats
  extends TransactionStats
  implements ICompaniesStats {}

export enum TransactionSettingType {
  OnlyWholePortfolioBidsAllowed = 1,
  AdoptPlatformNda = 2,
  AllowBids = 3,
  DataRoom = 4,
  Qms = 5,
}

export interface ITransactionSettings {
  type: TransactionSettingType;
  isActive: boolean;
}
