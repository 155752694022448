import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";

import Loader from "common/components/TapLoader";
import { useApprovedBuyersContext } from "../state/ApprovedBuyersContext";
import { RequestedBuyersLoaded } from "../state/ApprovedBuyersState";
import { LoadRequestedBuyers } from "../state/ApprovedBuyerEvent";
import { PageHead } from "common/components/PageHead";
import { RequestedBuyersTable } from "../components/RequestedBuyersTable";

export const RequestedBuyers = () => {
  const { requestedBuyerTablePageState, emitEvent } =
    useApprovedBuyersContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    emitEvent!(new LoadRequestedBuyers());
  }, []);

  const filteredBuyers =
    requestedBuyerTablePageState instanceof RequestedBuyersLoaded
      ? requestedBuyerTablePageState.requestedBuyers.filter((buyer) =>
          buyer.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

  return (
    <>
      <Stack width="100%">
        <Helmet title="Requested" />
        <PageHead title="Requested Buyers" />
      </Stack>
      <Stack style={{ width: "100%" }}>
        {requestedBuyerTablePageState instanceof RequestedBuyersLoaded ? (
          <RequestedBuyersTable
            requestedBuyers={filteredBuyers}
            searchQuery={search}
          />
        ) : (
          <Loader />
        )}
      </Stack>
    </>
  );
};
