import { Either, left, right } from "fp-ts/lib/Either";

import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";

import { FundFilterSpecs } from "../models/IssuerFilterSpecs";
import { PageSpecs } from "../../../../common/@types/models/PageSpecs";
import { FundsPage } from "../models/FundsPage";
import { FundGeography } from "../../../common/models/FundGeography";
import { FundStrategy } from "../../../common/models/FundStrategy";

interface IResp {
  items: {
    id: string;
    name: string;
    managerName: string;
    geography: FundGeography;
    strategy: FundStrategy;
    vintage: number;
    size: number;
    isWatchlisted: boolean;
    logoUrl: string;
  }[];
  total: number;
  perPage: number;
  pageIndex: number;
  pageCount: number;
}

export class RetrieveFilteredFunds
  implements IAsyncUseCase<IParams, FundsPage>
{
  call: (params: IParams) => Promise<Either<Failure, FundsPage>> = async (
    params,
  ) => {
    return await new TapClient("/funds/", params)
      .post()
      .then((resp: IResp) => {
        return right({
          funds: resp.items.map((item) => {
            return {
              ...item,
              size: new DollarAmount(item.size),
            };
          }),
          totalFunds: resp.total,
          pageIndex: resp.pageIndex,
          pageCount: resp.pageCount,
          perPage: resp.perPage,
        });
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  paginationSpecs: PageSpecs;
  filters: FundFilterSpecs;
}
