import { gql } from "@apollo/client";

export const GetPortfolioSummaryExposureQuery = gql`
  query Query(
    $entitiesIds: [String!]!
    $reportDate: Date!
    $groupBy: FundExposureGrouping!
  ) {
    fundSummaryExposure(
      entitiesIds: $entitiesIds
      reportDate: $reportDate
      groupBy: $groupBy
    ) {
      groupingData {
        groupedBy
        aggregateData {
          groupKey
          groupIds
          fundSize
          portfolioPercent
          fundsCount
          nav
          committed
          unfunded
          called
          distributed
          netMoic
          netIrr
        }
        topLevelAggregateData {
          groupKey
          groupIds
          fundSize
          portfolioPercent
          fundsCount
          nav
          committed
          unfunded
          called
          distributed
          netMoic
          netIrr
        }
      }
      reportData {
        tapFundId
        fundName
        tapManagerId
        managerName
        fundSize
        fundCommittedCapital
        strategy
        vintage
        geography
        reportDate
        lpCommittedCapital
        lpContributedCapitalItd
        lpDistributedCapitalItd
        lpRemainingUnfunded
        lpEndingCapitalAccountValue
        lpCurrentExposure
        lpInterimContributions
        lpInterimDistributions
        unrealizedInvestmentCount
        top3Concentration
        top10Concentration
        percentPublic
        weightedAvgRevenueGrowth
        weightedAvgEbitdaMargin
        weightedAvgEbitdaMultiple
        weightedAvgLeverage
        fundNavQoqPercentChg1q
        fundNavQoqPercentChg2q
        fundNavQoqPercentChg3q
        fundNavYoyPercentChangeFy1
        fundNavYoyPercentChangeFy2
        tapPriceEstimate
        tapPriceEstimateCertainty
        baseManagementFee
        carry
        hurdleRate
        ptpRiskLevel
        ptpQueueLength
        rofr
        rofo
        blocker
        domicile
        fundSeriesAverageMoic
        managerId
        isHumanVerified
        issuerId
        sector
        portfolioPercent
      }
    }
  }
`;
