import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IIssuer } from "common/@types/models/Issuer";

export class ListIssuersForAsk implements IAsyncUseCase<IParams, IIssuer[]> {
  call: (params: IParams) => Promise<Either<Failure, IIssuer[]>> =
    async ({}) => {
      return await new TapClient(`holding/issuers/list/`)
        .get({})
        .then((resp: IIssuer[]) => {
          return right(resp);
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {}
