import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { DocumentTypeEnum } from "experiences/documents/models/DocumentType";
import { IDocumentAccessPackage } from "experiences/documents/models/DocumentAccessPackage";

export class GetNDAAccessPackageForUser
  implements IAsyncUseCase<IParams, IDocumentAccessPackage>
{
  call: (params: IParams) => Promise<Either<Failure, IDocumentAccessPackage>> =
    async (params) => {
      return await new TapClient(`documents/nda/access-package/get/`, params)
        .post()
        .then((resp: IDocumentAccessPackage) => right(resp))
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {
  documentType: DocumentTypeEnum;
}
