import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";

import { LoginSessionClaimLinkActivated } from "experiences/authentication/presentation/state/AuthenticationEvent";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { LoadingCentered } from "experiences/authentication/presentation/components/Loading";
import { startScreenForExperienceType } from "Routes";

export const SessionClaim = () => {
  const [loading, setLoading] = useState(true);
  const { user, emitEvent, sessionClaimState } = useAuthContext();
  const { idb64, token } = useParams();

  useEffect(() => {
    setLoading(true);
    emitEvent!(
      new LoginSessionClaimLinkActivated({ idb64: idb64!, token: token! }),
    );
  }, []);

  useEffect(() => {
    if (user?.email) {
      setLoading(false);
    }
  }, [sessionClaimState, user]);

  if (loading) {
    return <LoadingCentered />;
  }

  if (user) {
    // When user does exist, go to the start screen for the experience type of the user
    return (
      <Navigate
        to={startScreenForExperienceType[user.experienceType]}
        replace
      />
    );
  }

  return null;
};
