import { ReactNode } from "react";
import { Icon } from "@phosphor-icons/react";

import { cn } from "common/utils";

export const OverlayWithIcon = ({
  title,
  subtitle,
  children,
  Icon,
  spinIcon,
  className,
  customIcon,
}: {
  title: string;
  subtitle: string | ReactNode;
  children?: ReactNode;
  Icon?: Icon;
  spinIcon?: boolean;
  className?: string;
  customIcon?: ReactNode;
}) => {
  return (
    <div
      className={cn(
        "h-full w-full relative flex items-center justify-center",
        className,
      )}
    >
      <div className="w-full max-w-96 flex flex-col items-center gap-4">
        {Icon && (
          <Icon
            size={24}
            className={cn({
              "animate-spin": spinIcon,
            })}
          />
        )}
        {customIcon}
        <div>
          <h2 className="text-sm font-medium font-sans select-none w-full text-center mb-2">
            {title}
          </h2>
          <p className="text-sm font-normal font-sans select-none w-full text-center">
            {subtitle}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};
