import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { portfolioClient } from "common/clients/ApolloClient";
import TapClient from "common/clients/TapClient";
import { LP_ROUTES } from "common/constants/routes";
import { IPortfolioDocument } from "experiences/portfolio-v2/domain/models/PortfolioDocuments";
import { GetPortfolioDocumentsQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioDocumentsQuery";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { PortfolioV2Routes } from "./navigation";

export const usePortfolioDocuments = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<IPortfolioDocument[]>([]);
  const { selectedEntitiesIds } = useContext(PortfolioContext);

  // useEffect(() => {
  //   setDocuments(mockPortfolioDocuments);
  // }, []);

  const { data, loading, refetch } = useQuery<{
    documentsInformation: IPortfolioDocument[];
  }>(GetPortfolioDocumentsQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
    },
    skip: selectedEntitiesIds.length === 0,
  });

  const getDocuments = async () => {
    if (selectedEntitiesIds.length === 0) return;
    await refetch({
      entitiesIds: selectedEntitiesIds,
    }).then((res) => {
      setDocuments(res.data?.documentsInformation || []);
    });
  };

  useEffect(() => {
    getDocuments();
  }, [selectedEntitiesIds]);

  const handleUploadDocumentsClick = () => {
    const path = `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.UploadDocuments}`;
    console.log("navigating to", path);
    navigate(path);
  };

  const handleDownloadAll = () => {
    console.log("downloading all documents");
  };

  const handleFileDownload = async ({
    document, // we only need this for the document.fileUrl but keeping it for now
  }: {
    document: IPortfolioDocument;
  }) => {
    const path = `/portfolio/file-download-url/get/`;

    // Omit the 1st character (a "/" character)
    const filePath = document.fileUrl;

    console.log("DOWNLOADING FILE FORM PORTFOLIO", filePath);

    return await new TapClient(path, {
      filePath: filePath,
      version: "v2", // New dataroom download requires this argument on the api
    })
      .post()
      .then((resp) => {
        window.open(resp.url, "_blank");
        // TODO: Add analytics
        // TODO: Add error handling
      })
      .catch((resp: any) => {
        console.error(resp);
      });
  };

  return {
    documents,
    loading: loading,
    refetch,
    handleUploadDocumentsClick,
    handleDownloadAll,
    handleFileDownload,
  };
};
