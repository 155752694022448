import { Box, Divider, Stack, Typography } from "@mui/material";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { Percentage } from "common/@types/app/Percentage";
import { Bid } from "experiences/transactions/domain/models/Bidding";

interface IProps {
  bid: Bid;
}

export const BidDetailsTable: React.FC<IProps> = ({ bid }) => {
  return (
    <Stack
      divider={<Divider />}
      sx={{
        border: "1px solid #E7E8EC",
        borderRadius: "6px",
      }}
    >
      {[
        {
          label: "Transaction Name",
          value: bid.txnName!,
        },
        {
          label: "Bidder",
          value: bid.bidderOrgName!,
        },
        {
          label: "Proceeds",
          value: new DollarAmount(bid.dollarValue!).formatted(),
        },
        {
          label: "Price",
          value: new Percentage(bid.percentValue! * 100).formatted(),
        },
      ].map((detail) => (
        <BidDetail {...detail} />
      ))}
    </Stack>
  );
};

interface IBidDetailProps {
  label: string;
  value: string;
}

export const BidDetail: React.FC<IBidDetailProps> = ({ label, value }) => {
  return (
    <Stack direction="row">
      <Box
        height={54}
        width={224}
        sx={{ backgroundColor: "#F5F4F1" }}
        pl={4}
        pt={2}
      >
        <Typography fontWeight="bold">{label}</Typography>
      </Box>
      <Box height={54} width={296} pl={4} pt={2}>
        <Typography color="#737476">{value}</Typography>
      </Box>
    </Stack>
  );
};
