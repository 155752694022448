import { DollarAmount } from "common/@types/app/DollarAmount";
import { Fund } from "experiences/funds/domain/models/Fund";
import { IssuerType } from "common/@types/models/Issuer";
import { viewpointRelatedParties } from "experiences/funds/presentation/components/viewpoint/viewpointRelatedParties";
import { viewpointCashFlows } from "experiences/funds/presentation/components/viewpoint/viewpointCashFlows";
import { viewpointInvestments } from "experiences/funds/presentation/components/viewpoint/viewpointInvestments";
import { VIEWPOINT_PARTNERS_PRICE_NAV_ONLY } from "experiences/funds/presentation/components/viewpoint/viewpointPriceHistory";

export const VIEWPOINT_PARTNERS_DEMO_NAMES = [
  "ViewPoint Partners Fund IV",
  "ViewPoint Partners IX Demo Data",
  "ViewPoint Growth III",
];

// manually load the data from this file https://docs.google.com/spreadsheets/d/1xlyOjQU5yXuve2ZtidDOJmjKAow1WgoM7pjBhNC8nUg/edit#gid=522990320
export const VIEWPOINT_PARTNERS_DEMO_DATA: Fund = {
  id: "viewpoint_fund_1",
  name: VIEWPOINT_PARTNERS_DEMO_NAMES[0],
  vintage: 2017,
  geography: 1,
  size: new DollarAmount(80030000),
  structure: "Commingled",
  domicile: "United States",
  country: "United States",
  psin: "VIEWPT-VC-04-17-FA",
  strategy: 1,
  managerName: "Viewpoint Partners",
  isWatchlisted: false,
  type: IssuerType.Fund,
  relatedParties: viewpointRelatedParties,
  cashFlows: viewpointCashFlows,
  investments: viewpointInvestments,
  historicPricing: VIEWPOINT_PARTNERS_PRICE_NAV_ONLY,
  marketPricePoints: 8700,
};

export const VIEWPOINT_FUND_DETAILS = {
  marketPricePoints: VIEWPOINT_PARTNERS_DEMO_DATA.marketPricePoints,
  openInterest: VIEWPOINT_PARTNERS_DEMO_DATA.openInterest,
};

export const VIEWPOINT_FUND_ID = "8i3QZdGDbdA2n";
