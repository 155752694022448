import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";

export interface ITenderParticipationInterest {
  reservePrice: number;
  tenderAmount: number;
}

interface IParams {
  txnId: string;
}

export class GetParticipationInterest
  implements IAsyncUseCase<IParams, ITenderParticipationInterest>
{
  call: (
    params: IParams,
  ) => Promise<Either<Failure, ITenderParticipationInterest>> = async ({
    txnId,
  }) => {
    return await new TapClient(
      `/transactions/tenders/${txnId}/participant-interest/`,
    )
      .get({})
      .then((resp: ITenderParticipationInterest) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}
