import { HarveyEnum } from "experiences/portfolio/domain/models/Holding";

interface IProps {
  props: {};
}

const EmptyHarvey: React.FC<IProps> = ({ props }) => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 4C7.5 5.933 5.933 7.5 4 7.5C2.067 7.5 0.5 5.933 0.5 4C0.5 2.067 2.067 0.5 4 0.5C5.933 0.5 7.5 2.067 7.5 4Z"
      stroke="#737476"
    />
  </svg>
);

const QuarterHarvey: React.FC<IProps> = ({ props }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="4" cy="4" r="3.5" stroke="#737476" />
    <path
      d="M6.22228 0.674121C6.88008 1.11365 7.39277 1.73836 7.69552 2.46927C7.8975 2.95689 7.99998 3.47717 8 3.9999L5.5 4H4V0C4.79112 0 5.56448 0.234596 6.22228 0.674121Z"
      fill="#737476"
    />
  </svg>
);

// HalfHarvey
const HalfHarvey: React.FC<IProps> = ({ props }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="4" cy="4" r="3.5" stroke="#737476" />
    <path
      d="M4 0C5.06087 1.26507e-08 6.07828 0.421427 6.82843 1.17157C7.57857 1.92172 8 2.93913 8 4C8 5.06087 7.57857 6.07828 6.82843 6.82843C6.07828 7.57857 5.06087 8 4 8L4 4V0Z"
      fill="#737476"
    />
  </svg>
);

// ThreeFourthsHarvey
const ThreeFourthsHarvey: React.FC<IProps> = ({ props }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="4" cy="4" r="3.5" stroke="#737476" />
    <path
      d="M4 0C4.79113 9.43407e-09 5.56448 0.234596 6.22228 0.674122C6.88008 1.11365 7.39277 1.73836 7.69552 2.46927C7.99827 3.20017 8.07748 4.00444 7.92314 4.78036C7.7688 5.55629 7.38784 6.26902 6.82843 6.82843C6.26902 7.38784 5.55629 7.7688 4.78036 7.92314C4.00444 8.07748 3.20017 7.99827 2.46927 7.69552C1.73836 7.39277 1.11365 6.88008 0.674121 6.22228C0.234596 5.56448 -1.88681e-08 4.79113 0 4H4V0Z"
      fill="#737476"
    />
  </svg>
);

// FullHarvey
const FullHarvey = ({ props }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="4" cy="4" r="3.5" fill="#737476" stroke="#737476" />
  </svg>
);

interface IHarveyBallProps {
  harveyLevel: HarveyEnum;
  props: any;
}

const HarveyBall: React.FC<IHarveyBallProps> = ({ harveyLevel, ...props }) => {
  return harveyLevel === HarveyEnum.Full ? (
    <FullHarvey props={props} />
  ) : harveyLevel === HarveyEnum.ThreeFourths ? (
    <ThreeFourthsHarvey props={props} />
  ) : harveyLevel === HarveyEnum.Half ? (
    <HalfHarvey props={props} />
  ) : (
    <QuarterHarvey props={props} />
  );
};

export default HarveyBall;
