import { useState } from "react";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import {
  BidBulkUploadFunds,
  FundBid,
} from "experiences/funds/domain/usecases/BidBulkUploadFunds";

export const useIoiBulkUploadSubmit = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitIois = async ({
    iois,
    reconfirm,
  }: {
    iois: FundBid[];
    reconfirm?: boolean;
  }) => {
    setIsLoading(true);
    return await new BidBulkUploadFunds()
      .call({
        iois,
        options: {
          reconfirm,
        },
      })
      .then((resp) => {
        setIsLoading(false);
        return match<Failure, FundBid[], { success: boolean; response: any }>(
          (response: Failure) => {
            return { success: false, response };
          },
          (response) => {
            return { success: true, response };
          },
        )(resp);
      });
  };

  return {
    isLoading,
    submitIois,
  };
};
