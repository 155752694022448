import {
  Box,
  Stack,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Check } from "@phosphor-icons/react";
import { BUTTON_TYPES, Button } from "common/components/Button";
import FullModalContainer from "experiences/common/FullModalContainer";
import { ReviewStepper } from "experiences/common/ReviewStepper";
import styled from "styled-components";

const stepSizePx = 28;

export const CustomizedConnector = withStyles({
  vertical: {
    marginLeft: 9,
  },
  lineVertical: {
    borderLeftWidth: 2,
  },
  root: {
    display: "block",
    position: "relative",
    marginLeft: 0,
    left: stepSizePx / 2,
    borderColor: "#dbdcda",
  },
  line: {
    borderColor: "#dbdcda",
    position: "absolute",
  },
})(StepConnector);

const StyledStep = styled.div`
  width: ${stepSizePx}px;
  height: ${stepSizePx}px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 1px solid #dbdcda;
`;

const CompletedStepIcon = styled(StyledStep)`
  background-color: #fafaf8;
  border: 2px solid #bcb3a5;
  outline: 3px solid #dfdfd9;
`;

const StepLabelTitle = styled.h3`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  color: #21272d;

  cursor: default;
`;

const StepLabelDescription = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #737476;

  cursor: default;
`;

const CustomStepperIcon = ({ completed, ...asd }: StepIconProps) => {
  if (completed) {
    return (
      <CompletedStepIcon>
        <Check size={16} color={"#BCB3A5"} />
      </CompletedStepIcon>
    );
  }
  return <StyledStep></StyledStep>;
};

interface IGPMessageConfirmScreen {
  onClose?: () => void;
}

const GPMessageConfirmScreen: React.FC<IGPMessageConfirmScreen> = ({
  onClose = () => {},
}) => {
  return (
    <FullModalContainer
      topBarHeaderText={"Access Fund Details"}
      headerText={"GP Access Request Received"}
      subheaderText={
        "To preserve the confidentiality of private markets, our team will review your request to access this fund as a GP. "
      }
      onClose={onClose}
    >
      <Stack
        height={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={10}
        spacing={8}
      >
        <ReviewStepper
          steps={[
            {
              label: "Request Received",
              caption: "Our team has been notified of your access request.",
            },
            {
              label: "GP Status Verified",
              caption:
                "We confirm that you are the GP. If needed we will request documentation.",
            },
            {
              label: "Access Approved",
              caption:
                "Once we’ve confirmed your role, you will have full access to the fund details.",
            },
          ]}
        />
        <Button
          type={BUTTON_TYPES.SECONDARY}
          onClick={onClose}
          style={{
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Continue
        </Button>
      </Stack>
    </FullModalContainer>
  );
};

export default GPMessageConfirmScreen;
