import { Box } from "@mui/material";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import styled from "styled-components";

export const StyledList = styled.div<{
  showBorderTop?: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  & .fixed {
    table-layout: fixed;
  }

  & table.dashboard_table:not(.dont_hover) {
    tbody {
      max-width: 100%;
      tr {
        &:hover:not(:focus) {
          background-color: #fafaf8;
        }
      }
      & tr.dont_hover {
        &:hover {
          background-color: initial;
        }
      }
    }
  }
  & table.dashboard_table {
    display: table;
    width: inherit;
    max-width: inherit;
    border-collapse: collapse;
    overflow: hidden;

    thead {
      tr {
        background-color: #f8f8f5;
        border-bottom: 1px solid #dfdfd9;
        max-width: 100%;

        th {
          height: 40px;
          vertical-align: center;
          color: var(--branding-light, #737476);
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.12px;
          text-align: left;
          padding-left: 16px;
          padding-right: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:first-child {
            padding-left: 16px;
          }

          &:last-child {
            padding-right: 16px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #dfdfd9;
        height: 40px;
        max-width: 100%;
        overflow: hidden;

        transition: background-color 70ms ease-in-out;

        td {
          border-right: none;
          color: #21272d;
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.12px;
          padding-left: 16px;
          padding-right: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: default;

          &:first-child {
            padding-left: 16px;
          }

          &:last-child {
            padding-right: 16px;
          }

          /* bullet icons */
          img.table_bullet {
            display: inline-block;
          }
          img.table_bullet + img.table_bullet {
            margin-left: 8px;
          }
          img.table_bullet:last-of-type {
            margin-right: 8px;
          }

          &.locked {
            filter: blur(7px);
            opacity: 0.5;
            user-select: none;
          }
        }

        &.locked {
          border-bottom: none;
        }

        &:focus {
          background-color: #ebebe4;
          outline: none;
          &:hover {
            background-color: #d6d6cb;
          }
        }
      }

      tr:last-child {
        border-bottom: none;
      }

      tr.ioi_row {
        cursor: pointer;
      }
    }

    tfoot {
      tr {
        background-color: #f8f8f5;
        border-top: 1px solid #dfdfd9;
        height: 60px;

        td {
          border-right: none;
          color: #21272d;
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.12px;
          padding-left: 16px;
          padding-right: 16px;

          &:first-child {
            padding-left: 16px;
          }

          &:last-child {
            padding-right: 16px;
          }
        }
      }
    }

    ${(props) =>
      props.showBorderTop &&
      `
      border-top: 1px solid #dfdfd9;
    `}
  }

  /* min height so that verify permission overlay fits inside */
  &.locked {
    min-height: 320px;
  }

  /* hide rows if table is locked */
  & table.dashboard_table {
    /* display: none; */
    /* border-bottom: none; */
  }
  /* } */

  .dashboard_table-value {
    text-align: right;
  }

  .dashboard_table-psin {
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .dashboard_table-green {
    color: #53b656 !important;
  }

  .dashboard_table-red {
    color: #e74c3c !important;
  }

  .border_left {
    border-left: 1px solid #dfdfd9;
  }
`;

export const DashboardList = ({
  title,
  secondaryText,
  table,
  showBorderTop,
  onTitleClick,
  countLabel,
  actions,
  className,
}: {
  title?: string;
  secondaryText?: string;
  table: React.ReactNode;
  showBorderTop?: boolean;
  onTitleClick?: () => void;
  countLabel?: string | number;
  actions?: {
    label: string;
    onClick: () => void;
    type: BUTTON_TYPES;
    icon?: React.ReactNode;
  }[];
  className?: string;
}) => {
  return (
    <StyledList className={className}>
      <PageSectionTitleDivider
        showBorderTop={showBorderTop}
        secondaryText={secondaryText}
        onClick={onTitleClick}
        countLabel={countLabel}
      >
        {title}
      </PageSectionTitleDivider>
      <div style={{ width: "100%" }}>{table}</div>
      {actions && (
        <Box p={2}>
          {actions.map((action) => (
            <Button
              key={action.label}
              onClick={action.onClick}
              type={action.type}
              icon={action.icon}
            >
              {action.label}
            </Button>
          ))}
        </Box>
      )}
    </StyledList>
  );
};
