import React, { useState } from "react";
import { Radio, Stack, Typography } from "@mui/material";
import { Key, UserCircle, UserList } from "@phosphor-icons/react";
import { useParams } from "react-router";

import { cn } from "common/utils";
import { Button, BUTTON_TYPES } from "common/components/Button";
import FullModalContainer from "experiences/common/FullModalContainer";
import { useFundsContext } from "../../state/FundsContext";
import {
  AccessChoice,
  VerifyAccessChoiceSelected,
} from "../../state/FundsEvent";

interface IChoiceContainerProps {
  children: React.ReactNode;
  onClick: () => void;
  isActive: boolean;
}

export const ChoiceContainer: React.FC<IChoiceContainerProps> = ({
  children,
  onClick,
  isActive,
}) => {
  return (
    <div onClick={onClick} className="relative w-full h-72">
      <div className="absolute right-1 top-1">
        <Radio color="secondary" checked={isActive} />
      </div>
      <div
        className={cn(
          "cursor-pointer flex flex-col items-center justify-center w-full h-full rounded-lg outline outline-offset-2 select-none outline-zinc-200 hover:outline-nomad-300 transition-all",
          {
            "outline-nomad-300": isActive,
          },
        )}
      >
        {children}
      </div>
    </div>
  );
};

interface IChoiceContentProps {
  logo: React.ReactNode;
  text: string;
}

const ChoiceContent: React.FC<IChoiceContentProps> = ({ logo, text }) => {
  return (
    <Stack spacing={2} alignItems={"center"}>
      {logo}
      <Typography maxWidth={200} textAlign={"center"}>
        {text}
      </Typography>
    </Stack>
  );
};

interface IAccessSelectionProps {
  onClose?: () => void;
}

const AccessSelectionScreen: React.FC<IAccessSelectionProps> = ({
  onClose = () => {},
}) => {
  const [choice, setChoice] = useState<AccessChoice>();
  const { emitEvent } = useFundsContext();

  const { fundId } = useParams();

  return (
    <FullModalContainer
      topBarHeaderText={"Access Fund Details"}
      headerText={"How do you have access?"}
      subheaderText={
        "Select your relationship to this fund in order to get access to the underlying fund data"
      }
      onClose={onClose}
      childrenClassName="flex flex-col w-full max-w-3xl gap-4 p-4 box-border ml-auto mr-auto"
    >
      <div className="flex gap-4 w-full">
        <ChoiceContainer
          onClick={() => {
            setChoice(AccessChoice.LP);
          }}
          isActive={choice === AccessChoice.LP}
        >
          <ChoiceContent
            logo={<UserCircle size={56} color="#BCB3A5" />}
            text={"I am an LP in this fund"}
          />
        </ChoiceContainer>
        <ChoiceContainer
          onClick={() => {
            setChoice(AccessChoice.ApprovedBuyer);
          }}
          isActive={choice === AccessChoice.ApprovedBuyer}
        >
          <ChoiceContent
            logo={<UserList size={56} color="#BCB3A5" />}
            text={"I would like to be added as an approved buyer"}
          />
        </ChoiceContainer>
        <ChoiceContainer
          onClick={() => {
            setChoice(AccessChoice.GP);
          }}
          isActive={choice === AccessChoice.GP}
        >
          <ChoiceContent
            logo={<Key size={56} color="#BCB3A5" />}
            text={"I am the GP"}
          />
        </ChoiceContainer>
      </div>

      <Button
        type={BUTTON_TYPES.SECONDARY}
        onClick={() => {
          emitEvent!(
            new VerifyAccessChoiceSelected({
              choice: choice!,
              fundId: fundId!,
            }),
          );
        }}
        size="large"
        disabled={!choice}
        className="!mt-10"
      >
        Continue
      </Button>
    </FullModalContainer>
  );
};

export default AccessSelectionScreen;
