import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  IconButton,
  Modal,
  Radio,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Link, Link as PhosphorLink } from "@phosphor-icons/react";
import { Barcode } from "@phosphor-icons/react/dist/ssr";

import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { TapCheckbox } from "common/components/Checkbox";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { usePortfolioContext } from "../state/PortfolioContext";
import {
  LPPortalConnectionSubmitted,
  UploadHoldingListSubmitted,
} from "../state/PortfolioEvent";
import { LPPortal } from "experiences/portfolio/domain/models/LPPortal";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  generateUserFields,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { listOfLpPortals } from "../components/LpPortalList";

export const LPPortalNameInput = styled.input`
  display: block;
  color: #21272d;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.11px;
  border-radius: 6px;
  border: 1px solid #dfdfd9;
  background: rgba(255, 255, 255, 0.92);
  padding: 8px 12px;
  box-sizing: border-box;
  outline: none;
  min-width: 206px;

  &:hover {
    border: 1px solid #bcb3a5;
  }

  &:focus {
    border: 1px solid #bcb3a5;
    box-shadow: 0 0 0 3px rgba(188, 179, 165, 0.25);
  }
`;

export const lpListAvailable = [
  {
    imagePath: "/images/portals/arch-logo.svg",
    portalId: LPPortal.Arch,
    altText: "",
  },
  {
    imagePath: "/images/portals/s&p-logo.png",
    portalId: LPPortal.SnP,
    altText: "",
  },
  {
    imagePath: "/images/portals/canoe-logo.svg",
    portalId: LPPortal.Canoe,
    altText: "",
  },
  {
    imagePath: "/images/portals/cobalt-logo.png",
    portalId: LPPortal.Cobalt,
    altText: "",
  },
  {
    imagePath: "/images/portals/solovis-logo.png",
    portalId: LPPortal.Solovis,
    altText: "",
  },
  {
    imagePath: "/images/portals/burgiss-logo.png",
    portalId: LPPortal.Burgiss,
    altText: "",
  },
  {
    imagePath: "/images/portals/efront-logo.png",
    portalId: LPPortal.Efront,
    altText: "",
  },
  {
    imagePath: "/images/portals/northern-trust-logo.png",
    portalId: LPPortal.NorthernTrust,
    altText: "",
  },
  {
    imagePath: "/images/portals/addepar-logo.png",
    portalId: LPPortal.Addepar,
    altText: "",
  },
  {
    imagePath: "/images/portals/step-stone-logo.png",
    portalId: LPPortal.StepStone,
    altText: "",
  },
  {
    imagePath: "/images/portals/state-street-logo.png",
    portalId: LPPortal.StateStreet,
    altText: "",
  },
  {
    imagePath: "/images/portals/bny-mellon-logo.png",
    portalId: LPPortal.BnyMellon,
    altText: "",
  },
];

export const useHoldingsFileUpload = () => {
  const { emitEvent } = usePortfolioContext();

  const [file, setFile] = useState<File>();
  const { user } = useAuthContext();

  const { sendMessage } = useSendSlackMessage({
    channel: import.meta.env.VITE_APP_SLACK_IOI_CHANNEL,
  });

  const handleUploadClick = () => {
    emitEvent!(new UploadHoldingListSubmitted({ file: file! }));
    sendMessage({
      blocks: [
        generateSlackMessagePayload({
          title: "User has uploaded a holdings list",
          fields: generateUserFields(user),
          context: generateObjectAsFields({
            "processing file": file?.name,
          }),
        }),
      ],
    });
  };

  return {
    file,
    setFile,
    handleUploadClick,
  };
};

const ConnectPortfolio = () => {
  const [activePortalId, setActivePortalId] = useState<number>();
  const [isDifferentSystem, setIsDifferentSystem] = useState<boolean>(false);
  const [portalCustomName, setPortalCustomName] = useState<string>();
  const [confirmBoxOpen, setConfirmBoxOpen] = useState<boolean>(false);
  const { file, setFile, handleUploadClick } = useHoldingsFileUpload();
  const navigate = useNavigate();

  useEffect(() => {
    if (activePortalId && isDifferentSystem) {
      setIsDifferentSystem(false);
      setPortalCustomName(undefined);
    }
  }, [activePortalId]);

  useEffect(() => {
    if (isDifferentSystem && activePortalId) {
      setActivePortalId(undefined);
    }
  }, [isDifferentSystem]);

  const handleBulkUploadClick = () => {
    navigate(LP_ROUTES.PortfolioUploadCapitalAccounts);
  };

  return (
    <>
      <StepperFlow name="Add Holdings" activeStepIndex={0}>
        <div className="flex w-full h-full">
          <div className="w-1/2 h-full flex flex-col items-center justify-start box-border p-6 gap-6">
            <div>
              <PageHead
                title="Connect Your Portfolio"
                description="Connect your LP portfolio management tool to automatically sync your holdings, get estimated market prices, and be notified of buy and sell interest."
                alignCenter
              />
            </div>
            <ContainerChoiceSection
              choicesSet={listOfLpPortals}
              activePortalChoiceId={activePortalId}
              setActivePortalChoiceId={setActivePortalId}
            />

            <div className="flex gap-2 items-center mt-4 whitespace-nowrap">
              <TapCheckbox
                checked={isDifferentSystem}
                onChange={() => setIsDifferentSystem(!isDifferentSystem)}
              />
              I use another portfolio management system
            </div>
            {isDifferentSystem && (
              <LPPortalNameInput
                value={portalCustomName}
                placeholder="Name of system"
                style={{ width: "400px" }}
                onChange={(event) => setPortalCustomName(event.target.value)}
              />
            )}
            <div className="flex flex-row py-8">
              <>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  onClick={() => {
                    file ? handleUploadClick() : setConfirmBoxOpen(true);
                  }}
                  disabled={
                    !Boolean(file) &&
                    !(isDifferentSystem && portalCustomName) &&
                    !Boolean(activePortalId)
                  }
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingLeft: "48px",
                    paddingRight: "48px",
                  }}
                  icon={<Link />}
                  size="large"
                >
                  Connect Portfolio
                </Button>
                <ConfirmLPPortalDialogue
                  open={confirmBoxOpen}
                  portalChoice={
                    isDifferentSystem && portalCustomName
                      ? portalCustomName!
                      : (lpListAvailable.find(
                          (portal) => portal.portalId == activePortalId!,
                        ) ?? "")
                  }
                  onClose={() => setConfirmBoxOpen(false)}
                />
              </>
            </div>
          </div>
          <div className="w-1/2 h-full flex flex-col items-center justify-start box-border p-6 gap-6">
            <div>
              <PageHead
                title="Import Capital Account Data"
                description="Upload your Capital Account Statements and your holdings information is automatically extracted. Or manually input the holdings yourself."
                alignCenter
              />
            </div>
            <div className="w-full h-auto box-border px-8">
              <img
                src="/images/upload-holdings-ui-preview.webp"
                className="rounded-lg shadow-md"
                alt=""
              />
            </div>
            <Button
              fullWidth
              icon={<Barcode height={16} />}
              onClick={handleBulkUploadClick}
              size="large"
              type={BUTTON_TYPES.SECONDARY}
              className="mt-4"
            >
              Use Bulk Upload Table
            </Button>
          </div>
        </div>
      </StepperFlow>
    </>
  );
};

export default ConnectPortfolio;

interface IContainerChoiceSectionProps {
  choicesSet: ILPPortalChoice[][];
  activePortalChoiceId?: number;
  setActivePortalChoiceId: (portalId: number) => void;
}

export const ContainerChoiceSection: React.FC<IContainerChoiceSectionProps> = ({
  choicesSet,
  activePortalChoiceId,
  setActivePortalChoiceId,
}) => {
  return (
    <div className="w-full h-auto flex flex-col gap-4">
      {choicesSet.map((choiceSet) => (
        <div className="w-full h-auto flex flex-row gap-4 justify-evenly">
          {choiceSet.map((choice) => (
            <LpChoiceContainer
              key={choice.portalId}
              {...choice}
              isActive={activePortalChoiceId === choice.portalId}
              onClick={() => setActivePortalChoiceId(choice.portalId)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export interface ILPPortalChoice {
  imagePath: string;
  portalId: LPPortal;
  altText: string;
}

export interface IChoiceContainerProps extends ILPPortalChoice {
  sx?: SxProps;
  isActive?: boolean;
  onClick: () => void;
}

const StyledChoiceContainer = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 96px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fafaf8;
  transition: all 0.2s ease-in-out;

  img {
    user-select: none;
    display: block;
    width: 120px;
    align-self: center;
  }

  outline: ${(props) =>
    props.isActive ? "4px solid #dfdfd9" : "4px solid transparent"};
  border: ${(props) =>
    props.isActive ? "2px solid #bcb3a5" : "2px solid #dfdfd9"};

  &:hover {
    outline: ${(props) =>
      props.isActive ? "4px solid #dfdfd9" : "4px solid #fafaf8"};
    border: 2px solid #bcb3a5;
  }
`;

export const LpChoiceContainer: React.FC<IChoiceContainerProps> = ({
  sx,
  imagePath,
  altText,
  isActive = false,
  onClick,
}) => {
  return (
    <StyledChoiceContainer onClick={onClick} isActive={isActive}>
      <div style={{ position: "absolute", right: "4px", top: "4px" }}>
        <Radio color="secondary" checked={isActive} />
      </div>

      <img src={imagePath} alt={altText} />
    </StyledChoiceContainer>
  );
};

interface IConfirmLPPortalDialogueProps {
  open: boolean;
  onClose: () => void;
  portalChoice: ILPPortalChoice | string;
}

export const ConfirmLPPortalDialogue: React.FC<
  IConfirmLPPortalDialogueProps
> = ({ open, onClose, portalChoice }) => {
  const [allowImportChecked, setAllowImportChecked] = useState<boolean>(true);
  const { emitEvent } = usePortfolioContext();

  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Stack sx={{ px: 4, py: 4 }} spacing={2}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ position: "relative" }}
            >
              <Typography variant="h5">Confirm portfolio linking</Typography>
              <IconButton
                onClick={onClose}
                sx={{
                  height: 40,
                  width: 40,
                  float: "right",
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography variant="body1" color="#737476">
              Tap will connect to your LP portfolio management tool to regularly
              import updated fund holdings.
            </Typography>
          </Stack>
          <Box
            sx={{
              height: "128px",
              border: `1px solid #DFDFD9`,
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", top: "16px", left: "20px" }}>
              <img src="/images/tap-logo.svg" alt="tap-logo" width={"64px"} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "20px",
                left: "20px",
              }}
            >
              {typeof portalChoice == "string" ? (
                <Typography variant="h6">{portalChoice}</Typography>
              ) : (
                <img
                  src={(portalChoice as ILPPortalChoice).imagePath}
                  alt={(portalChoice as ILPPortalChoice).altText}
                  height={"24px"}
                />
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#DFDFD9",
                height: "1px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                border: `1px solid #DFDFD9`,
                borderRadius: "50px",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FAFAF8",
                right: "20px",
              }}
            >
              <PhosphorLink size={32} />
            </Box>
          </Box>
          <Stack
            direction={"row"}
            spacing={1}
            display="flex"
            alignItems={"center"}
            onClick={() => setAllowImportChecked(!allowImportChecked)}
          >
            <TapCheckbox
              checked={allowImportChecked}
              onChange={() => setAllowImportChecked(!allowImportChecked)}
            />
            <Typography>I agree Tap can import my holdings data.</Typography>
          </Stack>
          <Button
            type={BUTTON_TYPES.SECONDARY}
            onClick={() => {
              emitEvent!(
                new LPPortalConnectionSubmitted({
                  portal:
                    typeof portalChoice == "string"
                      ? portalChoice
                      : portalChoice.portalId,
                }),
              );
            }}
            disabled={!allowImportChecked}
            style={{
              marginTop: "40px",
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
              height: "48px",
            }}
          >
            Continue
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
