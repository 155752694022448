import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { CompanyDataTable } from "common/reports/company-data/CompanyDataTable";
import { cn } from "common/utils";

import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { EmptyState } from "common/components/EmptyState";
import { BarLoader } from "common/components/BarLoader";

import { IReportDate } from "../useFundDataReportDate";
import { IInvestmentsInsights } from "./model";

export const CompanyDataSection = ({
  data,
  reportDateSelector,
  loading,
}: {
  data: IInvestmentsInsights[];
  reportDateSelector?: IReportDate | null;
  loading: boolean;
}) => {
  const showReportDateSelector = reportDateSelector?.options.length >= 1;
  const hasData = data.length > 0;
  const showEmptyState = !loading && !hasData;

  return (
    <div className={cn("relative", {})}>
      <PageSectionTitleDivider
        showBorderTop
        actions={
          showReportDateSelector && (
            <SelectDateDropdown
              value={reportDateSelector.currentDate}
              onValueChange={reportDateSelector.onReportDateChange}
              options={reportDateSelector.options}
              loading={loading}
            />
          )
        }
      >
        Company Data
      </PageSectionTitleDivider>

      {loading && <BarLoader />}
      {!loading && hasData && (
        <div className="w-full max-w-full overflow-x-scroll h-full overflow-y-scroll relative pb-12">
          <CompanyDataTable data={data} />
        </div>
      )}
      {showEmptyState && (
        <EmptyState
          title="We don't have any company data for these interests"
          description="Check back later..."
        />
      )}
    </div>
  );
};
