import { Either, left, right } from "fp-ts/lib/Either";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IRegistrationForm } from "../models/RegForm";

export class RegisterUser implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    form,
  }) => {
    return await new TapClient("/users/register/", form)
      .post()
      .then((res: any) => {
        return right(new NoReturn());
      })
      .catch((resp: any) => {
        return left(new Failure("Failed to create user."));
      });
  };
}

interface IParams {
  form: IRegistrationForm;
}
