import { useParams } from "react-router";
import { useQuery } from "@apollo/client";

import { transactionsClient } from "common/clients/ApolloClient";

import {
  GetDataSummarySectionsStatus,
  TransactionDataSummarySectionsStatus,
  TransactionDataSummarySectionStatus,
} from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";

export const useFundDataStatus = () => {
  const { txnId } = useParams();

  const {
    data: status,
    loading,
    error,
    refetch: refetchStatus,
  } = useQuery<TransactionDataSummarySectionsStatus>(
    GetDataSummarySectionsStatus,
    {
      client: transactionsClient,
      variables: { transactionId: txnId },
      fetchPolicy: "cache-and-network",
    },
  );

  const fundExposureSummaryStatus =
    status?.dataSummarySectionsStatuses?.fundExposureSummary;
  const soiLookthroughStatus =
    status?.dataSummarySectionsStatuses?.soiLookthrough;
  const capitalAccountsStatus = status?.dataSummarySectionsStatuses?.cas;
  const dealBreakdownStatus =
    status?.dataSummarySectionsStatuses?.dealBreakdown;
  const qualitativeDataStatus =
    status?.dataSummarySectionsStatuses?.fundExposureSummary; // TODO: change this to qualitative data

  const isCapitalAccountsReady =
    capitalAccountsStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const isFundExposureSummaryReady =
    fundExposureSummaryStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const isSoiLookthroughReady =
    soiLookthroughStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const isDealBreakdownReady =
    dealBreakdownStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const isQualitativeDataReady =
    qualitativeDataStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  return {
    loading,
    error,
    refetchStatus,
    fundExposureSummaryStatus,
    soiLookthroughStatus,
    capitalAccountsStatus,
    dealBreakdownStatus,
    qualitativeDataStatus,
    isCapitalAccountsReady,
    isFundExposureSummaryReady,
    isSoiLookthroughReady,
    isDealBreakdownReady,
    isQualitativeDataReady,
  };
};
