import { Either, left, right } from "fp-ts/lib/Either";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";

const MATCH_NAMES_URL = "/managers/match-names/";

export class MatchNames implements IAsyncUseCase<IParams, Alert[]> {
  call: (params: IParams) => Promise<Either<Failure, Alert[]>> = async (
    payload,
  ) => {
    return await new TapClient(MATCH_NAMES_URL, payload)
      .post()
      .then((resp: Alert[]) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  searchTerms: string[];
}
