import { useQuery } from "@apollo/client";
import { useParams } from "react-router";

import { transactionsClient } from "common/clients/ApolloClient";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { useFundDataStatus } from "./useFundDataStatus";
import { isTxnIdQuay } from "./utils";
import { GetTransactionFundDataSummarySoiCsvQuery } from "experiences/transactions/domain/usecases/GetTransactionFundDataCsvQuery";
import { createFundExposureSummaryExcel } from "experiences/common/excel/fund-summary/createExcel";
import toast from "react-hot-toast";
import { useContext, useState } from "react";
import { TransactionFundDataContext } from "../../state/TransactionFundDataContext";

export const useFundDataSummary = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const { txnId } = useParams();
  const isQuay = isTxnIdQuay({ txnId });
  const [isDownloading, setIsDownloading] = useState(false);
  const { reportDates } = useContext(TransactionFundDataContext);
  const currentReportDate = reportDates.dealBreakdown.currentDate;

  const { fundExposureSummaryStatus, dealBreakdownStatus, error } =
    useFundDataStatus();

  const { refetch: downloadCsvData } = useQuery<FundDataSummaryCsv>(
    GetTransactionFundDataSummarySoiCsvQuery,
    {
      variables: { transactionId: txnId, reportDate: currentReportDate },
      client: transactionsClient,
      fetchPolicy: "no-cache",
      skip: true,
    },
  );

  const handleDownloadClick = () => {
    setIsDownloading(true);
    toast.success(
      "Download started. Make sure your browser is accepting multiple files when prompted.",
    );

    const variables = {
      reportDate: currentReportDate,
      transactionId: txnId,
    };

    downloadCsvData({
      variables,
    })
      .then((res) => {
        // SOI lookthrough is a download of the Excel file
        // createSoiExcel({
        //   data: res.data?.dataSummaryCsv?.soiLookthroughTabs,
        //   namePrefix: `${summary?.transaction?.name
        //     .replace(/ /g, "_")
        //     .toLowerCase()}-soi`,
        // });

        // Fund exposure summary is a download of the Excel file
        createFundExposureSummaryExcel({
          data: res.data?.dataSummaryCsv?.fundExposureSummaryTabs,
          namePrefix: `${summary?.transaction?.name
            .replace(/ /g, "_")
            .toLowerCase()}-summary`,
        });
      })
      .catch((error) => {
        toast.error("Download failed. Please try again.");
      })
      .finally(() => {
        setIsDownloading(false);
        toast.success("Download complete!");
      });
  };

  return {
    error,
    isQuay,
    handleDownloadClick,
    fundExposureSummaryStatus,
    dealBreakdownStatus,
    isDownloading,
  };
};
