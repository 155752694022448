import { useState } from "react";
import { useNavigate } from "react-router";
import { match } from "fp-ts/lib/Either";
import { useSnackbar } from "notistack";

import { GP_ROUTES, LP_ROUTES } from "common/constants/routes";
import { CreateTransaction } from "../../domain/usecases/CreateTransaction";
import {
  CloseSellInterestFlowPressed,
  ConfigureTransactionScreenContinueClicked,
  CreateHoldingModalSubmitted,
  EngageTapScreenContinueClicked,
  ConfirmHoldingsDetailsContinueClicked,
  PortfolioEvent,
  SellInterestFlowTriggered,
  SubmitSellInterestDonePressed,
  ChooseManagerInvolvementContinueClicked,
  UploadHoldingListSubmitted,
  LPPortalConnectionSubmitted,
  SetTimelineScreenContinueClicked,
  CapitalAccountsFileSubmitted,
  GetHoldingsProcessRequested,
  LpCapitalAccountsFileSubmitted,
  MainPortfolioScreenRequested,
} from "./PortfolioEvent";
import {
  ConfigureTransactionLoaded,
  ConfirmHoldingDetailsLoaded,
  EngageTapLoaded,
  HoldingProcessStateLoaded,
  HoldingProcessStateLoading,
  MainPortfolioScreenFailedToLoad,
  MainPortfolioScreenLoaded,
  MainPortfolioScreenLoading,
  PortfolioState,
  ReviewTransactionLoaded,
  SellInterestFlowFailedSubmission,
  SellInterestFlowLoaded,
  SellInterestFlowLoading,
  SellInterestFlowState,
  SetTimelineLoaded,
} from "./PortfolioState";
import {
  ICreateTransactionForm,
  IHoldingSetting,
} from "experiences/portfolio/domain/models/CreateTransactionForm";
import { NoReturn } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { GetPortfolio } from "experiences/portfolio/domain/usecases/GetPortfolio";
import { CreateHolding } from "experiences/portfolio/domain/usecases/CreateHolding";
import { LPPortalOption } from "experiences/portfolio/domain/models/LPPortal";
import { Portfolio } from "experiences/portfolio/domain/models/Portfolio";
import { IHolding } from "experiences/portfolio/domain/models/Holding";
import { CreateLPPortalConnection } from "experiences/portfolio/domain/usecases/CreateLPPortalConnection";
import { UploadHoldingsList } from "experiences/portfolio/domain/usecases/UploadHoldingsList";
import { UploadCapitalAccounts } from "experiences/portfolio/domain/usecases/UploadCapitalAccounts";
import { GetHoldingProcess } from "experiences/portfolio/domain/usecases/GetHoldingProcess";
import { IHoldingProcess } from "experiences/portfolio/domain/models/HoldingProcess";
import {
  PRELOAD_QUERY_PARAM,
  PROCESS_QUERY_PARAM,
} from "../components/holdings-bulk-upload/usePreloadHoldings";

export interface IUseCases {
  submitHoldingSellRequest: CreateTransaction;
  getPortfolio: GetPortfolio;
  createHolding: CreateHolding;
  createLPPortalConnection: CreateLPPortalConnection;
  uploadHoldingList: UploadHoldingsList;
  uploadCapitalAccounts: UploadCapitalAccounts;
  getHoldingsProcess: GetHoldingProcess;
}

export const useManagePortfolioState = ({
  submitHoldingSellRequest,
  getPortfolio,
  createHolding,
  uploadHoldingList,
  createLPPortalConnection,
  uploadCapitalAccounts,
  getHoldingsProcess,
}: IUseCases) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const [holdingsProcessState, setHoldingsProcessState] = useState<
  //   IHoldingProcess[]
  // >(new HoldingProcessState({}));
  const [holdingsProcessState, setHoldingsProcessState] = useState(
    new HoldingProcessStateLoading(),
  );

  const [sellInterestFlowState, setSellInterestFlowState] =
    useState<SellInterestFlowState>(new SellInterestFlowLoading());

  const [sellInterestForm, setSellInterestForm] =
    useState<ICreateTransactionForm>({ engagementLetterSigned: false });

  const [selectedHoldings, setSelectedHoldings] = useState<IHolding[]>([]);

  const [portfolioScreenState, setPortfolioScreenState] =
    useState<PortfolioState>(new MainPortfolioScreenLoading());

  const submitSellFlow = () => {
    submitHoldingSellRequest.call({ form: sellInterestForm }).then((resp) =>
      setSellInterestFlowState(
        match<Failure, NoReturn, void>(
          (failure: Failure) => new SellInterestFlowFailedSubmission(),
          (_: NoReturn) => new SellInterestFlowLoaded(),
        ),
      ),
    );
  };

  const refreshPortfolio = () => {
    getPortfolio.call({}).then((resp) =>
      setPortfolioScreenState(
        match<Failure, Portfolio, PortfolioState>(
          (failure: Failure) => {
            return new MainPortfolioScreenFailedToLoad();
          },
          (portfolio: Portfolio) => {
            return new MainPortfolioScreenLoaded({ portfolio: portfolio });
          },
        )(resp),
      ),
    );
  };

  const uploadHoldings = (file: File) => {
    uploadHoldingList.call({ file }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during uploading ioi list, try again later.", {
            variant: "error",
          });
        },
        (_: NoReturn) => {
          navigate(LP_ROUTES.PortfolioPortalConnectionReview, {
            replace: true,
          });
        },
      )(resp);
    });
  };

  const uploadCapAccounts = (files: File[]) => {
    uploadCapitalAccounts.call({ files }).then((resp) => {
      match<Failure, { processingId: string }, void>(
        (_: Failure) => {
          enqueueSnackbar(
            "An error occurred while uploading capital accounts, try again later.",
            {
              variant: "error",
            },
          );
        },
        ({ processingId }: { processingId: string }) => {
          const query = new URLSearchParams({
            [PROCESS_QUERY_PARAM]: processingId,
            [PRELOAD_QUERY_PARAM]: "true",
          });

          navigate(LP_ROUTES.PortfolioHoldingsUpload + "?" + query.toString(), {
            replace: true,
          });
        },
      )(resp);
    });
  };

  const uploadLpCapAccounts = (files: File[]) => {
    uploadCapitalAccounts.call({ files }).then((resp) => {
      match<Failure, { processingId: string }, void>(
        (_: Failure) => {
          enqueueSnackbar(
            "An error occurred while uploading capital accounts, try again later.",
            {
              variant: "error",
            },
          );
        },
        ({ processingId }: { processingId: string }) => {
          const query = new URLSearchParams({
            [PROCESS_QUERY_PARAM]: processingId,
            [PRELOAD_QUERY_PARAM]: "true",
          });

          navigate(
            GP_ROUTES.LimitedPartnersUploadCapitalAccountsBulk +
              "?" +
              query.toString(),
            {
              replace: true,
            },
          );
        },
      )(resp);
    });
  };

  const getHoldingsProcessForId = (id: string) => {
    getHoldingsProcess.call({ id }).then((resp) => {
      match<Failure, IHoldingProcess[], void>(
        (_: Failure) => {
          enqueueSnackbar(
            "An error occurred while fetching holding process, try again later.",
            {
              variant: "error",
            },
          );
        },
        (process: IHoldingProcess[]) => {
          setHoldingsProcessState(
            new HoldingProcessStateLoaded({ holdings: process }),
          );
        },
      )(resp);
    });
  };

  const connectLPPortal = (portal: LPPortalOption) => {
    createLPPortalConnection.call({ portal }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar(
            "Error during creating LP portal connection, try again later.",
            {
              variant: "error",
            },
          );
        },
        (_: NoReturn) => {
          navigate(LP_ROUTES.PortfolioPortalConnectionReview, {
            replace: true,
          });
        },
      )(resp);
    });
  };

  // event handler
  const emitEvent = (event: PortfolioEvent) => {
    if (event instanceof SellInterestFlowTriggered) {
      setSelectedHoldings(event.holdings);
      setSellInterestFlowState(
        new ConfirmHoldingDetailsLoaded({
          selectedHoldings: event.holdings,
        }),
      );
      navigate(LP_ROUTES.PortfolioSellHoldings);
    } else if (event instanceof ConfirmHoldingsDetailsContinueClicked) {
      setSellInterestForm((prev) => {
        const mappedHoldings: IHoldingSetting[] = selectedHoldings.map(
          (holding) => {
            return {
              holdingId: holding.id,
              isManagerAdmin: false,
            };
          },
        );
        return { ...prev, holdingSettings: mappedHoldings };
      });

      // with the introduction of company holdings (portcos),
      // we are skipping the manager involvement step:
      // setSellInterestFlowState(
      //   new ChooseManagerInvolvementLoaded({
      //     selectedHoldings: selectedHoldings,
      //   })
      // );

      setSellInterestFlowState(new ConfigureTransactionLoaded());
    } else if (event instanceof ChooseManagerInvolvementContinueClicked) {
      setSellInterestForm((prev) => {
        const mappedHoldings: IHoldingSetting[] =
          sellInterestForm.holdingSettings!.map((holding) => {
            return {
              holdingId: holding.holdingId,
              isManagerAdmin: Boolean(
                event.adminEnabledGPHoldings.find(
                  (adminHolding) => holding.holdingId == adminHolding.id,
                ),
              ),
            };
          });
        return { ...prev, holdingSettings: mappedHoldings };
      });
      setSellInterestFlowState(new ConfigureTransactionLoaded());
    } else if (event instanceof ConfigureTransactionScreenContinueClicked) {
      setSellInterestForm({ ...sellInterestForm, ...event });
      setSellInterestFlowState(new EngageTapLoaded());
    } else if (event instanceof EngageTapScreenContinueClicked) {
      setSellInterestForm({ ...sellInterestForm, ...event });
      setSellInterestFlowState(new SetTimelineLoaded());
    } else if (event instanceof SetTimelineScreenContinueClicked) {
      setSellInterestForm({ ...sellInterestForm, ...event });
      setSellInterestFlowState(
        new ReviewTransactionLoaded({
          transactionName: sellInterestForm.transactionName!,
          accumNav: new DollarAmount(
            selectedHoldings
              .map((holding) => holding.bookValue)
              .reduce((prev, curr) => prev + curr),
          ),
          targetCloseDate: event.targetCloseDate.end,
          selectedHoldings: selectedHoldings,
          managerAdminHoldingIds: selectedHoldings
            .filter((holding) =>
              Boolean(
                sellInterestForm.holdingSettings!.find(
                  (formHolding) =>
                    formHolding.holdingId == holding.id &&
                    formHolding.isManagerAdmin,
                ),
              ),
            )
            .map((holding) => holding.id),
        }),
      );
    } else if (event instanceof SubmitSellInterestDonePressed) {
      submitSellFlow();
      setSellInterestForm({});
      setSellInterestFlowState(new SellInterestFlowLoaded());
      navigate(LP_ROUTES.TransactionsActive);
    } else if (event instanceof CloseSellInterestFlowPressed) {
      setSellInterestForm({});
      setSellInterestFlowState(new SellInterestFlowLoaded());
      navigate(-1);
    } else if (event instanceof CreateHoldingModalSubmitted) {
      createHolding.call({ form: event.form }).then((resp) =>
        setPortfolioScreenState(
          match<Failure, NoReturn, void>(
            (failure: Failure) => new MainPortfolioScreenFailedToLoad(),
            (_: NoReturn) => {
              refreshPortfolio();
            },
          ),
        ),
      );
    } else if (event instanceof UploadHoldingListSubmitted) {
      uploadHoldings(event.file);
    } else if (event instanceof LPPortalConnectionSubmitted) {
      connectLPPortal(event.portal);
    } else if (event instanceof CapitalAccountsFileSubmitted) {
      uploadCapAccounts(event.files);
    } else if (event instanceof LpCapitalAccountsFileSubmitted) {
      uploadLpCapAccounts(event.files);
    } else if (event instanceof GetHoldingsProcessRequested) {
      getHoldingsProcessForId(event.id);
    } else if (event instanceof MainPortfolioScreenRequested) {
      refreshPortfolio();
    }
  };

  return {
    emitEvent,
    sellInterestFlowState,
    portfolioScreenState,
    holdingsProcessState,
  };
};
