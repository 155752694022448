export class DollarAmount {
  amount: number;

  constructor(amount: number) {
    this.amount = amount;
  }

  static fromString: (stringAmount: string) => DollarAmount = (
    stringAmount,
  ) => {
    let cleaned = stringAmount.replaceAll("$", "").replaceAll(",", "");
    return new DollarAmount(+cleaned);
  };

  formatted: (decimalPlaces?: number, postfix?: string) => string = (
    decimalPlaces?,
    postfix?,
  ) => {
    let post = postfix ?? "";
    return (
      "$ " +
      this.amount.toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces ?? 0,
        maximumFractionDigits: decimalPlaces ?? 0,
      }) +
      " " +
      post
    );
  };

  formattedBig: (decimalPlaces?: number) => string = (decimalPlaces?) => {
    let postfix = "";
    let big = 0;

    if (this.amount >= quadrillion) {
      return new DollarAmount(1 / 0).formatted();
    } else if (this.amount >= trillion) {
      postfix = trillionPostFix;
      big = this.amount / trillion;
    } else if (this.amount >= billion) {
      postfix = billionPostFix;
      big = this.amount / billion;
    } else if (this.amount >= million) {
      postfix = millionPostFix;
      big = this.amount / million;
    } else if (this.amount >= thosand) {
      postfix = thousandPostFix;
      big = this.amount / thosand;
    } else {
      return this.formatted(1);
    }

    return new DollarAmount(big).formatted(1, postfix);
  };
}

const thousandPostFix = "K";
const millionPostFix = "M";
const billionPostFix = "B";
const trillionPostFix = "T";
const billion = 1_000_000_000;
const million = 1_000_000;
const thosand = 1_000;
const trillion = 1_000_000_000_000;
export const quadrillion = 1_000_000_000_000_000;

export const makeNumber = (num: any) => {
  if (num === undefined) return num;
  if (isNaN(num))
    return +num.replaceAll(",", "").replaceAll("$", "").replaceAll("%", "");
  return num;
};
