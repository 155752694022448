import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import SelectDropDown from "experiences/common/SelectDropDown";
import {
  FundStrategy,
  FundStrategyNameMap,
} from "experiences/common/models/FundStrategy";
import { useFundsContext } from "experiences/funds/presentation/state/FundsContext";
import { StrategyFilterChanged } from "experiences/funds/presentation/state/FundsEvent";
import FilterPill from "./FilterPill";
import { arrayEquals } from "common/utils/arrayEquals";

export const StrategyFilter = () => {
  const { emitEvent, filtersSpecs } = useFundsContext();

  const isStrategySelected = (strategy: FundStrategy) =>
    filtersSpecs!.strategies?.includes(strategy);

  const toggleStrategy = (strategy: FundStrategy) => {
    emitEvent!(
      new StrategyFilterChanged({
        strategies: isStrategySelected(strategy)
          ? filtersSpecs!.strategies?.filter((str) => str != strategy)
          : [...filtersSpecs?.strategies!, strategy],
      }),
    );
  };

  const showClearOption =
    !filtersSpecs?.strategies || !arrayEquals(filtersSpecs?.strategies, []);

  const clearStrategies = () => {
    emitEvent!(new StrategyFilterChanged({ strategies: [] }));
  };

  const getValue = () => {
    const startegiesSelectedCount = filtersSpecs!.strategies!.length;
    return startegiesSelectedCount > 0
      ? `${startegiesSelectedCount} selected`
      : "";
  };

  return (
    <FilterPill
      title="Strategy"
      rightText={getValue()}
      menuWidth={500}
      showClear={showClearOption}
      clearFn={clearStrategies}
    >
      <Stack direction="row">
        {[
          [
            FundStrategy.VentureCapital,
            FundStrategy.Growth,
            FundStrategy.RealEstate,
            FundStrategy.Other,
          ],
          [
            FundStrategy.Secondaries,
            FundStrategy.Infrastructure,
            FundStrategy.NaturalResource,
          ],
          [
            FundStrategy.PrivateEquity,
            FundStrategy.PrivateDebt,
            FundStrategy.FundOfFunds,
          ],
          [],
        ].map((strategyRow) => (
          <Stack>
            {strategyRow.map((strategy) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isStrategySelected(strategy)}
                    onChange={() => toggleStrategy(strategy)}
                  />
                }
                label={FundStrategyNameMap.get(strategy)}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </FilterPill>
  );
};

export default StrategyFilter;
