import { gql } from "@apollo/client";

export const GetTransactionDataroomQuery = gql`
  query Dataroom($transactionId: String!) {
    dataroom(transactionId: $transactionId) {
      details {
        transactionId
        name
        path
        zipPath
      }
      directory
    }
  }
`;
