const bidColor = "#395f39";

export const BidTriangle = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M4 9H11L7.5 4.5L4 9Z" fill={bidColor}></path>
    </svg>
  );
};
