import { gql } from "@apollo/client";

export const GetPortfolioEntitiesQuery = gql`
  query Entities {
    entities {
      id
      name
      type
    }
  }
`;
