import { TransactionAgreement } from "../models/TransactionAgreement";

export const TransactionAgreementsMock: TransactionAgreement[] = [
  {
    id: "0aaa56d1-96e8-5dc4-87fb-93f5148a4ca0",
    ndaName: "Viewpoint Partners Fund IV NDA FORM Nov 2023",
    funds: "IV, Opportunities",
    signatures: 5,
    status: "Active",
  },
  {
    id: "0aaa56d1-96e8-5dc4-87fb-93f5148a4ca0",
    ndaName: "Viewpoint Partners Fund III NDA FORM Nov 2023",
    funds: "III",
    signatures: 30,
    status: "Active",
  },
  {
    id: "0aaa56d1-96e8-5dc4-87fb-93f5148a4ca0",
    ndaName: "Viewpoint Partners Fund II NDA FORM Nov 2023",
    funds: "II",
    signatures: 34,
    status: "Active",
  },
  {
    id: "0aaa56d1-96e8-5dc4-87fb-93f5148a4ca0",
    ndaName: "Viewpoint Partners Fund I NDA FORM Nov 2023",
    funds: "I",
    signatures: 29,
    status: "Active",
  },
];
