import { useContext, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";
import { CaretUpDown } from "@phosphor-icons/react/dist/ssr";

import { Button, BUTTON_TYPES } from "common/components/Button";
import { ToggleRow } from "experiences/common/ToggleRow";
import { EmptyState } from "common/components/EmptyState";

import { TransactionFundDataContext } from "../../state/TransactionFundDataContext";

export const HoldingSelector = () => {
  const [open, setOpen] = useState(false);

  const {
    selectedHoldingsIds,
    onHoldingSelect,
    onHoldingRemove,
    holdingsOptions,
  } = useContext(TransactionFundDataContext);

  const label =
    selectedHoldingsIds.length === holdingsOptions.length
      ? "All Fund Interests"
      : `${selectedHoldingsIds.length} of ${holdingsOptions.length} selected`;

  const isEnabled = holdingsOptions.length > 0;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="relative right-0">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          icon={<CaretUpDown />}
          iconRight
          onClick={() => setOpen((prev) => !prev)}
          aria-expanded={open}
          className="min-w-64 !justify-between relative h-9"
        >
          {label}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 -space-x-6 relative right-8">
        <div className="w-full h-full">
          {isEnabled ? (
            <div className="">
              {holdingsOptions.map((option) => {
                const isSelected = selectedHoldingsIds.includes(option.id);
                return (
                  <ToggleRow
                    switchLeft
                    compact
                    title={option.label}
                    checked={isSelected}
                    disabled={selectedHoldingsIds.length === 1 && isSelected}
                    onChange={() => {
                      if (isSelected) {
                        onHoldingRemove(option.id);
                      } else {
                        onHoldingSelect(option.id);
                      }
                    }}
                    tapGold
                    smallToggle
                  />
                );
              })}
            </div>
          ) : (
            <EmptyState title="No fund interests available" />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
