import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { GpLimitedPartner } from "experiences/limited-partners/domain/models/GpLimitedPartner";

export const GpLimitedPartnersTable = ({
  partners,
}: {
  partners: GpLimitedPartner[];
}) => {
  const handleDownloadClick = (document: GpLimitedPartner) => {
    console.log("handle nda download", document);
  };

  return (
    <>
      <TapTable
        data={partners}
        emptyStateTitle={
          !partners.length ? "There are no partners to display." : null
        }
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "contact",
            label: "Contact Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "cav",
            label: "CAV",
            align: TableAlignment.RIGHT,
          },
          {
            key: "unfunded",
            label: "Unfunded",
            align: TableAlignment.RIGHT,
          },
          {
            key: "distributions",
            label: "Distributions",
            align: TableAlignment.RIGHT,
          },
          {
            key: "participation",
            label: "Participation",
            align: TableAlignment.RIGHT,
          },
          {
            key: "lastAccess",
            label: "Last Access",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(partner: GpLimitedPartner) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                <StyledFundNameAndImage>
                  <img
                    src={`https://cdn.usetap.com/funds/logos/${partner.logoFileName}`}
                    alt="Fund logo"
                  />{" "}
                  {partner.lpName}
                </StyledFundNameAndImage>
              </TableCell>
              <TableCell>{partner.fundScop}</TableCell>
              <TableCell>{partner.contantName}</TableCell>
              <TableCell className="text-right">{partner.cav}</TableCell>
              <TableCell className="text-right">{partner.unfunded}</TableCell>
              <TableCell className="text-right">
                {partner.distributions}
              </TableCell>
              <TableCell className="text-right">
                {partner.currentParticipation}
              </TableCell>
              <TableCell>{partner.lastAccessDate}</TableCell>
            </>
          );
        }}
      />
    </>
  );
};
