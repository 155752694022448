import { useEffect, useState } from "react";
import { Pencil } from "@phosphor-icons/react";

import { Button } from "common/components/Button";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import {
  ITxnMonitorParticipationLP,
  txnMonitorParticipationLps,
} from "../../mock/GP";
import { SideComparison } from "../SideComparison";
import TextChip from "experiences/common/TextChip";
import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";

export const PreliminaryInterestTable = () => {
  const participatingLPs = 22;
  const interest = "$563.8M";
  const [data, setData] = useState<ITxnMonitorParticipationLP[]>([]);
  const isLoading = false;

  useEffect(() => {
    setData(txnMonitorParticipationLps);
  }, []);

  return (
    <>
      <SideComparison
        skipBorderTop
        biggerHeight
        items={[
          {
            title: "LPs Interested",
            value: String(participatingLPs),
          },
          {
            title: "Preliminary Interest",
            value: interest,
          },
        ]}
      />
      <TapTable
        isLoading={isLoading}
        data={data}
        emptyStateTitle={"..."}
        emptyStateDescription={"..."}
        skipTopBorder
        columns={[
          {
            key: "",
            label: "Limited Partner",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Interested",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "CAV",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Preliminary Interest (%)",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Preliminary Interest ($)",
            align: TableAlignment.RIGHT,
          },
          {
            key: "",
            label: "Reserve Price",
            align: TableAlignment.LEFT,
          },
          {
            key: "",
            label: "Pro-forma Proceeds",
            align: TableAlignment.RIGHT,
          },
          {
            key: "actions",
            label: "",
            align: TableAlignment.RIGHT,
          },
        ]}
        renderRow={(lp: ITxnMonitorParticipationLP) => {
          const onRowClick = (m: ITxnMonitorParticipationLP) => {
            console.log("team member clicked", m);
          };

          return (
            <>
              <TableCell
                isPrimaryColumn
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                <StyledFundNameAndImage>
                  <img
                    src={`https://cdn.usetap.com/funds/logos/${lp.logoFileName}`}
                    alt="Fund logo"
                  />{" "}
                  {lp.lpName}
                </StyledFundNameAndImage>
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                <TextChip
                  text={lp.isParticipating}
                  success={lp.isParticipating === "YES"}
                />
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
                className="text-right"
              >
                {lp.cav}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                {lp.participatingPercent}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
                className="text-right"
              >
                {lp.participatingDollar}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
              >
                {lp.reservePrice}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(lp)}
                className="text-right"
              >
                {lp.proFormaSize}
              </TableCell>

              <TableCell hasActions>{null}</TableCell>
            </>
          );
        }}
      />
    </>
  );
};
