import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import { PageHead } from "common/components/PageHead";
import { LP_ROUTES } from "common/constants/routes";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import FormikTextField from "common/components/formik/FormikTextField";
import FormikDropdownSelect from "common/components/formik/FormikDropdownSelect";
import {
  FormikQuarterPicker,
  getLastQuarter,
} from "common/components/formik/FormikQuarterPicker";
import FormikCheckbox from "common/components/formik/FormikCheckbox";
import { Button, BUTTON_TYPES } from "common/components/Button";
import TapClient from "common/clients/TapClient";
import {
  ParticipantCapacity,
  ParticipantCapacityOptions,
} from "experiences/transactions/domain/models/Participant";
import { ITransaction } from "experiences/transactions/domain/models/Transaction";

const initialValues = {
  transactionName: "Project ",
  targetClosingDate: "",
  creatorCapacity: ParticipantCapacity.Seller,
  ndasEnabled: false,
  dataroomEnabled: false,
  biddingEnabled: false,
  isLiveTransactionForSingleParticipant: false,
  qmsEnabled: false,
  referenceDate: "",
};

const validationSchema = yup.object({
  // Must start with the word 'Project' and contain something else next to it
  transactionName: yup
    .string()
    .required("Required")
    .matches(/^project/i, "Must start with the word 'Project'")
    .min(9, "Must contain at least a word next to 'Project'"),
  targetClosingDate: yup.string().required("Required"),
  creatorCapacity: yup.number().required("Required"),
  ndasEnabled: yup.boolean(),
  dataroomEnabled: yup.boolean(),
  biddingEnabled: yup.boolean(),
  isLiveTransactionForSingleParticipant: yup
    .boolean()
    .required(
      "Please certify that this is a live transaction for a single seller.",
    )
    .isTrue(
      "Please certify that this is a live transaction for a single seller.",
    ),
  qmsEnabled: yup
    .boolean()
    .test("oneOfRequired", "Please select at least one", (value, testContext) =>
      Boolean(
        testContext.parent.ndasEnabled ||
          testContext.parent.dataroomEnabled ||
          testContext.parent.biddingEnabled ||
          testContext.parent.qmsEnabled,
      ),
    ),
  referenceDate: yup.date().required("Required").default(getLastQuarter()),
});

const PATH = "/transactions/lp-led/v2/create/";

const useCreateTransaction = () => {
  const lastStep = 1;
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const handleCloseClick = () => {
    navigate(-1);
  };

  const goToNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  useEffect(() => {
    if (currentStep === lastStep) {
      const navigateTo = LP_ROUTES.Transactions;

      navigate(navigateTo);
    }
  }, [currentStep]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        transactionName: values.transactionName,
        targetCloseDate: values.targetClosingDate,
        creatorCapacity: values.creatorCapacity,
        adoptPlatformNda: values.ndasEnabled,
        useDataroom: values.dataroomEnabled,
        useBidding: values.biddingEnabled,
        useQms: values.qmsEnabled,
        holdings: [],
        referenceDate: values.referenceDate,
      };

      await new TapClient(PATH, payload)
        .post()
        .then((transaction: Pick<ITransaction, "id">) => {
          toast.success("Transaction created successfully");
          navigate(
            LP_ROUTES.TransactionDetails.replace(":txnId", transaction.id),
          );
        })
        .catch((error: any) => {
          // TODO: Raise sentry error
          console.error({ error });
        });
    },
  });

  return {
    currentStep,
    goToNextStep,
    handleCloseClick,
    formik,
  };
};

const Step0CreateTransaction = ({
  goToNextStep,
  formik,
}: {
  goToNextStep: () => void;
  formik;
}) => {
  return (
    <div className="w-screen box-border p-8 h-full">
      <form
        className="max-w-xl flex-1 flex flex-col gap-8 w-full mx-auto"
        onSubmit={formik.handleSubmit}
      >
        <div>
          <PageHead
            paddingless
            title="Create Transaction"
            description={
              <>
                Select a name and configure basic parameters of this
                transaction. The transaction is only visible to you, buyers that
                you invite, and GPs of selected funds.
              </>
            }
          />
        </div>
        <FormikTextField
          name="transactionName"
          formik={formik}
          label="Transaction Name"
          fullWidth
        />
        <FormikQuarterPicker
          formik={formik}
          name="targetClosingDate"
          defaultNow
          label="Target Closing"
          inputProps={undefined}
        />
        <FormikDropdownSelect
          formik={formik}
          label="Your Capacity"
          name="creatorCapacity"
          options={ParticipantCapacityOptions}
        />
        {/* new reference date picker */}
        <FormikQuarterPicker
          formik={formik}
          name="referenceDate"
          defaultNow
          label="Reference Date"
          inputProps={undefined}
        />
        <div>
          <FormikCheckbox
            name={"isLiveTransactionForSingleParticipant"}
            label={
              "I certify that this is a live transaction for a single seller."
            }
            formik={formik}
            className="w-full"
          />
        </div>
        <div>
          <label className="font-medium text-sm text-stone-900">
            Select Features (You can change this later)
          </label>
          <div className="flex flex-col pl-2 pb-2 pt-2">
            <FormikCheckbox
              name={"ndasEnabled"}
              label={"NDAs"}
              formik={formik}
              className="w-full"
              tooltipText="Utilize Tap’s template NDA for any participants you add to the transaction."
            />
            <FormikCheckbox
              name={"dataroomEnabled"}
              label={"Data Room"}
              formik={formik}
              className="w-full"
              tooltipText="Extract, analyze, and export all of the underlying data relevant to this transaction across funds, capital accounts and more."
            />
            <FormikCheckbox
              label={"Bidding"}
              name={"biddingEnabled"}
              formik={formik}
              className="w-full"
              tooltipText="Track bids on the transaction with Tap’s in-app bid management software."
            />
            <FormikCheckbox
              name={"qmsEnabled"}
              label={"Qualified Matching Service (QMS)"}
              formik={formik}
              className="w-full"
              tooltipText="Ensure this transaction is compliant and avoid PTP delays by using Tap to document and enforce QMS requirements. "
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button htmlType="submit" type={BUTTON_TYPES.SECONDARY}>
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export const CreateTransaction = () => {
  const { currentStep, goToNextStep, handleCloseClick, formik } =
    useCreateTransaction();

  return (
    <>
      <PageHead title="Create Transaction" />

      <StepperFlow
        onClose={handleCloseClick}
        name="Create Transaction"
        activeStepIndex={0}
      >
        <div className="h-full max-h-full min-h-full">
          {currentStep === 0 && (
            <Step0CreateTransaction
              goToNextStep={goToNextStep}
              formik={formik}
            />
          )}
        </div>
      </StepperFlow>
    </>
  );
};
