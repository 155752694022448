import { DollarAmount } from "common/@types/app/DollarAmount";
import { FundCoreSector } from "experiences/common/models/FundCoreSector";
import { FundCurrency } from "experiences/common/models/FundCurrencies";
import { FundGeography } from "experiences/common/models/FundGeography";
import { FundStrategy } from "experiences/common/models/FundStrategy";
import {
  IOIType,
  IndicationOfInterest,
} from "experiences/indications-of-interest/domain/models/IOI";

export enum BidCategory {
  Below = 1,
  Within = 2,
  Above = 3,
}

export enum BidStatus {
  Ok = 1,
  InsufficientData = 2,
  PartialUserBid = 3,
  NoUserBids = 4,
}

export type BidRankType = {
  bidCategory: BidCategory;
  bidDelta: number;
  bidRange: {
    start: number;
    end: number;
  };
  status: BidStatus;
};

// Note: casing update happens at TapClient @ processResponse
export type FundSummaryIn = {
  country: string | null;
  currency: number;
  domicile: string;
  geoFocus?: null;
  geography: number;
  id: string;
  ioisPermitted: boolean;
  legalName: string;
  managerId: string;
  managerName: string;
  name: string;
  psin?: string;
  size?: number;
  status?: string;
  strategy: number;
  structure: string;
  type: number;
  vintage: number;
  iois: IndicationOfInterest[];
  bidRank: BidRankType;
  permissionProfile: PermissionProfile;
};

export type FundSummary = {
  country: string | null;
  domicile: string | null;
  geoFocus?: null;
  geography: FundGeography;
  id: string;
  ioisPermitted: boolean;
  legalName: string;
  managerId: string;
  managerName: string;
  name: string;
  psin?: string;
  size?: DollarAmount; // todo: convert to formatted number
  status?: string; // todo: verify
  strategy: FundStrategy;
  structure: string | null;
  type: IOIType;
  vintage: number;
  iois: IndicationOfInterest[];
  permissionProfile: PermissionProfile;
  bidRank: BidRankType;
  coreSector?: FundCoreSector;
  currency?: FundCurrency;
  series?: string;
};

enum IssuerPermissionRight {
  View = 1,
  Create = 2,
  Change = 3,
  Remove = 5,

  BidRank = 50,
  Everything = 98,
  Nothing = 99,
}

enum FundPermissionScope {
  BasicInfo = 1,
  BuyIOIs = 2,
  SellIOIs = 3,
  Valuations = 4,
  Investments = 5,
  CashFlows = 6,
}

export type FundUserPermission = {
  right: IssuerPermissionRight;
  scope: FundPermissionScope;
  status: IssuerPermissionStatus;
};

enum IssuerPermissionStatus {
  Active = 1,
  InActive = 2,
  Pending = 3,
}

export interface IPermissionProfile {
  permissions: FundUserPermission[];
}

export class PermissionProfile implements IPermissionProfile {
  permissions: FundUserPermission[];

  constructor({ permissions }: IPermissionProfile) {
    this.permissions = permissions;
  }

  activeBuyerViewPermissions = () => {
    return this.permissions.filter(
      (p) =>
        p.status === IssuerPermissionStatus.Active &&
        (p.right === IssuerPermissionRight.View ||
          p.right === IssuerPermissionRight.BidRank),
    );
  };

  activeViewPermissions = () => {
    return this.permissions.filter(
      (p) =>
        (p.status === IssuerPermissionStatus.Active &&
          p.right === IssuerPermissionRight.View) ||
        (p.status === IssuerPermissionStatus.Active &&
          p.right === IssuerPermissionRight.Everything),
    );
  };

  isPermittedToViewBasicInfo = () => {
    const found = this.activeViewPermissions().find(
      (p) => p.scope === FundPermissionScope.BasicInfo,
    );
    return found !== undefined ? true : false;
  };

  isPermittedToViewCashFlows = () => {
    const found = this.activeViewPermissions().find(
      (p) => p.scope === FundPermissionScope.CashFlows,
    );
    return found !== undefined ? true : false;
  };

  isPermittedToViewInvestments = () => {
    const found = this.activeViewPermissions().find(
      (p) => p.scope === FundPermissionScope.Investments,
    );
    return found !== undefined ? true : false;
  };

  isPermittedToViewBuyIOIs = () => {
    const found = this.activeBuyerViewPermissions().find(
      (p) => p.scope === FundPermissionScope.BuyIOIs,
    );
    return found !== undefined ? true : false;
  };

  isPermittedToViewSellIOIs = () => {
    const found = this.activeViewPermissions().find(
      (p) => p.scope === FundPermissionScope.SellIOIs,
    );

    return found !== undefined ? true : false;
  };
}
