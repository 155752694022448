import { useEffect, useState } from "react";

import { BUTTON_TYPES, Button } from "common/components/Button";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import {
  ITxnMonitorNonBindingIOIBuyer,
  txnMonitorNonBindingIOIBuyers,
} from "../../mock/GP";
import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";

// TODO: add link to file on view bid letter button

export const NonBindingBidsTable = () => {
  const [data, setData] = useState<ITxnMonitorNonBindingIOIBuyer[]>([]);
  const isLoading = false;

  useEffect(() => {
    setData(txnMonitorNonBindingIOIBuyers);
  }, []);

  return (
    <TapTable
      isLoading={isLoading}
      data={data}
      emptyStateTitle={"..."}
      emptyStateDescription={"..."}
      skipTopBorder
      columns={[
        {
          key: "",
          label: "Buyer",
          align: TableAlignment.LEFT,
        },
        {
          key: "",
          label: "Price",
          align: TableAlignment.LEFT,
        },
        {
          key: "",
          label: "Amount",
          align: TableAlignment.RIGHT,
        },
        {
          key: "actions",
          label: "",
          align: TableAlignment.RIGHT,
        },
      ]}
      renderRow={(buyer: ITxnMonitorNonBindingIOIBuyer) => {
        const onRowClick = (m: ITxnMonitorNonBindingIOIBuyer) => {
          console.log("team member clicked", m);
        };

        const showViewBidLetterButton = buyer.amount !== "-";

        return (
          <>
            <TableCell
              isPrimaryColumn
              alignTop
              cursorPointer={true}
              onClick={() => onRowClick(buyer)}
            >
              <StyledFundNameAndImage>
                <img
                  src={`https://cdn.usetap.com/funds/logos/${buyer.logoFileName}`}
                  alt="Fund logo"
                />{" "}
                {buyer.buyerName}
              </StyledFundNameAndImage>
            </TableCell>
            <TableCell>{buyer.pricePercent}</TableCell>
            <TableCell className="text-right">{buyer.amount}</TableCell>
            <TableCell hasActions>
              <div className="flex gap-2">
                {showViewBidLetterButton && (
                  <Button onClick={() => console.log("view bid letter")}>
                    View Bid Letter
                  </Button>
                )}
              </div>
            </TableCell>
          </>
        );
      }}
    />
  );
};
