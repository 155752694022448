export enum ParticipantCapacity {
  Seller = 1,
  Buyer = 2,
  Manager = 3,
}

export enum ParticipantRole {
  Principal = 1,
  TeamMember = 2,
  Advisor = 3,
  TapAdvisor = 4,
}

export interface IParticipant {
  // more fields can be added as we need them
  id: string;
  capacity: ParticipantCapacity;
  role: ParticipantRole;
  userId?: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  txnId: string;
  txnName: string;
  isSuggested: boolean;
  currentPhase: ParticipantPhase;
  organizationName: string;
  organizationId: string;
  fullName: string;
  inviteLink?: string;
  txnActions: ParticipantTransactionAction[];
}

export const isSeller = (participant: IParticipant) => {
  return participant.capacity == ParticipantCapacity.Seller;
};

export const isBuyer = (participant: IParticipant) => {
  return participant.capacity == ParticipantCapacity.Buyer;
};

export enum ParticipantPhase {
  Suggested = 1,
  InvitePending = 2,
  Invited = 3,
  TeaserViewed = 4,
  DataRoomAccessed = 5,
  BidSubmitted = 6,
  BidAdvancedRound2 = 7,
  BidAccepted = 8,
  Inactive = 99,
}

export const ParticipantStatusNameMap = new Map<ParticipantPhase, String>([
  [ParticipantPhase.InvitePending, "Invite Pending"],
  [ParticipantPhase.TeaserViewed, "Teaser Viewed"],
  [ParticipantPhase.Invited, "Invited"],
  [ParticipantPhase.Suggested, "Suggested"],
  [ParticipantPhase.DataRoomAccessed, "Data Room Accessed"],
  [ParticipantPhase.BidSubmitted, "Bid Submitted"],
  [ParticipantPhase.BidAdvancedRound2, "Awaiting Bid (Round 2)"],
  [ParticipantPhase.BidAccepted, "Bid Accepted"],
  [ParticipantPhase.Inactive, "Inactive"],
]);

export interface ICreateParticipantForm {
  transactionId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: ParticipantCreationRole;
  organizationName: string;
}

export enum ParticipantCreationRole {
  SecondarySeller = 1,
  MyTeamMember = 2,
  MyAdvisor = 3,
  GeneralPartner = 4,
  SecondaryBuyer = 5,
  BuyerTeamMember = 6,
  BuyerAdvisor = 7,
}

export const ParticipantCreationRoleNames = new Map<
  ParticipantCreationRole,
  string
>([
  [ParticipantCreationRole.SecondarySeller, "Principal of Seller"],
  [ParticipantCreationRole.MyAdvisor, "Advisor of Seller"],
  [ParticipantCreationRole.MyTeamMember, "Team Member of Seller"],
  [ParticipantCreationRole.GeneralPartner, "General Partner"],
  [ParticipantCreationRole.SecondaryBuyer, "Principal of Buyer"],
  [ParticipantCreationRole.BuyerTeamMember, "Team Member of Buyer"],
  [ParticipantCreationRole.BuyerAdvisor, "Advisor of Buyer"],
]);

export const ParticipantRoleNames = new Map<ParticipantRole, string>([
  [ParticipantRole.Advisor, "Advisor"],
  [ParticipantRole.TeamMember, "Team Member"],
  [ParticipantRole.Principal, "Principal"],
]);

export const ParticipantCapacityNames = new Map<ParticipantCapacity, string>([
  [ParticipantCapacity.Buyer, "Buyer"],
  [ParticipantCapacity.Seller, "Seller"],
  [ParticipantCapacity.Manager, "Manager"],
]);

export const ParticipantCapacityOptions = [
  {
    value: ParticipantCapacity.Seller,
    label: "Seller",
  },
  {
    value: ParticipantCapacity.Buyer,
    label: "Buyer",
  },
  {
    value: ParticipantCapacity.Manager,
    label: "Manager",
  },
];

export const getParticipantPersona = (participant: IParticipant) => {
  return `${ParticipantRoleNames.get(
    participant.role,
  )} of ${ParticipantCapacityNames.get(participant.capacity)}`;
};

export interface ParticipantTransactionAction {
  type: ParticipantActionType;
  timestamp: string;
}

export enum ParticipantActionType {
  // LP Led specific
  ViewedTeaser = 1,
  AccessedDataRoom = 2,
  SubmittedBid = 3,

  // tender specific
  DeclinedParticipation = 1001,
  OfferReviewed = 1002,
  InterestIndicated = 1003,
  FinalizedParticipation = 1004,
  TenderFileDownloaded = 1005,
  SingedOfferLetter = 1006,

  // universal
  ExitedTransaction = 99,
}
