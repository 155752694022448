export enum CapitalAccountStatementKeys {
  fundName = "fundName",
  managerName = "managerName",
  managerId = "managerId",
  psin = "psin",
  isHumanVerified = "isHumanVerified",
  nav = "nav",
  remainingUnfunded = "remainingUnfunded",
  percentUnfunded = "percentUnfunded",
  commitment = "commitment",
  percentFundCommitment = "percentFundCommitment",
  contributedCapital = "contributedCapital",
  distributedCapital = "distributedCapital",
  recallableCapital = "recallableCapital",
  tvpi = "tvpi",
  dpi = "dpi",
  percentWriteUpQoq = "percentWriteUpQoq",
  percentWriteUpYoy = "percentWriteUpYoy",
  postReferenceContributions = "postReferenceContributions",
  postReferenceDistributions = "postReferenceDistributions",
}

export interface ICapitalAccount {
  [CapitalAccountStatementKeys.fundName]: string;
  [CapitalAccountStatementKeys.managerName]: string;
  [CapitalAccountStatementKeys.managerId]: string;
  [CapitalAccountStatementKeys.psin]: string;
  [CapitalAccountStatementKeys.isHumanVerified]: boolean;
  [CapitalAccountStatementKeys.nav]: string | null;
  [CapitalAccountStatementKeys.remainingUnfunded]: string | null;
  [CapitalAccountStatementKeys.percentUnfunded]: string | null;
  [CapitalAccountStatementKeys.commitment]: string | null;
  [CapitalAccountStatementKeys.percentFundCommitment]: string | null;
  [CapitalAccountStatementKeys.contributedCapital]: string | null;
  [CapitalAccountStatementKeys.distributedCapital]: string | null;
  [CapitalAccountStatementKeys.recallableCapital]: string | null;
  [CapitalAccountStatementKeys.tvpi]: string | null;
  [CapitalAccountStatementKeys.dpi]: string | null;
  [CapitalAccountStatementKeys.percentWriteUpQoq]: string | null;
  [CapitalAccountStatementKeys.percentWriteUpYoy]: string | null;
  [CapitalAccountStatementKeys.postReferenceContributions]: string | null;
  [CapitalAccountStatementKeys.postReferenceDistributions]: string | null;
}

export interface IFundDataCapitalAccounts {
  capitalAccounts: ICapitalAccount[];
}
