import { FairnessOpinion } from "../models/FairnessOpinion";

export const fairnessOpinionListMock: FairnessOpinion[] = [
  {
    transaction: "Fund IV Q2 2024 Tender",
    period: "Q2 2024",
    fund: "Viewpoint Partners Fund IV",
    opinion: "FAIR",
  },
  {
    transaction: "Fund III Q2 2024 Tender",
    period: "Q2 2024",
    fund: "Viewpoint Partners Fund III",
    opinion: "FAIR",
  },
  {
    transaction: "Opp Q2 2024 Tender",
    period: "Q2 2024",
    fund: "Viewpoint Partners Opportunities",
    opinion: "FAIR",
  },
  {
    transaction: "Fund IV Q4 2023 Tender",
    period: "Q4 2023",
    fund: "Viewpoint Partners Fund IV",
    opinion: "FAIR",
  },
  {
    transaction: "Fund III Q4 2023 Tender",
    period: "Q4 2023",
    fund: "Viewpoint Partners Fund III",
    opinion: "FAIR",
  },
  {
    transaction: "Opp Q4 2023 Tender",
    period: "Q4 2023",
    fund: "Viewpoint Partners Opportunities",
    opinion: "FAIR",
  },
  {
    transaction: "Project Gallic",
    period: "Q2 2023",
    fund: "Viewpoint Partners Fund II",
    opinion: "FAIR",
  },
];
