import { createContext, useContext } from "react";

import { ICurrentUser } from "../../domain/models/CurrentUser";
import { TabType } from "../../domain/models/TabType";
import { AuthenticateUser } from "../../domain/usecases/AuthenticateUser";
import { GetCurrentUser } from "../../domain/usecases/GetCurrentUser";
import { HandleLogout } from "../../domain/usecases/HandleLogout";
import { RegisterUser } from "../../domain/usecases/RegisterUser";
import { SendRegStepSlackEvent } from "../../domain/usecases/SendRegStepSlackEvent";
import { SubmitLoginForm } from "../../domain/usecases/SubmitLoginForm";
import { AuthenticationEvent } from "./AuthenticationEvent";
import { useManageAuthenticationState } from "./AuthenticationManager";
import { AuthenticationState, IStepDetail } from "./AuthenticationState";
import { AuthenticateUserWithOTP } from "../../domain/usecases/AuthenticateUserWithOTP";
import { OnboardInvitee } from "../../domain/usecases/OnboardInvitee";
import { ValidateInvitee } from "../../domain/usecases/ValidateInvitee";
import { SubmitImpersonationForm } from "../../domain/usecases/SubmitImpersonationForm";
import { StopImpersonation } from "../../domain/usecases/StopImpersonation";
import { GetImpersonationInfo } from "../../domain/usecases/GetImpersonationInfo";

export interface AuthenticationContextProps {
  loginPageState?: AuthenticationState;
  sessionClaimState?: AuthenticationState;
  user?: ICurrentUser;
  userIsLoading?: boolean;
  selectedTab?: TabType;
  emitEvent?: (event: AuthenticationEvent) => void;
  currRegStepState?: IStepDetail;
  canCurrentUserImpersonate?: () => boolean;
}

const initialContextState: AuthenticationContextProps = {};

const AuthenticationContext = createContext(initialContextState);

interface IAuthenticationStateProviderProps {
  children: React.ReactNode;
}

const AuthenticationStateProvider = ({
  children,
}: IAuthenticationStateProviderProps) => {
  const {
    loginPageState,
    sessionClaimState,
    emitEvent,
    user,
    userIsLoading,
    selectedTab,
    currRegStepState,
    canCurrentUserImpersonate,
  } = useManageAuthenticationState({
    authUserWithOTP: new AuthenticateUserWithOTP(),
    submitLoginForm: new SubmitLoginForm(),
    authenticateUser: new AuthenticateUser(),
    getCurrentUser: new GetCurrentUser(),
    handleLogout: new HandleLogout(),
    sendRegStepSlackEvent: new SendRegStepSlackEvent(),
    registerUser: new RegisterUser(),
    validateInvitee: new ValidateInvitee(),
    onboardInvitee: new OnboardInvitee(),
    submitImpersonationForm: new SubmitImpersonationForm(),
    stopImpersonation: new StopImpersonation(),
    getImpersonationInfo: new GetImpersonationInfo(),
  });

  return (
    <AuthenticationContext.Provider
      value={{
        loginPageState,
        emitEvent,
        user,
        userIsLoading,
        sessionClaimState,
        selectedTab,
        currRegStepState,
        canCurrentUserImpersonate,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthContext must be used within a AuthenticationStateProvider",
    );
  }
  return context;
};

export { AuthenticationStateProvider, AuthenticationContext };
