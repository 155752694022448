import { PageHead } from "common/components/PageHead";
import { CommentaryUpdates } from "experiences/transactions/presentation/components/fund-data/commentary/CommentaryUpdates";
import { CompanyDataSection } from "experiences/transactions/presentation/components/fund-data/commentary/CompanyData";
import { LiquidityProjectionSection } from "experiences/transactions/presentation/components/fund-data/commentary/LiquidityProjection";
import { ManagerAssessmentSection } from "experiences/transactions/presentation/components/fund-data/commentary/ManagerAssessment";
import { EntitiesSelector } from "../components/EntitiesDropdown";
import { useCommentary } from "../components/useCommentary";
import { parseStrToNumber } from "experiences/common/excel/utils";

export const CommentaryPage = () => {
  const {
    managerAssessmentCharts,
    liquidityProjectionChart,
    commentaryUpdates,
    companyData,
    loading,
    entitySelector,
    reportDate,
  } = useCommentary();

  const sortedUpdates = [...commentaryUpdates].sort(
    (a, b) =>
      (parseStrToNumber(b.lpImpliedTransactionValue || "") || 0) -
      (parseStrToNumber(a.lpImpliedTransactionValue || "") || 0),
  );

  return (
    <div className="w-full">
      <PageHead
        title="Commentary"
        actions={
          <EntitiesSelector
            entitiesOptions={entitySelector.entitiesOptions}
            selectedEntitiesIds={entitySelector.selectedEntitiesIds}
            onSelectEntity={entitySelector.onSelectEntity}
            onRemoveEntity={entitySelector.onRemoveEntity}
            onToggleAllEntities={entitySelector.onToggleAllEntities}
          />
        }
      />
      <ManagerAssessmentSection
        managerAssessmentCharts={managerAssessmentCharts}
        loading={loading}
        reportDate={reportDate}
      />
      {/* <LiquidityProjectionSection
        chart={liquidityProjectionChart}
        loading={loading}
      /> */}
      <CommentaryUpdates updatesData={sortedUpdates} loading={loading} />
      <CompanyDataSection data={companyData} loading={loading} />
    </div>
  );
};
