import TextChip from "experiences/common/TextChip";
import { Button } from "shadcn/ui/Button";

interface ITaskContainerProps {
  title: string;
  description: string;
  icon: JSX.Element;
  children: React.ReactNode;
}

export const TaskContainer: React.FC<ITaskContainerProps> = ({
  title,
  description,
  icon,
  children,
}) => {
  return (
    <div className="flex flex-row p-4 justify-between items-center border border-stone-200 bg-stone-50 rounded-xl m-4">
      <div className="flex flex-row flex-row items-center gap-4">
        {icon}
        <div className="flex flex-col justify-center">
          <p className="font-bold">{title}</p>
          <p className="text-sm text-stone-500">{description}</p>
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center">{children}</div>
    </div>
  );
};

export interface ITaskProps {
  title: string;
  description: string;
  Icon: JSX.Element;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  textChipString?: string;
  isChipSuccess?: boolean;
  isChipPending?: boolean;
}

export const Task: React.FC<ITaskProps> = ({
  title,
  description,
  Icon,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  textChipString,
  isChipSuccess,
  isChipPending,
}) => {
  return (
    <TaskContainer title={title} description={description} icon={Icon}>
      <>
        {textChipString && (
          <div>
            <TextChip
              size="medium"
              text={textChipString}
              pending={isChipPending}
              success={isChipSuccess}
            />
          </div>
        )}
        {primaryButtonText && onPrimaryButtonClick && (
          <Button onClick={onPrimaryButtonClick}>{primaryButtonText}</Button>
        )}
        {secondaryButtonText && onSecondaryButtonClick && (
          <Button variant="outline" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
        )}
      </>
    </TaskContainer>
  );
};
