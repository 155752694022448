import { ArrowRight } from "@phosphor-icons/react";
import styled from "styled-components";

const StyledRightArrow = styled.span`
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
`;

export const RightArrow = () => {
  return (
    <StyledRightArrow>
      <ArrowRight />
    </StyledRightArrow>
  );
};
