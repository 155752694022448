import { LineSegments } from "@phosphor-icons/react";
import { generatePath, useNavigate } from "react-router";

import { GP_ROUTES } from "common/constants/routes";
import { GpDashboardTransaction } from "experiences/dashboard/mock/GP";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { DEMO_TXN_ID } from "common/constants/platform";

import { GpDashboardTaskList } from "./GpDashboardTaskList";
import { useGpDashboardTaskList } from "./useGpDashboardTaskList";

import { GpDashboardTask } from "../GpDashboardPage";
import { GpDashboardCenterColumnContent } from "../../components/DashboardCenterColumn";
import { ActivityEntry } from "../../components/ActivityEntry";

const GpDashboardTransactionList = ({
  transactions,
  transactionsLoaded,
}: {
  transactions: GpDashboardTransaction[];
  transactionsLoaded: boolean;
}) => {
  const navigate = useNavigate();

  const handleTransactionClick = (transaction: GpDashboardTransaction) => {
    const path = generatePath(GP_ROUTES.TransactionDetailOverview, {
      transactionId: DEMO_TXN_ID,
    });
    navigate(path);
  };

  const countLabel = transactions
    ? transactions.length > 0
      ? String(transactions.length)
      : null
    : null;

  return (
    <>
      <PageSectionTitleDivider showBorderTop countLabel={countLabel}>
        Transactions
      </PageSectionTitleDivider>
      {transactions.map((event, i) => {
        // const logo = <DefaultFundIcon className="w-4 " />;

        const quarterLabel =
          event.txnName.split(" ").find((word) => word.startsWith("Q")) || "";

        const tokens = [
          {
            ...(quarterLabel
              ? {
                  icon: "",
                  value: quarterLabel,
                  label: `Tender Offer`,
                }
              : {}),
          },
          {
            icon: <LineSegments />,
            value: "",
            label: event.type,
          },
        ];

        return (
          <ActivityEntry
            transactionLogo={
              !!quarterLabel ? (
                <img
                  src={`https://cdn.usetap.com/funds/logos/ViewpointPartners.jpg`}
                />
              ) : undefined
            }
            title={event.txnName}
            tokens={tokens}
            onDetailClick={() => handleTransactionClick(event)}
            key={i}
            tags={[String(event.alert)]}
          />
        );
      })}
    </>
  );
};

export const GpDashboardCenterColumn = ({
  tasks: innerTasks, // innerTasks are tasks before appending Active IOIs (won't be there 100% of the time)
  transactions,
  transactionsLoaded,
}: {
  tasks: GpDashboardTask[];
  transactions: GpDashboardTransaction[];
  transactionsLoaded: boolean;
}) => {
  const { count, tasks } = useGpDashboardTaskList({
    tasks: innerTasks,
  });

  return (
    <GpDashboardCenterColumnContent>
      <GpDashboardTaskList tasks={tasks} count={count} />
      <GpDashboardTransactionList
        transactions={transactions}
        transactionsLoaded={transactionsLoaded}
      />
    </GpDashboardCenterColumnContent>
  );
};
