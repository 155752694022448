import { gql } from "@apollo/client";

export const GetPortfolioCashflowsQuery = gql`
  query Query($entitiesIds: [String!]!, $reportDate: Date!) {
    interimCashflows(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      fund
      issuerId
      noticeId
      type
      amount
      wireDate
      description
    }
  }
`;
