import React from "react";
import styled from "styled-components";

const StyledChip = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  padding: 5px 9px 5px 6px;
  border: 1px dashed #dfdfd9;
  border-radius: 40px;
  white-space: nowrap;

  cursor: pointer;

  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 13px;

  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #b9b9b0;
  }

  flex: none;
  order: 1;
  flex-grow: 0;

  & .pill-value {
    font-weight: 500;
    margin-left: 4px;
  }
`;

interface IPillProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const Pill: React.FC<IPillProps> = ({ children, onClick }) => {
  return <StyledChip onClick={onClick}>{children}</StyledChip>;
};
