import { useEffect, useState } from "react";

import { CircleChartData } from "experiences/common/CircleChart";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import {
  coreSectorLabels,
  investmentGeographyLabels,
} from "experiences/transactions/domain/models/FundDataSoiLookthrough";
import { strategyLabels } from "experiences/transactions/domain/models/FundExposureSummary";

import {
  IPortfolioExposureSummary,
  IPortfolioExposureSummaryWithGrouping,
} from "../models/PortfolioExposureSummary";

enum ExposureSegmentationKey {
  Strategy = "strategy",
  Geography = "geography",
  Sector = "sector",
}

const generateCircleChartData = (
  data: IPortfolioExposureSummary[],
  key: keyof IPortfolioExposureSummary,
): CircleChartData[] => {
  // Step 1: Group al entries in data by strategy key

  const nav = data.reduce((acc, item) => {
    return acc + parseFloat(item.lpEndingCapitalAccountValue || "0");
  }, 0);

  // TODO: add placeholder data entry if nav is 0 so that chart is not empty
  if (nav === 0) {
    return [];
  }

  const groupedData: Record<string, IPortfolioExposureSummary[]> = data.reduce(
    (acc, item) => {
      const keyValue = item[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(item);
      return acc;
    },
    {},
  );

  const groupedAndAddedNav = Object.entries(groupedData).map(
    ([name, items]) => {
      return {
        name,
        value: items.reduce(
          (acc, item) =>
            acc + parseFloat(item.lpEndingCapitalAccountValue || "0"),
          0,
        ),
      };
    },
  );

  // Divide each value by nav to get the percentage
  return groupedAndAddedNav.map((item) => {
    let name = item.name;
    if (key === ExposureSegmentationKey.Strategy) {
      name = strategyLabels[name] || NOT_AVAILABLE_STR;
    }
    if (key === ExposureSegmentationKey.Geography) {
      name = investmentGeographyLabels[name] || NOT_AVAILABLE_STR;
    }
    if (key === ExposureSegmentationKey.Sector) {
      name = coreSectorLabels[name] || NOT_AVAILABLE_STR;
    }

    return {
      name,
      percentage: item.value / nav,
    };
  });
};

export const useExposureSegmentationByNav = ({
  data,
  loading,
}: {
  data: IPortfolioExposureSummaryWithGrouping | null;
  loading: boolean;
}) => {
  const [exposureSegmentationCharts, setExposureSegmentationCharts] = useState<{
    strategy: CircleChartData[];
    geography: CircleChartData[];
    sector: CircleChartData[];
  }>({ strategy: [], geography: [], sector: [] });

  useEffect(() => {
    const geographyChartData = generateCircleChartData(
      data?.reportData || [],
      ExposureSegmentationKey.Geography,
    );
    const strategyChartData = generateCircleChartData(
      data?.reportData || [],
      ExposureSegmentationKey.Strategy,
    );
    const sectorChartData = generateCircleChartData(
      data?.reportData || [],
      ExposureSegmentationKey.Sector,
    );
    setExposureSegmentationCharts({
      strategy: strategyChartData,
      geography: geographyChartData,
      sector: sectorChartData,
    });
  }, [data]);

  return {
    exposureSegmentationCharts,
    loading,
  };
};
