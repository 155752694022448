import { match } from "fp-ts/lib/Either";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Smartlook from "smartlook-client";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { UNIVERSAL_ROUTES } from "common/constants/routes";
import { Failure } from "common/@types/app/Failure";
import { NoReturn } from "common/@types/app/UseCase";
import { getTabIndexFromPathname } from "common/utils/getTabIndexFromPathname";

import { IRegistrationForm } from "../../domain/models/RegForm";
import { RegisterUser } from "../../domain/usecases/RegisterUser";
import { TabType, TabTypeRoute } from "../../domain/models/TabType";
import {
  ICurrentUser,
  isCurrentUserAdmin,
} from "../../domain/models/CurrentUser";
import { AuthenticateUser } from "../../domain/usecases/AuthenticateUser";
import { GetCurrentUser } from "../../domain/usecases/GetCurrentUser";
import { HandleLogout } from "../../domain/usecases/HandleLogout";
import { SendRegStepSlackEvent } from "../../domain/usecases/SendRegStepSlackEvent";
import { SubmitLoginForm } from "../../domain/usecases/SubmitLoginForm";

import {
  CreateYourAccountFormSubmitted,
  SelectYourOrganizationFormSubmitted as PickOrganizationFormSubmitted,
  AuthenticationEvent,
  CurrentUserLogoutRequested,
  InitialSignupPageSubmitted,
  LoginSessionClaimLinkActivated,
  OTPLoginSubmitted,
  RedirectedToInviteeOnboarding,
  RegisterButtonClickedOnLoginFlow,
  SelectPreferencesFormSubmitted,
  SelectGeographiesFormSubmitted,
  SendLoginEmailButtonClicked,
  SetSelectedTabInAuthedAppBar,
  ImpersonateUserFormSubmitted,
  StopImpersonationClicked,
  ResetLoginState,
} from "./AuthenticationEvent";
import {
  LoginFormLoaded,
  AuthenticationState,
  SendingLoginEmailFailed,
  SendingLoginEmailInProgress,
  SendingLoginEmailSucceeded,
  LoginFormState,
  LoginSessionClaimState,
  LoginSessionClaimRejected,
  LoginSessionClaimSuccess,
  LoginSessionClaimInProgress,
  IStepDetail,
  CreateYourAccountStepLoaded,
  SelectYourOrganizationStepLoaded,
  CreateProfileStepLoaded,
  SelectGeographiesStepLoaded,
  RegistrationSubmittedStepLoaded,
  CreateAccountFailed,
} from "./AuthenticationState";
import { AuthenticateUserWithOTP } from "../../domain/usecases/AuthenticateUserWithOTP";
import { OnboardInvitee } from "../../domain/usecases/OnboardInvitee";
import { ValidateInvitee } from "../../domain/usecases/ValidateInvitee";
import { RegStep } from "../../domain/models/RegStep";
import { RegAssetClassPref } from "../../domain/models/RegAssetClassPref";
import { IValidatedInviteeData } from "../../domain/models/ValidatedInviteeData";
import { RegGeography } from "../../domain/models/RegGeography";
import { RegRangeNames, RegRangeValues } from "../../domain/models/RegRange";
import { SubmitImpersonationForm } from "../../domain/usecases/SubmitImpersonationForm";
import { StopImpersonation } from "../../domain/usecases/StopImpersonation";
import { GetImpersonationInfo } from "../../domain/usecases/GetImpersonationInfo";
import useLocalStorage from "common/hooks/useLocalStorage";
import { AUTH_KEYS } from "common/constants/localStorage";
import { IImpersonationInfo } from "experiences/authentication/domain/models/ImpersonationInfo";
import {
  Organization,
  OrganizationsThatSkipOnboardingInvestmentPreferences,
} from "experiences/authentication/domain/models/Organization";

export const REDIRECT_QUERY_KEY = "redirect";

interface IUseCases {
  submitLoginForm: SubmitLoginForm;
  authenticateUser: AuthenticateUser;
  getCurrentUser: GetCurrentUser;
  handleLogout: HandleLogout;
  sendRegStepSlackEvent: SendRegStepSlackEvent;
  authUserWithOTP: AuthenticateUserWithOTP;
  registerUser: RegisterUser;
  onboardInvitee: OnboardInvitee;
  validateInvitee: ValidateInvitee;
  submitImpersonationForm: SubmitImpersonationForm;
  stopImpersonation: StopImpersonation;
  getImpersonationInfo: GetImpersonationInfo;
}

export const useManageAuthenticationState = ({
  submitLoginForm,
  authenticateUser,
  getCurrentUser,
  handleLogout,
  sendRegStepSlackEvent,
  authUserWithOTP,
  registerUser,
  onboardInvitee,
  validateInvitee,
  submitImpersonationForm,
  stopImpersonation,
  getImpersonationInfo,
}: IUseCases) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loginPageState, setLoginPageState] = useState<LoginFormState>(
    new LoginFormLoaded(),
  );

  const [searchParams] = useSearchParams();

  const defaultTab =
    getTabIndexFromPathname(TabTypeRoute) || TabType.Transactions;
  const [selectedTab, setSelectedTab] = useState<TabType>(defaultTab);
  const [sessionClaimState, setSessionClaimState] =
    useState<LoginSessionClaimState>();

  const [user, setUser] = useState<ICurrentUser>();
  const [userIsLoading, setUserIsLoading] = useState<boolean>(true);
  const [initialRequestedPath, setInitialRequestedPath] = useState<string>("");
  const location = useLocation();

  // this state is used only when the reg flow is used for invitees
  const [inviteeId, setInviteeId] = useState<string>();
  const [inviteeEmail, setInviteeEmail] = useState<string>();
  const [preSelectedOrg, setPreSelectedOrg] = useState<Organization>();
  const [currRegStepState, setCurrRegStepState] = useState<IStepDetail>();
  const [regForm, setRegForm] = useState<IRegistrationForm>({});
  const [impersonationState, setImpersonationState] = useLocalStorage(
    AUTH_KEYS.IMPERSONATION,
    undefined,
  );
  let previousEvent: AuthenticationEvent;

  const refreshUser = (
    onGettingUser?: (retrievedUser: ICurrentUser) => void,
  ) => {
    getCurrentUser.call({}).then((resp) => {
      match<Failure, ICurrentUser, void>(
        () => {
          setUser(undefined);
        },
        (user: ICurrentUser) => {
          setUser(user);
        },
      )(resp);
      setUserIsLoading(false);
    });
  };

  const canCurrentUserImpersonate = () => {
    return isCurrentUserAdmin(user!);
  };

  useEffect(() => {
    setInitialRequestedPath((prev) => {
      if (prev.trim() == "") return location.pathname + location.search;
      return prev;
    });
  }, []);

  useEffect(() => {
    if (impersonationState) {
      getImpersonationInfo.call({}).then((resp) => {
        match<Failure, IImpersonationInfo, void>(
          () => {},
          (info: IImpersonationInfo) => {
            setImpersonationState(JSON.stringify(info));
          },
        )(resp);
      });
    }
  }, [user]);

  // try to get a user every time you instantiate
  useEffect(() => {
    if (!Smartlook.initialized()) {
      Smartlook.disable();
      Smartlook.record({ forms: true });
    }
    refreshUser((retrievedUser: ICurrentUser) => {
      Smartlook.identify(retrievedUser.id, {
        name: `${retrievedUser.firstName} ${retrievedUser.lastName}`,
        // in the future log more fields
      });
    });
  }, []);

  const emitEvent = (event: AuthenticationEvent) => {
    // don't allow spamming of events, this is probably a good idea for authentication
    // but its not a good idea for most other features where there is a potential of
    // emitting the same UI event twice in a row
    if (event === previousEvent) {
      return;
    }

    if (event instanceof SendLoginEmailButtonClicked) {
      setLoginPageState(new SendingLoginEmailInProgress());
      submitLoginForm
        .call({
          email: event.email,
          redirectOnLogin: btoa(initialRequestedPath),
        })
        .then((resp) =>
          setLoginPageState(
            match<Failure, NoReturn, AuthenticationState>(
              (failure: Failure) => new SendingLoginEmailFailed(failure),
              () => new SendingLoginEmailSucceeded(),
            )(resp),
          ),
        );
    } else if (event instanceof LoginSessionClaimLinkActivated) {
      authenticateUser
        .call({ uidb64: event.idb64, token: event.token })
        .then((resp) => {
          setSessionClaimState(new LoginSessionClaimInProgress());

          match<Failure, ICurrentUser, void>(
            () => {
              setSessionClaimState(
                new LoginSessionClaimRejected(
                  "The link is invalid or expired, please try to login again",
                ),
              );
            },
            (user: ICurrentUser) => {
              localStorage.removeItem("isImpersonating");
              localStorage.removeItem(AUTH_KEYS.IMPERSONATION);
              setUser(user);
              setUserIsLoading(false);
              if (searchParams.get(REDIRECT_QUERY_KEY)) {
                navigate(atob(searchParams.get(REDIRECT_QUERY_KEY)!));
              }
              setSessionClaimState(new LoginSessionClaimSuccess());
            },
          )(resp);
        });
    } else if (event instanceof CurrentUserLogoutRequested) {
      setUser(undefined);
      localStorage.removeItem("isImpersonating");
      localStorage.removeItem(AUTH_KEYS.IMPERSONATION);
      handleLogout.call({});
    } else if (event instanceof SetSelectedTabInAuthedAppBar) {
      setSelectedTab(event.tab);
    } else if (event instanceof ImpersonateUserFormSubmitted) {
      submitImpersonationForm
        .call({
          userId: event.userId,
          reason: event.reason,
        })
        .then((resp) => {
          setUserIsLoading(true);
          localStorage.setItem("isImpersonating", "true");
          match<Failure, ICurrentUser, void>(
            (failure: Failure) => {
              enqueueSnackbar(failure.message, { variant: "error" });
              setUser(undefined);
            },
            (user: ICurrentUser) => {
              setImpersonationState(JSON.stringify({ impersonating: true }));
              setUser(user);
            },
          )(resp);
          setUserIsLoading(false);
        });
    } else if (event instanceof StopImpersonationClicked) {
      stopImpersonation.call({}).then((resp) => {
        setUserIsLoading(true);
        match<Failure, ICurrentUser, void>(
          (failure: Failure) => {
            enqueueSnackbar(failure.message, { variant: "error" });
            setUser(undefined);
          },
          (user: ICurrentUser) => {
            setImpersonationState(undefined);
            localStorage.removeItem("isImpersonating");
            localStorage.removeItem(AUTH_KEYS.IMPERSONATION);
            setUser(user);
          },
        )(resp);
        setUserIsLoading(false);
      });
    } else if (event instanceof InitialSignupPageSubmitted) {
      sendRegStepSlackEvent.call({
        isForInvitee: Boolean(inviteeId),
        regStep: RegStep.Initial,
        payload: {
          zipCode: event.regForm.zipcode,
        },
      });
      setRegForm({
        ...regForm,
        zipcode: event.regForm.zipcode,
      });
      setCurrRegStepState(new CreateYourAccountStepLoaded({}));
    } else if (event instanceof CreateYourAccountFormSubmitted) {
      // slack the message
      sendRegStepSlackEvent.call({
        regStep: RegStep.CreateAccount,
        isForInvitee: Boolean(inviteeId),
        payload: {
          Email: event.regForm.email,
          "Full Name": `${event.regForm.firstName} ${event.regForm.lastName}`,
          "Phone Number": event.regForm.phone || "Not Provided",
          "Is Accredited Investor": event.regForm.isAccreditedInvestor
            ? "Yes"
            : "No",
        },
      });

      setRegForm({
        ...regForm,
        firstName: event.regForm.firstName,
        lastName: event.regForm.lastName,
        email: event.regForm.email,
        phone: event.regForm.phone,
        isAccreditedInvestor: event.regForm.isAccreditedInvestor,
      });

      setCurrRegStepState(
        new SelectYourOrganizationStepLoaded({
          preSelectedOrg,
          fullName: `${event.regForm.firstName} ${event.regForm.lastName}`,
        }),
      );
    } else if (event instanceof PickOrganizationFormSubmitted) {
      sendRegStepSlackEvent.call({
        regStep: RegStep.CreateAccountInfo,
        isForInvitee: Boolean(inviteeId),
        payload: {
          "Firm Name": event.regForm?.firmName || "Not Part",
        },
      });

      // if the firm type is in the list of firms that skip onboarding
      if (
        OrganizationsThatSkipOnboardingInvestmentPreferences.includes(
          event.regForm.firmType,
        )
      ) {
        setCurrRegStepState(new RegistrationSubmittedStepLoaded());

        const firmForm = {
          isFirmMember: event.regForm.isFirmMember,
          firmId: event.regForm.firmId,
          firmName: event.regForm.firmName,
          firmType: event.regForm.firmType,
        };

        // update the state, just in case
        setRegForm({
          ...regForm,
          ...firmForm,
          marketEngagement: event.regForm.marketEngagement,
        });

        // manually attach firmForm values, do not rely on setState because it is async and we need the values immediately
        if (inviteeId) {
          onboardInvitee.call({
            form: {
              ...regForm,
              ...firmForm,
              ...{ inviteeId: inviteeId ?? "" },
            },
          });
          enqueueSnackbar("Success! Login with your email", {
            variant: "success",
          });
          navigate(UNIVERSAL_ROUTES.AuthLogin);
        } else {
          registerUser.call({
            form: {
              ...regForm,
              ...firmForm,
              ...{
                inviteeId: inviteeId ?? "",
              },
            },
          });
          setRegForm({}); // reset the form
        }
        return;
      }

      setRegForm({
        ...regForm,
        isFirmMember: event.regForm.isFirmMember,
        firmId: event.regForm.firmId,
        firmName: event.regForm.firmName,
        firmType: event.regForm.firmType,
        marketEngagement: event.regForm.marketEngagement,
      });
      setCurrRegStepState(new CreateProfileStepLoaded());
    } else if (event instanceof SelectPreferencesFormSubmitted) {
      sendRegStepSlackEvent.call({
        regStep: RegStep.InvestmentPreferences,
        isForInvitee: Boolean(inviteeId),
        payload: {
          "Interest In Venture Capital": event.regForm.interestedIn?.includes(
            RegAssetClassPref.VentureCapital,
          )
            ? "Yes"
            : "No",
          "Interest In Secondaries": event.regForm.interestedIn?.includes(
            RegAssetClassPref.Secondaries,
          )
            ? "Yes"
            : "No",
          "Interest In Growth": event.regForm.interestedIn?.includes(
            RegAssetClassPref.Growth,
          )
            ? "Yes"
            : "No",
          "Interest In Private Debt": event.regForm.interestedIn?.includes(
            RegAssetClassPref.PrivateDebt,
          )
            ? "Yes"
            : "No",
          "Interest In Buyout": event.regForm.interestedIn?.includes(
            RegAssetClassPref.Buyout,
          )
            ? "Yes"
            : "No",
          "Interest In Natural Resources": event.regForm.interestedIn?.includes(
            RegAssetClassPref.NaturalResources,
          )
            ? "Yes"
            : "No",
          "Interest In Infrastructure": event.regForm.interestedIn?.includes(
            RegAssetClassPref.Infrastructure,
          )
            ? "Yes"
            : "No",
          "Interest In Real Estate": event.regForm.interestedIn?.includes(
            RegAssetClassPref.RealEstate,
          )
            ? "Yes"
            : "No",
          "Interest In Fund Of Funds": event.regForm.interestedIn?.includes(
            RegAssetClassPref.FundOfFunds,
          )
            ? "Yes"
            : "No",
          "Firm's Investment Lower Range": RegRangeNames.get(
            event.regForm.investmentSizeRange?.start,
          ),
          "Firm's Investment Higher Range": RegRangeNames.get(
            event.regForm.investmentSizeRange?.end,
          ),
        },
      });

      setRegForm({
        ...regForm,
        interestedIn: event.regForm.interestedIn,
        investmentSizeRange: {
          start: RegRangeValues.get(event.regForm.investmentSizeRange?.start)!,
          end: RegRangeValues.get(event.regForm.investmentSizeRange?.end)!,
        },
      });

      setCurrRegStepState(new SelectGeographiesStepLoaded());
    } else if (event instanceof SelectGeographiesFormSubmitted) {
      const regParams = {
        form: {
          ...regForm,
          geographiesInterestedIn: event.regForm.geographiesInterestedIn,
          inviteeId: inviteeId ?? "",
        },
      };

      if (inviteeId) {
        onboardInvitee.call(regParams);
        enqueueSnackbar("Success! Login with your email", {
          variant: "success",
        });
        navigate(UNIVERSAL_ROUTES.AuthLogin);
      } else {
        registerUser.call(regParams);
        setRegForm({}); // reset the form
      }

      sendRegStepSlackEvent.call({
        regStep: RegStep.InvestmentPreferencesGeo,
        isForInvitee: Boolean(inviteeId),
        payload: {
          "Invest In North America":
            event.regForm.geographiesInterestedIn?.includes(
              RegGeography.NortAmerica,
            )
              ? "Yes"
              : "No",
          "Invest In Middle East":
            event.regForm.geographiesInterestedIn?.includes(
              RegGeography.MiddleEast,
            )
              ? "Yes"
              : "No",
          "Invest In Latin America":
            event.regForm.geographiesInterestedIn?.includes(
              RegGeography.LatinAmerica,
            )
              ? "Yes"
              : "No",
          "Invest In Asia": event.regForm.geographiesInterestedIn?.includes(
            RegGeography.Asia,
          )
            ? "Yes"
            : "No",
          "Invest In Europe": event.regForm.geographiesInterestedIn?.includes(
            RegGeography.Europe,
          )
            ? "Yes"
            : "No",
          "Invest In China": event.regForm.geographiesInterestedIn?.includes(
            RegGeography.China,
          )
            ? "Yes"
            : "No",
          "Interest In Australia":
            event.regForm.geographiesInterestedIn?.includes(
              RegGeography.Australia,
            )
              ? "Yes"
              : "No",
          "Interest In Africa": event.regForm.geographiesInterestedIn?.includes(
            RegGeography.Africa,
          )
            ? "Yes"
            : "No",
        },
      });
      setCurrRegStepState(new RegistrationSubmittedStepLoaded());
    } else if (event instanceof ResetLoginState) {
      setLoginPageState(new LoginFormLoaded());
    } else if (event instanceof RegisterButtonClickedOnLoginFlow) {
      setLoginPageState(new LoginFormLoaded());
      // Note: we have a bug here
      // also reset the error state in case we were in an error state
      // example: go to this url, when the invite fails click on register
      // http://localhost:3000/auth/onboard/invitee/6hoNWnmnTl3LE/
      // This is an idea but it's not enough or correct to fix the bug
      // setCurrRegStepState(undefined);
    } else if (event instanceof OTPLoginSubmitted) {
      authUserWithOTP
        .call({
          form: {
            challengeId: event.challengeId,
            code: event.code,
          },
        })
        .then((resp) => {
          match<Failure, ICurrentUser, void>(
            (failure: Failure) => {
              enqueueSnackbar(failure.message, { variant: "error" });
              setUser(undefined);
            },
            (user: ICurrentUser) => {
              setUser(user);
              if (searchParams.get(REDIRECT_QUERY_KEY)) {
                navigate(atob(searchParams.get(REDIRECT_QUERY_KEY)!));
              }
            },
          )(resp);
          setUserIsLoading(false);
        });
    } else if (event instanceof RedirectedToInviteeOnboarding) {
      if (event.inviteeId)
        validateInvitee
          .call({
            inviteeId: event.inviteeId!,
          })
          .then((resp) => {
            match<Failure, IValidatedInviteeData, void>(
              (failure: Failure) => {
                enqueueSnackbar(failure.message, { variant: "error" });
                setCurrRegStepState(new CreateAccountFailed({}));
              },
              (invitee: IValidatedInviteeData) => {
                if (invitee.isAlreadyOnboarded) {
                  enqueueSnackbar("Already onboarded! Login with your email", {
                    variant: "success",
                  });
                  navigate(UNIVERSAL_ROUTES.AuthLogin);
                } else {
                  setCurrRegStepState(
                    new CreateYourAccountStepLoaded({ email: invitee.email }),
                  );
                  setInviteeEmail(invitee.email);
                  setInviteeId(event.inviteeId);
                  setPreSelectedOrg(invitee.preSelectedOrg);
                }
              },
            )(resp);
            setUserIsLoading(false);
          });
      else {
        setCurrRegStepState(new CreateYourAccountStepLoaded({}));
      }
    }

    // once we are done handling our event lets set it as previous state
    previousEvent = event;
  };

  return {
    loginPageState,
    emitEvent,
    user,
    sessionClaimState,
    refreshUser,
    userIsLoading,
    selectedTab,
    currRegStepState,
    canCurrentUserImpersonate,
  };
};
