import { RequestedBuyer } from "experiences/approved-buyers/domain/models/RequestedBuyer";
import { ApprovedBuyer } from "../../domain/models/ApprovedBuyer";
import { BlockedBuyer } from "experiences/approved-buyers/domain/models/BlockedBuyer";
import { ArchivedBuyer } from "experiences/approved-buyers/domain/models/ArchivedBuyer";

export class ApprovedBuyersState {}

export class ApprovedBuyersLoaded extends ApprovedBuyersState {
  approvedBuyers: ApprovedBuyer[];

  constructor({ approvedBuyers }: { approvedBuyers: ApprovedBuyer[] }) {
    super();
    this.approvedBuyers = approvedBuyers;
  }
}

export class RequestedBuyersLoaded extends ApprovedBuyersState {
  requestedBuyers: RequestedBuyer[];

  constructor({ requestedBuyers }: { requestedBuyers: RequestedBuyer[] }) {
    super();
    this.requestedBuyers = requestedBuyers;
  }
}

export class BlockedBuyersLoaded extends ApprovedBuyersState {
  blockedBuyers: BlockedBuyer[];

  constructor({ blockedBuyers }: { blockedBuyers: BlockedBuyer[] }) {
    super();
    this.blockedBuyers = blockedBuyers;
  }
}

export class ArchivedBuyersLoaded extends ApprovedBuyersState {
  archivedBuyers: ArchivedBuyer[];

  constructor({ archivedBuyers }: { archivedBuyers: ArchivedBuyer[] }) {
    super();
    this.archivedBuyers = archivedBuyers;
  }
}
