import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  CompanyReportDateName,
  IReportDates,
} from "experiences/portfolio-v2/domain/models/ReportDates";

import { GetCompanyReportDatesQuery } from "experiences/portfolio-v2/domain/usecases/GetCompanyReportDates";
import { getReportDatesForReportName } from "experiences/portfolio-v2/domain/hooks/getReportDatesForReportName";
import { companyClient } from "common/clients/ApolloClient";
import { toast } from "react-hot-toast";

export const useCompanyReportDates = (companyId: string) => {
  const [currentReportDate, setCurrentReportDate] = useState<string | null>(
    null,
  );
  const { data, loading, error, refetch } = useQuery<{
    reportDates: IReportDates[];
  }>(GetCompanyReportDatesQuery, {
    variables: { companyId },
    client: companyClient,
  });

  useEffect(() => {
    if (data?.reportDates.length > 0) {
      // Set to most recent report date
      // I'm assuming they're ordered by date but if not, we'll need to sort them
      setCurrentReportDate(data.reportDates[0].name);
    }
  }, [data]);

  useEffect(() => {
    if (companyId) {
      refetch({ companyId });
    }
  }, [companyId]);

  useEffect(() => {
    if (error) {
      toast.error(
        "@Mo: Error fetching report dates for company id: " + companyId,
      );
    }
  }, [error]);

  const companyDataReportDatesOptions = getReportDatesForReportName({
    reportDates: data?.reportDates || [],
    reportName: CompanyReportDateName.CompanyData,
  }).map((date) => ({
    label: date,
    value: date,
  }));

  const fundExposureSummaryReportDatesOptions = getReportDatesForReportName({
    reportDates: data?.reportDates || [],
    reportName: CompanyReportDateName.FundExposureSummary,
  }).map((date) => ({
    label: date,
    value: date,
  }));

  const qualitativeDataReportDatesOptions = getReportDatesForReportName({
    reportDates: data?.reportDates || [],
    reportName: CompanyReportDateName.QualitativeData,
  }).map((date) => ({
    label: date,
    value: date,
  }));

  const onReportDateChange = (reportDate: string) => {
    setCurrentReportDate(reportDate);
  };

  return {
    companyDataReportDatesOptions,
    fundExposureSummaryReportDatesOptions,
    qualitativeDataReportDatesOptions,
    loading,
    error,
    currentReportDate,
    onReportDateChange,
  };
};
