import { Either, left, right } from "fp-ts/lib/Either";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { Index } from "experiences/funds/domain/models/Index";
import formatNumber from "accounting-js/lib/formatNumber.js";

const INDEXES_URL = "/indexes/list";

export class LoadIndexes implements IAsyncUseCase<IParams, Index[]> {
  call: (params: IParams) => Promise<Either<Failure, Index[]>> = async (
    payload,
  ) => {
    return await new TapClient(INDEXES_URL, payload)
      .get({})
      .then((resp: Index[]) => {
        const indexes = resp.map((index) => ({
          ...index,
          mostRecentPrice: formatNumber(index.mostRecentPrice, {
            precision: 0,
          }),
        }));
        return right(indexes);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {}
