import TextChip from "experiences/common/TextChip";
import { classNames } from "primereact/utils";
import React from "react";
import styled from "styled-components";

const StyledPageTitle = styled.div<{
  hasDescription?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px 0px;
  margin-bottom: ${(props) => (props.hasDescription ? "16px" : "32px")};
  box-sizing: border-box;

  .title {
    display: flex;
    gap: 8px;
  }

  h1 {
    font-family: "StaffWide", "Open sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #21272d;
    user-select: none;
  }

  .actions {
    display: flex;
  }

  &.paddingless {
    padding: 0;
    margin-bottom: ${(props) => (props.hasDescription ? "16px" : "0px")};
  }

  &.center {
    justify-content: center;
  }
`;

const StyledPageDescription = styled.p`
  display: block;
  color: #737476;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 0 32px 32px;
  box-sizing: border-box;
  text-align: center;
  text-align: left;

  cursor: default;

  a {
    color: #bcb3a5;
    text-decoration: underline;
    cursor: pointer;
  }

  &.paddingless {
    padding: 0;
  }

  &.center {
    text-align: center;
  }
`;

const StyledHug = styled.span`
  display: flex;
  padding: 3px 10px;
  box-sizing: border-box;
  background-color: #f5f4f1;
  border-radius: 24px;
  font-family: "StaffWide", "Open sans", sans-serif;

  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: -0.01em;
  color: #737476;
  cursor: default;
  user-select: none;
  align-items: center;
`;

/**
 * PageHead is a component that is used to display a page title and description.
 * @param title The title of the page.
 * @param hug A small piece of text that is displayed next to the title.
 * @param titleStyle Custom styles to apply to the title.
 * @param descriptionStyle Custom styles to apply to the description.
 * @param actions Any actions that should be displayed next to the title.
 * @param description The description of the page, shown below the title.
 * @param paddingless Whether or not to remove the top and lateral padding from the title, and the space below the description (or title if there's no description).
 * @returns A page title element and an optional subtitle element, _they are not wrapped in a container_.
 * @example
 * <PageHead
 *   title="Configure Transaction"
 *   description="Select a name and configure basic parameters of this transaction."
 *   paddingless
 *   descriptionStyle={{ marginBottom: 48 }}
 * />
 */

export const PageHead = ({
  title,
  hug,
  titleStyle,
  descriptionStyle,
  actions,
  description,
  paddingless,
  alignCenter,
  countLabel,
  className,
}: {
  title?: React.ReactNode;
  hug?: React.ReactNode;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  actions?: React.ReactNode;
  description?: React.ReactNode;
  paddingless?: boolean;
  alignCenter?: boolean;
  countLabel?: string | number;
  className?: string;
}) => (
  <>
    <StyledPageTitle
      hasDescription={Boolean(description)}
      style={titleStyle}
      className={classNames({
        paddingless: paddingless,
        center: alignCenter,
        className: className,
      })}
    >
      <span className="title">
        <h1>{title}</h1>
        {hug && <StyledHug>{hug}</StyledHug>}
        {countLabel ? (
          <StyledHug>
            <TextChip
              text={countLabel}
              style={{ lineHeight: 1, padding: "2px 2px" }}
            />
          </StyledHug>
        ) : null}
      </span>
      <span className="actions">{actions}</span>
    </StyledPageTitle>
    {description && (
      <StyledPageDescription
        className={classNames({
          paddingless: paddingless,
          center: alignCenter,
        })}
        style={descriptionStyle}
      >
        {description}
      </StyledPageDescription>
    )}
  </>
);
