import { createContext, useContext } from "react";
import { FundFilterSpecs } from "../../domain/models/IssuerFilterSpecs";
import { TabType } from "../../domain/models/TabType";
import { RetrieveFilteredFunds } from "../../domain/usecases/RetrieveFilteredFunds";
import { RetrieveSingleFund } from "../../domain/usecases/RetrieveSingleFund";
import { MatchNames } from "experiences/funds/domain/usecases/MatchNames";
import { FundsEvent } from "./FundsEvent";
import { useManageFundsState } from "./FundsManager";
import {
  AlertsScreenState,
  FundScreenState,
  MultiSelectActionBeltState,
  VerifyPermissionState,
} from "./FundsState";
import { CreateNewAlert } from "../../domain/usecases/CreateNewAlert";
import { ListAlerts } from "../../domain/usecases/ListAlerts";
import { ToggleAlertActivation } from "../../domain/usecases/ToggleAlertActivation";
import { FundToWatchlistAction } from "../../domain/usecases/FundToWatchlistAction";
import { ListWatchlistUserFunds } from "../../domain/usecases/ListWatchlistUserFunds";
import { FlagFundApprovedBuyer } from "experiences/funds/domain/usecases/FlagFundApprovedBuyer";
import { RetrieveSingleFundSummary } from "experiences/funds/domain/usecases/RetrieveSingleFundSummary";

export interface FundsContextProps {
  fundTablePageState?: FundScreenState;
  watchlistState?: FundScreenState;
  actionBeltState?: MultiSelectActionBeltState;
  alertTablePageState?: AlertsScreenState;
  selectedTab?: TabType;
  filtersSpecs?: FundFilterSpecs;
  isBulkWatchlistLoading?: boolean;
  emitEvent?: (event: FundsEvent) => void;
  retrieveSingleFund?: RetrieveSingleFund;
  retrieveSingleFundSummary?: RetrieveSingleFundSummary;
  matchNames?: MatchNames;
  verifyPermState?: VerifyPermissionState;
}

const initialContextState: FundsContextProps = {};

const FundsContext = createContext(initialContextState);

interface IFundsStateProviderProps {
  children: React.ReactNode;
}

const FundsStateProvider = ({ children }: IFundsStateProviderProps) => {
  return (
    <FundsContext.Provider
      value={useManageFundsState({
        flagFundApprovedBuyer: new FlagFundApprovedBuyer(),
        retrieveListings: new RetrieveFilteredFunds(),
        retrieveSingleFund: new RetrieveSingleFund(),
        retrieveSingleFundSummary: new RetrieveSingleFundSummary(),
        createAlert: new CreateNewAlert(),
        listAlerts: new ListAlerts(),
        toggleAlertActivation: new ToggleAlertActivation(),
        fundWatchlistAction: new FundToWatchlistAction(),
        listWatchlistUserFunds: new ListWatchlistUserFunds(),
        matchNames: new MatchNames(),
      })}
    >
      {children}
    </FundsContext.Provider>
  );
};

export const useFundsContext = () => useContext(FundsContext);

export { FundsStateProvider, FundsContext };
