import { NOT_AVAILABLE_STR } from "common/constants/platform";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shadcn/ui/select";

export const SelectDateDropdown = ({
  options,
  value,
  onValueChange,
  loading,
  disabled,
}: {
  options: { label: string; value: string }[];
  value: string | null;
  onValueChange: (value: string) => void;
  loading: boolean;
  disabled?: boolean;
}) => {
  const hasOptions = options.length > 0;
  const hasValue = !!value;
  const isDisabled = disabled || loading || !hasOptions || !hasValue;

  return (
    <div className="flex gap-4 h-8 items-center">
      <label className="text-xs font-medium select-none cursor-default">
        Report Date:
      </label>
      <Select onValueChange={onValueChange} value={value} disabled={isDisabled}>
        <SelectTrigger className="w-[150px] h-8" disabled={isDisabled}>
          <SelectValue
            placeholder={
              isDisabled || !hasOptions || !hasValue
                ? NOT_AVAILABLE_STR
                : "Select a date"
            }
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
            {loading && <SelectItem value="loading">Loading...</SelectItem>}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
