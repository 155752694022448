import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { BookmarksSimple, Info } from "@phosphor-icons/react";

import { cn } from "common/utils";
import { TapCheckbox } from "common/components/Checkbox";
import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES } from "common/components/Button";
import { Fund } from "experiences/funds/domain/models/Fund";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import { TableBullet } from "common/constants/assets"; // exporting icons from here for now makes more sense
import { fCurrency } from "common/utils/formatNumber";
import { Tooltip, TooltipContent, TooltipTrigger } from "shadcn/ui/tooltip";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

export const COLUMN_ACTIONS_KEY = "actions";

export const TableBulletIcon = ({ visible }: { visible?: boolean }) => {
  return (
    <img
      src={TableBullet}
      alt=""
      className={classNames("table-bullet-icon", { hidden: !visible })}
    />
  );
};

// general table code
export const StyledTableCell = styled.td`
  position: relative;
  display: table-cell;
  font-family: "Inter", sans-serif;
  line-height: 1;
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  overflow: auto;

  &.wrap {
    white-space: normal;
  }

  // has image icon
  &:has(.table_fund_logo) {
    // padding-top: 6px;
    // padding-bottom: 6px;
  }

  *:has(.table-bullet-icon),
  &.has-bullet {
    padding-left: 48px !important;
  }

  &:has(.table-checkbox),
  &.has-checkbox {
    padding-left: 48px !important;
  }

  // has table and checkbox
  &:has(.table-checkbox):has(.table-bullet-icon),
  &.has-checkbox.has-bullet {
    // pad content to the right of the checkbox and bullet
    padding-left: 72px !important;
    // move bullet to the right of the checkbox
    .table-bullet-icon {
      left: 48px;
    }
  }

  // has pin
  :has(.table-pin-icon),
  .has-pin {
    padding-right: 48px !important;
  }

  &.align-top {
    vertical-align: top;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.is-primary-column {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  // mui checkbox has an invisible 8px per side wrapper
  .table-checkbox {
    position: absolute;
    left: 24px;
    display: inline-block;
    top: calc(50%);
    transform: translateY(-50%);
  }

  .table-bullet-icon {
    position: absolute;
    left: 24px;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);

    user-select: none;

    &.hidden {
      opacity: 0;
    }
  }

  /* correct bullet height if we are alignning the row vertically to the top */
  &.align-top .table-bullet-icon {
    top: 20px;
  }

  .table-pin-icon {
    position: absolute;
    right: 12px;
    display: inline-block;
    padding: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    border-radius: 50%;
    cursor: pointer;

    user-select: none;

    &.pinned {
      display: inline-block;
      opacity: 1;
    }

    &.loading {
      animation: blink 220ms linear infinite;
    }
  }

  &:hover:not(.pinned) {
    .table-pin-icon {
      display: inline-block;
      opacity: 0.5;
      background-color: #e4e4e4;
    }
  }

  &:hover.pinned {
    .table-pin-icon {
      background-color: #e6e6e6;
    }
  }

  &.paddingless {
    padding: 0 !important;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

export const TableTopCard = ({ children }: { children: React.ReactNode }) => {
  return <TableCell>{children}</TableCell>;
};

// TODO @jmz7v: Move this one to a common folder right next to the <Table /> component
export const TableCell = ({
  children,
  hasBullet,
  hasCheckbox,
  className,
  hasActions,
  pinned,
  isPinLoading,
  onPinClick,
  isPrimaryColumn,
  wrap,
  style,
  paddingless,
  alignTop,
  onClick,
  cursorPointer,
  colSpan,
  isUnverified,
  isFixed,
  title,
}: {
  children: React.ReactNode;
  hasBullet?: boolean;
  hasCheckbox?: boolean;
  className?: string;
  hasActions?: boolean;
  pinned?: boolean;
  isPinLoading?: boolean;
  onPinClick?: (e?: React.MouseEvent) => void;
  isPrimaryColumn?: boolean;
  wrap?: boolean;
  style?: React.CSSProperties;
  paddingless?: boolean;
  alignTop?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  cursorPointer?: boolean;
  colSpan?: number;
  isUnverified?: boolean;
  isFixed?: boolean;
  title?: string;
}) => {
  return (
    <StyledTableCell
      className={classNames("table-cell w-fit no-scrollbar", className, {
        [COLUMN_ACTIONS_KEY]: hasActions,
        pinned: pinned,
        "is-primary-column": isPrimaryColumn,
        "has-bullet": hasBullet,
        "has-checkbox": hasCheckbox,
        "has-pin": onPinClick,
        wrap: wrap,
        paddingless: paddingless,
        "align-top": alignTop,
        "cursor-pointer": cursorPointer,
        "!text-nomad-400 !font-semibold": isUnverified,
        "!text-nomad-700": isUnverified && isPrimaryColumn,
        "animate-pulse": isUnverified && !isPrimaryColumn,
        "!sticky left-0 !bg-neutral-50 z-10 border-zinc-400 overflow-visible":
          isFixed,
      })}
      style={style}
      onClick={onClick}
      colSpan={colSpan}
      title={title}
    >
      {hasBullet && <TableBulletIcon visible={true} />}
      {onPinClick && (
        <BookmarksSimple
          color="#21272A"
          width={32}
          height={32}
          className={classNames("table-pin-icon", {
            pinned,
            loading: isPinLoading,
          })}
          onClick={onPinClick}
        />
      )}
      {children}
    </StyledTableCell>
  );
};

export const StyledTableRow = styled.tr<{
  showActionsOnHover?: boolean;
  skipBottomBorder?: boolean;
  blurred?: boolean;
}>`
  &:last-child {
    border-bottom: 1px solid #dfdfd9;
    ${({ skipBottomBorder }) =>
      skipBottomBorder && "border-bottom: none !important;"}
    margin-bottom: 20px;
  }

  th,
  td {
    padding: 12px 16px;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    cursor: default;
    color: #21272d;
    white-space: nowrap;

    border-left: 1px solid #dfdfd9;

    ${({ blurred }) =>
      blurred && "border-left: none !important; user-select: none;"}

    &:last-child {
      padding-right: 24px;
    }

    &:first-child {
      border-left: none;
    }

    &.align-left {
      text-align: left;
    }
    &.align-right {
      text-align: right;
    }
    &.align-center {
      text-align: center;
    }
    &.wrap {
      white-space: normal;
    }

    &.${COLUMN_ACTIONS_KEY} {
      border-left: none !important;
      padding-top: 0;
      padding-bottom: 0;

      &:empty {
        /* display: none; */
      }
    }
  }

  th {
    color: #737476;
    font-weight: 600;
  }

  & + & th,
  & + & td {
    border-top: 1px solid #dfdfd9;

    ${({ blurred }) => blurred && "border-top: none !important;"}
  }

  &:hover {
    background-color: #fafaf8;
  }

  &.show-actions-on-hover {
    td.${COLUMN_ACTIONS_KEY} {
      opacity: 0;
    }
    &:hover {
      td.${COLUMN_ACTIONS_KEY} {
        opacity: 1;
      }
    }
  }
`;

export const StyledTableHead = styled.thead<{
  skipTopBorder?: boolean;
}>`
  tr {
    border-top: 1px solid #dfdfd9;
    ${({ skipTopBorder }) => skipTopBorder && "border-top: none!important;"}
    border-bottom: 1px solid #dfdfd9;
    background-color: #f8f8f5;

    th {
      input[type="checkbox"] {
        margin-right: 12px;
        margin-left: 8px;
      }
    }
    /* Prevents table row from losing background-color because of the :empty selector that displays none if actions column is empty */
    th.actions {
      display: table-cell !important;
    }

    &:last-child {
      ${({ skipTopBorder }) =>
        skipTopBorder && "border-bottom: none!important;"}
    }
  }
`;

export const StyledTableBody = styled.tbody``;

export const StyledTable = styled.table<{
  layoutFixed?: boolean;
  blurred?: boolean;
  borderSeparate?: boolean;
  borderRight?: boolean;
}>`
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  border-collapse: collapse;

  ${({ layoutFixed }) => layoutFixed && "table-layout: fixed;"}
  ${({ borderSeparate }) => borderSeparate && "border-collapse: separate;"}
  ${({ blurred }) => blurred && "pointer-events: none;"}
  ${({ borderRight }) => borderRight && "border-right: 1px solid #dfdfd9;"}
`;

export enum TableAlignment {
  LEFT = "align-left",
  RIGHT = "align-right",
  CENTER = "align-center",
}

// temp: specific table code
export const fundsTableColumns = [
  {
    label: "Fund",
    key: "name",
    align: TableAlignment.LEFT,
  },
  {
    label: "Manager",
    key: "manager",
    align: TableAlignment.LEFT,
  },
  {
    label: "Strategy",
    key: "strategy",
    align: TableAlignment.LEFT,
  },
  {
    label: "Vintage",
    key: "vintage",
    align: TableAlignment.LEFT,
  },
  {
    label: "Geography",
    key: "geography",
    align: TableAlignment.LEFT,
  },
  {
    label: "Fund size",
    key: "size",
    align: TableAlignment.LEFT,
  },
  {
    label: "",
    key: COLUMN_ACTIONS_KEY,
    align: TableAlignment.LEFT,
  },
];

export const FundStrategy = ({ fund }: { fund: Fund }) => {
  return (
    <>
      {(fund.strategy && FundStrategyNameMap.get(fund.strategy)) ||
        NOT_AVAILABLE_STR}
    </>
  );
};

export const StyledTableHeadCard = styled(TableCell)<{
  skipTopBorder?: boolean;
}>`
  background-color: #ffffff;
  ${({ skipTopBorder }) => skipTopBorder && "border-top: none !important;"}
  vertical-align: top;

  .head_title {
    display: block;

    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.01em;
    color: #737476;
    cursor: default;
  }

  .head_value {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 54px;
    font-family: "StaffWide", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #21272d;
    cursor: default;
    justify-content: space-between;
  }

  &.${COLUMN_ACTIONS_KEY} {
    border-left: none !important;
  }

  &.has-background-chart {
    position: relative;
    padding-bottom: 40px;
  }
`;

const StyledTableHeadBackgroundChart = styled.div`
  position: absolute;

  width: 100%;
  height: 140px;
  left: 0;
  bottom: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const TableHeadCard = ({
  title,
  value,
  style,
  hasActions,
  skipTopBorder,
  backgroundChartSvg,
}: {
  title?: string;
  value?: React.ReactNode;
  style?: React.CSSProperties;
  hasActions?: boolean;
  skipTopBorder?: boolean;
  backgroundChartSvg?: React.ReactNode;
}) => {
  return (
    <StyledTableHeadCard
      style={style}
      className={classNames({
        [COLUMN_ACTIONS_KEY]: hasActions,
        "has-background-chart": !!backgroundChartSvg,
      })}
      skipTopBorder={skipTopBorder}
    >
      {title && <span className="head_title">{title}</span>}
      {value && <span className="head_value">{value}</span>}
      {backgroundChartSvg && (
        <StyledTableHeadBackgroundChart>
          {backgroundChartSvg}
        </StyledTableHeadBackgroundChart>
      )}
    </StyledTableHeadCard>
  );
};

export interface ITabTableColumn {
  label: string;
  key: string;
  align?: TableAlignment;
  tooltip?: string | React.ReactNode;
  className?: string;
}

/*
 * the table hides all actions by default, shows them on hover
 */
export const TapTable = ({
  data,
  columns,
  emptyStateTitle,
  emptyStateDescription,
  emptyStateActions,
  renderRow,
  renderBody,
  toggleAllChecked,
  onToggleAllClick,
  showActionsOnHover,
  tableHeadCards,
  layoutFixed,
  skipTopBorder,
  skipBottomBorder,
  groupBy,
  renderGroupHeader,
  isLoading,
  showEmptyState,
  className,
  blurred,
  fixedHeaders,
}: {
  data: any[];
  columns: ITabTableColumn[];
  emptyStateTitle?: string;
  emptyStateDescription?: React.ReactNode;
  emptyStateActions?: {
    label: string;
    onClick: () => void;
    type: BUTTON_TYPES;
    icon?: React.ReactNode;
  }[];
  renderRow?: (
    row: any,
    {
      isFirstInGroup,
      fixedHeaders,
      columnIndex,
    }: {
      isFirstInGroup?: boolean;
      fixedHeaders?: boolean;
      columnIndex?: number;
    },
  ) => React.ReactNode;
  renderBody?: (row: any) => React.ReactNode;
  toggleAllChecked?: boolean;
  onToggleAllClick?: () => void;
  showActionsOnHover?: boolean;
  tableHeadCards?: React.ReactNode;
  layoutFixed?: boolean;
  skipTopBorder?: boolean;
  skipBottomBorder?: boolean;
  groupBy?: (row: any) => React.ReactNode;
  renderGroupHeader?: (row: any) => React.ReactNode;
  isLoading?: boolean;
  showEmptyState?: boolean;
  className?: string;
  blurred?: boolean;
  fixedHeaders?: boolean;
}) => {
  return (
    <>
      <StyledTable
        layoutFixed={layoutFixed}
        className={cn(className, {
          "border-separate": fixedHeaders,
        })}
        blurred={blurred}
      >
        <StyledTableHead skipTopBorder={skipTopBorder}>
          {tableHeadCards && <StyledTableRow>{tableHeadCards}</StyledTableRow>}
          <StyledTableRow className={cn({})}>
            {columns.map((col, i) => {
              const isActionsColumn = col.key === COLUMN_ACTIONS_KEY;
              const hasTooltip = col.tooltip;

              return (
                <th
                  key={col.key}
                  className={classNames(
                    "vertical-middle !overflow-auto",
                    col.align,
                    {
                      [COLUMN_ACTIONS_KEY]: isActionsColumn,
                      "sticky top-0 z-10 overflow-visible bg-neutral-50":
                        fixedHeaders,
                      "left-0 z-20 border-r border-zinc-400":
                        i === 0 && fixedHeaders,
                    },
                    col.className,
                  )}
                  title={col.label}
                >
                  {i === 0 && onToggleAllClick && (
                    <TapCheckbox
                      checked={!!toggleAllChecked}
                      onChange={onToggleAllClick}
                    />
                  )}
                  {col.label}
                  {hasTooltip && (
                    <Tooltip delayDuration={100}>
                      <TooltipTrigger asChild>
                        <Info
                          weight="fill"
                          className="inline ml-2 vertical-middle"
                        />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-96 font-normal whitespace-normal">
                        {col.tooltip}
                      </TooltipContent>
                    </Tooltip>
                  )}
                </th>
              );
            })}
          </StyledTableRow>
        </StyledTableHead>
        {renderBody ? (
          renderBody(data)
        ) : (
          <StyledTableBody
            className={cn({
              "blur-md": blurred,
            })}
          >
            {data.map((row, i) => {
              const groupByResult = groupBy && groupBy(row);
              const nextGroupByResult =
                groupBy && data[i + 1] && groupBy(data[i + 1]);
              const lastGroupByResult =
                groupBy && data[i - 1] && groupBy(data[i - 1]);

              const isFirstInGroup = groupByResult !== lastGroupByResult;

              return (
                <>
                  {groupBy && isFirstInGroup && (
                    <StyledTableRow
                      key={`${i}-group`}
                      style={{ pointerEvents: "none" }}
                      blurred={blurred}
                    >
                      <TableCell isPrimaryColumn colSpan={columns.length}>
                        {renderGroupHeader && renderGroupHeader(row)}
                      </TableCell>
                    </StyledTableRow>
                  )}
                  <StyledTableRow
                    key={i}
                    className={classNames({
                      "show-actions-on-hover": showActionsOnHover,
                      "relative z-0": fixedHeaders,
                    })}
                    blurred={blurred}
                    skipBottomBorder={skipBottomBorder}
                  >
                    {renderRow(row, {
                      isFirstInGroup,
                      fixedHeaders,
                      columnIndex: i,
                    })}
                  </StyledTableRow>
                </>
              );
            })}
          </StyledTableBody>
        )}
      </StyledTable>
      {data.length === 0 && !isLoading && showEmptyState ? (
        <EmptyState
          title={emptyStateTitle}
          description={emptyStateDescription}
          actions={emptyStateActions}
          className="min-h-96"
        />
      ) : null}
    </>
  );
};

export const tableCellDataFormatter = ({
  value,
  format,
}: {
  value: any;
  format?:
    | "currency"
    | "multiplier"
    | "percentage"
    | "percentage1dec"
    | "percentage2dec"
    | "date"
    | "round2dec";
}) => {
  if (typeof format === "undefined" && value !== null && value !== undefined) {
    return value;
  }

  if (value === null || value === undefined) {
    return NOT_AVAILABLE_STR;
  }

  if (format === "date") {
    return value;
  }

  if (
    value.toString() === "0" ||
    (value.toString() === "0.0" && value !== NOT_AVAILABLE_STR)
  ) {
    // Skip all formatting for 0 values
    return 0;
  }

  if (format === "currency") {
    if (value === NOT_AVAILABLE_STR) return NOT_AVAILABLE_STR;
    value = fCurrency(value, 0);
  }
  if (format === "multiplier") {
    if (value === NOT_AVAILABLE_STR) return NOT_AVAILABLE_STR;
    value = `${Number.parseFloat(value.toString()).toFixed(1)}x`;
  }
  if (format === "percentage") {
    if (value === NOT_AVAILABLE_STR) return NOT_AVAILABLE_STR;
    value = `${(Number.parseFloat(value.toString()) * 100).toFixed(0)}%`;
  }
  if (format === "percentage1dec") {
    if (value === NOT_AVAILABLE_STR) return NOT_AVAILABLE_STR;
    value = `${(Number.parseFloat(value.toString()) * 100).toFixed(1)}%`;
  }
  if (format === "percentage2dec") {
    if (value === NOT_AVAILABLE_STR) return NOT_AVAILABLE_STR;
    value = `${(Number.parseFloat(value.toString()) * 100).toFixed(2)}%`;
  }
  if (format === "round2dec") {
    if (value === NOT_AVAILABLE_STR) return NOT_AVAILABLE_STR;
    value = Number.parseFloat(value.toString()).toFixed(2);
  }

  return value;
};
