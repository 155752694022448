import json2mq from "json2mq";
import { DisclaimerFooter } from "./DisclaimerFooter";
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import { json } from "d3";

interface IFormContentSkeletonProps {
  formTitle: string;
  withDisclaimer?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const FormContentSkeleton: React.FC<IFormContentSkeletonProps> = ({
  formTitle,
  withDisclaimer = false,
  children,
  className,
}) => {
  const isLargeHeight = useMediaQuery(json2mq({ minHeight: 768 }));

  return (
    <div
      className={classNames(
        "no-scrollbar w-full h-full max-h-full overflow-y-scroll flex items-center justify-center box-border no-scrollbar ",
        className,
      )}
    >
      <div
        className={classNames(
          "flex flex-col h-full pb-32 box-border max-h-full w-full max-w-lg overflow-y-scroll no-scrollbar px-4 py-8",
          {
            "justify-center": isLargeHeight,
          },
        )}
      >
        <h3 className="font-title font-semibold text-lg mb-8">{formTitle}</h3>
        <div className="flex flex-col gap-2 mb-8">{children}</div>
        {withDisclaimer && <DisclaimerFooter />}
      </div>
    </div>
  );
};
