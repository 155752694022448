import { Toaster } from "react-hot-toast";

export const toastStyles = {
  border: "1px solid #e2e2e2",
};

export const CustomToaster = () => {
  return (
    <Toaster toastOptions={{ style: toastStyles }} position="bottom-right" />
  );
};
