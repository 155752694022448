import { GP_ROUTES, GpComplianceRoutes } from "common/constants/routes";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";
import { QualifiedMatchingServicePage } from "./QualifiedMatchingService";
import { FairnessOpinionsPage } from "./FairnessOpinions";
import { ComplianceStateProvider } from "../state/ComplianceContext";
import { FirmOverviewPage } from "./FirmOverview";
// import DataRoom from "../components/data-room/DataRoom";
// import { GpTransactionDataRoomDataPage } from "../gp/dataroom/DataPage";
// import { IDataRoom } from "experiences/transactions/domain/models/DataRoom";

export const ComplianceWrapper = () => {
  const baseRoute = GP_ROUTES.Compliance;
  const { transactionId = "" } = useParams();

  return (
    <ComplianceStateProvider>
      <Routes>
        <Route
          path={GpComplianceRoutes.QualifiedMatchingService}
          element={<QualifiedMatchingServicePage />}
        />
        <Route
          path={GpComplianceRoutes.FairnessOpinions}
          element={<FairnessOpinionsPage />}
        />
        <Route
          path={GpComplianceRoutes.FirmOverviewDetail}
          element={<FirmOverviewPage />}
        />
        <Route
          path="/"
          element={
            <Navigate
              to={`${generatePath(
                `${baseRoute}${GpComplianceRoutes.QualifiedMatchingService}`,
                {
                  transactionId,
                },
              )}`}
            />
          }
        />
      </Routes>
    </ComplianceStateProvider>
  );
};
