import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PlusCircle, XCircle } from "@phosphor-icons/react";

import { Pill } from "experiences/common/Pill";

interface IFilterPillProps {
  title: string;
  rightText?: string;
  onClick?: () => void;
  children: React.ReactNode;
  menuWidth?: number | string;
  showClear?: boolean;
  clearFn?: () => void;
}

const StyledDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background: #ffffff;
  border: 1px solid #dfdfd9;
  box-shadow: 0px 9px 24px rgba(184, 184, 184, 0.32);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
`;

const StyledFilterPillWrapper = styled.div`
  position: relative;
`;

const FilterPill: React.FC<IFilterPillProps> = ({
  title,
  rightText,
  onClick,
  children,
  menuWidth,
  showClear = false,
  clearFn,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef(null);
  const onClickOutside = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const Icon = showClear ? XCircle : PlusCircle;

  return (
    <StyledFilterPillWrapper onClick={() => setOpen(true)}>
      <Pill onClick={onClick}>
        <Icon
          style={{
            color: "#fff",
            fontSize: "16px",
            borderRadius: "50%",
            marginRight: "6px",
            backgroundColor: "#bcb3a5",
            ...(showClear && { backgroundColor: "#4d4d4d" }),
          }}
          onClick={() => {
            if (showClear && clearFn) {
              clearFn();
            }
          }}
        />

        {title}

        {rightText && (
          <Box
            sx={{ width: "1px", backgroundColor: "#dfdfd9" }}
            alignItems={"center"}
          />
        )}
        {rightText && (
          <span className="pill-value">{<strong>{rightText}</strong>}</span>
        )}
      </Pill>
      {open && (
        <StyledDropdown
          ref={ref}
          style={{
            width: menuWidth ?? 400,
          }}
        >
          <Box px={2} py={1}>
            {children}
          </Box>
        </StyledDropdown>
      )}
    </StyledFilterPillWrapper>
  );
};

export default FilterPill;
