import { useState } from "react";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import FormikTextField from "../../../../common/components/formik/FormikTextField";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";
import { IOSSwitch } from "common/components/IOSSwitch";
import { RedliningFormSubmitted } from "../state/DocumentsEvent";
import { useDocumentsContext } from "../state/DocumentsContext";

export const RequestRedliningModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { emitEvent } = useDocumentsContext();
  const [isRedliner, setIsRedliner] = useState<boolean>(true);
  const [isSigner, setIsSigner] = useState<boolean>(true);

  const requestRedliningValidationSchema = yup.object({
    requester: yup.boolean(),
    redlinerEmail: yup.string().when("requester", {
      is: false,
      then: yup.string().email("Invalid email").required("Required"),
      otherwise: yup.string().notRequired(),
    }),
    signer: yup.boolean(),
    signerEmail: yup.string().when("signer", {
      is: false,
      then: yup.string().email("Invalid email").required("Required"),
      otherwise: yup.string().notRequired(),
    }),
  });

  const requestRedliningForm = useFormik({
    initialValues: {
      redlinerEmail: undefined,
      signerEmail: undefined,
    },
    onSubmit: (values) => {
      emitEvent!(
        new RedliningFormSubmitted({
          currentUserIsRedliner: isRedliner,
          emailOfDifferentReadliner: values.redlinerEmail,
          currentUserIsSigner: isSigner,
          emailOfDifferentSigner: values.signerEmail,
        }),
      );
      handleClose();
    },
    validationSchema: requestRedliningValidationSchema,
  });

  const handleClose = () => {
    onClose();
    requestRedliningForm.resetForm();
  };

  return (
    <Modal
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={requestRedliningForm.submitForm}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <Box>
              <TapUiModalHead
                title="Request Edits"
                subtitle="You are requesting to redline Tap’s Platform NDA. Provide some additional details to continue."
                onCloseClick={handleClose}
              />
            </Box>

            <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <IOSSwitch
                checked={isRedliner}
                onChange={(_, checked) => {
                  setIsRedliner(checked);
                }}
              />
              <Typography>
                I am the person at my firm redlining the document.
              </Typography>
            </Box>
            {!isRedliner ? (
              <FormikTextField
                name="redlinerEmail"
                formik={requestRedliningForm}
                label="Redliner Email"
                fullWidth
                placeholder="first.last@company.com"
              />
            ) : null}

            <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <IOSSwitch
                checked={isSigner}
                onChange={(_, checked) => {
                  setIsSigner(checked);
                }}
              />
              <Typography>
                I am the person at my firm signing the document.
              </Typography>
            </Box>
            {!isSigner ? (
              <FormikTextField
                name="signerEmail"
                formik={requestRedliningForm}
                label="Signatory Email"
                fullWidth
                placeholder="first.last@company.com"
              />
            ) : null}

            <Button
              onClick={requestRedliningForm.submitForm}
              fullWidth
              type={BUTTON_TYPES.SECONDARY}
              size="large"
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
