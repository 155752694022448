import { useEffect, useState } from "react";

import {
  CaretDown,
  CaretUp,
  CurrencyDollar,
  Package,
} from "@phosphor-icons/react";

import Apollo from "../assets/apollo.jpg";
import GeneralCatalyst from "../assets/general-catalyst.png";
import Blackstone from "../assets/blackstone.png";
import { DashboardEvent } from "../components/DashboardCenterColumn";

const demoActivity = [
  {
    id: 1,
    logo: Apollo,
    fundName: "Apollo Investment Fund II",
    locked: true,
    tokens: [
      {
        icon: <CaretDown />,
        label: "New Ask",
      },
      {
        icon: <CurrencyDollar />,
        value: "XX.X%",
        label: "Price",
      },
      {
        icon: <Package />,
        value: "XX.X M",
        label: "Amount",
      },
    ],
  },
  {
    id: 1,
    logo: GeneralCatalyst,
    fundName: "General Catalyst Early Stage Fund",
    locked: true,
    tokens: [
      {
        icon: <CaretDown />,
        label: "New Ask",
      },
      {
        icon: <CurrencyDollar />,
        value: "XX.X%",
        label: "Price",
      },
      {
        icon: <Package />,
        value: "XX.X M",
        label: "Amount",
      },
    ],
  },
  {
    id: 1,
    logo: Blackstone,
    fundName: "Blackstone Strategic Partners Fund XI",
    locked: true,
    tokens: [
      {
        icon: <CaretDown />,
        label: "New Ask",
      },
      {
        icon: <CurrencyDollar />,
        value: "XX.X%",
        label: "Price",
      },
      {
        icon: <Package />,
        value: "XX.X M",
        label: "Amount",
      },
    ],
  },
  {
    id: 1,
    logo: Apollo,
    fundName: "Apollo Investment Fund II",
    locked: true,
    tokens: [
      {
        icon: <CaretUp />,
        label: "New Bid",
      },
      {
        icon: <CurrencyDollar />,
        value: "XX.X%",
        label: "Price",
      },
      {
        icon: <Package />,
        value: "XX.X M",
        label: "Amount",
      },
    ],
  },
];

export const useActivity = () => {
  const [activity, setActivity] = useState<DashboardEvent[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setActivity(demoActivity);
    setLoaded(true);
  }, []);

  return {
    activity,
    loaded,
  };
};
