import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import SelectDropDown from "experiences/common/SelectDropDown";
import {
  FundGeography,
  FundGeographyNameMap,
} from "experiences/common/models/FundGeography";
import { useFundsContext } from "experiences/funds/presentation/state/FundsContext";
import { GeographyFilterChanged } from "experiences/funds/presentation/state/FundsEvent";
import FilterPill from "./FilterPill";
import { arrayEquals } from "common/utils/arrayEquals";

export const GeographyFilter = () => {
  const { emitEvent, filtersSpecs } = useFundsContext();

  const isGeographySelected = (geography: FundGeography) =>
    filtersSpecs!.geographies?.includes(geography);

  const toggleGeography = (geography: FundGeography) => {
    emitEvent!(
      new GeographyFilterChanged({
        geographies: isGeographySelected(geography)
          ? filtersSpecs!.geographies?.filter((geo) => geo != geography)
          : [...filtersSpecs?.geographies!, geography],
      }),
    );
  };

  const showClearOption =
    !filtersSpecs?.geographies || !arrayEquals(filtersSpecs?.geographies, []);

  const clearGeographies = () => {
    emitEvent!(new GeographyFilterChanged({ geographies: [] }));
  };

  const getValue = () => {
    const startegiesSelectedCount = filtersSpecs!.geographies!.length;
    return startegiesSelectedCount > 0
      ? `${startegiesSelectedCount} selected`
      : undefined;
  };

  return (
    <FilterPill
      title="Geography"
      rightText={getValue()}
      clearFn={clearGeographies}
      showClear={showClearOption}
    >
      <Stack direction="row">
        {[
          [
            FundGeography.NorthAmerica,
            FundGeography.LatinAmerica,
            FundGeography.MiddleEast,
          ],
          [FundGeography.Europe, FundGeography.Africa],
          [FundGeography.Asia, FundGeography.Australia],
        ].map((geographyRow) => (
          <Stack>
            {geographyRow.map((geography) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isGeographySelected(geography)}
                    onChange={() => toggleGeography(geography)}
                  />
                }
                label={FundGeographyNameMap.get(geography)}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </FilterPill>
  );
};

export default GeographyFilter;
