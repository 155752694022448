import { useState } from "react";
import { useParams } from "react-router";
import { Divider, Drawer, Stack, Typography } from "@mui/material";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { DollarAmount } from "common/@types/app/DollarAmount";
import { Percentage } from "common/@types/app/Percentage";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { useTransactionsContext } from "../../state/TransactionsContext";
import {
  BuyerBidRowClicked,
  CreateBidFlowTriggered,
} from "../../state/TransactionsEvent";
import { BidsListLoaded } from "../../state/TransactionsState";
import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from "experiences/funds/presentation/components/Table";
import CreateBidFlow from "./CreateBidFlow";

import {
  PartialBid,
  Bid,
} from "experiences/transactions/domain/models/Bidding";

import TextChip from "experiences/common/TextChip";
import BidDetailsScreen from "./BidDetailsScreen";

interface IBuyerBidsViewScreenProps {
  txnSummary: ITransactionSummary;
}

interface IBuyerBidRowProps {
  bid: Bid;
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

const BuyerBidRow: React.FC<IBuyerBidRowProps> = ({ bid }) => {
  const bidName = `${moment(bid.createdAt).format("YYYY-MM-DD")} ${
    bid instanceof PartialBid ? "Partial" : "Whole"
  } Portfolio Bid`;

  const { emitEvent } = useTransactionsContext();

  const { txnId } = useParams();

  const statusChip = bid.isSettling() ? (
    <TextChip text={"SETTLING"} />
  ) : (
    <TextChip text={"SUBMITTED"} />
  );

  const [detailsScreenOpen, setDetailsScreenOpen] = useState<boolean>(false);

  const onDetailsClose = () => {
    setDetailsScreenOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <StyledTableRow
        onClick={() => {
          setDetailsScreenOpen(true);
          emitEvent!(new BuyerBidRowClicked({ txnId: txnId! }));
        }}
      >
        <StyledTableCell>
          <Stack direction="row">
            <Typography fontWeight="bold">{bidName}</Typography>
            <span>{statusChip}</span>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="left">
          {new Percentage(bid.percentValue! * 100).formatted()}
        </StyledTableCell>
        <StyledTableCell align="left">
          {new DollarAmount(bid.dollarValue!).formatted()}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Stack direction="row" justifyContent="space-between">
            <Typography>
              {bid.updatedAt &&
                moment(bid.updatedAt).format("YYYY-MM-DD hh:mm A")}
            </Typography>
            <ChevronRightIcon />
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
      <Drawer
        anchor={"bottom"}
        open={detailsScreenOpen}
        onClose={onDetailsClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <BidDetailsScreen onClose={onDetailsClose} bid={bid} />
      </Drawer>
    </>
  );
};

interface IBuyerBiddingSectionHeaderProps {
  onNewBid: () => void;
}

const BuyerBiddingSectionHeader: React.FC<IBuyerBiddingSectionHeaderProps> = ({
  onNewBid,
}) => {
  return (
    <Stack py={1} direction="row-reverse">
      <Button onClick={onNewBid} className="!pl-2" icon={<PlusIcon />}>
        Submit bid
      </Button>
    </Stack>
  );
};

export const BuyerBidViewScreen: React.FC<IBuyerBidsViewScreenProps> = ({
  txnSummary,
}) => {
  const [bidFlowOpen, setBidFlowOpen] = useState<boolean>(false);
  const { emitEvent, bidsListState } = useTransactionsContext();

  const openBidCreationFlow = () => {
    emitEvent!(
      new CreateBidFlowTriggered({ txnId: txnSummary.transaction.id }),
    );
    setBidFlowOpen(true);
  };

  return (
    <>
      <Stack direction="column" divider={<Divider />} width={"100%"}>
        <BuyerBiddingSectionHeader onNewBid={openBidCreationFlow} />
        {bidsListState instanceof BidsListLoaded &&
        bidsListState.bids.length <= 0 ? (
          <EmptyState
            style={{
              height: "calc(100vh - 200px)",
            }}
            title="No current bids"
            description="Once you have bids submitted on the transaction, you can view and manage them here."
            actions={[
              {
                label: "Submit Bid",
                onClick: openBidCreationFlow,
                type: BUTTON_TYPES.SECONDARY,
                icon: <PlusIcon />,
              },
            ]}
          />
        ) : (
          bidsListState instanceof BidsListLoaded &&
          bidsListState.bids.length > 0 && (
            <StyledTable>
              <StyledTableHead>
                <StyledTableRow>
                  <th style={{ textAlign: "left" }}>Bid</th>
                  <th style={{ textAlign: "left" }}>Pricing (%)</th>
                  <th style={{ textAlign: "left" }}>Pricing ($)</th>
                  <th style={{ textAlign: "left" }}>Last Updated</th>
                </StyledTableRow>
              </StyledTableHead>
              <StyledTableBody>
                {bidsListState.bids.map((bid) => (
                  <BuyerBidRow bid={bid} />
                ))}
              </StyledTableBody>
            </StyledTable>
          )
        )}
      </Stack>
      <CreateBidFlow open={bidFlowOpen} onClose={() => setBidFlowOpen(false)} />
    </>
  );
};
