import Quill, { Parchment } from "quill";
import "./quill.css";

export const setupInlineStyles = () => {
  // configure Quill to use inline styles so the email's format properly
  var DirectionAttribute = Quill.import("attributors/attribute/direction");
  Quill.register(DirectionAttribute, true);

  var AlignClass = Quill.import("attributors/class/align");
  Quill.register(AlignClass, true);

  var BackgroundClass = Quill.import("attributors/class/background");
  Quill.register(BackgroundClass, true);

  var ColorClass = Quill.import("attributors/class/color");
  Quill.register(ColorClass, true);

  var DirectionClass = Quill.import("attributors/class/direction");
  Quill.register(DirectionClass, true);

  var FontClass = Quill.import("attributors/class/font");
  Quill.register(FontClass, true);

  var SizeClass = Quill.import("attributors/class/size");
  Quill.register(SizeClass, true);

  var AlignStyle = Quill.import("attributors/style/align");
  Quill.register(AlignStyle, true);

  var BackgroundStyle = Quill.import("attributors/style/background");
  Quill.register(BackgroundStyle, true);

  var ColorStyle = Quill.import("attributors/style/color");
  Quill.register(ColorStyle, true);

  var DirectionStyle = Quill.import("attributors/style/direction");
  Quill.register(DirectionStyle, true);

  var FontStyle = Quill.import("attributors/style/font");
  Quill.register(FontStyle, true);

  var SizeStyle = Quill.import("attributors/style/size");
  Quill.register(SizeStyle, true);

  // Don't forget corresponding css
  const Size = Quill.import("attributors/style/size");
  Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
  Quill.register(Size, true);

  //Text indent
  // const Parchment = Quill.import("parchment");
  // class IndentAttributor extends Parchment.Attributor.Style {
  // Updated this ^^^^^ when migrating to Quill 2
  class IndentAttributor extends Parchment.StyleAttributor {
    add(node, value) {
      if (value === 0) {
        this.remove(node);
        return true;
      } else {
        return super.add(node, `${value}em`);
      }
    }
  }

  let IndentStyle = new IndentAttributor("indent", "text-indent", {
    scope: Parchment.Scope.BLOCK,
    whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"],
  });

  Quill.register(IndentStyle, true);

  var Blockquote = Quill.import("formats/blockquote");

  class BlockquoteInlineStyle extends Blockquote {
    static create(value) {
      BlockquoteInlineStyle.blotName = "blockquote";
      BlockquoteInlineStyle.tagName = "blockquote";
      let node = super.create();

      const attr = document.createAttribute("style");
      attr.value = "border-left: 4px solid #ccc; padding-left: 24px;";
      node.setAttributeNode(attr);
      return node;
    }
  }
  Quill.register(BlockquoteInlineStyle, true);
};
