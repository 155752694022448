import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import authService from "../../../../common/services/authService";

export class HandleLogout implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> =
    async ({}) => {
      authService.logout();
      return right(new NoReturn());
    };
}

interface IParams {}
