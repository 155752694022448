import styled from "styled-components";

import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { RequestedBuyer } from "experiences/approved-buyers/domain/models/RequestedBuyer";
import { StyledFundNameAndImage } from "./ApprovedBuyersTable";

export const RequestedBuyersTable = ({
  requestedBuyers: requestedBuyers,
}: {
  requestedBuyers: RequestedBuyer[];
  searchQuery?: string;
}) => {
  return (
    <>
      <TapTable
        data={requestedBuyers}
        emptyStateTitle={
          !requestedBuyers.length ? "There are no buyers to display." : null
        }
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "type",
            label: "Type",
            align: TableAlignment.LEFT,
          },
          {
            key: "checkSize",
            label: "Check Size",
            align: TableAlignment.LEFT,
          },
          {
            key: "stapleRation",
            label: "Staple",
            align: TableAlignment.LEFT,
          },
          {
            key: "matchScore",
            label: "Match Score",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(buyer: RequestedBuyer) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                <StyledFundNameAndImage>
                  <img
                    src={`https://cdn.usetap.com/funds/logos/${buyer.logoFileName}`}
                    alt="Fund logo"
                  />{" "}
                  {buyer.name}
                </StyledFundNameAndImage>
              </TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    flexDirection: "column",
                    maxWidth: 400,
                  }}
                >
                  {buyer.fundScope}
                </div>
              </TableCell>
              <TableCell>{buyer.type}</TableCell>
              <TableCell>{buyer.checkSize}</TableCell>
              <TableCell>
                <TextChip
                  text={buyer.staple}
                  pending={buyer.staple.toLocaleLowerCase() === "no"}
                  success={buyer.staple.toLocaleLowerCase() === "yes"}
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>{buyer.matchScore}</TableCell>
            </>
          );
        }}
      />
    </>
  );
};
