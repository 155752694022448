import { XCircle } from "@phosphor-icons/react";

import { VerifyPermissionOverlay } from "../VerifyPermissionOverlay";

export const InsufficientData = () => {
  return (
    <VerifyPermissionOverlay
      messageStyle={{ maxWidth: 280, whiteSpace: "pre-wrap" }}
      mainIcon={<XCircle size={32} color="#BCB3A5" />}
      headerText="Cannot Display Bid Rank"
      subHeaderText={
        "There is not enough data to show where your bid ranks. Either there are very few other bids or you did not include a price on your bid."
      }
    />
  );
};
