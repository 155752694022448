import { gql } from "@apollo/client";

export const GET_TRANSACTION_QUALITATIVE_DATA_UPDATES = gql`
  query QualitativeData(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]!
  ) {
    qualitativeData(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      investmentsUpdates {
        holding
        fund
        fundName
        managerId
        managerName
        company
        companyName
        reportDate
        type
        headline
        text
        impact
        lpImpliedTransactionValue
      }
    }
  }
`;

export const GET_TRANSACTION_QUALITATIVE_DATA_INSIGHTS = gql`
  query QualitativeData(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]!
  ) {
    qualitativeData(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      investmentsInsights {
        holding
        fund
        fundName
        managerId
        managerName
        company
        companyName
        reportDate
        gpOutlook
        gpOutlookDescription
        performanceToPlan
        performanceToPlanDescription
        liquidityTimeline
        liquidityTimelineDescription
        distressIndicator
        distressIndicatorDescription
        macroExposure
        macroExposureDescription
        lpImpliedTransactionValue
        lpImpliedInvestmentPercentage
      }
    }
  }
`;
