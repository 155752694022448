import { MicrosoftExcelLogo } from "@phosphor-icons/react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shadcn/ui/select";

import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { PageWrapperPortfolioV2 } from "experiences/common/PageWrapper";
import { EntitiesSelector } from "../components/EntitiesDropdown";
import {
  aggregateSoiGroupingLabels,
  AggregateSoiGroupKeys,
} from "../components/TableGrouping";
import { useLookthroughSoi } from "../components/useLookthroughSoi";
import { LookthroughSOITable } from "../components/LookthroughSoiTable";
import { CompanyLoanDataTable } from "../components/CompanyLoanDataTable";

const aggregateSoiGroupingOptions = Object.values(AggregateSoiGroupKeys).map(
  (key) => ({
    label: aggregateSoiGroupingLabels[key],
    value: key,
  }),
);

export const LookthroughSoiPage = () => {
  const {
    loading,
    entitySelector,
    data,
    reportDate,
    reportDatesOptions,
    onReportDateChange,
    onGroupByChange,
    groupBy,
    companyLoanData,
    handleDownloadClick,
    isDownloading,
  } = useLookthroughSoi();

  return (
    <PageWrapperPortfolioV2 className="min-h-fit">
      <PageHead
        title="Lookthrough SOI"
        actions={
          <EntitiesSelector
            entitiesOptions={entitySelector.entitiesOptions}
            selectedEntitiesIds={entitySelector.selectedEntitiesIds}
            onSelectEntity={entitySelector.onSelectEntity}
            onRemoveEntity={entitySelector.onRemoveEntity}
            onToggleAllEntities={entitySelector.onToggleAllEntities}
          />
        }
      />
      <PageSectionTitleDivider
        showBorderTop
        skipBorderBottom
        actions={
          <>
            <SelectDateDropdown
              value={reportDate}
              onValueChange={onReportDateChange}
              options={reportDatesOptions}
              loading={loading}
            />
            <Button
              onClick={handleDownloadClick}
              type={BUTTON_TYPES.SECONDARY}
              icon={<MicrosoftExcelLogo />}
              loading={isDownloading}
            >
              Download Data
            </Button>
          </>
        }
      >
        <div className="flex gap-4 h-8 items-center">
          <label className="text-xs font-medium select-none cursor-default">
            Grouping by
          </label>
          <Select
            onValueChange={onGroupByChange}
            disabled={loading}
            value={groupBy}
          >
            <SelectTrigger className="w-[150px] h-8" disabled={loading}>
              <SelectValue placeholder="Select Grouping" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {aggregateSoiGroupingOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </PageSectionTitleDivider>
      <LookthroughSOITable data={data} loading={loading} />
      <CompanyLoanDataTable data={companyLoanData} loading={loading} />
    </PageWrapperPortfolioV2>
  );
};
