import { PageHead } from "common/components/PageHead";
import { Helmet } from "react-helmet";
import { useComplianceContext } from "../state/ComplianceContext";
import { useEffect } from "react";
import { FairnessOpinionListRequested } from "../state/ComplianceEvent";
import { FairnessOpinionListLoaded } from "../state/ComplianceState";
import { EmptyState } from "common/components/EmptyState";
import { FairnessOpinionsTable } from "../components/FairnessOpinionsTable";

export const FairnessOpinionsPage = () => {
  const { emitEvent, fairnessOpinionListState } = useComplianceContext();

  useEffect(() => {
    emitEvent(new FairnessOpinionListRequested());
  }, []);

  const fairnessOpinions =
    fairnessOpinionListState instanceof FairnessOpinionListLoaded
      ? fairnessOpinionListState.fairnessOpinions
      : [];

  return (
    <>
      <Helmet title="Fairness Opinions" />
      <PageHead title="Fairness Opinions" />

      {fairnessOpinionListState instanceof FairnessOpinionListLoaded ? (
        <FairnessOpinionsTable fairnessOpinions={fairnessOpinions} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
