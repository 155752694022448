import styled from "styled-components";

import { BUTTON_TYPES, Button } from "common/components/Button";

const StyledTask = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 14px;

  .container {
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--branding-keyline-default, #dfdfd9);
    background: var(--branding-texture-2, #fafaf8);

    .taskDetails {
      display: flex;
      align-items: center;

      .icon {
        position: relative;
        width: 15px;
        height: 15px;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
      }

      .taskInfo {
        .title {
          color: var(--branding-dark, #21272d);
          font-family: "Inter", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.15px;
        }
        .description {
          color: var(--branding-light, #737476);
          font-family: "Inter", sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.13px;
        }
      }
    }
  }
`;

export const DashboardTask = ({
  icon,
  title,
  description,
  buttonCta,
  onCtaClick,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonCta: string;
  onCtaClick: () => void;
}) => {
  return (
    <StyledTask>
      <div className="container select-none">
        <div className="taskDetails">
          <div className="icon">{icon}</div>
          <div className="taskInfo">
            <h4 className="title">{title}</h4>
            <p className="description">{description}</p>
          </div>
        </div>
        <Button
          onClick={onCtaClick}
          type={BUTTON_TYPES.SECONDARY}
          className="ml-2"
        >
          {buttonCta}
        </Button>
      </div>
    </StyledTask>
  );
};
