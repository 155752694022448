import { Either, left, right } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { IIssuer } from "common/@types/models/Issuer";

export enum MatchStatus {
  PartialMatch = 1,
  InvalidInput = 2,
  NoMatch = 3,
  PerfectMatch = 4,
}

/**
 * @description This is the result of a matchNames request
 * @param {string} searchTerm - the term sent to the server
 * @param {IIssuer[]} matchedCandidates - for now it will always be 1 item
 * @param {MatchStatus} status - tells us a little bit about what happened
 * @param {number} occurrences - how many times one thing was replicated in the request that was sent because the backend dedupes
 * @returns {MatchResult}
 *
 */

export interface MatchResult {
  searchTerm: string;
  matchedCandidates: IIssuer[];
  status: MatchStatus;
  occurrences: number;
}

interface IParams {
  searchTerms: string[];
  useAi?: boolean;
}

export class MatchNames implements IAsyncUseCase<IParams, MatchResult[]> {
  call: (params: IParams) => Promise<Either<Failure, MatchResult[]>> = async (
    payload,
  ) => {
    const useAi = payload.useAi || false;
    const path = useAi ? "/funds/ai-guess/" : "/funds/match-names/";

    return await new TapClient(path, { searchTerms: payload.searchTerms })
      .post()
      .then((resp: MatchResult[]) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}
