import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import {
  Bid,
  IPartialBid,
  IWholePortfolioBid,
  bidFromResponse,
} from "../models/Bidding";
import axios from "axios";

interface IResp {
  bid: IWholePortfolioBid | IPartialBid;
  uploadUrl: {
    url: string;
    fields: any;
  };
}

export class UploadDocumentToBid implements IAsyncUseCase<IParams, Bid> {
  call: (params: IParams) => Promise<Either<Failure, Bid>> = async ({
    form,
    txnId,
  }) => {
    return await new TapClient(
      `/transactions/${txnId}/bidding/bid/document-upload-url/get/`,
      {
        documentName: form.file.name
          .replaceAll(" ", "_")
          .replaceAll("(", "")
          .replaceAll(")", ""),
        bidId: form.bidId,
      },
    )
      .post()
      .then((resp: IResp) => {
        const formData = new FormData();
        Object.keys(resp.uploadUrl.fields).map((key) => {
          formData.append(key, resp.uploadUrl.fields[key]);
        });

        formData.append("file", form.file);
        axios
          .post(resp.uploadUrl.url, formData, {
            headers: {
              "Content-Type": form.file.type,
            },
          })
          .catch(function (error) {
            return left(error);
          });

        return right(bidFromResponse(resp.bid));
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

export interface IGetBidUploadUrlForm {
  file: File;
  bidId: string;
}

interface IParams {
  form: IGetBidUploadUrlForm;
  txnId: string;
}
