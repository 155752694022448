export interface DealBreakdownRow {
  label: string;
  key: string;
  value: number | string;
  confidence?: DataConfidence;
  format?: "currency" | "percentage" | "date" | "multiplier" | "round2dec";
}

export enum DataConfidence {
  Confirmed = "Confirmed",
  Estimated = "Estimated",
}

// left column data is:
// NAV	$29.9 M
// Unfunded	$0.5 M
// Post-Reference Date Contributions	$0
// Post-Reference Date Distributions	$0
// Weighted Avg. Vintage	2019
export const leftColumnDataQuay: DealBreakdownRow[] = [
  {
    label: "NAV",
    key: "nav",
    value: 29900000,
    confidence: DataConfidence.Confirmed,
    format: "currency",
  },
  {
    label: "Unfunded",
    key: "unfunded",
    value: 500000,
    confidence: DataConfidence.Confirmed,
    format: "currency",
  },
  {
    label: "Post-Reference Date Contributions",
    key: "postReferenceDateContributions",
    value: 0,
    confidence: DataConfidence.Confirmed,
    format: "currency",
  },
  {
    label: "Post-Reference Date Distributions",
    key: "postReferenceDateDistributions",
    value: 0,
    confidence: DataConfidence.Confirmed,
    format: "currency",
  },
  {
    label: "Weighted Avg. Vintage",
    key: "weightedAvgVintage",
    value: 2019,
    confidence: DataConfidence.Confirmed,
  },
];

// center column data is:
// Investment Count	108
// Investment Concentration (Top 3)	42%
// Investment Concentration (Top 10)	55%
// Fund Count	5
// GP Count	2
export const centerColumnDataQuay: DealBreakdownRow[] = [
  {
    label: "Investment Count",
    key: "investmentCount",
    value: 108,
    confidence: DataConfidence.Confirmed,
  },
  {
    label: "Investment Concentration (Top 3)",
    key: "investmentConcentrationTop3",
    value: 0.42,
    confidence: DataConfidence.Confirmed,
    format: "percentage",
  },
  {
    label: "Investment Concentration (Top 10)",
    key: "investmentConcentrationTop10",
    value: 0.55,
    confidence: DataConfidence.Confirmed,
    format: "percentage",
  },
  {
    label: "Fund Count",
    key: "fundCount",
    value: 5,
    confidence: DataConfidence.Confirmed,
  },
  {
    label: "GP Count",
    key: "gpCount",
    value: 2,
    confidence: DataConfidence.Confirmed,
  },
];

// right column data is:
// Weighted Avg. MOIC	2.6x
// Weighted Avg. TVPI	2.0x
// Weighted Avg. DPI	0.0x
// LTM Write Up (%)	9%
// Weighted Avg. Estimated Price	N/A
export const rightColumnDataQuay: DealBreakdownRow[] = [
  {
    label: "Weighted Avg. MOIC",
    key: "weightedAvgMOIC",
    value: 2.6,
    confidence: DataConfidence.Confirmed,
    format: "multiplier",
  },
  {
    label: "Weighted Avg. TVPI",
    key: "weightedAvgTVPI",
    value: 2.0,
    confidence: DataConfidence.Confirmed,
    format: "multiplier",
  },
  {
    label: "Weighted Avg. DPI",
    key: "weightedAvgDPI",
    value: 0.0,
    confidence: DataConfidence.Confirmed,
    format: "multiplier",
  },
  {
    label: "LTM Write Up (%)",
    key: "ltmWriteUp",
    value: 0.09,
    confidence: DataConfidence.Confirmed,
    format: "percentage",
  },
  {
    label: "Weighted Avg. Estimated Price",
    key: "weightedAvgEstimatedPrice",
    value: "",
    confidence: DataConfidence.Estimated,
  },
];

export const leftColumnData: DealBreakdownRow[] = [
  {
    label: "NAV",
    key: "nav",
    value: 139000000,
    confidence: DataConfidence.Confirmed,
    format: "currency",
  },
  {
    label: "Unfunded",
    key: "unfunded",
    value: 16000000,
    confidence: DataConfidence.Estimated,
  },
  {
    label: "Fund Count",
    key: "fundCount",
    value: 5,
    confidence: DataConfidence.Confirmed,
  },
  {
    label: "GP Count",
    key: "gpCount",
    value: 4,
    confidence: DataConfidence.Estimated,
  },
  {
    label: "Vintages",
    key: "vintages",
    value: "2016-2020",
    confidence: DataConfidence.Confirmed,
  },
];

// investment count 37
// investment concentration (top 5) 41.5%
// investment concentration (top 10) 46.2%
// post-reference date net cash flow 1500000
// ltm write up 10.2%
export const centerColumnData: DealBreakdownRow[] = [
  {
    label: "Investment Count",
    key: "investmentCount",
    value: 37,
    confidence: DataConfidence.Confirmed,
  },
  {
    label: "Investment Concentration (Top 5)",
    key: "investmentConcentrationTop5",
    value: 41.5,
    confidence: DataConfidence.Confirmed,
    format: "percentage",
  },
  {
    label: "Investment Concentration (Top 10)",
    key: "investmentConcentrationTop10",
    value: 46.2,
    confidence: DataConfidence.Estimated,
    format: "percentage",
  },
  {
    label: "Post-Reference Date Net Cash Flow",
    key: "postReferenceDateNetCashFlow",
    value: 1500000,
    confidence: DataConfidence.Confirmed,
    format: "currency",
  },
  {
    label: "LTM Write Up",
    key: "ltmWriteUp",
    value: 10.2,
    confidence: DataConfidence.Estimated,
  },
];

// weighted avg tvpi 2.4x
// weighted avg dpi 1.0x
// weighted avg irr 13.6%
// fund damily track record 2.1x
// weighted avg estimated price 91%
export const rightColumnData: DealBreakdownRow[] = [
  {
    label: "Weighted Avg TVPI",
    key: "weightedAvgTVPI",
    value: 2.4,
    confidence: DataConfidence.Confirmed,
    format: "multiplier",
  },
  {
    label: "Weighted Avg DPI",
    key: "weightedAvgDPI",
    value: 1.0,
    confidence: DataConfidence.Confirmed,
    format: "multiplier",
  },
  {
    label: "Weighted Avg IRR",
    key: "weightedAvgIRR",
    value: 13.6,
    confidence: DataConfidence.Confirmed,
    format: "percentage",
  },
  {
    label: "Fund Family Track Record",
    key: "fundFamilyTrackRecord",
    value: 2.1,
    confidence: DataConfidence.Confirmed,
    format: "multiplier",
  },
  {
    label: "Weighted Avg Estimated Price",
    key: "weightedAvgEstimatedPrice",
    value: 91,
    confidence: DataConfidence.Estimated,
    format: "percentage",
  },
];

export interface DealBreakdownChart {
  name: string;
  percentage: string | number;
}

const strategy: DealBreakdownChart[] = [
  { name: "Distressed", percentage: 0.1 },
  { name: "Buyout", percentage: 0.2 },
  { name: "Growth", percentage: 0.8 },
  { name: "Venture", percentage: 0.4 },
  { name: "Infrastructure", percentage: 0.2 },
];

const geography: DealBreakdownChart[] = [
  { name: "North America", percentage: 0.2 },
  { name: "Europe", percentage: 0.2 },
  { name: "Asia", percentage: 0.3 },
  { name: "Other", percentage: 0.3 },
];

const companySector: DealBreakdownChart[] = [
  { name: "Consumer", percentage: 0.25 },
  { name: "Healthcare", percentage: 0.2 },
  { name: "Technology", percentage: 0.6 },
  { name: "Other", percentage: 0.3 },
];

const strategyQuay: DealBreakdownChart[] = [
  { name: "Venture Capital", percentage: 1 },
];

const geographyQuay: DealBreakdownChart[] = [
  { name: "United States", percentage: 0.98 },
  { name: "Canada", percentage: 0.02 },
];

// Information Technology	71%
// Financials	23%
// Transportation	2%
// Media	0%
const companySectorQuay: DealBreakdownChart[] = [
  { name: "Information Technology", percentage: 0.71 },
  { name: "Financials", percentage: 0.23 },
  { name: "Transportation", percentage: 0.02 },
  { name: "Media", percentage: 0 },
];

export const mockData = {
  left: leftColumnData,
  center: centerColumnData,
  right: rightColumnData,
  strategy,
  geography,
  companySector,
};

export const mockDataQuay = {
  left: leftColumnDataQuay,
  center: centerColumnDataQuay,
  right: rightColumnDataQuay,
  strategy: strategyQuay,
  geography: geographyQuay,
  companySector: companySectorQuay,
};
