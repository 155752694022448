import { DashboardList } from "experiences/common/List";

import {
  ActiveRecentIoisLoaded,
  ActiveRecentIoisState,
  DashboardState,
} from "../state/DashboardState";
import { LoadedWithNDASignature } from "experiences/documents/presentation/state/DashboardTaskSectionState";

import { SignNdaTask } from "./tasks/SignNdaTask";
import { ActiveIoisTask } from "./tasks/ActiveIoisTask";
import { tapFeatureFlags, FlagNames } from "experiences/common/Flags";

export const useDashboardTaskList = ({
  dashboardTaskSectionState,
  activeRecentIoisState,
}: {
  dashboardTaskSectionState: DashboardState;
  activeRecentIoisState: ActiveRecentIoisState;
}) => {
  const loaded = activeRecentIoisState instanceof ActiveRecentIoisLoaded;

  // Only show the active (last 30 days) ioi list if it's loaded and the ioi count is greater than 0
  const showActiveIois =
    (activeRecentIoisState instanceof ActiveRecentIoisLoaded &&
      activeRecentIoisState.iois?.length > 0) ||
    false;

  const showNdaTask =
    dashboardTaskSectionState instanceof LoadedWithNDASignature &&
    tapFeatureFlags[FlagNames.ShowDashboardSignNdaTask];

  let count = 0;

  if (showActiveIois) {
    count++;
  }

  if (showNdaTask) {
    count++;
  }

  return {
    showActiveIois,
    showNdaTask,
    count,
    loaded,
  };
};

export const DashboardTaskList = ({
  dashboardTaskSectionState,
  activeRecentIoisState,
}: {
  dashboardTaskSectionState: DashboardState;
  activeRecentIoisState: ActiveRecentIoisState;
}) => {
  const { showActiveIois, showNdaTask, count, loaded } = useDashboardTaskList({
    dashboardTaskSectionState,
    activeRecentIoisState,
  });

  if (!loaded || count === 0) {
    return null;
  }

  return (
    <DashboardList
      title="Tasks"
      table={
        <>
          {showNdaTask ? <SignNdaTask /> : null}
          {showActiveIois ? <ActiveIoisTask /> : null}
        </>
      }
      countLabel={String(count)}
    />
  );
};
