import { gql } from "@apollo/client";

export const GetPortfolioHoldingsQuery = gql`
  query Holdings($entitiesIds: [String!]!) {
    holdings(entitiesIds: $entitiesIds) {
      id
      issuerId
      issuerType
      issuerName
      entityId
      bookValue
      contributed
      committed
      distributed
      marketValue
      referenceDate
    }
  }
`;
