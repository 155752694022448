import { DatePickerWithRange } from "shadcn/ui/DatePickerWithRange";
import { Range } from "experiences/common/models/Range";

const FormikDatePickerWithRange = ({ formik, name, ...props }) => {
  return (
    <DatePickerWithRange
      initialRange={formik.values[name] as Range<Date>}
      {...props}
      value={formik.values[name]}
      onChange={(value) => {
        formik.setFieldValue(name, value);
      }}
      errorMessage={
        formik.touched[name] && Boolean(formik.errors[name])
          ? String(formik.errors[name])
          : undefined
      }
    />
  );
};

export default FormikDatePickerWithRange;
