import {
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";

const FormikDropdownSelect = ({ formik, name, options, ...props }) => (
  <FormControl
    fullWidth
    error={formik.touched[name] && Boolean(formik.errors[name])}
  >
    <TextField
      select
      {...props}
      name={name}
      variant="filled"
      InputProps={{ disableUnderline: true }}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    {formik.touched[name] && formik.errors[name] && (
      <FormHelperText>{formik.errors[name]}</FormHelperText>
    )}
  </FormControl>
);

export default FormikDropdownSelect;
