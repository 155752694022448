import { GP_ROUTES, GpTransactionMonitorRoutes } from "common/constants/routes";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";

import { PreliminaryInterestTable } from "./monitor/PreliminaryInterestTable";
import { NonBindingBidsTable } from "./monitor/NonBindingBidsTable";
import { BindingBidsTable } from "./monitor/BindingBidsTable";
import { ParticipationAmountTable } from "./monitor/ParticipationAmountTable";

export const GpTransactionMonitor = () => {
  const baseRoute = GP_ROUTES.TransactionDetailTransactionMonitor;
  const params = useParams();

  return (
    <Routes>
      <Route
        path={GpTransactionMonitorRoutes.PreliminaryInterest}
        element={<PreliminaryInterestTable />}
      />
      <Route
        path={GpTransactionMonitorRoutes.NonBindingBids}
        element={<NonBindingBidsTable />}
      />
      <Route
        path={GpTransactionMonitorRoutes.BindingBids}
        element={<BindingBidsTable />}
      />
      <Route
        path={GpTransactionMonitorRoutes.ParticipationAmount}
        element={<ParticipationAmountTable />}
      />
      <Route
        path="/"
        element={
          <Navigate
            to={`${generatePath(
              `${baseRoute}${GpTransactionMonitorRoutes.PreliminaryInterest}`,
              {
                transactionId: params.transactionId,
              },
            )}`}
          />
        }
      />
    </Routes>
  );
};
