import { classNames } from "primereact/utils";

export const SideComparison = ({
  items,
  skipBorderTop,
  skipBorderBottom,
  biggerHeight,
}: {
  items: {
    title: string;
    value: string | JSX.Element | JSX.Element[] | JSX.Element[][];
  }[];
  skipBorderTop?: boolean;
  skipBorderBottom?: boolean;
  biggerHeight?: boolean;
}) => {
  return (
    <div
      className={classNames("flex flex-row select-none cursor-default", {
        "border-t-nomad-100 border-t": !skipBorderTop,
        "border-b-nomad-100 border-b": !skipBorderBottom,
      })}
    >
      {items.map((item) => (
        <div
          className={classNames("flex flex-1 flex-col p-4", {
            "border-l-nomad-100 border-l": item !== items[0],
          })}
        >
          <p className={classNames("text-nomad-600 text-xs font-medium", {})}>
            {item.title}
          </p>
          <p
            className={classNames("text-nomad-900 text-2xl font-bold", {
              "mt-8": biggerHeight,
            })}
          >
            {item.value}
          </p>
        </div>
      ))}
    </div>
  );
};
