import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GetTransactionHoldingsQuery } from "experiences/transactions/domain/usecases/GetTransactionHoldingsQuery";
import { transactionsClient } from "common/clients/ApolloClient";

export interface TransactionHolding {
  id: string;
  issuerName: string;
  isManagerAdmin: boolean;
  holding: {
    id: string;
    issuerId: string;
    issuerType: string;
    entityId: string;
    bookValue: number;
    contributed: number;
    committed: number;
    distributed: number;
    marketValue: number;
  };
}

export const useTransactionHoldings = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const [holdings, setHoldings] = useState<TransactionHolding[]>([]);

  const { data, loading, refetch } = useQuery(GetTransactionHoldingsQuery, {
    variables: { transactionId },
    client: transactionsClient,
    skip: !transactionId,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setHoldings(data.holdings);
    }
  }, [data]);

  // Refresh if transactionId changes
  useEffect(() => {
    refetch();
  }, [transactionId]);

  return { holdings, loading, refetch };
};
