import { useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { generatePath } from "react-router";

import { LP_ROUTES } from "common/constants/routes";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

// Pages
import { portfolioClient } from "common/clients/ApolloClient";
import { IPortfolioEntity } from "experiences/portfolio-v2/domain/models/PortfolioEntities";
import { IPortfolioHolding } from "experiences/portfolio-v2/domain/models/PortfolioHoldings";
import {
  IPortfolioStatus,
  IPortfolioStatusDataSection,
} from "experiences/portfolio-v2/domain/models/PortfolioStatus";
import { IReportDates } from "experiences/portfolio-v2/domain/models/ReportDates";
import { GetPortfolioHoldingsQuery } from "experiences/portfolio-v2/domain/usecases/GetPorfolioHoldingsQuery";
import { GetPortfolioEntitiesQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioEntitiesQuery";
import { GetPortfolioReportDatesQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioReportDates";
import { GetPortfolioStatusQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioStatusQuery";

export const PortfolioContext = createContext<{
  loading: boolean;
  portfolioBaseRoute: string;
  holdings: IPortfolioHolding[];
  entities: IPortfolioEntity[];
  reportDates: IReportDates[];
  selectedEntitiesIds: string[];
  entitiesLoading: boolean;
  holdingsLoading: boolean;
  onSelectEntity: (entityId: string) => void;
  onRemoveEntity: (entityId: string) => void;
  refetchHoldings: () => void;
  portfolioStatus: IPortfolioStatusDataSection;
  onToggleAllEntities: (checked: boolean) => void;
  uploadEntity: IPortfolioEntity | null;
  setUploadEntity: (entity: IPortfolioEntity | null) => void;
}>({
  loading: false,
  portfolioBaseRoute: "",
  holdings: [],
  entities: [],
  reportDates: [],
  selectedEntitiesIds: [],
  entitiesLoading: false,
  holdingsLoading: false,
  onSelectEntity: () => {},
  onRemoveEntity: () => {},
  refetchHoldings: () => {},
  portfolioStatus: {
    cas: null,
    fundExposureSummary: null,
    soiLookThrough: null,
  },
  onToggleAllEntities: () => {},
  uploadEntity: null,
  setUploadEntity: () => {},
});

export const PortfolioProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuthContext();
  const [holdings, setHoldings] = useState<IPortfolioHolding[]>([]);
  const [entities, setEntities] = useState<IPortfolioEntity[]>([]);
  const [selectedEntitiesIds, setSelectedEntitiesIds] = useState<string[]>([]);
  const [uploadEntity, setUploadEntity] = useState<IPortfolioEntity | null>(
    null,
  );
  const [reportDates, setReportDates] = useState<IReportDates[]>([]);
  const [portfolioStatus, setPortfolioStatus] =
    useState<IPortfolioStatusDataSection>({
      cas: null,
      fundExposureSummary: null,
      soiLookThrough: null,
    });

  const {
    data: entitiesData,
    refetch: refetchEntities,
    loading: entitiesLoading,
  } = useQuery<{
    entities: IPortfolioEntity[];
  }>(GetPortfolioEntitiesQuery, {
    client: portfolioClient,
  });

  // TODO: can we remove holdings from context if selector is grabbing entities instead of holdings?
  const {
    data: holdingsData,
    refetch: refetchHoldings,
    loading: holdingsLoading,
  } = useQuery<{
    holdings: IPortfolioHolding[];
  }>(GetPortfolioHoldingsQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
    },
    skip: selectedEntitiesIds.length === 0,
  });

  const {
    data: reportDatesData,
    refetch: refetchReportDates,
    loading: reportDatesLoading,
  } = useQuery<{
    reportsDates: { name: string; dates: string[] }[];
  }>(GetPortfolioReportDatesQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
    },
    skip: selectedEntitiesIds.length === 0,
  });

  const {
    data: portfolioStatusData,
    refetch: refetchPortfolioStatus,
    loading: portfolioStatusLoading,
  } = useQuery<IPortfolioStatus>(GetPortfolioStatusQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
    },
    skip: selectedEntitiesIds.length === 0,
  });

  const loading =
    entitiesLoading ||
    holdingsLoading ||
    reportDatesLoading ||
    portfolioStatusLoading;

  useEffect(() => {
    setHoldings(holdingsData?.holdings || []);
  }, [holdingsData?.holdings]);

  useEffect(() => {
    setReportDates(reportDatesData?.reportsDates || []);
  }, [reportDatesData?.reportsDates]);

  useEffect(() => {
    const newEntities = entitiesData?.entities || [];
    setEntities(newEntities);
    // By default select all entities
    const allEntitiesIds = newEntities.map((entity) => entity.id);
    setSelectedEntitiesIds(allEntitiesIds);
  }, [entitiesData?.entities]);

  useEffect(() => {
    setPortfolioStatus(
      portfolioStatusData?.dataSectionsStatus || {
        cas: null,
        fundExposureSummary: null,
        soiLookThrough: null,
      },
    );
  }, [portfolioStatusData?.dataSectionsStatus]);

  const handleRefetchHoldings = async () => {
    await refetchHoldings({
      entitiesIds: selectedEntitiesIds,
    }).then((res) => {
      setHoldings(res.data?.holdings || []);
    });

    await refetchReportDates({
      entitiesIds: selectedEntitiesIds,
    }).then((res) => {
      setReportDates(res.data?.reportsDates || []);
    });

    await refetchPortfolioStatus({
      entitiesIds: selectedEntitiesIds,
    }).then((res) => {
      setPortfolioStatus(res.data?.dataSectionsStatus);
    });
  };

  // Refetch holdings and report dates when selected entities change
  useEffect(() => {
    if (selectedEntitiesIds.length > 0) {
      handleRefetchHoldings();
    } else {
      setHoldings([]);
      setReportDates([]);
      setPortfolioStatus({
        cas: null,
        fundExposureSummary: null,
        soiLookThrough: null,
      });
    }
  }, [selectedEntitiesIds]);

  const portfolioBaseRoute = generatePath(LP_ROUTES.PortfolioEntrypoint);

  const onSelectEntity = (entityId: string) => {
    setSelectedEntitiesIds([...selectedEntitiesIds, entityId]);
  };

  const onRemoveEntity = (entityId: string) => {
    setSelectedEntitiesIds(selectedEntitiesIds.filter((id) => id !== entityId));
  };

  const onToggleAllEntities = (checked: boolean) => {
    if (checked) {
      setSelectedEntitiesIds(entities.map((entity) => entity.id));
    } else {
      setSelectedEntitiesIds([]);
    }
  };

  return (
    <PortfolioContext.Provider
      value={{
        loading,
        holdings,
        entities,
        reportDates,
        portfolioBaseRoute,
        selectedEntitiesIds,
        entitiesLoading,
        holdingsLoading,
        onSelectEntity,
        onRemoveEntity,
        refetchHoldings,
        portfolioStatus,
        onToggleAllEntities,
        uploadEntity,
        setUploadEntity,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};
