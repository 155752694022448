import { useNavigate } from "react-router";
import { match } from "fp-ts/lib/Either";
import { toast } from "react-hot-toast";
import { useSnackbar } from "notistack";

import { Failure } from "common/@types/app/Failure";
import { NoReturn } from "common/@types/app/UseCase";
import {
  ArchivedMode,
  AskMode,
  BidMode,
  ListIOIMode,
  DashboardMode,
} from "experiences/indications-of-interest/domain/models/ListIOIMode";
import { ArchiveIOI } from "experiences/indications-of-interest/domain/usecases/ArchiveIOI";
import { CreateIOI } from "experiences/indications-of-interest/domain/usecases/CreateIOI";
import { ListIOIs } from "experiences/indications-of-interest/domain/usecases/ListIOIs";
import { ListIssuersForAsk } from "experiences/indications-of-interest/domain/usecases/ListIssuersForAsk";
import {
  CreateIOIFormLoadedBidAndAsk,
  CreateIOIFormLoadedBidOnly,
  CreateIOIFormOff,
  IOIsLoaded,
  IOIsLoading,
  IOIsState,
} from "./IOIState";
import {
  IOIType,
  IndicationOfInterest,
} from "experiences/indications-of-interest/domain/models/IOI";
import {
  ArchiveIOIOptionPressed,
  BulkIOIUploadScreenPasted,
  BulkIOIUploadScreenIssuerCacheIssuerAdded,
  BulkIOIUploadScreenSubmitted,
  CreateIOIFormSubmitted,
  CreateIOIFormTriggered,
  IOIEvent,
  NewIOIFlowCloseRequested,
} from "./IOIEvent";
import {
  ICreateAskForm,
  ICreateBidForm,
} from "experiences/indications-of-interest/domain/models/CreateIOIForm";
import { IIssuer } from "common/@types/models/Issuer";
import { UploadIOIsList } from "experiences/indications-of-interest/domain/usecases/UploadIOIsList";
import {
  MatchNames,
  MatchResult,
} from "experiences/funds/domain/usecases/MatchNames";
import { LP_ROUTES } from "common/constants/routes";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { useStateAndRef } from "common/utils/useStateAndRef";
import { useEffect, useState } from "react";

interface IUseCases {
  listIOIs: ListIOIs;
  archiveIOI: ArchiveIOI;
  createIOI: CreateIOI;
  listIssuersForAsk: ListIssuersForAsk;
  uploadIOIsList: UploadIOIsList;

  skipLoadAllIoisByDefault?: boolean;
}

export const useManageIOIsState = ({
  listIOIs,
  archiveIOI,
  createIOI,
  listIssuersForAsk,
  uploadIOIsList,
  skipLoadAllIoisByDefault,
}: IUseCases) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const [archivedIOIsState, setArchivedIOIsState] = useState<IOIsState>(
    new IOIsLoading(),
  );
  const [asksState, setAsksState] = useState<IOIsState>(new IOIsLoading());
  const [bidsState, setBidsState] = useState<IOIsState>(new IOIsLoading());
  const [dashboardState, setDashboardState] = useState<IOIsState>(
    new IOIsLoading(),
  );
  const [createIOIFormState, setCreateIOIFormState] = useState<IOIsState>(
    new CreateIOIFormOff(),
  );

  const [issuersForAsk, setIssuersForAsk] = useState<IIssuer[]>([]);

  const { sendMessage } = useSendSlackMessage({
    channel: import.meta.env.VITE_APP_SLACK_IOI_CHANNEL,
  });

  const [_issuerCache, _setIssuerCache, issuerCacheState] = useStateAndRef(
    new Set<IIssuer>(),
  );

  const refreshIOIsList = (mode: ListIOIMode) => {
    listIOIs.call({ mode }).then((resp) => {
      match<Failure, IndicationOfInterest[], void>(
        (_: Failure) => {
          enqueueSnackbar("Error during updating IOIs list", {
            variant: "error",
          });
        },
        (iois: IndicationOfInterest[]) => {
          setBidsState((prev) => {
            return mode == BidMode ? new IOIsLoaded({ iois }) : prev;
          });
          setAsksState((prev) => {
            return mode == AskMode ? new IOIsLoaded({ iois }) : prev;
          });
          setArchivedIOIsState((prev) => {
            return mode == ArchivedMode ? new IOIsLoaded({ iois }) : prev;
          });
          setDashboardState((prev) => {
            return mode == DashboardMode ? new IOIsLoaded({ iois }) : prev;
          });
        },
      )(resp);
    });
  };

  const archive = (ioiId: string) => {
    archiveIOI.call({ ioiId }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during archiving IOI", {
            variant: "error",
          });
        },
        (_) => {
          refreshAllIOILists();
        },
      )(resp);
    });
  };

  const create = (form: ICreateAskForm | ICreateBidForm, type: IOIType) => {
    createIOI.call({ form, type }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during creating IOI", {
            variant: "error",
          });
        },
        (_) => {
          sendMessage({
            blocks: [
              generateSlackMessagePayload({
                color: type == IOIType.Ask ? "#FF0000" : "#008000",
                title: `IOI Created 📝🚀 ${
                  type == IOIType.Ask ? "(Ask)" : "(Bid)"
                }`,
                fields: generateObjectAsFields({
                  "user name": `${user?.firstName} ${user?.lastName}`,
                  "user email": user?.email,
                }),
                context: generateObjectAsFields(form),
              }),
            ],
          });
          refreshAllIOILists();
        },
      )(resp);
    });
  };

  const setIssuersForAskForm = () => {
    listIssuersForAsk.call({}).then((resp) => {
      match<Failure, IIssuer[], void>(
        (_: Failure) => {
          toast("Failed to get issuers.");
        },
        (issuers: IIssuer[]) => {
          setIssuersForAsk(issuers);
        },
      )(resp);
    });
  };

  const setIOIForm = () => {
    setCreateIOIFormState(() => {
      if (issuersForAsk.length > 0) {
        return new CreateIOIFormLoadedBidAndAsk({
          issuersForAsk,
        });
      } else {
        return new CreateIOIFormLoadedBidOnly();
      }
    });
  };

  const bulkUpload = (file: File) => {
    uploadIOIsList.call({ file }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during uploading ioi list, try again later.", {
            variant: "error",
          });
        },
        (_: NoReturn) => {
          navigate(LP_ROUTES.IndicationsOfInterestBids, { replace: true });
          enqueueSnackbar(
            "Uploaded list successfully. Currently being processed.",
            {
              variant: "success",
            },
          );
        },
      )(resp);
    });
  };

  const matchFundNames = (searchTerms: string[]) => {
    new MatchNames()
      .call({
        searchTerms,
      })
      .then((resp) => {
        return match<Failure, MatchResult[], void>(
          (_: Failure) => {
            enqueueSnackbar("Error while getting fund options for list.", {
              variant: "error",
            });
          },
          (matches: MatchResult[]) => {
            return matches;
          },
        )(resp);
      });
  };

  const addIssuerToCache = (issuer: IIssuer) => {
    issuerCacheState.current.add(issuer);
  };

  const refreshAllIOILists = () => {
    refreshIOIsList(BidMode);
    refreshIOIsList(AskMode);
    refreshIOIsList(ArchivedMode);
    refreshIOIsList(DashboardMode);
    setIssuersForAskForm();
  };

  useEffect(() => {
    if (skipLoadAllIoisByDefault) return;
    refreshAllIOILists();
  }, []);

  let prevEvent: IOIEvent;
  const emitEvent = (event: IOIEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof CreateIOIFormSubmitted) {
      create(event.form, event.type);
    } else if (event instanceof ArchiveIOIOptionPressed) {
      archive(event.ioiId);
    } else if (event instanceof CreateIOIFormTriggered) {
      setIOIForm();
    } else if (event instanceof NewIOIFlowCloseRequested) {
      setCreateIOIFormState(new CreateIOIFormOff());
    } else if (event instanceof BulkIOIUploadScreenSubmitted) {
      bulkUpload(event.file);
    } else if (event instanceof BulkIOIUploadScreenPasted) {
      matchFundNames(event.searchTerms);
    } else if (event instanceof BulkIOIUploadScreenIssuerCacheIssuerAdded) {
      addIssuerToCache(event.issuer);
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    asksState,
    bidsState,
    archivedIOIsState,
    dashboardState,
    createIOIFormState,
    issuerCacheState,
  };
};
