import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { InviteSignatoryModal } from "../components/InviteSignatoryModal";
import { RequestRedliningModal } from "../components/RequestRedliningModal";
import PandaDocIframe from "../components/PandaDocEmbedView";
import { useDocumentsContext } from "../state/DocumentsContext";
import { IDocumentSignaturePackage } from "experiences/documents/models/DocumentSignaturePackage";
import { ClaimSignatoryModal } from "../components/ClaimSignatoryModal";
import { SignatureScreenSignatureSucceeded } from "../state/DocumentsEvent";

const SignNDA: React.FC<IDocumentSignaturePackage> = ({
  embedLink,
  isRedliner,
  isSignatory,
  signatoryEmail,
}) => {
  const { emitEvent } = useDocumentsContext();

  const [showAssignSignatory, setShowAssignSignatory] =
    useState<boolean>(false);
  const [showRequestRedlining, setShowRequestRedlining] =
    useState<boolean>(false);
  const [showClaimSignatory, setShowClaimSignatory] = useState<boolean>(false);

  const handleOpenAssignSignatory = () => {
    setShowAssignSignatory(true);
  };

  const handleCloseSignatoryModal = () => {
    setShowAssignSignatory(false);
  };

  const handleOpenRequestRedlining = () => {
    setShowRequestRedlining(true);
  };

  const handleCloseRequestRedlining = () => {
    setShowRequestRedlining(false);
  };

  const handleOpenClaimSignatory = () => {
    setShowClaimSignatory(true);
  };

  const handleCloseClaimSignatory = () => {
    setShowClaimSignatory(false);
  };

  return (
    <>
      <PageHead title="Sign NDA" />
      <StepperFlow
        name="Sign NDA"
        activeStepIndex={0}
        childrenTopBar={
          <Box display="flex" gap="8px">
            {isSignatory && (
              <Button
                onClick={handleOpenAssignSignatory}
                type={BUTTON_TYPES.PRIMARY}
              >
                Assign Signatory
              </Button>
            )}
            {/* <Button
              onClick={handleOpenRequestRedlining}
              type={BUTTON_TYPES.PRIMARY}
            >
              Request Redlining
            </Button> */}
          </Box>
        }
      >
        {!isSignatory && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100vw",
              height: "48px",
              backgroundColor: "#BCB3A5",
              marginTop: "-32px",
              padding: "0 16px",
            }}
          >
            <Typography
              sx={{
                color: "#21272D",
                fontFamily: '"Inter", sans-serif',
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
              }}
            >
              You are not the signatory for this document. The signatory is
              currently <strong>{signatoryEmail}</strong>.
            </Typography>
            <Button
              onClick={handleOpenClaimSignatory}
              type={BUTTON_TYPES.SECONDARY}
              size="medium"
            >
              Claim Signatory
            </Button>
          </Box>
        )}
        <Stack
          sx={{
            height: isSignatory ? "calc(100% + 32px)" : "100%",
            width: "100vw",
            marginTop: isSignatory ? "-32px" : "0px",
          }}
        >
          <PandaDocIframe
            embedLink={embedLink}
            onSignatureComplete={() => {
              emitEvent!(new SignatureScreenSignatureSucceeded({}));
            }}
          />
        </Stack>
        <InviteSignatoryModal
          open={showAssignSignatory}
          onClose={handleCloseSignatoryModal}
        />
        <RequestRedliningModal
          open={showRequestRedlining}
          onClose={handleCloseRequestRedlining}
        />
        <ClaimSignatoryModal
          open={showClaimSignatory}
          onClose={handleCloseClaimSignatory}
        />
      </StepperFlow>
    </>
  );
};

export default SignNDA;
