import { forwardRef } from "react";
import { classNames } from "primereact/utils";

import { Fund } from "experiences/funds/domain/models/Fund";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { viewpointInvestments } from "../viewpoint/viewpointInvestments";
import { EmptyState } from "common/components/EmptyState";
import { StyledList } from "experiences/common/List";
import { cn } from "common/utils";
import { FundConnectPortfolioOverlay } from "experiences/dashboard/presentation/components/VerifyPermissionOverlay";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { useNavigate } from "react-router";
import { LP_ROUTES } from "common/constants/routes";

const useInvestmentsTable = ({ fund }: { fund?: Fund }) => {
  const investments = fund?.investments || [];

  const totals = {
    cost: investments.reduce((acc, curr) => acc + curr.cost, 0),
    bookValue: investments.reduce((acc, curr) => acc + curr.bookValue, 0),
    unrelalizedPL: investments.reduce(
      (acc, curr) => acc + curr.unrelalizedPL,
      0,
    ),
    realizedPL: investments.reduce((acc, curr) => acc + curr.realizedPL, 0),
  };

  return {
    investments,
    totals,
  };
};

enum ColumnKeys {
  Company = "company",
  Type = "type",
  Class = "class",
  Date = "date",
  Cost = "cost",
  BookValue = "bookValue",
  UnrealizedPL = "unrelalizedPL",
  RealizedPL = "realizedPL",
}

const columnLabels = {
  [ColumnKeys.Company]: "Company",
  [ColumnKeys.Type]: "Type",
  [ColumnKeys.Class]: "Class",
  [ColumnKeys.Date]: "Date",
  [ColumnKeys.Cost]: "Cost",
  [ColumnKeys.BookValue]: "Book Value",
  [ColumnKeys.UnrealizedPL]: "Unrealized P/L",
  [ColumnKeys.RealizedPL]: "Realized P/L",
};

const InvestmentRow = ({
  row,
  locked,
}: {
  row: (typeof viewpointInvestments)[0];
  locked: boolean;
}) => {
  const navigate = useNavigate();

  const onRowClick = () => {
    console.log("redirecting to company detail page, need to add company ID");
    navigate(
      `${LP_ROUTES.CompanyDetail.replace(":companyId", row.company || "test-company-id")}`,
    );
  };

  return (
    <tr
      className={classNames({ locked: locked })}
      onClick={onRowClick}
      style={{ cursor: "pointer" }}
    >
      <td className={classNames({ locked: locked })}>{row.company}</td>
      <td className={classNames({ locked: locked })}>{row.type}</td>
      <td className={classNames({ locked: locked })}>{row.class}</td>
      <td className={classNames({ locked: locked })}>{row.date}</td>
      <td className={classNames("border-left", { locked: locked })}>
        {(row.cost && new DollarAmount(row.cost).formattedBig()) ||
          NOT_AVAILABLE_STR}
      </td>
      <td className={classNames("border-left", { locked: locked })}>
        {(row.bookValue && new DollarAmount(row.bookValue).formattedBig()) ||
          NOT_AVAILABLE_STR}
      </td>
      <td className={classNames("border-left", { locked: locked })}>
        {(row.unrelalizedPL &&
          new DollarAmount(row.unrelalizedPL).formattedBig()) ||
          NOT_AVAILABLE_STR}
      </td>
      <td className={classNames("border-left", { locked: locked })}>
        {(row.realizedPL && new DollarAmount(row.realizedPL).formattedBig()) ||
          NOT_AVAILABLE_STR}
      </td>
    </tr>
  );
};

// Note: main component is wrapped in forwardRef to allow parent component to scroll to this component
export const InvestmentsTable = forwardRef(function InvestmentsTable(
  {
    fund,
    locked,
  }: {
    fund?: Fund;
    locked: boolean;
  },
  ref: any,
) {
  const { investments, totals } = useInvestmentsTable({
    fund,
  });

  const tableHeaders = [
    {
      label: columnLabels[ColumnKeys.Company],
      key: ColumnKeys.Company,
      borderLeft: false,
    },
    {
      label: columnLabels[ColumnKeys.Type],
      key: ColumnKeys.Type,
      borderLeft: false,
    },
    {
      label: columnLabels[ColumnKeys.Class],
      key: ColumnKeys.Class,
      borderLeft: false,
    },
    {
      label: columnLabels[ColumnKeys.Date],
      key: ColumnKeys.Date,
      borderLeft: false,
    },
    {
      label: columnLabels[ColumnKeys.Cost],
      key: ColumnKeys.Cost,
      borderLeft: true,
    },
    {
      label: columnLabels[ColumnKeys.BookValue],
      key: ColumnKeys.BookValue,
      borderLeft: true,
    },
    {
      label: columnLabels[ColumnKeys.UnrealizedPL],
      key: ColumnKeys.UnrealizedPL,
      borderLeft: true,
    },
    {
      label: columnLabels[ColumnKeys.RealizedPL],
      key: ColumnKeys.RealizedPL,
      borderLeft: true,
    },
  ];

  const totalsRowData = {
    [ColumnKeys.Cost]: new DollarAmount(totals[ColumnKeys.Cost]).formattedBig(),
    [ColumnKeys.BookValue]: new DollarAmount(
      totals[ColumnKeys.BookValue],
    ).formattedBig(),
    [ColumnKeys.UnrealizedPL]: new DollarAmount(
      totals[ColumnKeys.UnrealizedPL],
    ).formattedBig(),
    [ColumnKeys.RealizedPL]: new DollarAmount(
      totals[ColumnKeys.RealizedPL],
    ).formattedBig(),
  };

  const hasInvestments = investments.length > 0;

  return (
    <div ref={ref}>
      <PageSectionTitleDivider showBorderTop>
        Investments
      </PageSectionTitleDivider>
      <div
        className={cn("relative", {
          "min-h-96": locked,
        })}
      >
        <StyledList
          className={classNames({ locked: locked })}
          style={{
            position: "relative",
            ...(locked
              ? {
                  filter: "blur(7px)",
                  opacity: 0.3,
                }
              : {}),
          }}
        >
          {hasInvestments ? (
            <table className="dashboard_table">
              <thead>
                <tr>
                  {tableHeaders.map(({ key, borderLeft, label }) => (
                    <th
                      key={key}
                      className={classNames({ border_left: borderLeft })}
                    >
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {investments.map((row) => (
                  <InvestmentRow row={row} locked={locked} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>Totals</td>
                  {Object.entries(totalsRowData).map(([key, value]) => (
                    <td className="border_left" key={key}>
                      {value}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          ) : (
            <EmptyState
              title="We don't have registered investments for this entry"
              style={{ marginTop: 120 }}
            />
          )}
        </StyledList>
        {locked && <FundConnectPortfolioOverlay />}
      </div>
    </div>
  );
});
