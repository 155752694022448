const steps = [
  {
    isActive: true,
    label: "Invite Sent",
    description:
      "Tap has sent an email to the signatory prompting them to sign the NDA.",
  },
  {
    isActive: false,
    label: "Signatory Executes the NDA",
    description: "The signatory can execute and/or redline the NDA on Tap.",
  },
  {
    isActive: false,
    label: "You Receive Executed Copy",
    description:
      "Once the document is signed you will receive a copy via email.",
  },
];

export const useSignatoryStepsContext = () => {
  return { steps };
};
