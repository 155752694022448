import { ApprovedBuyer } from "../models/ApprovedBuyer";

export const approvedBuyersMock: ApprovedBuyer[] = [
  {
    id: "543ffadb-6d63-4440-a591-612eff8d38a6",
    logoFileName: "SilverleafFamilyOffice.jpg",
    name: "Silverleaf Family Office",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Family Office",
    checkSize: "$5M - $50M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 7,
  },
  {
    id: "a595baba-3770-44fe-9f21-498b922a5f04",
    logoFileName: "MarbleArchPrivateEquity.jpg",
    name: "Marble Arch Private Equity",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 4,
  },
  {
    id: "6694aade-cb7b-4aac-95d6-73d83dd3ea8d",
    logoFileName: "YellowstonePartners.jpg",
    name: "Yellowstone Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 4,
  },
  {
    id: "91aa2a94-05d8-411c-90bb-8350a2fa3716",
    logoFileName: "HorizonEndowment.jpg",
    name: "Horizon Endowment",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Endowment",
    checkSize: "$25M - $200M",
    staple: "NO",
    informationRights: "PARTIAL",
    txnsCount: 4,
  },
  {
    id: "406e7af0-bb14-4029-8c22-1eff2c600511",
    logoFileName: "WillowCreekPension.jpg",
    name: "Willow Creek Pension",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Pension",
    checkSize: "$30M - $80M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 4,
  },
  {
    id: "2e04ea14-530e-479d-9dfa-9c319bc22768",
    logoFileName: "SalientPartners.jpg",
    name: "Salient Partners",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "IV, Opportunities",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 4,
  },
  {
    id: "f090db24-ee47-45e5-9c7f-4957dffe8868",
    logoFileName: "GoldenHillsGroup.jpg",
    name: "Golden Hills Group",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "IV, Opportunities",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 4,
  },
  {
    id: "ca7e1df8-a35a-4f71-80f9-640bef7c3388",
    logoFileName: "HueInvestmentGroup.jpg",
    name: "Hue Investment Group",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$25M - $200M",
    staple: "YES",
    informationRights: "NO",
    txnsCount: 2,
  },
  {
    id: "d3e813be-b9d6-46e7-894f-832253522657",
    logoFileName: "JanetStreet.jpg",
    name: "Janet Street",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 2,
  },
  {
    id: "a73ee1e2-76e6-46c0-9fb2-84655988f928",
    logoFileName: "OpenFront.jpg",
    name: "Open Front",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 1,
  },
  {
    id: "c5f28887-29e2-41d9-bbd6-545103434241",
    logoFileName: "TRECP.jpg",
    name: "TRECP",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "NO",
    informationRights: "YES",
    txnsCount: 0,
  },
  {
    id: "0a9d27c0-2925-4466-8dd0-a896db7bee23",
    logoFileName: "HashmontPartners.jpg",
    name: "Hashmont Partners",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "NO",
    informationRights: "YES",
    txnsCount: 0,
  },
  {
    id: "17428865-4db9-45a0-bde9-d4607261409b",
    logoFileName: "HiFongGroup.jpg",
    name: "HiFong Group",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$25M - $200M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 0,
  },
  {
    id: "1c48eb32-6a81-4004-bf2b-f11bec4b90a1",
    logoFileName: "Knoll&Co.jpg",
    name: "Knoll & Co",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 0,
  },
  {
    id: "521dcdf8-f792-498b-acee-71a0b664b137",
    logoFileName: "VitalRockPartners.jpg",
    name: "Vital Rock Partners",
    I: "FALSE",
    II: "FALSE",
    III: "FALSE",
    IV: "TRUE",
    Opportunities: "TRUE",
    fundScope: "IV, Opportunities",
    type: "Secondaries Fund",
    checkSize: "$50M - $500M",
    staple: "YES",
    informationRights: "YES",
    txnsCount: 0,
  },
];
