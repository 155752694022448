import { interpolate, samples, parse, formatHex } from "culori";

import { Group } from "@visx/group";
import { Pie } from "@visx/shape";

import { cn } from "common/utils";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

export interface CircleChartData {
  name: string;
  percentage: number | string;
}

// https://lch.oklch.com/
const orangeInitial = "oklch(50% 0.1717 39.56)";
const orangeFinal = "oklch(95% 0.0331 79.27)";
const greenInitial = "oklch(50% 0.0671 138.13)";
const greenFinal = "oklch(95% 0.0045 134.85)";
const blueInitial = "oklch(50% 0.1389 241.96)";
const blueFinal = "oklch(95% 0.025 236.83)";
const grayInitial = "oklch(50% 0.0234 264.37)";
const grayFinal = "oklch(95% 0.0029 264.54)";

// initial is darker
export const chartColors: Record<string, [string, string]> = {
  orange: [orangeInitial, orangeFinal],
  green: [greenInitial, greenFinal],
  blue: [blueInitial, blueFinal],
  default: [grayInitial, grayFinal],
};

export const CircleChart = ({
  title,
  data,
  width,
  height,
  colorScale,
  loading,
}: {
  title: string;
  data: CircleChartData[];
  width: number;
  height: number;
  colorScale: [string, string];
  loading?: boolean;
}) => {
  const radius = Math.min(width, height) / 2;
  const donutThickness = 24;
  const centerX = width / 2;
  const centerY = height / 2;
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const total = data.reduce((acc, d) => acc + Number(d.percentage), 0);

  const isEmpty = data.length === 0 && !loading;

  let pieData = data;

  if (isEmpty) {
    pieData = [{ name: NOT_AVAILABLE_STR, percentage: 100 }];
    // colorScale = [chartColors.default[0], chartColors.default[0]];
  }
  if (loading) {
    pieData = [{ name: "Loading", percentage: 100 }];
    // colorScale = [chartColors.default[0], chartColors.default[0]];
  }

  const count = isEmpty || loading ? 1 : pieData.length;

  const interpolated: () => any = interpolate([
    parse(colorScale[0]),
    parse(colorScale[1]),
  ]);
  const sampled: number[] = samples(count);
  const palette: { mode: string; r: number; g: number; b: number }[] =
    sampled.map(interpolated);

  // Convert the palette to hex
  // then reverse it so it starts with the first color
  const realPalette = palette.map(formatHex).reverse();

  // Math.PI / 2 is 90 degrees, but I want 60 degrees so
  const startAngle = Math.PI / 3;

  const sortedPiedata = pieData.sort((a, b) =>
    Number(a.percentage) > Number(b.percentage) ? 1 : -1,
  );

  return (
    <div className="flex items-center justify-start gap-8 flex-col 2xl:justify-center 2xl:flex-row h-full">
      <div className="relative">
        <svg width={width} height={height}>
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Pie
              data={sortedPiedata}
              pieValue={(d) => Number(d.percentage)}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={3}
              padAngle={0.01}
              startAngle={startAngle}
              endAngle={Math.PI * 2 + startAngle}
            >
              {({ arcs, path, pie }) => (
                <g>
                  {arcs.map((arc, i) => {
                    const r = radius - donutThickness / 2;
                    // For some reason, the arcs are not centered correctly, so I'm adding a 3.14 offset to the arc
                    const arcOffset = Math.PI;
                    const x =
                      r *
                      Math.cos((arc.endAngle + arc.startAngle - arcOffset) / 2);
                    const y =
                      r *
                      Math.sin((arc.endAngle + arc.startAngle - arcOffset) / 2);

                    const value = Math.round((arc.value / total) * 100);

                    const isZero = value === 0;

                    // Label is the actual % value of the pie chart slice
                    const label =
                      isEmpty || loading || isZero ? "" : `${value}%`;

                    const labelColor =
                      sampled[i] > 0.4 ? colorScale[1] : colorScale[0];

                    return (
                      <g key={`pie-arc-${i}`}>
                        <path
                          className={`arc${i}`}
                          d={path(arc)}
                          fill={realPalette[i]}
                          opacity={loading ? 0.1 : 1}
                        />
                        {!loading && !isZero && (
                          <text
                            fill={labelColor}
                            fontSize={10}
                            textAnchor="middle"
                            transform="translate(0, 4)"
                            style={{}}
                            x={x}
                            y={y}
                          >
                            {label}
                          </text>
                        )}
                      </g>
                    );
                  })}
                </g>
              )}
            </Pie>
          </Group>
        </svg>
        <span className="absolute w-full h-full left-0 top-0">
          <p
            className={cn(
              "text-lg font-bold font-staff left-1/2 top-1/2 absolute -translate-x-1/2 -translate-y-1/2 leading-tight tracking-tight select-none cursor-default text-center",
              {
                "text-stone-400": loading,
              },
            )}
          >
            {title}
          </p>
        </span>
      </div>
      <div className="">
        <div className="flex flex-col-reverse justify-center">
          {data.map((d, i) => (
            <div key={i} className="flex items-center h-4 select-none">
              <span
                className="w-2 h-2 mr-2 rounded-full"
                style={{
                  backgroundColor: realPalette[i],
                }}
              ></span>
              <span className="text-xs whitespace-nowrap">{d.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
