import { saveAs } from "file-saver";
import { Workbook, Worksheet } from "exceljs";
import csvToJson from "convert-csv-to-json";

import { IWorksheetColumn } from "./model";

export const DEFAULT_WORKSHEET_COLUMN_WIDTH = 15;
export const WORKSHEET_COLUMN_WIDTH_LARGE = 30;
export const WORKSHEET_COLUMN_WIDTH_EXTRA_LARGE = 50;

export const parseStrToNumber = (str: string): number | null => {
  if (str.length === 0) {
    return null;
  }

  return Number(
    str.replaceAll("$", "").replaceAll(",", "").replaceAll("%", ""),
  );
};

// This function MUTATES the table data
export const formatCellsCurrency = ({
  worksheet,
  columns,
  rowIndex,
  rowOffset,
}: {
  worksheet: Worksheet;
  columns: string[];
  rowIndex: number;
  rowOffset: number;
}) => {
  columns.forEach((column) => {
    const cell = worksheet.getRow(rowIndex + rowOffset).getCell(column);

    // The cell may be calculated by a formula, so we don't want to parse it as a number
    if (
      cell.value === null ||
      cell.value === undefined ||
      typeof cell.value === "undefined" ||
      (typeof cell.value === "string" && cell.value === "")
    ) {
      cell.numFmt = "";
      cell.value = null;
      return;
    }

    if (typeof cell.value === "string") {
      cell.value = parseStrToNumber(cell.value as string);
      cell.numFmt = "$#,##0";
    }
  });
};

// This function MUTATES the table data
// Note: The original CSV export has the data on a 0-100% scale, so we need to divide by 100 to get the correct percentage because excel
// uses a 0-1 scale
export const formatCellsPercentage = ({
  worksheet,
  columns,
  rowIndex,
  rowOffset,
}: {
  worksheet: Worksheet;
  columns: string[];
  rowIndex: number;
  rowOffset: number;
}) => {
  columns.forEach((column) => {
    const cell = worksheet.getRow(rowIndex + rowOffset).getCell(column);

    // The cell may be calculated by a formula, so we don't want to parse it as a number
    if (cell.value === null || cell.value === undefined) {
      return;
    }

    if (typeof cell.value === "string") {
      cell.value = parseStrToNumber(cell.value as string) / 100;
    }
  });

  // Also, set these columns to be percentage formatted
  columns.forEach((colKey) => {
    const column = worksheet.getColumn(colKey);
    column.numFmt = "0.0%";
  });
};

// This function MUTATES the table data
export const formatCellsMultiplier = ({
  worksheet,
  columns,
  rowIndex,
  rowOffset,
}: {
  worksheet: Worksheet;
  columns: string[];
  rowIndex: number;
  rowOffset: number;
}) => {
  columns.forEach((column) => {
    const cell = worksheet.getRow(rowIndex + rowOffset).getCell(column);

    // The cell may be calculated by a formula, so we don't want to parse it as a number
    if (cell.value === null || cell.value === undefined) {
      return;
    }

    if (typeof cell.value === "string") {
      cell.value = parseStrToNumber(cell.value as string);
    }
  });

  // Also, set these columns to be multiplier formatted
  columns.forEach((colKey) => {
    const column = worksheet.getColumn(colKey);
    column.numFmt = "0.0x";
  });
};

// This function MUTATES the table data
export const formatCellsDate = ({
  worksheet,
  columns,
  rowIndex,
  rowOffset,
}: {
  worksheet: Worksheet;
  columns: string[];
  rowIndex: number;
  rowOffset: number;
}) => {
  columns.forEach((column) => {
    const cell = worksheet.getRow(rowIndex + rowOffset).getCell(column);

    // The cell may be calculated by a formula, so we don't want to parse it as a number
    if (cell.value === null || cell.value === undefined) {
      return;
    }

    // Date is in the format of YYYY-MM-DD
    if (typeof cell.value === "string") {
      cell.value = new Date(cell.value);
    }
  });
};

export const saveWorkbookToDownloads = async (
  workbook: Workbook,
  fileName: string,
) => {
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      // console.log("buffer", buffer);
    })
    .catch((error) => {
      throw error;
    })
    .catch((error) => {
      throw error;
    });

  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(
    new Blob([buffer], {
      type: "application/vnd.ms-excel",
    }),
    fileName,
  );
};

export const generateScaffoldWorkbook = () => {
  const workbook = new Workbook();

  workbook.creator = "Tap Inc.";
  workbook.lastModifiedBy = "Tap Inc.";
  workbook.created = new Date();
  workbook.modified = new Date();

  return workbook;
};

const generateWorksheet = ({
  workbook,
  label,
  columns,
}: {
  workbook: Workbook;
  label: string;
  columns: IWorksheetColumn[];
}) => {
  const worksheet = workbook.addWorksheet(label);

  worksheet.state = "visible";

  if (columns.length > 0) {
    worksheet.columns = columns;
  }

  return worksheet;
};

const styleWorksheets = ({ worksheets }: { worksheets: Worksheet[] }) => {
  worksheets.forEach((worksheet) => {
    // Black background for the header row
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "000000" },
    };

    // White text for the header row
    worksheet.getRow(1).font = {
      color: { argb: "FFFFFF" },
    };
  });
};

export const generateWorksheets = ({
  workbook,
  sheets,
}: {
  workbook: Workbook;
  sheets: {
    label: string;
    columns: IWorksheetColumn[];
  }[];
}) => {
  const worksheets = [];

  sheets.forEach((sheet) => {
    const worksheet = generateWorksheet({
      workbook,
      label: sheet.label,
      columns: sheet.columns,
    });

    worksheets.push(worksheet);
  });

  styleWorksheets({ worksheets });

  return worksheets;
};

export const getCsvData = (data, tabName: string) => {
  const string = data.find((tab) => tab.name === tabName)?.data;

  if (!string) {
  }

  return csvToJson
    .fieldDelimiter(",")
    .supportQuotedField(true)
    .csvStringToJson(string);
};
