import { useFormik } from "formik";
import * as yup from "yup";

import { Box, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { usePortfolioContext } from "../state/PortfolioContext";
import { CreateHoldingModalSubmitted } from "../state/PortfolioEvent";
import FormikTextField from "../../../../common/components/formik/FormikTextField";
import IssuerSelector from "../../../funds/presentation/components/IssuersSelector";
import {
  FormikQuarterPicker,
  getLastQuarter,
} from "../../../../common/components/formik/FormikQuarterPicker";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import { Fund } from "experiences/funds/domain/models/Fund";
import Autocomplete from "common/components/Autocomplete";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";

interface IAddHoldingModal {
  open: boolean;
  onClose: () => void;
  lockedFund?: Fund;
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

const AddHoldingModal: React.FC<IAddHoldingModal> = ({
  open,
  onClose,
  lockedFund,
}) => {
  const { emitEvent } = usePortfolioContext();
  const classes = useStyles();

  const validationSchema = yup.object({
    referenceDate: yup.date().required("Required").default(getLastQuarter()),
    issuerId: lockedFund ? yup.string() : yup.string().required("Required"),
    nav: yup.string().required("Required"),
    committed: yup.string(),
    contributed: yup.string(),
    distributed: yup.string(),
  });
  const makeNumber = (num: string) => {
    return +num.replaceAll(",", "").replaceAll("$", "");
  };

  const formik = useFormik({
    initialValues: {
      issuerId: lockedFund ? lockedFund.id : "",
      referenceDate: "",
      nav: "",
      committed: "",
      contributed: "",
      distributed: "",
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent!(
        new CreateHoldingModalSubmitted({
          form: {
            issuerId: lockedFund ? lockedFund.id : values.issuerId,
            referenceDate: values.referenceDate,
            nav: makeNumber(values.nav),
            committed: values.committed ? makeNumber(values.committed) : 0,
            contributed: values.contributed
              ? makeNumber(values.contributed)
              : 0,
            distributed: values.distributed
              ? makeNumber(values.distributed)
              : 0,
          },
        }),
      );
      formik.resetForm();
      onClose();
    },
  });

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      classes={{ paper: classes.drawerPaper }}
    >
      <StepperFlow name="Add Holdings" activeStepIndex={0} onClose={onClose}>
        <form
          onSubmit={formik.submitForm}
          className="flex flex-col gap-6 max-w-screen-md mx-auto"
        >
          <div className="flex flex-col">
            <PageHead
              title="Add Holdings"
              description="Enter your details to add holdings to your portfolio"
              paddingless
            />
          </div>
          {lockedFund ? (
            <Autocomplete
              label={"Name"}
              options={[]}
              disabled
              inputValue={lockedFund.name}
              onChange={() => {}}
            />
          ) : (
            <IssuerSelector label="Name" formik={formik} name="issuerId" />
          )}
          <FormikTextField
            name="nav"
            formik={formik}
            label="NAV"
            mask="currency"
            fullWidth
          />
          <FormikQuarterPicker
            formik={formik}
            name="referenceDate"
            label="Reference Date"
            defaultNow={true}
            inputProps={undefined}
          />
          <FormikTextField
            name="committed"
            formik={formik}
            label="Committed"
            mask="currency"
            fullWidth
          />
          <FormikTextField
            name="contributed"
            formik={formik}
            label="Contributed"
            mask="currency"
            fullWidth
          />
          <FormikTextField
            name="distributed"
            formik={formik}
            label="Distributed"
            mask="currency"
            fullWidth
          />

          <Box>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              onClick={formik.submitForm}
              size="large"
              fullWidth
              icon={<PlusIcon />}
              disabled={formik.isSubmitting}
            >
              Add Holding
            </Button>
          </Box>
        </form>
      </StepperFlow>
    </Drawer>
  );
};

export default AddHoldingModal;
