import { IIssuer } from "common/@types/models/Issuer";
import TapClient from "common/clients/TapClient";

export const getDropdownOptions = async (
  query: string,
  process: (string: any[]) => void,
  issuerCacheRef: React.MutableRefObject<Set<IIssuer>>,
) => {
  await new TapClient("/issuers/", {
    paginationSpecs: {
      perPage: 10,
      pageIndex: 1,
    },
    filters: { searchTerm: query },
  })
    .post()
    .then(
      (resp: {
        items: IIssuer[];
        total: number;
        pageIndex: number;
        pageCount: number;
        perPage: number;
      }) => {
        // store new fund names in cache, so that we can use them for validation
        // (we only allow valid fund names, validation will gather from this list)
        const currentIssuers = Array.from(issuerCacheRef.current);

        resp.items.forEach((issuer) => {
          const existingIssuer = currentIssuers.find(
            (existingIssuer) => existingIssuer.id === issuer.id,
          );

          if (!existingIssuer) {
            issuerCacheRef.current.add(issuer);
          }
        });

        // handsontable receives a process() function that *only* takes in a string[]
        // process(issuerCacheRef.current.forea.map((issuer) => issuer.name));
        const issuerNames = <string[]>[];
        issuerCacheRef.current.forEach((issuer) =>
          issuerNames.push(issuer.name),
        );
        process(issuerNames);

        return {
          issuers: resp.items,
          totalFunds: resp.total,
          pageIndex: resp.pageIndex,
          pageCount: resp.pageCount,
          perPage: resp.perPage,
        };
      },
    );
};
