import { DEMO_TXN_ID } from "common/constants/platform";
import { ITransaction } from "experiences/transactions/domain/models/Transaction";

interface Buyer {
  logoFileName: string;
  name: string;
  status: "Suggested" | "Approved" | "Requested";
}

interface ITenderOffer extends ITransaction {
  isClosed: boolean;
  name: string;
  alertCount?: number;
  referenceYear: number;
  referenceQuarter: number;
}

class TenderOffer implements ITenderOffer {
  id: string;
  isClosed: boolean;
  name: string;
  alertCount?: number;
  referenceYear: number;
  referenceQuarter: number;

  constructor({
    id,
    isClosed,
    alertCount,
    referenceYear,
    name,
    referenceQuarter,
  }: ITenderOffer) {
    this.id = id;
    this.isClosed = isClosed;
    this.alertCount = alertCount;
    this.referenceYear = referenceYear;
    this.referenceQuarter = referenceQuarter;
    this.name = name;
  }
}

interface LimitedPartner {
  logoFileName: string;
  lpName: string;
  currentParticipation?: string;
}

const dashboardBuyersList: Buyer[] = [
  {
    logoFileName: "BigRockCapitalPartners.jpg",
    name: "Big Rock Capital Partners",
    status: "Requested",
  },
  {
    logoFileName: "OakwoodEquityHoldings.jpg",
    name: "Oakwood Equity Holdings",
    status: "Requested",
  },
  {
    logoFileName: "CrestwoodPrivateWealth.jpg",
    name: "Crestwood Private Wealth",
    status: "Requested",
  },
  {
    logoFileName: "FalconStrategicInvestments.jpg",
    name: "Falcon Strategic Investments",
    status: "Requested",
  },
  {
    logoFileName: "AuroraVentureHoldings.jpg",
    name: "Aurora Venture Holdings",
    status: "Requested",
  },
  {
    logoFileName: "BluePeakEquityPartners.jpg",
    name: "BluePeak Equity Partners",
    status: "Requested",
  },
  {
    logoFileName: "CrestviewCapital.jpg",
    name: "Crestview Capital",
    status: "Requested",
  },
  {
    logoFileName: "DragonflyInvestmentGroup.jpg",
    name: "Dragonfly Investment Group",
    status: "Requested",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    name: "Silverleaf Family Office",
    status: "Approved",
  },
  {
    logoFileName: "MarbleArchPrivateEquity.jpg",
    name: "Marble Arch Private Equity",
    status: "Approved",
  },
  {
    logoFileName: "YellowstonePartners.jpg",
    name: "Yellowstone Partners",
    status: "Approved",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    name: "Horizon Endowment",
    status: "Approved",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    name: "Willow Creek Pension",
    status: "Approved",
  },
  {
    logoFileName: "SalientPartners.jpg",
    name: "Salient Partners",
    status: "Approved",
  },
  {
    logoFileName: "GoldenHillsGroup.jpg",
    name: "Golden Hills Group",
    status: "Approved",
  },
  {
    logoFileName: "HueInvestmentGroup.jpg",
    name: "Hue Investment Group",
    status: "Approved",
  },
  {
    logoFileName: "JanetStreet.jpg",
    name: "Janet Street",
    status: "Approved",
  },
  {
    logoFileName: "OpenFront.jpg",
    name: "Open Front",
    status: "Approved",
  },
  {
    logoFileName: "TRECP.jpg",
    name: "TRECP",
    status: "Approved",
  },
  {
    logoFileName: "HashmontPartners.jpg",
    name: "Hashmont Partners",
    status: "Approved",
  },
  {
    logoFileName: "HiFongGroup.jpg",
    name: "HiFong Group",
    status: "Approved",
  },
  {
    logoFileName: "Knoll&Co.jpg",
    name: "Knoll & Co",
    status: "Approved",
  },
  {
    logoFileName: "VitalRockPartners.jpg",
    name: "Vital Rock Partners",
    status: "Approved",
  },
];

// dashboard
const dashboardLimitedPartners: LimitedPartner[] = [
  {
    logoFileName: "BlueSkyPrivateEquity.jpg",
    lpName: "Blue Sky Private Equity",
    currentParticipation: "$163,889,504",
  },
  {
    logoFileName: "GaussFoundation.jpg",
    lpName: "Gauss Foundation",
    currentParticipation: "$142,895,543",
  },
  {
    logoFileName: "YellowstonePartners.jpg",
    lpName: "Yellowstone Partners",
    currentParticipation: "$77,609,911",
  },
  {
    logoFileName: "JSERS.jpg",
    lpName: "JSERS",
    currentParticipation: "$61,446,564",
  },
  {
    logoFileName: "GranitePeakCapitalPartners.jpg",
    lpName: "Granite Peak Capital Partners",
    currentParticipation: "$25,246,130",
  },
  {
    logoFileName: "CedarGroveCapitalPartners.jpg",
    lpName: "Cedar Grove Capital Partners",
    currentParticipation: "$20,850,328",
  },
  {
    logoFileName: "YarboCountyTeachers.jpg",
    lpName: "Yarbo County Teachers",
    currentParticipation: "$20,124,433",
  },
  {
    logoFileName: "SilverleafFamilyOffice.jpg",
    lpName: "Silverleaf Family Office",
    currentParticipation: "$19,486,871",
  },
  {
    logoFileName: "BancGaston.jpg",
    lpName: "Banc Gaston",
    currentParticipation: "$17,727,849",
  },
  {
    logoFileName: "NobleTrustEquityPartners.jpg",
    lpName: "Noble Trust Equity Partners",
    currentParticipation: "$14,588,407",
  },
  {
    logoFileName: "WillowCreekInvestmentPartners.jpg",
    lpName: "Willow Creek Investment Partners",
    currentParticipation: "$14,370,700",
  },
  {
    logoFileName: "DadeEmployeeRetirementSystem.jpg",
    lpName: "Dade Employee Retirement System",
    currentParticipation: "$12,972,315",
  },
  {
    logoFileName: "GoldenOakInvestments.jpg",
    lpName: "Golden Oak Investments",
    currentParticipation: "$8,033,120",
  },
  {
    logoFileName: "DiamondKeyInvestmentGroup.jpg",
    lpName: "Diamond Key Investment Group",
    currentParticipation: "$5,192,229",
  },
  {
    logoFileName: "WillowCreekPension.jpg",
    lpName: "Willow Creek Pension",
    currentParticipation: "$3,858,844",
  },
  {
    logoFileName: "JanetStreet.jpg",
    lpName: "Janet Street",
    currentParticipation: "$2,508,771",
  },
  {
    logoFileName: "HorizonEndowment.jpg",
    lpName: "Horizon Endowment",
    currentParticipation: "$2,134,758",
  },
  {
    logoFileName: "SummitRidgeFamilyOffice.jpg",
    lpName: "Summit Ridge Family Office",
    currentParticipation: "$1,389,855",
  },
  {
    logoFileName: "StarlightVentureCapital.jpg",
    lpName: "Starlight Venture Capital",
    currentParticipation: "$1,079,196",
  },
  {
    logoFileName: "EntrypointFundIII.jpg",
    lpName: "Entrypoint Fund III",
    currentParticipation: "$1,022,478",
  },
  {
    logoFileName: "SunsetHarborWealthManagement.jpg",
    lpName: "Sunset Harbor Wealth Management",
    currentParticipation: "$931,162",
  },
  {
    logoFileName: "CrystalLakeCapital.jpg",
    lpName: "Crystal Lake Capital",
    currentParticipation: "$538,905",
  },
];

// THIS IS FOR DASHBOARD ONLY
export type GpDashboardTransaction = {
  txnName: string;
  type: string;
  alert: number | string;
};

const transactionsList: GpDashboardTransaction[] = [
  {
    txnName: "Fund IV Q2 2024 Tender",
    type: "Recurring Tender",
    alert: 1,
  },
  {
    txnName: "Fund III Q2 2024 Tender",
    type: "Recurring Tender",
    alert: 2,
  },
  {
    txnName: "Opp Q2 2024 Tender",
    type: "Recurring Tender",
    alert: 1,
  },
  {
    txnName: "Fund IV Q4 2023 Tender",
    type: "Recurring Tender",
    alert: "Closed",
  },
  {
    txnName: "Fund III Q4 2023 Tender",
    type: "Recurring Tender",
    alert: "Closed",
  },
  {
    txnName: "Opp Q4 2023 Tender",
    type: "Recurring Tender",
    alert: "Closed",
  },
  {
    txnName: "Project Hidalgo",
    type: "LP-Led",
    alert: "Closed",
  },
  {
    txnName: "Project Overton",
    type: "LP-Led",
    alert: "Closed",
  },
  {
    txnName: "Project Janis",
    type: "LP-Led",
    alert: "Closed",
  },
  {
    txnName: "Project Gallic",
    type: "One-Off Tender",
    alert: "Closed",
  },
  {
    txnName: "Project Granite",
    type: "LP-Led",
    alert: "Closed",
  },
  {
    txnName: "Project Nether",
    type: "LP-Led",
    alert: "Closed",
  },
  {
    txnName: "Project Osprey",
    type: "LP-Led",
    alert: "Closed",
  },
];

export type IGpTransaction = {
  id: string;
  name: string;
  type: string;
  period: string;
  funds: string;
  sizeDollar: string;
  sizePercent: string;
  stage: string;
  lpScope: string;
  buyersCount: number;
  alertsCount: number;
};

const gpTransactionList: IGpTransaction[] = [
  {
    id: "961c8da5-fbbf-4f9c-b676-578b1405f852",
    name: "Fund IV Q2 2024 Tender",
    type: "Recurring Tender",
    period: "Q2 2024",
    funds: "IV",
    sizeDollar: "$102,006,340",
    sizePercent: "0.55%",
    stage: "Participation Period",
    lpScope: "ALL",
    buyersCount: 11,
    alertsCount: 1,
  },
  {
    id: "4d1116f9-1f7c-4852-b4a2-742bb10494d2",
    name: "Fund III Q2 2024 Tender",
    type: "Recurring Tender",
    period: "Q2 2024",
    funds: "III",
    sizeDollar: "$195,217,335",
    sizePercent: "1.91%",
    stage: "Participation Period",
    lpScope: "ALL",
    buyersCount: 12,
    alertsCount: 2,
  },
  {
    id: "561125b4-9f80-49ce-ab48-8554d7c37e7e",
    name: "Opp Q2 2024 Tender",
    type: "Recurring Tender",
    period: "Q2 2024",
    funds: "Opportunities",
    sizeDollar: "$94,557,462",
    sizePercent: "0.43%",
    stage: "Participation Period",
    lpScope: "ALL",
    buyersCount: 7,
    alertsCount: 0,
  },
  {
    id: "9e508ee4-fe01-4584-bc4b-78dc45efef05",
    name: "Fund IV Q4 2023 Tender",
    type: "Recurring Tender",
    period: "Q4 2023",
    funds: "IV",
    sizeDollar: "$234,554,288",
    sizePercent: "0.11%",
    stage: "Closed",
    lpScope: "ALL",
    buyersCount: 12,
    alertsCount: 0,
  },
  {
    id: "20916531-b4f3-458e-b8de-c5739c714f9b",
    name: "Fund III Q4 2023 Tender",
    type: "Recurring Tender",
    period: "Q4 2023",
    funds: "III",
    sizeDollar: "$291,370,215",
    sizePercent: "1.85%",
    stage: "Closed",
    lpScope: "ALL",
    buyersCount: 12,
    alertsCount: 0,
  },
  {
    id: "61eb159f-eced-467e-afdb-4efb09021938",
    name: "Opp Q4 2023 Tender",
    type: "Recurring Tender",
    period: "Q4 2023",
    funds: "Opportunities",
    sizeDollar: "$125,881,322",
    sizePercent: "0.38%",
    stage: "Closed",
    lpScope: "ALL",
    buyersCount: 6,
    alertsCount: 0,
  },
  {
    id: "f3aa5b4b-87e6-41c1-ade4-a805d82a2ce6",
    name: "Project Hidalgo",
    type: "LP-Led",
    period: "Q4 2023",
    funds: "II",
    sizeDollar: "$82,400,000",
    sizePercent: "0.84%",
    stage: "Closed",
    lpScope: "Willow Creek Pension",
    buyersCount: 9,
    alertsCount: 0,
  },
  {
    id: "3d9c8dc8-8872-490d-91e3-adcc1d475a8f",
    name: "Project Overton",
    type: "LP-Led",
    period: "Q3 2023",
    funds: "II",
    sizeDollar: "$2,836,724",
    sizePercent: "0.29%",
    stage: "Closed",
    lpScope: "Entrypoint Fund III",
    buyersCount: 11,
    alertsCount: 0,
  },
  {
    id: "6dc1f04d-2611-4a97-83ae-eb2e8354ddec",
    name: "Project Janis",
    type: "LP-Led",
    period: "Q3 2023",
    funds: "III",
    sizeDollar: "$88,116,925",
    sizePercent: "1.05%",
    stage: "Binding Bids",
    lpScope: "Sunset Harbor Wealth Management",
    buyersCount: 12,
    alertsCount: 0,
  },
  {
    id: "34a97dbe-33f4-45da-9dfe-ae15a9751c24",
    name: "Project Gallic",
    type: "One-Off Tender",
    period: "Q2 2023",
    funds: "II",
    sizeDollar: "90,408,403",
    sizePercent: "0.42%",
    stage: "Closed",
    lpScope: "ALL",
    buyersCount: 7,
    alertsCount: 0,
  },
  {
    id: "8c178030-f76a-4505-a9b8-95e5d78db5cb",
    name: "Project Granite",
    type: "LP-Led",
    period: "Q1 2023",
    funds: "II",
    sizeDollar: "$48,804,334",
    sizePercent: "0.40%",
    stage: "Closed",
    lpScope: "Golden Hills Group",
    buyersCount: 12,
    alertsCount: 0,
  },
  {
    id: "46962207-3787-4a45-b18a-d1bdf5438426",
    name: "Project Nether",
    type: "LP-Led",
    period: "Q1 2023",
    funds: "II",
    sizeDollar: "$23,639,366",
    sizePercent: "0.90%",
    stage: "Closed",
    lpScope: "Oakhill Family Office",
    buyersCount: 12,
    alertsCount: 0,
  },
  {
    id: "276d819b-7d16-45e8-b91d-060240b77c45",
    name: "Project Osprey",
    type: "LP-Led",
    period: "Q1 2023",
    funds: "III",
    sizeDollar: "$58,638,572",
    sizePercent: "0.15%",
    stage: "Closed",
    lpScope: "Stanley & Kase Advisory",
    buyersCount: 6,
    alertsCount: 0,
  },
];

export {
  dashboardBuyersList,
  dashboardLimitedPartners,
  transactionsList,
  gpTransactionList,
  Buyer,
  LimitedPartner,
  TenderOffer,
};
