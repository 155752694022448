import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IOIType } from "../models/IOI";
import { ICreateAskForm, ICreateBidForm } from "../models/CreateIOIForm";

export class CreateIOI implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    type,
    form,
  }) => {
    return await new TapClient(`ioi/create/`, {
      type,
      ...form,
    })
      .post()
      .then((_) => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  form: ICreateAskForm | ICreateBidForm;
  type: IOIType;
}
