export enum FundCurrency {
  USD = 1,
  CAD = 2,
  EUR = 3,
  GBP = 4,
  RMB = 5,
  TWD = 6,
  HKD = 7,
  INR = 8,
  AUD = 9,
  JPY = 10,
  ILS = 11,
  AED = 12,
  AZN = 13,
  BDT = 14,
  BHD = 15,
  BOB = 16,
  BRL = 17,
  BWP = 18,
  CHF = 19,
  CLP = 20,
  COP = 21,
  CZK = 22,
  DKK = 23,
  DM = 24,
  EEK = 25,
  EGP = 26,
  GHS = 27,
  HRK = 28,
  HUF = 29,
  IDR = 30,
  ISK = 31,
  JOD = 32,
  KES = 33,
  KHR = 34,
  KRW = 35,
  KWD = 36,
  KZT = 37,
  LKR = 38,
  MAD = 39,
  MUR = 40,
  MXN = 41,
  MYR = 42,
  NAD = 43,
  NGN = 44,
  NOK = 45,
  NZD = 46,
  OMR = 47,
  PEN = 48,
  PKR = 49,
  PLN = 50,
  QAR = 51,
  RUB = 52,
  SAR = 53,
  SEK = 54,
  SGD = 55,
  THB = 56,
  TND = 57,
  TRY = 58,
  TTD = 59,
  VND = 60,
  ZAR = 61,
  ZMW = 62,
}

export const FundCurrencyNameMap = new Map<FundCurrency, String>([
  [FundCurrency.USD, "US Dollar"],
  [FundCurrency.CAD, "Canadian Dollar"],
  [FundCurrency.EUR, "Euro"],
  [FundCurrency.GBP, "Pound Sterling"],
  [FundCurrency.RMB, "Renminbi (China CNY)"],
  [FundCurrency.TWD, "New Taiwan Dollar"],
  [FundCurrency.HKD, "Hong Kong Dollar"],
  [FundCurrency.INR, "Indian Rupee"],
  [FundCurrency.AUD, "Australian Dollar"],
  [FundCurrency.JPY, "Yen"],
  [FundCurrency.ILS, "New Israeli Sheqel"],
  [FundCurrency.AED, "UAE Dirham"],
  [FundCurrency.AZN, "Azerbaijanian Manat"],
  [FundCurrency.BDT, "Taka"],
  [FundCurrency.BHD, "Bahraini Dinar"],
  [FundCurrency.BOB, "Boliviano"],
  [FundCurrency.BRL, "Brazilian Real"],
  [FundCurrency.BWP, "Pula"],
  [FundCurrency.CHF, "Swiss Franc"],
  [FundCurrency.CLP, "Chilean Peso"],
  [FundCurrency.COP, "Colombian Peso"],
  [FundCurrency.CZK, "Czech Koruna"],
  [FundCurrency.DKK, "Danish Krone"],
  [FundCurrency.DM, "Deutschmark (DEM)"],
  [FundCurrency.EEK, "Kroon"],
  [FundCurrency.EGP, "Egyptian Pound"],
  [FundCurrency.GHS, "Cedi"],
  [FundCurrency.HRK, "Croatian Kuna"],
  [FundCurrency.HUF, "Forint"],
  [FundCurrency.IDR, "Rupiah"],
  [FundCurrency.ISK, "Iceland Krona"],
  [FundCurrency.JOD, "Jordanian Dinar"],
  [FundCurrency.KES, "Kenyan Shilling"],
  [FundCurrency.KHR, "Riel"],
  [FundCurrency.KRW, "Won"],
  [FundCurrency.KWD, "Kuwaiti Dinar"],
  [FundCurrency.KZT, "Tenge"],
  [FundCurrency.LKR, "Sri Lanka Rupee"],
  [FundCurrency.MAD, "Moroccan Dirham"],
  [FundCurrency.MUR, "Mauritius Rupee"],
  [FundCurrency.MXN, "Mexican Peso"],
  [FundCurrency.MYR, "Malaysian Ringgit"],
  [FundCurrency.NAD, "Namibia Dollar"],
  [FundCurrency.NGN, "Naira"],
  [FundCurrency.NOK, "Norwegian Krone"],
  [FundCurrency.NZD, "New Zealand Dollar"],
  [FundCurrency.OMR, "Rial Omani"],
  [FundCurrency.PEN, "Nuevo Sol"],
  [FundCurrency.PKR, "Pakistan Rupee"],
  [FundCurrency.PLN, "Zloty"],
  [FundCurrency.QAR, "Qatari Rial"],
  [FundCurrency.RUB, "Russian Ruble"],
  [FundCurrency.SAR, "Saudi Riyal"],
  [FundCurrency.SEK, "Swedish Krona"],
  [FundCurrency.SGD, "Singapore Dollar"],
  [FundCurrency.THB, "Baht"],
  [FundCurrency.TND, "Tunisian Dinar"],
  [FundCurrency.TRY, "Turkish Lira"],
  [FundCurrency.TTD, "Trinidad and Tobago Dollar"],
  [FundCurrency.VND, "Vietnamese dong"],
  [FundCurrency.ZAR, "South African Rand"],
  [FundCurrency.ZMW, "Zambian Kwacha"],
]);
