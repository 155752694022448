import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ArrowRight } from "@phosphor-icons/react";

import FormikCheckbox from "common/components/formik/FormikCheckbox";
import { BUTTON_TYPES, Button } from "common/components/Button";
import Range from "common/components/Range";
import { SelectPreferencesFormSubmitted } from "../../state/AuthenticationEvent";
import { useAuthContext } from "../../state/AuthenticationContext";
import { RegAssetClassPref } from "../../../domain/models/RegAssetClassPref";

const RANGE_DATA = [
  { value: 0, label: "<$100K" },
  { value: 1, label: "$250K" },
  { value: 2, label: "$500K" },
  { value: 3, label: "$1M" },
  { value: 4, label: "$5M" },
  { value: 5, label: "$20M" },
  { value: 6, label: "$50M" },
  { value: 7, label: "$100M+" },
];

const CreateProfile = () => {
  const { emitEvent } = useAuthContext();
  const validationSchema = yup.object({
    ventureCapital: yup.boolean(),
    secondaries: yup.boolean(),
    growth: yup.boolean(),
    privateDebt: yup.boolean(),
    buyout: yup.boolean(),
    naturalResources: yup.boolean(),
    infrastructure: yup.boolean(),
    realEstate: yup.boolean(),
    fundOfFunds: yup
      .boolean()
      .test(
        "oneOfRequired",
        "Please select at least one",
        (value, testContext) =>
          Boolean(
            testContext.parent.ventureCapital ||
              testContext.parent.secondaries ||
              testContext.parent.growth ||
              testContext.parent.privateDebt ||
              testContext.parent.buyout ||
              testContext.parent.naturalResources ||
              testContext.parent.infrastructure ||
              testContext.parent.realEstate ||
              testContext.parent.fundOfFunds,
          ),
      ),
  });
  const formik = useFormik({
    initialValues: {
      ventureCapital: false,
      secondaries: false,
      growth: false,
      privateDebt: false,
      buyout: false,
      naturalResources: false,
      infrastructure: false,
      realEstate: false,
      fundOfFunds: false,
    },
    validationSchema,
    onSubmit: () => {
      emitEvent!(
        new SelectPreferencesFormSubmitted({
          regForm: {
            interestedIn: [
              ...Object.keys(formik.values)
                .map((key) => {
                  if (key === "ventureCapital" && formik.values[key]) {
                    return RegAssetClassPref.VentureCapital;
                  } else if (key === "secondaries" && formik.values[key]) {
                    return RegAssetClassPref.Secondaries;
                  } else if (key === "growth" && formik.values[key]) {
                    return RegAssetClassPref.Growth;
                  } else if (key === "privateDebt" && formik.values[key]) {
                    return RegAssetClassPref.PrivateDebt;
                  } else if (key === "buyout" && formik.values[key]) {
                    return RegAssetClassPref.Buyout;
                  } else if (key === "naturalResources" && formik.values[key]) {
                    return RegAssetClassPref.NaturalResources;
                  } else if (key === "infrastructure" && formik.values[key]) {
                    return RegAssetClassPref.Infrastructure;
                  } else if (key === "realEstate" && formik.values[key]) {
                    return RegAssetClassPref.RealEstate;
                  } else if (key === "fundOfFunds" && formik.values[key]) {
                    return RegAssetClassPref.FundOfFunds;
                  } else {
                    return RegAssetClassPref.Unknown;
                  }
                })
                .filter((pref) => pref !== RegAssetClassPref.Unknown),
            ],
            investmentSizeRange: {
              start: rangeValues[0],
              end: rangeValues[1],
            },
          },
        }),
      );
    },
  });
  const [rangeValues, setRangeValues] = React.useState([2, 5]);

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-8">
      <div>
        <p className="mb-4 font-medium">
          What geographies do you invest in? What asset classes do your firm
          invest in?
        </p>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack>
            <FormikCheckbox
              name="ventureCapital"
              label={
                <Typography variant="body2" color="textPrimary">
                  Venture Capital
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="growth"
              label={
                <Typography variant="body2" color="textPrimary">
                  Growth
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="buyout"
              label={
                <Typography variant="body2" color="textPrimary">
                  Buyout
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="infrastructure"
              label={
                <Typography variant="body2" color="textPrimary">
                  Infrastructure
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="fundOfFunds"
              label={
                <Typography variant="body2" color="textPrimary">
                  Fund of Funds
                </Typography>
              }
              formik={formik}
            />
          </Stack>
          <Stack pl={2}>
            <FormikCheckbox
              name="secondaries"
              label={
                <Typography variant="body2" color="textPrimary">
                  Secondaries
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="privateDebt"
              label={
                <Typography variant="body2" color="textPrimary">
                  Private Debt
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="naturalResources"
              label={
                <Typography variant="body2" color="textPrimary">
                  Natural Resources
                </Typography>
              }
              formik={formik}
            />
            <FormikCheckbox
              name="realEstate"
              label={
                <Typography variant="body2" color="textPrimary">
                  Real Estate
                </Typography>
              }
              formik={formik}
            />
          </Stack>
        </Stack>
      </div>

      <div className="block py-4 h-28">
        <Typography variant="body1" color="#21272D" mb={2}>
          What is your firm’s typical investment size range?
        </Typography>
        <div className="px-4">
          <Range
            values={rangeValues}
            setValues={setRangeValues}
            data={RANGE_DATA}
          />
        </div>
      </div>
      <Button
        type={BUTTON_TYPES.SECONDARY}
        size="large"
        onClick={formik.submitForm}
        icon={<ArrowRight />}
        iconRight
        fullWidth
      >
        Continue
      </Button>
    </form>
  );
};

export default CreateProfile;
