import { Bid } from "experiences/indications-of-interest/domain/models/IOI";
import { useIOIsContext } from "experiences/indications-of-interest/presentation/state/IOIContext";
import { IOIsLoaded } from "../../state/IOIState";
import { IIssuer, IssuerType } from "common/@types/models/Issuer";
import { useSearchParams } from "react-router-dom";

export const PRELOAD_QUERY_PARAM = "reconfirm";

// Sorts the bids by manager name
// This is not required for the functionality of the component but is a nice to have
// Could be skipped if we want to
const sortIoisByManager = (iois: Bid[]) => {
  return iois.sort((a, b) => {
    if (a.managerName < b.managerName) {
      return -1;
    }
    if (a.managerName > b.managerName) {
      return 1;
    }
    return 0;
  });
};

export const usePreloadBids = () => {
  const { bidsState } = useIOIsContext();
  const [query] = useSearchParams();
  const isReconfirm = query.has(PRELOAD_QUERY_PARAM);

  const preloadedIois =
    bidsState instanceof IOIsLoaded
      ? sortIoisByManager(bidsState.iois as Bid[])
      : [];

  // Convert the bids to the format that the table expects
  const bids = preloadedIois.map((bid) => {
    return [
      bid.issuerName,
      bid.minAmount, // todo: add unformat
      bid.maxAmount, // todo: add unformat
      (bid.minPrice && bid.minPrice / 100) || undefined, // todo: add unformat
      (bid.maxPrice && bid.maxPrice / 100) || undefined, // todo: add unformat
      bid.referenceDate, // todo: add unformat
      bid.expirationDate, // todo: add unformat
    ];
  });

  const preloadedIssuers: IIssuer[] = preloadedIois.map((bid) => {
    return {
      name: bid.issuerName,
      id: bid.fundId,
      type: IssuerType.Fund,
    };
  });

  return {
    bids,
    isReconfirm,
    preloadedIssuers,
  };
};
