import { Box, InputLabel, Typography } from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ArrowRight } from "@phosphor-icons/react";

import { BUTTON_TYPES, Button } from "common/components/Button";
import { IOSSwitch } from "common/components/IOSSwitch";
import { SelectYourOrganizationFormSubmitted } from "../../state/AuthenticationEvent";
import { useAuthContext } from "../../state/AuthenticationContext";
import { RegMarketEngagement } from "../../../domain/models/RegMarketEngagement";
import { OrganizationSelector } from "../../components/OrganizationsSelector";
import {
  CreateOrganizationForm,
  ICreateOrganizationForm,
  IOrganization,
  Organization,
  OrganizationType,
} from "experiences/authentication/domain/models/Organization";

interface IProps {
  preSelectedOrg?: IOrganization;
  fullName: string;
}

export const SelectYourOrganizationStep: React.FC<IProps> = ({
  preSelectedOrg,
  fullName,
}) => {
  const { emitEvent } = useAuthContext();
  const [isOrganizationMember, setIsOrganizationMember] =
    useState<boolean>(true);
  const validationSchema = yup.object({
    firm: yup.lazy((value) =>
      isOrganizationMember
        ? value instanceof Organization
          ? yup
              .object({
                id: yup.string().required("Required"),
                name: yup.string().required("Required"),
                type: yup.number().required("Required"),
              })
              .required("Required")
          : value instanceof CreateOrganizationForm
            ? yup
                .object({
                  name: yup.string().required("Required"),
                  type: yup.number().required("Required"),
                })
                .required("Required")
            : yup.string().required("Required")
        : yup.string().notRequired(),
    ),
  });

  interface IFormValues {
    firm: IOrganization | ICreateOrganizationForm | undefined;
    buying: boolean;
    selling: boolean;
  }

  const formik = useFormik<IFormValues>({
    initialValues: {
      firm: preSelectedOrg,
      buying: false,
      selling: false,
    },
    validationSchema,
    onSubmit: (values) => {
      let firm = Boolean(preSelectedOrg)
        ? preSelectedOrg
        : isOrganizationMember
          ? values.firm
          : {
              name: `${fullName}'s Personal Organization`,
              type: OrganizationType.PERSONAL_INVESTOR,
            };

      emitEvent!(
        new SelectYourOrganizationFormSubmitted({
          regForm: {
            isFirmMember: isOrganizationMember,
            firmId: firm instanceof Organization ? firm.id : "",
            firmName: firm.name,
            firmType: firm.type,
            marketEngagement: [
              ...Object.keys(formik.values)
                .map((key) => {
                  if (key === "buying" && formik.values[key]) {
                    return RegMarketEngagement.Buying;
                  } else if (key === "selling" && formik.values[key]) {
                    return RegMarketEngagement.Selling;
                  } else {
                    return RegMarketEngagement.Unknown;
                  }
                })
                .filter((pref) => pref !== RegMarketEngagement.Unknown),
            ],
          },
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="min-w-full">
      <div className="w-full flex flex-col gap-8 min-w-full">
        <div className="flex items-center gap-4 min-w-full">
          <IOSSwitch
            disabled={Boolean(preSelectedOrg)}
            defaultChecked={isOrganizationMember}
            onChange={(_, checked) => {
              setIsOrganizationMember(checked);
            }}
          />
          <Typography>I am investing as part of an organization.</Typography>
        </div>

        {isOrganizationMember ? (
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="firmName"
              sx={{ marginBottom: 1 }}
            >
              <Typography variant="body1" color="#21272D" display="inline">
                Organization Name
              </Typography>
            </InputLabel>
            <OrganizationSelector
              preSelectedOrg={preSelectedOrg}
              onFormFieldChange={(value) => formik.setFieldValue("firm", value)}
              errorMessage={
                formik.touched["firm"] && formik.errors["firm"]
                  ? String(formik.errors["firm"])
                  : undefined
              }
              placeholder="Select your organization"
            />
          </Box>
        ) : null}

        <Button
          type={BUTTON_TYPES.SECONDARY}
          size="large"
          onClick={formik.submitForm}
          icon={<ArrowRight />}
          iconRight
          className="mt-12"
          fullWidth
        >
          Continue
        </Button>
      </div>
    </form>
  );
};
