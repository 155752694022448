import React from "react";
import { cn } from "common/utils";

export interface DescriptionListEntry {
  label: string | number;
  value?: string | number | React.ReactNode | React.ReactNode[] | null;
}

export const DescriptionList = ({
  items,
  className,
  entryClassName,
  labelClassName,
  valueClassName,
}: {
  items: DescriptionListEntry[];
  className?: string;
  entryClassName?: string;
  labelClassName?: string;
  valueClassName?: string;
}) => {
  return (
    <dl className={cn("flex flex-col", className)}>
      {items.map((item, index) => {
        const isLabelNode = typeof item.label === "object";
        const isValueNode = typeof item.value === "object";
        return (
          <div
            key={index}
            className={cn(
              "flex flex-row text-sm w-full justify-between gap-4 min-h-11 px-4 items-center cursor-default border-b last:border-none",
              entryClassName,
            )}
          >
            <dt
              className={cn(
                "text-neutral-500 font-normal w-fit text-left",
                labelClassName,
                isLabelNode ? "py-2" : "py-3",
              )}
            >
              {item.label}
            </dt>
            <dd
              className={cn(
                "text-neutral-900 font-medium w-fit text-right",
                valueClassName,
                isValueNode ? "py-2" : "py-3",
              )}
            >
              {item.value}
            </dd>
          </div>
        );
      })}
    </dl>
  );
};
