import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { IIssuer, IssuerType } from "common/@types/models/Issuer";
import { usePortfolioContext } from "../../state/PortfolioContext";
import { GetHoldingsProcessRequested } from "../../state/PortfolioEvent";
import {
  HoldingProcessStateLoaded,
  HoldingProcessStateLoading,
} from "../../state/PortfolioState";

export const PRELOAD_QUERY_PARAM = "reconfirm";
export const PROCESS_QUERY_PARAM = "process";

export const usePreloadHoldings = ({
  hasLpColumn,
}: {
  hasLpColumn?: boolean;
}) => {
  const [query] = useSearchParams();
  const isReconfirm = query.has(PRELOAD_QUERY_PARAM);
  const processId = query.get(PROCESS_QUERY_PARAM);
  const { emitEvent, holdingsProcessState } = usePortfolioContext();

  useEffect(() => {
    if (isReconfirm) {
      emitEvent(
        new GetHoldingsProcessRequested({
          id: processId,
        }),
      );
    }
  }, [isReconfirm]);

  const isLoading =
    holdingsProcessState instanceof HoldingProcessStateLoading && isReconfirm;

  const preloadedHoldings =
    holdingsProcessState instanceof HoldingProcessStateLoaded
      ? holdingsProcessState.holdings
      : [];

  // Convert the holdings to the format that the table expects
  const holdings = preloadedHoldings.map((holding) => {
    if (hasLpColumn) {
      return [
        holding.fundName,
        holding.lpName,
        (holding.nav && holding.nav) || undefined,
        (holding.committed && holding.committed) || undefined,
        (holding.contributed && holding.contributed) || undefined,
        (holding.distributed && holding.distributed) || undefined,
        holding.referenceDate,
      ];
    }

    return [
      holding.fundName,
      (holding.nav && holding.nav) || undefined,
      (holding.committed && holding.committed) || undefined,
      (holding.contributed && holding.contributed) || undefined,
      (holding.distributed && holding.distributed) || undefined,
      holding.referenceDate,
    ];
  });

  const preloadedIssuers: IIssuer[] = preloadedHoldings.map((holding) => {
    return {
      name: holding.fundName,
      id: undefined, // TODO: find a way to get the real id???
      type: IssuerType.Fund,
    };
  });

  return {
    isLoading,
    holdings,
    isReconfirm,
    preloadedIssuers,
  };
};
