import { useEffect } from "react";
import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";

import {
  Route,
  Routes,
  generatePath,
  useNavigate,
  useParams,
} from "react-router";
import { useTransactionsContext } from "../state/TransactionsContext";
import { LoadTransactionDetails } from "../state/TransactionsEvent";
import { TransactionDetailsScreenLoaded } from "../state/TransactionsState";
import {
  ITransactionSummary,
  TransactionStatus,
} from "../../domain/models/Transaction";
import { GpTransactionDetailRoutes, GP_ROUTES } from "common/constants/routes";
import { APP_BAR_TRANSACTION_NAME_NODE } from "common/constants/assets";
import { SidebarSideMenu } from "experiences/dashboard/presentation/components/SidebarMenu";
import {
  ParticipantCapacity,
  ParticipantRole,
} from "experiences/transactions/domain/models/Participant";
import { GpTransactionParticipants } from "./GpTransactionParticipants";
import { GpTransactionOverview } from "./GpTransactionOverview";
import { GpTransactionMonitor } from "./GpTransactionMonitor";
import {
  LayoutWrapper,
  MainContent,
  Sidebar,
} from "common/components/MainSidebarLayout";
import { GpTransactionDataRoom } from "./GpTransactionDataroom";
import { TransactionContact } from "experiences/common/TransactionContact";

export const DEMO_TXN_ID = "demo";

const TransactionsMenu = [
  {
    label: "Overview",
    path: GpTransactionDetailRoutes.Overview,
  },
  {
    label: "Transaction Monitor",
    path: `${GpTransactionDetailRoutes.TransactionMonitor}`,
  },
  {
    label: "Data Room",
    path: GpTransactionDetailRoutes.DataRoom,
  },
  {
    label: "Participants",
    path: GpTransactionDetailRoutes.Participants,
  },
];

const quickDemo: ITransactionSummary = {
  transaction: {
    id: "demo",
    name: "Fund III Q2 2024 Tender",
    tenderOfferQuarter: "2024 Q2",
    referenceDateQuarter: "2023 Q4",
    isRecurringTenderOffer: true,
    currentStatus: TransactionStatus.Live,
    image: "ViewpointPartners.jpg",
  },
  holdingsCount: 12,
  committedTotal: 1000000,
  nav: 1000000,
  dataroom: {
    details: {
      name: "Demo Transaction",
      path: "/demo",
      transactionId: "demo",
    },
    directory: {
      name: "Demo Transaction",
      path: "/demo",
      size: "1.2GB",
      children: [],
    },
  },
  percentCalled: 33,
  viewerParticipant: {
    id: "demo",
    capacity: ParticipantCapacity.Buyer,
    role: ParticipantRole.Principal,
    userId: "demo",
    firstName: "Demo",
    lastName: "User",
    email: "123@demo.com",
  },
};

const contact = {
  name: "Michael Blanchet",
  title: "Advisor at Tap",
  avatar: "https://cdn.usetap.com/funds/logos/MichaelBlanchet.jpg",
};

export const GpTransactionDetailPage = () => {
  const { transactionId } = useParams();
  const { emitEvent, txnDetailsState } = useTransactionsContext();
  const navigate = useNavigate();

  const transactionSummary =
    txnDetailsState instanceof TransactionDetailsScreenLoaded
      ? txnDetailsState.summary
      : quickDemo;

  useEffect(() => {
    emitEvent!(new LoadTransactionDetails({ txnId: transactionId! }));
  }, []);

  if (!transactionSummary) return null;

  if (!transactionId) navigate(GP_ROUTES.Dashboard);

  const baseRoute = generatePath(GP_ROUTES.TransactionDetail, {
    transactionId,
  });

  const transactionNameElement = document.getElementById(
    APP_BAR_TRANSACTION_NAME_NODE,
  );

  return (
    <LayoutWrapper key={transactionId}>
      {transactionSummary.transaction.name && (
        <Helmet>
          <title>{transactionSummary.transaction.name}</title>
        </Helmet>
      )}
      <Sidebar
        title={transactionSummary.transaction.name}
        image={transactionSummary.transaction.image}
      >
        <SidebarSideMenu
          menu={TransactionsMenu}
          baseRoute={baseRoute}
          isFundSidebar
          contact={<TransactionContact />}
        />
      </Sidebar>
      <MainContent>
        <Routes>
          <Route
            path={GpTransactionDetailRoutes.Overview}
            element={<GpTransactionOverview />}
          />
          <Route
            path={`${GpTransactionDetailRoutes.TransactionMonitor}/*`}
            element={<GpTransactionMonitor />}
          />
          <Route
            path={`${GpTransactionDetailRoutes.DataRoom}/*`}
            element={<GpTransactionDataRoom />}
          />
          <Route
            path={`${GpTransactionDetailRoutes.Participants}/*`}
            element={<GpTransactionParticipants />}
          />
          <Route
            path={GpTransactionDetailRoutes.Settlement}
            element={<>settlement</>}
          />
        </Routes>
      </MainContent>
      {transactionNameElement &&
        createPortal(
          transactionSummary.transaction.name,
          transactionNameElement,
        )}
    </LayoutWrapper>
  );
};
