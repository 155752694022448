import { useEffect } from "react";

interface PandaDocEmbedViewProps {
  embedLink: string;
  onSignatureComplete?: () => void;
}

const PandaDocEmbedView = ({
  embedLink,
  onSignatureComplete,
}: PandaDocEmbedViewProps) => {
  useEffect(() => {
    const messageHandler = (event) => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;

      switch (type) {
        case "session_view.document.loaded":
          break;
        case "session_view.document.completed":
          onSignatureComplete();
          break;
        case "session_view.document.exception":
          break;
      }
    };

    window.addEventListener("message", messageHandler);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return <iframe src={embedLink} width="100%" height="100%"></iframe>;
};

export default PandaDocEmbedView;
