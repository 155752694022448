import React from "react";
import { Stack } from "@mui/material";

import { usePortfolioContext } from "../../state/PortfolioContext";
import { ConfirmHoldingsDetailsContinueClicked } from "../../state/PortfolioEvent";
import { IHolding } from "../../../domain/models/Holding";
import { DateFormatter } from "../../../../../common/@types/app/DateFormatter";
import { DollarAmount } from "../../../../../common/@types/app/DollarAmount";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { StyledList } from "experiences/common/List";

interface IProps {
  holdings: IHolding[];
}

const ConfirmHoldingsDetailsStep: React.FC<IProps> = ({ holdings }) => {
  const { emitEvent } = usePortfolioContext();
  return (
    <Stack p={8}>
      <Stack maxWidth={660}>
        <PageHead
          title="Confirm Holdings Details"
          description="Review the information of the funds you want to sell below. If
            anything is incorrect or incomplete this data can be edited on the
            Portfolio page."
          paddingless
          descriptionStyle={{ marginBottom: 48 }}
        />
        <StyledList>
          <table className={`dashboard_table dont_hover`}>
            <thead>
              <tr>
                <th>name</th>
                <th>net asset value</th>
                <th>reference date</th>
              </tr>
            </thead>
            <tbody>
              {holdings.map((holding: IHolding) => {
                return (
                  <tr key={holding.id}>
                    <td style={{ minWidth: 400 }}>{holding.issuer.name}</td>
                    <td>
                      {new DollarAmount(holding.bookValue).formattedBig()}{" "}
                    </td>
                    <td>
                      {new DateFormatter(
                        holding.referenceDate,
                      ).quarterFormatted()}{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </StyledList>

        <Button
          fullWidth
          type={BUTTON_TYPES.SECONDARY}
          size="large"
          onClick={() => {
            emitEvent!(new ConfirmHoldingsDetailsContinueClicked());
          }}
          style={{ marginTop: 64 }}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export default ConfirmHoldingsDetailsStep;
