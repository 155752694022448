import React, { useEffect, useState } from "react";
import {
  Autocomplete as MuiAutocomplete,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";

import TextField from "./TextField";
import { Option } from "../@types/common";
import { FUND_LOGO_URL } from "common/constants/platform";
import TextChip from "experiences/common/TextChip";
import { OrganizationTypeLabels } from "experiences/authentication/domain/models/Organization";
import Highlighted from "./Highlighted";

interface IProps {
  label: string;
  options: Option[];
  onChange: (value: string | Option) => void;
  onInputChange?: (value: string) => void;
  filterOptions?: (
    options: Option[],
    state: FilterOptionsState<Option>,
  ) => Option[];
  value?: string;
  name?: string;
  disabled?: boolean;
  inputValue?: string;
  error?: string;
}

const filter = createFilterOptions<Option>();

const Autocomplete: React.FC<IProps> = ({
  label,
  options,
  onChange,
  onInputChange,
  filterOptions,
  value,
  inputValue,
  disabled = false,
  ...props
}) => {
  const [inputText, setInputText] = useState<string>("");
  const optionByValue: Record<string, Option> = {};
  options.forEach((option) => {
    optionByValue[option.value] = option;
  });

  useEffect(() => {
    if (optionByValue["new"]) {
      onChange(optionByValue["new"].label);
    }
  }, []);

  return (
    <MuiAutocomplete
      id={label}
      disabled={disabled}
      inputValue={inputValue}
      value={optionByValue[value] ?? null}
      onChange={(_, selectedOption) => {
        const selected = selectedOption?.label.startsWith("Add")
          ? selectedOption?.label
          : selectedOption?.value;
        onChange(selected);
      }}
      filterOptions={
        filterOptions
          ? filterOptions
          : (options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) =>
                option.label?.toLowerCase().includes(inputValue.toLowerCase()),
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }
      }
      onClose={() => setInputText("")}
      options={options}
      renderOption={(props, option: Option) => (
        <li style={{ display: "flex", alignItems: "center" }} {...props}>
          {option?.img ? (
            <img
              src={`${FUND_LOGO_URL}/${option?.img}.jpg`}
              style={{
                marginRight: 8,
                width: 32,
                height: 32,
                borderRadius: 10,
              }}
            />
          ) : null}
          <Highlighted text={option.label} highlight={inputText} />
          {option?.type ? (
            <TextChip
              style={{ marginLeft: 8 }}
              text={OrganizationTypeLabels[option?.type]}
            />
          ) : null}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          label={label}
          onChange={
            onInputChange
              ? (e: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange(e.target.value);
                  setInputText(e.target.value);
                }
              : null
          }
        />
      )}
      {...props}
    />
  );
};

export default Autocomplete;
