import { useState } from "react";
import { useNavigate } from "react-router";

import { VIEWPOINT_PARTNERS_DEMO_NAMES } from "../components/viewpoint/viewpointOverview";
import { Fund } from "experiences/funds/domain/models/Fund";
import { LP_ROUTES } from "common/constants/routes";

export const useFundPrivacy = ({ fund }: { fund?: Fund }) => {
  const isViewpoint = VIEWPOINT_PARTNERS_DEMO_NAMES.includes(fund?.name || "");
  const hideAll = !isViewpoint;

  const [unlockPriceOverview, setUnlockPriceOverview] = useState(hideAll);
  const [unlockBasicInformation, setUnlockBasicInformation] = useState(hideAll);
  const [unlockPriceHistory, setUnlockPriceHistory] = useState(hideAll);
  const [unlockIndicationsOfInterest, setUnlockIndicationsOfInterest] =
    useState(hideAll);
  const [unlockPerformanceChart, setUnlockPerformanceChart] = useState(hideAll);
  const [unlockInvestments, setUnlockInvestments] = useState(hideAll);
  const [unlockRelatedParties, setUnlockRelatedParties] = useState(hideAll);

  const navigate = useNavigate();

  const handleUnlockPriceOverview = () => {
    setUnlockPriceOverview(!unlockPriceOverview);
  };

  const handleUnlockBasicInformation = () => {
    setUnlockBasicInformation(!unlockBasicInformation);
  };

  const handleUnlockPriceHistory = () => {
    setUnlockPriceHistory(!unlockPriceHistory);
  };

  const handleUnlockIndicationsOfInterest = () => {
    setUnlockIndicationsOfInterest(!unlockIndicationsOfInterest);
  };

  const handleUnlockPerformanceChart = () => {
    setUnlockPerformanceChart(!unlockPerformanceChart);
  };

  const handleUnlockInvestments = () => {
    setUnlockInvestments(!unlockInvestments);
  };

  const handleUnlockRelatedParties = () => {
    setUnlockRelatedParties(!unlockRelatedParties);
  };

  const handleVerifyPermissionClick = () => {
    navigate(LP_ROUTES.PortfolioConnectPortfolio);
  };

  return {
    unlockPriceOverview,
    handleUnlockPriceOverview,
    unlockBasicInformation,
    handleUnlockBasicInformation,
    unlockPriceHistory,
    handleUnlockPriceHistory,
    unlockIndicationsOfInterest,
    handleUnlockIndicationsOfInterest,
    unlockPerformanceChart,
    handleUnlockPerformanceChart,
    unlockInvestments,
    handleUnlockInvestments,
    unlockRelatedParties,
    handleUnlockRelatedParties,
    isViewpoint,
    handleVerifyPermissionClick,
  };
};
