import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { TransactionNDA } from "experiences/transactions/domain/models/TransactionNDA";
import { Button } from "common/components/Button";
import { Download } from "@phosphor-icons/react";

export const TransactionNDATable = ({
  transactionNDAs,
}: {
  transactionNDAs: TransactionNDA[];
}) => {
  return (
    <>
      <TapTable
        data={transactionNDAs}
        emptyStateTitle={
          !transactionNDAs.length
            ? "There are no transactions to display."
            : null
        }
        columns={[
          {
            key: "documentName",
            label: "Document Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "funds",
            label: "Funds",
            align: TableAlignment.LEFT,
          },
          {
            key: "signatures",
            label: "Signatures",
            align: TableAlignment.LEFT,
          },
          {
            key: "status",
            label: "Status",
            align: TableAlignment.LEFT,
          },
          {
            key: "actions",
            label: "Actions",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(document: TransactionNDA) => {
          return (
            <>
              <TableCell isPrimaryColumn alignTop>
                {document.ndaName}
              </TableCell>
              <TableCell>{document.funds}</TableCell>
              <TableCell>{document.signatures}</TableCell>
              <TableCell>
                <TextChip
                  text={document.status}
                  success={document.status.toLocaleLowerCase() === "active"}
                  style={{ marginLeft: 8 }}
                />
              </TableCell>
              <TableCell>
                <a href="https://tap-public-assets.s3.amazonaws.com/Tap+-+Mutual+NDA+Form+-+2023.11.docx">
                  <Button icon={<Download />}>Download</Button>
                </a>
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
