import { useEffect, useState } from "react";
import { match } from "fp-ts/lib/Either";

import { FundSummary } from "experiences/funds/domain/models/FundSummary";
import { RetrieveSingleFundSummary } from "experiences/funds/domain/usecases/RetrieveSingleFundSummary";
import { Failure } from "common/@types/app/Failure";
import { VIEWPOINT_PARTNERS_DEMO_NAMES } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";
import { VIEWPOINT_PARTNERS_INDICATIONS_OF_INTEREST } from "../components/viewpoint/viewpointIndicationsOfInterest";

export const useViewFundSummary = ({ fundId }: { fundId?: string }) => {
  const [summary, setSummary] = useState<FundSummary>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | Failure>();
  const fundIois = summary?.iois || [];

  const iois = !loading ? fundIois : [];

  const getSummary = () => {
    setLoading(true);

    if (!fundId) return;

    new RetrieveSingleFundSummary().call({ fundId }).then((resp) => {
      match<Failure, FundSummary, void>(
        (error: Failure) => {
          setLoading(false);
          setError(error);
        },
        (summary: FundSummary) => {
          setSummary(summary);

          // REPLACE WITH VIEWPOINT DEMO DATA IF FUND IS A DEMO FUND
          if (VIEWPOINT_PARTNERS_DEMO_NAMES.includes(summary.name)) {
            const newIois = [
              ...summary.iois,
              ...VIEWPOINT_PARTNERS_INDICATIONS_OF_INTEREST,
            ];
            setSummary({
              ...summary,
              iois: newIois,
            });
            setLoading(false);
            return;
          }

          setLoading(false);
        },
      )(resp);
    });
  };

  useEffect(() => {
    if (fundId) {
      getSummary();
    }
  }, [fundId]);

  const refreshSummary = () => {
    getSummary();
  };

  return {
    summary,
    iois,
    loading,
    error,
    refreshSummary,
  };
};
