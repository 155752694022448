import { Box, Modal, Stack } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import FormikTextField from "../../../../common/components/formik/FormikTextField";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";
import { InviteSignatoryCompleted } from "../state/DocumentsEvent";
import { useDocumentsContext } from "../state/DocumentsContext";

export const InviteSignatoryModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { emitEvent } = useDocumentsContext();

  const signatoryValidationSchema = yup.object({
    signatoryFirstName: yup.string().required("Required"),
    signatoryLastName: yup.string().required("Required"),
    signatoryEmail: yup.string().email("Invalid email").required("Required"),
  });

  const signatoryForm = useFormik({
    initialValues: {
      signatoryFirstName: "",
      signatoryLastName: "",
      signatoryEmail: "",
    },
    onSubmit: (values) => {
      emitEvent!(
        new InviteSignatoryCompleted({
          signatoryEmail: values.signatoryEmail,
          signatoryFirstName: values.signatoryFirstName,
          signatoryLastName: values.signatoryLastName,
        }),
      );
      handleClose();
    },
    validationSchema: signatoryValidationSchema,
  });

  const handleClose = () => {
    onClose();
    signatoryForm.resetForm();
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={signatoryForm.submitForm}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <Box>
              <TapUiModalHead
                title="Invite new signatory"
                subtitle="Add the email of the person at your organization who can sign the document."
                onCloseClick={handleClose}
              />
            </Box>

            <Stack direction="row" spacing={2}>
              <FormikTextField
                name="signatoryFirstName"
                formik={signatoryForm}
                label="Signatory First Name"
                fullWidth
                placeholder="Jane"
              />
              <FormikTextField
                name="signatoryLastName"
                formik={signatoryForm}
                label="Signatory Last Name"
                fullWidth
                placeholder="Doe"
              />
            </Stack>

            <FormikTextField
              name="signatoryEmail"
              formik={signatoryForm}
              label="Signatory Email"
              fullWidth
              placeholder="first.last@company.com"
            />

            <Button
              onClick={signatoryForm.submitForm}
              fullWidth
              type={BUTTON_TYPES.SECONDARY}
              size="large"
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
