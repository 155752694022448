import styled from "styled-components";
import { FilePdf } from "@phosphor-icons/react";

import { FileDownloadBox } from "../../FileDownloadBox";

export type TeaserFile = {
  name: string;
  size: number;
  url: string;
  type: string;
};

const StyledTeaserWrapper = styled.div`
  display: block;
  padding: 12px 32px;
`;

export const ProjectTeaser = ({
  files,
  style,
}: {
  files: TeaserFile[];
  style?: React.CSSProperties;
}) => {
  return (
    <StyledTeaserWrapper style={style}>
      {files.map((file) => {
        const isPdf = file.type === "application/pdf";
        return (
          <FileDownloadBox
            title={file.name}
            downloadLink={file.url}
            subtitle={`${file.size} KB`}
            icon={isPdf && <FilePdf size={32} weight="light" />}
          />
        );
      })}
    </StyledTeaserWrapper>
  );
};
