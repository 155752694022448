import { CreateLPPortalConnection } from "experiences/portfolio/domain/usecases/CreateLPPortalConnection";
import { GetHoldingProcess } from "experiences/portfolio/domain/usecases/GetHoldingProcess";
import { UploadCapitalAccounts } from "experiences/portfolio/domain/usecases/UploadCapitalAccounts";
import { UploadHoldingsList } from "experiences/portfolio/domain/usecases/UploadHoldingsList";
import { createContext, useContext } from "react";
import { CreateHolding } from "../../domain/usecases/CreateHolding";
import { CreateTransaction } from "../../domain/usecases/CreateTransaction";
import { GetPortfolio } from "../../domain/usecases/GetPortfolio";
import { PortfolioEvent } from "./PortfolioEvent";
import { useManagePortfolioState } from "./PortfolioManager";
import {
  HoldingProcessStateLoaded,
  PortfolioState,
  SellInterestFlowState,
} from "./PortfolioState";

export interface PortfolioContextProps {
  sellInterestFlowState?: SellInterestFlowState;
  portfolioScreenState?: PortfolioState;
  holdingsProcessState?: HoldingProcessStateLoaded;
  emitEvent?: (event: PortfolioEvent) => void;
}

const initialContextState: PortfolioContextProps = {};

const PortfolioContext = createContext(initialContextState);

interface IPortfolioStateProviderProps {
  children: React.ReactNode;
}

const PortfolioStateProvider = ({ children }: IPortfolioStateProviderProps) => {
  const {
    sellInterestFlowState,
    emitEvent,
    portfolioScreenState,
    holdingsProcessState,
  } = useManagePortfolioState({
    submitHoldingSellRequest: new CreateTransaction(),
    createHolding: new CreateHolding(),
    getPortfolio: new GetPortfolio(),
    createLPPortalConnection: new CreateLPPortalConnection(),
    uploadHoldingList: new UploadHoldingsList(),
    uploadCapitalAccounts: new UploadCapitalAccounts(),
    getHoldingsProcess: new GetHoldingProcess(),
  });

  return (
    <PortfolioContext.Provider
      value={{
        sellInterestFlowState: sellInterestFlowState,
        portfolioScreenState: portfolioScreenState,
        holdingsProcessState: holdingsProcessState,
        emitEvent: emitEvent,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolioContext = () => useContext(PortfolioContext);

export { PortfolioContext, PortfolioStateProvider };
