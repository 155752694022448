import {
  LP_ROUTES,
  LPTransactionDataRoomRoutes,
} from "common/constants/routes";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";
import DataRoom from "../components/data-room/DataRoom";
import { GpTransactionDataRoomDataPage } from "../gp/dataroom/DataPage";
import { IDataRoom } from "experiences/transactions/domain/models/DataRoom";

interface IProps {
  dataRoom: IDataRoom;
}

export const LPDataRoom: React.FC<IProps> = ({ dataRoom }) => {
  const baseRoute = LP_ROUTES.TenderDetailsDataRoom;
  const { txnId } = useParams();

  return (
    <>
      <Routes>
        <Route
          path={LPTransactionDataRoomRoutes.Data}
          element={<GpTransactionDataRoomDataPage />}
        />
        <Route
          path={LPTransactionDataRoomRoutes.Documents}
          element={<DataRoom dataRoom={dataRoom} />}
        />
        <Route
          path="/*"
          element={
            <Navigate
              to={`${generatePath(
                `${baseRoute}${LPTransactionDataRoomRoutes.Data}`,
                { txnId },
              )}`}
            />
          }
        />
      </Routes>
    </>
  );
};
