import { useContext } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes } from "react-router";

import { ContentWrapper } from "common/components/WidthWrapper";
import { PageWrapper } from "experiences/common/PageWrapper";
import {
  PortfolioV2DefaultRoute,
  PortfolioV2Routes,
} from "experiences/portfolio-v2/presentation/components/navigation";
import {
  PortfolioContext,
  PortfolioProvider,
} from "../state/PorfolioV2Context";

// Pages
import { CashflowsPage } from "./CashflowsPage";
import { CommentaryPage } from "./CommentaryPage";
import { DocumentsPage } from "./DocumentsPage";
import { LookthroughSoiPage } from "./LookthroughSoiPage";
import { PerformancePage } from "./PerformancePage";
import { SummaryPage } from "./SummaryPage";
import { UploadDocumentsPage } from "./UploadDocumentsPage";

export const PortfolioPageV2 = () => {
  const { portfolioBaseRoute } = useContext<{ portfolioBaseRoute: string }>(
    PortfolioContext,
  );

  // prettier-ignore
  return (
    <ContentWrapper fullWidth>
      <PortfolioProvider>
      <PageWrapper>
        <Helmet title="Portfolio" />
          <Routes>
            <Route path={PortfolioV2Routes.Summary} element={<SummaryPage />} />
            <Route path={PortfolioV2Routes.Performance} element={<PerformancePage />} />
            <Route path={PortfolioV2Routes.Investments} element={<LookthroughSoiPage />} />
            <Route path={PortfolioV2Routes.Documents} element={<DocumentsPage />} />
            <Route path={PortfolioV2Routes.Cashflows} element={<CashflowsPage />} />
            <Route path={PortfolioV2Routes.Commentary} element={<CommentaryPage />} />
            <Route path={PortfolioV2Routes.UploadDocuments} element={<UploadDocumentsPage />} />
            <Route path="*" element={<Navigate to={`${portfolioBaseRoute}${PortfolioV2DefaultRoute}`} replace />} />
        </Routes>
      </PageWrapper>
      </PortfolioProvider>
    </ContentWrapper>
  );
};
