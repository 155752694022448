import { IBidDocument } from "experiences/transactions/domain/models/Bidding";
import { useTransactionsContext } from "../../state/TransactionsContext";
import { useParams } from "react-router";

import { Box, Stack, Typography } from "@mui/material";
import { ReviewBidStepDownloadDocumentClicked } from "../../state/TransactionsEvent";
import { Button } from "common/components/Button";
import { Download } from "@phosphor-icons/react";

interface IReviewDocumentSectionProps {
  document: IBidDocument;
}

const ReviewDocumentSection: React.FC<IReviewDocumentSectionProps> = ({
  document,
}) => {
  const { emitEvent } = useTransactionsContext();
  const { txnId } = useParams();
  return (
    <Box
      maxWidth={360}
      sx={{ backgroundColor: "#F5F4F1", borderRadius: "6px" }}
    >
      <Stack p={2} height={"100%"}>
        <Typography variant="body1" fontWeight="bold">
          Letter of Intent
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            noWrap
            variant="subtitle2"
            fontWeight={400}
            sx={{
              color: "#737476",
            }}
          >
            {document.fileName}
          </Typography>
          <Button
            size="small"
            onClick={() => {
              emitEvent!(
                new ReviewBidStepDownloadDocumentClicked({
                  document: document,
                  txnId: txnId!,
                }),
              );
            }}
            icon={<Download />}
          >
            Download
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReviewDocumentSection;
