import { classNames } from "primereact/utils";
import styled from "styled-components";

import { Index } from "experiences/funds/domain/models/Index";
import { Stack } from "@mui/material";

const StyledIndex = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  & + & {
    margin-left: 20px;
  }

  .single_index {
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;
    padding-left: 16px;

    box-sizing: border-box;

    font-size: 12px;
    line-height: 1;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    user-select: none;
    white-space: nowrap;

    & + & {
      margin-left: 20px;
    }

    &-price {
      margin-left: 4px;

      &.positive-change {
        color: #425e3d;
      }

      &.negative-change {
        color: #983a43;
      }
    }
  }
`;

const StyledIndexPriceChangePill = styled.span`
  display: inline-flex;
  font-size: 9px;
  margin-left: 8px;

  padding: 3px 4px;
  border-radius: 7px;

  &.positive-change {
    color: #53b656;
    background-color: #53b65621;
  }

  &.negative-change {
    color: #ff5c5c;
    background-color: #ff000021;
  }
`;

export const IndexPriceChangePill = ({
  priceChange,
  className,
}: {
  priceChange: number;
  className?: string;
}) => {
  const positiveChange = priceChange > 0;

  return (
    <StyledIndexPriceChangePill
      className={classNames({
        "positive-change": positiveChange,
        "negative-change": !positiveChange,
        className,
      })}
    >
      {priceChange}%
    </StyledIndexPriceChangePill>
  );
};

const SingleIndex = ({ index }: { index: Index }) => {
  const mostRecentPrice = index.mostRecentPrice;
  const change = index.priceChange;

  const positiveChange = change > 0;

  return (
    <StyledIndex>
      <div className="single_index">
        <p>{index.name}</p>
        <p
          className={classNames("single_index-price", {
            "positive-change": positiveChange,
            "negative-change": !positiveChange,
          })}
        >
          {mostRecentPrice}%
        </p>
        <IndexPriceChangePill priceChange={change} />
      </div>
    </StyledIndex>
  );
};

export const Indexes = ({ indexes = [] }: { indexes?: Index[] }) => {
  // create an array of 10 times the indexes to create the infinite scroll effect
  // const allIndexes = Array.from({ length: 10 }, () => indexes).flat();

  if (indexes.length === 0) return null;

  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      width={"100%"}
      sx={{ borderBottom: "1px solid #dfdfd9" }}
    >
      {indexes.map((index, i) => {
        return <SingleIndex key={i} index={index} />;
      })}
    </Stack>
  );
};
