import { Shape } from "common/utils/yupSchema";
import { TransactionPeriodName } from "./TimelinePeriod";
import { Range } from "experiences/common/models/Range";
import * as yup from "yup";
import { ITransactionPeriod } from "experiences/transactions/domain/models/Transaction";
import { useFormik } from "formik";
import { Modal } from "@mui/material";
import {
  TapUiModalContent,
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import FormikDatePickerWithRange from "common/components/formik/FormikDatePickerWithRange";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { Switch } from "shadcn/ui/switch";
import { useState } from "react";
import classNames from "classnames";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { useTransactionsContext } from "../../state/TransactionsContext";
import { TimelineEditModalSubmitted } from "../../state/TransactionsEvent";

interface UpsertTimelineModalProps {
  existingTimeline: ITransactionPeriod[];
  open: boolean;
  onClose: () => void;
  txnId: string;
}

const UpsertTimelineModal: React.FC<UpsertTimelineModalProps> = ({
  existingTimeline,
  open,
  onClose,
  txnId,
}) => {
  const strRangeToDateRange = (strRange: Range<string>): Range<Date> => ({
    start: new Date(strRange.start),
    end: new Date(strRange.end),
  });

  const getDateRangesOfPeriod = (
    periodName: string,
    timeline: ITransactionPeriod[],
  ) => {
    const found = timeline.findLast((p) => p.name === periodName);
    if (found) {
      return strRangeToDateRange(found.period);
    }
    return;
  };

  const getExistingDateRangesOfPeriod = (periodName: string) =>
    getDateRangesOfPeriod(periodName, existingTimeline);

  const [round2Enabled, setRound2Enabled] = useState(
    Boolean(
      getExistingDateRangesOfPeriod(
        TransactionPeriodName.SECOND_ROUND_BID_EVALUATION,
      ) ||
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE,
        ),
    ),
  );
  const { emitEvent } = useTransactionsContext();

  const validationSchema = yup.object({
    [TransactionPeriodName.TRANSACTION_PREPARATION]:
      yup.object<Shape<Range<Date>>>(),
    [TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE]:
      yup.object<Shape<Range<Date>>>(),
    [TransactionPeriodName.FIRST_ROUND_BID_EVALUATION]:
      yup.object<Shape<Range<Date>>>(),
    [TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE]:
      yup.object<Shape<Range<Date>>>(),
    [TransactionPeriodName.SECOND_ROUND_BID_EVALUATION]:
      yup.object<Shape<Range<Date>>>(),
    [TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING]:
      yup.object<Shape<Range<Date>>>(),
  });

  const form = useFormik({
    initialValues: {
      [TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING]:
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING,
        ),
      [TransactionPeriodName.FIRST_ROUND_BID_EVALUATION]:
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.FIRST_ROUND_BID_EVALUATION,
        ),
      [TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE]:
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE,
        ),
      [TransactionPeriodName.SECOND_ROUND_BID_EVALUATION]:
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.SECOND_ROUND_BID_EVALUATION,
        ),
      [TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE]:
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE,
        ),
      [TransactionPeriodName.TRANSACTION_PREPARATION]:
        getExistingDateRangesOfPeriod(
          TransactionPeriodName.TRANSACTION_PREPARATION,
        ),
    },
    validationSchema,
    onSubmit: (values) => {
      const newTimeline: ITransactionPeriod[] = [];
      for (const key in values) {
        if (
          [
            TransactionPeriodName.SECOND_ROUND_BID_EVALUATION,
            TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE,
          ].includes(key)
        ) {
          if (!round2Enabled) {
            continue;
          }
        }
        if (values[key] && values[key].start && values[key].end) {
          newTimeline.push({
            name: key,
            period: {
              start: values[key].start.toISOString().slice(0, 10),
              end: values[key].end.toISOString().slice(0, 10),
            },
          });
          if (key === TransactionPeriodName.TRANSACTION_PREPARATION) {
            newTimeline.push({
              name: TransactionPeriodName.TARGET_CLOSE,
              period: {
                start: values[key].start.toISOString().slice(0, 10),
                end: values[key].end.toISOString().slice(0, 10),
              },
            });
          }
        }
      }

      emitEvent(new TimelineEditModalSubmitted({ newTimeline, txnId }));

      onClose();
    },
  });

  return (
    <Modal keepMounted open={open} onClose={onClose} className="z-40">
      <TapUiVerticalModalWrapper className="max-w-md">
        <TapUiModalHead
          title="Change Timeline Dates"
          subtitle="Configure the timeline for this transaction. Each period has a start and an end date. This timeline is only used for display purposes. It can be edited any time by transaction admins."
          onCloseClick={onClose}
        />
        <TapUiModalContent>
          <div className="flex flex-col w-full gap-2">
            <div>
              <div className="font-semibold p-2">Transaction Preparation</div>
              <div className="flex flex-col w-full bg-stone-100 rounded-lg">
                <div className="p-2 flex flex-col gap-1">
                  <div className="flex flex-row w-full justify-between items-end">
                    <div className="text-sm font-semibold">
                      Marketing & Setup
                    </div>
                    {form.values[TransactionPeriodName.TRANSACTION_PREPARATION]
                      ?.end && (
                      <div className="text-xs pl-1">
                        Target Launch by{" "}
                        <span className="font-semibold">
                          {new DateFormatter(
                            form.values[
                              TransactionPeriodName.TRANSACTION_PREPARATION
                            ].end.toISOString(),
                          ).prettyDate()}
                        </span>
                      </div>
                    )}
                  </div>
                  <FormikDatePickerWithRange
                    formik={form}
                    name={TransactionPeriodName.TRANSACTION_PREPARATION}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="font-semibold p-2">Auction (1st Round)</div>
              <div className="flex flex-col w-full bg-stone-100 rounded-lg">
                <div className="p-2 flex flex-col gap-1">
                  <div className="flex flex-row w-full justify-between items-end">
                    <div className="text-sm font-semibold">Due Diligence</div>
                    {form.values[
                      TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE
                    ]?.end && (
                      <div className="text-xs">
                        Bids Due by{" "}
                        <span className="font-semibold">
                          {new DateFormatter(
                            form.values[
                              TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE
                            ].end.toISOString(),
                          ).prettyDate()}
                        </span>
                      </div>
                    )}
                  </div>
                  <FormikDatePickerWithRange
                    formik={form}
                    name={TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE}
                  />
                </div>
                <div className="p-2 flex flex-col gap-1">
                  <div className="flex flex-row w-full justify-between items-end">
                    <div className="text-sm font-semibold">Evaluation</div>
                    {form.values[
                      TransactionPeriodName.FIRST_ROUND_BID_EVALUATION
                    ]?.end && (
                      <div className="text-xs">
                        {round2Enabled ? "Advancing" : "Winning"} Bid(s)
                        Selected by{" "}
                        <span className="font-semibold">
                          {new DateFormatter(
                            form.values[
                              TransactionPeriodName.FIRST_ROUND_BID_EVALUATION
                            ].end.toISOString(),
                          ).prettyDate()}
                        </span>
                      </div>
                    )}
                  </div>
                  <FormikDatePickerWithRange
                    formik={form}
                    name={TransactionPeriodName.FIRST_ROUND_BID_EVALUATION}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className={classNames(
                  "flex flex-row font-semibold w-full justify-between p-2",
                  { "line-through": !round2Enabled },
                )}
              >
                <div>Auction (2nd Round)</div>
                <Switch
                  checked={round2Enabled}
                  onCheckedChange={setRound2Enabled}
                />
              </div>
              {round2Enabled && (
                <div className="flex flex-col w-full bg-stone-100 rounded-lg">
                  <div className="p-2 flex flex-col gap-1">
                    <div className="flex flex-row w-full justify-between items-end">
                      <div className="text-sm font-semibold">Due Diligence</div>
                      {form.values[
                        TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE
                      ]?.end && (
                        <div className="text-xs">
                          Bids Due by{" "}
                          <span className="font-semibold">
                            {new DateFormatter(
                              form.values[
                                TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE
                              ].end.toISOString(),
                            ).prettyDate()}
                          </span>
                        </div>
                      )}
                    </div>
                    <FormikDatePickerWithRange
                      formik={form}
                      name={TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE}
                    />
                  </div>
                  <div className="p-2 flex flex-col gap-1">
                    <div className="flex flex-row w-full justify-between items-end">
                      <div className="text-sm font-semibold">Evaluation</div>
                      {form.values[
                        TransactionPeriodName.SECOND_ROUND_BID_EVALUATION
                      ]?.end && (
                        <div className="text-xs">
                          Winning Bid(s) Selected by{" "}
                          <span className="font-semibold">
                            {new DateFormatter(
                              form.values[
                                TransactionPeriodName
                                  .SECOND_ROUND_BID_EVALUATION
                              ].end.toISOString(),
                            ).prettyDate()}
                          </span>
                        </div>
                      )}
                    </div>
                    <FormikDatePickerWithRange
                      formik={form}
                      name={TransactionPeriodName.SECOND_ROUND_BID_EVALUATION}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="font-semibold p-2">Transaction Closing</div>
              <div className="flex flex-col w-full bg-stone-100 rounded-lg">
                <div className="p-2 flex flex-col gap-1">
                  <div className="flex flex-row w-full justify-between items-end">
                    <div className="text-sm font-semibold">
                      Settlement & Closing
                    </div>
                    {form.values[
                      TransactionPeriodName
                        .DOCUMENTATION_AND_TRANSACTION_CLOSING
                    ]?.end && (
                      <div className="text-xs pl-1">
                        Target Close by{" "}
                        <span className="font-semibold">
                          {new DateFormatter(
                            form.values[
                              TransactionPeriodName
                                .DOCUMENTATION_AND_TRANSACTION_CLOSING
                            ].end.toISOString(),
                          ).prettyDate()}
                        </span>
                      </div>
                    )}
                  </div>
                  <FormikDatePickerWithRange
                    formik={form}
                    name={
                      TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse pt-4">
            <Button type={BUTTON_TYPES.SECONDARY} onClick={form.submitForm}>
              Submit
            </Button>
          </div>
        </TapUiModalContent>
      </TapUiVerticalModalWrapper>
    </Modal>
  );
};

export default UpsertTimelineModal;
