import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";

import { mockData } from "../../mockData";
import { GpLimitedPartner } from "../models/GpLimitedPartner";

interface IParams {}

export class GetLimitedPartners
  implements IAsyncUseCase<IParams, GpLimitedPartner[]>
{
  call: (params: IParams) => Promise<Either<Failure, GpLimitedPartner[]>> =
    async (): Promise<Either<Failure, GpLimitedPartner[]>> => {
      return right([...mockData]);
    };
}
