import {
  TableAlignment,
  TableCell,
  TableHeadCard,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { Button } from "common/components/Button";
import { FileArrowDown } from "@phosphor-icons/react/dist/ssr";
import { FirmOverview } from "experiences/compliance/domain/models/FirmOverview";

const tableColumns = [
  {
    key: "transactionId",
    label: "Transaction Id",
    align: TableAlignment.LEFT,
  },
  {
    key: "transactionName",
    label: "Transaction Name",
    align: TableAlignment.LEFT,
  },
  {
    key: "period",
    label: "Period",
    align: TableAlignment.LEFT,
  },
  {
    key: "qmsTransactions",
    label: "QMS Transactions",
    align: TableAlignment.LEFT,
  },
  {
    key: "nonQmsTransactions",
    label: "Non QMS Transactions",
    align: TableAlignment.LEFT,
  },
  {
    key: "turnoverTotal",
    label: "Turnover Total",
    align: TableAlignment.LEFT,
  },
  {
    key: "actions",
    label: "",
    align: TableAlignment.RIGHT,
  },
];

const TurnoverQmsChart = () => (
  <svg
    width="236"
    height="82"
    viewBox="0 0 236 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <g clip-path="url(#clip0_623_2503)">
      <path
        d="M553.925 39.9576C498.984 39.9576 325.797 50.5038 251.782 50.5038C177.767 50.5038 74.3495 80.3083 -23.9989 80.3083C-122.347 80.3083 -251.113 48.6696 -317.016 48.6696C-382.92 48.6696 -443.754 63.3426 -443.754 63.3426V109.883H1543.49V41.3331C1488.55 41.3331 1449.06 31.4747 1395.46 31.4747C1341.86 31.4747 1183.56 75.7229 1112.58 75.7229C1041.61 75.7229 951.82 41.4566 869.492 50.5038C787.165 59.5509 608.865 39.9576 553.925 39.9576Z"
        fill="url(#paint0_linear_623_2503)"
        fill-opacity="0.15"
      />
      <path
        d="M1543.49 41.383C1488.55 41.383 1449.06 31.4747 1395.46 31.4747C1341.86 31.4747 1183.56 75.9465 1112.58 75.9465C1041.61 75.9465 957.248 41.383 879.444 50.5999C801.64 59.8169 608.865 40.0004 553.925 40.0004C498.984 40.0004 325.797 50.5999 251.782 50.5999C177.767 50.5999 74.3495 80.555 -23.9989 80.555C-122.347 80.555 -251.113 48.7565 -317.016 48.7565C-382.92 48.7565 -443.754 63.5037 -443.754 63.5037"
        stroke="#BCB3A5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_623_2503"
        x1="549.869"
        y1="29.6194"
        x2="549.869"
        y2="109.883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BCB3A5" />
        <stop offset="1" stop-color="#BCB3A5" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_623_2503">
        <rect width="236" height="82" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const TurnoverNonQmsChart = () => (
  <svg
    width="241"
    height="93"
    viewBox="0 0 241 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M403.751 17.0117C366.484 17.0117 249.006 36.9181 198.8 36.9181C148.594 36.9181 78.4431 93.1754 11.7309 93.1754C-54.9814 93.1754 -142.326 33.4561 -187.03 33.4561C-231.734 33.4561 -273 61.152 -273 61.152V149H1075V19.6082C1037.73 19.6082 1010.95 1 974.588 1C938.229 1 830.847 84.5205 782.704 84.5205C734.562 84.5205 673.654 19.8412 617.809 36.9181C561.964 53.9951 441.019 17.0117 403.751 17.0117Z"
      fill="url(#paint0_linear_623_2510)"
      fill-opacity="0.15"
    />
    <path
      d="M1075 19.7746C1037.73 19.7746 1010.95 1 974.588 1C938.229 1 830.847 85.2676 782.704 85.2676C734.562 85.2676 677.336 19.7746 624.559 37.2394C571.783 54.7042 441.019 17.1549 403.751 17.1549C366.484 17.1549 249.006 37.2394 198.8 37.2394C148.594 37.2394 78.4431 94 11.7309 94C-54.9814 94 -142.326 33.7465 -187.03 33.7465C-231.734 33.7465 -273 61.6901 -273 61.6901"
      stroke="#BCB3A5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_623_2510"
        x1="401"
        y1="-2.50199"
        x2="401"
        y2="149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BCB3A5" />
        <stop offset="1" stop-color="#BCB3A5" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const FirmOverviewTable = ({
  firmOverview,
  title,
}: {
  firmOverview: FirmOverview;
  title: string;
}) => {
  const qmsTransactionsValue = firmOverview.transactions
    .map((transaction) => String(transaction.qmsTransactions))
    .filter((transaction) => transaction !== "-")
    .map((transaction) => parseFloat(transaction))
    .reduce((acc, transaction) => acc + transaction, 0)
    .toFixed(2);

  const nonQmsTransactionsValue = firmOverview.transactions
    .map((transaction) => String(transaction.nonQmsTransactions))
    .filter((transaction) => transaction !== "-")
    .map((transaction) => parseFloat(transaction))
    .reduce((acc, transaction) => acc + transaction, 0)
    .toFixed(2);

  const turnoverTotalValue = firmOverview.transactions
    .map((transaction) => String(transaction.turnoverTotal))
    .filter((transaction) => transaction !== "-")
    .map((transaction) => parseFloat(transaction))
    .reduce((acc, transaction) => acc + transaction, 0)
    .toFixed(2);

  return (
    <>
      <TapTable
        skipTopBorder
        data={firmOverview.transactions}
        columns={tableColumns}
        emptyStateTitle="No transactions found"
        renderRow={(transaction: FirmOverview["transactions"][0]) => {
          return (
            <>
              <TableCell isPrimaryColumn hasBullet>
                {transaction.transactionId}
              </TableCell>
              <TableCell>{transaction.transactionName}</TableCell>
              <TableCell>{transaction.period}</TableCell>
              <TableCell>{transaction.qmsTransactions}</TableCell>
              <TableCell>{transaction.nonQmsTransactions}</TableCell>
              <TableCell>{transaction.turnoverTotal}</TableCell>
              <TableCell hasActions>
                <Button icon={<FileArrowDown />}>QMS Certificate</Button>
              </TableCell>
            </>
          );
        }}
        tableHeadCards={
          <>
            <TableHeadCard
              //   style={{ minWidth: "40%" }}
              skipTopBorder
              hasActions
              value={title}
            />
            <TableHeadCard hasActions />
            <TableHeadCard hasActions />
            <TableHeadCard
              style={{ width: "20%" }}
              title="QMS Transactions"
              value={`${qmsTransactionsValue}%`}
              backgroundChartSvg={<TurnoverQmsChart />}
            />
            <TableHeadCard
              style={{ width: "20%" }}
              title="Non QMS Transactions"
              value={`${nonQmsTransactionsValue}%`}
              backgroundChartSvg={<TurnoverNonQmsChart />}
            />

            <TableHeadCard
              style={{ width: "20%" }}
              title="Turnover Total"
              value={`${turnoverTotalValue}%`}
            />

            <TableHeadCard hasActions />
          </>
        }
      />
    </>
  );
};
