import { generatePath, useNavigate } from "react-router";

import { InformationPopup } from "common/components/InformationPopup";
import { TableFundLogo } from "common/components/TableFundLogo";
import { COMPANY_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { LP_ROUTES } from "common/constants/routes";
import TextChip from "experiences/common/TextChip";

import {
  ITabTableColumn,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { TableAlignment } from "experiences/funds/presentation/components/Table";
import { VIEWPOINT_FUND_ID } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";
import {
  IInvestmentsInsightsWithTransactionPercent,
  LiquidityTimeline,
  GpOutlook,
  PerformanceToPlan,
  DistressIndicator,
  GpOutlookLabels,
  PerformanceToPlanLabels,
  DistressIndicatorLabels,
  LiquidityTimelineLabels,
  IInvestmentsInsights,
} from "experiences/transactions/presentation/components/fund-data/commentary/model";

enum TableColumnKeys {
  CompanyName = "companyName",
  FundName = "fundName",
  InvestmentPercent = "investmentPercent",
  LiquidityTimeline = "liquidityTimeline",
  Outlook = "gpOutlook",
  PerformanceToPlan = "performanceToPlan",
  DistressIndicator = "distressIndicator",
}

const columns: ITabTableColumn[] = [
  {
    label: "Company Name",
    key: TableColumnKeys.CompanyName,
    align: TableAlignment.LEFT,
  },
  {
    label: "Fund Name",
    key: TableColumnKeys.FundName,
    align: TableAlignment.LEFT,
  },
  {
    label: "Investment %",
    key: TableColumnKeys.InvestmentPercent,
    align: TableAlignment.RIGHT,
  },
  {
    label: "Outlook",
    key: TableColumnKeys.Outlook,
    align: TableAlignment.LEFT,
  },
  {
    label: "Performance to Plan",
    key: TableColumnKeys.PerformanceToPlan,
    align: TableAlignment.LEFT,
  },
  {
    label: "Liquidity Timeline",
    key: TableColumnKeys.LiquidityTimeline,
    align: TableAlignment.LEFT,
  },
  {
    label: "Distress Indicator",
    key: TableColumnKeys.DistressIndicator,
    align: TableAlignment.LEFT,
  },
];

export const CompanyDataTable = ({
  data,
}: {
  data: IInvestmentsInsights[];
}) => {
  const navigate = useNavigate();

  const handleRowClick = (rowId: string) => {
    const path = generatePath(LP_ROUTES.FundsFundDetail, {
      fundId: VIEWPOINT_FUND_ID,
    });
    navigate(path);
  };

  return (
    <TapTable
      skipTopBorder
      columns={columns}
      className="w-full max-w-full overflow-x-scroll h-full max-h-96 overflow-y-scroll relative pb-16"
      data={data}
      renderRow={({
        company, // this is the companyId
        companyName,
        fundName,
        lpImpliedInvestmentPercentage,
        liquidityTimeline,
        liquidityTimelineDescription,
        gpOutlook,
        gpOutlookDescription,
        performanceToPlan,
        performanceToPlanDescription,
        distressIndicator,
        distressIndicatorDescription,
      }: IInvestmentsInsightsWithTransactionPercent) => {
        const isUnverified = false;
        const imgSrc = `${COMPANY_LOGO_URL}/${company}.jpg`;

        const isLiquidityTimelinePositive =
          liquidityTimeline === LiquidityTimeline.Planned ||
          liquidityTimeline === LiquidityTimeline.ActivelyExploring;
        const isLiquidityTimelineNeutral =
          liquidityTimeline === LiquidityTimeline.NoMention;
        const isLiquidityTimelineDefined =
          isLiquidityTimelinePositive || isLiquidityTimelineNeutral;

        const isOutlookPositive = gpOutlook === GpOutlook.Positive;
        const isOutlookNeutral =
          gpOutlook === GpOutlook.Neutral ||
          gpOutlook === GpOutlook.NotMentioned;
        const isOutlookNegative = gpOutlook === GpOutlook.Negative;
        const isOutlookDefined =
          isOutlookPositive || isOutlookNeutral || isOutlookNegative;

        const isPerformanceToPlanPositive =
          performanceToPlan === PerformanceToPlan.AheadOfPlan;
        const isPerformanceToPlanNegative =
          performanceToPlan === PerformanceToPlan.BehindPlan;
        const isPerformanceToPlanNeutral =
          performanceToPlan === PerformanceToPlan.NotMentioned ||
          performanceToPlan === PerformanceToPlan.OnPlan;
        const isPerformanceToPlanDefined =
          isPerformanceToPlanPositive ||
          isPerformanceToPlanNegative ||
          isPerformanceToPlanNeutral;

        const isDistressIndicatorPositive =
          distressIndicator === DistressIndicator.NotDistressed;
        const isDistressIndicatorNegative =
          distressIndicator === DistressIndicator.Distressed;
        const isDistressIndicatorDefined =
          isDistressIndicatorPositive || isDistressIndicatorNegative;

        return (
          <>
            <TableCell
              isPrimaryColumn
              className="!pt-1 !pb-1"
              onClick={() => handleRowClick(company)}
            >
              <TableFundLogo
                imgSrc={imgSrc}
                fundName={companyName || NOT_AVAILABLE_STR}
              />
            </TableCell>
            <TableCell
              isUnverified={isUnverified}
              onClick={() => handleRowClick(company)}
            >
              {fundName || NOT_AVAILABLE_STR}
            </TableCell>
            <TableCell
              isUnverified={isUnverified}
              className="text-right"
              onClick={() => handleRowClick(company)}
            >
              {tableCellDataFormatter({
                value: lpImpliedInvestmentPercentage,
                format: "percentage1dec",
              })}
            </TableCell>
            <TableCell
              isUnverified={isUnverified}
              onClick={() => handleRowClick(company)}
            >
              {!!gpOutlook && isOutlookDefined ? (
                <div className="flex items-center">
                  <TextChip
                    success={isOutlookPositive}
                    danger={isOutlookNegative}
                    text={GpOutlookLabels[gpOutlook]}
                  />
                  {gpOutlookDescription && (
                    <InformationPopup contentText={gpOutlookDescription} />
                  )}
                </div>
              ) : (
                NOT_AVAILABLE_STR
              )}
            </TableCell>
            <TableCell
              isUnverified={isUnverified}
              onClick={() => handleRowClick(company)}
            >
              {!!performanceToPlan && isPerformanceToPlanDefined ? (
                <div className="flex items-center">
                  <TextChip
                    success={isPerformanceToPlanPositive}
                    danger={isPerformanceToPlanNegative}
                    text={PerformanceToPlanLabels[performanceToPlan]}
                  />
                  {performanceToPlanDescription && (
                    <InformationPopup
                      contentText={performanceToPlanDescription}
                    />
                  )}
                </div>
              ) : (
                NOT_AVAILABLE_STR
              )}
            </TableCell>
            <TableCell
              isUnverified={isUnverified}
              onClick={() => handleRowClick(company)}
            >
              {!!liquidityTimeline && isLiquidityTimelineDefined ? (
                <div className="flex items-center">
                  <TextChip
                    success={isLiquidityTimelinePositive}
                    text={LiquidityTimelineLabels[liquidityTimeline]}
                  />
                  {liquidityTimelineDescription && (
                    <InformationPopup
                      contentText={liquidityTimelineDescription}
                    />
                  )}
                </div>
              ) : (
                NOT_AVAILABLE_STR
              )}
            </TableCell>
            <TableCell
              isUnverified={isUnverified}
              onClick={() => handleRowClick(company)}
            >
              {!!distressIndicator && isDistressIndicatorDefined ? (
                <div className="flex items-center">
                  <TextChip
                    success={isDistressIndicatorPositive}
                    danger={isDistressIndicatorNegative}
                    text={DistressIndicatorLabels[distressIndicator]}
                  />
                  {distressIndicatorDescription && (
                    <InformationPopup
                      contentText={distressIndicatorDescription}
                    />
                  )}
                </div>
              ) : (
                NOT_AVAILABLE_STR
              )}
            </TableCell>
          </>
        );
      }}
    />
  );
};
