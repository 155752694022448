import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from "shadcn/ui/Carousel";

import { UpdateCard } from "common/components/UpdateCard";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { BarLoader } from "common/components/BarLoader";
import { ICompanyQualitativeDataInvestmentUpdate } from "experiences/portfolio-v2/domain/usecases/GetCompanyQualitativeData";
import { EmptyState } from "common/components/EmptyState";

export const CompanyUpdates = ({
  investmentsUpdates,
  loading,
}: {
  investmentsUpdates: ICompanyQualitativeDataInvestmentUpdate[];
  loading: boolean;
}) => {
  return (
    <div className="pt-4">
      <PageSectionTitleDivider showBorderTop>
        Company Updates
      </PageSectionTitleDivider>
      <Carousel className="p-4">
        <CarouselContent className="-ml-4">
          {investmentsUpdates.map((update, index) => {
            const fundImageUrl = undefined;
            return (
              <CarouselItem className="basis-1/3 pl-4">
                <UpdateCard
                  key={`${update.companyName}-${index}`}
                  fundImageUrl={fundImageUrl}
                  fundTitle={
                    <span>
                      <span className="font-semibold">{update.fundName}</span>{" "}
                    </span>
                  }
                  fundSubtitle={update.fund}
                  updateTitle={update.headline}
                  updateDesctription={update.text}
                  type={update.type}
                  reportDate={update.reportDate}
                />
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselDots className="mt-4" />
      </Carousel>
      {loading && investmentsUpdates.length === 0 && <BarLoader />}
      {!loading && investmentsUpdates.length === 0 && (
        <EmptyState
          title="No updates found"
          description="No updates found for this company"
        />
      )}
    </div>
  );
};
