import { cn } from "common/utils";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";

import { CircleChartData } from "experiences/common/CircleChart";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";

import { IReportDate } from "../useFundDataReportDate";
import { OutlookChart, PerformanceToBudgetChart } from "../DealBreakdownCharts";
import { BarLoader } from "common/components/BarLoader";
import { EmptyState } from "common/components/EmptyState";

export const ManagerAssessmentSection = ({
  managerAssessmentCharts,
  reportDate,
  loading,
}: {
  managerAssessmentCharts: {
    outlookChartData: CircleChartData[];
    performanceToPlanChartData: CircleChartData[];
  };
  reportDate?: IReportDate;
  loading: boolean;
}) => {
  const showReportDateSelector = reportDate?.options.length >= 1;
  const hasData =
    managerAssessmentCharts.outlookChartData.length > 0 &&
    managerAssessmentCharts.performanceToPlanChartData.length > 0;
  const showEmptyState = !loading && !hasData;

  if (loading) {
    return (
      <div className={cn("relative", {})}>
        <PageSectionTitleDivider loading={loading} showBorderTop>
          Manager Assessment
        </PageSectionTitleDivider>
        <BarLoader />
      </div>
    );
  }

  if (showEmptyState) {
    return (
      <EmptyState
        title="We don't have any manager assessment data for these interests."
        description="Please check back later or select a different date."
      />
    );
  }

  return (
    <div className={cn("relative", {})}>
      <PageSectionTitleDivider
        showBorderTop
        actions={
          <>
            {showReportDateSelector && (
              <SelectDateDropdown
                value={reportDate.currentDate}
                onValueChange={reportDate.onReportDateChange}
                options={reportDate.options}
                loading={loading}
              />
            )}
          </>
        }
      >
        Manager Assessment
      </PageSectionTitleDivider>
      <div className="flex items-center py-12 gap-8 justify-around">
        <OutlookChart data={managerAssessmentCharts.outlookChartData} />
        <PerformanceToBudgetChart
          data={managerAssessmentCharts.performanceToPlanChartData}
        />
      </div>
    </div>
  );
};
