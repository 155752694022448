import axios from "axios";
import { Either, left, right } from "fp-ts/lib/Either";
import slugify from "slugify";

import TapClient from "common/clients/TapClient";
import { IAsyncUseCase, NoReturn } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";

import { DataroomUploadS3Response } from "experiences/common/FileUploadS3Response";
import toast from "react-hot-toast";

const UPLOAD_FILE_API_PATH = "/transactions/:transactionId/dataroom/upload/";
const PROCESS_FILE_API_PATH = "/transactions/:transactionId/dataroom/process/";
const NOTIFY_FILE_PROCESS_API_PATH =
  "/transactions/:transactionId/dataroom/notify/";
const API_PAYLOAD_KEY = "files_names";

interface IParams {
  files: File[];
  transactionId: string;
}

const generateFormData = ({
  files,
  res,
  index,
}: {
  files: File[];
  res: DataroomUploadS3Response;
  index: number;
}) => {
  const formData = new FormData();

  if (!res || !Object.keys(res).length) {
    return;
  }

  const { fields, url } = res[Object.keys(res)[index]];

  formData.append("key", fields.key);
  formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
  formData.append("policy", fields.policy);
  formData.append("signature", fields.signature);
  formData.append("file", files[index]);

  return {
    formData,
    url,
    type: files[index].type,
  };
};

export class UploadDataroomFile implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<
    Either<
      Failure,
      {
        files: File[];
        transactionId: string;
      }
    >
  > = async ({ files, transactionId }) => {
    const formattedFiles = new Map(
      files.map((file) => [slugify(file.name), file]),
    );
    // payload should be an array of strings with the formatted file names values
    const createUrlsPayload = Array.from(formattedFiles.keys());

    const uploadToS3Path = UPLOAD_FILE_API_PATH.replace(
      ":transactionId",
      transactionId,
    );

    return await new TapClient(uploadToS3Path, {
      [API_PAYLOAD_KEY]: createUrlsPayload,
    })
      .withoutCasing()
      .get({})
      .then(async (resp: DataroomUploadS3Response) => {
        const fileFormData = createUrlsPayload.map((key, index) =>
          generateFormData({
            files: Array.from(formattedFiles.values()),
            res: resp,
            index,
          }),
        );

        const finalResult = await Promise.all(
          fileFormData.map(({ formData, url, type }) =>
            axios
              .post(url, formData, {
                headers: {
                  "Content-Type": type,
                },
              })
              .then(() => right(new NoReturn()))
              .catch((error) => left(error)),
          ),
        )
          .then((results) => {
            toast.success(
              "File uploaded successfully, it will be processed soon.",
              {
                duration: 5000,
              },
            );
            return right({
              processingId: resp.processing_id,
            });
          })
          .catch((error) => left(error));

        return finalResult;
      })
      .catch((resp: any) => {
        return left(new Failure(resp.response));
      });
  };
}

export class ProcessDataroomFile
  implements
    IAsyncUseCase<
      {
        transactionId: string;
      },
      NoReturn
    >
{
  call: (params: { transactionId: string }) => Promise<
    Either<
      Failure,
      {
        transactionId: string;
      }
    >
  > = async (payload) => {
    const processFilePath = PROCESS_FILE_API_PATH.replace(
      ":transactionId",
      payload.transactionId,
    );

    return await new TapClient(processFilePath, {})
      .post()
      .then(async (resp) => {
        toast.success("Your file has been processed, please wait.", {
          duration: 3000,
        });

        return resp;
      })
      .catch((resp: any) => {
        return left(new Failure(resp.response));
      });
  };
}

export class NotifyDataroomFileProcess
  implements
    IAsyncUseCase<
      {
        transactionId: string;
        participantIds?: string[];
        message?: string;
      },
      NoReturn
    >
{
  call: (params: {
    transactionId: string;
    participantIds?: string[];
    message?: string;
  }) => Promise<
    Either<
      Failure,
      {
        transactionId: string;
      }
    >
  > = async ({ transactionId, participantIds, message }) => {
    const processFilePath = NOTIFY_FILE_PROCESS_API_PATH.replace(
      ":transactionId",
      transactionId,
    );

    const payload = {
      participantIds,
      message,
    };

    return await new TapClient(processFilePath, payload)
      .post()
      .then(async (resp) => {
        toast("Notifications have been sent.");
        return resp;
      })
      .catch((resp: any) => {
        toast.error("Failed to send notifications.");
        return left(new Failure(resp.response));
      });
  };
}
