import { ITransactionPeriod } from "experiences/transactions/domain/models/Transaction";
import { TimelineStep } from "common/components/TimelineStep";

import { useTimeline } from "./useTimeline";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

export const Timeline = ({ timeline }: { timeline: ITransactionPeriod[] }) => {
  const { periods } = useTimeline({ timeline });

  return (
    <div>
      {periods.map((period, index) => {
        return (
          <TimelineStep
            key={index}
            title={period.title}
            subtitle={
              period.subtitle
                ? new DateFormatter(period.subtitle).prettyDate()
                : NOT_AVAILABLE_STR
            }
            bullets={period.checklist}
            completed={period.completed}
            current={period.current}
            openByDefault={period.current}
            isLastStep={index === periods.length - 1}
            secondaryTitle={period.secondaryTitle}
            secondarySubtitle={
              period.secondarySubtitle
                ? new DateFormatter(period.secondarySubtitle).prettyDate()
                : NOT_AVAILABLE_STR
            }
          />
        );
      })}
    </div>
  );
};
