import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ICurrentUser } from "../models/CurrentUser";

export class GetCurrentUser implements IAsyncUseCase<IParams, ICurrentUser> {
  call: (params: IParams) => Promise<Either<Failure, ICurrentUser>> =
    async () => {
      return await new TapClient(`/users/me`)
        .get({})
        .then((resp: any) => {
          return right(resp);
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {}
