import { generatePath, useNavigate } from "react-router";

import { Bid } from "experiences/indications-of-interest/domain/models/IOI";
import { EmptyState } from "common/components/EmptyState";
import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, PlusIcon } from "common/components/Button";
import { DashboardList } from "experiences/common/List";
import { FUND_LOGO_URL } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";

import { formatPercentPoints } from "../../../funds/presentation/pages/ViewFund";

import BuyListPlaceholder from "../assets/dashboard-buy-list-empty-placeholder.svg";

const RenderBidTable = ({ bids }: { bids: Bid[] }) => {
  const navigate = useNavigate();

  const goToFundDetail = (fundId: string) => {
    const path = generatePath(LP_ROUTES.FundsFundDetail, { fundId });
    navigate(path);
  };

  return (
    <>
      <table className={`dashboard_table w-full`}>
        <tbody>
          {bids.map((bid: Bid) => {
            const min =
              (bid.minPrice && formatPercentPoints(bid.minPrice * 100)) || "";
            const max =
              (bid.maxPrice && formatPercentPoints(bid.maxPrice * 100)) || "";
            const minMaxStr = `${min}${
              min.length && max.length ? " - " : ""
            }${max}`;

            const imgSrc = `${FUND_LOGO_URL}/${bid.managerId}.jpg`;

            return (
              <tr onClick={() => goToFundDetail(bid.fundId)} key={bid.id}>
                <td>
                  <TableFundLogo imgSrc={imgSrc} fundName={bid.issuerName} />
                </td>
                <td
                  className="text-right"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  {minMaxStr || "-"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const DashboardBuyList = ({
  handleBidsClick,
  bids,
  bidsLoaded,
}: {
  handleBidsClick: () => void;
  bids: Bid[];
  bidsLoaded: boolean;
}) => {
  const navigate = useNavigate();

  const goToBuyList = () => {
    navigate(LP_ROUTES.IndicationsOfInterestBids, {
      replace: true,
    });
  };

  return (
    <DashboardList
      title="Buy List"
      secondaryText={bidsLoaded && bids.length > 0 ? "Price" : undefined}
      onTitleClick={handleBidsClick}
      className="relative"
      table={
        bidsLoaded && bids.length === 0 ? (
          <>
            <EmptyState
              title="Buy list"
              description="Add funds to your Buy List to get deal flow from potential sellers."
              actions={[
                {
                  label: "Add Funds to Buy List",
                  onClick: goToBuyList,
                  type: BUTTON_TYPES.SECONDARY,
                  icon: <PlusIcon />,
                },
              ]}
              className="min-h-96"
            />
            <img
              src={BuyListPlaceholder}
              alt="Buy List"
              style={{ margin: "0 auto", userSelect: "none" }}
              className="w-full px-6 !-mt-20 max-w-96"
            />
          </>
        ) : (
          <RenderBidTable bids={bids} />
        )
      }
      loaded={bidsLoaded}
    />
  );
};
