import { Organization } from "experiences/authentication/domain/models/Organization";
import { IFailure } from "../../../../common/@types/app/Failure";

export class AuthenticationState {}

export class LoginFormState extends AuthenticationState {}

// sending email failed
export class SendingLoginEmailFailed extends LoginFormState {
  failure: IFailure;

  constructor(failure: IFailure) {
    super();
    this.failure = failure;
  }
}

export class SendingLoginEmailSucceeded extends LoginFormState {}

export class SendingLoginEmailInProgress extends LoginFormState {}

export class LoginFormLoaded extends LoginFormState {}

// session claim states
export class LoginSessionClaimState extends AuthenticationState {}

export class LoginSessionClaimInProgress extends LoginSessionClaimState {}

export class LoginSessionClaimSuccess extends LoginSessionClaimState {}

export class LoginSessionClaimRejected extends LoginSessionClaimState {
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class LoginSessionClaimExpired extends LoginSessionClaimState {}

// registration states
export class RegistrationState extends AuthenticationState {}

export interface IStepDetail {
  progress: number;
  tagText: string;
  headerText: string;
  withPercent: boolean;
  withTag: boolean;
  disclaimerOn: boolean;
}

export class InitialRegStepLoaded
  extends RegistrationState
  implements IStepDetail
{
  progress: number = 0;
  tagText: string = "";
  headerText: string = "Sign up to view the market";
  withPercent: boolean = false;
  withTag: boolean = false;
  disclaimerOn: boolean = true;
}

export class CreateYourAccountStepLoaded
  extends RegistrationState
  implements IStepDetail
{
  progress: number = 0;
  tagText: string = "create your account";
  headerText: string = "Create Your Account";
  withPercent: boolean = true;
  withTag: boolean = true;
  disclaimerOn: boolean = true;
  email?: string;

  constructor({ email }: { email?: string }) {
    super();
    this.email = email;
  }
}

export class CreateAccountFailed
  extends CreateYourAccountStepLoaded
  implements IStepDetail {}

export class SelectYourOrganizationStepLoaded
  extends RegistrationState
  implements IStepDetail
{
  progress: number = 0.25;
  tagText: string = "create your account info";
  headerText: string = "Select Your Organization";
  withPercent: boolean = true;
  withTag: boolean = true;
  disclaimerOn: boolean = false;
  preSelectedOrg?: Organization;
  fullName: string;

  constructor({
    preSelectedOrg,
    fullName,
  }: {
    preSelectedOrg?: Organization;
    fullName: string;
  }) {
    super();
    this.preSelectedOrg = preSelectedOrg;
    this.fullName = fullName;
  }
}

export class CreateProfileStepLoaded
  extends RegistrationState
  implements IStepDetail
{
  progress: number = 0.5;
  tagText: string = "select investment preferences";
  headerText: string = "Select Investment Preferences";
  withPercent: boolean = true;
  withTag: boolean = true;
  disclaimerOn: boolean = false;
}

export class SelectGeographiesStepLoaded
  extends RegistrationState
  implements IStepDetail
{
  progress: number = 0.75;
  tagText: string = "select investment preferences geo";
  headerText: string = "Select Investment Preferences";
  withPercent: boolean = true;
  withTag: boolean = true;
  disclaimerOn: boolean = false;
}

export class RegistrationSubmittedStepLoaded
  extends RegistrationState
  implements IStepDetail
{
  progress: number = 1;
  tagText: string = "onboarding";
  headerText: string = "Pending Approval";
  withPercent: boolean = true;
  withTag: boolean = true;
  disclaimerOn: boolean = false;
}
