import React from "react";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { TableLayout } from "../../../../common/TableLayout";
import { DollarAmount } from "../../../../../common/@types/app/DollarAmount";
import { Percentage } from "../../../../../common/@types/app/Percentage";
import { IPartialBid } from "experiences/transactions/domain/models/Bidding";

interface IProps {
  bid: IPartialBid;
}

export const BidBreakDownTable: React.FC<IProps> = ({ bid }) => (
  <TableLayout
    minWidth="500px"
    headerElems={[
      {
        label: "Asset name",
        key: "fundName",
        width: "50%",
      },
      {
        label: "proceeds",
        key: "proceeds",
        width: "30%",
      },
      {
        label: "price",
        key: "price",
        width: "20%",
      },
    ]}
    rows={
      <React.Fragment>
        {bid
          .detailsSet!.map((detail) => {
            return {
              proceeds: new DollarAmount(detail.price!).formatted(),
              price: detail.price
                ? new Percentage(detail.percentValue! * 100).formatted()
                : "-",
              fundName: detail.holdingFundName!,
            };
          })
          .map((row) => (
            <BidBreakDownTableRow {...row} />
          ))}
      </React.Fragment>
    }
  />
);

interface IBidBreakDownTableRowProps {
  fundName: string;
  proceeds: string;
  price: string;
}

export const BidBreakDownTableRow: React.FC<IBidBreakDownTableRowProps> = ({
  fundName,
  proceeds,
  price,
}) => {
  return (
    <TableRow>
      <TableCell>
        <Typography fontWeight="bold">
          <Box pr={4}>{fundName}</Box>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="#737476">{proceeds}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="#737476">{price}</Typography>
      </TableCell>
    </TableRow>
  );
};
