import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Plus, Pencil } from "@phosphor-icons/react/dist/ssr";
import { Stack } from "@mui/material";

import { PageHead } from "common/components/PageHead";
import {
  TapTable,
  TableAlignment,
  TableCell,
} from "experiences/funds/presentation/components/Table";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { useFirm } from "../hooks/useFirm";
import { FirmFund } from "experiences/firm/domain/models/FirmFund";

const FundsTable = ({
  funds,
  isLoading,
}: {
  funds: FirmFund[];
  isLoading: boolean;
}) => {
  console.log({ funds });

  return (
    <>
      <TapTable
        data={funds}
        isLoading={isLoading}
        emptyStateTitle={"There are no matches for your search."}
        columns={[
          {
            key: "name",
            label: "Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "strategy",
            label: "Strategy",
            align: TableAlignment.LEFT,
          },
          {
            key: "vintage",
            label: "Vintage",
            align: TableAlignment.LEFT,
          },
          {
            key: "size",
            label: "Size",
            align: TableAlignment.LEFT,
          },
          {
            key: "domicile",
            label: "Domicile",
            align: TableAlignment.LEFT,
          },
          {
            key: "actions",
            label: "",
            align: TableAlignment.RIGHT,
          },
        ]}
        renderRow={(fund: FirmFund) => {
          const onRowClick = (m: FirmFund) => {
            console.log("fund clicked", m);
          };

          return (
            <>
              <TableCell
                isPrimaryColumn
                hasBullet
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(fund)}
              >
                {fund.fundName}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(fund)}
              >
                {fund.strategy}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(fund)}
              >
                {fund.vintage}
              </TableCell>
              <TableCell>{fund.size}</TableCell>
              <TableCell>{fund.domicile}</TableCell>
              <TableCell hasActions></TableCell>
            </>
          );
        }}
      />
    </>
  );
};

export const FirmFunds = () => {
  const { loadFunds, funds, isLoadingFunds } = useFirm();

  useEffect(() => {
    loadFunds();
  }, []);

  return (
    <>
      <Helmet title="Funds" />
      <PageHead
        title="Funds"
        actions={
          <Button type={BUTTON_TYPES.SECONDARY} icon={<Plus />}>
            Add Fund
          </Button>
        }
      />
      <Stack style={{ width: "100%" }}>
        <FundsTable funds={funds} isLoading={isLoadingFunds} />
      </Stack>
    </>
  );
};
