import { gql } from "@apollo/client";

export const GetTransactionFundDataSummarySoiCsvQuery = gql`
  query DataSummaryCsv($transactionId: String!, $reportDate: Date!) {
    dataSummaryCsv(transactionId: $transactionId, reportDate: $reportDate) {
      fundExposureSummaryTabs {
        name
        data
      }
    }
  }
`;

export const GetTransactionFundDataSoiCsvQueryVariables = gql`
  query DataSummaryCsv($transactionId: String!, $reportDate: Date!) {
    dataSummaryCsv(transactionId: $transactionId, reportDate: $reportDate) {
      soiLookthroughTabs {
        name
        data
      }
    }
  }
`;

export const GetTransactionFundDataCapitalAccountStatementCsvQueryVariables = gql`
  query DataSummaryCsv($transactionId: String!, $reportDate: Date!) {
    dataSummaryCsv(transactionId: $transactionId, reportDate: $reportDate) {
      casTabs {
        name
        data
      }
    }
  }
`;
