export enum TransactionOptionTypeLiteral {
  OnlyWholePortfolioBidsAllowed = "ONLY_WHOLE_PORTFOLIO_BIDS_ALLOWED",
  AdoptPlatformNda = "ADOPT_PLATFORM_NDA",
  AllowBids = "ALLOW_BIDS",
  DataRoom = "DATAROOM",
  Qms = "QMS",
}

export const TransactionOptionLabels: Record<
  TransactionOptionTypeLiteral,
  string
> = {
  [TransactionOptionTypeLiteral.OnlyWholePortfolioBidsAllowed]:
    "Only whole portfolio bids allowed",
  [TransactionOptionTypeLiteral.AdoptPlatformNda]: "Adopt Tap's Platform NDA",
  [TransactionOptionTypeLiteral.AllowBids]: "Allow Bids",
  [TransactionOptionTypeLiteral.DataRoom]: "Data Room",
  [TransactionOptionTypeLiteral.Qms]: "Qualified Matching Service (QMS)",
};

export interface ITransactionOptions {
  type: TransactionOptionTypeLiteral;
  isActive: boolean;
}
