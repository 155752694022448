import {
  generatePath,
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { classNames } from "primereact/utils";

import { RowTabNavLinkWrapper } from "experiences/authentication/presentation/components/RowTabWrapper";
import {
  LP_ROUTES,
  LPTransactionDataRoomRoutes,
} from "common/constants/routes";
import { BreadCrumbs } from "experiences/common/BreadCrumbs";
import { RightArrow } from "experiences/common/RightArrow";
import {
  APP_BAR_COMPANY_NAME_NODE,
  APP_BAR_FUND_NAME_NODE,
  APP_BAR_TRANSACTION_NAME_NODE,
} from "common/constants/assets";
import { PortfolioV2Tabs } from "experiences/portfolio-v2/presentation/components/navigation";
import TextChip from "experiences/common/TextChip";

const dataRoomMenuItems = [
  { label: "Data", path: LPTransactionDataRoomRoutes.Data },
  { label: "Documents", path: LPTransactionDataRoomRoutes.Documents },
];

export const LPNavLinks = () => {
  const currentLocation = useLocation();
  const { txnId = "" } = useParams();

  return (
    <RowTabNavLinkWrapper>
      <Routes>
        <Route
          path={`${LP_ROUTES.Funds}/*`}
          element={
            <>
              <NavLink to={LP_ROUTES.FundsScreen}>Funds</NavLink>
              <NavLink to={LP_ROUTES.FundsAlerts}>Alerts</NavLink>
            </>
          }
        />
        <Route
          path={`${LP_ROUTES.PortfolioEntrypoint}/*`}
          element={
            <>
              {PortfolioV2Tabs.map((tab) => (
                <NavLink
                  key={tab.path}
                  to={tab.path}
                  className={classNames({
                    "pointer-events-none opacity-50": !tab.enabled,
                  })}
                >
                  {tab.label}
                  {!tab.enabled && (
                    <TextChip
                      text="Coming Soon"
                      size="small"
                      pending
                      className="ml-2"
                    />
                  )}
                </NavLink>
              ))}
            </>
          }
        />
        <Route
          path={`${LP_ROUTES.CompanyDetail}/*`}
          element={
            <RowTabNavLinkWrapper>
              <BreadCrumbs to={"-1"}>Previous Fund</BreadCrumbs>
              <RightArrow />
              <NavLink
                to={window.location.pathname}
                reloadDocument
                className="dont_activate"
              >
                <span id={APP_BAR_COMPANY_NAME_NODE}></span>
              </NavLink>
            </RowTabNavLinkWrapper>
          }
        />
        <Route
          path={`${LP_ROUTES.FundsFundDetail}/*`}
          element={
            <RowTabNavLinkWrapper>
              <BreadCrumbs to={LP_ROUTES.FundsScreen}>Funds</BreadCrumbs>
              <RightArrow />
              <NavLink
                to={window.location.pathname}
                reloadDocument
                className="dont_activate"
              >
                <span id={APP_BAR_FUND_NAME_NODE}></span>
              </NavLink>
            </RowTabNavLinkWrapper>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionsActive}/*`}
          element={
            <>
              <NavLink to={LP_ROUTES.TransactionsActive}>Active</NavLink>
              <NavLink to={LP_ROUTES.TransactionsPending}>Pending</NavLink>
            </>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionsPending}/*`}
          element={
            <>
              <NavLink to={LP_ROUTES.TransactionsActive}>Active</NavLink>
              <NavLink to={LP_ROUTES.TransactionsPending}>Pending</NavLink>
            </>
          }
        />
        <Route
          path={`${LP_ROUTES.TransactionDetails}/*`}
          element={
            <>
              <BreadCrumbs to={LP_ROUTES.TransactionsActive}>
                Transactions
              </BreadCrumbs>
              <RightArrow />
              <NavLink
                to={window.location.pathname}
                reloadDocument
                className="dont_activate"
              >
                <span id={APP_BAR_TRANSACTION_NAME_NODE}></span>
              </NavLink>
            </>
          }
        />
        <Route
          path={`${LP_ROUTES.IndicationsOfInterest}/*`}
          element={
            <>
              <NavLink to={LP_ROUTES.IndicationsOfInterestBids}>
                Buy List
              </NavLink>
              <NavLink to={LP_ROUTES.IndicationsOfInterestAsks}>
                Sell List
              </NavLink>
              <NavLink to={LP_ROUTES.IndicationsOfInterestArchive}>
                Archived
              </NavLink>
            </>
          }
        />
        <Route
          path={`${LP_ROUTES.TenderDetails}/*`}
          element={
            currentLocation.pathname.includes(
              generatePath(LP_ROUTES.TenderDetailsDataRoom, {
                txnId,
              }),
            ) && (
              <>
                {dataRoomMenuItems.map((item, index) => {
                  const to = generatePath(
                    `${LP_ROUTES.TenderDetailsDataRoom}${item.path}`,
                    {
                      txnId,
                    },
                  );

                  const isActive = currentLocation.pathname.startsWith(to);

                  return (
                    <NavLink
                      key={index}
                      to={to}
                      className={classNames({
                        active: isActive,
                      })}
                    >
                      {item.label}
                    </NavLink>
                  );
                })}
              </>
            )
          }
        />
      </Routes>
    </RowTabNavLinkWrapper>
  );
};
