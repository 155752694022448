import { useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

import { LP_ROUTES } from "common/constants/routes";
import {
  AssignedSignatoryCompleted,
  ClaimSignatorySubmitted,
  DocumentsEvent,
  InviteSignatoryCompleted,
  NDASignatureFlowAccessed,
  RedirectedToDashboard,
  RedliningPromptConfirmClicked,
  RedliningPromptContinueToSignClicked,
  RedliningFormSubmitted,
  RedliningRequestCompleted,
  SignatureScreenSignatureSucceeded,
  RedirectedToUserSettingsPage,
} from "./DocumentsEvent";
import { GetNDASignaturePackageForUser } from "experiences/documents/domain/usecases/GetNDASignaturePackageForUser";
import { GetNDAAccessPackageForUser } from "experiences/documents/domain/usecases/GetNDAAccessPackageForUser";
import {
  DocumentListLoaded,
  DocumentListLoading,
  DocumentListState,
  DocumentSignFlowLoading,
  DocumentSignFlowState,
  RedliningPromptScreenLoaded,
  RequestRedliningConfirmationScreenLoaded,
  SignatureScreenLoaded,
} from "./DocumentsState";
import { DocumentTypeEnum } from "experiences/documents/models/DocumentType";
import { IDocumentAccessPackage } from "experiences/documents/models/DocumentAccessPackage";
import { Failure } from "common/@types/app/Failure";
import { match } from "fp-ts/lib/Either";
import { DocumentStatus } from "experiences/documents/models/DocumentStatus";
import {
  DashboardTaskSectionState,
  LoadedWithNDASignature,
  LoadedWithoutNDASignature,
} from "./DashboardTaskSectionState";
import { IDocumentSignaturePackage } from "experiences/documents/models/DocumentSignaturePackage";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { DelegateSignatureForDocument } from "experiences/documents/domain/usecases/DelegateSignatureForDocument";
import { NoReturn } from "common/@types/app/UseCase";
import { ListDocumentsForCurrentUser } from "experiences/documents/domain/usecases/ListDocumentsForCurrentUser";
import { IDocument } from "experiences/documents/models/Document";

interface RedliningInfo {
  currentUserIsRedliner: boolean;
  emailOfDifferentReadliner?: string;
  currentUserIsSigner: boolean;
  emailOfDifferentSigner?: string;
}

interface IUseCases {
  getNDAAccessPackageForUser: GetNDAAccessPackageForUser;
  getNDASignaturePackageForUser: GetNDASignaturePackageForUser;
  delegateSignatureForDocument: DelegateSignatureForDocument;
  listDocumentsForUser: ListDocumentsForCurrentUser;
}

export const useManageDocumentsState = ({
  listDocumentsForUser,
  getNDAAccessPackageForUser,
  getNDASignaturePackageForUser,
  delegateSignatureForDocument,
}: IUseCases) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { sendMessage } = useSendSlackMessage();

  const [redliningState, setRedliningState] = useState<RedliningInfo>();

  const [documentSignFlowState, setDocumentSignFlowState] =
    useState<DocumentSignFlowState>(new DocumentSignFlowLoading());

  const [documentListState, setDocumentListState] = useState<DocumentListState>(
    new DocumentListLoading(),
  );

  const [dashboardTaskSectionState, setDashboardTaskSectionState] =
    useState<DashboardTaskSectionState>(new LoadedWithoutNDASignature());

  const { user } = useAuthContext();

  const getNDAAccessPackage = () => {
    getNDAAccessPackageForUser
      .call({ documentType: DocumentTypeEnum.PlatformNDA })
      .then((resp) => {
        match<Failure, IDocumentAccessPackage, void>(
          (_: Failure) => {
            // TODO: toasting here
          },
          (pkg: IDocumentAccessPackage) => {
            if (pkg.documentStatus == DocumentStatus.InProgress) {
              setDashboardTaskSectionState(new LoadedWithNDASignature());
            }
          },
        )(resp);
      });
  };

  const getNDASignaturePackage = () => {
    setDocumentSignFlowState(new DocumentSignFlowLoading());
    getNDASignaturePackageForUser
      .call({ documentType: DocumentTypeEnum.PlatformNDA })
      .then((resp) => {
        match<Failure, IDocumentSignaturePackage, void>(
          (_: Failure) => {
            // TODO: toasting here
          },
          (pkg: IDocumentSignaturePackage) => {
            setDocumentSignFlowState(new SignatureScreenLoaded(pkg));
          },
        )(resp);
      });
  };

  const getDocumentsList = () => {
    listDocumentsForUser.call({}).then((resp) => {
      match<Failure, IDocument[], void>(
        (_: Failure) => {
          // toast something here
        },
        (docs: IDocument[]) => {
          setDocumentListState(new DocumentListLoaded({ documents: docs }));
        },
      )(resp);
    });
  };

  const claimPlatformNDASignatory = () => {
    delegateSignatureForDocument
      .call({
        documentType: DocumentTypeEnum.PlatformNDA,
        delegateEmail: user.email,
        delegateFirstName: user.firstName,
        delegateLastName: user.lastName,
      })
      .then((resp) => {
        match<Failure, NoReturn, void>(
          (_: Failure) => {
            // TODO: toasting here
          },
          (_) => {
            getNDASignaturePackage();
          },
        )(resp);
      });
  };

  const assignPlatformNDASignatory = ({
    signatoryFirstName,
    signatoryLastName,
    signatoryEmail,
  }: {
    signatoryFirstName: string;
    signatoryLastName: string;
    signatoryEmail: string;
  }) => {
    delegateSignatureForDocument
      .call({
        documentType: DocumentTypeEnum.PlatformNDA,
        delegateEmail: signatoryEmail,
        delegateFirstName: signatoryFirstName,
        delegateLastName: signatoryLastName,
      })
      .then((resp) => {
        match<Failure, NoReturn, void>(
          (_: Failure) => {
            // TODO: toasting here
          },
          (_) => {
            getNDASignaturePackage();
          },
        )(resp);
      });
  };

  // event handler
  const emitEvent = (event: DocumentsEvent) => {
    if (event instanceof NDASignatureFlowAccessed) {
      getNDASignaturePackage();
    } else if (event instanceof RedliningFormSubmitted) {
      setRedliningState(event);
      setDocumentSignFlowState(new RedliningPromptScreenLoaded());
    } else if (event instanceof RedliningPromptConfirmClicked) {
      sendMessage({
        blocks: [
          generateSlackMessagePayload({
            title: `${user.firstName} ${user.lastName} requested to redline the Platform NDA`,
            fields: generateObjectAsFields({
              "who to carry out redlining?":
                redliningState.currentUserIsRedliner
                  ? `${user.email} - (I am the redliner)`
                  : `${redliningState.emailOfDifferentReadliner} - (Someone else is the redliner)`,
              "who has to sign ultimately?": redliningState.currentUserIsSigner
                ? `${user.email} - (I am the signer)`
                : `${redliningState.emailOfDifferentSigner} - (Someone else is the signer)`,
            }),
          }),
        ],
      });
      setDocumentSignFlowState(new RequestRedliningConfirmationScreenLoaded());
    } else if (event instanceof RedliningPromptContinueToSignClicked) {
      getNDASignaturePackage();
    } else if (event instanceof RedliningRequestCompleted) {
      enqueueSnackbar(
        "Redlining request completed. We'll contact you shortly.",
        {
          variant: "success",
        },
      );
      navigate(LP_ROUTES.Dashboard);
    } else if (event instanceof InviteSignatoryCompleted) {
      assignPlatformNDASignatory(event);
    } else if (event instanceof AssignedSignatoryCompleted) {
      enqueueSnackbar("New signatory assigned! Invitation has been sent.", {
        variant: "success",
      });
      navigate(LP_ROUTES.Dashboard);
    } else if (event instanceof RedirectedToDashboard) {
      getNDAAccessPackage();
    } else if (event instanceof ClaimSignatorySubmitted) {
      claimPlatformNDASignatory();
    } else if (event instanceof SignatureScreenSignatureSucceeded) {
      enqueueSnackbar("Successfully signed Tap Platform NDA", {
        variant: "success",
      });
      navigate(LP_ROUTES.Dashboard);
    } else if (event instanceof RedirectedToUserSettingsPage) {
      getDocumentsList();
    }
  };

  return {
    documentSignFlowState,
    emitEvent,
    dashboardTaskSectionState,
    documentListState,
  };
};
