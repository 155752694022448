import { DashboardList } from "experiences/common/List";
import { GpDashboardTask } from "../GpDashboardPage";
import { DashboardTask } from "../../components/DashboardTask";

const RenderTasksTable = ({ tasks }: { tasks: GpDashboardTask[] }) => {
  return (
    <div className="flex flex-col gap-0 py-2 px-2">
      {tasks.map((task, i) => {
        return (
          <DashboardTask
            key={i}
            title={task.title}
            icon={task.icon}
            description={task.description}
            buttonCta={task.buttonCta}
            onCtaClick={task.buttonClick}
          />
        );
      })}
    </div>
  );
};

export const GpDashboardTaskList = ({
  tasks,
  count,
}: {
  tasks: GpDashboardTask[];
  count: number;
}) => {
  return (
    <DashboardList
      title="Tasks"
      table={<RenderTasksTable tasks={tasks} />}
      countLabel={count}
    />
  );
};
