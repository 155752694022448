import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { DocumentTypeEnum } from "experiences/documents/models/DocumentType";
import { IDocumentSignaturePackage } from "experiences/documents/models/DocumentSignaturePackage";

export class GetNDASignaturePackageForUser
  implements IAsyncUseCase<IParams, IDocumentSignaturePackage>
{
  call: (
    params: IParams,
  ) => Promise<Either<Failure, IDocumentSignaturePackage>> = async (params) => {
    return await new TapClient(`documents/nda/signing-package/get/`, params)
      .post()
      .then((resp: IDocumentSignaturePackage) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  documentType: DocumentTypeEnum;
}
