import { FirmFund } from "experiences/firm/domain/models/FirmFund";
import { FirmTeamMember } from "experiences/firm/domain/models/FirmTeamMember";

export class FirmState {}

export class FirmTeamMembersState extends FirmState {}

export class FirmTeamMembersLoading extends FirmTeamMembersState {
  members: FirmTeamMember[];

  constructor(members: FirmTeamMember[]) {
    super();
    this.members = members;
  }
}

export class FirmTeamMembersLoaded extends FirmTeamMembersState {
  members: FirmTeamMember[];

  constructor(members: FirmTeamMember[]) {
    super();
    this.members = members;
  }
}

export class FirmFundsState extends FirmState {}

export class FirmFundsLoading extends FirmFundsState {
  funds: FirmFund[];

  constructor(funds: FirmFund[]) {
    super();
    this.funds = funds;
  }
}

export class FirmFundsLoaded extends FirmFundsState {
  funds: FirmFund[];

  constructor(funds: FirmFund[]) {
    super();
    this.funds = funds;
  }
}
