import { Option } from "common/@types/common";
import Autocomplete from "../Autocomplete";

const FormikAutocomplete = ({
  formik,
  name,
  options,
  onOptionChange,
  onCustomSelection,
  ...props
}: {
  formik: any;
  name: string;
  options: { value: string; label: string }[];
  onOptionChange?: (option: { value: string; label: string }) => void;
  onCustomSelection?: () => void;
}) => {
  return (
    // @ts-ignore
    <Autocomplete
      {...props}
      name={name}
      options={options}
      value={formik.values[name]}
      onChange={(value: string | Option) => {
        const isCustom = typeof value === "string" && value?.startsWith("Add");
        formik.setFieldValue(name, value);
        onOptionChange &&
          !isCustom &&
          onOptionChange(options.find((option) => option.value === value)!);
        onCustomSelection && isCustom && onCustomSelection();
      }}
      error={formik.touched[name] ? formik.errors[name] : undefined}
    />
  );
};

export default FormikAutocomplete;
