import { Either, left, right } from "fp-ts/lib/Either";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { Fund } from "../models/Fund";
import { FundGeography } from "../../../common/models/FundGeography";
import { FundStrategy } from "../../../common/models/FundStrategy";

interface IResp {
  id: string;
  name: string;
  managerName: string;
  geography: FundGeography;
  strategy: FundStrategy;
  vintage: number;
  size: number;
  isWatchlisted: boolean;
}

export class RetrieveSingleFund implements IAsyncUseCase<IParams, Fund> {
  call: (params: IParams) => Promise<Either<Failure, Fund>> = async ({
    id,
  }) => {
    return await new TapClient(`/fund/${id}`)
      .get({})
      .then((resp: IResp) => {
        return right({
          ...resp,
          size: new DollarAmount(resp.size),
        });
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  id: string;
}
