import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";
import InfoCircle from "./Icons/InfoCircle";

interface IInformationPopupProps {
  contentText: string;
  size?: number;
}

export const InformationPopup: React.FC<IInformationPopupProps> = ({
  contentText,
  size = 16,
}) => {
  return (
    <InformationTooltip
      title={
        <React.Fragment>
          <Typography variant="body2" sx={{ color: "#737476" }}>
            {contentText}
          </Typography>
        </React.Fragment>
      }
    >
      <Box
        sx={{
          px: 1,
          alignItems: "center",
          display: "flex",
        }}
      >
        <InfoCircle />
      </Box>
    </InformationTooltip>
  );
};

const InformationTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="right" {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    border: "1px solid #dadde9",
  },
}));
