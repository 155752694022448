import StepperFlow from "experiences/common/Stepper/StepperFlow";
import React from "react";
import { Button } from "shadcn/ui/Button";
import TenderSimulator from "./TenderSimulator";
import { makeStyles } from "@mui/styles";
import { Drawer } from "@mui/material";
import { useTransactionsContext } from "../state/TransactionsContext";
import { IndicateInterestScreenSubmitted } from "../state/TransactionsEvent";
import { useParams } from "react-router";

const useStyles = makeStyles((_) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

interface IProps {
  onClose: () => void;
  open: boolean;
}

export const IndicateInterest: React.FC<IProps> = ({ onClose, open }) => {
  const [tenderAmount, setTenderAmount] = React.useState<number>(75_000_000);
  const [reservePrice, setReservePrice] = React.useState<number>(1);
  const { txnId } = useParams();
  const { emitEvent } = useTransactionsContext();
  const classes = useStyles();
  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <StepperFlow
        onClose={onClose}
        name={"Indicate Interest"}
        activeStepIndex={0}
      >
        <TenderSimulator
          tenderAmount={tenderAmount}
          setTenderAmount={setTenderAmount}
          initialReservePrice={reservePrice}
          setReservePrice={setReservePrice}
          allowReservePriceChange
          title={"Indicate Preliminary Interest"}
          description="Indicate how much you may be interested in selling in the potential tender offer transaction. These indications of interest are non-binding and do not commit you to any action. All sizing and pricing information is confidential and will not be disclosed to buyers at any point.          "
        />
        <div className="flex flex-row-reverse w-full p-12 gap-4">
          <Button
            className="font-bold p-2"
            onClick={() => {
              emitEvent(
                new IndicateInterestScreenSubmitted({
                  tenderAmount,
                  reservePrice,
                  txnId: txnId,
                }),
              );
              onClose();
            }}
          >
            Submit
          </Button>
          <Button
            className="p-2 border-2 border-stone-200 text-black bg-white"
            variant="secondary"
            onClick={onClose}
          >
            Decline Participation
          </Button>
        </div>
      </StepperFlow>
    </Drawer>
  );
};
