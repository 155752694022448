import { createContext, useContext } from "react";

import { GpLimitedPartnersEvent } from "./GpLimitedPartnersEvent";
import { useGpLimitedPartnersState } from "./GpLimitedPartnersManager";
import { GpLimitedPartnersState } from "./GpLimitedPartnersState";
import { GetLimitedPartners } from "experiences/limited-partners/domain/usecases/GetLimitedPartners";

export interface GpLimitedPartnersContextProps {
  emitEvent?: (event: GpLimitedPartnersEvent) => void;
  limitedPartnersState?: GpLimitedPartnersState;
}

const initialContextState: GpLimitedPartnersContextProps = {};

const GpLimitedPartnersContext = createContext(initialContextState);

interface IGpLimitedPartnersContextProps {
  children: React.ReactNode;
}

const GpLimitedPartnersStateProvider = ({
  children,
}: IGpLimitedPartnersContextProps) => {
  const { emitEvent, limitedPartnersState } = useGpLimitedPartnersState({
    getLimitedPartners: new GetLimitedPartners(),
  });

  return (
    <GpLimitedPartnersContext.Provider
      value={{
        emitEvent,
        limitedPartnersState,
      }}
    >
      {children}
    </GpLimitedPartnersContext.Provider>
  );
};

export const useLimitedPartnersContext = () =>
  useContext(GpLimitedPartnersContext);

export { GpLimitedPartnersStateProvider, GpLimitedPartnersContext };
