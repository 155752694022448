import { useEffect, useMemo, useRef, useState } from "react";
import { CellChange, RangeType } from "handsontable/common";
import { CellCoords } from "handsontable";
import { useNavigate } from "react-router";

import { registerCellType, DropdownCellType } from "handsontable/cellTypes";
import { registerAllModules } from "handsontable/registry";

registerAllModules();
registerCellType(DropdownCellType);

import "handsontable/dist/handsontable.full.min.css";

import { IIssuer } from "common/@types/models/Issuer";
import { useStateAndRef } from "common/utils/useStateAndRef";
import { MatchStatus } from "experiences/funds/domain/usecases/MatchNames";
import { useGetFundNamesFromApi } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/useGetFundNames";
import { useIoiBulkUploadSubmit } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/useIoiBulkUploadSubmitFund";
import { getDropdownOptions } from "./getDropdownOptions";
import { LP_ROUTES } from "common/constants/routes";
import {
  afterOnCellMouseOut,
  afterOnCellMouseOver,
  formatCurrencyColumns,
  formatPercentageColumns,
  getNamesForColumn,
  getRawTableData,
  handleAddMoreRowsClick,
  validateAndSubmit,
} from "experiences/indications-of-interest/presentation/components/handsOnTableUtils";
import {
  onlyFutureDatesValidator,
  BULK_UPLOAD_DATE_FORMAT,
} from "experiences/indications-of-interest/presentation/components/handsOnTableUtils";
import { useIOIsContext } from "experiences/indications-of-interest/presentation/state/IOIContext";
import {
  BulkIOIUploadScreenPasted,
  BulkIOIUploadScreenIssuerCacheIssuerAdded,
} from "../../state/IOIEvent";
import { FundBid } from "experiences/funds/domain/usecases/BidBulkUploadFunds";
import { SUPPORT_EMAIL } from "common/constants/assets";

const TABLE_MAX_ROWS = 100;
export const DEFAULT_ROW_COUNT = 20;
export const ADD_MORE_ROWS_COUNT = 20;

enum COLUMNS {
  FUND_NAME = "name",
  MIN_AMOUNT = "min_amount",
  MAX_AMOUNT = "max_amount",
  MIN_PRICE = "min_price",
  MAX_PRICE = "max_price",
  REFERENCE_DATE = "reference_date",
  EXPIRATION_DATE = "expiration_date",
}

export const TABLE_COLUMN_TITLES = {
  [COLUMNS.FUND_NAME]: `Fund Name*`,
  [COLUMNS.MIN_AMOUNT]: "Min Amount*",
  [COLUMNS.MAX_AMOUNT]: "Max Amount",
  [COLUMNS.MIN_PRICE]: "Min Price",
  [COLUMNS.MAX_PRICE]: "Max Price",
  [COLUMNS.REFERENCE_DATE]: "Reference Date",
  [COLUMNS.EXPIRATION_DATE]: "Expiration Date",
};

const ioiBulkUploadTableColumns = [
  {
    id: COLUMNS.FUND_NAME,
    title: TABLE_COLUMN_TITLES[COLUMNS.FUND_NAME],
    type: "autocomplete",
    width: 320,
    allowEmpty: false,
    tooltip: (
      <>
        Required. Use full fund names. The "Autocorrect fund names" button will
        attempt to correct individual fund names. If a fund does not exist in
        the Tap database, notify our team at {SUPPORT_EMAIL}.
      </>
    ),
  },
  {
    id: COLUMNS.MIN_AMOUNT,
    title: TABLE_COLUMN_TITLES[COLUMNS.MIN_AMOUNT],
    type: "numeric",
    validator: "numeric",
    allowEmpty: false,
    width: 140,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
    tooltip: <>Required. Your minimum transaction size in terms of NAV.</>,
  },
  {
    id: COLUMNS.MAX_AMOUNT,
    title: TABLE_COLUMN_TITLES[COLUMNS.MAX_AMOUNT],
    type: "numeric",
    validator: "numeric",
    width: 140,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
    tooltip: <>Optional. Your maximum transaction size in terms of NAV.</>,
  },
  {
    id: COLUMNS.MIN_PRICE,
    title: TABLE_COLUMN_TITLES[COLUMNS.MIN_PRICE],
    type: "numeric",
    validator: "numeric",
    width: 70,
    numericFormat: {
      pattern: {
        output: "percent",
        // trimMantissa: true,
        mantissa: 2,
      },
    },
    tooltip: <>Optional. Your minimum price as a percent of NAV.</>,
  },
  {
    id: COLUMNS.MAX_PRICE,
    title: TABLE_COLUMN_TITLES[COLUMNS.MAX_PRICE],
    type: "numeric",
    validator: "numeric",
    width: 70,
    numericFormat: {
      pattern: {
        output: "percent",
        // trimMantissa: true,
        mantissa: 2,
      },
    },
    tooltip: <>Optional. Your maximum price as a percent of NAV.</>,
  },
  {
    id: COLUMNS.REFERENCE_DATE,
    title: TABLE_COLUMN_TITLES[COLUMNS.REFERENCE_DATE],
    type: "date",
    validator: "date",
    dateFormat: BULK_UPLOAD_DATE_FORMAT,
    width: 140,
    tooltip: (
      <>
        Optional. The quarter end of the reporting period on which your pricing
        is based. For instance, if you pricing was based on Q3 2023 you would
        put 2023-09-30.
      </>
    ),
  },
  {
    id: COLUMNS.EXPIRATION_DATE,
    title: TABLE_COLUMN_TITLES[COLUMNS.EXPIRATION_DATE],
    type: "date",
    validator: onlyFutureDatesValidator,
    dateFormat: BULK_UPLOAD_DATE_FORMAT,
    width: 140,
    tooltip: (
      <>
        Optional. The date this IOI will expire. Use {BULK_UPLOAD_DATE_FORMAT}{" "}
        format. If this field is left blank then your IOI is "Good 'Til Canceled
      </>
    ),
  },
];

export const FUND_NAME_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.FUND_NAME,
);
const FUND_MIN_AMOUNT_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MIN_AMOUNT,
);
const FUND_MAX_AMOUNT_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MAX_AMOUNT,
);
const FUND_MIN_PRICE_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MIN_PRICE,
);
const FUND_MAX_PRICE_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MAX_PRICE,
);
const FUND_REFERENCE_DATE_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.REFERENCE_DATE,
);
const FUND_EXPIRATION_DATE_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.EXPIRATION_DATE,
);

const dataSchema = {
  fundName: "",
  minAmount: "",
  maxAmount: "",
  minPrice: "",
  maxPrice: "",
  referenceDate: "",
  expirationDate: "",
};

type TableRow = [
  string | null, // COLUMNS.FUND_NAME
  number | null, // COLUMNS.MIN_AMOUNT
  number | null, // COLUMNS.MAX_AMOUNT
  number | null, // COLUMNS.MIN_PRICE
  number | null, // COLUMNS.MAX_PRICE
  string | null, // COLUMNS.REFERENCE_DATE
  string | null, // COLUMNS.EXPIRATION_DATE
];

export const useBulkUpload = ({
  isReconfirm,
  preloadedIssuers,
  bids,
}: {
  isReconfirm?: boolean;
  preloadedIssuers?: IIssuer[];
  bids: (string | number | null | undefined)[][];
}) => {
  const hotRef = useRef(null);
  const { handleCallMatchNames, isLoading: validateNamesLoading } =
    useGetFundNamesFromApi({
      useAi: true,
    });
  const { submitIois, isLoading: submitLoading } = useIoiBulkUploadSubmit();
  const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);
  const [tableValid, _, tableValidRef] = useStateAndRef(true);
  const navigate = useNavigate();
  const { emitEvent, issuerCacheState } = useIOIsContext();

  const loadPrepopulatedDataIntoTable = () => {
    // load the data into the table
    const table = getTableRef();
    const { rowsWithSomeData } = getRawTableData(table);

    // only load some data if the table is empty
    const tableHasAtLeastOnePrePopulatedRow = rowsWithSomeData.length > 0;

    // we don't want to be loading data into the table if it already has data
    // for both performance reasons and because it will avoid a bug in which data could be reset
    // if the user changes the data while the table is "loading" or handsontable is not yet aware
    // of the data that was loaded
    if (!tableHasAtLeastOnePrePopulatedRow) {
      // loadData is better than setData because it does it in a single step
      // setDataAtCell will trigger a re-render for each cell, and takes a lot of time
      table.loadData(bids);
      // add some extra empty rows for better UX
      handleAddMoreRowsClick(table, setRowCount, 10);
    }
  };

  // if we're preloading data to the table, make sure the cache includes the preloaded data so that the funds are considered valid
  useEffect(() => {
    if (isReconfirm && preloadedIssuers) {
      // save issuers to the cache
      preloadedIssuers.forEach((issuer) => {
        emitEvent!(new BulkIOIUploadScreenIssuerCacheIssuerAdded({ issuer }));
      });

      // save preloaded data to the table
      loadPrepopulatedDataIntoTable();
    }
  }, [preloadedIssuers, isReconfirm]);

  const getTableRef = () => hotRef?.current?.hotInstance;

  const disableAddMoreRows = rowCount >= TABLE_MAX_ROWS;

  // This is pretty much the same list of columns but has a few considerations:
  // - useMemo so that the table is not re-rendering all of the time, this prevents a few bugs I (@jmz7v) observed
  // - this starts from `ioiBulkUploadTableColumns` but we add a few extra fields to the fund name column
  //   - source: this is the function that will be called when the user types in the fund name column, this is where we do the search
  //     and will be calling the api that gets the fund names, in an identical way to how the search fund field works
  //   - validator: handsontable validation works with a function and a callback, but because we're pulling data from the api
  //     we cannot just "validate" against a list of funds (options), so every fund that is returned from the api is saved
  //     at the `issuerCache` state, and then we validate against that list, if the string is not in the list, then it's not valid
  //     (handsontable only works with an array of strings, not objects, so we need to do this by querying directly for the name)
  //     if the string is in the list, then it's valid, we return that to the callback, and handsontable will handle the rest
  const FULL_COLUMN_LIST = {
    ...ioiBulkUploadTableColumns[FUND_NAME_COL_INDEX],
    source: (query: string, process: (string: any[]) => void) => {
      return getDropdownOptions(query, process, issuerCacheState);
    },
    validator: async (value: string, callback: (valid: boolean) => void) => {
      const isValid = Array.from(issuerCacheState.current).some((issuer) => {
        return issuer.name === value;
      });

      callback(isValid);
    },
  };

  // Plug the fund name with the new source and validator function into the columns list
  // Note: Looks like handsontable provides a way for you to update its "settings", but I didn't explore that option
  // and decided to pass the new list of columns directly to the table instead
  const TABLE_COLUMNS_WITH_HIJACKED_LIST = useMemo(() => {
    return [
      ...ioiBulkUploadTableColumns.splice(
        0,
        FUND_MIN_AMOUNT_COL_INDEX,
        FULL_COLUMN_LIST,
      ),
      ...ioiBulkUploadTableColumns.slice(FUND_MIN_AMOUNT_COL_INDEX),
    ];
  }, [FULL_COLUMN_LIST, issuerCacheState.current.size]);

  // array of strings that contains all the column titles
  const tableColumnTitles = TABLE_COLUMNS_WITH_HIJACKED_LIST.map(
    (column) => column.title,
  );

  // array of numbers that contains all the column widths
  const tableColumnWidths = TABLE_COLUMNS_WITH_HIJACKED_LIST.map(
    (column) => column.width,
  );

  const submitApiCall = async (valid: boolean) => {
    tableValidRef.current = valid;

    // Abort if the table is not valid
    if (!valid) {
      return undefined;
    }

    const table = getTableRef();
    const { rowsWithSomeData } = getRawTableData<TableRow>(table);

    const payload: FundBid[] = table
      .getSourceData()
      // Only include rows that have some data
      .filter((_: any, index: number) => rowsWithSomeData.includes(index))
      // Convert the table data to the format that the api expects
      .map((row: any) => {
        const issuer = Array.from(issuerCacheState.current).find((issuer) => {
          if (typeof issuer.name === "undefined") {
            return false;
          }
          return issuer.name === row[FUND_NAME_COL_INDEX];
        });

        return {
          fundId: issuer?.id, // Because we aborted if the table is not valid, we can assume that issuer is not undefined
          minAmount: row[FUND_MIN_AMOUNT_COL_INDEX],
          maxAmount: row[FUND_MAX_AMOUNT_COL_INDEX],
          minPrice: row[FUND_MIN_PRICE_COL_INDEX] * 100, // table uses (0,1) format but api uses (0,100)
          maxPrice: row[FUND_MAX_PRICE_COL_INDEX] * 100, // table uses (0,1) format but api uses (0,100)
          referenceDate: row[FUND_REFERENCE_DATE_COL_INDEX],
          expirationDate: row[FUND_EXPIRATION_DATE_COL_INDEX],
          isGtc: false, // hardcoded because it's not in the table but it's required by the api
          isLpOnFund: false, // hardcoded because it's not in the table but it's required by the api
        };
      });

    submitIois({ iois: payload, reconfirm: isReconfirm }).then((r) => {
      if (r.success) {
        navigate(LP_ROUTES.IndicationsOfInterestBids);
      }
      // TODO: Hook up a toast or call to sentry
    });
  };

  // This function will:
  // 1. Get all fund names from the table
  // 2. Send them to the api, will receive a "match result" for each fund name
  // 3. For each result, we'll replace the fund name in the table with the matched fund name from the api`
  const autoCorrectFundNames = async () => {
    const table = getTableRef();
    const {
      names: fundNames,
      rowsWithSomeData,
      data,
    } = getNamesForColumn(table, FUND_NAME_COL_INDEX);

    // Get matching fund names from the api
    const fundCorrections = await handleCallMatchNames({
      searchTerms: fundNames,
    });

    // DO NOT DELETE THIS 👇👇👇
    if (!fundCorrections) return null;

    // Update the issuer cache with the results from the matches
    // so that they are considered valid options and not rejected by the validator
    // this is a side effect, if we extract this, be a bit careful
    fundCorrections.forEach((match) => {
      const candidate = match.matchedCandidates[0];
      if (Boolean(candidate)) {
        emitEvent!(
          new BulkIOIUploadScreenIssuerCacheIssuerAdded({ issuer: candidate }),
        );
      }
    });

    // For each row that has some data, get the fund name and replace it with the matched fund name from the api
    // but only do that if it was a perfect or partial match, do not strictly rely on the correction.matchedCandidates[0] value
    // as it might not exist (will be an empty array) if it was not a perfect or partial match
    // Reminder: this function will work with both perfect and partial matches, but the handlePaste function
    // will work exclusively with perfect matches
    const newFundNameCellValues: [
      rowIndex: number,
      colIndex: number,
      newValue?: string,
    ][] = rowsWithSomeData.map((rowIndex) => {
      const fundName = String(data[rowIndex][FUND_NAME_COL_INDEX]);
      const correction = fundCorrections.find(
        (c) => c.searchTerm.toLowerCase() === fundName.toLowerCase(),
      );
      const correctionCandidate =
        (correction?.matchedCandidates?.length &&
          correction?.matchedCandidates[0]) ||
        undefined;

      const correctionValue = correctionCandidate?.name || "";

      return [rowIndex, FUND_NAME_COL_INDEX, correctionValue];
    });

    // Filter out rows that don't have a new fund name
    const cleanNewFundNameCellValues = newFundNameCellValues.filter((row) =>
      Boolean(row[2]),
    );

    // setDataAtCell requires data in the format:
    // [[rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue]]
    table.setDataAtCell(cleanNewFundNameCellValues);
  };

  const handlePaste = async (
    pastedData: any[][],
    pastePosition: RangeType[],
  ) => {
    const table = getTableRef();

    // step 1. detect if what was pasted was on the fund name column
    if (pastePosition[0].startCol === FUND_NAME_COL_INDEX) {
      // do the actual fund name validation

      const fundNames = pastedData.map((row) => row[0]); // grab the 1st row from the pasted events, from the 1st section of the pasted events

      // step 2. grab all rows that were pasted
      // Get matching fund names from the api
      const fundCorrections = await handleCallMatchNames({
        searchTerms: fundNames,
      });

      emitEvent!(
        new BulkIOIUploadScreenPasted({
          searchTerms: fundNames,
        }),
      );

      // DO NOT DELETE THIS 👇👇👇 (prevents from crashing if there are not suggested matches)
      if (!fundCorrections || fundCorrections.length === 0) return null;

      // Update the issuer cache with the results from the matches
      // so that they are considered valid options and not rejected by the validator
      // this is a side effect, if we extract this, be a bit careful
      fundCorrections.forEach((match) => {
        const candidate = match.matchedCandidates[0];
        if (Boolean(candidate)) {
          emitEvent!(
            new BulkIOIUploadScreenIssuerCacheIssuerAdded({
              issuer: candidate,
            }),
          );
        }
      });

      const range = Array.from(
        Array(pastePosition[0].endRow - pastePosition[0].startRow).keys(),
        (i) => i + pastePosition[0].startRow,
      );

      const newFundNameCellValues: [
        rowIndex: number,
        colIndex: number,
        newValue?: string,
      ][] = range.map((rowIndex) => {
        const adjustedRow = rowIndex - pastePosition[0].startRow;
        const fundName = String(pastedData[adjustedRow][FUND_NAME_COL_INDEX]);

        const correction = fundCorrections.find(
          (c) => c.searchTerm.toLowerCase() === fundName.toLowerCase(),
        );

        // do the corrections but ONLY if it was a perfect match
        const correctionCandidate =
          (correction?.matchedCandidates?.length &&
            correction.status === MatchStatus.PerfectMatch &&
            correction?.matchedCandidates[0]) ||
          undefined;

        const correctionValue = correctionCandidate?.name || "";

        // if it was not a perfect match, mark the cell as invalid
        if (
          correction?.matchedCandidates?.length &&
          correction.status !== MatchStatus.PerfectMatch
        ) {
          table.setCellMeta(rowIndex, FUND_NAME_COL_INDEX, "valid", false);
          tableValidRef.current = false;
        }
        // force a re-render of the table
        table.render();

        return [rowIndex, FUND_NAME_COL_INDEX, correctionValue];
      });

      // Filter out rows that don't have a new fund name
      const cleanNewFundNameCellValues = newFundNameCellValues.filter((row) =>
        Boolean(row[2]),
      );

      // setDataAtCell requires data in the format:
      // [[rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue]]
      table.setDataAtCell(cleanNewFundNameCellValues);
    }
  };

  const afterChange = (changes: CellChange[] | null) => {
    const table = getTableRef();

    formatPercentageColumns({
      table,
      changes,
      columnsToFormat: [FUND_MIN_PRICE_COL_INDEX, FUND_MAX_PRICE_COL_INDEX],
    });

    formatCurrencyColumns({
      table,
      changes,
      columnsToFormat: [FUND_MIN_AMOUNT_COL_INDEX, FUND_MAX_AMOUNT_COL_INDEX],
    });
  };

  // TODO:
  // when grabbing values from % cols, we need to multiply by 100
  // do this later bc it's pretty hard to get it right
  const beforeCopy = (data: any[], coords: RangeType[]) => {};

  return {
    hotRef,
    getTableRef,
    handleAddMoreRowsClick: () =>
      handleAddMoreRowsClick(getTableRef(), setRowCount, ADD_MORE_ROWS_COUNT),
    disableAddMoreRows,
    handleSubmitClick: () =>
      validateAndSubmit({
        table: getTableRef(),
        columnsToValidate: [
          FUND_NAME_COL_INDEX,
          FUND_MIN_AMOUNT_COL_INDEX,
          FUND_REFERENCE_DATE_COL_INDEX,
          FUND_EXPIRATION_DATE_COL_INDEX,
        ],
        tableValidRef,
        submitApiCall,
      }),
    submitLoading,
    autoCorrectFundNames,
    validateNamesLoading,
    tableColumnTitles,
    tableColumns: TABLE_COLUMNS_WITH_HIJACKED_LIST,
    tableColumnWidths,
    dataSchema,
    tableValid: tableValid,
    handlePaste,
    afterOnCellMouseOver: (
      _: MouseEvent,
      coords: CellCoords,
      el: HTMLElement,
    ) => afterOnCellMouseOver(coords, el, TABLE_COLUMNS_WITH_HIJACKED_LIST),
    afterOnCellMouseOut: afterOnCellMouseOut,
    afterChange,
    beforeCopy,
  };
};
