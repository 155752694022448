import {
  chartColors,
  CircleChart,
  CircleChartData,
} from "experiences/common/CircleChart";

export const StrategyChart = ({
  data,
  loading,
}: {
  data: CircleChartData[];
  loading?: boolean;
}) => {
  return (
    <CircleChart
      title="Strategy"
      data={data}
      width={200}
      height={200}
      colorScale={chartColors.green}
      loading={loading}
    />
  );
};

export const GeographyChart = ({
  data,
  loading,
}: {
  data: CircleChartData[];
  loading?: boolean;
}) => {
  return (
    <CircleChart
      title="Geography"
      data={data}
      width={200}
      height={200}
      colorScale={chartColors.blue}
      loading={loading}
    />
  );
};

export const CompanySectorChart = ({
  data,
  loading,
}: {
  data: CircleChartData[];
  loading?: boolean;
}) => {
  return (
    <CircleChart
      title="Company Sector"
      data={data}
      width={200}
      height={200}
      colorScale={chartColors.orange}
      loading={loading}
    />
  );
};

export const OutlookChart = ({
  data,
  loading,
}: {
  data: CircleChartData[];
  loading?: boolean;
}) => {
  return (
    <CircleChart
      title="Outlook"
      data={data}
      width={200}
      height={200}
      colorScale={chartColors.blue}
      loading={loading}
    />
  );
};

export const PerformanceToBudgetChart = ({
  data,
  loading,
}: {
  data: CircleChartData[];
  loading?: boolean;
}) => {
  return (
    <CircleChart
      title="Performance to Budget"
      data={data}
      width={200}
      height={200}
      colorScale={chartColors.orange}
      loading={loading}
    />
  );
};
