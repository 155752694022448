const steps = [
  {
    isActive: true,
    label: "Trusted Standard Documentation",
    description:
      "Tap’s mutual NDA has been trusted by hundreds of institutional secondary buyers and sellers across the world with billions in AUM.",
  },
  {
    isActive: true,
    label: "Faster Process",
    description:
      "Complete the NDA process in minutes, rather than days of back and forth.",
  },
  {
    isActive: true,
    label: "Less Legal Time",
    description:
      "Save legal time and money by working with proven standard documents.",
  },
];

export const useRequestRedliningContext = () => {
  return { steps };
};
