import { useEffect, useState } from "react";
import { PortfolioV2Routes } from "../components/navigation";
import { useNavigate } from "react-router";

import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { PageHead } from "common/components/PageHead";

import { UploadDocumentsStep0 } from "../components/UploadDocumentsStep0";
import { UploadDocumentsStep1 } from "../components/UploadDocumentsStep1";
import { UploadDocumentsStep2 } from "../components/UploadDocumentsStep2";

const INITIAL_STEP = 0;
const UPLOAD_DOCUMENTS_LAST_STEP = 2;

const usePortfolioUploadDocumentsFlow = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);

  const goToNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleCloseClick = () => {
    navigate(-1);
  };

  const goToFirstStep = () => {
    setCurrentStep(INITIAL_STEP);
  };

  // When in last step, go back to the main documents page
  useEffect(() => {
    if (currentStep === UPLOAD_DOCUMENTS_LAST_STEP) {
      const queryString = new URLSearchParams();

      const basePath = location.pathname.replace(
        PortfolioV2Routes.UploadDocuments,
        "",
      );

      const navigateTo = `${basePath}${PortfolioV2Routes.Documents}${
        queryString.toString() ? `?${queryString.toString()}` : ""
      }`;

      navigate(navigateTo);
    }
  }, [currentStep]);

  return {
    currentStep,
    goToNextStep,
    handleCloseClick,
    goToFirstStep,
  };
};

export const UploadDocumentsPage = () => {
  const { currentStep, goToNextStep, handleCloseClick, goToFirstStep } =
    usePortfolioUploadDocumentsFlow();

  return (
    <>
      <PageHead title="Upload Documents" />
      <StepperFlow
        onClose={handleCloseClick}
        name="Upload Documents"
        activeStepIndex={currentStep}
      >
        {currentStep === 0 && (
          <UploadDocumentsStep0 goToNextStep={goToNextStep} />
        )}
        {currentStep === 1 && (
          <UploadDocumentsStep1
            goToNextStep={goToNextStep}
            goToFirstStep={goToFirstStep}
          />
        )}
        {currentStep === 2 && <UploadDocumentsStep2 />}
      </StepperFlow>
    </>
  );
};
