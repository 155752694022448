import { Either, left, right } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { IAsyncUseCase, NoReturn } from "common/@types/app/UseCase";
import { Failure } from "common/@types/app/Failure";

import { ITPTransaction } from "experiences/transactions/presentation/tp/pages/TpTransactionListPage";

const PATH = "/transactions/third-party/list/";

export class ListTPTransactions
  implements IAsyncUseCase<IParams, ITPTransaction[]>
{
  call: (params: IParams) => Promise<Either<Failure, ITPTransaction[]>> =
    async () => {
      return await new TapClient(PATH)
        .get({})
        .then((resp: ITPTransaction[]) => right(resp))
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {}
