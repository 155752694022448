import { Either, left, right } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";

import { ITransactionSettings } from "../models/Transaction";

export class GetTransactionSettings
  implements IAsyncUseCase<IParams, ITransactionSettings[]>
{
  call: (params: IParams) => Promise<Either<Failure, ITransactionSettings[]>> =
    async ({ txnId }) => {
      return await new TapClient(`/transactions/${txnId}/settings/`)
        .get({})
        .then((resp: ITransactionSettings[]) => {
          return right(resp);
        })
        .catch((resp: any) => {
          return left(new Failure(resp.response));
        });
    };
}

interface IParams {
  txnId: string;
}
