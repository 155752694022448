import { TransactionNDA } from "../models/TransactionNDA";

export const TransactionNDAMock: TransactionNDA[] = [
  {
    id: "0aaa56d1-96e8-5dc4-87fb-93f5148a4ca0",
    ndaName: "Viewpoint Partners NDA FORM Nov 2023",
    funds: "ALL",
    signatures: 23,
    status: "Active",
  },
  {
    id: "f760d2cb-9e88-5193-8f65-3f64e00c8011",
    ndaName: "Viewpoint Partners NDA FORM July 2020",
    funds: "ALL",
    signatures: 17,
    status: "Inactive",
  },
  {
    id: "b2686b0a-ddfc-50c7-9043-130a17955e0b",
    ndaName: "Viewpoint Partners Fund II NDA FORM",
    funds: "II",
    signatures: 5,
    status: "Inactive",
  },
];
