import { IIconProps, SvgIconWrapper } from "./Icon";

const Globe: React.FC<IIconProps> = (props) => {
  return (
    <SvgIconWrapper
      clipPathId="clip0_182_1337"
      strokeWidth={1}
      pathDs={[
        "M4.5 8.85001C6.57107 8.85001 8.25 7.17107 8.25 5.10001C8.25 3.02894 6.57107 1.35001 4.5 1.35001C2.42893 1.35001 0.75 3.02894 0.75 5.10001C0.75 7.17107 2.42893 8.85001 4.5 8.85001Z",
        "M0.75 5.10001H8.25",
        "M4.5 1.35001C5.43798 2.37689 5.97103 3.70952 6 5.10001C5.97103 6.49049 5.43798 7.82312 4.5 8.85001C3.56202 7.82312 3.02897 6.49049 3 5.10001C3.02897 3.70952 3.56202 2.37689 4.5 1.35001V1.35001Z",
      ]}
      width={10}
      height={10}
      {...props}
    ></SvgIconWrapper>
  );
};

export default Globe;
