import { Stack } from "@mui/material";
import { DollarAmount } from "common/@types/app/DollarAmount";
import TextField from "common/components/TextField";
import SelectDropDown from "experiences/common/SelectDropDown";
import { useFundsContext } from "experiences/funds/presentation/state/FundsContext";
import { FundSizeFilterChanged } from "experiences/funds/presentation/state/FundsEvent";
import FilterPill from "./FilterPill";

export const SizeFilter = () => {
  const { emitEvent, filtersSpecs } = useFundsContext();

  let sizeFrom = new DollarAmount(filtersSpecs?.sizeRange?.start ?? 0);
  let sizeTo = new DollarAmount(filtersSpecs?.sizeRange?.end ?? 1 / 0);

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let toVal: number | undefined = +event.target.value
      .replaceAll(",", "")
      .replaceAll("$", "");
    if (toVal <= 0) {
      toVal = undefined;
    }

    if (toVal == filtersSpecs?.sizeRange?.end) return;
    emitEvent!(
      new FundSizeFilterChanged({
        sizeRange:
          !toVal && !filtersSpecs?.sizeRange?.start
            ? undefined
            : {
                end: toVal,
                start: filtersSpecs?.sizeRange?.start ?? sizeFrom.amount,
              },
      }),
    );
  };

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fromVal = +event.target.value.replaceAll(",", "").replaceAll("$", "");

    if (fromVal == filtersSpecs?.sizeRange?.start) return;

    emitEvent!(
      new FundSizeFilterChanged({
        sizeRange:
          fromVal == 0 && !isFinite(filtersSpecs?.sizeRange?.end!)
            ? undefined
            : {
                start: fromVal,
                end: filtersSpecs?.sizeRange?.end ?? sizeTo.amount,
              },
      }),
    );
  };

  const showClearOption =
    Boolean(filtersSpecs?.sizeRange?.start) ||
    Boolean(filtersSpecs?.sizeRange?.end);

  const clearSize = () => {
    emitEvent!(new FundSizeFilterChanged({ sizeRange: {} }));
  };

  return (
    <FilterPill
      clearFn={clearSize}
      showClear={showClearOption}
      title="Fund Size"
      rightText={sizeFrom.formattedBig() + " - " + sizeTo.formattedBig()}
      menuWidth={420}
    >
      <Stack direction={"row"} spacing={1} my={1}>
        <TextField
          mask="currency"
          name="from"
          label="From"
          value={sizeFrom.amount ? sizeFrom.amount : ""}
          autoComplete="from"
          InputProps={{
            onChange: handleFromChange,
          }}
          fullWidth
        />
        <TextField
          mask="currency"
          name="to"
          label="To"
          autoComplete="to"
          value={sizeTo.amount}
          InputProps={{
            onChange: handleToChange,
          }}
          fullWidth
        />
      </Stack>
    </FilterPill>
  );
};

export default SizeFilter;
