import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { CaretRight } from "@phosphor-icons/react";

import { cn } from "common/utils";
import {
  ITabTableColumn,
  StyledTableBody,
  StyledTableRow,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { BarLoader } from "common/components/BarLoader";
import { TableFundLogo } from "common/components/TableFundLogo";
import {
  IPerformanceAggregateData,
  IPortfolioPerformance,
  IReportData,
} from "../components/usePortfolioPerformance";
import { PortfolioPerformanceGroupingKeys } from "./TableGrouping";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import { FUND_LOGO_URL } from "common/constants/platform";
import { VIEWPOINT_FUND_ID } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";
import { LP_ROUTES } from "common/constants/routes";

enum RowType {
  Total = "total",
  Group = "group",
  Holding = "fund",
}

interface ITableRow {
  type: RowType;
  label: string;
  id: string;
  children: ITableRow[];
}

interface IHoldingRow {
  id: string;
  label: string;
  type: RowType;
}

// This is the type for the rows that are grouped
// i.e. total, and the middle level
interface IGroupedRow extends IHoldingRow, IPerformanceAggregateData {}

interface IIndividualRow extends IHoldingRow, IReportData {}

enum PerformanceTableColumnKeys {
  Holding = "holding",
  PortfolioPercentage = "portfolio_percentage",
  NAV = "nav",
  NetMoic = "net_moic", // TODO: remove "net"
  TVPI = "tvpi",
  NetDpi = "net_dpi", // TODO: remove "net"
}

// prettier-ignore
const columns: ITabTableColumn[] = [
  { label: "Holding", key: PerformanceTableColumnKeys.Holding, align: TableAlignment.LEFT },
  { label: "Portfolio %", key: PerformanceTableColumnKeys.PortfolioPercentage, align: TableAlignment.RIGHT },
  { label: "NAV", key: PerformanceTableColumnKeys.NAV, align: TableAlignment.RIGHT },
  { label: "MOIC", key: PerformanceTableColumnKeys.NetMoic, align: TableAlignment.RIGHT },
  { label: "TVPI", key: PerformanceTableColumnKeys.TVPI, align: TableAlignment.RIGHT },
  { label: "DPI", key: PerformanceTableColumnKeys.NetDpi, align: TableAlignment.RIGHT },
];

const usePerformanceTable = ({
  data,
  loading,
}: {
  data: IPortfolioPerformance["portfolioPerformance"];
  loading: boolean;
}) => {
  const [holdings, setHoldings] = useState<IHoldingRow[]>([]);
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [openRowIds, setOpenRowIds] = useState<string[]>([]);
  const portfolioContext = useContext(PortfolioContext);
  const navigate = useNavigate();

  const getGroupLabelString = (
    groupingKey: PortfolioPerformanceGroupingKeys,
    value: string | number,
  ): string => {
    if (!groupingKey) return String(value);
    if (String(value).toLowerCase() === "total") return "Total";

    if (groupingKey === PortfolioPerformanceGroupingKeys.Entity) {
      const entity = portfolioContext.entities.find((e) => e.id === value);
      return entity?.name || String(value);
    }
    if (groupingKey === PortfolioPerformanceGroupingKeys.Manager) {
      const report = data.reportData.find((r) => r.managerId === value);
      return report?.managerName || String(value);
    }
    if (groupingKey === PortfolioPerformanceGroupingKeys.Strategy) {
      return !!FundStrategyNameMap.get(Number(value))
        ? String(FundStrategyNameMap.get(Number(value)))
        : "Other";
    }
    if (groupingKey === PortfolioPerformanceGroupingKeys.Geography) {
      return !!FundGeographyNameMap.get(Number(value))
        ? String(FundGeographyNameMap.get(Number(value)))
        : "Other";
    }
    if (groupingKey === PortfolioPerformanceGroupingKeys.Vintage) {
      return String(value);
    }
    return String(value);
  };

  const handleTotalRowClick = (rowId: string) => {
    if (openRowIds.includes(rowId)) {
      setOpenRowIds([]); // Close all rows
      setRows((prevRows) => {
        const topLevelRow = prevRows.find((row) => row.id === rowId);
        const topLevelRowWithoutChildren = {
          ...topLevelRow,
          children: [],
        };

        return [topLevelRowWithoutChildren];
      });
    } else {
      // Open total row
      setOpenRowIds([rowId]);
      // Generate one row for each entry in data.groupingData.aggregateData
      // Append them to rows

      const newRows: ITableRow[] = data.groupingData.aggregateData
        .map((group) => {
          return {
            type: RowType.Group,
            label: group.groupKey,
            id: group.groupKey,
            children: [],
          };
        })
        .sort((a, b) => {
          // Sort vintage in descending order
          if (
            data?.groupingData?.groupedBy ===
            PortfolioPerformanceGroupingKeys.Vintage
          ) {
            return Number(b.label) - Number(a.label);
          }
          return 0;
        });

      setRows((prev) => {
        const rowToUpdate = prev.find((row) => row.id === rowId);
        if (!rowToUpdate) return prev;

        return [
          ...prev.filter((row) => row.id !== rowId),
          { ...rowToUpdate, children: [...rowToUpdate.children, ...newRows] },
        ];
      });
    }
  };

  const handleRowGroupClick = (rowId: string) => {
    if (openRowIds.includes(rowId)) {
      setOpenRowIds((prev) => [...prev.filter((id) => id !== rowId)]);

      setRows((prevRows) => {
        const totalRow = prevRows.find((row) => row.type === RowType.Total);

        const groupRowIndex = totalRow?.children.findIndex(
          (row) => row.id === rowId,
        );

        if (groupRowIndex === -1) return prevRows;

        const newGroupRow: ITableRow = {
          ...totalRow.children[groupRowIndex],
          children: [],
        };

        const newTotalRow: ITableRow = {
          ...totalRow,
          children: [
            ...totalRow.children.slice(0, groupRowIndex),
            newGroupRow,
            ...totalRow.children.slice(groupRowIndex + 1),
          ],
        };

        return [newTotalRow];
      });
    } else {
      // Add to open rows
      setOpenRowIds((prev) => [...prev, rowId]);
      // Grab all holdings that belong to this group
      const groupIssuerIds = data.groupingData.aggregateData
        .find((group) => group.groupKey === rowId)
        ?.groupElements.map((e) => e.id);
      const groupHoldings = holdings.filter((h) =>
        groupIssuerIds.includes(h.holdingId),
      );

      // Add groupHoldings to the row where (row.id === rowId).children
      setRows((prevRows) => {
        // First, find the total row
        const totalRow = prevRows.find((row) => row.type === RowType.Total);
        const rowToUpdateIndex = totalRow?.children.findIndex(
          (row) => row.id === rowId,
        );
        if (rowToUpdateIndex === -1) return prevRows;

        // Set children of rowToUpdate to groupHoldings
        const groupChildren: ITableRow[] = groupHoldings.map((h) => ({
          type: RowType.Holding,
          label: h.label,
          id: h.id,
          children: [],
        }));

        const newRowToUpdate: ITableRow = Object.assign(
          {},
          totalRow.children[rowToUpdateIndex],
          {
            children: [
              ...totalRow.children[rowToUpdateIndex].children,
              ...groupChildren,
            ],
          },
        );

        const newTotalRow: ITableRow = Object.assign({}, totalRow, {
          children: [
            ...totalRow.children.slice(0, rowToUpdateIndex),
            newRowToUpdate,
            ...totalRow.children.slice(rowToUpdateIndex + 1),
          ],
        });

        return [newTotalRow];
      });
    }
  };

  const handleRowClick = (rowId: string, rowType: RowType) => {
    if (rowType === RowType.Total) {
      handleTotalRowClick(rowId);
      return;
    }
    if (rowType === RowType.Group) {
      handleRowGroupClick(rowId);
      return;
    }
    if (rowType === RowType.Holding) {
      const path = generatePath(LP_ROUTES.FundsFundDetail, {
        fundId: VIEWPOINT_FUND_ID,
      });
      navigate(path);
    }
  };

  // Step 1: Always have the top level aggregate data
  useEffect(() => {
    if (!data?.reportData || !data?.groupingData || loading) return;

    // 1a: Format all rows and groups with special formatting that may be needed
    // TODO: Do this later
    // NOTES: maybe use the "groupKey" to identify rows when grouping albeit not enough if combined with ungrouped holdings
    // Grab all holdings and categories and put them inside holdings
    // Actually this is truly the 1st step
    const topLevelHolding: IGroupedRow = {
      type: RowType.Total,
      id: "total",
      label: "Total",
      ...data?.groupingData?.topLevelAggregateData,
    };
    const groupHoldings: IGroupedRow[] = data?.groupingData?.aggregateData.map(
      (row) => {
        return {
          type: RowType.Group,
          label: getGroupLabelString(
            data?.groupingData?.groupedBy as PortfolioPerformanceGroupingKeys,
            row.groupKey,
          ),
          id: row.groupKey,
          ...row,
        };
      },
    );
    const individualHoldings: IIndividualRow[] = data?.reportData.map((row) => {
      return {
        type: RowType.Holding,
        id: row.holdingId,
        label: row.fundName,
        ...row,
      };
    });

    const allHoldings = [
      topLevelHolding,
      ...groupHoldings,
      ...individualHoldings,
    ];

    // By default, always add the top level row
    const newRows: ITableRow[] = data.groupingData.aggregateData
      .map((group) => {
        return {
          type: RowType.Group,
          label: group.groupKey,
          id: group.groupKey,
          children: [],
        };
      })
      .sort((a, b) => {
        // Sort vintage in descending order
        if (
          data?.groupingData?.groupedBy ===
          PortfolioPerformanceGroupingKeys.Vintage
        ) {
          return Number(b.label) - Number(a.label);
        }
        return 0;
      });

    setHoldings(allHoldings);
    setOpenRowIds(["total"]);
    setRows((prev) => {
      const topLevelRow: ITableRow = {
        type: RowType.Total,
        label: "Total",
        id: "total",
        children: newRows,
      };

      return [...prev.filter((row) => row.id !== "total"), topLevelRow];
    });
  }, [data, data?.groupingData?.groupedBy, data?.reportData, loading]);

  const renderRow = (row: ITableRow) => {
    const isOpen = openRowIds.includes(row.id);
    const isHolding = row.type === RowType.Holding;
    const isGroupLevel =
      row.type === RowType.Group || row.type === RowType.Total;
    const showCaret = isGroupLevel;

    const holdingForRow = holdings.find((holding) => holding.id === row.id);

    const label = isGroupLevel
      ? getGroupLabelString(data?.groupingData?.groupedBy, row.label)
      : row.label;

    const imgSrc = `${FUND_LOGO_URL}/${holdingForRow?.managerId}.jpg`;

    if (isGroupLevel) {
      const holding = holdings.find((h) => h.id === row.id) as IGroupedRow;

      // Use row data instead of holdingForRow
      return (
        <>
          <StyledTableRow
            onClick={() => handleRowClick(row.id, row.type)}
            key={row.id}
          >
            <TableCell
              className={cn("select-none overflow-ellipsis", {
                "!pl-10": row.type === RowType.Group,
                "!pl-16 !pt-1 !pb-1": isHolding,
                "!font-medium": isGroupLevel,
              })}
            >
              {showCaret && (
                <CaretRight
                  className={cn(
                    "transition-transform inline mr-2",
                    isOpen && "rotate-90",
                  )}
                />
              )}
              {isHolding ? (
                <TableFundLogo imgSrc={imgSrc} fundName={label} />
              ) : (
                label
              )}
            </TableCell>
            <TableCell className="!w-36 !max-w-36 text-right">
              {tableCellDataFormatter({
                value: holding?.portfolioPercent,
                format: "percentage",
              })}
            </TableCell>
            <TableCell className="!w-36 !max-w-36 text-right">
              {tableCellDataFormatter({
                value: holding.nav,
                format: "currency",
              })}
            </TableCell>
            <TableCell className="!w-36 !max-w-36 text-right">
              {tableCellDataFormatter({
                value: holding.grossMoic,
                format: "multiplier",
              })}
            </TableCell>
            <TableCell className="!w-36 !max-w-36 text-right">
              {tableCellDataFormatter({
                value: holding.grossTvpi,
                format: "multiplier",
              })}
            </TableCell>
            <TableCell className="!w-36 !max-w-36 text-right">
              {tableCellDataFormatter({
                value: holding.grossDpi,
                format: "multiplier",
              })}
            </TableCell>
          </StyledTableRow>
          {row.children.length > 0 &&
            row.children.map((child) => renderRow(child))}
        </>
      );
    } else {
      const holding = holdings.find((h) => h.id === row.id) as IIndividualRow;
      return (
        <StyledTableRow
          onClick={() => handleRowClick(row.id, row.type)}
          key={row.id}
        >
          <TableCell
            className={cn("select-none overflow-ellipsis", {
              "!pl-10": row.type === RowType.Group,
              "!pl-16 !pt-1 !pb-1": isHolding,
              "!font-medium": isGroupLevel,
            })}
          >
            {showCaret && (
              <CaretRight
                className={cn(
                  "transition-transform inline mr-2",
                  isOpen && "rotate-90",
                )}
              />
            )}
            {isHolding ? (
              <TableFundLogo imgSrc={imgSrc} fundName={label} />
            ) : (
              label
            )}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holding?.portfolioPercent,
              format: "percentage",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holding.nav,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holding.moic,
              format: "multiplier",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holding.tvpi,
              format: "multiplier",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holding.dpi,
              format: "multiplier",
            })}
          </TableCell>
        </StyledTableRow>
      );
    }
  };

  return {
    rows,
    renderRow,
  };
};

export const PerformanceTable = ({
  loading,
  data,
}: {
  loading: boolean;
  data: IPortfolioPerformance["portfolioPerformance"];
}) => {
  const reportData = data?.reportData || [];
  const hasData = reportData.length > 0;

  const { rows, renderRow } = usePerformanceTable({ data, loading });

  return (
    <div className="w-full max-w-full overflow-x-scroll h-auto min-h-96 relative pb-12">
      {!loading && hasData && (
        <TapTable
          data={rows}
          columns={columns}
          emptyStateTitle="No entries found"
          emptyStateDescription="Please check back later."
          isLoading={loading}
          showEmptyState={!loading && !hasData}
          skipTopBorder
          renderBody={(bodyData) => (
            <StyledTableBody>{bodyData.map(renderRow)}</StyledTableBody>
          )}
        />
      )}
      {loading && <BarLoader />}
    </div>
  );
};
