import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { UserSettings } from "experiences/authentication/domain/models/Settings";

export class GetUserSettings implements IAsyncUseCase<IParams, UserSettings> {
  call: (params: IParams) => Promise<Either<Failure, UserSettings>> =
    async () => {
      return await new TapClient(`/users/profile`)
        .get({})
        .then((resp: any) => {
          return right(resp);
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {}
