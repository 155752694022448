import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";

import { TransactionFundDataRoutes } from "./TransactionFundDataScreen";
import { Step0UploadDocuments } from "./verify-permission/Step0UploadDocuments";
import { Step1ClassifyDocuments } from "./verify-permission/Step1ClassifyDocuments";
import { Step2DataExtraction } from "./verify-permission/Step2DataExtraction";

export const FORCE_CLASSIFY_DOCUMENTS_MOCK = false;
const INITIAL_STEP = FORCE_CLASSIFY_DOCUMENTS_MOCK ? 1 : 0;
const CLASSIFY_DOCUMENTS_LAST_STEP = 3;

const useTransactionFundDataVerifyPermissionFlow = () => {
  const lastStep = CLASSIFY_DOCUMENTS_LAST_STEP;
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseClick = () => {
    navigate(-1);
  };

  const goToNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const goToFirstStep = () => {
    setCurrentStep(INITIAL_STEP);
  };

  useEffect(() => {
    if (currentStep === lastStep) {
      const queryString = new URLSearchParams();

      const basePath = location.pathname.replace(
        TransactionFundDataRoutes.VerifyPermission,
        "",
      );

      const navigateTo = `${basePath}${TransactionFundDataRoutes.Summary}${
        queryString.toString() ? `?${queryString.toString()}` : ""
      }`;

      navigate(navigateTo);
    }
  }, [currentStep]);

  return {
    currentStep,
    goToNextStep,
    goToFirstStep,
    handleCloseClick,
  };
};

export const TransactionFundDataVerifyPermissionFlow = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const { currentStep, goToNextStep, goToFirstStep, handleCloseClick } =
    useTransactionFundDataVerifyPermissionFlow();

  return (
    <>
      <PageHead title="Access Transaction Data" />

      <StepperFlow
        onClose={handleCloseClick}
        name="Access Transaction Data"
        activeStepIndex={0}
      >
        <div className="h-full max-h-full min-h-full">
          {currentStep === 0 && (
            <Step0UploadDocuments
              goToNextStep={goToNextStep}
              summary={summary}
            />
          )}
          {currentStep === 1 && (
            <Step1ClassifyDocuments
              goToNextStep={goToNextStep}
              goToFirstStep={goToFirstStep}
            />
          )}
          {currentStep === 2 && (
            <Step2DataExtraction goToNextStep={goToNextStep} />
          )}
        </div>
      </StepperFlow>
    </>
  );
};
