import { Helmet } from "react-helmet";
import { createSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Archive, ArrowsClockwise, Barcode } from "@phosphor-icons/react";
import { Stack } from "@mui/material";

import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import Loader from "common/components/TapLoader";
import { PageHead } from "common/components/PageHead";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { AddInterestModal, IoiType } from "./AddInterestModal";
import { LP_ROUTES } from "common/constants/routes";
import { useIOIsContext } from "../state/IOIContext";
import { CreateIOIFormOff, IOIsLoaded } from "../state/IOIState";
import { Bid } from "experiences/indications-of-interest/domain/models/IOI";
import {
  ArchiveIOIOptionPressed,
  CreateIOIFormTriggered,
  NewIOIFlowCloseRequested,
} from "../state/IOIEvent";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { PRELOAD_QUERY_PARAM } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/usePreloadBids";
import { FUND_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";

const NEW_TABLE_ENABLED = true;

const useBuyList = () => {
  const navigate = useNavigate();
  const { bidsState, emitEvent, createIOIFormState } = useIOIsContext();

  const handleNewBidClick = () => {
    emitEvent!(new CreateIOIFormTriggered());
  };

  const handleCloseNewBidModal = () => {
    emitEvent!(new NewIOIFlowCloseRequested());
  };

  const handleArchiveClick = (bid: Bid) => {
    emitEvent!(new ArchiveIOIOptionPressed({ ioiId: bid.id }));
  };

  const handleBuyListClick = () => {
    const route = NEW_TABLE_ENABLED
      ? LP_ROUTES.IndicationsOfInterestTableUpload
      : LP_ROUTES.IndicationsOfInterestBulkUpload;

    navigate(route);
  };

  const handlePreloadBuyListClick = () => {
    navigate({
      pathname: LP_ROUTES.IndicationsOfInterestTableUpload,
      search: createSearchParams({ [PRELOAD_QUERY_PARAM]: "true" }).toString(),
    });
  };

  const goToFundDetail = (fundId: string, e?: React.MouseEvent) => {
    // do not navigate if the user is clicking on the checkbox
    if (e?.isPropagationStopped() || e?.target !== e?.currentTarget) {
      return;
    }

    // TODO @jmz7v
    // Use https://reactrouter.com/en/main/utils/generate-path
    navigate(`${LP_ROUTES.FundsFundDetail.replace(":fundId", fundId)}`);
  };

  return {
    bidsState,
    createIOIFormState,
    handleNewBidClick,
    handleCloseNewBidModal,
    handleArchiveClick,
    handleBuyListClick,
    handlePreloadBuyListClick,
    goToFundDetail,
  };
};

const sortIoisByManager = (iois: Bid[]) => {
  return iois.sort((a, b) => {
    if (a.managerName < b.managerName) {
      return -1;
    }
    if (a.managerName > b.managerName) {
      return 1;
    }
    return 0;
  });
};

export const Bids = () => {
  const {
    bidsState,
    createIOIFormState,
    handleNewBidClick,
    handleCloseNewBidModal,
    handleArchiveClick,
    handleBuyListClick,
    handlePreloadBuyListClick,
    goToFundDetail,
  } = useBuyList();

  const headActions = (
    <Stack direction={"row"} spacing={1}>
      <Button
        onClick={handleNewBidClick}
        icon={<PlusIcon />}
        type={BUTTON_TYPES.SECONDARY}
      >
        Add Buy Interest
      </Button>
      <Button
        onClick={handleBuyListClick}
        type={BUTTON_TYPES.PRIMARY}
        icon={<Barcode />}
      >
        Bulk Upload
      </Button>
      <Button onClick={handlePreloadBuyListClick} icon={<ArrowsClockwise />}>
        Bulk Update
      </Button>
    </Stack>
  );

  const sortedBids =
    bidsState instanceof IOIsLoaded
      ? sortIoisByManager(bidsState.iois as Bid[])
      : [];

  return (
    <>
      <Helmet title="Buy List" />
      <PageHead
        title="Buy List"
        {...(bidsState instanceof IOIsLoaded
          ? { hug: String(sortedBids.length) }
          : {})}
        actions={headActions}
      />
      {bidsState instanceof IOIsLoaded ? (
        <TapTable
          data={sortedBids}
          groupBy={(bid: Bid) => bid.managerName}
          renderGroupHeader={(bid: Bid) => bid.managerName}
          emptyStateTitle="No active interests"
          emptyStateDescription="Add your list of target funds to be notified of buying opportunities and price estimates."
          emptyStateActions={[
            {
              label: "Add Buy Interest",
              onClick: handleNewBidClick,
              type: BUTTON_TYPES.SECONDARY,
              icon: <PlusIcon />,
            },
            {
              label: "Bulk Upload",
              onClick: handleBuyListClick,
              type: BUTTON_TYPES.PRIMARY,
              icon: <Barcode />,
            },
          ]}
          columns={[
            {
              key: "fund",
              label: "Fund",
              align: TableAlignment.LEFT,
            },
            {
              key: "create_date",
              label: "Create Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "ref_date",
              label: "Ref Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "expiration_date",
              label: "Exp Date",
              align: TableAlignment.LEFT,
            },
            {
              key: "bid",
              label: "Bid (% of NAV)",
              align: TableAlignment.LEFT,
            },
            {
              key: "min_nav",
              label: "Min NAV",
              align: TableAlignment.LEFT,
            },
            {
              key: "max_nav",
              label: "Max NAV",
              align: TableAlignment.LEFT,
            },
            {
              key: "actions",
              label: "",
              align: TableAlignment.LEFT,
            },
          ]}
          renderRow={(bid: Bid) => {
            const fundId = bid.fundId;

            const imgSrc = `${FUND_LOGO_URL}/${fundId}.jpg`;

            return (
              <>
                <TableCell
                  isPrimaryColumn
                  onClick={(e) => goToFundDetail(fundId, e)}
                >
                  <TableFundLogo imgSrc={imgSrc} fundName={bid.issuerName} />
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {new DateFormatter(bid.createdAt).humanDate()}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {bid.referenceDate
                    ? new DateFormatter(bid.referenceDate).quarterFormatted()
                    : NOT_AVAILABLE_STR}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {bid.expirationDate
                    ? new DateFormatter(bid.expirationDate).quarterFormatted()
                    : NOT_AVAILABLE_STR}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {(Boolean(bid.minPrice) && `${bid.minPrice}%`) || null}
                  {(Boolean(bid.minPrice) && Boolean(bid.maxPrice) && " - ") ||
                    null}
                  {(Boolean(bid.maxPrice) && `${bid.maxPrice}%`) || null}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {new DollarAmount(bid.minAmount).formatted()}
                </TableCell>
                <TableCell onClick={(e) => goToFundDetail(fundId, e)}>
                  {bid.maxAmount
                    ? new DollarAmount(bid.maxAmount).formatted()
                    : "-"}
                </TableCell>
                <TableCell hasActions>
                  <Button
                    onClick={() => handleArchiveClick(bid)}
                    icon={<Archive />}
                  >
                    Archive
                  </Button>
                </TableCell>
              </>
            );
          }}
        />
      ) : (
        <Loader />
      )}
      <AddInterestModal
        type={IoiType.Bid}
        open={!(createIOIFormState instanceof CreateIOIFormOff)}
        onClose={handleCloseNewBidModal}
        onFormSubmitted={handleCloseNewBidModal}
      />
    </>
  );
};
