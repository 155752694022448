import { useEffect } from "react";
import { useLocation } from "react-router";
import { CircleHalf, Envelope } from "@phosphor-icons/react";
import { classNames } from "primereact/utils";

import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import { formatPercentPoints } from "../../pages/ViewFund";
import { TransactionDetailsRoutes } from "experiences/transactions/presentation/components/TransactionDetails";
import { Fund } from "experiences/funds/domain/models/Fund";
import { IoiType } from "experiences/indications-of-interest/presentation/pages/AddInterestModal";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { IOIType } from "experiences/indications-of-interest/domain/models/IOI";
import { ioiIsExpired } from "./IndicationsOfInterestTable";
import { FUND_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import { SidebarSideMenu } from "../../../../dashboard/presentation/components/SidebarMenu";
import { FundSummary } from "experiences/funds/domain/models/FundSummary";
import { PageSidebar } from "experiences/common/PageWrapper";
import { StyledFundDetails } from "./SidebarStyles";
import { useViewpointFundDetails } from "../../hooks/useViewpointFundDetails";
import { isFundViewpoint } from "../../hooks/useViewFund";
import { SUPPORT_EMAIL } from "common/constants/assets";
import { SidebarHead } from "./SidebarHead";
import { DescriptionList } from "common/components/DescriptionList";

export const overviewKey = "overview";
export const priceHistoryKey = "price_history";
export const ioisKey = "iois";
export const performanceKey = "performance";
export const investmentsKey = "investments";
export const cashFlowsKey = "cash_flows";

export const sidebarPositions = {
  [priceHistoryKey]: {
    label: "Price History",
  },
  [overviewKey]: {
    label: "Basic Information",
  },
  [ioisKey]: {
    label: "IOIs",
  },
  [performanceKey]: {
    label: "Performance",
  },
  [investmentsKey]: {
    label: "Investments",
  },
  [cashFlowsKey]: {
    label: "Cash Flows",
  },
};

/**
 * Note: these fields show up for viewpoint (id:008SXxu86bHRs) but is hidden for other funds
 */
export const Sidebar = ({
  fund,
  summary,
  toggleSubmitIoiModal,
}: {
  fund?: Fund;
  summary?: FundSummary;
  toggleSubmitIoiModal: (type: IoiType) => void;
}) => {
  const fundId = fund?.id;
  const managerId = fund?.managerId;
  const location = useLocation();

  const isViewpoint = isFundViewpoint(fund);
  const canViewBasicInfo =
    summary?.permissionProfile.isPermittedToViewBasicInfo() || isViewpoint;

  const viewpointFundDetails = useViewpointFundDetails({
    locked: !canViewBasicInfo,
    toggleSubmitIoiModal,
  });

  if (!fundId) return null;

  useEffect(() => {
    // Default list state to 1st element in sidebarPositions if no hash is present
    // Search keywords: .scroll .scrollIntoView priceHistoryKey basicInformationKey
    if (!location.hash) {
      const initialScrollTo = Object.keys(sidebarPositions)[0];
      location.hash = `#${initialScrollTo}`;
    }
  }, []);

  // open interest is the sum of all ioi's min or max amount
  // only one of them is required, consider both max and min amounts
  // but only grab the highest one of them
  const openInterest = summary?.iois
    .filter((ioi) => !ioiIsExpired(ioi))
    .reduce((acc, ioi) => {
      return acc + Math.max(ioi.maxAmount || 0, ioi.minAmount || 0);
    }, 0);

  const highestBid = summary?.iois
    .filter((ioi) => !ioiIsExpired(ioi))
    .reduce((acc, ioi) => {
      if (ioi.type === IOIType.Bid) {
        return Math.max(acc, ioi.maxPrice || ioi.minPrice || 0);
      } else {
        return acc;
      }
    }, 0);

  const highestAsk = summary?.iois
    .filter((ioi) => !ioiIsExpired(ioi))
    .reduce((acc, ioi) => {
      if (ioi.type === IOIType.Ask) {
        return Math.max(acc, ioi.maxPrice || ioi.minPrice || 0);
      } else {
        return acc;
      }
    }, 0);

  const bidAskStr = `${
    (highestBid && formatPercentPoints(highestBid * 100)) || "–"
  } / ${(highestAsk && formatPercentPoints(highestAsk * 100)) || "–"}`;

  const marketPriceStr = viewpointFundDetails.marketPricePoints;

  const fundLogoSrc = isViewpoint
    ? "/images/ui/viewpoint.png"
    : `${FUND_LOGO_URL}/${managerId}.jpg`;

  const showHarveyCircle =
    marketPriceStr && canViewBasicInfo && marketPriceStr !== NOT_AVAILABLE_STR;

  return (
    <PageSidebar className="no-scrollbar">
      <StyledFundDetails className="no-scrollbar">
        <SidebarHead fundLogoSrc={fundLogoSrc} fundName={fund?.name || ""} />
        <DescriptionList
          items={[
            {
              bold: true,
              label: "Market Price",
              value: (
                <>
                  {marketPriceStr}
                  {showHarveyCircle ? (
                    <CircleHalf
                      weight="fill"
                      height={12}
                      width={12}
                      style={{ display: "inline" }}
                    />
                  ) : null}
                </>
              ),
            },
            {
              label: "Bid/Ask",
              value: bidAskStr,
              locked: !canViewBasicInfo,
            },
            {
              label: "Open Interest",
              value: new DollarAmount(openInterest || 0).formattedBig(),
              locked: !canViewBasicInfo,
            },
          ]}
        />

        <div className="bid_actions">
          <Button
            type={BUTTON_TYPES.SECONDARY}
            size="medium"
            onClick={viewpointFundDetails.handleSubmidBid}
            icon={<PlusIcon />}
          >
            Add Indication of Interest
          </Button>
          <Button
            size="medium"
            href={`mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            icon={<Envelope />}
            as={"a"}
          >
            Request Quote
          </Button>
        </div>
      </StyledFundDetails>
      <SidebarSideMenu
        baseRoute={window.location.origin}
        menu={[
          {
            label: sidebarPositions[priceHistoryKey].label,
            path: `${LP_ROUTES.FundsFundDetail.replace(
              ":fundId",
              fundId,
            )}#${priceHistoryKey}` as TransactionDetailsRoutes,
          },
          {
            label: sidebarPositions[overviewKey].label,
            path: `${LP_ROUTES.FundsFundDetail.replace(
              ":fundId",
              fundId,
            )}#${overviewKey}` as TransactionDetailsRoutes,
          },
          {
            label: sidebarPositions[ioisKey].label,
            path: `${LP_ROUTES.FundsFundDetail.replace(
              ":fundId",
              fundId,
            )}#${ioisKey}` as TransactionDetailsRoutes,
          },
          // {
          //   label: sidebarPositions[performanceKey].label,
          //   path: `${ROUTES.FundsFundDetail.replace(
          //     ":fundId",
          //     fundId
          //   )}#${performanceKey}` as TransactionDetailsRoutes,
          // },
          {
            label: sidebarPositions[investmentsKey].label,
            path: `${LP_ROUTES.FundsFundDetail.replace(
              ":fundId",
              fundId,
            )}#${investmentsKey}` as TransactionDetailsRoutes,
          },
          {
            label: sidebarPositions[cashFlowsKey].label,
            path: `${LP_ROUTES.FundsFundDetail.replace(
              ":fundId",
              fundId,
            )}#${cashFlowsKey}` as TransactionDetailsRoutes,
          },
        ]}
      ></SidebarSideMenu>
    </PageSidebar>
  );
};
