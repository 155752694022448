import { cn } from "common/utils";
import {
  TableAlignment,
  TableCell,
} from "experiences/funds/presentation/components/Table";

export enum FundExposureColumnsQuay {
  FUND_NAME = "FUND_NAME",
  MANAGER_NAME = "MANAGER_NAME",
  TXN_PERCENT = "TXN_%",
  STRATEGY = "STRATEGY",
  VINTAGE = "VINTAGE",
  GEOGRAPHY = "GEOGRAPHY",
  FUND_COMMITTED_CAPITAL = "FUND_COMMITTED_CAPITAL",
  MOIC = "MOIC",
  QUARTILE = "QUARTILE",
  FUND_SERIES_AVERAGE_MOIC = "FUND_SERIES_AVERAGE_MOIC",
  FUND_NAV_QOQ_PERCENT_CHG_1Q = "FUND_NAV_QOQ_PERCENT_CHG_1Q",
  FUND_NAV_YOY_PERCENT_CHANGE_FY1 = "FUND_NAV_YOY_PERCENT_CHANGE_FY1",
  UNREALIZED_INVESTMENT_COUNT = "UNREALIZED_INVESTMENT_COUNT",
  TOP_3_CONCENTRATION = "TOP_3_CONCENTRATION",
  TOP_10_CONCENTRATION = "TOP_10_CONCENTRATION",
  WEIGHTED_AVG_REVENUE_GROWTH = "WEIGHTED_AVG_REVENUE_GROWTH",
  CAPITAL_ACCOUNT_CURRENCY = "CAPITAL_ACCOUNT_CURRENCY",
  LP_CURRENT_EXPOSURE = "LP_CURRENT_EXPOSURE",
  LP_COMMITTED_CAPITAL = "LP_COMMITTED_CAPITAL",
  LP_REMAINING_UNFUNDED = "LP_REMAINING_UNFUNDED",
  LP_CONTRIBUTED_CAPITAL_ITD = "LP_CONTRIBUTED_CAPITAL_ITD",
  LP_DISTRIBUTED_CAPITAL_ITD = "LP_DISTRIBUTED_CAPITAL_ITD",
  LP_ENDING_CAPITAL_ACCOUNT_VALUE = "LP_ENDING_CAPITAL_ACCOUNT_VALUE",
  LP_CAS_TVPI = "LP_CAS_TVPI",
  LP_CAS_DPI = "LP_CAS_DPI",
}

export const renderRowMockQuay = (row: any, { fixedHeaders }) => {
  return (
    <>
      <TableCell
        isPrimaryColumn
        className={cn({
          "!sticky left-0 !bg-neutral-50 z-10 border-zinc-400 overflow-visible":
            fixedHeaders,
        })}
      >
        {row["FUND_NAME"]}
      </TableCell>
      <TableCell>{row["MANAGER_NAME"]}</TableCell>
      <TableCell>{row["TXN_%"]}</TableCell>
      <TableCell>{row["STRATEGY"]}</TableCell>
      <TableCell>{row["VINTAGE"]}</TableCell>
      <TableCell>{row["GEOGRAPHY"]}</TableCell>
      <TableCell>{row["FUND_COMMITTED_CAPITAL"]}</TableCell>
      <TableCell>{row["MOIC"]}</TableCell>
      <TableCell>{row["QUARTILE"]}</TableCell>
      <TableCell>{row["FUND_SERIES_AVERAGE_MOIC"]}</TableCell>
      <TableCell>{row["FUND_NAV_QOQ_PERCENT_CHG_1Q"]}</TableCell>
      <TableCell>{row["FUND_NAV_YOY_PERCENT_CHANGE_FY1"]}</TableCell>
      <TableCell>{row["UNREALIZED_INVESTMENT_COUNT"]}</TableCell>
      <TableCell>{row["TOP_3_CONCENTRATION"]}</TableCell>
      <TableCell>{row["TOP_10_CONCENTRATION"]}</TableCell>
      <TableCell>{row["WEIGHTED_AVG_REVENUE_GROWTH"]}</TableCell>
      <TableCell>{row["CAPITAL_ACCOUNT_CURRENCY"]}</TableCell>
      <TableCell>{row["LP_CURRENT_EXPOSURE"]}</TableCell>
      <TableCell>{row["LP_COMMITTED_CAPITAL"]}</TableCell>
      <TableCell>{row["LP_REMAINING_UNFUNDED"]}</TableCell>
      <TableCell>{row["LP_CONTRIBUTED_CAPITAL_ITD"]}</TableCell>
      <TableCell>{row["LP_DISTRIBUTED_CAPITAL_ITD"]}</TableCell>
      <TableCell>{row["LP_ENDING_CAPITAL_ACCOUNT_VALUE"]}</TableCell>
      <TableCell>{row["LP_CAS_TVPI"]}</TableCell>
      <TableCell>{row["LP_CAS_DPI"]}</TableCell>
    </>
  );
};

// disable prettier for this block
// prettier-ignore
export const mockColumnsQuay = [
    { label: "Fund Name", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_NAME },
    { label: "Manager Name", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.MANAGER_NAME },
    { label: "Txn %", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.TXN_PERCENT },
    { label: "Strategy", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.STRATEGY },
    { label: "Vintage", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.VINTAGE },
    { label: "Geography", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.GEOGRAPHY },
    { label: "Fund Committed Capital", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_COMMITTED_CAPITAL },
    { label: "MOIC", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.MOIC },
    { label: "Quartile", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.QUARTILE },
    { label: "Fund Series Average MOIC", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_SERIES_AVERAGE_MOIC },
    { label: "Fund NAV QoQ % Chg 1Q", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_NAV_QOQ_PERCENT_CHG_1Q },
    { label: "Fund NAV YoY % Change FY1", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.FUND_NAV_YOY_PERCENT_CHANGE_FY1 },
    { label: "Unrealized Investment Count", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.UNREALIZED_INVESTMENT_COUNT },
    { label: "Top 3 Concentration", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.TOP_3_CONCENTRATION },
    { label: "Top 10 Concentration", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.TOP_10_CONCENTRATION },
    { label: "Weighted Avg Revenue Growth", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.WEIGHTED_AVG_REVENUE_GROWTH },
    { label: "Capital Account Currency", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.CAPITAL_ACCOUNT_CURRENCY },
    { label: "LP Current Exposure", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CURRENT_EXPOSURE },
    { label: "LP Committed Capital", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_COMMITTED_CAPITAL },
    { label: "LP Remaining Unfunded", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_REMAINING_UNFUNDED },
    { label: "LP Contributed Capital ITD", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CONTRIBUTED_CAPITAL_ITD },
    { label: "LP Distributed Capital ITD", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_DISTRIBUTED_CAPITAL_ITD },
    { label: "LP Ending Capital Account Value", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_ENDING_CAPITAL_ACCOUNT_VALUE },
    { label: "LP CAS TVPI", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CAS_TVPI },
    { label: "LP CAS DPI", align: TableAlignment.LEFT, key: FundExposureColumnsQuay.LP_CAS_DPI },
  ];

// prettier-ignore
export const fundExposureSummaryMockQuay = [
  { FUND_NAME: "RSCM Fund III, L.P.", MANAGER_NAME: "Right Side Capital Management", "TXN_%": "38.0%", STRATEGY: "Venture", VINTAGE: "2017", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$16,045,228", MOIC: "3.6x", QUARTILE: "2", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "3%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "4%", UNREALIZED_INVESTMENT_COUNT: "57", TOP_3_CONCENTRATION: "51%", TOP_10_CONCENTRATION: "78%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$11,663,413", LP_COMMITTED_CAPITAL: "$3,935,228", LP_REMAINING_UNFUNDED: "$0", LP_CONTRIBUTED_CAPITAL_ITD: "$3,935,228", LP_DISTRIBUTED_CAPITAL_ITD: "$314,818", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$11,663,413", LP_CAS_TVPI: "3.0x", LP_CAS_DPI: "0.1x" },
  { FUND_NAME: "Signal Peak Ventures III, L.P.", MANAGER_NAME: "Signal Peak Ventures", "TXN_%": "25.0%", STRATEGY: "Venture", VINTAGE: "2018", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$153,061,224", MOIC: "2.8x", QUARTILE: "-", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "5%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "15%", UNREALIZED_INVESTMENT_COUNT: "17", TOP_3_CONCENTRATION: "70%", TOP_10_CONCENTRATION: "97%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$7,726,648", LP_COMMITTED_CAPITAL: "$3,500,000", LP_REMAINING_UNFUNDED: "$218,750", LP_CONTRIBUTED_CAPITAL_ITD: "$3,281,250", LP_DISTRIBUTED_CAPITAL_ITD: "$0", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$7,507,898", LP_CAS_TVPI: "2.3x", LP_CAS_DPI: "0.0x" },
  { FUND_NAME: "Peregrine Select Fund II, LP", MANAGER_NAME: "Right Side Capital Management", "TXN_%": "5.0%", STRATEGY: "Venture", VINTAGE: "2021", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$43,020,000", MOIC: "1.1x", QUARTILE: "-", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "3%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "12%", UNREALIZED_INVESTMENT_COUNT: "29", TOP_3_CONCENTRATION: "28%", TOP_10_CONCENTRATION: "59%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$1,617,382", LP_COMMITTED_CAPITAL: "$1,500,000", LP_REMAINING_UNFUNDED: "$0", LP_CONTRIBUTED_CAPITAL_ITD: "$1,500,000", LP_DISTRIBUTED_CAPITAL_ITD: "$0", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$1,617,382", LP_CAS_TVPI: "1.1x", LP_CAS_DPI: "0.0x" },
  { FUND_NAME: "Peregrine Select Fund II, LP", MANAGER_NAME: "Right Side Capital Management", "TXN_%": "5.0%", STRATEGY: "Venture", VINTAGE: "2021", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$43,020,000", MOIC: "1.1x", QUARTILE: "-", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "3%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "12%", UNREALIZED_INVESTMENT_COUNT: "29", TOP_3_CONCENTRATION: "28%", TOP_10_CONCENTRATION: "59%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$1,617,382", LP_COMMITTED_CAPITAL: "$1,500,000", LP_REMAINING_UNFUNDED: "$0", LP_CONTRIBUTED_CAPITAL_ITD: "$1,500,000", LP_DISTRIBUTED_CAPITAL_ITD: "$0", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$1,617,382", LP_CAS_TVPI: "1.1x", LP_CAS_DPI: "0.0x" },
  { FUND_NAME: "Signal Peak Ventures III CIV-A, L.P.", MANAGER_NAME: "Signal Peak Ventures", "TXN_%": "14%", STRATEGY: "Venture", VINTAGE: "2022", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$50,959,596", MOIC: "1.8x", QUARTILE: "-", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "-1%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "74%", UNREALIZED_INVESTMENT_COUNT: "1", TOP_3_CONCENTRATION: "100%", TOP_10_CONCENTRATION: "100%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$4,325,761", LP_COMMITTED_CAPITAL: "$2,503,007", LP_REMAINING_UNFUNDED: "0", LP_CONTRIBUTED_CAPITAL_ITD: "$2,503,007", LP_DISTRIBUTED_CAPITAL_ITD: "$0", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$4,325,761", LP_CAS_TVPI: "1.7x", LP_CAS_DPI: "0.0x" },
  { FUND_NAME: "Signal Peak Ventures III CIV-A, L.P.", MANAGER_NAME: "Signal Peak Ventures", "TXN_%": "6%", STRATEGY: "Venture", VINTAGE: "2022", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$50,959,596", MOIC: "1.8x", QUARTILE: "-", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "-1%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "74%", UNREALIZED_INVESTMENT_COUNT: "1", TOP_3_CONCENTRATION: "100%", TOP_10_CONCENTRATION: "100%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$1,730,305", LP_COMMITTED_CAPITAL: "$1,001,203", LP_REMAINING_UNFUNDED: "0", LP_CONTRIBUTED_CAPITAL_ITD: "$1,001,203", LP_DISTRIBUTED_CAPITAL_ITD: "$0", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$1,730,305", LP_CAS_TVPI: "1.7x", LP_CAS_DPI: "0.0x" },
  { FUND_NAME: "Signal Peak Ventures IV, L.P.", MANAGER_NAME: "Signal Peak Ventures", "TXN_%": "5%", STRATEGY: "Venture", VINTAGE: "2022", GEOGRAPHY: "US", FUND_COMMITTED_CAPITAL: "$50,771,807", MOIC: "1.3x", QUARTILE: "-", FUND_SERIES_AVERAGE_MOIC: "-", FUND_NAV_QOQ_PERCENT_CHG_1Q: "4%", FUND_NAV_YOY_PERCENT_CHANGE_FY1: "56%", UNREALIZED_INVESTMENT_COUNT: "6", TOP_3_CONCENTRATION: "74%", TOP_10_CONCENTRATION: "100%", WEIGHTED_AVG_REVENUE_GROWTH: "-", CAPITAL_ACCOUNT_CURRENCY: "USD", LP_CURRENT_EXPOSURE: "$1,642,545", LP_COMMITTED_CAPITAL: "$1,500,000", LP_REMAINING_UNFUNDED: "$251,250", LP_CONTRIBUTED_CAPITAL_ITD: "$1,248,750", LP_DISTRIBUTED_CAPITAL_ITD: "$0", LP_ENDING_CAPITAL_ACCOUNT_VALUE: "$1,391,295", LP_CAS_TVPI: "1.1x", LP_CAS_DPI: "0.0x" },
]
