import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { Portfolio } from "../models/Portfolio";

export class GetPortfolio implements IAsyncUseCase<IParams, Portfolio> {
  call: (params: IParams) => Promise<Either<Failure, Portfolio>> = async () => {
    return await new TapClient(`/portfolio/get/`)
      .get({})
      // .then((resp: Portfolio) => right(resp))
      .then((resp: Portfolio) => {
        if (resp.holdings.length == 0) return right(resp);
        const arrOfPrices: number[] = [];
        resp.holdings = resp.holdings.map((holding) => {
          arrOfPrices.push(holding.fundPrice);
          return holding;
        });

        resp.totalTvpi = resp.totalTvpi
          ? Math.round(Number(resp.totalTvpi.toFixed(1)) * 10) / 10
          : undefined;
        // note: I @jmz7v added the ? thingy but need to check if logic is correct
        // or default to a different value or not show anything at all here
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {}

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}
