export enum RegMarketEngagement {
  Unknown = 0,
  Buying = 1,
  Selling = 2,
}

export const RegMarketEngagementLabels = {
  [RegMarketEngagement.Unknown]: "Unknown",
  [RegMarketEngagement.Buying]: "Buying",
  [RegMarketEngagement.Selling]: "Selling",
};
