import classNames from "classnames";
import { StyledList } from "./List";
import { cn } from "common/utils";

export const SimpleTable = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <StyledList>
      <table className="dashboard_table dont_hover">
        <tbody>{children}</tbody>
      </table>
    </StyledList>
  );
};

export const SimpleTableRow = ({
  locked,
  label,
  value = "-",
  valueClassname,
  valueLighter,
  loading,
}: {
  locked?: boolean;
  label: React.ReactNode;
  value?: React.ReactNode;
  valueClassname?: string;
  valueLighter?: boolean;
  loading?: boolean;
}) => {
  return (
    <tr
      className={classNames("dont_hover", {
        locked: locked,
      })}
    >
      <td className={classNames({ locked })}>{label}</td>
      <td
        className={cn("dashboard_table-value !font-bold", {
          "!text-stone-400 animate-pulse": valueLighter,
          valueClassname,
          locked,
        })}
      >
        {loading ? "-" : value}
      </td>
    </tr>
  );
};
