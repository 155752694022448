import { Either, left, right } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";

import { FirmTeamMember } from "experiences/firm/domain/models/FirmTeamMember";

const PATH = "/api/firm/team-members";

export class GetFirmTeamMembers
  implements IAsyncUseCase<IParams, FirmTeamMember[]>
{
  call: (params: IParams) => Promise<Either<Failure, FirmTeamMember[]>> =
    async () => {
      return await new TapClient(PATH)
        .get({})
        .then((resp: FirmTeamMember[]) => right(resp))
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

// We are not sending any params to the get request but may in the future, so let's keep the interface
interface IParams {}
