import {
  NavLink,
  Route,
  Routes,
  generatePath,
  useLocation,
  useParams,
} from "react-router-dom";
import { classNames } from "primereact/utils";

import { RowTabNavLinkWrapper } from "experiences/authentication/presentation/components/RowTabWrapper";
import {
  GP_ROUTES,
  GpTransactionDataRoomRoutes,
  GpTransactionMonitorRoutes,
  GpTransactionParticipantRoutes,
} from "common/constants/routes";
import { BreadCrumbs } from "experiences/common/BreadCrumbs";
import { RightArrow } from "experiences/common/RightArrow";
import { APP_BAR_TRANSACTION_NAME_NODE } from "common/constants/assets";

const transactionMonitorMenuItems = [
  {
    label: "Preliminary Interest",
    path: GpTransactionMonitorRoutes.PreliminaryInterest,
  },
  {
    label: "Non-Binding Bids",
    path: GpTransactionMonitorRoutes.NonBindingBids,
  },
  {
    label: "Binding Bids",
    path: GpTransactionMonitorRoutes.BindingBids,
  },
  {
    label: "Participation Amount",
    path: GpTransactionMonitorRoutes.ParticipationAmount,
  },
];

const participantMenuItems = [
  {
    label: "Participants",
    path: GpTransactionParticipantRoutes.Participants,
  },
  {
    label: "Team Members",
    path: GpTransactionParticipantRoutes.TeamMembers,
  },
];

const dataRoomMenuItems = [
  { label: "Data", path: GpTransactionDataRoomRoutes.Data },
  { label: "Documents", path: GpTransactionDataRoomRoutes.Documents },
];

export const GPNavLinks = () => {
  const currentLocation = useLocation();
  const { transactionId = "" } = useParams();

  const showTransactionMonitorMenuItems = currentLocation.pathname.includes(
    generatePath(GP_ROUTES.TransactionDetailTransactionMonitor, {
      transactionId,
    }),
  );

  const showTransactionParticipantMenuItems = currentLocation.pathname.includes(
    generatePath(GP_ROUTES.TransactionDetailParticipants, {
      transactionId,
    }),
  );

  const showTransactionDataRoomMenuItems = currentLocation.pathname.includes(
    generatePath(GP_ROUTES.TransactionDetailDataRoom, {
      transactionId,
    }),
  );

  return (
    <RowTabNavLinkWrapper>
      <Routes>
        <Route
          path={`${GP_ROUTES.TransactionSettings}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.TransactionSettingsTransactionPolicies}>
                Transaction Policies
              </NavLink>
              <NavLink to={GP_ROUTES.TransactionsSettingsNDAs}>NDAs</NavLink>
              <NavLink to={GP_ROUTES.TransactionsSettingsTransferAgreements}>
                Transfer Agreements
              </NavLink>
            </>
          }
        />

        <Route
          path={`${GP_ROUTES.TransactionDetail}/*`}
          element={
            <>
              <BreadCrumbs to={GP_ROUTES.Dashboard}>Dashboard</BreadCrumbs>
              <RightArrow />
              <NavLink
                to={window.location.pathname}
                reloadDocument
                className="dont_activate"
              >
                <span id={APP_BAR_TRANSACTION_NAME_NODE}></span>
              </NavLink>
              {/*
               * This is done via a function that replaces :transactionId because all other routes are static
               * but this one depends on :transactionId and it being sent as a plain string manually for
               * the router to know that it is active and to highlight the current sub-path
               */}
              {showTransactionMonitorMenuItems && (
                <>
                  {transactionMonitorMenuItems.map((item, index) => {
                    const to = generatePath(
                      `${GP_ROUTES.TransactionDetailTransactionMonitor}${item.path}`,
                      {
                        transactionId: transactionId,
                      },
                    );

                    const isActive =
                      currentLocation.pathname.startsWith(to) ||
                      currentLocation.hash.includes(item.path.split("#")[1]);

                    return (
                      <NavLink
                        key={index}
                        to={to}
                        className={classNames({
                          active: isActive,
                        })}
                      >
                        {item.label}
                      </NavLink>
                    );
                  })}
                </>
              )}
              {showTransactionParticipantMenuItems && (
                <>
                  {participantMenuItems.map((item, index) => {
                    const to = generatePath(
                      `${GP_ROUTES.TransactionDetailParticipants}${item.path}`,
                      {
                        transactionId: transactionId,
                      },
                    );

                    const isActive = currentLocation.pathname.startsWith(to);

                    return (
                      <NavLink
                        key={index}
                        to={to}
                        className={classNames({
                          active: isActive,
                        })}
                      >
                        {item.label}
                      </NavLink>
                    );
                  })}
                </>
              )}
              {showTransactionDataRoomMenuItems && (
                <>
                  {dataRoomMenuItems.map((item, index) => {
                    const to = generatePath(
                      `${GP_ROUTES.TransactionDetailDataRoom}${item.path}`,
                      {
                        transactionId: transactionId,
                      },
                    );

                    const isActive = currentLocation.pathname.startsWith(to);

                    return (
                      <NavLink
                        key={index}
                        to={to}
                        className={classNames({
                          active: isActive,
                        })}
                      >
                        {item.label}
                      </NavLink>
                    );
                  })}
                </>
              )}
            </>
          }
        />

        <Route
          path={`${GP_ROUTES.ApprovedBuyersApproved}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.ApprovedBuyersApproved}>Approved</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersRequested}>
                Requested
              </NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersBlocked}>Blocked</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersArchived}>Archived</NavLink>
            </>
          }
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersRequested}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.ApprovedBuyersApproved}>Approved</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersRequested}>
                Requested
              </NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersBlocked}>Blocked</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersArchived}>Archived</NavLink>
            </>
          }
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersBlocked}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.ApprovedBuyersApproved}>Approved</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersRequested}>
                Requested
              </NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersBlocked}>Blocked</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersArchived}>Archived</NavLink>
            </>
          }
        />
        <Route
          path={`${GP_ROUTES.ApprovedBuyersArchived}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.ApprovedBuyersApproved}>Approved</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersRequested}>
                Requested
              </NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersBlocked}>Blocked</NavLink>
              <NavLink to={GP_ROUTES.ApprovedBuyersArchived}>Archived</NavLink>
            </>
          }
        />
        <Route
          path={`${GP_ROUTES.Firm}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.FirmTeamMembers}>Team Members</NavLink>
              <NavLink to={GP_ROUTES.FirmFunds}>Funds</NavLink>
            </>
          }
        />

        <Route
          path={`${GP_ROUTES.Compliance}/*`}
          element={
            <>
              <NavLink to={GP_ROUTES.ComplianceQualifiedMatchingService}>
                Qualified Matching Service
              </NavLink>
              <NavLink to={GP_ROUTES.ComplianceFairnessOpinions}>
                Fairness Opinions
              </NavLink>
            </>
          }
        />
      </Routes>
    </RowTabNavLinkWrapper>
  );
};
