import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";

import { approvedBuyersMock } from "./approvedBuyersMock";
import { ApprovedBuyer } from "../models/ApprovedBuyer";

interface IParams {}

export class GetApprovedBuyers
  implements IAsyncUseCase<IParams, ApprovedBuyer[]>
{
  call: (params: IParams) => Promise<Either<Failure, ApprovedBuyer[]>> =
    async (): Promise<Either<Failure, ApprovedBuyer[]>> => {
      return right([...approvedBuyersMock]);
    };
}
