import { BarLoader } from "common/components/BarLoader";
import { useParams } from "react-router";
import { MissingDocumentsState } from "./verify-permission/VerifyPermissionOverlay";
import { DataExtractionInProgress } from "experiences/common/DataExtractionInProgress";

export const FundDataWrapper = ({
  children,
  awaitingUpload,
  extractionInProgress,
  extractionReady,
  barLoader,
}: {
  children: React.ReactNode;
  awaitingUpload?: boolean;
  extractionInProgress?: boolean;
  extractionReady?: boolean;
  barLoader?: boolean;
}) => {
  const { txnId } = useParams();

  return (
    <div className="max-w-full w-full overflow-x-hidden relative min-h-96 max-h-screen overflow-y-scroll no-scrollbar pb-12">
      {barLoader && <BarLoader className="min-h-96" />}
      {awaitingUpload && !barLoader && <MissingDocumentsState txnId={txnId} />}
      {extractionInProgress && !barLoader && (
        <DataExtractionInProgress className="max-h-96" />
      )}
      {extractionReady && !barLoader && children}
    </div>
  );
};
