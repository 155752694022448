import { gql } from "@apollo/client";

export const GetPortfolioCsvCapitalAccountsQuery = gql`
  query FundSummaryExposure($entitiesIds: [String!]!, $reportDate: Date!) {
    dataSummaryCsv(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      casTabs {
        name
        data
      }
    }
  }
`;

export const GetPortfolioCsvExposureSummaryQuery = gql`
  query FundSummaryExposure($entitiesIds: [String!]!, $reportDate: Date!) {
    dataSummaryCsv(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      fundExposureSummaryTabs {
        name
        data
      }
    }
  }
`;

export const GetPortfolioCsvSoiQuery = gql`
  query FundSummaryExposure($entitiesIds: [String!]!, $reportDate: Date!) {
    dataSummaryCsv(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      soiLookThroughTabs {
        name
        data
      }
    }
  }
`;

export const GetPortfolioCsvCompanyLoanQuery = gql`
  query FundSummaryExposure($entitiesIds: [String!]!, $reportDate: Date!) {
    dataSummaryCsv(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      companyLoanDataTabs {
        name
        data
      }
    }
  }
`;

export const GetPortfolioCsvPerformanceQuery = gql`
  query FundSummaryExposure($entitiesIds: [String!]!, $reportDate: Date!) {
    dataSummaryCsv(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      portfolioPerformance {
        name
        data
      }
    }
  }
`;
