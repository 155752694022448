import { useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { Helmet } from "react-helmet";

import { Stack } from "@mui/material";
import Loader from "common/components/TapLoader";
import { PageHead } from "common/components/PageHead";
import {
  TapTable,
  TableAlignment,
  TableCell,
} from "experiences/funds/presentation/components/Table";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import Target from "common/components/Icons/Target";
import Globe from "common/components/Icons/Globe";
import { Button, BUTTON_TYPES, PlusIcon } from "common/components/Button";

import { LP_ROUTES } from "common/constants/routes";
import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import TextChip from "experiences/common/TextChip";
import { SearchInput } from "common/components/SearchInput";
import { TableFundLogo } from "common/components/TableFundLogo";
import { TRANSACTION_LOGO_URL } from "common/constants/platform";

import { useTransactionsContext } from "../state/TransactionsContext";
import { TransactionsLoaded } from "../state/TransactionsState";
import { ParticipantAccessStatus } from "../../domain/models/Transaction";
import {
  FundsStats,
  ILPLedTransaction,
  LPLedTransaction,
  TransactionStatus,
} from "../../domain/models/Transaction";
import { SignNDAModal } from "./ndas/SignNDAModal";
import { TransactionSummaryCell } from "./TransactionSummaryCell";

export const getActiveTransactions = (transactions: LPLedTransaction[]) => {
  return transactions.filter((txn) =>
    [TransactionStatus.Draft, TransactionStatus.Live].includes(
      txn.currentStatus,
    ),
  );
};
export const getPendingTransactions = (transactions: LPLedTransaction[]) => {
  return transactions.filter(
    (txn) => txn.currentStatus == TransactionStatus.Pending,
  );
};

const TransactionsTable = ({
  transactions,
  searchQuery,
}: {
  transactions: LPLedTransaction[];
  searchQuery?: string;
}) => {
  const navigate = useNavigate();
  const [showNdaModal, setShowNdaModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<
    LPLedTransaction | undefined
  >(undefined);

  const getSelectedTransaction = (transaction: LPLedTransaction) => {
    return transactions.find((txn) => txn.id === transaction.id);
  };

  // Go to transaction but check if NDA is required
  const handleViewTransactionClick = (transaction: ILPLedTransaction) => {
    const dataRoomUrl = generatePath(LP_ROUTES.TransactionDetails, {
      txnId: transaction.id,
    });

    if (
      [
        ParticipantAccessStatus.CanAccessDirectly,
        ParticipantAccessStatus.CanAccessWithAcknowledgement,
      ].includes(transaction.requesterAccessStatus)
    ) {
      navigate(dataRoomUrl);
    } else if (
      transaction.requesterAccessStatus ==
      ParticipantAccessStatus.HasToSignPlatformNDA
    ) {
      navigate(LP_ROUTES.DocumentsSignNDA);
    } else {
      setShowNdaModal(true);
      setSelectedTransaction(getSelectedTransaction(transaction));
    }
  };

  const filteredTransactions = searchQuery?.length
    ? transactions.filter((txn) => {
        return txn.name.toLowerCase().includes(searchQuery.toLowerCase());
      })
    : transactions;

  const showEmptyState = !searchQuery?.length && !filteredTransactions.length;

  return (
    <>
      <TapTable
        data={filteredTransactions}
        showEmptyState
        emptyStateTitle={
          showEmptyState
            ? "Add Transaction"
            : "There are no matches for your search."
        }
        emptyStateDescription={
          showEmptyState ? "Begin analyzing a new opportunity." : null
        }
        emptyStateActions={[
          ...(showEmptyState
            ? [
                {
                  label: "Create Transaction",
                  onClick: () => navigate(LP_ROUTES.TransactionsCreate),
                  icon: <PlusIcon />,
                  type: BUTTON_TYPES.SECONDARY,
                },
              ]
            : []),
        ]}
        columns={[
          {
            key: "name",
            label: "Project Name",
            align: TableAlignment.LEFT,
          },
          {
            key: "tags",
            label: "Tags",
            align: TableAlignment.LEFT,
          },
          {
            key: "summary",
            label: "Summary",
            align: TableAlignment.LEFT,
          },
          {
            key: "size",
            label: "Size",
            align: TableAlignment.LEFT,
          },
          {
            key: "actions",
            label: "",
            align: TableAlignment.LEFT,
          },
        ]}
        renderRow={(transaction: LPLedTransaction) => {
          const isNew = transaction.isNew;
          const isDraft = transaction.currentStatus === TransactionStatus.Draft;
          const isPending =
            transaction.currentStatus === TransactionStatus.Pending;
          const isActive = transaction.currentStatus === TransactionStatus.Live;

          const onRowClick = (transaction: LPLedTransaction) => {
            // only do this if transaction is active, otherwise it will be handled by the button
            if (isActive || isDraft) handleViewTransactionClick(transaction);
          };

          const logoUrl = `${TRANSACTION_LOGO_URL}/${transaction.id}.jpg`;

          return (
            <>
              <TableCell
                isPrimaryColumn
                alignTop
                {...((isActive || isDraft) && {
                  cursorPointer: true,
                  onClick: () => onRowClick(transaction),
                })}
              >
                <TableFundLogo imgSrc={logoUrl} fundName={transaction.name}>
                  <div className="ml-12">
                    {isNew && <TextChip text="new" success />}
                    {isPending && <TextChip text="pending" pending />}
                    {isDraft && <TextChip text="draft" pending />}
                    {isActive && <TextChip text="active" success />}
                  </div>
                </TableFundLogo>
              </TableCell>
              <TableCell
                alignTop
                {...((isActive || isDraft) && {
                  cursorPointer: true,
                  onClick: () => onRowClick(transaction),
                })}
                className="!max-w-96"
              >
                <div className="flex gap-2" style={{ flexFlow: "wrap" }}>
                  {transaction.stats instanceof FundsStats &&
                    transaction.stats.strategies.map((strategy) => (
                      <TextChip
                        icon={<Target color="#737476" />}
                        text={FundStrategyNameMap.get(strategy)!}
                        style={{ margin: 0 }}
                      />
                    ))}
                  {transaction.stats instanceof FundsStats &&
                    transaction.stats.geographies.map((geography) => (
                      <TextChip
                        icon={<Globe color="#737476" />}
                        text={<>{FundGeographyNameMap.get(geography)!}</>}
                        style={{ margin: 0 }}
                      />
                    ))}
                </div>
              </TableCell>
              <TableCell
                paddingless
                {...((isActive || isDraft) && {
                  cursorPointer: true,
                  onClick: () => onRowClick(transaction),
                })}
                className="align-top"
              >
                <TransactionSummaryCell transaction={transaction} />
              </TableCell>
              <TableCell
                alignTop
                {...((isActive || isDraft) && {
                  cursorPointer: true,
                  onClick: () => onRowClick(transaction),
                })}
              >
                {new DollarAmount(transaction.accumNav).formatted()}
              </TableCell>
              <TableCell
                hasActions
                {...((isActive || isDraft) && {
                  cursorPointer: true,
                  onClick: () => onRowClick(transaction),
                })}
              >
                {transaction.teaser && (
                  <Button
                    as="a"
                    href={transaction.teaser.presignedUrl}
                    target="_blank"
                  >
                    Download teaser
                  </Button>
                )}
              </TableCell>
            </>
          );
        }}
      />
      <SignNDAModal
        onClose={() => setShowNdaModal(false)}
        open={showNdaModal && !!selectedTransaction}
        txn={selectedTransaction!}
      />
    </>
  );
};

export const PendingTransactions = () => {
  const { txnsListState } = useTransactionsContext();
  const transactionsLoaded = txnsListState instanceof TransactionsLoaded;
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const pendingTransactions =
    (transactionsLoaded && getPendingTransactions(txnsListState.txns)) || [];
  const pendingTransactionsCount =
    transactionsLoaded && pendingTransactions.length;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleCreateTransactionClick = () => {
    navigate(LP_ROUTES.TransactionsCreate);
  };

  return (
    <>
      <Helmet title="Pending Transactions" />
      <PageHead
        title="Pending"
        {...(pendingTransactionsCount ? { hug: pendingTransactionsCount } : {})}
        actions={
          <div className="flex gap-4">
            <SearchInput
              onChange={handleSearchChange}
              placeholder="Search..."
              value={search}
              disabled={!transactionsLoaded}
            />
            <Button
              type={BUTTON_TYPES.SECONDARY}
              onClick={handleCreateTransactionClick}
            >
              Create Transaction
            </Button>
          </div>
        }
      />
      <Stack style={{ width: "100%" }}>
        {!transactionsLoaded && <Loader />}
        {transactionsLoaded && (
          <TransactionsTable
            transactions={pendingTransactions}
            searchQuery={search}
          />
        )}
      </Stack>
    </>
  );
};

export const ActiveTransactions = () => {
  const { txnsListState } = useTransactionsContext();
  const transactionsLoaded = txnsListState instanceof TransactionsLoaded;
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const activeTransactions =
    (transactionsLoaded && getActiveTransactions(txnsListState.txns)) || [];
  const activeTransactionsCount =
    transactionsLoaded && activeTransactions.length;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleCreateTransactionClick = () => {
    navigate(LP_ROUTES.TransactionsCreate);
  };

  return (
    <>
      <Helmet title="Active Transactions" />
      <PageHead
        title="Active"
        {...(activeTransactionsCount ? { hug: activeTransactionsCount } : {})}
        actions={
          <div className="flex gap-4">
            <SearchInput
              onChange={handleSearchChange}
              placeholder="Search..."
              value={search}
              disabled={!transactionsLoaded}
            />

            <Button
              type={BUTTON_TYPES.SECONDARY}
              onClick={handleCreateTransactionClick}
              icon={<PlusIcon />}
            >
              Create Transaction
            </Button>
          </div>
        }
      />
      <Stack style={{ width: "100%" }}>
        {!transactionsLoaded && <Loader />}
        {transactionsLoaded && (
          <TransactionsTable
            transactions={activeTransactions}
            searchQuery={search}
          />
        )}
      </Stack>
    </>
  );
};
