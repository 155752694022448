import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ICreateTransactionForm } from "../models/CreateTransactionForm";

export class CreateTransaction implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    form,
  }) => {
    console.log(form);
    return await new TapClient(`/transactions/create/`, form)
      .post()
      .then(() => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  form: ICreateTransactionForm;
}
