import csvToJson from "convert-csv-to-json";

import { performanceColumns, PerformanceKeys, PerformanceTabs } from "./model";
import {
  formatCellsCurrency,
  formatCellsDate,
  formatCellsMultiplier,
  formatCellsPercentage,
  generateScaffoldWorkbook,
  generateWorksheets,
  saveWorkbookToDownloads,
} from "experiences/common/excel/utils";

const fundExposureSummaryCurrencyColumns = [PerformanceKeys.Nav];

const fundExposureSummaryPercentageColumns = [PerformanceKeys.PortfolioPercent];

const fundExposureSummaryMultiplierColumns = [
  PerformanceKeys.GrossTvpi,
  PerformanceKeys.GrossDpi,
  PerformanceKeys.GrossMoic,
];
const fundExposureSummaryDateColumns = [];

const getDataByTabName = (data, tabName: string) => {
  const csvStr = data.find((tab) => tab.name === tabName)?.data;

  if (!csvStr) {
    return [];
  }

  return csvToJson
    .fieldDelimiter(",")
    .supportQuotedField(true)
    .csvStringToJson(csvStr);
};

export const createPerformanceExcel = async ({
  data,
  namePrefix,
}: {
  data: FundSummaryDataTab[];
  namePrefix: string;
}) => {
  const workbook = generateScaffoldWorkbook();

  const [fundExposureSummarySheet] = generateWorksheets({
    workbook,
    sheets: [
      {
        label: PerformanceTabs.PortfolioPerformance,
        columns: performanceColumns,
      },
    ],
  });

  // Cleaned up the data from the CSV string, get a real JSON object
  const fundExposureSummaryData = getDataByTabName(
    data,
    PerformanceTabs.PortfolioPerformance,
  );

  // Fund Exposure Summary
  fundExposureSummarySheet.addRows(fundExposureSummaryData);
  fundExposureSummaryData.forEach((row, index) => {
    const indexOffset = 2; // 1 is for the header row, 1 is because excel is 1-indexed

    // 1st format all the values to numbers / currency
    formatCellsCurrency({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryCurrencyColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 2nd format all the values to numbers / percentage
    formatCellsPercentage({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryPercentageColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 3rd format all the values to numbers / multiplier
    formatCellsMultiplier({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryMultiplierColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });

    // 4th format all the values to date
    formatCellsDate({
      worksheet: fundExposureSummarySheet,
      columns: fundExposureSummaryDateColumns,
      rowIndex: index,
      rowOffset: indexOffset,
    });
  });

  await saveWorkbookToDownloads(workbook, `${namePrefix}.xlsx`);
};
