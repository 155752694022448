export enum RegGeography {
  Unknown = 0,
  NortAmerica = 1,
  LatinAmerica = 2,
  Europe = 3,
  Australia = 4,
  MiddleEast = 5,
  Asia = 6,
  China = 7,
  Africa = 8,
}

export const RegGeographyLabels = {
  [RegGeography.Unknown]: "Unknown",
  [RegGeography.NortAmerica]: "North America",
  [RegGeography.LatinAmerica]: "Latin America",
  [RegGeography.Europe]: "Europe",
  [RegGeography.Australia]: "Australia",
  [RegGeography.MiddleEast]: "Middle East",
  [RegGeography.Asia]: "Asia",
  [RegGeography.China]: "China",
  [RegGeography.Africa]: "Africa",
};
