import { DEMO_TXN_ID } from "common/constants/platform";
import { IDataRoom } from "experiences/transactions/domain/models/DataRoom";
import {
  ITransaction,
  TransactionType,
} from "experiences/transactions/domain/models/Transaction";
import {
  documents,
  IDocument,
  ILPTenderTimelineStep,
  lpTenderTimelineSteps,
} from "experiences/transactions/presentation/mock/LP";
import { mockDR } from "./DataRoom";

// kept away separately from the GP mock on purpose
export interface ITenderOffer extends ITransaction {
  isClosed: boolean;
  name: string;
  alertCount?: number;
  referenceYear: number;
  referenceQuarter: number;
  offerDate: string;
  isRecurring?: boolean;
  timelineSteps: ILPTenderTimelineStep[];
  docs: IDocument[];
  dataRoom: IDataRoom;
  isAmountIndicated: boolean;
  tenderAmount: number;
  reservePrice: number;
  loiEmbedLink: string;
  isFinalized: boolean;
  image?: string;
}

class MockTenderOffer implements ITenderOffer {
  id: string;
  isClosed: boolean;
  name: string;
  alertCount?: number;
  referenceYear: number;
  referenceQuarter: number;
  offerDate: string;
  isRecurring?: boolean;
  timelineSteps: ILPTenderTimelineStep[];
  docs: IDocument[];
  dataRoom: IDataRoom;
  isAmountIndicated: boolean;
  tenderAmount: number;
  reservePrice: number;
  loiEmbedLink: string;
  isFinalized: boolean;
  image?: string;

  constructor({
    id,
    isClosed,
    alertCount,
    referenceYear,
    name,
    referenceQuarter,
    offerDate,
    isRecurring,
    timelineSteps,
    docs,
    dataRoom,
    isAmountIndicated,
    tenderAmount,
    reservePrice,
    loiEmbedLink,
    isFinalized,
    image,
  }: ITenderOffer) {
    this.id = id;
    this.isClosed = isClosed;
    this.alertCount = alertCount;
    this.referenceYear = referenceYear;
    this.referenceQuarter = referenceQuarter;
    this.name = name;
    this.offerDate = offerDate;
    this.isRecurring = isRecurring;
    this.timelineSteps = timelineSteps;
    this.docs = docs;
    this.dataRoom = dataRoom;
    this.isAmountIndicated = isAmountIndicated;
    this.tenderAmount = tenderAmount;
    this.reservePrice = reservePrice;
    this.loiEmbedLink = loiEmbedLink;
    this.isFinalized = isFinalized;
    this.image = image;
  }
  closingDate: string;
  type: TransactionType;
}

const transactionsList: MockTenderOffer[] = [
  new MockTenderOffer({
    id: DEMO_TXN_ID,
    name: "Fund III Q2 2024 Tender",
    isClosed: false,
    alertCount: 4,
    referenceYear: 2023,
    referenceQuarter: 4,
    offerDate: "2024 Q2",
    isRecurring: true,
    timelineSteps: lpTenderTimelineSteps,
    docs: documents,
    dataRoom: mockDR,
    isAmountIndicated: false,
    tenderAmount: 50_000_000,
    reservePrice: 1,
    loiEmbedLink:
      "https://app.pandadoc.com/document/a5258f656472377ce99876426cc6169c124d4dce",
    isFinalized: false,
    image: "ViewpointPartners.jpg",
    closingDate: "June 30, 2024",
    type: TransactionType.Tender,
  }),
];

export { transactionsList, MockTenderOffer };
