import { Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import FormikTextField from "../../../../../common/components/formik/FormikTextField";
import { InitialSignupPageSubmitted } from "../../state/AuthenticationEvent";
import { useAuthContext } from "../../state/AuthenticationContext";
import { BUTTON_TYPES, Button } from "common/components/Button";

export const InitialSignup = () => {
  const { emitEvent } = useAuthContext();
  const validationSchema = yup.object({
    zipCode: yup.string().min(5).required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      zipCode: "",
    },
    validationSchema,
    onSubmit: () => {
      emitEvent!(
        new InitialSignupPageSubmitted({
          regForm: {
            zipcode: formik.values.zipCode,
          },
        }),
      );
    },
  });
  return (
    <Stack spacing={4}>
      <Stack direction="column" spacing={2}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", color: "#848484" }}
        >
          Primary Residence
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormikTextField
            name="zipCode"
            formik={formik}
            label="Zip Code"
            autoComplete="zipCode"
            mask="zipCode"
            fullWidth
          />
        </form>
      </Stack>
      <Stack spacing={2}>
        <Button
          size="large"
          onClick={formik.submitForm}
          fullWidth
          type={BUTTON_TYPES.SECONDARY}
        >
          Continue
        </Button>
        <span>
          <Typography color="#848484" display="inline">
            If you are not a resident of the United States,{" "}
          </Typography>
          <Button
            onClick={() => {
              emitEvent!(
                new InitialSignupPageSubmitted({
                  regForm: {},
                }),
              );
            }}
          >
            skip this step.
          </Button>
        </span>
      </Stack>
    </Stack>
  );
};
