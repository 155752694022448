import { useState } from "react";
import styled from "styled-components";

import { PageHead } from "common/components/PageHead";
import { Timeline } from "./Timeline";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import {
  ProjectTeaser,
  TeaserFile,
} from "experiences/transactions/presentation/components/timeline/ProjectTeaser";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { Button } from "common/components/Button";
import UpsertTimelineModal from "./UpsertTimelineModal";
import { ParticipantCapacity } from "experiences/transactions/domain/models/Participant";
import Loader from "common/components/TapLoader";
import { EmptyState } from "common/components/EmptyState";
import { ListChecks } from "@phosphor-icons/react";

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.14px;
  color: #000;
  text-align: left;
  cursor: default;
`;

const Paragraph = styled(Text)``;

const UnorderedList = styled(Text)`
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  width: 100%;
  text-align: left;
  align-items: flex-start;
  p {
    margin-bottom: 8px;
  }
  li {
    width: 100%;
    margin-left: 32px;
    margin-bottom: 8px;
  }
`;

const useTransactionTimeline = () => {
  const teaserFiles: TeaserFile[] = [
    {
      name: "Safari Teaser Nov 23.pdf",
      size: 402,
      url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      type: "application/pdf",
    },
    {
      name: "Safari Teaser Nov 23.pdf",
      size: 402,
      url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      type: "application/pdf",
    },
    {
      name: "Safari Teaser Nov 23.pdf",
      size: 402,
      url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      type: "application/pdf",
    },
  ];

  return {
    teaserFiles,
  };
};

const SHOW_SUMMARY = false;
const SHOW_HIGHLIGHTS = false;
const SHOW_TEASER = false;

const Summary = () => {
  return (
    <>
      <PageHead title="Summary" />
      <Paragraph style={{ marginTop: -16 }} as="p">
        An existing LP in ViewPoint Venture Capital Fund I (“Fund I”, or the
        “Fund”) is looking to sell up to 50% of its position in the Fund
        representing ~$50m of NAV as of Q1 2023.
      </Paragraph>
    </>
  );
};

const Highlights = () => {
  return (
    <>
      <PageHead title="Highlights" />
      <UnorderedList as="ul">
        <p>
          Founded in 2017, Signals Venture Capital is an early-stage Venture
          Capital Firm
        </p>
        <li>
          As at Q1 2023 the Fair Market Value (“FMV”) of Fund I (2018 vintage)
          is $110m. ~84% of the Fund is contributed
        </li>
        <li>
          The Fund has 17 active portfolio companies across enterprise tech,
          mobility, fintech and SME tech sectors. Stage of development varies
          from early stage to growth stage and the assets are generally held in
          minority positions (&ld;15%)
        </li>
        <li>
          As at Q1 2023 the core portfolio (investments executed under current
          team), generated a 40% IRR and a 2.5x MOIC
        </li>
        <li>
          Largest asset (~40%) at $42m FMV is AlaskaCo, a leading digital
          software platform valued at 5.4x MOIC
        </li>
        <li>
          2nd largest (~11%) at $14m FMV is DakotaCo, an tech as a service
          provider valued at 2.5x MOIC
        </li>
        <li>
          3rd largest (~10%), legacy asset, at $10m FMV is MontanaCo, a tech
          company builder valued at 1.3x MOIC
        </li>
        <li>
          On average, the investments are valued at a 20% discount compared to
          last post-money valuations
        </li>
        <li>
          Five investments have been made to date in Fund II and the GP is
          targeting ~20-30 assets in North America over 3-5 years
        </li>
        <li>
          Management with Top-quartile performance since 2010 (30% IRR, 5.5x
          MOIC)
        </li>
      </UnorderedList>
    </>
  );
};

const Teaser = () => {
  const { teaserFiles } = useTransactionTimeline();
  return (
    <>
      <PageHead title="Project Teaser" />
      <ProjectTeaser files={teaserFiles} />
    </>
  );
};

export const TransactionTimeline = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const [showModal, setShowModal] = useState(false);
  if (!summary) return <Loader />;

  // If there's only 1 period, we want to show the empty state (period would be transaction closing date)
  const isTimelineEmpty = summary.transaction.periods?.length <= 1;

  return (
    <div>
      {SHOW_SUMMARY && <Summary />}
      {SHOW_HIGHLIGHTS && <Highlights />}
      {SHOW_TEASER && <Teaser />}

      <PageSectionTitleDivider
        actions={
          summary.viewerParticipant?.capacity != ParticipantCapacity.Buyer && (
            <Button
              size="medium"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        Timeline
      </PageSectionTitleDivider>
      {isTimelineEmpty ? (
        <EmptyState
          title="Timeline Not Available"
          description="The seller has not yet uploaded a timeline for this transaction."
          icon={<ListChecks size={38} className="fill-nomad-300 mb-4" />}
        />
      ) : (
        <Timeline timeline={summary.transaction.periods} />
      )}
      <UpsertTimelineModal
        existingTimeline={summary.transaction.periods}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        txnId={summary.transaction.id}
      />
    </div>
  );
};
