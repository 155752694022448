import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { DollarAmount } from "../../../../../common/@types/app/DollarAmount";
import { IHolding } from "../../../domain/models/Holding";
import { usePortfolioContext } from "../../state/PortfolioContext";
import { SubmitSellInterestDonePressed } from "../../state/PortfolioEvent";
import { PageHead } from "common/components/PageHead";
import { StyledList } from "experiences/common/List";
import { BUTTON_TYPES, Button } from "common/components/Button";

const TransactionReviewStep = ({
  state,
}: {
  state: {
    transactionName: string;
    accumNav: DollarAmount;
    targetCloseDate: string;
    selectedHoldings: IHolding[];
  };
}) => {
  const { emitEvent } = usePortfolioContext();
  return (
    <Stack p={8}>
      <Stack maxWidth={540} spacing={4}>
        <div>
          <PageHead
            title="Review Transaction"
            description="Review the details of your transaction. The deal will only be shared as you add and approve potential buyers."
          />
        </div>
        <Stack
          divider={<Divider />}
          sx={{
            border: "1px solid #E7E8EC",
            borderRadius: "6px",
          }}
        >
          {[
            {
              label: "Transaction Name",
              value: state.transactionName,
            },
            {
              label: "NAV",
              value: state.accumNav.formatted(),
            },
            {
              label: "Target Close Date",
              value: new Date(state.targetCloseDate).toDateString(),
            },
          ].map((detail) => (
            <TransactionDetail {...detail} />
          ))}
        </Stack>
        <StyledList>
          <table className={`dashboard_table dont_hover`}>
            <thead>
              <tr>
                <th>Asset</th>
                <th className="dashboard_table-value">NAV</th>
              </tr>
            </thead>
            <tbody>
              {state.selectedHoldings.map((holding: IHolding) => {
                return (
                  <tr>
                    <td>{holding.issuer.name}</td>
                    <td className="dashboard_table-value">
                      {new DollarAmount(holding.bookValue).formattedBig()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </StyledList>

        <Button
          size="large"
          onClick={() => {
            emitEvent!(new SubmitSellInterestDonePressed());
          }}
          fullWidth
          type={BUTTON_TYPES.SECONDARY}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

interface ITransactionDetailProps {
  label: string;
  value: string;
}

export const TransactionDetail: React.FC<ITransactionDetailProps> = ({
  label,
  value,
}) => {
  return (
    <Stack direction="row">
      <Box
        height={54}
        width={224}
        sx={{ backgroundColor: "#F5F4F1" }}
        pl={4}
        pt={2}
      >
        <Typography fontWeight="bold">{label}</Typography>
      </Box>
      <Box height={54} width={296} pl={4} pt={2}>
        <Typography color="#737476">{value}</Typography>
      </Box>
    </Stack>
  );
};

export default TransactionReviewStep;
