import { IRegistrationForm } from "../../domain/models/RegForm";
import { TabType } from "../../domain/models/TabType";

export class AuthenticationEvent {}

export class LoginEvent extends AuthenticationEvent {}

export class SendLoginEmailButtonClicked extends LoginEvent {
  email: string;

  constructor(email: string) {
    super();
    this.email = email;
  }
}

export class LogoutEvent extends AuthenticationEvent {}

export class CurrentUserLogoutRequested extends LogoutEvent {}

export class LoginSessionClaimEvent extends AuthenticationEvent {}

export class LoginSessionClaimLinkActivated extends LoginSessionClaimEvent {
  idb64: string;
  token: string;

  constructor({ idb64, token }: { idb64: string; token: string }) {
    super();
    this.idb64 = idb64;
    this.token = token;
  }
}

export class AuthedAppBarEvent extends AuthenticationEvent {}

export class ResetLoginState extends AuthenticationEvent {}

export class RegisterButtonClickedOnLoginFlow extends AuthenticationEvent {}

export class LoginButtonClickedOnRegFlow extends AuthenticationEvent {}

export class SetSelectedTabInAuthedAppBar extends AuthedAppBarEvent {
  tab: TabType;

  constructor({ tab }: { tab: TabType }) {
    super();
    this.tab = tab;
  }
}

export class ImpersonateUserFormSubmitted extends AuthedAppBarEvent {
  userId: string;
  reason: string;

  constructor({ userId, reason }: { userId: string; reason: string }) {
    super();
    this.userId = userId;
    this.reason = reason;
  }
}

export class StopImpersonationClicked extends AuthenticationEvent {}

export class RedirectedToInviteeOnboarding extends AuthenticationEvent {
  inviteeId?: string;

  constructor({ inviteeId }: { inviteeId?: string }) {
    super();
    this.inviteeId = inviteeId;
  }
}

export class RegistrationFlowEvent extends AuthenticationEvent {
  regForm: IRegistrationForm;

  constructor({ regForm }: { regForm: IRegistrationForm }) {
    super();
    this.regForm = regForm;
  }
}

export class OTPLoginSubmitted extends AuthenticationEvent {
  code: number;
  challengeId: string;

  constructor({ code, challengeId }: { code: number; challengeId: string }) {
    super();
    this.code = code;
    this.challengeId = challengeId;
  }
}

export class InitialSignupPageSubmitted extends RegistrationFlowEvent {}

export class CreateYourAccountFormSubmitted extends RegistrationFlowEvent {}

export class SelectYourOrganizationFormSubmitted extends RegistrationFlowEvent {}

export class SelectPreferencesFormSubmitted extends RegistrationFlowEvent {}

export class SelectGeographiesFormSubmitted extends RegistrationFlowEvent {}

export class SelectPreferences extends RegistrationFlowEvent {}
