import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageHead } from "common/components/PageHead";
import { EmptyState } from "common/components/EmptyState";
import { TransactionNDATable } from "../components/transaction-settings/TransactionNDATable";
import { useTransactionsContext } from "../state/TransactionsContext";
import { LoadTransactionNDAs } from "../state/TransactionsEvent";
import { TransactionSettingsNDAsLoaded } from "../state/TransactionsState";

export const TransactionSettingsNDAs = () => {
  const { transactionNDAState, emitEvent } = useTransactionsContext();

  useEffect(() => {
    emitEvent!(new LoadTransactionNDAs());
  }, []);

  const ndas =
    transactionNDAState instanceof TransactionSettingsNDAsLoaded
      ? transactionNDAState.transactionNDAs
      : [];

  return (
    <>
      <Helmet title="Transaction NDAs" />
      <PageHead title="NDAs" countLabel={ndas.length} />
      {transactionNDAState instanceof TransactionSettingsNDAsLoaded ? (
        <TransactionNDATable transactionNDAs={ndas} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
