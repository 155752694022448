import React from "react";
import MaskedInput from "react-text-mask";

const YearlyQuarterMask = ({ maskOptions, ...inputProps }: any) => (
  <MaskedInput
    {...inputProps}
    mask={[/\d/, "Q", " ", /\d/, /\d/, /\d/, /\d/]}
  />
);

export default YearlyQuarterMask;
