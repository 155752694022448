import AddHoldingModal from "experiences/portfolio/presentation/pages/AddHoldingModal";
import { useFundsContext } from "../../state/FundsContext";
import {
  ShowConfirmHoldingsScreen,
  ShowGPAccessRequestReceiptScreen,
  VerifyPermissionState,
} from "../../state/FundsState";
import AccessSelectionScreen from "./AccessSelectionScreen";
import { useParams } from "react-router";
import GPMessageConfirmScreen from "./GPMessageConfirmScreen";
import { VerifyPermissionFlowClosed } from "../../state/FundsEvent";

const VerifyPermissionFlow = () => {
  const { verifyPermState, emitEvent } = useFundsContext();

  const { fundId } = useParams();

  const closeFlow = () => {
    emitEvent!(new VerifyPermissionFlowClosed({ fundId: fundId! }));
  };

  const buildBasedOnState = (state: VerifyPermissionState) => {
    if (state instanceof ShowGPAccessRequestReceiptScreen) {
      return <GPMessageConfirmScreen onClose={closeFlow} />;
    }
    return <AccessSelectionScreen onClose={closeFlow} />;
  };
  return (
    <>
      {buildBasedOnState(verifyPermState!)}
      <AddHoldingModal
        open={verifyPermState instanceof ShowConfirmHoldingsScreen}
        lockedFund={
          verifyPermState instanceof ShowConfirmHoldingsScreen
            ? verifyPermState.fund
            : undefined
        }
        onClose={closeFlow}
      />
    </>
  );
};

export default VerifyPermissionFlow;
