import { Either, left, right } from "fp-ts/lib/Either";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { Failure } from "common/@types/app/Failure";
import { ITenderDoc } from "./DownloadOfferDoc";

export enum TenderDocType {
  PDF = 1,
  Spreadsheet = 2,
  Word = 3,
  PowerPoint = 4,
}

export class ListOfferDocs implements IAsyncUseCase<IParams, ITenderDoc[]> {
  call: (params: IParams) => Promise<Either<Failure, ITenderDoc[]>> = async ({
    txnId,
  }) => {
    return await new TapClient(`/transactions/tenders/${txnId}/offer-docs/`)
      .get({})
      .then((resp: ITenderDoc[]) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  txnId: string;
}
