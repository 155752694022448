export enum Strategy {
  VentureCapital = "VentureCapital",
  PrivateEquity = "PrivateEquity",
  HedgeFunds = "HedgeFunds",
  FundOfFunds = "FundOfFunds",
  RealEstate = "RealEstate",
  PrivateDebt = "PrivateDebt",
  Growth = "Growth",
  Secondaries = "Secondaries",
  Infrastructure = "Infrastructure",
  NaturalResources = "NaturalResources",
  Other = "Other",
}

export enum Geography {
  NorthAmerica = "NorthAmerica",
  Europe = "Europe",
  Asia = "Asia",
  LatinAmerica = "LatinAmerica",
  Australia = "Australia",
  MiddleEast = "MiddleEast",
  Africa = "Africa",
}

export const strategyLabels = {
  [Strategy.VentureCapital]: "Venture Capital",
  [Strategy.PrivateEquity]: "Private Equity",
  [Strategy.HedgeFunds]: "Hedge Funds",
  [Strategy.FundOfFunds]: "Fund of Funds",
  [Strategy.RealEstate]: "Real Estate",
  [Strategy.PrivateDebt]: "Private Debt",
  [Strategy.Growth]: "Growth",
  [Strategy.Secondaries]: "Secondaries",
  [Strategy.Infrastructure]: "Infrastructure",
  [Strategy.NaturalResources]: "Natural Resources",
  [Strategy.Other]: "Other",
};

export const geographyLabels = {
  [Geography.NorthAmerica]: "North America",
  [Geography.Europe]: "Europe",
  [Geography.Asia]: "Asia",
  [Geography.LatinAmerica]: "Latin America",
  [Geography.Australia]: "Australia",
  [Geography.MiddleEast]: "Middle East",
  [Geography.Africa]: "Africa",
};

export interface IFundExposureSummaryEntry {
  tapFundId: string;
  fundName: string;
  tapManagerId: string;
  managerName: string;
  fundSize?: string;
  fundCommittedCapital?: string;
  strategy?: Strategy | null;
  vintage?: number;
  geography?: Geography | null;
  reportDate?: string;
  lpCommittedCapital?: string;
  lpContributedCapitalItd?: string;
  lpDistributedCapitalItd?: string;
  lpRemainingUnfunded?: string;
  lpEndingCapitalAccountValue?: string;
  lpCurrentExposure?: string;
  lpInterimContributions?: string;
  lpInterimDistributions?: string;
  unrealizedInvestmentCount?: string;
  top3Concentration?: string;
  top10Concentration?: string;
  percentPublic?: string;
  weightedAvgRevenueGrowth?: string;
  weightedAvgEbitdaMargin?: string;
  weightedAvgEbitdaMultiple?: string;
  weightedAvgLeverage?: string;
  fundNavQoqPercentChg1q?: string | number;
  fundNavQoqPercentChg2q?: string | number;
  fundNavQoqPercentChg3q?: string | number;
  fundNavYoyPercentChangeFy1?: string | number;
  fundNavYoyPercentChangeFy2?: string | number;
  tapPriceEstimate?: string;
  tapPriceEstimateCertainty?: string;
  baseManagementFee?: string;
  carry?: string;
  hurdleRate?: string;
  ptpRiskLevel?: number;
  ptpQueueLength?: number;
  rofr?: boolean;
  rofo?: boolean;
  blocker?: boolean;
  domicile?: string;
  fundSeriesAverageMoic?: string;
  isHumanVerified?: boolean;
  issuerId?: string;
}

export interface IFundExposureSummary {
  fundSummaryExposure: IFundExposureSummaryEntry[]; // maybe update the backend to read fundExposeSummary instead of fundSummaryExposure
}

export interface IFundExposureSummaryEntryWithAddedFields
  extends IFundExposureSummaryEntry {
  transactionPercent: number;
}
