import * as React from "react";
const DocumentPen = (props: {}) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1857_3385)">
      <path
        d="M14.0972 3.6084V8.50336H18.9921"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1953 20.3911H18.2932C18.4786 20.3911 18.6565 20.3174 18.7876 20.1863C18.9188 20.0552 18.9924 19.8773 18.9924 19.6918V8.50336L14.0975 3.6084H5.70612C5.52066 3.6084 5.34279 3.68207 5.21165 3.81321C5.08051 3.94435 5.00684 4.12222 5.00684 4.30768V11.9998"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0317 20.4209H7.22168"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22106 18.4205H5.29804C5.21304 18.4205 5.13152 18.3867 5.07141 18.3266C5.01131 18.2665 4.97754 18.185 4.97754 18.1V16.3092C4.9774 16.2675 4.98546 16.2263 5.00127 16.1878C5.01708 16.1493 5.04033 16.1143 5.06969 16.0848L9.87723 11.2773C9.90706 11.247 9.94261 11.2229 9.98181 11.2065C10.021 11.1901 10.0631 11.1816 10.1056 11.1816C10.1481 11.1816 10.1902 11.1901 10.2294 11.2065C10.2686 11.2229 10.3041 11.247 10.334 11.2773L12.1208 13.0641C12.151 13.0939 12.1751 13.1294 12.1915 13.1686C12.2079 13.2078 12.2164 13.2499 12.2164 13.2924C12.2164 13.3349 12.2079 13.377 12.1915 13.4162C12.1751 13.4554 12.151 13.491 12.1208 13.5208L7.22106 18.4205Z"
        stroke="#BCB3A5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.82324 12.3311L11.0668 14.5746"
        stroke="#BCB3A5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1857_3385">
        <rect
          width={22.377}
          height={22.377}
          fill="white"
          transform="translate(0.811035 0.811523)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default DocumentPen;
