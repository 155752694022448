import { Either, left } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { GetCurrentUser } from "./GetCurrentUser";
import { ICurrentUser } from "../models/CurrentUser";

interface IParams {
  userId: string;
  reason: string;
}

export class SubmitImpersonationForm
  implements IAsyncUseCase<IParams, NoReturn>
{
  call: (params: IParams) => Promise<Either<Failure, ICurrentUser>> = async ({
    userId,
    reason,
  }) => {
    return await new TapClient("/impersonate/", {
      userId,
      reason,
    })
      .post()
      .then(() => {
        return new GetCurrentUser().call({});
      })
      .catch((resp: any) => {
        return left(new Failure(resp.response.data?.error));
      });
  };
}
