import TextField from "../TextField";

const FormikTextField = ({
  formik,
  name,
  additionalOnChange = (event: any) => {},
  TextFieldComponent = TextField,
  ...props
}) => {
  return (
    <TextFieldComponent
      {...props}
      name={name}
      value={formik.values[name]}
      onChange={(event) => {
        formik.handleChange(event);
        additionalOnChange(event);
      }}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  );
};

export default FormikTextField;
