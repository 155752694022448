const steps = [
  {
    isActive: true,
    label: "Request Received",
    description: "Our team has been notified of your redlining request",
  },
  {
    isActive: false,
    label: "Adjust & Review Document ",
    description: "Changes to the document are made and reviewed offline",
  },
  {
    isActive: false,
    label: "Finalize & Sign",
    description: "Once all changes are agreed, the document is signed",
  },
];

export const useRedliningStepsContext = () => {
  return { steps };
};
