import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import TextChip from "experiences/common/TextChip";
import { Button } from "common/components/Button";
import { FileArrowDown } from "@phosphor-icons/react/dist/ssr";
import { QmsEntry } from "experiences/compliance/domain/models/QmsEntry";
import { generatePath, useNavigate } from "react-router";
import { GP_ROUTES } from "common/constants/routes";
import { firmOverviewMock } from "experiences/compliance/domain/mock/FirmOverviewMock";

const tableColumns = [
  {
    key: "name",
    label: "Name",
    align: TableAlignment.LEFT,
  },
  {
    key: "qmsEnabled",
    label: "QMS Enabled",
    align: TableAlignment.LEFT,
  },
  {
    key: "qmsTurnover",
    label: "QMS Turnover",
    align: TableAlignment.LEFT,
  },
  {
    key: "nonQmsTurnover",
    label: "Non-QMS Turnover",
    align: TableAlignment.LEFT,
  },
  {
    key: "status",
    label: "Status",
    align: TableAlignment.LEFT,
  },
  {
    key: "actions",
    label: "",
    align: TableAlignment.RIGHT,
  },
];

export const QualifiedMatchingServiceTable = ({
  qmsEntries,
}: {
  qmsEntries: QmsEntry[];
}) => {
  const navigate = useNavigate();

  const handleDownloadQmsCertificateClick = (qmsEntry: QmsEntry) => {
    console.log("download", qmsEntry);
  };

  const handleRowClick = (qmsEntry: QmsEntry) => {
    console.log("row click", qmsEntry);
    navigate(
      generatePath(GP_ROUTES.ComplianceFirmOverviewDetail, {
        firmId: firmOverviewMock.firmId,
      }),
    );
  };

  return (
    <>
      <TapTable
        data={qmsEntries}
        emptyStateTitle={
          !qmsEntries.length ? "There are no matches to display." : null
        }
        columns={tableColumns}
        renderRow={(qmsEntry: QmsEntry) => {
          return (
            <>
              <TableCell
                isPrimaryColumn
                hasBullet
                onClick={() => handleRowClick(qmsEntry)}
              >
                {qmsEntry.name}
              </TableCell>
              <TableCell onClick={() => handleRowClick(qmsEntry)}>
                <TextChip
                  text={qmsEntry.qmsEnabled}
                  success={qmsEntry.qmsEnabled.toLocaleLowerCase() === "yes"}
                />
              </TableCell>
              <TableCell onClick={() => handleRowClick(qmsEntry)}>
                {qmsEntry.qmsTurnover}
              </TableCell>
              <TableCell onClick={() => handleRowClick(qmsEntry)}>
                {qmsEntry.nonQmsTurnover}
              </TableCell>
              <TableCell onClick={() => handleRowClick(qmsEntry)}>
                <TextChip
                  text={qmsEntry.status}
                  success={
                    qmsEntry.status.toLocaleLowerCase() === "safe harbor"
                  }
                />
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => handleDownloadQmsCertificateClick(qmsEntry)}
                  icon={<FileArrowDown />}
                >
                  QMS Certificate
                </Button>
              </TableCell>
            </>
          );
        }}
      />
    </>
  );
};
