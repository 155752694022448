import { Box, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  CaretDown,
  CaretRight,
  DownloadSimple,
  File,
  FilePdf,
  FolderNotch,
  FolderSimpleDashed,
  Upload,
} from "@phosphor-icons/react";

import { IDataRoom, IItem } from "../../../domain/models/DataRoom";
import { useTransactionsContext } from "../../state/TransactionsContext";
import {
  DowloadDataRoomFilePressed,
  DownloadDataRoomZipPressed,
  LoadTransactionDetails,
} from "../../state/TransactionsEvent";

import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  TableAlignment,
} from "experiences/funds/presentation/components/Table";
import { BUTTON_TYPES, Button } from "common/components/Button";

import { PageHead } from "common/components/PageHead";
import { ReuploadDataroomModal } from "./ReuploadDataRoomModal";
import { ProcessDataRoomFileFinished } from "../../state/TransactionsState";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { ParticipantListLoaded } from "../../state/participants/ParticipantsState";
import { ParticipantCapacity } from "experiences/transactions/domain/models/Participant";
import { BarLoader } from "common/components/BarLoader";
import { useQuery } from "@apollo/client";
import { GetTransactionDataroomQuery } from "experiences/transactions/domain/usecases/GetTransactionDataroomQuery";
import { transactionsClient } from "common/clients/ApolloClient";
import { useLocation, useNavigate, useParams } from "react-router";
import { LP_ROUTES } from "common/constants/routes";
import { TransactionDetailsRoutes } from "../TransactionDetails";
import { TransactionFundDataRoutes } from "../fund-data/TransactionFundDataScreen";

const SIZE_COLUMN_WIDTH_PX = 150;

const EMPTY_SIZE = "0.0 MB";

const EMPTY_DATAROOM = []; // Keep as constant https://github.com/TanStack/table/issues/4240#issuecomment-1200893711

const useReuploadDataroom = () => {
  const [open, setOpen] = useState(false);

  const handleReuploadDataroomClick = () => {
    setOpen(true);
  };

  const closeReuploadDataroom = () => {
    setOpen(false);
  };

  return {
    open,
    handleReuploadDataroomClick,
    closeReuploadDataroom,
  };
};

const DataRoom = () => {
  const { txnId } = useParams();
  const { emitEvent, dataroomUploadState, participantsListState } =
    useTransactionsContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const currentPath = useLocation();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowHovered, setRowHovered] = useState<string>("");
  const { handleReuploadDataroomClick, closeReuploadDataroom, open } =
    useReuploadDataroom();

  const { data, loading, refetch, error } = useQuery<{
    dataroom?: IDataRoom;
  }>(GetTransactionDataroomQuery, {
    client: transactionsClient,
    variables: { transactionId: txnId },
  });

  const isFolder = (itemName: string, hasItems: boolean) => {
    return hasItems || !`${itemName}`.match(/\.[0-9a-z]+$/i);
  };

  const columns = useMemo<ColumnDef<IItem>[]>(
    () => [
      {
        accessorKey: "name",
        header: () => "Name",
        id: "document",
        cell: ({ row, getValue }) => {
          const isEmpty = row.original.size === EMPTY_SIZE;
          const isFolderValue = isFolder(
            `${getValue()}`,
            Boolean(row.getIsExpanded()),
          );
          const isFile = !isFolderValue;

          return (
            <div
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
              className="block relative h-4"
              onMouseEnter={() => {
                setRowHovered(() => row.id);
              }}
              onMouseLeave={() => {
                setRowHovered(() => {
                  return "";
                });
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignContent={"center"}
                alignItems={"center"}
                className="relative"
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    minWidth: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flex: 1,
                    lineHeight: "100%",
                  }}
                  className="relative"
                >
                  <Stack
                    direction="row"
                    sx={{ cursor: "pointer" }}
                    alignItems="center"
                  >
                    {isFile && <File color="#737476" size={16} />}
                    {isFolderValue && isEmpty && (
                      <FolderSimpleDashed
                        color="#737476"
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                    )}
                    {isFolderValue && !isEmpty && (
                      <FolderNotch
                        color="#737476"
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                    )}
                    {isFolderValue && !isEmpty && row.getIsExpanded() && (
                      <CaretDown color="#737476" size={16} />
                    )}
                    {isFolderValue && !isEmpty && !row.getIsExpanded() && (
                      <CaretRight color="#737476" size={16} />
                    )}
                  </Stack>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${getValue()}`}
                  </div>
                </Stack>
                {!isFolder(`${getValue()}`, Boolean(row.getIsExpanded())) && (
                  <Button
                    icon={<DownloadSimple />}
                    onClick={(e) => handleDownload(row.original, e)}
                    style={{ marginLeft: 8 }}
                    size="small"
                    className={classNames(
                      "!absolute !right-0 -translate-y-1/2 top-1/2 !opacity-0 !pointer-events-none group-hover:!opacity-100 group-hover:!pointer-events-auto transition-opacity",
                    )}
                  >
                    Download
                  </Button>
                )}
              </Stack>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.size,
        id: "size",
        cell: (info) => {
          const size = info.getValue() as String;

          // Split the size into the number and the unit, and convert the number to a float, then use only 1 decimal place
          const [number, unit] = size.split(" ");
          const sizeNumber = parseFloat(number).toFixed(1);

          return (
            <div className="whitespace-nowrap select-none">
              {sizeNumber} {unit}
            </div>
          );
        },
        header: () => <div>Size</div>,
      },
    ],
    [],
  );

  const preData = useMemo(() => {
    return data?.dataroom?.directory?.items || EMPTY_DATAROOM;
  }, [data]);

  const table = useReactTable({
    columns,
    // ------------------------------
    // ------------------------------
    // ------------------------------
    // @MO this is what existed before but it breaks for some reason
    // Please let's verify this change doesn't break anything else
    // PREVIOUS 👇
    // data: dataRoom.directory.items[0].items!,
    // NEW 👇
    data: preData,
    // ------------------------------
    // ------------------------------
    // ------------------------------
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows: (row: IItem) => row.items,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    // Keep the open condition in the useEffect to avoid infinite reloading of the transaction details
    if (dataroomUploadState instanceof ProcessDataRoomFileFinished && open) {
      if (!txnId) {
        return;
      }
      // emitEvent!(new LoadTransactionDetails({ txnId }));
      refetch();
    }
  }, [dataroomUploadState]);

  const currUserParticipant =
    participantsListState instanceof ParticipantListLoaded
      ? participantsListState.participants.find(
          (participant) => participant.userId === user?.id,
        )
      : null;

  const currUserParticipantIsBuyer =
    currUserParticipant?.capacity === ParticipantCapacity.Buyer;
  const isUploadButtonVisible = !currUserParticipantIsBuyer;

  const handleDownloadAll = () => {
    emitEvent!(
      new DownloadDataRoomZipPressed({
        dataroom: data?.dataroom,
        txnId,
      }),
    );
  };

  const handleDownload = (
    item: IItem,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // prevent from "double clicking" if the click happens on the button
    e.stopPropagation();

    emitEvent!(
      new DowloadDataRoomFilePressed({
        item: item,
        txnId,
      }),
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <PageHead
          title="Documents"
          actions={
            <>
              {isUploadButtonVisible && (
                <>
                  <Button
                    icon={<Upload />}
                    onClick={handleReuploadDataroomClick}
                  >
                    Upload Dataroom
                  </Button>
                </>
              )}
              <Button
                icon={<DownloadSimple />}
                onClick={handleDownloadAll}
                type={BUTTON_TYPES.SECONDARY}
              >
                Download All
              </Button>
            </>
          }
        />

        <div>
          <StyledTable className="table-fixed">
            <StyledTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <StyledTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    // if is size column, set a fixed width for the column
                    const isSizeColumn = header.id === "size";

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: isSizeColumn ? SIZE_COLUMN_WIDTH_PX : "auto",
                        }}
                        className={`${TableAlignment.LEFT} relative`}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </>
                        )}
                      </th>
                    );
                  })}
                </StyledTableRow>
              ))}
            </StyledTableHead>

            {loading && <BarLoader />}

            <StyledTableBody>
              {error && (
                <div className="text-sm p-8 select-none text-stone-400 w-full text-center">
                  There was an error loading the data room. Please try again
                  later.
                </div>
              )}
              {table.getRowModel().rows.map((row) => {
                return (
                  <StyledTableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      // if is size column, set a fixed width for the column
                      // otherwise, let the column size be determined by the content
                      // but this only applies to this table, to prevent bad overflows
                      // we'll manually set an whitespace wrap on the cell if it's not the size column
                      //
                      // if we see overlapping text in other columns, we can do the same thing
                      // but for now, this is the only column that needs it
                      const isDocumentColumn = cell.column.id === "document";

                      // for some reason the ellipsis is not showing up
                      const documentColumnStyles = {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whitespace: "nowrap",
                      };

                      // Hide Mac store files
                      const isDsStore = cell.row.original.name === ".DS_Store";

                      if (isDsStore) {
                        return null;
                      }

                      return (
                        <StyledTableCell
                          onClick={
                            isFolder(
                              `${cell.getValue()}`,
                              Boolean(row.getIsExpanded()),
                            )
                              ? row.getToggleExpandedHandler()
                              : (e) => handleDownload(row.original, e)
                          }
                          key={cell.id}
                          style={{
                            ...(isDocumentColumn ? documentColumnStyles : {}),
                            cursor: "pointer",
                          }}
                          className="group"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </StyledTable>
        </div>
      </div>

      <ReuploadDataroomModal
        open={open}
        handleClose={closeReuploadDataroom}
        transactionId={txnId}
      />
    </>
  );
};

export default DataRoom;
