import { useState } from "react";
import { useSnackbar } from "notistack";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import { IManager } from "common/@types/models/Manager";
import { MatchNames } from "experiences/managers/domain/usecases/MatchNames";

export enum MatchStatus {
  PartialMatch = 1,
  InvalidInput = 2,
  NoMatch = 3,
  PerfectMatch = 4,
}

interface MatchResult {
  searchTerm: string; // the term sent to the server
  matchedCandidates: IManager[]; // for now it will always be 1 item
  status: MatchStatus; // tells us a little bit about what happened
  occurrences: number; // how many times one thing was replicated in the request that was sent because the backend dedupes
}

export const useGetManagerNamesFromApi = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCallMatchNames = async ({
    searchTerms,
  }: {
    searchTerms: string[];
  }) => {
    setIsLoading(true);
    return await new MatchNames()
      .call({
        searchTerms,
      })
      .then((resp) => {
        setIsLoading(false);
        return match<Failure, MatchResult[], MatchResult[] | void>(
          (_: Failure) => {
            enqueueSnackbar("Error while getting manager options for list.", {
              variant: "error",
            });
          },
          (matches: MatchResult[]) => {
            return matches;
          },
        )(resp);
      });
  };

  return { handleCallMatchNames, isLoading };
};
