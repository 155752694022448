import { Download } from "@phosphor-icons/react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shadcn/ui/select";
import { BUTTON_TYPES } from "common/components/Button";
import { Button } from "common/components/Button";
import { DescriptionList } from "common/components/DescriptionList2";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { SelectWithLabelWrapper } from "common/components/SelectWithLabelWrapper";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { tableCellDataFormatter } from "experiences/funds/presentation/components/Table";
import { ICompanyData } from "experiences/portfolio-v2/domain/usecases/GetCompanyDataQuery";

enum BasicInformationKeys {
  RevenueLfy = "revenueLfy",
  RevenueLtm = "revenueLtm",
  RevenueProjected = "revenueProjected",
  RevenueYoyChange = "revenueYoyChange",
  Ebitda = "ebitda",
  EbitdaYoyChange = "ebitdaYoyChange",
  EbitdaMargin = "ebitdaMargin",
  EbitdaProjected = "ebitdaProjected",
  CompanyCash = "companyCash",
  CompanyDebt = "companyDebt",
  CompanyNetDebt = "companyNetDebt",
  Capex = "capex",
}

const basicInformationLabels = {
  [BasicInformationKeys.RevenueLfy]: "Revenue LFY",
  [BasicInformationKeys.RevenueLtm]: "Revenue LTM",
  [BasicInformationKeys.RevenueProjected]: "Revenue Projected",
  [BasicInformationKeys.RevenueYoyChange]: "Revenue YoY % Change",
  [BasicInformationKeys.Ebitda]: "EBITDA",
  [BasicInformationKeys.EbitdaYoyChange]: "EBITDA YoY % Change",
  [BasicInformationKeys.EbitdaMargin]: "EBITDA Margin",
  [BasicInformationKeys.EbitdaProjected]: "EBITDA Projcted",
  [BasicInformationKeys.CompanyCash]: "Company Cash",
  [BasicInformationKeys.CompanyDebt]: "Company Debt",
  [BasicInformationKeys.CompanyNetDebt]: "Company Net Debt",
  [BasicInformationKeys.Capex]: "Capex",
};

const BasicInformationLeft = (data: ICompanyData) => {
  return (
    <DescriptionList
      className="flex-1"
      items={[
        {
          label: basicInformationLabels[BasicInformationKeys.RevenueLfy],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.RevenueLfy],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.RevenueLtm],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.RevenueLtm],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.RevenueProjected],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.RevenueProjected],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.RevenueYoyChange],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.RevenueYoyChange],
            format: "percentage",
          }),
        },
      ]}
    />
  );
};

const BasicInformationCenter = (data: ICompanyData) => {
  return (
    <DescriptionList
      className="flex-1"
      items={[
        {
          label: basicInformationLabels[BasicInformationKeys.Ebitda],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.Ebitda],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.EbitdaYoyChange],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.EbitdaYoyChange],
            format: "percentage",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.EbitdaMargin],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.EbitdaMargin],
            format: "percentage",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.EbitdaProjected],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.EbitdaProjected],
            format: "currency",
          }),
        },
      ]}
    />
  );
};

const BasicInformationRight = (data: ICompanyData) => {
  return (
    <DescriptionList
      className="flex-1"
      items={[
        {
          label: basicInformationLabels[BasicInformationKeys.CompanyCash],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.CompanyCash],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.CompanyDebt],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.CompanyDebt],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.CompanyNetDebt],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.CompanyNetDebt],
            format: "currency",
          }),
        },
        {
          label: basicInformationLabels[BasicInformationKeys.Capex],
          value: tableCellDataFormatter({
            value: data[BasicInformationKeys.Capex],
            format: "currency",
          }),
        },
      ]}
    />
  );
};

export const BasicInformation = ({
  company,
  loading,
  reportDatesOptions,
  currentReportDate,
  onReportDateChange,
}: {
  company: ICompanyData;
  loading: boolean;
  reportDatesOptions: { label: string; value: string }[];
  currentReportDate: string;
  onReportDateChange: (value: string) => void;
}) => {
  return (
    <div>
      <PageSectionTitleDivider
        actions={
          <div className="flex flex-row gap-4">
            <SelectWithLabelWrapper label="Report Date:">
              <Select
                onValueChange={onReportDateChange}
                value={currentReportDate}
                disabled={loading || reportDatesOptions.length === 0}
              >
                <SelectTrigger className="w-[150px] h-8">
                  <SelectValue
                    placeholder={
                      reportDatesOptions.length === 0 || !currentReportDate
                        ? NOT_AVAILABLE_STR
                        : "Select a date"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {reportDatesOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </SelectWithLabelWrapper>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              icon={<Download />}
              disabled={loading || !company?.companyId}
            >
              Download Data
            </Button>
          </div>
        }
      >
        Basic Information
      </PageSectionTitleDivider>
      <div className="flex flex-row">
        <BasicInformationLeft data={company} />
        <BasicInformationCenter data={company} />
        <BasicInformationRight data={company} />
      </div>
    </div>
  );
};
