import { createContext, useContext } from "react";

import { useManageFirmState } from "experiences/firm/presentation/state/FirmManager";
import { GetFirmTeamMembers } from "experiences/firm/domain/usecases/GetFirmTeamMembers";
import { GetFirmFunds } from "experiences/firm/domain/usecases/GetFirmFunds";

import { FirmEvent } from "./FirmEvent";
import { FirmFundsLoading, FirmTeamMembersLoading } from "./FirmState";

const FirmContext = createContext<{
  emitEvent: (event: FirmEvent) => void;
  teamMembersState?: FirmTeamMembersLoading;
  firmFundsState?: FirmFundsLoading;
}>({
  emitEvent: () => {},
  teamMembersState: undefined,
  firmFundsState: undefined,
});

const FirmStateProvider = ({ children }: { children: React.ReactNode }) => {
  const { emitEvent, teamMembersState, firmFundsState } = useManageFirmState({
    getFirmTeamMembers: new GetFirmTeamMembers(),
    getFirmFunds: new GetFirmFunds(),
  });

  return (
    <FirmContext.Provider
      value={{
        emitEvent: emitEvent,
        teamMembersState: teamMembersState,
        firmFundsState: firmFundsState,
      }}
    >
      {children}
    </FirmContext.Provider>
  );
};

export const useFirmContext = () => useContext(FirmContext);

export { FirmStateProvider, FirmContext };
