import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { MicrosoftExcelLogo } from "@phosphor-icons/react";

import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import {
  CompanySectorChart,
  GeographyChart,
  StrategyChart,
} from "./DealBreakdownCharts";
import {
  DataConfidence,
  DealBreakdownChart,
  DealBreakdownRow,
} from "./mock/DealBreakdownMock";
import { DealBreakdownTable } from "./common/DealBreakdownTable";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { GetTransactionFundDataDealBreakdown } from "experiences/transactions/domain/usecases/GetTransactionFundDataDealBreakdown";
import { transactionsClient } from "common/clients/ApolloClient";
import { FundDataDealBreakdown } from "experiences/transactions/domain/models/FundDataDealBreakdown";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";

import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";
import { FundDataVerifyPermissionOverlay } from "experiences/dashboard/presentation/components/VerifyPermissionOverlay";
import { DataExtractionInProgress } from "experiences/common/DataExtractionInProgress";
import { TransactionFundDataContext } from "../../state/TransactionFundDataContext";
import { getValidValue } from "../../../../../common/utils/getValidValue.ts";

export const DealBreakdownLeftColumn = ({
  data,
}: {
  data: DealBreakdownRow[];
}) => {
  return <DealBreakdownTable data={data} />;
};
export const DealBreakdownCenterColumn = ({
  data,
}: {
  data: DealBreakdownRow[];
}) => {
  return <DealBreakdownTable data={data} />;
};
export const DealBreakdownRightColumn = ({
  data,
}: {
  data: DealBreakdownRow[];
}) => {
  return <DealBreakdownTable data={data} />;
};

// prettier-ignore
const defaultDealBreakdownData: {
  left: DealBreakdownRow[];
  center: DealBreakdownRow[];
  right: DealBreakdownRow[];
  strategy: DealBreakdownChart[];
  geography: DealBreakdownChart[];
  companySector: DealBreakdownChart[];
} = {
  left: [
    { key: "nav", label: "NAV", value: "", },
    { key: "unfunded", label: "Unfunded", value: "", },
    { key: "interimContributions", label: "Post-Reference Contributions", value: "", },
    { key: "interimDistributions", label: "Post-Reference Distributions", value: "", },
    { key: "vintages", label: "Vintages", value: "", },
  ],
  center: [
    { key: "investmentCount", label: "Investment Count", value: "", },
    { key: "top3Concentration", label: "Top 3 Concentration", value: "", },
    { key: "top10Concentration", label: "Top 10 Concentration", value: "", },
    { key: "fundCount", label: "Fund Count", value: "", },
    { key: "gpCount", label: "GP Count", value: "", },
  ],
  right: [
    { key: "weightedAvgDpi", label: "Weighted Avg DPI", value: "" },
    { key: "weightedAvgMoic", label: "Weighted Avg MOIC", value: "" },
    { key: "weightedAvgRevenueGrowth", label: "Weighted Avg Revenue Growth", value: "" },
    { key: "fundSeriesTrackRecord", label: "Fund Family Track Record", value: "" },
    { key: "weightedAvgEstimatedPrice", label: "Weighted Avg Estimated Price", value: "" },
  ],
  strategy: [],
  geography: [],
  companySector: [],
};

const useDealBreakdownData = ({
  currentReportDate,
}: {
  currentReportDate?: string;
}) => {
  const [data, setData] = useState<{
    left: DealBreakdownRow[];
    center: DealBreakdownRow[];
    right: DealBreakdownRow[];
    strategy: DealBreakdownChart[];
    geography: DealBreakdownChart[];
    companySector: DealBreakdownChart[];
  }>(defaultDealBreakdownData);
  const { txnId } = useParams<{ txnId: string }>();
  const { selectedHoldingsIds } = useContext(TransactionFundDataContext);

  const {
    refetch,
    data: d1,
    loading,
  } = useQuery<FundDataDealBreakdown>(GetTransactionFundDataDealBreakdown, {
    variables: {
      transactionId: txnId,
      reportDate: currentReportDate,
      holdingsIds: selectedHoldingsIds,
    },
    client: transactionsClient,
    fetchPolicy: "network-only",
    skip: !currentReportDate,
  });

  // if selectedHoldings length changes, refetch data
  useEffect(() => {
    if (selectedHoldingsIds.length > 0 && currentReportDate) {
      refetch({
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldingsIds,
      });
    }
  }, [selectedHoldingsIds, currentReportDate]);

  useEffect(() => {
    if (d1?.dealBreakdown) {
      const txnNav = d1.dealBreakdown?.nav?.value
        ? Number.parseFloat(d1.dealBreakdown?.nav?.value)
        : 0;

      // Left Data:
      // NAV
      // Unfunded
      // Interim Contributions
      // Interim Distributions
      // Vintages
      const leftData: DealBreakdownRow[] = [
        {
          key: "nav",
          label: "NAV",
          value: txnNav,
          confidence: d1.dealBreakdown?.nav?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "currency",
        },
        {
          key: "unfunded",
          label: "Unfunded",
          value: Number.parseFloat(d1.dealBreakdown?.unfunded?.value),
          confidence: d1.dealBreakdown?.unfunded?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "currency",
        },
        {
          key: "interimContributions",
          label: "Post-Reference Contributions",
          value: Number.parseFloat(
            d1.dealBreakdown?.interimContributions?.value,
          ),
          confidence: d1.dealBreakdown?.interimContributions?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "currency",
        },
        {
          key: "interimDistributions",
          label: "Post-Reference Distributions",
          value: Number.parseFloat(
            d1.dealBreakdown?.interimDistributions?.value,
          ),
          confidence: d1.dealBreakdown?.interimDistributions?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "currency",
        },
        {
          key: "vintages",
          label: "Average Vintage",
          value: d1.dealBreakdown?.vintages?.value,
          confidence: d1.dealBreakdown?.vintages?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
        },
      ];

      const top3Concentration = d1.dealBreakdown?.top3Concentration?.value
        ? Number.parseFloat(d1.dealBreakdown?.top3Concentration?.value)
        : 0;
      const top10Concentration = d1.dealBreakdown?.top10Concentration?.value
        ? Number.parseFloat(d1.dealBreakdown?.top10Concentration?.value)
        : 0;

      // Center Data:
      // Investment Count
      // Top 3 Concentration
      // Top 10 Concentration
      // Fund Count
      // GP Count
      const centerData: DealBreakdownRow[] = [
        {
          key: "investmentCount",
          label: "Investment Count",
          value: d1.dealBreakdown?.investmentCount?.value,
          confidence: d1.dealBreakdown?.investmentCount?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
        },
        {
          key: "top3Concentration",
          label: "Top 3 Concentration",
          value: top3Concentration,
          confidence: d1.dealBreakdown?.top3Concentration?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "percentage",
        },
        {
          key: "top10Concentration",
          label: "Top 10 Concentration",
          value: top10Concentration,
          confidence: d1.dealBreakdown?.top10Concentration?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "percentage",
        },
        {
          key: "fundCount",
          label: "Fund Count",
          value: d1.dealBreakdown?.fundCount?.value,
          confidence: d1.dealBreakdown?.fundCount?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
        },
        {
          key: "gpCount",
          label: "GP Count",
          value: d1.dealBreakdown?.gpCount?.value,
          confidence: d1.dealBreakdown?.gpCount?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
        },
      ];

      // Right Data:
      // Weighted Avg DPI
      // Weighted Avg MOIC
      // Weighted Avg Revenue Growth
      // Fund Family Track Record
      // Weighted Avg Estimated Price
      const rightData: DealBreakdownRow[] = [
        {
          key: "weightedAvgDpi",
          label: "Weighted Avg DPI",
          value: getValidValue(d1.dealBreakdown?.weightedAvgDpi?.value),
          confidence: d1.dealBreakdown?.weightedAvgDpi?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "multiplier",
        },
        {
          key: "weightedAvgMoic",
          label: "Weighted Avg MOIC",
          value: getValidValue(d1.dealBreakdown?.weightedAvgMoic?.value),
          confidence: d1.dealBreakdown?.weightedAvgMoic?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "multiplier",
        },
        {
          key: "weightedAvgRevenueGrowth",
          label: "Weighted Avg Revenue Growth",
          value: getValidValue(
            d1.dealBreakdown?.weightedAvgRevenueGrowth?.value,
          ),
          confidence: d1.dealBreakdown?.weightedAvgRevenueGrowth?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "percentage",
        },
        {
          key: "fundSeriesTrackRecord",
          label: "Fund Family Track Record",
          value: getValidValue(d1.dealBreakdown?.fundSeriesTrackRecord?.value),
          confidence: d1.dealBreakdown?.fundSeriesTrackRecord?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "multiplier",
        },
        {
          key: "weightedAvgEstimatedPrice",
          label: "Weighted Avg Estimated Price",
          value: getValidValue(
            d1.dealBreakdown?.weightedAvgEstimatedPrice?.value,
          ),
          confidence: d1.dealBreakdown?.weightedAvgEstimatedPrice?.verified
            ? DataConfidence.Confirmed
            : DataConfidence.Estimated,
          format: "percentage",
        },
      ];

      const strategyData: DealBreakdownChart[] =
        d1.dealBreakdown?.strategies || [];
      const geographyData: DealBreakdownChart[] =
        d1.dealBreakdown?.geographies || [];
      const companySectorData: DealBreakdownChart[] =
        d1.dealBreakdown?.sectors || [];

      // Save data to state
      setData({
        left: leftData,
        center: centerData,
        right: rightData,
        strategy: strategyData,
        geography: geographyData,
        companySector: companySectorData,
      });
    }
  }, [d1]);

  return { data, loading };
};

export const DealBreakdown = ({
  handleDownloadClick,
  status,
  isDownloading,
}: {
  handleDownloadClick: () => void;
  status: TransactionDataSummarySectionStatus;
  isDownloading: boolean;
}) => {
  const { txnId } = useParams();
  const { reportDates } = useContext(TransactionFundDataContext);

  const { data: dealBreakdownData, loading } = useDealBreakdownData({
    currentReportDate: reportDates.dealBreakdown.currentDate,
  });

  const awaitingUpload =
    status === TransactionDataSummarySectionStatus.NotYetUploaded;
  const extractionInProgress =
    status === TransactionDataSummarySectionStatus.UploadedAndProcessing;
  const extractionReady =
    status === TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  const hasReportDates = reportDates.dealBreakdown.options.length >= 1;

  return (
    <div className="relative min-h-64">
      <PageSectionTitleDivider
        actions={
          hasReportDates && (
            <>
              <SelectDateDropdown
                value={reportDates.dealBreakdown.currentDate}
                onValueChange={reportDates.dealBreakdown.onReportDateChange}
                options={reportDates.dealBreakdown.options}
                loading={loading}
              />
              <Button
                onClick={handleDownloadClick}
                type={BUTTON_TYPES.SECONDARY}
                icon={<MicrosoftExcelLogo />}
                loading={isDownloading}
              >
                Download Data
              </Button>
            </>
          )
        }
      >
        Deal Breakdown
      </PageSectionTitleDivider>

      {awaitingUpload && <FundDataVerifyPermissionOverlay txnId={txnId} />}
      {extractionInProgress && <DataExtractionInProgress />}

      {extractionReady && (
        <>
          <div className="flex">
            <DealBreakdownTable
              data={dealBreakdownData.left}
              loading={loading}
            />
            <DealBreakdownTable
              data={dealBreakdownData.center}
              loading={loading}
            />
            <DealBreakdownTable
              data={dealBreakdownData.right}
              loading={loading}
            />
          </div>
          <div className="flex items-start py-12 gap-8 justify-around">
            <StrategyChart
              data={dealBreakdownData.strategy}
              loading={loading}
            />
            <GeographyChart
              data={dealBreakdownData.geography}
              loading={loading}
            />
            <CompanySectorChart
              data={dealBreakdownData.companySector}
              loading={loading}
            />
          </div>
        </>
      )}
    </div>
  );
};
