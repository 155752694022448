import styled from "styled-components";

export const SearchInput = styled.input`
  display: block;
  color: #21272d;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.11px;
  border-radius: 6px;
  border: 1px solid #dfdfd9;
  background: rgba(255, 255, 255, 0.92);
  padding: 8px 12px;
  box-sizing: border-box;
  outline: none;
  min-width: 240px;

  &:hover:not(:disabled) {
    border: 1px solid #bcb3a5;
  }

  &:focus {
    border: 1px solid #bcb3a5;
    box-shadow: 0px 0px 0px 3px rgba(188, 179, 165, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
