import { gql } from "@apollo/client";

export const GET_TRANSACTION_INTERIM_CASHFLOWS = gql`
  query Query($transactionId: String!, $reportDate: Date!) {
    interimCashflows(transactionId: $transactionId, reportDate: $reportDate) {
      fund
      issuerId
      noticeId
      type
      amount
      wireDate
      description
    }
  }
`;
