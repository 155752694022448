import styled from "styled-components";

import { cn } from "common/utils";

export const StyledLoader = styled.div`
  width: 24px;

  aspect-ratio: 0.75;
  --c: no-repeat linear-gradient(#0a0a0a 0 0);
  background:
    var(--c) 0% 100%,
    var(--c) 50% 100%,
    var(--c) 100% 100%;
  background-size: 20% 65%;
  animation: l5 1s infinite linear;
  font-size: 10px;
  line-height: 1;
  color: transparent;
  user-select: none;

  @keyframes l5 {
    20% {
      background-position:
        0% 50%,
        50% 100%,
        100% 100%;
    }
    40% {
      background-position:
        0% 0%,
        50% 50%,
        100% 100%;
    }
    60% {
      background-position:
        0% 100%,
        50% 0%,
        100% 50%;
    }
    80% {
      background-position:
        0% 100%,
        50% 100%,
        100% 0%;
    }
  }
`;

export const BarLoader = ({
  className,
  appHeight,
}: {
  className?: string;
  appHeight?: boolean;
}) => {
  return (
    <div
      className={cn(
        "flex justify-center items-center h-48",
        {
          "h-[calc(100%-theme(space.appMenuHeight))]": appHeight,
        },
        className,
      )}
    >
      <StyledLoader className="___loader">Loading...</StyledLoader>
    </div>
  );
};
