import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { TapTable } from "experiences/funds/presentation/components/Table";
import { fundExposureMockColumnsQuay } from "../mock/SoiLookthroughMock";
import {
  fundExposureSummaryMockQuay,
  renderRowMockQuay,
} from "../mock/FundExposureSummaryMockQuay";

export const FundExposureSummaryQuay = ({}: {}) => {
  return (
    <>
      <PageSectionTitleDivider showBorderTop>
        Fund Exposure Summary
      </PageSectionTitleDivider>

      <div className="w-full max-w-full overflow-x-scroll h-full max-h-96 overflow-y-scroll relative">
        <TapTable
          skipTopBorder
          columns={fundExposureMockColumnsQuay}
          fixedHeaders
          renderRow={(row, options) => renderRowMockQuay(row, options)}
          data={fundExposureSummaryMockQuay}
          className="!max-w-max !w-max !overflow-scroll"
        />
      </div>
    </>
  );
};
