import { useContext, useEffect, useState } from "react";
import { useNavigate, generatePath } from "react-router";
import { CaretRight } from "@phosphor-icons/react";

import { cn } from "common/utils";
import { LP_ROUTES } from "common/constants/routes";
import { FUND_LOGO_URL, NOT_AVAILABLE_STR } from "common/constants/platform";
import {
  StyledTableBody,
  StyledTableRow,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { ITabTableColumn } from "experiences/funds/presentation/components/Table";
import { BarLoader } from "common/components/BarLoader";
import { IPortfolioLookthroughSoiWithGrouping } from "experiences/portfolio-v2/domain/models/PortfolioSoi";
import { TableFundLogo } from "common/components/TableFundLogo";
import { FundCoreSectorNameMap } from "experiences/common/models/FundCoreSector";
import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import {
  coreSectorLabels,
  InvestmentTypeIndexToType,
  investmentTypeLabels,
} from "experiences/transactions/domain/models/FundDataSoiLookthrough";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { AggregateSoiGroupKeys } from "../components/TableGrouping";
import { VIEWPOINT_FUND_ID } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";

enum RowType {
  Total = "total",
  Group = "group",
  Holding = "holding",
}

interface ITableRow {
  type: RowType;
  label: string;
  id: string;
  children: ITableRow[];
}

enum InvestmentsTableColumnKeys {
  Investment = "investment",
  Fund = "fund",
  PortfolioPercentage = "portfolio_percentage",
  Type = "type",
  Sector = "sector",
  Date = "date",
  Cost = "cost",
  FMV = "fmv",
  MOIC = "investmentMoic",
}

const columns: ITabTableColumn[] = [
  {
    label: "Investment",
    key: InvestmentsTableColumnKeys.Investment,
    align: TableAlignment.LEFT,
    className: "!w-96 !min-w-96",
  },
  {
    label: "Fund",
    key: InvestmentsTableColumnKeys.Fund,
    align: TableAlignment.LEFT,
    className: "!w-64 !min-w-64",
  },
  {
    label: "Portfolio %",
    key: InvestmentsTableColumnKeys.PortfolioPercentage,
    align: TableAlignment.RIGHT,
    className: "!w-36 !min-w-36",
  },
  {
    label: "Type",
    key: InvestmentsTableColumnKeys.Type,
    align: TableAlignment.LEFT,
    className: "!w-48 !min-w-48",
  },
  {
    label: "Sector",
    key: InvestmentsTableColumnKeys.Sector,
    align: TableAlignment.LEFT,
    className: "!w-48 !min-w-48",
  },
  {
    label: "Date",
    key: InvestmentsTableColumnKeys.Date,
    align: TableAlignment.LEFT,
    className: "!w-36 !min-w-36",
  },
  {
    label: "Cost",
    key: InvestmentsTableColumnKeys.Cost,
    align: TableAlignment.RIGHT,
    className: "!w-36 !min-w-36",
  },
  {
    label: "FMV",
    key: InvestmentsTableColumnKeys.FMV,
    align: TableAlignment.RIGHT,
    className: "!w-36 !min-w-36",
  },
  {
    label: "MOIC",
    key: InvestmentsTableColumnKeys.MOIC,
    align: TableAlignment.RIGHT,
    className: "!w-36 !min-w-36",
  },
];

interface IHoldingRow {
  id: string;
  label: string;
  type: RowType;
  companiesCount: number;
  isHumanVerified: boolean;
  totalCost: string;
  totalValue: string;
  realizedCost: string;
  realizedValue: string;
  unrealizedCost: string;
  unrealizedValue: string;
  portfolioPercent: string;
  investmentMoic: string;
  nav: string;
  sector: string;
  investmentType: string;
  investmentGeography: string;
  investmentDate: string;
  managerId: string;
  managerName: string;
}

// This is almost a 1:1 copy of SummaryTable.tsx BUT for lookthrough soi, meaning IHoldingRow has a different set of fields
const useLookthroughSoiTable = ({
  data,
  loading,
}: {
  data: IPortfolioLookthroughSoiWithGrouping | null;
  loading: boolean;
}) => {
  const [holdings, setHoldings] = useState<IHoldingRow[]>([]);
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [openRowIds, setOpenRowIds] = useState<string[]>([]);
  const portfolioContext = useContext(PortfolioContext);
  const navigate = useNavigate();

  const getGroupLabelString = (
    groupingKey: AggregateSoiGroupKeys,
    value: string | number,
  ): string => {
    if (!groupingKey) return String(value);
    if (String(value).toLowerCase() === "total") return "Total";

    if (groupingKey === AggregateSoiGroupKeys.Type) {
      return investmentTypeLabels[InvestmentTypeIndexToType[value]] || "Other";
    }
    if (groupingKey === AggregateSoiGroupKeys.Sector) {
      return !!FundCoreSectorNameMap.get(Number(value))
        ? String(FundCoreSectorNameMap.get(Number(value)))
        : "Other";
    }
    if (groupingKey === AggregateSoiGroupKeys.Geography) {
      return !!FundGeographyNameMap.get(Number(value))
        ? String(FundGeographyNameMap.get(Number(value)))
        : "Other";
    }
    if (groupingKey === AggregateSoiGroupKeys.Entity) {
      const entity = portfolioContext.entities.find((e) => e.id === value);
      return entity?.name || String(value);
    }
    if (groupingKey === AggregateSoiGroupKeys.Manager) {
      const manager =
        data.reportData.find((r) => r.managerId === value)?.managerName || "";
      return manager || String(value);
    }
    if (String(value).toLowerCase() === "total") return "Total";
    return String(value);
  };

  const handleTotalRowClick = (rowId: string) => {
    if (openRowIds.includes(rowId)) {
      setOpenRowIds([]); // Close all rows
      setRows((prevRows) => {
        const topLevelRow = prevRows.find((row) => row.id === rowId);
        const topLevelRowWithoutChildren = {
          ...topLevelRow,
          children: [],
        };

        return [topLevelRowWithoutChildren];
      });
    } else {
      // Open total row
      setOpenRowIds([rowId]);
      // Generate one row for each entry in data.groupingData.aggregateData
      // Append them to rows
      const newRows: ITableRow[] = data.groupingData.aggregateData.map(
        (group) => {
          return {
            type: RowType.Group,
            // label: getGroupLabelString(
            //   data.groupingData.groupedBy,
            //   group.groupKey,
            // ),
            label: group.groupKey,
            id: group.groupKey,
            children: [],
          };
        },
      );

      setRows((prev) => {
        const rowToUpdate = prev.find((row) => row.id === rowId);
        if (!rowToUpdate) return prev;

        return [
          ...prev.filter((row) => row.id !== rowId),
          { ...rowToUpdate, children: [...rowToUpdate.children, ...newRows] },
        ];
      });
    }
  };

  const handleRowGroupClick = (rowId: string) => {
    if (openRowIds.includes(rowId)) {
      setOpenRowIds((prev) => [...prev.filter((id) => id !== rowId)]);

      setRows((prevRows) => {
        const totalRow = prevRows.find((row) => row.type === RowType.Total);

        const groupRowIndex = totalRow?.children.findIndex(
          (row) => row.id === rowId,
        );

        if (groupRowIndex === -1) return prevRows;

        const newGroupRow: ITableRow = {
          ...totalRow.children[groupRowIndex],
          children: [],
        };

        const newTotalRow: ITableRow = {
          ...totalRow,
          children: [
            ...totalRow.children.slice(0, groupRowIndex),
            newGroupRow,
            ...totalRow.children.slice(groupRowIndex + 1),
          ],
        };

        return [newTotalRow];
      });
    } else {
      // Open row
      setOpenRowIds((prev) => [...prev, rowId]);
      // Grab all holdings that belong to this group
      const groupIssuerIds = data.groupingData.aggregateData.find(
        (group) => group.groupKey === rowId,
      ).groupIds;
      const groupHoldings = holdings.filter((h) =>
        groupIssuerIds.includes(h.id),
      );

      // Add groupHoldings to the row where (row.id === rowId).children
      setRows((prevRows) => {
        // First, find the total row
        const totalRow = prevRows.find((row) => row.type === RowType.Total);
        const rowToUpdateIndex = totalRow?.children.findIndex(
          (row) => row.id === rowId,
        );
        if (rowToUpdateIndex === -1) return prevRows;

        // Set children of rowToUpdate to groupHoldings
        const groupChildren: ITableRow[] = groupHoldings.map((h) => ({
          type: RowType.Holding,
          label: h.label,
          id: h.id,
          children: [],
        }));

        const newRowToUpdate: ITableRow = Object.assign(
          {},
          totalRow.children[rowToUpdateIndex],
          {
            children: [
              ...totalRow.children[rowToUpdateIndex].children,
              ...groupChildren,
            ],
          },
        );

        const newTotalRow: ITableRow = Object.assign({}, totalRow, {
          children: [
            ...totalRow.children.slice(0, rowToUpdateIndex),
            newRowToUpdate,
            ...totalRow.children.slice(rowToUpdateIndex + 1),
          ],
        });

        return [newTotalRow];
      });
    }
  };

  const handleRowClick = (rowId: string, rowType: RowType) => {
    if (rowType === RowType.Total) {
      handleTotalRowClick(rowId);
      return;
    }
    if (rowType === RowType.Group) {
      handleRowGroupClick(rowId);
      return;
    }
    if (rowType === RowType.Holding) {
      const path = generatePath(LP_ROUTES.FundsFundDetail, {
        fundId: VIEWPOINT_FUND_ID,
      });
      navigate(path);
      return;
    }
  };

  // Step 1: Always have the top level aggregate data
  useEffect(() => {
    if (!data?.reportData || !data?.groupingData || loading) return;

    const topLevelHolding: IHoldingRow =
      {
        id: "total",
        label: "Total",
        type: RowType.Total,
        companiesCount: data.groupingData.topLevelAggregateData.companiesCount,
        isHumanVerified:
          data.groupingData.topLevelAggregateData.isHumanVerified,
        totalCost: data.groupingData.topLevelAggregateData.unrealizedCost,
        totalValue: data.groupingData.topLevelAggregateData.totalValue,
        realizedCost: data.groupingData.topLevelAggregateData.realizedCost,
        realizedValue: data.groupingData.topLevelAggregateData.realizedValue,
        unrealizedCost: data.groupingData.topLevelAggregateData.unrealizedCost,
        unrealizedValue:
          data.groupingData.topLevelAggregateData.unrealizedValue,
        portfolioPercent:
          data.groupingData.topLevelAggregateData.portfolioPercent,
        investmentMoic: data.groupingData.topLevelAggregateData.investmentMoic,
        nav: data.groupingData.topLevelAggregateData.nav,
        sector: data.groupingData.topLevelAggregateData.sector,
      } || {};
    const groupHoldings: IHoldingRow[] =
      data?.groupingData?.aggregateData?.map((group) => {
        return {
          id: group.groupKey,
          label: data?.groupingData?.groupedBy
            ? getGroupLabelString(data.groupingData.groupedBy, group.groupKey)
            : NOT_AVAILABLE_STR,
          type: RowType.Group,
          companiesCount: group.companiesCount,
          isHumanVerified: group.isHumanVerified,
          totalCost: group.unrealizedCost,
          totalValue: group.totalValue,
          realizedCost: group.realizedCost,
          realizedValue: group.realizedValue,
          unrealizedCost: group.unrealizedCost,
          unrealizedValue: group.unrealizedValue,
          portfolioPercent: group.portfolioPercent,
          investmentMoic: group.investmentMoic,
          nav: group.nav,
        };
      }) || [];
    const actualHoldings: IHoldingRow[] =
      data?.reportData
        ?.map((entry) => {
          return {
            ...entry,
            id: entry.issuerId,
            label: entry.companyName,
            type: RowType.Holding,
            companiesCount: 1,
            isHumanVerified: false,
            totalCost: entry.unrealizedCost,
            totalValue: entry.lpImpliedInvestmentValue,
            realizedCost: entry.realizedCost,
            realizedValue: entry.lpImpliedInvestmentValue,
            unrealizedCost: entry.unrealizedCost,
            unrealizedValue: entry.unrealizedValue,
            portfolioPercent: entry.portfolioPercent,
            investmentMoic: entry.investmentMoic,
            nav: entry.nav,
            fmv: entry.unrealizedValue,
            sector: entry.sector,
            investmentType: entry.investmentType,
            investmentGeography: entry.investmentGeography,
            investmentDate: entry.investmentDate,
          };
        })
        .sort((a, b) => {
          return (
            Number(b.portfolioPercent || 0) - Number(a.portfolioPercent || 0)
          );
        }) || [];
    const allHoldings = [topLevelHolding, ...groupHoldings, ...actualHoldings];

    // By default, always add the top level row
    const newRows: ITableRow[] = data.groupingData.aggregateData.map(
      (group) => {
        return {
          type: RowType.Group,
          label: group.groupKey,
          id: group.groupKey,
          children: [],
        };
      },
    );

    setHoldings(allHoldings);
    setOpenRowIds(["total"]);
    setRows((prev) => {
      const topLevelRow: ITableRow = {
        type: RowType.Total,
        label: "Total",
        id: "total",
        children: newRows,
      };

      return [...prev.filter((row) => row.id !== "total"), topLevelRow];
    });
  }, [data, data?.groupingData?.groupedBy, data?.reportData, loading]);

  const renderRow = (row: ITableRow) => {
    const isOpen = openRowIds.includes(row.id);
    const isHolding = row.type === RowType.Holding;
    const isGroup = row.type === RowType.Group || row.type === RowType.Total;
    const groupData = data?.groupingData?.aggregateData.find(
      (group) => group.groupKey === row.id,
    );
    const showCaret = isGroup;

    const holdingForRow = holdings.find((h) => h.id === row.id);
    const reportDataForRow = data?.reportData?.find(
      (r) => r.issuerId === row.id,
    );

    const label =
      isGroup && data?.groupingData?.groupedBy
        ? getGroupLabelString(data.groupingData.groupedBy, row.label)
        : row.label;

    const imgSrc = `${FUND_LOGO_URL}/${holdingForRow?.companyId}.jpg`;

    const groupIssuerIds = isGroup ? groupData?.groupIds || [] : [];
    const groupHoldings = isGroup
      ? holdings.filter((h) => groupIssuerIds.includes(h.id))
      : [];

    // If it's a holding, just show the sector
    // If it's a group, show all unique sectors in the group
    const sectorLabel = isHolding
      ? coreSectorLabels[holdingForRow?.sector || ""]
      : groupHoldings
          .map((h) => coreSectorLabels[h.sector || ""])
          .filter((sector, index, self) => self.indexOf(sector) === index)
          .filter((type) => type !== "" && type !== null && type !== undefined)
          .join(", ");

    // If it's a holding, just show the investment type
    // If it's a group, show all unique investment types in the group
    const typeLabel = isHolding
      ? investmentTypeLabels[holdingForRow?.investmentType || ""]
      : groupHoldings
          .map((h) => investmentTypeLabels[h.investmentType || ""])
          .filter((type, index, self) => self.indexOf(type) === index)
          .filter((type) => type !== "" && type !== null && type !== undefined)
          .join(", ");

    const fmvLabel = isHolding
      ? tableCellDataFormatter({
          value: holdingForRow.unrealizedValue,
          format: "currency",
        })
      : tableCellDataFormatter({
          value: groupData?.unrealizedValue,
          format: "currency",
        });

    return (
      <>
        <StyledTableRow
          onClick={() => handleRowClick(row.id, row.type)}
          key={row.id}
        >
          <TableCell
            className={cn("select-none overflow-ellipsis", {
              "!pl-10": row.type === RowType.Group,
              "!pl-16 !pt-1 !pb-1": isHolding,
              "!font-medium": isGroup,
            })}
          >
            {showCaret && (
              <CaretRight
                className={cn(
                  "transition-transform inline mr-2",
                  isOpen && "rotate-90",
                )}
              />
            )}
            {isHolding ? (
              <TableFundLogo imgSrc={imgSrc} fundName={label} />
            ) : (
              label
            )}
          </TableCell>
          <TableCell className="!w-96 !min-w-96 select-none overflow-ellipsis">
            {reportDataForRow?.fundName || ""}
          </TableCell>
          <TableCell className="!w-36 !min-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.portfolioPercent,
              format: "percentage",
            })}
          </TableCell>
          <TableCell
            className={cn("!w-48 !min-w-48", {
              "text-zinc-500": isGroup,
            })}
            title={typeLabel}
          >
            {typeLabel}
          </TableCell>
          <TableCell
            className={cn("!w-48 !min-w-48", {
              "text-zinc-500": isGroup,
            })}
            title={sectorLabel}
          >
            {sectorLabel}
          </TableCell>
          <TableCell className="!w-36 !min-w-36 text-right">
            {reportDataForRow?.investmentDate || ""}
          </TableCell>
          <TableCell className="!w-36 !min-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.totalCost,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !min-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.unrealizedValue,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !min-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.investmentMoic,
              format: "multiplier",
            })}
          </TableCell>
        </StyledTableRow>
        {row.children.length > 0 &&
          row.children.map((child) => renderRow(child))}
      </>
    );
  };

  return { rows, renderRow };
};

export const LookthroughSOITable = ({
  data,
  loading,
}: {
  data: IPortfolioLookthroughSoiWithGrouping;
  loading: boolean;
}) => {
  const { rows, renderRow } = useLookthroughSoiTable({ data, loading });

  return (
    <div className="w-full max-w-full overflow-x-scroll relative pb-12">
      <TapTable
        data={loading ? [] : rows}
        columns={columns}
        layoutFixed
        isLoading={loading}
        className="w-full min-w-[700px]"
        renderBody={(bodyData) => (
          <StyledTableBody>{bodyData.map(renderRow)}</StyledTableBody>
        )}
      />
      {loading && <BarLoader />}
    </div>
  );
};
