import { gql } from "@apollo/client";

export interface ClassifyDocumentInput {
  holdingId: string;
  entityDocumentId: string;
  useInExtraction: boolean;
}

export const PostPortfolioClassifyDocumentsMutation = gql`
  mutation Mutation($docs: [ClassifyDocumentInput!]!) {
    classifyDocuments(docs: $docs) {
      fileName
      result
      reportId
    }
  }
`;
