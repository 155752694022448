import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { INDADoc } from "../models/TransactionDocument";

export class ListTransactionNDAs implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, INDADoc[]>> = async ({
    txnId,
  }) => {
    return await new TapClient(`/transactions/${txnId}/ndas/`)
      .get({})
      .then((resp: INDADoc[]) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  txnId: string;
}
