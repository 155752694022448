import toast from "react-hot-toast";
import { Either, left, right } from "fp-ts/lib/Either";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { ISendParticipantsInviteForm } from "../models/SendParticipantsInviteForm";

export class SendParticipantsInvite
  implements IAsyncUseCase<IParams, NoReturn>
{
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    form,
  }) => {
    return await new TapClient(
      `/transactions/${form.txnId}/participants/invite/`,
      form,
    )
      .post()
      .then((_) => {
        toast.success("Invites sent successfully");
        return right(new NoReturn());
      })
      .catch((resp: any) => {
        toast.error("Failed to send invites");
        return left(new Failure(resp.response));
      });
  };
}

interface IParams {
  form: ISendParticipantsInviteForm;
}
