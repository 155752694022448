export interface IIssuer {
  id: string;
  name: string;
  type: IssuerType;
}

export enum IssuerType {
  Fund = 1,
  Company = 2,
}

export enum IssuerTypeGql {
  Fund = "Fund",
  Company = "Company",
}

export const IssuerTypeLabel: Record<IssuerType, string> = {
  [IssuerType.Fund]: "Fund",
  [IssuerType.Company]: "Company",
};
