import * as d3 from "d3";
import {
  forwardRef,
  useEffect,
  useState,
  MutableRefObject,
  useContext,
} from "react";

import TextChip from "experiences/common/TextChip";
import { formatPercentPoints } from "../../pages/ViewFund";
import { DollarAmount } from "common/@types/app/DollarAmount";
import {
  IOIType,
  IOITypeLabel,
  IndicationOfInterest,
} from "experiences/indications-of-interest/domain/models/IOI";
import { IoiType } from "experiences/indications-of-interest/presentation/pages/AddInterestModal";
import { IOI_DATE_FORMAT } from "./PriceHistoryChart";
import { BidsContext } from "experiences/dashboard/presentation/state/BidContext";
import { IoiBuyInterestList } from "experiences/dashboard/presentation/components/IoiBuyInterestList";
import { IoiSellInterestList } from "experiences/dashboard/presentation/components/IoiSellInterestList";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { classNames } from "primereact/utils";
import { Fund } from "experiences/funds/domain/models/Fund";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { useNavigate } from "react-router";
import { LP_ROUTES } from "common/constants/routes";

type IndicationOfInterestTableProps = {
  toggleContactModal: (type: IOIType) => void;
  toggleSubmitIoiModal: (type: IoiType) => void;
  ref: MutableRefObject<any>;
  iois: IndicationOfInterest[];
  canViewBuyIois: boolean;
  canViewSellIois: boolean;
  fund: Fund;
};

export const TableBulletGreen = ({ className }: { className?: string }) => {
  return (
    <img src="/images/ui/table_bullet_green.svg" alt="" className={className} />
  );
};

export const TableBulletOrange = ({ className }: { className?: string }) => {
  return (
    <img
      src="/images/ui/table_bullet_orange.svg"
      alt=""
      className={className}
    />
  );
};

const ioiIsBid = (ioi: IndicationOfInterest) => ioi.type === IOIType.Bid;
const ioiIsAsk = (ioi: IndicationOfInterest) => ioi.type === IOIType.Ask;
const ioiIsOpen = (ioi: IndicationOfInterest) => ioi.isArchived === false;
export const ioiIsExpired = (ioi: IndicationOfInterest) => {
  if (ioi.isGtc) return false;
  if (!ioi.expirationDate) return false;
  if (ioi.referenceDate === "GTC") return false;

  const isExpired =
    d3.timeParse(IOI_DATE_FORMAT)(ioi.expirationDate) < new Date();

  return isExpired;
};

export const useIndicationOfInterest = ({
  iois,
}: {
  iois: IndicationOfInterest[];
}) => {
  const [bidPriceData, setBidPriceData] = useState<IndicationOfInterest[]>([]);
  const [askPriceData, setAskPriceData] = useState<IndicationOfInterest[]>([]);

  const loadPriceData = async () => {
    const bids = iois
      .filter(ioiIsBid)
      .filter(ioiIsOpen)
      .sort((a, b) => {
        return b.max - a.max;
      });
    const asks = iois
      .filter(ioiIsAsk)
      .filter(ioiIsOpen)
      .sort((a, b) => {
        return b.max - a.max;
      });
    setBidPriceData(bids);
    setAskPriceData(asks);
  };

  useEffect(() => {
    loadPriceData();
  }, [iois]);

  const highestBid = bidPriceData.reduce((acc, cur) => {
    return cur.maxAmount > acc ? cur.maxAmount : acc;
  }, 0);

  // do not start from 0 because any number is higher than 0
  const lowestBid = bidPriceData.reduce((acc, cur) => {
    return cur.minAmount < acc ? cur.minAmount : acc;
  }, highestBid);

  const highestAsk = askPriceData.reduce((acc, cur) => {
    return cur.maxAmount > acc ? cur.maxAmount : acc;
  }, 0);

  // do not start from 0 because any number is higher than 0
  const lowestAsk = askPriceData.reduce((acc, cur) => {
    return cur.minAmount < acc ? cur.minAmount : acc;
  }, highestAsk);

  return {
    bidPriceData,
    askPriceData,
    highestBid,
    lowestBid,
    highestAsk,
    lowestAsk,
  };
};

const REQUEST_FOR_QUOTE = "RFQ";

const renderBidAskPrice = ({
  minPrice,
  maxPrice,
}: {
  minPrice?: number;
  maxPrice?: number;
}) => {
  const isPriceMinMaxEqual =
    Number(minPrice) === Number(maxPrice) && minPrice !== 0 && maxPrice !== 0;

  const isPriceSingleValue =
    isPriceMinMaxEqual || Number(minPrice) === 0 || Number(maxPrice) === 0;

  if (isPriceMinMaxEqual) {
    if (minPrice === 0 || minPrice === null) return REQUEST_FOR_QUOTE;
    return formatPercentPoints(minPrice * 100);
  }

  if (isPriceSingleValue && !!minPrice) {
    return formatPercentPoints(minPrice * 100);
  }

  if (isPriceSingleValue && !!maxPrice) {
    return formatPercentPoints(maxPrice * 100);
  }

  return `${formatPercentPoints(minPrice * 100)} -
      ${formatPercentPoints(maxPrice * 100)}`;
};

const renderCheckSize = ({
  minAmount,
  maxAmount,
}: {
  minAmount?: number;
  maxAmount?: number;
}) => {
  const isCheckSizeMinMaxEqual =
    Number(minAmount) === Number(maxAmount) &&
    minAmount !== 0 &&
    maxAmount !== 0;

  const isCheckSizeSingleValue =
    isCheckSizeMinMaxEqual ||
    Number(minAmount) === 0 ||
    Number(maxAmount) === 0;

  if (isCheckSizeMinMaxEqual) {
    return new DollarAmount(minAmount).formattedBig();
  }

  if (isCheckSizeSingleValue && !!minAmount) {
    return new DollarAmount(minAmount).formattedBig();
  }

  if (isCheckSizeSingleValue && !!maxAmount) {
    return new DollarAmount(maxAmount).formattedBig();
  }

  return `${new DollarAmount(minAmount).formattedBig()} -
      ${new DollarAmount(maxAmount).formattedBig()}`;
};

// Note: main component is wrapped in forwardRef to allow parent component to scroll to this component
export const IndicationsOfInterestTable = forwardRef(
  function IndicationsOfInterestTable(
    {
      toggleContactModal,
      toggleSubmitIoiModal,
      fund,
      iois,
      canViewBuyIois,
      canViewSellIois,
    }: IndicationOfInterestTableProps,
    ref: any,
  ) {
    const { bidRank, bidState } = useContext(BidsContext);
    const { bidPriceData, askPriceData, highestBid, highestAsk } =
      useIndicationOfInterest({
        iois,
      });

    const nonExpiredBids = bidPriceData.filter((ioi) => !ioiIsExpired(ioi));
    const nonExpiredAsks = askPriceData.filter((ioi) => !ioiIsExpired(ioi));

    // I know this will be counter intuitive but
    // if the fund is not viewpoint, we want to show viewpoint IOIs (they'll be blurred)
    // if the fund is viewpoint, show the real IOIs
    // todo: use summary real permission to determine if we should show real IOIs or not

    const handleIoiClick = ({
      ioi,
      locked,
    }: {
      ioi: IndicationOfInterest;
      locked: boolean;
    }) => {
      if (locked) return;

      toggleContactModal(ioi.type);
    };

    const renderBidAskRow = (
      row: (typeof bidPriceData)[0],
      i: number,
      locked: boolean,
    ) => {
      // convert maxAmount to a percentage from lowestBid to highestBid
      const fillMinBid = (row.minAmount / highestBid) * 100;
      const fillMinAsk = (row.minAmount / highestAsk) * 100;
      const fillMin = row.type === IOIType.Bid ? fillMinBid : fillMinAsk;
      const fillMaxBid = (row.maxAmount / highestBid) * 100;
      const fillMaxAsk = (row.maxAmount / highestAsk) * 100;
      const fillMax = row.type === IOIType.Bid ? fillMaxBid : fillMaxAsk;
      const showOrangeBullet = row.isArchived === false;
      const showGreenBullet = row.isArchived === true;

      const minGradient = `linear-gradient(90deg, rgba(160, 142, 113, 0.25) 0%, rgba(160, 142, 113, 0.25) ${fillMin}%, rgba(255, 255, 255, 0.00) ${fillMin}%)`;
      const maxGradient = `linear-gradient(90deg, rgba(216, 198, 169, 0.25) 0%, rgba(216, 198, 169, 0.25) ${fillMax}%, rgba(255, 255, 255, 0.00) ${fillMax}%)`;

      const referenceDate = row.referenceDate
        ? new DateFormatter(row.referenceDate).quarterFormatted()
        : NOT_AVAILABLE_STR;

      return (
        <tr
          className={classNames("ioi_row", { locked: locked })}
          key={`${row.id}-${i}`}
          onClick={() => handleIoiClick({ ioi: row, locked })}
        >
          <td
            className={classNames("text-center w-12 overflow-hidden", {
              locked: locked,
            })}
            style={{ textOverflow: "unset" }}
          >
            {showOrangeBullet && <TableBulletOrange className="table_bullet" />}
            {showGreenBullet && <TableBulletGreen className="table_bullet" />}
          </td>
          <td
            className={classNames({ locked: locked })}
            style={{
              textAlign: "center",
            }}
          >
            {renderBidAskPrice({
              minPrice: row.minPrice,
              maxPrice: row.maxPrice,
            })}
          </td>
          <td
            style={{ background: `${minGradient}, ${maxGradient}` }}
            className={classNames("relative pl-2 pr-2", { locked: locked })}
          >
            {renderCheckSize({
              minAmount: row.minAmount,
              maxAmount: row.maxAmount,
            })}
          </td>
          <td
            className={classNames({
              locked: locked,
              "opacity-50": referenceDate === NOT_AVAILABLE_STR,
            })}
          >
            {referenceDate}
          </td>
          <td className={classNames({ locked: locked })}>
            <TextChip text={IOITypeLabel[row.type]} />
          </td>
        </tr>
      );
    };

    return (
      <div ref={ref}>
        {Boolean(fund) && (
          <>
            <IoiBuyInterestList
              hasNonExpiredBids={nonExpiredBids.length > 0}
              Bids={nonExpiredBids.map((row, i) =>
                renderBidAskRow(row, i, !canViewBuyIois),
              )}
              bidState={bidState}
              bidRank={bidRank}
              toggleSubmitIoiModal={() => toggleSubmitIoiModal(IoiType.Bid)}
              fundId={fund.id}
              canViewBuyIois={canViewBuyIois}
            />
            <IoiSellInterestList
              canViewSellIois={canViewSellIois}
              fundId={fund.id}
              nonExpiredAsks={nonExpiredAsks}
              renderBidAskRow={renderBidAskRow}
            />
          </>
        )}
      </div>
    );
  },
);
