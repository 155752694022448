import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Stack } from "@mui/material";

import { PageHead } from "common/components/PageHead";
import {
  TapTable,
  TableAlignment,
  TableCell,
} from "experiences/funds/presentation/components/Table";
import { FirmTeamMember } from "experiences/firm/domain/models/FirmTeamMember";
import { useFirm } from "../hooks/useFirm";
import TextChip from "experiences/common/TextChip";

const columns = [
  {
    key: "name",
    label: "Name",
    align: TableAlignment.LEFT,
  },
  {
    key: "email",
    label: "Email",
    align: TableAlignment.LEFT,
  },
  {
    key: "fund-access",
    label: "Fund Access",
    align: TableAlignment.LEFT,
  },
  {
    key: "last-access",
    label: "Last Access",
    align: TableAlignment.LEFT,
  },
  {
    key: "actions",
    label: "",
    align: TableAlignment.RIGHT,
  },
];

const TeamMembersTable = ({
  teamMembers,
  isLoading,
}: {
  teamMembers: FirmTeamMember[];
  isLoading: boolean;
}) => {
  return (
    <>
      <TapTable
        isLoading={isLoading}
        data={teamMembers}
        emptyStateTitle={"There are no members associated with this firm."}
        emptyStateDescription={
          "Invite team members to collaborate on your firm's data and documents."
        }
        columns={columns}
        renderRow={(member: FirmTeamMember) => {
          const onRowClick = (m: FirmTeamMember) => {
            console.log("team member clicked", m);
          };

          return (
            <>
              <TableCell
                isPrimaryColumn
                hasBullet
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(member)}
              >
                {member.pcptName}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(member)}
              >
                {member.email}
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(member)}
              >
                <TextChip text={member.fundAccess} />
              </TableCell>
              <TableCell
                alignTop
                cursorPointer={true}
                onClick={() => onRowClick(member)}
              >
                {member.lastAccess}
              </TableCell>

              <TableCell hasActions></TableCell>
            </>
          );
        }}
      />
    </>
  );
};

export const FirmTeamMembers = () => {
  const { loadTeamMembers, teamMembers, isLoadingTeamMembers } = useFirm();

  useEffect(() => {
    loadTeamMembers();
  }, []);

  return (
    <>
      <Helmet title="Team Members" />
      <PageHead title="Team Members" />
      <Stack style={{ width: "100%" }}>
        <TeamMembersTable
          teamMembers={teamMembers}
          isLoading={isLoadingTeamMembers}
        />
      </Stack>
    </>
  );
};
