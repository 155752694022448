import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IDocument } from "experiences/documents/models/Document";

export class ListDocumentsForCurrentUser
  implements IAsyncUseCase<IParams, IDocument[]>
{
  call: (params: IParams) => Promise<Either<Failure, IDocument[]>> = async (
    params,
  ) => {
    return await new TapClient(`documents/list/`, params)
      .post()
      .then((resp: IDocument[]) => {
        return right(resp);
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {}
