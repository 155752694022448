import { useNavigate } from "react-router";
import { ArrowLeft } from "@phosphor-icons/react";

import { usePortfolioContext } from "../state/PortfolioContext";
import { CloseSellInterestFlowPressed } from "../state/PortfolioEvent";
import {
  ConfigureTransactionLoaded,
  ConfirmHoldingDetailsLoaded,
  EngageTapLoaded,
  ReviewTransactionLoaded,
  SellInterestFlowLoaded,
  SellInterestFlowState,
  SetTimelineLoaded,
} from "../state/PortfolioState";
import ConfirmHoldingsDetailsStep from "../components/sell/ConfirmHoldingsDetailsStep";
import ConfigureTransactionStep from "../components/sell/ConfigureTransactionStep";
import EngageTapStep from "../components/sell/EngageTapStep";
import TransactionReviewStep from "../components/sell/TransactionReviewStep";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import SetTimelineStep from "../components/sell/SetTimelineStep";
import { EmptyState } from "common/components/EmptyState";
import { LP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES } from "common/components/Button";

enum StepIndex {
  ConfirmHoldings = 0,
  ConfigureTransaction = 1,
  EngageTap = 2,
  ConfigureTimeline = 3,
  ReviewTransaction = 4,
}

const sellHoldingFlowSteps = [
  { id: 0, name: "Confirm Holding Details" },
  { id: 1, name: "Configure Transaction" },
  { id: 2, name: "Engage Tap" },
  { id: 3, name: "Configure Timeline" },
  { id: 4, name: "Review Transaction" },
];

export const SellHoldingFlow = () => {
  const { emitEvent, sellInterestFlowState } = usePortfolioContext();
  const navigate = useNavigate();

  const closeDrawer = () => {
    emitEvent!(new CloseSellInterestFlowPressed());
  };

  const flowLoaded = sellInterestFlowState instanceof SellInterestFlowLoaded;

  const buildBasedOnState = (state: SellInterestFlowState) => {
    const confirmHoldingsStepActive =
      state instanceof ConfirmHoldingDetailsLoaded;
    const configureTransactionsStepActive =
      state instanceof ConfigureTransactionLoaded;
    const engageTapStepActive = state instanceof EngageTapLoaded;
    const configureTimelineStepActive = state instanceof SetTimelineLoaded;
    const reviewTransactionStepActive =
      state instanceof ReviewTransactionLoaded;

    const activeStepIndex = confirmHoldingsStepActive
      ? StepIndex.ConfirmHoldings
      : configureTransactionsStepActive
        ? StepIndex.ConfigureTransaction
        : engageTapStepActive
          ? StepIndex.EngageTap
          : configureTimelineStepActive
            ? StepIndex.ConfigureTimeline
            : reviewTransactionStepActive
              ? StepIndex.ReviewTransaction
              : StepIndex.ConfirmHoldings;

    return flowLoaded ? (
      <StepperFlow
        onClose={closeDrawer}
        steps={sellHoldingFlowSteps}
        name={"Submit Sell Interest"}
        activeStepIndex={activeStepIndex}
      >
        {confirmHoldingsStepActive ? (
          <ConfirmHoldingsDetailsStep holdings={state.selectedHoldings} />
        ) : null}
        {configureTransactionsStepActive ? <ConfigureTransactionStep /> : null}
        {engageTapStepActive ? <EngageTapStep /> : null}
        {configureTimelineStepActive ? <SetTimelineStep /> : null}
        {reviewTransactionStepActive ? (
          <TransactionReviewStep state={state} />
        ) : null}
      </StepperFlow>
    ) : (
      <EmptyState
        title="There was an issue"
        actions={[
          {
            label: "Go back to portfolio",
            onClick: () => {
              navigate(LP_ROUTES.Portfolio);
            },
            type: BUTTON_TYPES.PRIMARY,
            icon: <ArrowLeft />,
          },
        ]}
      />
    );
  };
  return buildBasedOnState(sellInterestFlowState);
};
