import { useState } from "react";
import { match } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import { GetFirmTeamMembers } from "experiences/firm/domain/usecases/GetFirmTeamMembers";

import { FirmTeamMember } from "experiences/firm/domain/models/FirmTeamMember";
import {
  FirmFundsLoaded,
  FirmFundsLoading,
  FirmTeamMembersLoaded,
  FirmTeamMembersLoading,
} from "./FirmState";
import {
  FirmEvent,
  FirmFundsRequested,
  FirmTeamMembersRequested,
} from "./FirmEvent";
import { FirmFund } from "experiences/firm/domain/models/FirmFund";
import { GetFirmFunds } from "experiences/firm/domain/usecases/GetFirmFunds";
import { mockFirmTeamMembers } from "../mockFirmTeamMembers";
import { mockFirmFunds } from "../mockFirmFunds";

// TODO: add firm funds
export const useManageFirmState = ({
  getFirmTeamMembers,
  getFirmFunds,
}: {
  getFirmTeamMembers: GetFirmTeamMembers;
  getFirmFunds: GetFirmFunds;
}) => {
  const [teamMembersState, setTeamMembersState] = useState(
    new FirmTeamMembersLoading([]),
  );
  const [firmFundsState, setFirmFundsState] = useState(
    new FirmFundsLoading([]),
  );

  let prevEvent: FirmEvent;

  const emitEvent = (event: FirmEvent) => {
    // Prevents the same event from being emitted twice, can we skip this?
    if (prevEvent == event) {
      return;
    }

    if (event instanceof FirmTeamMembersRequested) {
      getFirmTeamMembers.call({}).then((resp) => {
        match<Failure, FirmTeamMember[], void>(
          (_: Failure) => {
            console.info("failed to load firm members");
            setTeamMembersState(new FirmTeamMembersLoaded(mockFirmTeamMembers));
          },
          (members: FirmTeamMember[]) => {
            setTeamMembersState(new FirmTeamMembersLoaded(members));
          },
        )(resp);
      });
    } else if (event instanceof FirmFundsRequested) {
      getFirmFunds.call({}).then((resp) => {
        match<Failure, FirmFund[], void>(
          (_: Failure) => {
            console.info("failed to load firm funds");
            setFirmFundsState(new FirmFundsLoaded(mockFirmFunds));
          },
          (funds: FirmFund[]) => {
            setFirmFundsState(new FirmFundsLoaded(funds));
          },
        )(resp);
      });
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    teamMembersState,
    firmFundsState,
  };
};
