import { Navigate, Route, Routes, generatePath, useParams } from "react-router";
import { NavLink } from "react-router-dom";

import { baseTransactionPathForExperienceType } from "Routes";

import { BUTTON_TYPES, PlusIcon } from "common/components/Button";
import { RowTabNavLinkWrapper } from "experiences/authentication/presentation/components/RowTabWrapper";
import { useAuthContext } from "../../../../authentication/presentation/state/AuthenticationContext";
import {
  IParticipant,
  ParticipantCapacity,
  ParticipantRole,
} from "../../../domain/models/Participant";
import { useTransactionsContext } from "../../state/TransactionsContext";
import { ParticipantListLoaded } from "../../state/participants/ParticipantsState";
import { TransactionDetailsRoutes } from "../TransactionDetails";
import { ParticipantsTable } from "./ParticipantsTable";
import { getParticipantById, getViewableParticipants } from "./utils";

export const ParticipantsTabWrapper = ({
  handleAddParticipant,
  handleSendInvite,
  selectParticipants,
  unselectParticipants,
  selectedParticipants,
  userCanSendInvites,
}: {
  handleAddParticipant: () => void;
  handleSendInvite: () => void;
  selectParticipants: (pcpts: IParticipant[]) => void;
  unselectParticipants: (pcpts: IParticipant[]) => void;
  selectedParticipants: IParticipant[];
  userCanSendInvites: boolean;
}) => {
  const { participantsListState } = useTransactionsContext();
  const { user } = useAuthContext();
  const { txnId } = useParams();

  // TODO: Maybe change this for a loading state... but do that later
  if (!user) return null;

  if (participantsListState instanceof ParticipantListLoaded) {
    const currUserParticipant = getParticipantById({
      participants: participantsListState.participants,
      participantId: user.id,
    });

    // filter out all participants that are not the current user that's logged in
    const viewableParticipants = getViewableParticipants({
      participants: participantsListState.participants,
      participantId: user.id,
    });

    const buyersList = viewableParticipants.filter(
      (participant) => participant.capacity == ParticipantCapacity.Buyer,
    );

    const sellerAdvisorsAndTeamMembersList = [
      ...(currUserParticipant?.id ? [currUserParticipant] : []),
      ...viewableParticipants.filter(
        (participant) =>
          participant.capacity == ParticipantCapacity.Seller &&
          [
            ParticipantRole.TapAdvisor,
            ParticipantRole.Advisor,
            ParticipantRole.TeamMember,
          ].includes(participant.role),
      ),
    ];

    const buyersOfSameOrgList = viewableParticipants.filter(
      (participant) =>
        participant.capacity == ParticipantCapacity.Buyer &&
        participant.organizationId == currUserParticipant?.organizationId,
    );

    // if the logged in user is the buyer...
    if (currUserParticipant?.capacity == ParticipantCapacity.Buyer) {
      return (
        <ParticipantsTable
          selectParticipants={selectParticipants}
          unselectParticipants={unselectParticipants}
          currentParticipant={currUserParticipant}
          handleSendInvite={handleSendInvite}
          selectedParticipants={selectedParticipants}
          participants={buyersOfSameOrgList}
          userCanSendInvites={userCanSendInvites}
        />
      );
    }

    const baseRoute = generatePath<string>(
      baseTransactionPathForExperienceType[user.experienceType],
      { txnId },
    );

    return (
      <>
        <RowTabNavLinkWrapper showBorderBottom className="h-12">
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.ParticipantsBuyers}`}
          >
            Buyers
          </NavLink>
          <NavLink
            to={`${baseRoute}${TransactionDetailsRoutes.ParticipantsTeamMembers}`}
          >
            Team Members
          </NavLink>
        </RowTabNavLinkWrapper>
        <Routes>
          <Route
            path={TransactionDetailsRoutes.ParticipantsBuyers.replace(
              TransactionDetailsRoutes.Participants,
              "",
            )}
            element={
              <ParticipantsTable
                selectParticipants={selectParticipants}
                unselectParticipants={unselectParticipants}
                participants={buyersList}
                handleSendInvite={handleSendInvite}
                currentParticipant={currUserParticipant}
                selectedParticipants={selectedParticipants}
                omitCurrentParticipant
                userCanSendInvites={userCanSendInvites}
                emptyActions={[
                  {
                    label: "Add Participants",
                    onClick: handleAddParticipant,
                    type: BUTTON_TYPES.PRIMARY,
                    icon: <PlusIcon />,
                  },
                ]}
              />
            }
          />
          <Route
            path={TransactionDetailsRoutes.ParticipantsTeamMembers.replace(
              TransactionDetailsRoutes.Participants,
              "",
            )}
            element={
              <ParticipantsTable
                selectParticipants={selectParticipants}
                unselectParticipants={unselectParticipants}
                currentParticipant={currUserParticipant}
                selectedParticipants={selectedParticipants}
                handleSendInvite={handleSendInvite}
                participants={sellerAdvisorsAndTeamMembersList}
                userCanSendInvites={userCanSendInvites}
                emptyActions={[
                  {
                    label: "Add Participants",
                    onClick: handleAddParticipant,
                    type: BUTTON_TYPES.PRIMARY,
                    icon: <PlusIcon />,
                  },
                ]}
              />
            }
          />
          {/* if route is TransactionDetailsRoutes.Participants, redirect to baseRoute + TransactionDetailsRoutes.ParticipantsBuyers */}
          <Route
            // path={TransactionDetailsRoutes.Participants}
            path="*"
            element={
              <Navigate
                to={`${baseRoute}${TransactionDetailsRoutes.ParticipantsBuyers}`}
                replace
              />
            }
          />
        </Routes>
      </>
    );
  }

  return null;
};
