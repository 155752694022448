import { useContext } from "react";

import { TableFundLogo } from "common/components/TableFundLogo";
import { COMPANY_LOGO_URL } from "common/constants/platform";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import {
  ITabTableColumn,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { cn } from "common/utils";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";

import { ICompanyData } from "./model";
import { useInvestmentsCompanyData } from "./useInvestments";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";

enum TableColumnKeys {
  CompanyName = "companyName",
  TransactionPercent = "transactionPercent",
  TapCompanyId = "tapCompanyId",
  LpImpliedInvestmentValue = "lpImpliedInvestmentValue",
  FundName = "fundName",
  TapFundId = "tapFundId",
  TapInvestmentId = "tapInvestmentId",
  ReportDate = "reportDate",
  CompanyCurrency = "companyCurrency",
  FundOwnershipPercent = "fundOwnershipPercent",
  EquityValue = "equityValue",
  EnterpriseValue = "enterpriseValue",
  RevenueLtm = "revenueLtm",
  RevenueLatestAnnual = "revenueLatestAnnual",
  RevenueYoyPercentChgLtm = "revenueYoyPercentChgLtm",
  RevenueYoyPercentChgAnnual = "revenueYoyPercentChgAnnual",
  RevenueProjected = "revenueProjected",
  EbitdaLtm = "ebitdaLtm",
  EbitdaLatestAnnual = "ebitdaLatestAnnual",
  EbitdaYoyPercentChgLtm = "ebitdaYoyPercentChgLtm",
  EbitdaYoyPercentChgAnnual = "ebitdaYoyPercentChgAnnual",
  EbitdaProjected = "ebitdaProjected",
  EbitdaLtmMargin = "ebitdaLtmMargin",
  CompanyCash = "companyCash",
  CompanyDebt = "companyDebt",
  CompanyNetDebt = "companyNetDebt",
  CapexLtm = "capexLtm",
  CapexLatestAnnual = "capexLatestAnnual",
  EbitdaMultipleEntry = "ebitdaMultipleEntry",
  EbitdaMultipleLtm = "ebitdaMultipleLtm",
  LeverageMultipleEntry = "leverageMultipleEntry",
  LeverageMultipleLtm = "leverageMultipleLtm",
  RevenueMultipleEntry = "revenueMultipleEntry",
  RevenueMultipleLtm = "revenueMultipleLtm",
  CompanyDescription = "companyDescription",
}

// prettier-ignore
const columns: ITabTableColumn[] = [
  { label: "Company Name", key: TableColumnKeys.CompanyName, align: TableAlignment.LEFT, className: "!w-96 !max-w-96" },
  { label: "Txn %", key: TableColumnKeys.TransactionPercent, align: TableAlignment.RIGHT, },
  { label: "Fund Name", key: TableColumnKeys.FundName, align: TableAlignment.LEFT, },
  { label: "Equity Value", key: TableColumnKeys.EquityValue, align: TableAlignment.RIGHT, },
  { label: "Enterprise Value", key: TableColumnKeys.EnterpriseValue, align: TableAlignment.RIGHT, },
  { label: "Revenue LTM", key: TableColumnKeys.RevenueLtm, align: TableAlignment.RIGHT, },
  { label: "Revenue Latest Annual", key: TableColumnKeys.RevenueLatestAnnual, align: TableAlignment.RIGHT, },
  { label: "Revenue YoY % Chg LTM", key: TableColumnKeys.RevenueYoyPercentChgLtm, align: TableAlignment.RIGHT, },
  { label: "Revenue YoY % Chg Annual", key: TableColumnKeys.RevenueYoyPercentChgAnnual, align: TableAlignment.RIGHT, },
  { label: "Revenue Projected", key: TableColumnKeys.RevenueProjected, align: TableAlignment.RIGHT, },
  { label: "EBITDA LTM", key: TableColumnKeys.EbitdaLtm, align: TableAlignment.RIGHT, },
  { label: "EBITDA Latest Annual", key: TableColumnKeys.EbitdaLatestAnnual, align: TableAlignment.RIGHT, },
  { label: "EBITDA YoY % Chg LTM", key: TableColumnKeys.EbitdaYoyPercentChgLtm, align: TableAlignment.RIGHT, },
  { label: "EBITDA YoY % Chg Annual", key: TableColumnKeys.EbitdaYoyPercentChgAnnual, align: TableAlignment.RIGHT, },
  { label: "EBITDA Projected", key: TableColumnKeys.EbitdaProjected, align: TableAlignment.RIGHT, },
  { label: "EBITDA LTM Margin", key: TableColumnKeys.EbitdaLtmMargin, align: TableAlignment.RIGHT, },
  { label: "Company Cash", key: TableColumnKeys.CompanyCash, align: TableAlignment.RIGHT, },
  { label: "Company Debt", key: TableColumnKeys.CompanyDebt, align: TableAlignment.RIGHT, },
  { label: "Company Net Debt", key: TableColumnKeys.CompanyNetDebt, align: TableAlignment.RIGHT, },
  { label: "Capex LTM", key: TableColumnKeys.CapexLtm, align: TableAlignment.RIGHT, },
  { label: "Capex Latest Annual", key: TableColumnKeys.CapexLatestAnnual, align: TableAlignment.RIGHT, },
  { label: "EBITDA Multiple Entry", key: TableColumnKeys.EbitdaMultipleEntry, align: TableAlignment.RIGHT, },
  { label: "EBITDA Multiple LTM", key: TableColumnKeys.EbitdaMultipleLtm, align: TableAlignment.RIGHT, },
  { label: "Leverage Multiple Entry", key: TableColumnKeys.LeverageMultipleEntry, align: TableAlignment.RIGHT, },
  { label: "Leverage Multiple LTM", key: TableColumnKeys.LeverageMultipleLtm, align: TableAlignment.RIGHT, },
  { label: "Revenue Multiple Entry", key: TableColumnKeys.RevenueMultipleEntry, align: TableAlignment.RIGHT, },
  { label: "Revenue Multiple LTM", key: TableColumnKeys.RevenueMultipleLtm, align: TableAlignment.RIGHT, },
]

export const CompanyDataTable = () => {
  const { reportDates } = useContext(TransactionFundDataContext);
  const { companyData, companyDataLoading } = useInvestmentsCompanyData({
    currentReportDate: reportDates.qualitativeData.currentDate,
  });

  const extractionReady = true;
  const hasReportDates = reportDates.qualitativeData.options.length > 0;

  const renderRow = (row: ICompanyData) => {
    const imgSrc = `${COMPANY_LOGO_URL}/${row.companyId || undefined}.jpg`;

    return (
      // prettier-ignore
      <>
        <TableCell isPrimaryColumn isFixed className={cn("!whitespace-nowrap !overflow-hidden !overflow-ellipsis !pt-1 !pb-1", "!w-96 !max-w-96")}> <TableFundLogo imgSrc={imgSrc} fundName={row.companyName} size="small" /> </TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.lpImpliedInvestmentPercentage, format: "percentage2dec" })}</TableCell>
        <TableCell>{row.fundName}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.equityValue, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.enterpriseValue, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueLtm, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueLatestAnnual, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueYoyPercentChgLtm, format: "percentage" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueYoyPercentChgAnnual, format: "percentage" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueProjected, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaLtm, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaLatestAnnual, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaYoyPercentChgLtm, format: "percentage" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaYoyPercentChgAnnual, format: "percentage" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaProjected, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaLtmMargin, format: "percentage" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.companyCash, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.companyDebt, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.companyNetDebt, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.capexLtm, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.capexLatestAnnual, format: "currency" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaMultipleEntry, format: "multiplier" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.ebitdaMultipleLtm, format: "multiplier" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.leverageMultipleEntry, format: "multiplier" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.leverageMultipleLtm, format: "multiplier" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueMultipleEntry, format: "multiplier" })}</TableCell>
        <TableCell className="text-right">{tableCellDataFormatter({ value: row.revenueMultipleLtm, format: "multiplier" })}</TableCell>
      </>
    );
  };

  return (
    <div className="relative min-h-64 mt-8">
      <PageSectionTitleDivider
        showBorderTop
        actions={
          extractionReady &&
          hasReportDates && (
            <SelectDateDropdown
              value={reportDates.qualitativeData.currentDate}
              onValueChange={reportDates.qualitativeData.onReportDateChange}
              options={reportDates.qualitativeData.options}
              loading={companyDataLoading}
            />
          )
        }
      >
        Company Data
      </PageSectionTitleDivider>
      <div className="w-full max-w-full overflow-x-scroll relative pb-16">
        <TapTable
          columns={columns}
          data={companyData}
          isLoading={companyDataLoading}
          renderRow={renderRow}
          skipTopBorder
          fixedHeaders
          emptyStateTitle="No company data found"
          emptyStateDescription="No company data found for the selected report date and issuers"
          showEmptyState={companyData.length === 0}
          className="!max-w-max !w-max !overflow-scroll"
        />
      </div>
    </div>
  );
};
