import { GP_ROUTES, GpLimitedPartnersRoutes } from "common/constants/routes";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";
import { GpLimitedPartners } from "./GpLimitedPartners";
import { GpLimitedPartnersUploadSplit } from "./GpLimitedPartnersUploadSplit";
import { GpLimitedPartnersUploadCapitalAccountStatement } from "./GpLimitedPartnersUploadCapitalAccountStatement";
import { GpLimitedPartnersUploadHoldings } from "./GpLimitedPartnersUploadHoldings";

export const GpLimitedPartnersWrapper = () => {
  const baseRoute = GP_ROUTES.LimitedPartners;
  const { transactionId = "" } = useParams();

  return (
    <Routes>
      <Route
        path={GpLimitedPartnersRoutes.List}
        element={<GpLimitedPartners />}
      />
      <Route
        path={GpLimitedPartnersRoutes.UploadSplit}
        element={<GpLimitedPartnersUploadSplit />}
      />
      <Route
        path={GpLimitedPartnersRoutes.UploadCapitalAccountStatement}
        element={<GpLimitedPartnersUploadCapitalAccountStatement />}
      />
      <Route
        path={GpLimitedPartnersRoutes.UploadCapitalAccountsBulk}
        element={<GpLimitedPartnersUploadHoldings />}
      />
      <Route
        path="/"
        element={
          <Navigate
            to={`${generatePath(`${baseRoute}${GpLimitedPartnersRoutes.List}`, {
              transactionId,
            })}`}
          />
        }
      />
    </Routes>
  );
};
