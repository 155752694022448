import { TransactionDetailsEvent } from "../TransactionsEvent";
import { IParticipant } from "experiences/transactions/domain/models/Participant";

export class ParticipantsEvent extends TransactionDetailsEvent {
  participantId: string;

  constructor({
    participantId,
    txnId,
  }: {
    participantId: string;
    txnId: string;
  }) {
    super({ txnId: txnId });
    this.participantId = participantId;
  }
}

export class RejectButtonClicked extends ParticipantsEvent {}

export class SendInviteButtonClicked extends TransactionDetailsEvent {
  pcpts: IParticipant[];

  constructor({ pcpts, txnId }: { pcpts: IParticipant[]; txnId: string }) {
    super({ txnId: txnId });
    this.pcpts = pcpts;
  }
}

export class CopyInviteLinkButtonClicked extends TransactionDetailsEvent {
  pcpt: IParticipant;

  constructor({ pcpt, txnId }: { pcpt: IParticipant; txnId: string }) {
    super({ txnId: txnId });
    this.pcpt = pcpt;
  }
}
