import styled from "styled-components";

const Rotate = styled.span`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  svg {
    animation:
      spin 600ms linear infinite,
      fadeIn 800ms linear;
  }
`;

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const LoadingIcon = () => (
  <Rotate>
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <rect fill="#fff" fillOpacity=".01" height="32" rx="6" width="32" /> */}
      <g stroke="#737476" strokeWidth="1.77778">
        <rect
          height="12.4444"
          opacity=".3"
          rx="6.22222"
          width="12.4444"
          x="9.77756"
          y="9.7778"
        />
        <path d="m15.5557 9.77802h.4444c3.4364 0 6.2222 2.78578 6.2222 6.22218" />
      </g>
    </svg>
  </Rotate>
);

export const LoadingCentered = () => {
  return (
    <StyledLoading>
      <LoadingIcon />
    </StyledLoading>
  );
};
