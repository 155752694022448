import { useParams } from "react-router";
import { useFormik } from "formik";
import { Box, Modal, Stack } from "@mui/material";
import { Editor } from "primereact/editor";
import "primeicons/primeicons.css";
// Commented because it's breaking
import * as yup from "yup";
import { setupInlineStyles } from "common/quill/quill-config.js";

import { PaperPlaneRight } from "@phosphor-icons/react";
import { useTransactionsContext } from "../../state/TransactionsContext.js";
import { IParticipant } from "experiences/transactions/domain/models/Participant";

import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";
import "primereact/resources/themes/fluent-light/theme.css";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { NotifyDataroomUploadRequested } from "../../state/TransactionsEvent.js";

// quill is used for the rich text editor
setupInlineStyles();

export const SendDataRoomUpdateModal = ({
  participants,
  open,
  onClose,
}: {
  participants: IParticipant[];
  open: boolean;
  onClose: () => void;
}) => {
  const { emitEvent } = useTransactionsContext();

  const validationSchema = yup.object({
    message: yup.string().required("Content is required to send invite."),
  });

  const { txnId } = useParams();

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent(
        new NotifyDataroomUploadRequested({
          transactionId: txnId,
          participantIds: participants.map((p) => p.id),
          message: values.message,
        }),
      );
      handleClose();
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const renderRichTextHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-strike"></button>
        <button className="ql-blockquote"></button>
        <select className="ql-align"></select>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-indent" value="-1"></button>
        <button className="ql-indent" value="+1"></button>
        <button className="ql-header" value="1"></button>
        <button className="ql-header" value="2"></button>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
      </span>
    );
  };

  const richTextheader = renderRichTextHeader();

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={formik.submitForm}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <TapUiModalHead
              title="Send Dataroom Update"
              subtitle="The selected participants will receive an email notification with the message below."
              onCloseClick={handleClose}
            />
            <div id="editor-container">
              <Editor
                style={{
                  height: "320px",
                }}
                name={"message"}
                value={formik.values["message"]}
                headerTemplate={richTextheader}
                onTextChange={(e) => {
                  formik.setFieldValue("message", e.htmlValue ?? "");
                }}
              />
            </div>
            {formik.touched["message"] && formik.errors["message"] && (
              <p style={{ color: "red", fontSize: "12px" }}>
                {formik.errors["message"]}
              </p>
            )}

            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
            >
              <Button type={BUTTON_TYPES.PRIMARY} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type={BUTTON_TYPES.SECONDARY}
                onClick={formik.submitForm}
                icon={<PaperPlaneRight />}
                iconRight
              >
                Send Notifications
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
