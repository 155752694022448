import { cn } from "common/utils";
import { TRANSACTION_LOGO_URL } from "common/constants/platform";

const FALLBACK_LOGO_ID = "default-fund-logo.png";

export const TableFundLogo = ({
  imgSrc,
  fundName,
  children,
  size = "small",
}: {
  imgSrc: string;
  fundName?: string;
  children?: React.ReactNode;
  size?: "small" | "medium" | "large";
}) => {
  return (
    <div
      className="inline items-center table_fund_logo max-w-full overflow-ellipsis align-middle"
      title={fundName}
    >
      <img
        src={`${imgSrc}`}
        alt={`${fundName} logo`}
        className={cn(
          "w-8 h-8 mr-4 overflow-hidden border border-stone-200 select-none rounded-lg shadow-sm bg-white inline",
          {
            "w-8 h-8 min-w-8 min-h-8": size === "small",
            "w-10 h-10 min-w-10 min-h-10": size === "medium",
            "w-12 h-12 min-w-12 min-h-12": size === "large",
          },
        )}
        onError={(e) => {
          e.currentTarget.src = `${TRANSACTION_LOGO_URL}/${FALLBACK_LOGO_ID}`;
          e.currentTarget.alt = "";
          e.currentTarget.className = cn(e.currentTarget.className, {
            "p-1": size === "small",
            "p-2": size === "medium",
            "p-3": size === "large",
          });
        }}
      />
      {fundName && fundName}
      {children}
    </div>
  );
};
