import { useCallback, useEffect, useMemo, useState } from "react";
import { Prohibit, Warning } from "@phosphor-icons/react";
import moment from "moment";

import { useAuthContext } from "../state/AuthenticationContext";
import { Button } from "common/components/Button";
import { StopImpersonationClicked } from "../state/AuthenticationEvent";
import useLocalStorage from "common/hooks/useLocalStorage";
import { AUTH_KEYS } from "common/constants/localStorage";
import { APP_MENU_HEIGHT_PX } from "experiences/common/PageWrapper";

const SECONDS_TO_MILLISECONDS = 1000;

const ImpersonationBanner = () => {
  const { user, emitEvent } = useAuthContext();
  const [impersonationState, , removeImpersonation] = useLocalStorage(
    AUTH_KEYS.IMPERSONATION,
    "false",
  );
  const [impersonation, setImpersonation] = useState<
    Record<string, unknown> | undefined
  >(undefined);
  const [timeLeft, setTimeLeft] = useState(impersonation?.timeLeft);

  const stopImpersonation = useCallback(() => {
    emitEvent!(new StopImpersonationClicked());
    removeImpersonation();
    window.location.reload();
  }, [emitEvent, removeImpersonation]);

  useEffect(() => {
    let parsedData;
    try {
      parsedData = JSON.parse(impersonationState) ?? undefined;
    } catch (error) {
      parsedData = undefined;
    }
    setImpersonation(parsedData);
    setTimeLeft(Math.floor(parsedData?.timeLeft));
  }, [impersonationState]);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1) {
          stopImpersonation();
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, SECONDS_TO_MILLISECONDS);

    return () => clearInterval(intervalId);
  }, [timeLeft, stopImpersonation]);

  const formattedTimeLeft = useMemo(() => {
    return moment
      .utc((timeLeft as number) * SECONDS_TO_MILLISECONDS)
      .format("HH:mm:ss");
  }, [timeLeft]);

  if (!user) return null;

  return (
    <div
      className={`flex justify-around items-center bg-yellow-400 fixed top-0 w-full z-50 p-2`}
      style={{ height: APP_MENU_HEIGHT_PX }}
    >
      <p className="mr-4 flex items-center">
        <Warning size={24} className="mr-2" />
        You're impersonating{" "}
        <span className="font-bold ml-2">{user.email}</span>
        <Button
          onClick={stopImpersonation}
          className="!ml-4"
          icon={<Prohibit />}
        >
          Stop
        </Button>
      </p>
      {timeLeft ? (
        <span className="mr-6">Time left: {formattedTimeLeft}</span>
      ) : null}
    </div>
  );
};

export default ImpersonationBanner;
