import { useEffect } from "react";
import { Navigate, Route, Routes, generatePath, useParams } from "react-router";
import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";

import { baseTransactionPathForExperienceType } from "Routes";
import { APP_BAR_TRANSACTION_NAME_NODE } from "common/constants/assets";
import { SidebarSideMenu } from "experiences/dashboard/presentation/components/SidebarMenu";
import {
  LayoutWrapper,
  MainContent,
  Sidebar,
} from "common/components/MainSidebarLayout";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

import DataRoom from "./data-room/DataRoom";
import { useTransactionsContext } from "../state/TransactionsContext";
import {
  ProcessDataRoomFileFinished,
  TransactionDetailsScreenLoaded,
} from "../state/TransactionsState";
import BidsViewScreen from "./bidding/BidsViewScreen";
import { ParticipantsScreen } from "./participants/ParticipantsScreen";
import { TransactionTimeline } from "./timeline/TransactionTimeline";
import { TransactionContact } from "experiences/common/TransactionContact";
import {
  LoadTransactionDetails,
  ManageParticipantsButtonClicked,
} from "../state/TransactionsEvent";
import { TransactionSettings } from "../pages/TransactionSettings";
import { TransactionFundDataScreen } from "./fund-data/TransactionFundDataScreen";
import { useTransactionParticipants } from "./fund-data/common/useTransactionParticipants";

export enum TransactionDetailsRoutes {
  Timeline = "/timeline",
  Participants = "/participants",
  ParticipantsBuyers = "/participants/buyers",
  ParticipantsTeamMembers = "/participants/members",
  FundData = "/fund-data",
  DataRoom = "/dataroom",
  Bidding = "/bidding",
  Settlement = "/settlement",
  Settings = "/settings",
}

// prettier-ignore
const transactionSidebarMenuEntries = [
  { label: "Data Room", path: TransactionDetailsRoutes.FundData },
  { label: "Timeline", path: TransactionDetailsRoutes.Timeline },
  { label: "Participants", path: TransactionDetailsRoutes.Participants },
  { label: "Bids", path: TransactionDetailsRoutes.Bidding },
  { label: "Settings", path: TransactionDetailsRoutes.Settings },
];

export const TransactionDetails = () => {
  const { txnId } = useParams();
  const { emitEvent, txnDetailsState, emitPcptEvent, dataroomUploadState } =
    useTransactionsContext();
  const { user } = useAuthContext();
  const { showSettingsPage } = useTransactionParticipants();
  // const { showNewDocumentsTab } = useShowNewDocumentsTab({ txnId });

  const experienceType = user?.experienceType;

  const transactionSummary =
    txnDetailsState instanceof TransactionDetailsScreenLoaded
      ? txnDetailsState.summary
      : null;

  useEffect(() => {
    emitEvent!(new LoadTransactionDetails({ txnId: txnId! }));
    // load participants when loading transaction details
    // participans will be used in the participants screen and in the data room screen
    emitPcptEvent!(new ManageParticipantsButtonClicked({ txnId: txnId! }));
  }, []);

  // When process is finished after upload, refetch the dataroom
  useEffect(() => {
    if (dataroomUploadState instanceof ProcessDataRoomFileFinished) {
      // 2024-12-06 this is commented for some reason? maybe it's not needed anymore?
      // Cannot fully recall why I commented this out
      // refetchDataroom();
    }
  }, [dataroomUploadState]);

  if (!txnId) {
    return null;
  }

  const baseRoute = generatePath(
    baseTransactionPathForExperienceType[experienceType],
    { txnId },
  );

  const transactionNameElement = document.getElementById(
    APP_BAR_TRANSACTION_NAME_NODE,
  );

  const transactionName = transactionSummary?.transaction.name;

  // Only show settings page for non-buyers
  const menu = [
    ...transactionSidebarMenuEntries.filter(
      (item) => item.path !== TransactionDetailsRoutes.Settings,
    ),
    ...(showSettingsPage
      ? [{ label: "Settings", path: TransactionDetailsRoutes.Settings }]
      : []),
  ];

  return (
    <LayoutWrapper key={txnId}>
      {transactionName && (
        <Helmet>
          <title>{transactionName}</title>
        </Helmet>
      )}
      <Sidebar title={transactionName} image={`${txnId}.jpg`}>
        <SidebarSideMenu
          menu={menu}
          baseRoute={baseRoute}
          isFundSidebar
          contact={<TransactionContact />}
          loading={!transactionSummary}
        />
      </Sidebar>
      <MainContent>
        {/* prettier-ignore */}
        <Routes>
          <Route path={TransactionDetailsRoutes.Timeline} element={<TransactionTimeline summary={transactionSummary} />} />
          <Route path={`${TransactionDetailsRoutes.Participants}/*`} element={<ParticipantsScreen />} />
          <Route path={TransactionDetailsRoutes.DataRoom} element={<DataRoom />} />
          <Route path={`${TransactionDetailsRoutes.FundData}/*`} element={<TransactionFundDataScreen summary={transactionSummary} />} />
          <Route path={TransactionDetailsRoutes.Participants} element={<ParticipantsScreen />} />
          <Route path={TransactionDetailsRoutes.Bidding} element={<BidsViewScreen txnSummary={transactionSummary} />} />
          <Route path={TransactionDetailsRoutes.Settlement} element={<p>Settlement</p>} />
          {showSettingsPage && (<Route path={TransactionDetailsRoutes.Settings} element={<TransactionSettings />} />)}
          <Route path="*" element={ <Navigate to={`${baseRoute}${TransactionDetailsRoutes.FundData}`} replace /> } />
        </Routes>
      </MainContent>
      {transactionNameElement &&
        createPortal(transactionName, transactionNameElement)}
    </LayoutWrapper>
  );
};
