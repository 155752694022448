import React from "react";

import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Info } from "@phosphor-icons/react";
import { Tooltip, TooltipContent, TooltipTrigger } from "shadcn/ui/tooltip";

interface Props {
  checked?: boolean;
  label?: string;
  labelProps?: Object;
  helperText?: string;
  onChange: (event: any) => void;
  className?: string;
  name?: string;
  tooltipText?: string;
}

export const Checkbox = ({
  checked,
  label,
  labelProps,
  helperText,
  className,
  name,
  tooltipText,
  ...props
}: Props) => (
  <>
    <FormControlLabel
      name={name}
      control={<MuiCheckbox {...props} checked={checked} />}
      label={
        <span className="flex gap-2 items-center">
          {label}
          {tooltipText && (
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Info weight="fill" />
              </TooltipTrigger>
              <TooltipContent className="max-w-60">
                {tooltipText}
              </TooltipContent>
            </Tooltip>
          )}
        </span>
      }
      className={className}
      {...labelProps}
    />

    {helperText && <FormHelperText error>{helperText}</FormHelperText>}
  </>
);

export const TapCheckbox = ({
  checked,
  onChange,
  className,
}: {
  checked: boolean;
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}) => {
  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={className}
      />
    </>
  );
};

export default Checkbox;
