import { SimpleTable, SimpleTableRow } from "experiences/common/SimpleTable";
import { DataConfidence, DealBreakdownRow } from "../mock/DealBreakdownMock";
import { tableCellDataFormatter } from "experiences/funds/presentation/components/Table";

export const DealBreakdownTable = ({
  data,
  loading,
}: {
  data: DealBreakdownRow[];
  loading?: boolean;
}) => {
  return (
    <SimpleTable className="dont_hover cursor-default">
      {data.map((data) => {
        const isEstimated = data.confidence === DataConfidence.Estimated;

        const value = tableCellDataFormatter({
          value: data?.value || undefined,
          format: data?.format || undefined,
        });

        return (
          <SimpleTableRow
            label={data.label}
            value={value}
            key={data.key}
            valueLighter={isEstimated || loading}
            loading={loading}
          />
        );
      })}
    </SimpleTable>
  );
};
