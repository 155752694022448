import React from "react";
import { useParams } from "react-router";
import { Stack, Typography } from "@mui/material";

import { StepHeader } from "../../StepHeader";
import { TOSSubheader } from "../../TOSSubheader";
import {
  Bid,
  BidTransactionHolding,
} from "experiences/transactions/domain/models/Bidding";
import { useTransactionsContext } from "../../../state/TransactionsContext";
import { ReviewBidStepSubmitted } from "../../../state/TransactionsEvent";
import ReviewPricingTable from "../ReviewPricingTable";
import ReviewDocumentSection from "../ReviewDocumentSection";
import { Button } from "common/components/Button";

interface IProps {
  fullBid: Bid;
  holdings: BidTransactionHolding[];
}

const ReviewStep: React.FC<IProps> = ({ fullBid, holdings }) => {
  const { emitEvent } = useTransactionsContext();
  const { txnId } = useParams();

  return (
    <Stack p={8}>
      <Stack spacing={12}>
        <Stack spacing={4}>
          <Stack>
            <StepHeader text="Review Bid" />
            <TOSSubheader />
          </Stack>
          <ReviewDocumentSection document={fullBid.document!} />
          <ReviewPricingTable fullBid={fullBid} holdings={holdings} />
        </Stack>
        <Button
          fullWidth
          onClick={() =>
            emitEvent!(
              new ReviewBidStepSubmitted({
                txnId: txnId!,
                bidId: fullBid.id!,
              }),
            )
          }
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReviewStep;
