import React from "react";
import { useNavigate } from "react-router";
import { Check, XCircle } from "@phosphor-icons/react";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { cn } from "common/utils";
import Loader from "common/components/TapLoader";
import { APP_MENU_HEIGHT_PX } from "../PageWrapper";

export interface IStep {
  name: string;
}

interface IStepperFlowProps {
  name: string;
  steps?: IStep[];
  activeStepIndex: number;
  onClose?: () => void;
  children?: React.ReactNode;
  childrenTopBar?: React.ReactNode;
  className?: string;
  childrenClassName?: string;
}

const StepperFlow: React.FC<IStepperFlowProps> = ({
  name,
  steps,
  activeStepIndex,
  onClose,
  children,
  childrenTopBar,
  className,
  childrenClassName,
}) => {
  const navigate = useNavigate();
  onClose = onClose
    ? onClose
    : () => {
        navigate(-1);
      };

  return (
    <div
      className={cn(
        `fixed top-0 left-0 w-full flex flex-col h-full bg-white z-50`,
        className,
      )}
    >
      <FlowTopBar
        onClose={onClose}
        flowName={name}
        childrenTopBar={childrenTopBar}
      />
      {!steps && <div className="w-full bg-slate-200 h-[1px]" />}
      {steps?.length ? (
        <Stack direction="row" justifyContent="space-evenly" height="64px">
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor:
                  index == activeStepIndex
                    ? "#ffffff"
                    : "rgba(245, 244, 241, 0.40);",
                border: "1px solid #DFDFD9",
                ...(index == activeStepIndex && {
                  borderTop: "none",
                  borderBottom: "3px solid #BCB3A5",
                }),
                borderRight: "none",
                display: "flex",
                alignItems: "center",
                pl: "16px",
                userSelect: "none",
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <FlowStepCircleIndicator
                  stepStatus={
                    index > activeStepIndex
                      ? StepStatus.Incomplete
                      : index < activeStepIndex
                        ? StepStatus.Complete
                        : StepStatus.InProgress
                  }
                />
                <Typography>{step.name}</Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
      ) : null}
      {children ? (
        <div
          className={cn(
            "h-[calc(100vh-57px)] overflow-y-scroll overflow-x-hidden flex items-center justify-center no-srollbar py-8 box-border",
            childrenClassName,
          )}
        >
          {children}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default StepperFlow;

interface IFlowTopBarProps {
  onClose: () => void;
  flowName: string;
  childrenTopBar?: React.ReactNode;
}

export const FlowTopBar: React.FC<IFlowTopBarProps> = ({
  onClose,
  flowName,
  childrenTopBar,
}) => {
  return (
    <Stack
      direction="row"
      width="100%"
      px={2}
      height={`${APP_MENU_HEIGHT_PX}px`}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <IconButton onClick={onClose} style={{ marginRight: 8 }}>
          <XCircle />
        </IconButton>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "1",
            fontWeight: "600",
            margin: 0,
            padding: 0,
            cursor: "default",
          }}
        >
          {flowName}
        </p>
      </Box>
      {childrenTopBar ? (
        <Box display="flex" alignItems="center">
          {childrenTopBar}
        </Box>
      ) : null}
    </Stack>
  );
};

enum StepStatus {
  Complete = 1,
  InProgress = 2,
  Incomplete = 3,
}

interface IFlowStepCircleIndicatorProps {
  stepStatus: StepStatus;
}

const FlowStepCircleIndicator: React.FC<IFlowStepCircleIndicatorProps> = ({
  stepStatus,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "50px",
        width: "20px",
        height: "20px",
        backgroundColor:
          StepStatus.Complete == stepStatus ? "#BCB3A5" : "#ffffff",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        border:
          stepStatus == StepStatus.Incomplete
            ? "1px solid #DFDFD9"
            : "1px solid #BCB3A5",
      }}
    >
      {StepStatus.Complete == stepStatus && (
        <Check size={12} weight="bold" color="#ffffff" />
      )}
    </Box>
  );
};
