import { IManager } from "common/@types/models/Manager";
import TapClient from "common/clients/TapClient";

export const getDropdownOptions = async (
  query: string,
  process: (string: any[]) => void,
  managerCacheRef: React.MutableRefObject<Set<IManager>>,
) => {
  await new TapClient("/managers/", {
    paginationSpecs: {
      perPage: 10,
      pageIndex: 1,
    },
    filters: { searchTerm: query },
  })
    .post()
    .then(
      (resp: {
        items: IManager[];
        total: number;
        pageIndex: number;
        pageCount: number;
        perPage: number;
      }) => {
        // store new fund names in cache, so that we can use them for validation
        // (we only allow valid fund names, validation will gather from this list)
        const currentManagers = Array.from(managerCacheRef.current);

        resp.items.forEach((m) => {
          const existingManager = currentManagers.find(
            (manager) => manager.id === m.id,
          );

          if (!existingManager) {
            managerCacheRef.current.add(m);
          }
        });

        // handsontable receives a process() function that *only* takes in a string[]
        // process(managerCacheRef.current.foreach.map((manager) => manager.name));
        const managerNames = <string[]>[];
        managerCacheRef.current.forEach((m) => managerNames.push(m.name));
        process(managerNames);

        return {
          managers: resp.items,
          totalFunds: resp.total,
          pageIndex: resp.pageIndex,
          pageCount: resp.pageCount,
          perPage: resp.perPage,
        };
      },
    );
};
