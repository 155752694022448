import { useEffect, useState } from "react";

import TapClient from "common/clients/TapClient";

import {
  ITransactionSettings,
  TransactionSettingType,
} from "experiences/transactions/domain/models/Transaction";

export const useShowNewDocumentsTab = ({ txnId }: { txnId: string }) => {
  const [showNewDocumentsTab, setShowNewDocumentsTab] = useState(false);
  const [loading, setLoading] = useState(false);

  // TODO: Only load the setting for dataroom
  const loadTransactionSettings = async () => {
    setLoading(true);
    const path = `/transactions/${txnId}/settings/`;
    await new TapClient(path)
      .get({})
      .then((resp: ITransactionSettings[]) => {
        setShowNewDocumentsTab(
          resp.some(
            (s) => s.type === TransactionSettingType.DataRoom && s.isActive,
          ),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTransactionSettings();
  }, [txnId]);

  return {
    showNewDocumentsTab,
    loading,
  };
};
