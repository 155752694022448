import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { IssuerFilterSpecs } from "../models/IssuerFilterSpecs";
import { PageSpecs } from "../../../../common/@types/models/PageSpecs";
import { IssuersPage } from "../models/IssuersPage";
import { IIssuer } from "common/@types/models/Issuer";

interface IResp {
  items: IIssuer[];
  total: number;
  perPage: number;
  pageIndex: number;
  pageCount: number;
}

export class RetrieveFilteredIssuers
  implements IAsyncUseCase<IParams, IssuersPage>
{
  call: (params: IParams) => Promise<Either<Failure, IssuersPage>> = async (
    params,
  ) => {
    return await new TapClient("/issuers/", params)
      .post()
      .then((resp: IResp) => {
        return right({
          issuers: resp.items,
          totalFunds: resp.total,
          pageIndex: resp.pageIndex,
          pageCount: resp.pageCount,
          perPage: resp.perPage,
        });
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  paginationSpecs: PageSpecs;
  filters: IssuerFilterSpecs;
}
