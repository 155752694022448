import {
  Box,
  Stack,
  Typography,
  Button as MaterialButton,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useParams } from "react-router";

import { StepHeader } from "../../StepHeader";
import { TOSSubheader } from "../../TOSSubheader";
import DocumentPen from "../../../../../../common/components/Icons/DocumentPen";
import { useTransactionsContext } from "../../../state/TransactionsContext";
import { UploadLetterOfIntentStepSubmitted } from "../../../state/TransactionsEvent";
import { Button } from "common/components/Button";

interface IUploadLOIStepProps {
  presignedDownloadUrl?: string;
  fileName?: string;
}

const UploadLOIStep: React.FC<IUploadLOIStepProps> = ({
  presignedDownloadUrl,
  fileName,
}) => {
  const [file, setFile] = useState<File>();

  const setSelectedFile = (file: File) => {
    setFile(file);
  };

  const { txnId } = useParams();
  const { emitEvent } = useTransactionsContext();
  const onSaveAndContinue = () => {
    emitEvent!(
      new UploadLetterOfIntentStepSubmitted({
        txnId: txnId!,
        letterOfIntent: file!,
      }),
    );
  };

  return (
    <Stack p={8}>
      <Stack spacing={12} maxWidth="480px">
        <Stack>
          <StepHeader text="Upload Letter of Intent" />
          <TOSSubheader />
        </Stack>
        <UploadDocumentSection
          presignedDownloadUrl={presignedDownloadUrl}
          fileName={fileName}
          selectedFile={file}
          setSelectedFile={setSelectedFile}
        />
        <Button size="large" disabled={!file} onClick={onSaveAndContinue}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontWeight="bold">Save & Continue</Typography>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
};

interface IUploadDocumentSectionProps {
  presignedDownloadUrl?: string;
  fileName?: string;
  selectedFile?: File;
  setSelectedFile: (file: File) => void;
}

export const UploadDocumentSection: React.FC<IUploadDocumentSectionProps> = ({
  presignedDownloadUrl,
  fileName,
  selectedFile,
  setSelectedFile,
}) => {
  const subheaderText = selectedFile ? selectedFile.name : "Incomplete";
  const uploadButtonText = selectedFile ? "Update Document" : "Upload Document";
  const uploadButtonLeadingLogo = <DocumentPen />;

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F4F1",
        borderRadius: "6px",
      }}
      p={2}
    >
      <Stack spacing={0.25}>
        <Typography variant="body1" fontWeight="bold">
          Binding Letter of Intent
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={400}
          sx={{
            color: "#737476",
          }}
        >
          {subheaderText}
        </Typography>
        <Box height={10} />
        <MaterialButton
          variant="contained"
          component="label"
          sx={{
            height: "48px",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {uploadButtonLeadingLogo}
            <Typography fontWeight="bold">{uploadButtonText}</Typography>
            <input
              type="file"
              hidden
              onChange={onFileChanged}
              accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv"
            />
          </Stack>
        </MaterialButton>
      </Stack>
    </Box>
  );
};

export default UploadLOIStep;
