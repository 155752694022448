export enum TransactionPeriodName {
  TRANSACTION_PREPARATION = "Transaction Preparation",
  FIRST_ROUND_DUE_DILIGENCE = "First Round Due Diligence",
  FIRST_ROUND_BID_EVALUATION = "First Round Bid Evaluation",
  SECOND_ROUND_DUE_DILIGENCE = "Second Round Due Diligence",
  SECOND_ROUND_BID_EVALUATION = "Second Round Bid Evaluation",
  DOCUMENTATION_AND_TRANSACTION_CLOSING = "Documentation and Transaction Closing",
  TARGET_CLOSE = "Target Close",
}

export const TransactionPeriodLabels = {
  [TransactionPeriodName.TRANSACTION_PREPARATION]: {
    start: "Transaction Preparation",
    end: "Transaction Launch",
  },
  [TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE]: {
    start: "Round 1 Due Diligence",
    end: "Bids Due",
  },
  [TransactionPeriodName.FIRST_ROUND_BID_EVALUATION]: {
    start: "Round 1 Bid Evaluation",
    end: "Bids Selected By",
  },
  [TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE]: {
    start: "Round 2 Due Diligence",
    end: "Bids Due",
  },
  [TransactionPeriodName.SECOND_ROUND_BID_EVALUATION]: {
    start: "Round 2 Bid Evaluation",
    end: "Bids Selected By",
  },
  [TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING]: {
    start: "Documentation and Transaction Closing",
    end: "Target Closing",
  },
  [TransactionPeriodName.TARGET_CLOSE]: {
    start: "Closing Period",
    end: "Target Closing",
  },
};

export const TransactionPeriodChecklist: Record<
  TransactionPeriodName,
  string[]
> = {
  [TransactionPeriodName.TRANSACTION_PREPARATION]: [
    "Confirm transaction strategy",
    "Finalize sale portfolio",
    "Compile due diligence information",
    "Review legal documentation",
    "Prepare and finalize virtual data room",
    "Prepare opportunity teaser",
  ],
  [TransactionPeriodName.FIRST_ROUND_DUE_DILIGENCE]: [
    "Buyer outreach",
    "Facilitate buyer diligence including information requests and GP call(s)",
    "Compile updated due diligence information",
    "Collect Round 1 bids",
  ],
  [TransactionPeriodName.FIRST_ROUND_BID_EVALUATION]: [
    "Discuss and assess Round 1 bids",
    "Select which buyers to invite to Round 2",
  ],
  [TransactionPeriodName.SECOND_ROUND_DUE_DILIGENCE]: [
    "Facilitate buyer diligence including information requests and GP call(s)",
    "Compile updated due diligence information",
    "Collect Round 2 bids",
  ],
  [TransactionPeriodName.SECOND_ROUND_BID_EVALUATION]: [
    "Discuss and assess Round 2 bids",
    "Select winning buyer(s) and grant exclusivity",
  ],
  [TransactionPeriodName.DOCUMENTATION_AND_TRANSACTION_CLOSING]: [
    "GP communication",
    "Pre-emption processes",
    "Compile updated sale portfolio information",
    "PSA(s) negotiation",
    "Transfer agreement negotiation",
    "Funds Flow & Closing",
  ],
  [TransactionPeriodName.TARGET_CLOSE]: [],
};
