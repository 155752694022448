import { Either, left, right } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { PageSpecs } from "../../../../common/@types/models/PageSpecs";
import { IImpersonateUser } from "../models/ImpersonateUser";
import { ImpersonationUsersFilterSpec } from "../models/ImpersonationUsersFilterSpec";
import { ImpersonationUsersPage } from "../models/ImpersonationUsersPage";

interface IResp {
  items: IImpersonateUser[];
  total: number;
  perPage: number;
  pageIndex: number;
  pageCount: number;
}

export class GetImpersonateUsers
  implements IAsyncUseCase<IParams, ImpersonationUsersPage>
{
  call: (params: IParams) => Promise<Either<Failure, ImpersonationUsersPage>> =
    async (params) => {
      return await new TapClient("/impersonate/list/", params)
        .post()
        .then((resp: IResp) => {
          return right({
            users: resp.items,
            total: resp.total,
            pageIndex: resp.pageIndex,
            pageCount: resp.pageCount,
            perPage: resp.perPage,
          });
        })
        .catch((resp) => left(new Failure(resp.response)));
    };
}

interface IParams {
  paginationSpecs: PageSpecs;
  filters: ImpersonationUsersFilterSpec;
}
