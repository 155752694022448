import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AddressBook,
  Bank,
  ChartLine,
  ChatCenteredText,
  ClipboardText,
  Gear,
  Handshake,
  House,
  SignOut,
} from "@phosphor-icons/react";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { GP_ROUTES, UNIVERSAL_ROUTES } from "common/constants/routes";
import { useKeyboardShortcuts } from "../../KeyboardShortcuts";
import { ClickOutside } from "common/components/ClickOutside";

import { GPNavLinks } from "./GPNavLinks";
import { NavLinks } from "../NavLinks";

import {
  CurrentExperience,
  StyledDropdown,
  StyledMenu,
  StyledMenuGroup,
  NavLinkWrapper,
  StyledUser,
  UserPicture,
  TapIconLink,
  TapSupportLink,
} from "../styles";
import { CurrentExperienceIcon } from "../CurrentExperience";

const Dropdown = () => {
  return (
    <StyledDropdown>
      <Link to={UNIVERSAL_ROUTES.AuthLogout}>
        Logout <SignOut />
      </Link>
    </StyledDropdown>
  );
};

const User = () => {
  const { user } = useAuthContext();
  const [showDropdown, setShowDropdown] = useState(false);

  if (!user) return null;

  const initials = user.firstName[0] + user.lastName[0];

  return (
    <StyledUser onClick={() => setShowDropdown(!showDropdown)}>
      <ClickOutside
        handleOutsideClick={() => setShowDropdown(false)}
        role="menu"
      >
        {user.firstName}
        <UserPicture initials={initials} />

        {showDropdown && <Dropdown />}
      </ClickOutside>
    </StyledUser>
  );
};

export const GPAppBar = () => {
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);

  // for some reason, navlink's active prop is not working so I'm doing it manually

  // we are gonna remove these for now
  const dashboardActive = window.location.pathname.startsWith(
    GP_ROUTES.Dashboard,
  );
  const transactionsActive = window.location.pathname.startsWith(
    GP_ROUTES.TransactionDetail.replace(":transactionId", ""),
  );

  const transactionListActive = window.location.pathname.includes(
    GP_ROUTES.TransactionList,
  );
  const approvedBuyersActive = window.location.pathname.includes(
    GP_ROUTES.ApprovedBuyers,
  );
  const transactionSettingsActive = window.location.pathname.includes(
    GP_ROUTES.TransactionSettings,
  );
  const complianceActive = window.location.pathname.includes(
    GP_ROUTES.Compliance,
  );
  const marketMonitorActive = window.location.pathname.includes(
    GP_ROUTES.MarketMonitor,
  );
  const firmActive = window.location.pathname.includes(GP_ROUTES.Firm);
  const limitedPartnersActive = window.location.pathname.includes(
    GP_ROUTES.LimitedPartners,
  );

  const menuButtons = [
    {
      to: GP_ROUTES.Dashboard,
      label: "Dashboard",
      icon: House,
    },
    {
      to: GP_ROUTES.ApprovedBuyers,
      label: "Approved Buyers",
      icon: AddressBook,
    },
    {
      to: GP_ROUTES.TransactionSettings,
      label: "Transaction Settings",
      icon: ClipboardText,
    },
    {
      to: GP_ROUTES.Compliance,
      label: "Compliance",
      icon: Bank,
    },
    {
      to: GP_ROUTES.TransactionList,
      label: "Transactions",
      icon: Handshake,
    },
    {
      to: GP_ROUTES.LimitedPartners,
      label: "Limited Partners",
      icon: ChatCenteredText,
    },
  ];

  const bottomMenuButtons = [
    {
      to: GP_ROUTES.Firm,
      label: "Firm",
      icon: Gear,
    },
  ];

  const goToCurrentExperience = () => {
    if (dashboardActive) {
      navigate(GP_ROUTES.Dashboard);
      return;
    } else if (transactionListActive) {
      navigate(GP_ROUTES.TransactionList);
      return;
    } else if (transactionsActive) {
      navigate(GP_ROUTES.ApprovedBuyers);
      return;
    } else if (approvedBuyersActive) {
      navigate(GP_ROUTES.ApprovedBuyersApproved);
      return;
    } else if (transactionSettingsActive) {
      navigate(GP_ROUTES.TransactionSettings);
      return;
    } else if (complianceActive) {
      navigate(GP_ROUTES.Compliance);
      return;
    } else if (marketMonitorActive) {
      navigate(GP_ROUTES.MarketMonitor);
      return;
    } else if (firmActive) {
      navigate(GP_ROUTES.Firm);
      return;
    } else if (limitedPartnersActive) {
      navigate(GP_ROUTES.LimitedPartners);
      return;
    }
  };

  const handleSearchClick = () => {
    setSearchOpen(true);
  };

  useKeyboardShortcuts({
    openGlobalSearch: handleSearchClick,
  });

  return (
    <>
      <StyledMenu>
        <StyledMenuGroup>
          <TapIconLink to={GP_ROUTES.Root} />
          <NavLinks buttons={menuButtons} />
        </StyledMenuGroup>
        <StyledMenuGroup>
          <NavLinks buttons={bottomMenuButtons} />
          <TapSupportLink />
        </StyledMenuGroup>
      </StyledMenu>

      <NavLinkWrapper>
        <CurrentExperience onClick={goToCurrentExperience}>
          {dashboardActive && (
            <CurrentExperienceIcon icon={House} label="Dashboard" />
          )}
          {transactionsActive && (
            <CurrentExperienceIcon icon={Handshake} label="Dashboard" />
          )}
          {transactionListActive && (
            <CurrentExperienceIcon icon={Handshake} label="Transactions" />
          )}
          {firmActive && <CurrentExperienceIcon icon={Gear} label="Firm" />}
          {approvedBuyersActive && (
            <CurrentExperienceIcon icon={AddressBook} label="Approved Buyers" />
          )}
          {transactionSettingsActive && (
            <CurrentExperienceIcon
              icon={ClipboardText}
              label="Transaction Settings"
            />
          )}
          {complianceActive && (
            <CurrentExperienceIcon icon={Bank} label="Compliance" />
          )}
          {marketMonitorActive && (
            <CurrentExperienceIcon icon={ChartLine} label="Market Monitor" />
          )}
          {limitedPartnersActive && (
            <CurrentExperienceIcon
              icon={ChatCenteredText}
              label="Limited Partners"
            />
          )}
        </CurrentExperience>
        <GPNavLinks />
        <User />
      </NavLinkWrapper>
    </>
  );
};
