import { useMemo, useRef, useState } from "react";
import { CellChange, RangeType } from "handsontable/common";
import { CellCoords } from "handsontable";
import { useNavigate } from "react-router";

import { registerCellType, DropdownCellType } from "handsontable/cellTypes";
import { registerAllModules } from "handsontable/registry";

registerAllModules();
registerCellType(DropdownCellType);

import "handsontable/dist/handsontable.full.min.css";

import { useIoiBulkUploadSubmitManager } from "experiences/indications-of-interest/presentation/components/manager-bulk-upload/useIoiBulkUploadSubmitManager";
import { getDropdownOptions } from "./getDropdownOptions";
import { LP_ROUTES } from "common/constants/routes";
import {
  afterOnCellMouseOut,
  afterOnCellMouseOver,
  formatCurrencyColumns,
  getNamesForColumn,
  getRawTableData,
  handleAddMoreRowsClick,
  validateAndSubmit,
} from "experiences/indications-of-interest/presentation/components/handsOnTableUtils";
import { useStateAndRef } from "common/utils/useStateAndRef";
import { IManager } from "common/@types/models/Manager";
import { useGetManagerNamesFromApi } from "./useGetManagerNames";
import { MatchStatus } from "experiences/funds/domain/usecases/MatchNames";
import { SUPPORT_EMAIL } from "common/constants/assets";

const TABLE_MAX_ROWS = 100;
export const DEFAULT_ROW_COUNT = 20;
export const ADD_MORE_ROWS_COUNT = 20;

enum COLUMNS {
  MANAGER_NAME = "name",
  MIN_AMOUNT = "min_amount",
  MAX_AMOUNT = "max_amount",
}

export const TABLE_COLUMN_TITLES = {
  [COLUMNS.MANAGER_NAME]: `Manager Name*`,
  [COLUMNS.MIN_AMOUNT]: "Min Amount*",
  [COLUMNS.MAX_AMOUNT]: "Max Amount",
};

const ioiBulkUploadTableColumns = [
  {
    id: COLUMNS.MANAGER_NAME,
    title: TABLE_COLUMN_TITLES[COLUMNS.MANAGER_NAME],
    type: "autocomplete",
    width: 340,
    allowEmpty: false,
    tooltip: (
      <>
        Required. Use full names. The "Autocorrect Names" button will attempt to
        correct individual names. If a manager does not exist in the Tap
        database, notify our team at {SUPPORT_EMAIL}.
      </>
    ),
  },
  {
    id: COLUMNS.MIN_AMOUNT,
    title: TABLE_COLUMN_TITLES[COLUMNS.MIN_AMOUNT],
    type: "numeric",
    validator: "numeric",
    allowEmpty: false,
    width: 200,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
    tooltip: <>Required. Your minimum transaction size in terms of NAV.</>,
  },
  {
    id: COLUMNS.MAX_AMOUNT,
    title: TABLE_COLUMN_TITLES[COLUMNS.MAX_AMOUNT],
    type: "numeric",
    validator: "numeric",
    width: 200,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
    tooltip: <>Optional. Your maximum transaction size in terms of NAV.</>,
  },
];

export const MANAGER_NAME_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MANAGER_NAME,
);
const MANAGER_MIN_AMOUNT_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MIN_AMOUNT,
);
const MANAGER_MAX_AMOUNT_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.MAX_AMOUNT,
);

const dataSchema = {
  managerName: "",
  minAmount: "",
  maxAmount: "",
};

type TableRow = [
  string | null, // COLUMNS.MANAGER_NAME
  number | null, // COLUMNS.MIN_AMOUNT
  number | null, // COLUMNS.MAX_AMOUNT
];

export const useBulkUpload = () => {
  const hotRef = useRef(null);
  const [, , managerCacheRef] = useStateAndRef(new Set<IManager>());
  const { handleCallMatchNames, isLoading: validateNamesLoading } =
    useGetManagerNamesFromApi();
  const { submitBids, isLoading: submitLoading } =
    useIoiBulkUploadSubmitManager();
  const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);
  const [tableValid, _, tableValidRef] = useStateAndRef(true);
  const navigate = useNavigate();

  const getTableRef = () => hotRef?.current?.hotInstance;

  const disableAddMoreRows = rowCount >= TABLE_MAX_ROWS;

  // This is pretty much the same list of columns but has a few considerations:
  // - useMemo so that the table is not re-rendering all of the time, this prevents a few bugs I (@jmz7v) observed
  // - this starts from `ioiBulkUploadTableColumns` but we add a few extra fields to the manager name column
  //   - source: this is the function that will be called when the user types in the manager name column, this is where we do the search
  //     and will be calling the api that gets the manager names, in an identical way to how the search manager field works
  //   - validator: handsontable validation works with a function and a callback, but because we're pulling data from the api
  //     we cannot just "validate" against a list of managers (options), so every manager that is returned from the api is saved
  //     at the `issuerCache` state, and then we validate against that list, if the string is not in the list, then it's not valid
  //     (handsontable only works with an array of strings, not objects, so we need to do this by querying directly for the name)
  //     if the string is in the list, then it's valid, we return that to the callback, and handsontable will handle the rest
  const FULL_COLUMN_LIST = {
    ...ioiBulkUploadTableColumns[MANAGER_NAME_COL_INDEX],
    source: (query: string, process: (string: any[]) => void) =>
      getDropdownOptions(query, process, managerCacheRef),
    validator: async (
      managerName: string,
      callback: (valid: boolean) => void,
    ) => {
      const table = getTableRef();
      const { rowsWithSomeData, data } = getRawTableData<TableRow>(table);

      const isValid = Array.from(managerCacheRef.current).some((manager) => {
        return manager.name === managerName;
      });

      const managerNames = rowsWithSomeData.map((rowIndex) => {
        return data[rowIndex][MANAGER_NAME_COL_INDEX] || "";
      });

      // manager name should be unique, check if there's 2 or more of the same manager name
      const alreadyExists =
        managerNames.filter((name) => name === managerName).length > 1;

      callback(isValid && !alreadyExists);
    },
  };

  // Plug the manager name with the new source and validator function into the columns list
  // Note: Looks like handsontable provides a way for you to update its "settings", but I didn't explore that option
  // and decided to pass the new list of columns directly to the table instead
  const TABLE_COLUMNS_WITH_HIJACKED_LIST = useMemo(() => {
    return [
      ...ioiBulkUploadTableColumns.splice(
        0,
        MANAGER_MIN_AMOUNT_COL_INDEX,
        FULL_COLUMN_LIST,
      ),
      ...ioiBulkUploadTableColumns.slice(MANAGER_MIN_AMOUNT_COL_INDEX),
    ];
  }, [FULL_COLUMN_LIST, managerCacheRef.current.size]);

  // array of strings that contains all the column titles
  const tableColumnTitles = TABLE_COLUMNS_WITH_HIJACKED_LIST.map(
    (column) => column.title,
  );

  // array of numbers that contains all the column widths
  const tableColumnWidths = TABLE_COLUMNS_WITH_HIJACKED_LIST.map(
    (column) => column.width,
  );

  const submitApiCall = async (valid: boolean) => {
    tableValidRef.current = valid;

    if (!valid) {
      return undefined;
    }

    const table = getTableRef();
    const { rowsWithSomeData } = getRawTableData<TableRow>(table);

    const bids = table
      .getSourceData()
      .filter((_: any, index: number) => rowsWithSomeData.includes(index))
      .map((row: any) => {
        const manager = Array.from(managerCacheRef.current).find((manager) => {
          if (typeof manager.name === "undefined") {
            return false;
          }
          return manager.name === row[MANAGER_NAME_COL_INDEX];
        });

        const bid = {
          managerId: manager?.id,
          minAmount: row[MANAGER_MIN_AMOUNT_COL_INDEX],
          maxAmount: row[MANAGER_MAX_AMOUNT_COL_INDEX],
        };

        return bid;
      });

    submitBids({ bids }).then((r) => {
      if (r.success) {
        navigate(LP_ROUTES.IndicationsOfInterestBids);
      }
      // TODO: Hook up a toast or call to sentry
    });
  };

  // This function will:
  // 1. Get all manager names from the table
  // 2. Send them to the api, will receive a "match result" for each manager name
  // 3. For each result, we'll replace the manager name in the table with the matched manager name from the api`
  const autoCorrectManagerNames = async () => {
    const table = getTableRef();
    const {
      names: managerNames,
      rowsWithSomeData,
      data,
    } = getNamesForColumn(table, MANAGER_NAME_COL_INDEX);

    // Get matching manager names from the api
    const nameCorrections = await handleCallMatchNames({
      searchTerms: managerNames,
    });

    // DO NOT DELETE THIS 👇👇👇
    if (!nameCorrections) return null;

    // Update the issuer cache with the results from the matches
    // so that they are considered valid options and not rejected by the validator
    // this is a side effect, if we extract this, be a bit careful
    nameCorrections.forEach((match) => {
      const candidate = match.matchedCandidates[0];
      if (Boolean(candidate)) {
        managerCacheRef.current.add(candidate);
      }
    });

    // For each row that has some data, get the manager name and replace it with the matched manager name from the api
    // but only do that if it was a perfect or partial match, do not strictly rely on the correction.matchedCandidates[0] value
    // as it might not exist (will be an empty array) if it was not a perfect or partial match
    // Reminder: this function will work with both perfect and partial matches, but the handlePaste function
    // will work exclusively with perfect matches
    const newManagerNameCellValues: [
      rowIndex: number,
      colIndex: number,
      newValue?: string,
    ][] = rowsWithSomeData.map((rowIndex) => {
      const managerName = String(data[rowIndex][MANAGER_NAME_COL_INDEX]);
      const correction = nameCorrections.find(
        (c) => c.searchTerm.toLowerCase() === managerName.toLowerCase(),
      );
      const correctionCandidate =
        (correction?.matchedCandidates?.length &&
          correction?.matchedCandidates[0]) ||
        undefined;

      const correctionValue = correctionCandidate?.name || "";

      return [rowIndex, MANAGER_NAME_COL_INDEX, correctionValue];
    });

    // Filter out rows that don't have a new manager name
    const cleanNewManagerNameCellValues = newManagerNameCellValues.filter(
      (row) => Boolean(row[2]),
    );

    // setDataAtCell requires data in the format:
    // [[rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue]]
    table.setDataAtCell(cleanNewManagerNameCellValues);
  };

  const handlePaste = async (
    pastedData: any[][],
    pastePosition: RangeType[],
  ) => {
    const table = getTableRef();

    // step 1. detect if what was pasted was on the manager name column
    if (pastePosition[0].startCol === MANAGER_NAME_COL_INDEX) {
      // do the actual manager name validation

      const managerNames = pastedData.map((row) => row[0]); // grab the 1st row from the pasted events, from the 1st section of the pasted events

      // step 2. grab all rows that were pasted
      // Get matching manager names from the api
      const managerCorrections = await handleCallMatchNames({
        searchTerms: managerNames,
      });

      // DO NOT DELETE THIS 👇👇👇 (prevents from crashing if there are not suggested matches)
      if (!managerCorrections || managerCorrections.length === 0) return null;

      // Update the issuer cache with the results from the matches
      // so that they are considered valid options and not rejected by the validator
      // this is a side effect, if we extract this, be a bit careful
      managerCorrections.forEach((match) => {
        const candidate = match.matchedCandidates[0];
        if (Boolean(candidate)) {
          managerCacheRef.current.add(candidate);
        }
      });

      const range = Array.from(
        Array(pastePosition[0].endRow - pastePosition[0].startRow).keys(),
        (i) => i + pastePosition[0].startRow,
      );

      const newManagerNameCellValues: [
        rowIndex: number,
        colIndex: number,
        newValue?: string,
      ][] = range.map((rowIndex) => {
        const adjustedRow = rowIndex - pastePosition[0].startRow;
        const managerName = String(
          pastedData[adjustedRow][MANAGER_NAME_COL_INDEX],
        );

        const correction = managerCorrections.find(
          (c) => c.searchTerm.toLowerCase() === managerName.toLowerCase(),
        );

        // do the corrections but ONLY if it was a perfect match
        const correctionCandidate =
          (correction?.matchedCandidates?.length &&
            correction.status === MatchStatus.PerfectMatch &&
            correction?.matchedCandidates[0]) ||
          undefined;

        const correctionValue = correctionCandidate?.name || "";

        return [rowIndex, MANAGER_NAME_COL_INDEX, correctionValue];
      });

      // Filter out rows that don't have a new manager name
      const cleanNewManagerNameCellValues = newManagerNameCellValues.filter(
        (row) => Boolean(row[2]),
      );

      // setDataAtCell requires data in the format:
      // [[rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue]]
      table.setDataAtCell(cleanNewManagerNameCellValues);
    }
  };

  const afterChange = (changes: CellChange[] | null) => {
    const table = getTableRef();

    formatCurrencyColumns({
      table,
      changes,
      columnsToFormat: [
        MANAGER_MIN_AMOUNT_COL_INDEX,
        MANAGER_MAX_AMOUNT_COL_INDEX,
      ],
    });
  };

  return {
    hotRef,
    getTableRef,
    handleAddMoreRowsClick: () =>
      handleAddMoreRowsClick(getTableRef(), setRowCount, ADD_MORE_ROWS_COUNT),
    disableAddMoreRows,
    handleSubmitClick: () =>
      validateAndSubmit({
        table: getTableRef(),
        columnsToValidate: [
          MANAGER_NAME_COL_INDEX,
          MANAGER_MIN_AMOUNT_COL_INDEX,
        ],
        tableValidRef: tableValidRef,
        submitApiCall: submitApiCall,
      }),
    submitLoading,
    autoCorrectManagerNames,
    validateNamesLoading,
    tableColumnTitles,
    tableColumns: TABLE_COLUMNS_WITH_HIJACKED_LIST,
    tableColumnWidths,
    dataSchema,
    tableValid: tableValid,
    handlePaste,
    afterOnCellMouseOver: (
      _: MouseEvent,
      coords: CellCoords,
      el: HTMLElement,
    ) => afterOnCellMouseOver(coords, el, TABLE_COLUMNS_WITH_HIJACKED_LIST),
    afterOnCellMouseOut: afterOnCellMouseOut,
    afterChange,
  };
};
