import { gql } from "@apollo/client";

export const GetPortfolioStatusQuery = gql`
  query Query($entitiesIds: [String!]!) {
    dataSectionsStatus(entitiesIds: $entitiesIds) {
      cas
      fundExposureSummary
      soiLookThrough
    }
  }
`;
