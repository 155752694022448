export enum FundGeography {
  NorthAmerica = 1,
  Europe = 2,
  Asia = 3,
  LatinAmerica = 4,
  Australia = 5,
  MiddleEast = 6,
  Africa = 7,
}

export const FundGeographyNameMap = new Map<FundGeography, String>([
  [FundGeography.NorthAmerica, "North America"],
  [FundGeography.Europe, "Europe"],
  [FundGeography.Asia, "Asia"],
  [FundGeography.LatinAmerica, "Latin America"],
  [FundGeography.Australia, "Australia"],
  [FundGeography.MiddleEast, "Middle East"],
  [FundGeography.Africa, "Africa"],
]);
