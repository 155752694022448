import React, { cloneElement, isValidElement } from "react";
import styled from "styled-components";
import {
  CaretRight,
  Crosshair,
  CrosshairSimple,
  GlobeSimple,
  Lock,
  Plus,
} from "@phosphor-icons/react";

import TextChip from "experiences/common/TextChip";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import { useNavigate } from "react-router";
import { LP_ROUTES } from "common/constants/routes";
import { cn } from "common/utils";

const StyledActicityEntry = styled.div<{
  locked?: boolean;
}>`
  display: flex;
  align-items: center;
  min-height: 96px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: ${({ locked }) => (locked ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ locked }) =>
      locked ? "transparent" : "rgb(250, 250, 248)"};
  }

  .transaction_left {
    .transaction_logo_text {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 10px;
      overflow: hidden;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fafafa;
      background-color: #171717;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      border: 1px solid #a3a3a3;
    }

    .transaction_logo {
      position: relative;
      width: 36px;
      height: 36px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid var(--branding-keyline-default, #dfdfd9);
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #bcb3a5;
      background-color: rgba(188, 179, 165, 0.02);
      padding: 4px;
      box-sizing: border-box;
    }
  }
  .transaction_body {
    flex: 1;
    margin-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-sizing: border-box;
  }

  .transaction_tokens {
    display: flex;
    min-height: 16px;
    align-items: center;

    .transaction_token {
      display: flex;
      user-select: none;
      align-items: center;
      color: #737476;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.12px;
      white-space: nowrap;

      .transaction_token_icon,
      svg.transaction_token_icon {
        display: inline-block;
        margin-right: 4px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid var(--branding-keyline-default, #dfdfd9);
        user-select: none;
        padding: 2px;
      }

      &.is_bid,
      &.is_ask {
        font-weight: 600;
      }

      // slightly larger padding for bid/ask icons so that icon is smaller
      &.is_bid .transaction_token_icon,
      &.is_ask .transaction_token_icon {
        padding: 3px;
      }

      &.is_bid {
        color: #425e3d;
      }

      &.is_ask {
        color: #983a43;
      }

      &.is_ask .transaction_token_icon {
        color: #983a43;
        transform: rotate(180deg);
      }

      .transaction_token_value {
        font-weight: 600;
        margin-right: 4px;
        white-space: nowrap;
      }
      .transaction_token_value-locked {
        filter: blur(4px);
        margin-right: 4px;
        user-select: none;
      }
      .transaction_token_label {
        white-space: nowrap;
      }
    }
    .transaction_token + .transaction_token {
      margin-left: 16px;
    }
  }

  & + & {
    border-top: 1px solid var(--branding-keyline-default, #dfdfd9);
  }
`;

const StyledNotificationDot = styled.div`
  display: block;
  width: 9px;
  height: 9px;
  content: "";
  font-size: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fa963a;
  border: 1px solid #ffffff;
  border-radius: 50%;
  z-index: 1;
`;

const TransactionToken = ({
  isBid,
  isAsk,
  isLocked,
  icon,
  label,
  value,
}: {
  icon?: React.ReactNode;
  isBid?: boolean;
  isAsk?: boolean;
  isLocked?: boolean;
  label?: string;
  value?: string | number;
}) => {
  const hasIcon = !!icon;
  const isReactIcon = isValidElement(icon);

  if (!Boolean(label)) {
    return null;
  }

  return (
    <div
      className={`transaction_token ${isBid ? "is_bid" : ""} ${
        isAsk ? "is_ask" : ""
      }`}
    >
      {hasIcon && !isReactIcon && (
        <img src={icon as string} alt="" className="transaction_token_icon" />
      )}
      {isReactIcon &&
        cloneElement(icon as any, {
          className: "transaction_token_icon",
        })}
      <span
        className={`transaction_token_value ${
          isLocked && !(isBid || isAsk) ? "transaction_token_value-locked" : ""
        }`}
      >
        {value}
      </span>
      <span className="transaction_token_label">{label}</span>
    </div>
  );
};

const TransactionTokens = ({
  tokens,
}: {
  tokens?: {
    icon?: string | React.ReactNode;
    value?: string | number;
    label?: string;
    locked?: boolean;
  }[];
}) => {
  if (!tokens || tokens.length === 0) {
    return null;
  }

  return (
    <div className="transaction_tokens">
      {tokens.map((token, i) => {
        const isBid = token.label?.toLowerCase().includes("bid");
        const isAsk = token.label?.toLowerCase().includes("ask");

        return (
          <TransactionToken
            key={`token-${i}`}
            isBid={isBid}
            isAsk={isAsk}
            isLocked={token.locked}
            icon={token.icon}
            label={token.label}
            value={token.value}
          />
        );
      })}
    </div>
  );
};

const TransactionTags = ({ tags }: { tags?: string[] }) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <span className="flex gap-2 mt-2 mb-2 flex-wrap">
      {tags.map((tag, i) => {
        const isStrategy = Array.from(FundStrategyNameMap.values()).includes(
          tag,
        );

        const isGeography = Array.from(FundGeographyNameMap.values()).includes(
          tag,
        );

        const isClosed = tag.toLowerCase().includes("closed");

        const isNumber = !isNaN(Number(tag));

        return (
          <TextChip
            key={`tag-${i}`}
            text={tag}
            {...(isStrategy && { icon: <CrosshairSimple /> })}
            {...(isGeography && { icon: <GlobeSimple /> })}
            success={isClosed}
            pending={isNumber}
            className="whitespace-nowrap"
          />
        );
      })}
    </span>
  );
};

export const ActivityEntry = ({
  transactionLogo = <Crosshair />,
  transactionLogoText,
  title,
  description,
  isLocked,
  onDetailClick,
  tokens,
  tags,
  hasNotifications,
  className,
  titleClassName,
  descriptionClassName,
}: {
  transactionLogo?: React.ReactNode;
  transactionLogoText?: string;
  title: string;
  description?: string;
  isLocked?: boolean;
  onDetailClick?: () => void;
  tokens?: {
    icon?: string | React.ReactNode;
    value?: string | number;
    label?: string;
    locked?: boolean;
  }[];
  tags?: string[];
  hasNotifications?: boolean;
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
}) => {
  return (
    <StyledActicityEntry
      onClick={onDetailClick}
      locked={isLocked}
      className={cn("select-none", {
        "cursor-not-allowed": isLocked,
        className,
      })}
    >
      <div className="transaction_left" style={{ position: "relative" }}>
        {transactionLogo && !transactionLogoText && (
          <div className="transaction_logo">{transactionLogo}</div>
        )}
        {transactionLogoText && (
          <div className="transaction_logo_text">{transactionLogoText}</div>
        )}
        {hasNotifications && <StyledNotificationDot />}
      </div>
      <div className="transaction_body">
        <h2
          className={cn("font-medium tracking-tight text-sm", titleClassName)}
        >
          {title}
        </h2>
        {description && (
          <p
            className={cn(
              "text-xs text-zinc-600 mt-1 select-none cursor-default",
              descriptionClassName,
            )}
          >
            {description}
          </p>
        )}

        <TransactionTags tags={tags} />
        <TransactionTokens tokens={tokens} />
      </div>
      <div className="transaction_tokens">
        {isLocked && <Lock width={16} height={16} />}
        {onDetailClick && !isLocked && <CaretRight />}
      </div>
    </StyledActicityEntry>
  );
};

export const CreateTransactionActivityEntry = () => {
  const navigate = useNavigate();

  const handleDetailClick = () => {
    navigate(LP_ROUTES.TransactionsCreate);
  };

  return (
    <ActivityEntry
      title="Create Transaction"
      description="Start a buy or sell-side transaction with automatic data extraction."
      transactionLogo={<Plus weight="bold" />}
      onDetailClick={handleDetailClick}
      className="cursor-pointer"
      titleClassName="text-stone-600"
      descriptionClassName="text-neutral-500"
    />
  );
};
