import { CaretDown, CaretUp, CheckCircle, Circle } from "@phosphor-icons/react";
import { classNames } from "primereact/utils";
import { useState } from "react";
import styled from "styled-components";

const distanceBetweenBulletAndLeft = 24;
const distanceToContent = 72;
const bulletsPaddingLeft = 16;
const completedBulletSize = 24;
const pendingBulletSize = 24;
const completedColor = "#BCB3A5";
const pendingColor = "#DFDFD9";
const openTimelineDetailDistanceToTopPx = 32;
const openTimelineDetailDistanceToTopNoSubtitlePx = 24;
const timelineBulletDistanceToTopPx = 36;
const timelineBulletDistanceToTopNoSubtitlePx = 24;

const StyledStep = styled.div<{
  completed?: boolean;
  current?: boolean;
  last?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
  color: #000;
  text-align: left;

  .step-details {
    flex: 1;
    display: block;
    margin-left: ${distanceToContent}px;
    width: calc(100% - 48px);
    padding-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid var(--branding-keyline-default, #dfdfd9);
    ${(props) => (props.last ? "border-bottom: none;" : "")}
    box-sizing: border-box;
  }

  .step-title {
    color: var(--branding-dark, #21272d);
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */

    &--completed {
      color: #737476;
      font-weight: 500;
    }

    &--current {
      // color: #bcb3a5;
      font-weight: 600;
    }
  }

  .step-subtitle {
    margin-top: 4px;
  }

  .step-description {
    color: var(--branding-light, #737476);

    font-size: 14px;
    font-weight: 400;
    line-height: 130%;

    cursor: default;
    user-select: none;
  }

  .step-content {
  }

  .step-action {
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: ${openTimelineDetailDistanceToTopPx}px;
    margin-right: 16px;

    svg {
      border-radius: 50%;
      padding: 6px;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: var(--branding-keyline-default, #dfdfd9);
      }
    }

    &--disabled:hover {
      cursor: not-allowed;
      background-color: transparent;
    }

    &--no-subtitle {
      margin-top: ${openTimelineDetailDistanceToTopNoSubtitlePx}px;
    }
  }

  .step-content-bullets {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    width: calc(100% - ${distanceBetweenBulletAndLeft}px);
    max-width: calc(100% - ${distanceBetweenBulletAndLeft}px);
    text-align: left;
    align-items: flex-start;
    color: var(--branding-light, #737476);

    li {
      display: list-item;
      width: 100%;
      max-width: 100%;
      margin-left: ${bulletsPaddingLeft}px;
      cursor: default;
    }
    li + li {
      margin-top: 6px;
    }
  }

  --timeline-bullet-distance-to-top: ${timelineBulletDistanceToTopPx}px;

  // if the step has no subtitle but the next one does, slightly increase the distance to top so that the
  // actual line goes directly in contact with the bullet of the next step
  :has(.step-timeline--no-subtitle) + :has(.step-timeline--subtitle) {
    .step-timeline {
      --timeline-bullet-distance-to-top: ${timelineBulletDistanceToTopNoSubtitlePx}px;
    }
  }

  .step-timeline {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;

    &--no-subtitle {
      --timeline-bullet-distance-to-top: ${timelineBulletDistanceToTopNoSubtitlePx}px;
    }

    top: var(--timeline-bullet-distance-to-top);
    left: ${distanceBetweenBulletAndLeft}px;

    svg {
      background-color: white;
      z-index: 1;
    }

    &::before {
      content: "";
      width: 1px;
      position: absolute;
      left: 50%;
      height: 100%;
      top: 0;
      transform: translateX(-50%);
      background: linear-gradient(
        to bottom,
        ${pendingColor},
        ${pendingColor} var(--timeline-bullet-distance-to-top),
        ${pendingColor} var(--timeline-bullet-distance-to-top),
        ${pendingColor} 100%
      );
      ${(props) =>
        props.completed
          ? `
        background: linear-gradient(
          to bottom,
          ${completedColor},
          ${completedColor} var(--timeline-bullet-distance-to-top),
          ${completedColor} var(--timeline-bullet-distance-to-top),
          ${completedColor} 100%
        );
        `
          : props.current
            ? `
            background: linear-gradient(
              to bottom,
              ${completedColor},
              ${completedColor} calc(var(--timeline-bullet-distance-to-top) - ${
                completedBulletSize / 2
              }px),
              ${pendingColor} calc(var(--timeline-bullet-distance-to-top) - ${
                completedBulletSize / 2
              }px),
              ${pendingColor} 100%
            );
            `
            : ""}
    }
  }

  &:first-child .step-timeline::before {
    background: linear-gradient(
      to bottom,
      transparent,
      transparent --var(--timeline-bullet-distance-to-top),
      ${(props) => (props.completed ? completedColor : pendingColor)}
        --var(--timeline-bullet-distance-to-top),
      ${(props) => (props.completed ? completedColor : pendingColor)} 100%
    );
  }

  &:last-child .step-timeline::before {
    display: none;
  }
`;

export const TimelineStep = ({
  title,
  subtitle,
  secondaryTitle,
  secondarySubtitle,
  content,
  completed,
  current,
  bullets,
  disableShowMore,
  hideShowMore,
  openByDefault,
  isLastStep,
  children,
  titleTokens,
}: {
  title: string;
  subtitle: string | React.ReactNode;
  secondaryTitle?: string;
  secondarySubtitle?: string;
  content?: React.ReactNode;
  completed?: boolean;
  current?: boolean;
  bullets?: string[];
  disableShowMore?: boolean;
  hideShowMore?: boolean;
  openByDefault?: boolean;
  isLastStep?: boolean;
  children?: React.ReactNode;
  titleTokens?: React.ReactNode; // TODO: use token params instead
}) => {
  const [open, setOpen] = useState(
    disableShowMore ? false : openByDefault || false,
  );

  const ShowMoreButton = open ? CaretDown : CaretUp;

  const handleShowMore = () => {
    if (disableShowMore) {
      return;
    }
    setOpen(!open);
  };

  let Bullet = Circle;

  if (completed) {
    Bullet = CheckCircle;
  }

  const hasSubtitle = subtitle && subtitle.length > 0;

  return (
    <StyledStep completed={completed} current={current} last={isLastStep}>
      <div
        className={classNames("step-timeline", {
          "step-timeline--subtitle": hasSubtitle,
          "step-timeline--no-subtitle": !hasSubtitle,
        })}
      >
        <Bullet
          color={completed ? completedColor : pendingColor}
          weight="regular"
          size={completed ? completedBulletSize : pendingBulletSize}
          enableBackground={1}
          style={{
            ...(completed && {
              boxShadow:
                "0 0 0 3px var(--branding-texture-2, rgba(188, 179, 165, 0.25))",
              borderRadius: "50%",
              outline: "none",
              backgroundColor: "white",
              border: "none",
            }),
            ...(current && {
              boxShadow:
                "0 0 0 3px var(--branding-texture-2, rgba(188, 179, 165, 0.25))",
              borderRadius: "50%",
              outline: "#bcb3a5",
              color: "#bcb3a5",
              backgroundColor: "#bcb3a5",
              border: "3px solid white",
            }),
          }}
        />
      </div>
      <div className="step-details">
        <div className="step-description flex">
          <div>
            <div
              className={classNames("step-title", {
                "step-title--completed": completed,
                "step-title--current": current,
              })}
            >
              {title}
            </div>
            <div className="step-subtitle">{subtitle}</div>
          </div>
          {titleTokens && <div className="ml-4">{titleTokens}</div>}
          {secondaryTitle || secondarySubtitle ? (
            <div
              className={classNames("flex-1 block", {
                "pr-12": isLastStep,
                "pr-4": !isLastStep,
              })}
            >
              <div
                className={classNames("step-title self-end text-right", {
                  "step-title--completed": completed,
                  "step-title--current": current,
                })}
              >
                {secondaryTitle}
              </div>
              <div className="step-subtitle text-right">
                {secondarySubtitle}
              </div>
            </div>
          ) : null}
        </div>
        {(content || bullets || children) && open && (
          <div className="step-content">
            {content}
            <ul className="step-content-bullets">
              {bullets &&
                bullets.map((bullet) => <li key={bullet}>{bullet}</li>)}
            </ul>
            {children}
          </div>
        )}
      </div>
      {!isLastStep && !hideShowMore && (
        <div
          className={classNames("step-action", {
            "step-action--no-subtitle": !hasSubtitle,
          })}
        >
          <ShowMoreButton
            size={30}
            onClick={handleShowMore}
            className={classNames({
              "step-action--disabled": disableShowMore,
            })}
          />
        </div>
      )}
    </StyledStep>
  );
};
