import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";

import { RequestedBuyer } from "../models/RequestedBuyer";
import { requestedBuyerMock } from "./requestedBuyersMock";

interface IParams {}

export class GetRequestedBuyers
  implements IAsyncUseCase<IParams, RequestedBuyer[]>
{
  call: (params: IParams) => Promise<Either<Failure, RequestedBuyer[]>> =
    async (): Promise<Either<Failure, RequestedBuyer[]>> => {
      return right([...requestedBuyerMock]);
    };
}
