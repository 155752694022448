export class Percentage {
  amount: number;

  constructor(amount: number) {
    this.amount = amount;
  }

  static fromString: (stringAmount: string) => Percentage = (stringAmount) => {
    let cleaned = stringAmount.replaceAll("%", "").replaceAll(",", "");
    return new Percentage(+cleaned);
  };

  formatted: (roundTo?: number) => string = (roundTo = 2) =>
    (Math.round(this.amount * 10 ** roundTo) / 10 ** roundTo).toFixed(roundTo) +
    "%";
}
