import { useContext } from "react";
import { MicrosoftExcelLogo } from "@phosphor-icons/react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shadcn/ui/select";

import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { chartColors, CircleChart } from "experiences/common/CircleChart";
import { PageWrapperPortfolioV2 } from "experiences/common/PageWrapper";
import { useExposureSegmentationByNav } from "experiences/portfolio-v2/domain/hooks/useExposureSegmentationByNav";

import { EntitiesSelector } from "../components/EntitiesDropdown";
import { IPortfolioExposureSummaryWithGrouping } from "experiences/portfolio-v2/domain/models/PortfolioExposureSummary";
import { fundExposureGroupingOptions } from "../components/TableGrouping";
import { usePortfolioSummary } from "../components/usePortfolioSummary";
import { SummaryTable } from "../components/SummaryTable";
import { PortfolioContext } from "../state/PorfolioV2Context";

const ExposureSegmentationByNav = ({
  data,
  loading,
  reportDatesOptions,
  selectedReportDate,
  onSelectedReportDateChange,
  handleDownloadClick,
  isDownloading,
}: {
  data: IPortfolioExposureSummaryWithGrouping | null;
  loading: boolean;
  reportDatesOptions: { label: string; value: string }[];
  selectedReportDate: string | null;
  onSelectedReportDateChange: (reportDate: string) => void;
  handleDownloadClick: () => void;
  isDownloading: boolean;
}) => {
  const { selectedEntitiesIds } = useContext(PortfolioContext);
  const { exposureSegmentationCharts } = useExposureSegmentationByNav({
    data,
    loading,
  });

  return (
    <>
      <PageSectionTitleDivider
        showBorderTop
        actions={
          <>
            <SelectDateDropdown
              value={selectedReportDate}
              onValueChange={onSelectedReportDateChange}
              options={reportDatesOptions}
              loading={loading}
            />
            <Button
              onClick={handleDownloadClick}
              type={BUTTON_TYPES.SECONDARY}
              icon={<MicrosoftExcelLogo />}
              loading={isDownloading}
              disabled={!selectedReportDate || !data || !selectedEntitiesIds}
            >
              Download Data
            </Button>
          </>
        }
      >
        Exposure Segmentation
      </PageSectionTitleDivider>

      <div className="flex items-center py-12 gap-8 justify-around">
        <CircleChart
          title="Strategy"
          data={exposureSegmentationCharts.strategy}
          width={200}
          height={200}
          colorScale={chartColors.orange}
          loading={loading || !selectedReportDate}
        />
        <CircleChart
          title="Geography"
          data={exposureSegmentationCharts.geography}
          width={200}
          height={200}
          colorScale={chartColors.blue}
          loading={loading || !selectedReportDate}
        />
        <CircleChart
          title="Sector"
          data={exposureSegmentationCharts.sector}
          width={200}
          height={200}
          colorScale={chartColors.green}
          loading={loading || !selectedReportDate}
        />
      </div>
    </>
  );
};

export const SummaryPage = () => {
  const {
    loading,
    entitySelector,
    data,
    groupBy,
    onGroupByChange,
    reportDatesOptions,
    selectedReportDate,
    onSelectedReportDateChange,
    handleDownloadClick,
    isDownloading,
  } = usePortfolioSummary();

  return (
    <PageWrapperPortfolioV2>
      <PageHead
        title="Summary"
        actions={
          <EntitiesSelector
            entitiesOptions={entitySelector.entitiesOptions}
            selectedEntitiesIds={entitySelector.selectedEntitiesIds}
            onSelectEntity={entitySelector.onSelectEntity}
            onRemoveEntity={entitySelector.onRemoveEntity}
            onToggleAllEntities={entitySelector.onToggleAllEntities}
          />
        }
      />
      <ExposureSegmentationByNav
        data={data}
        loading={loading}
        reportDatesOptions={reportDatesOptions}
        selectedReportDate={selectedReportDate}
        onSelectedReportDateChange={onSelectedReportDateChange}
        handleDownloadClick={handleDownloadClick}
        isDownloading={isDownloading}
      />

      <PageSectionTitleDivider
        showBorderTop
        skipBorderBottom
        actions={
          <div className="flex gap-4 h-8 items-center">
            <label className="text-xs font-medium select-none cursor-default">
              Grouping by
            </label>
            <Select
              onValueChange={onGroupByChange}
              value={groupBy}
              disabled={loading}
            >
              <SelectTrigger className="w-[150px] h-8" disabled={loading}>
                <SelectValue placeholder="Select Grouping" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {fundExposureGroupingOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        }
      >
        Summary
      </PageSectionTitleDivider>

      <SummaryTable loading={loading} data={data} />
    </PageWrapperPortfolioV2>
  );
};
