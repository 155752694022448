// Requires participant context to be implemented
// Will return a contact object designed to be shown in the sidebar of the transaction
// The contact is a participant of this transaction that has the role of advisor

import { PARTICIPANT_LOGO_URL } from "common/constants/platform";
import {
  ParticipantCapacity,
  ParticipantRole,
} from "experiences/transactions/domain/models/Participant";
import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { ParticipantListLoaded } from "experiences/transactions/presentation/state/participants/ParticipantsState";

import { TapContact } from "./TapContact";

// If no advisor is found, it won't return a contact
const useTransactionContact = () => {
  const { participantsListState } = useTransactionsContext();
  const advisor =
    participantsListState instanceof ParticipantListLoaded
      ? participantsListState.participants.find(
          (p) =>
            p.role === ParticipantRole.TapAdvisor &&
            p.capacity == ParticipantCapacity.Seller,
        )
      : null;

  if (!advisor) return null;

  const initials = `${advisor.firstName[0] || ""}${advisor.lastName[0] || ""}`;

  return {
    name: `${advisor.firstName} ${advisor.lastName}`,
    title: advisor.title,
    email: advisor.email,
    avatar: `${PARTICIPANT_LOGO_URL}/${advisor.id}.jpg`,
    initials,
  };
};

export const TransactionContact = () => {
  const contact = useTransactionContact();

  if (!contact) return null;

  return <TapContact {...contact} />;
};
