import { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { ChevronsUpDown } from "lucide-react";

import { Button } from "common/components/Button";
import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";

import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "shadcn/ui/Command";
import { SelectOption } from "./useAddFundInterestForm";
import { IManager } from "common/@types/models/Manager";
import TapClient from "common/clients/TapClient";
import { UiCommandEmpty } from "./UiCommandEmpty";

const GET_MANAGERS_PATH = "/managers/";

const useSelectManagerDropdown = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [managers, setManagers] = useState<IManager[]>([]);
  const [search, setSearch] = useState<string>("");

  const refreshManagerOptions = async () => {
    setIsLoading(true);

    await new TapClient(GET_MANAGERS_PATH, {
      paginationSpecs: { perPage: 20, pageIndex: 1 },
      filters: { searchTerm: search },
    })
      .post()
      .then((resp) => {
        setManagers(resp.items);
      });

    setIsLoading(false);
  };

  useEffect(() => {
    refreshManagerOptions();
  }, []);

  useEffect(() => {
    if (search) {
      refreshManagerOptions();
    }
  }, [search]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const managerOptions: SelectOption[] = managers.map((manager) => ({
    value: manager.id,
    label: manager.name,
    type: manager.type,
  }));

  return {
    refreshManagerOptions,
    managers,
    managerOptions,
    isLoading,
    search,
    handleSearchChange,
  };
};

export const SelectManagerDropdown = ({
  open,
  onOpenChange,
  form,
  onManagerSelect,
  onOtherManagerSelect,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  form: FormikProps<any>;
  onManagerSelect: ({
    manager,
    type,
  }: {
    manager: SelectOption;
    type: IManager["type"];
  }) => void;
  onOtherManagerSelect: () => void;
}) => {
  const { managerOptions, search, handleSearchChange, managers } =
    useSelectManagerDropdown();

  const managerId = form.values.managerId;
  const managerName = form.values.managerName;

  return (
    <Popover open={open} onOpenChange={onOpenChange} defaultOpen modal>
      <PopoverTrigger>
        {managerId ? (
          <Button
            role="combobox"
            className="!w-full h-10 !shadow-none !font-normal !text-sm !justify-between"
            aria-expanded={open}
            onClick={() => onOpenChange((prev) => !prev)}
          >
            {managerName}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        ) : (
          <Button
            role="combobox"
            className="!w-full h-10 !shadow-none !font-normal !text-sm !justify-between"
            aria-expanded={open}
            onClick={() => onOpenChange((prev) => !prev)}
          >
            Select Manager
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="p-0 !relative !z-50 !translate-x-0 !translate-y-0 popover-content-same-trigger-width">
        <Command className="relative z-50" value={search}>
          <CommandInput
            placeholder="Select Manager"
            defaultValue={search}
            onValueChange={(value) => {
              handleSearchChange(value);
            }}
          />
          <CommandList>
            <UiCommandEmpty onClick={onOtherManagerSelect}>
              Can’t find the Manager?
              <span className="underline">Add it.</span>
            </UiCommandEmpty>
            <CommandGroup>
              {managerOptions.map((manager) => {
                const handleSelect = () => {
                  const selectedManager = managers.find(
                    (m) => m.id === manager.value,
                  );

                  onManagerSelect({
                    manager: {
                      value: selectedManager.id,
                      label: selectedManager.name,
                    },
                    type: selectedManager.type,
                  });
                  onOpenChange(false);
                };

                return (
                  <CommandItem
                    key={manager.value}
                    value={manager.label}
                    onSelect={handleSelect}
                    className="p-2 cursor-pointer hover:bg-nomad-50/50"
                  >
                    {manager.label}
                  </CommandItem>
                );
              })}
              <CommandItem
                onSelect={onOtherManagerSelect}
                className="flex gap-2 cursor-pointer hover:bg-nomad-50/50"
                forceMount
              >
                Can’t find the Manager?{" "}
                <span className="underline">Click here.</span>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
