import { Either, left } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import authService from "../../../../common/services/authService";
import { ICurrentUser } from "../models/CurrentUser";
import { GetCurrentUser } from "./GetCurrentUser";

export class AuthenticateUser implements IAsyncUseCase<IParams, ICurrentUser> {
  call: (params: IParams) => Promise<Either<Failure, ICurrentUser>> = async ({
    token,
    uidb64,
  }) => {
    return await new TapClient(`/auth/email/${uidb64}/${token}`)
      .get({})
      .then((res: any) => {
        // auth and then get the current user
        authService.handleAuthentication(res);
        return new GetCurrentUser().call({});
      })
      .catch((resp: any) => {
        return left(new Failure("whoopsie doodle"));
      });
  };
}

interface IParams {
  token: string;
  uidb64: string;
}
