import classNames from "classnames";
import { cn } from "common/utils";
import { Checkbox } from "shadcn/ui/checkbox";
import { Switch } from "shadcn/ui/switch";

export const ToggleRow = ({
  title,
  description,
  checked,
  onChange,
  isLoading,
  compact,
  switchLeft,
  disabled,
  tapGold,
  smallToggle,
  useCheckbox,
}: {
  title: string;
  description?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  isLoading?: boolean;
  compact?: boolean;
  switchLeft?: boolean;
  disabled?: boolean;
  tapGold?: boolean;
  smallToggle?: boolean;
  useCheckbox?: boolean;
}) => {
  return (
    <div
      className={cn(
        "flex justify-between items-center border-b border-[#bcb3a533] py-4 px-4 box-border !m-0 cursor-default select-none gap-4",
        {
          "py-2 px-0 border-none": compact,
          "flex-row-reverse": switchLeft,
        },
      )}
    >
      <div
        className={cn("flex-1 h-full flex flex-col", {
          "whitespace-nowrap overflow-hidden": compact,
        })}
      >
        <h3
          className="text-sm font-medium m-0 p-0 text-stone-900 tracking-tight"
          title={title}
        >
          {title}
        </h3>
        {description && (
          <p className="text-xs text-stone-500 mt-1">{description}</p>
        )}
      </div>
      <div className={cn("flex items-center")}>
        {useCheckbox ? (
          <Checkbox
            checked={checked}
            onCheckedChange={onChange}
            className={classNames("m-0 p-0", {
              "opacity-50 cursor-wait": isLoading,
              "cursor-not-allowed": disabled,
              "data-[state=checked]:!bg-nomad-300": tapGold,
            })}
          />
        ) : (
          <Switch
            checked={checked}
            onCheckedChange={onChange}
            className={classNames("m-0 p-0", {
              "opacity-50 cursor-wait": isLoading,
              "cursor-not-allowed": disabled,
              "data-[state=checked]:!bg-nomad-300": tapGold,
            })}
            small={smallToggle}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
