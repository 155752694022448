import { ArrowRight } from "@phosphor-icons/react";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { TimelineStep } from "common/components/TimelineStep";
import { UiProgress } from "experiences/common/Progress";

export const Step2DataExtraction = ({
  goToNextStep,
}: {
  goToNextStep: () => void;
}) => {
  return (
    <>
      <div className="max-w-2xl flex flex-col gap-8 h-full pt-12 pb-12">
        <div className="flex-1 flex flex-col">
          <PageHead
            title="Data Extraction"
            description="You’re on the way to having every piece of data from the fund documents extracted and ready for analysis."
          />

          <TimelineStep
            hideShowMore
            title="Upload Documents"
            subtitle="Fund documents have been securely uploaded."
            completed
          />
          <TimelineStep
            hideShowMore
            title="AI Data Extraction"
            subtitle={
              <>
                The data from the documents is being automatically extracted by
                large language models.
                <br />
                You will receive an email when the data is ready to view.
              </>
            }
            current
            openByDefault
          >
            <UiProgress
              title="Data Extraction Progress"
              value={30}
              outOf={100}
              incremental
            />
          </TimelineStep>
          <TimelineStep
            hideShowMore
            title="Analyst Review"
            subtitle="An experienced analyst will review the AI data for accuracy."
            isLastStep
          />
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type={BUTTON_TYPES.SECONDARY}
            onClick={goToNextStep}
            icon={<ArrowRight />}
            iconRight
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};
