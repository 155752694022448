import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { generatePath, useParams } from "react-router-dom";

import { DescriptionListEntry } from "common/components/DescriptionList2";
import { LP_ROUTES } from "common/constants/routes";
import { TransactionContact } from "experiences/common/TransactionContact";
import { SidebarSideMenu } from "experiences/dashboard/presentation/components/SidebarMenu";

import { BasicInformation } from "../components/company-detail/BasicInformation";
import { FundsTable } from "../components/company-detail/FundsTable";
import { CompanyUpdates } from "../components/company-detail/CompanyUpdates";
import { PageWrapper } from "experiences/common/PageWrapper";
import {
  LayoutWrapper,
  MainContent,
  Sidebar,
} from "common/components/MainSidebarLayout";
import { ContentWrapper } from "common/components/WidthWrapper";
import { PortfolioProvider } from "../state/PorfolioV2Context";
import { APP_BAR_COMPANY_NAME_NODE } from "common/constants/assets";
import { createPortal } from "react-dom";
import { useCompanyReportDates } from "../components/company-detail/useCompanyReportDates";
import { companyClient } from "common/clients/ApolloClient";
import { useQuery } from "@apollo/client";
import {
  GetCompanyDataQuery,
  ICompanyData,
  ICompanyDataQuery,
} from "experiences/portfolio-v2/domain/usecases/GetCompanyDataQuery";
import {
  GetCompanyFundExposureQuery,
  ICompanyFundExposure,
} from "experiences/portfolio-v2/domain/usecases/GetCompanyFundExposureQuery";
import { ICompanyFundExposureQuery } from "experiences/portfolio-v2/domain/usecases/GetCompanyFundExposureQuery";
import {
  GetCompanyQualitativeDataQuery,
  ICompanyQualitativeData,
  ICompanyQualitativeDataQuery,
} from "experiences/portfolio-v2/domain/usecases/GetCompanyQualitativeData";

const TransactionsMenu = [
  {
    label: "Basic Information",
  },
  {
    label: "Funds",
  },
  {
    label: "Updates",
  },
];

const useCompanyDetail = () => {
  const { companyId } = useParams();
  // Used ICompanyData from transactions experience but change it if needed
  const [company, setCompany] = useState<ICompanyData | null>(null);
  const [fundExposure, setFundExposure] = useState<ICompanyFundExposure[]>([]);
  const [qualitativeData, setQualitativeData] =
    useState<ICompanyQualitativeData | null>(null);
  const [descriptionListEntries, setDescriptionListEntries] = useState<
    DescriptionListEntry[]
  >([]);

  // ACTUAL FETCHING DATA FROM THE BACKEND
  const {
    companyDataReportDatesOptions,
    loading,
    currentReportDate,
    onReportDateChange,
  } = useCompanyReportDates(companyId);

  const companyDataQuery = useQuery<ICompanyDataQuery>(GetCompanyDataQuery, {
    variables: { companyId, reportDate: currentReportDate },
    client: companyClient,
    skip: true, // if query is not happening remove this
  });

  const companyFundExposureQuery = useQuery<ICompanyFundExposureQuery>(
    GetCompanyFundExposureQuery,
    {
      variables: { companyId, reportDate: currentReportDate },
      client: companyClient,
      skip: true,
    },
  );

  const companyQualitativeDataQuery = useQuery<ICompanyQualitativeDataQuery>(
    GetCompanyQualitativeDataQuery,
    {
      variables: { companyId, reportDate: currentReportDate },
      client: companyClient,
      skip: true,
    },
  );

  // Note: If you go to a new company there may be a flash of info from the previous company
  // A possible solution is to set all loading states to true when the companyId changes
  // and then set them to false when the data is fetched, and set state data to initial state
  const refetchQueries = async () => {
    setCompany(null);
    setFundExposure([]);
    setQualitativeData(null);

    // Queries should run simultaneously
    const [companyData, fundExposure, qualitativeData] = await Promise.all([
      companyDataQuery
        .refetch({ reportDate: currentReportDate, companyId })
        .then((res) => {
          if (res.data?.companyData) {
            setCompany(res.data.companyData);
            setDescriptionListEntries([
              {
                label: "Geography",
                value: "TODO: Grab from companyData",
              },
              {
                label: "Industry",
                value: "TODO: Grab from companyData",
              },
              {
                label: "Performance",
                value: "TODO: Grab from companyData OR fundExposure",
              },
            ]);
          }
        }),
      companyFundExposureQuery
        .refetch({ reportDate: currentReportDate, companyId })
        .then((res) => {
          if (res.data?.fundExposure) {
            setFundExposure(res.data.fundExposure || []);
          }
        }),
      companyQualitativeDataQuery
        .refetch({ reportDate: currentReportDate, companyId })
        .then((res) => {
          if (res.data?.qualitativeData) {
            setQualitativeData(res.data.qualitativeData);
          }
        }),
    ]);

    console.log("companyData", companyData);
    console.log("fundExposure", fundExposure);
    console.log("qualitativeData", qualitativeData);
  };

  useEffect(() => {
    if (companyId) {
      refetchQueries();
    }
  }, [companyId]);

  // MAYBE TODO: Put this inside a Context so we don't cascade a lot of props, doing it for now for speed
  return {
    companyId,
    isLoading:
      companyDataQuery.loading ||
      companyFundExposureQuery.loading ||
      companyQualitativeDataQuery.loading ||
      loading,
    descriptionListEntries,
    company,
    companyDataReportDatesOptions,
    currentReportDate,
    onReportDateChange,
    fundExposure,
    qualitativeData,
  };
};

export const CompanyDetail = () => {
  const {
    companyId,
    isLoading,
    company,
    companyDataReportDatesOptions,
    currentReportDate,
    onReportDateChange,
    fundExposure,
    qualitativeData,
  } = useCompanyDetail();

  const idForCompanyImg = company?.companyId || null;

  const baseRoute = generatePath(LP_ROUTES.PortfolioEntrypoint, { companyId });

  const companyNameElement = document.getElementById(APP_BAR_COMPANY_NAME_NODE);

  return (
    <PortfolioProvider>
      <ContentWrapper fullWidth>
        <PageWrapper className="w-full">
          <LayoutWrapper className="w-full">
            {companyId && (
              <Helmet>
                <title>{companyId}</title>
              </Helmet>
            )}
            {company?.companyName?.length > 0 &&
              createPortal(company?.companyName, companyNameElement)}
            <Sidebar title={company?.companyName || ""} image={idForCompanyImg}>
              <SidebarSideMenu
                menu={TransactionsMenu}
                baseRoute={baseRoute}
                contact={<TransactionContact />}
              />
            </Sidebar>
            <MainContent>
              <BasicInformation
                company={company}
                loading={isLoading}
                reportDatesOptions={companyDataReportDatesOptions}
                currentReportDate={currentReportDate}
                onReportDateChange={onReportDateChange}
              />
              <FundsTable funds={fundExposure} loading={isLoading} />
              <CompanyUpdates
                investmentsUpdates={qualitativeData?.investmentsUpdates || []}
                loading={isLoading}
              />
            </MainContent>
          </LayoutWrapper>
        </PageWrapper>
      </ContentWrapper>
    </PortfolioProvider>
  );
};
