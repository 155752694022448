import { useContext } from "react";
import { useParams } from "react-router";
import { MicrosoftExcelLogo } from "@phosphor-icons/react";

import { cn } from "common/utils";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { BarLoader } from "common/components/BarLoader";

import {
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { CapitalAccountStatementKeys } from "experiences/transactions/domain/models/FundDataCapitalAccounts";
import { EmptyState } from "common/components/EmptyState";
import { MissingDocumentsState } from "../verify-permission/VerifyPermissionOverlay";
import { DataExtractionInProgress } from "experiences/common/DataExtractionInProgress";
import { chartColors, CircleChart } from "experiences/common/CircleChart";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";

import { Button, BUTTON_TYPES } from "common/components/Button";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";
import { useCapitalAccounts } from "./useCapitalAccounts";
import {
  currencyKeys,
  multiplierKeys,
  percentageKeys,
  rightAlignedKeys,
  attachTitleKeys,
} from "./model";
import { DealBreakdownChart } from "../mock/DealBreakdownMock";

const CapitalAccountsCharts = ({
  exposureSegmentationCharts,
}: {
  exposureSegmentationCharts: {
    gpChart: DealBreakdownChart[];
    fundChart: DealBreakdownChart[];
  };
}) => {
  return (
    <div className="w-full max-w-full flex items-center my-12 gap-8 justify-around">
      <CircleChart
        data={exposureSegmentationCharts.fundChart}
        title="Fund"
        width={230}
        height={230}
        colorScale={chartColors.blue}
      />
    </div>
  );
};

export const CapitalAccountsTab = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const { txnId } = useParams();
  const { reportDates } = useContext(TransactionFundDataContext);

  const {
    columns,
    rotatedData,
    tableData,
    loading,
    exposureSegmentationCharts,
    handleDownloadDataClick,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
  } = useCapitalAccounts({
    txnId,
    currentReportDate: reportDates.capitalAccounts.currentDate,
    summary,
  });

  const hasFunds = columns.length > 1;
  const showReportDateSelector =
    hasFunds && reportDates.capitalAccounts.options.length >= 1;
  const showDownloadButton = hasFunds;

  if (loading && !hasFunds) {
    return <BarLoader className="min-h-96" />;
  }

  const fullWidthTable = columns.length >= 4;

  return (
    <>
      {loading && !hasFunds && <BarLoader className="min-h-96" />}

      {!loading && awaitingUpload && <MissingDocumentsState txnId={txnId} />}
      {!loading && extractionInProgress && (
        <DataExtractionInProgress className="max-h-96" />
      )}
      {extractionReady && hasFunds && (
        <>
          <PageSectionTitleDivider
            actions={
              <>
                {showReportDateSelector && (
                  <SelectDateDropdown
                    value={reportDates.capitalAccounts.currentDate}
                    onValueChange={
                      reportDates.capitalAccounts.onReportDateChange
                    }
                    options={reportDates.capitalAccounts.options}
                    loading={loading}
                  />
                )}
                {showDownloadButton && (
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    onClick={handleDownloadDataClick}
                    icon={<MicrosoftExcelLogo />}
                  >
                    Download Data
                  </Button>
                )}
              </>
            }
          >
            Capital Account Statement
          </PageSectionTitleDivider>
          <div className="w-full max-w-full overflow-x-scroll relative">
            <TapTable
              data={rotatedData}
              columns={columns}
              skipTopBorder
              isLoading={loading}
              fixedHeaders
              layoutFixed={fullWidthTable}
              className={cn("pb-16", {
                "w-auto max-w-full": fullWidthTable,
                "!w-max border-r": !fullWidthTable,
              })}
              renderRow={(row: {
                key: CapitalAccountStatementKeys;
                funds: string[];
                label: string;
              }) => {
                const showTableFullWidth = columns.length <= 3;

                return (
                  <>
                    <TableCell
                      isPrimaryColumn
                      isFixed
                      className="!w-60 !max-w-60 !whitespace-nowrap !overflow-hidden !overflow-ellipsis"
                    >
                      {row.label}
                    </TableCell>
                    {row.funds.map((value, index) => {
                      const isCurrency = currencyKeys.includes(row.key);
                      const isPercentage = percentageKeys.includes(row.key);
                      const isMultiplier = multiplierKeys.includes(row.key);
                      const isAttachTitle = attachTitleKeys.includes(row.key);
                      const isHumanVerified =
                        tableData[index][
                          CapitalAccountStatementKeys.isHumanVerified
                        ];

                      return (
                        <TableCell
                          key={index}
                          isUnverified={!isHumanVerified}
                          className={cn(
                            "!whitespace-nowrap !overflow-hidden !overflow-ellipsis",
                            {
                              hidden: isHumanVerified,
                              "!text-right": rightAlignedKeys.includes(row.key),
                              "!w-full": showTableFullWidth,
                              "table-cell": !showTableFullWidth,
                            },
                          )}
                          {...(isAttachTitle ? { title: value } : {})}
                        >
                          {tableCellDataFormatter({
                            value,
                            format: isCurrency
                              ? "currency"
                              : isPercentage
                                ? "percentage"
                                : isMultiplier
                                  ? "multiplier"
                                  : undefined,
                          })}
                        </TableCell>
                      );
                    })}
                  </>
                );
              }}
            />
          </div>
          <CapitalAccountsCharts
            exposureSegmentationCharts={exposureSegmentationCharts}
          />
        </>
      )}
      {!loading && extractionReady && !hasFunds && (
        <EmptyState
          title="We don't have any data for this transaction yet, please check back later."
          className="!h-96 !max-h-96 select-none"
        />
      )}
    </>
  );
};
