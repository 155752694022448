import { Modal } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";

import FormikTextField from "../../../../common/components/formik/FormikTextField";
import { BUTTON_TYPES, Button, PlusIcon } from "common/components/Button";
import {
  TapUiModalContent,
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import {
  CreateOrganizationForm,
  organizationTypeSelectOptions,
} from "experiences/authentication/domain/models/Organization";
import FormikDropdownSelect from "common/components/formik/FormikDropdownSelect";

interface CreateOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  setFirm: React.Dispatch<React.SetStateAction<CreateOrganizationForm>>;
  className?: string;
}

const CreateOrganizationModal = ({
  open,
  onClose,
  setFirm,
  className,
}: CreateOrganizationModalProps) => {
  const validationSchema = yup.object({
    firmName: yup.string().required("Required"),
    firmType: yup
      .number()
      .required("Required")
      .notOneOf([9999], "Invalid selection"),
  });
  const formik = useFormik({
    initialValues: {
      firmName: "",
      firmType: 6,
    },
    validationSchema,
    onSubmit: (values) => {
      setFirm(
        new CreateOrganizationForm({
          name: values.firmName,
          type: values.firmType,
        }),
      );
      onClose();
    },
  });

  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className={className}
    >
      <form onSubmit={formik.submitForm}>
        <TapUiVerticalModalWrapper className="!max-w-lg w-full">
          <TapUiModalHead
            title="New organization"
            subtitle="Add few last details about your organization"
            onCloseClick={onClose}
          />
          <TapUiModalContent>
            <FormikTextField
              name="firmName"
              formik={formik}
              label="Name"
              fullWidth
            />

            <FormikDropdownSelect
              formik={formik}
              name="firmType"
              fullWidth
              options={organizationTypeSelectOptions}
            />
          </TapUiModalContent>

          <Button
            onClick={formik.submitForm}
            fullWidth
            type={BUTTON_TYPES.SECONDARY}
            size="large"
            icon={<PlusIcon />}
          >
            Create Organization
          </Button>
        </TapUiVerticalModalWrapper>
      </form>
    </Modal>
  );
};

export default CreateOrganizationModal;
