import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";

export class ArchiveIOI implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    ioiId,
  }) => {
    return await new TapClient(`ioi/${ioiId}/archive/`)
      .post()
      .then((_) => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  ioiId: string;
}
