import { useState } from "react";
import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";

import { GP_ROUTES } from "common/constants/routes";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import { DropZone } from "experiences/common/DropZone";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  generateUserFields,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { usePortfolioContext } from "experiences/portfolio/presentation/state/PortfolioContext";
import { LpCapitalAccountsFileSubmitted } from "experiences/portfolio/presentation/state/PortfolioEvent";

export const GpLimitedPartnersUploadCapitalAccountStatement = () => {
  const [files, setFiles] = useState<File[]>([]);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { emitEvent } = usePortfolioContext();
  const { sendMessage } = useSendSlackMessage({
    channel: import.meta.env.VITE_APP_SLACK_IOI_CHANNEL,
  });

  const handleManualClick = () => {
    navigate(GP_ROUTES.LimitedPartnersUploadCapitalAccountsBulk);
  };

  const handleCloseClick = () => {
    navigate(GP_ROUTES.LimitedPartners);
  };

  const handleUploadClick = () => {
    emitEvent!(new LpCapitalAccountsFileSubmitted({ files: files }));

    sendMessage({
      blocks: [
        generateSlackMessagePayload({
          title: "User has uploaded a capital accounts file",
          fields: generateUserFields(user),
          context: generateObjectAsFields({
            "processing files": files.map((file) => file.name).join(", "),
          }),
        }),
      ],
    });
  };

  const handleFileChange = (files: File[]) => {
    if (files.length > 0) {
      setFiles(files);
    }
  };

  return (
    <>
      <PageHead title="Upload" />

      <StepperFlow
        onClose={handleCloseClick}
        name="Import Capital Account Statements"
        activeStepIndex={0}
      >
        <Stack width={"100%"} height={"100%"} justifyContent={"space-between"}>
          <Stack>
            <PageHead
              title="Import Capital Account Statements"
              description={
                <>
                  Upload PDF versions of your Capital Accounts Statements that
                  contain information on your commitment, contributions, and
                  distributions. Tap uses large language models to intelligently
                  extract the relevant data.
                </>
              }
            />
            <DropZone
              onFileChange={handleFileChange}
              multiple
              accept={["pdf"]}
            />
          </Stack>
          <Stack
            width={"100%"}
            direction="row"
            sx={{ pb: 6, mt: 6 }}
            gap={1.5}
            justifyContent={"end"}
          >
            <Button type={BUTTON_TYPES.PRIMARY} onClick={handleManualClick}>
              Fill Out Manually
            </Button>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              onClick={handleUploadClick}
              disabled={files.length === 0}
              icon={<ArrowRight />}
              iconRight
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </StepperFlow>
    </>
  );
};
