import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";

export enum PortfolioPerformanceGroupingKeys {
  Entity = "ENTITY",
  Strategy = "STRATEGY",
  Geography = "GEOGRAPHY",
  Vintage = "VINTAGE",
  Manager = "MANAGER",
}

export const PortfolioPerformanceGroupingLabels: Record<
  PortfolioPerformanceGroupingKeys,
  string
> = {
  [PortfolioPerformanceGroupingKeys.Entity]: "Entity",
  [PortfolioPerformanceGroupingKeys.Strategy]: "Strategy",
  [PortfolioPerformanceGroupingKeys.Geography]: "Geography",
  [PortfolioPerformanceGroupingKeys.Vintage]: "Vintage",
  [PortfolioPerformanceGroupingKeys.Manager]: "Manager",
};

export const portfolioPerformanceGroupingOptions = Object.values(
  PortfolioPerformanceGroupingKeys,
).map((key) => ({
  label: PortfolioPerformanceGroupingLabels[key],
  value: key,
}));

export enum FundExposureGroupingKeys {
  Entity = "ENTITY",
  Strategy = "STRATEGY",
  Geography = "GEOGRAPHY",
  Vintage = "VINTAGE",
  Manager = "MANAGER",
}

export const SummaryGroupLabels: Record<
  FundExposureGroupingKeys,
  Map<number, string | String>
> = {
  [FundExposureGroupingKeys.Entity]: new Map(),
  [FundExposureGroupingKeys.Strategy]: FundStrategyNameMap,
  [FundExposureGroupingKeys.Geography]: FundGeographyNameMap,
  [FundExposureGroupingKeys.Vintage]: null,
  [FundExposureGroupingKeys.Manager]: new Map(),
};

export const fundExposureGroupingLabels: Record<
  FundExposureGroupingKeys,
  string
> = {
  [FundExposureGroupingKeys.Entity]: "Entity",
  [FundExposureGroupingKeys.Strategy]: "Strategy",
  [FundExposureGroupingKeys.Geography]: "Geography",
  [FundExposureGroupingKeys.Vintage]: "Vintage",
  [FundExposureGroupingKeys.Manager]: "Manager",
};

export const fundExposureGroupingOptions = Object.values(
  FundExposureGroupingKeys,
).map((key) => ({
  label: fundExposureGroupingLabels[key],
  value: key,
}));

export interface IFundExposureSummaryAggregateData {
  groupKey: FundExposureGroupingKeys | string;
  groupIds: string[];
  fundSize: string;
  portfolioPercent: string;
  fundsCount: number | null;
  nav: string | null;
  committed: string | null;
  unfunded: string | null;
  called: string | null;
  distributed: string | null;
  netMoic: string | null;
  netIrr: string | null;
}

export interface IFundExposureSummaryGrouping {
  groupingInformation: {
    value: string;
    ids: string[];
  }[];
  groupedBy: FundExposureGroupingKeys;
  aggregateData: IFundExposureSummaryAggregateData[];
  topLevelAggregateData: IFundExposureSummaryAggregateData;
}
export enum AggregateSoiGroupKeys {
  Entity = "ENTITY",
  Geography = "GEOGRAPHY",
  Sector = "SECTOR",
  Type = "TYPE",
  Manager = "MANAGER",
}

export const aggregateSoiGroupingLabels: Record<AggregateSoiGroupKeys, string> =
  {
    [AggregateSoiGroupKeys.Entity]: "Entity",
    [AggregateSoiGroupKeys.Geography]: "Geography",
    [AggregateSoiGroupKeys.Sector]: "Sector",
    [AggregateSoiGroupKeys.Type]: "Type",
    [AggregateSoiGroupKeys.Manager]: "Manager",
  };

export interface ILookthroughSoiAggregateData {
  groupKey: AggregateSoiGroupKeys | string;
  groupIds: string[];
  companiesCount: number | null;
  isHumanVerified: boolean;
  totalCost: string | null;
  totalValue: string | null;
  realizedCost: string | null;
  realizedValue: string | null;
  unrealizedCost: string | null;
  unrealizedValue: string | null;
  portfolioPercent: string | null;
  investmentMoic: string | null;
  nav: string | null;
  sector: string | null;
}

export interface ILookthroughSoiGrouping {
  groupedBy: AggregateSoiGroupKeys;
  topLevelAggregateData: ILookthroughSoiAggregateData;
  aggregateData: ILookthroughSoiAggregateData[];
}
