import React, { useEffect, useState } from "react";
import { match } from "fp-ts/lib/Either";

import FormikAutocomplete from "../../../../common/components/formik/FormikAutocomplete";
import { Failure } from "../../../../common/@types/app/Failure";
import { IssuersPage } from "experiences/funds/domain/models/IssuersPage";
import { RetrieveFilteredIssuers } from "experiences/funds/domain/usecases/RetrieveFilteredIssuers";
import { IIssuer } from "common/@types/models/Issuer";

const mapIssuersToOptions = (issuers: IIssuer[]) =>
  issuers.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

interface IProps {
  name: string;
  formik: any;
  label: string;
  prefetchedIssuers?: IIssuer[];
  preSelectedIssuer?: IIssuer;
  onOptionChanged?: (label: string) => void;
}

const IssuerSelector: React.FC<IProps> = ({
  formik,
  prefetchedIssuers,
  preSelectedIssuer,
  onOptionChanged,
  ...props
}) => {
  const [issuers, setIssuers] = useState<IIssuer[]>([]);
  const [searchValue, setSearchValue] = useState<string>(() => {
    if (preSelectedIssuer) {
      return preSelectedIssuer?.name;
    }
    return "";
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refreshIssuers = () => {
    setIsLoading(true);
    if (!prefetchedIssuers) {
      new RetrieveFilteredIssuers()
        .call({
          paginationSpecs: { perPage: 100, pageIndex: 1 },
          filters: { searchTerm: searchValue },
        })
        .then((resp) => {
          match<Failure, IssuersPage, void>(
            (_: Failure) => {},
            (page: IssuersPage) => {
              setIssuers(page.issuers);
            },
          )(resp);
        });
    } else {
      setIssuers(prefetchedIssuers);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    refreshIssuers();
  }, [searchValue, prefetchedIssuers]);

  return (
    <FormikAutocomplete
      formik={formik}
      {...props}
      onOptionChange={(option) =>
        onOptionChanged && onOptionChanged(option.label)
      }
      onInputChange={(value: string) => {
        onOptionChanged && onOptionChanged(value);
        setSearchValue(value);
      }}
      loading={isLoading}
      options={
        preSelectedIssuer
          ? [
              ...mapIssuersToOptions([preSelectedIssuer]),
              ...mapIssuersToOptions(issuers),
            ]
          : mapIssuersToOptions(issuers)
      }
      filterOptions={(x: any) => x}
      defaultValue={
        preSelectedIssuer
          ? mapIssuersToOptions([preSelectedIssuer])[0].value
          : undefined
      }
    />
  );
};

export default IssuerSelector;
