import { useCallback, useContext } from "react";
import { useParams } from "react-router";

import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";
import {
  ITabTableColumn,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";

import { ITransactionFundDataReportDate } from "../useFundDataReportDate";
import { FundDataWrapper } from "../FundDataWrapper";
import { IInterimCashflow, IInterimCashflowLabels } from "./model";
import { useInterimCashflows } from "./useInterimCashflows";

const FORCE_HIDE_REPORT_DATE_SELECTOR = true;
const FORCE_REPORT_DATE = "2010-12-31";

// prettier-ignore
const columns: ITabTableColumn[] = [
    { label: "Fund", key: "fund", align: TableAlignment.LEFT },
    { label: "Type", key: "type", align: TableAlignment.LEFT },
    { label: "Amount", key: "amount", align: TableAlignment.RIGHT },
    { label: "Wire Date", key: "wireDate", align: TableAlignment.RIGHT },
    { label: "Description", key: "description", align: TableAlignment.LEFT },
];

const InterimCashflowsTable = ({
  interimCashflows,
  reportDates,
  loading,
}: {
  interimCashflows: IInterimCashflow[];
  reportDates: ITransactionFundDataReportDate["reportDates"];
  loading: boolean;
}) => {
  const hasReportDates = reportDates.interimCashflows.options.length > 0;

  const showReportDateSelector =
    hasReportDates && !FORCE_HIDE_REPORT_DATE_SELECTOR;

  const renderRow = useCallback((row: IInterimCashflow) => {
    return (
      <>
        <TableCell>{row.fund}</TableCell>
        <TableCell>
          {(!!IInterimCashflowLabels[row.type] &&
            IInterimCashflowLabels[row.type]) ||
            NOT_AVAILABLE_STR}
        </TableCell>
        <TableCell className="text-right">
          {tableCellDataFormatter({ value: row.amount, format: "currency" })}
        </TableCell>
        <TableCell className="text-right">
          {tableCellDataFormatter({ value: row.wireDate, format: "date" })}
        </TableCell>
        <TableCell title={row.description}>{row.description}</TableCell>
      </>
    );
  }, []);

  return (
    <div className="relative min-h-64">
      <PageSectionTitleDivider
        actions={
          <div className="flex gap-4 h-8">
            {showReportDateSelector && (
              <>
                <SelectDateDropdown
                  value={reportDates.interimCashflows.currentDate}
                  onValueChange={
                    reportDates.interimCashflows.onReportDateChange
                  }
                  options={reportDates.interimCashflows.options}
                  loading={loading}
                />
              </>
            )}
          </div>
        }
      >
        Interim Cashflows
      </PageSectionTitleDivider>
      <TapTable
        columns={columns}
        data={interimCashflows}
        isLoading={loading}
        renderRow={renderRow}
        skipTopBorder
        showEmptyState={interimCashflows.length === 0 && !loading}
        emptyStateTitle="No interim cashflows available."
      />
    </div>
  );
};

export const InterimCashflowsTab = () => {
  const { txnId } = useParams();

  const { reportDates } = useContext(TransactionFundDataContext);

  const {
    interimCashflows,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
    loading,
  } = useInterimCashflows({
    txnId,
    currentReportDate: FORCE_HIDE_REPORT_DATE_SELECTOR
      ? FORCE_REPORT_DATE
      : reportDates.interimCashflows.currentDate,
  });

  return (
    <FundDataWrapper
      barLoader={loading}
      awaitingUpload={awaitingUpload}
      extractionInProgress={extractionInProgress}
      extractionReady={extractionReady}
    >
      <InterimCashflowsTable
        interimCashflows={interimCashflows}
        reportDates={reportDates}
        loading={loading}
      />
    </FundDataWrapper>
  );
};
