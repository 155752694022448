export const TRANSACTION_PREPARATION_START_DATE_KEY =
  "transactionPreparationStartDate";
export const FIRST_ROUND_DUE_DILIGENCE_DATE_KEY = "firstRoundDueDiligenceDate";
export const FIRST_ROUND_BID_EVALUATION_DATE_KEY =
  "firstRoundBidEvaluationDate";
export const SECOND_ROUND_DUE_DILIGENCE_DATE_KEY =
  "secondRoundDueDiligenceDate";
export const SECOND_ROUND_BID_EVALUATION_DATE_KEY =
  "secondRoundBidEvaluationDate";
export const DOCUMENTATION_AND_TRANSACTION_CLOSING_DATE_KEY =
  "documentationAndTransactionClosingDate";
export const TARGET_CLOSE_DATE_KEY = "targetCloseDate";
