import { match } from "fp-ts/lib/Either";
import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router";

import { Failure } from "../../../../common/@types/app/Failure";
import { useAuthContext } from "../../../authentication/presentation/state/AuthenticationContext";
import { Fund } from "../../domain/models/Fund";
import { FundFilterSpecs } from "../../domain/models/IssuerFilterSpecs";
import { PageSpecs } from "../../../../common/@types/models/PageSpecs";
import { FundsPage } from "../../domain/models/FundsPage";
import { TabType, TabTypeRoute } from "../../domain/models/TabType";
import { RetrieveFilteredFunds } from "../../domain/usecases/RetrieveFilteredFunds";
import { RetrieveSingleFund } from "../../domain/usecases/RetrieveSingleFund";
import { RetrieveSingleFundSummary } from "experiences/funds/domain/usecases/RetrieveSingleFundSummary";
import { getTabIndexFromPathname } from "../../../../common/utils/getTabIndexFromPathname";
import {
  AlertActivationToggled,
  FundWatchlistToggled,
  FundsAppBarSelectedTabChanged,
  FundsEvent,
  FundSizeFilterChanged,
  GeographyFilterChanged,
  NewPageRequested,
  PageSizeChanged,
  SearchTermChanged,
  StrategyFilterChanged,
  VintageFilterChanged,
  SingleFundCheckToggled,
  EntireCurrentFundsPageCheckToggled,
  FundsTablePageEvent,
  FundsTableFilterEvent,
  FundWatchlistToggledForCurrentlyChecked,
  ConfirmCreateAlertButtonClicked,
  VerifyAccessChoiceSelected,
  AccessChoice,
  VerifyPermissionFlowClosed,
} from "./FundsEvent";
import {
  ScreenResultsLoaded,
  ScreenResultsLoadingFailed,
  ScreenResultsLoadingInPorgress,
  FundScreenState,
  AlertsScreenState,
  AlertsPageLoading,
  AlertsPageLoaded,
  AlertsPageFailedToLoad,
  CheckedFundsState,
  WatchlistLoaded,
  MultiSelectActionBeltNotLoaded,
  MultiSelectActionBeltState,
  MultiSelectActionBeltLoaded,
  VerifyPermissionState,
  ShowConfirmHoldingsScreen,
  ShowGPAccessRequestReceiptScreen,
  ShowAccessSelectionScreen,
} from "./FundsState";
import { NoReturn } from "../../../../common/@types/app/UseCase";
import { CreateNewAlert } from "../../domain/usecases/CreateNewAlert";
import { ListAlerts } from "../../domain/usecases/ListAlerts";
import { Alert } from "../../domain/models/Alert";
import { ToggleAlertActivation } from "../../domain/usecases/ToggleAlertActivation";
import {
  ActionType,
  FundToWatchlistAction,
} from "../../domain/usecases/FundToWatchlistAction";
import { ListWatchlistUserFunds } from "../../domain/usecases/ListWatchlistUserFunds";
import { MatchNames } from "experiences/funds/domain/usecases/MatchNames";
import { useSnackbar } from "notistack";
import { FlagFundApprovedBuyer } from "experiences/funds/domain/usecases/FlagFundApprovedBuyer";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  generateUserFields,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { LP_ROUTES } from "common/constants/routes";
import toast from "react-hot-toast";

interface IUseCases {
  retrieveListings: RetrieveFilteredFunds;
  retrieveSingleFund: RetrieveSingleFund;
  retrieveSingleFundSummary: RetrieveSingleFundSummary;
  createAlert: CreateNewAlert;
  listAlerts: ListAlerts;
  toggleAlertActivation: ToggleAlertActivation;
  fundWatchlistAction: FundToWatchlistAction;
  listWatchlistUserFunds: ListWatchlistUserFunds;
  matchNames: MatchNames;
  flagFundApprovedBuyer: FlagFundApprovedBuyer;
}

export const useManageFundsState = ({
  retrieveListings,
  retrieveSingleFund,
  createAlert,
  listAlerts,
  toggleAlertActivation,
  fundWatchlistAction,
  listWatchlistUserFunds,
  matchNames,
  flagFundApprovedBuyer,
}: IUseCases) => {
  // general states
  const navigate = useNavigate();
  const defaultTab = getTabIndexFromPathname(TabTypeRoute) || TabType.Screen;
  const [selectedTab, setSelectedTab] = useState<TabType>(defaultTab);

  // fund table related states/utils ..........................................................

  const { user } = useAuthContext();
  const { sendMessage } = useSendSlackMessage();
  const initialCheckedFunds = {
    isEntirePageChecked: false,
    checkedFundIds: [],
  };

  const { enqueueSnackbar } = useSnackbar();

  const [checkedFunds, setCheckedFunds] =
    useState<CheckedFundsState>(initialCheckedFunds);

  const [fundTablePageState, setFundTablePageState] = useState<FundScreenState>(
    new ScreenResultsLoadingInPorgress({ checkedFunds }),
  );

  const [fundPageSpecs, setFundPageSpecs] = useState<PageSpecs>({
    pageIndex: 1,
    perPage: 25,
  });

  const filtersInitial = {
    strategies: [],
    geographies: [],
  };

  const [filtersSpecs, setFiltersSpecs] =
    useState<FundFilterSpecs>(filtersInitial);

  const refreshFundsTable = (onDone?: () => void) => {
    retrieveListings
      .call({ paginationSpecs: fundPageSpecs, filters: filtersSpecs })
      .then((resp) => {
        match<Failure, FundsPage, void>(
          (_: Failure) => {
            setFundTablePageState(
              new ScreenResultsLoadingFailed({ checkedFunds }),
            );
          },
          (fundsPage: FundsPage) => {
            setFundTablePageState(
              (prev) =>
                new ScreenResultsLoaded({
                  fundsPage: fundsPage,
                  checkedFunds,
                  watchlistLoadingFundIds:
                    prev instanceof ScreenResultsLoaded
                      ? prev.watchlistLoadingFundIds
                      : [],
                }),
            );
          },
        )(resp);
      });
    if (onDone) onDone();
  };

  const updateFundFiltersAndTableState = (filteringSpecEvent: { spec: {} }) => {
    setFiltersSpecs((prevFiltersSpecs) => ({
      ...prevFiltersSpecs,
      ...filteringSpecEvent.spec,
    }));
  };

  const updateFundPaginationAndTableState = async (paginationSpecsEvent: {
    spec: {};
  }) => {
    setFundPageSpecs((prevPageSpec) => ({
      ...prevPageSpec,
      ...paginationSpecsEvent.spec,
    }));
  };

  useEffect(() => {
    refreshFundsTable();
  }, [filtersSpecs, fundPageSpecs]);

  useEffect(() => {
    if (fundTablePageState instanceof ScreenResultsLoaded)
      setFundTablePageState(
        new ScreenResultsLoaded({
          fundsPage: fundTablePageState.fundsPage,
          checkedFunds,
          watchlistLoadingFundIds: fundTablePageState.watchlistLoadingFundIds,
        }),
      );
  }, [checkedFunds]);

  // alerts screen related states/utils..........................................................
  const [alertTablePageState, setAlertTablePageState] =
    useState<AlertsScreenState>(new AlertsPageLoading());

  const appendAlertToTogglingArray = (newAlertId: string) => {
    setAlertTablePageState((prevState) => {
      if (prevState instanceof AlertsPageLoaded) {
        let newTogglingIds = prevState.alertIdsToggleInProgress;
        newTogglingIds.push(newAlertId);
        return new AlertsPageLoaded({
          alerts: prevState.alerts,
          alertIdsToggleInProgress: newTogglingIds,
        });
      }
    });
  };

  const removeAlertFromTogglingArray = (newAlertId: string) => {
    setAlertTablePageState((prevState) => {
      if (prevState instanceof AlertsPageLoaded) {
        let newTogglingIds = prevState.alertIdsToggleInProgress.filter(
          (id) => id != newAlertId,
        );
        return new AlertsPageLoaded({
          alerts: prevState.alerts,
          alertIdsToggleInProgress: newTogglingIds,
        });
      }
    });
  };

  const appendFundToWatchlistingArray = (newFundId: string) => {
    setFundTablePageState((prevState) => {
      if (prevState instanceof ScreenResultsLoaded) {
        let watchlistingIds = prevState.watchlistLoadingFundIds;
        watchlistingIds.push(newFundId);
        return new ScreenResultsLoaded({
          ...prevState,
          watchlistLoadingFundIds: watchlistingIds,
        });
      }
      return prevState;
    });
  };

  const removeFundToWatchlistingArray = (newFundId: string) => {
    setFundTablePageState((prevState) => {
      if (prevState instanceof ScreenResultsLoaded) {
        let watchlistingIds = prevState.watchlistLoadingFundIds.filter(
          (id) => id != newFundId,
        );
        return new ScreenResultsLoaded({
          ...prevState,
          watchlistLoadingFundIds: watchlistingIds,
        });
      }
      return prevState;
    });
  };

  const refreshAlertsTable = (onDone?: () => void) => {
    // setFundTablePageState(new ScreenResultsLoadingInPorgress());
    listAlerts.call({}).then((resp) => {
      match<Failure, Alert[], void>(
        (_: Failure) => {
          setAlertTablePageState(new AlertsPageFailedToLoad());
          if (onDone) onDone();
        },
        (alerts: Alert[]) => {
          setAlertTablePageState((prevState) => {
            if (prevState instanceof AlertsPageLoaded) {
              return new AlertsPageLoaded({
                alerts: alerts,
                alertIdsToggleInProgress: prevState.alertIdsToggleInProgress,
              });
            } else {
              return new AlertsPageLoaded({
                alerts: alerts,
                alertIdsToggleInProgress: [],
              });
            }
          });
          if (onDone) onDone();
        },
      )(resp);
    });
  };

  useEffect(() => {
    refreshAlertsTable();
  }, []);

  // ............................................................................................

  // Fund watchlist State........................................................................

  const [watchlistState, setWatchlistState] = useState<FundScreenState>(
    new ScreenResultsLoadingInPorgress({ checkedFunds }),
  );

  const refreshWatchlistTable = (onDone?: () => void) => {
    // setFundTablePageState(new ScreenResultsLoadingInPorgress());
    listWatchlistUserFunds
      .call({ paginationSpecs: fundPageSpecs, filters: filtersSpecs })
      .then((resp) => {
        match<Failure, Fund[], void>(
          (_: Failure) => {
            setWatchlistState(new ScreenResultsLoadingFailed({ checkedFunds }));
          },
          (funds: Fund[]) => {
            setWatchlistState(new WatchlistLoaded({ funds, checkedFunds }));
            if (onDone) onDone();
          },
        )(resp);
      });
  };

  useEffect(() => {
    refreshWatchlistTable();
  }, []);

  const [actionBeltState, setActionBeltState] =
    useState<MultiSelectActionBeltState>(new MultiSelectActionBeltNotLoaded());

  const [isBulkWatchlistLoading, setIsBulkWatchlistLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (fundTablePageState instanceof ScreenResultsLoaded) {
      const checkedIds = fundTablePageState.checkedFunds.checkedFundIds;
      const nonWatchlistedFundIdsOnPage = fundTablePageState.fundsPage.funds
        .filter((fund) => !fund.isWatchlisted)
        ?.map((fund) => fund.id);
      setActionBeltState(() => {
        if (checkedIds && checkedIds.length > 0) {
          if (
            nonWatchlistedFundIdsOnPage &&
            checkedIds.filter((id) => nonWatchlistedFundIdsOnPage.includes(id))
              .length > 0
          )
            return new MultiSelectActionBeltLoaded({
              bulkWatchlistToggeledOn: false,
            });
          return new MultiSelectActionBeltLoaded({
            bulkWatchlistToggeledOn: true,
          });
        }
        return new MultiSelectActionBeltNotLoaded();
      });
      setIsBulkWatchlistLoading(false);
    }
  }, [checkedFunds, fundTablePageState]);

  // permissions state
  const [verifyPermState, setVerifyPermState] =
    useState<VerifyPermissionState>();

  const getFund = (id: string, onFundRetrieved: (fund: Fund) => void) => {
    retrieveSingleFund.call({ id }).then((resp) => {
      match<Failure, Fund, void>(
        (_: Failure) =>
          enqueueSnackbar(
            "Encountered an error while retrieving fund from server",
            { variant: "error" },
          ),
        (fund: Fund) => onFundRetrieved(fund),
      )(resp);
    });
  };

  const flagApprovedBuyer = (fundId: string, onSuccess: () => void) => {
    // setFundTablePageState(new ScreenResultsLoadingInPorgress());
    flagFundApprovedBuyer.call({ fundId }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_) =>
          enqueueSnackbar(
            "Encountered an error while registering approved buyer",
            { variant: "error" },
          ),
        (_) => onSuccess(),
      )(resp);
    });
  };

  // UI events handler---------------------------------------------------------------------------
  const emitEvent = (event: FundsEvent) => {
    if (
      event instanceof FundsTablePageEvent ||
      event instanceof FundsTableFilterEvent
    ) {
      setCheckedFunds({
        isEntirePageChecked: false,
        checkedFundIds: [],
      });
    }

    if (event instanceof FundsAppBarSelectedTabChanged) {
      setSelectedTab(event.tab);
    } else if (
      event instanceof GeographyFilterChanged ||
      event instanceof StrategyFilterChanged ||
      event instanceof SearchTermChanged ||
      event instanceof FundSizeFilterChanged ||
      event instanceof VintageFilterChanged
    ) {
      setFundPageSpecs((prevPageSpecs) => ({
        ...prevPageSpecs,
        pageIndex: 1,
      }));

      updateFundFiltersAndTableState(event);
    } else if (event instanceof NewPageRequested) {
      updateFundPaginationAndTableState(event);
    } else if (event instanceof PageSizeChanged) {
      setFundPageSpecs((prevPageSpecs) => ({
        perPage: event.spec.perPage,
        pageIndex: 1,
      }));
    } else if (event instanceof AlertActivationToggled) {
      appendAlertToTogglingArray(event.alertId);
      toggleAlertActivation.call({ alertId: event.alertId }).then((resp) => {
        match<Failure, NoReturn, void>(
          (_: Failure) => {
            toast("Failed to toggle alert.");
          },
          async (_: NoReturn) => {
            refreshAlertsTable(() =>
              removeAlertFromTogglingArray(event.alertId),
            );
            toast("Alert toggled successfully.");
          },
        )(resp);
      });
    } else if (event instanceof FundWatchlistToggled) {
      if (fundTablePageState instanceof ScreenResultsLoaded) {
        appendFundToWatchlistingArray(event.fundId);
        const isAlreadyWatchlisted = fundTablePageState.fundsPage.funds.find(
          (fund) => fund.id == event.fundId,
        )!.isWatchlisted;
        fundWatchlistAction
          .call({
            action: isAlreadyWatchlisted ? ActionType.Remove : ActionType.Add,
            fundIds: [event.fundId],
          })
          .then((resp) => {
            match<Failure, NoReturn, void>(
              (_: Failure) => {},
              async (_: NoReturn) => {
                refreshFundsTable();
                refreshWatchlistTable(() =>
                  removeFundToWatchlistingArray(event.fundId),
                );
              },
            )(resp);
          });
      }
    } else if (event instanceof FundWatchlistToggledForCurrentlyChecked) {
      setIsBulkWatchlistLoading(true);
      if (fundTablePageState instanceof ScreenResultsLoaded) {
        const checkedIds = fundTablePageState.checkedFunds.checkedFundIds;
        const fundIds = fundTablePageState.fundsPage.funds
          .filter((fund) =>
            event.actionType == ActionType.Add
              ? !fund.isWatchlisted
              : fund.isWatchlisted,
          )
          .filter((fund) => checkedIds.includes(fund.id))
          ?.map((fund) => fund.id);
        fundWatchlistAction
          .call({
            action: event.actionType,
            fundIds: fundIds,
          })
          .then((resp) => {
            match<Failure, NoReturn, void>(
              (_: Failure) => {},
              async (_: NoReturn) => {
                refreshFundsTable();
                refreshWatchlistTable();
              },
            )(resp);
          });
      }
    } else if (event instanceof SingleFundCheckToggled) {
      let newChecked: CheckedFundsState = {
        ...checkedFunds,
      };
      if (checkedFunds.checkedFundIds.includes(event.fundId)) {
        const newArr = checkedFunds.checkedFundIds.filter(
          (id) => id != event.fundId,
        );
        newChecked.checkedFundIds = newArr;
        if (newArr.length < fundPageSpecs.perPage) {
          newChecked.isEntirePageChecked = false;
        }
      } else {
        const length = newChecked.checkedFundIds.push(event.fundId);
        if (length >= fundPageSpecs.perPage) {
          newChecked.isEntirePageChecked = true;
        }
      }
      setCheckedFunds(newChecked);
    } else if (event instanceof ConfirmCreateAlertButtonClicked) {
      createAlert
        .call({
          form: {
            name: event.name,
            dealSize: event.dealSize,
            fundFilters: filtersSpecs,
          },
        })
        .then((resp) => {
          match<Failure, NoReturn, void>(
            (_: Failure) => {
              toast("Failed to create alert.");
              // TODO: Add metrics/signal to track this failure
            },
            (_: NoReturn) => {
              refreshAlertsTable();
              navigate(LP_ROUTES.FundsAlerts);
              toast("Alert created successfully");
            },
          )(resp);
        });
    } else if (event instanceof EntireCurrentFundsPageCheckToggled) {
      if (checkedFunds.isEntirePageChecked)
        setCheckedFunds({
          isEntirePageChecked: false,
          checkedFundIds: [],
        });
      else if (fundTablePageState instanceof ScreenResultsLoaded)
        setCheckedFunds({
          isEntirePageChecked: true,
          checkedFundIds: fundTablePageState.fundsPage.funds.map(
            (fund) => fund.id,
          ),
        });
    } else if (event instanceof VerifyAccessChoiceSelected) {
      switch (event.choice) {
        case AccessChoice.ApprovedBuyer: {
          // call the approved buyer endpoint
          flagApprovedBuyer(event.fundId, () => {
            // if successful emit close event
            emitEvent(
              new VerifyPermissionFlowClosed({ fundId: event.fundId! }),
            );
          });
          break;
        }
        case AccessChoice.LP: {
          getFund(event.fundId, (fund) => {
            setVerifyPermState(new ShowConfirmHoldingsScreen({ fund }));
          });
          break;
        }
        case AccessChoice.GP: {
          getFund(event.fundId, (fund) => {
            sendMessage({
              blocks: [
                generateSlackMessagePayload({
                  color: "#0000FF",
                  title: "A user just requested GP permission on a fund",
                  context: generateUserFields(user),
                  fields: generateObjectAsFields({
                    "Requested Fund Name": fund.name,
                  }),
                }),
              ],
            });
          });
          setVerifyPermState(new ShowGPAccessRequestReceiptScreen());
          break;
        }
      }
    } else if (event instanceof VerifyPermissionFlowClosed) {
      setVerifyPermState(new ShowAccessSelectionScreen());
      navigate(
        generatePath(LP_ROUTES.FundsFundDetail, { fundId: event.fundId }),
        {
          replace: true,
        },
      );
    }
  };

  return {
    emitEvent,
    fundTablePageState,
    selectedTab,
    filtersSpecs,
    alertTablePageState,
    watchlistState,
    actionBeltState,
    isBulkWatchlistLoading,
    retrieveSingleFund,
    matchNames,
    verifyPermState,
  };
};
