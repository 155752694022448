import { ICurrentUser } from "experiences/authentication/domain/models/CurrentUser";

const slackApiUrl = "https://slack.com/api/chat.postMessage";
const defaultChannel = import.meta.env.VITE_APP_SLACK_EVENT_CHANNEL;
const token = import.meta.env.VITE_APP_SLACK_TOKEN;

// Send a slack message
// By default, all messages are going to
// #app-events https://usetap.slack.com/archives/C03LULFTH5F
// but test events should show up at
// #test-events https://usetap.slack.com/archives/C03L6U7HMQD

// See more at
// https://api.slack.com/methods/chat.postMessage

export enum PayloadTextType {
  Text = "plain_text",
  Markdown = "mrkdwn",
}

export interface IPayload {
  type: PayloadTextType;
  text: string;
}

export const generateSlackMessagePayload = ({
  title,
  fields,
  context,
  color,
}: {
  title?: string;
  fields?: IPayload[];
  context?: IPayload[];
  color?: string;
}) => {
  return {
    color,
    blocks: [
      {
        type: "section",
        text: {
          type: PayloadTextType.Text,
          text: title,
        },
      },
      ...(fields
        ? [
            {
              type: "section",
              fields: fields,
            },
          ]
        : []),
      ...(context
        ? [
            {
              type: "context",
              elements: context,
            },
          ]
        : []),
    ],
  };
};

export const generateObjectAsFields: (obj: any) => IPayload[] = (obj) => {
  return [
    ...Object.keys(obj).map((key) => {
      return {
        type: PayloadTextType.Markdown,
        text: `${toTitleCase(key)}: *${obj[key]}*`,
      };
    }),
  ];
};

const camelToFlat = (camel: string) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  let flat = "";

  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};

const toTitleCase = (str: string) => {
  str = camelToFlat(str);
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const generateUserFields = (user?: ICurrentUser) => {
  if (!user) return [];
  return [
    {
      type: PayloadTextType.Markdown,
      text: `User: *${user?.firstName} ${user?.lastName}*`,
    },
    {
      type: PayloadTextType.Markdown,
      text: `Email: ${user?.email}`,
    },
    {
      type: PayloadTextType.Markdown,
      text: `Phone: ${user?.phone}`,
    },
  ];
};

export const useSendSlackMessage = ({
  channel = defaultChannel,
}: {
  channel?: string;
} = {}) => {
  const sendMessage = async ({
    message,
    blocks,
  }: {
    message?: string;
    blocks?: any[];
  }) => {
    const query = new URLSearchParams();
    query.append("channel", channel || "");
    query.append("token", token || "");
    if (message) {
      query.append("text", JSON.stringify(message));
    }
    if (blocks) {
      query.append("attachments", JSON.stringify(blocks));
    }

    await fetch(slackApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: query.toString(),
    });
  };

  return { sendMessage };
};
