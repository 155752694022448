import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";

// Pages
import { TransactionHolding } from "../components/fund-data/verify-permission/useTransactionHoldings";
import { GetTransactionHoldingsQuery } from "experiences/transactions/domain/usecases/GetTransactionHoldingsQuery";
import { transactionsClient } from "common/clients/ApolloClient";
import {
  ITransactionFundDataReportDate,
  useFundDataReportDate,
} from "../components/fund-data/useFundDataReportDate";
import { IHoldingSelectorOption } from "../components/fund-data/useFundHoldings";

const getHoldingsOptions = (
  holdings: TransactionHolding[],
  selectedHoldingsIds: string[],
) => {
  return holdings.map((holding) => ({
    id: holding.holding.id,
    label: holding.issuerName,
    enabled: selectedHoldingsIds.includes(holding.holding.id),
  }));
};

export const TransactionFundDataContext = createContext<{
  loading: boolean;
  holdings: TransactionHolding[];
  selectedHoldingsIds: string[];
  onHoldingSelect: (holdingId: string) => void;
  onHoldingRemove: (holdingId: string) => void;
  holdingsOptions: IHoldingSelectorOption[];
  reportDates: ITransactionFundDataReportDate["reportDates"];
}>({
  loading: false,
  holdings: [],
  selectedHoldingsIds: [],
  onHoldingSelect: () => {},
  onHoldingRemove: () => {},
  holdingsOptions: [],
  reportDates: {
    dealBreakdown: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
    exposureSummary: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
    soiLookthrough: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
    capitalAccounts: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
    qualitativeData: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
    companyLoan: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
    interimCashflows: {
      options: [],
      currentDate: "",
      onReportDateChange: () => {},
    },
  },
});

export const TransactionFundDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { txnId } = useParams();
  const { user } = useAuthContext();

  const { reportDates, loading: reportDatesLoading } = useFundDataReportDate();

  const [holdings, setHoldings] = useState<TransactionHolding[]>([]);
  const [selectedHoldingsIds, setSelectedHoldingsIds] = useState<string[]>([]);

  const { data, loading: holdingsLoading } = useQuery(
    GetTransactionHoldingsQuery,
    {
      variables: { transactionId: txnId },
      client: transactionsClient,
      skip: !txnId,
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (data) {
      setHoldings(data.holdings);
      setSelectedHoldingsIds(
        data.holdings.map((holding) => holding.holding.id),
      );
    }
  }, [data]);

  const onHoldingSelect = (holdingId: string) => {
    setSelectedHoldingsIds([...selectedHoldingsIds, holdingId]);
  };

  const onHoldingRemove = (holdingId: string) => {
    setSelectedHoldingsIds(
      selectedHoldingsIds.filter((id) => id !== holdingId),
    );
  };

  const holdingsOptions = getHoldingsOptions(holdings, selectedHoldingsIds);

  return (
    <TransactionFundDataContext.Provider
      value={{
        holdings,
        selectedHoldingsIds,
        onHoldingSelect,
        onHoldingRemove,
        holdingsOptions,
        reportDates,
        loading: holdingsLoading || reportDatesLoading,
      }}
    >
      {children}
    </TransactionFundDataContext.Provider>
  );
};
