import { useState } from "react";
import { match } from "fp-ts/lib/Either";

import {
  ApprovedBuyersState,
  ApprovedBuyersLoaded,
  RequestedBuyersLoaded,
  BlockedBuyersLoaded,
  ArchivedBuyersLoaded,
} from "./ApprovedBuyersState";
import {
  ApprovedBuyersEvent,
  LoadApprovedBuyers,
  LoadArchivedBuyers,
  LoadBlockedBuyers,
  LoadRequestedBuyers,
} from "./ApprovedBuyerEvent";
import { GetApprovedBuyers } from "../../domain/usecases/GetApprovedBuyers";
import { ApprovedBuyer } from "experiences/approved-buyers/domain/models/ApprovedBuyer";
import { Failure } from "common/@types/app/Failure";
import { GetRequestedBuyers } from "experiences/approved-buyers/domain/usecases/GetRequestedBuyers";
import { RequestedBuyer } from "experiences/approved-buyers/domain/models/RequestedBuyer";
import { GetBlockedBuyers } from "experiences/approved-buyers/domain/usecases/GetBlockedBuyers";
import { BlockedBuyer } from "experiences/approved-buyers/domain/models/BlockedBuyer";
import { GetArchivedBuyers } from "experiences/approved-buyers/domain/usecases/GetArchivedBuyers";
import { ArchivedBuyer } from "experiences/approved-buyers/domain/models/ArchivedBuyer";

export interface IUseCases {
  getApprovedBuyers: GetApprovedBuyers;
  getRequestedBuyers: GetRequestedBuyers;
  getBlockedBuyers: GetBlockedBuyers;
  getArchivedBuyers: GetArchivedBuyers;
}

export const useManageApprovedBuyersState = ({
  getApprovedBuyers,
  getRequestedBuyers,
  getBlockedBuyers,
  getArchivedBuyers,
}: IUseCases) => {
  const [approvedBuyerTablePageState, setApprovedBuyerTablePageState] =
    useState<ApprovedBuyersState>();
  const [requestedBuyerTablePageState, setRequestedBuyerTablePageState] =
    useState<ApprovedBuyersState>();
  const [blockedBuyerTablePageState, setBlockedBuyerTablePageState] =
    useState<ApprovedBuyersState>();
  const [archivedBuyerTablePageState, setArchivedBuyerTablePageState] =
    useState<ApprovedBuyersState>();

  let prevEvent: ApprovedBuyersEvent;
  const emitEvent = (event: ApprovedBuyersEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (event instanceof LoadApprovedBuyers) {
      getApprovedBuyers.call({}).then((resp) => {
        match<Failure, ApprovedBuyer[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (approvedBuyers: ApprovedBuyer[]) => {
            setApprovedBuyerTablePageState(
              new ApprovedBuyersLoaded({
                approvedBuyers,
              }),
            );
          },
        )(resp);
      });
    } else if (event instanceof LoadRequestedBuyers) {
      getRequestedBuyers.call({}).then((resp) => {
        match<Failure, RequestedBuyer[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (requestedBuyers: RequestedBuyer[]) => {
            setRequestedBuyerTablePageState(
              new RequestedBuyersLoaded({
                requestedBuyers,
              }),
            );
          },
        )(resp);
      });
    } else if (event instanceof LoadBlockedBuyers) {
      getBlockedBuyers.call({}).then((resp) => {
        match<Failure, BlockedBuyer[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (blockedBuyers: BlockedBuyer[]) => {
            setBlockedBuyerTablePageState(
              new BlockedBuyersLoaded({
                blockedBuyers,
              }),
            );
          },
        )(resp);
      });
    } else if (event instanceof LoadArchivedBuyers) {
      getArchivedBuyers.call({}).then((resp) => {
        match<Failure, ArchivedBuyer[], void>(
          (_: Failure) => {
            console.log("error");
          },
          (archivedBuyers: ArchivedBuyer[]) => {
            setArchivedBuyerTablePageState(
              new ArchivedBuyersLoaded({
                archivedBuyers,
              }),
            );
          },
        )(resp);
      });
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    approvedBuyerTablePageState,
    requestedBuyerTablePageState,
    blockedBuyerTablePageState,
    archivedBuyerTablePageState,
  };
};
