import { InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { usePrevious } from "@uidotdev/usehooks";
import classNames from "classnames";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { Percentage } from "common/@types/app/Percentage";
import { PageHead } from "common/components/PageHead";
import TapLinearProgress from "common/components/TapLinearBar";
import TextField from "common/components/TextField";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { Range } from "experiences/common/models/Range";

interface ITenderSimulatorProps {
  tenderAmount: number;
  setTenderAmount: React.Dispatch<React.SetStateAction<number>>;
  initialReservePrice: number;
  allowReservePriceChange?: boolean;
  setReservePrice: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  description: string;
  interimCapitalCalls: number;
  interimDistributions: number;
  commitedBefore: number;
  unfundedBefore: number;
  cavBefore: number;
}

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiInputBase-input": {
      paddingTop: 8,
    },
    "& .MuiInputAdornment-root": {
      marginTop: "4px !important",
    },
  },
}));

const TenderSimulator: React.FC<ITenderSimulatorProps> = ({
  tenderAmount,
  setTenderAmount,
  allowReservePriceChange = false,
  initialReservePrice,
  setReservePrice,
  title,
  description,
  interimCapitalCalls,
  interimDistributions,
  commitedBefore,
  unfundedBefore,
  cavBefore,
}) => {
  // math
  const percentTendered = () => tenderAmount / cavBefore;
  const percentKept = () => 1 - percentTendered();
  const commitedAfter = () => commitedBefore * percentKept();
  const unfundedAfter = () =>
    unfundedBefore * percentKept() - interimCapitalCalls * percentKept();
  const cavAfter = () =>
    cavBefore * percentKept() +
    (interimCapitalCalls - interimDistributions) * percentKept();
  const pfCAVTendered = () => cavBefore * percentTendered();
  const pfOfferAmount = () => pfCAVTendered() * initialReservePrice;
  const pfInterimCapitalCalls = () => interimCapitalCalls * percentTendered();
  const pfInterimDistributions = () => interimDistributions * percentTendered();
  const pfCashProceeds = () =>
    pfOfferAmount() + pfInterimCapitalCalls() - pfInterimDistributions();
  const pfCommitmentRelease = () =>
    unfundedBefore * percentTendered() - pfInterimCapitalCalls();
  const pfLiquidityUnlocked = () => pfCashProceeds() + pfCommitmentRelease();

  // chart parameters
  const chartRanges = () => {
    return {
      cavTenderedRange: {
        start: 0,
        end: pfCAVTendered(),
      },
      offerAmountRange: {
        start: pfOfferAmount(),
        end: pfCAVTendered(),
      },
      capitalCallsRange: {
        start: pfOfferAmount(),
        end: pfOfferAmount() + interimCapitalCalls,
      },
      distributionsRange: {
        start: pfCashProceeds(),
        end: pfOfferAmount() + interimCapitalCalls,
      },
      cashProceedsRange: {
        start: 0,
        end: pfCashProceeds(),
      },
      commitmentReleaseRange: {
        start: pfCashProceeds(),
        end: pfLiquidityUnlocked(),
      },
      liquidityUnlockedRange: {
        start: 0,
        end: pfLiquidityUnlocked(),
      },
    };
  };

  const classes = useStyles();
  return (
    <>
      <div className="flex flex-col w-3/6 p-4 gap-12">
        <div>
          <PageHead paddingless title={title} description={description} />
        </div>
        <div className="flex flex-col gap-4">
          <InputLabel shrink={false} sx={{ marginBottom: 1 }}>
            <span className="text-black font-bold">Tender Amount</span>
          </InputLabel>
          <TextField
            placeholder="Ex: $20,000,000"
            fullWidth
            value={new DollarAmount(tenderAmount).formatted()}
            onChange={(e) => {
              if (e.target.value === "") {
                setTenderAmount(0);
                return;
              }
              setTenderAmount(parseInt(e.target.value.replace(/,/g, "")));
            }}
            mask="currency"
            className={classes.input}
          />
          <div className="flex flex-col gap-2">
            <TapLinearProgress
              variant="determinate"
              value={(tenderAmount / cavBefore) * 100}
            />
            <span className="text-stone-500">
              {new Percentage((tenderAmount / cavBefore) * 100).formatted(0)} of
              your Capital Account Value
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-black font-bold">
            Capital Account Preview (Q4 2023)
          </span>
          <div className="flex flex-row-reverse p-3">
            <div className="w-1/2 flex flex-row justify-between">
              <span className="px-6 py-2 border-b-2 border-nomad-400">
                Before
              </span>
              <span className="px-6 py-2 border-b-2 border-nomad-400">
                After
              </span>
            </div>
          </div>
          <div className="flex flex-col divide-y divide-solid">
            <BeforeAftertableLineItem
              before={commitedBefore}
              after={commitedAfter()}
              label="Commitment"
            />
            <BeforeAftertableLineItem
              before={unfundedBefore}
              after={unfundedAfter()}
              label="Unfunded"
            />
            <BeforeAftertableLineItem
              before={cavBefore}
              after={cavAfter()}
              label="Capital Account Value"
            />
          </div>
        </div>
        {allowReservePriceChange && (
          <div>
            <ReservePriceSection setReservePrice={setReservePrice} />
          </div>
        )}
        <div className="flex flex-col gap-4">
          <span className="text-black font-bold">Proceeds Preview</span>
          <div className="inline-block">
            <BarChart ranges={chartRanges()} cavBefore={cavBefore} />
          </div>
        </div>
        <div>
          <BreakDownTable
            cavTendered={pfCAVTendered()}
            offerAmount={pfOfferAmount()}
            pfCapitalCalls={pfInterimCapitalCalls()}
            pfDistributions={pfInterimDistributions()}
            pfCashProceeds={pfCashProceeds()}
            pfCommitmentRelease={pfCommitmentRelease()}
            pfLiquidityUnlocked={pfLiquidityUnlocked()}
          />
        </div>
      </div>
    </>
  );
};

export default TenderSimulator;

interface IReservePriceSectionProps {
  setReservePrice: React.Dispatch<React.SetStateAction<number>>;
}

const ReservePriceSection: React.FC<IReservePriceSectionProps> = ({
  setReservePrice,
}: IReservePriceSectionProps) => {
  const classes = useStyles();

  return (
    <div className="flex flex-col">
      <InputLabel shrink={false} sx={{ marginBottom: 1 }}>
        <div className="flex gap-1">
          <span className="text-black font-bold">Reserve Price</span>
          <span className="text-black font-thin">(Optional)</span>
        </div>
      </InputLabel>
      <TextField
        placeholder="Ex: 90%"
        fullWidth
        // initialValue={parseFloat(reservePrice * 100).toString()}
        // value={parseFloat(reservePrice * 100)}
        onChange={(e) => {
          if (e.target.value === "" || parseFloat(e.target.value) == 0) {
            setReservePrice(1);
            return;
          }
          setReservePrice(parseFloat(e.target.value) / 100);
        }}
        mask="percentage"
        className={classes.input}
      />
      <p className="text-stone-400 pt-2">
        Indicated as percentage of par price of NAV at the reference date of the
        transaction. This price is not disclosed to buyers at any point and is
        completely optional.
      </p>
    </div>
  );
};

interface IChartRanges {
  cavTenderedRange: Range<number>;
  offerAmountRange: Range<number>;
  capitalCallsRange: Range<number>;
  distributionsRange: Range<number>;
  cashProceedsRange: Range<number>;
  commitmentReleaseRange: Range<number>;
  liquidityUnlockedRange: Range<number>;
}
interface IBarChartProps {
  cavBefore: number;
  ranges: IChartRanges;
}

export const BarChart: React.FC<IBarChartProps> = ({ ranges, cavBefore }) => {
  const chartTop = 266;
  const ChartBottom = 34;

  const scale = (range: Range<number>) => {
    return {
      start: (range.start * chartTop) / 75_000_000 + ChartBottom,
      end: (range.end * chartTop) / 75_000_000 + ChartBottom,
    };
  };

  const yDelta = (range: Range<number>) => {
    return range.end - range.start;
  };

  const cav100 = new DollarAmount(cavBefore).formattedBig();
  const cav80 = new DollarAmount(cavBefore * 0.8).formattedBig();
  const cav60 = new DollarAmount(cavBefore * 0.6).formattedBig();
  const cav40 = new DollarAmount(cavBefore * 0.4).formattedBig();
  const cav20 = new DollarAmount(cavBefore * 0.2).formattedBig();

  const {
    cavTenderedRange,
    offerAmountRange,
    capitalCallsRange,
    distributionsRange,
    cashProceedsRange,
    commitmentReleaseRange,
    liquidityUnlockedRange,
  } = ranges;

  const fromRanges = usePrevious(ranges);
  const {
    cavTenderedRange: fromCavTenderedRange,
    offerAmountRange: fromOfferAmountRange,
    capitalCallsRange: fromCapitalCallsRange,
    distributionsRange: fromDistributionsRange,
    cashProceedsRange: fromCashProceedsRange,
    commitmentReleaseRange: fromCommitmentReleaseRange,
    liquidityUnlockedRange: fromLiquidityUnlockedRange,
  } = fromRanges || ranges;
  return (
    <svg
      key={
        offerAmountRange.end.toString() + yDelta(offerAmountRange).toString()
      }
      width="100%"
      height="100%"
      viewBox="0 0 422 305"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text y="9" font-size=".6em" fill="#737476">
        {cav100}
      </text>
      <text y="61" font-size=".6em" fill="#737476">
        {cav80}
      </text>
      <text y="115" font-size=".6em" fill="#737476">
        {cav60}
      </text>
      <text y="167" font-size=".6em" fill="#737476">
        {cav40}
      </text>
      <text y="220" font-size=".6em" fill="#737476">
        {cav20}
      </text>
      <text y="274" font-size=".6em" fill="#737476">
        0
      </text>
      <text y="290" x="52" width="20" font-size=".6em" fill="#737476">
        CAV
      </text>
      <text y="299" x="43" width="20" font-size=".6em" fill="#737476">
        Tendered
      </text>
      <text y="290" x="105" width="20" font-size=".6em" fill="#737476">
        Offer
      </text>
      <text y="299" x="100" width="20" font-size=".6em" fill="#737476">
        Amount
      </text>
      <text y="290" x="148" width="20" font-size=".6em" fill="#737476">
        Interim
      </text>
      <text y="299" x="152" width="20" font-size=".6em" fill="#737476">
        Calls
      </text>
      <text y="290" x="200" width="20" font-size=".6em" fill="#737476">
        Interim
      </text>
      <text y="299" x="192" width="20" font-size=".6em" fill="#737476">
        Distributions
      </text>
      <text y="290" x="262" width="20" font-size=".6em" fill="#737476">
        Cash
      </text>
      <text y="299" x="255" width="20" font-size=".6em" fill="#737476">
        Proceeds
      </text>
      <text y="290" x="300" width="20" font-size=".6em" fill="#737476">
        Commitment
      </text>
      <text y="299" x="310" width="20" font-size=".6em" fill="#737476">
        Release
      </text>
      <text y="290" x="360" width="20" font-size=".6em" fill="#737476">
        Liquidity
      </text>
      <text y="299" x="358" width="20" font-size=".6em" fill="#737476">
        Unlocked
      </text>
      // number lines
      <rect
        x="23"
        y="5.25"
        width="399"
        height="0.5"
        fill="#DFDFD9"
        fill-opacity="0.8"
      />
      <rect
        x="17"
        y="58.25"
        width="405"
        height="0.5"
        fill="#DFDFD9"
        fill-opacity="0.8"
      />
      <rect
        x="17"
        y="111.25"
        width="405"
        height="0.5"
        fill="#DFDFD9"
        fill-opacity="0.8"
      />
      <rect
        x="16"
        y="164.25"
        width="406"
        height="0.5"
        fill="#DFDFD9"
        fill-opacity="0.8"
      />
      <rect
        x="15"
        y="217.25"
        width="407"
        height="0.5"
        fill="#DFDFD9"
        fill-opacity="0.8"
      />
      <rect
        x="15"
        y="271"
        width="407"
        height="0.5"
        fill="#DFDFD9"
        fill-opacity="0.8"
      />
      // actual views
      <g transform="scale(1,-1) translate(0,-305)">
        <rect
          x="38.2917"
          y={`${scale(cavTenderedRange).start}`}
          width="45"
          fill="#BCB3A5"
        >
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromCavTenderedRange))}`}
            to={`${yDelta(scale(cavTenderedRange))}`}
            dur="0.5s"
            fill="freeze"
          />
        </rect>
        <rect x="90.9424" width="45" fill="#737476">
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromOfferAmountRange))}`}
            to={`${yDelta(scale(offerAmountRange))}`}
            dur="0.5s"
            fill="freeze"
          />
          <animate
            attributeName="y"
            from={`${scale(fromOfferAmountRange).start}`}
            to={`${scale(offerAmountRange).start}`}
            dur="0.2s"
            fill="freeze"
          />
        </rect>
        <rect x="144" width="45" fill="#737476">
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromCapitalCallsRange))}`}
            to={`${yDelta(scale(capitalCallsRange))}`}
            dur="0.5s"
            fill="freeze"
          />
          <animate
            attributeName="y"
            from={`${scale(fromCapitalCallsRange).start}`}
            to={`${scale(capitalCallsRange).start}`}
            dur="0.2s"
            fill="freeze"
          />
        </rect>
        <rect x="194" width="45" height="0" fill="#737476">
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromDistributionsRange))}`}
            to={`${yDelta(scale(distributionsRange))}`}
            dur="0.5s"
            fill="freeze"
          />
          <animate
            attributeName="y"
            from={`${scale(fromDistributionsRange).start}`}
            to={`${scale(distributionsRange).start}`}
            dur="0.2s"
            fill="freeze"
          />
        </rect>
        <rect
          x="249"
          y={`${scale(cashProceedsRange).start}`}
          width="45"
          fill="#BCB3A5"
        >
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromCashProceedsRange))}`}
            to={`${yDelta(scale(cashProceedsRange))}`}
            dur="0.5s"
            fill="freeze"
          />
        </rect>
        <rect x="302" width="45" fill="#737476">
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromCommitmentReleaseRange))}`}
            to={`${yDelta(scale(commitmentReleaseRange))}`}
            dur="0.5s"
            fill="freeze"
          />
          <animate
            attributeName="y"
            from={`${scale(fromCommitmentReleaseRange).start}`}
            to={`${scale(commitmentReleaseRange).start}`}
            dur="0.2s"
            fill="freeze"
          />
        </rect>
        <rect
          x="354"
          y={`${scale(liquidityUnlockedRange).start}`}
          width="45"
          fill="#BCB3A5"
        >
          <animate
            attributeName="height"
            from={`${yDelta(scale(fromLiquidityUnlockedRange))}`}
            to={`${yDelta(scale(liquidityUnlockedRange))}`}
            dur="0.5s"
            fill="freeze"
          />
        </rect>
      </g>
    </svg>
  );
};

interface IBreakDownTableItemProps {
  isSubItem?: boolean;
  isTopItem?: boolean;
  isBottomItem?: boolean;
  label: string;
  dollarValue: number;
  percentValue: number;
}

const BreakDownTableLineItem: React.FC<IBreakDownTableItemProps> = ({
  isSubItem = false,
  isTopItem = false,
  isBottomItem = false,
  label,
  dollarValue,
  percentValue,
}) => {
  return (
    <div
      className={classNames("flex flex-row bg-stone-100 px-6 py-3", {
        "text-stone-600": !isTopItem && !isBottomItem,
        "rounded-t-lg text-black font-bold pt-6": isTopItem,
        "rounded-b-lg text-black font-bold pb-6": isBottomItem,
      })}
    >
      <div
        className={classNames("w-2/3 justify-between", {
          "pl-2": isSubItem,
        })}
      >
        {label}
      </div>
      <div className="w-1/3 flex flex-row justify-between pl-2">
        <span>{new DollarAmount(dollarValue).formatted()}</span>
        <span>
          {isTopItem
            ? ""
            : isNaN(percentValue)
              ? NOT_AVAILABLE_STR
              : new Percentage(percentValue).formatted(1)}
        </span>
      </div>
    </div>
  );
};

interface IBreakDownTableProps {
  cavTendered: number;
  offerAmount: number;
  pfCapitalCalls: number;
  pfDistributions: number;
  pfCashProceeds: number;
  pfCommitmentRelease: number;
  pfLiquidityUnlocked: number;
}
const BreakDownTable: React.FC<IBreakDownTableProps> = ({
  cavTendered,
  offerAmount,
  pfCapitalCalls,
  pfDistributions,
  pfCashProceeds,
  pfCommitmentRelease,
  pfLiquidityUnlocked,
}) => {
  return (
    <div className="flex flex-col divide-y divide-solid divide-stone-300">
      <BreakDownTableLineItem
        isTopItem
        label="Capital Account Value Tendered"
        dollarValue={cavTendered}
        percentValue={0}
      />
      <BreakDownTableLineItem
        label="Offer Amount"
        dollarValue={offerAmount}
        percentValue={(offerAmount / cavTendered) * 100}
      />
      <BreakDownTableLineItem
        isSubItem
        label="Interim Capital Calls"
        dollarValue={pfCapitalCalls}
        percentValue={(pfCapitalCalls / cavTendered) * 100}
      />
      <BreakDownTableLineItem
        isSubItem
        label="Interim Distributions"
        dollarValue={pfDistributions}
        percentValue={(pfDistributions / cavTendered) * 100}
      />
      <BreakDownTableLineItem
        label="Cash Proceeds"
        dollarValue={pfCashProceeds}
        percentValue={(pfCashProceeds / cavTendered) * 100}
      />
      <BreakDownTableLineItem
        isSubItem
        label="Commitment Release"
        dollarValue={pfCommitmentRelease}
        percentValue={(pfCommitmentRelease / cavTendered) * 100}
      />
      <BreakDownTableLineItem
        isBottomItem
        label="Liquidity Unlocked"
        dollarValue={pfLiquidityUnlocked}
        percentValue={(pfLiquidityUnlocked / cavTendered) * 100}
      />
    </div>
  );
};

interface IBeforeAftertableLineItemProps {
  before: number;
  after: number;
  label: string;
}

export const BeforeAftertableLineItem: React.FC<
  IBeforeAftertableLineItemProps
> = ({ before, after, label }) => {
  return (
    <div className="flex flex-row p-3">
      <div className="w-1/2 text-stone-500">{label}</div>
      <div className="w-1/2 flex flex-row justify-between">
        <span>{new DollarAmount(before).formatted()}</span>
        <span>{new DollarAmount(after).formatted()}</span>
      </div>
    </div>
  );
};
