import { IWorksheetColumn } from "experiences/common/excel/model";
import {
  DEFAULT_WORKSHEET_COLUMN_WIDTH,
  WORKSHEET_COLUMN_WIDTH_LARGE,
} from "experiences/common/excel/utils";

export enum PerformanceKeys {
  FundsCount = "funds_count",
  PortfolioPercent = "portfolio_percent",
  Nav = "nav",
  GrossTvpi = "gross_tvpi",
  GrossDpi = "gross_dpi",
  GrossMoic = "gross_moic",
}

export interface IFundExposureSummary {
  [PerformanceKeys.FundsCount]: string;
  [PerformanceKeys.PortfolioPercent]: string;
  [PerformanceKeys.Nav]: string;
  [PerformanceKeys.GrossTvpi]: string;
  [PerformanceKeys.GrossDpi]: string;
  [PerformanceKeys.GrossMoic]: string;
}

export const fundExposureSummaryKeyLabels: Record<
  keyof IFundExposureSummary,
  string
> = {
  [PerformanceKeys.FundsCount]: "Funds Count",
  [PerformanceKeys.PortfolioPercent]: "Portfolio Percent",
  [PerformanceKeys.Nav]: "Nav",
  [PerformanceKeys.GrossTvpi]: "Gross Tvpi",
  [PerformanceKeys.GrossDpi]: "Gross Dpi",
  [PerformanceKeys.GrossMoic]: "Gross Moic",
};

export enum PerformanceTabs {
  PortfolioPerformance = "Portfolio Performance",
}

// prettier-ignore
export const performanceColumns: IWorksheetColumn[] = [
  { key: PerformanceKeys.FundsCount, header: fundExposureSummaryKeyLabels[PerformanceKeys.FundsCount], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: PerformanceKeys.PortfolioPercent, header: fundExposureSummaryKeyLabels[PerformanceKeys.PortfolioPercent], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: PerformanceKeys.Nav, header: fundExposureSummaryKeyLabels[PerformanceKeys.Nav], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: PerformanceKeys.GrossTvpi, header: fundExposureSummaryKeyLabels[PerformanceKeys.GrossTvpi], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: PerformanceKeys.GrossDpi, header: fundExposureSummaryKeyLabels[PerformanceKeys.GrossDpi], width: WORKSHEET_COLUMN_WIDTH_LARGE },
  { key: PerformanceKeys.GrossMoic, header: fundExposureSummaryKeyLabels[PerformanceKeys.GrossMoic], width: WORKSHEET_COLUMN_WIDTH_LARGE },
];
