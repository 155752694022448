import { useNavigate } from "react-router";
import { useAuthContext } from "../state/AuthenticationContext";

import { Helmet } from "react-helmet";
import { BUTTON_TYPES, Button } from "common/components/Button";

import { RegisterButtonClickedOnLoginFlow } from "../state/AuthenticationEvent";
import { UNIVERSAL_ROUTES } from "common/constants/routes";

import Logo from "../../../../common/components/Logo";

export const AuthAppBar = ({
  title,
  showLoginButton,
  showSignUpButton,
  children,
}: {
  title?: string;
  showLoginButton?: boolean;
  showSignUpButton?: boolean;
  children?: React.ReactNode;
}) => {
  const { emitEvent } = useAuthContext();
  const navigate = useNavigate();

  const loginButton = (
    <div className="flex items-center gap-4 text-sm font-medium text-zinc-600">
      Already have an account?
      <Button
        onClick={() => navigate(UNIVERSAL_ROUTES.AuthLogin)}
        type={BUTTON_TYPES.SECONDARY}
      >
        Login
      </Button>
    </div>
  );

  const signUpButton = (
    <div className="flex items-center gap-4 text-sm font-medium text-zinc-600">
      Don't have an account?
      <Button
        onClick={() => {
          emitEvent!(new RegisterButtonClickedOnLoginFlow());
          navigate(UNIVERSAL_ROUTES.SignUp);
        }}
        type={BUTTON_TYPES.SECONDARY}
      >
        Register
      </Button>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between items-center p-4 sticky top-0 border-b cursor-default select-none text-sm font-medium text-zinc-600 gap-4">
        <Logo width={68} height={34} />
        {title && <Helmet title={title} />}
        {showLoginButton && loginButton}
        {showSignUpButton && signUpButton}
        {children}
      </div>
    </div>
  );
};
