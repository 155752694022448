import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { Fund } from "../models/Fund";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import { FundGeography } from "../../../common/models/FundGeography";
import { FundStrategy } from "../../../common/models/FundStrategy";

interface IResp {
  id: string;
  name: string;
  managerName: string;
  geography: FundGeography;
  strategy: FundStrategy;
  vintage: number;
  size: number;
  isWatchlisted: boolean;
}

export class ListWatchlistUserFunds implements IAsyncUseCase<IParams, Fund[]> {
  call: (params: IParams) => Promise<Either<Failure, Fund[]>> = async ({}) => {
    return await new TapClient(`watchlist/funds/list/`)
      .get({})
      .then((resp: IResp[]) => {
        return right(
          resp.map((item) => {
            return {
              ...item,
              size: new DollarAmount(item.size),
            };
          }),
        );
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {}
