import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { ParentSize } from "@visx/responsive";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear } from "@visx/scale";
import { GridRows } from "@visx/grid";
import { useTooltipInPortal } from "@visx/tooltip";

import { DollarAmount } from "common/@types/app/DollarAmount";
import { cn } from "common/utils";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { BarLoader } from "common/components/BarLoader";

import { LiquidityTimeline, LiquidityTimelineLabels } from "./model";

type BarGroupProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const leftAxisWidth = 70;
const MAX_BAR_WIDTH = 120;

const VALUE_KEY = "value";

const liquidityTimelineToColor = {
  [LiquidityTimeline.Planned]: "oklch(50% 0.0671 138.13)",
  [LiquidityTimeline.ActivelyExploring]: "oklch(70% 0.0671 138.13)",
  [LiquidityTimeline.NoMention]: "oklch(90% 0 140)",
};

const liquidityProjectionOrder = [
  LiquidityTimeline.Planned,
  LiquidityTimeline.ActivelyExploring,
  LiquidityTimeline.NoMention,
];

export interface ILiquidityProjectionTimeline {
  name: LiquidityTimeline;
  [VALUE_KEY]: number;
}

const LiquidityProjectionChart = ({
  width,
  height,
  margin = { top: 16, right: 0, bottom: 40, left: 0 + leftAxisWidth },
  style,
  chart,
}: BarGroupProps & {
  style?: React.CSSProperties;
  chart: ILiquidityProjectionTimeline[];
}) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const groupedData = useMemo(() => {
    return chart.reduce((acc, current) => {
      const existingGroup = acc.find((group) => group.name === current.name);
      if (existingGroup) {
        existingGroup.value += current.value;
      } else {
        acc.push({ name: current.name, value: current.value });
      }
      return acc;
    }, []);
  }, [chart]);

  const highestValue = useMemo(() => {
    return groupedData.reduce((max, current) => {
      return current.value > max ? current.value : max;
    }, 0);
  }, [groupedData]);

  const xScale = useMemo(() => {
    return scaleBand<string>({
      domain: groupedData
        .map((d) => `${d.name}`)
        .sort(
          (a, b) =>
            liquidityProjectionOrder.indexOf(a as LiquidityTimeline) -
            liquidityProjectionOrder.indexOf(b as LiquidityTimeline),
        ),
      range: [0, xMax],
      padding: 0.2,
    });
  }, [groupedData, width]);

  const yScale = useMemo(() => {
    return scaleLinear({
      domain: [0, highestValue],
      range: [yMax, 0],
    });
  }, [highestValue, yMax, width, height, chart]);

  // this looks unused but removing it breaks the chart
  // it's because of how d3 works internally, requires calling quarterScale.rangeRound
  useMemo(() => {
    xScale.rangeRound([0, xMax]);
  }, []);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  if (width === 0) {
    return null;
  }

  return (
    <div style={style} className="w-full">
      <div style={{ position: "relative" }} className="w-full">
        <svg width={width} height={height} ref={containerRef}>
          <Group top={margin.top} left={margin.left}>
            <AxisLeft
              scale={yScale}
              hideAxisLine
              hideTicks
              numTicks={6}
              tickFormat={(value) =>
                new DollarAmount(value.valueOf()).formattedBig()
              }
              tickLabelProps={{
                className: "select-none fill-zinc-900",
              }}
            />
            <GridRows
              left={0}
              scale={yScale}
              width={innerWidth}
              stroke="rgba(223, 223, 217, 0.80)"
              pointerEvents="none"
            />

            {groupedData.map((d, i) => {
              const bandWidth = xScale.bandwidth();
              const barWidth =
                bandWidth > MAX_BAR_WIDTH ? MAX_BAR_WIDTH : bandWidth;
              const barHeight = yMax - (yScale(d.value) ?? 0);
              const barX = xScale(d.name);
              const barY = yMax - barHeight;

              return (
                <Bar
                  key={`bar-${i}`}
                  x={barX + bandWidth / 2 - barWidth / 2}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={liquidityTimelineToColor[d.name]}
                />
              );
            })}
          </Group>
          <AxisBottom
            top={yMax + margin.top}
            left={margin.left}
            scale={xScale}
            hideTicks
            hideAxisLine
            tickFormat={(value: LiquidityTimeline) => {
              return LiquidityTimelineLabels[value];
            }}
            tickLabelProps={{
              className: "select-none fill-zinc-900",
            }}
          />
        </svg>
      </div>
    </div>
  );
};

export const LiquidityProjectionSection = ({
  chart = [],
  loading,
}: {
  chart: {
    name: LiquidityTimeline;
    value: number;
  }[];
  loading: boolean;
}) => {
  return (
    <div className={cn("relative", {})}>
      <PageSectionTitleDivider showBorderTop>
        Liquidity Projection
      </PageSectionTitleDivider>
      <ParentSize className="relative h-[300px]">
        {(parent) => {
          if (loading) {
            return <BarLoader />;
          }

          return parent.width > 0 && chart.length > 0 && !loading ? (
            <LiquidityProjectionChart
              chart={chart}
              width={parent.width}
              height={300}
            />
          ) : null;
        }}
      </ParentSize>
    </div>
  );
};
