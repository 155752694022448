import { useEffect, useState } from "react";
import { Button } from "common/components/Button";
import { FormikProps } from "formik";
import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";
import { CaretUpDown } from "@phosphor-icons/react";

import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "shadcn/ui/Command";
import { SelectOption } from "./useAddFundInterestForm";
import TapClient from "common/clients/TapClient";
import { Fund } from "experiences/funds/domain/models/Fund";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { UiCommandEmpty } from "./UiCommandEmpty";

const GET_FUNDS_PATH = "/funds/";

const useSelectFundDropdown = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [funds, setFunds] = useState<Fund[]>([]);
  const [search, setSearch] = useState<string>("");

  const refreshFundOptions = async () => {
    setIsLoading(true);

    await new TapClient(GET_FUNDS_PATH, {
      paginationSpecs: { perPage: 20, pageIndex: 1 },
      filters: { searchTerm: search },
    })
      .post()
      .then((resp) => {
        setFunds(resp.items);
      });

    setIsLoading(false);
  };

  useEffect(() => {
    refreshFundOptions();
  }, []);

  useEffect(() => {
    if (search) {
      refreshFundOptions();
    }
  }, [search]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const fundOptions: SelectOption[] = funds.map((fund) => ({
    value: fund.id,
    label: fund.name,
  }));

  return {
    refreshFundOptions,
    funds,
    fundOptions,
    isLoading,
    search,
    handleSearchChange,
  };
};

// Specs:
// - Show a dropdown of funds, limited to 5 results
// - If the fund isn't found, allow the user to create a new fund
export const SelectFundDropdown = ({
  openFundOptionsDropdown,
  setOpenFundOptionsDropdown,
  form,
  handleSelectFundInterest,
  handleFundNotFoundCreateNew,
  showFundSize,
}: {
  openFundOptionsDropdown: boolean;
  setOpenFundOptionsDropdown: (open: boolean) => void;
  form: FormikProps<any>;
  handleSelectFundInterest: ({
    fundInterest,
  }: {
    fundInterest: SelectOption;
  }) => void;
  handleFundNotFoundCreateNew: () => void;
  showFundSize?: boolean;
}) => {
  const { fundOptions, search, handleSearchChange, funds } =
    useSelectFundDropdown();

  const selectedFund = form.values.fundName;
  const selectedFundSize = funds.find(
    (fund) => fund.id === form.values.fundId,
  )?.size;

  const fundSizeSuffix =
    showFundSize && selectedFundSize
      ? ` - ${DollarAmount.fromString(
          selectedFundSize.toString(),
        ).formattedBig()}`
      : null;

  return (
    <Popover
      open={openFundOptionsDropdown}
      onOpenChange={setOpenFundOptionsDropdown}
      defaultOpen
      modal
    >
      <PopoverTrigger className="!w-full !max-w-full overflow-x-hidden">
        {selectedFund ? (
          <Button
            role="combobox"
            className="!w-full !max-w-full !justify-between !h-10"
            aria-expanded={openFundOptionsDropdown}
            onClick={() => setOpenFundOptionsDropdown((prev) => !prev)}
          >
            {form.values.fundName}
            {fundSizeSuffix}
            <CaretUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        ) : (
          <Button
            role="combobox"
            className="!w-full !max-w-full !justify-between !h-10"
            aria-expanded={openFundOptionsDropdown}
            onClick={() => setOpenFundOptionsDropdown((prev) => !prev)}
          >
            Select Fund
            <CaretUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="!max-w-full p-0 !relative !z-50 !translate-x-0 !translate-y-0 popover-content-same-trigger-width">
        <Command className="relative z-50 !max-w-full !w-full" value={search}>
          <CommandInput
            placeholder="Search Funds"
            defaultValue={search}
            onValueChange={(value) => handleSearchChange(value)}
          />
          <CommandList>
            <UiCommandEmpty onClick={handleFundNotFoundCreateNew}>
              Can’t find the fund?
              <span className="underline">Add it</span>
            </UiCommandEmpty>
            <CommandGroup>
              {fundOptions.map((fundInterest) => {
                const fund = funds.find(
                  (fund) => fund.id === fundInterest.value,
                );

                const fundSize = DollarAmount.fromString(
                  fund?.size?.toString() || "0",
                ).formattedBig();
                return (
                  <CommandItem
                    key={fundInterest.value}
                    value={fundInterest.label} // TODO: When submittting, we need to submit the value
                    onSelect={() => {
                      const managerName = fund?.managerName || "";

                      handleSelectFundInterest({
                        fundInterest,
                        managerName,
                      });
                      setOpenFundOptionsDropdown(false);
                    }}
                  >
                    {fundInterest.label}
                    {fundSizeSuffix}
                  </CommandItem>
                );
              })}
              <CommandItem
                className="flex gap-2"
                onSelect={handleFundNotFoundCreateNew}
                forceMount
              >
                Can’t find the fund?
                <span className="underline">Add it</span>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
