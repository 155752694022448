import { FirmOverview } from "experiences/compliance/domain/models/FirmOverview";
import { FairnessOpinion } from "../../domain/models/FairnessOpinion";
import { QmsEntry } from "../../domain/models/QmsEntry";

export class ComplianceState {}

export class QmsListLoaded extends ComplianceState {
  qmsList: QmsEntry[];

  constructor({ qmsList }: { qmsList: QmsEntry[] }) {
    super();
    this.qmsList = qmsList;
  }
}

export class FairnessOpinionListLoaded extends ComplianceState {
  fairnessOpinions: FairnessOpinion[];

  constructor({ fairnessOpinions }: { fairnessOpinions: FairnessOpinion[] }) {
    super();
    this.fairnessOpinions = fairnessOpinions;
  }
}

export class FirmOverviewLoaded extends ComplianceState {
  firmOverview: FirmOverview;

  constructor({ firmOverview }: { firmOverview: FirmOverview }) {
    super();
    this.firmOverview = firmOverview;
  }
}
