export enum RegAssetClassPref {
  Unknown = 0,
  Buyout = 1,
  PrivateDebt = 2,
  VentureCapital = 3,
  RealEstate = 4,
  Infrastructure = 5,
  Growth = 7,
  NaturalResources = 8,
  FundOfFunds = 9,
  Secondaries = 10,
}

export const RegAssetClassPrefLabels = {
  [RegAssetClassPref.Buyout]: "Buyout",
  [RegAssetClassPref.PrivateDebt]: "Private Debt",
  [RegAssetClassPref.VentureCapital]: "Venture Capital",
  [RegAssetClassPref.RealEstate]: "Real Estate",
  [RegAssetClassPref.Infrastructure]: "Infrastructure",
  [RegAssetClassPref.Growth]: "Growth",
  [RegAssetClassPref.NaturalResources]: "Natural Resources",
  [RegAssetClassPref.FundOfFunds]: "Fund of Funds",
  [RegAssetClassPref.Secondaries]: "Secondaries",
};
