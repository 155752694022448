import { useFirmContext } from "experiences/firm/presentation/state/FirmContext";
import { FirmTeamMember } from "experiences/firm/domain/models/FirmTeamMember";
import {
  FirmFundsLoaded,
  FirmFundsLoading,
  FirmTeamMembersLoaded,
  FirmTeamMembersLoading,
} from "../state/FirmState";
import {
  FirmTeamMembersRequested,
  FirmFundsRequested,
} from "../state/FirmEvent";
import { FirmFund } from "experiences/firm/domain/models/FirmFund";

export const useFirm = () => {
  const { emitEvent, teamMembersState, firmFundsState } = useFirmContext();

  // Doing this explicitly to avoid generating a request when the component mounts
  // This will allows us to use the hook in a variety of ways without worrying about the request being made automatically
  const loadTeamMembers = () => {
    emitEvent(new FirmTeamMembersRequested());
  };

  const loadFunds = () => {
    emitEvent(new FirmFundsRequested());
  };

  const isLoadingTeamMembers =
    teamMembersState instanceof FirmTeamMembersRequested ||
    teamMembersState instanceof FirmTeamMembersLoading;

  const teamMembers =
    teamMembersState instanceof FirmTeamMembersLoaded
      ? teamMembersState.members
      : new Array<FirmTeamMember>();

  const isLoadingFunds =
    firmFundsState instanceof FirmFundsRequested ||
    firmFundsState instanceof FirmFundsLoading;

  const funds =
    firmFundsState instanceof FirmFundsLoaded
      ? firmFundsState.funds
      : new Array<FirmFund>();

  return {
    loadTeamMembers,
    isLoadingTeamMembers,
    teamMembers,
    loadFunds,
    isLoadingFunds,
    funds,
  };
};
