import {
  IIndicationOfInterest,
  IOIType,
} from "experiences/indications-of-interest/domain/models/IOI";

export const VIEWPOINT_PARTNERS_INDICATIONS_OF_INTEREST: IIndicationOfInterest[] =
  [
    {
      createdAt: "2018-06-01",
      type: IOIType.Bid,
      minPrice: 81.3,
      maxPrice: 81.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 135000000,
      maxAmount: 159000000,
      id: "05f21d55-531a-536f-8f62-749af1f8f4ac",
      userId: "80f0c36d-f67c-5b7b-a656-a88e03570447",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 65059333,
      // maxNav: 65059333,
      // minTvpi: 0.81,
      // maxTvpi: 0.81,
    },
    {
      createdAt: "2018-06-22",
      type: IOIType.Ask,
      minPrice: 80.9,
      maxPrice: 80.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 5000000,
      maxAmount: 7000000,
      id: "5ed32e2e-937f-522b-b037-70a5b38484e4",
      userId: "9b2d15cf-82d9-5c88-a343-37eca120947d",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 64743534,
      // maxNav: 64743534,
      // minTvpi: 0.81,
      // maxTvpi: 0.81,
    },
    {
      createdAt: "2018-06-29",
      type: IOIType.Bid,
      minPrice: 70.8,
      maxPrice: 70.8,
      expirationDate: "2018-09-02",
      minAmount: 10000000,
      maxAmount: 12000000,
      id: "53ed7dfb-a090-5a61-8af0-b60622b2d2d0",
      userId: "9d619035-82c3-5f5b-a720-afae87bb6682",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 56652055,
      // maxNav: 56652055,
      // minTvpi: 0.71,
      // maxTvpi: 0.71,
    },
    {
      createdAt: "2018-08-24",
      type: IOIType.Bid,
      minPrice: 72.2,
      maxPrice: 72.2,
      expirationDate: "2018-10-28",
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "214e69b9-e02b-50a2-a0a2-65a17e0d8e72",
      userId: "05e18983-92a1-56fa-a8ca-0b75ed28d823",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 64981136,
      // maxNav: 64981136,
      // minTvpi: 0.72,
      // maxTvpi: 0.72,
    },
    {
      createdAt: "2018-09-14",
      type: IOIType.Bid,
      minPrice: 80.9,
      maxPrice: 80.9,
      expirationDate: "2018-11-18",
      minAmount: 8500000,
      maxAmount: 9500000,
      id: "d503055e-7ed4-511b-bc34-ab4fc5282d63",
      userId: "d5550893-4556-5275-bd52-3cead20ed967",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 72785257,
      // maxNav: 72785257,
      // minTvpi: 0.81,
      // maxTvpi: 0.81,
    },
    {
      createdAt: "2018-09-28",
      type: IOIType.Bid,
      minPrice: 79.3,
      maxPrice: 79.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "88c9afbf-bc41-5a19-9b05-8397d7e0ef24",
      userId: "0d4ef437-825a-5254-9ce2-0ee7c7eef23c",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 71380561,
      // maxNav: 71380561,
      // minTvpi: 0.79,
      // maxTvpi: 0.79,
    },
    {
      createdAt: "2018-10-19",
      type: IOIType.Ask,
      minPrice: 79.2,
      maxPrice: 79.2,
      expirationDate: null,
      isGtc: true,
      minAmount: 15000000,
      maxAmount: 17000000,
      id: "5a2bcfbb-8e4d-52a9-add7-c97c660f3c8e",
      userId: "61d309e6-5f17-50b3-ab64-ba2e8ad4ab1b",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 85559951,
      // maxNav: 85559951,
      // minTvpi: 0.79,
      // maxTvpi: 0.79,
    },
    {
      createdAt: "2019-01-11",
      type: IOIType.Bid,
      minPrice: 64.4,
      maxPrice: 64.4,
      expirationDate: "2019-03-17",
      minAmount: 110000000,
      maxAmount: 144000000,
      id: "0d4dc9ef-0d2f-5d10-bb35-67d1978b8c84",
      userId: "22bbff02-7280-527d-8be1-c4c9d57a28ea",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 75952644,
      // maxNav: 75952644,
      // minTvpi: 0.64,
      // maxTvpi: 0.64,
    },
    {
      createdAt: "2019-02-01",
      type: IOIType.Ask,
      minPrice: 82.1,
      maxPrice: 85.1,
      expirationDate: "2019-04-07",
      minAmount: 10000000,
      maxAmount: 14000000,
      id: "492dde9e-eebe-50fd-84f0-903cdab2a8cc",
      userId: "f8c05dfe-ddcf-58a3-912f-cdb0c777642f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 96890629,
      // maxNav: 100430629,
      // minTvpi: 0.82,
      // maxTvpi: 0.85,
    },
    {
      createdAt: "2019-02-08",
      type: IOIType.Bid,
      minPrice: 62.1,
      maxPrice: 62.1,
      expirationDate: "2019-04-14",
      minAmount: 75000000,
      maxAmount: 95000000,
      id: "7feb25f1-a5c2-503f-b412-570c2beba6ab",
      userId: "801ed8ac-7874-562c-971d-0fc2db39aa81",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 73293476,
      // maxNav: 73293476,
      // minTvpi: 0.62,
      // maxTvpi: 0.62,
    },
    {
      createdAt: "2019-04-05",
      type: IOIType.Bid,
      minPrice: 69.3,
      maxPrice: 69.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 95000000,
      maxAmount: 108000000,
      id: "f011d9a9-28e7-5a13-9e9a-6911920b44d4",
      userId: "8e66f1cf-e248-5e47-a4cb-6814ca4ec1d2",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 158874209,
      // maxNav: 158874209,
      // minTvpi: 0.83,
      // maxTvpi: 0.83,
    },
    {
      createdAt: "2019-04-26",
      type: IOIType.Bid,
      minPrice: 58.1,
      maxPrice: 58.1,
      expirationDate: "2019-06-30",
      minAmount: 100000000,
      maxAmount: 106000000,
      id: "d784ac50-69d1-5eed-8eb8-0666b205f0d1",
      userId: "6b5e314a-1467-504f-bf07-188183319be4",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 133159793,
      // maxNav: 133159793,
      // minTvpi: 0.7,
      // maxTvpi: 0.7,
    },
    {
      createdAt: "2019-06-14",
      type: IOIType.Bid,
      minPrice: 61.0,
      maxPrice: 61.0,
      expirationDate: "2019-08-18",
      minAmount: 1500000,
      maxAmount: 1500000,
      id: "f9be9e83-7e1d-5aac-8d7b-e0c18d06864b",
      userId: "97af9436-a742-56f4-ad90-f75e5d912b66",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 139784076,
      // maxNav: 139784076,
      // minTvpi: 0.73,
      // maxTvpi: 0.73,
    },
    {
      createdAt: "2019-07-05",
      type: IOIType.Ask,
      minPrice: 81.7,
      maxPrice: 85.7,
      expirationDate: "2019-09-08",
      minAmount: 105000000,
      maxAmount: 119000000,
      id: "10939c2b-1600-5f63-856d-94254a920f85",
      userId: "311493f6-3b0f-51a1-abb4-cfd8ad656b04",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 211277512,
      // maxNav: 221626312,
      // minTvpi: 1.08,
      // maxTvpi: 1.13,
    },
    {
      createdAt: "2019-07-12",
      type: IOIType.Bid,
      minPrice: 71.6,
      maxPrice: 71.6,
      expirationDate: null,
      isGtc: true,
      minAmount: 4500000,
      maxAmount: 6500000,
      id: "ab8b0ea5-ea1e-553a-99a6-397a79bbb4ff",
      userId: "ca8a4000-620c-5f6f-8581-f234535726c1",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 185117480,
      // maxNav: 185117480,
      // minTvpi: 0.94,
      // maxTvpi: 0.94,
    },
    {
      createdAt: "2019-08-02",
      type: IOIType.Ask,
      minPrice: 81.9,
      maxPrice: 81.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "4bc4c2f2-566b-5c4d-a33e-de247714e1b9",
      userId: "cff17e88-6698-592c-a454-519f505e1e90",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 211770372,
      // maxNav: 211770372,
      // minTvpi: 1.08,
      // maxTvpi: 1.08,
    },
    {
      createdAt: "2019-09-13",
      type: IOIType.Bid,
      minPrice: 62.7,
      maxPrice: 65.7,
      expirationDate: "2019-11-17",
      minAmount: 7500000,
      maxAmount: 11500000,
      id: "7abf2520-c405-59cc-b067-93222ca4ca66",
      userId: "946f7b94-f60e-5e1f-9007-706ef02ceec9",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 162269661,
      // maxNav: 170031261,
      // minTvpi: 0.83,
      // maxTvpi: 0.87,
    },
    {
      createdAt: "2019-10-04",
      type: IOIType.Bid,
      minPrice: 63.7,
      maxPrice: 63.7,
      expirationDate: null,
      isGtc: true,
      minAmount: 45000000,
      maxAmount: 54000000,
      id: "b7e02ba5-68b2-5aef-92c5-65591c3bd876",
      userId: "e81e3d0b-0095-5e2e-a150-678e5aa33fee",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 196729079,
      // maxNav: 196729079,
      // minTvpi: 0.95,
      // maxTvpi: 0.95,
    },
    {
      createdAt: "2019-10-18",
      type: IOIType.Bid,
      minPrice: 72.9,
      maxPrice: 77.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 135000000,
      maxAmount: 179000000,
      id: "04d9c482-7c32-5c84-94b8-445960c06370",
      userId: "aca95554-242f-565a-8cf9-7149b6903942",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 225376410,
      // maxNav: 240826410,
      // minTvpi: 1.09,
      // maxTvpi: 1.17,
    },
    {
      createdAt: "2019-11-08",
      type: IOIType.Ask,
      minPrice: 73.1,
      maxPrice: 73.1,
      expirationDate: null,
      isGtc: true,
      minAmount: 135000000,
      maxAmount: 172000000,
      id: "5af9a3a6-582b-5a05-b568-197011db2e48",
      userId: "56a3429b-578c-5d02-a302-f103cd87e2ac",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 225768467,
      // maxNav: 225768467,
      // minTvpi: 1.1,
      // maxTvpi: 1.1,
    },
    {
      createdAt: "2019-11-15",
      type: IOIType.Bid,
      minPrice: 72.6,
      maxPrice: 72.6,
      expirationDate: null,
      isGtc: true,
      minAmount: 50000000,
      maxAmount: 69000000,
      id: "be522352-a034-5dbe-a78f-2daf5f165c77",
      userId: "5574a492-3fa8-56ea-8ee0-5b98a6830500",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 224340771,
      // maxNav: 224340771,
      // minTvpi: 1.09,
      // maxTvpi: 1.09,
    },
    {
      createdAt: "2019-12-06",
      type: IOIType.Ask,
      minPrice: 71.5,
      maxPrice: 80.5,
      expirationDate: null,
      isGtc: true,
      minAmount: 95000000,
      maxAmount: 119000000,
      id: "246a123f-59e8-50ae-ac01-eea2efb94e8f",
      userId: "b1152f11-5142-50c1-9ee2-ec585310eb30",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 221010173,
      // maxNav: 248820173,
      // minTvpi: 1.07,
      // maxTvpi: 1.21,
    },
    {
      createdAt: "2019-12-13",
      type: IOIType.Bid,
      minPrice: 71.7,
      maxPrice: 71.7,
      expirationDate: "2020-02-16",
      minAmount: 10000000,
      maxAmount: 12000000,
      id: "7e8f8da8-5ded-56cd-9069-403cb78a0557",
      userId: "14878987-49ac-54d5-97b2-eca87bb81472",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 221649426,
      // maxNav: 221649426,
      // minTvpi: 1.08,
      // maxTvpi: 1.08,
    },
    {
      createdAt: "2020-02-07",
      type: IOIType.Bid,
      minPrice: 66.3,
      maxPrice: 66.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 9000000,
      maxAmount: 11000000,
      id: "bb6347c1-ba8d-50c0-95bc-c9cc6d533be0",
      userId: "a7467166-4267-5458-bc2b-101364fa0fec",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 307874848,
      // maxNav: 307874848,
      // minTvpi: 1.46,
      // maxTvpi: 1.46,
    },
    {
      createdAt: "2020-02-28",
      type: IOIType.Bid,
      minPrice: 64.3,
      maxPrice: 73.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 2000000,
      maxAmount: 3000000,
      id: "f60c0645-1aa7-5e9c-8bdb-8db976e8a65a",
      userId: "ee2e7fb7-d985-5de8-b7ae-632d5d55fb5c",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 298681729,
      // maxNav: 340459729,
      // minTvpi: 1.42,
      // maxTvpi: 1.61,
    },
    {
      createdAt: "2020-03-13",
      type: IOIType.Bid,
      minPrice: 63.4,
      maxPrice: 68.4,
      expirationDate: "2020-05-17",
      minAmount: 1500000,
      maxAmount: 2500000,
      id: "59cd0ec1-6a80-59ad-a869-04443b53014d",
      userId: "d8172cbb-8e0a-5f86-bfee-2c1c24cb9168",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 294456229,
      // maxNav: 317666229,
      // minTvpi: 1.4,
      // maxTvpi: 1.51,
    },
    {
      createdAt: "2020-04-03",
      type: IOIType.Ask,
      minPrice: 69.0,
      maxPrice: 74.0,
      expirationDate: null,
      isGtc: true,
      minAmount: 50000000,
      maxAmount: 68000000,
      id: "7b8c8c17-cfda-53d3-96e4-295dc3832e52",
      userId: "1e791028-45af-59db-b9d8-1bd2b134d5b7",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 426845012,
      // maxNav: 457785012,
      // minTvpi: 1.93,
      // maxTvpi: 2.07,
    },
    {
      createdAt: "2020-06-26",
      type: IOIType.Bid,
      minPrice: 49.8,
      maxPrice: 58.8,
      expirationDate: "2020-08-30",
      minAmount: 3500000,
      maxAmount: 4500000,
      id: "d0ac2ff5-3b73-584b-a20a-731bf568fb13",
      userId: "d80d4e3f-332b-5231-b021-70072074b4b2",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 319105858,
      // maxNav: 376786858,
      // minTvpi: 1.44,
      // maxTvpi: 1.7,
    },
    {
      createdAt: "2020-07-17",
      type: IOIType.Ask,
      minPrice: 70.2,
      maxPrice: 79.2,
      expirationDate: "2020-09-20",
      minAmount: 60000000,
      maxAmount: 82000000,
      id: "beb5a0c0-54dd-5484-971f-d32647bd6bba",
      userId: "676bb93d-4968-5c57-a9e2-0eb0bce87aca",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 511043220,
      // maxNav: 576554220,
      // minTvpi: 2.04,
      // maxTvpi: 2.3,
    },
    {
      createdAt: "2020-07-24",
      type: IOIType.Bid,
      minPrice: 50.7,
      maxPrice: 50.7,
      expirationDate: null,
      isGtc: true,
      minAmount: 125000000,
      maxAmount: 152000000,
      id: "0e742c63-d98b-506c-9c73-c4a0c7fdf27d",
      userId: "1fa5e448-d3cf-5470-894f-3010189dbab2",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 368968624,
      // maxNav: 368968624,
      // minTvpi: 1.47,
      // maxTvpi: 1.47,
    },
    {
      createdAt: "2020-09-18",
      type: IOIType.Bid,
      minPrice: 63.1,
      maxPrice: 63.1,
      expirationDate: null,
      isGtc: true,
      minAmount: 30000000,
      maxAmount: 37000000,
      id: "81408802-291d-5b2a-9c44-97fa0ba7d4f6",
      userId: "60603ca7-80a9-523e-a571-1231aafb49a8",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 459040425,
      // maxNav: 459040425,
      // minTvpi: 1.83,
      // maxTvpi: 1.83,
    },
    {
      createdAt: "2020-10-09",
      type: IOIType.Bid,
      minPrice: 51.7,
      maxPrice: 52.7,
      expirationDate: "2020-12-13",
      minAmount: 65000000,
      maxAmount: 90000000,
      id: "7da7e806-e928-527a-a02b-55f82cd18b74",
      userId: "409e5f4e-481b-5d5e-927c-02e82a7d0070",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 452834844,
      // maxNav: 461602044,
      // minTvpi: 1.61,
      // maxTvpi: 1.64,
    },
    {
      createdAt: "2020-11-27",
      type: IOIType.Bid,
      minPrice: 50.1,
      maxPrice: 51.1,
      expirationDate: "2021-01-31",
      minAmount: 10000000,
      maxAmount: 12000000,
      id: "020f2772-af96-5df0-91d5-35d792897ca0",
      userId: "d60cdc4e-c45c-5bf2-aeee-bb2018bac2f4",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 438881973,
      // maxNav: 447649173,
      // minTvpi: 1.56,
      // maxTvpi: 1.59,
    },
    {
      createdAt: "2020-12-18",
      type: IOIType.Ask,
      minPrice: 61.1,
      maxPrice: 65.1,
      expirationDate: null,
      isGtc: true,
      minAmount: 500000,
      maxAmount: 500000,
      id: "ac60dd57-135e-5e6d-b79a-1754a37e79c5",
      userId: "3f06888e-a7d7-58ff-b526-f96fad5f6ece",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 535641756,
      // maxNav: 570710556,
      // minTvpi: 1.91,
      // maxTvpi: 2.03,
    },
    {
      createdAt: "2020-12-25",
      type: IOIType.Bid,
      minPrice: 62.7,
      maxPrice: 62.7,
      expirationDate: null,
      isGtc: true,
      minAmount: 3500000,
      maxAmount: 4500000,
      id: "e03684c7-6f47-5806-83f9-064dc01bd95c",
      userId: "1e94da69-def4-5508-89fb-2a8c4cef0d55",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 537070008,
      // maxNav: 537070008,
      // minTvpi: 1.91,
      // maxTvpi: 1.91,
    },
    {
      createdAt: "2021-01-15",
      type: IOIType.Ask,
      minPrice: 63.1,
      maxPrice: 69.1,
      expirationDate: "2021-03-21",
      minAmount: 95000000,
      maxAmount: 115000000,
      id: "5d8bd4e6-a5ae-531b-8fc4-90c9e1b8cc7d",
      userId: "d060d2ea-6923-5eea-a9d1-19b92c2140db",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 589272804,
      // maxNav: 645270804,
      // minTvpi: 1.93,
      // maxTvpi: 2.11,
    },
    {
      createdAt: "2021-02-26",
      type: IOIType.Bid,
      minPrice: 68.8,
      maxPrice: 68.8,
      expirationDate: "2021-05-02",
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "caafa28f-9049-5092-991e-03bacda1db14",
      userId: "5a14d4a3-8ab5-59b3-8b27-456b8045092f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 641764932,
      // maxNav: 641764932,
      // minTvpi: 2.1,
      // maxTvpi: 2.1,
    },
    {
      createdAt: "2021-03-12",
      type: IOIType.Ask,
      minPrice: 79.9,
      maxPrice: 85.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 135000000,
      maxAmount: 177000000,
      id: "f91eb547-e1ab-5839-8630-256b1b8afe0f",
      userId: "991e9f8e-2e25-5519-b7ff-b51c47086175",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 745868324,
      // maxNav: 801866324,
      // minTvpi: 2.44,
      // maxTvpi: 2.62,
    },
    {
      createdAt: "2021-03-19",
      type: IOIType.Bid,
      minPrice: 61.2,
      maxPrice: 63.2,
      expirationDate: null,
      isGtc: true,
      minAmount: 50000000,
      maxAmount: 67000000,
      id: "eb5e344d-98c7-5bac-8f8b-50c641bc6d93",
      userId: "de2aa3c9-b696-516c-a0e9-4faeb48c0b84",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 571114273,
      // maxNav: 589780273,
      // minTvpi: 1.87,
      // maxTvpi: 1.93,
    },
    {
      createdAt: "2021-04-02",
      type: IOIType.Bid,
      minPrice: 63.7,
      maxPrice: 70.7,
      expirationDate: "2021-06-06",
      minAmount: 60000000,
      maxAmount: 74000000,
      id: "4d0c0200-32b1-505e-83bf-f226a7f88679",
      userId: "6b8bca5e-6c37-50c2-96cc-87fee5b4ac15",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 771695974,
      // maxNav: 856465974,
      // minTvpi: 2.23,
      // maxTvpi: 2.48,
    },
    {
      createdAt: "2021-04-23",
      type: IOIType.Ask,
      minPrice: 85.3,
      maxPrice: 85.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "e0806a7e-4dc6-50f1-a728-b9a3a0340999",
      userId: "661ba9d9-0ebd-5594-80e7-e726295edf1c",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1033053544,
      // maxNav: 1033053544,
      // minTvpi: 2.99,
      // maxTvpi: 2.99,
    },
    {
      createdAt: "2021-05-14",
      type: IOIType.Bid,
      minPrice: 77.9,
      maxPrice: 83.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 6000000,
      maxAmount: 9000000,
      id: "4d56cc21-0289-5610-a8dd-3493252dd879",
      userId: "1e85a788-b108-5a19-ab22-ba3ee7d8411e",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 943267588,
      // maxNav: 1015927588,
      // minTvpi: 2.73,
      // maxTvpi: 2.94,
    },
    {
      createdAt: "2021-05-28",
      type: IOIType.Bid,
      minPrice: 70.4,
      maxPrice: 73.4,
      expirationDate: "2021-08-01",
      minAmount: 8500000,
      maxAmount: 10500000,
      id: "3643076a-8d0b-5cbd-a9dc-5ed86ee25f3c",
      userId: "ecbf2ca2-e970-5a9f-9dd6-71ec6c6a1ba7",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 852410356,
      // maxNav: 888740356,
      // minTvpi: 2.46,
      // maxTvpi: 2.57,
    },
    {
      createdAt: "2021-06-18",
      type: IOIType.Bid,
      minPrice: 87.1,
      maxPrice: 94.1,
      expirationDate: "2021-08-22",
      minAmount: 8000000,
      maxAmount: 10000000,
      id: "9ae27ead-1c58-5241-89db-efb76174ccac",
      userId: "99629b5b-344b-5058-8594-aee4304ef77f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1055096681,
      // maxNav: 1139866681,
      // minTvpi: 3.05,
      // maxTvpi: 3.29,
    },
    {
      createdAt: "2021-07-30",
      type: IOIType.Bid,
      minPrice: 78.7,
      maxPrice: 78.7,
      expirationDate: null,
      isGtc: true,
      minAmount: 100000000,
      maxAmount: 135000000,
      id: "9a9f55eb-5097-5370-bf23-2a55ce07ab2b",
      userId: "d25d7976-f69e-5338-a8fb-a8ec074b640b",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1090605863,
      // maxNav: 1090605863,
      // minTvpi: 2.75,
      // maxTvpi: 2.75,
    },
    {
      createdAt: "2021-08-13",
      type: IOIType.Ask,
      minPrice: 89.4,
      maxPrice: 94.4,
      expirationDate: "2021-10-17",
      minAmount: 9500000,
      maxAmount: 12500000,
      id: "b7e8afc7-ec4e-51e5-bacb-168aa4f0e252",
      userId: "d5509e2c-ef4d-50da-bd03-df376da2b1e8",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1238475352,
      // maxNav: 1307775352,
      // minTvpi: 3.13,
      // maxTvpi: 3.3,
    },
    {
      createdAt: "2021-08-20",
      type: IOIType.Bid,
      minPrice: 81.2,
      maxPrice: 81.2,
      expirationDate: null,
      isGtc: true,
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "382f7088-a891-57b2-8b2d-37247ac669fd",
      userId: "59e113cb-c233-5b5e-86c4-cac973fab82b",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1125212173,
      // maxNav: 1125212173,
      // minTvpi: 2.84,
      // maxTvpi: 2.84,
    },
    {
      createdAt: "2021-09-03",
      type: IOIType.Bid,
      minPrice: 82.9,
      maxPrice: 91.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 95000000,
      maxAmount: 113000000,
      id: "c2200f48-74f2-544b-bf7c-7d1ccf15788b",
      userId: "5078072c-2efc-5f5d-81f7-053996f5a52f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1149390445,
      // maxNav: 1274130445,
      // minTvpi: 2.9,
      // maxTvpi: 3.22,
    },
    {
      createdAt: "2021-09-24",
      type: IOIType.Ask,
      minPrice: 92.2,
      maxPrice: 96.2,
      expirationDate: "2021-11-28",
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "b40a331a-7c23-5ce0-b1ad-0732509280d5",
      userId: "42c255eb-d49a-5ce2-9f7f-dcc89b0de1c0",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1296108511,
      // maxNav: 1352340511,
      // minTvpi: 3.27,
      // maxTvpi: 3.42,
    },
    {
      createdAt: "2021-10-15",
      type: IOIType.Bid,
      minPrice: 91.8,
      maxPrice: 91.8,
      expirationDate: null,
      isGtc: true,
      minAmount: 100000000,
      maxAmount: 130000000,
      id: "b1255d1c-a086-5d3f-8942-b177abe00943",
      userId: "a2436d36-0480-539c-b980-b9b4209c3868",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1291075099,
      // maxNav: 1291075099,
      // minTvpi: 3.26,
      // maxTvpi: 3.26,
    },
    {
      createdAt: "2021-10-29",
      type: IOIType.Bid,
      minPrice: 91.7,
      maxPrice: 99.7,
      expirationDate: "2022-01-02",
      minAmount: 6000000,
      maxAmount: 7000000,
      id: "e65c4350-57bf-5597-9b6a-c4e4cfae261a",
      userId: "bd3ae2bd-3823-552b-8ee2-2a255c9197dd",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1288544529,
      // maxNav: 1401008529,
      // minTvpi: 3.25,
      // maxTvpi: 3.54,
    },
    {
      createdAt: "2021-11-12",
      type: IOIType.Ask,
      minPrice: 93.3,
      maxPrice: 99.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 120000000,
      maxAmount: 145000000,
      id: "93f940f2-da26-55d5-b100-eefa125bd9e6",
      userId: "8c052d6a-a748-5973-a34b-f1084aba0c24",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1312163358,
      // maxNav: 1396511358,
      // minTvpi: 3.31,
      // maxTvpi: 3.53,
    },
    {
      createdAt: "2021-11-19",
      type: IOIType.Bid,
      minPrice: 83.4,
      maxPrice: 83.4,
      expirationDate: "2022-01-23",
      minAmount: 7500000,
      maxAmount: 8500000,
      id: "adaa2e65-5fb4-5adc-a0b4-ec03205bf251",
      userId: "d499d37c-0879-5886-8ec2-b8b1a71f0297",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1172554893,
      // maxNav: 1172554893,
      // minTvpi: 2.96,
      // maxTvpi: 2.96,
    },
    {
      createdAt: "2021-12-03",
      type: IOIType.Bid,
      minPrice: 83.6,
      maxPrice: 83.6,
      expirationDate: "2022-02-06",
      minAmount: 5500000,
      maxAmount: 5500000,
      id: "51860d58-0de4-51a1-90e7-8e5b9b5b8366",
      userId: "c021e560-51df-5f72-aec1-079b18ba02de",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1175792684,
      // maxNav: 1175792684,
      // minTvpi: 2.97,
      // maxTvpi: 2.97,
    },
    {
      createdAt: "2021-12-17",
      type: IOIType.Ask,
      minPrice: 95.9,
      maxPrice: 97.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 7500000,
      maxAmount: 9500000,
      id: "134d353f-defc-5983-a8e2-66b8eb8f2761",
      userId: "8f472084-b359-590b-a56f-605461a33b1f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1348336764,
      // maxNav: 1376452764,
      // minTvpi: 3.4,
      // maxTvpi: 3.48,
    },
    {
      createdAt: "2021-12-24",
      type: IOIType.Bid,
      minPrice: 88.4,
      maxPrice: 95.4,
      expirationDate: "2022-02-27",
      minAmount: 4000000,
      maxAmount: 5000000,
      id: "c60f871a-5679-5a9b-bf22-a82ae24aaefc",
      userId: "a030cd58-4444-53a7-a108-0f0d0e6caa77",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1120834108,
      // maxNav: 1209538108,
      // minTvpi: 2.83,
      // maxTvpi: 3.05,
    },
    {
      createdAt: "2022-01-07",
      type: IOIType.Bid,
      minPrice: 88.1,
      maxPrice: 88.1,
      expirationDate: "2022-03-13",
      minAmount: 8500000,
      maxAmount: 11500000,
      id: "ff332f56-9756-59f1-b5bc-3df299aaa0d3",
      userId: "6611056a-f3b7-5fc7-8fdf-be2604014e27",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1116757174,
      // maxNav: 1116757174,
      // minTvpi: 2.82,
      // maxTvpi: 2.82,
    },
    {
      createdAt: "2022-01-28",
      type: IOIType.Ask,
      minPrice: 99.1,
      maxPrice: 99.1,
      expirationDate: null,
      isGtc: true,
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "24cd1f68-0ab8-5933-92f2-88d7c77970bf",
      userId: "02a4b348-df8f-5bcc-a528-449220719b2a",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1255760922,
      // maxNav: 1255760922,
      // minTvpi: 3.17,
      // maxTvpi: 3.17,
    },
    {
      createdAt: "2022-02-18",
      type: IOIType.Bid,
      minPrice: 89.5,
      maxPrice: 89.5,
      expirationDate: null,
      isGtc: true,
      minAmount: 6500000,
      maxAmount: 8500000,
      id: "647d4f70-bd9c-5ba3-8a27-cdedc42c2b5d",
      userId: "ec80e82e-9148-5e7a-bca2-648527662ab4",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1133978514,
      // maxNav: 1133978514,
      // minTvpi: 2.86,
      // maxTvpi: 2.86,
    },
    {
      createdAt: "2022-03-18",
      type: IOIType.Bid,
      minPrice: 90.3,
      maxPrice: 90.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "d7fa5ddc-dfbf-5e10-85ad-2b14a6a8ab52",
      userId: "0b82f129-2cf8-56d1-9341-dd097e0df51c",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1144309937,
      // maxNav: 1144309937,
      // minTvpi: 2.89,
      // maxTvpi: 2.89,
    },
    {
      createdAt: "2022-04-01",
      type: IOIType.Ask,
      minPrice: 99.7,
      maxPrice: 107.7,
      expirationDate: null,
      isGtc: true,
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "07409a60-380b-5dbd-93fb-3edf44620764",
      userId: "b54207c4-6d69-589a-87d9-ef67b4c4edeb",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1578510489,
      // maxNav: 1705230489,
      // minTvpi: 3.99,
      // maxTvpi: 4.31,
    },
    {
      createdAt: "2022-04-08",
      type: IOIType.Bid,
      minPrice: 102.8,
      maxPrice: 102.8,
      expirationDate: null,
      isGtc: true,
      minAmount: 4500000,
      maxAmount: 5500000,
      id: "16558490-fb0f-5ce9-9561-f2bacd5367a2",
      userId: "52bedc5c-2b4d-541d-932e-ddba3842c51f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1627634807,
      // maxNav: 1627634807,
      // minTvpi: 4.11,
      // maxTvpi: 4.11,
    },
    {
      createdAt: "2022-04-22",
      type: IOIType.Bid,
      minPrice: 91.4,
      maxPrice: 91.4,
      expirationDate: null,
      isGtc: true,
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "20682c93-f349-5878-8d2a-19f6d5f00892",
      userId: "d681a448-2b85-5d0a-ac06-89f642fd25df",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1447828508,
      // maxNav: 1447828508,
      // minTvpi: 3.66,
      // maxTvpi: 3.66,
    },
    {
      createdAt: "2022-05-13",
      type: IOIType.Ask,
      minPrice: 107.4,
      maxPrice: 107.4,
      expirationDate: null,
      isGtc: true,
      minAmount: 6500000,
      maxAmount: 8500000,
      id: "3293fe16-50fe-5a8f-8830-e2b59ed4a873",
      userId: "315b5dbd-e9d8-542e-bd13-fedcc56e7c00",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1700614076,
      // maxNav: 1700614076,
      // minTvpi: 4.29,
      // maxTvpi: 4.29,
    },
    {
      createdAt: "2022-06-03",
      type: IOIType.Bid,
      minPrice: 86.3,
      maxPrice: 95.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 100000000,
      maxAmount: 113000000,
      id: "72cecf69-1b4f-5e43-a0b3-ccefce7f3f62",
      userId: "bb52ef40-e5ff-54ae-92d2-81fc874d3d08",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1367378361,
      // maxNav: 1509938361,
      // minTvpi: 3.45,
      // maxTvpi: 3.81,
    },
    {
      createdAt: "2022-06-24",
      type: IOIType.Bid,
      minPrice: 95.0,
      maxPrice: 95.0,
      expirationDate: "2022-08-28",
      minAmount: 105000000,
      maxAmount: 122000000,
      id: "4b9ea193-e359-56ff-b7f5-43283591580a",
      userId: "30a412bc-2a8b-5ed7-b6a6-2e7c58fec75a",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1505276592,
      // maxNav: 1505276592,
      // minTvpi: 3.8,
      // maxTvpi: 3.8,
    },
    {
      createdAt: "2022-07-08",
      type: IOIType.Ask,
      minPrice: 92.9,
      maxPrice: 92.9,
      expirationDate: "2022-09-11",
      minAmount: 15000000,
      maxAmount: 19000000,
      id: "517ab8cf-20cb-50cb-abeb-b01d114a2e08",
      userId: "ec8c1ada-9660-54b5-9afe-72a3220a5a22",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1512362123,
      // maxNav: 1512362123,
      // minTvpi: 3.82,
      // maxTvpi: 3.82,
    },
    {
      createdAt: "2022-08-19",
      type: IOIType.Ask,
      minPrice: 99.2,
      maxPrice: 109.2,
      expirationDate: null,
      isGtc: true,
      minAmount: 140000000,
      maxAmount: 172000000,
      id: "28b3ecb5-4326-51e9-95d6-1e6e3296cc80",
      userId: "20aa6b0e-7b23-5375-ba59-d2281c43dec3",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1613761490,
      // maxNav: 1776517490,
      // minTvpi: 4.08,
      // maxTvpi: 4.49,
    },
    {
      createdAt: "2022-09-09",
      type: IOIType.Bid,
      minPrice: 76.0,
      maxPrice: 83.0,
      expirationDate: "2022-11-13",
      minAmount: 100000000,
      maxAmount: 125000000,
      id: "d1e80679-8393-507f-9d16-fbea809d0519",
      userId: "18f7040b-662c-56c6-89d0-d547185c90b7",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1236298347,
      // maxNav: 1350227547,
      // minTvpi: 3.12,
      // maxTvpi: 3.41,
    },
    {
      createdAt: "2022-09-16",
      type: IOIType.Bid,
      minPrice: 84.6,
      maxPrice: 84.6,
      expirationDate: null,
      isGtc: true,
      minAmount: 6000000,
      maxAmount: 8000000,
      id: "05d39a22-242d-5b01-bd95-f5f829614298",
      userId: "2a766881-7121-5f1b-8b89-c908ed887ec8",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1377446164,
      // maxNav: 1377446164,
      // minTvpi: 3.48,
      // maxTvpi: 3.48,
    },
    {
      createdAt: "2022-09-23",
      type: IOIType.Bid,
      minPrice: 73.5,
      maxPrice: 73.5,
      expirationDate: null,
      isGtc: true,
      minAmount: 30000000,
      maxAmount: 36000000,
      id: "bd50e3cf-52b0-5011-94fd-9c5cbf7f0bbb",
      userId: "0e235b57-ff8e-52a4-8339-b87dfb00c535",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1196711017,
      // maxNav: 1196711017,
      // minTvpi: 3.02,
      // maxTvpi: 3.02,
    },
    {
      createdAt: "2022-09-30",
      type: IOIType.Ask,
      minPrice: 95.1,
      maxPrice: 95.1,
      expirationDate: "2022-12-04",
      minAmount: 3500000,
      maxAmount: 4500000,
      id: "5c128e8f-ed67-59e1-a085-b9af52606db6",
      userId: "1fe52fae-518c-55b3-8777-2e8513695b02",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1547815822,
      // maxNav: 1547815822,
      // minTvpi: 3.91,
      // maxTvpi: 3.91,
    },
    {
      createdAt: "2022-10-21",
      type: IOIType.Bid,
      minPrice: 74.4,
      maxPrice: 82.4,
      expirationDate: null,
      isGtc: true,
      minAmount: 9500000,
      maxAmount: 11500000,
      id: "36a3fc29-9495-596d-8955-9a0170d0ba02",
      userId: "c25460ce-8da7-535a-8398-1845e1c21c64",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1210906196,
      // maxNav: 1341110996,
      // minTvpi: 3.06,
      // maxTvpi: 3.39,
    },
    {
      createdAt: "2022-12-02",
      type: IOIType.Bid,
      minPrice: 72.3,
      maxPrice: 75.3,
      expirationDate: "2023-02-05",
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "42ea5822-b775-5340-ab9c-1be2bdc73cab",
      userId: "3143fe88-3738-5f2d-a177-e47d6aa63904",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1177056183,
      // maxNav: 1225882983,
      // minTvpi: 2.97,
      // maxTvpi: 3.1,
    },
    {
      createdAt: "2022-12-23",
      type: IOIType.Ask,
      minPrice: 85.6,
      maxPrice: 93.6,
      expirationDate: null,
      isGtc: true,
      minAmount: 140000000,
      maxAmount: 169000000,
      id: "d33b8700-8a85-5aff-98d4-13e9b3e47716",
      userId: "c6cbeb25-4eb6-5eef-8347-308fcc22190f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1392649825,
      // maxNav: 1522854625,
      // minTvpi: 3.52,
      // maxTvpi: 3.85,
    },
    {
      createdAt: "2023-01-06",
      type: IOIType.Bid,
      minPrice: 77.1,
      maxPrice: 77.1,
      expirationDate: null,
      isGtc: true,
      minAmount: 3000000,
      maxAmount: 4000000,
      id: "ac1ecf9f-cb55-504c-8b91-3c4d8a69bb00",
      userId: "08f5ae5b-4b2d-56a2-ad94-f33302922bb9",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1221943491,
      // maxNav: 1221943491,
      // minTvpi: 3.09,
      // maxTvpi: 3.09,
    },
    {
      createdAt: "2023-01-20",
      type: IOIType.Bid,
      minPrice: 87.2,
      maxPrice: 93.2,
      expirationDate: "2023-03-26",
      minAmount: 8500000,
      maxAmount: 10500000,
      id: "ff454fae-5f71-5601-a407-322787084e5e",
      userId: "924d80a8-2e1e-55a1-8f18-651c132d48b3",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1381462921,
      // maxNav: 1476502921,
      // minTvpi: 3.49,
      // maxTvpi: 3.73,
    },
    {
      createdAt: "2023-02-03",
      type: IOIType.Bid,
      minPrice: 88.3,
      maxPrice: 88.3,
      expirationDate: null,
      isGtc: true,
      minAmount: 95000000,
      maxAmount: 127000000,
      isArchived: false,
      // minNav: 1398214601,
      // maxNav: 1398214601,
      // minTvpi: 3.53,
      // maxTvpi: 3.53,
    },
    {
      createdAt: "2023-02-10",
      type: IOIType.Ask,
      minPrice: 88.2,
      maxPrice: 88.2,
      expirationDate: "2023-04-16",
      minAmount: 5000000,
      maxAmount: 7000000,
      isArchived: false,
      // minNav: 1397689638,
      // maxNav: 1397689638,
      // minTvpi: 3.53,
      // maxTvpi: 3.53,
    },
    {
      createdAt: "2023-03-03",
      type: IOIType.Bid,
      minPrice: 76.6,
      maxPrice: 76.6,
      expirationDate: null,
      isGtc: true,
      minAmount: 10000000,
      maxAmount: 13000000,
      isArchived: false,
      // minNav: 1213149432,
      // maxNav: 1213149432,
      // minTvpi: 3.06,
      // maxTvpi: 3.06,
    },
    {
      createdAt: "2023-03-10",
      type: IOIType.Bid,
      minPrice: 86.8,
      maxPrice: 86.8,
      expirationDate: "2023-05-14",
      minAmount: 7000000,
      maxAmount: 10000000,
      isArchived: false,
      // minNav: 1374952291,
      // maxNav: 1374952291,
      // minTvpi: 3.47,
      // maxTvpi: 3.47,
    },
    {
      createdAt: "2023-03-17",
      type: IOIType.Ask,
      minPrice: 88.0,
      maxPrice: 88.0,
      expirationDate: null,
      isGtc: true,
      minAmount: 40000000,
      maxAmount: 45000000,
      id: "ec69f644-52b1-505c-a54e-d87a4498b472",
      userId: "00a5c1c0-bee9-5758-96ea-05feff901b1f",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1393359979,
      // maxNav: 1393359979,
      // minTvpi: 3.52,
      // maxTvpi: 3.52,
    },
    {
      createdAt: "2023-03-24",
      type: IOIType.Ask,
      minPrice: 99.2,
      maxPrice: 99.2,
      expirationDate: null,
      isGtc: true,
      minAmount: 35000000,
      maxAmount: 41000000,
      id: "f2becd5f-bcc5-5426-8017-6f07e7b3beb8",
      userId: "d4bf80bb-a677-5bc6-899f-95fdbf1181a2",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1571272762,
      // maxNav: 1571272762,
      // minTvpi: 3.97,
      // maxTvpi: 3.97,
    },
    {
      createdAt: "2023-03-31",
      type: IOIType.Bid,
      minPrice: 77.8,
      maxPrice: 77.8,
      expirationDate: null,
      isGtc: true,
      minAmount: 85000000,
      maxAmount: 114000000,
      isArchived: false,
      // minNav: 1309986837,
      // maxNav: 1309986837,
      // minTvpi: 3.31,
      // maxTvpi: 3.31,
    },
    {
      createdAt: "2023-04-14",
      type: IOIType.Bid,
      minPrice: 89.2,
      maxPrice: 89.2,
      expirationDate: "2023-06-18",
      minAmount: 15000000,
      maxAmount: 18000000,
      isArchived: false,
      // minNav: 1501684331,
      // maxNav: 1501684331,
      // minTvpi: 3.79,
      // maxTvpi: 3.79,
    },
    {
      createdAt: "2023-04-21",
      type: IOIType.Bid,
      minPrice: 78.8,
      maxPrice: 88.8,
      expirationDate: "2023-06-25",
      minAmount: 1000000,
      maxAmount: 1000000,
      id: "af465a04-8390-58cc-98c5-4f9cab9804f8",
      userId: "5a29cc33-003e-55ba-80bd-91488e227b07",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1326408882,
      // maxNav: 1494708882,
      // minTvpi: 3.35,
      // maxTvpi: 3.77,
    },
    {
      createdAt: "2023-05-05",
      type: IOIType.Ask,
      minPrice: 87.9,
      maxPrice: 95.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 60000000,
      maxAmount: 67000000,
      id: "dfa82ddd-5dee-59f9-a659-71a83180bb19",
      userId: "f90f7109-795b-5c9a-a7bd-fe8c0cb2ba01",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1478528208,
      // maxNav: 1613168208,
      // minTvpi: 3.73,
      // maxTvpi: 4.07,
    },
    {
      createdAt: "2023-05-12",
      type: IOIType.Ask,
      minPrice: 99.6,
      maxPrice: 99.6,
      expirationDate: null,
      isGtc: true,
      minAmount: 20000000,
      maxAmount: 28000000,
      id: "e798071b-dea5-51cf-a5d4-1e0f74f9c6e3",
      userId: "b611f426-b750-5e4c-a51b-649186104607",
      isArchived: false,
      managerName: "Viewpoint Partners",
      issuerName: "Viewpoint Partners",
      logoUrl: "dvw464wIqbclZ.jpg",
      // isArchived: true,
      // minNav: 1675745112,
      // maxNav: 1675745112,
      // minTvpi: 4.23,
      // maxTvpi: 4.23,
    },
    {
      createdAt: "2023-05-19",
      type: IOIType.Bid,
      minPrice: 90.2,
      maxPrice: 90.2,
      expirationDate: null,
      isGtc: true,
      minAmount: 85000000,
      maxAmount: 92000000,
      isArchived: false,
      // minNav: 1517464646,
      // maxNav: 1517464646,
      // minTvpi: 3.83,
      // maxTvpi: 3.83,
    },
    {
      createdAt: "2023-05-26",
      type: IOIType.Bid,
      minPrice: 77.9,
      maxPrice: 77.9,
      expirationDate: null,
      isGtc: true,
      minAmount: 80000000,
      maxAmount: 109000000,
      isArchived: false,
      // minNav: 1310267983,
      // maxNav: 1310267983,
      // minTvpi: 3.31,
      // maxTvpi: 3.31,
    },
    {
      createdAt: "2023-06-09",
      type: IOIType.Bid,
      minPrice: 88.9,
      maxPrice: 92.9,
      expirationDate: "2023-08-13",
      minAmount: 2500000,
      maxAmount: 3500000,
      isArchived: false,
      // minNav: 1495514308,
      // maxNav: 1562834308,
      // minTvpi: 3.78,
      // maxTvpi: 3.95,
    },
    {
      createdAt: "2023-06-16",
      type: IOIType.Bid,
      minPrice: 80.0,
      maxPrice: 88.0,
      expirationDate: null,
      isGtc: true,
      minAmount: 6000000,
      maxAmount: 7000000,
      isArchived: false,
      // minNav: 1346264006,
      // maxNav: 1480904006,
      // minTvpi: 3.4,
      // maxTvpi: 3.74,
    },
    {
      createdAt: "2023-06-30",
      type: IOIType.Ask,
      minPrice: 89.0,
      maxPrice: 90.0,
      expirationDate: "2023-09-03",
      minAmount: 9500000,
      maxAmount: 14500000,
      isArchived: false,
      // minNav: 1514764980,
      // maxNav: 1531792980,
      // minTvpi: 3.83,
      // maxTvpi: 3.87,
    },
    {
      createdAt: "2023-07-14",
      type: IOIType.Bid,
      minPrice: 89.0,
      maxPrice: 89.0,
      expirationDate: "2023-09-17",
      minAmount: 5000000,
      maxAmount: 5000000,
      isArchived: false,
      // minNav: 1515345730,
      // maxNav: 1515345730,
      // minTvpi: 3.83,
      // maxTvpi: 3.83,
    },
    {
      createdAt: "2023-07-28",
      type: IOIType.Ask,
      minPrice: 97.7,
      maxPrice: 101.7,
      expirationDate: null,
      isGtc: true,
      minAmount: 20000000,
      maxAmount: 26000000,
      isArchived: false,
      // minNav: 1663346062,
      // maxNav: 1731458062,
      // minTvpi: 4.2,
      // maxTvpi: 4.37,
    },
  ];
