import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { BidTransactionHolding } from "../models/Bidding";

export class GetTransactionHoldingsForBidding
  implements IAsyncUseCase<IParams, BidTransactionHolding[]>
{
  call: (params: IParams) => Promise<Either<Failure, BidTransactionHolding[]>> =
    async ({ txnId }) => {
      return await new TapClient(
        `/transactions/${txnId}/bidding/transaction-holdings/get/`,
      )
        .get({})
        .then((resp: BidTransactionHolding[]) => {
          return right(resp);
        })
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

interface IParams {
  txnId: string;
}
