import { IFundDataDocument } from "experiences/transactions/presentation/components/fund-data/documents/model";
import TapClient from "common/clients/TapClient";

export const handleDataroomDownload = async ({
  document, // we only need this for the document.fileUrl but keeping it for now
  txnId,
}: {
  document: IFundDataDocument;
  txnId: string;
}) => {
  const path = `/transactions/:txnId/dataroom/file-download-url/get/`;
  const fullPath = path.replace(":txnId", txnId);

  // Omit the 1st character (a "/" character)
  const filePath = document.fileUrl;

  return await new TapClient(fullPath, {
    filePath: filePath,
    version: "v2", // New dataroom download requires this argument on the api
  })
    .post()
    .then((resp) => {
      window.open(resp.url, "_blank");
      // TODO: Add analytics
      // TODO: Add error handling
    })
    .catch((resp: any) => {
      console.error(resp);
    });
};
