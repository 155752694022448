import { CircleChartData } from "experiences/common/CircleChart";

import {
  GpOutlook,
  GpOutlookLabels,
  IInvestmentsInsights,
  IQualitativeDataInsights,
  PerformanceToPlan,
  PerformanceToPlanLabels,
} from "./model";

// Company Data:
// 1. Does not have a report date
// 2. Has a table with the following columns:
//    - Company Name
//    - Fund Name
//    - Investment %
//    - Liquidity Timeline
//    - Outlook
//    - Performance to Plan
//    - Distress Indicator
//    - Size

// Overview:
// 1. Manager Assessment
// 1.1 Has a report date
// 1.2 Has 2 CircleChart components: Outlook, Performance to Budget
// 2. Liquidity Projection
// 2.1 This is a bar chart, todo: review the bar chart we're using to see if it can be reused here
// 3. Updates
// 4. Company Data

// Outlook Chart:
// 1. Map through investmentsInsights
// 2. Group by "gpOutlook"
// 3. Count the number of each type
// 4. Return an array of objects with the type and the count

// Performance to Plan Chart:
// 1. Map through investmentsInsights
// 2. Group by "performanceToPlan"
// 3. Count the number of each type
// 4. Return an array of objects with the type and the count
export const generateManagerAssessmentCharts = (
  insights: IInvestmentsInsights[],
) => {
  const outlookChartData: CircleChartData[] = insights
    .map((insight) => {
      return {
        name:
          insight.gpOutlook === null
            ? GpOutlook.NotMentioned
            : insight.gpOutlook,
        percentage: 1,
      };
    })
    .reduce((acc: CircleChartData[], insight) => {
      // group by name, and sum the percentages
      const existing = acc.find((item) => item.name === insight.name);
      if (existing) {
        existing.percentage =
          Number(existing.percentage) + Number(insight.percentage);
      } else {
        acc.push(insight);
      }
      return acc;
    }, [])
    .map((item) => {
      return {
        name: GpOutlookLabels[item.name],
        percentage: item.percentage,
      };
    });

  const performanceToPlanChartData: CircleChartData[] = insights
    .map((insight) => {
      return {
        name:
          insight.performanceToPlan === null
            ? PerformanceToPlan.NotMentioned
            : insight.performanceToPlan,
        percentage: 1,
      };
    })
    .reduce((acc: CircleChartData[], insight) => {
      // group by name, and sum the percentages
      const existing = acc.find((item) => item.name === insight.name);
      if (existing) {
        existing.percentage =
          Number(existing.percentage) + Number(insight.percentage);
      } else {
        acc.push(insight);
      }
      return acc;
    }, [])
    .map((item) => {
      return {
        name: PerformanceToPlanLabels[item.name],
        percentage: item.percentage,
      };
    });

  return { outlookChartData, performanceToPlanChartData };
};
