import { useEffect, useState } from "react";
import {
  IBidHoldingPrice,
  BidTransactionHolding,
  BidTypeEnum,
  IWholePortfolioPrice,
} from "../../../../domain/models/Bidding";
import { useTransactionsContext } from "../../../state/TransactionsContext";
import { useParams } from "react-router";
import { EnterBidPricingStepSubmitted } from "../../../state/TransactionsEvent";
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import * as yup from "yup";
import { StepHeader } from "../../StepHeader";
import { TOSSubheader } from "../../TOSSubheader";
import { IOSSwitch } from "../../../../../../common/components/IOSSwitch";
import { TableLayout } from "../../../../../common/TableLayout";
import { useFormik } from "formik";
import { getLastQuarter } from "../../../../../../common/components/formik/FormikQuarterPicker";
import { DollarAmount } from "../../../../../../common/@types/app/DollarAmount";
import FormikTextField from "../../../../../../common/components/formik/FormikTextField";
import TextField from "../../../../../../common/components/TextField";
import { Percentage } from "../../../../../../common/@types/app/Percentage";
import { Button } from "common/components/Button";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { TableFundLogo } from "common/components/TableFundLogo";
import { FUND_LOGO_URL } from "common/constants/platform";

interface IEnterBidPricingProps {
  txnHoldings: BidTransactionHolding[];
}

export const EnterBidPricing: React.FC<IEnterBidPricingProps> = ({
  txnHoldings,
}) => {
  // alphabatize holdings by issuer name
  txnHoldings.sort((a, b) => {
    if (a.issuerName < b.issuerName) return -1;
    if (a.issuerName > b.issuerName) return 1;
    return 0;
  });

  const { emitEvent } = useTransactionsContext();
  const { txnId } = useParams();

  const wholePortfolioCheckedInitial = false;
  const [wholePortfolioOn, setWholePortfolioOn] = useState<boolean>(
    wholePortfolioCheckedInitial,
  );

  const [partialBidPrices, setPartialBidPrices] = useState<IBidHoldingPrice[]>(
    [],
  );

  const onPartialBidPriceChange = (price: IBidHoldingPrice) => {
    setPartialBidPrices((prev) => {
      const copy = [...prev];
      const existingIndex = copy.findIndex(
        (iter) => iter.txnHoldingId == price.txnHoldingId,
      );
      if (price.price != undefined && price.referenceDate != undefined) {
        if (existingIndex >= 0) {
          copy[existingIndex] = price;
          return copy;
        } else {
          copy.push(price);
          return copy;
        }
      } else {
        copy.splice(existingIndex, 1);
        return copy;
      }
    });
  };

  const [wholePortfolioPrice, setWholePortfolioPrice] =
    useState<IWholePortfolioPrice>({});

  const onWholePortfolioPriceChange = (price: IWholePortfolioPrice) => {
    setWholePortfolioPrice(price);
  };

  const onSaveAndContinue = () => {
    if (
      (wholePortfolioOn && wholePortfolioPrice.price) ||
      (!wholePortfolioOn && partialBidPrices.length > 0)
    )
      emitEvent!(
        new EnterBidPricingStepSubmitted({
          txnId: txnId!,
          form: wholePortfolioOn
            ? {
                bidType: BidTypeEnum.WholePortfolio,
                txnId: txnId!,
                details: wholePortfolioPrice,
              }
            : {
                bidType: BidTypeEnum.Partial,
                txnId: txnId!,
                details: partialBidPrices,
              },
        }),
      );
  };

  return (
    <Stack p={8}>
      {/* <form onSubmit={formik.submitForm}> */}
      <form>
        <Stack spacing={4}>
          <Stack spacing={4}>
            <Stack>
              <StepHeader text="Enter Bid Pricing" />
              <TOSSubheader />
            </Stack>
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IOSSwitch
                  defaultChecked={wholePortfolioCheckedInitial}
                  onChange={(_, checked) => {
                    setWholePortfolioOn(checked);
                  }}
                />
                <Typography>Whole portfolio deal only.</Typography>
              </Stack>
              <TableLayout
                headerStyles={{ fontSize: "12px" }}
                minWidth="1200px"
                containerProps={{
                  sx: {
                    border: "1px solid #DBDCDA",
                  },
                }}
                headerElems={[
                  {
                    label: "name",
                    key: "fundName",
                    width: "25%",
                  },
                  {
                    label: "ref date",
                    key: "refDate",
                    width: "10%",
                  },
                  {
                    label: "commitment",
                    key: "nav",
                    width: "10%",
                  },
                  {
                    label: "unfunded",
                    key: "nav",
                    width: "10%",
                  },
                  {
                    label: "NAV",
                    key: "nav",
                    width: "10%",
                  },
                  {
                    label: "price",
                    key: "committed",
                    width: "20%",
                  },
                  {
                    label: "price (% of NAV)",
                    key: "contributed",
                    width: "10%",
                  },
                ]}
                withLeadingEmptyColumn
                rows={
                  <>
                    {txnHoldings.map((holding) => (
                      <HoldingPricingRow
                        txnHolding={holding}
                        pricingEnabled={!wholePortfolioOn}
                        onPriceChanged={onPartialBidPriceChange}
                      />
                    ))}
                    <TotalPricingRow
                      pricingEnabled={wholePortfolioOn}
                      totalNav={txnHoldings
                        .map((holding) => holding.holding.bookValue)
                        .reduce((prev, curr) => prev + curr)}
                      totalPrice={
                        partialBidPrices.length > 0
                          ? partialBidPrices
                              .map((price) => +price.price!)
                              .reduce((prev, curr) => prev + curr)
                          : 0
                      }
                      onPriceChanged={onWholePortfolioPriceChange}
                      totalUnfunded={txnHoldings
                        .map(
                          (holding) =>
                            holding.holding.committed -
                            holding.holding.contributed,
                        )
                        .reduce((prev, curr) => prev + curr)}
                      totalCommitment={txnHoldings
                        .map((holding) => holding.holding.committed)
                        .reduce((prev, curr) => prev + curr)}
                    />
                  </>
                }
              />
            </Stack>
            <Box width={400}>
              <Button fullWidth size="large" onClick={onSaveAndContinue}>
                Save & Continue
              </Button>
            </Box>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

interface IHoldingPricingRowProps {
  txnHolding: BidTransactionHolding;
  pricingEnabled: boolean;
  onPriceChanged: (price: IBidHoldingPrice) => void;
}

interface HoldingPricingRowFormValues {
  referenceDate: string;
  priceDollar?: string;
  pricePercent?: string;
}

export const HoldingPricingRow: React.FC<IHoldingPricingRowProps> = ({
  txnHolding,
  pricingEnabled,
  onPriceChanged,
}) => {
  const validationSchema = yup.object({
    referenceDate: pricingEnabled
      ? yup.date().required("Required").default(getLastQuarter())
      : yup.date(),
    priceDollar: pricingEnabled
      ? yup.number().required("Required")
      : yup.number(),
    pricePercent: pricingEnabled
      ? yup.number().required("Required")
      : yup.number(),
  });

  const initialVals: HoldingPricingRowFormValues = {
    referenceDate: "",
    priceDollar: undefined,
    pricePercent: undefined,
  };

  const makeNumber: (num: any) => number = (num) => {
    if (num == undefined || num == "") return undefined;
    if (isNaN(num))
      return +num.replaceAll(",", "").replaceAll("$", "").replaceAll("%", "");
    return num;
  };

  const formik = useFormik({
    initialValues: initialVals,
    validationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const numberPriceDollar = makeNumber(formik.values.priceDollar);
    onPriceChanged({
      price: numberPriceDollar,
      txnHoldingId: txnHolding.id,
      referenceDate: formik.values.referenceDate,
    });
  }, [formik.values.priceDollar]);

  const BuildIfPricingEnabled = (child: React.ReactNode) => {
    return pricingEnabled ? (
      child
    ) : (
      <Typography fontWeight="bold">-</Typography>
    );
  };

  return (
    <TableRow>
      <TableCell>
        {" "}
        <TableFundLogo
          imgSrc={`${FUND_LOGO_URL}${txnHolding.holding.issuerId}.jpg`}
        />
      </TableCell>
      <TableCell>
        <Typography fontWeight="bold">{txnHolding.issuerName}</Typography>
        <Typography fontSize={12} fontWeight="bold" color={"#848484"}>
          USD
        </Typography>
      </TableCell>
      <TableCell>
        {BuildIfPricingEnabled(
          <Box width={95} className="font-bold">
            {/* <FormikQuarterPicker
              formik={formik}
              name="referenceDate"
              defaultNow
              disabled
              inputProps={{
                variant: "outlined",
                InputLabelProps: { shrink: false },
                size: "small",
              }}
              label=""
            /> */}
            {new DateFormatter(
              txnHolding.holding.referenceDate,
            ).quarterFormatted()}
          </Box>,
        )}
      </TableCell>
      <TableCell>
        <Typography>
          {new DollarAmount(txnHolding.holding.committed)
            .formatted()
            .replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {new DollarAmount(
            txnHolding.holding.committed - txnHolding.holding.contributed,
          )
            .formatted()
            .replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {new DollarAmount(txnHolding.holding.bookValue)
            .formatted()
            .replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="bold" display="inline-block">
          {BuildIfPricingEnabled(
            <Box width={180}>
              <FormikTextField
                additionalOnChange={(event: any) => {
                  formik.setFieldValue(
                    "pricePercent",
                    event.target.value
                      ? (makeNumber(event.target.value) /
                          txnHolding.holding.bookValue) *
                          100
                      : event.target.value,
                  );
                }}
                formik={formik}
                name="priceDollar"
                TextFieldComponent={PricingBidCurrencyField}
                label=""
              />
            </Box>,
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="bold" display="inline-block">
          {BuildIfPricingEnabled(
            <Box width={100}>
              <FormikTextField
                additionalOnChange={(event: any) => {
                  formik.setFieldValue(
                    "priceDollar",
                    event.target.value
                      ? (makeNumber(event.target.value) / 100) *
                          txnHolding.holding.bookValue
                      : event.target.value,
                  );
                }}
                formik={formik}
                name="pricePercent"
                TextFieldComponent={PricingBidPercentField}
                label=""
              />
            </Box>,
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const PricingBidCurrencyField = ({ ...props }) => (
  <TextField variant="outlined" size="small" mask="currency" {...props} />
);

const PricingBidPercentField = ({ ...props }) => (
  <TextField variant="outlined" size="small" mask="percentage" {...props} />
);

interface ITotalPricingRowProps {
  pricingEnabled: boolean;
  totalPrice?: number;
  totalUnfunded: number;
  totalCommitment: number;
  totalNav: number;
  onPriceChanged: (price: IWholePortfolioPrice) => void;
}

interface TotalPricingRowFormValues {
  priceDollar?: number;
  pricePercent?: number;
}

export const TotalPricingRow: React.FC<ITotalPricingRowProps> = ({
  pricingEnabled,
  totalNav,
  totalPrice,
  onPriceChanged,
  totalCommitment,
  totalUnfunded,
}) => {
  const validationSchema = yup.object({
    priceDollar: pricingEnabled
      ? yup.number().required("Required")
      : yup.number(),
    pricePercent: pricingEnabled
      ? yup.number().required("Required")
      : yup.number(),
  });

  const initialVals: TotalPricingRowFormValues = {
    priceDollar: undefined,
    pricePercent: undefined,
  };

  const makeNumber = (num: any) => {
    if (num === undefined) return num;
    if (isNaN(num))
      return +num.replaceAll(",", "").replaceAll("$", "").replaceAll("%", "");
    return num;
  };

  const formik = useFormik({
    initialValues: initialVals,
    validationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const numberPriceDollar = makeNumber(formik.values.priceDollar);
    onPriceChanged({
      price: numberPriceDollar,
    });
  }, [formik.values.priceDollar]);

  return (
    <TableRow>
      <TableCell>
        <div></div>
      </TableCell>
      <TableCell>
        <Typography fontWeight="bold">{"Total"}</Typography>
      </TableCell>
      <TableCell>
        <div></div>
      </TableCell>
      <TableCell>
        <Typography>
          {new DollarAmount(totalCommitment).formatted().replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {new DollarAmount(totalUnfunded).formatted().replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {new DollarAmount(totalNav).formatted().replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="bold" display="inline-block">
          {pricingEnabled ? (
            <Box width={180}>
              <FormikTextField
                formik={formik}
                additionalOnChange={(event: any) => {
                  formik.setFieldValue(
                    "pricePercent",
                    event.target.value
                      ? (makeNumber(event.target.value) / totalNav) * 100
                      : event.target.value,
                  );
                }}
                name="priceDollar"
                TextFieldComponent={PricingBidCurrencyField}
                label=""
              />
            </Box>
          ) : (
            new DollarAmount(totalPrice!).formatted().replaceAll(" ", "")
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="bold" display="inline-block">
          {pricingEnabled ? (
            <Box width={120}>
              <FormikTextField
                formik={formik}
                additionalOnChange={(event: any) => {
                  formik.setFieldValue(
                    "priceDollar",
                    event.target.value
                      ? (makeNumber(event.target.value) / 100) * totalNav
                      : event.target.value,
                  );
                }}
                name="pricePercent"
                TextFieldComponent={PricingBidPercentField}
                label=""
              />
            </Box>
          ) : (
            new Percentage((totalPrice! / totalNav) * 100).formatted()
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
