import { useContext, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "shadcn/ui/Popover";

import { CaretUpDown } from "@phosphor-icons/react/dist/ssr";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { ToggleRow } from "experiences/common/ToggleRow";
import { EmptyState } from "common/components/EmptyState";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { Input } from "shadcn/ui/input";
import { Checkbox } from "shadcn/ui/checkbox";

export interface IEntityOption {
  id: string;
  label: string;
  enabled: boolean;
}

export const useEntitiesSelector = () => {
  const {
    entities,
    selectedEntitiesIds,
    onSelectEntity,
    onRemoveEntity,
    onToggleAllEntities,
  } = useContext(PortfolioContext);

  const entitiesOptions = entities
    .map((entity) => ({
      id: entity.id,
      label: entity.name,
      enabled: selectedEntitiesIds.includes(entity.id),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    entities,
    entitiesOptions,
    selectedEntitiesIds,
    onSelectEntity,
    onRemoveEntity,
    onToggleAllEntities,
  };
};

export const EntitiesSelector = ({
  entitiesOptions,
  selectedEntitiesIds,
  onSelectEntity,
  onRemoveEntity,
  onToggleAllEntities,
}: {
  entitiesOptions: IEntityOption[];
  selectedEntitiesIds: string[];
  onSelectEntity: (companyId: string) => void;
  onRemoveEntity: (companyId: string) => void;
  onToggleAllEntities: (checked: boolean) => void;
}) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const label =
    selectedEntitiesIds.length === entitiesOptions.length
      ? "All Entities Shown"
      : `${selectedEntitiesIds.length} of ${entitiesOptions.length} selected`;

  const isEnabled = entitiesOptions.length > 0;

  const filteredEntitiesOptions = entitiesOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase()),
  );

  const resetSearch = () => {
    setSearch("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const isAllSelected = entitiesOptions.every((option) => option.enabled);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="relative right-0">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          icon={<CaretUpDown />}
          iconRight
          onClick={() => setOpen((prev) => !prev)}
          aria-expanded={open}
          className="min-w-64 !justify-between relative h-9"
        >
          {label}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 -space-x-6 relative right-8">
        <div className="w-full h-full">
          {isEnabled ? (
            <div className="">
              <Input
                placeholder="Search entities"
                value={search}
                onChange={onSearchChange}
              />

              <div className="flex items-center space-x-2 h-14 border-b border-gray-200">
                <Checkbox
                  id="toggle-all"
                  onCheckedChange={onToggleAllEntities}
                  checked={isAllSelected}
                  defaultChecked={true}
                />
                <label
                  htmlFor="toggle-all"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Select all
                </label>
              </div>

              {filteredEntitiesOptions.map((option) => {
                const isSelected = selectedEntitiesIds.includes(option.id);
                return (
                  <ToggleRow
                    switchLeft
                    compact
                    title={option.label}
                    checked={isSelected}
                    disabled={selectedEntitiesIds.length === 1 && isSelected}
                    onChange={() => {
                      if (isSelected) {
                        onRemoveEntity(option.id);
                      } else {
                        onSelectEntity(option.id);
                      }
                    }}
                    tapGold
                    useCheckbox
                  />
                );
              })}
            </div>
          ) : (
            <EmptyState title="No entities available" className="!h-24" />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
