import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import {
  ILPLedTransaction,
  ITransactionSummary,
  LPLedTransaction,
  TransactionType,
} from "../models/Transaction";
import { ITenderOffer, TenderOffer } from "../models/Tender";

export class GetTransactionSummary
  implements IAsyncUseCase<IParams, ITransactionSummary>
{
  call: (params: IParams) => Promise<Either<Failure, ITransactionSummary>> =
    async ({ txnId }) => {
      return await new TapClient(`/transactions/${txnId}/summary/`)
        .get({})
        .then((resp: ITransactionSummary) => {
          resp = {
            ...resp,
            transaction:
              resp.transaction.type == TransactionType.LPLed
                ? new LPLedTransaction({
                    ...(resp.transaction as ILPLedTransaction),
                  })
                : new TenderOffer({
                    ...(resp.transaction as ITenderOffer),
                  }),
          };

          return right(resp);
        })
        .catch((resp: any) => {
          return left(new Failure(resp.response));
        });
    };
}

interface IParams {
  txnId: string;
}
