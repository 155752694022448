import { ArchivedBuyer } from "../models/ArchivedBuyer";

export const archivedBuyersMock: ArchivedBuyer[] = [
  {
    id: "543ffadb-6d63-4440-a591-612eff8d38a6",
    logoFileName: "VitalCapital.jpg",
    name: "Vital Capital",
    I: "TRUE",
    II: "TRUE",
    III: "TRUE",
    IV: "TRUE",
    opportunities: "TRUE",
    fundScope: "ALL",
    type: "Secondaries Fund",
    checkSize: "$50M - $1,000M",
    staple: "NO",
  },
];
