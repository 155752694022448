const DownloadDownArrow = (props = {}) => (
  <svg
    width={10}
    height={13.75}
    viewBox="0 0 10 13.75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.688 0.75c0.388 0 0.703 0.31 0.703 0.693v7.198l2.797 -2.603c0.262 -0.274 0.764 -0.283 1.026 -0.007 0.263 0.28 0.17 0.763 -0.058 1.013l-3.983 3.704c-0.324 0.237 -0.638 0.263 -0.967 0L0.222 7.043c-0.237 -0.24 -0.323 -0.738 -0.058 -1.013 0.263 -0.273 0.784 -0.23 1.026 0.007l2.798 2.603V1.443A0.7 0.7 0 0 1 4.689 0.75Zm3.986 11.11a0.7 0.7 0 0 1 0.703 0.696 0.7 0.7 0 0 1 -0.703 0.696H0.703a0.7 0.7 0 0 1 -0.703 -0.696c0 -0.383 0.313 -0.696 0.703 -0.696h7.968Z"
      fill="#21272D"
    />
  </svg>
);
export default DownloadDownArrow;
