import { useNavigate, generatePath } from "react-router";

import { LP_ROUTES } from "common/constants/routes";
import { cn } from "common/utils";
import {
  StyledTableRow,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { ITabTableColumn } from "experiences/funds/presentation/components/Table";
import { BarLoader } from "common/components/BarLoader";
import { FUND_LOGO_URL } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";
import { VIEWPOINT_FUND_ID } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";
import {
  CashflowTypeLabels,
  IPortfolioCashflows,
} from "experiences/portfolio-v2/domain/models/PortfolioCashflows";

enum ColumnKeys {
  Fund = "fund",
  NoticeId = "noticeId",
  Type = "type",
  Amount = "amount",
  WireDate = "wireDate",
  Description = "description",
}

const columnLabels: Record<ColumnKeys, string> = {
  [ColumnKeys.Fund]: "Fund",
  [ColumnKeys.Type]: "Type",
  [ColumnKeys.Amount]: "Amount",
  [ColumnKeys.WireDate]: "Wire Date",
  [ColumnKeys.Description]: "Description",
  [ColumnKeys.NoticeId]: "Notice ID",
};

const columns: ITabTableColumn[] = [
  {
    label: columnLabels[ColumnKeys.Fund],
    key: ColumnKeys.Fund,
    align: TableAlignment.LEFT,
    className: "!w-48",
  },

  {
    label: columnLabels[ColumnKeys.Type],
    key: ColumnKeys.Type,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.Amount],
    key: ColumnKeys.Amount,
    align: TableAlignment.RIGHT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.WireDate],
    key: ColumnKeys.WireDate,
    align: TableAlignment.RIGHT,
    className: "!w-36",
  },
  {
    label: columnLabels[ColumnKeys.Description],
    key: ColumnKeys.Description,
    align: TableAlignment.LEFT,
    className: "!w-96",
  },
  {
    label: columnLabels[ColumnKeys.NoticeId],
    key: ColumnKeys.NoticeId,
    align: TableAlignment.LEFT,
    className: "!w-36",
  },
];

// This is almost a 1:1 copy of SummaryTable.tsx BUT for lookthrough soi, meaning IHoldingRow has a different set of fields
const useCashflowsTable = ({
  data,
  loading,
}: {
  data: IPortfolioCashflows[];
  loading: boolean;
}) => {
  const navigate = useNavigate();

  const rows = data.map((row) => ({
    ...row,
  }));

  const handleRowClick = (rowId: string) => {
    const path = generatePath(LP_ROUTES.FundsFundDetail, {
      fundId: VIEWPOINT_FUND_ID,
    });
    navigate(path);
  };

  const renderRow = (row: IPortfolioCashflows) => {
    const imgSrc = `${FUND_LOGO_URL}/${row.issuerId}.jpg`;

    return (
      <>
        <TableCell className={cn("select-none overflow-ellipsis !pt-1 !pb-1")}>
          <div className="flex items-center gap-2">
            <TableFundLogo imgSrc={imgSrc}>{row.fund}</TableFundLogo>
          </div>
        </TableCell>
        <TableCell className="!w-36 !max-w-36 text-left">
          {CashflowTypeLabels[row.type]}
        </TableCell>
        <TableCell className="!w-36 !max-w-36 text-right">
          {tableCellDataFormatter({
            value: row.amount,
            format: "currency",
          })}
        </TableCell>
        <TableCell className="!w-36 !max-w-36 text-right">
          {tableCellDataFormatter({
            value: row.wireDate,
            format: "date",
          })}
        </TableCell>
        <TableCell className="!w-96 !max-w-96 text-left">
          {row.description}
        </TableCell>
        <TableCell className="!w-36 !max-w-36 text-left">
          {row.noticeId}
        </TableCell>
      </>
    );
  };

  return { rows, renderRow };
};

export const CashflowsTable = ({
  data,
  loading,
}: {
  data: IPortfolioCashflows[];
  loading: boolean;
}) => {
  const { rows, renderRow } = useCashflowsTable({ data, loading });

  return (
    <>
      <div className="w-full max-w-full overflow-x-scroll min-h-96 h-auto relative pb-12">
        <TapTable
          data={loading ? [] : rows}
          columns={columns}
          layoutFixed
          isLoading={loading}
          className="w-full min-w-[700px]"
          renderRow={renderRow}
          showEmptyState
          emptyStateTitle="No company cashflows found"
          emptyStateDescription="No company cashflows found for the selected report date and entities."
        />
        {loading && <BarLoader />}
      </div>
    </>
  );
};
