import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import * as yup from "yup";

import { transactionsClient } from "common/clients/ApolloClient";

import { GetTransactionFundDocuments } from "experiences/transactions/domain/usecases/GetTransactionFundDocuments";
import { RemoveFundInterestDocuments } from "experiences/transactions/domain/usecases/RemoveFundInterestDocuments";
import { UploadTransactionClassifyDocuments } from "experiences/transactions/domain/usecases/UploadTransactionClassifyDocuments";
import toast from "react-hot-toast";
import { FORCE_CLASSIFY_DOCUMENTS_MOCK } from "../TransactionFundDataVerifyPermission";
import {
  classifyDocumentsMock,
  classifyHoldingsMock,
} from "./classifyDocumentsMock";
import { documentTypeOptions } from "./model";
import { ITransactionHolding } from "./useAddFundInterestForm";
import {
  TransactionHolding,
  useTransactionHoldings,
} from "./useTransactionHoldings";

export interface FundInterestDocument {
  fileName: string;
  fileId: string;
  reportId: string;
  transactionDocumentId: string;
  documentProcessed: boolean;
  holdingId: string | null;
  fundId: string | null;
}

interface ClassifyDocumentsPayload {
  transactionDocuments: {
    fileName: string;
    fileId: string;
    fundId: string;
    holdingId: string;
  }[];
  transactionId: string;
}

// Specs:
// Fund Interest is used to render the list of documents, related to what files the user uploaded in the previous step
// Holdings is the list of holdings that the user has attached to this transaction, will be rendered in the "fund interest" table dropdown
export const useClassifyDocuments = ({
  goToNextStep,
  goToFirstStep,
}: {
  goToNextStep: () => void;
  goToFirstStep: () => void;
}) => {
  const { txnId } = useParams();
  const [showAddFundInterest, setShowAddFundInterest] = useState(false);
  const [clickedRemoveDocument, setClickedRemoveDocument] = useState(false);
  const [currentFileId, setCurrentFileId] = useState<string | null>(null);

  const openAddFundInterestModal = ({ fileId }: { fileId: string }) => {
    setShowAddFundInterest(true);
    setCurrentFileId(fileId);
  };

  const closeAddFundInterestModal = () => {
    setShowAddFundInterest(false);
  };

  const [uploadFundInterests, { loading: uploadFundInterestsLoading }] =
    useMutation(UploadTransactionClassifyDocuments, {
      client: transactionsClient,
    });

  const {
    data,
    loading: documentsLoading,
    refetch: refetchDocuments,
  } = useQuery<{
    documentsInformation: FundInterestDocument[];
  }>(GetTransactionFundDocuments, {
    client: transactionsClient,
    variables: {
      transactionId: txnId,
    },
    fetchPolicy: "no-cache",
  });

  const [removeDocuments, { loading: removeDocumentsLoading }] = useMutation(
    RemoveFundInterestDocuments,
    {
      client: transactionsClient,
    },
  );

  const documents = FORCE_CLASSIFY_DOCUMENTS_MOCK
    ? classifyDocumentsMock.data.documentsInformation
    : data?.documentsInformation || [];

  const documentsWithoutHoldingId = documents.filter(
    (document) => document.holdingId === null || document.holdingId === "",
  );

  let {
    holdings,
    loading: holdingsLoading,
    refetch: refetchHoldings,
  } = useTransactionHoldings({
    transactionId: txnId,
  });

  if (FORCE_CLASSIFY_DOCUMENTS_MOCK) {
    holdings = classifyHoldingsMock.data.holdings;
  }

  useEffect(() => {
    if (data?.documentsInformation.length === 0 && clickedRemoveDocument) {
      goToFirstStep();
    }
  }, [data?.documentsInformation.length, clickedRemoveDocument]);

  const validationSchema = useMemo(() => {
    return documentsWithoutHoldingId.reduce(
      (acc, doc) => {
        acc[`${doc.fileId}-holdingId`] = yup
          .string()
          .required("Please select a fund interest or create one.");
        return acc;
      },
      {} as Record<string, yup.ObjectSchema<any>>,
    );
  }, [documents]);

  // Classify Documents Form
  const form = useFormik({
    initialValues: {},
    validationSchema: yup.object(validationSchema),
    onSubmit: (values: { [key: string]: string }) => {
      const payload: ClassifyDocumentsPayload = {
        // use documentsWithoutHoldingId because those are the real payload
        transactionDocuments: documentsWithoutHoldingId.map((doc) => {
          const holdingForDocument = holdings.find(
            (h) => h.id === values[`${doc.fileId}-holdingId`],
          );

          const fundId = holdingForDocument?.holding.issuerId;
          // Please note we're looking for holding[n].holding.id instead of holding[n].id
          const holdingId = holdingForDocument?.holding.id;
          // Because we're using the holding[n].holding.id, I'm using the holding[n].id to get the parent holding id
          const parentHoldingId = holdingForDocument?.id;

          if (!fundId) {
            throw new Error("Fund ID not found");
          }

          // Technically this is not required, but it's a good check to make sure we're not assigning the wrong holding
          if (parentHoldingId !== values[`${doc.fileId}-holdingId`]) {
            throw new Error("Holding ID does not match");
          }

          return {
            fileName: doc.fileName,
            fileId: doc.fileId,
            fundId,
            holdingId,
          };
        }),
        transactionId: txnId,
      };

      uploadFundInterests({
        variables: payload,
      })
        .then(() => {
          goToNextStep();
          form.setSubmitting(false);
        })
        .catch(() => {
          toast.error("Failed to classify documents");
          form.setSubmitting(false);
        });
    },
    validateOnChange: false,
  });

  const onHoldingClick = (
    doc: FundInterestDocument,
    holding: TransactionHolding,
  ) => {
    form.setFieldValue(`${doc.fileId}-holdingId`, holding.id);
  };

  const removeDocument = async (doc: FundInterestDocument) => {
    setClickedRemoveDocument(true);
    form.setFieldValue(`${doc.fileId}-holdingId`, "");
    await removeDocuments({
      variables: {
        transactionId: txnId,
        filesIds: [doc.fileId],
      },
    })
      .then(() => {
        toast.success("Document removed");
      })
      .catch(() => {
        toast.error("Failed to remove document");
      })
      .finally(() => {
        refetchDocuments();
      });
  };

  // When a holding is added, update the form with the new holding id
  // TODO: merge ITransactionHolding with TransactionHolding
  const onHoldingAdded = async ({
    holding,
  }: {
    holding: ITransactionHolding;
  }) => {
    // Update the form with the new holding id
    await form.setFieldValue(`${currentFileId}-holdingId`, holding.id);

    // Reset the current file id
    setCurrentFileId(null);
  };

  return {
    showAddFundInterest,
    openAddFundInterestModal,
    closeAddFundInterestModal,
    documents,
    holdings,
    form,
    documentTypeOptions,
    refetchHoldings,
    uploadFundInterestsLoading,
    documentsLoading,
    onHoldingClick,
    documentsWithoutHoldingId,
    removeDocument,
    removeDocumentsLoading,
    onHoldingAdded,
    currentFileId,
  };
};
