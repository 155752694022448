import { ITransaction, Transaction, TransactionStatus } from "./Transaction";

export interface ITenderPeriodMaterial {
  title: string;
  subItems: string[];
  pcptCapacity: string;
  correspondingStatus: TransactionStatus;
}

export const tenderPeriodMaterials: ITenderPeriodMaterial[] = [
  {
    pcptCapacity: "LP",
    title: "Preliminary Period",
    subItems: [
      "Reach out to Limited Partners to gauge interest.",
      "Collect preliminary information on assets and capital accounts.",
    ],
    correspondingStatus: TransactionStatus.PreliminaryInterest,
  },
  {
    subItems: [
      "Provide buyers access to a virtual data room with fund documents and performance data.",
      "Allow buyers to conduct initial financial, legal, and compliance reviews.",
    ],
    title: "Round 1: Non-Binding Bids",
    pcptCapacity: "Buyers",
    correspondingStatus: TransactionStatus.Round1,
  },
  {
    subItems: [
      "Perform more detailed analysis on the fund's assets, liabilities, and operations.",
      "Hold an investor call with Viewpoint and potential buyers.",
      "Solicit final binding offers from buyers, including price and transaction terms.",
    ],
    title: "Round 2: Binding Bids",
    pcptCapacity: "Buyers",
    correspondingStatus: TransactionStatus.Round2,
  },
  {
    subItems: [
      "Present final offers to sellers for consideration.",
      "Sellers decide whether to accept offers based on price and terms.",
      "Execute commitment letters from sellers who accept the offers.",
    ],
    title: "Participation Period",
    pcptCapacity: "LP",
    correspondingStatus: TransactionStatus.Participation,
  },
  {
    subItems: [
      "Finalize and execute purchase and sale agreements.",
      "Arrange for the transfer of funds from buyers to sellers.",
      "Complete the transfer of ownership interests in the PE fund.",
    ],
    title: "Closing Period",
    pcptCapacity: "All",
    correspondingStatus: TransactionStatus.Settled,
  },
];

export interface ITenderOffer extends ITransaction {
  currentStatus: TransactionStatus;
  offerPrice: number;
  capacity: number;
  recurring: boolean;
  summary: Object;
}

export class TenderOffer extends Transaction implements ITenderOffer {
  currentStatus: TransactionStatus;
  offerPrice: number;
  capacity: number;
  recurring: boolean;
  summary: Object;

  constructor({
    currentStatus,
    offerPrice,
    capacity,
    recurring,
    summary,
    ...other
  }: ITenderOffer) {
    super({ ...other });
    this.currentStatus = currentStatus;
    this.offerPrice = offerPrice;
    this.capacity = capacity;
    this.recurring = recurring;
    this.summary = summary;
  }
}

export interface ITenderListing extends ITransaction {
  currentStatus: TransactionStatus;
}

export class TenderListing extends Transaction implements ITenderListing {
  currentStatus: TransactionStatus;

  constructor({ currentStatus, ...other }: ITenderListing) {
    super({ ...other });
    this.currentStatus = currentStatus;
  }
}
