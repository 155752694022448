import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES, Button } from "common/components/Button";
import {
  TapTable,
  TableAlignment,
  TableCell,
} from "experiences/funds/presentation/components/Table";

import { TapCheckbox } from "common/components/Checkbox";
import TextChip from "experiences/common/TextChip";
import { useTransactionsContext } from "experiences/transactions/presentation/state/TransactionsContext";
import { IMockedParticipant } from "../../mock/GP";
import { Envelope, Trash } from "@phosphor-icons/react";
import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";

// Filtered by type: "team member", on IMockedParticipant
export const GpTransactionTeamMembersTable = ({
  participants,
  emptyActions,
  selectParticipants,
  unselectParticipants,
  selectedParticipants = [],
}: {
  participants: IMockedParticipant[];
  currentParticipant?: IMockedParticipant;
  selectParticipants: (pcpts: IMockedParticipant[]) => void;
  unselectParticipants: (pcpts: IMockedParticipant[]) => void;
  selectedParticipants: IMockedParticipant[];
  emptyActions?: [
    {
      label: string;
      onClick: () => void;
      type: BUTTON_TYPES;
      icon?: React.ReactNode;
    },
  ];
}) => {
  const { emitPcptEvent } = useTransactionsContext();

  const removeParticipant = (participant: IMockedParticipant) => {
    // TODO: implement removeParticipant using emitPcptEvent
    console.info({ participant, emitPcptEvent });
  };

  const toggleAllChecked = participants.every((pcpt) =>
    selectedParticipants.includes(pcpt),
  );

  const checkAll = () => {
    toggleAllChecked
      ? unselectParticipants(participants)
      : selectParticipants(participants);
  };

  // actually, let's not filter anything
  const filteredParticipants = participants.filter((participant) => {
    return participant.type === "team member";
  });

  if (filteredParticipants.length === 0) {
    return (
      <EmptyState
        title="No members yet"
        description="Invite people to the transaction or connect with the Tap team to add people."
        actions={emptyActions}
      />
    );
  }

  const handleCheckboxClick = ({
    checked,
    pcpt,
  }: {
    checked: boolean;
    pcpt: IMockedParticipant;
  }) => {
    checked ? selectParticipants([pcpt]) : unselectParticipants([pcpt]);
  };

  return (
    <TapTable
      skipTopBorder
      emptyStateTitle="No Participants yet"
      emptyStateDescription="Invite people to the transaction or connect with the Tap team to add people."
      emptyStateActions={emptyActions}
      showActionsOnHover
      data={filteredParticipants}
      columns={[
        {
          key: "firm",
          label: "Firm",
          align: TableAlignment.LEFT,
        },
        {
          key: "name",
          label: "Name",
          align: TableAlignment.LEFT,
        },
        {
          key: "status",
          label: "Status",
          align: TableAlignment.LEFT,
        },
        {
          key: "last_access",
          label: "Last Access",
          align: TableAlignment.LEFT,
        },
        {
          key: "actions",
          label: "",
          align: TableAlignment.LEFT,
        },
      ]}
      renderRow={(participant: IMockedParticipant) => {
        const isSameAsViewer = false;

        const isChecked = !!selectedParticipants.find(
          (pcpt) => pcpt.id === participant.id,
        );

        const showSendInviteButton = participant.status === "Not Invited";

        return (
          <>
            <TableCell>
              <StyledFundNameAndImage>
                <img
                  src={`https://cdn.usetap.com/funds/logos/${participant.logoFileName}`}
                  alt="Fund logo"
                />{" "}
                {participant.pcptName}
              </StyledFundNameAndImage>
            </TableCell>
            <TableCell>
              {participant.contactName}

              {isSameAsViewer && (
                <TextChip
                  success
                  text="you"
                  size="small"
                  style={{ marginLeft: 8 }}
                />
              )}
            </TableCell>
            <TableCell>{participant.status}</TableCell>
            <TableCell>{participant.lastActivity}</TableCell>
            <TableCell>
              <div className="flex flex-1 gap-2 justify-end">
                {showSendInviteButton && (
                  <Button
                    onClick={() => removeParticipant(participant)}
                    type={BUTTON_TYPES.SECONDARY}
                    icon={<Envelope />}
                    iconRight
                  >
                    Send Invite
                  </Button>
                )}
                <Button
                  onClick={() => removeParticipant(participant)}
                  icon={<Trash />}
                  iconRight
                >
                  Remove
                </Button>
              </div>
            </TableCell>
          </>
        );
      }}
    />
  );
};
