export enum IFundDataReportName {
  CapitalAccounts = "capital_accounts_dates",
  DealBreakdown = "deal_breakdown_dates",
  FundExposureSummary = "fund_exposure_summary_dates",
  SoiLookthrough = "soi_lookthrough_dates",
  QualitativeData = "qualitative_data_dates",
  CompanyLoan = "company_loan_dates",
  InterimCashflows = "interim_cashflows_dates",
}

export interface IFundDataReportDate {
  name: IFundDataReportName;
  dates: string[];
}

export interface IFundDataReportDates {
  reportsDates: IFundDataReportDate[];
}
