import { gql } from "@apollo/client";

export const GetTransactionFundDataReportDates = gql`
  query ReportsDates($transactionId: String!) {
    reportsDates(transactionId: $transactionId) {
      name
      dates
    }
  }
`;
