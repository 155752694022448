import { IDocument } from "experiences/documents/models/Document";

export class DocumentsState {}

export class DocumentSignFlowState extends DocumentsState {}

export class DocumentSignFlowLoading extends DocumentSignFlowState {}

export class NDASignatureFLowLoaded extends DocumentsState {}

export class SignatureScreenLoaded extends NDASignatureFLowLoaded {
  isSignatory: boolean;
  embedLink: string;
  signatoryEmail: string;
  isRedliner: boolean;

  constructor({
    embedLink,
    isSignatory,
    signatoryEmail,
    isRedliner,
  }: {
    embedLink: string;
    isSignatory: boolean;
    signatoryEmail: string;
    isRedliner: boolean;
  }) {
    super();
    this.embedLink = embedLink;
    this.isSignatory = isSignatory;
    this.signatoryEmail = signatoryEmail;
    this.isRedliner = isRedliner;
  }
}

export class RedliningPromptScreenLoaded extends NDASignatureFLowLoaded {}

export class RequestRedliningConfirmationScreenLoaded extends NDASignatureFLowLoaded {}

export class DelegateSignatureConfirmationScreenLoaded extends NDASignatureFLowLoaded {}

export class DocumentListState extends DocumentsState {}

export class DocumentListLoading extends DocumentListState {}

export class DocumentListLoaded extends DocumentListState {
  documents: IDocument[];

  constructor({ documents }: { documents: IDocument[] }) {
    super();
    this.documents = documents;
  }
}
