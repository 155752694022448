import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import TextChip from "experiences/common/TextChip";
import { CheckFat, FileText, Signature } from "@phosphor-icons/react";
import { TimelineStep } from "common/components/TimelineStep";
import React from "react";
import { IndicateInterest } from "./IndicateInterest";
import { FinalizeElection } from "./FinalizeElectionFlow";
import { generatePath, useNavigate, useParams } from "react-router";
import { LP_ROUTES, TenderDetailsRoutes } from "common/constants/routes";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { tenderPeriodMaterials } from "experiences/transactions/domain/models/Tender";
import {
  OverviewScreenLoaded,
  OverviewScreenLoading,
  TaskStatus,
} from "../state/tenders/TendersState";
import Loader from "common/components/TapLoader";
import { Task } from "../components/tenders/Task";

interface IProps {
  state: OverviewScreenLoading | OverviewScreenLoaded;
}

const OverviewScreen: React.FC<IProps> = ({ state }) => {
  if (state instanceof OverviewScreenLoading) return <Loader />;
  return (
    <div>
      <>
        <PageSectionTitleDivider>
          <div className="flex gap-2 items-center">
            <p>Action Items</p>
            <TextChip text={"1"} pending />
          </div>
        </PageSectionTitleDivider>
        <SignOfferLetterTask status={state.signOfferLetterStatus} />
        <FinalizeParticipationTask status={state.finalizeParticipationStatus} />
        <PreliminaryInterestTask status={state.indicateInterestStatus} />
        <ReviewTenderOfferTask status={state.viewOfferStatus} />
      </>
      <PageSectionTitleDivider>Timeline</PageSectionTitleDivider>
      <div>
        {state.periods.map((period) => {
          let materials = tenderPeriodMaterials.find(
            (m) => m.title === period.name,
          );
          return (
            <TimelineStep
              title={materials.title}
              subtitle={
                period.period.start &&
                new DateFormatter(period.period.start).date.toUTCString()
              }
              secondaryTitle={"Due"}
              secondarySubtitle={
                period.period.end &&
                new DateFormatter(period.period.end).date.toUTCString()
              }
              key={materials.correspondingStatus}
              completed={
                state.currentTenderStatus >= materials.correspondingStatus
              }
              current={
                state.currentTenderStatus == materials.correspondingStatus
              }
              bullets={materials.subItems}
              openByDefault={
                state.currentTenderStatus == materials.correspondingStatus
              }
              titleTokens={
                <TextChip
                  size="medium"
                  text={materials.pcptCapacity}
                  pending={materials.pcptCapacity === "Buyers"}
                  success={materials.pcptCapacity === "LP"}
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
};

interface ITaskVariantProps {
  status: TaskStatus;
}

const PreliminaryInterestTask: React.FC<ITaskVariantProps> = ({ status }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  if (status === TaskStatus.DontShow) return;

  const buttons =
    status === TaskStatus.NotStarted
      ? {
          primaryButtonText: "Indicate Interest",
          onPrimaryButtonClick: () => setOpen(true),
        }
      : {
          secondaryButtonText: "Review",
          onSecondaryButtonClick: () => setOpen(true),
        };

  return (
    <>
      <Task
        {...buttons}
        title={"Indicate Preliminary Interest"}
        description={
          "Indicate if you want to participate in the tender offer and how much you want to sell."
        }
        Icon={<CheckFat size={24} className="text-nomad-300" />}
      />
      <IndicateInterest open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const ReviewTenderOfferTask: React.FC<ITaskVariantProps> = ({
  status,
}) => {
  if (status === TaskStatus.DontShow) return;

  const buttons =
    status === TaskStatus.NotStarted
      ? {
          primaryButtonText: "Review Tender Offer",
          onPrimaryButtonClick: () => navigate(offerScreenRoute),
          secondaryButtonText: "Decline",
          onSecondaryButtonClick: () => navigate(offerScreenRoute),
        }
      : {
          secondaryButtonText: "Review",
          onSecondaryButtonClick: () => navigate(offerScreenRoute),
        };

  const navigate = useNavigate();
  const { txnId } = useParams();
  const offerScreenRoute =
    generatePath(LP_ROUTES.TenderDetails, { txnId }) +
    TenderDetailsRoutes.Offer;
  return (
    <Task
      {...buttons}
      title={"Review Tender Offer"}
      description={
        "View the final pricing and select the tender amount you want to request."
      }
      Icon={<FileText size={24} className="text-nomad-300" />}
    />
  );
};

export const FinalizeParticipationTask: React.FC<ITaskVariantProps> = ({
  status,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  if (status === TaskStatus.DontShow) return;

  const buttons =
    status === TaskStatus.NotStarted
      ? {
          primaryButtonText: "Finalize Participation",
          onPrimaryButtonClick: () => setOpen(true),
        }
      : {
          secondaryButtonText: "Review",
          onSecondaryButtonClick: () => setOpen(true),
        };

  return (
    <>
      <Task
        {...buttons}
        primaryButtonText={"Finalize Participation"}
        title={"Finalize Participation"}
        description={
          "Preview your tender transaction and confirm your participation"
        }
        Icon={<Signature size={24} className="text-nomad-300" />}
      />
      <FinalizeElection open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const SignOfferLetterTask: React.FC<ITaskVariantProps> = ({
  status,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  if (status === TaskStatus.DontShow) return;

  const buttons =
    status === TaskStatus.NotStarted
      ? {
          primaryButtonText: "Sign Letter",
          onPrimaryButtonClick: () => setOpen(true),
        }
      : {
          secondaryButtonText: "Review",
          onSecondaryButtonClick: () => setOpen(true),
        };
  return (
    <>
      <Task
        {...buttons}
        onPrimaryButtonClick={() => setOpen(true)}
        primaryButtonText={"Sign Offer Letter"}
        title={"Sign Offer Letter"}
        description={"Review terms one final time and sign the offer letter"}
        Icon={<Signature size={24} className="text-nomad-300" />}
      />
      <FinalizeElection open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default OverviewScreen;
