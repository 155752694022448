import { DateFormatter } from "common/@types/app/DateFormatter";
import { Button } from "common/components/Button";
import { IDocument } from "experiences/documents/models/Document";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";

interface IProps {
  documents: IDocument[];
}

// NOTE: This component is mocked up but not implemented because we don't have real data for it
export const SettingsDocumentsList: React.FC<IProps> = ({ documents }) => {
  return (
    <TapTable
      emptyStateTitle="There are no documents to display."
      data={documents}
      showActionsOnHover
      skipTopBorder
      columns={[
        {
          key: "name",
          label: "Document Name",
          align: TableAlignment.LEFT,
        },
        {
          key: "createdAt",
          label: "Date",
          align: TableAlignment.LEFT,
        },
        {
          key: "",
          label: "",
          align: TableAlignment.LEFT,
        },
      ]}
      // TODO: Add generic type for document
      renderRow={(doc: IDocument) => {
        return (
          <>
            <TableCell>{doc.name}</TableCell>
            <TableCell>
              {new DateFormatter(doc.createdAt).humanDate()}
            </TableCell>
            <TableCell hasActions>
              <Button onClick={() => window.open(doc.accessLink)}>
                Download
              </Button>
            </TableCell>
          </>
        );
      }}
    />
  );
};
