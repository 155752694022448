export interface IIconProps {
  width?: number;
  height?: number;
  color?: string;
  scale?: number;
  strokeWidth?: number;
}

interface ISvgIconWrapperProps extends IIconProps {
  clipPathId: string;
  strokeWidth: number;
  strokeLineCap?: "butt" | "round" | "square" | "inherit" | undefined;
  strokeLineJoin?: "miter" | "round" | "bevel" | "inherit" | undefined;
  pathDs: string[];
  fillRule?: "inherit" | "nonzero" | "evenodd";
  clipRule?: string;
  fill?: string;
}

export const SvgIconWrapper: React.FC<ISvgIconWrapperProps> = ({
  width = 20,
  height = 20,
  color = "white",
  clipPathId,
  scale,
  strokeWidth,
  strokeLineCap = "round",
  strokeLineJoin = "round",
  pathDs,
  fillRule = "evenodd",
  clipRule = "evenodd",
  fill = "transparent",
}) => {
  if (scale) {
    width = width * scale;
    height = height * scale;
  }
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath={`url(#${clipPathId})`}
        transform={`scale(${scale})`}
        fill={fill}
      >
        {pathDs.map((pathd, index) => (
          <path
            key={index}
            d={pathd}
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLineCap}
            strokeLinejoin={strokeLineJoin}
            fillRule={fillRule}
            fill={fill}
            clipRule={clipRule}
          />
        ))}
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
