import { createContext, useContext } from "react";
import { TransactionsEvent } from "./TransactionsEvent";
import { ListUserTransactions } from "../../domain/usecases/ListUserTransactions";
import {
  GpTransactionListMockState,
  TransactionAgreementsState,
  TransactionNDAState,
  TransactionSettingsState,
  TransactionsState,
} from "./TransactionsState";
import { ParticipantListState } from "./participants/ParticipantsState";
import { CreateTransactionParticipant } from "../../domain/usecases/CreateTransactionParticipant";
import { RemoveParticipant } from "../../domain/usecases/RemoveParticipant";
import { GetTransactionSummary } from "../../domain/usecases/GetTransactionSummary";
import { ListTransactionParticipants } from "../../domain/usecases/ListTransactionParticipants";
import { ListTransactionNDAs } from "../../domain/usecases/ListTransactionNDAs";
import { SignAllParticipantNDAs } from "../../domain/usecases/SignAllParticipantNDAs";
import { GetDataRoomFileDownloadURL } from "../../../portfolio/domain/usecases/GetDataRoomFileDownloadURL";
import { GetDataRoomZipDownloadURL } from "../../../portfolio/domain/usecases/GetDataRoomZipDownloadURL";
import { ApproveParticipantToInvite } from "../../domain/usecases/ApproveParticipantToInvite";
import { GetTransactionHoldingsForBidding } from "../../domain/usecases/GetTransactionHoldingsForBidding";
import { UpsertBidWithPricing } from "../../domain/usecases/UpsertBidWithPricing";
import { UploadDocumentToBid } from "experiences/transactions/domain/usecases/UploadDocumentToBid";
import { SubmitBid } from "experiences/transactions/domain/usecases/SubmitBid";
import { ListBidsOfUserOnTransaction } from "experiences/transactions/domain/usecases/ListBidsOfUserOnTransaction";
import { AcceptBid } from "experiences/transactions/domain/usecases/AcceptBid";
import { DeleteBid } from "experiences/transactions/domain/usecases/DeleteBid";
import { useManageTransactionsState } from "./TransactionsManager";
import { useManageParticipantState } from "./participants/ParticipantsManager";
import { SendParticipantsInvite } from "experiences/transactions/domain/usecases/SendParticipantsInvite";
import {
  NotifyDataroomFileProcess,
  ProcessDataroomFile,
  UploadDataroomFile,
} from "experiences/transactions/domain/usecases/UploadDataroomFile";
import { GetTransactionPolicies } from "experiences/transactions/domain/usecases/GetTransactionPolicies";
import { GetTransactionNDAs } from "experiences/transactions/domain/usecases/GetTransactionNDAs";
import { GetTransactionAgreements } from "experiences/transactions/domain/usecases/GetTransactionAgreements";
import { GetGpMockTransactions } from "experiences/transactions/domain/usecases/GetGpTransactionList";
import { TendersEvent } from "./tenders/TendersEvent";
import { TendersState } from "./tenders/TendersState";
import { useManageTendersState } from "./tenders/TendersManager";
import { DeclineParticipation } from "experiences/transactions/domain/usecases/tenders/DeclineParticipation";
import { DownloadOfferDoc } from "experiences/transactions/domain/usecases/tenders/DownloadOfferDoc";
import { FinalizeParticipation } from "experiences/transactions/domain/usecases/tenders/FinalizeParticipation";
import { GetHoldingsProfile } from "experiences/transactions/domain/usecases/tenders/GetHoldingsProfile";
import { ListOfferDocs } from "experiences/transactions/domain/usecases/tenders/ListOfferDocs";
import { GetParticipationInterest } from "experiences/transactions/domain/usecases/tenders/GetParticipationInterest";
import { GetTenderMaterials } from "experiences/transactions/domain/usecases/tenders/GetTenderMaterials";
import { useTPTransactionsState } from "./tp/TpTransactionsManager";
import { ListTPTransactions } from "experiences/transactions/domain/usecases/tp/ListTransactions";
import { TPTransactionsState } from "./tp/TPTransactionsState";
import { CreateThirdPartyTransaction } from "experiences/portfolio/domain/usecases/CreateThirdPartyTransaction";
import { GetTransactionSettings } from "experiences/transactions/domain/usecases/GetTransactionSettings";
import { UpdateTransactionName } from "experiences/transactions/domain/usecases/UpdateTransactionName";
import { UpdateTransactionSetting } from "experiences/transactions/domain/usecases/UpdateTransactionSetting";
import { GetTimeline } from "experiences/transactions/domain/usecases/GetTimeline";
import { UpsertTimeline } from "experiences/transactions/domain/usecases/UpsertTimeline";

export interface TenderProps {
  emitEvent?: (event: TendersEvent) => void;
  tenderDetailsState?: TendersState;
  overviewScreenState?: TendersState;
  offerScreenState?: TendersState;
  dataRoomScreenState?: TendersState;
  finalizeElectionFlowState?: TendersState;
}

export interface TPTransactionsProps {
  emitEvent?: (event: TransactionsEvent) => void;
  transactionListState?: TPTransactionsState;
}

export interface TransactionsContextProps {
  emitEvent?: (event: TransactionsEvent) => void;
  emitPcptEvent?: (event: TransactionsEvent) => void;
  txnsListState?: TransactionsState;
  participantsListState?: ParticipantListState;
  txnDetailsState?: TransactionsState;
  txnDetailsSettingsState?: TransactionsState;
  txnNDAsState?: TransactionsState;
  createBidFlowState?: TransactionsState;
  bidsListState?: TransactionsState;
  bidDetailsScreenState?: TransactionsState;
  transactionPoliciesState?: TransactionSettingsState;
  transactionNDAState?: TransactionNDAState;
  transactionAgreementsState?: TransactionAgreementsState;
  gpTransactionListState?: GpTransactionListMockState;
  dataroomUploadState?: TransactionsState;
  tender?: TenderProps;
  tpTransactions?: TPTransactionsProps;
}

const initialContextState: TransactionsContextProps = {};

const TransactionsContext = createContext(initialContextState);

interface ITransactionsStateProviderProps {
  children: React.ReactNode;
}

const TransactionsStateProvider = ({
  children,
}: ITransactionsStateProviderProps) => {
  const {
    txnsListState,
    emitEvent,
    txnDetailsState,
    txnDetailsSettingsState,
    txnNDAsState,
    createBidFlowState,
    bidsListState,
    bidDetailsScreenState,
    transactionPoliciesState,
    transactionNDAState,
    transactionAgreementsState,
    gpTransactionListState,
    dataroomUploadState,
  } = useManageTransactionsState({
    getDataRoomFileURL: new GetDataRoomFileDownloadURL(),
    getDataRoomZipURL: new GetDataRoomZipDownloadURL(),
    retrieveTransactions: new ListUserTransactions(),
    getTxnSummary: new GetTransactionSummary(),
    getTxnSettings: new GetTransactionSettings(),
    updateTransactionName: new UpdateTransactionName(),
    updateTransactionSetting: new UpdateTransactionSetting(),
    listTxnNDAs: new ListTransactionNDAs(),
    signPcptNdas: new SignAllParticipantNDAs(),
    getTxnHoldingsForBidding: new GetTransactionHoldingsForBidding(),
    upsertBidWithPricing: new UpsertBidWithPricing(),
    uploadDocumentToBid: new UploadDocumentToBid(),
    submitTxnBid: new SubmitBid(),
    listBidsOfUserOnTxn: new ListBidsOfUserOnTransaction(),
    acceptBid: new AcceptBid(),
    deleteBid: new DeleteBid(),
    uploadDataroomFile: new UploadDataroomFile(),
    processDataroomFile: new ProcessDataroomFile(),
    notifyDataroomProcessFile: new NotifyDataroomFileProcess(),
    getTransactionPolicies: new GetTransactionPolicies(),
    getTransactionNDAs: new GetTransactionNDAs(),
    getTransactionAgreements: new GetTransactionAgreements(),
    getGpTransactions: new GetGpMockTransactions(),
    createThirdPartyTransaction: new CreateThirdPartyTransaction(),
    getTimeline: new GetTimeline(),
    upsertTimeline: new UpsertTimeline(),
  });

  const { participantsListState, emitPcptEvent } = useManageParticipantState({
    approvePcptToInvite: new ApproveParticipantToInvite(),
    createTxnParticipant: new CreateTransactionParticipant(),
    retrieveParticipants: new ListTransactionParticipants(),
    removeParticipant: new RemoveParticipant(),
    sendParticipantsInvite: new SendParticipantsInvite(),
    txnDetailsState,
  });

  const tenderMgmt = useManageTendersState({
    declineParticipation: new DeclineParticipation(),
    downloadOfferDoc: new DownloadOfferDoc(),
    finalizeParticipation: new FinalizeParticipation(),
    getHoldingsProfile: new GetHoldingsProfile(),
    listOfferDocs: new ListOfferDocs(),
    getParticipationInterest: new GetParticipationInterest(),
    getTenderMaterials: new GetTenderMaterials(),
    submitParticipationInterest: new GetParticipationInterest(),
    getTenderSummary: new GetTransactionSummary(),
  });

  const tpTransactions: TPTransactionsProps = useTPTransactionsState({
    listTPTransactions: new ListTPTransactions(),
  });

  return (
    <TransactionsContext.Provider
      value={{
        txnsListState,
        emitEvent,
        emitPcptEvent,
        participantsListState,
        txnDetailsState,
        txnDetailsSettingsState,
        txnNDAsState,
        createBidFlowState,
        bidsListState,
        bidDetailsScreenState,
        transactionPoliciesState,
        transactionNDAState,
        transactionAgreementsState,
        gpTransactionListState,
        dataroomUploadState,
        tender: tenderMgmt,
        tpTransactions,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

export const useTransactionsContext = () => useContext(TransactionsContext);

export { TransactionsStateProvider, TransactionsContext };
