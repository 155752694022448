import { MicrosoftExcelLogo } from "@phosphor-icons/react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shadcn/ui/select";

import { Button, BUTTON_TYPES } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { PageWrapperPortfolioV2 } from "experiences/common/PageWrapper";
import { SelectDateDropdown } from "experiences/common/SelectDateDropdown";
import { EntitiesSelector } from "../components/EntitiesDropdown";
import {
  navByStrategyMock,
  PerformanceChartWrapper,
} from "../components/PerformanceChart";
import { PerformanceTable } from "../components/PerformanceTable";
import { portfolioPerformanceGroupingOptions } from "../components/TableGrouping";
import { usePortfolioPerformance } from "../components/usePortfolioPerformance";

export const PerformancePage = () => {
  const {
    loading,
    entitySelector,
    data,
    groupBy,
    onGroupByChange,
    handleDownloadClick,
    isDownloading,
    reportDatesOptions,
    onSelectedReportDateChange,
    selectedReportDate,
  } = usePortfolioPerformance();

  return (
    <PageWrapperPortfolioV2>
      <PageHead
        title="Performance"
        actions={
          <div className="flex items-center gap-4">
            <EntitiesSelector
              entitiesOptions={entitySelector.entitiesOptions}
              selectedEntitiesIds={entitySelector.selectedEntitiesIds}
              onSelectEntity={entitySelector.onSelectEntity}
              onRemoveEntity={entitySelector.onRemoveEntity}
              onToggleAllEntities={entitySelector.onToggleAllEntities}
            />
            <Button
              onClick={handleDownloadClick}
              type={BUTTON_TYPES.SECONDARY}
              icon={<MicrosoftExcelLogo />}
              loading={isDownloading}
            >
              Download Data
            </Button>
          </div>
        }
      />
      <PageSectionTitleDivider
        skipBorderBottom
        actions={
          <>
            <SelectDateDropdown
              value={selectedReportDate}
              onValueChange={onSelectedReportDateChange}
              options={reportDatesOptions}
              loading={loading}
            />
            <Button
              onClick={handleDownloadClick}
              type={BUTTON_TYPES.SECONDARY}
              icon={<MicrosoftExcelLogo />}
              loading={isDownloading}
            >
              Download Data
            </Button>
          </>
        }
        showBorderTop
      >
        <div className="flex gap-4 h-8 items-center">
          <label className="text-xs font-medium select-none cursor-default">
            Grouping by
          </label>
          <Select
            onValueChange={onGroupByChange}
            disabled={loading}
            value={groupBy}
          >
            <SelectTrigger className="w-[150px] h-8" disabled={loading}>
              <SelectValue placeholder="Select Grouping" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {portfolioPerformanceGroupingOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </PageSectionTitleDivider>

      <div className="items-center py-12 gap-8 justify-around hidden">
        <PerformanceChartWrapper
          data={navByStrategyMock}
          currentChart={groupBy}
        />
      </div>
      <PerformanceTable loading={loading} data={data} />
    </PageWrapperPortfolioV2>
  );
};
