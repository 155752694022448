import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { pipe } from "fp-ts/lib/function";
import { getOrElse, match } from "fp-ts/lib/Either";

import { ListUserTransactions } from "../../domain/usecases/ListUserTransactions";
import {
  BidCreationSuccessful,
  BidDetailsScreenLoaded,
  BidsListLoaded,
  EnterBidPricingScreenLoaded,
  GpTransactionListMockState,
  GpTransactionListMockStateLoaded,
  ListNDAsInProgress,
  ProcessDataRoomFileFinished,
  ProcessDataRoomFileProcessing,
  ResetDataroomFileState,
  ReviewBidScreenLoaded,
  TenderOverviewScreenLoaded,
  TransactionAgreementsState,
  TransactionDetailsLoading,
  TransactionDetailsScreenLoaded,
  TransactionNDAState,
  TransactionNDAsLoaded,
  TransactionPoliciesLoaded,
  TransactionSettingsAgreementsLoaded,
  TransactionSettingsNDAsLoaded,
  TransactionSettingsScreenLoaded,
  TransactionSettingsState,
  TransactionSettingsUpdateFailed,
  TransactionSettingsUpdateLoading,
  TransactionSettingsUpdateSuccess,
  TransactionsFailedToLoad,
  TransactionsLoaded,
  TransactionsLoading,
  TransactionsState,
  UploadDataRoomFileFailed,
  UploadDataRoomFileSubmitting,
  UploadDataRoomFileSuccess,
  UploadLetterOfIntentScreenLoaded,
} from "./TransactionsState";
import {
  AcceptBidButtonClicked,
  BidListDownloadDocumentClicked,
  BuyerBidRowClicked,
  CreateBidFlowTriggered,
  DeleteBidButtonClicked,
  DowloadDataRoomFilePressed,
  DownloadDataRoomZipPressed,
  EnterBidPricingStepSubmitted,
  NDAsParticipantSignatureSubmitted,
  RedirectedToBidsListScreen,
  RedirectedToSignNDAScreen,
  LoadTransactionDetails,
  ReviewBidStepDownloadDocumentClicked,
  ReviewBidStepSubmitted,
  TransactionsEvent,
  UploadLetterOfIntentStepSubmitted,
  LoadTransactionPolicies,
  LoadTransactionNDAs,
  LoadTransactionAgreements,
  GpTransactionListRequested,
  TransactionDetailsEvent,
  UploadDataRoomFilePressed,
  ProcessDataroomUploadPressed,
  NotifyDataroomUploadRequested,
  CreateThirdPartyTransactionFormSubmitted,
  TimelineEditModalSubmitted,
  TransactionNameUpdated,
  TransactionSettingUpdated,
} from "./TransactionsEvent";
import { Failure } from "../../../../common/@types/app/Failure";
import {
  ITransaction,
  ITransactionSettings,
  ITransactionSummary,
  LPLedTransaction,
  TransactionSettingType,
} from "../../domain/models/Transaction";
import { NoReturn } from "../../../../common/@types/app/UseCase";
import { GetTransactionSummary } from "../../domain/usecases/GetTransactionSummary";
import { ListTransactionNDAs } from "../../domain/usecases/ListTransactionNDAs";
import { SignAllParticipantNDAs } from "../../domain/usecases/SignAllParticipantNDAs";
import { ISignatureForm } from "../../domain/models/SignatureForm";

import { INDADoc } from "../../domain/models/TransactionDocument";
import { IDataRoom, IItem } from "../../domain/models/DataRoom";
import { GetDataRoomFileDownloadURL } from "../../../portfolio/domain/usecases/GetDataRoomFileDownloadURL";
import { GetDataRoomZipDownloadURL } from "../../../portfolio/domain/usecases/GetDataRoomZipDownloadURL";
import { GetTransactionHoldingsForBidding } from "../../domain/usecases/GetTransactionHoldingsForBidding";
import {
  Bid,
  BidPricingUpsertForm,
  BidTransactionHolding,
} from "../../domain/models/Bidding";
import { UpsertBidWithPricing } from "../../domain/usecases/UpsertBidWithPricing";
import { LP_ROUTES } from "common/constants/routes";
import { UploadDocumentToBid } from "experiences/transactions/domain/usecases/UploadDocumentToBid";
import { SubmitBid } from "experiences/transactions/domain/usecases/SubmitBid";
import {
  generateObjectAsFields,
  generateSlackMessagePayload,
  useSendSlackMessage,
} from "common/hooks/useSendSlackMessage";
import { ListBidsOfUserOnTransaction } from "experiences/transactions/domain/usecases/ListBidsOfUserOnTransaction";
import { AcceptBid } from "experiences/transactions/domain/usecases/AcceptBid";
import { DeleteBid } from "experiences/transactions/domain/usecases/DeleteBid";
import {
  UploadDataroomFile,
  ProcessDataroomFile,
  NotifyDataroomFileProcess,
} from "experiences/transactions/domain/usecases/UploadDataroomFile";
import { GetTransactionPolicies } from "experiences/transactions/domain/usecases/GetTransactionPolicies";
import { TransactionPolicy } from "experiences/transactions/domain/models/TransactionPolicy";
import { GetTransactionNDAs } from "experiences/transactions/domain/usecases/GetTransactionNDAs";
import { TransactionNDA } from "experiences/transactions/domain/models/TransactionNDA";
import { GetTransactionAgreements } from "experiences/transactions/domain/usecases/GetTransactionAgreements";
import { TransactionAgreement } from "experiences/transactions/domain/models/TransactionAgreement";
import { transactionPoliciesMock } from "experiences/transactions/domain/usecases/transactionPoliciesMock";
import {
  IGpTransaction,
  gpTransactionList,
} from "experiences/dashboard/mock/GP";
import { GetGpMockTransactions } from "experiences/transactions/domain/usecases/GetGpTransactionList";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { DEMO_TXN_ID } from "../gp/GpTransactionDetailPage";
import { CreateThirdPartyTransaction } from "experiences/portfolio/domain/usecases/CreateThirdPartyTransaction";
import { ICreateThirdPartyTransactionForm } from "experiences/portfolio/domain/models/CreateTransactionForm";
import { GetTransactionSettings } from "experiences/transactions/domain/usecases/GetTransactionSettings";
import { UpsertTimeline } from "experiences/transactions/domain/usecases/UpsertTimeline";
import { GetTimeline } from "experiences/transactions/domain/usecases/GetTimeline";
import { UpdateTransactionName } from "experiences/transactions/domain/usecases/UpdateTransactionName";
import { UpdateTransactionSetting } from "experiences/transactions/domain/usecases/UpdateTransactionSetting";
import toast from "react-hot-toast";

export interface IUseCases {
  retrieveTransactions: ListUserTransactions;
  getTxnSummary: GetTransactionSummary;
  getTxnSettings: GetTransactionSettings;
  updateTransactionName: UpdateTransactionName;
  updateTransactionSetting: UpdateTransactionSetting;
  listTxnNDAs: ListTransactionNDAs;
  signPcptNdas: SignAllParticipantNDAs;
  getDataRoomFileURL: GetDataRoomFileDownloadURL;
  getDataRoomZipURL: GetDataRoomZipDownloadURL;
  getTxnHoldingsForBidding: GetTransactionHoldingsForBidding;
  upsertBidWithPricing: UpsertBidWithPricing;
  uploadDocumentToBid: UploadDocumentToBid;
  submitTxnBid: SubmitBid;
  listBidsOfUserOnTxn: ListBidsOfUserOnTransaction;
  acceptBid: AcceptBid;
  deleteBid: DeleteBid;
  uploadDataroomFile: UploadDataroomFile;
  processDataroomFile: ProcessDataroomFile;
  notifyDataroomProcessFile: NotifyDataroomFileProcess;
  getTransactionPolicies: GetTransactionPolicies;
  getTransactionNDAs: GetTransactionNDAs;
  getTransactionAgreements: GetTransactionAgreements;
  getGpTransactions: GetGpMockTransactions;
  createThirdPartyTransaction: CreateThirdPartyTransaction;
  getTimeline: GetTimeline;
  upsertTimeline: UpsertTimeline;
}

const showLPMockTender = import.meta.env
  .VITE_APP_SLACK_SHOW_MOCK_LP_SIDE_TENDER;

export const useManageTransactionsState = ({
  retrieveTransactions,
  getTxnSummary,
  getTxnSettings,
  updateTransactionName,
  updateTransactionSetting,
  listTxnNDAs,
  signPcptNdas,
  getDataRoomFileURL,
  getDataRoomZipURL,
  getTxnHoldingsForBidding,
  upsertBidWithPricing,
  uploadDocumentToBid,
  submitTxnBid,
  listBidsOfUserOnTxn,
  acceptBid,
  deleteBid,
  uploadDataroomFile,
  processDataroomFile,
  notifyDataroomProcessFile,
  getTransactionPolicies,
  getTransactionNDAs,
  getTransactionAgreements,
  getGpTransactions,
  createThirdPartyTransaction,
  getTimeline,
  upsertTimeline,
}: IUseCases) => {
  const [txnsListState, setTxnsListState] = useState<TransactionsState>(
    new TransactionsLoading(),
  );
  const [gpTransactionListState, setGpTransactionListState] =
    useState<GpTransactionListMockState>(new GpTransactionListMockState());
  const [transactionPoliciesState, setTransactionPoliciesState] =
    useState<TransactionSettingsState>();
  const [transactionNDAState, setTransactionNDAState] =
    useState<TransactionNDAState>();
  const [transactionAgreementsState, setTransactionAgreementsState] =
    useState<TransactionAgreementsState>();
  const [dataroomUploadState, setDataroomUploadState] =
    useState<TransactionsState>();

  const { enqueueSnackbar } = useSnackbar();
  const { sendMessage } = useSendSlackMessage();
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const handleDownloadFromUrl = async (downloadUrl: string) => {
    window.open(downloadUrl);
  };

  const downloadDataRoomFile = (txnId: string, item: IItem) => {
    getDataRoomFileURL
      .call({ txnId: txnId, filePath: item.path })
      .then((resp) => {
        match<Failure, string, void>(
          (_: Failure) => {
            // something went wrong in getting the url
          },
          (url: string) => {
            handleDownloadFromUrl(url);
          },
        )(resp);
      });
  };

  const downloadDataRoomZip = (txnId: string, dataroom: IDataRoom) => {
    getDataRoomZipURL.call({ txnId: txnId }).then((resp) => {
      match<Failure, string, void>(
        (_: Failure) => {
          // something went wrong in getting the url
        },
        async (url: string) => {
          const finished = await handleDownloadFromUrl(url);
          // TODO: add a loading spinner to the download zip button here
          return finished;
        },
      )(resp);
    });
  };

  const uploadDataroomFileZip = (
    txnId: string,
    files: File[],
    notify: boolean,
  ) => {
    setDataroomUploadState(new UploadDataRoomFileSubmitting());

    uploadDataroomFile
      .call({ files, transactionId: txnId, notify })
      .then((resp) => {
        match<Failure, any, void>(
          (_: Failure) => {
            enqueueSnackbar(
              "An error occurred while uploading capital accounts, try again later.",
              {
                variant: "error",
              },
            );
          },
          (_: any) => {
            // After the file was uploaded, we need to trigger the processing of the file
            setDataroomUploadState(new UploadDataRoomFileSuccess());
            emitEvent(
              new ProcessDataroomUploadPressed({
                transactionId: txnId,
                notify,
              }),
            );
          },
        )(resp);
      })
      .catch((e) => {
        setDataroomUploadState(new UploadDataRoomFileFailed());
      });
  };

  const triggerDataroomProcessing = ({
    transactionId,
  }: {
    transactionId: string;
  }) => {
    setDataroomUploadState(new ProcessDataRoomFileProcessing());
    processDataroomFile.call({ transactionId }).then((resp) => {
      match<Failure, any, void>(
        (error: Failure) => {
          enqueueSnackbar(
            "An error occurred while processing the dataroom, try again later.",
            {
              variant: "error",
            },
          );
        },
        (_: any) => {
          setDataroomUploadState(new ProcessDataRoomFileFinished());
        },
      )(resp);
    });
  };

  const triggerDataroomProcessingNotification = ({
    transactionId,
    participantIds,
    message,
  }: {
    transactionId: string;
    participantIds?: string[];
    message?: string;
  }) => {
    notifyDataroomProcessFile
      .call({ transactionId, participantIds, message })
      .then((resp) => {
        match<Failure, any, void>(
          (error: Failure) => {
            // enqueueSnackbar("An error occurred while sending the notification", {
            //   variant: "error",
            // });
          },
          (_: any) => {
            // setDataroomUploadState(new ProcessDataRoomFileFinished());
          },
        )(resp);
      });
  };

  const refreshTransactions = () => {
    retrieveTransactions.call({}).then((resp) => {
      match<Failure, ITransaction[], void>(
        (_: Failure) => {
          setTxnsListState(new TransactionsFailedToLoad());
        },
        (txns: ITransaction[]) => {
          setTxnsListState(
            new TransactionsLoaded({
              txns: [...txns],
            }),
          );
        },
      )(resp);
    });
  };

  const [txnDetailsState, setTxnDetailsState] = useState<TransactionsState>(
    new TransactionDetailsLoading(),
  );
  const [txnDetailsSettingsState, setTxnDetailsSettingsState] =
    useState<TransactionsState>();

  const refreshTxnSummary = (txnId: string) => {
    getTxnSummary.call({ txnId }).then((resp) => {
      match<Failure, ITransactionSummary, void>(
        (failure: Failure) => {},
        (txnSummary: ITransactionSummary) => {
          setTxnDetailsState(
            txnSummary.transaction instanceof LPLedTransaction
              ? new TransactionDetailsScreenLoaded({
                  summary: txnSummary,
                })
              : new TenderOverviewScreenLoaded({
                  summary: txnSummary,
                }),
          );
        },
      )(resp);
    });
  };

  const refreshTxnSettings = (txnId: string) => {
    getTxnSettings.call({ txnId }).then((resp) => {
      match<Failure, any, void>(
        (_: Failure) => {},
        (settings: ITransactionSettings[]) => {
          setTxnDetailsSettingsState(
            new TransactionSettingsScreenLoaded({ settings }),
          );
        },
      )(resp);
    });
  };

  const updateTxnName = (txnId: string, name: string) => {
    updateTransactionName.call({ name, txnId }).then((resp) => {
      match<Failure, any, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during updating transaction name", {
            variant: "error",
          });
        },
        (_: any) => {
          refreshTxnSummary(txnId);
        },
      )(resp);
    });
  };

  const updateTransactionSettings = (
    txnId: string,
    type: TransactionSettingType,
    isActive: boolean,
  ) => {
    setTxnDetailsSettingsState(new TransactionSettingsUpdateLoading());

    updateTransactionSetting.call({ txnId, type, isActive }).then((resp) => {
      refreshTxnSettings(txnId);
      match<Failure, any, void>(
        (_: Failure) => {
          setTxnDetailsSettingsState(new TransactionSettingsUpdateFailed());
          enqueueSnackbar("Error during updating transaction settings", {
            variant: "error",
          });
        },
        (_: any) => {
          setTxnDetailsSettingsState(new TransactionSettingsUpdateSuccess());
          toast.success("Transaction settings updated successfully");
          refreshTxnSettings(txnId);
        },
      )(resp);
    });
  };

  const [txnNDAsState, setTxnNDAsState] = useState<TransactionsState>(
    new ListNDAsInProgress(),
  );

  const listNDAs = (txnId: string) => {
    listTxnNDAs.call({ txnId }).then((resp) => {
      match<Failure, INDADoc[], void>(
        (_: Failure) => {
          enqueueSnackbar("Error when loading NDAs", {
            variant: "error",
          });
        },
        (ndas: INDADoc[]) => {
          setTxnNDAsState(new TransactionNDAsLoaded({ ndas: ndas }));
        },
      )(resp);
    });
  };

  const signAllPcptNDAs = (
    txnId: string,
    pcptId: string,
    signature: ISignatureForm,
  ) => {
    signPcptNdas.call({ txnId, pcptId, signature }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during signing NDAs", {
            variant: "error",
          });
        },
        (_) => {
          navigate(`${LP_ROUTES.Transactions}/${txnId}/details/`);
          refreshTransactions();
        },
      )(resp);
    });
  };

  const [createBidFlowState, setCreatebidFlowState] =
    useState<TransactionsState>(new TransactionDetailsLoading());

  const getHoldingsForBid = (txnId: string) => {
    getTxnHoldingsForBidding.call({ txnId }).then((resp) => {
      match<Failure, BidTransactionHolding[], void>(
        (_: Failure) => {
          enqueueSnackbar("Error during retrieving holding to bid on", {
            variant: "error",
          });
        },
        (holdings: BidTransactionHolding[]) => {
          setCreatebidFlowState(
            new EnterBidPricingScreenLoaded({ holdings: holdings }),
          );

          // we are making the assumption that if this method is called
          // then the state of the transaction has been worked out properly
          if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
            const summary = txnDetailsState.summary;
            sendMessage({
              blocks: [
                generateSlackMessagePayload({
                  title:
                    "A Participant opened the bid flow (currently on the pricing step)",
                  fields: generateObjectAsFields({
                    "participant name": `${summary.viewerParticipant.firstName} ${summary.viewerParticipant.lastName}`,
                    "transaction name": summary.transaction.name,
                  }),
                  context: generateObjectAsFields({
                    "participant ID": summary.viewerParticipant.id,
                    "transaction ID": summary.transaction.id,
                  }),
                }),
              ],
            });
          }
        },
      )(resp);
    });
  };

  const [currBid, setCurrBid] = useState<Bid>();

  const upsertBidWithPrice = (form: BidPricingUpsertForm) => {
    upsertBidWithPricing.call({ form }).then((resp) => {
      match<Failure, Bid, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during submitting bid pricing", {
            variant: "error",
          });
        },
        (bid: Bid) => {
          setCurrBid(bid);
          setCreatebidFlowState(
            new UploadLetterOfIntentScreenLoaded({
              fileName: currBid?.document?.fileName,
            }),
          );
          // we are making the assumption that if this method is called
          // then the state of the transaction has been worked out properly
          if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
            const summary = txnDetailsState.summary;
            sendMessage({
              blocks: [
                generateSlackMessagePayload({
                  title:
                    "A bid price was submitted (currently on the LOI upload step)",
                  fields: generateObjectAsFields({
                    "participant name": `${summary.viewerParticipant.firstName} ${summary.viewerParticipant.lastName}`,
                    "transaction name": summary.transaction.name,
                  }),
                  context: generateObjectAsFields({
                    "participant ID": summary.viewerParticipant.id,
                    "transaction ID": summary.transaction.id,
                    "bid ID": bid.id,
                  }),
                }),
              ],
            });
          }
        },
      )(resp);
    });
  };

  const uploadDocToBid = (document: File, txnId: string) => {
    uploadDocumentToBid
      .call({
        form: {
          file: document,
          bidId: currBid?.id!,
        },
        txnId,
      })
      .then((resp) => {
        match<Failure, Bid, void>(
          (_: Failure) => {
            enqueueSnackbar("Error during uploading bid document", {
              variant: "error",
            });
          },
          async (bid: Bid) => {
            setCurrBid(bid);

            const holdings = pipe(
              await getTxnHoldingsForBidding.call({ txnId }),
              getOrElse(() => [] as BidTransactionHolding[]),
            );

            setCreatebidFlowState(
              new ReviewBidScreenLoaded({
                fullBid: bid,
                holdings: holdings,
              }),
            );

            // we are making the assumption that if this method is called
            // then the state of the transaction has been worked out properly
            if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
              const summary = txnDetailsState.summary;
              sendMessage({
                blocks: [
                  generateSlackMessagePayload({
                    title:
                      "LOI was uploaded to a bid (currently on the bid review step)",
                    fields: generateObjectAsFields({
                      "participant name": `${summary.viewerParticipant.firstName} ${summary.viewerParticipant.lastName}`,
                      "transaction name": summary.transaction.name,
                    }),
                    context: generateObjectAsFields({
                      "participant ID": summary.viewerParticipant.id,
                      "transaction ID": summary.transaction.id,
                      "bid ID": bid.id,
                    }),
                  }),
                ],
              });
            }
          },
        )(resp);
      });
  };

  const submitBid = (txnId: string, bidId: string) => {
    submitTxnBid.call({ form: { txnId, bidId } }).then((resp) => {
      match<Failure, Bid, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during submitting bid", {
            variant: "error",
          });
        },
        (bid: Bid) => {
          refreshBids(txnId);
          setCurrBid(undefined);
          setCreatebidFlowState(new BidCreationSuccessful());

          // we are making the assumption that if this method is called
          // then the state of the transaction has been worked out properly
          if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
            const summary = txnDetailsState.summary;
            sendMessage({
              blocks: [
                generateSlackMessagePayload({
                  title: "A Participant have submitted a bid",
                  fields: generateObjectAsFields({
                    "participant name": `${summary.viewerParticipant.firstName} ${summary.viewerParticipant.lastName}`,
                    "transaction name": summary.transaction.name,
                  }),
                  context: generateObjectAsFields({
                    "participant ID": summary.viewerParticipant.id,
                    "transaction ID": summary.transaction.id,
                    "bid ID": bidId,
                  }),
                }),
              ],
            });
          }
        },
      )(resp);
    });
  };

  const refreshBids = (txnId: string) => {
    listBidsOfUserOnTxn.call({ txnId }).then((resp) => {
      match<Failure, Bid[], void>(
        (_: Failure) => {
          enqueueSnackbar("Error during getting bids list", {
            variant: "error",
          });
        },
        (bids: Bid[]) => {
          setBidsListState(new BidsListLoaded({ bids }));
        },
      )(resp);
    });
  };

  const advanceBidToSettlement = (txnId: string, bidId: string) => {
    acceptBid.call({ form: { bidId, txnId } }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during accepting a bid", {
            variant: "error",
          });
        },
        (_) => {
          refreshBids(txnId);
          // we are making the assumption that if this method is called
          // then the state of the transaction has been worked out properly
          if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
            const summary = txnDetailsState.summary;
            sendMessage({
              blocks: [
                generateSlackMessagePayload({
                  title: "A bid was advanced to settlement",
                  fields: generateObjectAsFields({
                    "advanced by": `${summary.viewerParticipant.firstName} ${summary.viewerParticipant.lastName}`,
                    "transaction name": summary.transaction.name,
                  }),
                  context: generateObjectAsFields({
                    "advanced by ID": summary.viewerParticipant.id,
                    "transaction ID": summary.transaction.id,
                    "bid ID": bidId,
                  }),
                }),
              ],
            });
          }
        },
      )(resp);
    });
  };

  const upsertTxnTimeline = (event: TimelineEditModalSubmitted) => {
    upsertTimeline
      .call({ txnId: event.txnId, periods: event.newTimeline })
      .then((resp) => {
        match<Failure, any, void>(
          (_: Failure) => {
            enqueueSnackbar("Error during updating the timeline", {
              variant: "error",
            });
          },
          (_) => {
            refreshTxnSummary(event.txnId);
          },
        )(resp);
      });
  };

  const [bidsListState, setBidsListState] = useState<TransactionsState>();

  const [bidDetailsScreenState, setBidDetailsScreenState] =
    useState<TransactionsState>();

  const getHoldingsForBidDetails = (txnId: string) => {
    getTxnHoldingsForBidding.call({ txnId }).then((resp) => {
      match<Failure, BidTransactionHolding[], void>(
        (_: Failure) => {
          enqueueSnackbar("Error during retrieving holdings to view bid", {
            variant: "error",
          });
        },
        (holdings: BidTransactionHolding[]) => {
          setBidDetailsScreenState(
            new BidDetailsScreenLoaded({
              holdings,
            }),
          );
        },
      )(resp);
    });
  };

  const deleteThisBid = (txnId: string, bidId: string) => {
    deleteBid.call({ form: { bidId, txnId } }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during deleting the bid", {
            variant: "error",
          });
        },
        (_) => {
          // we are making the assumption that if this method is called
          // then the state of the transaction has been worked out properly
          if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
            const summary = txnDetailsState.summary;
            sendMessage({
              blocks: [
                generateSlackMessagePayload({
                  title: "A bid was deleted",
                  fields: generateObjectAsFields({
                    "participant name": `${summary.viewerParticipant.firstName} ${summary.viewerParticipant.lastName}`,
                    "transaction name": summary.transaction.name,
                  }),
                  context: generateObjectAsFields({
                    "participant ID": summary.viewerParticipant.id,
                    "transaction ID": summary.transaction.id,
                    "bid ID": bidId,
                  }),
                }),
              ],
            });
          }
          refreshBids(txnId);
        },
      )(resp);
    });
  };

  const cleanTransactionDetails = () => {
    setTxnDetailsState(new TransactionDetailsLoading());
  };

  const loadTransactionPolicies = () => {
    getTransactionPolicies.call({}).then((resp) => {
      match<Failure, TransactionPolicy[], void>(
        (_: Failure) => {
          setTransactionPoliciesState(
            new TransactionPoliciesLoaded({
              transactionPolicies: transactionPoliciesMock,
            }),
          );
        },
        (policies: TransactionPolicy[]) => {
          setTransactionPoliciesState(
            new TransactionPoliciesLoaded({
              transactionPolicies: policies,
            }),
          );
        },
      )(resp);
    });
  };

  const loadTransactionNDAs = () => {
    getTransactionNDAs.call({}).then((resp) => {
      match<Failure, TransactionNDA[], void>(
        (_: Failure) => {
          console.log("error");
        },
        (transactionNDAs: TransactionNDA[]) => {
          setTransactionNDAState(
            new TransactionSettingsNDAsLoaded({
              transactionNDAs,
            }),
          );
        },
      )(resp);
    });
  };

  const loadTransactionAgreements = () => {
    getTransactionAgreements.call({}).then((resp) => {
      match<Failure, TransactionAgreement[], void>(
        (_: Failure) => {},
        (transactionAgreements: TransactionAgreement[]) => {
          setTransactionAgreementsState(
            new TransactionSettingsAgreementsLoaded({
              transactionAgreements,
            }),
          );
        },
      )(resp);
    });
  };

  const loadGpTransactions = async () => {
    getGpTransactions.call({}).then((resp) => {
      match<Failure, IGpTransaction[], void>(
        (_: Failure) => {
          // save demo transactions
          new GpTransactionListMockStateLoaded({
            transactions: gpTransactionList,
          });
        },
        (transactions: IGpTransaction[]) => {
          setGpTransactionListState(
            new GpTransactionListMockStateLoaded({ transactions }),
          );
        },
      )(resp);
    });
  };

  const create3rdPartyTxn = (form: ICreateThirdPartyTransactionForm) => {
    createThirdPartyTransaction.call({ form }).then((resp) => {
      match<Failure, NoReturn, void>(
        (_: Failure) => {
          enqueueSnackbar("Error during creating a 3rd party transaction", {
            variant: "error",
          });
        },
        (_) => {
          refreshTransactions();
        },
      )(resp);
    });
  };

  const resetDataroomFileState = () => {
    setDataroomUploadState({});
  };

  useEffect(() => {
    refreshTransactions();
  }, []);

  // event emitter
  let prevEvent: TransactionsEvent;
  const emitEvent = (event: TransactionsEvent) => {
    if (prevEvent == event) {
      return;
    }

    if (
      event instanceof TransactionDetailsEvent &&
      event.txnId === DEMO_TXN_ID
    ) {
      return;
    }
    // real event handling

    if (event instanceof LoadTransactionDetails) {
      if (txnDetailsState instanceof TransactionDetailsScreenLoaded) {
        cleanTransactionDetails();
      }
      refreshTxnSummary(event.txnId);
      // refreshTxnSettings(event.txnId);
    } else if (event instanceof NDAsParticipantSignatureSubmitted) {
      signAllPcptNDAs(event.txnId, event.participantId, event.signature);
    } else if (event instanceof RedirectedToSignNDAScreen) {
      listNDAs(event.txnId);
    } else if (event instanceof DowloadDataRoomFilePressed) {
      downloadDataRoomFile(event.txnId, event.item);
    } else if (event instanceof DownloadDataRoomZipPressed) {
      downloadDataRoomZip(event.txnId, event.dataroom);
    } else if (event instanceof CreateBidFlowTriggered) {
      getHoldingsForBid(event.txnId);
    } else if (event instanceof EnterBidPricingStepSubmitted) {
      upsertBidWithPrice(event.form);
    } else if (event instanceof UploadLetterOfIntentStepSubmitted) {
      uploadDocToBid(event.letterOfIntent, event.txnId);
    } else if (event instanceof ReviewBidStepDownloadDocumentClicked) {
      handleDownloadFromUrl(event.document.url!);
    } else if (event instanceof ReviewBidStepSubmitted) {
      submitBid(event.txnId, event.bidId);
    } else if (event instanceof RedirectedToBidsListScreen) {
      refreshBids(event.txnId);
    } else if (event instanceof BidListDownloadDocumentClicked) {
      handleDownloadFromUrl(event.document.url!);
    } else if (event instanceof AcceptBidButtonClicked) {
      advanceBidToSettlement(event.txnId, event.bidId);
    } else if (event instanceof BuyerBidRowClicked) {
      getHoldingsForBidDetails(event.txnId);
    } else if (event instanceof DeleteBidButtonClicked) {
      deleteThisBid(event.txnId, event.bidId);
    } else if (event instanceof LoadTransactionPolicies) {
      loadTransactionPolicies();
    } else if (event instanceof LoadTransactionNDAs) {
      loadTransactionNDAs();
    } else if (event instanceof LoadTransactionAgreements) {
      loadTransactionAgreements();
    } else if (event instanceof GpTransactionListRequested) {
      loadGpTransactions();
    } else if (event instanceof UploadDataRoomFilePressed) {
      uploadDataroomFileZip(event.txnId, event.files, event.notify);
    } else if (event instanceof ProcessDataroomUploadPressed) {
      triggerDataroomProcessing({
        transactionId: event.transactionId,
        notify: event.notify,
      });
    } else if (event instanceof NotifyDataroomUploadRequested) {
      triggerDataroomProcessingNotification({
        transactionId: event.transactionId,
        participantIds: event.participantIds,
        message: event.message,
      });
    } else if (event instanceof ResetDataroomFileState) {
      resetDataroomFileState();
    } else if (event instanceof CreateThirdPartyTransactionFormSubmitted) {
      create3rdPartyTxn(event.form);
    } else if (event instanceof TimelineEditModalSubmitted) {
      upsertTxnTimeline(event);
    } else if (event instanceof TransactionNameUpdated) {
      updateTxnName(event.txnId, event.name);
    } else if (event instanceof TransactionSettingUpdated) {
      updateTransactionSettings(event.txnId, event.type, event.isActive);
    }

    prevEvent = event;
  };

  return {
    emitEvent,
    txnsListState,
    txnDetailsState,
    txnDetailsSettingsState,
    txnNDAsState,
    createBidFlowState,
    bidsListState,
    bidDetailsScreenState,
    transactionPoliciesState,
    transactionNDAState,
    transactionAgreementsState,
    gpTransactionListState,
    dataroomUploadState,
  };
};
