import { Either, left, right } from "fp-ts/lib/Either";

import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import TapClient from "common/clients/TapClient";
import { IImpersonationInfo } from "../models/ImpersonationInfo";

interface IParams {}

export class GetImpersonationInfo
  implements IAsyncUseCase<IParams, IImpersonationInfo>
{
  call: (params: IParams) => Promise<Either<Failure, IImpersonationInfo>> =
    async () => {
      return await new TapClient("/impersonate/")
        .get({})
        .then(async (resp: any) => {
          return right(resp);
        })
        .catch((resp: any) => {
          return left(new Failure(resp.message));
        });
    };
}
