import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "common/utils";

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(
  (
    {
      className,
      value,
      value2,
      label,
      label2,
      outOf,
      labelOutOf,
      animate,
      flash,
      ...props
    },
    ref,
  ) => {
    const outOfValue = outOf || 100;
    const relativeValue = (value / outOfValue) * 100;
    const relativeValue2 = (value2 / outOfValue) * 100;

    return (
      <div className="overflow-hidden h-8">
        <ProgressPrimitive.Root
          ref={ref}
          className={cn(
            "relative h-3 w-full rounded-full bg-nomad-100",
            className,
          )}
          {...props}
        >
          <div className="rounded-full overflow-hidden">
            <ProgressPrimitive.Indicator
              className={cn(
                "h-3 w-full flex-1 bg-stone-400 transition-all rounded-full",
                animate && "animate-ping",
                flash && "animate-pulse",
              )}
              style={{ width: `${relativeValue}%` }}
            />
            {value2 && (
              <ProgressPrimitive.Indicator
                className="h-3 w-full flex-1 bg-nomad-900 transition-all rounded-full absolute top-0 depth-1 opacity-50"
                style={{ width: `${relativeValue2}%` }}
              />
            )}
          </div>
          {label && (
            <div
              className="flex absolute h-4 place-content-end w-full text-right top-4 left-0 right-0 bottom-0 items-center text-xs font-semibold text-nomad-800select-none"
              style={{ transform: `translateX(-${100 - relativeValue}%)` }}
            >
              {label}
            </div>
          )}
          {label2 && (
            <div
              className="flex absolute h-4 place-content-end w-full text-right top-4 left-0 right-0 bottom-0 items-center text-xs font-semibold text-nomad-800select-none"
              style={{ transform: `translateX(-${100 - relativeValue2}%)` }}
            >
              {label2}
            </div>
          )}
          {labelOutOf && (
            <div className="flex absolute h-4 place-content-end w-full text-right top-4 right-0 bottom-0 items-center text-xs font-semibold text-nomad-800 select-none">
              {labelOutOf}
            </div>
          )}
        </ProgressPrimitive.Root>
      </div>
    );
  },
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
