// window.open(downloadUrl);

import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";

export interface ITenderDoc {
  id: string;
  transactionId: string;
  fileId: string;
  fileName: string;
  type: string;
  presignedUrl: string;
}

interface IParams {
  txnId: string;
  docId: string;
}

export class DownloadOfferDoc implements IAsyncUseCase<IParams, ITenderDoc> {
  call: (params: IParams) => Promise<Either<Failure, ITenderDoc>> = async ({
    txnId,
    docId,
  }) => {
    return await new TapClient(
      `/transactions/tenders/${txnId}/offer-docs/${docId}/download-url/`,
    )
      .get({})
      .then((resp: ITenderDoc) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}
