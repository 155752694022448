import { CapitalAccountStatementKeys } from "experiences/transactions/domain/models/FundDataCapitalAccounts";

export const capitalAccountLabels = {
  [CapitalAccountStatementKeys.isHumanVerified]: "Verified",
  [CapitalAccountStatementKeys.managerId]: "Manager ID",
  [CapitalAccountStatementKeys.managerName]: "Manager Name",
  [CapitalAccountStatementKeys.fundName]: "Fund Name",
  [CapitalAccountStatementKeys.psin]: "PSIN",
  [CapitalAccountStatementKeys.nav]: "NAV",
  [CapitalAccountStatementKeys.remainingUnfunded]: "Remaining Unfunded",
  [CapitalAccountStatementKeys.percentUnfunded]: "Percent Unfunded",
  [CapitalAccountStatementKeys.commitment]: "Commitment",
  [CapitalAccountStatementKeys.percentFundCommitment]:
    "Percent Fund Commitment",
  [CapitalAccountStatementKeys.contributedCapital]: "Contributed Capital (ITD)",
  [CapitalAccountStatementKeys.distributedCapital]: "Distributed Capital (ITD)",
  [CapitalAccountStatementKeys.recallableCapital]: "Recallable Capital (ITD)",
  [CapitalAccountStatementKeys.tvpi]: "TVPI",
  [CapitalAccountStatementKeys.dpi]: "DPI",
  [CapitalAccountStatementKeys.percentWriteUpQoq]: "Percent Write Up QoQ",
  [CapitalAccountStatementKeys.percentWriteUpYoy]: "Percent Write Up YoY",
  [CapitalAccountStatementKeys.postReferenceContributions]:
    "Post Reference Contributions",
  [CapitalAccountStatementKeys.postReferenceDistributions]:
    "Post Reference Distributions",
};

export const attachTitleKeys = [
  CapitalAccountStatementKeys.managerName,
  CapitalAccountStatementKeys.fundName,
];

export const multiplierKeys = [
  CapitalAccountStatementKeys.tvpi,
  CapitalAccountStatementKeys.dpi,
];

export const currencyKeys = [
  CapitalAccountStatementKeys.nav,
  CapitalAccountStatementKeys.remainingUnfunded,
  CapitalAccountStatementKeys.commitment,
  CapitalAccountStatementKeys.contributedCapital,
  CapitalAccountStatementKeys.distributedCapital,
  CapitalAccountStatementKeys.recallableCapital,
  CapitalAccountStatementKeys.postReferenceContributions,
  CapitalAccountStatementKeys.postReferenceDistributions,
];

export const percentageKeys = [
  CapitalAccountStatementKeys.percentUnfunded,
  CapitalAccountStatementKeys.percentFundCommitment,
  CapitalAccountStatementKeys.percentWriteUpQoq,
  CapitalAccountStatementKeys.percentWriteUpYoy,
];

export const rightAlignedKeys = [
  CapitalAccountStatementKeys.nav,
  CapitalAccountStatementKeys.remainingUnfunded,
  CapitalAccountStatementKeys.commitment,
  CapitalAccountStatementKeys.contributedCapital,
  CapitalAccountStatementKeys.distributedCapital,
  CapitalAccountStatementKeys.recallableCapital,
  CapitalAccountStatementKeys.postReferenceContributions,
  CapitalAccountStatementKeys.postReferenceDistributions,
  CapitalAccountStatementKeys.percentUnfunded,
  CapitalAccountStatementKeys.percentFundCommitment,
  CapitalAccountStatementKeys.percentWriteUpQoq,
  CapitalAccountStatementKeys.percentWriteUpYoy,
  CapitalAccountStatementKeys.tvpi,
  CapitalAccountStatementKeys.dpi,
];
