import { gql } from "@apollo/client";

export const GetPortfolioSoiQuery = gql`
  query Query(
    $entitiesIds: [String!]!
    $groupBy: AggregateSoiGrouping!
    $reportDate: Date!
  ) {
    aggregateSoi(
      entitiesIds: $entitiesIds
      groupBy: $groupBy
      reportDate: $reportDate
    ) {
      reportData {
        companyName
        tapCompanyId
        fundName
        tapFundId
        tapInvestmentId
        reportDate
        companyCurrency
        investmentDate
        holdPeriod
        investmentType
        investmentGeography
        sector
        investmentStatus
        totalCost
        totalValue
        investmentMoic
        realizedCost
        realizedValue
        unrealizedCost
        unrealizedValue
        lpImpliedInvestmentValue
        lpImpliedInvestmentPercentage
        isHumanVerified
        issuerId
        companyId
        managerId
        managerName
        portfolioPercent
      }
      groupingData {
        groupedBy
        topLevelAggregateData {
          groupKey
          groupIds
          companiesCount
          isHumanVerified
          totalCost
          totalValue
          realizedCost
          realizedValue
          unrealizedCost
          unrealizedValue
          portfolioPercent
          investmentMoic
          nav
        }
        aggregateData {
          groupKey
          groupIds
          companiesCount
          isHumanVerified
          totalCost
          totalValue
          realizedCost
          realizedValue
          unrealizedCost
          unrealizedValue
          portfolioPercent
          investmentMoic
          nav
        }
      }
    }
  }
`;
