import { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";

import FormikTextField from "../../../../common/components/formik/FormikTextField";
import IssuerSelector from "../../../funds/presentation/components/IssuersSelector";
import {
  FormikQuarterPicker,
  getLastQuarter,
} from "../../../../common/components/formik/FormikQuarterPicker";
import FormikCheckbox from "common/components/formik/FormikCheckbox";
import FormikDatePicker from "common/components/formik/FormikDatePicker";
import { useIOIsContext } from "../state/IOIContext";
import { CreateIOIFormSubmitted } from "../state/IOIEvent";
import { IOIType } from "experiences/indications-of-interest/domain/models/IOI";
import {
  CreateIOIFormLoadedBidAndAsk,
  CreateIOIFormLoadedBidOnly,
} from "../state/IOIState";
import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES, Button } from "common/components/Button";
import { makeNumber } from "common/@types/app/DollarAmount";
import { Fund } from "experiences/funds/domain/models/Fund";
import { TapUiModalHead } from "../components/UiModal";
import { LP_ROUTES } from "common/constants/routes";

export enum IoiType {
  Bid = "bid",
  Ask = "ask",
}

export const AddInterestModal = ({
  open,
  type = IoiType.Ask,
  onClose,
  fund,
  prefillFundId,
  onFormSubmitted,
}: {
  open: boolean;
  type?: IoiType;
  onClose: () => void;
  fund?: Fund;
  prefillFundId?: boolean;
  onFormSubmitted?: () => void;
}) => {
  const [bidOrAsk, setBidOrAsk] = useState<IoiType>(type);
  const { emitEvent, createIOIFormState } = useIOIsContext();
  const navigate = useNavigate();

  useEffect(() => {
    setBidOrAsk(type);
  }, [type]);

  const [fundName, setFundName] = useState<string>();

  const bidValidationSchema = yup.object({
    fundId: yup.string().required("Required"),
    referenceDate: yup.date().required("Required").default(getLastQuarter()),
    minAmount: yup.string().required("Required"),
    maxAmount: yup.string(),
    minPrice: yup.string(),
    maxPrice: yup.string(),
    isGtc: yup.boolean().default(true),
    isLpOnFund: yup.boolean().default(true),
    expDate: yup.date().when("isGtc", {
      is: false,
      then: yup.date().required("Required"),
    }),
  });

  const bidForm = useFormik({
    initialValues: {
      fundId: (prefillFundId && fund?.id) || "",
      referenceDate: getLastQuarter().toISOString().substring(0, 10),
      minAmount: "",
      maxAmount: "",
      minPrice: "",
      maxPrice: "",
      isGtc: true,
      isLpOnFund: true,
      expDate: "",
    },
    onSubmit: (values) => {
      const expirationDate = !values.isGtc
        ? new Date(values.expDate).toISOString().substring(0, 10)
        : undefined;

      emitEvent!(
        new CreateIOIFormSubmitted({
          type: IOIType.Bid,
          form: {
            fundName,
            fundId: values.fundId,
            referenceDate: values.referenceDate,
            minAmount: makeNumber(values.minAmount),
            maxAmount: values.maxAmount
              ? makeNumber(values.maxAmount)
              : undefined,
            minPrice: values.minPrice ? makeNumber(values.minPrice) : undefined,
            maxPrice: values.maxPrice ? makeNumber(values.maxPrice) : undefined,
            isLpOnFund: values.isLpOnFund,
            isGtc: values.isGtc,
            expirationDate: expirationDate,
          },
        }),
      );
      onFormSubmitted();
      handleClose();
    },
    validationSchema: bidValidationSchema,
  });

  const askValidationSchema = yup.object({
    fundId: yup.string().required("Required"),
    referenceDate: yup.date().required("Required").default(getLastQuarter()),
    minAmount: yup.string(),
    maxAmount: yup.string().required("Required"),
    minPrice: yup.string(),
    maxPrice: yup.string(),
    isGtc: yup.boolean().default(true),
    expDate: yup.date().when("isGtc", {
      is: false,
      then: yup.date().required("Required"),
    }),
  });

  // technically, should be the same as bidForm but we'll keep it separate for now
  const askForm = useFormik({
    initialValues: {
      fundId: (prefillFundId && fund?.id) || "",
      referenceDate: getLastQuarter().toISOString().substring(0, 10),
      minAmount: "",
      maxAmount: "",
      minPrice: "",
      maxPrice: "",
      isGtc: true,
      expDate: "",
    },
    onSubmit: (values) => {
      const expirationDate = !values.isGtc
        ? new Date(values.expDate).toISOString().substring(0, 10)
        : undefined;

      emitEvent!(
        new CreateIOIFormSubmitted({
          type: IOIType.Ask,
          form: {
            fundName,
            fundId: values.fundId,
            referenceDate: values.referenceDate,
            minAmount: values.minAmount
              ? makeNumber(values.minAmount)
              : undefined,
            maxAmount: makeNumber(values.maxAmount),
            minPrice: values.minPrice ? makeNumber(values.minPrice) : undefined,
            maxPrice: values.maxPrice ? makeNumber(values.maxPrice) : undefined,
            isGtc: values.isGtc,
            expirationDate: expirationDate,
          },
        }),
      );
      onFormSubmitted();
      handleClose();
    },
    validationSchema: askValidationSchema,
  });

  const form = bidOrAsk === IoiType.Bid ? bidForm : askForm;
  const validationSchema =
    bidOrAsk === IoiType.Bid ? bidValidationSchema : askValidationSchema;

  const minAmountRequired =
    validationSchema.fields.minAmount.spec.presence === "required";
  const maxAmountRequired =
    validationSchema.fields.maxAmount.spec.presence === "required";
  const minPriceRequired =
    validationSchema.fields.minPrice.spec.presence === "required";
  const maxPriceRequired =
    validationSchema.fields.maxPrice.spec.presence === "required";

  const handleClose = () => {
    onClose();
    form.resetForm();
  };

  const showFundSelector =
    !prefillFundId &&
    createIOIFormState instanceof CreateIOIFormLoadedBidAndAsk;

  return (
    <Modal
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <form onSubmit={form.submitForm}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Stack sx={{ px: 4, py: 4 }} spacing={2}>
            <Box>
              <TapUiModalHead
                title="Add Interest"
                subtitle="Place a standing indication of interest (IOI) for a fund. Only
                verified LPs or white-listed buyers can see IOIs for a specific
                fund. You can only submit an Ask for funds in which you are an
                LP."
                onCloseClick={handleClose}
              />
            </Box>
            <ToggleButtonGroup
              color="primary"
              exclusive
              onChange={(_, value) => {
                form.resetForm();
                setBidOrAsk(value);
              }}
              aria-label="Platform"
              value={bidOrAsk}
            >
              <ToggleButton value={IoiType.Bid}>Buy</ToggleButton>
              <ToggleButton value={IoiType.Ask}>Sell</ToggleButton>
            </ToggleButtonGroup>
            {bidOrAsk !== IoiType.Bid &&
            createIOIFormState instanceof CreateIOIFormLoadedBidOnly ? (
              <EmptyState
                title="No current portfolio holdings"
                description="You can only submit Asks for funds in which you are an LP. Add your private fund holdings to get estimated market prices and be notified of buy interest and bids."
                actions={[
                  {
                    label: "Add Holdings",
                    onClick: () => navigate(LP_ROUTES.PortfolioHoldings),
                    type: BUTTON_TYPES.SECONDARY,
                  },
                ]}
              />
            ) : (
              <Stack spacing={2}>
                {showFundSelector && bidOrAsk === IoiType.Bid && (
                  <IssuerSelector
                    label="Fund Name"
                    formik={form}
                    name="fundId"
                  />
                )}
                {showFundSelector && bidOrAsk === IoiType.Ask && (
                  <IssuerSelector
                    label="Fund Name"
                    formik={form}
                    onOptionChanged={(value) => setFundName(value)}
                    prefetchedIssuers={createIOIFormState.issuersForAsk}
                    name="fundId"
                  />
                )}
                <FormikQuarterPicker
                  formik={form}
                  name="referenceDate"
                  label="Reference Date"
                  inputProps={undefined}
                  defaultNow
                />
                <Stack direction="row" spacing={2}>
                  <FormikTextField
                    name="minAmount"
                    formik={form}
                    label="Min Amount (NAV)"
                    mask="currency"
                    fullWidth
                    placeholder={minAmountRequired ? "required" : "optional"}
                  />
                  <FormikTextField
                    name="maxAmount"
                    formik={form}
                    label="Max Amount (Nav)"
                    mask="currency"
                    fullWidth
                    placeholder={maxAmountRequired ? "required" : "optional"}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormikTextField
                    name="minPrice"
                    formik={form}
                    label="Min Price (% of NAV)"
                    mask="percentage"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    placeholder={minPriceRequired ? "required" : "optional"}
                  />
                  <FormikTextField
                    name="maxPrice"
                    formik={form}
                    label="Max Price (% of NAV)"
                    mask="percentage"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    placeholder={maxPriceRequired ? "required" : "optional"}
                  />
                </Stack>
                {bidOrAsk === IoiType.Bid && (
                  <Stack spacing={2}>
                    <FormikCheckbox
                      name="isLpOnFund"
                      label="I am an existing LP on the fund"
                      formik={form}
                    />
                  </Stack>
                )}
                <Stack spacing={2}>
                  {!form.values.expDate && (
                    <FormikCheckbox
                      name="isGtc"
                      label={`This ${
                        bidOrAsk === IoiType.Bid ? "bid" : "ask"
                      } is Good ‘Til Canceled (GTC)`}
                      formik={form}
                    />
                  )}
                  {!form.values.isGtc && (
                    <FormikDatePicker
                      formik={form}
                      inputFormat="MM/dd/yyyy"
                      placeholder="DD / MM / YYYY"
                      name="expDate"
                      label="Expiration Date"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Stack>
                <Button
                  onClick={form.submitForm}
                  fullWidth
                  type={BUTTON_TYPES.SECONDARY}
                  size="large"
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
