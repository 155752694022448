import { Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import FormikTextField from "../../../../common/components/formik/FormikTextField";
import { BUTTON_TYPES, Button } from "common/components/Button";
import {
  TapUiModalContent,
  TapUiModalHead,
  TapUiVerticalModalWrapper,
} from "experiences/indications-of-interest/presentation/components/UiModal";
import { TransactionNameUpdated } from "../state/TransactionsEvent";
import { useTransactionsContext } from "../state/TransactionsContext";
import { useEffect } from "react";

export const UpdateTransactionNameModal = ({
  open,
  onClose,
  className,
  defaultName,
  txnId,
}: {
  open: boolean;
  onClose: () => void;
  className?: string;
  defaultName?: string;
  txnId: string;
}) => {
  const { emitEvent } = useTransactionsContext();

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: defaultName || "",
    },
    validationSchema,
    onSubmit: (values) => {
      emitEvent(
        new TransactionNameUpdated({
          name: values.name,
          txnId,
        }),
      );
      onClose();
    },
  });

  // Reset form if modal is closed and reopened
  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className={className}
    >
      <form onSubmit={formik.submitForm}>
        <TapUiVerticalModalWrapper className="!max-w-lg w-full">
          <TapUiModalHead
            title="Edit Transaction Name"
            onCloseClick={onClose}
          />
          <TapUiModalContent>
            <FormikTextField
              name="name"
              formik={formik}
              label="Name"
              fullWidth
            />
          </TapUiModalContent>

          <Button
            onClick={formik.submitForm}
            fullWidth
            type={BUTTON_TYPES.SECONDARY}
            size="large"
          >
            Submit
          </Button>
        </TapUiVerticalModalWrapper>
      </form>
    </Modal>
  );
};
