import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { cn } from "common/utils";
import { DollarAmount } from "common/@types/app/DollarAmount";

import { IInvestmentsUpdates } from "./model";
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from "shadcn/ui/Carousel";
import { EmptyState } from "common/components/EmptyState";
import { BarLoader } from "common/components/BarLoader";
import { UpdateCard } from "common/components/UpdateCard";

export const CommentaryUpdates = ({
  updatesData,
  loading,
}: {
  updatesData: IInvestmentsUpdates[];
  loading: boolean;
}) => {
  const hasUpdates = updatesData.length > 0;
  const showEmptyState = !loading && !hasUpdates;

  return (
    <div className={cn("relative", {})}>
      <PageSectionTitleDivider showBorderTop>Updates</PageSectionTitleDivider>
      {loading && <BarLoader />}
      {!loading && hasUpdates && (
        <Carousel className="p-4">
          <CarouselContent className="-ml-4">
            {updatesData.map((update, index) => {
              const fundImageUrl = undefined;
              return (
                <CarouselItem className="basis-1/3 pl-4">
                  <UpdateCard
                    key={`${update.holding}-${index}`}
                    fundImageUrl={fundImageUrl}
                    fundTitle={
                      <span>
                        <span className="font-semibold">
                          {update.companyName}
                        </span>{" "}
                        in{" "}
                        <span className="font-semibold">{update.fundName}</span>
                      </span>
                    }
                    fundSubtitle={new DollarAmount(
                      Number(update.lpImpliedTransactionValue),
                    ).formattedBig()}
                    updateTitle={update.headline}
                    updateDesctription={update.text}
                    type={update.type}
                    // reportDate={update.reportDate}
                  />
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselDots className="mt-4" />
        </Carousel>
      )}
      {showEmptyState && (
        <EmptyState
          title="We don't have any updates for these interests"
          description="Check back later..."
        />
      )}
    </div>
  );
};
