export const DisclaimerFooter = () => {
  return (
    <p className="block pb-2 text-xs text-neutral-400 leading-5">
      By continuing you are agreeing to{" "}
      <a
        href="https://www.usetap.com/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
        className="font-medium text-neutral-600 underline"
      >
        Tap Privacy Policy
      </a>{" "}
      and{" "}
      <a
        href="https://www.usetap.com/terms"
        rel="noopener noreferrer"
        target="_blank"
        className="font-medium text-neutral-600 underline"
      >
        Terms of Service.
      </a>
      <br />
      Private equity investments involve risk including loss of capital and are
      not suitable for all investors.
    </p>
  );
};
