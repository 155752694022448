import dayjs from "dayjs";

const HUMAN_DATE_FORMAT = "YYYY/MM/DD";
export const PRETTY_DATE_FORMAT = "MMM D[,] YYYY";
export class DateFormatter {
  date: Date;

  constructor(date: string) {
    this.date = dayjs(date.slice(0, 10)).toDate();
  }

  get dateAsString() {
    return this.date.toISOString().slice(0, 10);
  }

  quarterFormatted: () => string = () => {
    if (this.date === null) return "";

    const month = this.date.getMonth();
    const year = this.date.getFullYear();
    let quarter = "";
    if (month >= 9) {
      quarter = "Q4";
    } else if (month >= 6) {
      quarter = "Q3";
    } else if (month >= 3) {
      quarter = "Q2";
    } else {
      quarter = "Q1";
    }

    return `${quarter} ${year}`;
  };

  daysUntil: () => string = () => {
    return dayjs(this.dateAsString).format(PRETTY_DATE_FORMAT);
  };

  daysUntilOrPast: () => string = () => {
    return dayjs(this.dateAsString).format(PRETTY_DATE_FORMAT);
  };

  humanDate: () => string = () => {
    return dayjs(this.dateAsString).format(HUMAN_DATE_FORMAT);
  };

  prettyDate: () => string = () => {
    return dayjs(this.dateAsString).format(PRETTY_DATE_FORMAT);
  };
}
