import { IIssuer } from "common/@types/models/Issuer";
import { IndicationOfInterest } from "experiences/indications-of-interest/domain/models/IOI";

export class IOIsState {}

export class IOIsLoaded extends IOIsState {
  iois: IndicationOfInterest[];

  constructor({ iois }: { iois: IndicationOfInterest[] }) {
    super();
    this.iois = iois;
  }
}

export class IOIsLoading extends IOIsState {}

export class IOIsFailedToLoad extends IOIsState {}

export class CreateIOIFormState extends IOIsState {}

export class CreateIOIFormOff extends CreateIOIFormState {}

export class CreateIOIFormLoadedBidOnly extends CreateIOIFormState {}

export class CreateIOIFormLoadedBidAndAsk extends CreateIOIFormState {
  issuersForAsk: IIssuer[];

  constructor({ issuersForAsk }: { issuersForAsk: IIssuer[] }) {
    super();
    this.issuersForAsk = issuersForAsk;
  }
}
