export enum RegRange {
  LessThan100K = 0,
  K250 = 1,
  K500 = 2,
  M1 = 3,
  M5 = 4,
  M20 = 5,
  M50 = 6,
  M100Plus = 7,
}

export const RegRangeNames = new Map<RegRange, string>([
  [RegRange.LessThan100K, "<$100K"],
  [RegRange.K250, "$250K"],
  [RegRange.K500, "$500K"],
  [RegRange.M1, "$1M"],
  [RegRange.M5, "$5M"],
  [RegRange.M20, "$20M"],
  [RegRange.M50, "$50M"],
  [RegRange.M100Plus, "$100M+"],
]);

export const RegRangeValues = new Map<RegRange, number>([
  [RegRange.LessThan100K, 99999],
  [RegRange.K250, 250000],
  [RegRange.K500, 500000],
  [RegRange.M1, 1000000],
  [RegRange.M5, 5000000],
  [RegRange.M20, 20000000],
  [RegRange.M50, 50000000],
  [RegRange.M100Plus, 100000000],
]);
