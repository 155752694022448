import Checkbox from "../Checkbox";

const FormikCheckbox = ({
  formik,
  name,
  className,
  tooltipText,
  ...props
}: {
  formik: any;
  name: string;
  style?: React.CSSProperties;
  label?: string;
  fullWidth?: boolean;
  className?: string;
  tooltipText?: string;
}) => (
  <Checkbox
    {...props}
    name={name}
    value={formik.values[name]}
    checked={formik.values[name]}
    onChange={formik.handleChange}
    htmlFor={name}
    error={Boolean(formik.touched[name]) ? formik.errors[name] : undefined}
    helperText={formik.touched[name] && formik.errors[name]}
    className={className}
    tooltipText={tooltipText}
  />
);

export default FormikCheckbox;
