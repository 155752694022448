export enum FundCoreSector {
  Energy = 1,
  Materials = 2,
  Industrial = 3,
  Consumer = 4,
  ConsumerStaples = 5,
  HealthCare = 6,
  Financials = 7,
  InformationTechnology = 8,
  CommunicationServices = 9,
  Utilities = 10,
  RealEstate = 11,
  Diversified = 12,
  DiversifiedServices = 13,
}

export const FundCoreSectorNameMap = new Map<FundCoreSector, String>([
  [FundCoreSector.Energy, "Energy"],
  [FundCoreSector.Materials, "Materials"],
  [FundCoreSector.Industrial, "Industrial"],
  [FundCoreSector.Consumer, "Consumer"],
  [FundCoreSector.ConsumerStaples, "Consumer Staples"],
  [FundCoreSector.HealthCare, "Health Care"],
  [FundCoreSector.Financials, "Financials"],
  [FundCoreSector.InformationTechnology, "Information Technology"],
  [FundCoreSector.CommunicationServices, "Communication Services"],
  [FundCoreSector.Utilities, "Utilities"],
  [FundCoreSector.RealEstate, "Real Estate"],
  [FundCoreSector.Diversified, "Diversified"],
  [FundCoreSector.DiversifiedServices, "Diversified Services"],
]);
