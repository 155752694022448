import { useEffect } from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router";
import classNames from "classnames";

import { BUTTON_TYPES, Button } from "common/components/Button";
import { PageHead } from "common/components/PageHead";
import StepperFlow from "experiences/common/Stepper/StepperFlow";
import {
  TableAlignment,
  TableCell,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { IIndicationOfInterest } from "experiences/indications-of-interest/domain/models/IOI";
import { ArrowLeft, ContactlessPayment } from "@phosphor-icons/react";
import { BarLoader } from "common/components/BarLoader";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { formatPercentPoints } from "experiences/funds/presentation/pages/ViewFund";
import { fNumber } from "common/utils/formatNumber";
import { ActiveRecentIoiListRequested } from "../state/DashboardEvent";
import { ActiveRecentIoisLoaded } from "../state/DashboardState";
import { useDashboardContext } from "../state/DashboardContext";
import { GP_ROUTES } from "common/constants/routes";
import { NOT_AVAILABLE_STR } from "common/constants/platform";

// TODO (longer term): Either put this into an env var or move to a different config/setting file/tool
// We already have many variables like this one
const CALENDLY_URL = "https://calendly.com/owen-tap/30min";

const activeIndicationsOfInterestTableColumns = [
  {
    label: "Fund",
    key: "name",
    align: TableAlignment.LEFT,
  },
  {
    label: "Create Date",
    key: "createDate",
    align: TableAlignment.LEFT,
  },
  {
    label: "Ref Date",
    key: "refDate",
    align: TableAlignment.LEFT,
  },
  {
    label: "Bid (% of NAV)",
    key: "percentOfNav",
    align: TableAlignment.RIGHT,
  },
  {
    label: "Min NAV",
    key: "minNavAmount",
    align: TableAlignment.RIGHT,
  },
  {
    label: "Max NAV",
    key: "maxNavAmount",
    align: TableAlignment.RIGHT,
  },
];

const ActiveIndicationsOfInterestTable = ({
  iois,
}: {
  iois: IIndicationOfInterest[];
}) => {
  const sortedIoisByManagerName = iois.sort(
    (a, b) =>
      a.managerName.localeCompare(b.managerName) ||
      b.minPrice - a.minPrice ||
      b.minAmount - a.minAmount,
  );

  return (
    <TapTable
      emptyStateTitle="No funds match your search"
      emptyStateDescription={
        <span>
          Please widen your search or change your filters.
          <br />
          If the fund you are looking for is not listed, please reach out to us
          at <a href="mailto:team@usetap.com">team@usetap.com</a>.
        </span>
      }
      data={sortedIoisByManagerName}
      columns={activeIndicationsOfInterestTableColumns}
      showActionsOnHover
      groupBy={(ioi: IIndicationOfInterest) => ioi.managerName}
      renderGroupHeader={(ioi: IIndicationOfInterest) => ioi.managerName}
      renderRow={(ioi: IIndicationOfInterest) => {
        const hasMinAmount = !!ioi.minAmount;
        const hasMaxAmount = !!ioi.maxAmount;
        const hasMinPrice = !!ioi.minPrice;

        return (
          <>
            <TableCell hasBullet isPrimaryColumn>
              {ioi.issuerName}
            </TableCell>
            <TableCell>
              {new DateFormatter(ioi.createdAt).humanDate()}
            </TableCell>

            <TableCell>
              {Boolean(ioi.expirationDate)
                ? new DateFormatter(ioi.expirationDate).quarterFormatted()
                : NOT_AVAILABLE_STR}
            </TableCell>
            <TableCell className={classNames("text-right")}>
              {hasMinPrice
                ? `${formatPercentPoints(ioi.minPrice * 100)}`
                : NOT_AVAILABLE_STR}
            </TableCell>
            <TableCell className={classNames("text-right")}>
              {hasMinAmount ? `$${fNumber(ioi.minAmount)}` : NOT_AVAILABLE_STR}
            </TableCell>
            <TableCell className={classNames("text-right")}>
              {hasMaxAmount ? `$${fNumber(ioi.maxAmount)}` : NOT_AVAILABLE_STR}
            </TableCell>
          </>
        );
      }}
    />
  );
};

export const ActiveIndicationsOfInterest = () => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(GP_ROUTES.Dashboard);
  };

  const { activeRecentIoisState, emitEvent } = useDashboardContext();

  useEffect(() => {
    emitEvent(new ActiveRecentIoiListRequested());
  }, []);

  const loadedActiveIois =
    activeRecentIoisState instanceof ActiveRecentIoisLoaded;

  return (
    <>
      <StepperFlow
        onClose={handleClose}
        name="New Indications of Interest"
        activeStepIndex={0}
      >
        <div>
          <Stack>
            <PageHead
              title="New Indications of Interest"
              description="Below you will find indications of interest on the holdings in your portfolio. These IOIs come from secondaries funds and other secondary market buyers. Reach out to a Tap Advisor if you would like more information."
            />

            {loadedActiveIois ? (
              <ActiveIndicationsOfInterestTable
                iois={activeRecentIoisState.iois}
              />
            ) : (
              <BarLoader />
            )}
          </Stack>
          <div className="flex flex-row justify-end mt-8 gap-1.5 mb-16">
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleClose}
              icon={<ArrowLeft />}
            >
              Close
            </Button>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              href={CALENDLY_URL}
              icon={<ContactlessPayment />}
              target="_blank"
              as={"a"}
              disabled={!loadedActiveIois}
            >
              Speak with an advisor
            </Button>
          </div>
        </div>
      </StepperFlow>
    </>
  );
};
