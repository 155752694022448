import React from "react";
import { Box, TablePagination, Typography } from "@mui/material";
import { generatePath, useNavigate } from "react-router";

import { LP_ROUTES } from "common/constants/routes";
import { useFundsContext } from "../state/FundsContext";
import { FundsState, ScreenResultsLoaded } from "../state/FundsState";
import { NewPageRequested, PageSizeChanged } from "../state/FundsEvent";
import { FundGeographyNameMap } from "../../../common/models/FundGeography";
import { Fund } from "experiences/funds/domain/models/Fund";
import { TapTable, fundsTableColumns, FundStrategy, TableCell } from "./Table";
import { Button } from "common/components/Button";
import { SUPPORT_EMAIL } from "common/constants/assets";
import { TableFundLogo } from "common/components/TableFundLogo";

export const FundsTable = () => {
  const { emitEvent, fundTablePageState } = useFundsContext();
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    emitEvent!(new NewPageRequested({ pageIndex: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    emitEvent!(new PageSizeChanged({ perPage: +event.target.value }));
  };

  const handleGoToFundDetail = (fund: Fund) => {
    navigate(generatePath(LP_ROUTES.FundsFundDetail, { fundId: fund.id }));
  };

  const buildBasedOnState: (state: FundsState) => React.ReactNode = (state) => {
    if (state instanceof ScreenResultsLoaded) {
      let fundsPage = state.fundsPage;
      return (
        <>
          <TapTable
            emptyStateTitle="No funds match your search"
            emptyStateDescription={
              <span>
                Please widen your search or change your filters.
                <br />
                If the fund you are looking for is not listed, please reach out
                to us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                .
              </span>
            }
            data={fundsPage.funds}
            columns={fundsTableColumns}
            showActionsOnHover
            renderRow={(fund: Fund) => {
              const goToFundDetail = (e?: React.MouseEvent) => {
                navigate(
                  `${LP_ROUTES.FundsFundDetail.replace(":fundId", fund.id)}`,
                );
              };

              return (
                <>
                  <TableCell
                    hasBullet
                    isPrimaryColumn
                    onClick={() => handleGoToFundDetail(fund)}
                  >
                    <TableFundLogo imgSrc={fund.logoUrl} fundName={fund.name} />
                  </TableCell>
                  <TableCell onClick={goToFundDetail}>
                    {fund.managerName}
                  </TableCell>
                  <TableCell onClick={goToFundDetail}>
                    <FundStrategy fund={fund} />
                  </TableCell>
                  <TableCell onClick={goToFundDetail}>{fund.vintage}</TableCell>
                  <TableCell onClick={goToFundDetail}>
                    {fund.geography
                      ? FundGeographyNameMap.get(fund.geography)
                      : "-"}
                  </TableCell>
                  <TableCell onClick={goToFundDetail}>
                    {fund.size?.amount ? (
                      <span>
                        <Typography display="inline">
                          {fund.size.formattedBig().split(" ")[0] +
                            fund.size.formattedBig().split(" ")[1]}
                        </Typography>
                        <Typography
                          display="inline"
                          sx={{ fontWeight: "bold" }}
                        >
                          {" " + fund.size.formattedBig().split(" ")[2]}
                        </Typography>
                      </span>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell hasActions onClick={goToFundDetail}>
                    {/* <Button onClick={handleBuyButtonClick}>Buy</Button> */}
                    <Button onClick={goToFundDetail}>View</Button>
                  </TableCell>
                </>
              );
            }}
          />

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component="div"
            count={fundsPage.totalFunds}
            rowsPerPage={fundsPage.perPage}
            page={fundsPage.pageIndex - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      );
    }
    return <></>;
  };
  return <Box pb={8}>{buildBasedOnState(fundTablePageState!)}</Box>;
};
