import { DollarAmount } from "common/@types/app/DollarAmount";
import { DescriptionList } from "common/components/DescriptionList";
import { NOT_AVAILABLE_STR } from "common/constants/platform";
import { RegAssetClassPrefLabels } from "experiences/authentication/domain/models/RegAssetClassPref";
import { RegGeographyLabels } from "experiences/authentication/domain/models/RegGeography";
import { UserSettings } from "experiences/authentication/domain/models/Settings";

export const InvestmentProfile = ({
  user,
}: {
  user: UserSettings | undefined;
}) => {
  const asssetClasses =
    user?.interestedIn?.map((assetClass) => {
      return RegAssetClassPrefLabels[assetClass];
    }) || [];

  const investmentRange =
    (user?.investmentSizeRange &&
      `${
        new DollarAmount(user?.investmentSizeRange?.start).formattedBig() ||
        NOT_AVAILABLE_STR
      } - ${
        new DollarAmount(user?.investmentSizeRange?.end).formattedBig() ||
        NOT_AVAILABLE_STR
      }`) ||
    NOT_AVAILABLE_STR;

  const geographies =
    user?.geographiesInterestedIn?.map((geography) => {
      return RegGeographyLabels[geography];
    }) || [];

  const investmentProfileItems = [
    {
      label: "Asset Classes",
      value: asssetClasses.join(", ") || NOT_AVAILABLE_STR,
    },
    {
      label: "Investment Range",
      value: investmentRange,
    },
    {
      label: "Geographies",
      value: geographies.join(", ") || NOT_AVAILABLE_STR,
    },
  ];

  return <DescriptionList items={investmentProfileItems} />;
};
