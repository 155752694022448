import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";

export class AcceptBid implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    form,
  }) => {
    return await new TapClient(
      `/transactions/${form.txnId}/bidding/bid/accept/`,
      form,
    )
      .post()
      .then(() => {
        return right(new NoReturn());
      })
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IAcceptBidForm {
  bidId: string;
  txnId: string;
}

interface IParams {
  form: IAcceptBidForm;
}
