import { DownloadSimple, UploadSimple } from "@phosphor-icons/react";

import {
  StyledTableBody,
  TapTable,
} from "experiences/funds/presentation/components/Table";

import { PageHead } from "common/components/PageHead";
import { Button, BUTTON_TYPES } from "common/components/Button";
import { dataroomV2TableColumns } from "common/components/dataroom/DataroomV2";
import { usePortfolioDocuments } from "../components/usePortfolioDocuments";
import { useDocumentsTable } from "common/components/dataroom/useDocumentsTable";
import { BarLoader } from "common/components/BarLoader";
import { Input } from "shadcn/ui/input";

export const DocumentsPage = ({}: {}) => {
  const {
    documents,
    loading,
    handleDownloadAll,
    handleFileDownload,
    handleUploadDocumentsClick,
  } = usePortfolioDocuments();

  const { rows, renderRow, search, setSearch } = useDocumentsTable({
    documentsList: documents,
    handleFileDownload,
  });

  const showDownloadAllButton = documents.length > 0;

  return (
    <div className="w-full">
      <PageHead
        title="Documents"
        actions={
          <div className="flex items-center gap-2">
            <Button
              icon={<UploadSimple />}
              onClick={handleUploadDocumentsClick}
              type={
                showDownloadAllButton
                  ? BUTTON_TYPES.PRIMARY
                  : BUTTON_TYPES.SECONDARY
              }
            >
              Upload Documents
            </Button>
            {showDownloadAllButton && (
              <Button
                icon={<DownloadSimple />}
                onClick={handleDownloadAll}
                type={BUTTON_TYPES.SECONDARY}
                disabled={!showDownloadAllButton}
              >
                Download All
              </Button>
            )}
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              className="hidden "
            />
          </div>
        }
      />

      <TapTable
        data={rows}
        isLoading={loading}
        layoutFixed
        columns={dataroomV2TableColumns}
        renderBody={(data) => (
          <StyledTableBody>{data.map(renderRow)}</StyledTableBody>
        )}
        className="w-full min-w-[700px]"
        showEmptyState
        emptyStateTitle="There are no documents to display."
        emptyStateDescription="Upload documents to see them here."
      />
      {loading && <BarLoader />}
    </div>
  );
};
