import { Navigate } from "react-router-dom";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { UNIVERSAL_ROUTES } from "common/constants/routes";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuthContext();

  if (!user) {
    // Redirect them to the ROUTES.SignUp page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // TODO: I think we should have a special route for login
    // return <Navigate to={ROUTES.SignUp} state={{ from: location }} replace />;
    return <Navigate to={UNIVERSAL_ROUTES.AuthLogin} replace />;
  }

  return children;
};
