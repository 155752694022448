import { gql } from "@apollo/client";

export const GetTransactionFundDataSoi = gql`
  query AggregateSoi(
    $transactionId: String!
    $reportDate: Date!
    $holdingsIds: [String!]
  ) {
    aggregateSoi(
      transactionId: $transactionId
      reportDate: $reportDate
      holdingsIds: $holdingsIds
    ) {
      companyName
      tapCompanyId
      fundName
      companyId
      tapFundId
      tapInvestmentId
      reportDate
      companyCurrency
      investmentDate
      holdPeriod
      investmentType
      investmentGeography
      sector
      investmentStatus
      totalCost
      totalValue
      investmentMoic
      realizedCost
      realizedValue
      unrealizedCost
      unrealizedValue
      lpImpliedInvestmentValue
      lpImpliedInvestmentPercentage
      isHumanVerified
    }
  }
`;
