import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { CellCoords } from "handsontable";
import { CellChange, RangeType } from "handsontable/common";
import { registerAllModules } from "handsontable/registry";
import { match } from "fp-ts/lib/Either";
import toast, { Toaster } from "react-hot-toast";

import { Failure } from "common/@types/app/Failure";
import {
  Holding,
  HoldingKeys,
  HoldingsBulkUpload,
} from "experiences/portfolio/domain/usecases/HoldingsBulkUpload";

import { registerCellType, DropdownCellType } from "handsontable/cellTypes";

registerAllModules();
registerCellType(DropdownCellType);

import "handsontable/dist/handsontable.full.min.css";

import { useGetFundNamesFromApi } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/useGetFundNames";
import { useStateAndRef } from "common/utils/useStateAndRef";
import { getDropdownOptions } from "experiences/indications-of-interest/presentation/components/fund-bulk-upload/getDropdownOptions";
import { useIOIsContext } from "experiences/indications-of-interest/presentation/state/IOIContext";
import {
  BULK_UPLOAD_DATE_FORMAT,
  afterOnCellMouseOut,
  afterOnCellMouseOver,
  beforePaste,
  formatCurrencyColumns,
  getNamesForColumn,
  getRawTableData,
  handleAddMoreRowsClick,
} from "experiences/indications-of-interest/presentation/components/handsOnTableUtils";
import { LP_ROUTES } from "common/constants/routes";
import { MatchStatus } from "experiences/funds/domain/usecases/MatchNames";
import { BulkIOIUploadScreenIssuerCacheIssuerAdded } from "experiences/indications-of-interest/presentation/state/IOIEvent";
import { IIssuer } from "common/@types/models/Issuer";
import { SUPPORT_EMAIL } from "common/constants/assets";

const TABLE_MAX_ROWS = 100;
export const DEFAULT_ROW_COUNT = 20;
export const ADD_MORE_ROWS_COUNT = 20;

enum COLUMNS {
  FUND_NAME = "name", // Note: not the same thing as issuer id
  LP_NAME = "lpName",
  NAV = HoldingKeys.Nav,
  COMMITTED = HoldingKeys.Committed, // TODO: this is now named COMMITTED, should we rename? or keep as is
  CONTRIBUTED = HoldingKeys.Contributed, // this is a new field
  DISTRIBUTED = HoldingKeys.Distributed, // this is a new field
  // UNFUNDED = "unfunded", // this field is removed
  REFERENCE_DATE = HoldingKeys.ReferenceDate,
}

export const TABLE_COLUMN_TITLES = {
  [COLUMNS.FUND_NAME]: `Fund Name*`,
  [COLUMNS.LP_NAME]: `LP Name*`,
  [COLUMNS.NAV]: `NAV*`,
  [COLUMNS.COMMITTED]: `Committed`,
  [COLUMNS.CONTRIBUTED]: `Contributed`,
  [COLUMNS.DISTRIBUTED]: `Distributed`,
  [COLUMNS.REFERENCE_DATE]: `Reference Date*`,
};

const ioiBulkUploadTableColumns = [
  {
    id: COLUMNS.FUND_NAME,
    title: TABLE_COLUMN_TITLES[COLUMNS.FUND_NAME],
    type: "autocomplete",
    width: 350,
    allowEmpty: false,
    tooltip: (
      <>
        Required. Use full fund names. The "Autocorrect names" button will
        attempt to correct individual fund names. If a fund does not exist in
        the Tap database, notify our team at {SUPPORT_EMAIL}.
      </>
    ),
  },
  {
    id: COLUMNS.LP_NAME,
    title: TABLE_COLUMN_TITLES[COLUMNS.LP_NAME],
    type: "text",
    width: 300,
    allowEmpty: false,
    tooltip: <>Required.</>,
  },
  {
    id: COLUMNS.NAV,
    title: TABLE_COLUMN_TITLES[COLUMNS.NAV],
    type: "numeric",
    validator: "numeric",
    allowEmpty: false,
    width: 130,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
    tooltip: <>Required. Your minimum transaction size in terms of NAV.</>,
  },
  {
    id: COLUMNS.COMMITTED,
    title: TABLE_COLUMN_TITLES[COLUMNS.COMMITTED],
    type: "numeric",
    validator: "numeric",
    width: 130,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
  },
  {
    id: COLUMNS.CONTRIBUTED,
    title: TABLE_COLUMN_TITLES[COLUMNS.CONTRIBUTED],
    type: "numeric",
    validator: "numeric",
    width: 130,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
  },
  {
    id: COLUMNS.DISTRIBUTED,
    title: TABLE_COLUMN_TITLES[COLUMNS.DISTRIBUTED],
    type: "numeric",
    validator: "numeric",
    width: 130,
    numericFormat: {
      pattern: "0,0 $",
      culture: "en-US",
    },
  },
  {
    id: COLUMNS.REFERENCE_DATE,
    title: TABLE_COLUMN_TITLES[COLUMNS.REFERENCE_DATE],
    type: "date",
    dateFormat: BULK_UPLOAD_DATE_FORMAT,
    width: 200,
    allowEmpty: false,
    tooltip: (
      <>
        Required. The quarter end of the reporting period on which your pricing
        is based. For instance, if you pricing was based on Q3 2023 you would
        put 2023-09-30.
      </>
    ),
  },
];

export const FUND_NAME_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.FUND_NAME,
);
const LP_NAME_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.LP_NAME,
);
const NAV_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.NAV,
);
const COMMITTED_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.COMMITTED,
);
const CONTRIBUTED_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.CONTRIBUTED,
);
const DISTRIBUTED_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.DISTRIBUTED,
);
const REFERENCE_DATE_COL_INDEX = ioiBulkUploadTableColumns.findIndex(
  (column) => column.id === COLUMNS.REFERENCE_DATE,
);

const dataSchema = {
  fundName: "",
  lpName: "",
  minAmount: "",
  maxAmount: "",
  committed: "",
  contributed: "",
  distributed: "",
  // unfunded: "",
  referenceDate: "",
};

type HoldingsTableColumns = [
  string | null, // COLUMNS.FUND_NAME
  number | null, // COLUMNS.MIN_AMOUNT
  number | null, // COLUMNS.MAX_AMOUNT
  number | null, // COLUMNS.COMMITTED
  number | null, // COLUMNS.CONTRIBUTED
  number | null, // COLUMNS.DISTRIBUTED
  // number | null, // COLUMNS.UNFUNDED
  string | null, // COLUMNS.REFERENCE_DATE
];

type HoldingsWithLpTableColumns = [
  string | null, // COLUMNS.FUND_NAME
  string | null, // COLUMNS.LP_NAME
  number | null, // COLUMNS.MIN_AMOUNT
  number | null, // COLUMNS.MAX_AMOUNT
  number | null, // COLUMNS.COMMITTED
  number | null, // COLUMNS.CONTRIBUTED
  number | null, // COLUMNS.DISTRIBUTED
  // number | null, // COLUMNS.UNFUNDED
  string | null, // COLUMNS.REFERENCE_DATE
];

type TableRow = HoldingsTableColumns | HoldingsWithLpTableColumns;

export const useHoldingsBulkUploadSubmit = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitHoldings = async ({ holdings }: { holdings: Holding[] }) => {
    setIsLoading(true);
    return await new HoldingsBulkUpload()
      .call({
        holdings,
      })
      .then((resp) => {
        setIsLoading(false);
        return match<Failure, Holding[], { success: boolean; response: any }>(
          (response: Failure) => {
            return { success: false, response };
          },
          (response) => {
            return { success: true, response };
          },
        )(resp);
      });
  };

  return {
    isLoading,
    submitHoldings,
  };
};

export const useBulkUpload = ({
  isReconfirm,
  preloadedIssuers,
  holdings,
  hasLpColumn,
}: {
  isReconfirm?: boolean;
  preloadedIssuers?: IIssuer[];
  holdings;
  hasLpColumn?: boolean;
}) => {
  const hotRef = useRef(null);
  const { handleCallMatchNames, isLoading: validateNamesLoading } =
    useGetFundNamesFromApi({
      useAi: true,
    });
  const { submitHoldings, isLoading: submitLoading } =
    useHoldingsBulkUploadSubmit();
  const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);
  const [tableValid, _, tableValidRef] = useStateAndRef(true);
  const { emitEvent, issuerCacheState } = useIOIsContext();
  const navigate = useNavigate();

  const getTableRef = () => hotRef?.current?.hotInstance;

  const loadPrepopulatedDataIntoTable = () => {
    // load the data into the table
    const table = getTableRef();
    const { rowsWithSomeData } = getRawTableData(table);

    // only load some data if the table is empty
    const tableHasAtLeastOnePrePopulatedRow = rowsWithSomeData.length > 0;

    // we don't want to be loading data into the table if it already has data
    // for both performance reasons and because it will avoid a bug in which data could be reset
    // if the user changes the data while the table is "loading" or handsontable is not yet aware
    // of the data that was loaded
    if (!tableHasAtLeastOnePrePopulatedRow) {
      // loadData is better than setData because it does it in a single step
      // setDataAtCell will trigger a re-render for each cell, and takes a lot of time
      table.loadData(holdings);
      // add some extra empty rows for better UX
      handleAddMoreRowsClick(table, setRowCount, 10);
    }
  };

  // if we're preloading data to the table, make sure the cache includes the preloaded data so that the funds are considered valid
  useEffect(() => {
    if (isReconfirm && preloadedIssuers.length) {
      // save issuers to the cache
      // TODO: FIX THIS
      // note: for capital accounts upload the api endpoint does not reply with actual IIsuers but rather it only replies with the fund names
      // so we need to find a way to get the actual issuer id from the fund name
      // in the other tables this is not an issue, so we just trigger the event and the cache is updated
      // but because we don't have the issuer id, we cannot do that here
      // so we are, for now, not adding anything to the cache
      // and just loading the data into the table, allowing "invalid" fund names to be added to the table
      // this should be corrected by the user by actually searching for the fund name and selecting the correct one
      // or by clicking the autocorrect fund names button that should trigger the actual fund name validation
      // preloadedIssuers.forEach((issuer) => {
      //   emitEvent!(new BulkIOIUploadScreenIssuerCacheIssuerAdded({ issuer }));
      // });

      // save preloaded data to the table
      loadPrepopulatedDataIntoTable();
    }
  }, [preloadedIssuers, isReconfirm]);

  const disableAddMoreRows = rowCount >= TABLE_MAX_ROWS;

  // This is pretty much the same list of columns but has a few considerations:
  // - useMemo so that the table is not re-rendering all of the time, this prevents a few bugs I (@jmz7v) observed
  // - this starts from `ioiBulkUploadTableColumns` but we add a few extra fields to the fund name column
  //   - source: this is the function that will be called when the user types in the fund name column, this is where we do the search
  //     and will be calling the api that gets the fund names, in an identical way to how the search fund field works
  //   - validator: handsontable validation works with a function and a callback, but because we're pulling data from the api
  //     we cannot just "validate" against a list of funds (options), so every fund that is returned from the api is saved
  //     at the `issuerCache` state, and then we validate against that list, if the string is not in the list, then it's not valid
  //     (handsontable only works with an array of strings, not objects, so we need to do this by querying directly for the name)
  //     if the string is in the list, then it's valid, we return that to the callback, and handsontable will handle the rest
  const FULL_COLUMN_LIST = {
    ...ioiBulkUploadTableColumns[FUND_NAME_COL_INDEX],
    source: (query: string, process: (string: any[]) => void) =>
      getDropdownOptions(query, process, issuerCacheState),
    validator: async (value: string, callback: (valid: boolean) => void) => {
      const isValid = Array.from(issuerCacheState.current).some((issuer) => {
        return issuer.name === value;
      });

      callback(isValid);
    },
  };

  // Plug the fund name with the new source and validator function into the columns list
  // Note: Looks like handsontable provides a way for you to update its "settings", but I didn't explore that option
  // and decided to pass the new list of columns directly to the table instead
  const TABLE_COLUMNS_WITH_HIJACKED_LIST = useMemo(() => {
    if (hasLpColumn) {
      return [
        ...ioiBulkUploadTableColumns.splice(
          0,
          LP_NAME_COL_INDEX,
          FULL_COLUMN_LIST,
        ),
        ...ioiBulkUploadTableColumns.slice(LP_NAME_COL_INDEX),
      ];
    }

    return [
      ...ioiBulkUploadTableColumns.splice(0, NAV_COL_INDEX, FULL_COLUMN_LIST),
      ...ioiBulkUploadTableColumns.slice(NAV_COL_INDEX),
    ];
  }, [FULL_COLUMN_LIST, issuerCacheState.current.size]);

  // array of strings that contains all the column titles
  const tableColumnTitles = TABLE_COLUMNS_WITH_HIJACKED_LIST.map(
    (column) => column.title,
  );

  // array of numbers that contains all the column widths
  const tableColumnWidths = TABLE_COLUMNS_WITH_HIJACKED_LIST.map(
    (column) => column.width,
  );

  const submitApiCall = async (valid: boolean) => {
    tableValidRef.current = valid;

    if (!valid) {
      return undefined;
    }

    const table = getTableRef();
    const { rowsWithSomeData } = getRawTableData<TableRow>(table);

    const holdings: Holding[] = table
      .getSourceData()
      .filter((_: any, index: number) => rowsWithSomeData.includes(index))
      .map((row: TableRow): Holding => {
        const issuer = Array.from(issuerCacheState.current).find((issuer) => {
          if (typeof issuer.name === "undefined") {
            return false;
          }
          return issuer.name === row[FUND_NAME_COL_INDEX];
        });

        // Will be used to filter out undefined values
        if (!issuer) {
          return undefined;
        }

        if (hasLpColumn) {
          return {
            [HoldingKeys.IssuerId]: issuer.id,
            [HoldingKeys.LpName]: String(row[LP_NAME_COL_INDEX]),
            [HoldingKeys.Nav]: Number(row[NAV_COL_INDEX]),
            [HoldingKeys.Committed]: Number(row[COMMITTED_COL_INDEX] || 0),
            [HoldingKeys.Contributed]: Number(row[CONTRIBUTED_COL_INDEX] || 0),
            [HoldingKeys.Distributed]: Number(row[DISTRIBUTED_COL_INDEX] || 0),
            [HoldingKeys.ReferenceDate]: row[REFERENCE_DATE_COL_INDEX],
          };
        }

        return {
          [HoldingKeys.IssuerId]: issuer.id,
          [HoldingKeys.Nav]: Number(row[NAV_COL_INDEX]),
          [HoldingKeys.Committed]: Number(row[COMMITTED_COL_INDEX] || 0),
          [HoldingKeys.Contributed]: Number(row[CONTRIBUTED_COL_INDEX] || 0),
          [HoldingKeys.Distributed]: Number(row[DISTRIBUTED_COL_INDEX] || 0),
          [HoldingKeys.ReferenceDate]: row[REFERENCE_DATE_COL_INDEX],
        };
      })
      .filter((holding: Holding | undefined) => holding);

    const { success } = await submitHoldings({ holdings });

    if (success) {
      navigate(LP_ROUTES.PortfolioHoldings);
    }
  };

  const validateTable = () => {
    const table = getTableRef();

    const { rowsWithSomeData, emptyRows } = getRawTableData<TableRow>(table);

    // handsontable does not provide a way to "clear" validation for empty rows
    // you can get an invalid empty row by having an invalid row and then clearing its values
    // that should now be an empty row, but it should be valid
    // so we need to manually remove the "invadid" styles from the empty rows
    const validateColumns = hasLpColumn
      ? [
          FUND_NAME_COL_INDEX,
          LP_NAME_COL_INDEX,
          NAV_COL_INDEX,
          REFERENCE_DATE_COL_INDEX,
        ]
      : [FUND_NAME_COL_INDEX, NAV_COL_INDEX, REFERENCE_DATE_COL_INDEX];

    // validate all cells that are required, set the ref to fase if there's an invalid cell

    rowsWithSomeData.forEach((row) => {
      validateColumns.forEach((col) => {
        table.validateCell(
          table.getDataAtCell(row, col),
          table.getCellMeta(row, col),
          (valid: boolean) => {
            // force errors on invalid cells
            if (!valid) {
              table.setCellMeta(row, col, "valid", false);
              tableValidRef.current = false;
            }
          },
        );
      });
    });

    // but we also need to remove the "invalid" styles from the empty rows, because they are valid
    emptyRows.forEach((row) => {
      validateColumns.forEach((col) => {
        table.setCellMeta(row, col, "valid", true);
      });
    });

    // re-render the table so that the styles are updated
    table.render();

    // only validate rows that have some data (rowsWithSomeData)
    table.validateRows(rowsWithSomeData, submitApiCall);

    // re-render the table so that the styles are updated
    table.render();

    // return the ref, this is done to prevent multiple re-render bugs caused by handsontable
    // because ideally we would use react's useState hook, but handsontable looses its state
    // if we do any state updates while the validate+submit process is happening, so we need to use a ref instead
    return { table, valid: tableValidRef };
  };

  const handleSubmitClick = () => {
    const { table, valid } = validateTable();

    return { table, valid };
  };

  // This function will:
  // 1. Get all fund names from the table
  // 2. Send them to the api, will receive a "match result" for each fund name
  // 3. For each result, we'll replace the fund name in the table with the matched fund name from the api`
  const autoCorrectFundNames = async () => {
    const table = getTableRef();
    const {
      names: fundNames,
      rowsWithSomeData,
      data,
    } = getNamesForColumn(table, FUND_NAME_COL_INDEX);

    // Get matching fund names from the api
    const fundCorrections = await handleCallMatchNames({
      searchTerms: fundNames,
    });

    // DO NOT DELETE THIS 👇👇👇
    if (!fundCorrections) return null;

    // Update the issuer cache with the results from the matches
    // so that they are considered valid options and not rejected by the validator
    // this is a side effect, if we extract this, be a bit careful
    fundCorrections.forEach((match) => {
      const candidate = match.matchedCandidates[0];
      if (Boolean(candidate)) {
        emitEvent!(
          new BulkIOIUploadScreenIssuerCacheIssuerAdded({ issuer: candidate }),
        );
      }
    });

    // For each row that has some data, get the fund name and replace it with the matched fund name from the api
    // but only do that if it was a perfect or partial match, do not strictly rely on the correction.matchedCandidates[0] value
    // as it might not exist (will be an empty array) if it was not a perfect or partial match
    // Reminder: this function will work with both perfect and partial matches, but the handlePaste function
    // will work exclusively with perfect matches
    const newFundNameCellValues: [
      rowIndex: number,
      colIndex: number,
      newValue?: string,
    ][] = rowsWithSomeData.map((rowIndex) => {
      const fundName = String(data[rowIndex][FUND_NAME_COL_INDEX]);
      const correction = fundCorrections.find(
        (c) =>
          c.searchTerm.toLowerCase().trim() === fundName.toLowerCase().trim(),
      );
      const correctionCandidate =
        (correction?.matchedCandidates?.length &&
          correction?.matchedCandidates[0]) ||
        undefined;

      const correctionValue = correctionCandidate?.name || "";

      return [rowIndex, FUND_NAME_COL_INDEX, correctionValue];
    });

    // Filter out rows that don't have a new fund name
    const cleanNewFundNameCellValues = newFundNameCellValues.filter((row) =>
      Boolean(row[2]),
    );

    // setDataAtCell requires data in the format:
    // [[rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue]]
    table.setDataAtCell(cleanNewFundNameCellValues);
  };

  const handlePaste = async (
    pastedData: any[][],
    pastePosition: RangeType[],
  ) => {
    const table = getTableRef();

    // step 1. detect if what was pasted was on the fund name column
    if (pastePosition[0].startCol === FUND_NAME_COL_INDEX) {
      // do the actual fund name validation

      const fundNames = pastedData.map((row) => row[0]); // grab the 1st row from the pasted events, from the 1st section of the pasted events

      // step 2. grab all rows that were pasted
      // Get matching fund names from the api
      const fundCorrections = await handleCallMatchNames({
        searchTerms: fundNames,
      });

      // DO NOT DELETE THIS 👇👇👇 (prevents from crashing if there are not suggested matches)
      if (!fundCorrections || fundCorrections.length === 0) return null;

      // Update the issuer cache with the results from the matches
      // so that they are considered valid options and not rejected by the validator
      // this is a side effect, if we extract this, be a bit careful
      fundCorrections.forEach((match) => {
        const candidate = match.matchedCandidates[0];
        if (Boolean(candidate)) {
          emitEvent!(
            new BulkIOIUploadScreenIssuerCacheIssuerAdded({
              issuer: candidate,
            }),
          );
        }
      });

      const range = Array.from(
        Array(pastePosition[0].endRow - pastePosition[0].startRow).keys(),
        (i) => i + pastePosition[0].startRow,
      );

      const newFundNameCellValues: [
        rowIndex: number,
        colIndex: number,
        newValue?: string,
      ][] = range.map((rowIndex) => {
        const adjustedRow = rowIndex - pastePosition[0].startRow;
        const fundName = String(pastedData[adjustedRow][FUND_NAME_COL_INDEX]);

        const correction = fundCorrections.find(
          (c) =>
            c.searchTerm.toLowerCase().trim() === fundName.toLowerCase().trim(),
        );

        // do the corrections but ONLY if it was a perfect match
        const correctionCandidate =
          (correction?.matchedCandidates?.length &&
            correction.status === MatchStatus.PerfectMatch &&
            correction?.matchedCandidates[0]) ||
          undefined;

        const correctionValue = correctionCandidate?.name || "";

        return [rowIndex, FUND_NAME_COL_INDEX, correctionValue];
      });

      // Filter out rows that don't have a new fund name
      const cleanNewFundNameCellValues = newFundNameCellValues.filter((row) =>
        Boolean(row[2]),
      );

      // setDataAtCell requires data in the format:
      // [[rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue], [rowIndex, colIndex, newValue]]
      table.setDataAtCell(cleanNewFundNameCellValues);
    }
  };

  const afterChange = (changes: CellChange[] | null) => {
    const table = getTableRef();

    formatCurrencyColumns({
      table,
      changes,
      columnsToFormat: [
        NAV_COL_INDEX,
        COMMITTED_COL_INDEX,
        CONTRIBUTED_COL_INDEX,
        DISTRIBUTED_COL_INDEX,
      ],
    });
  };

  return {
    hotRef,
    getTableRef,
    handleAddMoreRowsClick: (count = ADD_MORE_ROWS_COUNT) =>
      handleAddMoreRowsClick(getTableRef(), setRowCount, count),
    disableAddMoreRows,
    handleSubmitClick,
    submitLoading,
    autoCorrectFundNames,
    validateNamesLoading,
    tableColumnTitles,
    tableColumns: TABLE_COLUMNS_WITH_HIJACKED_LIST,
    tableColumnWidths,
    dataSchema,
    tableValid: tableValid,
    handlePaste,
    afterOnCellMouseOver: (
      _: MouseEvent,
      coords: CellCoords,
      el: HTMLElement,
    ) => afterOnCellMouseOver(coords, el, TABLE_COLUMNS_WITH_HIJACKED_LIST),
    afterOnCellMouseOut: afterOnCellMouseOut,
    afterChange,
    beforePaste: (data, coords) =>
      beforePaste({
        data,
        coords,
        currencyColumnIndexes: [
          NAV_COL_INDEX,
          COMMITTED_COL_INDEX,
          CONTRIBUTED_COL_INDEX,
          DISTRIBUTED_COL_INDEX,
        ],
      }),
  };
};
