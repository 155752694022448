export enum IOIType {
  Bid = 1,
  Ask = 2,
}
export interface IIndicationOfInterest {
  userId: string;
  isArchived: boolean;
  id: string;
  createdAt: string;
  issuerName: string;
  managerName: string;
  managerId: string;
  logoUrl: string;
  isOpaque?: boolean;
  isGtc?: boolean;
  type?: IOIType;
  minPrice?: number | null;
  maxPrice?: number | null;
  minAmount?: number | null;
  maxAmount?: number | null;
  expirationDate?: string | null;
}

export const IOITypeLabel = {
  [IOIType.Bid]: "Bid",
  [IOIType.Ask]: "Ask",
};

export interface IBid extends IIndicationOfInterest {
  referenceDate: string | null;
  maxAmount?: number;
  minAmount: number;
  maxPrice?: number;
  minPrice?: number;
  expirationDate?: string;
  isLpOnFund: boolean;
  fundId: string;
}

export interface IAsk extends IIndicationOfInterest {
  referenceDate: string | null;
  maxAmount: number;
  minAmount?: number;
  maxPrice?: number;
  minPrice?: number;
  expirationDate?: string;
  fundId: string;
  isOpaque?: boolean;
}

export class IndicationOfInterest implements IIndicationOfInterest {
  userId: string;
  isArchived: boolean;
  id: string;
  createdAt: string;
  issuerName: string;
  managerName: string;
  managerId: string;
  logoUrl: string;
  isOpaque?: boolean;
  isGtc?: boolean;
  type?: IOIType;

  constructor({
    id,
    isArchived,
    userId,
    createdAt,
    issuerName,
    managerName,
    managerId,
    logoUrl,
    isOpaque,
    isGtc,
    type,
  }: IIndicationOfInterest) {
    this.id = id;
    this.isArchived = isArchived;
    this.userId = userId;
    this.createdAt = createdAt;
    this.issuerName = issuerName;
    this.managerName = managerName;
    this.managerId = managerId;
    this.logoUrl = logoUrl;
    this.isOpaque = isOpaque;
    this.isGtc = isGtc;
    this.type = type;
  }
}

export class Bid extends IndicationOfInterest implements IBid {
  referenceDate: string | null;
  minAmount: number;
  maxPrice?: number;
  maxAmount?: number;
  minPrice?: number;
  isLpOnFund: boolean;
  expirationDate?: string;
  fundId: string;
  isGtc?: boolean;

  constructor({
    referenceDate,
    maxAmount,
    minAmount,
    maxPrice,
    minPrice,
    isLpOnFund,
    expirationDate,
    fundId,
    isGtc,
    ...props
  }: IBid) {
    super(props);
    this.referenceDate = referenceDate;
    this.fundId = fundId;
    this.maxAmount = maxAmount;
    this.minAmount = minAmount;
    this.maxPrice = maxPrice;
    this.minPrice = minPrice;
    this.isLpOnFund = isLpOnFund;
    this.expirationDate = expirationDate;
    this.fundId = fundId;
    this.isGtc = isGtc;
  }
}

export class Ask extends IndicationOfInterest implements IAsk {
  referenceDate: string | null;
  maxAmount: number;
  minPrice?: number;
  minAmount?: number;
  maxPrice?: number;
  isGtc?: boolean;
  expirationDate?: string;
  fundId: string;
  isOpaque?: boolean;

  constructor({
    referenceDate,
    maxAmount,
    minAmount,
    maxPrice,
    minPrice,
    isGtc,
    expirationDate,
    fundId,
    isOpaque,
    ...props
  }: IAsk) {
    super(props);
    this.referenceDate = referenceDate;
    this.maxAmount = maxAmount;
    this.minAmount = minAmount;
    this.maxPrice = maxPrice;
    this.minPrice = minPrice;
    this.isGtc = isGtc;
    this.expirationDate = expirationDate;
    this.fundId = fundId;
    this.isOpaque = isOpaque;
  }
}

export const askOrBid: (obj: any) => IndicationOfInterest = (obj) => {
  if (obj.type == IOIType.Bid) {
    return new Bid(obj);
  } else {
    return new Ask(obj);
  }
};
