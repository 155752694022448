import { useState } from "react";
import { useParams } from "react-router";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";

import { ITransactionSummary } from "../../../domain/models/Transaction";
import { useTransactionsContext } from "../../state/TransactionsContext";
import {
  AcceptBidButtonClicked,
  BidListDownloadDocumentClicked,
} from "../../state/TransactionsEvent";
import { BidsListLoaded } from "../../state/TransactionsState";
import { Bid } from "experiences/transactions/domain/models/Bidding";
import { Percentage } from "common/@types/app/Percentage";
import { DollarAmount } from "common/@types/app/DollarAmount";
import DownloadDownArrow from "common/components/Icons/DownloadDownArrow";
import TextChip from "experiences/common/TextChip";
import AcceptBidScreen from "./AcceptBidScreen";
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from "experiences/funds/presentation/components/Table";
import Loader from "common/components/TapLoader";
import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES, Button } from "common/components/Button";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: "calc(100%)",
  },
}));

interface ISellerBidRowProps {
  bid: Bid;
}
interface ISellerBidActionsProps {
  bid: Bid;
  onAcceptClicked?: () => void;
}

interface IDownloadLOIButtonProps {
  onClick: () => void;
}

const DownloadLOIButton: React.FC<IDownloadLOIButtonProps> = ({ onClick }) => {
  return (
    <Box>
      <Button onClick={onClick}>
        <DownloadDownArrow />
      </Button>
    </Box>
  );
};

const SellerBidActions: React.FC<ISellerBidActionsProps> = ({
  bid,
  onAcceptClicked,
}) => {
  const { emitEvent } = useTransactionsContext();
  const { txnId } = useParams();

  return (
    <Stack direction="row" spacing={1}>
      <DownloadLOIButton
        onClick={() => {
          emitEvent!(
            new BidListDownloadDocumentClicked({
              txnId: txnId!,
              document: bid.document!,
            }),
          );
        }}
      />
      <Stack justifyContent="center">
        {bid.isSettling() ? (
          <TextChip text={"SETTLING"} />
        ) : (
          <span>
            <Button
              size="small"
              onClick={onAcceptClicked}
              type={BUTTON_TYPES.SECONDARY}
            >
              ACCEPT
            </Button>
          </span>
        )}
      </Stack>
    </Stack>
  );
};

const SellerBidRow: React.FC<ISellerBidRowProps> = ({ bid }) => {
  const { emitEvent } = useTransactionsContext();
  const { txnId } = useParams();
  const [advanceModalOpen, setAdvanceModalOpen] = useState<boolean>(false);
  const classes = useStyles();

  const onClose = () => {
    setAdvanceModalOpen(false);
  };

  const onSubmitBidAcceptance = () => {
    emitEvent!(new AcceptBidButtonClicked({ txnId: txnId!, bidId: bid.id! }));
    onClose();
  };

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography fontWeight="bold">{bid.bidderOrgName!}</Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        {new Percentage(bid.percentValue! * 100).formatted()}
      </StyledTableCell>
      <StyledTableCell align="left">
        {new DollarAmount(bid.dollarValue!).formatted()}
      </StyledTableCell>
      <StyledTableCell align="left">
        {bid.submittedAt
          ? moment(bid.submittedAt).format("YYYY-MM-DD hh:mm A")
          : "Not Yet"}
      </StyledTableCell>
      <StyledTableCell align="left">
        <SellerBidActions
          bid={bid}
          onAcceptClicked={() => setAdvanceModalOpen(true)}
        />
        <Drawer
          anchor={"bottom"}
          open={advanceModalOpen}
          onClose={onClose}
          classes={{ paper: classes.drawerPaper }}
        >
          <AcceptBidScreen
            bid={bid}
            onSubmit={onSubmitBidAcceptance}
            onClose={onClose}
          />
        </Drawer>
      </StyledTableCell>
    </StyledTableRow>
  );
};

interface IBiddingEmptyContainerProps {
  txnSummary: ITransactionSummary;
}

const SellerBiddingEmptyContainer: React.FC<IBiddingEmptyContainerProps> = ({
  txnSummary,
}) => {
  return (
    <EmptyState
      title="No current bids"
      description="Once you have bids submitted on the transaction, you can view and manage them here."
    />
  );
};

interface ISellerBidsViewScreenProps {
  txnSummary: ITransactionSummary;
}

export const SellerBidViewScreen: React.FC<ISellerBidsViewScreenProps> = ({
  txnSummary,
}) => {
  const { bidsListState } = useTransactionsContext();

  return (
    <>
      {bidsListState instanceof BidsListLoaded ? (
        bidsListState.bids.length <= 0 ? (
          <SellerBiddingEmptyContainer txnSummary={txnSummary} />
        ) : (
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <th style={{ textAlign: "left" }}>Firm</th>
                <th style={{ width: "100px", textAlign: "left" }}>
                  Pricing (%)
                </th>
                <th style={{ width: "220px", textAlign: "left" }}>
                  Pricing ($)
                </th>
                <th style={{ width: "220px", textAlign: "left" }}>Submitted</th>
                <th style={{ width: "160px", textAlign: "left" }}></th>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {bidsListState.bids.map((bid) => (
                <SellerBidRow bid={bid} />
              ))}
            </StyledTableBody>
          </StyledTable>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};
