import { LPPortal } from "experiences/portfolio/domain/models/LPPortal";

export const listOfLpPortals = [
  [
    {
      imagePath: "/images/portals/addepar-logo.png",
      portalId: LPPortal.Addepar,
      altText: "",
    },
    {
      imagePath: "/images/portals/arch-logo.svg",
      portalId: LPPortal.Arch,
      altText: "",
    },
    {
      imagePath: "/images/portals/bny-mellon-logo.png",
      portalId: LPPortal.BnyMellon,
      altText: "",
    },
  ],
  [
    {
      imagePath: "/images/portals/canoe-logo.svg",
      portalId: LPPortal.Canoe,
      altText: "",
    },
    {
      imagePath: "/images/portals/cobalt-logo.png",
      portalId: LPPortal.Cobalt,
      altText: "",
    },
    {
      imagePath: "/images/portals/efront-logo.png",
      portalId: LPPortal.Efront,
      altText: "",
    },
  ],
  [
    {
      imagePath: "/images/portals/s&p-logo.png",
      portalId: LPPortal.SnP,
      altText: "",
    },
    {
      imagePath: "/images/portals/solovis-logo.png",
      portalId: LPPortal.Solovis,
      altText: "",
    },
    {
      imagePath: "/images/portals/state-street-logo.png",
      portalId: LPPortal.StateStreet,
      altText: "",
    },
  ],
  [
    {
      imagePath: "/images/portals/burgiss-logo.png",
      portalId: LPPortal.Burgiss,
      altText: "",
    },
    {
      imagePath: "/images/portals/northern-trust-logo.png",
      portalId: LPPortal.NorthernTrust,
      altText: "",
    },
    {
      imagePath: "/images/portals/step-stone-logo.png",
      portalId: LPPortal.StepStone,
      altText: "",
    },
  ],
];
