import { Either, left, right } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { FirmFund } from "experiences/firm/domain/models/FirmFund";

const PATH = "/api/firm/funds";

export class GetFirmFunds implements IAsyncUseCase<IParams, FirmFund[]> {
  call: (params: IParams) => Promise<Either<Failure, FirmFund[]>> =
    async () => {
      return await new TapClient(PATH)
        .get({})
        .then((resp: FirmFund[]) => right(resp))
        .catch((resp: any) => left(new Failure(resp.response)));
    };
}

// We are not sending any params to the get request but may in the future, so let's keep the interface
interface IParams {}
