import { useContext, useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { portfolioClient } from "common/clients/ApolloClient";
import { ReportDateName } from "experiences/portfolio-v2/domain/models/ReportDates";
import { ILiquidityProjectionTimeline } from "experiences/transactions/presentation/components/fund-data/commentary/LiquidityProjection";
import { getReportDatesForReportName } from "experiences/portfolio-v2/domain/hooks/getReportDatesForReportName";
import { generateManagerAssessmentCharts } from "experiences/transactions/presentation/components/fund-data/commentary/generateManagerAssessmentCharts";
import {
  IInvestmentsInsights,
  IInvestmentsUpdates,
} from "experiences/transactions/presentation/components/fund-data/commentary/model";

import { useEntitiesSelector } from "../components/EntitiesDropdown";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { parseStrToNumber } from "experiences/common/excel/utils";
import { IReportDate } from "experiences/transactions/presentation/components/fund-data/useFundDataReportDate";

export interface IQualitativeDataQuery {
  qualitativeData: {
    investmentsInsights: IInvestmentsInsights[];
    investmentsUpdates: IInvestmentsUpdates[];
  };
}

const QualitativeDataQuery = gql`
  query Query($entitiesIds: [String!]!, $reportDate: Date!) {
    qualitativeData(entitiesIds: $entitiesIds, reportDate: $reportDate) {
      investmentsInsights {
        holding
        fund
        fundName
        managerId
        managerName
        company
        companyId
        companyName
        reportDate
        isHumanVerified
        isReadyForUser
        gpOutlook
        gpOutlookDescription
        performanceToPlan
        performanceToPlanDescription
        liquidityTimeline
        liquidityTimelineDescription
        distressIndicator
        distressIndicatorDescription
        macroExposure
        macroExposureDescription
        lpImpliedTransactionValue
        lpImpliedInvestmentPercentage
      }
      investmentsUpdates {
        holding
        fund
        fundName
        managerId
        managerName
        company
        companyId
        companyName
        reportDate
        isHumanVerified
        isReadyForUser
        type
        headline
        text
        impact
        lpImpliedTransactionValue
      }
    }
  }
`;

export const useCommentary = () => {
  const [loading, setLoading] = useState(true);
  const [liquidityProjectionChart, setLiquidityProjectionChart] = useState<
    ILiquidityProjectionTimeline[]
  >([]);
  const [commentaryUpdates, setCommentaryUpdates] = useState<
    IInvestmentsUpdates[]
  >([]);
  const [companyData, setCompanyData] = useState<IInvestmentsInsights[]>([]);
  const { selectedEntitiesIds, reportDates: allReportDates } =
    useContext(PortfolioContext);
  const [currentReportDate, setCurrentReportDate] = useState<string | null>(
    getReportDatesForReportName({
      reportDates: allReportDates,
      reportName: ReportDateName.QualitativeData,
    }).length > 0
      ? getReportDatesForReportName({
          reportDates: allReportDates,
          reportName: ReportDateName.QualitativeData,
        })[0]
      : null,
  );

  const qualitativeDataReportDates = getReportDatesForReportName({
    reportDates: allReportDates,
    reportName: ReportDateName.QualitativeData,
  });

  const {
    data,
    loading: qualitativeDataLoading,
    refetch,
  } = useQuery<IQualitativeDataQuery>(QualitativeDataQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
      reportDate: currentReportDate,
    },
    skip: selectedEntitiesIds.length === 0 || !currentReportDate,
  });

  const entitySelector = useEntitiesSelector();

  useEffect(() => {
    if (qualitativeDataReportDates.length > 0) {
      setCurrentReportDate(qualitativeDataReportDates[0]);
    }
  }, [allReportDates]);

  const handleRefetchQualitativeData = async () => {
    if (currentReportDate && selectedEntitiesIds.length > 0) {
      setLoading(true);
      // This updates the "data" value, so it will automatically trigger a re-render
      await refetch({
        reportDate: currentReportDate,
        entitiesIds: selectedEntitiesIds,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    handleRefetchQualitativeData();
  }, [currentReportDate, selectedEntitiesIds]);

  const managerAssessmentCharts = useMemo(() => {
    return companyData.length > 0
      ? generateManagerAssessmentCharts(companyData)
      : { outlookChartData: [], performanceToPlanChartData: [] };
  }, [companyData]);

  useEffect(() => {
    if (data) {
      const sortedCompanyData = [
        ...data.qualitativeData.investmentsInsights,
      ].sort(
        (a, b) =>
          (parseStrToNumber(b.lpImpliedInvestmentPercentage || "") || 0) -
          (parseStrToNumber(a.lpImpliedInvestmentPercentage || "") || 0),
      );

      setCompanyData(sortedCompanyData);
      setCommentaryUpdates(data.qualitativeData.investmentsUpdates);
    }
  }, [data]);

  const reportDate: IReportDate = {
    options: qualitativeDataReportDates.map((date) => ({
      label: date,
      value: date,
    })),
    currentDate: currentReportDate,
    onReportDateChange: (value) => setCurrentReportDate(value),
  };

  return {
    managerAssessmentCharts,
    liquidityProjectionChart,
    commentaryUpdates,
    companyData,
    loading: loading || qualitativeDataLoading,
    entitySelector,
    reportDate,
  };
};
