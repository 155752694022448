import { Box, Stack, Typography } from "@mui/material";

interface ILogoTextChipProps {
  text: String;
  logoChild: React.ReactNode;
  direction?: "normal" | "reversed";
  color?: string;
  fontSize?: string;
}

const LogoTextChip: React.FC<ILogoTextChipProps> = ({
  logoChild,
  text,
  direction = "normal",
  color = "#737476",
  fontSize,
}) => {
  return (
    <Box display="inline-block" mx={0.5}>
      <Stack
        direction={direction == "normal" ? "row" : "row-reverse"}
        sx={{ backgroundColor: color + "30", borderRadius: "70px" }}
        alignItems="center"
        spacing={1}
        px={1.2}
        py={0.5}
      >
        {logoChild}
        <Typography
          variant="body1"
          fontSize={fontSize ?? "10px"}
          fontWeight={900}
          color={color}
        >
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

export default LogoTextChip;
