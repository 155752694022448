import { useState } from "react";
import styled from "styled-components";
import { NotePencil, Star, Unite, UserPlus } from "@phosphor-icons/react";

import { Avatar } from "common/components/Avatar";
import { Button } from "common/components/Button";
import { DescriptionList } from "common/components/DescriptionList";

import TextChip from "experiences/common/TextChip";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { UserSettings } from "experiences/authentication/domain/models/Settings";
import { RegMarketEngagementLabels } from "experiences/authentication/domain/models/RegMarketEngagement";

const StyledUserPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 24px;
  box-sizing: border-box;
  background: var(--branding-texture-2, #fafaf8);
`;

export const UserPreview = ({
  openUpdateUserModal,
  user,
}: {
  openUpdateUserModal: () => void;
  user?: UserSettings;
}) => {
  const { user: currentUser } = useAuthContext();
  const [showEditButton, setShowEditButton] = useState(false);

  const fullName =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : "--";

  const marketEngagement = user?.marketEngagement
    ? user.marketEngagement.map((quality) => RegMarketEngagementLabels[quality])
    : [];

  return (
    <StyledUserPreview>
      <div className="flex items-center gap-4">
        <Avatar size={64} user={user} />
        <h1 className="font-semibold text-lg tracking-tight leading-snug mt-2 cursor-default">
          {fullName}
        </h1>
      </div>
      <div>
        {currentUser.isActive ? (
          <TextChip
            text={
              <>
                <UserPlus />
                active
              </>
            }
            success
            style={{ marginLeft: 8 }}
          />
        ) : null}
        {currentUser.isStaff ? (
          <TextChip
            text={
              <>
                <Star color="#b85d09" /> staff
              </>
            }
            pending
            style={{ marginLeft: 8 }}
          />
        ) : null}
      </div>
      <DescriptionList
        items={[
          {
            label: "Firm",
            value: user?.firmName || "",
          },
          { label: "Email", value: user?.email || "" },
          {
            label: "Secondary Market Access",
            value: marketEngagement.join(", ") || "",
          },
        ]}
      />
      {showEditButton ? (
        <Button fullWidth onClick={openUpdateUserModal} icon={<NotePencil />}>
          Edit
        </Button>
      ) : null}
    </StyledUserPreview>
  );
};
