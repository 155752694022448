import axios from "axios";
import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import { RegStep } from "../models/RegStep";

export const RegStepNameMap = new Map<RegStep, String>([
  [RegStep.Initial, "Primary Residence"],
  [RegStep.CreateAccount, "Create your account"],
  [RegStep.CreateAccountInfo, "Create your account (cont)"],
  [RegStep.InvestmentPreferences, "Select Investment Preferences"],
  [RegStep.InvestmentPreferencesGeo, "Select Investment Preferences (cont)"],
]);

export class SendRegStepSlackEvent implements IAsyncUseCase<IParams, NoReturn> {
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async ({
    regStep,
    payload,
    isForInvitee,
  }) => {
    const ipResp: any = await axios.get("https://api.ipify.org/?format=json");
    const attachments = [
      {
        pretext: "Registration event on Tap",
        color: isForInvitee ? "#6C5B7B" : "#b7ff00",
        fields: [
          { title: "Client Public IP", value: ipResp.data.ip, short: true },
          { title: "Step", value: RegStepNameMap.get(regStep), short: true },
          ...Object.keys(payload).map((key) => {
            return { title: key, value: payload[key], short: true };
          }),
        ],
      },
    ];
    await fetch("https://slack.com/api/chat.postMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `channel=${import.meta.env.VITE_APP_SLACK_EVENT_CHANNEL!}&token=${
        import.meta.env.VITE_APP_SLACK_TOKEN
      }&attachments=${JSON.stringify(attachments)}`,
    })
      .then((res) => {})
      .catch((error) => {});
    return right(new NoReturn());
  };
}

interface IParams {
  regStep: RegStep;
  payload: any;
  isForInvitee: boolean;
}
