import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";

import { QmsEntry } from "../models/QmsEntry";
import { qmsListMock } from "../mock/QmsListMock";

interface IParams {}

export class GetQmsList implements IAsyncUseCase<IParams, QmsEntry[]> {
  call: (params: IParams) => Promise<Either<Failure, QmsEntry[]>> =
    async (): Promise<Either<Failure, QmsEntry[]>> => {
      return right([...qmsListMock]);
    };
}
