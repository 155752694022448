import { Either, right, left } from "fp-ts/lib/Either";

import TapClient from "common/clients/TapClient";
import { Failure } from "common/@types/app/Failure";
import { IAsyncUseCase } from "common/@types/app/UseCase";
import { IIndicationOfInterest } from "../models/IOI";

const GET_PATH = "ioi/active/since-last-digest";

export class ActiveRecentIoiList
  implements IAsyncUseCase<IParams, IIndicationOfInterest[]>
{
  call: (params: IParams) => Promise<Either<Failure, IIndicationOfInterest[]>> =
    async ({}) => {
      return await new TapClient(GET_PATH)
        .get({})
        .then((resp) => {
          return right(resp);
        })
        .catch((resp) => {
          return left(new Failure(resp));
        });
    };
}

interface IParams {}
