const askColor = "#a53140";

export const AskTriangle = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M4 6H11L7.5 10.5L4 6Z" fill={askColor}></path>
    </svg>
  );
};
