import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageHead } from "common/components/PageHead";
import { EmptyState } from "common/components/EmptyState";

import { QualifiedMatchingServiceTable } from "../components/QualifiedMatchingServiceTable";
import { useComplianceContext } from "../state/ComplianceContext";
import { QmsListRequested } from "../state/ComplianceEvent";
import { QmsListLoaded } from "../state/ComplianceState";

export const QualifiedMatchingServicePage = () => {
  const { emitEvent, qmsListState } = useComplianceContext();

  useEffect(() => {
    emitEvent(new QmsListRequested());
  }, []);

  const qmsList =
    qmsListState instanceof QmsListLoaded ? qmsListState.qmsList : [];

  return (
    <>
      <Helmet title="Qualified Matching Service" />
      <PageHead title="Qualified Matching Service" />

      {qmsListState instanceof QmsListLoaded ? (
        <QualifiedMatchingServiceTable qmsEntries={qmsList} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
