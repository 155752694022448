import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";

import { transactionsClient } from "common/clients/ApolloClient";
import { ITransactionSummary } from "experiences/transactions/domain/models/Transaction";

import { GetTransactionFundDataSoiCsvQueryVariables } from "experiences/transactions/domain/usecases/GetTransactionFundDataCsvQuery";
import { TransactionFundDataContext } from "experiences/transactions/presentation/state/TransactionFundDataContext";
import { createSoiExcel } from "experiences/common/excel/aggregate-soi/createExcel";
import { TransactionDataSummarySectionStatus } from "experiences/transactions/domain/usecases/GetTransactionDataSummarySectionsStatus";

import { SoiLookthrough } from "../SoiLookthrough";
import { GET_TRANSACTION_QUALITATIVE_DATA_INSIGHTS } from "../commentary/query";
import { IQualitativeDataInsights } from "../commentary/model";
import { useFundDataStatus } from "../useFundDataStatus";
import { CompanyDataTable } from "./CompanyDataTable";
import { FundDataWrapper } from "../FundDataWrapper";

const useInvestments = ({
  txnId,
  summary,
}: {
  txnId: string;
  summary: ITransactionSummary;
}) => {
  const { selectedHoldingsIds, reportDates } = useContext(
    TransactionFundDataContext,
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const { loading: loadingStatus, soiLookthroughStatus } = useFundDataStatus();

  const currentReportDate = reportDates.qualitativeData.currentDate;
  const soiCurrentReportDate = reportDates.soiLookthrough.currentDate;

  let {
    data: insights,
    loading: insightsLoading,
    refetch: refetchInsights,
  } = useQuery<IQualitativeDataInsights>(
    GET_TRANSACTION_QUALITATIVE_DATA_INSIGHTS,
    {
      client: transactionsClient,
      skip: !currentReportDate || selectedHoldingsIds.length === 0 || !txnId,
      variables: {
        transactionId: txnId,
        reportDate: currentReportDate,
        holdingsIds: selectedHoldingsIds,
      },
    },
  );

  useEffect(() => {
    if (txnId && currentReportDate && selectedHoldingsIds.length > 0) {
      refetchInsights();
    }
  }, [txnId, currentReportDate, soiCurrentReportDate, selectedHoldingsIds]);

  const queryVariables = {
    reportDate: soiCurrentReportDate,
    transactionId: txnId,
  };

  const { refetch: downloadCsvData } = useQuery<FundDataSummaryCsv>(
    GetTransactionFundDataSoiCsvQueryVariables,
    {
      variables: queryVariables,
      client: transactionsClient,
      fetchPolicy: "no-cache",
      skip: true,
    },
  );

  // Download only works for SOI lookthrough
  // Company data insights is not downloadable, as of now
  const handleDownloadClick = () => {
    setIsDownloading(true);
    toast.success(
      "Download started. Make sure your browser is accepting multiple files when prompted.",
    );

    downloadCsvData({
      variables: queryVariables,
    })
      .then((res) => {
        // SOI lookthrough is a download of the Excel file
        createSoiExcel({
          data: res.data?.dataSummaryCsv?.soiLookthroughTabs,
          namePrefix: `${summary?.transaction?.name
            .replace(/ /g, "_")
            .toLowerCase()}-soi`,
        });
      })
      .catch((error) => {
        toast.error("Download failed. Please try again.");
      })
      .finally(() => {
        setIsDownloading(false);
        toast.success("Download complete!");
      });
  };

  const awaitingUpload =
    soiLookthroughStatus === TransactionDataSummarySectionStatus.NotYetUploaded;

  const extractionInProgress =
    soiLookthroughStatus ===
    TransactionDataSummarySectionStatus.UploadedAndProcessing;

  const extractionReady =
    soiLookthroughStatus ===
    TransactionDataSummarySectionStatus.ReadyToDownloadCSV;

  return {
    insights,
    loading: insightsLoading || loadingStatus,
    soiLookthroughStatus,
    handleDownloadClick,
    isDownloading,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
  };
};

// Investments is a new section that will feed data from 2 previous sections:
// 1. SOI Lookthrough
// 2. Qualitative Data Insights
// Each one of these sections has a report date selector, so we need to make sure that the report date is set correctly
export const InvestmentsTab = ({
  summary,
}: {
  summary: ITransactionSummary;
}) => {
  const { txnId } = useParams();

  const {
    insights,
    loading,
    soiLookthroughStatus,
    handleDownloadClick,
    isDownloading,
    awaitingUpload,
    extractionInProgress,
    extractionReady,
  } = useInvestments({
    txnId,
    summary,
  });

  return (
    <FundDataWrapper
      barLoader={loading}
      awaitingUpload={awaitingUpload}
      extractionInProgress={extractionInProgress}
      extractionReady={extractionReady}
    >
      <SoiLookthrough
        status={soiLookthroughStatus}
        handleDownloadClick={handleDownloadClick}
        isDownloading={isDownloading}
      />
      <CompanyDataTable />
    </FundDataWrapper>
  );
};
