import { LP_ROUTES } from "common/constants/routes";

export enum PortfolioV2Routes {
  Summary = "/summary",
  Investments = "/investments",
  Commentary = "/commentary",
  Documents = "/documents",
  Cashflows = "/cashflows",
  Performance = "/performance",
  UploadDocuments = "/upload-documents",
}

// prettier-ignore
export const PortfolioV2Tabs = [
  { label: "Summary", path: `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Summary}`, enabled: true },
  { label: "Performance", path: `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Performance}`, enabled: true },
  { label: "Lookthrough SOI", path: `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Investments}`, enabled: true },
  { label: "Cashflows", path: `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Cashflows}`, enabled: true },
  // { label: "Commentary", path: `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Commentary}`, enabled: true },
  { label: "Documents", path: `${LP_ROUTES.PortfolioEntrypoint}${PortfolioV2Routes.Documents}`, enabled: true },
];

export const PortfolioV2DefaultRoute = PortfolioV2Routes.Summary;
