import { Envelope } from "@phosphor-icons/react";

import { Button } from "common/components/Button";

export const TapContact = ({
  name,
  title,
  email,
  avatar,
  initials,
}: {
  name: string;
  title: string;
  email: string;
  avatar: string | null;
  initials: string;
}) => {
  if (name === undefined) {
    return null;
  }

  return (
    <div className="flex px-4 box-border cursor-default select-none">
      {avatar ? (
        <img
          src={avatar}
          className="w-14 h-14 min-w-14 min-h-14 border-coolGray-200 border overflow-hidden rounded-full flex align-middle mr-4 object-cover"
        />
      ) : (
        <span className="w-14 h-14 min-w-14 min-h-14 border-coolGray-200 border overflow-hidden rounded-full flex align-middle mr-4 object-cover items-center text-center justify-center bg-gradient-to-r from-neutral-300/20 to-stone-400/40">
          {initials}
        </span>
      )}
      <div className="flex flex-col items-start gap-1">
        <h4 className="text-left text-sm font-semibold text-stone-800">
          {name}
        </h4>
        <p className="text-left text-xs text-stone-400">{title}</p>
        <Button
          className="!mt-2 flex gap-2 justify-center !whitespace-nowrap flex-nowrap !w-24 items-center"
          href={`mailto:${email}`}
          as="a"
          icon={<Envelope />}
          iconRight
        >
          Contact
        </Button>
      </div>
    </div>
  );
};
