import { PageSectionTitleDivider } from "common/components/PageSectionTitleDivider";
import { Helmet } from "react-helmet";
import { GpTransactionDetailDataroomCashFlowsChart } from "./CashFlowsChart";
import {
  gpTransactionDetailDataroomCashFlowsChartMock,
  gpTransactionDetailDataroomInvestmentsMock,
} from "./mock";
import { GpTransactionDetailDataroomInvestmentsTable } from "./InvestmentsTable";

export const GpTransactionDataRoomDataPage: React.FC = () => {
  const cashFlows = gpTransactionDetailDataroomCashFlowsChartMock;
  const investments = gpTransactionDetailDataroomInvestmentsMock;

  return (
    <>
      <Helmet title="Data" />

      <PageSectionTitleDivider>Cash Flows</PageSectionTitleDivider>
      <GpTransactionDetailDataroomCashFlowsChart />

      <PageSectionTitleDivider showBorderTop>
        Investments
      </PageSectionTitleDivider>
      <GpTransactionDetailDataroomInvestmentsTable />
    </>
  );
};
