import { generatePath, useNavigate } from "react-router";

import { LP_ROUTES } from "common/constants/routes";
import {
  FundsStats,
  ITransaction,
  LPLedTransaction,
} from "experiences/transactions/domain/models/Transaction";
import { EmptyState } from "common/components/EmptyState";
import { BUTTON_TYPES, PlusIcon } from "common/components/Button";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { DashboardList } from "experiences/common/List";
import { MockTenderOffer } from "experiences/dashboard/mock/LP";
import { FUND_LOGO_URL, TRANSACTION_LOGO_URL } from "common/constants/platform";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { TenderListing } from "experiences/transactions/domain/models/Tender";
import { ActivityEntry, CreateTransactionActivityEntry } from "./ActivityEntry";

const TransactionList = ({
  transactions,
}: {
  transactions: ITransaction[];
}) => {
  const navigate = useNavigate();

  const handleTransactionClick = (t) => {
    navigate(generatePath(LP_ROUTES.TransactionDetails, { txnId: t.id }));
  };

  const handleCreateTransactionClick = () => {
    navigate(LP_ROUTES.TransactionsCreate);
  };

  if (transactions.length === 0) {
    return (
      <EmptyState
        title="Add Transaction"
        description="Begin analyzing a new opportunity."
        className="min-h-96"
        actions={[
          {
            label: "Add Transaction",
            onClick: handleCreateTransactionClick,
            type: BUTTON_TYPES.SECONDARY,
            icon: <PlusIcon />,
          },
        ]}
      />
    );
  }

  const transactionList = transactions.map((transaction) => {
    if (transaction instanceof LPLedTransaction) {
      const strategies =
        (transaction.stats instanceof FundsStats &&
          transaction.stats.strategies.map((strategy) =>
            String(FundStrategyNameMap.get(strategy)),
          )) ||
        [];

      const geographies =
        (transaction.stats instanceof FundsStats &&
          transaction.stats.geographies.map((geography) =>
            String(FundGeographyNameMap.get(geography)),
          )) ||
        [];

      const tags = [...strategies, ...geographies];

      const vintageStart =
        transaction.stats instanceof FundsStats &&
        transaction.stats?.vintageRange?.start;

      const vintageEnd =
        transaction.stats instanceof FundsStats &&
        transaction.stats?.vintageRange?.end;

      const isVintageStartAndEndEqual =
        vintageStart === vintageEnd && vintageStart && vintageEnd;

      const vintageStr = isVintageStartAndEndEqual
        ? vintageStart || vintageEnd
        : `${vintageStart} - ${vintageEnd}`;

      const tokens = [
        {
          icon: "",
          value: new DollarAmount(transaction.accumNav).formattedBig(),
          label: "NAV",
        },
        {
          ...(transaction.stats instanceof FundsStats
            ? {
                icon: "",
                value: vintageStr,
                label: " Vintages",
              }
            : {}),
        },
        {
          icon: "",
          value: transaction.fundsCount,
          label: transaction.fundsCount === 1 ? "Fund" : "Funds",
        },
      ];

      const logoUrl = `${TRANSACTION_LOGO_URL}/${transaction.id}.jpg`;

      return (
        <ActivityEntry
          title={transaction.name}
          tokens={tokens}
          tags={tags}
          onDetailClick={() => handleTransactionClick(transaction)}
          hasNotifications={transaction.isNew}
          key={transaction.id}
          transactionLogo={<img src={logoUrl} alt={transaction.name} />}
        />
      );
    } else if (transaction instanceof MockTenderOffer) {
      return (
        <ActivityEntry
          transactionLogo={
            <img src={`${FUND_LOGO_URL}/${transaction.image}`} alt="" />
          }
          title={transaction.name}
          tokens={[
            {
              icon: "",
              value: "",
              label: `${transaction.referenceYear} Q${transaction.referenceQuarter} Tender Offer`,
            },
          ]}
          tags={["Tender Offer"]}
          onDetailClick={() =>
            navigate(
              generatePath(LP_ROUTES.TenderDetails, { txnId: transaction.id }),
            )
          }
          hasNotifications={true}
          key={transaction.id}
        />
      );
    } else if (transaction instanceof TenderListing) {
      return (
        <ActivityEntry
          transactionLogo={<img src={`${FUND_LOGO_URL}/no-where.jpg`} alt="" />}
          title={transaction.name}
          tokens={[
            {
              icon: "",
              value: "",
              label: `${
                transaction.closingDate
                  ? new DateFormatter(
                      transaction.closingDate,
                    ).quarterFormatted()
                  : "-"
              } Tender Offer`,
            },
          ]}
          tags={["Tender Offer"]}
          onDetailClick={() =>
            navigate(
              generatePath(LP_ROUTES.TenderDetails, { txnId: transaction.id }),
            )
          }
          hasNotifications={true}
          key={transaction.id}
        />
      );
    }
  });

  return (
    <>
      <CreateTransactionActivityEntry />
      {transactionList}
    </>
  );
};

export const DashboardTransactionList = ({
  transactions,
  showBorderTop,
}: {
  transactions: ITransaction[];
  showBorderTop?: boolean;
}) => {
  return (
    <DashboardList
      title="Transactions"
      table={<TransactionList transactions={transactions} />}
      showBorderTop={showBorderTop}
    />
  );
};
