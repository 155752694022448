import { Either, right } from "fp-ts/lib/Either";

import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";
import {
  IGpTransaction,
  gpTransactionList,
} from "experiences/dashboard/mock/GP";

interface IParams {}

export class GetGpMockTransactions
  implements IAsyncUseCase<IParams, IGpTransaction[]>
{
  call: (params: IParams) => Promise<Either<Failure, IGpTransaction[]>> =
    async (): Promise<Either<Failure, IGpTransaction[]>> => {
      return right([...gpTransactionList]);
    };
}
