import { Navigate, Route, Routes, useLocation } from "react-router";

import { startScreenForExperienceType } from "Routes";

import { UNIVERSAL_ROUTES } from "common/constants/routes";
import { InvalidLink } from "experiences/authentication/presentation/pages/Invalid";
import { LoginPage } from "experiences/authentication/presentation/pages/Login";
import { Logout } from "experiences/authentication/presentation/pages/Logout";
import { Register } from "experiences/authentication/presentation/pages/Register";
import { SessionClaim } from "experiences/authentication/presentation/pages/SessionClaim";
import { SixDigitCode } from "experiences/authentication/presentation/pages/SixDigitCode";
import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { Playground } from "./Playground";

export const UniversalRoutes = () => {
  const { user } = useAuthContext();
  const experienceType = user?.experienceType;
  const location = useLocation();

  // if it's going to any login/signup routes but not going to logout path
  // and there's already a session, redirect to ROUTES.Dashboard
  // which is another way of saying, do not show login if there's already a session
  // but allow the user to logout
  if (
    location.pathname.includes(UNIVERSAL_ROUTES.Auth) &&
    !location.pathname.includes(UNIVERSAL_ROUTES.AuthLogout) &&
    Boolean(user)
  ) {
    return (
      <Navigate to={startScreenForExperienceType[experienceType]} replace />
    );
  }

  return (
    <Routes>
      <Route path={`${UNIVERSAL_ROUTES.AuthLogin}/*`} element={<LoginPage />} />
      <Route
        path={`${UNIVERSAL_ROUTES.AuthRegisterInvitee}/*`}
        element={<Register />}
      />
      <Route path={`${UNIVERSAL_ROUTES.SignUp}/*`} element={<Register />} />
      <Route
        path={`${UNIVERSAL_ROUTES.AuthInvalid}/*`}
        element={<InvalidLink />}
      />
      <Route
        path={`${UNIVERSAL_ROUTES.AuthSessionClaim}`}
        element={<SessionClaim />}
      />
      <Route path={`${UNIVERSAL_ROUTES.AuthOtp}`} element={<SixDigitCode />} />
      <Route path={`${UNIVERSAL_ROUTES.AuthLogout}`} element={<Logout />} />
      <Route path={`${UNIVERSAL_ROUTES.Playground}`} element={<Playground />} />
      <Route
        path="*"
        element={
          <Navigate
            to={
              Boolean(user)
                ? startScreenForExperienceType[experienceType]
                : UNIVERSAL_ROUTES.AuthLogin
            }
            replace
          />
        }
      />
    </Routes>
  );
};
