import TapLinearProgress from "common/components/TapLinearBar";
import React from "react";

interface IProps {
  percentage: number;
}

const RegFlowProgressBar: React.FC<IProps> = ({ percentage }) => {
  return <TapLinearProgress variant="determinate" value={percentage * 100} />;
};

export default RegFlowProgressBar;
