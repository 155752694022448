import { Either } from "fp-ts/Either";
import { IFailure } from "./Failure";

export interface IUseCase<TParams, TReturned> {
  call: (params: TParams) => Either<IFailure, TReturned>;
}

export interface IAsyncUseCase<TParams, TReturned> {
  call: (params: TParams) => Promise<Either<IFailure, TReturned>>;
}

export class NoReturn {}
