export enum IInterimCashflowType {
  CapitalCall = "CapitalCall",
  Distribution = "Distribution",
  NetCapitalCall = "NetCapitalCall",
  NetDistribution = "NetDistribution",
}

export const IInterimCashflowLabels = {
  [IInterimCashflowType.CapitalCall]: "Capital Call",
  [IInterimCashflowType.Distribution]: "Distribution",
  [IInterimCashflowType.NetCapitalCall]: "Net Capital Call",
  [IInterimCashflowType.NetDistribution]: "Net Distribution",
};

export interface IInterimCashflow {
  fund: string;
  issuerId: string;
  noticeId: string;
  type: IInterimCashflowType | null;
  amount: string | null;
  wireDate: string | null;
  description: string | null;
}

export interface IInterimCashflowsQuery {
  interimCashflows: IInterimCashflow[];
}
