import styled from "styled-components";

import { BUTTON_TYPES, Button, LockIcon } from "common/components/Button";
import { CheckCircle, CircleNotch, Lock, Plugs } from "@phosphor-icons/react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { LP_ROUTES } from "common/constants/routes";
import { OverlayWithIcon } from "experiences/common/OverlayWithIcon";
import { TransactionFundDataRoutes } from "experiences/transactions/presentation/components/fund-data/TransactionFundDataScreen";
import { TransactionDetailsRoutes } from "experiences/transactions/presentation/components/TransactionDetails";
import { cn } from "common/utils";

const StyledVerifyPermissionOverlay = styled.div<{
  overlay?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;

  h2 {
    display: block;
    margin-top: 8px;
    color: var(--branding-dark, #21272d);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.15px;
    user-select: none;
  }
  p {
    display: block;
    margin-top: 4px;
    margin-bottom: 20px;
    color: var(--branding-dark, #21272d);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.13px;
    user-select: none;
    max-width: 450px;
  }

  ${(props) => {
    if (props.overlay) {
      return `
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        z-index: 1;
        height: 100%;
        width: 100%;

        & > svg, & > h2, & > p {
            text-shadow: 0px 0px 10px rgba(255, 255, 255, 1), 0px 0px 4px rgba(255, 255, 255, 1);
        }
      `;
    }
  }}
`;

export const VerifyPermissionOverlay = ({
  style,
  mainIcon,
  headerText,
  subHeaderText,
  handleVerifyPermissionClick,
  buttonText,
  buttonIcon = <LockIcon />,
  messageStyle = {},
}: {
  mainIcon: React.ReactNode;
  headerText: string;
  subHeaderText: string;
  handleVerifyPermissionClick?: () => void;
  buttonText?: string;
  style?: React.CSSProperties;
  buttonIcon?: JSX.Element;
  messageStyle?: React.CSSProperties;
}) => {
  return (
    <StyledVerifyPermissionOverlay style={style} overlay>
      {mainIcon}
      <h2>{headerText}</h2>
      <p style={messageStyle}>{subHeaderText}</p>
      {handleVerifyPermissionClick ? (
        <Button
          type={BUTTON_TYPES.SECONDARY}
          icon={buttonIcon}
          onClick={handleVerifyPermissionClick}
        >
          {buttonText}
        </Button>
      ) : null}
    </StyledVerifyPermissionOverlay>
  );
};

export const FundConnectPortfolioOverlay = () => {
  const navigate = useNavigate();
  return (
    <VerifyPermissionOverlay
      mainIcon={<Plugs size={32} color="#BCB3A5" />}
      buttonText="Connect Portfolio"
      headerText="Connect Portfolio"
      subHeaderText="To view this data you need to have connected your portfolio"
      handleVerifyPermissionClick={() =>
        navigate(LP_ROUTES.PortfolioConnectPortfolio)
      }
    />
  );
};

export const FundVerifyPermissionOverlay = ({ fundId }: { fundId: string }) => {
  const navigate = useNavigate();

  const handleVerifyPermissionClick = () => {
    const path = generatePath(LP_ROUTES.VerifyFundPermissions, {
      fundId,
    });

    navigate(path, { replace: true });
  };

  return (
    <VerifyPermissionOverlay
      mainIcon={<CheckCircle size={32} color="#BCB3A5" />}
      buttonText="Verify Permission"
      headerText={"Verify Permission"}
      subHeaderText={
        "To view this data you need to be an existing Limited Partner or an Approved Buyer."
      }
      buttonIcon={<LockIcon />}
      handleVerifyPermissionClick={handleVerifyPermissionClick}
    />
  );
};

export const FundDataVerifyPermissionOverlay = ({
  txnId,
  className,
}: {
  txnId: string;
  className?: string;
}) => {
  const navigate = useNavigate();
  const currentPath = useLocation();

  const handleVerifyPermissionClick = () => {
    const basePath = currentPath.pathname.split(
      TransactionDetailsRoutes.FundData,
    )[0];
    const path = `${basePath}${TransactionDetailsRoutes.FundData}${TransactionFundDataRoutes.VerifyPermission}`;

    navigate(path);
  };

  return (
    <div className="relative min-h-96">
      <OverlayWithIcon
        Icon={Lock}
        title="Upload Documents to View Data"
        subtitle="To view the detailed fund data you need to demonstrate that you have access permission."
        className={cn("absolute z-10 bg-white/20 min-h-96", className)}
      >
        <Button
          type={BUTTON_TYPES.SECONDARY}
          icon={<LockIcon />}
          onClick={handleVerifyPermissionClick}
        >
          Upload Documents
        </Button>
      </OverlayWithIcon>
    </div>
  );
};
