import styled from "styled-components";

import { APP_MENU_HEIGHT_PX } from "experiences/common/PageWrapper";

export const StyledDashboard = styled.div<{
  hasIndexes?: boolean;
}>`
  --indexes-ticker-height-px: 0px;

  display: flex;
  position: relative;

  height: calc(
    100vh - ${APP_MENU_HEIGHT_PX}px - var(--indexes-ticker-height-px)
  );
  position: relative;

  ${(props) => (props.hasIndexes ? "--indexes-ticker-height-px: 31px;" : "")}
`;
