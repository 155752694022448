import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase, NoReturn } from "../../../../common/@types/app/UseCase";
import TapClient from "../../../../common/clients/TapClient";
import { DocumentTypeEnum } from "experiences/documents/models/DocumentType";

export class DelegateSignatureForDocument
  implements IAsyncUseCase<IParams, NoReturn>
{
  call: (params: IParams) => Promise<Either<Failure, NoReturn>> = async (
    params,
  ) => {
    return await new TapClient(`documents/nda/signing-role/delegate/`, params)
      .post()
      .then(() => right(new NoReturn()))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  delegateFirstName: string;
  delegateLastName: string;
  delegateEmail: string;
  documentType: DocumentTypeEnum;
}
