import { Worksheet } from "exceljs";
import csvToJson from "convert-csv-to-json";

import {
  DEFAULT_WORKSHEET_COLUMN_WIDTH,
  generateScaffoldWorkbook,
  generateWorksheets,
  parseStrToNumber,
  saveWorkbookToDownloads,
} from "../utils";

import {
  capitalAccountKeyLabels,
  currencyRowKeys,
  percentageRowKeys,
  fundTableKeys,
  gpTableKeys,
  allLpKeys,
  individualLpKeys,
  CapitalAccountKeys,
  TableLabels,
} from "./model";

const generateTableData = ({
  tableLabel,
  tableKeys,
  fundData,
  worksheet,
}: {
  tableLabel: string;
  tableKeys: string[];
  fundData: {
    fundName: string;
    periods: string[];
    data: Record<string, string[]>;
  };
  worksheet: Worksheet;
}) => {
  const tableRows = tableKeys.map((key) => {
    const label = capitalAccountKeyLabels[key];
    const innerData: String[] = fundData.data[key] || [];
    return [label, ...innerData];
  });

  const headerRow = worksheet.addRows([[tableLabel, ...fundData.periods]]); // Keep a reference to the header row so we can style it later
  const addedRows = worksheet.addRows(tableRows);
  worksheet.addRows([[]]); // blank row at the end of this table

  // aqui ya hay valores en $0, el problema viene de arriba
  addedRows.forEach((row) => {
    fundData.periods.forEach((period, periodIndex) => {
      const cell = row.getCell(period);
      const untouchedValue = row.values[periodIndex + 1];

      // If we don't have a set value just set it to null and return, we don't want this to default as a $0 or 0% if there's no actual value
      if (cell.value === null || typeof cell.value === "undefined") {
        cell.value = null;
        return;
      }

      if (typeof cell.value === "string") {
        const parsedValue = parseStrToNumber(cell.value as string);

        // Deeper check, here's the logic:
        // I'm not sure where exactly the "" is converted to a 0, that then will be shown as $0 or 0% when formatted
        // But at this stage, `preCellValue` will be a string with "" intact or with "$0" if value comes as "$0" from the api
        // In theory, at this stage, `preCellValue` as "" is a string albeit parsedValue is 0
        // Later note: this was fixed at `parseStrToNumber` but will leave this here for now
        if (parsedValue === 0) {
          if (untouchedValue === "") {
            cell.value = null;
            return;
          }
        }
        cell.value = parsedValue;
      }

      const rowLabel = row.getCell("key").value;
      const rowKey = Object.keys(capitalAccountKeyLabels).find(
        (key) => capitalAccountKeyLabels[key] === rowLabel,
      ) as CapitalAccountKeys;

      const isCurrencyRow = currencyRowKeys.includes(rowKey);
      const isPercentageRow = percentageRowKeys.includes(rowKey);

      if (isCurrencyRow) {
        cell.numFmt = "$#,##0";
      }

      if (isPercentageRow) {
        // Numbers from the API come on a 0-1 scale, so we need to divide by 100 to get the correct percentage because excel
        // This will only affect values that aren't null or empty, that was discarded above
        if (typeof cell.value === "number") {
          cell.value = cell.value / 100;
        }
        cell.numFmt = "0.0%";
      }
    });
  });

  fundData.periods.forEach((period) => {
    const cell = headerRow[0].getCell(period);

    if (cell.value === null || cell.value === undefined) {
      return;
    }

    // Date is in the format of YYYY-MM-DD
    if (typeof cell.value === "string") {
      cell.value = new Date(cell.value);
    }
  });

  fundData.periods.forEach((period) => {
    const cell = headerRow[0].getCell(period);

    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "000000" },
    };

    cell.font = {
      color: { argb: "FFFFFF" },
    };
  });

  headerRow[0].getCell("key").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "000000" },
  };

  headerRow[0].getCell("key").font = {
    color: { argb: "FFFFFF" },
  };

  return tableRows;
};

interface CasStructuredData {
  fundName: string;
  periods: string[];
  data: Record<string, string[]>;
}

const parseCapitalAccountsCsv = (
  data: FundSummaryDataTab[],
): CasStructuredData[] => {
  const casTabs = data.map((casTab) => {
    if (!casTab.data) {
      return {
        fundName: casTab.name,
        periods: [],
        data: [],
      };
    }

    const data = csvToJson
      .fieldDelimiter(",")
      .supportQuotedField(true)
      .csvStringToJson(casTab.data || ",\r\n");

    const periodsAtFirstEntry = Object.keys(data[0]).filter((key) =>
      key.includes("-"),
    );

    // Data is horizontal but I want it vertical
    const orderedData = data.reduce((acc, i) => {
      const mainKey = String(Object.values(i)[0]);

      const allButFirst = Object.values(i).slice(1);

      return {
        ...acc,
        [mainKey]: allButFirst,
      };
    }, {});

    return {
      fundName: casTab.name,
      periods: periodsAtFirstEntry,
      data: orderedData,
    };
  });

  return casTabs;
};

export const createCapitalAccountsExcel = async ({
  data,
  namePrefix,
}: {
  data: FundSummaryDataTab[];
  namePrefix: string;
}) => {
  const capitalAccountsData = parseCapitalAccountsCsv(data);

  const workbook = generateScaffoldWorkbook();

  const workbookSheets = capitalAccountsData.map((fund) => {
    const columns = fund.periods.map((period) => {
      return {
        header: period,
        key: period,
        width: DEFAULT_WORKSHEET_COLUMN_WIDTH,
      };
    });

    return {
      label: fund.fundName,
      columns: [
        { header: "", key: "key", width: DEFAULT_WORKSHEET_COLUMN_WIDTH * 2 },
        ...columns,
      ],
    };
  });

  //   For each fund, create a worksheet
  const worksheets = generateWorksheets({
    workbook,
    sheets: workbookSheets,
  });

  // For each worksheet, add the data
  worksheets.forEach((worksheet, i) => {
    const fundData = capitalAccountsData[i];

    // Table #1: Fund
    generateTableData({
      tableLabel: TableLabels.Fund,
      tableKeys: fundTableKeys,
      fundData,
      worksheet,
    });

    // Table #2: GP
    generateTableData({
      tableLabel: TableLabels.Gp,
      tableKeys: gpTableKeys,
      fundData,
      worksheet,
    });

    // Table #3: All LPs
    generateTableData({
      tableLabel: TableLabels.AllLps,
      tableKeys: allLpKeys,
      fundData,
      worksheet,
    });

    // Table #4: Individual LP
    generateTableData({
      tableLabel: TableLabels.IndividualLp,
      tableKeys: individualLpKeys,
      fundData,
      worksheet,
    });
  });

  worksheets.forEach((worksheet) => {
    // Because we created a header row for the entire table, we need to remove the first row
    worksheet.spliceRows(0, 1);

    // Add a page break after the table
    const lastRow = worksheet.getRow(
      worksheet.actualRowCount + Object.values(TableLabels).length,
    );

    worksheet.spliceRows(lastRow.number, 10); // remove extra rows, 10 is arbitrary
    lastRow.addPageBreak();
    worksheet.spliceColumns(worksheet.actualColumnCount + 1, 10); // remove extra columns, 10 is arbitrary
  });

  await saveWorkbookToDownloads(workbook, `${namePrefix}.xlsx`);
};
