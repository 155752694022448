import { ReactNode } from "react";
import {
  UpdateType,
  updateTypeLabels,
} from "experiences/transactions/presentation/components/fund-data/commentary/model";
import { TableFundLogo } from "./TableFundLogo";
import TextChip from "experiences/common/TextChip";
import { cn } from "common/utils";

export const UpdateCard = ({
  fundImageUrl,
  fundTitle,
  fundSubtitle,
  updateTitle,
  updateDesctription,
  type,
  reportDate,
}: {
  fundImageUrl: string;
  fundTitle: string | ReactNode;
  fundSubtitle: string;
  updateTitle: string;
  updateDesctription: string | ReactNode;
  type: string;
  reportDate?: string;
}) => {
  const isPositive = type === UpdateType.RecentHighlight;
  const isNegative = type === UpdateType.RecentLowlight;

  return (
    <div
      className={cn(
        "relative border border-zinc-200 box-border rounded-lg p-4 !w-full !h-56 overflow-hidden select-none",
        "hover:border-zinc-300 hover:cursor-pointer hover:shadow-sm",
        {},
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-0 items-center">
          <TableFundLogo imgSrc={fundImageUrl} size="large" />
          <div className="flex flex-col">
            <div className="text-sm text-zinc-900" title={fundTitle.toString()}>
              {fundTitle}
            </div>
            <div className="text-xs text-zinc-500 font-regular">
              {fundSubtitle}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div
            className="font-semibold text-sm text-zinc-900 line-clamp-2"
            title={updateTitle.toString()}
          >
            {updateTitle}
          </div>
          <p
            className="text-xs text-zinc-900 font-regular line-clamp-3"
            title={updateDesctription.toString()}
          >
            {updateDesctription}
          </p>
        </div>
        <div className="flex flex-row gap-0 items-center justify-between">
          <TextChip
            success={isPositive}
            danger={isNegative}
            text={updateTypeLabels[type] || type}
          />
          {reportDate && (
            <div className="text-xs text-zinc-500 font-regular select-none">
              {new Date(reportDate).toLocaleDateString()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
