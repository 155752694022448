export enum TabType {
  Screen = 0,
  Alerts = 1,
  Watchlist = 2,
}

export const TabTypeNames = new Map<TabType, string>([
  [TabType.Screen, "Screen"],
  [TabType.Alerts, "Alerts"],
  [TabType.Watchlist, "Watchlist"],
]);

export const TabTypeRoute = new Map<TabType, string>([
  [TabType.Screen, "/funds/screen"],
  [TabType.Alerts, "/funds/alerts"],
  [TabType.Watchlist, "/funds/watchlist"],
]);
