import { ApolloProvider } from "@apollo/client";
import { StyledEngineProvider } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CustomToaster } from "shadcn/ui/Toast";
import Smartlook from "smartlook-client";

import { AppRoutes } from "Routes";
import NotistackProvider from "common/providers/NotistackProvider";
import ThemePrimaryColor from "common/providers/ThemePrimaryColor";
import ThemeConfig from "common/theme";

import { apolloClient } from "common/clients/ApolloClient";
import { AuthenticationStateProvider } from "experiences/authentication/presentation/state/AuthenticationContext";
import { TooltipProvider } from "shadcn/ui/tooltip";

export const App = () => {
  useEffect(() => {
    if (import.meta.env.VITE_APP_SMARTLOOK_PROJECT_KEY) {
      Smartlook.init(import.meta.env.VITE_APP_SMARTLOOK_PROJECT_KEY);
      Smartlook.record({ forms: true, numbers: true, ips: true, emails: true });
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeConfig>
        <ThemePrimaryColor>
          <NotistackProvider>
            <TooltipProvider>
              <AuthenticationStateProvider>
                <ApolloProvider client={apolloClient}>
                  <Helmet
                    encodeSpecialCharacters={true}
                    titleTemplate="%s – Tap: The first secondary trading platform for private funds"
                  ></Helmet>
                  <AppRoutes />
                  <CustomToaster />
                </ApolloProvider>
              </AuthenticationStateProvider>
            </TooltipProvider>
          </NotistackProvider>
        </ThemePrimaryColor>
      </ThemeConfig>
    </StyledEngineProvider>
  );
};
