export interface IOrganization {
  id: string;
  name: string;
  type: OrganizationType;
}

export interface ICreateOrganizationForm {
  name: string;
  type: OrganizationType;
}

export enum OrganizationType {
  MANAGER = 1,
  INSTITUTIONAL_INVESTOR = 2,
  SERVICE_PROVIDER = 3,
  ADVISOR = 4,
  PERSONAL_INVESTOR = 5,
  OTHER = 6,
  SECONDARY_FUND = 7,
  LAW_FIRM = 8,
}

export const OrganizationTypeLabels = {
  [OrganizationType.MANAGER]: "Manager",
  [OrganizationType.INSTITUTIONAL_INVESTOR]: "Institutional Investor",
  [OrganizationType.SERVICE_PROVIDER]: "Service Provider",
  [OrganizationType.ADVISOR]: "Advisor",
  [OrganizationType.PERSONAL_INVESTOR]: "Personal Investor",
  [OrganizationType.OTHER]: "Other",
  [OrganizationType.SECONDARY_FUND]: "Secondary Fund",
  [OrganizationType.LAW_FIRM]: "Law Firm",
};

export const OrganizationsThatSkipOnboardingInvestmentPreferences = [
  OrganizationType.MANAGER,
  OrganizationType.SERVICE_PROVIDER,
  OrganizationType.ADVISOR,
  OrganizationType.LAW_FIRM,
];

export const organizationTypeSelectOptions = [
  {
    value: OrganizationType.MANAGER,
    label: OrganizationTypeLabels[OrganizationType.MANAGER],
  },
  {
    value: OrganizationType.INSTITUTIONAL_INVESTOR,
    label: OrganizationTypeLabels[OrganizationType.INSTITUTIONAL_INVESTOR],
  },
  {
    value: OrganizationType.SERVICE_PROVIDER,
    label: OrganizationTypeLabels[OrganizationType.SERVICE_PROVIDER],
  },
  {
    value: OrganizationType.ADVISOR,
    label: OrganizationTypeLabels[OrganizationType.ADVISOR],
  },
  {
    value: OrganizationType.PERSONAL_INVESTOR,
    label: OrganizationTypeLabels[OrganizationType.PERSONAL_INVESTOR],
  },
  {
    value: OrganizationType.OTHER,
    label: OrganizationTypeLabels[OrganizationType.OTHER],
  },
  {
    value: OrganizationType.SECONDARY_FUND,
    label: OrganizationTypeLabels[OrganizationType.SECONDARY_FUND],
  },
  {
    value: OrganizationType.LAW_FIRM,
    label: OrganizationTypeLabels[OrganizationType.LAW_FIRM],
  },
];

export class Organization implements IOrganization {
  id: string;
  name: string;
  type: OrganizationType;

  constructor({
    id,
    name,
    type,
  }: {
    id: string;
    name: string;
    type: OrganizationType;
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
  }
}

export class CreateOrganizationForm implements ICreateOrganizationForm {
  name: string;
  type: OrganizationType;

  constructor({ name, type }: { name: string; type: OrganizationType }) {
    this.name = name;
    this.type = type;
  }
}

export interface OrganizationPage {
  organizations: Organization[];
  totalOrgs: number;
  pageIndex: number;
  perPage: number;
  pageCount: number;
}
