import React, { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { LP_ROUTES } from "common/constants/routes";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { ArrowUpRight, Barcode } from "@phosphor-icons/react";

import AddHoldingModal from "./AddHoldingModal";
import { EmptyState } from "../../../../common/components/EmptyState";
import { Portfolio } from "../../domain/models/Portfolio";
import { IHolding } from "../../domain/models/Holding";
import { usePortfolioContext } from "../state/PortfolioContext";
import { MainPortfolioScreenLoaded } from "../state/PortfolioState";
import { DollarAmount } from "../../../../common/@types/app/DollarAmount";
import {
  MainPortfolioScreenRequested,
  SellInterestFlowTriggered,
} from "../state/PortfolioEvent";
import LogoTextChip from "../../../../common/components/LogoTextChip";
import PeopleAlt from "../../../../common/components/Icons/PeopleAlt";
import { Percentage } from "../../../../common/@types/app/Percentage";
import { ContentWrapper } from "common/components/WidthWrapper";
import { TapCheckbox } from "common/components/Checkbox";
import { Button, BUTTON_TYPES, PlusIcon } from "common/components/Button";
import {
  TapTable,
  TableCell,
  TableAlignment,
  TableHeadCard,
} from "experiences/funds/presentation/components/Table";
import Loader from "common/components/TapLoader";
import HarveyBall from "common/icons/HarveyBall";
import { FUND_LOGO_URL } from "common/constants/platform";
import { TableFundLogo } from "common/components/TableFundLogo";
import { Tooltip, TooltipContent, TooltipTrigger } from "shadcn/ui/tooltip";

export const PortfolioHoldings = () => {
  const { portfolioScreenState, emitEvent } = usePortfolioContext();
  const navigate = useNavigate();
  const [addHoldingModalOn, setAddHoldingModalOn] = useState<boolean>(false);

  useEffect(() => {
    emitEvent(new MainPortfolioScreenRequested());
  }, []);

  const toggleAddHoldingModal = () => {
    setAddHoldingModalOn((prev) => !prev);
  };

  const handleBulkUploadClick = () => {
    // TODO: Ask @Jeff | @Thomas if we should drop the CSV file upload and go directly to the bulk upload page
    navigate(LP_ROUTES.PortfolioConnectPortfolio);
  };

  const holdingsLoaded =
    portfolioScreenState instanceof MainPortfolioScreenLoaded;

  const holdings =
    (holdingsLoaded && portfolioScreenState?.portfolio?.holdings) || [];

  return (
    <ContentWrapper fullWidth>
      <Helmet title="Portfolio" />
      {holdingsLoaded && holdings.length > 0 ? (
        <HoldingsTable
          holdings={holdings}
          portfolio={portfolioScreenState.portfolio}
          toggleAddHoldingModal={toggleAddHoldingModal}
        />
      ) : null}
      {holdingsLoaded && holdings.length === 0 ? (
        <EmptyState
          title="No current holdings"
          description="Add your LP holdings to get estimated market prices and get notified of bids from buyers."
          actions={[
            {
              label: "Add Holding",
              onClick: toggleAddHoldingModal,
              type: BUTTON_TYPES.PRIMARY,
              icon: <PlusIcon />,
            },
            {
              label: "Bulk Upload",
              onClick: handleBulkUploadClick,
              type: BUTTON_TYPES.SECONDARY,
              icon: <Barcode />,
            },
          ]}
        />
      ) : null}
      {!holdingsLoaded ? <Loader /> : null}
      <AddHoldingModal
        open={addHoldingModalOn}
        onClose={toggleAddHoldingModal}
      />
    </ContentWrapper>
  );
};

interface IHoldingsTableProps {
  holdings: IHolding[];
  portfolio: Portfolio;
  toggleAddHoldingModal: () => void;
}

export const HoldingsTable: React.FC<IHoldingsTableProps> = ({
  holdings,
  portfolio,
  toggleAddHoldingModal,
}) => {
  const [checkedHoldings, setCheckedHoldings] = useState<Set<IHolding>>(
    new Set(),
  );

  const { emitEvent } = usePortfolioContext();
  const allChecked = holdings.every((row) => checkedHoldings.has(row));

  const handleAllCheckboxClick = () => {
    setCheckedHoldings(() => (!allChecked ? new Set(holdings) : new Set()));
  };

  const handleCheckboxClick = ({
    checked,
    holding,
  }: {
    checked: boolean;
    holding: IHolding;
  }) => {
    setCheckedHoldings((prev) =>
      checked
        ? new Set([...Array.from(prev), holding])
        : new Set(
            Array.from(prev).filter((currHolding) => currHolding != holding),
          ),
    );
  };

  const handleSellButtonClick = (holding: IHolding) => {
    emitEvent!(new SellInterestFlowTriggered({ holdings: [holding] }));
  };

  const navigate = useNavigate();

  const goToFundDetail = (fundId: string, e?: React.MouseEvent) => {
    // do not navigate if the user is clicking on the checkbox
    if (e?.isPropagationStopped() || e?.target !== e?.currentTarget) {
      return;
    }

    navigate(generatePath(LP_ROUTES.FundsFundDetail, { fundId }));
  };

  return (
    <TapTable
      emptyStateTitle="No current holdings"
      data={holdings}
      showActionsOnHover
      toggleAllChecked={allChecked}
      onToggleAllClick={handleAllCheckboxClick}
      skipTopBorder
      columns={[
        {
          key: "fundName",
          label: "Name",
          align: TableAlignment.LEFT,
        },
        {
          key: "nav",
          label: "NAV",
          align: TableAlignment.LEFT,
        },
        {
          key: "marketValue",
          label: "Market Value Estimate",
          align: TableAlignment.LEFT,
        },
        {
          key: "priceEstimate",
          label: "Price Estimate",
          align: TableAlignment.LEFT,
        },
        {
          key: "tvpi",
          label: "Market TVPI",
          align: TableAlignment.LEFT,
        },
        {
          key: "actions",
          label: "",
          align: TableAlignment.LEFT,
        },
      ]}
      renderRow={(holding: IHolding) => {
        const checked = checkedHoldings.has(holding);
        // TODO: We need to get fund id to handle the view click
        const fundId = holding.issuer.id;

        return (
          <>
            <TableCell
              isPrimaryColumn
              hasCheckbox
              onClick={(e) => goToFundDetail(fundId, e)}
            >
              <TapCheckbox
                checked={checked}
                onChange={(e) =>
                  handleCheckboxClick({
                    checked: e.target.checked,
                    holding,
                  })
                }
                className="table-checkbox"
              />
              <TableFundLogo
                imgSrc={`${FUND_LOGO_URL}/${holding.issuer.id}.jpg`}
                fundName={holding.issuer.name}
              >
                {(holding.numberOfProspects && (
                  <span className="ml-2">
                    <Tooltip delayDuration={100}>
                      <TooltipTrigger>
                        <LogoTextChip
                          direction="reversed"
                          fontSize="12px"
                          logoChild={<PeopleAlt />}
                          color="#85D388"
                          text={`${holding.numberOfProspects}`}
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        Number of potential buyers for this holding on Tap.
                      </TooltipContent>
                    </Tooltip>
                  </span>
                )) ||
                  null}
              </TableFundLogo>
            </TableCell>
            <TableCell
              className={TableAlignment.LEFT}
              onClick={(e) => goToFundDetail(fundId, e)}
            >
              {new DollarAmount(holding.bookValue).formattedBig()}
            </TableCell>
            <TableCell
              className={TableAlignment.LEFT}
              onClick={(e) => goToFundDetail(fundId, e)}
            >
              {holding.marketValue
                ? new DollarAmount(holding.marketValue).formattedBig()
                : "Pending"}
            </TableCell>
            <TableCell
              className={TableAlignment.LEFT}
              onClick={(e) => goToFundDetail(fundId, e)}
            >
              {holding.fundPrice ? (
                <>
                  <div className="flex flex-direction-row items-center">
                    <span style={{ marginRight: "4px" }}>
                      {new Percentage(holding.fundPrice.price).formatted()}
                    </span>
                    <HarveyBall
                      harveyLevel={holding.fundPrice.level}
                      transform="scale(1.35)"
                    />
                  </div>
                </>
              ) : (
                "Pending"
              )}
            </TableCell>
            <TableCell
              className={TableAlignment.LEFT}
              onClick={(e) => goToFundDetail(fundId, e)}
            >
              {holding.tvpi ? `${holding.tvpi.toFixed(1)}x` : "Pending"}
            </TableCell>
            <TableCell hasActions>
              <Button onClick={() => handleSellButtonClick(holding)}>
                Sell
              </Button>
            </TableCell>
          </>
        );
      }}
      tableHeadCards={
        <>
          <TableHeadCard
            style={{ minWidth: "40%" }}
            title="Holdings"
            skipTopBorder
            value={
              <>
                {portfolio.holdingsCount}
                <div className="flex items-center justify-between gap-2">
                  {checkedHoldings.size > 0 && (
                    <div className="flex items-center gap-2">
                      <Typography variant="caption" fontWeight="bold">
                        {`${Array.from(checkedHoldings).length} selected`}
                      </Typography>
                      <Button
                        type={BUTTON_TYPES.SECONDARY}
                        onClick={() => {
                          emitEvent!(
                            new SellInterestFlowTriggered({
                              holdings: Array.from(checkedHoldings),
                            }),
                          );
                        }}
                        icon={<ArrowUpRight />}
                      >
                        Sell Selected
                      </Button>
                    </div>
                  )}
                  <Button
                    onClick={toggleAddHoldingModal}
                    type={BUTTON_TYPES.SECONDARY}
                    icon={<PlusIcon />}
                  >
                    Add Holding
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(LP_ROUTES.PortfolioUploadCapitalAccounts);
                    }}
                    type={BUTTON_TYPES.PRIMARY}
                    icon={<Barcode />}
                    className="!hidden"
                  >
                    Bulk Upload
                  </Button>
                </div>
              </>
            }
          />
          <TableHeadCard
            style={{ width: "20%" }}
            title="NAV"
            value={new DollarAmount(portfolio.accumBookValue).formattedBig()}
          />
          <TableHeadCard
            style={{ width: "20%" }}
            title="Market Value Estimate"
            value={
              portfolio.accumMarketValue
                ? new DollarAmount(portfolio.accumMarketValue).formattedBig()
                : "Pending"
            }
          />
          <TableHeadCard
            style={{ width: "20%" }}
            title="Price Estimate"
            value={
              portfolio.avgEstimatedPrice
                ? new Percentage(portfolio.avgEstimatedPrice).formatted()
                : "Pending"
            }
          />
          <TableHeadCard
            style={{ width: "10%" }}
            title="Market TVPI"
            value={portfolio.totalTvpi ? `${portfolio?.totalTvpi}x` : "Pending"}
          />
          <TableHeadCard
            hasActions
            style={{ width: "80px" }}
            title={""}
            value={""}
          />
        </>
      }
    />
  );
};
