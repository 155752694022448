import React, { useEffect } from "react";
import styled from "styled-components";

import { ITransaction } from "experiences/transactions/domain/models/Transaction";

import {
  Ask,
  Bid,
} from "experiences/indications-of-interest/domain/models/IOI";
import { RedirectedToDashboard } from "experiences/documents/presentation/state/DocumentsEvent";
import { useDocumentsContext } from "experiences/documents/presentation/state/DocumentsContext";

import { DashboardTransactionList } from "./DashboardTransactionList";
import { DashboardTaskList, useDashboardTaskList } from "./DashboardTaskList";

import { ActiveRecentIoiListRequested } from "../state/DashboardEvent";
import { useDashboardContext } from "../state/DashboardContext";

export type DashboardEvent = {
  id: number;
  logo: string;
  fundName: string;
  locked?: boolean;
  tags?: string[];
  hasNotifications?: boolean;
  tokens?: {
    icon?: string | React.ReactNode;
    value?: string | number;
    label?: string;
  }[];
};

export const GpDashboardCenterColumnContent = styled.div`
  flex-basis: 40%;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  height: inherit;
  max-height: inherit;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DashboardCenterColumn = ({
  activeTransactions,
}: {
  activeTransactions: ITransaction[];
  transactionsLoaded: boolean;
  activity: DashboardEvent[];
  activityLoaded: boolean;
  dashboardActivity: (Bid | Ask)[];
  dashboardLoaded: boolean;
}) => {
  const { dashboardTaskSectionState, emitEvent } = useDocumentsContext();
  const { emitEvent: emitDashboardEvent, activeRecentIoisState } =
    useDashboardContext();

  const { count } = useDashboardTaskList({
    dashboardTaskSectionState,
    activeRecentIoisState,
  });

  useEffect(() => {
    emitEvent(new RedirectedToDashboard());
    emitDashboardEvent(new ActiveRecentIoiListRequested());
  }, []);

  return (
    <GpDashboardCenterColumnContent>
      <DashboardTaskList
        activeRecentIoisState={activeRecentIoisState}
        dashboardTaskSectionState={dashboardTaskSectionState}
      />
      <DashboardTransactionList
        transactions={activeTransactions}
        showBorderTop={count > 0}
      />
    </GpDashboardCenterColumnContent>
  );
};
