import axios from "axios";
import authService, { memoizedRefreshToken } from "../services/authService";
import toCamelCase from "../utils/toCamelCase";
import toSnakeCase from "../utils/toSnakeCase";
import { AUTH_KEYS } from "common/constants/localStorage";

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

// throw alert if api url is not set
if (!import.meta.env.VITE_APP_API_URL) {
  alert("API URL is not set in .env file");
}

axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;

// throw alert if api url is not set
if (!import.meta.env.VITE_APP_API_URL) {
  alert("API URL is not set in .env file");
}

instance.interceptors.request.use(
  async (config: any) => {
    const session = authService.getSession();

    if (session?.access) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session?.access}`,
      };
    }

    return config;
  },
  (error: any) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      // @ts-ignore TODO is this ignore appropriate?
      if (result?.access) {
        config.headers = {
          ...config.headers,
          // @ts-ignore TODO is this ignore appropriate?
          authorization: `Bearer ${result?.access}`,
        };

        // make sure to update the session in local storage immediately after refreshing the token
        localStorage.setItem(AUTH_KEYS.SESSION, JSON.stringify(result));
      }

      return instance(config);
    }
    return Promise.reject(error);
  },
);

class TapClient {
  apiDomain: any;

  headers: any;

  payload: any;

  url: any;

  withAuth: any;

  withCasing: any;

  /*
  Little instance wrapper to talk to our backend.
  Makes it easy to send requests with/without authorization credentials
  and converts responses/payloads to/from camelCase and snakeCase
   */
  constructor(url: string, payload: any = null) {
    this.url = url;
    this.payload = payload || {};
    this.withAuth = true;
    this.withCasing = true;
    this.headers = {};
  }

  withoutCasing() {
    this.withCasing = false;
    return this;
  }

  _processResponse = (res: any) =>
    this.withCasing ? toCamelCase(res.data) : res.data;

  _processPayload(payload: any) {
    return this.withCasing ? toSnakeCase(payload) : payload;
  }

  get(stuff: any) {
    return instance
      .get(this.url, {
        ...stuff,
        params: this._processPayload(this.payload),
      })
      .then(this._processResponse);
  }

  post() {
    return instance
      .post(this.url, this._processPayload(this.payload))
      .then(this._processResponse);
  }

  patch() {
    return instance
      .patch(this.url, this._processPayload(this.payload))
      .then(this._processResponse);
  }

  delete() {
    return instance
      .delete(this.url, {
        data: this._processPayload(this.payload),
      })
      .then(this._processResponse);
  }
}

export default TapClient;
