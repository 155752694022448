import { useEffect, useState } from "react";
import { Modal, Stack, Box } from "@mui/material";

import { BUTTON_TYPES, Button } from "common/components/Button";
import { DropZone } from "experiences/common/DropZone";
import { TapUiModalHead } from "experiences/indications-of-interest/presentation/components/UiModal";

import { useTransactionsContext } from "../../state/TransactionsContext";
import { CircleNotch, FileMagnifyingGlass } from "@phosphor-icons/react";
import { UploadDataRoomFilePressed } from "../../state/TransactionsEvent";
import { delayMs } from "common/utils";
import {
  ProcessDataRoomFileFinished,
  ProcessDataRoomFileProcessing,
  ResetDataroomFileState,
  UploadDataRoomFileSubmitting,
  UploadDataRoomFileSuccess,
} from "../../state/TransactionsState";

export const ReuploadDataroomModal = ({
  open,
  handleClose,
  transactionId,
}: {
  open: boolean;
  handleClose: () => void;
  transactionId: string;
}) => {
  const { emitEvent, dataroomUploadState } = useTransactionsContext();

  const [files, setFiles] = useState<File[]>([]);

  // When the modal is closed, reset the files
  useEffect(() => {
    if (!open && files.length > 0) {
      // Do a small delay because if we reset immediately, the files will be removed before the modal is closed and the user will see a flicker
      delayMs(500).then(() => {
        setFiles([]);
        emitEvent(new ResetDataroomFileState());
      });
    }
  }, [open]);

  const handleFileChange = (files: File[]) => {
    if (files.length > 0) {
      setFiles(files);
    }
  };

  const handleUploadClick = () => {
    emitEvent(
      new UploadDataRoomFilePressed({
        files,
        txnId: transactionId,
      }),
    );
  };

  const isLoading = dataroomUploadState instanceof UploadDataRoomFileSubmitting;
  const isProcessing =
    dataroomUploadState instanceof ProcessDataRoomFileProcessing;
  const uploadSuccess =
    dataroomUploadState instanceof UploadDataRoomFileSuccess;
  const processSuccess =
    dataroomUploadState instanceof ProcessDataRoomFileFinished;
  const showSubmitButton = files.length > 0 && !processSuccess;

  useEffect(() => {
    if (processSuccess) {
      handleClose();

      delayMs(500).then(() => {
        setFiles([]);
        emitEvent(new ResetDataroomFileState());
      });
    }
  }, [uploadSuccess, processSuccess]);

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className="transition-all"
    >
      <div className="w-full max-w-2xl max-h-full overflow-y-scroll no-scrollbar bg-white rounded-xl px-6 py-6 leading-none -translate-x-1/2 left-2/4 absolute top-1/2 -translate-y-1/2">
        <TapUiModalHead
          title="Upload Dataroom"
          subtitle="Update the dataroom by uploading a new zip file containing all documents. This will completely replace the previous dataroom."
          onCloseClick={handleClose}
        />
        <DropZone
          className="mt-8"
          hideDropZoneWhenHasFiles
          onFileChange={handleFileChange}
          multiple={false}
          customMessage={
            <div>
              <span className="text-center flex justify-center items-center gap-2">
                Drag and drop a zip file here or
                <Button className="" icon={<FileMagnifyingGlass />}>
                  Browse Files
                </Button>
              </span>
            </div>
          }
          accept={[".zip"]}
          wrapperModalOpen={open}
        />

        {showSubmitButton && (
          <>
            <Stack className="mt-8" direction="row" spacing={2}>
              <Button
                onClick={handleUploadClick}
                type={BUTTON_TYPES.SECONDARY}
                fullWidth
                size="large"
                disabled={isLoading || isProcessing}
                icon={
                  isLoading || isProcessing ? (
                    <CircleNotch className="animate-spin" />
                  ) : undefined
                }
              >
                Upload
              </Button>
            </Stack>
            {isLoading && (
              <Box className="mt-4">
                <p className="text-sm text-gray-500">
                  Uploading your files. This may take a few minutes.
                </p>
              </Box>
            )}
            {isProcessing && (
              <Box className="mt-4">
                <p className="text-sm text-gray-500">
                  Your file has been uploaded, it's being processed.
                </p>
              </Box>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
