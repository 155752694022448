export const gpTransactionDetailDataroomCashFlowsChartMock = () => [];

export const gpTransactionDetailDataroomInvestmentsMock: GpTransactionDataroomInvestment[] =
  [
    {
      id: "9f47a508-8319-45d1-a921-55f0b6fe036d",
      company: "BellView",
      type: "Preferred Equity",
      class: "Series D",
      ownership: "6.3%",
      date: "1/12/23",
      cost: "$300,000,000",
      bookValue: "$621,600,000",
      unrealizedPL: "$621,600,000",
      realizedPL: "$0",
    },
    {
      id: "e6a3d52f-1fd2-4560-82c7-9722ca59ffb0",
      company: "Hatchet",
      type: "Preferred Equity",
      class: "Series E",
      ownership: "7.6%",
      date: "3/17/2020",
      cost: "$500,000,000",
      bookValue: "$852,000,000",
      unrealizedPL: "$852,000,000",
      realizedPL: "$0",
    },
    {
      id: "e9447561-9825-4aa4-acd6-b8caa2cadf80",
      company: "Kola",
      type: "Preferred Equity",
      class: "Growth",
      ownership: "3.9%",
      date: "11/29/2021",
      cost: "$400,000,000",
      bookValue: "$670,800,000",
      unrealizedPL: "$536,640,000",
      realizedPL: "$134,160,000",
    },
    {
      id: "d3bcf3cb-86bb-461c-bfa2-7223a69e1145",
      company: "Jamboree",
      type: "Preferred Equity",
      class: "Series D-2",
      ownership: "15.6%",
      date: "8/2/2022",
      cost: "$150,000,000",
      bookValue: "$247,950,000",
      unrealizedPL: "$0",
      realizedPL: "$247,950,000",
    },
    {
      id: "66970dfb-a35e-44f4-a45f-9b3ffae2e662",
      company: "Keep Card",
      type: "Preferred Equity",
      class: "Series B",
      ownership: "2.5%",
      date: "3/4/2021",
      cost: "$350,000,000",
      bookValue: "$676,550,000",
      unrealizedPL: "$676,550,000",
      realizedPL: "$0",
    },
    {
      id: "b7bdf2f2-7173-4813-bfa2-a0e917f4663a",
      company: "Quantum Leap Solutions",
      type: "Preferred Equity",
      class: "Series D",
      ownership: "4.1%",
      date: "1/17/2022",
      cost: "$200,000,000",
      bookValue: "$480,200,000",
      unrealizedPL: "$384,160,000",
      realizedPL: "$96,040,000",
    },
    {
      id: "79d20269-67bc-4fc6-b103-0c09465b0c2e",
      company: "Cedar Grove Enterprises",
      type: "Preferred Equity",
      class: "Series D",
      ownership: "16.6%",
      date: "4/1/2019",
      cost: "$350,000,000",
      bookValue: "$631,400,000",
      unrealizedPL: "$0",
      realizedPL: "$631,400,000",
    },
    {
      id: "de356173-9d0e-4c7a-bbbd-9706683cdba5",
      company: "Vim",
      type: "Preferred Equity",
      class: "Series G",
      ownership: "13.5%",
      date: "5/23/2022",
      cost: "$100,000,000",
      bookValue: "$198,200,000",
      unrealizedPL: "$198,200,000",
      realizedPL: "$0",
    },
    {
      id: "11a86c15-d502-4838-90a0-7674b431e8a8",
      company: "Pallet Pros",
      type: "Preferred Equity",
      class: "Series F",
      ownership: "15.9%",
      date: "8/2/2021",
      cost: "$500,000,000",
      bookValue: "$1,116,000,000",
      unrealizedPL: "$1,116,000,000",
      realizedPL: "$0",
    },
    {
      id: "02358dc0-f61a-4065-b2f8-4f77e039660f",
      company: "Sprig",
      type: "Preferred Equity",
      class: "Series C",
      ownership: "25.0%",
      date: "8/2/2020",
      cost: "$150,000,000",
      bookValue: "$235,200,000",
      unrealizedPL: "$0",
      realizedPL: "$235,200,000",
    },
    {
      id: "f23dcada-81bc-400f-bab4-8d75a45364b6",
      company: "Apex Dynamics",
      type: "Preferred Equity",
      class: "Growth",
      ownership: "12.3%",
      date: "7/7/2019",
      cost: "$150,000,000",
      bookValue: "$332,100,000",
      unrealizedPL: "$0",
      realizedPL: "$332,100,000",
    },
    {
      id: "28a7a924-fd82-4626-bc15-0b0526ccce5c",
      company: "BrightWave Analytics",
      type: "Preferred Equity",
      class: "Growth",
      ownership: "10.6%",
      date: "5/29/2019",
      cost: "$250,000,000",
      bookValue: "$540,000,000",
      unrealizedPL: "$540,000,000",
      realizedPL: "$0",
    },
    {
      id: "03c718dd-bebc-46c8-bf21-485643c4905c",
      company: "Sterling Bridge Technologies",
      type: "Preferred Equity",
      class: "Growth",
      ownership: "17.2%",
      date: "3/14/19",
      cost: "$350,000,000",
      bookValue: "$557,900,000",
      unrealizedPL: "$446,320,000",
      realizedPL: "$111,580,000",
    },
  ];

export type GpTransactionDataroomInvestment = {
  id: string;
  company: string;
  type: string;
  class: string;
  ownership: number | string;
  date: string;
  cost: number | string;
  bookValue: number | string;
  unrealizedPL: number | string;
  realizedPL: number | string;
};
