import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";

import { useEntitiesSelector } from "../components/EntitiesDropdown";
import { portfolioClient } from "common/clients/ApolloClient";
import { GetPortfolioSoiQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioSoiQuery";
import { GetPortfolioCompanyLoanDataQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioCompanyLoanData";

import { IPortfolioLookthroughSoi } from "experiences/portfolio-v2/domain/models/PortfolioSoi";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { ReportDateName } from "experiences/portfolio-v2/domain/models/ReportDates";
import { getReportDatesForReportName } from "experiences/portfolio-v2/domain/hooks/getReportDatesForReportName";
import { AggregateSoiGroupKeys } from "../components/TableGrouping";
import { ICompanyLoanData } from "experiences/transactions/presentation/components/fund-data/investments/model";
import { GetPortfolioCsvSoiQuery } from "experiences/portfolio-v2/domain/usecases/GetPortfolioCsvQuery";
import { createSoiExcel } from "experiences/common/excel/aggregate-soi/createExcel";

export const useLookthroughSoi = () => {
  const [loading, setLoading] = useState(false);
  const [reportDate, setReportDate] = useState<string | null>(null);
  const [groupBy, setGroupBy] = useState<AggregateSoiGroupKeys>(
    AggregateSoiGroupKeys.Sector,
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const { reportDates: allReportDates, selectedEntitiesIds } =
    useContext(PortfolioContext);
  const entitySelector = useEntitiesSelector();

  const reportDates = getReportDatesForReportName({
    reportDates: allReportDates,
    reportName: ReportDateName.SoiLookthrough,
  });

  const reportDatesOptions = reportDates.map((reportDate) => ({
    label: reportDate,
    value: reportDate,
  }));

  useEffect(() => {
    const hasReportDates = reportDates.length > 0;
    if (hasReportDates) {
      const firstReportDate = reportDates[0];
      setReportDate(firstReportDate);
    }
  }, [allReportDates]);

  const aggregateSoiData = useQuery<{
    aggregateSoi: IPortfolioLookthroughSoi[];
  }>(GetPortfolioSoiQuery, {
    client: portfolioClient,
    variables: {
      reportDate,
      entitiesIds: selectedEntitiesIds,
      groupBy,
    },
    skip: !reportDate || selectedEntitiesIds.length === 0,
  });

  const companyLoanData = useQuery<{
    companyLoanData: ICompanyLoanData[];
  }>(GetPortfolioCompanyLoanDataQuery, {
    client: portfolioClient,
    variables: {
      entitiesIds: selectedEntitiesIds,
      reportDate,
    },
    skip: !reportDate || selectedEntitiesIds.length === 0,
  });

  const { refetch: downloadCsvData } = useQuery<FundDataSummaryCsv>(
    GetPortfolioCsvSoiQuery,
    {
      variables: {
        entitiesIds: selectedEntitiesIds,
        reportDate: reportDate,
      },
      client: portfolioClient,
      fetchPolicy: "no-cache",
      skip: true,
    },
  );

  const handleRefetch = async () => {
    setLoading(true);
    if (!reportDate || selectedEntitiesIds.length === 0) {
      setLoading(false);
      return;
    }

    await aggregateSoiData.refetch({
      reportDate,
      entitiesIds: selectedEntitiesIds,
    });
    await companyLoanData.refetch({
      entitiesIds: selectedEntitiesIds,
      reportDate,
    });
    setLoading(false);
  };

  // When report date changes, refetch the data
  useEffect(() => {
    handleRefetch();
  }, [reportDate, selectedEntitiesIds, groupBy]); // TODO: check if selectedEntitiesIds is needed

  const onReportDateChange = (value: string) => {
    setReportDate(value);
  };

  const onGroupByChange = (value: AggregateSoiGroupKeys) => {
    setGroupBy(value);
  };

  const handleDownloadClick = () => {
    setIsDownloading(true);
    toast.success(
      "Download started. Make sure your browser is accepting multiple files when prompted.",
    );

    downloadCsvData({
      variables: {
        entitiesIds: selectedEntitiesIds,
        reportDate: reportDate,
      },
    })
      .then((res) => {
        createSoiExcel({
          // @ts-ignore; @ transaction it is soiLookthroughTabs but here is with capital T
          data: res.data?.dataSummaryCsv?.soiLookThroughTabs,
          namePrefix: `soi-lookthrough`,
        });
      })
      .catch((error) => {
        toast.error("Download failed. Please try again.");
      })
      .finally(() => {
        setIsDownloading(false);
        toast.success("Download complete!");
      });
  };

  return {
    loading: loading || aggregateSoiData.loading,
    entitySelector,
    data: aggregateSoiData?.data?.aggregateSoi || [],
    companyLoanData: companyLoanData?.data?.companyLoanData || [],
    reportDate,
    reportDatesOptions,
    onReportDateChange,
    onGroupByChange,
    groupBy,
    handleDownloadClick,
    isDownloading,
  };
};
