import axios from "axios";

interface S3FileParams {
  url: string;
  name: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  };
}

export const uploadFilesToS3 = async ({
  files,
  fileParams,
}: {
  files: File[];
  fileParams: S3FileParams[];
}) => {
  const fileFormData = fileParams
    .map((fileEntry) => {
      const { name, fields, url } = fileEntry;
      const file = files.find((f) => f.name === name);

      if (!file) {
        return;
      }

      const formData = new FormData();

      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);
      formData.append("file", file);

      return {
        formData,
        url,
        type: file.type,
      };
    })
    .filter(Boolean);

  const response = await Promise.all(
    fileFormData.map(({ formData, url, type }) =>
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": type,
          },
        })
        .then(() => {})
        .catch((error) => {
          if (error.status === 204) {
            return {};
          }
          console.error("Error uploading file to S3", error);
        }),
    ),
  );

  return response;
};
