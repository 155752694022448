import styled from "styled-components";

const SIDEBAR_WIDTH_PX = 56;

export const ContentWrapper = styled.div<{
  fullWidth?: boolean;
}>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding-left: ${SIDEBAR_WIDTH_PX}px;
  overflow-x: hidden;

  padding-top: ${SIDEBAR_WIDTH_PX}px;
  box-sizing: border-box;

  ${({ fullWidth }) =>
    fullWidth && `max-width: 100%; padding-left: ${SIDEBAR_WIDTH_PX}px;`}
`;
