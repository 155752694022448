import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { alpha, Theme } from "@mui/material/styles";

// eslint-disable-next-line no-unused-vars
export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreRoundedIcon,
      },

      styleOverrides: {
        root: {},
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: "8px",
          overflow: "hidden",
          transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
          ]),
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#ffffff",
          },
          "&.Mui-focused": {
            border: `1px solid ${theme.palette.grey[800]}`,
            backgroundColor: "#ffffff",
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 3px`,
            borderColor: theme.palette.primary.main,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
          underline: {
            "&:before": {
              borderBottom: "none",
            },
            "&:after": {
              borderBottom: "none",
            },
          },
        },
      },
    },
  };
}
