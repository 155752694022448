import { useContext, useEffect, useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import { generatePath, useNavigate } from "react-router";

import {
  ITabTableColumn,
  StyledTableBody,
  StyledTableRow,
  TableAlignment,
  TableCell,
  tableCellDataFormatter,
  TapTable,
} from "experiences/funds/presentation/components/Table";
import { IPortfolioExposureSummaryWithGrouping } from "experiences/portfolio-v2/domain/models/PortfolioExposureSummary";
import { FundExposureGroupingKeys } from "../components/TableGrouping";
import { cn } from "common/utils";
import { PortfolioContext } from "../state/PorfolioV2Context";
import { FundStrategyNameMap } from "experiences/common/models/FundStrategy";
import { FundGeographyNameMap } from "experiences/common/models/FundGeography";
import { BarLoader } from "common/components/BarLoader";
import { TableFundLogo } from "common/components/TableFundLogo";
import { FUND_LOGO_URL } from "common/constants/platform";
import { LP_ROUTES } from "common/constants/routes";
import { VIEWPOINT_FUND_ID } from "experiences/funds/presentation/components/viewpoint/viewpointOverview";

enum RowType {
  Total = "total",
  Group = "group",
  Holding = "fund",
}

interface ITableRow {
  type: RowType;
  label: string;
  id: string;
  children: ITableRow[];
}

// this is a keyof IPortfolioExposureSummary
enum SummaryTableColumnKeys {
  Holding = "holding",
  PortfolioPercent = "portfolioPercent",
  FundsCount = "fundsCount",
  Nav = "nav",
  Committed = "committed",
  Unfunded = "unfunded",
  Called = "called",
  Distributed = "distributed",
  NetMoic = "netMoic",
  NetIrr = "netIrr",
}
// prettier-ignore
const columns: ITabTableColumn[] = [
  { label: "Holding", key: SummaryTableColumnKeys.Holding, align: TableAlignment.LEFT, className: "!w-96 !max-w-96" },
  { label: "Portfolio Percent", key: SummaryTableColumnKeys.PortfolioPercent, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" },
  { label: "Funds Count", key: SummaryTableColumnKeys.FundsCount, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" },
  { label: "NAV", key: SummaryTableColumnKeys.Nav, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" },
  { label: "Committed", key: SummaryTableColumnKeys.Committed, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" },
  { label: "Unfunded", key: SummaryTableColumnKeys.Unfunded, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" },
  // { label: "Called", key: SummaryTableColumnKeys.Called, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" }, // Remoted 2024-12-16; remove from code later
  { label: "Distributed", key: SummaryTableColumnKeys.Distributed, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" },
  // { label: "Net MOIC", key: SummaryTableColumnKeys.NetMoic, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" }, // Remoted 2024-12-16; remove from code later
  // { label: "Net IRR", key: SummaryTableColumnKeys.NetIrr, align: TableAlignment.RIGHT, className: "!w-36 !max-w-36" }, // Remoted 2024-12-16; remove from code later
];

interface IHoldingRow {
  id: string;
  label: string;
  type: RowType;
  portfolioPercent: string;
  fundsCount: number;
  nav: string;
  committed: string;
  unfunded: string;
  called: string;
  distributed: string;
  netMoic: string;
  netIrr: string;
  issuerId: string;
}

const useSummaryTable = ({
  data,
  loading,
}: {
  data: IPortfolioExposureSummaryWithGrouping;
  loading: boolean;
}) => {
  const [holdings, setHoldings] = useState<IHoldingRow[]>([]);
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [openRowIds, setOpenRowIds] = useState<string[]>([]);
  const portfolioContext = useContext(PortfolioContext);
  const navigate = useNavigate();

  const getGroupLabelString = (
    groupingKey: FundExposureGroupingKeys,
    value: string | number,
  ): string => {
    if (!groupingKey) return String(value);
    if (String(value).toLowerCase() === "total") return "Total";

    if (groupingKey === FundExposureGroupingKeys.Entity) {
      const entity = portfolioContext.entities.find((e) => e.id === value);
      return entity?.name || String(value);
    }
    if (groupingKey === FundExposureGroupingKeys.Manager) {
      const report = data.reportData.find((r) => r.managerId === value);
      return report?.managerName || String(value);
    }
    if (groupingKey === FundExposureGroupingKeys.Strategy) {
      return !!FundStrategyNameMap.get(Number(value))
        ? String(FundStrategyNameMap.get(Number(value)))
        : "Other";
    }
    if (groupingKey === FundExposureGroupingKeys.Geography) {
      return !!FundGeographyNameMap.get(Number(value))
        ? String(FundGeographyNameMap.get(Number(value)))
        : "Other";
    }
    if (groupingKey === FundExposureGroupingKeys.Vintage) {
      return String(value);
    }
    return String(value);
  };

  const handleTotalRowClick = (rowId: string) => {
    if (openRowIds.includes(rowId)) {
      setOpenRowIds([]); // Close all rows
      setRows((prevRows) => {
        const topLevelRow = prevRows.find((row) => row.id === rowId);
        const topLevelRowWithoutChildren = {
          ...topLevelRow,
          children: [],
        };

        return [topLevelRowWithoutChildren];
      });
    } else {
      // Open total row
      setOpenRowIds([rowId]);
      // Generate one row for each entry in data.groupingData.aggregateData
      // Append them to rows

      const newRows: ITableRow[] = data.groupingData.aggregateData
        .map((group) => {
          return {
            type: RowType.Group,
            label: group.groupKey,
            id: group.groupKey,
            children: [],
          };
        })
        .sort((a, b) => {
          // Sort vintage in descending order
          if (
            data?.groupingData?.groupedBy === FundExposureGroupingKeys.Vintage
          ) {
            return Number(b.label) - Number(a.label);
          }
          return 0;
        });

      setRows((prev) => {
        const rowToUpdate = prev.find((row) => row.id === rowId);
        if (!rowToUpdate) return prev;

        return [
          ...prev.filter((row) => row.id !== rowId),
          { ...rowToUpdate, children: [...rowToUpdate.children, ...newRows] },
        ];
      });
    }
  };

  const handleRowGroupClick = (rowId: string) => {
    if (openRowIds.includes(rowId)) {
      setOpenRowIds((prev) => [...prev.filter((id) => id !== rowId)]);

      setRows((prevRows) => {
        const totalRow = prevRows.find((row) => row.type === RowType.Total);

        const groupRowIndex = totalRow?.children.findIndex(
          (row) => row.id === rowId,
        );

        if (groupRowIndex === -1) return prevRows;

        const newGroupRow: ITableRow = {
          ...totalRow.children[groupRowIndex],
          children: [],
        };

        const newTotalRow: ITableRow = {
          ...totalRow,
          children: [
            ...totalRow.children.slice(0, groupRowIndex),
            newGroupRow,
            ...totalRow.children.slice(groupRowIndex + 1),
          ],
        };

        return [newTotalRow];
      });
    } else {
      // Add to open rows
      setOpenRowIds((prev) => [...prev, rowId]);
      // Grab all holdings that belong to this group
      const groupIssuerIds = data.groupingData.aggregateData.find(
        (group) => group.groupKey === rowId,
      ).groupIds;
      const groupHoldings = holdings.filter((h) =>
        groupIssuerIds.includes(h.issuerId),
      );

      // Add groupHoldings to the row where (row.id === rowId).children
      setRows((prevRows) => {
        // First, find the total row
        const totalRow = prevRows.find((row) => row.type === RowType.Total);
        const rowToUpdateIndex = totalRow?.children.findIndex(
          (row) => row.id === rowId,
        );
        if (rowToUpdateIndex === -1) return prevRows;

        // Set children of rowToUpdate to groupHoldings
        const groupChildren: ITableRow[] = groupHoldings.map((h) => ({
          type: RowType.Holding,
          label: h.label,
          id: h.id,
          children: [],
        }));

        const newRowToUpdate: ITableRow = Object.assign(
          {},
          totalRow.children[rowToUpdateIndex],
          {
            children: [
              ...totalRow.children[rowToUpdateIndex].children,
              ...groupChildren,
            ],
          },
        );

        const newTotalRow: ITableRow = Object.assign({}, totalRow, {
          children: [
            ...totalRow.children.slice(0, rowToUpdateIndex),
            newRowToUpdate,
            ...totalRow.children.slice(rowToUpdateIndex + 1),
          ],
        });

        return [newTotalRow];
      });
    }
  };

  const handleRowClick = (rowId: string, rowType: RowType) => {
    if (rowType === RowType.Total) {
      handleTotalRowClick(rowId);
      return;
    }
    if (rowType === RowType.Group) {
      handleRowGroupClick(rowId);
      return;
    }
    if (rowType === RowType.Holding) {
      const path = generatePath(LP_ROUTES.FundsFundDetail, {
        fundId: VIEWPOINT_FUND_ID,
      });
      navigate(path);
    }
  };

  // Step 1: Always have the top level aggregate data
  useEffect(() => {
    if (data === null) {
      setHoldings([]);
      setRows([]);
      setOpenRowIds([]);
      return;
    }

    if (!data?.reportData || !data?.groupingData || loading) return;

    // 1a: Format all rows and groups with special formatting that may be needed
    // TODO: Do this later
    // NOTES: maybe use the "groupKey" to identify rows when grouping albeit not enough if combined with ungrouped holdings
    // Grab all holdings and categories and put them inside holdings
    // Actually this is truly the 1st step
    const topLevelHolding: IHoldingRow = {
      type: RowType.Total,
      portfolioPercent:
        data?.groupingData?.topLevelAggregateData?.portfolioPercent,
      fundsCount: data?.groupingData?.topLevelAggregateData?.fundsCount,
      nav: data?.groupingData?.topLevelAggregateData?.nav,
      committed: data?.groupingData?.topLevelAggregateData?.committed,
      unfunded: data?.groupingData?.topLevelAggregateData?.unfunded,
      called: data?.groupingData?.topLevelAggregateData?.called,
      distributed: data?.groupingData?.topLevelAggregateData?.distributed,
      netMoic: data?.groupingData?.topLevelAggregateData?.netMoic,
      netIrr: data?.groupingData?.topLevelAggregateData?.netIrr,
      label: "Total",
      id: "total",
      issuerId: "",
    };
    const groupHoldings: IHoldingRow[] = data?.groupingData?.aggregateData.map(
      (group) => {
        return {
          type: RowType.Group,
          portfolioPercent: group.portfolioPercent,
          fundsCount: group.fundsCount,
          nav: group.nav,
          committed: group.committed,
          unfunded: group.unfunded,
          called: group.called,
          distributed: group.distributed,
          netMoic: group.netMoic,
          netIrr: group.netIrr,
          label: getGroupLabelString(
            data?.groupingData?.groupedBy,
            group.groupKey,
          ),
          id: group.groupKey,
          issuerId: "",
        };
      },
    );
    const actualHoldings = data?.reportData
      .map((entry) => {
        return {
          type: RowType.Holding,
          portfolioPercent: entry.portfolioPercent,
          fundsCount: null,
          nav: entry.lpEndingCapitalAccountValue,
          committed: entry.lpCommittedCapital,
          unfunded: entry.lpRemainingUnfunded,
          called: "", // TODO: Add this to payload or ask which field to use
          distributed: entry.lpDistributedCapitalItd,
          netMoic: entry.fundSeriesAverageMoic,
          netIrr: entry.fundSeriesAverageMoic,
          label: entry.fundName,
          id: entry.issuerId,
          issuerId: entry.issuerId || "",
          managerId: entry.managerId || "",
        };
      })
      .sort((a, b) => {
        return Number(b.nav || 0) - Number(a.nav || 0);
      });

    const allHoldings = [topLevelHolding, ...groupHoldings, ...actualHoldings];

    // By default, always add the top level row
    const newRows: ITableRow[] = data.groupingData.aggregateData
      .map((group) => {
        return {
          type: RowType.Group,
          label: group.groupKey,
          id: group.groupKey,
          children: [],
        };
      })
      .sort((a, b) => {
        // Sort vintage in descending order
        if (
          data?.groupingData?.groupedBy === FundExposureGroupingKeys.Vintage
        ) {
          return Number(b.label) - Number(a.label);
        }
        return 0;
      });

    setHoldings(allHoldings);
    setOpenRowIds(["total"]);
    setRows((prev) => {
      const topLevelRow: ITableRow = {
        type: RowType.Total,
        label: "Total",
        id: "total",
        children: newRows,
      };

      return [...prev.filter((row) => row.id !== "total"), topLevelRow];
    });
  }, [data, data?.groupingData?.groupedBy, data?.reportData, loading]);

  const renderRow = (row: ITableRow) => {
    const isOpen = openRowIds.includes(row.id);
    const isHolding = row.type === RowType.Holding;
    const isFolder = row.type === RowType.Group || row.type === RowType.Total;
    const showCaret = isFolder;

    const holdingForRow = holdings.find((h) => h.id === row.id);

    const label = isFolder
      ? getGroupLabelString(data?.groupingData?.groupedBy, row.label)
      : row.label;

    const imgSrc = `${FUND_LOGO_URL}/${holdingForRow?.managerId}.jpg`;

    return (
      <>
        <StyledTableRow
          onClick={() => handleRowClick(row.id, row.type)}
          key={row.id}
        >
          <TableCell
            className={cn("select-none overflow-ellipsis", {
              "!pl-10": row.type === RowType.Group,
              "!pl-16 !pt-1 !pb-1": isHolding,
              "!font-medium": isFolder,
            })}
          >
            {showCaret && (
              <CaretRight
                className={cn(
                  "transition-transform inline mr-2",
                  isOpen && "rotate-90",
                )}
              />
            )}
            {isHolding ? (
              <TableFundLogo imgSrc={imgSrc} fundName={label} />
            ) : (
              label
            )}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.portfolioPercent,
              format: "percentage",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {holdingForRow.fundsCount}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.nav,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.committed,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.unfunded,
              format: "currency",
            })}
          </TableCell>
          <TableCell className="!w-36 !max-w-36 text-right">
            {tableCellDataFormatter({
              value: holdingForRow.distributed,
              format: "currency",
            })}
          </TableCell>
        </StyledTableRow>
        {row.children.length > 0 &&
          row.children.map((child) => renderRow(child))}
      </>
    );
  };

  return {
    rows,
    renderRow,
  };
};

export const SummaryTable = ({
  loading,
  data,
}: {
  loading: boolean;
  data: IPortfolioExposureSummaryWithGrouping;
}) => {
  const { rows, renderRow } = useSummaryTable({ data, loading });

  return (
    <div className="w-full max-w-full overflow-x-scroll min-h-96 h-auto relative pb-12">
      <TapTable
        data={loading ? [] : rows}
        columns={columns}
        layoutFixed
        isLoading={loading}
        className="w-full min-w-[700px]"
        renderBody={(bodyData) => (
          <StyledTableBody>{bodyData.map(renderRow)}</StyledTableBody>
        )}
      />
      {loading && <BarLoader />}
    </div>
  );
};
