import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageHead } from "common/components/PageHead";
import { EmptyState } from "common/components/EmptyState";
import { LoadTransactionPolicies } from "../state/TransactionsEvent";
import { useTransactionsContext } from "../state/TransactionsContext";
import { TransactionPoliciesTable } from "../components/transaction-settings/TransactionPoliciesTable";
import { TransactionPoliciesLoaded } from "../state/TransactionsState";

export const TransactionSettingsPolicies = () => {
  const { transactionPoliciesState, emitEvent } = useTransactionsContext();

  useEffect(() => {
    emitEvent!(new LoadTransactionPolicies());
  }, []);

  const policies =
    transactionPoliciesState instanceof TransactionPoliciesLoaded
      ? transactionPoliciesState.transactionPolicies
      : [];

  return (
    <>
      <Helmet title="Transaction Policies" />
      <PageHead title="Transaction Policies" countLabel={policies.length} />
      {transactionPoliciesState instanceof TransactionPoliciesLoaded ? (
        <TransactionPoliciesTable transactionPolicies={policies} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};
