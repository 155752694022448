import { useNavigate } from "react-router";
import styled from "styled-components";

import { GP_ROUTES, LP_ROUTES } from "common/constants/routes";
import { DashboardList } from "experiences/common/List";
import { APP_MENU_HEIGHT_PX } from "experiences/common/PageWrapper";
import { LimitedPartner } from "experiences/dashboard/mock/GP";
import { StyledFundNameAndImage } from "experiences/approved-buyers/presentation/components/ApprovedBuyersTable";

export const DashboardLeftColumnContent = styled.div`
  flex-basis: 30%;
  height: inherit;
  max-height: inherit;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;

  height: calc(
    100vh - ${APP_MENU_HEIGHT_PX}px - var(--indexes-ticker-height-px)
  );
  max-height: calc(
    100vh - ${APP_MENU_HEIGHT_PX}px - var(--indexes-ticker-height-px)
  );

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RenderManagedFunds = ({
  managedFunds,
}: {
  managedFunds: LimitedPartner[];
}) => {
  const navigate = useNavigate();

  const handleFundClick = (fund: LimitedPartner) => {
    console.log({ fund });
  };

  return (
    <>
      <table className={`dashboard_table`}>
        <thead>
          <tr>
            <th>Limited Partners</th>
            <th
              style={{
                textAlign: "right",
                fontVariantNumeric: "tabular-nums",
              }}
            >
              Active Indications
            </th>
          </tr>
        </thead>
        <tbody>
          {managedFunds.map((fund, i) => {
            return (
              <tr onClick={() => handleFundClick(fund)} key={i}>
                <td>
                  <StyledFundNameAndImage>
                    <img
                      src={`https://cdn.usetap.com/funds/logos/${fund.logoFileName}`}
                      alt="Fund logo"
                    />{" "}
                    {fund.lpName}
                  </StyledFundNameAndImage>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  {fund.currentParticipation}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const LimitedPartners = ({ managedFunds }: { managedFunds: any[] }) => {
  const navigate = useNavigate();

  const handleTitleClick = () => {
    navigate(GP_ROUTES.LimitedPartners);
  };

  return (
    <DashboardList
      title="Limited Partners"
      onTitleClick={handleTitleClick}
      table={<RenderManagedFunds managedFunds={managedFunds} />}
    />
  );
};

export const GpDashboardLeftColumn = ({
  managedFunds,
  managedFundsLoaded,
}: {
  managedFunds: any[];
  managedFundsLoaded: boolean;
}) => {
  return (
    <DashboardLeftColumnContent>
      {managedFundsLoaded && <LimitedPartners managedFunds={managedFunds} />}
    </DashboardLeftColumnContent>
  );
};
