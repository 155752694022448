import { Either, left, right } from "fp-ts/lib/Either";
import { Failure } from "common/@types/app/Failure";
import TapClient from "common/clients/TapClient";
import { IAsyncUseCase } from "common/@types/app/UseCase";

const BID_BULK_PATH = "/iois/manager/bulk/create/";

export type ManagerBid = {
  managerId: string;
  minAmount: number;
  maxAmount?: number;
};

export class BidBulkUploadManager implements IAsyncUseCase<IParams, any> {
  call: (params: IParams) => Promise<Either<Failure, any>> = async (
    payload,
  ) => {
    return await new TapClient(BID_BULK_PATH, payload)
      .post()
      .then((resp: any) => right(resp))
      .catch((resp: any) => left(new Failure(resp.response)));
  };
}

interface IParams {
  bids: ManagerBid[];
}
