import * as React from "react";
const PeopleAlt = (props: {}) => (
  <svg
    width={14}
    height={11}
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66661 2.93331C6.66661 4.2219 5.62189 5.26661 4.3333 5.26661C3.04471 5.26661 2 4.2219 2 2.93331C2 1.64472 3.04471 0.600006 4.3333 0.600006C5.62189 0.600006 6.66661 1.64472 6.66661 2.93331Z"
      fill="#85D388"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6662 3.60033C12.6662 4.52073 11.92 5.26691 10.9996 5.26691C10.0792 5.26691 9.33301 4.52073 9.33301 3.60033C9.33301 2.67992 10.0792 1.93375 10.9996 1.93375C11.92 1.93375 12.6662 2.67992 12.6662 3.60033Z"
      fill="#85D388"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.27593 10.5997H13.6661C13.7545 10.5997 13.8394 10.5647 13.9018 10.5021C13.9644 10.4397 13.9994 10.3547 13.9994 10.2664V8.26642C13.9994 6.97783 12.9548 5.93323 11.6661 5.93323H10.3329C9.63852 5.93323 9.01522 6.23656 8.58789 6.71746C9.05562 7.33284 9.33295 8.10079 9.33295 8.93314V10.2664C9.33295 10.3834 9.31287 10.4954 9.27593 10.5997Z"
      fill="#85D388"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66638 8.93314V10.2664C8.66638 10.4504 8.51705 10.5997 8.33313 10.5997H0.333362C0.149327 10.5997 0 10.4504 0 10.2664V8.93314C0 7.27616 1.34293 5.93323 2.99991 5.93323H5.66646C7.32344 5.93323 8.66638 7.27616 8.66638 8.93314Z"
      fill="#85D388"
    />
  </svg>
);
export default PeopleAlt;
