import { Failure } from "common/@types/app/Failure";
import { ITPTransaction } from "../../tp/pages/TpTransactionListPage";

export class TPTransactionsState {}

export class TPTransactionListLoading extends TPTransactionsState {}

export class TPTransactionListLoaded extends TPTransactionsState {
  transactions: ITPTransaction[];

  constructor({ transactions }: { transactions: ITPTransaction[] }) {
    super();
    this.transactions = transactions;
  }
}

export class TPTransactionListLoadFailed extends TPTransactionsState {
  error: Error | Failure;

  constructor(error: Error | Failure) {
    super();
    this.error = error;
  }
}
