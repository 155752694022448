import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bank, Handshake, SignOut } from "@phosphor-icons/react";

import { useAuthContext } from "experiences/authentication/presentation/state/AuthenticationContext";
import { THIRD_PARTY_ROUTES, UNIVERSAL_ROUTES } from "common/constants/routes";
import { useKeyboardShortcuts } from "../../KeyboardShortcuts";
import { ClickOutside } from "common/components/ClickOutside";

import { NavLinks } from "../NavLinks";

import {
  CurrentExperience,
  StyledDropdown,
  StyledMenu,
  StyledMenuGroup,
  NavLinkWrapper,
  StyledUser,
  UserPicture,
  TapIconLink,
  TapSupportLink,
} from "../styles";
import { CurrentExperienceIcon } from "../CurrentExperience";

const Dropdown = () => {
  return (
    <StyledDropdown>
      <Link to={UNIVERSAL_ROUTES.AuthLogout}>
        Logout <SignOut />
      </Link>
    </StyledDropdown>
  );
};

const User = () => {
  const { user } = useAuthContext();
  const [showDropdown, setShowDropdown] = useState(false);

  if (!user) return null;

  const initials = user.firstName[0] + user.lastName[0];

  return (
    <StyledUser onClick={() => setShowDropdown(!showDropdown)}>
      <ClickOutside
        handleOutsideClick={() => setShowDropdown(false)}
        role="menu"
      >
        {user.firstName}
        <UserPicture initials={initials} />

        {showDropdown && <Dropdown />}
      </ClickOutside>
    </StyledUser>
  );
};

export const TPAppBar = () => {
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);

  // for some reason, navlink's active prop is not working so I'm doing it manually
  const transactionListActive = window.location.pathname.includes(
    THIRD_PARTY_ROUTES.Transactions,
  );
  const compliancePageActive = window.location.pathname.includes(
    THIRD_PARTY_ROUTES.Compliance,
  );

  const menuButtons = [
    {
      to: THIRD_PARTY_ROUTES.Transactions,
      label: "Transactions",
      icon: Handshake,
    },
    {
      to: THIRD_PARTY_ROUTES.Compliance,
      label: "Compliance",
      icon: Bank,
    },
  ];

  const goToCurrentExperience = () => {
    if (transactionListActive) {
      navigate(THIRD_PARTY_ROUTES.Transactions);
      return;
    } else if (compliancePageActive) {
      navigate(THIRD_PARTY_ROUTES.Compliance);
      return;
    }
  };

  const handleSearchClick = () => {
    setSearchOpen(true);
  };

  useKeyboardShortcuts({
    openGlobalSearch: handleSearchClick,
  });

  return (
    <>
      <StyledMenu>
        <StyledMenuGroup>
          <TapIconLink to={THIRD_PARTY_ROUTES.Transactions} />
          <NavLinks buttons={menuButtons} />
        </StyledMenuGroup>
        <StyledMenuGroup>
          <TapSupportLink />
        </StyledMenuGroup>
      </StyledMenu>

      <NavLinkWrapper>
        <CurrentExperience onClick={goToCurrentExperience}>
          {transactionListActive && (
            <CurrentExperienceIcon icon={Handshake} label="Transactions" />
          )}
          {compliancePageActive && (
            <CurrentExperienceIcon icon={Bank} label="Compliance" />
          )}
        </CurrentExperience>

        <User />
      </NavLinkWrapper>
    </>
  );
};
