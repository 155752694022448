import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import TextChip from "experiences/common/TextChip";
import classNames from "classnames";

const StyledSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  a {
    display: block;
    padding: 8px 12px;
    box-sizing: border-box;
    color: #737476;
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 24px;
    border-left: 3px solid transparent;
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    transition: background-color 90ms ease-in-out;

    &:hover {
      background-color: #fbfaf8;
    }
    &.active {
      color: #21272d;
      border-left: 3px solid #bcb3a5;
    }
    &.coming_soon {
      color: #b1b2b3;
      pointer-events: none;
    }
  }
`;

type MenuItem = {
  label: string;
  path: string;
  comingSoon?: boolean;
}[];

export const SidebarSideMenu = ({
  baseRoute,
  menu,
  isFundSidebar,
  contact,
  loading,
}: {
  baseRoute?: string;
  menu: MenuItem;
  isFundSidebar?: boolean;
  contact?: React.ReactNode;
  loading?: boolean;
}) => {
  const currentLocation = useLocation();

  return (
    <StyledSideMenu className="h-100 no-scrollbar">
      <div className="flex-1 no-scrollbar p-5 box-border">
        {menu.map((item) => {
          const to = `${baseRoute || ""}${item.path}`;

          const isActive =
            currentLocation.pathname.startsWith(to) ||
            (isFundSidebar &&
              currentLocation.hash.includes(item.path.split("#")[1]));

          return (
            <Link
              to={to}
              className={classNames({
                active: isActive,
                coming_soon: item.comingSoon,
              })}
              key={`${item.label}-${item.path}`}
            >
              {item.label}
              {item.comingSoon && (
                <span style={{ marginLeft: 8 }}>
                  <TextChip text="coming soon" pending />
                </span>
              )}
            </Link>
          );
        })}
      </div>
      {contact ? contact : null}
    </StyledSideMenu>
  );
};
