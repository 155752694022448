import { Box, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { DateFormatter } from "common/@types/app/DateFormatter";
import { DollarAmount } from "common/@types/app/DollarAmount";
import { Percentage } from "common/@types/app/Percentage";
import { TableLayout } from "experiences/common/TableLayout";
import {
  Bid,
  BidTransactionHolding,
  IBidHoldingPrice,
  PartialBid,
  WholePortfolioBid,
} from "experiences/transactions/domain/models/Bidding";

interface IReviewPricingTableProps {
  fullBid: Bid;
  holdings: BidTransactionHolding[];
}

const ReviewPricingTable: React.FC<IReviewPricingTableProps> = ({
  fullBid,
  holdings,
}) => {
  return (
    <TableLayout
      headerStyles={{ fontSize: "12px" }}
      minWidth="800px"
      containerProps={{
        sx: {
          padding: 1,
          border: "1px solid #DBDCDA",
        },
      }}
      headerElems={[
        {
          label: "Name",
          key: "fundName",
          width: "25%",
        },
        {
          label: "reference date",
          key: "refDate",
          width: "20%",
        },
        {
          label: "net asset value",
          key: "nav",
          width: "20%",
        },
        {
          label: "price",
          key: "committed",
          width: "15%",
        },
        {
          label: "price (% of NAV)",
          key: "contributed",
          width: "15%",
        },
      ]}
      rows={
        <>
          {fullBid instanceof PartialBid ? (
            holdings!.map((holding) => (
              <HoldingPricingRow
                pricingDetail={fullBid.detailsSet!.find(
                  (detail) => detail.txnHoldingId == holding.id,
                )}
                holding={holding}
              />
            ))
          ) : fullBid instanceof WholePortfolioBid ? (
            holdings!.map((holding) => <HoldingPricingRow holding={holding} />)
          ) : (
            <></>
          )}
          <TotalPricingRow
            isWholePortfolio={fullBid instanceof WholePortfolioBid}
            nav={holdings
              .map((holding) => holding.holding.bookValue)
              .reduce((prev, curr) => prev + curr)}
            price={
              fullBid instanceof WholePortfolioBid
                ? fullBid.details?.price!
                : fullBid instanceof PartialBid
                  ? fullBid
                      .detailsSet!.map((details) => details.price!)
                      .reduce((prev, curr) => prev + curr)
                  : 0
            }
          />
        </>
      }
    />
  );
};

interface IHoldingPricingRowProps {
  holding: BidTransactionHolding;
  pricingDetail?: IBidHoldingPrice;
}

export const HoldingPricingRow: React.FC<IHoldingPricingRowProps> = ({
  holding,
  pricingDetail,
}) => {
  return (
    <TableRow>
      <TableCell>
        <Typography fontWeight="bold">{holding.issuerName}</Typography>
        <Typography fontSize={12} fontWeight="bold" color={"#848484"}>
          USD
        </Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        {pricingDetail ? (
          <TextField
            size="small"
            value={new DateFormatter(
              holding.holding.referenceDate,
            ).quarterFormatted()}
            disabled
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography>
          {new DollarAmount(holding.holding.bookValue)
            .formatted()
            .replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography fontWeight="bold" display="inline-block">
          {pricingDetail ? (
            <Box width={180}>
              <TextField
                size="small"
                value={new DollarAmount(pricingDetail.price!)
                  .formatted()
                  .replaceAll(" ", "")}
                disabled
              />
            </Box>
          ) : (
            "-"
          )}
        </Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography fontWeight="bold" display="inline-block">
          {pricingDetail ? (
            <Box width={180}>
              <TextField
                size="small"
                value={new Percentage(
                  (pricingDetail.price! / holding.holding.bookValue) * 100,
                ).formatted()}
                disabled
              />
            </Box>
          ) : (
            "-"
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

interface ITotalPricingRow {
  nav: number;
  price: number;
  isWholePortfolio?: boolean;
}

export const TotalPricingRow: React.FC<ITotalPricingRow> = ({
  nav,
  price,
  isWholePortfolio = false,
}) => {
  return (
    <TableRow>
      <TableCell>
        <Typography fontWeight="bold">{"Total"}</Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography>
          {new DollarAmount(nav).formatted().replaceAll(" ", "")}
        </Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography fontWeight="bold" display="inline-block">
          <Box width={140}>
            {isWholePortfolio ? (
              <TextField
                size="small"
                value={new DollarAmount(price!).formatted().replaceAll(" ", "")}
                disabled
              />
            ) : (
              new DollarAmount(price!).formatted().replaceAll(" ", "")
            )}
          </Box>
        </Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography fontWeight="bold" display="inline-block">
          <Box width={140}>
            {isWholePortfolio ? (
              <TextField
                size="small"
                value={new Percentage((price / nav) * 100).formatted()}
                disabled
              />
            ) : (
              new Percentage((price / nav) * 100).formatted()
            )}
          </Box>
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ReviewPricingTable;
