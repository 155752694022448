import { Either, right } from "fp-ts/lib/Either";
import { Failure } from "../../../../common/@types/app/Failure";
import { IAsyncUseCase } from "../../../../common/@types/app/UseCase";

import { ArchivedBuyer } from "../models/ArchivedBuyer";
import { archivedBuyersMock } from "./archivedBuyersMock";

interface IParams {}

export class GetArchivedBuyers
  implements IAsyncUseCase<IParams, ArchivedBuyer[]>
{
  call: (params: IParams) => Promise<Either<Failure, ArchivedBuyer[]>> =
    async (): Promise<Either<Failure, ArchivedBuyer[]>> => {
      return right([...archivedBuyersMock]);
    };
}
